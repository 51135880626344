<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->
<p-toast></p-toast>
<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">
    <app-dashboard-navbar></app-dashboard-navbar>
    <div class="dropdown profile-nav-item d-flex flex-column align-items-end">
        <a class="dropdown-toggle admin-btn" style="text-decoration: none;" role="button" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <div class="menu-profile">
                <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
                <span class="name">{{getname}}</span>
            </div>
        </a>
        <div class="dropdown-menu d-flex flex-column align-items-center">
            <div class="dropdown-header align">
                <div class="figure mb-3">
                    <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
                </div>
                <div class="info text-center">
                    <span class="name" style="white-space: break-spaces;">{{getname}}</span>
                    <p class="mb-3 email">{{getRole}}</p>
                </div>
            </div>
            <!-- <div class="dropdown-body">
				<ul class="profile-nav p-0 pt-3">
					<li class="nav-item"><a routerLink="/dashboard-my-profile" class="nav-link"><i class='bx bx-user'></i> <span>Profile Dashboard</span></a></li>
				</ul>
			</div> -->
            <div class="dropdown-footer">
                <ul class="profile-nav">
                    <li class="nav-item"><a class="nav-link" (click)="logout()"><i class='bx bx-log-out'></i> <span
                                class="logout-text">Logout</span></a></li>
                </ul>
            </div>

        </div>
    </div>
    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
        <h1>{{ Content.title }}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item">
                {{ Content.subtitle }}
            </li>
            <li class="item">{{ Content.title }}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->
    <form [formGroup]="oneToThreeYearMonitor" (ngSubmit)="submitGrowthMonitoringForm(formSubmitted)"
        #formSubmitted="ngForm" (keydown.enter)="$event.preventDefault()">
        <!-- BABY DETAILS SECTION -->
        <div class="add-listings-box">
            <h3>Baby Details</h3>
            <div class="row">
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="p-0">Name<span class="required">*</span></label>
                        <p-dropdown [options]="babyDetails" (onChange)="getBabyDetails($event)" optionValue="id"
                            [readonly]="true" optionLabel="name" [filter]="true" filterBy="name"
                            formControlName="baby_details_id" placeholder="Select Baby's Name"
                            [resetFilterOnHide]="true" [class.is-invalid]="(formSubmitted.submitted &&
                            oneToThreeYearMonitor.get('baby_details_id')?.invalid)
                            ||
                            oneToThreeYearMonitor.get('baby_details_id')?.invalid
                            &&
                            oneToThreeYearMonitor.get('baby_details_id').touched">
                        </p-dropdown>
                        <div class="invalid-feedback">Name is required</div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Gender</label>
                        <div class="card flex justify-content-center">
                            <input type="text" class="form-control" [(ngModel)]="babyGender"
                                [ngModelOptions]="{standalone: true}" placeholder="Select Baby's Gender" required
                                [readonly]="true">
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Date of birth</label>
                        <div class="card flex justify-content-center">
                            <!-- <p-calendar readonly [(ngModel)]="selectedDate" [ngModelOptions]="{standalone: true}"
                                [showIcon]="true" [readonlyInput]="true" placeholder="DD/MM/YYYY"></p-calendar> -->
                            <input type="text" name="name" class="form-control" placeholder="DD/MM/YYYY"
                                [value]="selectedDate" readonly />
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Days</label>
                        <input type="text" name="days" class="form-control" placeholder="Days" [value]="days"
                            readonly />
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Months</label>
                        <input type="text" name="months" class="form-control" placeholder="Months" [value]="month"
                            readonly />
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Years</label>
                        <input type="text" name="years" class="form-control" placeholder="Years" [value]="years"
                            readonly />
                    </div>
                </div>
            </div>
        </div>
        <!-- Form section -->
        <ng-container *ngIf="flag">
            <div class="add-listings-box py-3">
				<div class="row">
					<label class="subHeading mb-3">Height Weight</label>
					
					
					<div formArrayName="health_json">
						<!-- <div class="row">
						  <div class="col-lg-4 col-sm-6 col-12" *ngIf="isedit">
							<div class="form-group">
							  <div class="secondary-btn">
								<button type="button" [disabled]="heightConverted" (click)="convertHeightToCm()">Convert Height to CM</button>
							  </div>
							</div>
						  </div>
						  <div class="col-lg-4 col-sm-6 col-12" *ngIf="isedit">
							<div class="form-group">
							  <div class="secondary-btn">
								<button type="button"[disabled]="weightConverted" (click)="convertKgToPounds()">Convert Weight to Pounds</button>
							  </div>
							</div>
						  </div>
						</div> -->
					  
						<ng-container *ngFor="let control of gethealthFormArray().controls; index as i; last as last">
						  <div class="row" [formGroupName]="i">
							<div class="col-lg-4 col-sm-6 col-12">
							  <div class="form-group">
								<label class="px-0">Date</label>
								<div class="card flex justify-content-center">
								  <p-calendar formControlName="time" [showIcon]="true" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" [minDate]="today" [maxDate]="today"></p-calendar>
								</div>
							  </div>
							</div>
					  
							<div class="col-lg-4 col-sm-6 col-12">
							  <div class="form-group">
								<label class="px-0">Height</label>
								<div class="pl-0 checkbox-list">
								  <input type="text" formControlName="height" class="form-control" maxlength="5" placeholder="Enter Height" />
								</div>
							  </div>
							</div>
					  
							<div class="col-lg-4 col-sm-6 col-12">
							  <div class="form-group">
								<label class="px-0">Weight</label>
								<div class="pl-0 checkbox-list">
								  <input type="text" formControlName="weight" class="form-control" maxlength="5" placeholder="Enter Weight" (keypress)="keyPress($event)" />
								</div>
							  </div>
							</div>
					  
							<div class="col-12 d-flex mb-3 justify-content-end">
							  <p-button type="button" icon="bx bx-plus" (click)="addVitaminObject('add', i)" iconPos="right" class="mr-2 secondary-btn"></p-button>
							  <div class="remove">
								<p-button type="button" *ngIf="i != 0" class="ml-2 secondary-btn" icon="bx bx-minus" (click)="addVitaminObject('delete', i)" iconPos="right"></p-button>
							  </div>
							</div>
						  </div>
						</ng-container>
					  </div>
					  
				</div>
			</div>
<div class="add-listings-box py-3">
    <div class="row">
        <label class="subHeading mb-3">Baby Images of Growth Monitoring </label>
        <div *ngIf="babyImgJSon">
            <div *ngFor="let control of babyImgJSon">
                <a href="{{ImageUrl + control?.name}}" target="_blank">View</a>
            </div>
        </div>


        <div formArrayName="baby_images">
            <label class="fw-500 mb-1">Upload Photo </label>

            <ng-container *ngFor="let control of getImageArray().controls; index as i; last as last">

                <div class="row" [formGroupName]="i">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <input (change)="uploadPhoto($event)" type="file" accept=".png, .jpg, .jpeg"
                                class="form-control" aria-label="Upload" aria-describedby="inputGroupFileAddon">


                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 d-flex mb-2 align-items-end">
                        <p-button type="button" icon="bx bx-plus" (click)="addBabyImg('add', i)" iconPos="right"
                            class="mr-2 secondary-btn"></p-button>
                        <div class="remove">
                            <p-button type="button" *ngIf="i!= 0" class="ml-2 secondary-btn" icon="bx bx-minus"
                                (click)="addBabyImg('delete', i)" iconPos="right"></p-button>
                        </div>
                    </div>

                </div>

            </ng-container>

        </div>

        <div class="d-flex align-items-start">
            <button class="p-button" type="button" (click)="openCamera()">
                <i class="fas fa-camera"></i>
            </button>
            <div class=" imgPreview" *ngFor="let img of capturedByCamera">

                <!-- <a (click)="previewImage(img)">Click to preview</a> -->
                <a (click)="deletecapture(img)">✖</a>
                <img [src]="img" alt="">

            </div>


        </div>
    </div>
</div>
<!-- *MOTOR AND COORDINATION SECTION -->
<div class="add-listings-box py-3">
    <h3 class="text-center">1 - 3 YEARS OLD</h3>
    <div class="d-flex col-lg-6 col-md-12 justify-content-between">
        <label class="subHeading mb-3">Motor and Coordination</label>
        <label class="subHeading2 mb-3">Remarks</label>
    </div>
    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Walks independently and seldom falls
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="walk-yes" name="walk" value="Yes" formControlName="walk"
                            (change)="handleInput($event, 'Walks')">
                        <label for="walk-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="walk-no" name="walk" value="No" formControlName="walk"
                            (change)="handleInput($event, 'Walks')">
                        <label for="walk-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="walk_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Squats to pick up the toy
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="squats-toy-yes" formControlName="squats_toy" value="Yes"
                            (change)="handleInput($event, 'squats_toy')">
                        <label for="squats-toy-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="squats-toy-no" formControlName="squats_toy" value="No"
                            (change)="handleInput($event, 'squats_toy')">
                        <label for="squats-toy-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="squats_toy_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Starts to jump with both feet leaving the ground
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="jump-yes" formControlName="jump" value="Yes"
                            (change)="handleInput($event, 'jump')">
                        <label for="jump-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="jump-no" formControlName="jump" value="No"
                            (change)="handleInput($event, 'jump')">
                        <label for="jump-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="jump_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">When walking, able to pull toys behind them
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="pull-toys-yes" formControlName="pull_toys" value="Yes"
                            (change)="handleInput($event, 'pull_toys')">
                        <label for="pull-toys-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="pull-toys-no" formControlName="pull_toys" value="No"
                            (change)="handleInput($event, 'pull_toys')">
                        <label for="pull-toys-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="pull_toys_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Runs
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="runs-yes" formControlName="runs" value="Yes"
                            (change)="handleInput($event, 'runs')">
                        <label for="runs-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="runs-no" formControlName="runs" value="No"
                            (change)="handleInput($event, 'runs')">
                        <label for="runs-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="runs_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Stands on tip toes
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="stand-on-toes-yes" formControlName="stand_on_toes" value="Yes"
                            (change)="handleInput($event, 'stand_on_toes')">
                        <label for="stand-on-toes-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="stand-on-toes-no" formControlName="stand_on_toes" value="No"
                            (change)="handleInput($event, 'stand_on_toes')">
                        <label for="stand-on-toes-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark"
                    formControlName="stand_on_toes_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Stands on low furniture
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="stands-on-furniture-yes" formControlName="stands_on_furniture"
                            value="Yes" (change)="handleInput($event, 'stands_on_furniture')">
                        <label for="stands-on-furniture-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="stands-on-furniture-no" formControlName="stands_on_furniture" value="No"
                            (change)="handleInput($event, 'stands_on_furniture')">
                        <label for="stands-on-furniture-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark"
                    formControlName="stands_on_furniture_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Kicks large balls
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="kicks-balls-yes" formControlName="kicks_balls" value="Yes"
                            (change)="handleInput($event, 'kicks_balls')">
                        <label for="kicks-balls-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="kicks-balls-no" formControlName="kicks_balls" value="No"
                            (change)="handleInput($event, 'kicks_balls')">
                        <label for="kicks-balls-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="kicks_balls_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Goes up and down stairs with support
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="stairs-yes" formControlName="stairs" value="Yes"
                            (change)="handleInput($event, 'stairs')">
                        <label for="stairs-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="stairs-no" formControlName="stairs" value="No"
                            (change)="handleInput($event, 'stairs')">
                        <label for="stairs-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="stairs_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Consistently uses 2-3 word phrases
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="word-phrases-yes" formControlName="word_phrases" value="Yes"
                            (change)="handleInput($event, 'word_phrases')">
                        <label for="word-phrases-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="word-phrases-no" formControlName="word_phrases" value="No"
                            (change)="handleInput($event, 'word_phrases')">
                        <label for="word-phrases-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="word_phrases_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Follows 2-step unrelated directions, e.g. “Give me the ball and go get
                    your
                    coat”
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="unrelated-directions-yes" formControlName="unrelated_directions"
                            value="Yes" (change)="handleInput($event, 'unrelated_directions')">
                        <label for="unrelated-directions-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="unrelated-directions-no" formControlName="unrelated_directions"
                            value="No" (change)="handleInput($event, 'unrelated_directions')">
                        <label for="unrelated-directions-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark"
                    formControlName="unrelated_directions_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Simple understanding of concepts including colour, space, and time
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="understanding-yes" formControlName="understanding" value="Yes"
                            (change)="handleInput($event, 'understanding')">
                        <label for="understanding-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="understanding-no" formControlName="understanding" value="No"
                            (change)="handleInput($event, 'understanding')">
                        <label for="understanding-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark"
                    formControlName="understanding_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Frequently moving in and out of various positions (e.g. crawling,
                    climbing, cruising, and walking) to explore and get desirable objects
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="frequent-move-yes" formControlName="frequent_move" value="Yes"
                            (change)="handleInput($event, 'frequent_move')">
                        <label for="frequent-move-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="frequent-move-no" formControlName="frequent_move" value="No"
                            (change)="handleInput($event, 'frequent_move')">
                        <label for="frequent-move-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark"
                    formControlName="frequent_move_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Has adequate endurance and strength to play with peers </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="strength-yes" formControlName="strength" value="Yes"
                            (change)="handleInput($event, 'strength')">
                        <label for="strength-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="strength-no" formControlName="strength" value="No"
                            (change)="handleInput($event, 'strength')">
                        <label for="strength-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="strength_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Walks with heel toe pattern and not primarily on toes</label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="walk-with-heel-yes" formControlName="walk_with_heel" value="Yes"
                            (change)="handleInput($event, 'walk_with_heel')">
                        <label for="walk-with-heel-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="walk-with-heel-no" formControlName="walk_with_heel" value="No"
                            (change)="handleInput($event, 'walk_with_heel')">
                        <label for="walk-with-heel-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark"
                    formControlName="walk_with_heel_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Leans on furniture, walls, or people and sits slumped over when
                    tired</label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="leans-yes" formControlName="leans" value="Yes"
                            (change)="handleInput($event, 'leans')">
                        <label for="leans-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="leans-no" formControlName="leans" value="No"
                            (change)="handleInput($event, 'leans')">
                        <label for="leans-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="leans_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Coordinates both hands to play, such as swinging a bat or opening a
                    container</label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="coordinates-yes" formControlName="coordinates" value="Yes"
                            (change)="handleInput($event, 'coordinates')">
                        <label for="coordinates-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="coordinates-no" formControlName="coordinates" value="No"
                            (change)="handleInput($event, 'coordinates')">
                        <label for="coordinates-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="coordinates_description">
            </div>
        </div>
    </div>
</div>
<!-- *SENSORY SECTION -->
<div class="add-listings-box py-3">
    <div class="d-flex col-lg-6 col-md-12 justify-content-between">
        <label class="subHeading mb-3">Sensory</label>
        <label class="subHeading2 mb-3">Remarks</label>
    </div>
    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Helps with getting dressed/undressed
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="dressed-yes" formControlName="dressed" value="Yes"
                            (change)="handleInput($event, 'dressed')">
                        <label for="dressed-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="dressed-no" formControlName="dressed" value="No"
                            (change)="handleInput($event, 'dressed')">
                        <label for="dressed-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="dressed_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Has a regular sleep schedule
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="regular-sleep-yes" formControlName="regular_sleep" value="Yes"
                            (change)="handleInput($event, 'regular_sleep')">
                        <label for="regular-sleep-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="regular-sleep-no" formControlName="regular_sleep" value="No"
                            (change)="handleInput($event, 'regular_sleep')">
                        <label for="regular-sleep-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark"
                    formControlName="regular_sleep_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Eats an increasing variety of foods
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="foods-yes" formControlName="foods" value="Yes"
                            (change)="handleInput($event, 'foods')">
                        <label for="foods-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="foods-no" formControlName="foods" value="No"
                            (change)="handleInput($event, 'foods')">
                        <label for="foods-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="foods_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Flips switches on and off
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="flips-yes" formControlName="flips" value="Yes"
                            (change)="handleInput($event, 'flips')">
                        <label for="flips-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="flips-no" formControlName="flips" value="No"
                            (change)="handleInput($event, 'flips')">
                        <label for="flips-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="flips_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Uses crayons, pens, or markers to make marks on paper
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="crayons-yes" formControlName="crayons" value="Yes"
                            (change)="handleInput($event, 'crayons')">
                        <label for="crayons-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="crayons" formControlName="crayons" value="No"
                            (change)="handleInput($event, 'crayons')">
                        <label for="crayons">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="crayons_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Enjoys a variety of movement
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="sorts-yes" formControlName="sorts" value="Yes"
                            (change)="handleInput($event, 'sorts')">
                        <label for="sorts-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="sorts" formControlName="sorts" value="No"
                            (change)="handleInput($event, 'sorts')">
                        <label for="sorts">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="sorts_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Stacks 5 or more small blocks or toys on top of each other
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="stacks-yes" formControlName="stacks" value="Yes"
                            (change)="handleInput($event, 'stacks')">
                        <label for="stacks-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="stacks" formControlName="stacks" value="No"
                            (change)="handleInput($event, 'stacks')">
                        <label for="stacks">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="stacks_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Takes toys apart and puts them back together
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="takes-toys-yes" formControlName="takes_toys" value="Yes"
                            (change)="handleInput($event, 'takes_toys')">
                        <label for="takes-toys-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="takes-toys" formControlName="takes_toys" value="No"
                            (change)="handleInput($event, 'takes_toys')">
                        <label for="takes-toys">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="takes_toys_description">
            </div>
        </div>
    </div>
</div>
<!-- *COMMUNICATION SECTION -->
<div class="add-listings-box py-3">
    <div class="d-flex col-lg-6 col-md-12 justify-content-between">
        <label class="subHeading mb-3">Communication</label>
        <label class="subHeading2 mb-3">Remarks</label>
    </div>
    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Combines sounds and gestures
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="combines-yes" formControlName="combines" value="Yes"
                            (change)="handleInput($event,'combines')">
                        <label for="combines-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="combines-no" formControlName="combines" value="No"
                            (change)="handleInput($event,'combines')">
                        <label for="combines-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="combines_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Imitates simple words and actions
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="imitates-yes" formControlName="imitates" value="Yes"
                            (change)="handleInput($event,'imitates')">
                        <label for="imitates-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="imitates-no" formControlName="imitates" value="No"
                            (change)="handleInput($event,'imitates')">
                        <label for="imitates-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="imitates_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Follows simple directions
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="follows-yes" formControlName="follows" value="Yes"
                            (change)="handleInput($event,'follows')">
                        <label for="follows-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="follows-no" formControlName="follows" value="No"
                            (change)="handleInput($event,'follows')">
                        <label for="follows-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="follows_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Identifies 1-2 body parts when named
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="identifies-yes" formControlName="identifies" value="Yes"
                            (change)="handleInput($event,'identifies')">
                        <label for="identifies-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="identifies-no" formControlName="identifies" value="No"
                            (change)="handleInput($event,'identifies')">
                        <label for="identifies-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="identifies_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Produces speech-like babbling
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="babbling-yes" formControlName="babbling" value="Yes"
                            (change)="handleInput($event,'babbling')">
                        <label for="babbling-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="babbling-no" formControlName="babbling" value="No"
                            (change)="handleInput($event,'babbling')">
                        <label for="babbling-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="babbling_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Points at familiar objects and people in pictures
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="familiar-yes" formControlName="familiar" value="Yes"
                            (change)="handleInput($event,'familiar')">
                        <label for="familiar-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="familiar-no" formControlName="familiar" value="No"
                            (change)="handleInput($event,'familiar')">
                        <label for="familiar-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="familiar_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Understands basic nouns and pronouns
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="basic-yes" formControlName="basic" value="Yes"
                            (change)="handleInput($event,'basic')">
                        <label for="basic-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="basic-no" formControlName="basic" value="No"
                            (change)="handleInput($event,'basic')">
                        <label for="basic-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="basic_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Understands “mine” and “yours”
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="understands-yes" formControlName="understands" value="Yes"
                            (change)="handleInput($event,'understands')">
                        <label for="understands-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="understands-no" formControlName="understands" value="No"
                            (change)="handleInput($event,'understands')">
                        <label for="understands-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="understands_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Asks “what” and “where” and “why” questions
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="questions-yes" formControlName="questions" value="Yes"
                            (change)="handleInput($event,'questions')">
                        <label for="questions-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="questions-no" formControlName="questions" value="No"
                            (change)="handleInput($event,'questions')">
                        <label for="questions-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="questions_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Uses plurals, e.g. “dogs”
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="plurals-yes" formControlName="plurals" value="Yes"
                            (change)="handleInput($event,'plurals')">
                        <label for="plurals-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="plurals-no" formControlName="plurals" value="No"
                            (change)="handleInput($event,'plurals')">
                        <label for="plurals-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="plurals_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Understands “in” and “on”
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="in-on-yes" formControlName="in_on" value="Yes"
                            (change)="handleInput($event,'in_on')">
                        <label for="in-on-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="in-on-no" formControlName="in_on" value="No"
                            (change)="handleInput($event,'in_on')">
                        <label for="in-on-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="in_on_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Responds to yes/no questions with head shake/nod
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="head-shake-yes" formControlName="head_shake" value="Yes"
                            (change)="handleInput($event,'head_shake')">
                        <label for="head-shake-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="head-shake-no" formControlName="head_shake" value="No"
                            (change)="handleInput($event,'head_shake')">
                        <label for="head-shake-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="head_shake_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Uses at least 50 words
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="use-words-yes" formControlName="use_words" value="Yes"
                            (change)="handleInput($event,'use_words')">
                        <label for="use-words-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="use-words-no" formControlName="use_words" value="No"
                            (change)="handleInput($event,'use_words')">
                        <label for="use-words-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="use_words_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Names objects and pictures
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="names-yes" formControlName="names" value="Yes"
                            (change)="handleInput($event,'names')">
                        <label for="names-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="names-no" formControlName="names" value="No"
                            (change)="handleInput($event,'names')">
                        <label for="names-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="names_description">
            </div>
        </div>
    </div>



    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Understands action words
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="action-yes" formControlName="action" value="Yes"
                            (change)="handleInput($event,'action')">
                        <label for="action-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="action-no" formControlName="action" value="No"
                            (change)="handleInput($event,'action')">
                        <label for="action-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="action_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Uses gestures and words in pretend play
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="gestures-yes" formControlName="gestures" value="Yes"
                            (change)="handleInput($event,'gestures')">
                        <label for="gestures-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="gestures-no" formControlName="gestures" value="No"
                            (change)="handleInput($event,'gestures')">
                        <label for="gestures-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="gestures_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Follows 2-step related direction, e.g. “Pick up your coat and bring it to
                    me”
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="direction-yes" formControlName="direction" value="Yes"
                            (change)="handleInput($event,'direction')">
                        <label for="direction-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="direction-no" formControlName="direction" value="No"
                            (change)="handleInput($event,'direction')">
                        <label for="direction-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="direction_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Enjoys listening to stories
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="stories-yes" formControlName="stories" value="Yes"
                            (change)="handleInput($event,'stories')">
                        <label for="stories-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="stories-no" formControlName="stories" value="No"
                            (change)="handleInput($event,'stories')">
                        <label for="stories-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="stories_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Consistently uses 2-3 word phrases
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="phrases-yes" formControlName="phrases" value="Yes"
                            (change)="handleInput($event,'phrases')">
                        <label for="phrases-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="phrases-no" formControlName="phrases" value="No"
                            (change)="handleInput($event,'phrases')">
                        <label for="phrases-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="phrases_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Simple understanding of concepts including colour, space, and time
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="concepts-yes" formControlName="concepts" value="Yes"
                            (change)="handleInput($event,'concepts')">
                        <label for="concepts-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="concepts-no" formControlName="concepts" value="No"
                            (change)="handleInput($event,'concepts')">
                        <label for="concepts-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="concepts_description">
            </div>
        </div>
    </div>
</div>
<!-- *FEEDING SECTION -->
<div class="add-listings-box py-3">
    <div class="d-flex col-lg-6 col-md-12 justify-content-between">
        <label class="subHeading mb-3">Feeding</label>
        <label class="subHeading2 mb-3">Remarks</label>
    </div>
    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Increases variety of coarsely chopped table foods
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="table-foods-yes" formControlName="table_foods" value="Yes"
                            (change)="handleInput($event,'table_foods')">
                        <label for="table-foods-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="table-foods-no" formControlName="table_foods" value="No"
                            (change)="handleInput($event,'table_foods')">
                        <label for="table-foods-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="table_foods_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Holds and drink from a cup
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="drink-yes" formControlName="drink" value="Yes"
                            (change)="handleInput($event,'drink')">
                        <label for="drink-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="drink-no" formControlName="drink" value="No"
                            (change)="handleInput($event,'drink')">
                        <label for="drink-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="drink_description">
            </div>
        </div>
    </div>

</div>
<!-- *PLAY AND SOCIAL SKILLS SECTION -->
<div class="add-listings-box py-3">
    <div class="d-flex col-lg-6 col-md-12 justify-content-between">
        <label class="subHeading mb-3">Play and Social Skills</label>
        <label class="subHeading2 mb-3">Remarks</label>
    </div>
    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Interested in interacting with people (plays patty cake)
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="interacting-yes" formControlName="interacting" value="Yes"
                            (change)="handleInput($event,'interacting')">
                        <label for="interacting-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="interacting-no" formControlName="interacting" value="No"
                            (change)="handleInput($event,'interacting')">
                        <label for="interacting-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="interacting_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Raises hands to be picked up
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="picked-up-yes" formControlName="picked_up" value="Yes"
                            (change)="handleInput($event,'picked_up')">
                        <label for="picked-up-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="picked-up-no" formControlName="picked_up" value="No"
                            (change)="handleInput($event,'picked_up')">
                        <label for="picked-up-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="picked_up_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Maintains eye contact with people during playful interactions
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="eye-contacts-yes" formControlName="eye_contacts" value="Yes"
                            (change)="handleInput($event,'eye_contacts')">
                        <label for="eye-contacts-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="eye-contacts-no" formControlName="eye_contacts" value="No"
                            (change)="handleInput($event,'eye_contacts')">
                        <label for="eye-contacts-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="eye_contacts_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Interested in imitating others
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="imitating-yes" formControlName="imitating_others" value="Yes"
                            (change)="handleInput($event,'imitating_others')">
                        <label for="imitating-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="imitating-no" formControlName="imitating_others" value="No"
                            (change)="handleInput($event,'imitating_others')">
                        <label for="imitating-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark"
                    formControlName="imitating_others_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Turns head in response to name being called
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="turns-head-yes" formControlName="turns_head" value="Yes"
                            (change)="handleInput($event,'turns_head')">
                        <label for="turns-head-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="turns-head-no" formControlName="turns_head" value="No"
                            (change)="handleInput($event,'turns_head')">
                        <label for="turns-head-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="turns_head_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Points to objects of interest by 1 year old
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="points-objects-yes" formControlName="points_objects" value="Yes"
                            (change)="handleInput($event,'points_objects')">
                        <label for="points-objects-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="points-objects-no" formControlName="points_objects" value="No"
                            (change)="handleInput($event,'points_objects')">
                        <label for="points-objects-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark"
                    formControlName="points_objects_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Able to locate objects you are pointing to
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="locate-objects-yes" formControlName="locate_objects" value="Yes"
                            (change)="handleInput($event,'locate_objects')">
                        <label for="locate-objects-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="locate-objects-no" formControlName="locate_objects" value="No"
                            (change)="handleInput($event,'locate_objects')">
                        <label for="locate-objects-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark"
                    formControlName="locate_objects_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Claps and together in response to social play
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="claps-yes" formControlName="claps" value="Yes"
                            (change)="handleInput($event,'claps')">
                        <label for="claps-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="claps-no" formControlName="claps" value="No"
                            (change)="handleInput($event,'claps')">
                        <label for="claps-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="claps_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Enjoys playing with a variety of toys and textures
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="enjoy-playing-yes" formControlName="enjoy_playing" value="Yes"
                            (change)="handleInput($event,'enjoy_playing')">
                        <label for="enjoy-playing-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="enjoy-playing-no" formControlName="enjoy_playing" value="No"
                            (change)="handleInput($event,'enjoy_playing')">
                        <label for="enjoy-playing-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark"
                    formControlName="enjoy_playing_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Eagerly explores the environment when placed on the floor
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="explores-yes" formControlName="explores" value="Yes"
                            (change)="handleInput($event,'explores')">
                        <label for="explores-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="explores-no" formControlName="explores" value="No"
                            (change)="handleInput($event,'explores')">
                        <label for="explores-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="explores_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Enjoys exploring and playing at the playground
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="playground-yes" formControlName="playground" value="Yes"
                            (change)="handleInput($event,'playground')">
                        <label for="playground-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="playground-no" formControlName="playground" value="No"
                            (change)="handleInput($event,'playground')">
                        <label for="playground-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="playground_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Enjoys swinging on playground swings
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="swinging-yes" formControlName="swinging" value="Yes"
                            (change)="handleInput($event,'swinging')">
                        <label for="swinging-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="swinging-no" formControlName="swinging" value="No"
                            (change)="handleInput($event,'swinging')">
                        <label for="swinging-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="swinging_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Able to participate in small groups with other children
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="participate-yes" formControlName="participate" value="Yes"
                            (change)="handleInput($event,'participate')">
                        <label for="participate-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="participate-no" formControlName="participate" value="No"
                            (change)="handleInput($event,'participate')">
                        <label for="participate-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="participate_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Plays with toys without mouthing them
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="mouthing-yes" formControlName="mouthing" value="Yes"
                            (change)="handleInput($event,'mouthing')">
                        <label for="mouthing-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="mouthing-no" formControlName="mouthing" value="No"
                            (change)="handleInput($event,'mouthing')">
                        <label for="mouthing-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="mouthing_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Enjoys sitting to look at or listen to a book
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="listen-book-yes" formControlName="listen_book" value="Yes"
                            (change)="handleInput($event,'listen_book')">
                        <label for="listen-book-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="listen-book-no" formControlName="listen_book" value="No"
                            (change)="handleInput($event,'listen_book')">
                        <label for="listen-book-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="listen_book_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Enjoys swinging, being thrown in air, and roughhousing
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="roughhousing-yes" formControlName="roughhousing" value="Yes"
                            (change)="handleInput($event,'roughhousing')">
                        <label for="roughhousing-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="roughhousing-no" formControlName="roughhousing" value="No"
                            (change)="handleInput($event,'roughhousing')">
                        <label for="roughhousing-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="roughhousing_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Able to participate in messy activities that result in dirty hands
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="messy-activity-yes" formControlName="messy_activity" value="Yes"
                            (change)="handleInput($event,'messy_activity')">
                        <label for="messy-activity-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="messy-activity-no" formControlName="messy_activity" value="No"
                            (change)="handleInput($event,'messy_activity')">
                        <label for="messy-activity-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark"
                    formControlName="messy_activity_description">
            </div>
        </div>
    </div>
</div>
<!-- *SELF-EXPRESSION SECTION -->
<div class="add-listings-box py-3">
    <div class="d-flex col-lg-6 col-md-12 justify-content-between">
        <label class="subHeading mb-3">Self-Experssion</label>
        <label class="subHeading2 mb-3">Remarks</label>
    </div>
    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Comforted by cuddling and a parent's touch
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="cuddling-yes" formControlName="cuddling" value="Yes"
                            (change)="handleInput($event,'cuddling')">
                        <label for="cuddling-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="cuddling-no" formControlName="cuddling" value="No"
                            (change)="handleInput($event,'cuddling')">
                        <label for="cuddling-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="cuddling_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Able to calm with experiences such as rocking, touch, and soothing sounds
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="calm-yes" formControlName="calm" value="Yes"
                            (change)="handleInput($event,'calm')">
                        <label for="calm-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="calm-no" formControlName="calm" value="No"
                            (change)="handleInput($event,'calm')">
                        <label for="calm-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="calm_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Grown accustomed to everyday sounds and is usually not startled by them
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="accustomed-yes" formControlName="accustomed" value="Yes"
                            (change)="handleInput($event,'accustomed')">
                        <label for="accustomed-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="accustomed-no" formControlName="accustomed" value="No"
                            (change)="handleInput($event,'accustomed')">
                        <label for="accustomed-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="accustomed_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Has an established and reliable sleeping schedule
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="sleeping-yes" formControlName="sleeping" value="Yes"
                            (change)="handleInput($event,'sleeping')">
                        <label for="sleeping-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="sleeping-no" formControlName="sleeping" value="No"
                            (change)="handleInput($event,'sleeping')">
                        <label for="sleeping-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="sleeping_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Able to enjoy a wide variety of touch, noise, and smells
                </label>
                <div class=" d-flex">
                    <p class="me-3">
                        <input type="radio" id="touch-yes" formControlName="touch" value="Yes"
                            (change)="handleInput($event,'touch')">
                        <label for="touch-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="touch-no" formControlName="touch" value="No"
                            (change)="handleInput($event,'touch')">
                        <label for="touch-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="touch_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Cries and notices when hurt
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="cries-yes" formControlName="cries" value="Yes"
                            (change)="handleInput($event,'cries')">
                        <label for="cries-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="cries-no" formControlName="cries" value="No"
                            (change)="handleInput($event,'cries')">
                        <label for="cries-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="cries_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Able to self soothe when upset
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="soothe-yes" formControlName="soothe" value="Yes"
                            (change)="handleInput($event,'soothe')">
                        <label for="soothe-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="soothe-no" formControlName="soothe" value="No"
                            (change)="handleInput($event,'soothe')">
                        <label for="soothe-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="soothe_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Able to transition to new environment or activity
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="transition-yes" formControlName="transition" value="Yes"
                            (change)="handleInput($event,'transition')">
                        <label for="transition-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="transition-no" formControlName="transition" value="No"
                            (change)="handleInput($event,'transition')">
                        <label for="transition-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="transition_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Happy when not hungry or tired
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="happy-yes" formControlName="happy" value="Yes"
                            (change)="handleInput($event,'happy')">
                        <label for="happy-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="happy-no" formControlName="happy" value="No"
                            (change)="handleInput($event,'happy')">
                        <label for="happy-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="happy_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Does not require an excessive routine to calm
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="excessive-yes" formControlName="excessive" value="Yes"
                            (change)="handleInput($event,'excessive')">
                        <label for="excessive-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="excessive-no" formControlName="excessive" value="No"
                            (change)="handleInput($event,'excessive')">
                        <label for="excessive-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="excessive_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Able to be away from parents when with supportive and familiar people
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="supportive-yes" formControlName="supportive" value="Yes"
                            (change)="handleInput($event,'supportive')">
                        <label for="supportive-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="supportive-no" formControlName="supportive" value="No"
                            (change)="handleInput($event,'supportive')">
                        <label for="supportive-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="supportive_description">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
            <div class="form-group mb-2">
                <label class="p-0">Enjoys playing near peers
                </label>
                <div class="d-flex">
                    <p class="me-3">
                        <input type="radio" id="peers-yes" formControlName="peers" value="Yes"
                            (change)="handleInput($event,'peers')">
                        <label for="peers-yes">Yes</label>
                    </p>
                    <p class="d-flex">
                        <input type="radio" id="peers-no" formControlName="peers" value="No"
                            (change)="handleInput($event,'peers')">
                        <label for="peers-no">No</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12 mt-auto">
            <div class="form-group mb-0">
                <input class="form-control" type="text" placeholder="Remark" formControlName="peers_description">
            </div>
        </div>
    </div>
</div>
</ng-container>
<div class="add-listings-btn" style="text-align: right">
    <button type="submit" [disabled]="!flag">Submit</button>
</div>
</form>

<p-dialog id="capturewin" header="Capture Photo" [(visible)]="openCameraforUpload" [modal]="true" [draggable]="false"
    [closable]="false" [resizable]="false">
    <div>
        <button type="button" class="close-button" (click)="shutDownCamera()" aria-label="Close"></button>
        <video #video autoplay="true" playsinline="true"></video>
        <canvas #canvas style="display:none;"></canvas>
    </div>
    <div>
        <a class="captureButton" (click)="capture()"><i class="fas fa-camera"></i>
        </a>
    </div>
</p-dialog>


<!-- <p-dialog header="captured Photo" [(visible)]="imagePreview"
[modal]="true"
[draggable]="false" [closable]="false" [resizable]="false">
<div>
    <button type="button" class="close-button" (click)="closePreview()" aria-label="Close"></button>

    <img [src]="imgSrcForPreview" alt="">
</div>
<div style="cursor: pointer;">
    <a (click)="deletecapture(imgSrcForPreview)">Delete</a>
</div>

</p-dialog> -->
<app-copyrights></app-copyrights>
</div>