<p-toast></p-toast>

<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">
    <app-dashboard-navbar></app-dashboard-navbar>
    <!-- profile section -->
    <div class="dropdown profile-nav-item d-flex flex-column align-items-end">
        <a class="dropdown-toggle admin-btn" style="text-decoration: none;" role="button" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <div class="menu-profile">
                <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
                <span class="name">{{getname}}</span>
            </div>
        </a>
        <div class="dropdown-menu d-flex flex-column align-items-center">
            <div class="dropdown-header align">
                <div class="figure mb-3">
                    <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
                </div>
                <div class="info text-center">
                    <span class="name" style="white-space: break-spaces;">{{getname}}</span>
                    <p class="mb-3 email">{{getRole}}</p>
                </div>
            </div>
            <!-- <div class="dropdown-body">
            <ul class="profile-nav p-0 pt-3">
                <li class="nav-item"><a routerLink="/dashboard-my-profile" class="nav-link"><i class='bx bx-user'></i> <span>Profile Dashboard</span></a></li>
            </ul>
        </div> -->
            <div class="dropdown-footer">
                <ul class="profile-nav">
                    <li class="nav-item"><a class="nav-link" (click)="logout()"><i class='bx bx-log-out'></i> <span
                                class="logout-text">Logout</span></a></li>
                </ul>
            </div>

        </div>
    </div>
    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
        <h1 style="text-transform: capitalize;">{{ Content.title }}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item">
                {{ Content.subTitle }}
            </li>
            <li class="item" style="text-transform: capitalize;">{{ Content.title }}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

    <!-- Start Form -->
    <!-- Baby Child Registration listing SECTION -->

    <p-accordion>
        <p-accordionTab header="Baby Details" class="accordianStyle">
            <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group viewSummary">
                        <label>Name :</label>

                        <div type="text" readonly>
                            {{ summaryData && summaryData &&  summaryData && summaryData[0]?.name ?? '-' | titlecase }}
                        </div>

                    </div>
                </div>
                <div class="col-xl-4 col-lg-12
col-md-12">
                    <div class="form-group viewSummary">
                        <label>Gender :</label>
                        <!-- <input type="text" readonly class="form-control"
                        value="{{ summaryData && summaryData[0]?.gender ?? '-'}}" /> -->
                        <div type="text" readonly>{{ summaryData && summaryData[0]?.gender ?? '-' |
                            titlecase }}</div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group viewSummary">
                        <label>Date of Birth :</label>
                        <!-- <input type="text" readonly class="form-control"
                        value="{{ summaryData && summaryData[0]?.date_of_birth ?? '-'}}" /> -->
                        <div type="text" readonly>{{ summaryData && summaryData[0]?.date_of_birth ?? '-' |
                            titlecase }}</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group viewSummary">
                    <label>Days :</label>
                    <div type="text" readonly>{{ days }}</div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group viewSummary">
                    <label>Months :</label>
                    <div type="text" readonly>{{ months }}</div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group viewSummary">
                    <label>Years :</label>
                    <div type="text" readonly>{{ years }}</div>
                  </div>
                </div>
              </div>
        </p-accordionTab>

        <p-accordionTab header="Milk Feeding" *ngIf="babyAge !== 'three-years-above'" class="accordianStyle">
            <div class=" viewSummary row col">
                <div class="col-md-4 form-group">
                    <label for="">Feeding Prepared By:</label>
                    <strong>
                        {{FirstEntry?.feeding_prepared_mother == 1? ' Mother,': '' }}
                        {{FirstEntry?.feeding_prepared_nanny == 1? ' Nurse,': '' }}
                        {{FirstEntry?.feeding_prepared_other == 1? ' Other': '' }}
                    </strong>
                </div>
                <div class="col-md-8 form-group"> <label>Breast Feeding:</label>
                    <strong>{{FirstEntry?.feeding_frequency_breast }} Times</strong>
                </div>

                <div class="col-md-4  form-group">
                    <label for="">Time of feeding is: </label>
                </div>
                <div class="col-md-8  ">
                    <strong>
                        <div class="row">
                            <div class="col-md-3 form-group"
                                *ngFor="let data of FirstEntry?.breast_feeding let index = index">

                                <div class="input-group2 ">
                                    <span class="input-group-text "> {{ index+1 }}. </span>
                                    <div class="formbox">{{ formatDateTime(data?.feeding_breast_time)}}</div>
                                </div>
                            </div>
                        </div>
                    </strong>
                </div>
            </div>
            <div class="viewSummary row col">
                <div class="col-md-4 form-group">
                    <label for="">Milk Feeding:</label> <strong> Every {{ FirstEntry?.feeding_frequency?.frequency}}
                        Hours </strong>
                </div>
                <div class="col-md-4 form-group">
                    <label for="">Amount:</label> <strong>{{ FirstEntry?.feeding_amount_per}}</strong>
                </div>
                <div class="col-md-4 form-group">
                    <label for="">Formula Name:</label> <strong>{{ FirstEntry?.type_of_formula}}</strong>
                </div>
                <div class="col-md-4 form-group">
                    <label for="">Start Time:</label> <strong>{{ formatDateTime(FirstEntry?.feeding_time)}}</strong>
                </div>
                <div class="col-md-4 form-group">
                    <label for="">End Time:</label> <strong>{{
                        formatDateTime(FirstEntry?.feeding_breast_time_end|| FirstEntry?.feeding_formula_time_end)}}</strong>
                </div>
            </div>
            <div class="viewSummary row col">
                <div class="col-md-4 form-group">
                    <label for="">Bottle Sterilization All: </label>
                    <strong>
                        {{ FirstEntry?.bottle_sterilization_all|| FirstEntry?.
                            all_time? "Yes" : "No" }}
                    </strong>
                </div>
                <div class="col-md-8 form-group">
                    <label for="">Time:</label>
                    <strong>{{ formatDateTime(FirstEntry?.bottle_sterilization_all_time ||FirstEntry?. bottle_sterilization_all) }}</strong>
                </div>
                
                <div class="col-md-4 form-group">
                    <label for=""> Bottle Sterilization(One at a time): </label>
                    <strong>
                        {{ FirstEntry?.bottle_sterilization_onetime ? "Yes" : "No" }}
                    </strong>
                </div>
                <div class="col-md-8 mt-3">
                    <strong>
                        <div class="row">
                            <div class="col-md-3"
                                *ngFor="let data of FirstEntry?.bottle_sterilization let index = index">

                                <div class="input-group2 mb-3">
                                    <span class="input-group-text"> {{ index+1 }}. </span>
                                    <div class="formbox">{{ formatDateTime(data?.sterilization_timeArray)}}</div>
                                </div>
                            </div>
                        </div>
                    </strong>
                </div>
            </div>
            <div class="col">
                <!-- based on date  -->
                <div class="col">
                    <div class="add-listings-box py-0 my-3">
                        <h3 class="mb-0 heading-calender dateTable">Milk Feeding of the day (Select date for data)
                            <p-calendar placeholder="dd/mm/yyyy " [showTime]="false" dateFormat="dd/mm/yy"
                                (ngModelChange)="getDataByDate($event)" [(ngModel)]="calendar14"></p-calendar>
                        </h3>

                        <div class="add-listings-box py-0 my-3" *ngIf="calendar14">
                            <h3 class="mb-0 heading-calender dateTable">Breast Feeding (Subcategory)

                            </h3>
                            <ng-container *ngIf="!error; else elseBlock">
                                <div class="dataBydate">

                                <table class="table font-size-13">
                                    <thead>
                                        <tr>
                                            <th>Reference Time</th>
                                            <th>Actual Time</th>
                                            <th>Is it Done?</th>
                                            <th>Remark</th>
                                            <th>Assign Nurse</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of newData?.breast_feeding">
                                            <td>{{ formatDateTime(data?.feeding_breast_time) }}</td>
                                            <td>{{ formatDateTime(data?.feeding_breast_actual_time) }}</td>
                                            <td>{{data?.feeding_breast_checkbox == '1'? ' Yes' : 'No'}}</td>
                                            <td>{{data?.breast_feeding_remark }}</td>
                                            <td>{{data?.nurses }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </ng-container>
                            <ng-template #elseBlock>
                                <div *ngIf="calendar14">
                                    No data available for the selected date.
                                </div>
                            </ng-template>
                        </div>

                        <div class="add-listings-box py-0 my-3" *ngIf="calendar14">
                            <h3 class="mb-0 heading-calender dateTable">Formula Feeding (Subcategory)

                            </h3>
                            <ng-container *ngIf="!error; else elseBlock">
                                <div class="dataBydate">

                                <table class="table  font-size-13">
                                    <thead>
                                        <tr>
                                            <th>Reference Time</th>
                                            <th>Actual Time</th>
                                            <th>Amount</th>
                                            <th>Is it Done?</th>
                                            <th>Remarks</th>
                                            <th>Assign Nurse</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of newData?.formula_calculation">
                                            <td>{{ formatDateTime(data?.formula_timeArray) }}</td>
                                            <td>{{ formatDateTime(data?.nurse_feeding) }}</td>
                                            <td>{{data?.amount? data?.amount : ' '}}</td>
                                            <td>{{ data?.feeding_checkbox == '1' ? 'Yes' : 'No' }}</td>
                                            <td>{{ data?.remark? data?.remark : data?.formula_remark }}</td>
                                            <td>{{data?.nurses}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </ng-container>
                            <ng-template #elseBlock>
                                <div *ngIf="calendar14">
                                    No data available for the selected date.
                                </div>
                            </ng-template>
                        </div>


                        <!-- bottle sterilization start -->
                        <div class="col" *ngIf="calendar14">
                            <!-- based on date  -->
                            <div class="add-listings-box py-0 my-3">
                                <h3 class="mb-0 heading-calender dateTable">Bottle Sterilization All Time (Subcategory)
                                </h3>
                                <ng-container *ngIf="!error; else elseBlock">
                                    <div class="dataBydate">

                                    <table class="table  font-size-13
">
                                        <thead>
                                            <tr>
                                                <th>Reference Time</th>
                                                <th>Actual Time</th>
                                                <th>Is it Done?</th>
                                                <th>Remarks</th>
                                                <th>Assign Nurse</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr >
                                                <td>{{ formatDateTime(newData?.bottle_sterilization_all_time) }}</td>
                                                <td>{{ formatDateTime(newData?.actual_sterilization_all_time)}}</td>
                                                <td>{{ newData?.done_sterilization_onetime == '1'? 'Yes' : 'No' }}</td>
                                                <td>{{ newData?.onetime_Remark }}</td>
                                                <td>{{newData?.nurses}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </ng-container>
                                <ng-template #elseBlock>
                                    <div *ngIf="calendar14">
                                        No data available for the selected date.
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="col" *ngIf="calendar14">
                            <!-- based on date  -->
                            <div class="add-listings-box py-0 my-3">
                                <h3 class="mb-0 heading-calender dateTable">Bottle Sterilization (Subcategory)
                                </h3>
                                <ng-container *ngIf="!error; else elseBlock">
                                    <div class="dataBydate">

                                    <table class="table  font-size-13
">
                                        <thead>
                                            <tr>
                                                <th>Reference Time</th>
                                                <th>Actual Time</th>
                                                <th>Is it Done?</th>
                                                <th>Remarks</th>
                                                <th>Assign Nurse</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let data of newData?.bottle_sterilization">
                                                <td>{{ formatDateTime(data?.sterilization_timeArray) }}</td>
                                                <td>{{ formatDateTime(data?.actual_sterilization_timeArray)}}</td>
                                                <td>{{ data?.done_sterilization == '1'? 'Yes' : 'No' }}</td>
                                                <td>{{ data?.sterilization_remark }}</td>
                                                <td>{{data?.nurses}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </ng-container>
                                <ng-template #elseBlock>
                                    <div *ngIf="calendar14">
                                        No data available for the selected date.
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- bottle sterilization end  -->
            </div>





        </p-accordionTab>

        <p-accordionTab header="Feeding Assesment"
            *ngIf="babyAge !== 'three-years-above' && babyAge !== 'one-year-to-three-year-plan'" class="accordianStyle">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="form-group viewSummary">
                        <label for="">Baby is not interested when offered to breastfeed,
                            sleepy:</label>
                        <div type="text">{{FirstEntry?.answer ?? '-'}}</div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="form-group viewSummary">
                        <label for="">Baby is showing feeding cues but not
                            attaching:</label>
                        <div type="text">{{FirstEntry?.attach ?? '-'}}</div>
                    </div>
                </div>
            </div>
            <div class="row">

                <div class="col-lg-12 col-md-12">
                    <div class="form-group viewSummary">
                        <label for="">Attaches at the breast but quickly falls
                            asleep:</label>

                        <div type="text">{{FirstEntry?.Asleep ?? '-'}}</div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="form-group viewSummary">
                        <label for="">Attaches for short bursts with long pause:</label>
                        <div type="text">{{FirstEntry?.LongPause ?? '-'}}</div>
                    </div>
                </div>
            </div>
            <div class="row">

                <div class="col-lg-12 col-md-12">
                    <div class="form-group viewSummary">
                        <label for="">Attaches well with long rhythmical sucking and
                            swallowing
                            for a short feed:</label>
                        <div type="text">{{FirstEntry?.rhythmical ?? '-'}}</div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="form-group viewSummary">
                        <label for="">Attaches well for a sustained period with long
                            rhythmical
                            sucking and swallowing:</label>
                        <div type="text">{{FirstEntry?.rhythmical ?? '-'}}</div>
                    </div>
                </div>
            </div>
            <div class="row">

                <div class="col-lg-12 col-md-12">
                    <div class="form-group viewSummary">
                        <label for="">Normal skin color and tone:</label>
                        <div type="text">{{FirstEntry?.Normal_skin ?? '-'}}</div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="form-group viewSummary">
                        <label for="">Gaining weight appropriately:</label>

                        <div type="text">{{FirstEntry?.weight ?? '-'}}</div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group viewSummary">
                        <label for="">Other Concerns:</label>
                        <div type="text">{{FirstEntry?.Other_concerns ?? '-'}}</div>
                    </div>
                </div>
            </div>
        </p-accordionTab>

        <p-accordionTab header="Solid Food Feeding" class="accordianStyle" *ngIf="babyAge != 'zero-to-three-months'">

            <div class="row">
                <div class="col">
                    <div class="viewSummary">
                        <div class="col-md-4 form-group" *ngIf="FirstEntry?.solid_other_snack == '1'">
                            <label for="">Snack Food:</label>
                            <strong>
                                {{ FirstEntry?.soild_other_snack_food_components}}
                            </strong>
                        </div>
                        <div class="col-md-8 form-group" *ngIf="FirstEntry?.solid_other_snack == '1'">
                            <label for="">Time :</label> <strong>{{ formatDateTime(FirstEntry?.solid_other_snack_time
                                )}}</strong>
                        </div>
                        <div class="col-md-4 form-group" *ngIf="FirstEntry?.solid_food">
                            <label for="">Breakfast:</label>
                            <strong *ngFor="let breakfast of  FirstEntry?.solid_food let i = index">
                                {{i+1}}.{{ breakfast?.regular_meal_food_components}} at {{
                                formatDateTime(breakfast?.regular_meal_time )}}<br>
                            </strong>
                        </div>
                        <div class="col-md-4 form-group" *ngIf="FirstEntry?.lunch_food">
                            <label for="">Lunch:</label>
                            <strong *ngFor="let lunch of  FirstEntry?.lunch_food let i = index">
                                {{i+1}}.{{ lunch?.regular_meal_lunch_food_components}} at {{
                                formatDateTime(lunch?.regular_meal_lunch_time )}}<br>
                            </strong>
                        </div>

                        <div class="col-md-4 form-group" *ngIf="FirstEntry?.regular_food">
                            <label for="">Snacks:</label>
                            <strong>
                                1.{{ FirstEntry?.soild_other_snack_food_components}} at {{
                            formatDateTime(FirstEntry?.solid_other_snack_time)}}<br>
                            </strong>
                        </div>
                        <div class="col-md-4 form-group" *ngIf="FirstEntry?.dinner_food">
                            <label for=""> Dinner:</label>
                            <strong *ngFor="let dinner of  FirstEntry?.dinner_food let i = index">
                                {{i+1}}.{{ dinner?.regular_meal_dinner_food_components}} at {{
                                formatDateTime(dinner?.regular_meal_dinner_time )}}<br>
                            </strong>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <!-- based on date  -->
                <div class="add-listings-box py-0 ">
                    <h3 class="mb-0 heading-calender dateTable">Solid Food (Select Date for Data)
                        <p-calendar placeholder="dd/mm/yyyy " dateFormat="dd/mm/yy" [showTime]="false"
                            (ngModelChange)="getDataByDate($event)" [(ngModel)]="calendar11"></p-calendar>
                    </h3>
                    <ng-container *ngIf="calendar11 && !error; else elseBlock">
                        <div class="dataBydate">

                        <table class="table  font-size-13
">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Reference Time</th>
                                    <th> Actual Time</th>

                                    <th>Is it Done?</th>
                                    <th>Remarks</th>
                                    <th>Assign Nurse</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr
                                    *ngFor="let breakfast of newData?.solid_food ? newData?.solid_food :newData?.regular_food  ">
                                    <td>Breakfast</td>
                                    <td>{{ formatDateTime(breakfast?.regular_meal_time) }}</td>
                                    <td>{{ formatDateTime(breakfast?.regular_meal_actual_time) }}</td>
                                    <td>{{ breakfast.regular_meal_type == '1' ? 'Yes' : 'No' }}</td>
                                    <td>{{ breakfast?.regular_meal_food_components }}</td>
                                    <td>{{breakfast?.nurses}}</td>
                                </tr>
                                <tr *ngFor="let lunch of newData?.lunch_food">
                                    <td>Lunch</td>
                                    <td>{{ formatDateTime(lunch?.regular_meal_lunch_time) }}</td>
                                    <td> {{formatDateTime(lunch?.regular_meal_lunch_actual_time)}}</td>
                                    <td>{{ lunch.regular_meal_lunch == '1' ? 'Yes' : 'No' }}</td>
                                    <td>{{ lunch?.regular_meal_lunch_food_components }}</td>
                                    <td>{{lunch?.nurses}}</td>
                                </tr>
                                <tr *ngIf="newData?.regular_food">
                                    <td>Snacks</td>
                                    <td>{{ formatDateTime(newData?.solid_other_snack_time) }}</td>
                                    <td>{{ formatDateTime(newData?.solid_other_snack_actual_time) }}</td>
                                    <td>{{ newData?.solid_other_snack == '1' ? 'Yes' : 'No' }}</td>
                                    <td>{{ newData?.soild_other_snack_food_components }}</td>
                                    <td>{{newData?.nurses}}</td>
                                </tr>
                                <tr *ngFor="let dinner of newData?.dinner_food">
                                    <td>Dinner</td>
                                    <td>{{ formatDateTime(dinner?.regular_meal_dinner_time) }}</td>
                                    <td>{{ formatDateTime(dinner?.regular_meal_dinner_actual_time ) }}</td>
                                    <td>{{ dinner.regular_meal_dinner == '1' ? 'Yes' : 'No' }}</td>
                                    <td>{{ dinner?.regular_meal_dinner_food_components }}</td>
                                    <td>{{dinner?.nurses}}</td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </ng-container>
                    <ng-template #elseBlock>
                        <div *ngIf="calendar11">
                            No data available for the selected date.
                        </div>
                    </ng-template>
                </div>
            </div>

        </p-accordionTab>

        <p-accordionTab header="Allergy" class="accordianStyle"
            [class.allergyhighlight]="FirstEntry?.food_medicine_allergy == '1'">
            <div class="row">
                <div class="col">
                    <div class="viewSummary">
                        <div class="col-md-4 mt-3">
                            <label for="">Baby has Allergy ? </label>
                        </div>
                        <div class="col-md-8 mt-3">
                            <strong>
                                <div class="row">
                                    <div class="col-md-4 mt-3">

                                        <div class="input-group2 mb-3">
                                            <div class="formbox">{{FirstEntry?.food_medicine_allergy == '1' ? 'Yes':
                                                'No' }}</div>
                                        </div>
                                    </div>
                                </div>
                            </strong>
                        </div>

                        <div class="col-md-4 mt-3">
                            <label for=""> Special Instruction for Allergy
                            </label>
                        </div>

                        <div class="col-md-8 mt-3">
                            <strong>
                                <div class="row">
                                    <div class="col-md-4 mt-3">

                                        <div class="input-group2 mb-3">
                                            <div class="formbox">{{FirstEntry?.food_medicine_allergy_specify_prod}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </strong>
                        </div>
                    </div>
                </div>
            </div>
        </p-accordionTab>
        

        <p-accordionTab header="Diaper change" *ngIf="babyAge !== 'three-years-above'" class="accordianStyle">
            <div class="row">
                <div class="col">
                    <div class="viewSummary">


                        <div class="col-md-8">
                            <strong>
                                <div class="col-md-4  form-group" *ngIf="FirstEntry?.diaper_change_times">
                                    <label for="">Additional Diaper change in a Day : </label>
                                </div>
                                <div class="row"
                                    *ngFor="let data of FirstEntry?.diaper_change_times; let index = index">
                                    <div class="col-md-2 form-group">
                                        <label for="">Time:</label>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="input-group2 mb-3" [class.single-entry]="index === 0">
                                            <div class="formbox">{{ formatDateTime(data?.time) }}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 form-group">
                                        <label for="">Remark:</label>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="input-group2 mb-3" [class.single-entry]="index === 0">
                                            <div class="remark">{{ data?.diaper_remark }}</div>
                                        </div>
                                    </div>
                                </div>
                            </strong>
                        </div>
                        <div class="col-md-8 ">
                            <strong>
                                <div class="col-md-6  form-group" *ngIf="FirstEntry?.diaper_change_times">
                                    <label for="">Diaper change Times According to NCP : </label>
                                </div>
                                <div class="row">

                                    <div class="col-md-3"
                                        *ngFor="let data of FirstEntry?.diaper_changes let index = index">

                                        <div class="input-group2 mb-3">
                                            <span class="input-group-text"> {{ index+1 }}. </span>
                                            <div class="formbox">{{ formatDateTime(data?.diaper_label)}}</div>
                                        </div>
                                    </div>
                                </div>
                            </strong>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="add-listings-box py-0 ">
                    <h3 class="mb-0 heading-calender">Diaper Change Times (Select date for Specific data of the day)
                        <p-calendar placeholder="dd/mm/yyyy " dateFormat="dd/mm/yy" [showTime]="false"
                            (ngModelChange)="getDataByDate($event)" [(ngModel)]="calendar10"></p-calendar>
                    </h3>

                    <div class="add-listings-box py-0 my-3" *ngIf="calendar10">
                        <h3 class="mb-0 heading-calender dateTable"> Additional Diaper change in a Day

                        </h3>
                        <ng-container *ngIf="calendar10 && !error; else elseBlock">
                            <div class="dataBydate">

                            <table class="table  font-size-13
    ">
                                <thead>
                                    <tr *ngIf="newData?.diaper_change_times && newData?.diaper_change_times.length > 0">
                                        <th>Time</th>
                                        <th>Is it Done?</th>
                                        <th>Remark</th>
                                        <th>Assign Nurse</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of newData?.diaper_change_times; let index = index">
                                        <td>{{ formatDateTime(data?.time) }}</td>
                                        <td>{{ data?.change == '1' ? 'Yes' : 'No' }}</td>
                                        <td>{{ data?.diaper_remark }}</td>
                                        <td>{{data?.nurses}}</td>

                                    </tr>

                                </tbody>
                            </table>
                            </div>
                        </ng-container>
                        <ng-template #elseBlock>
                            <div *ngIf="calendar10">
                                No data available for the selected date.
                            </div>
                        </ng-template>
                    </div>

                    <div class="add-listings-box py-0 my-3" *ngIf="calendar10">
                        <h3 class="mb-0 heading-calender dateTable"> Diaper change in a Day

                        </h3>
                        <ng-container *ngIf="calendar10 && !error; else elseBlock">
                            <div class="dataBydate">

                                <div class="add-listings-box py-0 my-3" >
                                    <h3 class="mb-0 heading-calender dateTable">Diaper Changes
                        
                                    </h3>
                                    <ng-container *ngIf="!error; else elseBlock">
                                        <div class="dataBydate">
                        
                                        <table class="table  font-size-13">
                                            <thead>
                                                <tr>
                                                    <th>Reference Time</th>
                                                    <th>Actual Time</th>
                                                    <!-- <th>Amount</th> -->
                                                    <th>Is it Done?</th>
                                                    <th>Remarks</th>
                                                    <th>Assign Nurse</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of newData?.diaper_calculation">
                                                    <td>{{ formatDateTime(data?.diaper_timeArray) }}</td>
                                                    <td>{{ formatDateTime(data?.diaper_actual_time) }}</td>
                                                    <!-- <td>{{data?.amount? data?.amount : ' '}}</td> -->
                                                    <td>{{ data?.diaper_checkbox == '1' ? 'Yes' : 'No' }}</td>
                                                    <td>{{ data?.diaperRemark? data?.diaperRemark : data?.diaperRemark }}</td>
                                                    <td>{{data?.nurses}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </ng-container>
                                    <ng-template #elseBlock>
                                        <div *ngIf="calendar14">
                                            No data available for the selected date.
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <ng-template #elseBlock>
                        <div *ngIf="calendar10">
                            No data available for the selected date.
                        </div>
                    </ng-template>


                </div>
            </div>


        </p-accordionTab>


        <p-accordionTab header="Bathing" class="accordianStyle">
            <div class="row">
                <div class="col">
                    <div class="viewSummary">

                        <div class="col-md-4  col-12" *ngIf="FirstEntry?.bathing_shower">
                            <label for=""> Shower:</label>
                        </div>
                        <div class="col-md-8 " *ngIf="FirstEntry?.bathing_shower">
                            <strong>
                                <div class="row">
                                    <div class="col-md-3"
                                        *ngFor="let data of FirstEntry?.shower_bathing let index = index">

                                        <div class="input-group2 mb-1">
                                            <span class="input-group-text"> {{ index+1 }}. </span>
                                            <div class="formbox">{{ formatDateTime(data?.bathing_shower_time)}}</div>
                                        </div>
                                    </div>
                                </div>
                            </strong>
                        </div>



                        <div class="col-md-4 col-12" *ngIf="FirstEntry?.tub_bathing">
                            <label for=""> Tub:</label>
                        </div>
                        <div class="col-md-8 " *ngIf="FirstEntry?.tub_bathing">
                            <strong>
                                <div class="row">
                                    <div class="col-md-3"
                                        *ngFor="let data of FirstEntry?.tub_bathing  let index = index">

                                        <div class="input-group2 mb-1">
                                            <span class="input-group-text"> {{ index+1 }}. </span>
                                            <div class="formbox">{{ formatDateTime(data?.bathing_tub_time )}}</div>
                                        </div>
                                    </div>
                                </div>
                            </strong>
                        </div>

                        <div class="col-md-4 col-12" *ngIf="FirstEntry?.bathing_time">
                            <label for=""> Sponge:</label>
                        </div>
                        <div class="col-md-8 " *ngIf="FirstEntry?.bathing_time">
                            <strong>
                                <div class="row">
                                    <div class="col-md-3"
                                        *ngFor="let data of FirstEntry?.bathing_time let index = index">

                                        <div class="input-group2 mb-1">
                                            <span class="input-group-text"> {{ index+1 }}. </span>
                                            <div class="formbox">{{ formatDateTime(data?.bathing_sponge_time)}}</div>
                                        </div>
                                    </div>
                                </div>
                            </strong>
                        </div>
                    </div>
                </div>
            </div>
            <!-- based on date  -->

            <div class="col">
                <div class="add-listings-box py-0 my-3">
                    <h3 class="mb-0 heading-calender">Bathing (Select date for data)
                        <p-calendar placeholder="dd/mm/yyyy " dateFormat="dd/mm/yy" [showTime]="false"
                            (ngModelChange)="getDataByDate($event)" [(ngModel)]="calendar9"></p-calendar>
                    </h3>
                    <ng-container *ngIf="calendar9 && !error; else elseBlock">
                        <div class="dataBydate">

                        <table class="table  font-size-13
">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Reference Time</th>
                                    <th>Actual Type</th>
                                    <th>Is it Done?</th>
                                    <th>Remarks</th>
                                    <th>Assign Nurse</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of newData?.shower_bathing">
                                    <td>Shower</td>
                                    <td>{{ formatDateTime(data?.bathing_shower_time) }}</td>
                                    <td>{{ formatDateTime(data?.bathing_shower_actual_time) }}</td>
                                    <td>{{ data?.shower_checkbox == '1' ? 'Yes' : 'No' }}</td>
                                    <td>{{ data?.shower_remark}}</td>
                                    <td>{{data?.nurses}}</td>
                                </tr>
                                <tr *ngFor="let data of newData?.tub_bathing">
                                    <td>Tub</td>
                                    <td>{{ formatDateTime(data?.bathing_tub_time) }}</td>
                                    <td>{{ formatDateTime(data?.bathing_tub_actual_time) }}</td>
                                    <td>{{ data?.tub_checkbox == '1' ? 'Yes' : 'No' }}</td>
                                    <td>{{ data?.tubBathing_remark}}</td>
                                    <td>{{data?.nurses}}</td>
                                </tr>
                                <tr *ngFor="let data of newData?.bathing_time">
                                    <td>Sponge</td>
                                    <td>{{ formatDateTime(data?.bathing_sponge_time) }}</td>
                                    <td>{{ formatDateTime(data?.bathing_sponge_actual_time) }}</td>
                                    <td>{{ data?.sponge_checkbox == '1' ? 'Yes' : 'No' }}</td>
                                    <td>{{ data?.spongeBathing_remark}}</td>
                                    <td>{{data?.nurses}}</td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </ng-container>
                    <ng-template #elseBlock>
                        <div *ngIf="calendar9">
                            No data available for the selected date.
                        </div>
                    </ng-template>
                </div>
            </div>

        </p-accordionTab>

        <p-accordionTab header="Potty Training" class="accordianStyle"
            *ngIf="babyAge !== 'zero-to-three-months' && babyAge !== 'four-months-to-one-year-plan'">
            <div class="col">
                <div class="viewSummary row col" *ngFor="let data of FirstEntry?.potty_training?FirstEntry?.potty_training :FirstEntry?.child_training
                let index = index">

                    <div class="col-md-4 form-group">
                        <label for="">Training:</label> <strong>{{data?.training_child}}</strong>

                    </div>
                    <div class="col-md-8 form-group">
                        <label for="">Time:</label><strong>{{formatDateTime(data?.time_training_child)}}</strong>
                    </div>


                </div>



            </div>
            <div class="col">
                <div class="add-listings-box py-0 ">
                    <h3 class="mb-0 heading-calender">Training (Select date for data)
                        <p-calendar placeholder="dd/mm/yyyy " dateFormat="dd/mm/yy" [showTime]="false"
                            (ngModelChange)="getDataByDate($event)" [(ngModel)]="calendar7"></p-calendar>
                    </h3>
                    <ng-container *ngIf="calendar7 && !error; else elseBlock">
                        <div class="dataBydate">

                        <table class="table  font-size-13
">
                            <thead>
                                <tr>
                                    <th>Training</th>
                                    <th>Reference Time</th>
                                    <th>Actual Time</th>
                                    <th>Remark</th>
                                    <th>Is it Done?</th>
                                    <th>Assign Nurse</th>
                                    <!-- <th>Remarks</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let data of newData?.potty_training ? newData?.potty_training :newData?.child_training">
                                    <td>{{ data?.training_child }}</td>
                                    <td>{{formatDateTime(data?.time_training_child)}}</td>
                                    <td>{{formatDateTime(data?.time_training_actual_child)}}</td>
                                    <td>{{ data?.training_child_remark}}</td>
                                    <td>{{data?.done_training_child == '1' ? 'Yes': 'No'}}</td>
                                    <td>{{data?.nurses}}</td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </ng-container>
                    <ng-template #elseBlock>
                        <div *ngIf="calendar7">
                            No data available for the selected date.
                        </div>
                    </ng-template>
                </div>
            </div>

        </p-accordionTab>

        <p-accordionTab header="Skin Care" class="accordianStyle">
            <div class="col">
                <div class="viewSummary">
                    <div class="col-md-4 ">
                        <ng-container *ngFor="let data of FirstEntry?.skin_care">
                            <br>
                            <ng-container *ngIf="data.skin_care_baby_oil == '1'">
                                <label for="">Baby Oil,</label>
                            </ng-container>
                            <ng-container *ngIf="data.skin_care_lotion == '1'">

                                <label for="">Lotion,</label>
                            </ng-container>
                            <ng-container *ngIf="data.skin_care_others == '1'">
                                <label for="">other,</label>
                            </ng-container>

                            <label for="">Product :</label> <strong>{{ data.skin_care_specify_produ }}</strong>
                            <br>
                        </ng-container>

                        <ng-container *ngIf="FirstEntry?.skin_care_Wipes=='1'">
                            <br>
                            <label for="">Special Instruction for wipes :</label>
                            <strong>{{FirstEntry?.skin_care_special_instructions }}</strong>
                            <br>
                        </ng-container>

                        <ng-container *ngFor="let data of FirstEntry?.changing_colthes_frequency">
                            <br>
                            <label for="">Changing Clothes in a Day :</label> N-A
                            <br>
                        </ng-container>


                        <ng-container *ngIf="FirstEntry?.changing_colthes_spiecal_instructions">
                            <br>
                            <label for="">Instruction for Changing Clothes :</label> <strong>
                                {{FirstEntry?.changing_colthes_spiecal_instructions}}</strong>
                            <br>
                        </ng-container>
                    </div>

                </div>
            </div>


            <div class="col">
                <div class="add-listings-box py-0 my-3">
                    <h3 class="mb-0 heading-calender">Skin care of the day (Select date for data)
                        <p-calendar placeholder="dd/mm/yyyy " dateFormat="dd/mm/yy" [showTime]="false"
                            (ngModelChange)="getDataByDate($event)" [(ngModel)]="calendar6"></p-calendar>
                    </h3>
                    <ng-container *ngIf="calendar6 && !error; else elseBlock">
                        <!-- <div class="add-listings-box py-0 my-3">
                            <h3 class="mb-0 heading-calender">Skin care of the day </h3>
                            <table class="table  font-size-13
" *ngIf="newData?.skin_care">
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Is it Done?</th>
                                        <th>Product</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of newData?.skin_care">
                                        <td>{{ data?.skin_care_baby_oil == '1' ? 'Oil' : '' }}
                                            {{ data?.skin_care_lotion == '1' ? 'Lotion' : '' }}
                                            {{ data?.skin_care_others == '1' ? ',other' : '' }}
                                        </td>
                                        <td>{{ data?.skin_care_baby_oil == '1' ? 'Yes' : '' }}
                                            {{ data?.skin_care_lotion == '1' ? 'Yes' : '' }}
                                            {{ data?.skin_care_others == '1' ? ',Yes' : '' }}
                                        </td>
                                        <td>{{ data?.skin_care_specify_produ }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="add-listings-box py-0 my-3">
                            <h3 class="mb-0 heading-calender">Wipes in a day </h3>
                            <table class="table  font-size-13
" *ngIf="newData?.skin_care_Wipes=='1'">
                                <thead>
                                    <tr>
                                        <th>Is it Done?</th>
                                        <th>Special Instruction</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ newData?.skin_care_Wipes == '1' ? 'Yes' : '' }}</td>
                                        <td>{{ newData?.skin_care_special_instructions }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> -->
                        <div class="add-listings-box py-0 my-3">
                            <h3 class="mb-0 heading-calender">Changing Clothes in a day </h3>
                            <div class="dataBydate">

                            <table class="table  font-size-13
">
                                <thead>
                                    <tr>
                                        <th>Reference Time</th>
                                        <th>Actual Time</th>
                                        <th>Is it Done?</th>
                                        <th>Remark</th>
                                        <th>Assign Nurse</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of newData?.changing_clothes">
                                        <td>{{ formatDateTime(data?.Changing_time) }}</td>
                                        <td>{{ formatDateTime(data?.Changing_actual_time) }}</td>

                                        <td>{{ data?.changing_colthes == '1' ? 'Yes' : 'No' }}</td>
                                        <td>{{ data?.clothe_remark
                                            }}</td>
                                        <td>{{data?.nurses}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #elseBlock>
                        <div *ngIf="calendar6">
                            No data available for the selected date.
                        </div>
                    </ng-template>
                </div>
            </div>

        </p-accordionTab>

        <p-accordionTab header="Sleep Training" class="accordianStyle"
            *ngIf="babyAge != 'zero-to-three-months' && babyAge !== 'three-years-above'">
            <div class="col" *ngIf=" babyAge != 'four-months-to-one-year-plan'">
                <div class="viewSummary">

                    <div class="col-md-4 mt-3">
                        <label for="">Time of Training:</label>
                    </div>
                    <div class="col-md-8 mt-3">
                        <strong>
                            <div class="row">
                                <div class="col-md-3"
                                    *ngFor="let data of FirstEntry?.sleeping_training let index = index">

                                    <div class="input-group2 mb-3">
                                        <span class="input-group-text"> {{ index+1 }}. </span>
                                        <div class="formbox">{{ formatDateTime(data?.sleeping_time)}}</div>
                                    </div>
                                </div>
                            </div>
                        </strong>
                    </div>
                    <div class="col-md-4 mt-3">
                        <label for="">Training Of Remark</label>
                    </div>
                    <div class="col-md-8 mt-3">
                        <strong>
                            <div class="row">
                                <div class="col-md-3"
                                    *ngFor="let data of FirstEntry?.sleeping_training let index = index">

                                    <div class="input-group2 mb-3">
                                        <span class="input-group-text"> {{ index+1 }}. </span>
                                        <div class="formbox"> {{ data?.sleeping_remark ?? '-'}}</div>
                                    </div>
                                </div>
                            </div>
                        </strong>
                    </div>
                </div>
            </div>
            <div class=" row viewSummary" *ngIf=" babyAge == 'four-months-to-one-year-plan'">
                <div class="row ">
                    <div class="col-xl-4 col-lg-12
col-md-12 ">
                        <div class="form-group">
                            <label for="">Sleep Training
                            </label>

                            <div type="text" readonly>
                                <strong>
                                    {{FirstEntry?.sleep_train?? '-'
                                }}
                                </strong>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-12
col-md-12">
                        <div class="form-group">
                            <label for="">Wake up time (morning)</label>


                            <div type="text" readonly><strong>
                                    {{formatDateTime(
                                FirstEntry?.walk_time)
                                }}
                                </strong></div>
                        </div>
                    </div>
                </div>
                <div class="row">

                    <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                            <label for="">Nap time</label>


                            <div type="text" readonly><strong>
                                    {{formatDateTime
                                (FirstEntry?.nap_time)
                                }}
                                </strong></div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                            <label for="">Sleep time (evening)</label>


                            <div type="text" readonly><strong>
                                    {{formatDateTime
                                (FirstEntry?.sleep_time)
                                }}
                                </strong></div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                            <label for="">Stroller</label>


                            <div type="text" readonly><strong>
                                    {{
                                FirstEntry?.stroller??'-'
                                }}
                                </strong></div>
                        </div>
                    </div>
                </div>
                <div class="row">

                    <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                            <label for="">Can the baby get himself back to sleep when
                                interrupted
                                during short naps?</label>


                            <div type="text" readonly><strong>
                                    {{ FirstEntry?.interrupt??
                                '-'
                                }}
                                </strong></div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                            <label for="">Needs dummy to help soothe the baby during
                                sleep</label>

                            <div type="text" readonly><strong>
                                    {{FirstEntry?.need_dummy??'-' }}
                                </strong></div>
                        </div>
                    </div>
                </div>
                <div class="row">

                    <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                            <label for="">Pacifier</label>
                            <div type="text" readonly><strong>
                                    {{FirstEntry?.pacifier??
                                                                  '-'
                                                                  }}
                                </strong></div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                            <label for="">Songs before sleep
                            </label>

                            <div type="text" readonly><strong>
                                    {{
                                FirstEntry?.song_sleep??'-'
                                }}
                                </strong></div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                            <label for="">Reading Sleep</label>

                            <div type="text" readonly><strong>
                                    {{
                                FirstEntry?.reading_sleep??'-'
                                }}
                                </strong></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- based on date  -->

            <div class="col">
                <div class="add-listings-box py-0 my-3">
                    <h3 class="mb-0 heading-calender">Sleeping Training (Select date for data)
                        <p-calendar placeholder="dd/mm/yyyy " dateFormat="dd/mm/yy" [showTime]="false"
                            (ngModelChange)="getDataByDate($event)" [(ngModel)]="calendar5">
                        </p-calendar>
                    </h3>
                    <ng-container *ngIf="calendar5 && !error; else elseBlock">
                        <div class="dataBydate">

                        <table class="table  font-size-13
">
                            <thead>
                                <tr>
                                    <th>Time</th>
                                    <th>Is it Done?</th>
                                    <th>Remarks</th>
                                    <th>Assign Nurse</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of newData?.sleeping_training">
                                    <td>{{ formatDateTime(data?.sleeping_time) }}</td>
                                    <td>{{ data?.sleeping_remark ?? '-'}}</td>
                                    <td>{{data?.nurses}}</td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </ng-container>
                    <ng-template #elseBlock>
                        <div *ngIf="calendar5">
                            No data available for the selected date.
                        </div>
                    </ng-template>
                </div>
            </div>

        </p-accordionTab>

        <p-accordionTab header="Sleeping" class="accordianStyle">
            <div class="viewSummary row col">
                <div class="col-md-4 form-group">
                    <label for=""> Sleeping with:Mother</label>
                    <strong *ngFor="let data of FirstEntry?.sleeping_time">
                        {{ data?.sleeping_mother ? "Mother ," : " " }}
                    </strong>
                </div>
                <div class="col-md-8 form-group">
                    <label for="">Time with mother:</label> <strong *ngFor="let data of FirstEntry?.sleeping_time">{{
                        formatDateTime(data?.sleeping_mother_time)}}</strong>
                </div>
                <div class="col-md-4 form-group">
                    <label for="">Sleeping with:Nurse</label>
                    <strong *ngFor="let data of FirstEntry?.nanny_time">
                        {{ data?.sleeping_nanny ? "Nurse" : " " }}
                    </strong>
                </div>
                <div class="col-md-8 form-group">
                    <label for="">Time with nurse:</label> <strong *ngFor="let data of FirstEntry?.nanny_time">{{
                        formatDateTime(data?.sleeping_nanny_time)}}</strong>
                </div>
            </div>
            <div class="col">
                <!-- based on date  -->
                <div class="add-listings-box py-0 my-3">
                    <h3 class="mb-0 heading-calender dateTable">Sleeping (Select Date for Data)
                        <p-calendar placeholder="dd/mm/yyyy " dateFormat="dd/mm/yy" [showTime]="false"
                            (ngModelChange)="getDataByDate($event)" [(ngModel)]="calendar4">
                        </p-calendar>
                    </h3>
                    <ng-container *ngIf="calendar4 && !error; else elseBlock">
                        <div class="dataBydate">

                        <table class="table  font-size-13">
                            <thead>
                                <tr>
                                    <th>With</th>
                                    <th>Reference Time</th>
                                    <th>Actual Time</th>
                                    <th>Is it Done?</th>
                                    <!-- <th>Remarks</th> -->
                                    <th>Assign Nurse</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of newData?.sleeping_time">
                                    <td>Mother</td>
                                    <td>{{ formatDateTime(data?.sleeping_mother_time) }}</td>
                                    <td>{{ formatDateTime(data?.sleeping_mother_actual_time) }}</td>
                                    <td>{{ data?.sleeping_mother == '1' ? 'Yes' : 'No' }}</td>
                                    <td>{{data?.nurses}}</td>
                                </tr>
                                <tr *ngFor="let data of newData?.nanny_time">
                                    <td>Nurse</td>
                                    <td>{{ formatDateTime(data?.sleeping_nanny_time) }}</td>
                                    <td>{{ formatDateTime(data?.sleeping_nanny_actual_time) }}</td>
                                    <td>{{ data?.sleeping_nanny == '1' ? 'Yes' : 'No' }}</td>
                                    <td>{{data?.nurses}}</td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </ng-container>
                    <ng-template #elseBlock>
                        <div *ngIf="calendar4">
                            No data available for the selected date.
                        </div>
                    </ng-template>
                </div>
            </div>

        </p-accordionTab>

        <p-accordionTab header="Vitamins" class="accordianStyle">

            <div class="viewSummary row col" *ngFor="let vitamin of FirstEntry?.vitamins_medications_json let i=index">

                <div *ngIf="vitamin?.vitamin" class="col-md-12 form-group">
                    <label for="">{{i+1}}. Vitamin / Medications of baby is :</label> <strong>{{ vitamin?.vitamin ?
                        vitamin?.vitamin : '-'}}</strong>
                </div>


                <div *ngIf="vitamin?.frequency" class="col-md-12 form-group">
                    <ul>
                        <li>
                            <label for="">Frequency of this :</label><strong>{{ vitamin?.frequency ? vitamin?.frequency
                                : '-' }}</strong>
                        </li>
                        <li *ngFor="let time of vitamin.medicine_time ">
                            <label for="">Times for Vitamin / Medications :</label>
                            <strong>{{formatDateTime(time?.time)}}</strong>
                        </li>
                    </ul>
                </div>
            </div>



            <div class="add-listings-btn mt-3">
                <p-accordion class="mt-3">
                    <!-- based on latest nanny task -->




                    <!-- base on date -->
                    <div class="col">
                        <div class="add-listings-box py-0 my-3">
                            <h3 class="heading-calender dateTable">Vitamin Medications
                                <p-calendar placeholder="dd/mm/yyyy "[showTime]="false"
                                    (ngModelChange)="getDataByDate($event)" [(ngModel)]="calendar3">
                                </p-calendar>
                            </h3>
                            <ng-container *ngIf="calendar3 && !error; else elseBlock">
                                <div class="dataBydate">

                                <table class="table  font-size-13
">
                                    <thead>
                                        <tr>
                                            <th>Medications</th>
                                            <th>Frequency</th>
                                            <th>Reference Time</th>
                                            <th>Actual Time</th>
                                            <th>Is it Done?</th>
                                            <th>Remark</th>
                                            <th>Assign Nurse</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let vitamin of newData?.vitamins_medications_json">
                                            <td>{{ vitamin?.vitamin ? vitamin?.vitamin : '-' }}</td>
                                            <td>{{ vitamin?.frequency ? vitamin?.frequency : '-' }}</td>
                                            <td>
                                                <ng-container *ngFor="let time of vitamin.medicine_time">
                                                    {{ time?.time ? formatDateTime(time?.time) : '-' }}
                                                    <br>
                                                </ng-container>
                                            </td>
                                            <td>
                                                <ng-container *ngFor="let time of vitamin.medicine_time">
                                                    {{ time?.time ? formatDateTime(time?.actual_time) : '-' }}
                                                    <br>
                                                </ng-container>
                                            </td>
                                            <td>
                                                <ng-container *ngFor="let time of vitamin.medicine_time">
                                                    {{ time?.vitamin_checkbox ? 'Yes' : 'No' }}
                                                    <br>
                                                </ng-container>
                                            </td>
                                            <td>
                                                <ng-container *ngFor="let time of vitamin.medicine_time">
                                                    {{ time?.vitamin_remark?time?.vitamin_remark: ' '   }}
                                                    <br>
                                                </ng-container>
                                            </td>
                                            <td>
                                                <ng-container
                                                    *ngIf="vitamin.medicine_time && vitamin.medicine_time.length > 0">
                                                    {{ vitamin?.nurses ? vitamin?.nurses : ' ' }}
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </ng-container>
                            <ng-template #elseBlock>
                                <div *ngIf="calendar3">
                                    No data available for the selected date.
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </p-accordion>
            </div>
        </p-accordionTab>

        <p-accordionTab header="Activities" class="accordianStyle"
            *ngIf="babyAge !== 'zero-to-three-months' && babyAge !== 'four-months-to-one-year-plan'">
            <div class="add-listings-btn view">

                <p-accordion class="mt-3">
                    <!-- based on latest nanny task -->
                    <div class="viewSummary row col">
                        <div *ngFor="let data of FirstEntry?.outside_child let i=index">
                            <div class="col-md-4 form-group" *ngIf=" data.play_child_outside"> {{i+1}}. Playing outside
                            </div>

                            <div class="row">
                                <div *ngIf=" data.play_child_outside_special_Instructions" class="col-md-4 form-group">
                                    <label for="">Special instructions for playing outside:</label><strong>{{
                                        data?.play_child_outside_special_Instructions}}</strong>
                                </div>

                                <div *ngIf="data.play_child_outside" class="col-md-8 form-group">
                                    <label for="">Time :</label><strong>
                                        {{formatDateTime(data?.play_child_outside_time)}}</strong>
                                </div>
                            </div>





                        </div>

                        <div *ngFor="let data of FirstEntry?.escort_child let i=index ">
                            <div class="col-md-4 form-group" *ngIf=" data.escort_child_outside"> {{i+1}}. Escort child
                                outside </div>


                            <div class="row">

                                <div class="col-md-4 form-group"
                                    *ngIf=" data. escort_child_outside_special_Instructions"><label for="">Special
                                        instructions for Escort :</label><strong>
                                        {{ data?.escort_child_outside_special_Instructions }}
                                    </strong></div>

                                <div *ngIf="data.escort_child_outside_time" class="col-md-8 form-group">
                                    <label for="">Time :</label><strong>
                                        {{formatDateTime(data?.escort_child_outside_time)}}</strong>
                                </div>
                            </div>
                        </div>

                        <div *ngFor="let data of FirstEntry?.activities_name  let i=index">
                            <div class="col-md-4 form-group" *ngIf="data.other_activity"> {{i+1}}. Other Activity </div>
                            <div class="row">
                                <div class="col-md-4 form-group"
                                    *ngIf="data.activities_child_outside_special_Instructions">
                                    <label for="">Special instructions for other activities:</label><strong>{{
                                        data?.activities_child_outside_special_Instructions}}</strong>
                                </div>


                                <div class="col-md-8 form-group" *ngIf="data.other_child_outside_time">
                                    <label for="">Time :</label>
                                    <strong>{{formatDateTime(data?.other_child_outside_time)}}</strong>
                                </div>
                            </div>

                        </div>
                        <div *ngFor="let data of FirstEntry?.school_name  let i=index">
                            <div class="col-md-4 form-group" *ngIf="data.escort_child_school"> {{i+1}}. School
                                Activities
                            </div>
                            <div class="row">
                                <div class="col-md-4 form-group" *ngIf="data.escort_child_school_special_instructions">
                                    <label for="">Special instructions for other activities:</label><strong>{{
                                        data?.escort_child_school_special_instructions}}</strong>
                                </div>


                                <div class="col-md-8 form-group" *ngIf="data.school_child_outside_time">
                                    <label for="">Time :</label>
                                    <strong>{{formatDateTime(data?.school_child_outside_time)}}</strong>
                                </div>
                            </div>

                        </div>
                    </div>

                    <!-- based on date  -->



                    <div class="col">
                        <div class="add-listings-box py-0 my-3">
                            <h3 class="mb-0 heading-calender dateTable">Activities of the day (Select date for data)
                                <p-calendar placeholder="dd/mm/yyyy " dateFormat="dd/mm/yy" [showTime]="false"
                                    (ngModelChange)="getDataByDate($event)" [(ngModel)]="calendar2"></p-calendar>
                            </h3>
                            <ng-container *ngIf="calendar2 && !error; else elseBlock">
                                <div class="add-listings-box py-0 my-3">
                                    <h3 class="mb-0 heading-calender">Outside Activities </h3>
                                    <div class="dataBydate">

                                    <table class="table  font-size-13
" *ngIf="newData?.outside_child">
                                        <thead>
                                            <tr>
                                                <th>Reference Time</th>
                                                <th>Actual Time</th>

                                                <th>Special instruction</th>
                                                <th>Is it Done ? </th>
                                                <th>Remark</th>
                                                <th>Assign Nurse</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let data of newData?.outside_child">
                                                <td>{{formatDateTime(data?.play_child_outside_time)}}</td>
                                                <td>{{formatDateTime(data?.play_child_outside_actual_time)}}</td>

                                                <td>{{ data?.play_child_outside_special_Instructions }}</td>
                                                <td>{{ data.play_child_outside_done== 1 ? 'Yes' : 'No' }}</td>
                                                <td>{{ data?.play_child_outside_remark}}</td>
                                                <td>{{data?.nurses}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                                <div class="add-listings-box py-0 my-3">
                                    <h3 class="mb-0 heading-calender">Escort Activities </h3>
                                    <div class="dataBydate">

                                    <table class="table  font-size-13
" *ngIf="newData?.escort_child">
                                        <thead>
                                            <tr>
                                                <th>Reference Time</th>
                                                <th>Actual Time</th>
                                                <th>Special instruction</th>
                                                <th>Is it Done ? </th>
                                                <th>Remark</th>
                                                <th>Assign Nurse</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let data of newData?.escort_child">
                                                <td>{{formatDateTime(data?.escort_child_outside_time)}}</td>
                                                <td>{{formatDateTime(data?.escort_child_outside_actual_time)}}</td>
                                                <td>{{ data?.escort_child_outside_special_Instructions }}</td>
                                                <td>{{ data.escort_child_outside_done== 1 ? 'Yes' : 'No' }}</td>
                                                <td>{{ data?.escort_child_outside_remark}}</td>
                                                <td>{{data?.nurses}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                                <div class="add-listings-box py-0 my-3">
                                    <h3 class="mb-0 heading-calender"> Other Activities </h3>
                                    <div class="dataBydate">

                                    <table class="table  font-size-13" *ngIf="newData?.activities_name">
                                        <thead>
                                            <tr>
                                                <th>Reference Time</th>
                                                <th>Actual Time</th>
                                                <th>Special instruction</th>
                                                <th>Is it Done ? </th>
                                                <th>Remark</th>
                                                <th>Assign Nurse</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let data of newData?.activities_name">
                                                <td>{{formatDateTime(data?.other_child_outside_time)}}</td>
                                                <td>{{formatDateTime(data?.other_child_outside_actual_time)}}</td>
                                                <td>{{ data?.activities_child_outside_special_Instructions }}</td>
                                                <td>{{ data.other_child_outside_done == 1 ? 'Yes' : 'No' }}</td>
                                                <td>{{ data?.other_child_outside_remark}}</td>
                                                <td>{{data?.nurses}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                                <div class="add-listings-box py-0 my-3">
                                    <h3 class="mb-0 heading-calender"> School Activities </h3>
                                    <div class="dataBydate">

                                    <table class="table  font-size-13" *ngIf="newData?.activities_name">
                                        <thead>
                                            <tr>
                                                <th>Reference Time</th>
                                                <th>Actual Time</th>
                                                <th>Special instruction</th>
                                                <th>Is it Done ? </th>
                                                <th>Remark</th>
                                                <th>Assign Nurse</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let data of newData?.school_name">
                                                <td>{{formatDateTime(data?.school_child_outside_time)}}</td>
                                                <td>{{formatDateTime(data?.school_child_outside_actual_time)}}</td>
                                                <td>{{ data?.escort_child_school_special_instructions }}</td>
                                                <td>{{ data.school_child_outside_done == 1 ? 'Yes' : 'No' }}</td>
                                                <td>{{ data?.school_child_outside_remark}}</td>
                                                <td>{{data?.nurses}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #elseBlock>
                                <div *ngIf="calendar2">
                                    No data available for the selected date.
                                </div>
                            </ng-template>
                        </div>
                    </div>

                </p-accordion>
            </div>
        </p-accordionTab>

        <p-accordionTab header="Remarks" class="accordianStyle">
            <div class="add-listings-btn mt-3">
                <p-accordion class="mt-3">
                    <!-- based on latest nanny task -->


                    <div class="col-md-4 form-group">
                        <label for=""> Remark:</label>
                        <strong *ngFor="let data of FirstEntry?.sleeping_time">
                            {{FirstEntry?.remarks}}
                        </strong>
                    </div>
                    <!-- based on date  -->


                    <div class="col">
                        <div class="add-listings-box py-0 my-3 ">
                            <h3 class="mb-0 heading-calender dateTable ">Remark 
                                <p-calendar placeholder="dd/mm/yyyy " [showTime]="false" dateFormat="dd/mm/yy"
                                    (ngModelChange)="getDataByDate($event)" [(ngModel)]="calendar1"></p-calendar>
                            </h3>
                            <ng-container *ngIf="calendar1 && !error">
                                <div>
                                    <input type="text" name="remark" class="form-control" readonly
                                        [value]="newData?.remarks" placeholder="Remark" id="remark" />
                                </div>

                            </ng-container>


                            <div class="add-listings-box py-0 my-3 " *ngIf="calendar1 && !error">
                                <h3 class="mb-0 heading-calender dateTable ">Addition Remark from Nurse Side</h3>
                                <ng-container>



                                    <ng-container *ngIf="calendar1 && !error">
                                        <div class="dataBydate">

                                        <table class="table  font-size-13">
                                            <thead>
                                                <tr>
                                                    <th>Remarks</th>
                                                    <th>Time</th>
                                                    <th>Assign Nurse</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of newData?.nurse_remarks">
                                                    <td><textarea name="" readonly>{{ data?.nurse_remark}}</textarea> </td>
                                                    <td>{{ data?.nurse_remark_time }}</td>
                                                    <td>{{data?.nurses}}</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        </div>
                                    </ng-container>


                                    
                                </ng-container>

                                    
                            </div>

                            <div *ngIf="calendar1 && error">
                                No data available for the selected date.
                            </div>

                        </div>
                    </div>

                </p-accordion>
            </div>
        </p-accordionTab>


    </p-accordion>
    <!-- BABY DETAILS SECTION END-->
    <!-- DAILY CARE SECTION-->

    <!-- DAILY CARE SECTION END-->
    <!-- End Form -->
    <app-copyrights></app-copyrights>
</div>