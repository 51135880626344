import { Component, ElementRef, OnInit, ViewChild ,OnDestroy} from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { CommonApiService } from 'src/app/services/common-api.service';
import { GrowthMonitorService } from 'src/app/services/growth-monitor.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-above-three-yr-monitor',
  templateUrl: './above-three-yr-monitor.component.html',
  styleUrls: ['./above-three-yr-monitor.component.scss']
})
export class AboveThreeYrMonitorComponent implements OnInit,OnDestroy {
  @ViewChild('video') videoElement: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  stream: MediaStream;
  public flag: boolean = true
  capturedImg: File
  ImageName = "Captured-Image"
  openCameraforUpload: boolean = false
  IndexforImage = 0
  breadcrumb = [
    {
      title: 'Three Year and Above Growth Monitoring',
      subtitle: 'Dashboard'
    }
  ]
  genderArray: any = [
    { name: 'Male', value: 'male' },
    { name: 'Female', value: 'female' },
  ];
  getToken: any;
  getRole: any;
  getname: any;
  babyDetails: any = [];
  selectedDate: Date = null; // Initialize with today's date;
  babyGender: any;
  years: any = '';
  month: any = '';
  days: any = '';
  babyImgArray: any = []
  name: any = '';
  babyAge: any;
  today = new Date();
  isedit: boolean = false;
  heightConverted=false;
  weightConverted=false;
  threeYearsAndAbove: FormGroup;
  growthData: any;
  selectedFile: any;
  babyImgJSon: any;
  ImageUrl: string = environment.imgUrl;
  capturedByCamera: any = [];
  imagePreview: boolean = false;
  imgSrcForPreview: any;

  constructor(private common_service: CommonApiService, private fb: FormBuilder, private messageService: MessageService, private growth_service: GrowthMonitorService, private router: Router, private spinner: NgxSpinnerService) {
    this.threeYearsAndAbove = this.fb.group({
      baby_details_id: new FormControl('', Validators.required),
      selectedDate: new FormControl(''),
      enjoyandplay: new FormControl(null),
      enjoyandplay_description: new FormControl(null),
      enduranceandstrength: new FormControl(null),
      enduranceandstrength_description: new FormControl(null),
      coordinateandexplore: new FormControl(null),
      coordinateandexplore_description: new FormControl(null),
      healtoewalk: new FormControl(null),
      healtoewalk_description: new FormControl(null),
      balancetocatch: new FormControl(null),
      balancetocatch_description: new FormControl(null),
      balanceonunevensurfaces: new FormControl(null),
      balanceonunevensurfaces_description: new FormControl(null),
      walkinnewroomnobumping: new FormControl(null),
      walkinnewroomnobumping_description: new FormControl(null),
      leansoverandsitsslumped: new FormControl(null),
      leansoverandsitsslumped_description: new FormControl(null),
      throwandcatchwithbalance: new FormControl(null),
      throwandcatchwithbalance_description: new FormControl(null),
      bothhandstoplay: new FormControl(null),
      bothhandstoplay_description: new FormControl(null),
      partsingamesandactivities: new FormControl(null),
      partsingamesandactivities_description: new FormControl(null),
      colorandshapes: new FormControl(null),
      colorandshapes_description: new FormControl(null),
      usesforcewhenplaying: new FormControl(null),
      usesforcewhenplaying_description: new FormControl(null),
      goodsittingposture: new FormControl(null),
      goodsittingposture_description: new FormControl(null),
      useutensilsforfood: new FormControl(null),
      useutensilsforfood_description: new FormControl(null),
      establishedsleep: new FormControl(null),
      establishedsleep_description: new FormControl(null),
      selfcalmfallasleep: new FormControl(null),
      selfcalmfallasleep_description: new FormControl(null),
      wearnewclothes: new FormControl(null),
      wearnewclothes_description: new FormControl(null),
      appropriatebitesoffood: new FormControl(null),
      appropriatebitesoffood_description: new FormControl(null),
      toleratehairandnailcut: new FormControl(null),
      toleratehairandnailcut_description: new FormControl(null),
      adaptnewroutine: new FormControl(null),
      adaptnewroutine_description: new FormControl(null),
      takesbathandshower: new FormControl(null),
      takesbathandshower_description: new FormControl(null),
      eatsvarietyofdiet: new FormControl(null),
      eatsvarietyofdiet_description: new FormControl(null),
      drinkswithoutdribble: new FormControl(null),
      drinkswithoutdribble_description: new FormControl(null),
      notactiveinfamily: new FormControl(null),
      notactiveinfamily_description: new FormControl(null),
      completemultiplesteptasks: new FormControl(null),
      completemultiplesteptasks_description: new FormControl(null),
      wakesrestedandready: new FormControl(null),
      wakesrestedandready_description: new FormControl(null),
      cancount: new FormControl(null),
      cancount_description: new FormControl(null),
      knowshapes: new FormControl(null),
      knowshapes_description: new FormControl(null),
      developsfriendships: new FormControl(null),
      developsfriendships_description: new FormControl(null),
      expressesemotions: new FormControl(null),
      expressesemotions_description: new FormControl(null),
      followrules: new FormControl(null),
      followrules_description: new FormControl(null),
      payattention: new FormControl(null),
      payattention_description: new FormControl(null),
      vision: new FormControl(null),
      vision_description: new FormControl(null),
      writenumbersandletters: new FormControl(null),
      writenumbersandletters_description: new FormControl(null),
      writesentences: new FormControl(null),
      writesentences_description: new FormControl(null),
      recogniseothersperspective: new FormControl(null),
      recogniseothersperspective_description: new FormControl(null),
      leftright: new FormControl(null),
      leftright_description: new FormControl(null),
      reasonandargue: new FormControl(null),
      reasonandargue_description: new FormControl(null),
      categoriseobjects: new FormControl(null),
      categoriseobjects_description: new FormControl(null),
      yesterdaytodaytomorrow: new FormControl(null),
      yesterdaytodaytomorrow_description: new FormControl(null),
      copycomplexshapes: new FormControl(null),
      copycomplexshapes_description: new FormControl(null),
      sayssimplewords: new FormControl(null),
      sayssimplewords_description: new FormControl(null),
      canattendclass: new FormControl(null),
      canattendclass_description: new FormControl(null),
      formsnumbersandletters: new FormControl(null),
      formsnumbersandletters_description: new FormControl(null),
      recogniseothersperspectivesensory: new FormControl(null),
      recogniseothersperspectivesensory_description: new FormControl(null),
      longattention: new FormControl(null),
      longattention_description: new FormControl(null),
      onemoreresponsibility: new FormControl(null),
      onemoreresponsibility_description: new FormControl(null),
      fractionandspace: new FormControl(null),
      fractionandspace_description: new FormControl(null),
      understandmoney: new FormControl(null),
      understandmoney_description: new FormControl(null),
      telltime: new FormControl(null),
      telltime_description: new FormControl(null),
      monthsdaysinorder: new FormControl(null),
      monthsdaysinorder_description: new FormControl(null),
      enjoyreadingbook: new FormControl(null),
      enjoyreadingbook_description: new FormControl(null),
      understandscomplexlanguage: new FormControl(null),
      understandscomplexlanguage_description: new FormControl(null),
      findnewinfo: new FormControl(null),
      findnewinfo_description: new FormControl(null),
      takingpart: new FormControl(null),
      takingpart_description: new FormControl(null),
      recogniseownname: new FormControl(null),
      recogniseownname_description: new FormControl(null),
      knowletternames: new FormControl(null),
      knowletternames_description: new FormControl(null),
      recognisewordsoutside: new FormControl(null),
      recognisewordsoutside_description: new FormControl(null),
      attemptwritingtherename: new FormControl(null),
      attemptwritingtherename_description: new FormControl(null),
      talkingforsolutions: new FormControl(null),
      talkingforsolutions_description: new FormControl(null),
      talkaboutdidanddos: new FormControl(null),
      talkaboutdidanddos_description: new FormControl(null),
      unfamiliarpeopleunderstands: new FormControl(null),
      unfamiliarpeopleunderstands_description: new FormControl(null),
      eatssameasfamily: new FormControl(null),
      eatssameasfamily_description: new FormControl(null),
      feedswellutensils: new FormControl(null),
      feedswellutensils_description: new FormControl(null),
      likedislikesfood: new FormControl(null),
      likedislikesfood_description: new FormControl(null),
      pickyeater: new FormControl(null),
      pickyeater_description: new FormControl(null),
      wipeshandsnmouth: new FormControl(null),
      wipeshandsnmouth_description: new FormControl(null),
      servesself: new FormControl(null),
      servesself_description: new FormControl(null),
      poursliquid: new FormControl(null),
      poursliquid_description: new FormControl(null),
      eyecontact: new FormControl(null),
      eyecontact_description: new FormControl(null),
      playingsamllgroup: new FormControl(null),
      playingsamllgroup_description: new FormControl(null),
      initiateplay: new FormControl(null),
      initiateplay_description: new FormControl(null),
      turnsinresponse: new FormControl(null),
      turnsinresponse_description: new FormControl(null),
      exploringnew: new FormControl(null),
      exploringnew_description: new FormControl(null),
      newsocialplay: new FormControl(null),
      newsocialplay_description: new FormControl(null),
      playvarietytoys: new FormControl(null),
      playvarietytoys_description: new FormControl(null),
      aware: new FormControl(null),
      aware_description: new FormControl(null),
      craftswithglue: new FormControl(null),
      craftswithglue_description: new FormControl(null),
      roughsafeplay: new FormControl(null),
      roughsafeplay_description: new FormControl(null),
      swinging: new FormControl(null),
      swinging_description: new FormControl(null),
      newtoysplay: new FormControl(null),
      newtoysplay_description: new FormControl(null),
      locatepointing: new FormControl(null),
      locatepointing_description: new FormControl(null),
      enjoysitting: new FormControl(null),
      enjoysitting_description: new FormControl(null),
      playtoyforawhile: new FormControl(null),
      playtoyforawhile_description: new FormControl(null),
      happywhennothungry: new FormControl(null),
      happywhennothungry_description: new FormControl(null),
      accustomedsounds: new FormControl(null),
      accustomedsounds_description: new FormControl(null),
      reliablesleeping: new FormControl(null),
      reliablesleeping_description: new FormControl(null),
      widevariety: new FormControl(null),
      widevariety_description: new FormControl(null),
      crieswhenhurt: new FormControl(null),
      crieswhenhurt_description: new FormControl(null),
      selfcalmafterupset: new FormControl(null),
      selfcalmafterupset_description: new FormControl(null),
      transitiontonewenv: new FormControl(null),
      transitiontonewenv_description: new FormControl(null),
      nodistractionbysound: new FormControl(null),
      nodistractionbysound_description: new FormControl(null),
      copeunexpectedchange: new FormControl(null),
      copeunexpectedchange_description: new FormControl(null),
      health_json: this.fb.array([]),
      baby_images: this.fb.array([])

    })
  }

  logout() {
    localStorage.clear();
    // window.location.reload();
    this.router.navigate(['/']);
  }
  ngOnInit(): void {
    this.spinner.show()
    //   this.startCamera()
    this.common_service.getBabyDetails().subscribe((res: any) => {
      this.spinner.hide()
      this.babyDetails = res?.data;
      this.setBasicDetails();
    },
      (error: any) => {
        this.spinner.hide()

        // Handle any errors that occurred during the API call

      });
    this.getname = localStorage.getItem('name');
    this.getToken = localStorage.getItem('token');
    this.getRole = localStorage.getItem('role')
  }
  ngOnDestroy(): void {
    this.shutDownCamera();
    // Perform cleanup actions here, such as closing the camera
    // Close the camera code here...
  }
  setBasicDetails() {
    if (localStorage.getItem('editedOrderId')) {
      this.getEditFormData();
      this.isedit = true;
    }
    else if (localStorage.getItem('babyId')) {
      const getEdit = localStorage.getItem('babyId');
      this.getBabyDetails({ 'value': getEdit })
      this.threeYearsAndAbove.controls.baby_details_id.setValue(
        JSON.parse(getEdit)
      );
      this.addBabyImg('add', 0);

      this.addVitaminObject('add', 0);
      this.isedit = false;
      this.threeYearsAndAbove.controls.enjoyandplay_description.disable();
      this.threeYearsAndAbove.controls.enduranceandstrength_description.disable();
      this.threeYearsAndAbove.controls.coordinateandexplore_description.disable();
      this.threeYearsAndAbove.controls.healtoewalk_description.disable();
      this.threeYearsAndAbove.controls.balancetocatch_description.disable();
      this.threeYearsAndAbove.controls.balanceonunevensurfaces_description.disable();
      this.threeYearsAndAbove.controls.walkinnewroomnobumping_description.disable();
      this.threeYearsAndAbove.controls.leansoverandsitsslumped_description.disable();
      this.threeYearsAndAbove.controls.throwandcatchwithbalance_description.disable();
      this.threeYearsAndAbove.controls.bothhandstoplay_description.disable();
      this.threeYearsAndAbove.controls.partsingamesandactivities_description.disable();
      this.threeYearsAndAbove.controls.colorandshapes_description.disable();
      this.threeYearsAndAbove.controls.usesforcewhenplaying_description.disable();
      this.threeYearsAndAbove.controls.goodsittingposture_description.disable();
      this.threeYearsAndAbove.controls.useutensilsforfood_description.disable();
      this.threeYearsAndAbove.controls.establishedsleep_description.disable();
      this.threeYearsAndAbove.controls.selfcalmfallasleep_description.disable();
      this.threeYearsAndAbove.controls.wearnewclothes_description.disable();
      this.threeYearsAndAbove.controls.appropriatebitesoffood_description.disable();
      this.threeYearsAndAbove.controls.toleratehairandnailcut_description.disable();
      this.threeYearsAndAbove.controls.adaptnewroutine_description.disable();
      this.threeYearsAndAbove.controls.takesbathandshower_description.disable();
      this.threeYearsAndAbove.controls.eatsvarietyofdiet_description.disable();
      this.threeYearsAndAbove.controls.drinkswithoutdribble_description.disable();
      this.threeYearsAndAbove.controls.notactiveinfamily_description.disable();
      this.threeYearsAndAbove.controls.completemultiplesteptasks_description.disable();
      this.threeYearsAndAbove.controls.wakesrestedandready_description.disable();
      this.threeYearsAndAbove.controls.cancount_description.disable();
      this.threeYearsAndAbove.controls.knowshapes_description.disable();
      this.threeYearsAndAbove.controls.developsfriendships_description.disable();
      this.threeYearsAndAbove.controls.expressesemotions_description.disable();
      this.threeYearsAndAbove.controls.followrules_description.disable();
      this.threeYearsAndAbove.controls.payattention_description.disable();
      this.threeYearsAndAbove.controls.vision_description.disable();
      this.threeYearsAndAbove.controls.writenumbersandletters_description.disable();
      this.threeYearsAndAbove.controls.recogniseothersperspective_description.disable();
      this.threeYearsAndAbove.controls.leftright_description.disable();
      this.threeYearsAndAbove.controls.reasonandargue_description.disable();
      this.threeYearsAndAbove.controls.categoriseobjects_description.disable();
      this.threeYearsAndAbove.controls.yesterdaytodaytomorrow_description.disable();
      this.threeYearsAndAbove.controls.copycomplexshapes_description.disable();
      this.threeYearsAndAbove.controls.sayssimplewords_description.disable();
      this.threeYearsAndAbove.controls.canattendclass_description.disable();
      this.threeYearsAndAbove.controls.formsnumbersandletters_description.disable();
      this.threeYearsAndAbove.controls.recogniseothersperspective_description.disable();
      this.threeYearsAndAbove.controls.longattention_description.disable();
      this.threeYearsAndAbove.controls.onemoreresponsibility_description.disable();
      this.threeYearsAndAbove.controls.fractionandspace_description.disable();
      this.threeYearsAndAbove.controls.understandmoney_description.disable();
      this.threeYearsAndAbove.controls.telltime_description.disable();
      this.threeYearsAndAbove.controls.monthsdaysinorder_description.disable();
      this.threeYearsAndAbove.controls.enjoyreadingbook_description.disable();
      this.threeYearsAndAbove.controls.understandscomplexlanguage_description.disable();
      this.threeYearsAndAbove.controls.findnewinfo_description.disable();
      this.threeYearsAndAbove.controls.takingpart_description.disable();
      this.threeYearsAndAbove.controls.recogniseownname_description.disable();
      this.threeYearsAndAbove.controls.knowletternames_description.disable();
      this.threeYearsAndAbove.controls.recognisewordsoutside_description.disable();
      this.threeYearsAndAbove.controls.attemptwritingtherename_description.disable();
      this.threeYearsAndAbove.controls.talkingforsolutions_description.disable();
      this.threeYearsAndAbove.controls.talkaboutdidanddos_description.disable();
      this.threeYearsAndAbove.controls.unfamiliarpeopleunderstands_description.disable();
      this.threeYearsAndAbove.controls.eatssameasfamily_description.disable();
      this.threeYearsAndAbove.controls.feedswellutensils_description.disable();
      this.threeYearsAndAbove.controls.likedislikesfood_description.disable();
      this.threeYearsAndAbove.controls.pickyeater_description.disable();
      this.threeYearsAndAbove.controls.wipeshandsnmouth_description.disable();
      this.threeYearsAndAbove.controls.servesself_description.disable();
      this.threeYearsAndAbove.controls.poursliquid_description.disable();
      this.threeYearsAndAbove.controls.eyecontact_description.disable();
      this.threeYearsAndAbove.controls.playingsamllgroup_description.disable();
      this.threeYearsAndAbove.controls.initiateplay_description.disable();
      this.threeYearsAndAbove.controls.turnsinresponse_description.disable();
      this.threeYearsAndAbove.controls.exploringnew_description.disable();
      this.threeYearsAndAbove.controls.newsocialplay_description.disable();
      this.threeYearsAndAbove.controls.playvarietytoys_description.disable();
      this.threeYearsAndAbove.controls.aware_description.disable();
      this.threeYearsAndAbove.controls.craftswithglue_description.disable();
      this.threeYearsAndAbove.controls.roughsafeplay_description.disable();
      this.threeYearsAndAbove.controls.swinging_description.disable();
      this.threeYearsAndAbove.controls.newtoysplay_description.disable();
      this.threeYearsAndAbove.controls.locatepointing_description.disable();
      this.threeYearsAndAbove.controls.enjoysitting_description.disable();
      this.threeYearsAndAbove.controls.playtoyforawhile_description.disable();
      this.threeYearsAndAbove.controls.happywhennothungry_description.disable();
      this.threeYearsAndAbove.controls.accustomedsounds_description.disable();
      this.threeYearsAndAbove.controls.reliablesleeping_description.disable();
      this.threeYearsAndAbove.controls.widevariety_description.disable();
      this.threeYearsAndAbove.controls.crieswhenhurt_description.disable()
      this.threeYearsAndAbove.controls.selfcalmafterupset_description.disable();
      this.threeYearsAndAbove.controls.transitiontonewenv_description.disable();
      this.threeYearsAndAbove.controls.nodistractionbysound_description.disable();
      this.threeYearsAndAbove.controls.copeunexpectedchange_description.disable();
    }
  }
  keyPress(event: KeyboardEvent) {
    const pattern = /^\d+(\.\d{0,2})?$/;

    const inputValue = (event.target as HTMLInputElement).value;
    const proposedValue = inputValue + String.fromCharCode(event.charCode);

    if (!pattern.test(proposedValue)) {
      // Invalid input, prevent input
      event.preventDefault();
    }
  }
  /* -------------------------------------------------------------------------- */
  /*                // get baby details and show dob accordingly                */
  /* -------------------------------------------------------------------------- */
  getBabyDetails(event: any) {
    let data = this.babyDetails?.filter((res: any) => res?.id == event?.value);
    let firstLetter = data[0]?.gender.toLowerCase();
    let capitalize =
      firstLetter.slice(0, 1).toUpperCase() +
      firstLetter.slice(1, firstLetter.length);
    this.babyGender = capitalize;
    this.selectedDate = data[0].date_of_birth
    // Manually parse the date string in DD/MM/YYYY format
    const dateParts = data[0].date_of_birth.split('/');
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Months are 0-indexed in JavaScript
    const year = parseInt(dateParts[2], 10);

    const selectedDate = moment([year, month, day]);
    const today = moment();

    // Calculate the difference in years, months, and days
    const years = today.diff(selectedDate, 'years');
    selectedDate.add(years, 'years');

    const months = today.diff(selectedDate, 'months');
    selectedDate.add(months, 'months');

    const days = today.diff(selectedDate, 'days');

    this.years = years;
    this.month = months;
    this.days = days;
    if (this.years >= 3) {
      this.flag = true;
    } else {
      this.flag = false;
      this.messageService.add({
        severity: 'info',
        summary: 'Info',
        detail: 'Your child does not meet the eligibility criteria for this plan.',
      });
    }

    // if (this.years != null) {
    //   if (this.years == 1 || this.years == 2) {
    //     this.babyAge = '1 - 2 Years';
    //   } else if (this.years == 3 || this.years == 4) {
    //     this.babyAge = '3 - 4 Years';
    //   } else if (this.years == 4 || this.years == 5) {
    //     this.babyAge = '4 - 5 Years';
    //   } else if (this.years == 5 || this.years == 6) {
    //     this.babyAge = '5 - 6 Years';
    //   } else if (this.years < 1) {
    //     if (this.month > 0 && this.month <= 3) {
    //       this.babyAge = '0 - 3 Months';
    //     } else if (this.month > 3 && this.month <= 11) {
    //       this.babyAge = '4 Months - 1 Year';
    //     } else {
    //       this.babyAge = 'not found';
    //     }
    //   }
    // } else {
    //   this.years = 0;
    //   this.month = 0;
    //   this.days = 0;
    //   this.babyAge = 'not found';
    // }
  }
  getEditFormData() {
    this.spinner.show()
    const getEdit = localStorage.getItem('editedOrderId')
    this.common_service.getEditGrowthMonitoring(getEdit).then((res: any) => {
      this.spinner.hide()
      const detailsInGrowthMonitoring = res.data[0];
      const developmentMilestones = res.data[0].development_milestones;
      const milestoneData = JSON.parse(developmentMilestones[0].development_milestone);
      this.growthData = milestoneData
      const physical_assessment_details_json = developmentMilestones[0].physical_assessment_details_json

      this.babyGender = detailsInGrowthMonitoring.gender;
      this.selectedDate = detailsInGrowthMonitoring.date_of_birth;
      this.days = detailsInGrowthMonitoring.days;
      this.month = detailsInGrowthMonitoring.months;
      this.years = detailsInGrowthMonitoring.years;
      this.threeYearsAndAbove.controls.baby_details_id.setValue(detailsInGrowthMonitoring.baby_id)
      const VitaminsMedication =
      physical_assessment_details_json
      var check =true
      for (let i = 0; i < VitaminsMedication.length; i++) {
        if((VitaminsMedication[i].height != null) || (VitaminsMedication[i].time!=null) || (VitaminsMedication[i].weight != null)){
          check = false
          let vitaminArray = this.gethealthFormArray();
          let VitaminsMedicationFromApi = VitaminsMedication[i] || {};
  
          let newVitaminArray = new FormGroup({
            height: new FormControl(VitaminsMedicationFromApi.height || null),
            time: new FormControl(
              VitaminsMedicationFromApi.time
                ? new Date(VitaminsMedicationFromApi.time)
                : null
            ),
            weight: new FormControl(VitaminsMedicationFromApi.weight || null),
          });
  
          vitaminArray.insert(i, newVitaminArray);
        }
       
      }
      this.babyImgJSon = developmentMilestones[0].growth_images_json;
      this.addBabyImg('add', 0);
      this.threeYearsAndAbove.controls.enjoyandplay.setValue(milestoneData.enjoyandplay);
      this.threeYearsAndAbove.controls.enjoyandplay_description.setValue(milestoneData.enjoyandplay_description);
      this.threeYearsAndAbove.controls.enduranceandstrength.setValue(milestoneData.enduranceandstrength);
      this.threeYearsAndAbove.controls.enduranceandstrength_description.setValue(milestoneData.enduranceandstrength_description);
      this.threeYearsAndAbove.controls.coordinateandexplore.setValue(milestoneData.coordinateandexplore);
      this.threeYearsAndAbove.controls.coordinateandexplore_description.setValue(milestoneData.coordinateandexplore_description);
      this.threeYearsAndAbove.controls.healtoewalk.setValue(milestoneData.healtoewalk);
      this.threeYearsAndAbove.controls.healtoewalk_description.setValue(milestoneData.healtoewalk_description);
      this.threeYearsAndAbove.controls.balancetocatch.setValue(milestoneData.balancetocatch);
      this.threeYearsAndAbove.controls.balancetocatch_description.setValue(milestoneData.balancetocatch_description);
      this.threeYearsAndAbove.controls.balanceonunevensurfaces.setValue(milestoneData.balanceonunevensurfaces);
      this.threeYearsAndAbove.controls.balanceonunevensurfaces_description.setValue(milestoneData.balanceonunevensurfaces_description);
      this.threeYearsAndAbove.controls.walkinnewroomnobumping.setValue(milestoneData.walkinnewroomnobumping);
      this.threeYearsAndAbove.controls.walkinnewroomnobumping_description.setValue(milestoneData.walkinnewroomnobumping_description);
      this.threeYearsAndAbove.controls.leansoverandsitsslumped.setValue(milestoneData.leansoverandsitsslumped);
      this.threeYearsAndAbove.controls.leansoverandsitsslumped_description.setValue(milestoneData.leansoverandsitsslumped_description);
      this.threeYearsAndAbove.controls.throwandcatchwithbalance.setValue(milestoneData.throwandcatchwithbalance);
      this.threeYearsAndAbove.controls.throwandcatchwithbalance_description.setValue(milestoneData.throwandcatchwithbalance_description);
      this.threeYearsAndAbove.controls.bothhandstoplay.setValue(milestoneData.bothhandstoplay);
      this.threeYearsAndAbove.controls.bothhandstoplay_description.setValue(milestoneData.bothhandstoplay_description);
      this.threeYearsAndAbove.controls.partsingamesandactivities.setValue(milestoneData.partsingamesandactivities);
      this.threeYearsAndAbove.controls.partsingamesandactivities_description.setValue(milestoneData.partsingamesandactivities_description);
      this.threeYearsAndAbove.controls.colorandshapes.setValue(milestoneData.colorandshapes)
      this.threeYearsAndAbove.controls.colorandshapes_description.setValue(milestoneData.colorandshapes_description);
      this.threeYearsAndAbove.controls.usesforcewhenplaying.setValue(milestoneData.usesforcewhenplaying);
      this.threeYearsAndAbove.controls.usesforcewhenplaying_description.setValue(milestoneData.usesforcewhenplaying_description);
      this.threeYearsAndAbove.controls.goodsittingposture.setValue(milestoneData.goodsittingposture);
      this.threeYearsAndAbove.controls.goodsittingposture_description.setValue(milestoneData.goodsittingposture_description);
      this.threeYearsAndAbove.controls.useutensilsforfood.setValue(milestoneData.useutensilsforfood);
      this.threeYearsAndAbove.controls.useutensilsforfood_description.setValue(milestoneData.useutensilsforfood_description);
      this.threeYearsAndAbove.controls.establishedsleep.setValue(milestoneData.establishedsleep);
      this.threeYearsAndAbove.controls.establishedsleep_description.setValue(milestoneData.establishedsleep_description);
      this.threeYearsAndAbove.controls.selfcalmfallasleep.setValue(milestoneData.selfcalmfallasleep);
      this.threeYearsAndAbove.controls.selfcalmfallasleep_description.setValue(milestoneData.selfcalmfallasleep_description);
      this.threeYearsAndAbove.controls.wearnewclothes.setValue(milestoneData.wearnewclothes);
      this.threeYearsAndAbove.controls.wearnewclothes_description.setValue(milestoneData.wearnewclothes_description);
      this.threeYearsAndAbove.controls.appropriatebitesoffood.setValue(milestoneData.appropriatebitesoffood);
      this.threeYearsAndAbove.controls.appropriatebitesoffood_description.setValue(milestoneData.appropriatebitesoffood_description);
      this.threeYearsAndAbove.controls.toleratehairandnailcut.setValue(milestoneData.toleratehairandnailcut);
      this.threeYearsAndAbove.controls.toleratehairandnailcut_description.setValue(milestoneData.toleratehairandnailcut_description);
      this.threeYearsAndAbove.controls.adaptnewroutine.setValue(milestoneData.adaptnewroutine);
      this.threeYearsAndAbove.controls.adaptnewroutine_description.setValue(milestoneData.adaptnewroutine_description);
      this.threeYearsAndAbove.controls.takesbathandshower.setValue(milestoneData.takesbathandshower);
      this.threeYearsAndAbove.controls.takesbathandshower_description.setValue(milestoneData.takesbathandshower_description);
      this.threeYearsAndAbove.controls.eatsvarietyofdiet.setValue(milestoneData.eatsvarietyofdiet);
      this.threeYearsAndAbove.controls.eatsvarietyofdiet_description.setValue(milestoneData.eatsvarietyofdiet_description);
      this.threeYearsAndAbove.controls.drinkswithoutdribble.setValue(milestoneData.drinkswithoutdribble);
      this.threeYearsAndAbove.controls.drinkswithoutdribble_description.setValue(milestoneData.drinkswithoutdribble_description);
      this.threeYearsAndAbove.controls.notactiveinfamily.setValue(milestoneData.notactiveinfamily);
      this.threeYearsAndAbove.controls.notactiveinfamily_description.setValue(milestoneData.notactiveinfamily_description);
      this.threeYearsAndAbove.controls.completemultiplesteptasks.setValue(milestoneData.completemultiplesteptasks);
      this.threeYearsAndAbove.controls.completemultiplesteptasks_description.setValue(milestoneData.completemultiplesteptasks_description);
      this.threeYearsAndAbove.controls.wakesrestedandready.setValue(milestoneData.wakesrestedandready);
      this.threeYearsAndAbove.controls.wakesrestedandready_description.setValue(milestoneData.wakesrestedandready_description);
      this.threeYearsAndAbove.controls.cancount.setValue(milestoneData.cancount);
      this.threeYearsAndAbove.controls.cancount_description.setValue(milestoneData.cancount_description);
      this.threeYearsAndAbove.controls.knowshapes.setValue(milestoneData.knowshapes);
      this.threeYearsAndAbove.controls.knowshapes_description.setValue(milestoneData.knowshapes_description);
      this.threeYearsAndAbove.controls.developsfriendships.setValue(milestoneData.developsfriendships);
      this.threeYearsAndAbove.controls.developsfriendships_description.setValue(milestoneData.developsfriendships_description);
      this.threeYearsAndAbove.controls.expressesemotions.setValue(milestoneData.expressesemotions);
      this.threeYearsAndAbove.controls.expressesemotions_description.setValue(milestoneData.expressesemotions_description);
      this.threeYearsAndAbove.controls.followrules.setValue(milestoneData.followrules);
      this.threeYearsAndAbove.controls.followrules_description.setValue(milestoneData.followrules_description);
      this.threeYearsAndAbove.controls.payattention.setValue(milestoneData.payattention);
      this.threeYearsAndAbove.controls.payattention_description.setValue(milestoneData.payattention_description);
      this.threeYearsAndAbove.controls.vision.setValue(milestoneData.vision);
      this.threeYearsAndAbove.controls.vision_description.setValue(milestoneData.vision_description);
      this.threeYearsAndAbove.controls.writenumbersandletters.setValue(milestoneData.writenumbersandletters);
      this.threeYearsAndAbove.controls.writenumbersandletters_description.setValue(milestoneData.writenumbersandletters_description);
      this.threeYearsAndAbove.controls.writesentences.setValue(milestoneData.writesentences);
      this.threeYearsAndAbove.controls.writesentences_description.setValue(milestoneData.writesentences_description);
      this.threeYearsAndAbove.controls.recogniseothersperspective.setValue(milestoneData.recogniseothersperspective);
      this.threeYearsAndAbove.controls.recogniseothersperspective_description.setValue(milestoneData.recogniseothersperspective_description);
      this.threeYearsAndAbove.controls.leftright.setValue(milestoneData.leftright);
      this.threeYearsAndAbove.controls.leftright_description.setValue(milestoneData.leftright_description);
      this.threeYearsAndAbove.controls.reasonandargue.setValue(milestoneData.reasonandargue);
      this.threeYearsAndAbove.controls.reasonandargue_description.setValue(milestoneData.reasonandargue_description);
      this.threeYearsAndAbove.controls.categoriseobjects.setValue(milestoneData.categoriseobjects);
      this.threeYearsAndAbove.controls.categoriseobjects_description.setValue(milestoneData.categoriseobjects_description);
      this.threeYearsAndAbove.controls.yesterdaytodaytomorrow.setValue(milestoneData.yesterdaytodaytomorrow);
      this.threeYearsAndAbove.controls.yesterdaytodaytomorrow_description.setValue(milestoneData.yesterdaytodaytomorrow_description);
      this.threeYearsAndAbove.controls.copycomplexshapes.setValue(milestoneData.copycomplexshapes);
      this.threeYearsAndAbove.controls.copycomplexshapes_description.setValue(milestoneData.copycomplexshapes_description);
      this.threeYearsAndAbove.controls.sayssimplewords.setValue(milestoneData.sayssimplewords);
      this.threeYearsAndAbove.controls.sayssimplewords_description.setValue(milestoneData.sayssimplewords_description);
      this.threeYearsAndAbove.controls.canattendclass.setValue(milestoneData.canattendclass);
      this.threeYearsAndAbove.controls.canattendclass_description.setValue(milestoneData.canattendclass_description);
      this.threeYearsAndAbove.controls.formsnumbersandletters.setValue(milestoneData.formsnumbersandletters);
      this.threeYearsAndAbove.controls.formsnumbersandletters_description.setValue(milestoneData.formsnumbersandletters_description);
      this.threeYearsAndAbove.controls.recogniseothersperspectivesensory.setValue(milestoneData.recogniseothersperspectivesensory);
      this.threeYearsAndAbove.controls.recogniseothersperspective_description.setValue(milestoneData.recogniseothersperspective_description);
      this.threeYearsAndAbove.controls.longattention.setValue(milestoneData.longattention);
      this.threeYearsAndAbove.controls.longattention_description.setValue(milestoneData.longattention_description);
      this.threeYearsAndAbove.controls.onemoreresponsibility.setValue(milestoneData.onemoreresponsibility);
      this.threeYearsAndAbove.controls.onemoreresponsibility_description.setValue(milestoneData.onemoreresponsibility_description);
      this.threeYearsAndAbove.controls.fractionandspace.setValue(milestoneData.fractionandspace);
      this.threeYearsAndAbove.controls.fractionandspace_description.setValue(milestoneData.fractionandspace_description);
      this.threeYearsAndAbove.controls.understandmoney.setValue(milestoneData.understandmoney);
      this.threeYearsAndAbove.controls.understandmoney_description.setValue(milestoneData.understandmoney_description);
      this.threeYearsAndAbove.controls.telltime.setValue(milestoneData.telltime);
      this.threeYearsAndAbove.controls.telltime_description.setValue(milestoneData.telltime_description);
      this.threeYearsAndAbove.controls.monthsdaysinorder.setValue(milestoneData.monthsdaysinorder)
      this.threeYearsAndAbove.controls.monthsdaysinorder_description.setValue(milestoneData.monthsdaysinorder_description);
      this.threeYearsAndAbove.controls.enjoyreadingbook.setValue(milestoneData.enjoyreadingbook);
      this.threeYearsAndAbove.controls.enjoyreadingbook_description.setValue(milestoneData.enjoyreadingbook_description);
      this.threeYearsAndAbove.controls.understandscomplexlanguage.setValue(milestoneData.understandscomplexlanguage);
      this.threeYearsAndAbove.controls.understandscomplexlanguage_description.setValue(milestoneData.understandscomplexlanguage_description);
      this.threeYearsAndAbove.controls.findnewinfo.setValue(milestoneData.findnewinfo);
      this.threeYearsAndAbove.controls.findnewinfo_description.setValue(milestoneData.findnewinfo_description);
      this.threeYearsAndAbove.controls.takingpart.setValue(milestoneData.takingpart);
      this.threeYearsAndAbove.controls.takingpart_description.setValue(milestoneData.takingpart_description);
      this.threeYearsAndAbove.controls.recogniseownname.setValue(milestoneData.recogniseownname);
      this.threeYearsAndAbove.controls.recogniseownname_description.setValue(milestoneData.recogniseownname_description);
      this.threeYearsAndAbove.controls.knowletternames.setValue(milestoneData.knowletternames);
      this.threeYearsAndAbove.controls.knowletternames_description.setValue(milestoneData.knowletternames_description);
      this.threeYearsAndAbove.controls.recognisewordsoutside_description.setValue(milestoneData.recognisewordsoutside);
      this.threeYearsAndAbove.controls.recognisewordsoutside_description.setValue(milestoneData.recognisewordsoutside_description);
      this.threeYearsAndAbove.controls.attemptwritingtherename.setValue(milestoneData.attemptwritingtherename);
      this.threeYearsAndAbove.controls.attemptwritingtherename_description.setValue(milestoneData.attemptwritingtherename_description);
      this.threeYearsAndAbove.controls.talkingforsolutions.setValue(milestoneData.talkingforsolutions);
      this.threeYearsAndAbove.controls.talkingforsolutions_description.setValue(milestoneData.talkingforsolutions_description);
      this.threeYearsAndAbove.controls.talkaboutdidanddos.setValue(milestoneData.talkaboutdidanddos);
      this.threeYearsAndAbove.controls.talkaboutdidanddos_description.setValue(milestoneData.talkaboutdidanddos_description);
      this.threeYearsAndAbove.controls.unfamiliarpeopleunderstands.setValue(milestoneData.unfamiliarpeopleunderstands)
      this.threeYearsAndAbove.controls.unfamiliarpeopleunderstands_description.setValue(milestoneData.unfamiliarpeopleunderstands_description);
      this.threeYearsAndAbove.controls.eatssameasfamily.setValue(milestoneData.eatssameasfamily);
      this.threeYearsAndAbove.controls.eatssameasfamily_description.setValue(milestoneData.eatssameasfamily_description);
      this.threeYearsAndAbove.controls.feedswellutensils.setValue(milestoneData.feedswellutensils);
      this.threeYearsAndAbove.controls.feedswellutensils_description.setValue(milestoneData.feedswellutensils_description);
      this.threeYearsAndAbove.controls.likedislikesfood.setValue(milestoneData.likedislikesfood);
      this.threeYearsAndAbove.controls.likedislikesfood_description.setValue(milestoneData.likedislikesfood_description);
      this.threeYearsAndAbove.controls.pickyeater.setValue(milestoneData.pickyeater);
      this.threeYearsAndAbove.controls.pickyeater_description.setValue(milestoneData.pickyeater_description);
      this.threeYearsAndAbove.controls.wipeshandsnmouth.setValue(milestoneData.wipeshandsnmouth);
      this.threeYearsAndAbove.controls.wipeshandsnmouth_description.setValue(milestoneData.wipeshandsnmouth_description);
      this.threeYearsAndAbove.controls.servesself.setValue(milestoneData.servesself);
      this.threeYearsAndAbove.controls.servesself_description.setValue(milestoneData.servesself_description);
      this.threeYearsAndAbove.controls.poursliquid.setValue(milestoneData.poursliquid);
      this.threeYearsAndAbove.controls.poursliquid_description.setValue(milestoneData.poursliquid_description);
      this.threeYearsAndAbove.controls.eyecontact.setValue(milestoneData.eyecontact);
      this.threeYearsAndAbove.controls.eyecontact_description.setValue(milestoneData.eyecontact_description);
      this.threeYearsAndAbove.controls.playingsamllgroup.setValue(milestoneData.playingsamllgroup);
      this.threeYearsAndAbove.controls.playingsamllgroup_description.setValue(milestoneData.playingsamllgroup_description);
      this.threeYearsAndAbove.controls.initiateplay.setValue(milestoneData.initiateplay);
      this.threeYearsAndAbove.controls.initiateplay_description.setValue(milestoneData.initiateplay_description);
      this.threeYearsAndAbove.controls.turnsinresponse.setValue(milestoneData.turnsinresponse);
      this.threeYearsAndAbove.controls.turnsinresponse_description.setValue(milestoneData.turnsinresponse_description);
      this.threeYearsAndAbove.controls.exploringnew.setValue(milestoneData.exploringnew);
      this.threeYearsAndAbove.controls.exploringnew_description.setValue(milestoneData.exploringnew_description);
      this.threeYearsAndAbove.controls.newsocialplay.setValue(milestoneData.newsocialplay);
      this.threeYearsAndAbove.controls.newsocialplay_description.setValue(milestoneData.newsocialplay_description);
      this.threeYearsAndAbove.controls.playvarietytoys.setValue(milestoneData.playvarietytoys);
      this.threeYearsAndAbove.controls.playvarietytoys_description.setValue(milestoneData.playvarietytoys_description);
      this.threeYearsAndAbove.controls.aware.setValue(milestoneData.aware);
      this.threeYearsAndAbove.controls.aware_description.setValue(milestoneData.aware_description);
      this.threeYearsAndAbove.controls.craftswithglue.setValue(milestoneData.craftswithglue);
      this.threeYearsAndAbove.controls.craftswithglue_description.setValue(milestoneData.craftswithglue_description);
      this.threeYearsAndAbove.controls.roughsafeplay.setValue(milestoneData.roughsafeplay);
      this.threeYearsAndAbove.controls.roughsafeplay_description.setValue(milestoneData.roughsafeplay_description);
      this.threeYearsAndAbove.controls.swinging.setValue(milestoneData.swinging);
      this.threeYearsAndAbove.controls.swinging_description.setValue(milestoneData.swinging_description);
      this.threeYearsAndAbove.controls.newtoysplay.setValue(milestoneData.newtoysplay);
      this.threeYearsAndAbove.controls.newtoysplay_description.setValue(milestoneData.newtoysplay_description);
      this.threeYearsAndAbove.controls.locatepointing.setValue(milestoneData.locatepointing);
      this.threeYearsAndAbove.controls.locatepointing_description.setValue(milestoneData.locatepointing_description);
      this.threeYearsAndAbove.controls.enjoysitting.setValue(milestoneData.enjoysitting);
      this.threeYearsAndAbove.controls.enjoysitting_description.setValue(milestoneData.enjoysitting_description);
      this.threeYearsAndAbove.controls.playtoyforawhile.setValue(milestoneData.playtoyforawhile);
      this.threeYearsAndAbove.controls.playtoyforawhile_description.setValue(milestoneData.playtoyforawhile_description);
      this.threeYearsAndAbove.controls.happywhennothungry.setValue(milestoneData.happywhennothungry);
      this.threeYearsAndAbove.controls.happywhennothungry_description.setValue(milestoneData.happywhennothungry_description);
      this.threeYearsAndAbove.controls.accustomedsounds.setValue(milestoneData.accustomedsounds);
      this.threeYearsAndAbove.controls.accustomedsounds_description.setValue(milestoneData.accustomedsounds_description);
      this.threeYearsAndAbove.controls.reliablesleeping.setValue(milestoneData.reliablesleeping);
      this.threeYearsAndAbove.controls.reliablesleeping_description.setValue(milestoneData.reliablesleeping_description);
      this.threeYearsAndAbove.controls.widevariety.setValue(milestoneData.widevariety);
      this.threeYearsAndAbove.controls.widevariety_description.setValue(milestoneData.widevariety_description);
      this.threeYearsAndAbove.controls.crieswhenhurt.setValue(milestoneData.crieswhenhurt)
      this.threeYearsAndAbove.controls.crieswhenhurt_description.setValue(milestoneData.crieswhenhurt_description);
      this.threeYearsAndAbove.controls.selfcalmafterupset.setValue(milestoneData.selfcalmafterupset);
      this.threeYearsAndAbove.controls.selfcalmafterupset_description.setValue(milestoneData.selfcalmafterupset_description);
      this.threeYearsAndAbove.controls.transitiontonewenv.setValue(milestoneData.transitiontonewenv);
      this.threeYearsAndAbove.controls.transitiontonewenv_description.setValue(milestoneData.transitiontonewenv_description);
      this.threeYearsAndAbove.controls.nodistractionbysound.setValue(milestoneData.nodistractionbysound);
      this.threeYearsAndAbove.controls.nodistractionbysound_description.setValue(milestoneData.nodistractionbysound_description);
      this.threeYearsAndAbove.controls.copeunexpectedchange.setValue(milestoneData.copeunexpectedchange);
      this.threeYearsAndAbove.controls.copeunexpectedchange_description.setValue(milestoneData.copeunexpectedchange_description);
      this.disableForm();
      if(check == true){
        this.addVitaminObject('add' , 0)
 }
    }, (error: any) => {
      this.spinner.hide()

      // Handle any errors that occurred during the API call

    })
  }

  /*---------------------------------------------------------------------------*/
  /*                       Reloading of page                                    */
  /*----------------------------------------------------------------------------*/
  softReload() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['portal/patient-view']);
    });
  }
  /* -------------------------------------------------------------------------- */
  /*                           // submit form function                          */
  /* -------------------------------------------------------------------------- */
  startCamera() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          this.videoElement.nativeElement.srcObject = stream;
        })
        .catch(error => {
        });
    }
  }

  openCamera() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          this.stream = stream;
          this.videoElement.nativeElement.srcObject = stream;
          this.openCameraforUpload = true
        })
        .catch(error => {
        });
    }
  }
  shutDownCamera(){
    this.openCameraforUpload=false;
    const video = this.videoElement.nativeElement;
  if (video.srcObject) {
    const stream = video.srcObject as MediaStream;
    const tracks = stream.getTracks();
    tracks.forEach(track => track.stop()); // Stop tracks to close the camera
    video.srcObject = null; // Reset the srcObject to release the camera resource
    this.openCameraforUpload = false
  }
  }
  capture() {
    ++this.IndexforImage
    const context = this.canvas.nativeElement.getContext('2d');
    const canvasWidth = this.canvas.nativeElement.width;
    const canvasHeight = this.canvas.nativeElement.height;
    context.drawImage(this.videoElement.nativeElement, 0, 0, canvasWidth, canvasHeight);
    const imageData = this.canvas.nativeElement.toDataURL('image/png');
    this.capturedByCamera.push(imageData)
    const byteString = atob(imageData.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: 'image/png' });
    this.capturedImg = new File([blob], `captured-image${this.IndexforImage}.png`, { type: 'image/png' });
    this.uploadPhoto(null, this.capturedImg)
    this.stopCamera();
  }

  stopCamera() {
    const video = this.videoElement.nativeElement;
    if (video.srcObject) {
      const stream = video.srcObject as MediaStream;
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop()); // Stop tracks to close the camera
      video.srcObject = null; // Reset the srcObject to release the camera resource
      this.openCameraforUpload = false
    }
  }

  uploadPhoto(event: any, captureImg?: any) {

    if (captureImg) {
      this.selectedFile = captureImg;
    }
    else {
      this.selectedFile = event.target.files[0];
    }
    if (event &&
      event.target.files[0].type == 'image/jpeg' ||
      'image/jpg' ||
      'image/png' ||
      'application/pdf'
    ) {
      if (event) {
        this.babyImgArray.push(event.target.files[0]);
      }
      else {
        this.babyImgArray.push(captureImg);

      }
    }

  }

  previewImage(imgSrc: any) {
    this.imagePreview = true
    this.imgSrcForPreview = imgSrc
  }

  deletecapture(imgSrc: any) {
    const byteString = atob(imgSrc.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: 'image/png' });

    const deleteIMG = new File([blob], `captured-image${this.IndexforImage}.png`, { type: 'image/png' });
    this.babyImgArray.forEach((file, index) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const arrayBuffer = e.target.result;

        if (this.arrayBuffersEqual(arrayBuffer, ab)) {
          this.babyImgArray.splice(index, 1); // Remove the file from babyImgArray
        }
      };
      reader.readAsArrayBuffer(file);
    });


    this.capturedByCamera = this.capturedByCamera.filter(item => item !== imgSrc);
    this.imagePreview = false;
  }

  arrayBuffersEqual(buf1: ArrayBuffer, buf2: ArrayBuffer): boolean {
    if (buf1.byteLength !== buf2.byteLength) return false;
    const dv1 = new DataView(buf1);
    const dv2 = new DataView(buf2);
    for (let i = 0; i < buf1.byteLength; i++) {
      if (dv1.getUint8(i) !== dv2.getUint8(i)) return false;
    }
    return true;
  }


  closePreview() {
    this.imagePreview = false
  }
  growthMonitoringForm(formSubmitted) {
    this.threeYearsAndAbove.enable()
    //   const previousData = this.growthData?this.growthData:null
    //   let data = JSON.parse(JSON.stringify(formSubmitted.value))
    //   const newData = data
    //   const mergedObject = { ...newData };
    //   if (previousData) { 

    //     for (const [key, value] of Object.entries(previousData)) {
    //       if ((value !== null && key == 'health_json') && typeof value === 'object') {
    //       if (Object.keys(value).length > 0) {

    //         let a = newData.health_json;
    //         if (mergedObject.hasOwnProperty(key)) {
    //           mergedObject[key] = [];
    //          }
    //          let b =previousData.health_json
    //          if (previousData.hasOwnProperty(key)) {
    //           mergedObject[key] = [];
    //          }
    //          if(b){
    //           if (previousData.hasOwnProperty(key)) {
    //             b.forEach(element => {
    //               if(element != undefined){
    //                 mergedObject[key].push(element);
    //               }
    //           });
    //         }
    //          }
    //         if(a){
    //           if (mergedObject.hasOwnProperty(key)) {
    //             a.forEach(element => {
    //               if(element != undefined){
    //                 mergedObject[key].push(element);
    //               }          });

    //         }
    //         }


    //      }
    //       }

    //         if (value !== null && typeof value === 'object') {
    //             // Check if the value is an object

    //             if (!mergedObject.hasOwnProperty(key)) {
    //                 mergedObject[key] = {};
    //             }
    //             for (const [nestedKey, nestedValue] of Object.entries(value)) {

    //               if (
    //                     !mergedObject[key].hasOwnProperty(nestedKey) &&
    //                     nestedValue !== null &&
    //                     nestedValue !== undefined &&
    //                     typeof nestedValue === 'string' &&
    //                     nestedValue.trim() !== ''
    //                 ) {
    //                     mergedObject[key][nestedKey] = nestedValue;
    //                 }


    //             }
    //         } else {
    //             if (
    //                 !mergedObject.hasOwnProperty(key) &&
    //                 value !== null &&
    //                 value !== undefined &&
    //                 typeof value === 'string' &&
    //                 value.trim() !== ''
    //             ) {
    //                 mergedObject[key] = value;
    //             }
    //         }
    //     }
    // }

    // let stringifyData = JSON.stringify(this.threeYearsAndAbove)
    window.scrollTo(0, 0);
    if (this.threeYearsAndAbove.valid) {
      let jsonData = JSON.stringify(this.threeYearsAndAbove.value);
      // let dataObject = {
      //   baby_details_id: this.threeYearsAndAbove.value.baby_details_id,
      //   growth_monitoring_period_id: 4,
      //   development_milestones: jsonData,
      //   physical_assessment_details_json: null,
      // };

      let formData = new FormData;
      // formData.append('growth_id', '1')
      formData.append('baby_details_id', this.threeYearsAndAbove.value.baby_details_id)
      formData.append('growth_monitoring_period_id', '4')
      formData.append('development_milestones', jsonData)
      let images = JSON.stringify(this.threeYearsAndAbove.value.health_json);
      formData.append('physical_assessment_details_json', images)
      this.babyImgArray?.forEach((file) => {
        formData.append('media', file);
      });
      if (this.isedit == true) {
        const id = localStorage.getItem('editedOrderId')
        this.spinner.show();
        this.growth_service.editGrowthMonitorForm(formData, id).then(
          (res: any) => {
            this.spinner.hide()

            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: res?.message,
            });

            setTimeout(() => {
              this.router.navigateByUrl('/portal/first-screen')
            }, 400);
            this.isedit = false;
            // this.spinner.hide();
          },
          (error: any) => {
            this.spinner.hide()

            // Handle any errors that occurred during the API call
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error?.message,
            });
          }
        );
      } else {
        this.spinner.show();
        this.growth_service
          .postGrowthMonitorForm(formData)
          .then((res: any) => {
            this.spinner.hide()

            this.messageService.add({ severity: 'success', summary: 'Success', detail: res?.message });
            setTimeout(() => {
              this.router.navigateByUrl('/portal/first-screen')
            }, 400);
            // this.spinner.hide();
          },
            (error: any) => {
              this.spinner.hide()

              // Handle any errors that occurred during the API call
              this.messageService.add({ severity: 'error', summary: 'Error', detail: error?.message });
            }
          );
      }
    } else {
      this.spinner.hide()

      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Fill All the Details!',
      });
    }
    this.threeYearsAndAbove.reset();
  }
  handleInput(event: any, value: string) {
    if (value == 'enjoyandplay') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.enjoyandplay_description.enable();
      } else {
        this.threeYearsAndAbove.controls.enjoyandplay_description.disable();
        this.threeYearsAndAbove.controls.enjoyandplay_description.setValue(null);
      }
    } else if (value == 'enduranceandstrength') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.enduranceandstrength_description.enable();
      } else {
        this.threeYearsAndAbove.controls.enduranceandstrength_description.disable();
        this.threeYearsAndAbove.controls.enduranceandstrength_description.setValue(null);
      }
    } else if (value == 'coordinateandexplore') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.coordinateandexplore_description.enable();
      } else {
        this.threeYearsAndAbove.controls.coordinateandexplore_description.disable();
        this.threeYearsAndAbove.controls.coordinateandexplore_description.setValue(null);
      }
    } else if (value == 'healtoewalk') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.healtoewalk_description.enable();
      } else {
        this.threeYearsAndAbove.controls.healtoewalk_description.disable();
        this.threeYearsAndAbove.controls.healtoewalk_description.setValue(null);
      }
    } else if (value == 'balancetocatch') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.balancetocatch_description.enable();
      } else {
        this.threeYearsAndAbove.controls.balancetocatch_description.disable();
        this.threeYearsAndAbove.controls.balancetocatch_description.setValue(null);
      }
    } else if (value == 'balanceonunevensurfaces') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.balanceonunevensurfaces_description.enable();
      } else {
        this.threeYearsAndAbove.controls.balanceonunevensurfaces_description.disable();
        this.threeYearsAndAbove.controls.balanceonunevensurfaces_description.setValue(null);
      }
    } else if (value == 'walkinnewroomnobumping') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.walkinnewroomnobumping_description.enable();
      } else {
        this.threeYearsAndAbove.controls.walkinnewroomnobumping_description.disable();
        this.threeYearsAndAbove.controls.walkinnewroomnobumping_description.setValue(null);
      }
    } else if (value == 'leansoverandsitsslumped') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.leansoverandsitsslumped_description.enable();
      } else {
        this.threeYearsAndAbove.controls.leansoverandsitsslumped_description.disable();
        this.threeYearsAndAbove.controls.leansoverandsitsslumped_description.setValue(null);
      }
    } else if (value == 'throwandcatchwithbalance') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.throwandcatchwithbalance_description.enable();
      } else {
        this.threeYearsAndAbove.controls.throwandcatchwithbalance_description.disable();
        this.threeYearsAndAbove.controls.throwandcatchwithbalance_description.setValue(null);
      }
    } else if (value == 'bothhandstoplay') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.bothhandstoplay_description.enable();
      } else {
        this.threeYearsAndAbove.controls.bothhandstoplay_description.disable();
        this.threeYearsAndAbove.controls.bothhandstoplay_description.setValue(null);
      }
    } else if (value == 'partsingamesandactivities') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.partsingamesandactivities_description.enable();
      } else {
        this.threeYearsAndAbove.controls.partsingamesandactivities_description.disable();
        this.threeYearsAndAbove.controls.partsingamesandactivities_description.setValue(null);
      }
    } else if (value == 'colorandshapes') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.colorandshapes_description.enable();
      } else {
        this.threeYearsAndAbove.controls.colorandshapes_description.disable();
        this.threeYearsAndAbove.controls.colorandshapes_description.setValue(null);
      }
    } else if (value == 'usesforcewhenplaying') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.usesforcewhenplaying_description.enable();
      } else {
        this.threeYearsAndAbove.controls.usesforcewhenplaying_description.disable();
        this.threeYearsAndAbove.controls.usesforcewhenplaying_description.setValue(null);
      }
    } else if (value == 'goodsittingposture') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.goodsittingposture_description.enable();
      } else {
        this.threeYearsAndAbove.controls.goodsittingposture_description.disable();
        this.threeYearsAndAbove.controls.goodsittingposture_description.setValue(null);
      }
    } else if (value == 'useutensilsforfood') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.useutensilsforfood_description.enable();
      } else {
        this.threeYearsAndAbove.controls.useutensilsforfood_description.disable();
        this.threeYearsAndAbove.controls.useutensilsforfood_description.setValue(null);
      }
    } else if (value == 'establishedsleep') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.establishedsleep_description.enable();
      } else {
        this.threeYearsAndAbove.controls.establishedsleep_description.disable();
        this.threeYearsAndAbove.controls.establishedsleep_description.setValue(null);
      }
    } else if (value == 'selfcalmfallasleep') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.selfcalmfallasleep_description.enable();
      } else {
        this.threeYearsAndAbove.controls.selfcalmfallasleep_description.disable();
        this.threeYearsAndAbove.controls.selfcalmfallasleep_description.setValue(null);
      }
    } else if (value == 'wearnewclothes') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.wearnewclothes_description.enable();
      } else {
        this.threeYearsAndAbove.controls.wearnewclothes_description.disable();
        this.threeYearsAndAbove.controls.wearnewclothes_description.setValue(null);
      }
    } else if (value == 'appropriatebitesoffood') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.appropriatebitesoffood_description.enable();
      } else {
        this.threeYearsAndAbove.controls.appropriatebitesoffood_description.disable();
        this.threeYearsAndAbove.controls.appropriatebitesoffood_description.setValue(null);
      }
    } else if (value == 'toleratehairandnailcut') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.toleratehairandnailcut_description.enable();
      } else {
        this.threeYearsAndAbove.controls.toleratehairandnailcut_description.disable();
        this.threeYearsAndAbove.controls.toleratehairandnailcut_description.setValue(null);
      }
    } else if (value == 'adaptnewroutine') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.adaptnewroutine_description.enable();
      } else {
        this.threeYearsAndAbove.controls.adaptnewroutine_description.disable();
        this.threeYearsAndAbove.controls.adaptnewroutine_description.setValue(null);
      }
    } else if (value == 'takesbathandshower') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.takesbathandshower_description.enable();
      } else {
        this.threeYearsAndAbove.controls.takesbathandshower_description.disable();
        this.threeYearsAndAbove.controls.takesbathandshower_description.setValue(null);
      }
    } else if (value == 'eatsvarietyofdiet') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.eatsvarietyofdiet_description.enable();
      } else {
        this.threeYearsAndAbove.controls.eatsvarietyofdiet_description.disable();
        this.threeYearsAndAbove.controls.eatsvarietyofdiet_description.setValue(null);
      }
    } else if (value == 'drinkswithoutdribble') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.drinkswithoutdribble_description.enable();
      } else {
        this.threeYearsAndAbove.controls.drinkswithoutdribble_description.disable();
        this.threeYearsAndAbove.controls.drinkswithoutdribble_description.setValue(null);
      }
    } else if (value == 'notactiveinfamily') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.notactiveinfamily_description.enable();
      } else {
        this.threeYearsAndAbove.controls.notactiveinfamily_description.disable();
        this.threeYearsAndAbove.controls.notactiveinfamily_description.setValue(null);
      }
    } else if (value == 'completemultiplesteptasks') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.completemultiplesteptasks_description.enable();
      } else {
        this.threeYearsAndAbove.controls.completemultiplesteptasks_description.disable();
        this.threeYearsAndAbove.controls.completemultiplesteptasks_description.setValue(null);
      }
    } else if (value == 'wakesrestedandready') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.wakesrestedandready_description.enable();
      } else {
        this.threeYearsAndAbove.controls.wakesrestedandready_description.disable();
        this.threeYearsAndAbove.controls.wakesrestedandready_description.setValue(null);
      }
    } else if (value == 'cancount') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.cancount_description.enable();
      } else {
        this.threeYearsAndAbove.controls.cancount_description.disable();
        this.threeYearsAndAbove.controls.cancount_description.setValue(null);
      }
    } else if (value == 'knowshapes') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.knowshapes_description.enable();
      } else {
        this.threeYearsAndAbove.controls.knowshapes_description.disable();
        this.threeYearsAndAbove.controls.knowshapes_description.setValue(null);
      }
    } else if (value == 'developsfriendships') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.developsfriendships_description.enable();
      } else {
        this.threeYearsAndAbove.controls.developsfriendships_description.disable();
        this.threeYearsAndAbove.controls.developsfriendships_description.setValue(null);
      }
    } else if (value == 'expressesemotions') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.expressesemotions_description.enable();
      } else {
        this.threeYearsAndAbove.controls.expressesemotions_description.disable();
        this.threeYearsAndAbove.controls.expressesemotions_description.setValue(null);
      }
    } else if (value == 'followrules') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.followrules_description.enable();
      } else {
        this.threeYearsAndAbove.controls.followrules_description.disable();
        this.threeYearsAndAbove.controls.followrules_description.setValue(null);
      }
    } else if (value == 'payattention') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.payattention_description.enable();
      } else {
        this.threeYearsAndAbove.controls.payattention_description.disable();
        this.threeYearsAndAbove.controls.payattention_description.setValue(null);
      }
    } else if (value == 'vision') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.vision_description.enable();
      } else {
        this.threeYearsAndAbove.controls.vision_description.disable();
        this.threeYearsAndAbove.controls.vision_description.setValue(null);
      }
    } else if (value == 'writenumbersandletters') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.writenumbersandletters_description.enable();
      } else {
        this.threeYearsAndAbove.controls.writenumbersandletters_description.disable();
        this.threeYearsAndAbove.controls.writenumbersandletters_description.setValue(null);
      }
    } else if (value == 'recogniseothersperspective') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.recogniseothersperspective_description.enable();
      } else {
        this.threeYearsAndAbove.controls.recogniseothersperspective_description.disable();
        this.threeYearsAndAbove.controls.recogniseothersperspective_description.setValue(null);
      }
    } else if (value == 'leftright') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.leftright_description.enable();
      } else {
        this.threeYearsAndAbove.controls.leftright_description.disable();
        this.threeYearsAndAbove.controls.leftright_description.setValue(null);
      }
    } else if (value == 'reasonandargue') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.reasonandargue_description.enable();
      } else {
        this.threeYearsAndAbove.controls.reasonandargue_description.disable();
        this.threeYearsAndAbove.controls.reasonandargue_description.setValue(null);
      }
    } else if (value == 'categoriseobjects') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.categoriseobjects_description.enable();
      } else {
        this.threeYearsAndAbove.controls.categoriseobjects_description.disable();
        this.threeYearsAndAbove.controls.categoriseobjects_description.setValue(null);
      }
    } else if (value == 'yesterdaytodaytomorrow') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.yesterdaytodaytomorrow_description.enable();
      } else {
        this.threeYearsAndAbove.controls.yesterdaytodaytomorrow_description.disable();
        this.threeYearsAndAbove.controls.yesterdaytodaytomorrow_description.setValue(null);
      }
    } else if (value == 'copycomplexshapes') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.copycomplexshapes_description.enable();
      } else {
        this.threeYearsAndAbove.controls.copycomplexshapes_description.disable();
        this.threeYearsAndAbove.controls.copycomplexshapes_description.setValue(null);
      }
    } else if (value == 'sayssimplewords') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.sayssimplewords_description.enable();
      } else {
        this.threeYearsAndAbove.controls.sayssimplewords_description.disable();
        this.threeYearsAndAbove.controls.sayssimplewords_description.setValue(null);
      }
    } else if (value == 'canattendclass') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.canattendclass_description.enable();
      } else {
        this.threeYearsAndAbove.controls.canattendclass_description.disable();
        this.threeYearsAndAbove.controls.canattendclass_description.setValue(null);
      }
    } else if (value == 'formsnumbersandletters') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.formsnumbersandletters_description.enable();
      } else {
        this.threeYearsAndAbove.controls.formsnumbersandletters_description.disable();
        this.threeYearsAndAbove.controls.formsnumbersandletters_description.setValue(null);
      }
    } else if (value == 'recogniseothersperspective') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.recogniseothersperspective_description.enable();
      } else {
        this.threeYearsAndAbove.controls.recogniseothersperspective_description.disable();
        this.threeYearsAndAbove.controls.recogniseothersperspective_description.setValue(null);
      }
    } else if (value == 'longattention') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.longattention_description.enable();
      } else {
        this.threeYearsAndAbove.controls.longattention_description.disable();
        this.threeYearsAndAbove.controls.longattention_description.setValue(null);
      }
    } else if (value == 'onemoreresponsibility') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.onemoreresponsibility_description.enable();
      } else {
        this.threeYearsAndAbove.controls.onemoreresponsibility_description.disable();
        this.threeYearsAndAbove.controls.onemoreresponsibility_description.setValue(null);
      }
    } else if (value == 'fractionandspace') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.fractionandspace_description.enable();
      } else {
        this.threeYearsAndAbove.controls.fractionandspace_description.disable();
        this.threeYearsAndAbove.controls.fractionandspace_description.setValue(null);
      }
    } else if (value == 'understandmoney') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.understandmoney_description.enable();
      } else {
        this.threeYearsAndAbove.controls.understandmoney_description.disable();
        this.threeYearsAndAbove.controls.understandmoney_description.setValue(null);
      }
    } else if (value == 'telltime') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.telltime_description.enable();
      } else {
        this.threeYearsAndAbove.controls.telltime_description.disable();
        this.threeYearsAndAbove.controls.telltime_description.setValue(null);
      }
    } else if (value == 'monthsdaysinorder') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.monthsdaysinorder_description.enable();
      } else {
        this.threeYearsAndAbove.controls.monthsdaysinorder_description.disable();
        this.threeYearsAndAbove.controls.monthsdaysinorder_description.setValue(null);
      }
    } else if (value == 'enjoyreadingbook') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.enjoyreadingbook_description.enable();
      } else {
        this.threeYearsAndAbove.controls.enjoyreadingbook_description.disable();
        this.threeYearsAndAbove.controls.enjoyreadingbook_description.setValue(null);
      }
    } else if (value == 'understandscomplexlanguage') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.understandscomplexlanguage_description.enable();
      } else {
        this.threeYearsAndAbove.controls.understandscomplexlanguage_description.disable();
        this.threeYearsAndAbove.controls.understandscomplexlanguage_description.setValue(null);
      }
    } else if (value == 'findnewinfo') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.findnewinfo_description.enable();
      } else {
        this.threeYearsAndAbove.controls.findnewinfo_description.disable();
        this.threeYearsAndAbove.controls.findnewinfo_description.setValue(null);
      }
    }
    else if (value == 'takingpart') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.takingpart_description.enable();
      } else {
        this.threeYearsAndAbove.controls.takingpart_description.disable();
        this.threeYearsAndAbove.controls.takingpart_description.setValue(null);
      }
    } else if (value == 'recogniseownname') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.recogniseownname_description.enable();
      } else {
        this.threeYearsAndAbove.controls.recogniseownname_description.disable();
        this.threeYearsAndAbove.controls.recogniseownname_description.setValue(null);
      }
    } else if (value == 'knowletternames') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.knowletternames_description.enable();
      } else {
        this.threeYearsAndAbove.controls.knowletternames_description.disable();
        this.threeYearsAndAbove.controls.knowletternames_description.setValue(null);
      }
    } else if (value == 'recognisewordsoutside') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.recognisewordsoutside_description.enable();
      } else {
        this.threeYearsAndAbove.controls.recognisewordsoutside_description.disable();
        this.threeYearsAndAbove.controls.recognisewordsoutside_description.setValue(null);
      }
    } else if (value == 'attemptwritingtherename') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.attemptwritingtherename_description.enable();
      } else {
        this.threeYearsAndAbove.controls.attemptwritingtherename_description.disable();
        this.threeYearsAndAbove.controls.attemptwritingtherename_description.setValue(null);
      }
    }
    // else if (value == 'attemptwritingtherename') {

    //   if (event.target.value == 'Yes' || event.target.value == 'No') {
    //     this.threeYearsAndAbove.controls.attemptwritingtherename_description.enable();
    //   } else {
    //     this.threeYearsAndAbove.controls.attemptwritingtherename_description.disable();
    //     this.threeYearsAndAbove.controls.attemptwritingtherename_description.setValue(null);
    //   }
    // }
    else if (value == 'talkingforsolutions') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.talkingforsolutions_description.enable();
      } else {
        this.threeYearsAndAbove.controls.talkingforsolutions_description.disable();
        this.threeYearsAndAbove.controls.talkingforsolutions_description.setValue(null);
      }
    } else if (value == 'talkaboutdidanddos') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.talkaboutdidanddos_description.enable();
      } else {
        this.threeYearsAndAbove.controls.talkaboutdidanddos_description.disable();
        this.threeYearsAndAbove.controls.talkaboutdidanddos_description.setValue(null);
      }
    } else if (value == 'unfamiliarpeopleunderstands') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.unfamiliarpeopleunderstands_description.enable();
      } else {
        this.threeYearsAndAbove.controls.unfamiliarpeopleunderstands_description.disable();
        this.threeYearsAndAbove.controls.unfamiliarpeopleunderstands_description.setValue(null);
      }
    } else if (value == 'eatssameasfamily') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.eatssameasfamily_description.enable();
      } else {
        this.threeYearsAndAbove.controls.eatssameasfamily_description.disable();
        this.threeYearsAndAbove.controls.eatssameasfamily_description.setValue(null);
      }
    } else if (value == 'feedswellutensils') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.feedswellutensils_description.enable();
      } else {
        this.threeYearsAndAbove.controls.feedswellutensils_description.disable();
        this.threeYearsAndAbove.controls.feedswellutensils_description.setValue(null);
      }
    } else if (value == 'likedislikesfood') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.likedislikesfood_description.enable();
      } else {
        this.threeYearsAndAbove.controls.likedislikesfood_description.disable();
        this.threeYearsAndAbove.controls.likedislikesfood_description.setValue(null);
      }
    } else if (value == 'pickyeater') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.pickyeater_description.enable();
      } else {
        this.threeYearsAndAbove.controls.pickyeater_description.disable();
        this.threeYearsAndAbove.controls.pickyeater_description.setValue(null);
      }
    } else if (value == 'wipeshandsnmouth') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.wipeshandsnmouth_description.enable();
      } else {
        this.threeYearsAndAbove.controls.wipeshandsnmouth_description.disable();
        this.threeYearsAndAbove.controls.wipeshandsnmouth_description.setValue(null);
      }
    } else if (value == 'servesself') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.servesself_description.enable();
      } else {
        this.threeYearsAndAbove.controls.servesself_description.disable();
        this.threeYearsAndAbove.controls.servesself_description.setValue(null);
      }
    } else if (value == 'poursliquid') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.poursliquid_description.enable();
      } else {
        this.threeYearsAndAbove.controls.poursliquid_description.disable();
        this.threeYearsAndAbove.controls.poursliquid_description.setValue(null);
      }
    } else if (value == 'eyecontact') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.eyecontact_description.enable();
      } else {
        this.threeYearsAndAbove.controls.eyecontact_description.disable();
        this.threeYearsAndAbove.controls.eyecontact_description.setValue(null);
      }
    } else if (value == 'playingsamllgroup') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.playingsamllgroup_description.enable();
      } else {
        this.threeYearsAndAbove.controls.playingsamllgroup_description.disable();
        this.threeYearsAndAbove.controls.playingsamllgroup_description.setValue(null);
      }
    } else if (value == 'initiateplay') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.initiateplay_description.enable();
      } else {
        this.threeYearsAndAbove.controls.initiateplay_description.disable();
        this.threeYearsAndAbove.controls.initiateplay_description.setValue(null);
      }
    } else if (value == 'turnsinresponse') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.turnsinresponse_description.enable();
      } else {
        this.threeYearsAndAbove.controls.turnsinresponse_description.disable();
        this.threeYearsAndAbove.controls.turnsinresponse_description.setValue(null);
      }
    } else if (value == 'exploringnew') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.exploringnew_description.enable();
      } else {
        this.threeYearsAndAbove.controls.exploringnew_description.disable();
        this.threeYearsAndAbove.controls.exploringnew_description.setValue(null);
      }
    } else if (value == 'newsocialplay') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.newsocialplay_description.enable();
      } else {
        this.threeYearsAndAbove.controls.newsocialplay_description.disable();
        this.threeYearsAndAbove.controls.newsocialplay_description.setValue(null);
      }
    } else if (value == 'playvarietytoys') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.playvarietytoys_description.enable();
      } else {
        this.threeYearsAndAbove.controls.playvarietytoys_description.disable();
        this.threeYearsAndAbove.controls.playvarietytoys_description.setValue(null);
      }
    } else if (value == 'aware') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.aware_description.enable();
      } else {
        this.threeYearsAndAbove.controls.aware_description.disable();
        this.threeYearsAndAbove.controls.aware_description.setValue(null);
      }
    } else if (value == 'craftswithglue') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.craftswithglue_description.enable();
      } else {
        this.threeYearsAndAbove.controls.craftswithglue_description.disable();
        this.threeYearsAndAbove.controls.craftswithglue_description.setValue(null);
      }
    } else if (value == 'roughsafeplay') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.roughsafeplay_description.enable();
      } else {
        this.threeYearsAndAbove.controls.roughsafeplay_description.disable();
        this.threeYearsAndAbove.controls.roughsafeplay_description.setValue(null);
      }
    } else if (value == 'swinging') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.swinging_description.enable();
      } else {
        this.threeYearsAndAbove.controls.swinging_description.disable();
        this.threeYearsAndAbove.controls.swinging_description.setValue(null);
      }
    } else if (value == 'newtoysplay') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.newtoysplay_description.enable();
      } else {
        this.threeYearsAndAbove.controls.newtoysplay_description.disable();
        this.threeYearsAndAbove.controls.newtoysplay_description.setValue(null);
      }
    } else if (value == 'locatepointing') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.locatepointing_description.enable();
      } else {
        this.threeYearsAndAbove.controls.locatepointing_description.disable();
        this.threeYearsAndAbove.controls.locatepointing_description.setValue(null);
      }
    } else if (value == 'enjoysitting') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.enjoysitting_description.enable();
      } else {
        this.threeYearsAndAbove.controls.enjoysitting_description.disable();
        this.threeYearsAndAbove.controls.enjoysitting_description.setValue(null);
      }
    } else if (value == 'playtoyforawhile') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.playtoyforawhile_description.enable();
      } else {
        this.threeYearsAndAbove.controls.playtoyforawhile_description.disable();
        this.threeYearsAndAbove.controls.playtoyforawhile_description.setValue(null);
      }
    } else if (value == 'happywhennothungry') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.happywhennothungry_description.enable();
      } else {
        this.threeYearsAndAbove.controls.happywhennothungry_description.disable();
        this.threeYearsAndAbove.controls.happywhennothungry_description.setValue(null);
      }
    } else if (value == 'accustomedsounds') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.accustomedsounds_description.enable();
      } else {
        this.threeYearsAndAbove.controls.accustomedsounds_description.disable();
        this.threeYearsAndAbove.controls.accustomedsounds_description.setValue(null);
      }
    } else if (value == 'reliablesleeping') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.reliablesleeping_description.enable();
      } else {
        this.threeYearsAndAbove.controls.reliablesleeping_description.disable();
        this.threeYearsAndAbove.controls.reliablesleeping_description.setValue(null);
      }
    } else if (value == 'widevariety') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.widevariety_description.enable();
      } else {
        this.threeYearsAndAbove.controls.widevariety_description.disable();
        this.threeYearsAndAbove.controls.widevariety_description.setValue(null);
      }
    } else if (value == 'crieswhenhurt') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.crieswhenhurt_description.enable();
      } else {
        this.threeYearsAndAbove.controls.crieswhenhurt_description.disable();
        this.threeYearsAndAbove.controls.crieswhenhurt_description.setValue(null);
      }
    } else if (value == 'selfcalmafterupset') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.selfcalmafterupset_description.enable();
      } else {
        this.threeYearsAndAbove.controls.selfcalmafterupset_description.disable();
        this.threeYearsAndAbove.controls.selfcalmafterupset_description.setValue(null);
      }
    } else if (value == 'transitiontonewenv') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.transitiontonewenv_description.enable();
      } else {
        this.threeYearsAndAbove.controls.transitiontonewenv_description.disable();
        this.threeYearsAndAbove.controls.transitiontonewenv_description.setValue(null);
      }
    } else if (value == 'nodistractionbysound') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.nodistractionbysound_description.enable();
      } else {
        this.threeYearsAndAbove.controls.nodistractionbysound_description.disable();
        this.threeYearsAndAbove.controls.nodistractionbysound_description.setValue(null);
      }
    } else if (value == 'copeunexpectedchange') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.threeYearsAndAbove.controls.copeunexpectedchange_description.enable();
      } else {
        this.threeYearsAndAbove.controls.copeunexpectedchange_description.disable();
        this.threeYearsAndAbove.controls.copeunexpectedchange_description.setValue(null);
      }
    }
  }
  gethealthFormArray() {
    return this.threeYearsAndAbove.get(
      'health_json'
    ) as FormArray;
  }
  addVitaminObject(string: any, index: any) {
    let vitaminArray = this.gethealthFormArray();
    if (string == 'add') {
      let newVitaminArray = new FormGroup({
        height: new FormControl(null),
        time: new FormControl(null),
        weight: new FormControl(null),
      });

      // let formatedTime =  moment(newTime).format('HH:mm:ss');
      vitaminArray.insert(index + 1, newVitaminArray);
    } else {
      vitaminArray.removeAt(index);
    }
  }


  disableForm() {
    const formData = this.threeYearsAndAbove.value;
    for (let key in formData) {
      if (formData[key] !== null && formData[key] !== undefined) {
        if (typeof formData[key] === 'object') {
          for (let nestedKey in formData[key]) {
            if (formData[key][nestedKey] !== null && formData[key][nestedKey] !== undefined) {
              const control = this.threeYearsAndAbove.get(`${key}.${nestedKey}`);
              if (control) {
                control.disable();
              }
            }
          }
        } else {
          const control = this.threeYearsAndAbove.get(key);
          if (control) {
            control.disable();
          }
        }
      }
    }
  }


  getImageArray() {
    return this.threeYearsAndAbove.get(
      'baby_images'
    ) as FormArray;
  }

  addBabyImg(string: any, index: any) {
    let imgArray = this.getImageArray();
    if (string == 'add') {
      let newbabyImg = new FormGroup({
        // upload_photo: new FormControl(''),

      });
      // let formatedTime =  moment(newTime).format('HH:mm:ss');
      imgArray.insert(index + 1, newbabyImg);
    } else {
      imgArray.removeAt(index);
    }
  }

  // convertKgToPounds() {
  //   if (this.weightConverted) return; 

  //   // Get the FormArray from the form group
  //   const healthFormArray = this.threeYearsAndAbove.get('health_json') as FormArray;
  
  //   // Loop through each form group in the FormArray
  //   healthFormArray.controls.forEach((control) => {
  //     // Get the current weight value
  //     const weightInKg = control.get('weight').value;
  
  //     // Convert weight to pounds if it's a valid number
  //     if (weightInKg && !isNaN(weightInKg)) {
  //       const weightInPounds = (parseFloat(weightInKg) * 2.20462).toFixed(2);
  //       control.get('weight').setValue(weightInPounds);
  //     }
  //   });
  // this.weightConverted = true; //

  // }
  
  // convertHeightToCm(): void {
  //   if (this.heightConverted) return;
  //   const healthFormArray = this.threeYearsAndAbove.get('health_json') as FormArray;
  
  //   // Loop through each form group in the FormArray
  //   healthFormArray.controls.forEach((control) => {
  //     // Get the current weight value
  //     const heightIncm = control.get('height').value;

  //   if (heightIncm && !isNaN(heightIncm)) {
  //     const heightInInches = parseFloat(heightIncm);
  //     if (!isNaN(heightInInches)) {
  //       const heightInCm = heightInInches * 2.54;
  //       control.patchValue({ height: heightInCm.toFixed(2) });
  //     }
  //   }
  // });
  // this.heightConverted=true;
  // }
}
