import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService, ConfirmationService } from 'primeng/api';
import { TableService } from 'primeng/table';
import { MasterService } from 'src/app/services/master.service';

@Component({
  selector: 'app-stool-master',
  templateUrl: './stool-master.component.html',
  styleUrls: ['./stool-master.component.scss'],
  providers: [MessageService, ConfirmationService, TableService],
})
export class StoolMasterComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Stool',
      subTitle: 'Dashboard',
    },
  ];
  filterval: any;
  editStool: any = [];
  loading: any;
  member: any;
  tooltipText: string = 'Nanny Care Plan';

  stoolFormData: any = {};

  nannyPlans: FormGroup;

  stoolData: any = [];

  constructor(
    private masterService: MasterService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit() {
    this.getAllData();
  }

  formSubmit(form: NgForm) {
    this.spinner.show()
    console.log(form.valid, form.value);
    if (form.valid) {
      this.masterService
        .postStoolFormData(form.value)
        .then((res: any) => {
          this.spinner.hide()
          console.log(res.message);
          this.messageService.add({
            severity: 'success',
            detail: res?.message,
          });
          form.resetForm();
          this.stoolFormData = {};
          this.getAllData();
        })
        .catch((error: HttpErrorResponse) => {
          this.spinner.hide()

          console.log(error);
        });
    }
  }

  getAllData() {
    this.spinner.show()
    this.masterService.getStoolValues().then((res: any) => {
      this.spinner.hide()

      console.log('response', res);
      this.stoolData = res.data;
    });
  }
  /********************************************************************
                Edit Care Plan
********************************************************************/

  editStoolMaster(event: any) {
    this.editStool[event.id] = { ...event };
    console.log('Edit', this.editStool);
  }

  onRowEditSave(stool: any) {
    this.spinner.show()
    console.log('onRowEditSave', stool);
    this.loading = true;
    if (stool.name_description != '' && stool.name_description != null) {
      this.masterService
        .editStoolFormData(stool, stool.id)
        .subscribe((data: any) => {
          this.spinner.hide()

          this.messageService.add({
            severity: 'success',
            detail: data?.message,
          });
          this.loading = false;
        });
    } else {
      this.spinner.hide()

      this.messageService.add({
        severity: 'error',
        detail: 'Please Fill All the Details!',
      });
      this.loading = false;
    }
  }

  /********************************************************************
              Delete Care Plan
********************************************************************/

  deleteMaster(stool: any) {
    this.spinner.show()
    this.confirmationService.confirm({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.masterService
          .deleteStoolFormData(stool.id, { is_deleted: 1 })
          .subscribe((data: any) => {
            this.getAllData();
            this.spinner.hide()
            this.messageService.add({
              severity: 'success',
              detail: data?.message,
            });
          });
      },
    });
    this.spinner.hide()

  }
  reset(dt1:any) {
    dt1.reset();
    this.filterval = '';
  }

}
