<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

    <!-- Start -->
    <div class="invoice-area">
        <div class="invoice-header d-flex justify-content-between">
            <div class="invoice-left-text">
                <h3 class="mb-0">Milin Admin</h3>
                <p class="mt-2 mb-0">M/2750, <br>Quadra Street, <br>Victoria, <br>Canada.</p>
            </div>
            <div class="invoice-right-text">
                <h3 class="mb-0 text-uppercase">Invoice</h3>
            </div>
        </div>
        <div class="invoice-middle">
            <div class="row">
                <div class="col-lg-3">
                    <div class="text">
                        <h4 class="mb-2">Bill To</h4>
                        <span class="d-block mb-1">Jessie M Home</span>
                        <span class="d-block mb-1">2019 Redbud Drive</span>
                        <span class="d-block">New York, NY 10011</span>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="text">
                        <h4 class="mb-2">Ship To</h4>
                        <span class="d-block mb-1">Jessie M Home</span>
                        <span class="d-block mb-1">2019 Redbud Drive</span>
                        <span class="d-block">New York, NY 10011</span>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="text text-end">
                        <h5>Invoice # <sub>Int-001</sub></h5>
                        <h5>Invoice Date # <sub>11/12/2019</sub></h5>
                        <h5>P.O # <sub>2412/2019</sub></h5>
                        <h5 class="mb-0">Due Date # <sub>26/12/2019</sub></h5>
                    </div>
                </div>
            </div>
        </div>

        <div class="invoice-table table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Description</th>
                        <th>Unit Price</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>01</td>
                        <td>Apple iPhone XR (64GB)</td>
                        <td class="text-end">$100.00</td>
                        <td class="text-end">$100.00</td>
                    </tr>
                    <tr>
                        <td>01</td>
                        <td>Apple iPhone XR Black</td>
                        <td class="text-end">$25.00</td>
                        <td class="text-end">$50.00</td>
                    </tr>
                    <tr>
                        <td>01</td>
                        <td>HeadPhone</td>
                        <td class="text-end">$15.00</td>
                        <td class="text-end">$45.00</td>
                    </tr>
                    <tr>
                        <td>01</td>
                        <td>HeadPhone</td>
                        <td class="text-end">$15.00</td>
                        <td class="text-end">$45.00</td>
                    </tr>
                    <tr>
                        <td class="text-end" colspan="3"><strong>Subtotal</strong></td>
                        <td class="text-end">$195.00</td>
                    </tr>
                    <tr>
                        <td class="text-end" colspan="3"><strong>Sales Tax 5.0%</strong></td>
                        <td class="text-end">$9.75</td>
                    </tr>
                    <tr>
                        <td class="text-end total" colspan="3"><strong>Total</strong></td>
                        <td class="text-end total-price"><strong>$204.75</strong></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="invoice-btn-box text-end">
            <a routerLink="/dashboard-invoice" class="default-btn"><i class='bx bx-printer'></i> Print</a>
        </div>
    </div>
    <!-- End -->

    <div class="flex-grow-1"></div>

    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->