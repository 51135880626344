import { Component, OnInit } from '@angular/core';
import { MaikaListService } from 'src/app/services/maika-list.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-zero-three-growth-monitor-list',
  templateUrl: './zero-three-growth-monitor-list.component.html',
  styleUrls: ['./zero-three-growth-monitor-list.component.scss']
})
export class ZeroThreeGrowthMonitorListComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Zero to Three Months',
      subTitle: 'Dashboard',
    },
  ];
  accordionOpenStates: boolean[] = [];
  allData: any = [];
  babyData: any = {};
  detail: any = [];
  health: any = [];
  filterVal: string;
  displayMaximizable: boolean = false;
  filteredPDFData: any[];
  pdfData: any[];
  exportColumns: any = [];
  height: any = [];
  cols: any;
  constructor(private nanny_task_service: MaikaListService, private router: Router, private spinner: NgxSpinnerService,     private messageService: MessageService,
    ) { }
  // fn to get the data from backend
  getAllData() {
    // ;
    // this.spinner.show();
    this.nanny_task_service
      .getMonitorZeroToThreeMonthsList()
      .subscribe((res: any) => {
        this.allData = res?.data;
        // Initialize accordionOpenStates with false values
        this.accordionOpenStates = new Array(this.allData.length).fill(false);
        // this.spinner.hide();
      },(error: any) => {
        this.spinner.hide()
        // Handle any errors that occurred during the API call
       
      });
  }

  ngOnInit(): void {
    this.getAllData();
    localStorage.removeItem('editedOrderId');
    localStorage.removeItem('create');
    this.cols = [
      { field: 'sr_no', header: 'Sr No' },
      { field: 'name', header: 'Name' },
      { field: 'date_of_birth', header: 'Date of Birth' },
      { field: 'days', header: 'Age' },
      { field: 'months', header: 'Months' },
    ];
    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
  }

  getData(id: any) {
    this.allData?.map((res: any) => {
      if (res.baby_id == id) {
        this.babyData = res;
        // this.babyData?.development_milestones.map((res: any) => {
        //   let data = JSON.parse(JSON.parse(res?.development_milestone))
        //   this.parsed.push(data);
        // });
      }
    });
  }

  details: any = [];
  showMaximizableDialog(id: any) {
    this.detail = [];
    this.babyData?.development_milestones?.map((res: any) => {
      if (res.id == id) {
        this.detail = (JSON.parse(res?.development_milestone))
        this.health = this.detail.health_json;

      }
    });
    this.displayMaximizable = true;
  }
  editOnCLick(id: any) {
    localStorage.setItem('editedOrderId', id);
    this.router.navigateByUrl('/portal/zero-to-three-month-growth-monitoring');
  }
  /* -------------------------------------------------------------------------- */
  /*                        // reset table data function                        */
  /* -------------------------------------------------------------------------- */
  reset(dt) {
    dt.reset();
    this.filterVal = '';
  }
  onPDFExport() {
    this.filteredPDFData = [];
    this.pdfData = [];
    this.filteredPDFData = this.allData;

    for (let i = 0; i < this.filteredPDFData.length; i++) {
      this.pdfData.push({
        sr_no: i + 1,
        name: this.filteredPDFData[i]?.name ?? '-',
        date_of_birth: this.filteredPDFData[i]?.date_of_birth ?? '-',
        days: this.filteredPDFData[i]?.days ?? '-',
        months: this.filteredPDFData[i]?.months ?? '-',
      });
    }

    this.nanny_task_service.exportPdf(
      this.pdfData,
      this.exportColumns,
      'zeo-to-three-month.pdf'
    );
  }
}
