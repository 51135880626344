import { Component, OnInit } from '@angular/core';
import { MaikaListService } from 'src/app/services/maika-list.service';
import { DialogModule } from 'primeng/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-four-months-to-one-year-list',
  templateUrl: './four-months-to-one-year-list.component.html',
  styleUrls: ['./four-months-to-one-year-list.component.scss'],
})
export class FourMonthsToOneYearListComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Four Months to One Year',
      subTitle: 'Dashboard',
    },
  ];
  play: any ={};
  feeding: any = {};
  self_exp: any = {};
  communication: any = {};
  sensory: any = {};
  motor_coordination: any = {};
  accordionOpenStates: boolean[] = [];
  parsed: any = [];
  allData: any = [];
  babyData: any = {};
  other_food: any = {};
  other_task: any = {};
  escort: any = {};
  education: any = {};
  playing: any = {};
  sleep_training: any = {};
  diapering: any = {};
  food: any = {};
  sterilization: any = {};
  clothes: any = {};
  washing_frequency: any;
  bathing_frequency: any;
  bathing: any = {};
  others: any = [];
  health:any=[];
  filterVal: string;
  // feeding: any = {};
  viewDetail: any = [];
  detail: any = [];
  displayMaximizable: boolean = false;
  nannyTaskData: any = [];
  loading: any;
  tooltipText: string = 'Nanny Task List';
  filteredPDFData: any[];
  pdfData: any[];
  exportColumns: any = [];
  cols: any;
  constructor(private nanny_task_service: MaikaListService, private router:Router, private spinner:NgxSpinnerService ,    private messageService: MessageService,
    ) {}
  // fn to get the data from backend
  getAllData() {
    this.allData = [];
     this.spinner.show()
    this.nanny_task_service
      .getMonitorFourToOneYearList()
      .subscribe((res: any) => {
       this.spinner.hide();

        console.log('response:', res.data);
        this.allData = res?.data;
        // Initialize accordionOpenStates with false values
        this.accordionOpenStates = new Array(this.allData.length).fill(false);
      },
      (error: any) => {
        this.spinner.hide()
        // Handle any errors that occurred during the API call
      
       
      });
  }

  ngOnInit(): void {
    this.getAllData();
    localStorage.removeItem('editedOrderId')
    localStorage.removeItem('create');
    this.cols = [
      { field: 'sr_no', header: 'Sr No' },
      { field: 'name', header: 'Name' },
      { field: 'date_of_birth', header: 'Date of Birth' },
      { field: 'days', header: 'Age' },
      { field: 'months', header: 'Months' },
    ];
    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
  }

  getData(id: any) {
    this.parsed = [];
    console.log('id', id);
    this.allData?.map((res: any) => {
      console.log('getData', res);
      if (res.baby_id == id) {
        console.log('show....', res.baby_id, 'id', id);
        this.babyData = res;
        console.log('babyyyy:', this.babyData);
        this.babyData?.development_milestones.map((res: any) => {
          let data = JSON.parse(res?.development_milestone);
          console.log(data);
          this.parsed.push(data);
          console.log('development_milestone', this.parsed);
        });
      }
    });
  }

  details: any = [];
  showMaximizableDialog(id: any) {
    console.log('id: ' + id);
    console.log('called');
    this.detail = [];
    // ;
    console.log(
      'development_milestones:',
      this.babyData?.development_milestones
    );
    console.log(typeof this.babyData?.development_milestones);
    this.babyData?.development_milestones?.map((res: any) => {
      console.log(res);
      if (res.id == id) {
        this.detail = (JSON.parse(res?.development_milestone));
        console.log(this.detail);
        this.motor_coordination = this.detail?.motor_coordination;
        this.sensory = this.detail?.sensory;
        this.communication = this.detail?.communication;
        this.feeding = this.detail?.feeding;
        this.self_exp = this.detail?.self_expression;
        this.play = this.detail?.play_and_social_skills;
        this.health=this.detail?.health_json;
      }
    });
    this.displayMaximizable = true;
  }
  editOnCLick(id:any){
    localStorage.setItem('editedOrderId', id);
    console.log("id",id);
    this.router.navigateByUrl('/portal/four-to-one-year-growth-monitoring');
  }
   /* -------------------------------------------------------------------------- */
  /*                        // reset table data function                        */
  /* -------------------------------------------------------------------------- */
  reset(dt) {
    dt.reset();
    this.filterVal = '';
  }
  onPDFExport() {
    this.filteredPDFData = [];
    this.pdfData = [];
    this.filteredPDFData = this.allData;
  
    for (let i = 0; i < this.filteredPDFData.length; i++) {
      this.pdfData.push({
        sr_no: i + 1,
        name: this.filteredPDFData[i]?.name ?? '-',
        date_of_birth: this.filteredPDFData[i]?.date_of_birth ?? '-',
        days: this.filteredPDFData[i]?.days ?? '-',
        months: this.filteredPDFData[i]?.months ?? '-',
      });
    }
  
    this.nanny_task_service.exportPdf(
      this.pdfData,
      this.exportColumns,
      'zeo-to-three-month.pdf'
    );
  }
}
