import { Component, OnInit } from '@angular/core';
import { MaikaListService } from 'src/app/services/maika-list.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-three-year-and-above-monitor-list',
  templateUrl: './three-year-and-above-monitor-list.component.html',
  styleUrls: ['./three-year-and-above-monitor-list.component.scss']
})
export class ThreeYearAndAboveMonitorListComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Three Years and Above',
      subTitle: 'Dashboard',
    },
  ];

  communication: any = {};
  sensory: any = {};
  motor_coordination: any = {};
  accordionOpenStates: boolean[] = [];
  parsed: any = [];
  allData: any = [];
  babyData: any = {};
  other_food: any = {};
  health: any = [];
  other_task: any = {};
  escort: any = {};
  education: any = {};
  playing: any = {};
  sleep_training: any = {};
  diapering: any = {};
  food: any = {};
  sterilization: any = {};
  clothes: any = {};
  washing_frequency: any;
  filterVal: string;
  bathing_frequency: any;
  bathing: any = {};
  others: any = [];
  feeding: any = {};
  viewDetail: any = [];
  detail: any = [];
  displayMaximizable: boolean = false;
  nannyTaskData: any = [];
  loading: any;
  tooltipText: string = 'Nanny Task List';
  filteredPDFData: any[];
  pdfData: any[];
  exportColumns: any = [];
  cols: any;
  constructor(private nanny_task_service: MaikaListService, private router: Router, private spinner: NgxSpinnerService ,    private messageService: MessageService,
    ) { }
  // fn to get the data from backend
  getAllData() {
    // ;
    this.spinner.show();
    this.nanny_task_service
      .getMonitorThreeYearAndAboveList()
      .subscribe((res: any) => {
        this.spinner.hide();

        this.allData = res?.data;
        // Initialize accordionOpenStates with false values
        this.accordionOpenStates = new Array(this.allData.length).fill(false);
      },(error: any) => {
        this.spinner.hide()
        // Handle any errors that occurred during the API call
      
       
      });
  }

  ngOnInit(): void {
    this.getAllData();
    localStorage.removeItem('editedOrderId')
    localStorage.removeItem('create');
    this.cols = [
      { field: 'sr_no', header: 'Sr No' },
      { field: 'name', header: 'Name' },
      { field: 'date_of_birth', header: 'Date of Birth' },
      { field: 'days', header: 'Age' },
      { field: 'months', header: 'Months' },
    ];
    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
  }

  getData(id: any) {
    this.parsed = [];
    this.allData?.map((res: any) => {
      if (res.baby_id == id) {
        this.babyData = res;
        this.babyData?.development_milestones.map((res: any) => {
          let data = JSON.parse(JSON.parse(res?.development_milestone))
          this.parsed.push(data);
        });
      }
    });
  }

  details: any = [];
  showMaximizableDialog(id: any) {
    this.detail = [];
    // ;
    this.babyData?.development_milestones?.map((res: any) => {
      if (res.id == id) {
        this.detail = (JSON.parse(res?.development_milestone));
        this.health = this.detail.health_json;
        // this.motor_coordination = this.detail.motor_coordination;
        // this.sensory = this.detail.sensory;
        // this.communication = this.detail.communication;
      }
    });
    this.displayMaximizable = true;
  }
  editOnCLick(id: any) {
    localStorage.setItem('editedOrderId', id);
    this.router.navigateByUrl('/portal/three-year-and-above-year-growth-monitoring');
  }
  reset(dt) {
    dt.reset();
    this.filterVal = '';
  }
  onPDFExport() {
    this.filteredPDFData = [];
    this.pdfData = [];
    this.filteredPDFData = this.allData;

    for (let i = 0; i < this.filteredPDFData.length; i++) {
      this.pdfData.push({
        sr_no: i + 1,
        name: this.filteredPDFData[i]?.name ?? '-',
        date_of_birth: this.filteredPDFData[i]?.date_of_birth ?? '-',
        days: this.filteredPDFData[i]?.days ?? '-',
        months: this.filteredPDFData[i]?.months ?? '-',
      });
    }

    this.nanny_task_service.exportPdf(
      this.pdfData,
      this.exportColumns,
      'zeo-to-three-month.pdf'
    );
  }
}
