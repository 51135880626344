<!-- Start Copyright End -->
<div class="copyrights-area">
    <div class="row align-items-center">
        <div class="col-lg-6 col-sm-6 col-md-6">
            <p><img src="assets/img/Dr._Sunny_Home_Health_Care_Group.png" width="100" alt=""> Dr.Sunny Home Health Care Group <a href="https://envytheme.com/" target="_blank"></a></p>
        </div>
        <div class="col-lg-6 col-sm-6 col-md-6 text-end">
            <p>Designed & Developed by <a href="https://webanixsolutions.com/" target="_blank">WEBanix</a></p>
        </div>
    </div>
</div>
<!-- End Copyright End -->
