import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NannyTaskFormService {
  apiBaseUrl: string = environment.api_base_url;
  constructor(private http: HttpClient) { }
  /* -------------------------------------------------------------------------- */
  /*               // nany tasks form submit function                    */
  /* -------------------------------------------------------------------------- */
  submitNannyTasksForm(formData: any) {
    return this.http.post(this.apiBaseUrl + 'create-nannytask', formData)
  }
}
