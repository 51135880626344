<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->
<p-toast></p-toast>
<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">
    <app-dashboard-navbar></app-dashboard-navbar>
    <div class="dropdown profile-nav-item d-flex flex-column align-items-end">
        <a class="dropdown-toggle admin-btn" style="text-decoration: none;" role="button" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <div class="menu-profile">
                <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
                <span class="name">{{getname}}</span>
            </div>
        </a>
        <div class="dropdown-menu d-flex flex-column align-items-center">
            <div class="dropdown-header align">
                <div class="figure mb-3">
                    <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
                </div>
                <div class="info text-center">
                    <span class="name" style="white-space: break-spaces;">{{getname}}</span>
                    <p class="mb-3 email">{{getRole}}</p>
                </div>
            </div>
            <!-- <div class="dropdown-body">
				<ul class="profile-nav p-0 pt-3">
					<li class="nav-item"><a routerLink="/dashboard-my-profile" class="nav-link"><i class='bx bx-user'></i> <span>Profile Dashboard</span></a></li>
				</ul>
			</div> -->
            <div class="dropdown-footer">
                <ul class="profile-nav">
                    <li class="nav-item"><a class="nav-link" (click)="logout()"><i class='bx bx-log-out'></i> <span
                                class="logout-text">Logout</span></a></li>
                </ul>
            </div>

        </div>
    </div>
    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
        <h1>{{ Content.title }}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item">
                {{ Content.subtitle }}
            </li>
            <li class="item">{{ Content.title }}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

    <form [formGroup]="threeYearsAndAbove" (ngSubmit)="growthMonitoringForm(formSubmitted)" #formSubmitted="ngForm" (keydown.enter)="$event.preventDefault()">
        <!-- BABY DETAILS SECTION -->
        <div class="add-listings-box">
            <h3>Baby Details</h3>
            <div class="row">
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Name<span class="required">*</span> </label>
                        <div class="card flex justify-content-center">
                            <p-dropdown [options]="babyDetails" (onChange)="getBabyDetails($event)" optionValue="id"
                                [readonly]="true" optionLabel="name" [filter]="true" filterBy="name"
                                formControlName="baby_details_id" placeholder="Select Baby's Name"
                                [resetFilterOnHide]="true" [class.is-invalid]="(formSubmitted.submitted &&
                      threeYearsAndAbove.get('baby_details_id')?.invalid)
                      ||
                      threeYearsAndAbove.get('baby_details_id')?.invalid
                      &&
                      threeYearsAndAbove.get('baby_details_id').touched">
                            </p-dropdown>
                            <div class="invalid-feedback">Name is required</div>
                        </div>
                    </div>
                </div>
                <!-- Select Gender Start-->
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Gender </label>
                        <div class="card flex justify-content-center">
                            <!-- <p-dropdown optionLabel="name" optionValue="value" readonly
                      [(ngModel)]="babyGender" [ngModelOptions]="{standalone: true}" placeholder="Select Baby's Gender"
                      [resetFilterOnHide]="true" [readonly]="true">
                    </p-dropdown> -->
                            <input type="text" class="form-control" [(ngModel)]="babyGender"
                                [ngModelOptions]="{standalone: true}" placeholder="Select Baby's Gender"
                                [readonly]="true">
                        </div>
                    </div>
                </div>
                <!-- Select Gender End-->
                <!-- Select DOB Start-->
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Date of birth</label>
                        <div class="card flex justify-content-center">
                            <!-- <p-calendar readonly [(ngModel)]="selectedDate" [maxDate]="selectedDate"
                      [ngModelOptions]="{standalone: true}" readonly [showIcon]="true" [readonlyInput]="true"
                      placeholder="DD/MM/YYYY"></p-calendar> -->
                            <input type="text" name="name" class="form-control" placeholder="DD/MM/YYYY"
                                [value]="selectedDate" readonly />

                        </div>
                    </div>
                </div>
                <!-- Select DOB End-->
                <!-- Days, Months, Years Start-->
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Days</label>
                        <input type="text" name="name" class="form-control" placeholder="Days" [value]="days"
                            readonly />
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Months</label>
                        <input type="text" name="name" class="form-control" placeholder="Months" [value]="month"
                            readonly />
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Years</label>
                        <input type="text" name="name" class="form-control" placeholder="Years" [value]="years"
                            readonly />
                    </div>
                </div>
                <!-- Days, Months, Years End-->
            </div>
        </div>
        <!-- BABY DETAILS SECTION END -->
        <!-- FORM SECTION START -->
        <ng-container *ngIf="flag">
            <div class="add-listings-box py-3">
				<div class="row">
					<label class="subHeading mb-3">Height Weight</label>
					
					
					<div formArrayName="health_json">
						<!-- <div class="row">
						  <div class="col-lg-4 col-sm-6 col-12" *ngIf="isedit">
							<div class="form-group">
							  <div class="secondary-btn">
								<button type="button" [disabled]="heightConverted" (click)="convertHeightToCm()">Convert Height to CM</button>
							  </div>
							</div>
						  </div>
						  <div class="col-lg-4 col-sm-6 col-12" *ngIf="isedit">
							<div class="form-group">
							  <div class="secondary-btn">
								<button type="button"[disabled]="weightConverted" (click)="convertKgToPounds()">Convert Weight to Pounds</button>
							  </div>
							</div>
						  </div>
						</div> -->
					  
						<ng-container *ngFor="let control of gethealthFormArray().controls; index as i; last as last">
						  <div class="row" [formGroupName]="i">
							<div class="col-lg-4 col-sm-6 col-12">
							  <div class="form-group">
								<label class="px-0">Date</label>
								<div class="card flex justify-content-center">
								  <p-calendar formControlName="time" [showIcon]="true" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" [minDate]="today" [maxDate]="today"></p-calendar>
								</div>
							  </div>
							</div>
					  
							<div class="col-lg-4 col-sm-6 col-12">
							  <div class="form-group">
								<label class="px-0">Height</label>
								<div class="pl-0 checkbox-list">
								  <input type="text" formControlName="height" class="form-control" maxlength="5" placeholder="Enter Height" />
								</div>
							  </div>
							</div>
					  
							<div class="col-lg-4 col-sm-6 col-12">
							  <div class="form-group">
								<label class="px-0">Weight</label>
								<div class="pl-0 checkbox-list">
								  <input type="text" formControlName="weight" class="form-control" maxlength="5" placeholder="Enter Weight" (keypress)="keyPress($event)" />
								</div>
							  </div>
							</div>
					  
							<div class="col-12 d-flex mb-3 justify-content-end">
							  <p-button type="button" icon="bx bx-plus" (click)="addVitaminObject('add', i)" iconPos="right" class="mr-2 secondary-btn"></p-button>
							  <div class="remove">
								<p-button type="button" *ngIf="i != 0" class="ml-2 secondary-btn" icon="bx bx-minus" (click)="addVitaminObject('delete', i)" iconPos="right"></p-button>
							  </div>
							</div>
						  </div>
						</ng-container>
					  </div>
					  
				</div>
			</div>

            <!-- <div class="add-listings-box py-3">
            <div class="row">
                <label class="subHeading mb-3">Baby Images of Growth Monitoring </label>
                <div formArrayName="baby_images">
                    <ng-container *ngFor="let control of getImageArray().controls; index as i; last as last">
                        <div class="row" [formGroupName]="i">
                            
                            <div class="col-lg-4 col-sm-6 col-12">
                                <div class="form-group">
                                    <label class="px-0">Upload Photo </label>
                                    <input (change)="uploadPhoto($event)" type="file" accept=".png, .jpg, .jpeg" class="inputfile" placeholder="Name" />
                                </div>
                            </div>
                        </div>
                        <div class="col-12 d-flex mb-3 justify-content-end">
                            <p-button type="button" icon="bx bx-plus" (click)="addBabyImg('add', i)"
                                iconPos="right" class="mr-2"></p-button>
                            <div class="remove">
                                <p-button type="button" *ngIf="i!= 0" class="ml-2" icon="bx bx-minus"
                                    (click)="addBabyImg('delete', i)" iconPos="right"></p-button>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div> -->


            <div class="add-listings-box py-3">
                <div class="row">
                    <label class="subHeading mb-3">Baby Images of Growth Monitoring </label>
                    <div *ngIf="babyImgJSon">
                        <div *ngFor="let control of babyImgJSon">
                            <a href="{{ImageUrl + control?.name}}" target="_blank">View</a>
                        </div>
                    </div>


                    <div formArrayName="baby_images">
                        <label class="fw-500 mb-1">Upload Photo </label>

                        <ng-container *ngFor="let control of getImageArray().controls; index as i; last as last">

                            <div class="row" [formGroupName]="i">
                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="form-group mb-2">
                                        <input (change)="uploadPhoto($event)" type="file" accept=".png, .jpg, .jpeg"
                                            class="form-control" aria-label="Upload"
                                            aria-describedby="inputGroupFileAddon">


                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6 col-12 d-flex mb-2 align-items-end">
                                    <p-button type="button" icon="bx bx-plus" (click)="addBabyImg('add', i)"
                                        iconPos="right" class="mr-2 secondary-btn"></p-button>
                                    <div class="remove">
                                        <p-button type="button" *ngIf="i!= 0" class="ml-2 secondary-btn"
                                            icon="bx bx-minus" (click)="addBabyImg('delete', i)"
                                            iconPos="right"></p-button>
                                    </div>
                                </div>

                            </div>

                        </ng-container>

                    </div>

                    <div class="d-flex align-items-start">
                        <button class="p-button" type="button" (click)="openCamera()">
                            <i class="fas fa-camera"></i>
                        </button>
                        <div class=" imgPreview" *ngFor="let img of capturedByCamera">

                            <!-- <a (click)="previewImage(img)">Click to preview</a> -->
                            <a (click)="deletecapture(img)">✖</a>
                            <img [src]="img" alt="">

                        </div>


                    </div>
                </div>
            </div>
            <div class="add-listings-box py-3">
                <h3 class="text-center">3 YEARS OLD & ABOVE</h3>
                <div class="d-flex col-lg-6 col-md-12 justify-content-between">
                    <!-- *COORDINATION & DAILY ACTIVITIES SECTION -->
                    <label class="subHeading mb-3">Coordination & Daily Activities</label>
                    <label class="subHeading2 mb-3">Remarks</label>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Enjoys and seeks out various ways to
                                move and play
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="enjoyandplay-yes" name="enjoyandplay" value="Yes"
                                        formControlName="enjoyandplay" (change)="handleInput($event,'enjoyandplay')">
                                    <label for="enjoyandplay-yes">Yes</label>

                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="enjoyandplay-no" name="enjoyandplay" value="No"
                                        formControlName="enjoyandplay" (change)="handleInput($event,'enjoyandplay')">
                                    <label for="enjoyandplay-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="enjoyandplay_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Has adequate endurance and strength
                                to play with peers
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="enduranceandstrength-yes" name="enduranceandstrength"
                                        value="Yes" formControlName="enduranceandstrength"
                                        (change)="handleInput($event,'enduranceandstrength')">
                                    <label for="enduranceandstrength-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="enduranceandstrength-no" name="enduranceandstrength"
                                        value="No" formControlName="enduranceandstrength"
                                        (change)="handleInput($event,'enduranceandstrength')">
                                    <label for="enduranceandstrength-no">No</label>

                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="enduranceandstrength_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Coordinates movements needed to play
                                and explore
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="coordinateandexplore-yes" name="coordinateandexplore"
                                        value="Yes" formControlName="coordinateandexplore"
                                        (change)="handleInput($event,'coordinateandexplore')">
                                    <label for="coordinateandexplore-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="coordinateandexplore-no" name="coordinateandexplore"
                                        value="No" formControlName="coordinateandexplore"
                                        (change)="handleInput($event,'coordinateandexplore')">
                                    <label for="coordinateandexplore-no">No</label>

                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="coordinateandexplore_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Usually walks with heel toe pattern
                                and not primarily on toes
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="healtoewalk-yes" name="healtoewalk" value="Yes"
                                        formControlName="healtoewalk" (change)="handleInput($event,'healtoewalk')">
                                    <label for="healtoewalk-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="healtoewalk-no" name="healtoewalk" value="No"
                                        formControlName="healtoewalk" (change)="handleInput($event,'healtoewalk')">
                                    <label for="healtoewalk-no">No</label>

                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="healtoewalk_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Can maintain balance to catch ball or
                                when gently bumped by peers
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="balancetocatch-yes" name="balancetocatch" value="Yes"
                                        formControlName="balancetocatch"
                                        (change)="handleInput($event,'balancetocatch')">
                                    <label for="balancetocatch-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="balancetocatch-no" name="balancetocatch" value="No"
                                        formControlName="balancetocatch"
                                        (change)="handleInput($event,'balancetocatch')">
                                    <label for="balancetocatch-no">No</label>

                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="balancetocatch_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Able to walk and maintain balance
                                over uneven surfaces
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="balanceonunevensurfaces-yes" name="balanceonunevensurfaces"
                                        value="Yes" formControlName="balanceonunevensurfaces"
                                        (change)="handleInput($event,'balanceonunevensurfaces')">
                                    <label for="balanceonunevensurfaces-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="balanceonunevensurfaces-no" name="balanceonunevensurfaces"
                                        value="No" formControlName="balanceonunevensurfaces"
                                        (change)="handleInput($event,'balanceonunevensurfaces')">
                                    <label for="balanceonunevensurfaces-no">No</label>

                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="balanceonunevensurfaces_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Walks through new room without
                                bumping into objects or people
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="walkinnewroomnobumping-yes" name="walkinnewroomnobumping"
                                        value="Yes" formControlName="walkinnewroomnobumping"
                                        (change)="handleInput($event,'walkinnewroomnobumping')">
                                    <label for="walkinnewroomnobumping-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="walkinnewroomnobumping-no" name="walkinnewroomnobumping"
                                        value="No" formControlName="walkinnewroomnobumping"
                                        (change)="handleInput($event,'walkinnewroomnobumping')">
                                    <label for="walkinnewroomnobumping-no">No</label>

                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="walkinnewroomnobumping_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Only leans on furniture, walls or
                                people and sits slumped over when tired
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="leansoverandsitsslumped-yes" name="leansoverandsitsslumped"
                                        value="Yes" formControlName="leansoverandsitsslumped"
                                        (change)="handleInput($event,'leansoverandsitsslumped')">
                                    <label for="leansoverandsitsslumped-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="leansoverandsitsslumped-no" name="leansoverandsitsslumped"
                                        value="No" formControlName="leansoverandsitsslumped"
                                        (change)="handleInput($event,'leansoverandsitsslumped')">
                                    <label for="leansoverandsitsslumped-no">No</label>

                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="leansoverandsitsslumped_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Able to throw and attempt to catch
                                ball without losing balance
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="throwandcatchwithbalance-yes"
                                        name="throwandcatchwithbalance" value="Yes"
                                        formControlName="throwandcatchwithbalance"
                                        (change)="handleInput($event,'throwandcatchwithbalance')">
                                    <label for="throwandcatchwithbalance-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="throwandcatchwithbalance-no" name="throwandcatchwithbalance"
                                        value="No" formControlName="throwandcatchwithbalance"
                                        (change)="handleInput($event,'throwandcatchwithbalance')">
                                    <label for="throwandcatchwithbalance-no">No</label>

                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="throwandcatchwithbalance_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Coordinates both hands to play, such
                                as swinging a bat or opening a container
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="bothhandstoplay-yes" name="bothhandstoplay" value="Yes"
                                        formControlName="bothhandstoplay"
                                        (change)="handleInput($event,'bothhandstoplay')">
                                    <label for="bothhandstoplay-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="bothhandstoplay-no" name="bothhandstoplay" value="No"
                                        formControlName="bothhandstoplay"
                                        (change)="handleInput($event,'bothhandstoplay')">
                                    <label for="bothhandstoplay-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="bothhandstoplay_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Coordinates hand and finger movement
                                needed to participate in table top games and
                                activities
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="partsingamesandactivities-yes"
                                        name="partsingamesandactivities" value="Yes"
                                        formControlName="partsingamesandactivities"
                                        (change)="handleInput($event,'partsingamesandactivities')">
                                    <label for="partsingamesandactivities-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="partsingamesandactivities-no"
                                        name="partsingamesandactivities" value="No"
                                        formControlName="partsingamesandactivities"
                                        (change)="handleInput($event,'partsingamesandactivities')">
                                    <label for="partsingamesandactivities-no">No</label>

                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="partsingamesandactivities_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Able to color and begin to imitate
                                shapes
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="colorandshapes-yes" name="colorandshapes" value="Yes"
                                        formControlName="colorandshapes"
                                        (change)="handleInput($event,'colorandshapes')">
                                    <label for="colorandshapes-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="colorandshapes-no" name="colorandshapes" value="No"
                                        formControlName="colorandshapes"
                                        (change)="handleInput($event,'colorandshapes')">
                                    <label for="colorandshapes-no">No</label>

                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="colorandshapes_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Uses appropriate force when playing
                                with peers or pets or when holding objects
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="usesforcewhenplaying-yes" name="usesforcewhenplaying"
                                        value="Yes" formControlName="usesforcewhenplaying"
                                        (change)="handleInput($event,'usesforcewhenplaying')">
                                    <label for="usesforcewhenplaying-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="usesforcewhenplaying-no" name="usesforcewhenplaying"
                                        value="No" formControlName="usesforcewhenplaying"
                                        (change)="handleInput($event,'usesforcewhenplaying')">
                                    <label for="usesforcewhenplaying-no">No</label>

                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="usesforcewhenplaying_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Able to maintain good sitting posture
                                needed to sit in chair
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="goodsittingposture-yes" name="goodsittingposture"
                                        value="Yes" formControlName="goodsittingposture"
                                        (change)="handleInput($event,'goodsittingposture')">
                                    <label for="goodsittingposture-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="goodsittingposture-no" name="goodsittingposture" value="No"
                                        formControlName="goodsittingposture"
                                        (change)="handleInput($event,'goodsittingposture')">
                                    <label for="goodsittingposture-no">No</label>

                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="goodsittingposture_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Able to use utensils to pick up
                                pieces of food
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="useutensilsforfood-yes" name="useutensilsforfood"
                                        value="Yes" formControlName="useutensilsforfood"
                                        (change)="handleInput($event,'useutensilsforfood')">
                                    <label for="useutensilsforfood-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="useutensilsforfood-no" name="useutensilsforfood" value="No"
                                        formControlName="useutensilsforfood"
                                        (change)="handleInput($event,'useutensilsforfood')">
                                    <label for="useutensilsforfood-no">No</label>

                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="useutensilsforfood_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Has an established sleep schedule
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="establishedsleep-yes" name="establishedsleep" value="Yes"
                                        formControlName="establishedsleep"
                                        (change)="handleInput($event,'establishedsleep')">
                                    <label for="establishedsleep-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="establishedsleep-no" name="establishedsleep" value="No"
                                        formControlName="establishedsleep"
                                        (change)="handleInput($event,'establishedsleep')">
                                    <label for="establishedsleep-no">No</label>

                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="establishedsleep_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Able to self-calm to fall asleep
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="selfcalmfallasleep-yes" name="selfcalmfallasleep"
                                        value="Yes" formControlName="selfcalmfallasleep"
                                        (change)="handleInput($event,'selfcalmfallasleep')">
                                    <label for="selfcalmfallasleep-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="selfcalmfallasleep-no" name="selfcalmfallasleep" value="No"
                                        formControlName="selfcalmfallasleep"
                                        (change)="handleInput($event,'selfcalmfallasleep')">
                                    <label for="selfcalmfallasleep-no">No</label>

                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="selfcalmfallasleep_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Able to tolerate and wear textures of
                                new and varied articles of clothes
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="wearnewclothes-yes" name="wearnewclothes" value="Yes"
                                        formControlName="wearnewclothes"
                                        (change)="handleInput($event,'wearnewclothes')">
                                    <label for="wearnewclothes-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="wearnewclothes-no" name="wearnewclothes" value="No"
                                        formControlName="wearnewclothes"
                                        (change)="handleInput($event,'wearnewclothes')">
                                    <label for="wearnewclothes-no">No</label>

                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="wearnewclothes_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Able to take appropriate bites of
                                food, does not always stuff mouth
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="appropriatebitesoffood-yes" name="appropriatebitesoffood"
                                        value="Yes" formControlName="appropriatebitesoffood"
                                        (change)="handleInput($event,'appropriatebitesoffood')">
                                    <label for="appropriatebitesoffood-yes">Yes</label>
                                </p>
                                <p class="d-flex">

                                    <input type="radio" id="appropriatebitesoffood-no" name="appropriatebitesoffood"
                                        value="No" formControlName="appropriatebitesoffood"
                                        (change)="handleInput($event,'appropriatebitesoffood')">
                                    <label for="appropriatebitesoffood-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="appropriatebitesoffood_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Able to tolerate haircuts and nail
                                cutting without crying
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="toleratehairandnailcut-yes" name="toleratehairandnailcut"
                                        value="Yes" formControlName="toleratehairandnailcut"
                                        (change)="handleInput($event,'toleratehairandnailcut')">
                                    <label for="toleratehairandnailcut-yes">Yes</label>

                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="toleratehairandnailcut-no" name="toleratehairandnailcut"
                                        value="No" formControlName="toleratehairandnailcut"
                                        (change)="handleInput($event,'toleratehairandnailcut')">
                                    <label for="toleratehairandnailcut-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="toleratehairandnailcut_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Able to adapt to changes in routine
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="adaptnewroutine-yes" name="adaptnewroutine" value="Yes"
                                        formControlName="adaptnewroutine"
                                        (change)="handleInput($event,'adaptnewroutine')">
                                    <label for="adaptnewroutine-yes">Yes</label>

                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="adaptnewroutine-no" name="adaptnewroutine" value="No"
                                        formControlName="adaptnewroutine"
                                        (change)="handleInput($event,'adaptnewroutine')">
                                    <label for="adaptnewroutine-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="adaptnewroutine_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Takes bath or shower, although
                                preference may be present
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="takesbathandshower-yes" name="takesbathandshower"
                                        value="Yes" formControlName="takesbathandshower"
                                        (change)="handleInput($event,'takesbathandshower')">
                                    <label for="takesbathandshower-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="takesbathandshower-no" name="takesbathandshower" value="No"
                                        formControlName="takesbathandshower"
                                        (change)="handleInput($event,'takesbathandshower')">
                                    <label for="takesbathandshower-no">No</label>

                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="takesbathandshower_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Eats a diet rich in various foods,
                                temperatures, and textures
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="eatsvarietyofdiet-yes" name="eatsvarietyofdiet" value="Yes"
                                        formControlName="eatsvarietyofdiet"
                                        (change)="handleInput($event,'eatsvarietyofdiet')">
                                    <label for="eatsvarietyofdiet-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="eatsvarietyofdiet-no" name="eatsvarietyofdiet" value="No"
                                        formControlName="eatsvarietyofdiet"
                                        (change)="handleInput($event,'eatsvarietyofdiet')">
                                    <label for="eatsvarietyofdiet-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="eatsvarietyofdiet_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Drinks from a cup and straw without
                                dribbling
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="drinkswithoutdribble-yes" name="drinkswithoutdribble"
                                        value="Yes" formControlName="drinkswithoutdribble"
                                        (change)="handleInput($event,'drinkswithoutdribble')">
                                    <label for="drinkswithoutdribble-yes">Yes</label>
                                </p>
                                <p class="d-flex">

                                    <input type="radio" id="drinkswithoutdribble-no" name="drinkswithoutdribble"
                                        value="No" formControlName="drinkswithoutdribble"
                                        (change)="handleInput($event,'drinkswithoutdribble')">
                                    <label for="drinkswithoutdribble-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="drinkswithoutdribble_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Need for crashing, bumping and moving
                                fast does not interfere with participation in
                                activities and family life
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="notactiveinfamily-yes" name="notactiveinfamily" value="Yes"
                                        formControlName="notactiveinfamily"
                                        (change)="handleInput($event,'notactiveinfamily')">
                                    <label for="notactiveinfamily-yes">Yes</label>
                                </p>
                                <p class="d-flex">

                                    <input type="radio" id="notactiveinfamily-no" name="notactiveinfamily" value="No"
                                        formControlName="notactiveinfamily"
                                        (change)="handleInput($event,'notactiveinfamily')">
                                    <label for="notactiveinfamily-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="notactiveinfamily_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Able to complete everyday tasks that
                                have multiple steps, such as dressing
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="completemultiplesteptasks-yes"
                                        name="completemultiplesteptasks" value="Yes"
                                        formControlName="completemultiplesteptasks"
                                        (change)="handleInput($event,'completemultiplesteptasks')">
                                    <label for="completemultiplesteptasks-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="completemultiplesteptasks-no"
                                        name="completemultiplesteptasks" value="No"
                                        formControlName="completemultiplesteptasks"
                                        (change)="handleInput($event,'completemultiplesteptasks')">
                                    <label for="completemultiplesteptasks-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="completemultiplesteptasks_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Frequently wakes up rested and ready
                                for a new day
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="wakesrestedandready-yes" name="wakesrestedandready"
                                        value="Yes" formControlName="wakesrestedandready"
                                        (change)="handleInput($event,'wakesrestedandready')">
                                    <label for="wakesrestedandready-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="wakesrestedandready-no" name="wakesrestedandready"
                                        value="No" formControlName="wakesrestedandready"
                                        (change)="handleInput($event,'wakesrestedandready')">
                                    <label for="wakesrestedandready-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="wakesrestedandready_description">
                        </div>
                    </div>
                </div>
            </div>

            <!-- *SENSORY SECTION START -->
            <div class="add-listings-box py-3">
                <div class="d-flex col-lg-6 col-md-12 justify-content-between">
                    <label class="subHeading mb-3">Sensory</label>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Can count 1-10
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="cancount-yes" name="cancount" value="Yes"
                                        formControlName="cancount" (change)="handleInput($event,'cancount')">
                                    <label for="cancount-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="cancount-no" name="cancount" value="No"
                                        formControlName="cancount" (change)="handleInput($event,'cancount')">
                                    <label for="cancount-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="cancount_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Knows shapes
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="knowshapes-yes" name="knowshapes" value="Yes"
                                        formControlName="knowshapes" (change)="handleInput($event,'knowshapes')">
                                    <label for="knowshapes-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="knowshapes-no" name="knowshapes" value="No"
                                        formControlName="knowshapes" (change)="handleInput($event,'knowshapes')">
                                    <label for="knowshapes-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="knowshapes_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Develops friendships
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="developsfriendships-yes" name="developsfriendships"
                                        value="Yes" formControlName="developsfriendships"
                                        (change)="handleInput($event,'developsfriendships')">
                                    <label for="developsfriendships-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="developsfriendships-no" name="developsfriendships"
                                        value="No" formControlName="developsfriendships"
                                        (change)="handleInput($event,'developsfriendships')">
                                    <label for="developsfriendships-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="developsfriendships_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Expresses emotions
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="expressesemotions-yes" name="expressesemotions" value="Yes"
                                        formControlName="expressesemotions"
                                        (change)="handleInput($event,'expressesemotions')">
                                    <label for="expressesemotions-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="expressesemotions-no" name="expressesemotions" value="No"
                                        formControlName="expressesemotions"
                                        (change)="handleInput($event,'expressesemotions')">
                                    <label for="expressesemotions-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="expressesemotions_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Able to follow rules
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="followrules-yes" name="followrules" value="Yes"
                                        formControlName="followrules" (change)="handleInput($event,'followrules')">
                                    <label for="followrules-yes">Yes</label>

                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="followrules-no" name="followrules" value="No"
                                        formControlName="followrules" (change)="handleInput($event,'followrules')">
                                    <label for="followrules-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="followrules_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Able to sit and pay attention (e.g.
                                mat time)
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="payattention-yes" name="payattention" value="Yes"
                                        formControlName="payattention" (change)="handleInput($event,'payattention')">
                                    <label for="payattention-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="payattention-no" name="payattention" value="No"
                                        formControlName="payattention" (change)="handleInput($event,'payattention')">
                                    <label for="payattention-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="payattention_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">20/20 vision
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="vision-yes" name="vision" value="Yes"
                                        formControlName="vision" (change)="handleInput($event,'vision')">
                                    <label for="vision-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="vision-no" name="vision" value="No" formControlName="vision"
                                        (change)="handleInput($event,'vision')">
                                    <label for="vision-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="vision_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Able to write numbers and letters
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="writenumbersandletters-yes" name="writenumbersandletters"
                                        value="Yes" formControlName="writenumbersandletters"
                                        (change)="handleInput($event,'writenumbersandletters')">
                                    <label for="writenumbersandletters-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="writenumbersandletters-no" name="writenumbersandletters"
                                        value="No" formControlName="writenumbersandletters"
                                        (change)="handleInput($event,'writenumbersandletters')">
                                    <label for="writenumbersandletters-no">No</label>

                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="writenumbersandletters_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Begins to write simple sentences
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="writesentences-yes" name="writesentences" value="Yes"
                                        formControlName="writesentences"
                                        (change)="handleInput($event,'writesentences')">
                                    <label for="writesentences-yes">Yes</label>

                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="writesentences-no" name="writesentences" value="No"
                                        formControlName="writesentences"
                                        (change)="handleInput($event,'writesentences')">
                                    <label for="writesentences-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="writesentences_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Begins to recognise others
                                perspectives
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="recogniseothersperspective-yes" value="Yes"
                                        formControlName="recogniseothersperspective"
                                        (change)="handleInput($event,'recogniseothersperspective')">
                                    <label for="recogniseothersperspective-yes">Yes</label>

                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="recogniseothersperspective-no" value="No"
                                        formControlName="recogniseothersperspective"
                                        (change)="handleInput($event,'recogniseothersperspective')">
                                    <label for="recogniseothersperspective-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="recogniseothersperspective_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Knows left and right
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="leftright-yes" name="leftright" value="Yes"
                                        formControlName="leftright" (change)="handleInput($event,'leftright')">
                                    <label for="leftright-yes">Yes</label>

                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="leftright-no" name="leftright" value="No"
                                        formControlName="leftright" (change)="handleInput($event,'leftright')">
                                    <label for="leftright-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="leftright_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Begins to reason and argue; uses
                                words like why and because
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="reasonandargue-yes" name="reasonandargue" value="Yes"
                                        formControlName="reasonandargue"
                                        (change)="handleInput($event,'reasonandargue')">
                                    <label for="reasonandargue-yes">Yes</label>

                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="reasonandargue-no" name="reasonandargue" value="No"
                                        formControlName="reasonandargue"
                                        (change)="handleInput($event,'reasonandargue')">
                                    <label for="reasonandargue-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="reasonandargue_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Categorizes objects: “These are toys;
                                These are books”
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="categoriseobjects-yes" name="categoriseobjects" value="Yes"
                                        formControlName="categoriseobjects"
                                        (change)="handleInput($event,'categoriseobjects')">
                                    <label for="categoriseobjects-yes">Yes</label>

                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="categoriseobjects-no" name="categoriseobjects" value="No"
                                        formControlName="categoriseobjects"
                                        (change)="handleInput($event,'categoriseobjects')">
                                    <label for="categoriseobjects-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="categoriseobjects_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Understand concepts like yesterday,
                                today, and tomorrow
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="yesterdaytodaytomorrow-yes" name="yesterdaytodaytomorrow"
                                        value="Yes" formControlName="yesterdaytodaytomorrow"
                                        (change)="handleInput($event,'yesterdaytodaytomorrow')">
                                    <label for="yesterdaytodaytomorrow-yes">Yes</label>

                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="yesterdaytodaytomorrow-no" name="yesterdaytodaytomorrow"
                                        value="No" formControlName="yesterdaytodaytomorrow"
                                        (change)="handleInput($event,'yesterdaytodaytomorrow')">
                                    <label for="yesterdaytodaytomorrow-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="yesterdaytodaytomorrow_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Can copy complex shapes, such as a
                                diamond
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="copycomplexshapes-yes" name="copycomplexshapes" value="Yes"
                                        formControlName="copycomplexshapes"
                                        (change)="handleInput($event,'copycomplexshapes')">
                                    <label for="copycomplexshapes-yes">Yes</label>

                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="copycomplexshapes-no" name="copycomplexshapes" value="No"
                                        formControlName="copycomplexshapes"
                                        (change)="handleInput($event,'copycomplexshapes')">
                                    <label for="copycomplexshapes-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="copycomplexshapes_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Sounding out simple words like
                                “hang”, “neat”, “jump” and “sank”
                            </label>
                            <div class="d-flex">
                                <p class="me-3">

                                    <input type="radio" id="sayssimplewords-yes" name="sayssimplewords" value="Yes"
                                        formControlName="sayssimplewords"
                                        (change)="handleInput($event,'sayssimplewords')">
                                    <label for="sayssimplewords-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="sayssimplewords-no" name="sayssimplewords" value="No"
                                        formControlName="sayssimplewords"
                                        (change)="handleInput($event,'sayssimplewords')">
                                    <label for="sayssimplewords-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="sayssimplewords_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Can sit at a desk , follow teacher
                                instructions, and independently do simple in-class
                                assignments
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="canattendclass-yes" name="canattendclass" value="Yes"
                                        formControlName="canattendclass"
                                        (change)="handleInput($event,'canattendclass')">
                                    <label for="canattendclass-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="canattendclass-no" name="canattendclass" value="No"
                                        formControlName="canattendclass"
                                        (change)="handleInput($event,'canattendclass')">
                                    <label for="canattendclass-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="canattendclass_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Forms numbers and letters correctly
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="formsnumbersandletters-yes" name="formsnumbersandletters"
                                        value="Yes" formControlName="formsnumbersandletters"
                                        (change)="handleInput($event,'formsnumbersandletters')">
                                    <label for="formsnumbersandletters-yes">Yes</label>

                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="formsnumbersandletters-no" name="formsnumbersandletters"
                                        value="No" formControlName="formsnumbersandletters"
                                        (change)="handleInput($event,'formsnumbersandletters')">
                                    <label for="formsnumbersandletters-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="formsnumbersandletters_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Recognises others perspective
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="recogniseothersperspective-yes"
                                        name="recogniseothersperspective" value="Yes"
                                        formControlName="recogniseothersperspective"
                                        (change)="handleInput($event,'recogniseothersperspective')">
                                    <label for="recogniseothersperspective-yes">Yes</label>

                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="recogniseothersperspective-no"
                                        name="recogniseothersperspective" value="No"
                                        formControlName="recogniseothersperspective"
                                        (change)="handleInput($event,'recogniseothersperspective')">
                                    <label for="recogniseothersperspective-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="recogniseothersperspective_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Develops a longer attention span
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="longattention-yes" name="longattention" value="Yes"
                                        formControlName="longattention" (change)="handleInput($event,'longattention')">
                                    <label for="longattention-yes">Yes</label>

                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="longattention-no" name="longattention" value="No"
                                        formControlName="longattention" (change)="handleInput($event,'longattention')">
                                    <label for="longattention-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="longattention_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Willing to take on more
                                responsibility (i.e. chores)

                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="onemoreresponsibility-yes" name="onemoreresponsibility"
                                        value="Yes" formControlName="onemoreresponsibility"
                                        (change)="handleInput($event,'onemoreresponsibility')">
                                    <label for="onemoreresponsibility-yes">Yes</label>

                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="onemoreresponsibility-no" name="onemoreresponsibility"
                                        value="No" formControlName="onemoreresponsibility"
                                        (change)="handleInput($event,'onemoreresponsibility')">
                                    <label for="onemoreresponsibility-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="onemoreresponsibility_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Understands fractions and the concept
                                of space

                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="fractionandspace-yes" name="fractionandspace" value="Yes"
                                        formControlName="fractionandspace"
                                        (change)="handleInput($event,'fractionandspace')">
                                    <label for="fractionandspace-yes">Yes</label>

                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="fractionandspace-no" name="fractionandspace" value="No"
                                        formControlName="fractionandspace"
                                        (change)="handleInput($event,'fractionandspace')">
                                    <label for="fractionandspace-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="fractionandspace_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Understands money
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="understandmoney-yes" name="understandmoney" value="Yes"
                                        formControlName="understandmoney"
                                        (change)="handleInput($event,'understandmoney')">
                                    <label for="understandmoney-yes">Yes</label>

                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="understandmoney-no" name="understandmoney" value="No"
                                        formControlName="understandmoney"
                                        (change)="handleInput($event,'understandmoney')">
                                    <label for="understandmoney-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="understandmoney_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Can tell time
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="telltime-yes" name="telltime" value="Yes"
                                        formControlName="telltime" (change)="handleInput($event,'telltime')">
                                    <label for="telltime-yes">Yes</label>

                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="telltime-no" name="telltime" value="No"
                                        formControlName="telltime" (change)="handleInput($event,'telltime')">
                                    <label for="telltime-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="telltime_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Can name months and days of the week
                                in order
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="monthsdaysinorder-yes" name="monthsdaysinorder" value="Yes"
                                        formControlName="monthsdaysinorder"
                                        (change)="handleInput($event,'monthsdaysinorder')">
                                    <label for="monthsdaysinorder-yes">Yes</label>

                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="monthsdaysinorder-no" name="monthsdaysinorder" value="No"
                                        formControlName="monthsdaysinorder"
                                        (change)="handleInput($event,'monthsdaysinorder')">
                                    <label for="monthsdaysinorder-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="monthsdaysinorder_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Enjoys reading a book on their own
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="enjoyreadingbook-yes" name="enjoyreadingbook" value="Yes"
                                        formControlName="enjoyreadingbook"
                                        (change)="handleInput($event,'enjoyreadingbook')">
                                    <label for="enjoyreadingbook-yes">Yes</label>

                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="enjoyreadingbook-no" name="enjoyreadingbook" value="No"
                                        formControlName="enjoyreadingbook"
                                        (change)="handleInput($event,'enjoyreadingbook')">
                                    <label for="enjoyreadingbook-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="enjoyreadingbook_description">
                        </div>
                    </div>
                </div>
            </div>
            <!-- *SENSORY SECTION END -->
            <!-- *COMMUNICATION SECTION START -->
            <div class="add-listings-box py-3">
                <div class="d-flex col-lg-6 col-md-12 justify-content-between">
                    <label class="subHeading mb-3">Communication</label>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Understand more complex language
                                structures; for example, 'why.... Because'
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="understandscomplexlanguage-yes"
                                        name="understandscomplexlanguage" value="Yes"
                                        formControlName="understandscomplexlanguage"
                                        (change)="handleInput($event,'understandscomplexlanguage')">
                                    <label for="understandscomplexlanguage-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="understandscomplexlanguage-no"
                                        name="understandscomplexlanguage" value="No"
                                        formControlName="understandscomplexlanguage"
                                        (change)="handleInput($event,'understandscomplexlanguage')">
                                    <label for="understandscomplexlanguage-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="understandscomplexlanguage_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Ask lots of 'what', 'where' and 'why'
                                questions to find out new information
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="findnewinfo-yes" name="findnewinfo" value="Yes"
                                        formControlName="findnewinfo" (change)="handleInput($event,'findnewinfo')">
                                    <label for="findnewinfo-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="findnewinfo-no" name="findnewinfo" value="No"
                                        formControlName="findnewinfo" (change)="handleInput($event,'findnewinfo')">
                                    <label for="findnewinfo-no">No</label>
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="findnewinfo_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Taking part in longer and more
                                complicated make-believe play sequences with peers;
                                for example, buying a ticket, going on the bus,
                                getting off at the right stop and then getting back
                                on enjoy simple jokes – even though their jokes may
                                not make sense!
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="takingpart-yes" name="takingpart" value="Yes"
                                        formControlName="takingpart" (change)="handleInput($event,'takingpart')">
                                    <label for="takingpart-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="takingpart-no" name="takingpart" value="No"
                                        formControlName="takingpart" (change)="handleInput($event,'takingpart')">
                                    <label for="takingpart-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="takingpart_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Recognise their own written name
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="recogniseownname-yes" name="recogniseownname" value="Yes"
                                        formControlName="recogniseownname"
                                        (change)="handleInput($event,'recogniseownname')">
                                    <label for="recogniseownname-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="recogniseownname-no" name="recogniseownname" value="No"
                                        formControlName="recogniseownname"
                                        (change)="handleInput($event,'recogniseownname')">
                                    <label for="recogniseownname-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="recogniseownname_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Know some letter names
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="knowletternames-yes" name="knowletternames" value="Yes"
                                        formControlName="knowletternames"
                                        (change)="handleInput($event,'knowletternames')">
                                    <label for="knowletternames-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="knowletternames-no" name="knowletternames" value="No"
                                        formControlName="knowletternames"
                                        (change)="handleInput($event,'knowletternames')">
                                    <label for="knowletternames-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="knowletternames_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Recognise some printed words in the
                                environment; for example, a stop sign
                            </label>
                            <div class=d-flex>
                                <p class="me-3">
                                    <input type="radio" id="recognisewordsoutside-yes" name="recognisewordsoutside"
                                        value="Yes" formControlName="recognisewordsoutside"
                                        (change)="handleInput($event,'recognisewordsoutside')">
                                    <label for="recognisewordsoutside-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="recognisewordsoutside-no" name="recognisewordsoutside"
                                        value="No" formControlName="recognisewordsoutside"
                                        (change)="handleInput($event,'recognisewordsoutside')">
                                    <label for="recognisewordsoutside-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="recognisewordsoutside_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Attempting to write their name
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="attemptwritingtherename-yes" name="attemptwritingtherename"
                                        value="Yes" formControlName="attemptwritingtherename"
                                        (change)="handleInput($event,'attemptwritingtherename')">
                                    <label for="attemptwritingtherename-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="attemptwritingtherename-no" name="attemptwritingtherename"
                                        value="No" formControlName="attemptwritingtherename"
                                        (change)="handleInput($event,'attemptwritingtherename')">
                                    <label for="attemptwritingtherename-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="attemptwritingtherename_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Starting to use talking to make
                                friends and to solve problems
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="talkingforsolutions-yes" name="talkingforsolutions"
                                        value="Yes" formControlName="talkingforsolutions"
                                        (change)="handleInput($event,'talkingforsolutions')">
                                    <label for="talkingforsolutions-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="talkingforsolutions-no" name="talkingforsolutions"
                                        value="No" formControlName="talkingforsolutions"
                                        (change)="handleInput($event,'talkingforsolutions')">
                                    <label for="talkingforsolutions-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="talkingforsolutions_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Able to talk about what they have
                                done and what they might do; for example, they can
                                tell nana about their trip to the park, then talk
                                about what they want for dinner
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="talkaboutdidanddos-yes" name="talkaboutdidanddos"
                                        value="Yes" formControlName="talkaboutdidanddos"
                                        (change)="handleInput($event,'talkaboutdidanddos')">
                                    <label for="talkaboutdidanddos-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="talkaboutdidanddos-no" name="talkaboutdidanddos" value="No"
                                        formControlName="talkaboutdidanddos"
                                        (change)="handleInput($event,'talkaboutdidanddos')">
                                    <label for="talkaboutdidanddos-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="talkaboutdidanddos_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Understood by unfamiliar adults
                                almost all of the time
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="unfamiliarpeopleunderstands-yes"
                                        name="unfamiliarpeopleunderstands" value="Yes"
                                        formControlName="unfamiliarpeopleunderstands"
                                        (change)="handleInput($event,'unfamiliarpeopleunderstands')">
                                    <label for="unfamiliarpeopleunderstands-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="unfamiliarpeopleunderstands-no"
                                        name="unfamiliarpeopleunderstands" value="No"
                                        formControlName="unfamiliarpeopleunderstands"
                                        (change)="handleInput($event,'unfamiliarpeopleunderstands')">
                                    <label for="unfamiliarpeopleunderstands-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="unfamiliarpeopleunderstands_description">
                        </div>
                    </div>
                </div>
            </div>
            <!-- *COMMUNICATION SECTION END -->
            <!-- *FEEDING SECTION START -->
            <div class="add-listings-box py-3">
                <div class="d-flex col-lg-6 col-md-12 justify-content-between">
                    <label class="subHeading mb-3">Feeding</label>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Eats the same foods as the rest of
                                the family
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="eatssameasfamily-yes" name="eatssameasfamily" value="Yes"
                                        formControlName="eatssameasfamily"
                                        (change)="handleInput($event,'eatssameasfamily')">
                                    <label for="eatssameasfamily-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="eatssameasfamily-no" name="eatssameasfamily" value="No"
                                        formControlName="eatssameasfamily"
                                        (change)="handleInput($event,'eatssameasfamily')">
                                    <label for="eatssameasfamily-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="eatssameasfamily_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Feeds well with utensils
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="feedswellutensils-yes" name="feedswellutensils" value="Yes"
                                        formControlName="feedswellutensils"
                                        (change)="handleInput($event,'feedswellutensils')">
                                    <label for="feedswellutensils-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="feedswellutensils-no" name="feedswellutensils" value="No"
                                        formControlName="feedswellutensils"
                                        (change)="handleInput($event,'feedswellutensils')">
                                    <label for="feedswellutensils-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="feedswellutensils_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Has definite food likes and dislikes
                                & refuse certain foods
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="likedislikesfood-yes" name="likedislikesfood" value="Yes"
                                        formControlName="likedislikesfood"
                                        (change)="handleInput($event,'likedislikesfood')">
                                    <label for="likedislikesfood-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="likedislikesfood-no" name="likedislikesfood" value="No"
                                        formControlName="likedislikesfood"
                                        (change)="handleInput($event,'likedislikesfood')">
                                    <label for="likedislikesfood-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="likedislikesfood_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Possibly start to become a “picky
                                eater” (see post next week!)
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="pickyeater-yes" name="pickyeater" value="Yes"
                                        formControlName="pickyeater" (change)="handleInput($event,'pickyeater')">
                                    <label for="pickyeater-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="pickyeater-no" name="pickyeater" value="No"
                                        formControlName="pickyeater" (change)="handleInput($event,'pickyeater')">
                                    <label for="pickyeater-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="pickyeater_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Wipes own mouth and hands with a
                                napkin or cloth
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="wipeshandsnmouth-yes" name="wipeshandsnmouth" value="Yes"
                                        formControlName="wipeshandsnmouth"
                                        (change)="handleInput($event,'wipeshandsnmouth')">
                                    <label for="wipeshandsnmouth-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="wipeshandsnmouth-no" name="wipeshandsnmouth" value="No"
                                        formControlName="wipeshandsnmouth"
                                        (change)="handleInput($event,'wipeshandsnmouth')">
                                    <label for="wipeshandsnmouth-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="wipeshandsnmouth_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Starts to serve themselves at the
                                table with some spills
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="servesself-yes" name="servesself" value="Yes"
                                        formControlName="servesself" (change)="handleInput($event,'servesself')">
                                    <label for="servesself-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="servesself-no" name="servesself" value="No"
                                        formControlName="servesself" (change)="handleInput($event,'servesself')">
                                    <label for="servesself-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="servesself_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Pours liquids into cup from a small
                                container
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="poursliquid-yes" name="poursliquid" value="Yes"
                                        formControlName="poursliquid" (change)="handleInput($event,'poursliquid')">
                                    <label for="poursliquid-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="poursliquid-no" name="poursliquid" value="No"
                                        formControlName="poursliquid" (change)="handleInput($event,'poursliquid')">
                                    <label for="poursliquid-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="poursliquid_description">
                        </div>
                    </div>
                </div>

            </div>
            <!-- *FEEDING SECTION END -->
            <!-- *PLAY AND SOCIAL SKILLS START-->
            <div class="add-listings-box py-3">
                <div class="d-flex col-lg-6 col-md-12 justify-content-between">
                    <label class="subHeading mb-3">Play and Social Skills</label>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Interested in, aware of, and able to
                                maintain eye contact with others
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="eyecontact-yes" name="eyecontact" value="Yes"
                                        formControlName="eyecontact" (change)="handleInput($event,'eyecontact')">
                                    <label for="eyecontact-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="eyecontact-no" name="eyecontact" value="No"
                                        formControlName="eyecontact" (change)="handleInput($event,'eyecontact')">
                                    <label for="eyecontact-no">No</label>
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="eyecontact_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Enjoys playing in small groups with
                                children of the same age
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="playingsamllgroup-yes" name="playingsamllgroup" value="Yes"
                                        formControlName="playingsamllgroup"
                                        (change)="handleInput($event,'playingsamllgroup')">
                                    <label for="playingsamllgroup-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="playingsamllgroup-no" name="playingsamllgroup" value="No"
                                        formControlName="playingsamllgroup"
                                        (change)="handleInput($event,'playingsamllgroup')">
                                    <label for="playingsamllgroup-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="playingsamllgroup_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Able to initiate and play with
                                another child of the same age
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="initiateplay-yes" name="initiateplay" value="Yes"
                                        formControlName="initiateplay" (change)="handleInput($event,'initiateplay')">
                                    <label for="initiateplay-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="initiateplay-no" name="initiateplay" value="No"
                                        formControlName="initiateplay" (change)="handleInput($event,'initiateplay')">
                                    <label for="initiateplay-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="initiateplay_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Turns head in response to name being
                                called
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="turnsinresponse-yes" name="turnsinresponse" value="Yes"
                                        formControlName="turnsinresponse"
                                        (change)="handleInput($event,'turnsinresponse')">
                                    <label for="turnsinresponse-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="turnsinresponse-no" name="turnsinresponse" value="No"
                                        formControlName="turnsinresponse"
                                        (change)="handleInput($event,'turnsinresponse')">
                                    <label for="turnsinresponse-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="turnsinresponse_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Interested in exploring varied
                                environments, such as new playground or friend’s
                                house
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="exploringnew-yes" name="exploringnew" value="Yes"
                                        formControlName="exploringnew" (change)="handleInput($event,'exploringnew')">
                                    <label for="exploringnew-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="exploringnew-no" name="exploringnew" value="No"
                                        formControlName="exploringnew" (change)="handleInput($event,'exploringnew')">
                                    <label for="exploringnew-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="exploringnew_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Able to play in new social situations
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="newsocialplay-yes" name="newsocialplay" value="Yes"
                                        formControlName="newsocialplay" (change)="handleInput($event,'newsocialplay')">
                                    <label for="newsocialplay-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="newsocialplay-no" name="newsocialplay" value="No"
                                        formControlName="newsocialplay" (change)="handleInput($event,'newsocialplay')">
                                    <label for="newsocialplay-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="newsocialplay_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Enjoys playing with variety of toys
                                intended for children of the same age
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="playvarietytoys-yes" name="playvarietytoys" value="Yes"
                                        formControlName="playvarietytoys"
                                        (change)="handleInput($event,'playvarietytoys')">
                                    <label for="playvarietytoys-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="playvarietytoys-no" name="playvarietytoys" value="No"
                                        formControlName="playvarietytoys"
                                        (change)="handleInput($event,'playvarietytoys')">
                                    <label for="playvarietytoys-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="playvarietytoys_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Aware of risky and unsafe
                                environments and behavior
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="aware-yes" name="aware" value="Yes" formControlName="aware"
                                        (change)="handleInput($event,'aware')">
                                    <label for="aware-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="aware-no" name="aware" value="No" formControlName="aware"
                                        (change)="handleInput($event,'aware')">
                                    <label for="aware-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="aware_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Participates in crafts/activities
                                that involve wet textures, such as glue
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="craftswithglue-yes" name="craftswithglue" value="Yes"
                                        formControlName="craftswithglue"
                                        (change)="handleInput($event,'craftswithglue')">
                                    <label for="craftswithglue-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="craftswithglue-no" name="craftswithglue" value="No"
                                        formControlName="craftswithglue"
                                        (change)="handleInput($event,'craftswithglue')">
                                    <label for="craftswithglue-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="craftswithglue_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Enjoys rough but safe play with
                                peers, siblings, or parents
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="roughsafeplay-yes" name="roughsafeplay" value="Yes"
                                        formControlName="roughsafeplay" (change)="handleInput($event,'roughsafeplay')">
                                    <label for="roughsafeplay-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="roughsafeplay-no" name="roughsafeplay" value="No"
                                        formControlName="roughsafeplay" (change)="handleInput($event,'roughsafeplay')">
                                    <label for="roughsafeplay-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="roughsafeplay_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Enjoys swinging on playground swings
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="swinging-yes" name="swinging" value="Yes"
                                        formControlName="swinging" (change)="handleInput($event,'swinging')">
                                    <label for="swinging-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="swinging-no" name="swinging" value="No"
                                        formControlName="swinging" (change)="handleInput($event,'swinging')">
                                    <label for="swinging-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="swinging_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Enjoys playing with new toys
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="newtoysplay-yes" name="newtoysplay" value="Yes"
                                        formControlName="newtoysplay" (change)="handleInput($event,'newtoysplay')">
                                    <label for="newtoysplay-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="newtoysplay-no" name="newtoysplay" value="No"
                                        formControlName="newtoysplay" (change)="handleInput($event,'newtoysplay')">
                                    <label for="newtoysplay-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="newtoysplay_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Able to locate objects you are
                                pointing to
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="locatepointing-yes" name="locatepointing" value="Yes"
                                        formControlName="locatepointing"
                                        (change)="handleInput($event,'locatepointing')">
                                    <label for="locatepointing-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="locatepointing-no" name="locatepointing" value="No"
                                        formControlName="locatepointing"
                                        (change)="handleInput($event,'locatepointing')">
                                    <label for="locatepointing-no">No</label>
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="locatepointing_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Enjoys sitting to look at or listen
                                to a book
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="enjoysitting-yes" name="enjoysitting" value="Yes"
                                        formControlName="enjoysitting" (change)="handleInput($event,'enjoysitting')">
                                    <label for="enjoysitting-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="enjoysitting-no" name="enjoysitting" value="No"
                                        formControlName="enjoysitting" (change)="handleInput($event,'enjoysitting')">
                                    <label for="enjoysitting-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="enjoysitting_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Able to play with one toy or theme
                                for 15 minute periods of time
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="playtoyforawhile-yes" name="playtoyforawhile" value="Yes"
                                        formControlName="playtoyforawhile"
                                        (change)="handleInput($event,'playtoyforawhile')">
                                    <label for="playtoyforawhile-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="playtoyforawhile-no" name="playtoyforawhile" value="No"
                                        formControlName="playtoyforawhile"
                                        (change)="handleInput($event,'playtoyforawhile')">
                                    <label for="playtoyforawhile-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="playtoyforawhile_description">
                        </div>
                    </div>
                </div>
            </div>
            <!-- *PLAY AND SOCIAL SKILLS END-->

            <!-- *SELF-EXPRESSION START-->
            <div class="add-listings-box py-3">
                <div class="d-flex col-lg-6 col-md-12 justify-content-between">
                    <label class="subHeading mb-3">Self-Expression</label>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Happy when not hungry or tired
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="happywhennothungry-yes" name="happywhennothungry"
                                        value="Yes" formControlName="happywhennothungry"
                                        (change)="handleInput($event,'happywhennothungry')">
                                    <label for="happywhennothungry-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="happywhennothungry-no" name="happywhennothungry" value="No"
                                        formControlName="happywhennothungry"
                                        (change)="handleInput($event,'happywhennothungry')">
                                    <label for="happywhennothungry-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="happywhennothungry_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Accustomed to everyday sounds and is
                                usually not startled by them
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="accustomedsounds-yes" name="accustomedsounds" value="Yes"
                                        formControlName="accustomedsounds"
                                        (change)="handleInput($event,'accustomedsounds')">
                                    <label for="accustomedsounds-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="accustomedsounds-no" name="accustomedsounds" value="No"
                                        formControlName="accustomedsounds"
                                        (change)="handleInput($event,'accustomedsounds')">
                                    <label for="accustomedsounds-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="accustomedsounds_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Established and reliable sleeping
                                schedule
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="reliablesleeping-yes" name="reliablesleeping" value="Yes"
                                        formControlName="reliablesleeping"
                                        (change)="handleInput($event,'reliablesleeping')">
                                    <label for="reliablesleeping-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="reliablesleeping-no" name="reliablesleeping" value="No"
                                        formControlName="reliablesleeping"
                                        (change)="handleInput($event,'reliablesleeping')">
                                    <label for="reliablesleeping-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="reliablesleeping_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Able to enjoy a wide variety of
                                touch, noises, and smells
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="widevariety-yes" name="widevariety" value="Yes"
                                        formControlName="widevariety" (change)="handleInput($event,'widevariety')">
                                    <label for="widevariety-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="widevariety-no" name="widevariety" value="No"
                                        formControlName="widevariety" (change)="handleInput($event,'widevariety')">
                                    <label for="widevariety-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="widevariety_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Cries and notices when hurt
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="crieswhenhurt-yes" name="crieswhenhurt" value="Yes"
                                        formControlName="crieswhenhurt" (change)="handleInput($event,'crieswhenhurt')">
                                    <label for="crieswhenhurt-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="crieswhenhurt-no" name="crieswhenhurt" value="No"
                                        formControlName="crieswhenhurt" (change)="handleInput($event,'crieswhenhurt')">
                                    <label for="crieswhenhurt-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="crieswhenhurt_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Able to calm self down after
                                upsetting event
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="selfcalmafterupset-yes" name="selfcalmafterupset"
                                        value="Yes" formControlName="selfcalmafterupset"
                                        (change)="handleInput($event,'selfcalmafterupset')">
                                    <label for="selfcalmafterupset-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="selfcalmafterupset-no" name="selfcalmafterupset" value="No"
                                        formControlName="selfcalmafterupset"
                                        (change)="handleInput($event,'selfcalmafterupset')">
                                    <label for="selfcalmafterupset-no">No</label>
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="selfcalmafterupset_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Able to transition to new environment
                                or activity
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="transitiontonewenv-yes" name="transitiontonewenv"
                                        value="Yes" formControlName="transitiontonewenv"
                                        (change)="handleInput($event,'transitiontonewenv')">
                                    <label for="transitiontonewenv-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="transitiontonewenv-no" name="transitiontonewenv" value="No"
                                        formControlName="transitiontonewenv"
                                        (change)="handleInput($event,'transitiontonewenv')">
                                    <label for="transitiontonewenv-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="transitiontonewenv_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Able to pay attention and is not
                                distracted by sounds not noticed by others
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="nodistractionbysound-yes" name="nodistractionbysound"
                                        value="Yes" formControlName="nodistractionbysound"
                                        (change)="handleInput($event,'nodistractionbysound')">
                                    <label for="nodistractionbysound-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="nodistractionbysound-no" name="nodistractionbysound"
                                        value="No" formControlName="nodistractionbysound"
                                        (change)="handleInput($event,'nodistractionbysound')">
                                    <label for="nodistractionbysound-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="nodistractionbysound_description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Able to cope with an unexpected
                                change
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="copeunexpectedchange-yes" name="copeunexpectedchange"
                                        value="Yes" formControlName="copeunexpectedchange"
                                        (change)="handleInput($event,'copeunexpectedchange')">
                                    <label for="copeunexpectedchange-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="copeunexpectedchange-no" name="copeunexpectedchange"
                                        value="No" formControlName="copeunexpectedchange"
                                        (change)="handleInput($event,'copeunexpectedchange')">
                                    <label for="copeunexpectedchange-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="copeunexpectedchange_description">
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- *SELF-EXPRESSION END-->
        <div class="add-listings-btn" style="text-align: right">
            <button type="submit" [disabled]="!flag">Submit</button>
        </div>


        <!-- FORM SECTION END -->
    </form>

    <p-dialog id="capturewin"  header="Capture Photo" [(visible)]="openCameraforUpload" [modal]="true" [draggable]="false"
        [closable]="false" [resizable]="false">
        <div>
            <button type="button" class="close-button" (click)="shutDownCamera()" aria-label="Close"></button>
            <video #video autoplay="true" playsinline="true"></video>
            <canvas #canvas style="display:none;"></canvas>
        </div>
        <div>
            <a class="captureButton" (click)="capture()"><i class="fas fa-camera"></i>
            </a>
        </div>
    </p-dialog>


    <!-- <p-dialog header="captured Photo" [(visible)]="imagePreview"
[modal]="true"
[draggable]="false" [closable]="false" [resizable]="false">
<div>
    <button type="button" class="close-button" (click)="closePreview()" aria-label="Close"></button>

    <img [src]="imgSrcForPreview" alt="">
</div>
<div style="cursor: pointer;">
    <a (click)="deletecapture(imgSrcForPreview)">Delete</a>
</div>

</p-dialog> -->
    <app-copyrights></app-copyrights>
</div>