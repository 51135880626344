import { Component, OnInit } from '@angular/core';
import {
  FormArray, FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { CommonApiService } from 'src/app/services/common-api.service';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { UtilityTsService } from 'src/app/services/utility.ts.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MasterService } from 'src/app/services/master.service'


@Component({
  selector: 'app-extra-activities',
  templateUrl: './extra-activities.component.html',
  styleUrls: ['./extra-activities.component.scss']
})
export class ExtraActivitiesComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Extra Activities',
      subTitle: 'Dashboard',
    },
  ];
  getToken: any;
  getRole: any;
  getname: any;
  extraActivities: FormGroup;
  public flag: boolean = true;
  babyDetails: any = [];
  frequency: any = [];
  date: any;
  selectedDate: Date = null; // Initialize with today's date
  month: any = '';
  years: any = '';
  days: any = '';
  babyGender: any;
  babyname: string;
  isedit: boolean;
  permissionData: any = [];
  permissions: any = [];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private messageService: MessageService,
    private common_service: CommonApiService,
    private utility_service: UtilityTsService,
    private spinner: NgxSpinnerService,
    private masterService: MasterService
  ) {
    this.getAllUserPermission()
    this.extraActivities = this.fb.group({
      childroom: this.fb.array([]),
      accessories: this.fb.array([]),
      amenities: this.fb.array([]),
      others: this.fb.array([]),
      OutdoorActivitys: this.fb.array([]),
      baby_details_id: new FormControl(null, [Validators.required]),
      remarks: new FormControl(null),
    })
  }

  logout() {
    localStorage.clear();
    // window.location.reload();
    this.router.navigate(['/']);
  }
  ngOnInit(): void {
    this.spinner.show()
    // this.getEditFormData();
    this.common_service.getBabyDetails().subscribe((res: any) => {
      this.spinner.hide()

      this.babyDetails = res?.data;
      // For frequency dropdown list.
      for (let i = 1; i <= 10; i++) {
        this.frequency.push({ frequency: i });
      }
      // 
      //  this code their is set value is done for baby name gender date of birth and it done by suraj on 14-03-2024
      // 
      // 
      
      if (localStorage.getItem('extraactivity')) {
        this.isedit = true;
        this.getEditFormData();
      }
      else if (localStorage.getItem('create')) {
        const getEdit = localStorage.getItem('create');

        this.extraActivities.controls.baby_details_id.setValue(JSON.parse(getEdit));
        this.isedit = false;
        this.getBabyDetails({ value: getEdit })
        this.addchildroom('add', 0);
        this.addAccessories('add', 0);
        this.addAmenities('add', 0);
        this.addothers('add', 0);
        this.addOutdoorActivity('add', 0);
      }
    });
    this.getname = localStorage.getItem('name');
    this.getToken = localStorage.getItem('token');
    this.getRole = localStorage.getItem('role')

  }

  get Childroom(): FormArray {
    return this.extraActivities.get('childroom') as FormArray;
  }

  get Accessories(): FormArray {
    return this.extraActivities.get('accessories') as FormArray;
  }

  get Amenities(): FormArray {
    return this.extraActivities.get('amenities') as FormArray;
  }

  get Others(): FormArray {
    return this.extraActivities.get('others') as FormArray;
  }

  get OutdoorActivitys(): FormArray {
    return this.extraActivities.get('OutdoorActivitys') as FormArray;
  }

  getEditFormData() {
    this.spinner.show()
    const getEdit = localStorage.getItem('extraactivity')
    this.common_service.getEditChildSafety(getEdit).then((res: any) => {
      this.spinner.hide()
      const detailschildsafety = res.data[0].child_safety_json;

      this.babyGender = detailschildsafety?.gender;
      this.selectedDate = detailschildsafety?.date_of_birth;
      this.days = detailschildsafety?.days;
      this.month = detailschildsafety?.months;
      this.years = detailschildsafety?.years;
      // childroom
      for (let index = 0; index < detailschildsafety.childroom.length; index++) {

        let childroomArray = this.getcontainer('childroom');

        let newrequirementArray = this.fb.group({});
        newrequirementArray.addControl(`requireChanges_${index}`, new FormControl(detailschildsafety.childroom[index][`requireChanges_${index}`]));
        newrequirementArray.addControl(`requirement`, new FormControl(detailschildsafety.childroom[index][`requirement`]));
        newrequirementArray.addControl(`loosePart_${index}`, new FormControl(detailschildsafety.childroom[index][`loosePart_${index}`]));
        newrequirementArray.addControl(`identifyParts`, new FormControl(detailschildsafety.childroom[index][`identifyParts`]));
        newrequirementArray.addControl(`Washroom_fallRisk_${index}`, new FormControl(detailschildsafety.childroom[index][`Washroom_fallRisk_${index}`]));
        newrequirementArray.addControl(`washroom_risk`, new FormControl(detailschildsafety.childroom[index][`washroom_risk`]));

        childroomArray.insert(index + 1, newrequirementArray);
      }

      // Accessories

      for (let index = 0; index < detailschildsafety.accessories.length; index++) {

        let accessoriesArray = this.getcontainer('accessories');
        let newaccessoriesArray = this.fb.group({});

        newaccessoriesArray.addControl(`baby_stroller_${index}`, new FormControl(detailschildsafety.accessories[index][`baby_stroller_${index}`]));
        newaccessoriesArray.addControl(`baby_stroller_remark`, new FormControl(detailschildsafety.accessories[index]['baby_stroller_remark']));
        newaccessoriesArray.addControl(`toys_${index}`, new FormControl(detailschildsafety.accessories[index][`toys_${index}`]));
        newaccessoriesArray.addControl(`toys_remarks`, this.fb.array([]));
        newaccessoriesArray.addControl(`child_seat_${index}`, new FormControl(detailschildsafety.accessories[index][`child_seat_${index}`]));
        newaccessoriesArray.addControl(`child_seat_remark`, new FormControl(detailschildsafety.accessories[index][`child_seat_remark`]));

        accessoriesArray.insert(index + 1, newaccessoriesArray);


        for (let i = 0; i < detailschildsafety.accessories[index]['toys_remarks'].length; i++) {
          let remarkArray = this.getremark(index);
          let newremarkArray = this.fb.group({
            toys_remark: new FormControl(detailschildsafety.accessories[index]['toys_remarks'][i].toys_remark),
          });
          remarkArray.insert(i, newremarkArray); // Use just 'i' instead of 'index + 1'
        }

        // this.addremark('add',0,accessoriesArray.length-1);

      }

      // amenities
      for (let index = 0; index < detailschildsafety.amenities.length; index++) {
        let amenitiesArray = this.getcontainer('amenities');


        let newamenitiesArray = this.fb.group({});
        newamenitiesArray.addControl(`outdoor_playground_${index}`, new FormControl(detailschildsafety.amenities[index][`outdoor_playground_${index}`]));
        newamenitiesArray.addControl(`playground_remark`, new FormControl(detailschildsafety.amenities[index][`playground_remark`]));
        newamenitiesArray.addControl(`slides_${index}`, new FormControl(detailschildsafety.amenities[index][`slides_${index}`]));
        newamenitiesArray.addControl(`slides_remark`, new FormControl(detailschildsafety.amenities[index][`slides_remark`]));
        newamenitiesArray.addControl(`swimming_pool_${index}`, new FormControl(detailschildsafety.amenities[index][`swimming_pool_${index}`]));
        newamenitiesArray.addControl(`swimming_pool_remark`, new FormControl(detailschildsafety.amenities[index][`swimming_pool_remark`]));
        newamenitiesArray.addControl(`play_${index}`, new FormControl(detailschildsafety.amenities[index][`play_${index}`]));
        newamenitiesArray.addControl(`play_remark`, new FormControl(detailschildsafety.amenities[index][`play_remark`]));
        newamenitiesArray.addControl(`tricycle_${index}`, new FormControl(detailschildsafety.amenities[index][`tricycle_${index}`]));
        newamenitiesArray.addControl(`tricycle_remark`, new FormControl(detailschildsafety.amenities[index][`tricycle_remark`]));
        newamenitiesArray.addControl(`scooter_${index}`, new FormControl(detailschildsafety.amenities[index][`scooter_${index}`]));
        newamenitiesArray.addControl(`scooter_remark`, new FormControl(detailschildsafety.amenities[index][`scooter_remark`]));

        amenitiesArray.insert(index + 1, newamenitiesArray);
      }

      // others
      for (let index = 0; index < detailschildsafety.others.length; index++) {
        let othersArray = this.getcontainer('others');
        let newothersArray = this.fb.group({});
        newothersArray.addControl(`pets_${index}`, new FormControl(detailschildsafety.others[index][`pets_${index}`]))
        newothersArray.addControl(`pets_remark`, new FormControl(detailschildsafety.others[index][`pets_remark`]))
        othersArray.insert(index + 1, newothersArray);
      }

      // outdoor activity
      for (let index = 0; index < detailschildsafety.OutdoorActivitys.length; index++) {

        let outddoorArray = this.getcontainer('outdoorActivity');
        let newoutdoorArray = this.fb.group({});
        newoutdoorArray.addControl(`park_${index}`, new FormControl(detailschildsafety.OutdoorActivitys[index][`park_${index}`]));
        newoutdoorArray.addControl(`park_remark`, new FormControl(detailschildsafety.OutdoorActivitys[index][`park_remark`]));
        newoutdoorArray.addControl(`mall_${index}`, new FormControl(detailschildsafety.OutdoorActivitys[index][`mall_${index}`]));
        newoutdoorArray.addControl(`mall_remark`, new FormControl(detailschildsafety.OutdoorActivitys[index][`mall_remark`]));
        newoutdoorArray.addControl(`supermarket_${index}`, new FormControl(detailschildsafety.OutdoorActivitys[index][`supermarket_${index}`]));
        newoutdoorArray.addControl(`supermarket_remark`, new FormControl(detailschildsafety.OutdoorActivitys[index][`supermarket_remark`]));

        outddoorArray.insert(index + 1, newoutdoorArray);
      }

      this.extraActivities.get('baby_details_id').setValue(detailschildsafety.baby_details_id);
      this.getBabyDetails({ value: detailschildsafety.baby_details_id });

      // remark
      this.extraActivities.get('remarks').setValue(detailschildsafety.remarks);

      // this.immunizationForm.get('bcg_brand').setValue(detailshealthmonitoring.immunization_json.bcg_brand);

      // this.heathMonitoringForm.get('baby_details_id').setValue(detailshealthmonitoring.baby_details_id);
      // this.heathMonitoringForm.get('create_time').setValue(new Date(detailshealthmonitoring.create_time));
      // this.heathMonitoringForm.get('sick_or_not').setValue(detailshealthmonitoring.sick_or_not);

    })
  }

  // duplicate child room fields 
  addchildroom(string: any, index: any) {
    // add field for requirement 
    let childroomArray = this.getcontainer('childroom');
    if (string == 'add') {
      let newrequirementArray = this.fb.group({});
      newrequirementArray.addControl(`requireChanges_${index}`, new FormControl(null));
      newrequirementArray.addControl(`requirement`, new FormControl(null));
      newrequirementArray.addControl(`loosePart_${index}`, new FormControl(null));
      newrequirementArray.addControl(`identifyParts`, new FormControl(null));
      newrequirementArray.addControl(`Washroom_fallRisk_${index}`, new FormControl(null));
      newrequirementArray.addControl(`washroom_risk`, new FormControl(null));

      childroomArray.insert(index, newrequirementArray);
    } else {
      childroomArray.removeAt(index);
    }
  }
  // addchildroom(action: string, index: number) {
  //   const childroomArray = this.getcontainer('childroom');

  //   if (action === 'add') {
  //     const newRequirementArray = this.fb.group({
  //       [`requireChanges_${index}`]: new FormControl(null),
  //       'requirement': new FormControl(null),
  //       [`loosePart_${index}`]: new FormControl(null),
  //       'identifyParts': new FormControl(null),
  //       [`Washroom_fallRisk_${index}`]: new FormControl(null),
  //       'washroom_risk': new FormControl(null)
  //     });

  //     childroomArray.insert(index, newRequirementArray);
  //   } else {
  //     childroomArray.removeAt(index);
  //   }
  // }


  // add duplicate functionality for Accessories
  addAccessories(string: any, index: any) {
    // add field for requirement 
    let accessoriesArray = this.getcontainer('accessories');
    if (string == 'add') {
      let newaccessoriesArray = this.fb.group({});
      newaccessoriesArray.addControl(`baby_stroller_${index}`, new FormControl(null));
      newaccessoriesArray.addControl(`baby_stroller_remark`, new FormControl(null));
      newaccessoriesArray.addControl(`toys_${index}`, new FormControl(null));
      newaccessoriesArray.addControl(`toys_remarks`, this.fb.array([]));
      newaccessoriesArray.addControl(`child_seat_${index}`, new FormControl(null));
      newaccessoriesArray.addControl(`child_seat_remark`, new FormControl(null));

      accessoriesArray.insert(index, newaccessoriesArray);

      this.addremark('add', 0, accessoriesArray.length - 1);

    } else {
      accessoriesArray.removeAt(index);
    }
  }
  getAllUserPermission() {
    let id = localStorage.getItem('user_id');
    this.masterService.getUserPremissionById(id).then((res: any) => {

      this.permissionData = res.data;

      for (let i = 0; i < this.permissionData.length; i++) {
        
        this.permissions.push(this.permissionData[i].permission_id);
      }
    })
  }

  checkPermission(permission: number) {
   
    if (this.permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
    // return this.permissions.includes(permission);
  }
  // add duplicate Amenities 
  addAmenities(string: any, index: any) {
    let amenitiesArray = this.getcontainer('amenities');
    if (string == 'add') {
      let newamenitiesArray = this.fb.group({});
      newamenitiesArray.addControl(`outdoor_playground_${index}`, new FormControl(null));
      newamenitiesArray.addControl(`playground_remark`, new FormControl(null));
      newamenitiesArray.addControl(`slides_${index}`, new FormControl(null));
      newamenitiesArray.addControl(`slides_remark`, new FormControl(null));
      newamenitiesArray.addControl(`swimming_pool_${index}`, new FormControl(null));
      newamenitiesArray.addControl(`swimming_pool_remark`, new FormControl(null));
      newamenitiesArray.addControl(`play_${index}`, new FormControl(null));
      newamenitiesArray.addControl(`play_remark`, new FormControl(null));
      newamenitiesArray.addControl(`tricycle_${index}`, new FormControl(null));
      newamenitiesArray.addControl(`tricycle_remark`, new FormControl(null));
      newamenitiesArray.addControl(`scooter_${index}`, new FormControl(null));
      newamenitiesArray.addControl(`scooter_remark`, new FormControl(null));
      amenitiesArray.insert(index, newamenitiesArray);

    } else {
      amenitiesArray.removeAt(index);
    }
  }

  // add duplicate others field 
  addothers(string: any, index: any) {
    let othersArray = this.getcontainer('others');
    if (string == 'add') {
      let newothersArray = this.fb.group({});
      newothersArray.addControl(`pets_${index}`, new FormControl(null))
      newothersArray.addControl(`pets_remark`, new FormControl(null))

      othersArray.insert(index, newothersArray);
    } else {
      othersArray.removeAt(index);
    }
  }

  // add duplicate outdoor activity
  addOutdoorActivity(string: any, index: any) {
    let outddoorArray = this.getcontainer('outdoorActivity');
    if (string == 'add') {
      let newoutdoorArray = this.fb.group({});
      newoutdoorArray.addControl(`park_${index}`, new FormControl(null));
      newoutdoorArray.addControl(`park_remark`, new FormControl(null));
      newoutdoorArray.addControl(`mall_${index}`, new FormControl(null));
      newoutdoorArray.addControl(`mall_remark`, new FormControl(null));
      newoutdoorArray.addControl(`supermarket_${index}`, new FormControl(null));
      newoutdoorArray.addControl(`supermarket_remark`, new FormControl(null));

      outddoorArray.insert(index, newoutdoorArray);
    } else {
      outddoorArray.removeAt(index);
    }
  }

  // add remark functionality for toys in side Accessories 
  addremark(string: any, index: any, parentindex: number) {
    let remarkArray = this.getremark(parentindex);
    if (string == 'add') {
      let newremarkArray = this.fb.group({
        toys_remark: new FormControl(null),
      });

      remarkArray.insert(index, newremarkArray);
    } else {
      remarkArray.removeAt(index);
    }
  }

  // get remark array of toys
  getremark(index: number) {
    return (this.extraActivities.get('accessories') as FormArray).at(index).get('toys_remarks') as FormArray;
  }

  // get function for all form controls 
  getcontainer(container: any) {
    if (container == 'childroom') {
      return this.extraActivities.get('childroom') as FormArray;
    }
    if (container == 'accessories') {
      return this.extraActivities.get('accessories') as FormArray;
    }
    if (container == 'amenities') {
      return this.extraActivities.get('amenities') as FormArray;
    }
    if (container == 'others') {
      return this.extraActivities.get('others') as FormArray;
    }
    if (container == 'outdoorActivity') {
      return this.extraActivities.get('OutdoorActivitys') as FormArray;
    }
  }
  softReload() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['portal/patient-view']);
    });
  }

  saveeExtraActivities(formSubmitted: any) {
    this.spinner.show()
    let data = {
      baby_details_id: this.extraActivities.get('baby_details_id').value,
      child_safety_json: this.extraActivities.value,
    }
    data.child_safety_json.name = this.babyname;
    data.child_safety_json.date_of_birth = this.selectedDate
    data.child_safety_json.gender = this.babyGender;


    window.scroll(0, 0)
    if (formSubmitted.valid) {
      // this.spinner.show();
      if (this.isedit) {
        let id = localStorage.getItem('extraactivity');
        this.common_service.putEditChildSafety(id, data)
          .then((res: any) => {
            this.spinner.hide()
            this.messageService.add({
              severity: 'success', summary: 'Success', detail: res?.message,
            }
            );
            setTimeout(() => {
              this.router.navigateByUrl('/portal/first-screen')

            }, 400);
            // this.spinner.hide();
          },
            (error: any) => {
              this.spinner.hide()

              // Handle any errors that occurred during the API call
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: error?.message,
              });
              // this.spinner.hide();
            }
          );
      }
      else {
        this.common_service.postEnviromentalChildSafety(data)
          .then((res: any) => {
            this.spinner.hide()
           
            this.messageService.add({
              severity: 'success', summary: 'Success', detail: res?.message,
            });
            setTimeout(() => {
              this.router.navigateByUrl('/portal/first-screen')
            }, 400);
            // this.spinner.hide();

          }, (error: any) => {
            this.spinner.hide()

          
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error?.message,
            });
            // this.spinner.hide();
          }
          );
      }
    } else {
      this.spinner.hide()

      this.messageService.add({
        severity: 'error', summary: 'Error', detail: 'Please Fill All the Details!',
      });
    }
  }
  getBabyDetails(event: any) {
    let data = this.babyDetails?.filter((res: any) => res?.id == event?.value);
    const dateParts = data[0]?.date_of_birth.split('/');
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Months are 0-indexed in JavaScript
    const year = parseInt(dateParts[2], 10);
    let firstLetter = data[0]?.gender.toLowerCase();
    let capitalize =
      firstLetter.slice(0, 1).toUpperCase() +
      firstLetter.slice(1, firstLetter.length);
    this.babyGender = capitalize;
    this.selectedDate = data[0].date_of_birth
    this.babyname = data[0].name;
    // Manually parse the date string in DD/MM/YYYY format
    const selectedDate = moment([year, month, day]);
    const today = moment();

    // Calculate the difference in years, months, and days
    const years = today.diff(selectedDate, 'years');
    selectedDate.add(years, 'years');

    const months = today.diff(selectedDate, 'months');
    selectedDate.add(months, 'months');

    const days = today.diff(selectedDate, 'days');
    this.years = years;
    this.month = months;
    this.days = days;
  }

}