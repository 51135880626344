import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NanyCarePlansService {
  apiBaseUrl: string = environment.api_base_url;
  constructor(private http: HttpClient) { }
  /* -------------------------------------------------------------------------- */
  /*               // get nanny care plans function                    */
  /* -------------------------------------------------------------------------- */
  getNannyCarePlans() {
    return this.http.get(this.apiBaseUrl + 'get-all-nannyplan');
  }
   /* --------------------------------------------------------------------------*/
  /*               // Create nanny care plans function                    */
  /* -------------------------------------------------------------------------- */
  createNannyCarePlans(data?: any) {
    return this.http.post(this.apiBaseUrl + 'nanny-planCreate',data).toPromise();
  }

    /* --------------------------------------------------------------------------*/
  /*               // Update nanny care plans function                    */
  /* -------------------------------------------------------------------------- */

  editNannyCarePlans(data?: any, id?:any) {
    return this.http.put(this.apiBaseUrl + `update-nannyplan/${id}`,data);
  }

    /* --------------------------------------------------------------------------*/
  /*               // Delete nanny care plans function                    */
  /* -------------------------------------------------------------------------- */

  deleteNannyCarePlans(id?:any, deleted?:any) {
    return this.http.put(this.apiBaseUrl + `/delete-nanny-plan/${id}`,deleted);
  }
}
