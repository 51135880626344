<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->


<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column viewbox">


	<app-dashboard-navbar></app-dashboard-navbar>


	<!-- Breadcrumb Area -->
	<div *ngFor="let Content of breadcrumb;" class="breadcrumb-area">
		<h1>{{Content.title}}</h1>
		<ol class="breadcrumb">
			<li class="item">
				<a routerLink="/">Home</a>
			</li>
			<li class="item">
				{{Content.subTitle}}
			</li>
			<li class="item">{{Content.title}}</li>
		</ol>
	</div>
	<div class="add-listings-box">
		<div class="Master-view-box">
			<div class="theader d-flex justify-content-between flex-wrap">
				<div class="d-flex mb-2">
					<span class="p-input-icon-left ml-auto mr-2 w-100%">
						<i class="pi pi-search"></i>
						<input (input)="dt1.filterGlobal($event.target.value, 'contains')" pInputText type="text"
							class="form-control " style="text-align: left; padding-left: 20%; height: 45px !important"
							scriptInjection placeholder="Search Keyword" [(ngModel)]="filterVal">
					</span>
					<p-button type="button" class="mx-2" icon="bx bx-reset" tooltipPosition="bottom"
						(click)="reset(dt1)"></p-button>
				</div>
				<div class="add-listings-btn mb-0" style="text-align: right;" *ngIf="checkPermission(35)">
					<a (click)="getbabyhealthdata()" class="nav-link">
						<button type="submit">Add</button>
					</a>
				</div>
			</div>
		</div>

		<div class="row mt-4">
			<div class="col-lg-12 col-md-12">
				<p-table #dt1 [value]="childSafetyData" [rows]="10" [showCurrentPageReport]="true"
					[tableStyle]="{'min-width': '50rem', 'width' : '100%'}" [rowsPerPageOptions]="[10,25,50]"
					[loading]="loading" [paginator]="true" [globalFilterFields]="['name', 'date_of_birth', 'age']"
					dataKey="id" styleClass="p-datatable-sm p-datatable-customers" responsiveLayout="scroll"
					currentPageReportTemplate="Showing {first} to
					{last} of {totalRecords} entries" editMode="row">
					<ng-template pTemplate="header">
						<tr>
							<th> </th>
							<th style="min-width: 62px">S. No.</th>
							<th>Name</th>
							<th>Date of Birth</th>
							<th>Days</th>
							<th>Months</th>
							<th>Years</th>
						</tr>

					</ng-template>
					<!-- tables to show data -->
					<ng-template pTemplate="body" let-list let-expanded="expanded" let-i="rowIndex">
						<tr>
							<td>
								<button type="button" pButton pRipple [pRowToggler]="list"
									class="p-button-text p-button-rounded p-button-plain"
									[icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
									(click)="getData(list.id)"></button>
							</td>
							<td>{{ i + 1 }}</td>
							<td>
								{{ list.name | titlecase }}
							</td>
							<td>
								{{ list.date_of_birth}}
							</td>
							<td>
								{{ list.days }}
							</td>
							<td>
								{{ list.months }}
							</td>
							<td>
								{{ list.years }}
							</td>
						</tr>
					</ng-template>
					<ng-template pTemplate="emptymessage">
						<tr>
							<td colspan="8">No Data Found.</td>
						</tr>
					</ng-template>
					<ng-template pTemplate="rowexpansion" let-list>
						<tr>
							<td colspan="7" class="inner-data">
								<div class="p-3 py-0">
									<p-table [value]="list.environment_child_safety
                            " dataKey="id">
										<ng-template pTemplate="header">
						<tr>

							<th pSortableColumn="id" class="serial-width">Sr No.</th>
							<th pSortableColumn="customer">Action</th>
							<th pSortableColumn="date">Date</th>

						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-order let-i="rowIndex" let-expanded="expanded">
						<tr>
							<td>{{i+1}}</td>
							<td><button pButton pRipple class="p-button-rounded p-button-text p-button-info"
									pTooltip="View" tooltipPosition="bottom"
									(click)="showMaximizableDialog(order.id)"><i class="far fa-eye"></i></button>
								<button pButton pRipple class="p-button-rounded p-button-icon" pTooltip="Edit"
									tooltipPosition="bottom" (click)="editOnCLick(order.id)"> <i
										class="far fa-pencil"></i>
								</button>
							</td>
							<td>
								{{ order?.created_at?(order?.created_at | date: 'dd-MM-yyyy' ): "-" }}
							</td>
						</tr>
					</ng-template>
					<ng-template pTemplate="emptymessage">
						<tr>
							<td colspan="6">There are no order for this product yet.</td>
						</tr>
					</ng-template>
				</p-table>
			</div>
			</td>
			</tr>
			</ng-template>
			</p-table>
		</div>
		<!-- --------------------------------------------------------- view dialog ------------------------------------------------------- -->
		<!-- ***************************************************************************************************************** -->
		<p-dialog [(visible)]="displayMaximizable" [modal]="true" [style]="{ width: '90vw' }" [draggable]="false"
			[closable]="false" [resizable]="false" header="Baby Details">
			<!-- Start -->
			<ng-container>
				<p-accordion class="mt-3">
					<div class="row ">
						<div class="col-lg-12">
							<div class="my-profile-box">
								<form>
									<!-- Baby detail -->
									<div class="row viewSummary">
										<div class="col-xl-4 col-lg-12
										col-md-12">
											<div class="form-group">
												<label>Name :</label>
												<div type="text" readonly>
													{{babyData?.name??'-' | titlecase }}
												</div>
											</div>
										</div>
										<div class="col-xl-4 col-lg-12
                                                col-md-12">
											<div class="form-group">
												<label>Gender :</label>
												<div type="text" readonly>
													{{babyData?.gender??'-' | titlecase }}
												</div>
											</div>
										</div>
										<div class="col-xl-4 col-lg-12
										col-md-12">
											<div class="form-group">
												<label>Date of Birth :</label>
												<div type="text" readonly>
													{{babyData?.date_of_birth ?? '-'}}
												</div>
											</div>
										</div>
									</div>
									<div class="row viewSummary">
										<div class="                                                col-xl-4 col-lg-12
										col-md-12">
											<div class="form-group">
												<label>Days :</label>
												<div type="text" readonly>
													{{babyData?.days ?? '-'}}
												</div>
											</div>
										</div>
										<div class="col-xl-4 col-lg-12 col-md-12">
											<div class="form-group">
												<label>Months :</label>
												<div type="text" readonly>
													{{babyData?.months ?? '-'}}
												</div>
											</div>
										</div>
										<div class="col-xl-4 col-lg-12 col-md-12">
											<div class="form-group">
												<label>Years :</label>
												<div type="text" readonly>
													{{babyData?.years ?? '-'}}
												</div>
											</div>
										</div>
									</div>

									<div class="row">
										<p-accordionTab header="Child's Room" class="padfit">
											<div *ngFor="let dataChildren of children; let i = index">
												<!-- <h3 class="fw-bold">Child's Room</h3> -->
												<div class="row p-0  p-s-auto s-auto">
													<div class="col-md-6 col-lg-4">
														<!-- <div class="form-group">
                                                    <label>Requires Changes?</label>
                                                    <input type="text" readonly class="form-control" value="{{ require?.answer== 1? 'Yes': '-' ?? '-'
                                                    }}" />
                                                </div> -->

														<div class="form-group viewSummary">
															<label for>Requires Changes?</label>
															<div type="text" readonly>
																{{dataChildren['requireChanges_'+i]}}
															</div>
															<ng-template #elseBlock>
																<div type="text" readonly>
																	{{"-"}}
																</div>
															</ng-template>
														</div>

													</div>
													<div class="col-md-6 col-lg-4">
														<div class="form-group viewSummary">
															<label>
																Identify Requirements
															</label>
															<div type="text" readonly>{{ dataChildren?.requirement ?? '-'
                                                    }}</div>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6 col-lg-4">
														<div class="form-group viewSummary">
															<label for>
																Any Loose Part Or Sharp Edges?
															</label>
															<div type="text" readonly>
																{{dataChildren['loosePart_'+i]}}
															</div>
															<!-- <ng-template #elseBlock>
															<input type="text" readonly class="form-control" value="-">
														</ng-template> -->
														</div>
													</div>
													<div class="col-md-6 col-lg-4">
														<div class="form-group viewSummary">
															<label>Identify Parts</label>
															<div type="text" readonly>{{ dataChildren?.identifyParts ?? '-'
															}}</div>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6 col-lg-4">
														<div class="form-group viewSummary">
															<label for>Washroom(Fall Risk)</label>
															<div type="text" readonly>
																{{dataChildren['Washroom_fallRisk_'+i]}}
															</div>
															<!-- <ng-template #elseBlock>
															<input type="text" readonly class="form-control" value="-">
														</ng-template> -->
														</div>

													</div>
													<div class="col-md-6 col-lg-4">
														<div class="form-group viewSummary">
															<label>Identify Risks</label>
															<div type="text" readonly>{{ dataChildren?.washroom_risk ?? '-'
															}}</div>
														</div>
													</div>
												</div>
											</div>
										</p-accordionTab>
										<p-accordionTab header="Accessories" class="padfit">
											<div *ngFor="let dataAccessories of accessories; let i = index">
												<div class="row">
													<div class="col-xl-4 col-lg-12od P
											col-md-12">
														<div class="form-group viewSummary">
															<label for>
																Baby Stroller Available?
															</label>
															<div type="text" readonly>
																{{dataAccessories['baby_stroller_'+i]}}
															</div>
															<ng-template #elseBlock>
																<div type="text" readonly>
																	{{"-"}}
																</div>
															</ng-template>
														</div>

													</div>

													<div class="col-md-6 col-lg-4">
														<div class="form-group viewSummary">
															<label>Identify Risks</label>
															<div type="text" readonly>{{ dataAccessories?.baby_stroller_remark ?? '-'
															}}</div>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6 col-lg-4">
														<div class="form-group viewSummary">
															<label for>Toys :</label>
															<div type="text" readonly>
																{{dataAccessories['toys_'+i]}}
															</div>
															<ng-template #elseBlock>
																<div type="text" readonly>
																	{{"-"}}
																</div>
															</ng-template>
														</div>
													</div>
													<div class="col-md-6 col-lg-4"
														*ngFor="let remarks of dataAccessories.toys_remarks; let i = index">
														<div class="form-group viewSummary">
															<label>Kind :</label>
															<div type="text" readonly>
																{{remarks?.toys_remark ?? '-'}}
															</div>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6 col-lg-4">
														<div class="form-group viewSummary">
															<label for>
																Child seat available in the car?
															</label>
															<div type="text" readonly>
																{{dataAccessories['child_seat_'+i]}}
															</div>
															<ng-template #elseBlock>
																<div type="text" readonly>
																	{{"-"}}
																</div>
															</ng-template>
														</div>
													</div>
													<div class="col-md-6 col-lg-4">
														<div class="form-group viewSummary">
															<label>Other Concerns: :</label>
															<div type="text" readonly>
																{{dataAccessories?.child_seat_remark ?? '-'}}
															</div>
														</div>
													</div>
												</div>
											</div>
										</p-accordionTab>
										<p-accordionTab header="Amenities" class="padfit">
											<div *ngFor="let dataAmenities of amenitiesValue; let i = index">
												<div class="row">
													<div class="col-md-6 col-lg-4">
														<div class="form-group viewSummary">
															<label for>
																Outdoor Playground :
															</label>
															<div type="text" readonly>
																{{dataAmenities['outdoor_playground_'+i]}}
															</div>
															<!-- <ng-template #elseBlock>
															<input type="text" readonly class="form-control" value="-">
														</ng-template> -->
														</div>
													</div>
													<div class="col-md-6 col-lg-4">
														<div class="form-group viewSummary">
															<label>Other Concerns: :</label>
															<div type="text" readonly>
																{{dataAmenities?.playground_remark ?? '-'}}
															</div>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6 col-lg-4">
														<div class="form-group viewSummary">
															<label for>Slides :</label>
															<div type="text" readonly>
																{{dataAmenities['slides_'+i]}}
															</div>
															<ng-template #elseBlock>
																<div type="text" readonly>
																	{{"-"}}
																</div>
															</ng-template>
														</div>
													</div>
													<div class="col-md-6 col-lg-4">
														<div class="form-group viewSummary">
															<label>Other Concerns: :</label>
															<div type="text" readonly>
																{{dataAmenities?.slides_remark ?? '-'}}
															</div>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6 col-lg-4">
														<div class="form-group viewSummary">
															<label for>Swimming Pool :</label>
															<div type="text" readonly>
																{{dataAmenities['swimming_pool_'+i]}}
															</div>
															<!-- <ng-template #elseBlock>
															<input type="text" readonly class="form-control" value="-">
														</ng-template> -->
														</div>
													</div>
													<div class="col-md-6 col-lg-4">
														<div class="form-group viewSummary">
															<label>Other Concerns: :</label>
															<div type="text" readonly>
																{{dataAmenities?.swimming_pool_remark ?? '-'}}
															</div>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6 col-lg-4">
														<div class="form-group viewSummary">
															<label for>Play</label>
															<div type="text" readonly>
																{{dataAmenities['play_'+i]}}
															</div>
															<!-- <ng-template #elseBlock>
															<input type="text" readonly class="form-control" value="-">
														</ng-template> -->
														</div>
													</div>
													<div class="col-md-6 col-lg-4">
														<div class="form-group viewSummary">
															<label>Other Concerns: :</label>
															<div type="text" readonly>
																{{dataAmenities?.play_remark ?? '-'}}
															</div>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6 col-lg-4">
														<div class="form-group viewSummary">
															<label for>Tricycle</label>
															<div type="text" readonly>
																{{dataAmenities['tricycle_'+i]}}
															</div>
															<!-- <ng-template #elseBlock>
															<input type="text" readonly class="form-control" value="-">
														</ng-template> -->
														</div>
													</div>
													<div class="col-md-6 col-lg-4">
														<div class="form-group viewSummary">
															<label>Other Concerns: :</label>
															<div type="text" readonly>
																{{dataAmenities?.tricycle_remark ?? '-'}}
															</div>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6 col-lg-4">
														<div class="form-group viewSummary">
															<label for>Scooter</label>
															<div type="text" readonly>
																{{dataAmenities['scooter_'+i]}}
															</div>
															<!-- <ng-template #elseBlock>
															<input type="text" readonly class="form-control" value="-">
														</ng-template> -->
														</div>
													</div>
													<div class="col-md-6 col-lg-4">
														<div class="form-group viewSummary">
															<label>Other Concerns: :</label>
															<div type="text" readonly>
																{{dataAmenities?.scooter_remark ?? '-'}}
															</div>
														</div>
													</div>
												</div>
											</div>
										</p-accordionTab>
										<p-accordionTab header="Other" class="padfit">
											<div class="row" *ngFor="let dataOther of otherValue ; let i = index">
												<div class="col-md-6 col-lg-4">

													<div class="form-group viewSummary">
														<label for>Pets :</label>
														<div type="text" readonly>
															{{dataOther['pets_'+i]}}
														</div>
														<!-- <ng-template #elseBlock>
															<input type="text" readonly class="form-control" value="-">
														</ng-template> -->
													</div>
												</div>
												<div class="col-md-6 col-lg-4">
													<div class="form-group viewSummary">
														<label>Remarks :</label>
														<div type="text" readonly>
															{{dataOther?.pets_remark ?? '-'}}
														</div>
													</div>
												</div>
											</div>
										</p-accordionTab>
										<p-accordionTab header="Outdoor Activities" class="padfit">
											<div *ngFor="let dataOutdoor of outDoor; let i = index">
												<div class="row">
													<div class="col-md-6 col-lg-4">
														<div class="form-group viewSummary">
															<label for>Park :</label>
															<div type="text" readonly>
																{{dataOutdoor['park_'+i]}}
															</div>
															<!-- <ng-template #elseBlock>
															<input type="text" readonly class="form-control" value="-">
														</ng-template> -->
														</div>
													</div>
													<div class="col-md-6 col-lg-4">
														<div class="form-group viewSummary">
															<label>Remarks :</label>
															<div type="text" readonly>
																{{dataOutdoor?.park_remark ?? '-'}}
															</div>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6 col-lg-4">
														<div class="form-group viewSummary">
															<label for>Mall :</label>
															<div type="text" readonly>
																{{dataOutdoor['mall_'+i]}}
															</div>

															<!-- <ng-template #elseBlock>
															<input type="text" readonly class="form-control" value="-">
														</ng-template> -->
														</div>
													</div>
													<div class="col-md-6 col-lg-4">
														<div class="form-group viewSummary">
															<label>Remarks :</label>
															<div type="text" readonly>
																{{dataOutdoor?.mall_remark ?? '-'}}
															</div>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6 col-lg-4">
														<div class="form-group viewSummary">
															<label for>Supermarket :</label>
															<div type="text" readonly>
																{{dataOutdoor['supermarket_'+i]}}
															</div>
															<!-- <ng-template #elseBlock>
															<input type="text" readonly class="form-control" value="-">
														</ng-template> -->
														</div>
													</div>
													<div class="col-md-6 col-lg-4">
														<div class="form-group viewSummary">
															<label>Remarks :</label>
															<div type="text" readonly>
																{{dataOutdoor?.supermarket_remark ?? '-'}}
															</div>
														</div>
													</div>
												</div>
											</div>
										</p-accordionTab>
										<p-accordionTab header="Remarks" class="padfit">
											<div class="col-md-6 col-lg-4">
												<div class="form-group viewSummary">
													<label>Enter your remarks :</label>
													<div type="text" readonly>
														{{remark|| '-'}}
													</div>
												</div>
											</div>
										</p-accordionTab>

									</div>

								</form>
							</div>
						</div>
					</div>
				</p-accordion>
			</ng-container>
			<ng-template pTemplate="footer" let-list let-i="rowIndex">
				<div class="row ">
					<div class="col-md">
						<table class="table-stamp w-100 text-start">
							<tr class="row">
								<td class="col-sm-6 col-lg-3 "><b>Created By:</b> {{ viewDetail?.created_by}}</td>
								<td class="col-sm-6 col-lg-3 "><b>Created
										Date&Time:</b>{{ viewDetail.created_at.substring(0, 16).replace('T', ' ') }}
								</td>
								<td class="col-sm-6 col-lg-3 "><b>Updated By:</b> {{ viewDetail?.updated_by}}</td>
								<td class="col-sm-6 col-lg-3 "><b>Updated
										Date&Time:</b>{{ viewDetail.updated_at.substring(0, 16).replace('T', ' ') }}
								</td>
							</tr>
						</table>
					</div>
					<p-button (click)="displayMaximizable=
                            false;softReload();" label="Ok" styleClass="p-button-text"></p-button>
				</div>


			</ng-template>
		</p-dialog>
	</div>
</div>
<app-copyrights></app-copyrights>