<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->
<!-- import { DialogModule } from 'primeng/dialog'; -->

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">


  <!-- Breadcrumb Area -->
  <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
    <h1>{{ Content.title }}</h1>
    <ol class="breadcrumb">
      <li class="item"><a routerLink="/">Home</a></li>
      <!-- <li class="item">{{ Content.subTitle }}</li>
      <li class="item">{{ Content.title }}</li> -->
    </ol>
  </div>

  <!-- <div class="add-listings-box">
        <div class="row justify-content-between align-items-center">
            <div class="form-group col-4">
                <label class="p-0">Name <span class="required">*</span></label>
                <input type="text" name="name" class="form-control" placeholder="Name" (change)="getInput($event)" />
            </div>
            <div class="add-listings-btn mb-0 col-4 text-end">
                <button type="submit" (click)="addTableData()">Add</button>
            </div>
        </div>
    </div> -->
  <div class="add-listings-box">
    <div class="Master-view-box">
      <div class="theader d-flex justify-content-between">
        <div class="d-flex w-100">
          <span class="p-input-icon-left ml-auto mr-2 w-100%">
            <i class="pi pi-search"></i>
            <input pInputText type="text" class="form-control text-center" scriptInjection placeholder="Search Keyword"
              (input)="dt1.filterGlobal($event.target.value, 'contains')" [(ngModel)]="filterVal"/>
          </span>
          <p-button type="button" class="mx-2" (click)="reset(dt1)" icon="bx bx-reset" tooltipPosition="bottom" ></p-button>
        </div>
        <div class="add-listings-btn" style="text-align: right">
          <a routerLink="/portal/register" class="nav-link">
            <button type="submit">Register</button>
          </a>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-lg-12 col-md-12">
        <p-table #dt1 [value]="userreglist" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
        [tableStyle]="{ 'min-width': '50rem', width: '100%' }" styleClass="p-datatable-sm p-datatable-customers"
        [rowsPerPageOptions]="[10, 25, 50]" responsiveLayout="scroll" [loading]="loading" [paginator]="true"
        currentPageReportTemplate="{totalRecords} of records"
        [globalFilterFields]="['name', 'contact_no', 'age', 'date_of_birth']" editMode="row">
          <ng-template pTemplate="header">
            <tr>
              <th style="min-width: 42px">S. No.</th>
              <th>ID</th>
              <th>Name</th>
              <th>Contact No.</th>
              <th>Email</th>
              <th>Created At</th>
            </tr>
          </ng-template>
          <!-- table to show data -->
          <ng-template pTemplate="body" let-list let-i="rowIndex">
            <tr>
              <td>
                {{ i + 1 }}
              </td>
              <td>{{ list?.role_id?? "-" }}</td>
              <td>{{ list?.name ?? "-" | titlecase  }}</td>
              <td>{{ list?.mobile ?? "-" }}</td>
              <td>{{ list?.email ?? "-" }}</td>
              <td>{{formatDateTime( list?.created_at)?? "-" }}</td>

            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="5">No Data Found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>


    </div>
  </div>

</div>
