import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class InitialAssessmentFormService {
  reset(): null {
    throw new Error('Method not implemented.');
  }
  apiBaseUrl: string = environment.api_base_url;
  constructor(private http: HttpClient) { }
  /* -------------------------------------------------------------------------- */
  /*               // intial assessment form submit function                    */
  /* -------------------------------------------------------------------------- */
  submitInitialAssessmentForm(formData: any) {
    return this.http.post(this.apiBaseUrl + 'initial-assessment', formData)
  }
    /* -------------------------------------------------------------------------- */
   /*               // intial assessment form edit function                       */
  /* -------------------------------------------------------------------------- */
  getInitialAssessmentForm(initial_assessment_id: any) {
    return this.http.get(this.apiBaseUrl + 'initial-assessment/'+ `${initial_assessment_id}`).toPromise();
  }
 
  editInitialAssessmentForm(id: any, data:any) {
    return this.http.put(this.apiBaseUrl + 'initial-assessment/'+ `${id}`, data).toPromise();

  }
  getNurseDropDown() {
    return this.http.get(this.apiBaseUrl + 'all-nurses').toPromise();
  }
}
