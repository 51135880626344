import { Component, OnInit } from '@angular/core';
import { MaikaListService } from 'src/app/services/maika-list.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MasterService } from 'src/app/services/master.service';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-four-months-to-two-years-list',
  templateUrl: './four-months-to-two-years-list.component.html',
  styleUrls: ['./four-months-to-two-years-list.component.scss']
})
export class FourMonthsToTwoYearsListComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Four Months to One Year',
      subTitle: 'Dashboard'
    }
  ]
  fourMonthToTwoYearsData: any = []
  vaccination: any =[];
  vitamins: any = [];
  sleepTraing: any [];
  loading: any;
  skin:any=[];
  regular:any=[];
  lunch: any = [];
  dinner:any=[];
  diaper:any=[];
  tooltipText: string = 'Four Months to One Years List';
  datalength: any;
  viewDetail: any = {};
  filterVal: string;
  displayMaximizable: boolean = false;
  dataonzeroposition: any;
  filteredPDFData: any[];
  pdfData: any[];
  exportColumns: any = [];
  cols: any;
  edit: any;
  update:any;
  bottle:any=[];
  shower:any=[];
  bathing:any=[];
  tub:any=[];
  stellrization:any=[];
  permissionData: any = []; 
  medicine:any=[]
  permissions: any = [];
  constructor(private four_month_to_two_year_service: MaikaListService,
              private spinner:NgxSpinnerService,
              private router:Router, 
              private masterService: MasterService,
              private messageService: MessageService,
              ) { }

  // fn to get the data from backend
  getAllData() {
   this.spinner.show()
    this.four_month_to_two_year_service.getFourMonthToTwoYearList().subscribe((res: any) => {
      this.spinner.hide()
     
      
      this.fourMonthToTwoYearsData = res.data;
      this.datalength = res?.data?.length;
   
    },
    (error: any) => {
      this.spinner.hide()
      // Handle any errors that occurred during the API call
    
     
    })
  }

  ngOnInit(): void {
    this.getAllData();
    localStorage.removeItem('editedOrderId');
    localStorage.removeItem('create');
    this.cols = [
      { field: 'sr_no', header: 'Sr No' },
      { field: 'name', header: 'Name' },
      { field: 'date_of_birth', header: 'Date of Birth' },
      { field: 'days', header: 'Age' },
      { field: 'months', header: 'Months' },
    ];
    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.getAllUserPermission();
  }
  getAllUserPermission() {
    let id = localStorage.getItem('user_id');
    this.masterService.getUserPremissionById(id).then((res: any) => {
     

      this.permissionData = res.data;

      for(let i =0; i < this.permissionData.length; i++){
        
        this.permissions.push(this.permissionData[i].permission_id);
      }
      
    })
  }

  checkPermission(permission:number){
    
    return this.permissions.includes(permission);
  }
  formatDateTime(date: string | undefined): string {
    if (date) {
      const formattedDate = new Date(date);
      // const year = formattedDate.getFullYear();
      // const month = ('0' + (formattedDate.getMonth() + 1)).slice(-2);
      // const day = ('0' + formattedDate.getDate()).slice(-2);
      const hours = ('0' + formattedDate.getHours()).slice(-2);
      const minutes = ('0' + formattedDate.getMinutes()).slice(-2);
      const seconds = ('0' + formattedDate.getSeconds()).slice(-2);

      return `${hours}:${minutes}:${seconds}`;
      // return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }
    return '-';
  }
  // open view dialog
  details: any = [];
  showMaximizableDialog(id: any) {
    this.spinner.show()
    this.displayMaximizable = true;
    this.viewDetail = {};
    this.fourMonthToTwoYearsData?.map((res: any) => {
      this.spinner.hide()
      if (res.id == id) {
        this.viewDetail = res;
        this.dataonzeroposition=this.viewDetail.nanny_task_details_json;
        this.vaccination = this.dataonzeroposition?.vaccinations_json;
        this.vitamins = this.dataonzeroposition?.vitamins_medications_json;
        
        this.sleepTraing = this.dataonzeroposition?.sleep_training_json;
        
        //get data from form array
        this.skin=this.dataonzeroposition?.skin_care;
        this.lunch=this.dataonzeroposition?.lunch_food;
        this.dinner=this.dataonzeroposition?.dinner_food;
        this.regular=this.dataonzeroposition?.solid_food;
        this.edit = id;
        this.update=this.viewDetail.created_at;
        this.bottle=this.dataonzeroposition?.formula_calculation;
       this.stellrization=this.dataonzeroposition?.bottle_sterilization;
       this.diaper=this.dataonzeroposition?.diaper_change_times;
       this.bathing = this.dataonzeroposition?.bathing_time;
       this.tub = this.dataonzeroposition?.tub_bathing;
       this.shower = this.dataonzeroposition?.shower_bathing;
       this.medicine = this.dataonzeroposition?.vitamins_medications_json[0]?.medicine_time;
       
        
        // console.log("vaccine",JSON.parse(JSON.parse(res?.vaccinations_json)));
        // console.log("vitamin",JSON.parse(JSON.parse(res?.vitamins_medications_json)));
        // console.log("sleep",JSON.parse(JSON.parse(res?.sleep_training_json)));
        // this.sleepTraing = res?.sleep_training_json;
        // this.vaccination = JSON.parse(JSON.parse(res?.vaccinations_json));
        // this.vitamins = JSON.parse(JSON.parse(res?.vitamins_medications_json));
        // console.log("sleep training",this.sleepTraing);
        // this.relatives.push(JSON.parse(res?.relatives_name_json));
        // this.sibling.push(JSON.parse(res?.sibling_details_json));
      }
      else {
        this.spinner.hide()
        
      }
      console.warn(id);
    });
  }
  editOnCLick(id:any){
    localStorage.setItem('editedOrderId', id);
    this.router.navigateByUrl('/portal/four-months-to-one-year-plan');
  }
  reset(dt) {
    dt.reset();
    this.filterVal = '';
  }
  onPDFExport() {
    this.filteredPDFData = [];
    this.pdfData = [];
    this.filteredPDFData = this.fourMonthToTwoYearsData;
  
    for (let i = 0; i < this.filteredPDFData.length; i++) {
      this.pdfData.push({
        sr_no: i + 1,
        name: this.filteredPDFData[i]?.name ?? '-',
        date_of_birth: this.filteredPDFData[i]?.date_of_birth ?? '-',
        days: this.filteredPDFData[i]?.days ?? '-',
        months: this.filteredPDFData[i]?.months ?? '-',
      });
    }
  
    this.four_month_to_two_year_service.exportPdf(
      this.pdfData,
      this.exportColumns,
      'zeo-to-three-month.pdf'
    );
  }
}
