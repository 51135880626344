import { Component, OnInit } from '@angular/core';
import { PtServiceService} from 'src/app/services/pt-service.service'
import { DialogModule } from 'primeng/dialog';
@Component({
  selector: 'app-pt-assessment-listing',
  templateUrl: './pt-assessment-listing.component.html',
  styleUrls: ['./pt-assessment-listing.component.scss']
})
export class PtAssessmentListingComponent implements OnInit {
  breadcrumb = [
    {
      title: 'PT Assessment Form',
      subTitle: 'Dashboard'
    }
  ]
  data: any;

  assessment: any = [];
  physicalactivity: any =[];
  displayMaximizable: boolean = false;
  viewDetail: any = [];
  ptAssListData: any = [];
  medical:any=[];
  loading: any;
  filterVal: string;
  tooltipText: string = 'Initial Assessment';
  assessmentJson: any;
  constructor(private pt_assessment_service:  PtServiceService) { }



  ngOnInit(): void {
   this.getAllData();
  }


  // open view dialog
  details: any = [];
  showMaximizableDialog(id: any) {
    this.assessment=[];
    this.details = [];
    this.viewDetail = [];
    this.ptAssListData.map((res: any) => {
      console.log("showwww", id, "......", res.id)
      if (res.id == id) {

        console.log("view detail:", res);
        this.viewDetail.push(res);
        
        this.assessment.push(JSON.parse(res?.assesment_json));
        console.log("assessment",this.assessment);
        this.medical = JSON.parse(res?.assesment_json);
        console.log("medical",this.medical);
        this.assessmentJson =  JSON.parse(this.medical.assesment_json);
        console.log(this.assessmentJson);
      //  const painyes= JSON.parse(this.assessmentJson.pain_assessment.pain_yes);
      //  console.log("PAIN YES",painyes);

      }
      else {
        
      }
      console.log(id);
    });
    this.displayMaximizable = true;
  }

  getAllData() {

    this.pt_assessment_service. ptListingInitialAssessment().subscribe((res: any) => {
     
      this.ptAssListData = res.data;
      console.log("pt ass data", res.data);
      this.ptAssListData.map((ele: any) => {
        console.log(ele);
        
        JSON.parse(ele?.assesment_json)
        console.log("Assessment Json: ",JSON.parse(ele?.assesment_json))
        console.log("jdsj",this.ptAssListData)
      })
    })
  }
  reset(dt) {
    dt.reset();
    this.filterVal = '';
  }
  }


