<p-toast></p-toast>
<div class="main-content d-flex flex-column">
    <app-dashboard-navbar></app-dashboard-navbar>

    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
        <h1>{{ Content.title }}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item">
                <a routerLink="dashboard">{{ Content.subTitle }}</a>
            </li>
            <li class="item">{{ Content.title }}</li>
        </ol>
    </div>
    <form [formGroup]="initialAssessementPedia" (ngSubmit)="savePediaForm(pediaForm)" #pediaForm="ngForm">
        <!-- DATE PICKER -->
        <div class="add-listings-box">
            <h3>Basic Information </h3>
            <div class="row">
                <div class="col-sm-6 col-12">
                    <div class="form-group">
                        <label class="subHeading px-0">Date<span class="required">*</span></label>
                        <div class="card flex justify-content-center" id="date">
                            <p-calendar readonly [showIcon]="true" [readonlyInput]="true" dateFormat="dd/mm/yy" [maxDate]="selectedDate" [minDate]="selectedDate"
                                formControlName="date"  placeholder="DD/MM/YYYY" [class.is-invalid]="(pediaForm.submitted &&
                                initialAssessementPedia.get('date')?.invalid)
                                ||
                                initialAssessementPedia.get('date')?.invalid
                                &&
                                initialAssessementPedia.get('date').touched"></p-calendar>
                                 <div class="invalid-feedback">Date is required</div>
                        </div>
                    </div>
                </div>

                <!-- INITIAL ASSESSMENT -->
                <div class="col-sm-6 col-12">
                    <div class="form-group">
                        <label class="subHeading p-0">Initial Assessment:<span class="required">*</span> </label>
                        <textarea formControlName="initial_assessment" id="initial_assessment"
                            placeholder="Initial assessment" class="form-control px-2" id="" rows="3"
                            [disabled]=""[class.is-invalid]="(pediaForm.submitted &&
                            initialAssessementPedia.get('initial_assessment')?.invalid)
                            ||
                            initialAssessementPedia.get('initial_assessment')?.invalid
                            &&
                            initialAssessementPedia.get('initial_assessment').touched"></textarea>
                             <div class="invalid-feedback">Initial Assessment is required</div>
                    </div>
                </div>

                <!-- History of Present Illness -->
                <div class="col-sm-6 col-12">
                    <div class="form-group">
                        <label class="subHeading p-0">History of Present Illness:<span class="required">*</span> </label>
                        <textarea formControlName="history_present_illness" id="history_present_illness"
                            placeholder="History of present illness" class="form-control px-2" id="" rows="3"
                            [disabled]="" [class.is-invalid]="(pediaForm.submitted &&
                            initialAssessementPedia.get('history_present_illness')?.invalid)
                            ||
                            initialAssessementPedia.get('history_present_illness')?.invalid
                            &&
                            initialAssessementPedia.get('history_present_illness').touched"></textarea>
                              <div class="invalid-feedback">History of Present Illness is required</div>
                    </div>
                </div>

                <!--Past Medical history -->
                <div class="col-sm-6 col-12">
                    <div class="form-group">
                        <label class="subHeading p-0">Past Medical History:<span class="required">*</span> </label>
                        <textarea formControlName="past_medical_history" id="past_medical_history"
                            placeholder="Past medical history" class="form-control px-2" id="" rows="3"
                            [disabled]="" [class.is-invalid]="(pediaForm.submitted &&
                            initialAssessementPedia.get('past_medical_history')?.invalid)
                            ||
                            initialAssessementPedia.get('past_medical_history')?.invalid
                            &&
                            initialAssessementPedia.get('past_medical_history').touched"></textarea>
                             <div class="invalid-feedback">Past Medical History is required</div>
                    </div>
                </div>
            </div>


            <div class="form-group">
                <label class="subHeading px-0">Medications: </label>
                <div formArrayName="assesment_json">
                    <ng-container *ngFor="let control of getMedicationFormArray().controls; index as i; last as last">
                        <div class="row" [formGroupName]="i">
                            <div class="col-md-11 col-sm-10 col-12">
                                <div class="form-group">
                                    <label class="px-0">Medications</label>
                                    <div class="pl-0 checkbox-list">
                                        <input type="text" class="form-control" placeholder="Medications"
                                            formControlName="medication" id="medications" />
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-1 col-sm-2 col-12 mt-2 d-flex justify-content-end align-items-center">
                                <p-button type="button" icon="bx bx-plus" (click)="addMedication('add', i)"
                                    iconPos="right" class="mr-2"></p-button>
                                <div class="remove">
                                    <p-button type="button" *ngIf="i!= 0" class="ml-2" icon="bx bx-minus"
                                        (click)="addMedication('delete', i)" iconPos="right"></p-button>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <!-- Pain Assessment -->
            <div class="form-group">
                <label class="subHeading p-0">Pain Assessment: </label>
                <div class="row mt-3">
                    <div class="col-sm-6 col-12">
                        <label class="px-0">Tool:</label>
                        <input type="text" class="form-control px-2" placeholder="Tool"
                            formControlName="pain_assessment_tool" id="pain_assessment_tool" />
                    </div>
                    <div class="col-sm-6 col-12">
                        <label class="px-0">Score:</label>
                        <input type="text" class="form-control px-2" placeholder="Score"
                            formControlName="pain_assessment_score" id="pain_assessment_score" />
                    </div>
                </div>
            </div>
        </div>

        <!-- Vital Signs -->
        <div class="add-listings-box">
            <h3>Vital Signs: Pre-Session</h3>
            <div class="form-group">
                <div class="row mt-3">
                    <!-- Blood Pressure -->
                    <div class="col-sm-2 col-4">
                        <label class="px-0">Blood Pressure:</label>
                    </div>
                    <div class="col-sm-10 col-8">
                        <input type="text" class="form-control px-2" placeholder="Blood pressure" pattern="[0-9]*"
                        (keypress)="keyPress($event)"
                            formControlName="blood_pressure" id="blood_pressure" />
                    </div>
                </div>

                <!-- Temperature -->
                <div class="row mt-3">
                    <div class="col-sm-2 col-4">
                        <label class="px-0">Temperature:</label>
                    </div>
                    <div class="col-sm-10 col-8 ">
                        <input type="text" class="form-control px-2" placeholder="Temperature" pattern="[0-9]*"
                        (keypress)="keyPress($event)"
                            formControlName="temperature" id="temperature" />
                    </div>
                </div>

                <!-- Heart rate -->
                <div class="row mt-3">
                    <div class="col-sm-2 col-4">
                        <label class="px-0">Heart Rate:</label>
                    </div>
                    <div class="col-sm-10 col-8">
                        <input type="text" class="form-control px-2" placeholder="Heart rate" pattern="[0-9]*"
                        (keypress)="keyPress($event)"
                            formControlName="heart_rate" id="heart_rate" />
                    </div>
                </div>

                <!-- SPO2 -->
                <div class="row mt-3">
                    <div class="col-sm-2 col-4">
                        <label class="px-0">SPO2:</label>
                    </div>
                    <div class="col-sm-10 col-8">
                        <input type="text" class="form-control px-2" placeholder="SPO2" formControlName="spo2" pattern="[0-9]*"
                        (keypress)="keyPress($event)"
                            id="spo2" />
                    </div>
                </div>

                <!-- RR-->
                <div class="row mt-3">
                    <div class="col-sm-2 col-4">
                        <label class="px-0">RR:</label>
                    </div>
                    <div class="col-sm-10 col-8">
                        <input type="text" class="form-control px-2" placeholder="RR" pattern="[0-9]*"
                        (keypress)="keyPress($event)" formControlName="rr" id="rr" />
                    </div>
                </div>

                <!-- Attachment-->
                <!-- <div class="row mt-3"> -->
                <label class="subHeading my-3 p-0">Attachment:</label>
                <!-- <div class="col-2"> -->
                <div class="form-check checkbox-list">
                    <div class="row py-3">
                        <label class="checkbox checkbox-outline px-0 col-6 col-sm-2">
                            <input type="checkbox" value="sterilizeAll" formControlName="attachment_PEG"
                                id="attachment_PEG" />
                            PEG
                        </label>

                        <label class="checkbox checkbox-outline  px-0 col-6 col-sm-2">
                            <input type="checkbox" value="sterilizeOne" formControlName="attachment_trache"
                                id="attachment_trache" />
                            Trache
                        </label>

                        <label class="checkbox checkbox-outline px-0 col-6 col-sm-2">
                            <input type="checkbox" value="sterilizeOne" formControlName="attachment_NGT"
                                id="attachment_NGT" />
                            NGT
                        </label>

                        <label class="checkbox checkbox-outline px-0 col-6 col-sm-2">
                            <input type="checkbox" value="sterilizeOne" formControlName="attachment_FC1"
                                id="attachment_FC1" />
                            FC1
                        </label>

                        <label class="checkbox checkbox-outline px-0 col-6 col-sm-2">
                            <input type="checkbox" value="sterilizeOne" formControlName="attachment_colostomy"
                                id="attachment_colostomy" />
                            Colostomy
                        </label>

                        <label class="checkbox checkbox-outline px-0 col-6 col-sm-2">
                            <input type="checkbox" value="sterilizeOne" formControlName="attachment_FC2"
                                id="attachment_FC2" />
                            FC2
                        </label>

                        <label class="checkbox checkbox-outline px-0 col-6 col-sm-2">
                            <input type="checkbox" value="sterilizeOne" formControlName="attachment_ventilator"
                                id="attachment_ventilator" />
                            Ventilator
                        </label>

                        <label class="checkbox checkbox-outline px-0 col-6 col-sm-2">
                            <input type="checkbox" value="sterilizeOne" formControlName="attachment_canula"
                                id="attachment_canula" />
                            O2 Canula
                        </label>

                        <label class="checkbox checkbox-outline px-0 col-6 col-sm-2">
                            <input type="checkbox" value="sterilizeOne" formControlName="attachment_NA"
                                id="attachment_NA" />
                            NA
                        </label>



                        <label class="mr-3 p-0">
                            <input type="checkbox" class="checkbox checkbox-outline" value="sterilizeOne"
                                formControlName="others_box" id="others_box" (change)="handleInput($event, 'box')"/>
                            Others:</label>
                        <div class="col-sm-3 col-12 px-0">
                            <input type="text" class="form-control px-2 " placeholder="Others" formControlName="others"
                                id="others"  [class.is-invalid]="
                                (pediaForm.submitted &&
                                  initialAssessementPedia.get('others')?.invalid) ||
                                (initialAssessementPedia.get('others')?.invalid &&
                                  initialAssessementPedia.get('others').touched)
                              "/>
                        <div class="invalid-feedback">Others remark is required</div>
                        </div>
                    </div>
                </div>

                <!-- Respiratory assessment-->
                <label class="subHeading my-0 my-sm-3 p-0">Respiratory Assessment:</label>
                <div class="row ">
                    <!-- Cough -->
                    <div class="col-12 col-sm-2 mt-3">
                        <div class="form-group">
                            <label class="mr-3 p-0">Cough:</label>
                            <p class="d-flex">
                                <input type="radio" id="assessment_yes" value="Yes"
                                    formControlName="repiratory_assessment"    (change)="handleInput($event, 'cough')"/>
                                <label for="assessment_yes" class="mb-0 mx-2">Yes</label>
                            </p>
                            <p class="d-flex">
                                <input type="radio" id="assessment_no" value="No"
                                    formControlName="repiratory_assessment"    (change)="handleInput($event, 'cough')"/>
                                <label for="assessment_no" class="mb-0 mx-2">No</label>
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4 mt-0 mt-sm-3">
                        <label class="mr-3 p-0">Remark:</label>
                        <input type="text" class="form-control px-2" placeholder="Remark"
                            formControlName="cough_remark"[class.is-invalid]="(pediaForm.submitted &&
                            initialAssessementPedia.get('cough_remark')?.invalid)
                            ||
                            initialAssessementPedia.get('cough_remark')?.invalid
                            &&
                            initialAssessementPedia.get('cough_remark').touched" />
                         <div class="invalid-feedback">Cough remark is required</div>
                    </div>

                    <!-- Chest Secretions -->
                    <div class="col-12 col-sm-2 mt-3">
                        <div class="form-group">
                            <label class="mr-3 p-0">Chest Secretions:</label>
                            <p class="d-flex">
                                <input type="radio" id="chest_secretion_yes" value="Yes"
                                    formControlName="chest_secretion"   (change)="handleInput($event, 'secretion')"/>
                                <label for="chest_secretion_yes" class="mb-0 mx-2">Yes</label>
                            </p>
                            <p class="d-flex">
                                <input type="radio" id="chest_secretion_no" value="No"
                                    formControlName="chest_secretion"  (change)="handleInput($event, 'secretion')"/>
                                <label for="chest_secretion_no" class="mb-0 mx-2">No</label>
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4 mt-0 mt-sm-3">
                        <label class="mr-3 p-0">Remark:</label>
                        <input type="text" class="form-control px-2" placeholder="Remark"
                            formControlName="chest_secretion_remark" [class.is-invalid]="(pediaForm.submitted &&
                            initialAssessementPedia.get('chest_secretion_remark')?.invalid)
                            ||
                            initialAssessementPedia.get('chest_secretion_remark')?.invalid
                            &&
                            initialAssessementPedia.get('chest_secretion_remark').touched" />
                         <div class="invalid-feedback">Chest secretion remark is required</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Congition -->
        <div class="add-listings-box">
            <h3>Cognition</h3>
            <div class="form-group">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr class="border-light" style="white-space: nowrap;">
                                <th scope="col">
                                    <label class="subHeading p-0">Neurosensory:</label>
                                </th>
                                <th scope="col"><label class="subHeading p-0">Intact</label></th>
                                <th scope="col"><label class="subHeading p-0">Impaired</label></th>
                                <th scope="col"><label class="subHeading p-0">Absent</label></th>
                                <th scope="col"><label class="subHeading p-0">Not tested</label></th>
                                <th scope="col"><label class="subHeading p-0">Remark</label></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="border-light">
                                <td scope="row"><label class="p-0">Light Touch</label></td>
                                <td>
                                    <input class="p-radiobutton" type="radio" value="1" formControlName="lightallvalue" name="lightallvalue"
                                        id="intact" (change)="handleInput($event, 'lightall')" />
                                </td>

                                <td>
                                    <input class="p-radiobutton" type="radio" value="2" formControlName="lightallvalue" name="lightallvalue"
                                        id="intact2"(change)="handleInput($event, 'lightall')" />
                                </td>
                                <td>
                                    <input class="p-radiobutton" type="radio" value="3" formControlName="lightallvalue" name="lightallvalue"
                                        id="intact3"(change)="handleInput($event, 'lightall')" />
                                </td>
                                <td>
                                    <input class="p-radiobutton" type="radio" value="4" formControlName="lightallvalue" name="lightallvalue"
                                        id="intact4" (change)="handleInput($event, 'lightall')" />
                                </td>
                                <td>
                                    <input class="m-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                                        formControlName="light_touch_remark" id="light_touch_remark"  [class.is-invalid]="(pediaForm.submitted &&
                                        initialAssessementPedia.get('light_touch_remark')?.invalid)
                                        ||
                                        initialAssessementPedia.get('light_touch_remark')?.invalid
                                        &&
                                        initialAssessementPedia.get('light_touch_remark').touched"  />
                                         <div class="invalid-feedback">Remark is required</div>
                                </td>
                            </tr>
                            <tr class="border-light">
                                <td scope="row"><label class="p-0">Blunt Touch/Deep</label></td>
                                <td>
                                    <input class="p-radiobutton" type="radio" value="1" formControlName="blunttouchall" name="blunttouchall"
                                        id="blunttouchall_intact" (change)="handleInput($event, 'bluntall')" />
                                </td>
                                <td>
                                    <input class="p-radiobutton" type="radio" value="2" formControlName="blunttouchall" name="blunttouchall"
                                        id="blunttouchall_intact" (change)="handleInput($event, 'bluntall')" />
                                </td>
                                <td>
                                    <input class="p-radiobutton" type="radio" value="3" formControlName="blunttouchall" name="blunttouchall"
                                        id="blunttouchall_intact" (change)="handleInput($event, 'bluntall')" />
                                </td>
                                <td>
                                    <input class="p-radiobutton" type="radio" value="4" formControlName="blunttouchall" name="blunttouchall"
                                        id="blunttouchall_intact" (change)="handleInput($event, 'bluntall')" />
                                </td>
                                <td>
                                    <input class="m-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                                        formControlName="blunt_remark" id="blunt_remark"   [class.is-invalid]="(pediaForm.submitted &&
                                        initialAssessementPedia.get('blunt_remark')?.invalid)
                                        ||
                                        initialAssessementPedia.get('blunt_remark')?.invalid
                                        &&
                                        initialAssessementPedia.get('blunt_remark').touched" />
                                           <div class="invalid-feedback">Remark is required</div>
                                </td>
                            </tr>
                            <tr class="border-light">
                                <td scope="row"><label class="p-0">Pressure</label></td>
                                <td>
                                    <input class="p-radiobutton" type="radio" value="1" formControlName="pressureall" name="pressureall"
                                        id="intact" (change)="handleInput($event, 'pressall')" />
                                </td>
                                <td>
                                    <input class="p-radiobutton" type="radio" value="2" formControlName="pressureall" name="pressureall"
                                        id="intact" (change)="handleInput($event, 'pressall')" />
                                </td>
                                <td>
                                    <input class="p-radiobutton" type="radio" value="3" formControlName="pressureall" name="pressureall"
                                        id="intact" (change)="handleInput($event, 'pressall')" />
                                </td>
                                <td>
                                    <input class="p-radiobutton" type="radio" value="4" formControlName="pressureall" name="pressureall"
                                        id="intact" (change)="handleInput($event, 'pressall')" />
                                </td>
                                <td>
                                    <input class="m-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                                        formControlName="pressure_remark" id="pressure_remark"  [class.is-invalid]="(pediaForm.submitted &&
                                        initialAssessementPedia.get('pressure_remark')?.invalid)
                                        ||
                                        initialAssessementPedia.get('pressure_remark')?.invalid
                                        &&
                                        initialAssessementPedia.get('pressure_remark').touched"  />
                                           <div class="invalid-feedback">Remark is required</div>
                                </td>
                            </tr>
                            <tr class="border-light">
                                <td scope="row"><label class="p-0 ">Pin Prick</label></td>
                                <td>
                                    <input class="p-radiobutton" type="radio" value="1" formControlName="pinprickall" name="pinprickall"
                                        id="pinprickall1" (change)="handleInput($event, 'pinprickall1')" />
                                </td>
                                <td>
                                    <input class="p-radiobutton" type="radio" value="2" formControlName="pinprickall" name="pinprickall"
                                        id="pinprickall1" (change)="handleInput($event, 'pinprickall1')"/>
                                </td>
                                <td>
                                    <input class="p-radiobutton" type="radio" value="3" formControlName="pinprickall" name="pinprickall"
                                        id="pinprickall1" (change)="handleInput($event, 'pinprickall1')" />
                                </td>
                                <td>
                                    <input class="p-radiobutton" type="radio" value="4" formControlName="pinprickall" name="pinprickall"
                                        id="pinprickall1" (change)="handleInput($event, 'pinprickall1')" />
                                </td>
                                <td>
                                    <input class="m-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                                        formControlName="pin_remark" id="pin_remark"   [class.is-invalid]="(pediaForm.submitted &&
                                        initialAssessementPedia.get('pin_remark')?.invalid)
                                        ||
                                        initialAssessementPedia.get('pin_remark')?.invalid
                                        &&
                                        initialAssessementPedia.get('pin_remark').touched" />
                                    <div class="invalid-feedback">Remark is required</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- congnition -->
            <div class="form-group">
                <label class="subHeading p-0">Cognition:</label>
                <!-- Oriented to people -->
                <div class="row mt-3">
                    <div class="col-sm-3 col-6">
                        <label class=" p-0">Oriented to people:</label>
                    </div>
                    <!-- ***** -->
                    <div class="col-sm-2 col-3">
                        <p class="d-flex align-items-center">
                            <input type="radio" id="oriented_people_yes" value="Yes" name="oriented_people" formControlName="oriented_people" (change)="handleInput($event, 'people')">
                            <label for="oriented_people_yes" class="mb-0 mx-2 px-0" style="padding-left: 22px !important">Yes</label>
                        </p>
                    </div>
                    <div class="col-sm-2 col-3">
                        <p class="d-flex align-items-center">
                            <input type="radio" id="oriented_people_no" value="No"  name="oriented_people" formControlName="oriented_people"(change)="handleInput($event, 'people')">
                            <label for="oriented_people_no" class="mb-0 mx-2 px-0" style="padding-left: 22px !important">No</label>
                        </p>
                    </div>
                    <!-- ****** -->

                    <div class="col-sm-5 col-12">
                        <div class="form-group">
                            <input type="text" formControlName="oriented_people_remark" id="oriented_people_remark"
                                class="form-control" rows="3" placeholder="Remark"  [class.is-invalid]="
                                (pediaForm.submitted &&
                                  initialAssessementPedia.get('oriented_people_remark')?.invalid) ||
                                (initialAssessementPedia.get('oriented_people_remark')?.invalid &&
                                  initialAssessementPedia.get('oriented_people_remark').touched)
                              "  >
                               <div class="invalid-feedback">Remark is Required</div>
                        </div>
                    </div>
                </div>

                <!-- Oriented to time -->
                <div class="row mt-3">
                    <div class="col-sm-3 col-6">
                        <label class="p-0">Oriented to time:</label>
                    </div>
                    <div class="col-sm-2 col-3">
                        <p class="d-flex align-items-center">
                            <input type="radio"  name="oriented_time" id="oriented_time_yes" value="Yes" formControlName="oriented_time" (change)="handleInput($event, 'time')">
                            <label for="oriented_time_yes" class="mb-0 mx-2 px-0" style="padding-left: 22px !important">Yes</label>
                        </p>
                    </div>
                    <div class="col-sm-2 col-3">
                        <p class="d-flex align-items-center">
                            <input type="radio" name="oriented_time"  id="oriented_time_no" value="No" formControlName="oriented_time" (change)="handleInput($event, 'time')">
                            <label for="oriented_time_no" class="mb-0 mx-2 px-0" style="padding-left: 22px !important">No</label>
                        </p>
                    </div>
                    <!-- ****** -->

                    <div class="col-sm-5 col-12">
                        <div class="form-group">
                            <input type="text" formControlName="oriented_time_remark" id="oriented_time_remark"
                                class="form-control" rows="3" placeholder="Remark"  [class.is-invalid]="
                                (pediaForm.submitted &&
                                  initialAssessementPedia.get('oriented_time_remark')?.invalid) ||
                                (initialAssessementPedia.get('oriented_time_remark')?.invalid &&
                                  initialAssessementPedia.get('oriented_time_remark').touched)
                              "  >
                               <div class="invalid-feedback">Remark is Required</div>
                        </div>
                    </div>
                </div>

                <!-- Oriented to place -->
                <div class="row mt-3">
                    <div class="col-sm-3 col-6">
                        <label class="p-0">Oriented to place:</label>
                    </div>


                    <div class="col-sm-2 col-3">
                        <p class="d-flex align-items-center">
                            <input name="oriented_place" type="radio" id="oriented_place_yes" value="Yes" formControlName="oriented_place" (change)="handleInput($event, 'place')">
                            <label for="oriented_place_yes" class="mb-0 mx-2 px-0" style="padding-left: 22px !important">Yes</label>
                        </p>
                    </div>
                    <div class="col-sm-2 col-3">
                        <p class="d-flex align-items-center">
                            <input name="oriented_place" type="radio" id="oriented_place_no" value="No" formControlName="oriented_place" (change)="handleInput($event, 'place')">
                            <label for="oriented_place_no" class="mb-0 mx-2 px-0" style="padding-left: 22px !important">No</label>
                        </p>
                    </div>
                    <!-- ****** -->

                    <div class="col-sm-5 col-12">
                        <div class="form-group">
                            <input type="text" formControlName="oriented_place_remark" id="oriented_place_remark"
                                class="form-control" rows="3" placeholder="Remark"  [class.is-invalid]="
                                (pediaForm.submitted &&
                                  initialAssessementPedia.get('oriented_place_remark')?.invalid) ||
                                (initialAssessementPedia.get('oriented_place_remark')?.invalid &&
                                  initialAssessementPedia.get('oriented_place_remark').touched)
                              "  >
                               <div class="invalid-feedback">Remark is Required</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- PALPATION -->
        <div class="add-listings-box">
            <h3>Palpation</h3>
            <div class="form-group">
                <label class="subHeading p-0">Soft Tissues and Skin Assessment:</label>
                <!-- Swelling -->
                <div class="row mt-3">
                    <div class="col-12 col-sm-3">
                        <label class="p-0">Swelling:</label>
                    </div>
                    <div class="col-12 col-sm-3 mt-0 mt-sm-4">
                        <p class="d-flex">
                            <input type="radio" id="swelling_yes" value="Yes" formControlName="swelling" (change)="handleInput($event, 'swell')" />
                            <label for="swelling_yes" class="mb-0 mx-2">Yes</label>
                        </p>
                        <p class="d-flex">
                            <input type="radio" id="swelling_no" value="No" formControlName="swelling" (change)="handleInput($event, 'swell')" />
                            <label for="swelling_no" class="mb-0 mx-2">No</label>
                        </p>
                    </div>
                    <div class="col-12 col-sm-6">
                        <label class="mr-3 my-2 p-0">Remark</label>
                        <input type="text" class="form-control px-2" placeholder="Remark"
                            formControlName="swelling_remark"[class.is-invalid]="(pediaForm.submitted &&
                            initialAssessementPedia.get('swelling_remark')?.invalid)
                            ||
                            initialAssessementPedia.get('swelling_remark')?.invalid
                            &&
                            initialAssessementPedia.get('swelling_remark').touched" />
                         <div class="invalid-feedback">Swelling remark is required</div>
                    </div>
                </div>

                <!-- Scar -->
                <div class="row mt-3">
                    <div class="col-12 col-sm-3">
                        <label class="p-0">Scar:</label>
                    </div>
                    <div class="col-12 col-sm-3 mt-0 mt-sm-4">
                        <p class="d-flex">
                            <input type="radio" id="scar_yes" value="Yes" formControlName="scar"  (change)="handleInput($event, 'scar')" />
                            <label for="scar_yes" class="mb-0 mx-2">Yes</label>
                        </p>
                        <p class="d-flex">
                            <input type="radio" id="scar_no" value="No" formControlName="scar"(change)="handleInput($event, 'scar')" />
                            <label for="scar_no" class="mb-0 mx-2">No</label>
                        </p>
                    </div>
                    <div class="col-12 col-sm-6">
                        <label class="mr-3 my-2 p-0">Remark</label>
                        <input type="text" class="form-control px-2" placeholder="Remark"
                            formControlName="scar_remark"[class.is-invalid]="(pediaForm.submitted &&
                            initialAssessementPedia.get('scar_remark')?.invalid)
                            ||
                            initialAssessementPedia.get('scar_remark')?.invalid
                            &&
                            initialAssessementPedia.get('scar_remark').touched" />
                         <div class="invalid-feedback">Scar remark is required</div>
                    </div>
                </div>

                <!-- Worm -->
                <div class="row mt-3">
                    <div class="col-12 col-sm-3">
                        <label class="p-0">Worm:</label>
                    </div>
                    <div class="col-12 col-sm-3 mt-0 mt-sm-4">
                        <p class="d-flex">
                            <input type="radio" id="worm_yes" value="Yes" formControlName="worm" (change)="handleInput($event, 'worm')"/>
                            <label for="worm_yes" class="mb-0 mx-2">Yes</label>
                        </p>
                        <p class="d-flex">
                            <input type="radio" id="worm_no" value="No" formControlName="worm"(change)="handleInput($event, 'worm')" />
                            <label for="worm_no" class="mb-0 mx-2">No</label>
                        </p>
                    </div>
                    <div class="col-12 col-sm-6">
                        <label class="mr-3 my-2 p-0">Remark</label>
                        <input type="text" class="form-control px-2" placeholder="Remark"
                            formControlName="worm_remark"[class.is-invalid]="(pediaForm.submitted &&
                            initialAssessementPedia.get('worm_remark')?.invalid)
                            ||
                            initialAssessementPedia.get('worm_remark')?.invalid
                            &&
                            initialAssessementPedia.get('worm_remark').touched" />
                         <div class="invalid-feedback">Worm remark is required</div>
                    </div>
                </div>

                <!-- Infection -->
                <div class="row mt-3">
                    <div class="col-12 col-sm-3">
                        <label class="p-0">Infection:</label>
                    </div>
                    <div class="col-12 col-sm-3 mt-0 mt-sm-4">
                        <p class="d-flex">
                            <input type="radio" id="infection_yes" value="Yes" formControlName="infection" (change)="handleInput($event, 'infection')"  />
                            <label for="infection_yes" class="mb-0 mx-2">Yes</label>
                        </p>
                        <p class="d-flex">
                            <input type="radio" id="infection_no" value="No" formControlName="infection"(change)="handleInput($event, 'infection')"  />
                            <label for="infection_no" class="mb-0 mx-2">No</label>
                        </p>
                    </div>
                    <div class="col-12 col-sm-6">
                        <label class="mr-3 my-2 p-0">Remark</label>
                        <input type="text" class="form-control px-2" placeholder="Remark"
                            formControlName="infection_remark" [class.is-invalid]="(pediaForm.submitted &&
                            initialAssessementPedia.get('infection_remark')?.invalid)
                            ||
                            initialAssessementPedia.get('infection_remark')?.invalid
                            &&
                            initialAssessementPedia.get('infection_remark').touched" />
                         <div class="invalid-feedback">Infection remark is required</div>
                    </div>
                </div>

                <!-- Wound -->
                <div class="row mt-3">
                    <div class="col-12 col-sm-3">
                        <label class="p-0">Wound:</label>
                    </div>
                    <div class="col-12 col-sm-3 mt-0 mt-sm-4">
                        <p class="d-flex">
                            <input type="radio" id="wound_yes" value="Yes" formControlName="wound"(change)="handleInput($event, 'wound')" />
                            <label for="wound_yes" class="mb-0 mx-2">Yes</label>
                        </p>
                        <p class="d-flex">
                            <input type="radio" id="wound_no" value="No" formControlName="wound"  (change)="handleInput($event, 'wound')"/>
                            <label for="wound_no" class="mb-0 mx-2">No</label>
                        </p>
                    </div>
                    <div class="col-12 col-sm-6">
                        <label class="mr-3 my-2 p-0">Remark</label>
                        <input type="text" class="form-control px-2" placeholder="Remark"
                            formControlName="wound_remark"[class.is-invalid]="(pediaForm.submitted &&
                            initialAssessementPedia.get('wound_remark')?.invalid)
                            ||
                            initialAssessementPedia.get('wound_remark')?.invalid
                            &&
                            initialAssessementPedia.get('wound_remark').touched" />
                         <div class="invalid-feedback">Wound remark is required</div>
                    </div>
                </div>

                <!-- Pain -->
                <div class="row mt-3">
                    <div class="col-12 col-sm-3">
                        <label class="p-0">Pain:</label>
                    </div>
                    <div class="col-12 col-sm-3 mt-0 mt-sm-4">
                        <p class="d-flex">
                            <input type="radio" id="pain_yes" value="Yes" formControlName="pain"  (change)="handleInput($event, 'pain')"/>
                            <label for="pain_yes" class="mb-0 mx-2">Yes</label>
                        </p>
                        <p class="d-flex">
                            <input type="radio" id="pain_no" value="No" formControlName="pain" (change)="handleInput($event, 'pain')" />
                            <label for="pain_no" class="mb-0 mx-2">No</label>
                        </p>
                    </div>
                    <div class="col-12 col-sm-6">
                        <label class="mr-3 my-2 p-0">Remark</label>
                        <input type="text" class="form-control px-2" placeholder="Remark"
                            formControlName="pain_remark" [class.is-invalid]="(pediaForm.submitted &&
                            initialAssessementPedia.get('pain_remark')?.invalid)
                            ||
                            initialAssessementPedia.get('pain_remark')?.invalid
                            &&
                            initialAssessementPedia.get('pain_remark').touched" />
                         <div class="invalid-feedback"> Pain remark is required</div>
                    </div>
                </div>

                <!-- Others -->
                <div class="row mt-3">
                    <div class="col-12 col-sm-3">
                        <label class="p-0">Others</label>
                    </div>
                    <div class="col-12 col-sm-3 mt-0 mt-sm-4">
                        <p class="d-flex">
                            <input type="radio" id="others_yes" value="Yes" formControlName="others_soft_tissue"  (change)="handleInput($event, 'others')"/>
                            <label for="others_yes" class="mb-0 mx-2">Yes</label>
                        </p>
                        <p class="d-flex">
                            <input type="radio" id="others_no" value="No" formControlName="others_soft_tissue" (change)="handleInput($event, 'others')" />
                            <label for="others_no" class="mb-0 mx-2">No</label>
                        </p>
                    </div>
                    <div class="col-12 col-sm-6">
                        <label class="mr-3 my-2 p-0">Remark</label>
                        <input type="text" class="form-control px-2" placeholder="Remark"
                            formControlName="others_remark"[class.is-invalid]="(pediaForm.submitted &&
                            initialAssessementPedia.get('others_remark')?.invalid)
                            ||
                            initialAssessementPedia.get('others_remark')?.invalid
                            &&
                            initialAssessementPedia.get('others_remark').touched" />
                         <div class="invalid-feedback">Others remark is required</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- MOTOR COMPONENTS -->
        <div class="add-listings-box">
            <h3>Motor Components</h3>
            <label class="subHeading mb-3">Joint and Muscle:</label>
            <div class="form-group">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col" class="text-center" rowspan="2">
                                    <label class="subHeading p-0">Joint</label>
                                </th>
                                <th scope="col" class="text-center" rowspan="2">
                                    <label class="subHeading p-0">Movement</label>
                                </th>
                                <th scope="col" class="text-center" rowspan="2">
                                    <label class="subHeading p-0">Norm ROM</label>
                                </th>
                                <th scope="col" class="text-center" colspan="2">
                                    <label class="subHeading p-0">Passive ROM</label>
                                </th>
                                <th scope="col" class="text-center" colspan="2">
                                    <label class="subHeading p-0">Active ROM</label>
                                </th>
                                <th scope="col" class="text-center" rowspan="2">
                                    <label class="subHeading p-0">Comments</label>
                                </th>
                                <th scope="col" class="text-center" colspan="2">
                                    <label class="subHeading p-0">Muscle Strength</label>
                                </th>
                            </tr>
                            <tr>
                                <th class="text-center">R</th>
                                <th class="text-center">L</th>
                                <th class="text-center">R</th>
                                <th class="text-center">L</th>
                                <th class="text-center">R</th>
                                <th class="text-center">L</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- NECK ROW -->
                            <!-- Flexion -->

                            <tr>
                                <td scope="row" rowspan="4"><label class=" ">Neck</label></td>
                                <td>Flexion</td>
                                <td>0-50</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="neck_flexion_passive_r"
                                        id="neck_flexion_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="neck_flexion_passive_l"
                                        id="neck_flexion_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="neck_flexion_active_r"
                                        id="neck_flexion_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="neck_flexion_active_l"
                                        id="neck_flexion_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2" formControlName="neck_flexion_comment"
                                        id="neck_flexion_comment" placeholder="Comment" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="neck_flexion_muscle_r"
                                        id="neck_flexion_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="neck_flexion_muscle_l"
                                        id="neck_flexion_muscle_l" />
                                </td>
                            </tr>

                            <!-- Extension -->
                            <tr>
                                <td>Extension</td>
                                <td>0-60</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="neck_extension_passive_r"
                                        id="neck_extension_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="neck_extension_passive_l"
                                        id="neck_extension_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="neck_extension_active_r"
                                        id="neck_extension_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="neck_extension_active_l"
                                        id="neck_extension_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="neck_extension_comment" id="neck_extension_comment" placeholder="Comment" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="neck_extension_muscle_r"
                                        id="neck_extension_muscle_r"  />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="neck_extension_muscle_l"
                                        id="neck_extension_muscle_l" />
                                </td>
                            </tr>

                            <!-- Rotation -->
                            <tr>
                                <td>Rotation</td>
                                <td>0-80</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="neck_rotation_passive_r"
                                        id="neck_rotation_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="neck_rotation_passive_l"
                                        id="neck_rotation_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="neck_rotation_active_r"
                                        id="neck_rotation_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="neck_rotation_active_l"
                                        id="neck_rotation_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2" formControlName="neck_rotation_comment"
                                        id="neck_rotation_comment"  placeholder="Comment"/>
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="neck_rotation_muscle_r"
                                        id="neck_rotation_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="neck_rotation_muscle_l"
                                        id="neck_rotation_muscle_l" />
                                </td>
                            </tr>

                            <!-- Side flex -->
                            <tr>
                                <td>Side flex</td>
                                <td>0-45</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="neck_sideFlex_passive_r"
                                        id="neck_sideFlex_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="neck_sideFlex_passive_l"
                                        id="neck_sideFlex_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="neck_sideFlex_active_r"
                                        id="neck_sideFlex_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="neck_sideFlex_active_l"
                                        id="neck_sideFlex_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2" formControlName="neck_sideFlex_comment"
                                        id="neck_sideFlex_comment"  placeholder="Comment"/>
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="neck_sideFlex_muscle_r"
                                        id="neck_sideFlex_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="neck_sideFlex_muscle_l"
                                        id="neck_sideFlex_muscle_l" />
                                </td>
                            </tr>

                            <!-- SHOULDER ROW -->
                            <!-- Flexion -->
                            <tr>
                                <td scope="row" rowspan="6"><label class=" ">Shoulder</label></td>
                                <td>Flexion</td>
                                <td>0-180</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="shoulder_flexion_passive_r"
                                        id="shoulder_flexion_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="shoulder_flexion_passive_l"
                                        id="shoulder_flexion_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="shoulder_flexion_active_r"
                                        id="shoulder_flexion_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="shoulder_flexion_active_l"
                                        id="shoulder_flexion_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="shoulder_flexion_comment" id="shoulder_flexion_comment"  placeholder="Comment"/>
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="shoulder_flexion_muscle_r"
                                        id="shoulder_flexion_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="shoulder_flexion_muscle_l"
                                        id="shoulder_flexion_muscle_l" />
                                </td>
                            </tr>

                            <!-- Extension -->

                            <tr>
                                <td>Extension</td>
                                <td>0-60</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="shoulder_extension_passive_r"
                                        id="shoulder_extension_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="shoulder_extension_passive_l"
                                        id="shoulder_extension_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="shoulder_extension_active_r"
                                        id="shoulder_extension_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="shoulder_extension_active_l"
                                        id="shoulder_extension_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="shoulder_extension_comment" id="shoulder_extension_comment"  placeholder="Comment"/>
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="shoulder_extension_muscle_r"
                                        id="shoulder_extension_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="shoulder_extension_muscle_l"
                                        id="shoulder_extension_muscle_l" />
                                </td>
                            </tr>

                            <!-- Abduction -->
                            <tr>
                                <td>Abduction</td>
                                <td>0-180</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="shoulder_abduction_passive_r"
                                        id="shoulder_abduction_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="shoulder_abduction_passive_l"
                                        id="shoulder_abduction_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="shoulder_abduction_active_r"
                                        id="shoulder_abduction_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="shoulder_abduction_active_l"
                                        id="shoulder_abduction_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="shoulder_abduction_comment" id="shoulder_abduction_comment"  placeholder="Comment"/>
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="shoulder_abduction_muscle_r"
                                        id="shoulder_abduction_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="shoulder_abduction_muscle_l"
                                        id="shoulder_abduction_muscle_l" />
                                </td>
                            </tr>

                            <!-- Adduction -->
                            <tr>
                                <td>Adduction</td>
                                <td>0-90</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="shoulder_adduction_passive_r"
                                        id="shoulder_adduction_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="shoulder_adduction_passive_l"
                                        id="shoulder_adduction_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="shoulder_adduction_active_r"
                                        id="shoulder_adduction_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="shoulder_adduction_active_l"
                                        id="shoulder_adduction_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="shoulder_adduction_comment" id="shoulder_adduction_comment"  placeholder="Comment"/>
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="shoulder_adduction_muscle_r"
                                        id="shoulder_adduction_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="shoulder_adduction_muscle_l"
                                        id="shoulder_adduction_muscle_l" />
                                </td>
                            </tr>

                            <!-- Internal Rotation -->
                            <tr>
                                <td>Internal Rotation</td>
                                <td>0-70</td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="shoulder_internal_rotation_passive_r"
                                        id="shoulder_internal_rotation_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="shoulder_internal_rotation_passive_l"
                                        id="shoulder_internal_rotation_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="shoulder_internal_rotation_active_r"
                                        id="shoulder_internal_rotation_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="shoulder_internal_rotation_active_l"
                                        id="shoulder_internal_rotation_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="shoulder_internal_rotation_comment"
                                        id="shoulder_internal_rotation_comment"  placeholder="Comment"/>
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="shoulder_internal_rotation_muscle_r"
                                        id="shoulder_internal_rotation_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="shoulder_internal_rotation_muscle_l"
                                        id="shoulder_internal_rotation_muscle_l" />
                                </td>
                            </tr>

                            <!-- External Rotation -->
                            <tr>
                                <td>External Rotation</td>
                                <td>0-90</td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="shoulder_external_rotation_passive_r"
                                        id="shoulder_external_rotation_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="shoulder_external_rotation_passive_l"
                                        id="shoulder_external_rotation_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="shoulder_external_rotation_active_r"
                                        id="shoulder_external_rotation_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="shoulder_external_rotation_active_l"
                                        id="shoulder_external_rotation_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="shoulder_external_rotation_comment"
                                        id="shoulder_external_rotation_comment" placeholder="Comment" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="shoulder_external_rotation_muscle_r"
                                        id="shoulder_external_rotation_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="shoulder_external_rotation_muscle_l"
                                        id="shoulder_external_rotation_muscle_l" />
                                </td>
                            </tr>

                            <!-- ELBOW ROW-->
                            <!-- Flexion -->
                            <tr>
                                <td scope="row" rowspan="4"><label class=" ">Elbow</label></td>
                                <td>Flexion</td>
                                <td>0-50</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="elbow_flexion_passive_r"
                                        id="elbow_flexion_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="elbow_flexion_passive_l"
                                        id="elbow_flexion_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="elbow_flexion_active_r"
                                        id="elbow_flexion_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="elbow_flexion_active_l"
                                        id="elbow_flexion_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2" formControlName="elbow_flexion_comment"
                                        id="elbow_flexion_comment"  placeholder="Comment"/>
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="elbow_flexion_muscle_r"
                                        id="elbow_flexion_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="elbow_flexion_muscle_l"
                                        id="elbow_flexion_muscle_l" />
                                </td>
                            </tr>

                            <!-- Extension -->
                            <!-- Extension -->
                            <tr>
                                <td>Extension</td>
                                <td>0-60</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="elbow_extension_passive_r"
                                        id="elbow_extension_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="elbow_extension_passive_l"
                                        id="elbow_extension_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="elbow_extension_active_r"
                                        id="elbow_extension_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="elbow_extension_active_l"
                                        id="elbow_extension_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="elbow_extension_comment" id="elbow_extension_comment"  placeholder="Comment"/>
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="elbow_extension_muscle_r"
                                        id="elbow_extension_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="elbow_extension_muscle_l"
                                        id="elbow_extension_muscle_l" />
                                </td>
                            </tr>

                            <!-- Supination -->
                            <tr>
                                <td>Supination</td>
                                <td>0-80</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="elbow_supination_passive_r"
                                        id="elbow_supination_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="elbow_supination_passive_l"
                                        id="elbow_supination_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="elbow_supination_active_r"
                                        id="elbow_supination_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="elbow_supination_active_l"
                                        id="elbow_supination_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="elbow_supination_comment" id="elbow_supination_comment"placeholder="Comment" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="elbow_supination_muscle_r"
                                        id="elbow_supination_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="elbow_supination_muscle_l"
                                        id="elbow_supination_muscle_l" />
                                </td>
                            </tr>

                            <!-- Pronation -->
                            <tr>
                                <td>Pronation</td>
                                <td>0-80</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="elbow_pronation_passive_r"
                                        id="elbow_pronation_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="elbow_pronation_passive_l"
                                        id="elbow_pronation_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="elbow_pronation_active_r"
                                        id="elbow_pronation_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="elbow_pronation_active_l"
                                        id="elbow_pronation_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="elbow_pronation_comment" id="elbow_pronation_comment" placeholder="Comment"/>
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="elbow_pronation_muscle_r"
                                        id="elbow_pronation_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="elbow_pronation_muscle_l"
                                        id="elbow_pronation_muscle_l" />
                                </td>
                            </tr>

                            <!-- Flexion -->
                            <tr>
                                <td scope="row" rowspan="4"><label class="">Wrist</label></td>
                                <td>Flexion</td>
                                <td>0-50</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="wrist_flexion_passive_r"
                                        id="wrist_flexion_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="wrist_flexion_passive_l"
                                        id="wrist_flexion_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="wrist_flexion_active_r"
                                        id="wrist_flexion_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="wrist_flexion_active_l"
                                        id="wrist_flexion_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2" formControlName="wrist_flexion_comment"
                                        id="wrist_flexion_comment" placeholder="Comment"/>
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="wrist_flexion_muscle_r"
                                        id="wrist_flexion_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="wrist_flexion_muscle_l"
                                        id="wrist_flexion_muscle_l" />
                                </td>
                            </tr>

                            <!-- Extension -->
                            <tr>
                                <td>Extension</td>
                                <td>0-70</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="wrist_extension_passive_r"
                                        id="wrist_extension_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="wrist_extension_passive_l"
                                        id="wrist_extension_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="wrist_extension_active_r"
                                        id="wrist_extension_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="wrist_extension_active_l"
                                        id="wrist_extension_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="wrist_extension_comment" id="wrist_extension_comment" placeholder="Comment" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="wrist_extension_muscle_r"
                                        id="wrist_extension_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="wrist_extension_muscle_l"
                                        id="wrist_extension_muscle_l" />
                                </td>
                            </tr>

                            <!-- Ulnar Deviation -->
                            <tr>
                                <td>Ulnar Deviation</td>
                                <td>0-30</td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="wrist_ulnar_deviation_passive_r"
                                        id="wrist_ulnar_deviation_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="wrist_ulnar_deviation_passive_l"
                                        id="wrist_ulnar_deviation_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="wrist_ulnar_deviation_active_r"
                                        id="wrist_ulnar_deviation_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="wrist_ulnar_deviation_active_l"
                                        id="wrist_ulnar_deviation_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="wrist_ulnar_deviation_comment"
                                        id="wrist_ulnar_deviation_comment" placeholder="Comment"/>
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="wrist_ulnar_deviation_muscle_r"
                                        id="wrist_ulnar_deviation_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="wrist_ulnar_deviation_muscle_l"
                                        id="wrist_ulnar_deviation_muscle_l" />
                                </td>
                            </tr>

                            <!-- Radial Deviation -->
                            <tr>
                                <td>Radial Deviation</td>
                                <td>0-20</td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="wrist_radial_deviation_passive_r"
                                        id="wrist_radial_deviation_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="wrist_radial_deviation_passive_l"
                                        id="wrist_radial_deviation_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="wrist_radial_deviation_active_r"
                                        id="wrist_radial_deviation_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="wrist_radial_deviation_active_l"
                                        id="wrist_radial_deviation_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="wrist_radial_deviation_comment"
                                        id="wrist_radial_deviation_comment" placeholder="Comment"/>
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="wrist_radial_deviation_muscle_r"
                                        id="wrist_radial_deviation_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="wrist_radial_deviation_muscle_l"
                                        id="wrist_radial_deviation_muscle_l" />
                                </td>
                            </tr>

                            <!-- Thumb MCP -->
                            <tr>
                                <td scope="row" rowspan="5"><label class="">Digits</label></td>
                                <td>Thumb MCP Ext/Flex</td>
                                <td>10/30</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_thump_passive_r"
                                        id="digits_thump_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_thump_passive_l"
                                        id="digits_thump_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_thump_active_r"
                                        id="digits_thump_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_thump_active_l"
                                        id="digits_thump_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2" formControlName="digits_thump_comment"
                                        id="digits_thump_comment" placeholder="Comment" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_thump_muscle_r"
                                        id="digits_thump_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_thump_muscle_l"
                                        id="digits_thump_muscle_l" />
                                </td>
                            </tr>

                            <!-- Thumb IP -->
                            <tr>
                                <td>Thumb IP Ext/Flex</td>
                                <td>0/80</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_thump_ip_passive_r"
                                        id="digits_thump_ip_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_thump_ip_passive_l"
                                        id="digits_thump_ip_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_thump_ip_active_r"
                                        id="digits_thump_ip_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_thump_ip_active_l"
                                        id="digits_thump_ip_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="digits_thump_ip_comment" id="digits_thump_ip_comment" placeholder="Comment" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_thump_ip_muscle_r"
                                        id="digits_thump_ip_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_thump_ip_muscle_l"
                                        id="digits_thump_ip_muscle_l" />
                                </td>
                            </tr>

                            <!-- MCP Ext/Flex -->
                            <tr>
                                <td>MCP Ext/Flex</td>
                                <td>0/90</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_mcp_passive_r"
                                        id="digits_mcp_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_mcp_passive_l"
                                        id="digits_mcp_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_mcp_active_r"
                                        id="digits_mcp_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_mcp_active_l"
                                        id="digits_mcp_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2" formControlName="digits_mcp_comment"
                                        id="digits_mcp_comment" placeholder="Comment" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_mcp_muscle_r"
                                        id="digits_mcp_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_mcp_muscle_l"
                                        id="digits_mcp_muscle_l" />
                                </td>
                            </tr>

                            <!-- PIP Ext/Flex -->
                            <tr>
                                <td>PIP Ext/Flex</td>
                                <td>0/100</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_pip_passive_r"
                                        id="digits_pip_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_pip_passive_l"
                                        id="digits_pip_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_pip_active_r"
                                        id="digits_pip_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_pip_active_l"
                                        id="digits_pip_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2" formControlName="digits_pip_comment"
                                        id="digits_pip_comment"  placeholder="Comment"/>
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_pip_muscle_r"
                                        id="digits_pip_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_pip_muscle_l"
                                        id="digits_pip_muscle_l" />
                                </td>
                            </tr>

                            <!-- DIP Ext/Flex -->
                            <tr>
                                <td>DIP Ext/Flex</td>
                                <td>0/90</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_dip_passive_r"
                                        id="digits_dip_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_dip_passive_l"
                                        id="digits_dip_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_dip_active_r"
                                        id="digits_dip_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_dip_active_l"
                                        id="digits_dip_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2" formControlName="digits_dip_comment"
                                        id="digits_dip_comment" placeholder="Comment" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_dip_muscle_r"
                                        id="digits_dip_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="digits_dip_muscle_l"
                                        id="digits_dip_muscle_l" />
                                </td>
                            </tr>

                            <!-- HIP ROW -->
                            <!-- Flexion -->
                            <tr>
                                <td scope="row" rowspan="6"><label class="">Hip</label></td>
                                <td>Flexion</td>
                                <td>0-120</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="hip_flexion_passive_r"
                                        id="hip_flexion_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="hip_flexion_passive_l"
                                        id="hip_flexion_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="hip_flexion_active_r"
                                        id="hip_flexion_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="hip_flexion_active_l"
                                        id="hip_flexion_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2" formControlName="hip_flexion_comment"
                                        id="hip_flexion_comment" placeholder="Comment"/>
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="hip_flexion_muscle_r"
                                        id="hip_flexion_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="hip_flexion_muscle_l"
                                        id="hip_flexion_muscle_l" />
                                </td>
                            </tr>

                            <!-- Extension -->
                            <tr>
                                <td>Extension</td>
                                <td>0-20</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="hip_extension_passive_r"
                                        id="hip_extension_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="hip_extension_passive_l"
                                        id="hip_extension_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="hip_extension_active_r"
                                        id="hip_extension_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="hip_extension_active_l"
                                        id="hip_extension_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2" formControlName="hip_extension_comment"
                                        id="hip_extension_comment" placeholder="Comment" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="hip_extension_muscle_r"
                                        id="hip_extension_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="hip_extension_muscle_l"
                                        id="hip_extension_muscle_l" />
                                </td>
                            </tr>

                            <!-- Abduction -->
                            <tr>
                                <td>Abduction</td>
                                <td>0-45</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="hip_abduction_passive_r"
                                        id="hip_abduction_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="hip_abduction_passive_l"
                                        id="hip_abduction_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="hip_abduction_active_r"
                                        id="hip_abduction_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="hip_abduction_active_l"
                                        id="hip_abduction_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2" formControlName="hip_abduction_comment"
                                        id="hip_abduction_comment" placeholder="Comment"/>
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="hip_abduction_muscle_r"
                                        id="hip_abduction_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="hip_abduction_muscle_l"
                                        id="hip_abduction_muscle_l" />
                                </td>
                            </tr>

                            <!-- Adduction -->
                            <tr>
                                <td>Adduction</td>
                                <td>0-20</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="hip_adduction_passive_r"
                                        id="hip_adduction_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="hip_adduction_passive_l"
                                        id="hip_adduction_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="hip_adduction_active_r"
                                        id="hip_adduction_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="hip_adduction_active_l"
                                        id="hip_adduction_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2" formControlName="hip_adduction_comment"
                                        id="hip_adduction_comment"  placeholder="Comment"/>
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="hip_adduction_muscle_r"
                                        id="hip_adduction_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="hip_adduction_muscle_l"
                                        id="hip_adduction_muscle_l" />
                                </td>
                            </tr>

                            <!-- Internal Rotation -->
                            <tr>
                                <td>Internal Rotation</td>
                                <td>0-45</td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="hip_internal_rotation_passive_r"
                                        id="hip_internal_rotation_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="hip_internal_rotation_passive_l"
                                        id="hip_internal_rotation_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="hip_internal_rotation_active_r"
                                        id="hip_internal_rotation_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="hip_internal_rotation_active_l"
                                        id="hip_internal_rotation_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="hip_internal_rotation_comment"
                                        id="hip_internal_rotation_comment" placeholder="Comment" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="hip_internal_rotation_muscle_r"
                                        id="hip_internal_rotation_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="hip_internal_rotation_muscle_l"
                                        id="hip_internal_rotation_muscle_l" />
                                </td>
                            </tr>

                            <!-- External Rotation -->
                            <tr>
                                <td>External Rotation</td>
                                <td>0-45</td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="hip_external_rotation_passive_r"
                                        id="hip_external_rotation_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="hip_external_rotation_passive_l"
                                        id="hip_external_rotation_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="hip_external_rotation_active_r"
                                        id="hip_external_rotation_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="hip_external_rotation_active_l"
                                        id="hip_external_rotation_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="hip_external_rotation_comment"
                                        id="hip_external_rotation_comment" placeholder="Comment" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="hip_external_rotation_muscle_r"
                                        id="hip_external_rotation_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact"
                                        formControlName="hip_external_rotation_muscle_l"
                                        id="hip_external_rotation_muscle_l" />
                                </td>
                            </tr>

                            <!-- KNEE ROW -->
                            <!-- Flexion -->
                            <tr>
                                <td scope="row" rowspan="2"><label class="">Knee</label></td>
                                <td>Flexion</td>
                                <td>0-135</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="knee_flexion_passive_r"
                                        id="knee_flexion_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="knee_flexion_passive_l"
                                        id="knee_flexion_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="knee_flexion_active_r"
                                        id="knee_flexion_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="knee_flexion_active_l"
                                        id="knee_flexion_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2" formControlName="knee_flexion_comment"
                                        id="knee_flexion_comment" placeholder="Comment" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="knee_flexion_muscle_r"
                                        id="knee_flexion_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="knee_flexion_muscle_l"
                                        id="knee_flexion_muscle_l" />
                                </td>
                            </tr>

                            <!-- Extension -->
                            <tr>
                                <td>Extension</td>
                                <td>135-0</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="knee_extension_passive_r"
                                        id="knee_extension_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="knee_extension_passive_l"
                                        id="knee_extension_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="knee_extension_active_r"
                                        id="knee_extension_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="knee_extension_active_l"
                                        id="knee_extension_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="knee_extension_comment" id="knee_extension_comment" placeholder="Comment"/>
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="knee_extension_muscle_r"
                                        id="knee_extension_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="knee_extension_muscle_l"
                                        id="knee_extension_muscle_l" />
                                </td>
                            </tr>

                            <!-- ANKLE ROW -->
                            <!-- PF -->
                            <tr>
                                <td scope="row" rowspan="6"><label class=" ">Ankle</label></td>
                                <td>PF</td>
                                <td>0-45</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="ankle_pf_passive_r"
                                        id="ankle_pf_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="ankle_pf_passive_l"
                                        id="ankle_pf_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="ankle_pf_active_r"
                                        id="ankle_pf_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="ankle_pf_active_l"
                                        id="ankle_pf_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2" formControlName="ankle_pf_comment"
                                        id="ankle_pf_comment" placeholder="Comment"/>
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="ankle_pf_muscle_r"
                                        id="ankle_pf_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="ankle_pf_muscle_l"
                                        id="ankle_pf_muscle_l" />
                                </td>
                            </tr>

                            <!-- DF -->
                            <tr>
                                <td>DF</td>
                                <td>0-20</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="ankle_df_passive_r"
                                        id="ankle_df_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="ankle_df_passive_l"
                                        id="ankle_df_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="ankle_df_active_r"
                                        id="ankle_df_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="ankle_df_active_l"
                                        id="ankle_df_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2" formControlName="ankle_df_comment"
                                        id="ankle_df_comment"placeholder="Comment" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="ankle_df_muscle_r"
                                        id="ankle_df_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="ankle_df_muscle_l"
                                        id="ankle_df_muscle_l" />
                                </td>
                            </tr>

                            <!-- Inversion -->
                            <tr>
                                <td>Inversion</td>
                                <td>0-35</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="knee_inversion_passive_r"
                                        id="knee_inversion_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="knee_inversion_passive_l"
                                        id="knee_inversion_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="knee_inversion_active_r"
                                        id="knee_inversion_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="knee_inversion_active_l"
                                        id="knee_inversion_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="knee_inversion_comment" id="knee_inversion_comment"placeholder="Comment" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="knee_inversion_muscle_r"
                                        id="knee_inversion_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="knee_inversion_muscle_l"
                                        id="knee_inversion_muscle_l" />
                                </td>
                            </tr>

                            <!-- Eversion -->
                            <tr>
                                <td>Eversion</td>
                                <td>0-15</td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="knee_eversion_passive_r"
                                        id="knee_eversion_passive_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="knee_eversion_passive_l"
                                        id="knee_eversion_passive_l" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="knee_eversion_active_r"
                                        id="knee_eversion_active_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="knee_eversion_active_l"
                                        id="knee_eversion_active_l" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2" formControlName="knee_eversion_comment"
                                        id="knee_eversion_comment" placeholder="Comment" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="knee_eversion_muscle_r"
                                        id="knee_eversion_muscle_r" />
                                </td>
                                <td>
                                    <input type="checkbox" value="intact" formControlName="knee_eversion_muscle_l"
                                        id="knee_eversion_muscle_l" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <label class="subHeading mb-3">Deformities:</label>
            <div class="form-group">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col" class="text-center" rowspan="2">
                                    <label class="subHeading p-0">Extermeties</label>
                                </th>
                                <th scope="col" class="text-center" colspan="2">
                                    <label class="subHeading p-0">Muscle Tone</label>
                                </th>
                                <th scope="col" class="text-center" rowspan="2" colspan="2">
                                    <label class="subHeading p-0">Remarks</label>
                                </th>
                            </tr>
                            <tr>
                                <th class="text-center half-width">L</th>
                                <th class="text-center half-width">R</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Shoulder</td>

                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="extermeties_shoulder_muscle_tone_l"
                                        id="extermeties_shoulder_muscle_tone_l"placeholder="Muscle tone" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="extermeties_shoulder_muscle_tone_r"
                                        id="extermeties_shoulder_muscle_tone_r" placeholder="Muscle tone"/>
                                </td>
                                <td colspan="2">
                                    <input type="text" class="form-control px-2"
                                        formControlName="extermeties_shoulder_muscle_tone_remark"
                                        id="extermeties_shoulder_muscle_tone_remark" placeholder="Remark" />
                                </td>
                            </tr>
                            <tr>
                                <td>Elbow</td>

                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="extermeties_elbow_muscle_tone_l"
                                        id="extermeties_elbow_muscle_tone_l" placeholder="Muscle tone"/>
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="extermeties_elbow_muscle_tone_r"
                                        id="extermeties_elbow_muscle_tone_r" placeholder="Muscle tone"/>
                                </td>
                                <td colspan="2">
                                    <input type="text" class="form-control px-2"
                                        formControlName="extermeties_elbow_muscle_tone_remark"
                                        id="extermeties_elbow_muscle_tone_remark"  placeholder="Remark" />
                                </td>
                            </tr>
                            <tr>
                                <td>Wrist</td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="extermeties_wrist_muscle_tone_l"
                                        id="extermeties_wrist_muscle_tone_l"placeholder="Muscle tone" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="extermeties_wrist_muscle_tone_r"
                                        id="extermeties_wrist_muscle_tone_r" placeholder="Muscle tone"/>
                                </td>
                                <td colspan="2">
                                    <input type="text" class="form-control px-2"
                                        formControlName="extermeties_wrist_muscle_tone_remark"
                                        id="extermeties_wrist_muscle_tone_remark"  placeholder="Remark"/>
                                </td>
                            </tr>
                            <tr>
                                <td>Hip</td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="extermeties_hip_muscle_tone_l"
                                        id="extermeties_hip_muscle_tone_l" placeholder="Muscle tone"/>
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="extermeties_hip_muscle_tone_r"
                                        id="extermeties_hip_muscle_tone_r" placeholder="Muscle tone"/>
                                </td>
                                <td colspan="2">
                                    <input type="text" class="form-control px-2"
                                        formControlName="extermeties_hip_muscle_tone_remark"
                                        id="extermeties_hip_muscle_tone_remark"  placeholder="Remark" />
                                </td>
                            </tr>
                            <tr>
                                <td>Knee</td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="extermeties_knee_muscle_tone_l"
                                        id="extermeties_knee_muscle_tone_l"placeholder="Muscle tone" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="extermeties_knee_muscle_tone_r"
                                        id="extermeties_knee_muscle_tone_r"placeholder="Muscle tone" />
                                </td>
                                <td colspan="2">
                                    <input type="text" class="form-control px-2"
                                        formControlName="extermeties_knee_muscle_tone_remark"
                                        id="extermeties_knee_muscle_tone_remark"  placeholder="Remark" />
                                </td>
                            </tr>
                            <tr>
                                <td>Ankle</td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="extermeties_ankle_muscle_tone_l"
                                        id="extermeties_ankle_muscle_tone_l"  placeholder="Muscle tone" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="extermeties_ankle_muscle_tone_r"
                                        id="extermeties_ankle_muscle_tone_r" placeholder="Muscle tone"/>
                                </td>
                                <td colspan="2">
                                    <input type="text" class="form-control px-2"
                                        formControlName="extermeties_ankle_muscle_tone_remark"
                                        id="extermeties_ankle_muscle_tone_remark"  placeholder="Remark" />
                                </td>
                            </tr>
                            <tr>
                                <td>Trunk</td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="extermeties_trunk_muscle_tone_l"
                                        id="extermeties_trunk_muscle_tone_l" placeholder="Muscle tone" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="extermeties_trunk_muscle_tone_r"
                                        id="extermeties_trunk_muscle_tone_r"   placeholder="Muscle tone"/>
                                </td>
                                <td colspan="2">
                                    <input type="text" class="form-control px-2"
                                        formControlName="extermeties_trunk_muscle_tone_remark"
                                        id="extermeties_trunk_muscle_tone_remark"   placeholder="Remark"/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <label class="subHeading mb-3">Gross Motor Skills:</label>
            <div class="form-group">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col" class="text-center">
                                    <label class="subHeading p-0">Age</label>
                                </th>
                                <th scope="col" class="text-center">
                                    <label class="subHeading p-0">Activity</label>
                                </th>
                                <th scope="col" class="text-center">
                                    <label class="subHeading p-0">Yes</label>
                                </th>
                                <th scope="col" class="text-center">
                                    <label class="subHeading p-0">No</label>
                                </th>
                                <th scope="col" class="text-center">
                                    <label class="subHeading p-0">Remarks</label>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>3 Months</td>
                                <td>Neck Holding</td>
                                <td>
                                    <input class="m-3 p-radiobutton " type="radio" value="Yes"
                                        formControlName="three_months_neck" name="three_months_neck" id="three_months_neck" (change)="handleInput($event, 'neck')" />
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No"
                                        formControlName="three_months_neck" name="three_months_neck" id="three_months_neck" (change)="handleInput($event, 'neck')" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="three_months_neck_remark" id="three_months_neck_remark" placeholder="Remark"   [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('three_months_neck_remark')?.invalid) ||
                                        (initialAssessementPedia.get('three_months_neck_remark')?.invalid &&
                                          initialAssessementPedia.get('three_months_neck_remark').touched)
                                      "/>
                                      <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>5 Months</td>
                                <td>Roll Over</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes"
                                        formControlName="five_months_roll_over" name="five_months_roll_over" id="five_months_roll_over"  (change)="handleInput($event, 'roll')"/>
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No"
                                        formControlName="five_months_roll_over" name="five_months_roll_over" id="five_months_roll_over" (change)="handleInput($event, 'roll')"/>
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="five_months_roll_over_remark"
                                        id="five_months_roll_over_remark" placeholder="Remark"    [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('five_months_roll_over_remark')?.invalid) ||
                                        (initialAssessementPedia.get('five_months_roll_over_remark')?.invalid &&
                                          initialAssessementPedia.get('five_months_roll_over_remark').touched)
                                      "/>
                                      <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>6 Months</td>
                                <td>Sits in tripod fashion</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes"
                                        formControlName="six_months_tripod_fashion" name="six_months_tripod_fashion" id="six_months_tripod_fashion"  (change)="handleInput($event, 'tripod')"/>
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No"
                                        formControlName="six_months_tripod_fashion" name="six_months_tripod_fashion" id="six_months_tripod_fashion" (change)="handleInput($event, 'tripod')" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="six_months_tripod_fashion_remark"
                                        id="six_months_tripod_fashion_remark"  placeholder="Remark"   [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('six_months_tripod_fashion_remark')?.invalid) ||
                                        (initialAssessementPedia.get('six_months_tripod_fashion_remark')?.invalid &&
                                          initialAssessementPedia.get('six_months_tripod_fashion_remark').touched)
                                      " />
                                      <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>8 Months</td>
                                <td>Sitting without support</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes"
                                        formControlName="eight_months_sitting" name="eight_months_sitting" id="eight_months_sitting" (change)="handleInput($event, 'sitting')" />
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No"
                                        formControlName="eight_months_sitting" name="eight_months_sitting" id="eight_months_sitting"  (change)="handleInput($event, 'sitting')"/>
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="eight_months_sitting_remark"
                                        id="eight_months_sitting_remark"  placeholder="Remark"   [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('eight_months_sitting_remark')?.invalid) ||
                                        (initialAssessementPedia.get('eight_months_sitting_remark')?.invalid &&
                                          initialAssessementPedia.get('eight_months_sitting_remark').touched)
                                      " />
                                      <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>9 Months</td>
                                <td>Stands with support</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes"
                                        formControlName="nine_months_stand" name="nine_months_stand" id="nine_months_stand"  (change)="handleInput($event, 'support')"/>
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No"
                                        formControlName="nine_months_stand" name="nine_months_stand" id="nine_months_stand"  (change)="handleInput($event, 'support')"/>
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="nine_months_stand_remark" id="nine_months_stand_remark" placeholder="Remark"   [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('nine_months_stand_remark')?.invalid) ||
                                        (initialAssessementPedia.get('nine_months_stand_remark')?.invalid &&
                                          initialAssessementPedia.get('nine_months_stand_remark').touched)
                                      " />
                                      <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>12 Months</td>
                                <td>Stands without support</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes"
                                        formControlName="twelve_months_stand" id="twelve_months_stand"  (change)="handleInput($event, 'stand')"/>
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No"
                                        formControlName="twelve_months_stand" id="twelve_months_stand"  (change)="handleInput($event, 'stand')"/>
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="twelve_months_stand_remark" id="twelve_months_stand_remark"   placeholder="Remark"  [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('twelve_months_stand_remark')?.invalid) ||
                                        (initialAssessementPedia.get('twelve_months_stand_remark')?.invalid &&
                                          initialAssessementPedia.get('twelve_months_stand_remark').touched)
                                      " />
                                      <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>15 Months</td>
                                <td>Walk Alone</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes"
                                        formControlName="fifteen_months_walk"  id="fifteen_months_walk" (change)="handleInput($event, 'walk alone')" />
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No"
                                        formControlName="fifteen_months_walk" id="fifteen_months_walk"  (change)="handleInput($event, 'walk alone')"/>
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="fifteen_months_walk_remark" id="fifteen_months_walk_remark"   placeholder="Remark"   [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('fifteen_months_walk_remark')?.invalid) ||
                                        (initialAssessementPedia.get('fifteen_months_walk_remark')?.invalid &&
                                          initialAssessementPedia.get('fifteen_months_walk_remark').touched)
                                      "/>
                                      <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>18 Months</td>
                                <td>Run</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes"
                                        formControlName="eighteen_months_run" id="eighteen_months_run"  (change)="handleInput($event, 'run')"/>
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No"
                                        formControlName="eighteen_months_run" id="eighteen_months_run" (change)="handleInput($event, 'run')" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="eighteen_months_run_remark" id="eighteen_months_run_remark"  placeholder="Remark"   [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('eighteen_months_run_remark')?.invalid) ||
                                        (initialAssessementPedia.get('eighteen_months_run_remark')?.invalid &&
                                          initialAssessementPedia.get('eighteen_months_run_remark').touched)
                                      " />
                                     <div class="invalid-feedback">Remark is Required</div> 
                                </td>
                            </tr>
                            <tr>
                                <td>2 Years</td>
                                <td>Walk up and downstairs</td>
                                <td>
                                    <input class="m-3  p-radiobutton" type="radio" value="Yes"
                                        formControlName="two_years_walk_up" id="two_years_walk_up" (change)="handleInput($event, 'walk')" />
                                </td>
                                <td>
                                    <input class="m-3  p-radiobutton" type="radio" value="No"
                                        formControlName="two_years_walk_up" id="two_years_walk_up"  (change)="handleInput($event, 'walk')"/>
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="two_years_walk_up_remark" id="two_years_walk_up_remark"   placeholder="Remark"   [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('two_years_walk_up_remark')?.invalid) ||
                                        (initialAssessementPedia.get('two_years_walk_up_remark')?.invalid &&
                                          initialAssessementPedia.get('two_years_walk_up_remark').touched)
                                      "/>
                                      <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>3 Years</td>
                                <td>Rides Tricycle</td>
                                <td>
                                    <input class="m-3  p-radiobutton" type="radio" value="Yes"
                                        formControlName="three_years_rides" id="three_years_rides"  (change)="handleInput($event, 'rides')"/>
                                </td>
                                <td>
                                    <input class="m-3  p-radiobutton" type="radio" value="No"
                                        formControlName="three_years_rides" id="three_years_rides" (change)="handleInput($event, 'rides')"/>
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="three_years_rides_remark" id="three_years_rides_remark" placeholder="Remark"  [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('three_years_rides_remark')?.invalid) ||
                                        (initialAssessementPedia.get('three_years_rides_remark')?.invalid &&
                                          initialAssessementPedia.get('three_years_rides_remark').touched)
                                      " />
                                      <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>4 Years</td>
                                <td>Alternate feet going upstairs/downstairs</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes"
                                        formControlName="four_years_upstairs" id="four_years_upstairs"(change)="handleInput($event, 'upstair')" />
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No"
                                        formControlName="four_years_upstairs" id="four_years_upstairs" (change)="handleInput($event, 'upstair')" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="four_years_upstairs_remark" id="four_years_upstairs_remark"  placeholder="Remark"   [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('four_years_upstairs_remark')?.invalid) ||
                                        (initialAssessementPedia.get('four_years_upstairs_remark')?.invalid &&
                                          initialAssessementPedia.get('four_years_upstairs_remark').touched)
                                      "/>
                                      <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <label class="subHeading mb-3">Fine Motor Skills:</label>
            <div class="form-group">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col" class="text-center">
                                    <label class="subHeading p-0">Age</label>
                                </th>
                                <th scope="col" class="text-center">
                                    <label class="subHeading p-0">Activity</label>
                                </th>
                                <th scope="col" class="text-center">
                                    <label class="subHeading p-0">Yes</label>
                                </th>
                                <th scope="col" class="text-center">
                                    <label class="subHeading p-0">No</label>
                                </th>
                                <th scope="col" class="text-center">
                                    <label class="subHeading p-0">Remarks</label>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>4 Months</td>
                                <td>Bidextrous reach</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes"
                                        formControlName="four_months_bidextrous" id="four_months_bidextrous"  (change)="handleInput($event, 'bidextrous')"/>
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No"
                                        formControlName="four_months_bidextrous" id="four_months_bidextrous" (change)="handleInput($event, 'bidextrous')" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="four_months_bidextrous_remark"
                                        id="four_months_bidextrous_remark"  placeholder="Remark" [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('four_months_bidextrous_remark')?.invalid) ||
                                        (initialAssessementPedia.get('four_months_bidextrous_remark')?.invalid &&
                                          initialAssessementPedia.get('four_months_bidextrous_remark').touched)
                                      " />
                                       <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>6 Months</td>
                                <td>Transfer Objects</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes"
                                        formControlName="six_months_transfer" id="six_months_transfer" (change)="handleInput($event, 'transfer')"/>
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No"
                                        formControlName="six_months_transfer" id="six_months_transfer"(change)="handleInput($event, 'transfer')" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="six_months_transfer_remark" id="six_months_transfer_remark"  placeholder="Remark" [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('six_months_transfer_remark')?.invalid) ||
                                        (initialAssessementPedia.get('six_months_transfer_remark')?.invalid &&
                                          initialAssessementPedia.get('six_months_transfer_remark').touched)
                                      " />
                                       <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>9 Months</td>
                                <td>Immature pincer grasp</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes"
                                        formControlName="nine_months_immature" id="nine_months_immature"(change)="handleInput($event, 'immature')" />
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No"
                                        formControlName="nine_months_immature" id="nine_months_immature" (change)="handleInput($event, 'immature')" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="nine_months_immature_remark"
                                        id="nine_months_immature_remark"  placeholder="Remark" [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('nine_months_immature_remark')?.invalid) ||
                                        (initialAssessementPedia.get('nine_months_immature_remark')?.invalid &&
                                          initialAssessementPedia.get('nine_months_immature_remark').touched)
                                      "/>
                                       <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>12 Months</td>
                                <td>Mature pincer grasp</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes"
                                        formControlName="twelve_months_mature" id="twelve_months_mature"  (change)="handleInput($event, 'mature')"/>
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No"
                                        formControlName="twelve_months_mature" id="twelve_months_mature"  (change)="handleInput($event, 'mature')"/>
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="twelve_months_mature_remark"
                                        id="twelve_months_mature_remark"  placeholder="Remark" [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('twelve_months_mature_remark')?.invalid) ||
                                        (initialAssessementPedia.get('twelve_months_mature_remark')?.invalid &&
                                          initialAssessementPedia.get('twelve_months_mature_remark').touched)
                                      "/>
                                       <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>15 Months</td>
                                <td>Tower of 2 blocks</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes"
                                        formControlName="fifteen_months_tower" id="fifteen_months_tower"  (change)="handleInput($event, 'fifteen')"/>
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No"
                                        formControlName="fifteen_months_tower" id="fifteen_months_tower"  (change)="handleInput($event, 'fifteen')"/>
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="fifteen_months_tower_remark"
                                        id="fifteen_months_tower_remark"  placeholder="Remark"[class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('fifteen_months_tower_remark')?.invalid) ||
                                        (initialAssessementPedia.get('fifteen_months_tower_remark')?.invalid &&
                                          initialAssessementPedia.get('fifteen_months_tower_remark').touched)
                                      " />
                                       <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>18 Months</td>
                                <td>Tower of 3 blocks</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes"
                                        formControlName="eighteen_months_tower" id="eighteen_months_tower"  (change)="handleInput($event, 'eighteen')"/>
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No"
                                        formControlName="eighteen_months_tower" id="eighteen_months_tower" (change)="handleInput($event, 'eighteen')" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="eighteen_months_tower_remark"
                                        id="eighteen_months_tower_remark" placeholder="Remark" [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('eighteen_months_tower_remark')?.invalid) ||
                                        (initialAssessementPedia.get('eighteen_months_tower_remark')?.invalid &&
                                          initialAssessementPedia.get('eighteen_months_tower_remark').touched)
                                      " />
                                       <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>2 Years</td>
                                <td>Tower of 6 blocks</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes" formControlName="two_years_tower"
                                        id="two_years_tower" (change)="handleInput($event, 'two')" />
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No" formControlName="two_years_tower"
                                        id="two_years_tower"  (change)="handleInput($event, 'two')"/>
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="two_years_tower_remark" id="two_years_tower_remark" placeholder="Remark"[class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('two_years_tower_remark')?.invalid) ||
                                        (initialAssessementPedia.get('two_years_tower_remark')?.invalid &&
                                          initialAssessementPedia.get('two_years_tower_remark').touched)
                                      "  />
                                       <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>3 Years</td>
                                <td>Tower of 9 blocks</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes"
                                        formControlName="three_years_tower" id="three_years_tower"  (change)="handleInput($event, 'tower')"/>
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No"
                                        formControlName="three_years_tower" id="three_years_tower" (change)="handleInput($event, 'tower')"/>
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="three_years_tower_remark" id="three_years_tower_remark"  placeholder="Remark" [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('three_years_tower_remark')?.invalid) ||
                                        (initialAssessementPedia.get('three_years_tower_remark')?.invalid &&
                                          initialAssessementPedia.get('three_years_tower_remark').touched)
                                      "/>
                                       <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>4 Years</td>
                                <td>Copies cross; bridge with block</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes" formControlName="four_years_cross"
                                        id="four_years_cross"  (change)="handleInput($event, 'cross')"/>
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No" formControlName="four_years_cross"
                                        id="four_years_cross" (change)="handleInput($event, 'cross')" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="four_years_cross_remark" id="four_years_cross_remark" placeholder="Remark" [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('four_years_cross_remark')?.invalid) ||
                                        (initialAssessementPedia.get('four_years_cross_remark')?.invalid &&
                                          initialAssessementPedia.get('four_years_cross_remark').touched)
                                      " />
                                       <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>5 Years</td>
                                <td>Copies with triangle</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes"
                                        formControlName="five_years_triangle" id="five_years_triangle" (change)="handleInput($event, 'triangle')"/>
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No"
                                        formControlName="five_years_triangle" id="five_years_triangle"  (change)="handleInput($event, 'triangle')"/>
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="five_years_triangle_remark" id="five_years_triangle_remark" placeholder="Remark" [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('five_years_triangle_remark')?.invalid) ||
                                        (initialAssessementPedia.get('five_years_triangle_remark')?.invalid &&
                                          initialAssessementPedia.get('five_years_triangle_remark').touched)
                                      " />
                                       <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <label class="subHeading mb-3">Cognition and Adaptive Skills:</label>
            <div class="form-group">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col" class="text-center">
                                    <label class="subHeading p-0">Age</label>
                                </th>
                                <th scope="col" class="text-center">
                                    <label class="subHeading p-0">Activity</label>
                                </th>
                                <th scope="col" class="text-center">
                                    <label class="subHeading p-0">Yes</label>
                                </th>
                                <th scope="col" class="text-center">
                                    <label class="subHeading p-0">No</label>
                                </th>
                                <th scope="col" class="text-center">
                                    <label class="subHeading p-0">Remarks</label>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>2 Months</td>
                                <td>Social smile</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes" formControlName="two_months_smile"
                                        id="two_months_smile"  (change)="handleInput($event, 'smile')"/>
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No" formControlName="two_months_smile"
                                        id="two_months_smile" (change)="handleInput($event, 'smile')" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="two_months_smile_remark" id="two_months_smile_remark" placeholder="Remark" [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('two_months_smile_remark')?.invalid) ||
                                        (initialAssessementPedia.get('two_months_smile_remark')?.invalid &&
                                          initialAssessementPedia.get('two_months_smile_remark').touched)
                                      " />
                                       <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>3 Months</td>
                                <td>Recognize mother</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes"
                                        formControlName="three_months_reco_mother" id="three_months_reco_mother"(change)="handleInput($event, 'mother')" />
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No"
                                        formControlName="three_months_reco_mother" id="three_months_reco_mother" (change)="handleInput($event, 'mother')"/>
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="three_months_reco_mother_remark"
                                        id="three_months_reco_mother_remark"  placeholder="Remark" [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('three_months_reco_mother_remark')?.invalid) ||
                                        (initialAssessementPedia.get('three_months_reco_mother_remark')?.invalid &&
                                          initialAssessementPedia.get('three_months_reco_mother_remark').touched)
                                      "/>
                                       <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>4 Months</td>
                                <td>Stranger anxiety</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes"
                                        formControlName="four_months_anxiety" id="four_months_anxiety"  (change)="handleInput($event, 'anxiety')"/>
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No"
                                        formControlName="four_months_anxiety" id="four_months_anxiety"(change)="handleInput($event, 'anxiety')" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="four_months_anxiety_remark" id="four_months_anxiety_remark" placeholder="Remark" [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('four_months_anxiety_remark')?.invalid) ||
                                        (initialAssessementPedia.get('four_months_anxiety_remark')?.invalid &&
                                          initialAssessementPedia.get('four_months_anxiety_remark').touched)
                                      " />
                                       <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>9 Months</td>
                                <td>Waves 'bye-bye'</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes"
                                        formControlName="nine_months_waves" id="nine_months_waves"  (change)="handleInput($event, 'waves')" />
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No"
                                        formControlName="nine_months_waves" id="nine_months_waves" (change)="handleInput($event, 'waves')" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="nine_months_waves_remark" id="nine_months_waves_remark"   placeholder="Remark" [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('nine_months_waves_remark')?.invalid) ||
                                        (initialAssessementPedia.get('nine_months_waves_remark')?.invalid &&
                                          initialAssessementPedia.get('nine_months_waves_remark').touched)
                                      "/>
                                       <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>12 Months</td>
                                <td>Comes when called</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes"
                                        formControlName="twelve_months_comes" id="twelve_months_comes"  (change)="handleInput($event, 'comes')"/>
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No"
                                        formControlName="twelve_months_comes" id="twelve_months_comes"  (change)="handleInput($event, 'comes')"/>
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="twelve_months_comes_remark" id="twelve_months_comes_remark" placeholder="Remark" [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('twelve_months_comes_remark')?.invalid) ||
                                        (initialAssessementPedia.get('twelve_months_comes_remark')?.invalid &&
                                          initialAssessementPedia.get('twelve_months_comes_remark').touched)
                                      " />
                                       <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>18 Months</td>
                                <td>Copies of Task</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes"
                                        formControlName="eighteen_months_task" id="eighteen_months_task"  (change)="handleInput($event, 'task')"/>
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No"
                                        formControlName="eighteen_months_task" id="eighteen_months_task"(change)="handleInput($event, 'task')" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="eighteen_months_task_remark"
                                        id="eighteen_months_task_remark" placeholder="Remark" [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('eighteen_months_task_remark')?.invalid) ||
                                        (initialAssessementPedia.get('eighteen_months_task_remark')?.invalid &&
                                          initialAssessementPedia.get('eighteen_months_task_remark').touched)
                                      " />
                                       <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>2 Years</td>
                                <td>Ask for food, drink, toilet</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes" formControlName="two_years_askfor"
                                        id="two_years_askfor"  (change)="handleInput($event, 'askfor')"/>
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No" formControlName="two_years_askfor"
                                        id="two_years_askfor"  (change)="handleInput($event, 'askfor')"/>
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="two_years_askfor_remark" id="two_years_askfor_remark"  placeholder="Remark" [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('two_years_askfor_remark')?.invalid) ||
                                        (initialAssessementPedia.get('two_years_askfor_remark')?.invalid &&
                                          initialAssessementPedia.get('two_years_askfor_remark').touched)
                                      " />
                                       <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>3 Years</td>
                                <td>Knows full name</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes" formControlName="three_years_name"
                                        id="three_years_name" (change)="handleInput($event, 'name')" />
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No" formControlName="three_years_name"
                                        id="three_years_name"(change)="handleInput($event, 'name')" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="three_years_name_remark" id="three_years_name_remark"  placeholder="Remark" [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('three_years_name_remark')?.invalid) ||
                                        (initialAssessementPedia.get('three_years_name_remark')?.invalid &&
                                          initialAssessementPedia.get('three_years_name_remark').touched)
                                      "/>
                                       <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>4 Years</td>
                                <td>Goes to toilet alone</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes"
                                        formControlName="four_years_toilet" id="four_years_toilet" (change)="handleInput($event, 'toilet')" />
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No"
                                        formControlName="four_years_toilet" id="four_years_toilet"(change)="handleInput($event, 'toilet')" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="four_years_toilet_remark" id="four_years_toilet_remark" placeholder="Remark" [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('four_years_toilet_remark')?.invalid) ||
                                        (initialAssessementPedia.get('four_years_toilet_remark')?.invalid &&
                                          initialAssessementPedia.get('four_years_toilet_remark').touched)
                                      " />
                                       <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>5 Years</td>
                                <td>Can dress and undress</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes" formControlName="five_years_dress"
                                        id="five_years_dress" (change)="handleInput($event, 'dress')" />
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No" formControlName="five_years_dress"
                                        id="five_years_dress" (change)="handleInput($event, 'dress')"/>
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2"
                                        formControlName="five_years_dress_remark" id="five_years_dress_remark"  placeholder="Remark" [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('five_years_dress_remark')?.invalid) ||
                                        (initialAssessementPedia.get('five_years_dress_remark')?.invalid &&
                                          initialAssessementPedia.get('five_years_dress_remark').touched)
                                      " />
                                       <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <label class="subHeading mb-3">Language Skills:</label>
            <div class="form-group">
                <div class="row mb-3">
                    <div class="col-6">
                        <p class="d-flex align-items-center">
                            <input type="radio" id="language_normal" formControlName="language_normal"
                                value="Normal" />
                            <label for="language_normal" class="mb-0 mx-2 ">Normal</label>
                        </p>
                        <p class="d-flex align-items-center">
                            <input type="radio" id="language_delayed" formControlName="language_normal"
                                value="Delayed" />
                            <label for="language_delayed" class="mb-0 mx-2">Delayed</label>
                        </p>
                    </div>

                    <div class="col-12 col-sm-6">
                        <label class="px-0 mt-1">Remarks:</label>
                        <div class="form-group">
                            <textarea formControlName="language_remark" id="language_remark" class="form-control"
                                rows="3" placeholder="Remark"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Tranfer and Assisting Devices  -->
        <div class="add-listings-box">
            <h3>Transfer and Assisting Devices:</h3>
            <div class="form-group">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Transfer and Assisting Devices:</th>
                                <th>Yes</th>
                                <th>No</th>
                                <th>Remarks</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Orthosis</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes" formControlName="orthosis"
                                        id="orthosis" (change)="handleInput($event, 'orthosis')" />
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No" formControlName="orthosis"
                                        id="orthosis"  (change)="handleInput($event, 'orthosis')"/>
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2" formControlName="orthosis_remark"
                                        id="orthosis_remark"   placeholder="Remark" [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('orthosis_remark')?.invalid) ||
                                        (initialAssessementPedia.get('orthosis_remark')?.invalid &&
                                          initialAssessementPedia.get('orthosis_remark').touched)
                                      " />
                                           <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>Splint</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes" formControlName="splint"
                                        id="splint" (change)="handleInput($event, 'splint')" />
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No" formControlName="splint"
                                        id="splint" (change)="handleInput($event, 'splint')" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2" formControlName="splint_remark"
                                        id="splint_remark" placeholder="Remark" [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('splint_remark')?.invalid) ||
                                        (initialAssessementPedia.get('splint_remark')?.invalid &&
                                          initialAssessementPedia.get('splint_remark').touched)
                                      "  />
                                           <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>Wheelchair</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes" formControlName="wheelchair"
                                        id="wheelchair" (change)="handleInput($event, 'wheelchair')" />
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No" formControlName="wheelchair"
                                        id="wheelchair" (change)="handleInput($event, 'wheelchair')" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2" formControlName="wheelchair_remark"
                                        id="wheelchair_remark" placeholder="Remark"  [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('wheelchair_remark')?.invalid) ||
                                        (initialAssessementPedia.get('wheelchair_remark')?.invalid &&
                                          initialAssessementPedia.get('wheelchair_remark').touched)
                                      "   />
                                           <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>Cruthces</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes" formControlName="cruthces"
                                        id="cruthces"  (change)="handleInput($event, 'cruthces')"/>
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No" formControlName="cruthces"
                                        id="cruthces"  (change)="handleInput($event, 'cruthces')"/>
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2" formControlName="cruthces_remark"
                                        id="cruthces_remark" placeholder="Remark" [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('cruthces_remark')?.invalid) ||
                                        (initialAssessementPedia.get('cruthces_remark')?.invalid &&
                                          initialAssessementPedia.get('cruthces_remark').touched)
                                      "   />
                                           <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                            <tr>
                                <td>Others</td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="Yes" formControlName="other"
                                        id="other" (change)="handleInput($event, 'remark')" />
                                </td>
                                <td>
                                    <input class="m-3 p-radiobutton" type="radio" value="No" formControlName="other"
                                        id="other" (change)="handleInput($event, 'remark')" />
                                </td>
                                <td>
                                    <input type="text" class="form-control px-2" formControlName="other_remark"
                                        id="other_remark" placeholder="Remark" [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('other_remark')?.invalid) ||
                                        (initialAssessementPedia.get('other_remark')?.invalid &&
                                          initialAssessementPedia.get('other_remark').touched)
                                      " />
                                           <div class="invalid-feedback">Remark is Required</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="col-sm-6 col-12">
                        <label class="subHeading p-0">Problems Noted: </label>
                        <textarea formControlName="problems_noted" id="problems_noted" placeholder="Problem Note"
                            class="form-control px-2" id="" rows="3" [disabled]=""></textarea>
                    </div>

                    <div class="col-sm-6 col-12">
                        <label class="subHeading p-0 my-3">Session Goals: </label>
                        <div class="row">
                            <div class="col-12 col-sm-5">
                                <div class="form-group">
                                    <label class="checkbox checkbox-outline px-0">
                                        <input type="checkbox" value="sterilizeAll" formControlName="short_term"
                                            id="short_term" (change)="handleInput($event, 'short')" />
                                        Short Term(1 Month)
                                    </label>
                                </div>
                            </div>
                            <div class="col-12 col-sm-7">
                                <div class="form-group">
                                    <input type="text" class="w-100 form-control" value="intact"
                                        formControlName="short_term_session" id="short_term_session"  placeholder="Short term session" [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('short_term_session')?.invalid) ||
                                        (initialAssessementPedia.get('short_term_session')?.invalid &&
                                          initialAssessementPedia.get('short_term_session').touched)
                                      "  />
                                       <div class="invalid-feedback">Remark is Required</div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-5">
                                <div class="form-group">
                                    <label class="checkbox checkbox-outline px-0">
                                        <input type="checkbox" value="sterilizeAll" formControlName="long_term"
                                            id="long_term"  (change)="handleInput($event, 'long')"/>
                                        Long Term(3-6 Month)
                                    </label>
                                </div>
                            </div>

                            <div class="col-12 col-sm-7">
                                <div class="form-group">
                                    <input type="text" class="w-100 form-control" value="intact"
                                        formControlName="long_term_session" id="long_term_session" placeholder="Long term session"  [class.is-invalid]="
                                        (pediaForm.submitted &&
                                          initialAssessementPedia.get('long_term_session')?.invalid) ||
                                        (initialAssessementPedia.get('long_term_session')?.invalid &&
                                          initialAssessementPedia.get('long_term_session').touched)
                                      " />
                                       <div class="invalid-feedback">Remark is Required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- scope of session -->
                    <div class="col-sm-6 col-12">
                        <div class="form-group">
                            <label class="subHeading p-0">Scope of Session:</label>
                            <p-dropdown optionValue="id" [options]="sessionList" class="data-padding"
                                optionLabel="session_title" [filter]="true" filterBy="session_title"
                                formControlName="scope_session" id="scope_session" placeholder="Scope of session"
                                [resetFilterOnHide]="true">
                            </p-dropdown>
                        </div>
                    </div>

                    <!-- Recommended duration -->
                    <div class="col-sm-6 col-12">
                        <div class="form-group">
                            <label class="subHeading p-0">Recommended Duration:</label>
                            <p-dropdown optionValue="id" [options]="recomended_duration" optionLabel="month"
                                [filter]="true" filterBy="month" formControlName="recommended_duration"
                                id="recommended_duration" placeholder="Recommended duration" [resetFilterOnHide]="true">
                            </p-dropdown>
                        </div>
                    </div>

                    <!-- No. of PT Sessions per Month -->
                    <div class="col-sm-6 col-12">
                        <div class="form-group">
                            <label class="subHeading p-0">No. of PT Sessions per Month:</label>
                            <p-dropdown optionValue="session_per_month" [options]="session_per_month" optionLabel="session_per_month"
                                [filter]="true" filterBy="name" formControlName="no_pt_sesssion_per_month"
                                id="no_pt_sesssion_per_month" placeholder="No of PT session per month"
                                [resetFilterOnHide]="true">
                            </p-dropdown>
                        </div>
                    </div>

                    <!-- Goals to be acheived -->
                    <div class="col-sm-6 col-12">
                        <div class="form-group">
                            <label class="subHeading p-0">Goals to be Acheived: </label>
                            <textarea formControlName="goals_acheived" placeholder="Goals to be acheived"
                                class="form-control px-2" id="goals_acheived" rows="3"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="add-listings-box">
            <h3>Sessions Technique:</h3>
            <div class="form-group">
                <!-- Exercise (ROM) -->
                <div class="row">
                    <div class="col-12 col-sm-2">
                        <div class="form-group">
                            <label class="checkbox checkbox-outline px-0">
                                <input type="checkbox" value="sterilizeAll" formControlName="exercise_checkbox"
                                    id="exercise_checkbox"  (change)="handleInput($event, 'exercise')"/>
                                Exercise(ROM)
                            </label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-5 mt-1">
                        <p-dropdown optionValue="duration" [options]="duration" optionLabel="duration" [filter]="true"
                            filterBy="duration" formControlName="exercise_duration" id="exercise_duration"
                            placeholder="Duration" [resetFilterOnHide]="true" [class.is-invalid]="(pediaForm.submitted &&
                            initialAssessementPedia.get('exercise_duration')?.invalid)
                            ||
                            initialAssessementPedia.get('exercise_duration')?.invalid
                            &&
                            initialAssessementPedia.get('exercise_duration').touched">
                        </p-dropdown>
                        <div class="invalid-feedback">Exercise duration is required</div>
                    </div>
                    <div class="col-12 col-sm-5 mt-1">
                        <div class="form-group">
                            <input type="text" class="w-100 form-control" value="intact"
                                formControlName="exercise_details" id="exercise_details" placeholder="Details"  [class.is-invalid]="(pediaForm.submitted &&
                                initialAssessementPedia.get('exercise_details')?.invalid)
                                ||
                                initialAssessementPedia.get('exercise_details')?.invalid
                                &&
                                initialAssessementPedia.get('exercise_details').touched" />
                             <div class="invalid-feedback">Exercise details is required</div>
                        </div>
                    </div>
                </div>

                <!-- TENS -->
                <div class="row">
                    <div class="col-12 col-sm-2">
                        <div class="form-group">
                            <label class="checkbox checkbox-outline px-0">
                                <input type="checkbox" value="sterilizeAll" formControlName="tens_checkbox"
                                    id="tens_checkbox"   (change)="handleInput($event, 'ten')"/>
                                TENS
                            </label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-5 mt-1">
                        <p-dropdown optionValue="duration" [options]="duration" optionLabel="duration" [filter]="true"
                            filterBy="duration" formControlName="tens_duration" id="tens_duration"
                            placeholder="Duration" [resetFilterOnHide]="true" [class.is-invalid]="(pediaForm.submitted &&
                            initialAssessementPedia.get('tens_duration')?.invalid)
                            ||
                            initialAssessementPedia.get('tens_duration')?.invalid
                            &&
                            initialAssessementPedia.get('tens_duration').touched">
                        </p-dropdown>
                        <div class="invalid-feedback">Tens duration is required</div>
                    </div>
                    <div class="col-12 col-sm-5 mt-1">
                        <div class="form-group">
                            <input type="text" class="w-100 form-control" value="intact" formControlName="tens_details"
                                placeholder="Details" id="tens_details"  [class.is-invalid]="(pediaForm.submitted &&
                                initialAssessementPedia.get('tens_details')?.invalid)
                                ||
                                initialAssessementPedia.get('tens_details')?.invalid
                                &&
                                initialAssessementPedia.get('tens_details').touched" />
                             <div class="invalid-feedback">Tens details is required</div>
                        </div>
                    </div>
                </div>

                <!-- Altrasound -->
                <div class="row">
                    <div class="col-12n col-sm-2">
                        <div class="form-group">
                            <label class="checkbox checkbox-outline px-0">
                                <input type="checkbox" value="sterilizeAll" formControlName="ultrasound_checkbox"
                                    id="ultrasound_checkbox"  (change)="handleInput($event, 'ultrasound')" />
                                Ultrasound
                            </label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-5 mt-1">
                        <p-dropdown optionValue="duration" [options]="duration" optionLabel="duration" [filter]="true"
                            filterBy="duration" formControlName="ultrasound_duration" id="ultrasound_duration"
                            placeholder="Duration" [resetFilterOnHide]="true"[class.is-invalid]="(pediaForm.submitted &&
                            initialAssessementPedia.get('ultrasound_duration')?.invalid)
                            ||
                            initialAssessementPedia.get('ultrasound_duration')?.invalid
                            &&
                            initialAssessementPedia.get('ultrasound_duration').touched">
                        </p-dropdown>
                        <div class="invalid-feedback">Ultrasound duration is required</div>
                    </div>
                    <div class="col-12 col-sm-5 mt-1">
                        <div class="form-group">
                            <input type="text" class="w-100 form-control" value="intact"
                                formControlName="ultrasound_details" id="ultrasound_details" placeholder="Details" [class.is-invalid]="(pediaForm.submitted &&
                               initialAssessementPedia.get('ultrasound_details')?.invalid)
                                ||
                                initialAssessementPedia.get('ultrasound_details')?.invalid
                                &&
                                initialAssessementPedia.get('ultrasound_details').touched" />
                             <div class="invalid-feedback">Ultrasound  details is required</div>
                        </div>
                    </div>
                </div>

                <!-- Hot Packs -->
                <div class="row">
                    <div class="col-12 col-sm-2">
                        <div class="form-group">
                            <label class="checkbox checkbox-outline px-0">
                                <input type="checkbox" value="sterilizeAll" formControlName="hot_packs_checkbox"
                                    id="hot_packs_checkbox"  (change)="handleInput($event, 'packs')"  />
                                Hot Packs
                            </label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-5 mt-1">
                        <p-dropdown optionValue="duration" [options]="duration" optionLabel="duration" [filter]="true"
                            filterBy="duration" formControlName="hot_packs_duration" id="hot_packs_duration"
                            placeholder="Duration" [resetFilterOnHide]="true"  [class.is-invalid]="(pediaForm.submitted &&
                            initialAssessementPedia.get('hot_packs_duration')?.invalid)
                            ||
                            initialAssessementPedia.get('hot_packs_duration')?.invalid
                            &&
                            initialAssessementPedia.get('hot_packs_duration').touched">
                        </p-dropdown>
                        <div class="invalid-feedback">Hot packs duration is required</div>
                    </div>
                    <div class="col-12 col-sm-5 mt-1">
                        <div class="form-group">
                            <input type="text" class="w-100 form-control" value="intact"
                                formControlName="hot_packs_details" id="hot_packs_details" placeholder="Details" [class.is-invalid]="(pediaForm.submitted &&
                                initialAssessementPedia.get('hot_packs_details')?.invalid)
                                ||
                                initialAssessementPedia.get('hot_packs_details')?.invalid
                                &&
                                initialAssessementPedia.get('hot_packs_details').touched" />
                             <div class="invalid-feedback">Hot packs details is required</div>
                        </div>
                    </div>
                </div>

                <!-- Stimulation -->
                <div class="row">
                    <div class="col-12 col-sm-2">
                        <div class="form-group">
                            <label class="checkbox checkbox-outline px-0">
                                <input type="checkbox" value="sterilizeAll" formControlName="stimulation_checkbox"
                                    id="stimulation_checkbox" (change)="handleInput($event, 'stimulation')" />
                                Stimulation
                            </label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-5 mt-1">
                        <p-dropdown optionValue="duration" [options]="duration" optionLabel="duration" [filter]="true"
                            filterBy="duration" formControlName="stimulation_duration" id="stimulation_duration"
                            placeholder="Duration" [resetFilterOnHide]="true" [class.is-invalid]="(pediaForm.submitted &&
                            initialAssessementPedia.get('stimulation_duration')?.invalid)
                            ||
                            initialAssessementPedia.get('stimulation_duration')?.invalid
                            &&
                            initialAssessementPedia.get('stimulation_duration').touched">
                        </p-dropdown>
                        <div class="invalid-feedback">Stimulation durations is required</div>
                    </div>
                    <div class="col-12 col-sm-5 mt-1">
                        <div class="form-group">
                            <input type="text" class="w-100 form-control" value="intact"
                                formControlName="stimulation_details" placeholder="Details" id="stimulation_details"[class.is-invalid]="(pediaForm.submitted &&
                                initialAssessementPedia.get('stimulation_details')?.invalid)
                                ||
                                initialAssessementPedia.get('stimulation_details')?.invalid
                                &&
                                initialAssessementPedia.get('stimulation_details').touched" />
                             <div class="invalid-feedback">Stimulation details is required</div>
                        </div>
                    </div>
                </div>

                <!-- Vibrator -->
                <div class="row">
                    <div class="col-12 col-sm-2">
                        <div class="form-group">
                            <label class="checkbox checkbox-outline px-0">
                                <input type="checkbox" value="sterilizeAll" formControlName="vibrator_checkbox"
                                    id="vibrator_checkbox" (change)="handleInput($event, 'vibrator')" />
                                Vibrator
                            </label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-5 mt-1">
                        <p-dropdown optionValue="duration" [options]="duration" optionLabel="duration" [filter]="true"
                            filterBy="duration" formControlName="vibrator_duration" id="vibrator_duration"
                            placeholder="Duration" [resetFilterOnHide]="true" [class.is-invalid]="(pediaForm.submitted &&
                            initialAssessementPedia.get('vibrator_duration')?.invalid)
                            ||
                            initialAssessementPedia.get('vibrator_duration')?.invalid
                            &&
                            initialAssessementPedia.get('vibrator_duration').touched">
                        </p-dropdown>
                        <div class="invalid-feedback">Vibrator durations is required</div>
                    </div>
                    <div class="col-12 col-sm-5 mt-1">
                        <div class="form-group">
                            <input type="text" class="w-100 form-control" value="intact"
                                formControlName="vibrator_details" id="vibrator_details" placeholder="Details" [class.is-invalid]="(pediaForm.submitted &&
                                initialAssessementPedia.get('vibrator_details')?.invalid)
                                ||
                                initialAssessementPedia.get('vibrator_details')?.invalid
                                &&
                                initialAssessementPedia.get('vibrator_details').touched" />
                             <div class="invalid-feedback">Vibrator details is required</div>
                        </div>
                    </div>
                </div>

                <!-- Tapping -->
                <div class="row">
                    <div class="col-12 col-sm-2">
                        <div class="form-group">
                            <label class="checkbox checkbox-outline px-0">
                                <input type="checkbox" value="sterilizeAll" formControlName="tapping_checkbox"
                                    id="tapping_checkbox" (change)="handleInput($event, 'tapping')" />
                                Tapping
                            </label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-5 mt-1">
                        <p-dropdown optionValue="duration" [options]="duration" optionLabel="duration" [filter]="true"
                            filterBy="duration" formControlName="tapping_duration" id="tapping_duration"
                            placeholder="Duration" [resetFilterOnHide]="true" [class.is-invalid]="(pediaForm.submitted &&
                            initialAssessementPedia.get('tapping_duration')?.invalid)
                            ||
                            initialAssessementPedia.get('tapping_duration')?.invalid
                            &&
                            initialAssessementPedia.get('tapping_duration').touched">
                        </p-dropdown>
                        <div class="invalid-feedback">Tapping durations is required</div>
                    </div>
                    <div class="col-12 col-sm-5 mt-1">
                        <div class="form-group">
                            <input type="text" class="w-100 form-control" value="intact"
                                formControlName="tapping_details" placeholder="Details" id="tapping_details"[class.is-invalid]="(pediaForm.submitted &&
                                initialAssessementPedia.get('tapping_details')?.invalid)
                                ||
                                initialAssessementPedia.get('tapping_details')?.invalid
                                &&
                                initialAssessementPedia.get('tapping_details').touched" />
                             <div class="invalid-feedback">Tapping details is required</div>
                        </div>
                    </div>
                </div>

                <!-- Chest Therapy -->
                <div class="row">
                    <div class="col-12 col-sm-2">
                        <div class="form-group">
                            <label class="checkbox checkbox-outline px-0">
                                <input type="checkbox" value="sterilizeAll" formControlName="chest_therapy_checkbox"
                                    id="chest_therapy_checkbox" (change)="handleInput($event, 'chest')" />
                                Chest Therapy
                            </label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-5 mt-1">
                        <p-dropdown optionValue="duration" [options]="duration" optionLabel="duration" [filter]="true"
                            filterBy="duration" formControlName="chest_therapy_duration" id="chest_therapy_duration"
                            placeholder="Duration" [resetFilterOnHide]="true" [class.is-invalid]="(pediaForm.submitted &&
                            initialAssessementPedia.get('chest_therapy_duration')?.invalid)
                            ||
                            initialAssessementPedia.get('chest_therapy_duration')?.invalid
                            &&
                            initialAssessementPedia.get('chest_therapy_duration').touched">
                        </p-dropdown>
                        <div class="invalid-feedback">Chest therapy durations is required</div>
                    </div>
                    <div class="col-12 col-sm-5 mt-1">
                        <div class="form-group">
                            <input type="text" class="w-100 form-control" value="intact"
                                formControlName="chest_therapy_details" id="chest_therapy_details"
                                placeholder="Details" [class.is-invalid]="(pediaForm.submitted &&
                                initialAssessementPedia.get('chest_therapy_details')?.invalid)
                                ||
                                initialAssessementPedia.get('chest_therapy_details')?.invalid
                                &&
                                initialAssessementPedia.get('chest_therapy_details').touched" />
                             <div class="invalid-feedback">Chest therapy details is required</div>
                        </div>
                    </div>
                </div>

                <!-- Others -->
                <div class="row">
                    <div class="col-12 col-sm-2">
                        <div class="form-group">
                            <label class="checkbox checkbox-outline px-0">
                                <input type="checkbox" value="sterilizeAll"
                                    formControlName="session_technique_others_checkbox"
                                    id="session_technique_others_checkbox"  (change)="handleInput($event, 'other')"/>
                                Others
                            </label>
                        </div>
                    </div>

                    <div class="col-12 col-sm-10">
                        <div class="form-group">
                            <input type="text" class="w-100 form-control" value="intact"
                                formControlName="session_technique_others_details" id="session_technique_others_details"
                                placeholder="Details" [class.is-invalid]="(pediaForm.submitted &&
                                initialAssessementPedia.get('session_technique_others_details')?.invalid)
                                ||
                                initialAssessementPedia.get('session_technique_others_details')?.invalid
                                &&
                                initialAssessementPedia.get('session_technique_others_details').touched" />
                             <div class="invalid-feedback">Session technique others details is required</div>
                        </div>
                    </div>
                </div>

                <!-- Re assessment date -->
                <div class="row">
                    <div class="col-sm-6 col-12">
                        <div class="form-group">
                            <div class="form-group">
                                <label class="subHeading px-0">Re-assessment Date:</label>
                                <div class="card flex justify-content-center" id="submit_button">
                                    <p-calendar readonly  [showIcon]="true"  dateFormat="dd/mm/yy" placeholder="DD/MM/YYYY"
                                        [readonlyInput]="true" formControlName="re_date"></p-calendar>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="add-listings-btn" style="text-align: right">
            <button type="submit">Submit</button>
        </div>
    </form>
</div>
