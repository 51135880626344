import { Component, OnInit, ViewChild } from '@angular/core';
import { MaikaListService } from 'src/app/services/maika-list.service';
import { MasterService } from 'src/app/services/master.service';
import { Router } from '@angular/router';
import { MessageService, ConfirmationService } from 'primeng/api';
import { CommonApiService } from 'src/app/services/common-api.service';
import { DialogModule } from 'primeng/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Table } from 'primeng/table';
import { Dropdown } from 'primeng/dropdown';
@Component({
  selector: 'app-audit-logs',
  templateUrl: './audit-logs.component.html',
  styleUrls: ['./audit-logs.component.scss']
})
export class AuditLogsComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Audit Log History',
      subTitle: 'Dashboard'
    }
  ]

  @ViewChild('dt1') dataTable: Table;
  @ViewChild('dropdown') dropdown: Dropdown;
  getname:any;
  getRole:any;
  getToken:any;
  filterVal: string;
  day: number;
  month: number;
  year: number;
  nurseLogin: boolean = false
  nurce_care_link: string = '';
  groth_monitoring_link: string = '';
  headNurse: boolean = false
  superAdmin: boolean = false
  loading: any;
  date: any = new Date('2000-01-04T10:04:04.000Z');
  data: any;
  contract: any = [];
  sibling: any = [];
  relatives: any = [];
  service: any
  nurse: any
  displayMaximizable: boolean = false;
  viewDetail: any = [];
  initialAssData: any = []
  permissionData: any = [];
  permissions: any = [];
  admin: boolean = false
  tooltipText: string = 'Baby Child Registration';
  filterVals: any;
  filterValues: any[];
  dropdownValue
  options: any = [
    { label: 'Active', value: '1' },
    { label: 'Inactive', value: '0' },
  ];
  constructor(private initial_ass_service: MaikaListService, private common_service: CommonApiService,private router: Router, private spinner: NgxSpinnerService, private masterService: MasterService, private messageService: MessageService, private confirmationService: ConfirmationService,) {
  }

 

  ngOnInit(): void {
    localStorage.removeItem('babyId');
    localStorage.removeItem('editedOrderId')
    // localStorage.removeItem('showDTS')
    localStorage.removeItem('create');
    this.getaudit()
    this.getname = localStorage.getItem('name');
    this.getToken = localStorage.getItem('token');
    this.getRole = localStorage.getItem('role')
  
  }

  getaudit() {
    this.common_service.getauditlog().subscribe((res: any) => {
      this.loading = false;
  
      // Check if res.data exists and is an array, or handle other formats
      if (Array.isArray(res)) {
        this.initialAssData = res;
      } else if (res && res.data && Array.isArray(res.data)) {
        this.initialAssData = res.data;
      } else {
        this.initialAssData = [];
      }
    }, error => {
      this.loading = false;
      this.initialAssData = []; // Ensure the data is an empty array in case of error
    });
  }
  
  reset(dt) {
    // this.dropdownValue=' '
    dt?.reset();
    this.dropdown?.clear(this.filterVals);
    this.dataTable?.reset();
    this.filterVal = '';
  }
  logout() {
    localStorage.clear();
    // window.location.reload();
    this.router.navigate(['/']);
  }
}
