<p-toast></p-toast>


<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">


	<app-dashboard-navbar></app-dashboard-navbar>


	<!-- Breadcrumb Area -->
	<div *ngFor="let Content of breadcrumb;" class="breadcrumb-area">
		<h1>{{Content.title}}</h1>
		<ol class="breadcrumb">
			<li class="item">
				<a routerLink="/">Home</a>
			</li>
			<li class="item">
				<a routerLink="dashboard">
					{{Content.subTitle}}
				</a>
			</li>
			<li class="item">{{Content.title}}</li>
		</ol>
	</div>
	<!-- End Breadcrumb Area -->


	<!-- Start -->
	<form #assessment="ngForm" [formGroup]="ptAssessment" (ngSubmit)="saveAssessmentForm(assessment)">
		<!-- BASIC INFORMATION SECTION -->
		<div class="add-listings-box">
			<h3>Basic Information</h3>
			<div class="row">
				<div class="col-lg-4 col-sm-6 col-12">
					<div class="form-group">
						<label class="px-0">Patient Name<span class="required">*</span></label>
						<div class="card flex justify-content-center">
							<input [class.is-invalid]="(assessment.submitted &&
                        ptAssessment.get('patient_name')?.invalid)
                        ||
                        ptAssessment.get('patient_name')?.invalid
                        &&
                        ptAssessment.get('patient_name').touched" type="text" formControlName="patient_name"
								class="form-control" placeholder="Patient Name" maxlength="50">
							<div class="invalid-feedback">Name is required</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-sm-6 col-12">
					<div class="form-group">
						<label class="px-0">Patient Code<span class="required">*</span></label>
						<div class="card flex justify-content-center">
							<input [class.is-invalid]="(assessment.submitted &&
                        ptAssessment.get('patient_code')?.invalid)
                        ||
                        ptAssessment.get('patient_code')?.invalid
                        &&
                        ptAssessment.get('patient_code').touched" type="text" formControlName="patient_code"
								class="form-control" placeholder="Patient Code">
							<div class="invalid-feedback">
								Patient Code is required
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-sm-6 col-12">
					<div class="form-group">
						<label class="px-0">Patient Age<span class="required">*</span></label>
						<div class="card flex justify-content-center">
							<input type="text" (keypress)="keyPress($event)" formControlName="patient_age"
								class="form-control" placeholder="Patient Age" [class.is-invalid]="(assessment.submitted &&
                        ptAssessment.get('patient_age')?.invalid)
                        ||
                        ptAssessment.get('patient_age')?.invalid
                        &&
                        ptAssessment.get('patient_age').touched" maxlength="2" appNumericInput>
							<div class="invalid-feedback">
								Patient Age is required
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-sm-6 col-12">
					<div class="form-group">
						<label class="px-0">Date of Birth<span class="required">*</span></label>
						<div class="card flex justify-content-center">
							<p-calendar [showIcon]="true" [maxDate]="selectedDate" dateFormat="dd/mm/yy"
								[class.is-invalid]="(assessment.submitted &&
                        ptAssessment.get('dob')?.invalid)
                        ||
                        ptAssessment.get('dob')?.invalid
                        &&
                        ptAssessment.get('dob').touched" placeholder="DD/MM/YYYY" formControlName="dob"></p-calendar>
							<div class="invalid-feedback">
								Date of Birth is required
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-sm-6 col-12">
					<div class="form-group">
						<label class="px-0">Gender<span class="required">*</span></label>
						<div class="card flex justify-content-center">
							<p-dropdown [options]="genderArray" [resetFilterOnHide]="true" [class.is-invalid]="(assessment.submitted &&
                        ptAssessment.get('gender')?.invalid)
                        ||
                        ptAssessment.get('gender')?.invalid
                        &&
                        ptAssessment.get('gender').touched" optionLabel="name" optionValue="value"
								placeholder="Select Gender" formControlName="gender">
							</p-dropdown>
							<div class="invalid-feedback">Gender is required</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="add-listings-box">
			<h3>Initial Assessment</h3>
			<div class="row">
				<div class="col-sm-6 col-12">
					<div class="form-group">
						<label class="px-0">
							Degree of Physical Activity
						</label>
						<div class="form-check checkbox-list p-0">
							<div class="form-group" formGroupName="physical_activity">
								<p class="d-flex"></p>
								<p class="d-flex">
									<input type="radio" id="bedbound" formControlName="bedbound" value="Bedbound">
									<label for="bedbound" class="mb-0 mx-2">
										Bedbound
									</label>
								</p>
								<p class="d-flex">
									<input type="radio" formControlName="bedbound" id="chairbound" value="Chairbound">
									<label for="chairbound" class="mb-0 mx-2 ">
										Chairbound
									</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="ambulatory" formControlName="bedbound" value="Ambulatory">
									<label for="ambulatory" class="mb-0 mx-2">
										Ambulatory
									</label>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="col-sm-6 col-12">
					<div class="form-group">
						<label class="px-0">Medical Diagnosis</label>
						<textarea formControlName="medical_diagnosis" id="medical_diagnosis" class="form-control"
							rows="3" placeholder="Medical Diagnosis"></textarea>
					</div>
				</div>
				<div class="col-sm-6 col-12">
					<div class="form-group">
						<label class="px-0">Chief Complaint</label>
						<textarea formControlName="chief_complain" id="chief_complain" class="form-control" rows="3"
							placeholder="Chief Complaint"></textarea>
					</div>
				</div>
				<div class="col-sm-6 col-12">
					<div class="form-group">
						<label class="px-0">
							Consciousness/Mental State Remarks
						</label>
						<textarea formControlName="mental_status_remarks" id="mental_status_remarks"
							class="form-control" rows="3" placeholder="Consciousness/Mental State Remarks"></textarea>
					</div>
				</div>
				<div class="col-lg-4 col-sm-6 col-12">
					<div class="form-group">
						<label class="px-0">Weight</label>
						<div class="card flex justify-content-center">
							<input type="text" id="weight" formControlName="weight" class="form-control"
								placeholder="Weight" (keypress)="keyPress($event)" maxlength="3">
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-sm-6 col-12">
					<div class="form-group">
						<label class="px-0">Weight Remarks</label>
						<div class="card flex justify-content-center">
							<input type="text" id="weight_remarks" formControlName="weight_remarks" class="form-control"
								placeholder="Weight Remarks">
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-sm-6 col-12">
					<div class="form-group">
						<label class="px-0">Height</label>
						<div class="card flex justify-content-center">
							<input type="text" id="height" formControlName="height" class="form-control"
								placeholder="Height in cm" (keypress)="keyPress($event)" maxlength="3">
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="add-listings-box">
			<h3>Attachment</h3>
			<div class="row">
				<div class="col-12">
					<div class="form-group">
						<label class="px-0">Attachment:</label>
						<div class="form-check checkbox-list p-0">
							<div class="form-group" formGroupName="attachment">
								<div class="row">
									<p class="d-flex col-sm-3 col-6 align-items-center mb-2">
										<input type="checkbox" id="ngt" formControlName="ngt" value="mother">
										<label for="mother" class="mb-0 mx-2 px-0">
											NGT
										</label>
									</p>
									<p class="d-flex col-sm-3 col-6 mb-2">
										<input type="checkbox" formControlName="colostomy" id="colostomy" value="nanny">
										<label for="nanny" class="mb-0 mx-2 px-0">
											Colostomy
										</label>
									</p>
									<p class="d-flex col-sm-3 col-6 mb-2 align-items-center">
										<input type="checkbox" id="pegtube" formControlName="pegtube" value="other">
										<label for="other" class="mb-0 mx-2 px-0">
											PEGTUBE
										</label>
									</p>
									<p class="d-flex col-sm-3 col-6 mb-2 align-items-center">
										<input type="checkbox" id="fc" formControlName="fc" value="other">
										<label for="other" class="mb-0 mx-2 px-0">
											FC
										</label>
									</p>
									<p class="d-flex col-sm-3 col-6 mb-2 align-items-center">
										<input type="checkbox" id="tracheostomy" formControlName="tracheostomy"
											value="other">
										<label for="other" class="mb-0 mx-2 px-0">
											Tracheostomy
										</label>
									</p>
									<p class="d-flex col-sm-3 col-6 mb-2 align-items-center">
										<input type="checkbox" id="mechanical_ventilator"
											formControlName="mechanical_ventilator" value="other">
										<label for="other" class="mb-0 mx-2 px-0">
											Mechanical Ventilator
										</label>
									</p>
									<p class="d-flex col-sm-3 col-6 mb-2 align-items-center">
										<input type="checkbox" id="na" formControlName="na" value="other">
										<label for="other" class="mb-0 mx-2 px-0">
											NA
										</label>
									</p>
									<div class="col-sm-9 col-12 mb-2 align-items-center">
										<div class="row">
											<div class="col-4 d-flex align-items-center">
												<input type="checkbox" id="others" formControlName="others"
													value="other" change="handle" (change)="handleInput($event, 'box')">
												<label for="other" class="mb-0 mx-2 px-0">
													Others
												</label>
											</div>
											<div class="col-sm-4 col-8">
												<input type="text" id="others_remarks" formControlName="others_remarks"
													class="form-control" placeholder="Others" [class.is-invalid]="(assessment.submitted &&
													ptAssessment.get(['attachment','others_remarks'])?.invalid)
													||
													ptAssessment.get(['attachment','others_remarks'])?.invalid
													&&
													ptAssessment.get(['attachment','others_remarks']).touched" />
												<div class="invalid-feedback">Others remark is required</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-sm-6 col-12">
					<div class="form-group">
						<label class="px-0">Attachment Remarks</label>
						<textarea formControlName="attachment_remarks" id="attachment_remarks" class="form-control"
							rows="3" placeholder="Attachment Remarks"></textarea>
					</div>
				</div>
			</div>
		</div>
		<div class="add-listings-box">
			<h3>Vital Signs</h3>
			<div class="row">
				<div class="col-12 table-responsive">
					<table class="mb-4">
						<thead>
							<tr style="white-space: nowrap;">
								<th>Vital Signs</th>
								<th>Temperature</th>
								<th>Pulse (bpm)</th>
								<th>Resp (bpm)</th>
								<th>BP (mmhg)</th>
								<th>SPO2</th>
							</tr>
						</thead>
						<tbody formGroupName="vital_signs">
							<tr>
								<th>Pre Session</th>
								<td>
									<div class="form-group">
										<input type="text" id="pre_temp" formControlName="pre_temp"
											class="form-control table-input" placeholder="Temperature in Celsius"
											(keypress)="keyPress($event)">
									</div>
								</td>
								<td>
									<div class="form-group">
										<input type="text" id="pre_pulse" formControlName="pre_pulse"
											class="form-control table-input" placeholder="Pulse (bpm)"
											(keypress)="keyPress($event)">
									</div>
								</td>
								<td>
									<div class="form-group">
										<input type="text" id="pre_resp" formControlName="pre_resp"
											class="form-control table-input" placeholder="Resp (bpm)"
											(keypress)="keyPress($event)">
									</div>
								</td>
								<td>
									<div class="form-group">
										<input type="text" id="pre_bp" formControlName="pre_bp"
											class="form-control table-input" placeholder="BP (mmhg)"
											(keypress)="keyPress($event)">
									</div>
								</td>
								<td>
									<div class="form-group">
										<input type="text" id="pre_sp" formControlName="pre_sp"
											class="form-control table-input" placeholder="SPO2"
											(keypress)="keyPress($event)">
									</div>
								</td>
							</tr>
							<tr>
								<th>Post Session</th>
								<td>
									<div class="form-group">
										<input type="text" id="post_temp" formControlName="post_temp"
											class="form-control table-input" placeholder="Temperature in Celsius"
											(keypress)="keyPress($event)">
									</div>
								</td>
								<td>
									<div class="form-group">
										<input type="text" id="post_pulse" formControlName="post_pulse"
											class="form-control table-input" placeholder="Pulse (bpm)"
											(keypress)="keyPress($event)">
									</div>
								</td>
								<td>
									<div class="form-group">
										<input type="text" id="post_resp" formControlName="post_resp"
											class="form-control table-input" placeholder="Resp (bpm)"
											(keypress)="keyPress($event)">
									</div>
								</td>
								<td>
									<div class="form-group">
										<input type="text" id="post_bp" formControlName="post_bp"
											class="form-control table-input" placeholder="BP (mmhg)"
											(keypress)="keyPress($event)">
									</div>
								</td>
								<td>
									<div class="form-group">
										<input type="text" id="post_sp" formControlName="post_sp"
											class="form-control table-input" placeholder="SPO2"
											(keypress)="keyPress($event)">
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="col-12">
					<div class="form-group">
						<label class="px-0">
							If in pain, pain assessment complete
						</label>
						<div class="form-check checkbox-list p-0">
							<div class="form-group" formGroupName="pain_assessment">
								<div class="row">
									<p class="d-flex col-sm-2 col-12 align-items-center mb-2">
										<input type="radio" id="pain_yes" formControlName="pain_yes" value="Yes"
											(change)="handleInput($event, 'pain')">
										<label for="pain_yes" class="mb-0 mx-2 ">
											Yes
										</label>
									</p>
									<p class="d-flex col-sm-2 col-4  mb-2 align-items-center">
										<label for="nanny" class="mb-0 px-0">
											Pain Tool:
										</label>
									</p>
									<div class=" col-8 mb-2">
										<div class="form-group m-0">
											<input type="text" id="pain_yes_remark" formControlName="pain_yes_remark"
												class="form-control" placeholder="Pain remark" [class.is-invalid]="(assessment.submitted &&
												ptAssessment.get(['pain_assessment','pain_yes_remark'])?.invalid)
												||
												ptAssessment.get(['pain_assessment','pain_yes_remark'])?.invalid
												&&
												ptAssessment.get(['pain_assessment','pain_yes_remark']).touched">
										</div>
										<div class="invalid-feedback">Pain tool remark is required</div>
									</div>
									<p class="d-flex col-sm-2 col-12 align-items-center mb-2">
										<input type="radio" id="pain_no" formControlName="pain_yes" value="No"
											(change)="handleInput($event, 'pain')">
										<label for="pain_no" class="mb-0 mx-2">
											No
										</label>
									</p>
									<p class="d-flex col-sm-2 col-4 mb-2 align-items-center">
										<label for="nanny" class="mb-0 px-0">
											Pain Score:
										</label>
									</p>
									<div class=" col-8 mb-2">
										<div class="form-group m-0">
											<input type="text" id="pain_no_remark" formControlName="pain_no_remark"
												class="form-control" placeholder="Pain remark" [class.is-invalid]="(assessment.submitted &&
												ptAssessment.get(['pain_assessment','pain_no_remark'])?.invalid)
												||
												ptAssessment.get(['pain_assessment','pain_no_remark'])?.invalid
												&&
												ptAssessment.get(['pain_assessment','pain_no_remark']).touched">

										</div>
										<div class="invalid-feedback">Pain score remark is required</div>
									</div>
									<!-- <p class="d-flex col-sm-2 col-12 align-items-center mb-2">
										<input type="checkbox" id="pain_score_na" formControlName="pain_yes"
											value="NA"  (change)="handleInput($event, 'pain')">
										<label for="mother" class="mb-0 mx-2 px-0">
											NA
										</label>
									</p> -->
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12">
					<div class="form-group">
						<div class="row mb-2">
							<div class="col-lg-2 col-3 d-flex align-items-center">
								<label for="nanny" class="mb-0 px-0">
									Onset:
								</label>
							</div>
							<div class="col-lg-8 col-9">
								<input type="text" id="onset" formControlName="onset" class="form-control"
									placeholder="Onset">
							</div>
						</div>
						<div class="row mb-2">
							<div class="col-lg-2 col-3 d-flex align-items-center">
								<label for="nanny" class="mb-0 px-0">
									Location:
								</label>
							</div>
							<div class="col-lg-8 col-9">
								<input type="text" id="location" formControlName="location" class="form-control"
									placeholder="Location">
							</div>
						</div>
						<div class="row mb-2">
							<div class="col-lg-2 col-3 d-flex align-items-center">
								<label for="nanny" class="mb-0 px-0">
									Character:
								</label>
							</div>
							<div class="col-lg-8 col-9">
								<input type="text" id="character" formControlName="character" class="form-control"
									placeholder="Character">
							</div>
						</div>
						<div class="row mb-2">
							<div class="col-lg-2 col-3 d-flex align-items-center">
								<label for="nanny" class="mb-0 px-0">
									Aggravating factor:
								</label>
							</div>
							<div class="col-lg-8 col-9">
								<input type="text" id="aggravating_factor" formControlName="aggravating_factor"
									class="form-control" placeholder="Aggravating factor">
							</div>
						</div>
						<div class="row mb-2">
							<div class="col-lg-2 col-3 d-flex align-items-center">
								<label for="nanny" class="mb-0 px-0">
									Relieving Factor:
								</label>
							</div>
							<div class="col-lg-8 col-9">
								<input type="text" id="relieving_factor" formControlName="relieving_factor"
									class="form-control" placeholder="Relieving Factor">
							</div>
						</div>
						<div class="row mb-2">
							<div class="col-lg-2 col-3 d-flex align-items-center">
								<label for="nanny" class="mb-0 px-0">
									Timing:
								</label>
							</div>
							<div class="col-lg-8 col-9">
								<input type="text" id="timing" formControlName="timing" class="form-control"
									placeholder="Timing">
							</div>
						</div>
						<div class="row mb-2">
							<div class="col-lg-2 col-3 d-flex align-items-center">
								<label for="nanny" class="mb-0 px-0">
									Severity:
								</label>
							</div>
							<div class="col-lg-8 col-9">
								<input type="text" id="severity" formControlName="severity" class="form-control"
									placeholder="Severity">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="add-listings-box">
			<h3>Mental Status</h3>
			<div class="form-group">
				<div class="row" formGroupName="mental_status">
					<p class="d-flex col-lg-3 col-sm-4 col-6  align-items-center mb-2">
						<input type="radio" id="alert" formControlName="alert" value="Alert">
						<label for="alert" class="mb-0 mx-2 ">
							Alert
						</label>
					</p>
					<p class="d-flex col-lg-3 col-sm-4 col-6 mb-2">
						<input type="radio" formControlName="alert" id="oriented" value="Oriented">
						<label for="oriented" class="mb-0 mx-2 ">
							Oriented
						</label>
					</p>
					<p class="d-flex col-lg-3 col-sm-4 col-6 mb-2 align-items-center">
						<input type="radio" id="confused" formControlName="alert" value="Confused">
						<label for="confused" class="mb-0 mx-2 ">
							Confused
						</label>
					</p>
					<p class="d-flex col-lg-3 col-sm-4 col-6 mb-2 align-items-center">
						<input type="radio" id="restless" formControlName="alert" value="Restless">
						<label for="restless" class="mb-0 mx-2 ">
							Restless
						</label>
					</p>
					<p class="d-flex col-lg-3 col-sm-4 col-6 mb-2 align-items-center">
						<input type="radio" id="stupurous" formControlName="alert" value="Stupurous">
						<label for="stupurous" class="mb-0 mx-2 ">
							Stupurous
						</label>
					</p>
					<p class="d-flex col-lg-3 col-sm-4 col-6 mb-2 align-items-center">
						<input type="radio" id="unresponsive" formControlName="alert" value="Unresponsive">
						<label for="unresponsive" class="mb-0 mx-2 ">
							Unresponsive
						</label>
					</p>
					<p class="d-flex col-lg-3 col-sm-4 col-6 mb-2 align-items-center">
						<input type="radio" id="lethargic" formControlName="alert" value="Lethargic">
						<label for="lethargic" class="mb-0 mx-2 ">
							Lethargic
						</label>
					</p>
				</div>
			</div>
		</div>
		<div class="add-listings-box">
			<h3>Seizure Disorder</h3>
			<div class="form-group">
				<div class="row mb-3">
					<div class="col-sm-6 col-12">

						<p class="d-flex">
							<input type="radio" id="seizure_yes" value="1" formControlName="seizure_yes"
								(change)="handleInput($event, 'seizure')" />
							<label for="seizure_yes" class="mb-0 mx-2">Yes</label>
						</p>
						<p class="d-flex">
							<input type="radio" id="seizure_no" value="0" formControlName="seizure_yes"
								(change)="handleInput($event, 'seizure')" />
							<label for="seizure_no" class="mb-0 mx-2">No</label>
						</p>

					</div>


					<div class="col-sm-6 col-12">
						<label class="px-0 my-2 mt-sm-0">Comment</label>
						<div class="form-group">
							<textarea formControlName="seizure_comment" id="seizure_comment" class="form-control"
								rows="3" placeholder="Comment" class="form-control px-2" id="specify" rows="3"
								[class.is-invalid]="(assessment.submitted &&
								ptAssessment.get('seizure_comment')?.invalid)
								||
								ptAssessment.get('seizure_comment')?.invalid
								&&
								ptAssessment.get('seizure_comment').touched"></textarea>
							<div class="invalid-feedback">Seizure comment is required</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="add-listings-box">
			<h3>Sensory Information</h3>
			<div class="form-group">
				<div class="row" formGroupName="sensory_information">
					<p class="d-flex col-sm-3 col-6 align-items-center mb-2">
						<input type="radio" id="paralysis" formControlName="paralysis" value="Paralysis">
						<label for="paralysis" class="mb-0 mx-2 ">
							Paralysis
						</label>
					</p>
					<p class="d-flex col-sm-3 col-6 mb-2">
						<input type="radio" formControlName="paralysis" id="numbness" value="Numbness">
						<label for="numbness" class="mb-0 mx-2 ">
							Numbness
						</label>
					</p>
					<p class="d-flex col-sm-3 col-6 mb-2 align-items-center">
						<input type="radio" id="tingling" formControlName="paralysis" value="Tingling">
						<label for="tingling" class="mb-0 mx-2 ">
							Tingling
						</label>
					</p>
					<p class="d-flex col-sm-3 col-6 mb-2 align-items-center">
						<input type="radio" id="dizziness" formControlName="paralysis" value="Dizziness">
						<label for="dizziness" class="mb-0 mx-2 ">
							Dizziness
						</label>
					</p>
					<p class="d-flex col-sm-3 col-6 mb-2 align-items-center">
						<input type="radio" id="unable_assess" formControlName="paralysis" value="Unable Assess">
						<label for="unable_assess" class="mb-0 mx-2 ">
							Unable to assess
						</label>
					</p>
				</div>
			</div>
		</div>
		<div class="add-listings-box">
			<h3>Cognition</h3>
			<div class="row" formGroupName="cognition">
				<div class="col-12 table-responsive">
					<table class="mb-4">
						<thead>
							<tr style="white-space: nowrap;">
								<th>Neurosensory</th>
								<th>Intact</th>
								<th>Impaired</th>
								<th>Absent</th>
								<th>Not tested</th>
								<th class="text-center">Remarks</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th>Light Touch:</th>
								<td>
									<div class="form-group">
										<input type="radio" value="light intact" id="light_intact" name="light_intact"
											value="1" formControlName="light_intact" class="p-radiobutton"
											(change)="handleInput($event, 'light')">
									</div>
								</td>
								<td>
									<div class="form-group">
										<input type="radio" value="light impaired" id="light_impaired"
											name="light_intact" value="2" formControlName="light_intact"
											class="p-radiobutton" (change)="handleInput($event, 'light')">
									</div>
								</td>
								<td>
									<div class="form-group">
										<input type="radio" value="light absent" id="light_absent" name="light_intact"
											value="3" formControlName="light_intact" class="p-radiobutton"
											(change)="handleInput($event, 'light')">
									</div>
								</td>
								<td>
									<div class="form-group">
										<input type="radio" value="light not tested" id="light_not_tested"
											name="light_intact" value="4" formControlName="light_intact"
											class="p-radiobutton" (change)="handleInput($event, 'light')">
									</div>
								</td>
								<td>
									<div class="form-group">
										<input type="text" formControlName="light_remarks" id="light_remarks"
											class="form-control remark" placeholder="Remarks" [class.is-invalid]="(assessment.submitted &&
											ptAssessment.get([ 'cognition','light_remarks'])?.invalid)
											||
											ptAssessment.get(['cognition','light_remarks'])?.invalid
											&&
											ptAssessment.get([ 'cognition','light_remarks']).touched">
										<div class="invalid-feedback">Light Touch remark is required</div>
									</div>

								</td>
							</tr>

							<tr>
								<th>
									Blunt touch/deep pressure
								</th>
								<td>
									<div class="form-group">
										<input type="radio" id="blunt_intact" name="blunt_intact" value="1"
											class="p-radiobutton" formControlName="blunt_intact"
											(change)="handleInput($event, 'blunt')">
									</div>
								</td>
								<td>
									<div class="form-group">
										<input type="radio" id="blunt_impaired" name="blunt_intact" value="2"
											class="p-radiobutton" formControlName="blunt_intact"
											(change)="handleInput($event, 'blunt')">
									</div>
								</td>
								<td>
									<div class="form-group">
										<input type="radio" id="blunt_absent" name="blunt_intact" value="3"
											class="p-radiobutton" formControlName="blunt_intact"
											(change)="handleInput($event, 'blunt')">
									</div>
								</td>
								<td>
									<div class="form-group">
										<input type="radio" formControlName="blunt_intact" name="blunt_intact" value="4"
											class="p-radiobutton" (change)="handleInput($event, 'blunt')">
									</div>
								</td>
								<td>
									<div class="form-group">
										<input type="text" formControlName="blunt_remarks" id="blunt_remarks"
											class="form-control remark" placeholder="Remarks" [class.is-invalid]="(assessment.submitted &&
											ptAssessment.get([ 'cognition','blunt_remarks'])?.invalid)
											||
											ptAssessment.get(['cognition','blunt_remarks'])?.invalid
											&&
											ptAssessment.get([ 'cognition','blunt_remarks']).touched">
										<div class="invalid-feedback">Blunt Intact remark is required</div>
									</div>

								</td>
							</tr>
							<tr>
								<th>Pin Prick</th>
								<td>
									<div class="form-group">
										<input type="radio" value="1" id="pin_intact" name="pin_intact"
											class="p-radiobutton" formControlName="pin_intact"
											(change)="handleInput($event, 'pin')">
									</div>
								</td>
								<td>
									<div class="form-group">
										<input type="radio" value="2" id="pin_impaired" name="pin_intact"
											class="p-radiobutton" formControlName="pin_intact"
											(change)="handleInput($event, 'pin')">
									</div>
								</td>
								<td>
									<div class="form-group">
										<input type="radio" value="3" id="pin_absent" name="pin_intact"
											class="p-radiobutton" formControlName="pin_intact"
											(change)="handleInput($event, 'pin')">
									</div>
								</td>
								<td>
									<div class="form-group">
										<input type="radio" value="4" id="pin_not_tested" name="pin_intact"
											class="p-radiobutton" formControlName="pin_intact"
											(change)="handleInput($event, 'pin')">
									</div>
								</td>
								<td>
									<div class="form-group">
										<input type="text" formControlName="pin_remarks" id="pin_remarks"
											class="form-control remark" placeholder="Remarks" [class.is-invalid]="(assessment.submitted &&
											ptAssessment.get([ 'cognition','pin_remarks'])?.invalid)
											||
											ptAssessment.get(['cognition','pin_remarks'])?.invalid
											&&
											ptAssessment.get([ 'cognition','pin_remarks']).touched">
										<div class="invalid-feedback">Pin Prick remark is required</div>
									</div>

								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="col-12">
					<div class="form-group" formGroupName="cognition_oriented">
						<div class="row">
							<div class="col-sm-3 col-6">
								<label class="px-0">Oriented to people</label>
							</div>
							<div class="col-sm-2 col-3">
								<p class="d-flex align-items-center">
									<input type="radio" id="people_yes" formControlName="people_yes" value="Yes"
										(change)="handleInput($event, 'people')">
									<label for="people_yes" class="mb-0 mx-2">
										Yes
									</label>
								</p>
							</div>
							<div class="col-sm-2 col-3">
								<p class="d-flex align-items-center">
									<input type="radio" id="people_no" formControlName="people_yes" value="No"
										(change)="handleInput($event, 'people')">
									<label for="people_no" class="mb-0 mx-2">
										No
									</label>
								</p>
							</div>
							<div class="col-sm-5 col-12">
								<div class="form-group">
									<textarea formControlName="people_remark" class="form-control" rows="3"
										placeholder="Comment" [class.is-invalid]="(assessment.submitted &&
										ptAssessment.get([ 'cognition','cognition_oriented','people_remark'])?.invalid)
										||
										ptAssessment.get(['cognition','cognition_oriented','people_remark'])?.invalid
										&&
										ptAssessment.get([ 'cognition','cognition_oriented','people_remark']).touched"></textarea>
									<div class="invalid-feedback"> Orineted People remark is required</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-3 col-6">
								<label class="px-0">Oriented to time</label>
							</div>
							<div class="col-sm-2 col-3">
								<p class="d-flex align-items-center">
									<input type="radio" id="time_yes" formControlName="time_yes" value="Yes"
										(change)="handleInput($event, 'time')">
									<label for="time_yes" class="mb-0 mx-2 ">
										Yes
									</label>
								</p>

							</div>
							<div class="col-sm-2 col-3">
								<p class="d-flex align-items-center">
									<input type="radio" id="time_no" formControlName="time_yes" value="No"
										(change)="handleInput($event, 'time')">
									<label for="time_no" class="mb-0 mx-2 ">
										No
									</label>
								</p>

							</div>
							<div class="col-sm-5 col-12">
								<div class="form-group">
									<textarea formControlName="time_remark" class="form-control" rows="3"
										placeholder="Comment" [class.is-invalid]="(assessment.submitted &&
										ptAssessment.get([ 'cognition','cognition_oriented','time_remark'])?.invalid)
										||
										ptAssessment.get(['cognition','cognition_oriented','time_remark'])?.invalid
										&&
										ptAssessment.get([ 'cognition','cognition_oriented','time_remark']).touched"></textarea>
									<div class="invalid-feedback"> Orineted Time remark is required</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-3 col-6">
								<label class="px-0">Oriented to place</label>
							</div>
							<div class="col-sm-2 col-3">
								<p class="d-flex align-items-center">
									<input type="radio" id="place_yes" formControlName="place_yes" value="Yes"
										(change)="handleInput($event, 'place')">
									<label for="place_yes" class="mb-0 mx-2 ">
										Yes
									</label>
								</p>

							</div>
							<div class="col-sm-2 col-3">
								<p class="d-flex align-items-center">
									<input type="radio" id="place_no" formControlName="place_yes" value="No"
										(change)="handleInput($event, 'place')">
									<label for="place_no" class="mb-0 mx-2 ">
										No
									</label>
								</p>
							</div>
							<div class="col-sm-5 col-12">
								<div class="form-group">
									<textarea formControlName="place_remark" class="form-control" rows="3"
										placeholder="Comment" [class.is-invalid]="(assessment.submitted &&
										ptAssessment.get([ 'cognition','cognition_oriented','place_remark'])?.invalid)
										||
										ptAssessment.get(['cognition','cognition_oriented','place_remark'])?.invalid
										&&
										ptAssessment.get([ 'cognition','cognition_oriented','place_remark']).touched"></textarea>
									<div class="invalid-feedback">Orineted Place remark is required</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="add-listings-box">
			<div class="row">
				<div class="col-lg-6 col-12">
					<h3>Visual Impairment</h3>
					<div class="form-group">
						<div class="row" formGroupName="visual_impairment">
							<p class="d-flex align-items-center mb-2">
								<input type="radio" id="visual_none" formControlName="visual_none" value="1"
									(change)="handleInput($event, 'speech')">
								<label for="visual_none" class="mb-0 mx-2">
									None
								</label>
							</p>
							<p class="d-flex mb-2 align-items-center">
								<input type="radio" formControlName="visual_none" id="visual_glasses" value="2"
									(change)="handleInput($event, 'speech')">
								<label for="visual_glasses" class="mb-0 mx-2">
									Glasses
								</label>
							</p>
							<div class="mb-2">
								<div class="row">
									<div class="col-5 d-flex">
										<p class="d-flex align-items-center mb-2">
											<span>
												<input type="radio" id="visual_contact_lens"
													formControlName="visual_none" value="3"
													(change)="handleInput($event, 'lenses')"
													[class.is-invalid]="(assessment.submitted && ptAssessment.get(['visual_impairment', 'visual_contact_left'])?.invalid) || ptAssessment.get(['visual_impairment', 'visual_contact_left'])?.invalid && ptAssessment.get(['visual_impairment', 'visual_contact_left']).touched">
												<label for="visual_contact_lens" class="mb-0 mx-2">
													Contact lenses
												</label>
												<div class="invalid-feedback">Contact lenses are required</div>
											</span>
										</p>

									</div>
									<div class="col-7">
										<div class="d-flex mb-2 align-items-center">
											<input type="checkbox" id="visual_contact_left"
												formControlName="visual_contact_left" value="visual_contact_left"
												(change)="handleInput($event, 'lenses')">

											<label for="visual_contact_left" class="mb-0 mx-2 px-0">
												Left
											</label>
										</div>

										<div class="d-flex mb-2 align-items-center">
											<input type="checkbox" id="visual_contact_right"
												formControlName="visual_contact_right" value="visual_contact_right"
												(change)="handleInput($event, 'lenses')">
											<label for="visual_contact_right" class="mb-0 mx-2 px-0">
												Right
											</label>
										</div>
									</div>


								</div>
							</div>
							<div class="mb-2">
								<div class="row">
									<div class="col-5 d-flex">
										<p class="d-flex mb-2 align-items-center">
											<span>
												<input type="radio" id="visual_blind" formControlName="visual_none"
													value="4" (change)="handleInput($event, 'blind')"
													[class.is-invalid]="(assessment.submitted &&
												ptAssessment.get([ 'visual_impairment','visual_blind_left'])?.invalid)
												||
												ptAssessment.get(['visual_impairment','visual_blind_left'])?.invalid
												&&
												ptAssessment.get([ 'visual_impairment','visual_blind_left']).touched">
												<label for="visual_blind" class="mb-0 mx-2 ">
													Blind
												</label>
												<div class="invalid-feedback">Blind side is required</div>
											</span>
										</p>
									</div>
									<div class="col-7">
										<div class="d-flex mb-2 align-items-center">
											<input type="checkbox" id="visual_blind_left"
												formControlName="visual_blind_left" value="visual_blind_left"
												(change)="handleInput($event, 'blind')">
											<label for="visual_blind_left" class="mb-0 mx-2 px-0">
												Left
											</label>
										</div>

										<p class="d-flex mb-2 align-items-center">
											<input type="checkbox" id="visual_blind_right"
												formControlName="visual_blind_right" value="visual_blind_right"
												(change)="handleInput($event, 'blind')">
											<label for="visual_blind_right" class="mb-0 mx-2 px-0">
												Right
											</label>
										</p>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6 col-12">
					<h3>Hearing Impairement</h3>
					<div class="form-group">
						<div class="row" formGroupName="hearing_impairment">
							<p class="d-flex align-items-center mb-2">
								<input type="radio" id="hearing_none" formControlName="hearing_none" value="1"
									(change)="handleInput($event, 'hearing')">
								<label for="hearing_none" class="mb-0 mx-2 ">
									None
								</label>
							</p>
							<div class="mb-2">
								<div class="row">
									<div class="col-5 d-flex">
										<p class="d-flex align-items-center mb-2">
											<span>
												<input type="radio" id="hearing_impaired" formControlName="hearing_none"
													value="2" (change)="handleInput($event, 'impaired')"
													[class.is-invalid]="(assessment.submitted &&
												ptAssessment.get([ 'hearing_impairment','hearing_impaired_left'])?.invalid)
												||
												ptAssessment.get(['hearing_impairment','hearing_impaired_left'])?.invalid
												&&
												ptAssessment.get([ 'hearing_impairment','hearing_impaired_left']).touched">
												<label for="hearing_impaired" class="mb-0 mx-2 ">
													Impaired
												</label>
												<div class="invalid-feedback">Impaired side is required</div>
											</span>
										</p>
									</div>
									<div class="col-7">
										<div class="d-flex mb-2 align-items-center">
											<input type="checkbox" id="hearing_impaired_left"
												formControlName="hearing_impaired_left" value="hearing_impaired_left"
												(change)="handleInput($event, 'impaired')">
											<label for="hearing_impaired_left" class="mb-0 mx-2 px-0">
												Left
											</label>

										</div>
										<p class="d-flex mb-2 align-items-center">
											<input type="checkbox" id="hearing_impaired_right"
												formControlName="hearing_impaired_right" value="hearing_impaired_right"
												(change)="handleInput($event, 'impaired')">
											<label for="hearing_impaired_right" class="mb-0 mx-2 px-0">
												Right
											</label>
										</p>
									</div>
								</div>
							</div>
							<div class="mb-2">
								<div class="row">
									<div class="col-5 d-flex">
										<p class="d-flex align-items-center mb-2">
											<span>
												<input type="radio" id="hearing_aid" formControlName="hearing_none"
													value="3" (change)="handleInput($event, 'aid')" [class.is-invalid]="(assessment.submitted &&
												ptAssessment.get([ 'hearing_impairment','hearing_aid_left'])?.invalid)
												||
												ptAssessment.get(['hearing_impairment','hearing_aid_left'])?.invalid
												&&
												ptAssessment.get([ 'hearing_impairment','hearing_aid_left']).touched">
												<label for="hearing_aid" class="mb-0 mx-2">
													Hearing Aid
												</label>
												<div class="invalid-feedback">Hearing aid side is required</div>
											</span>
										</p>
									</div>
									<div class="col-7">
										<div class="d-flex mb-2 align-items-center">
											<input type="checkbox" id="hearing_aid_left"
												formControlName="hearing_aid_left" value="hearing_aid_left"
												(change)="handleInput($event, 'aid')">
											<label for="hearing_aid_left" class="mb-0 mx-2 px-0">
												Left
											</label>

										</div>
										<p class="d-flex mb-2 align-items-center">
											<input type="checkbox" id="hearing_aid_right"
												formControlName="hearing_aid_right" value="hearing_aid_right"
												(change)="handleInput($event, 'aid')">
											<label for="hearing_aid_right" class="mb-0 mx-2 px-0">
												Right
											</label>
										</p>
									</div>
								</div>
							</div>
							<div class="mb-2">
								<div class="row">
									<div class="col-5 d-flex">
										<p class="d-flex align-items-center mb-2">
											<span>
												<input type="radio" id="hearing_deaf" formControlName="hearing_none"
													value="4" (change)="handleInput($event, 'deaf')" [class.is-invalid]="(assessment.submitted &&
												ptAssessment.get([ 'hearing_impairment','hearing_deaf_left'])?.invalid)
												||
												ptAssessment.get(['hearing_impairment','hearing_deaf_left'])?.invalid
												&&
												ptAssessment.get([ 'hearing_impairment','hearing_deaf_left']).touched">
												<label for="hearing_deaf" class="mb-0 mx-2 ">
													Deaf
												</label>
												<div class="invalid-feedback">Deaf side is required</div>
											</span>
										</p>
									</div>
									<div class="col-7">
										<div class="d-flex mb-2 align-items-center">
											<input type="checkbox" id="hearing_deaf_left"
												formControlName="hearing_deaf_left" value="hearing_deaf_left"
												(change)="handleInput($event, 'deaf')">
											<label for="hearing_deaf_left" class="mb-0 mx-2 px-0">
												Left
											</label>

										</div>
										<p class="d-flex mb-2 align-items-center">
											<input type="checkbox" id="hearing_deaf_right"
												formControlName="hearing_deaf_right" value="hearing_deaf_right"
												(change)="handleInput($event, 'deaf')">
											<label for="hearing_deaf_right" class="mb-0 mx-2 px-0">
												Right
											</label>
										</p>
									</div>
								</div>
							</div>
							<div class="mb-2">
								<div class="row">
									<div class="col-5 d-flex">
										<p class="d-flex align-items-center mb-2">
											<span>
												<input type="radio" id="hearing_unable" formControlName="hearing_none"
													value="5" (change)="handleInput($event, 'unable')"
													[class.is-invalid]="(assessment.submitted &&
												ptAssessment.get([ 'hearing_impairment','hearing_unable_left'])?.invalid)
												||
												ptAssessment.get(['hearing_impairment','hearing_unable_left'])?.invalid
												&&
												ptAssessment.get([ 'hearing_impairment','hearing_unable_left']).touched">
												<label for="hearing_unable" class="mb-0 mx-2">
													Unable to Assess
												</label>
												<div class="invalid-feedback">Hearing side is required</div>
											</span>
										</p>
									</div>
									<div class="col-7">
										<div class="d-flex mb-2 align-items-center">
											<input type="checkbox" id="hearing_unable_left"
												formControlName="hearing_unable_left" value="hearing_unable_left"
												(change)="handleInput($event, 'unable')">
											<label for="hearing_unable_left" class="mb-0 mx-2 px-0">
												Left
											</label>

										</div>
										<p class="d-flex mb-2 align-items-center">
											<input type="checkbox" id="hearing_unable_right"
												formControlName="hearing_unable_right" value="hearing_unable_right"
												(change)="handleInput($event, 'unable')">
											<label for="hearing_unable_right" class="mb-0 mx-2 px-0">
												Right
											</label>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="add-listings-box">
			<h3>Speech Impairment</h3>
			<div class="form-group" formGroupName="speech_impairment">
				<div class="row">
					<p class="d-flex col-sm-3 col-6 align-items-center mb-2">
						<input type="radio" id="speech_none" formControlName="speech_none" value="speech_none">
						<label for="speech_none" class="mb-0 mx-2 ">
							None
						</label>
					</p>
					<p class="d-flex col-sm-3 col-6 mb-2">
						<input type="radio" formControlName="speech_none" id="speech_slurred" value="speech_slurred">
						<label for="speech_slurred" class="mb-0 mx-2 ">
							Slurred
						</label>
					</p>
					<p class="d-flex col-sm-3 col-6 mb-2 align-items-center">
						<input type="radio" id="speech_stuttered" formControlName="speech_none"
							value="speech_stuttered">
						<label for="speech_stuttered" class="mb-0 mx-2 ">
							Stuttered
						</label>
					</p>
					<p class="d-flex col-sm-3 col-6 mb-2 align-items-center">
						<input type="radio" id="speech_mute" formControlName="speech_none" value="speech_mute">
						<label for="speech_mute" class="mb-0 mx-2 ">
							Mute
						</label>
					</p>
					<p class="d-flex col-sm-3 col-6 mb-2 align-items-center">
						<input type="radio" id="speech_unable" formControlName="speech_none" value="speech_unable">
						<label for="speech_unable" class="mb-0 mx-2 ">
							Unable to Assess
						</label>
					</p>
				</div>
				<div class="row">
					<div class="col-12 my-3">
						<label class="px-0">Other Remarks</label>
						<textarea formControlName="speech_remarks" id="speech_remarks" class="form-control" rows="3"
							placeholder="Remarks"></textarea>
					</div>
				</div>
			</div>
		</div>
		<div class="add-listings-box">
			<h3>
				Respiratory Assessment
			</h3>
			<div class="form-group" formGroupName="repository_assessment">
				<div class="row">
					<p class="d-flex col-12 align-items-center mb-2">
						<input type="checkbox" id="repository_none" formControlName="repository_none"
							value="speech_none" (change)="handleInput($event, 'repo')">
						<label for="speech_none" class="mb-0 mx-2">
							No known Problems (WNL)
						</label>
					</p>
				</div>
				<div class="row">
					<p class="d-flex col-lg col-sm-3 col-6 align-items-center mb-2">
						<input type="radio" id="repository_cough" formControlName="repository_cough" value="Cough">
						<label for="repository_cough" class="mb-0 mx-2">
							Cough
						</label>
					</p>
					<p class="d-flex col-lg col-sm-3 col-6 align-items-center mb-2">
						<input type="radio" id="repository_productive" formControlName="repository_cough"
							value="Productive">
						<label for="repository_productive" class="mb-0 mx-2">
							Productive
						</label>
					</p>
					<p class="d-flex col-lg col-sm-3 col-6 align-items-center mb-2">
						<input type="radio" id="repository_non_productive" formControlName="repository_cough"
							value="Non Productive">
						<label for="repository_non_productive" class="mb-0 mx-2">
							Non Productive
						</label>
					</p>
					<p class="d-flex col-lg col-sm-3 col-6 align-items-center mb-2">
						<input type="radio" id="repository_dyspnea" formControlName="repository_cough" value="Dyspnea">
						<label for="repository_dyspnea" class="mb-0 mx-2">
							Dyspnea
						</label>
					</p>
					<p class="d-flex col-lg col-sm-3 col-6 align-items-center mb-2">
						<input type="radio" id="repository_orthopnia" formControlName="repository_cough"
							value="Orthopnea">
						<label for="repository_orthopnia" class="mb-0 mx-2">
							Orthopnea
						</label>
					</p>
				</div>
				<label class="subHeading my-3 px-0">Breathing Sound:</label>
				<div class="row">
					<!-- Clear -->
					<div class="col-md-4 col-6 m-0 p-0 row mt-3">
						<label class="col-6">Clear</label>
						<div class="col-6">
							<div class="d-flex">
								<input type="checkbox" id="breath_sound_clear_right"
									formControlName="breath_sound_clear" value="speech_none">
								<label for="speech_none" class="mb-2 mx-2 px-0">
									Right
								</label>
							</div>


							<div class="d-flex">
								<input type="checkbox" id="breath_sound_clear_left" formControlName="breath_sound_left"
									value="speech_none">
								<label for="speech_none" class="mb-2 mx-2 px-0">
									Left
								</label>
							</div>
						</div>
					</div>
					<!-- Crackles -->
					<div class="col-md-4 col-6 m-0 p-0 row mt-3">
						<label class="col-6">Crackles</label>
						<div class="col-6">
							<div class="d-flex">
								<input type="checkbox" id="breath_sound_crackles_right"
									formControlName="breath_sound_crackles" value="speech_none">
								<label for="speech_none" class="mb-2 mx-2 px-0">
									Right
								</label>
							</div>


							<div class="d-flex">
								<input type="checkbox" id="breath_sound_crackles_left"
									formControlName="breath_sound_crackles_left" value="speech_none">
								<label for="speech_none" class="mb-2 mx-2 px-0">
									Left
								</label>
							</div>
						</div>
					</div>
					<!-- Wheezing -->
					<div class="col-md-4 col-6 m-0 p-0 row mt-3">
						<label class="col-6">Wheezing</label>
						<div class="col-6">
							<div class="d-flex">
								<input type="checkbox" id="breath_sound_wheezing_right"
									formControlName="breath_sound_wheezing" value="speech_none">
								<label for="speech_none" class="mb-2 mx-2 px-0">
									Right
								</label>
							</div>


							<div class="d-flex">
								<input type="checkbox" id="breath_sound_wheezing_left"
									formControlName="breath_sound_wheezing_left" value="speech_none">
								<label for="speech_none" class="mb-2 mx-2 px-0">
									Left
								</label>
							</div>
						</div>
					</div>


					<!-- Diminish -->
					<div class="col-md-4 col-6 m-0 p-0 row mt-3">
						<label class="col-6">Diminish</label>
						<div class="col-6">
							<div class="d-flex">
								<input type="checkbox" id="breath_sound_diminish_right"
									formControlName="breath_sound_diminish" value="speech_none">
								<label for="speech_none" class="mb-2 mx-2 px-0">
									Right
								</label>
							</div>


							<div class="d-flex">
								<input type="checkbox" id="breath_sound_diminish_left"
									formControlName="breath_sound_diminish_left" value="speech_none">
								<label for="speech_none" class="mb-2 mx-2 px-0">
									Left
								</label>
							</div>
						</div>
					</div>


					<!-- Rhonchi -->
					<div class="col-md-4 col-6 m-0 p-0 row mt-3">
						<label class="col-6">Rhonchi</label>
						<div class="col-6">
							<div class="d-flex">
								<input type="checkbox" id="breath_sound_rhonchi" formControlName="breath_sound_rhonchi"
									value="speech_none">
								<label for="speech_none" class="mb-2 mx-2 px-0">
									Right
								</label>
							</div>


							<div class="d-flex">
								<input type="checkbox" id="breath_sound_rhonchi_left"
									formControlName="breath_sound_rhonchi_left" value="speech_none">
								<label for="speech_none" class="mb-2 mx-2 px-0">
									Left
								</label>
							</div>
						</div>
					</div>


					<!-- Stridor -->
					<div class="col-md-4 col-6 m-0 p-0 row mt-3">
						<label class="col-6">Stridor</label>
						<div class="col-6">
							<div class="d-flex">
								<input type="checkbox" id="breath_sound_stridor_right"
									formControlName="breath_sound_stridor" value="speech_none">
								<label for="speech_none" class="mb-2 mx-2 px-0">
									Right
								</label>
							</div>


							<div class="d-flex">
								<input type="checkbox" id="breath_sound_stridor_left"
									formControlName="breath_sound_stridor_left" value="speech_none">
								<label for="speech_none" class="mb-2 mx-2 px-0">
									Left
								</label>
							</div>
						</div>
					</div>


					<!-- Absent -->
					<div class="col-md-4 col-6 m-0 p-0 row mt-3">
						<label class="col-6">Absent</label>
						<div class="col-6">
							<div class="d-flex">
								<input type="checkbox" id="breath_sound_absent_right"
									formControlName="breath_sound_absent" value="speech_none">
								<label for="speech_none" class="mb-2 mx-2 px-0">
									Right
								</label>
							</div>


							<div class="d-flex">
								<input type="checkbox" id="breath_sound_absent_left"
									formControlName="breath_sound_absent_left" value="speech_none">
								<label for="speech_none" class="mb-2 mx-2 px-0">
									Left
								</label>
							</div>
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-12 my-3">
						<label class="px-0">Comments:</label>
						<textarea formControlName="breath_sound_comments" id="breath_sound_comments"
							class="form-control" rows="3" placeholder="Comments"></textarea>
					</div>
				</div>


				<!-- Breathing pattern -->
				<div class="row">
					<label class="subHeading px-0">Breathing Pattern</label>
					<div class="col-sm-6 col-12 row">
						<p class="d-flex">
							<input type="radio" id="breathing_pattern_Normal" value="Normal"
								formControlName="breathing_pattern_Normal" (change)="handleInput($event, 'breath')" />
							<label for="breathing_pattern_Normal" class="mb-0 mx-2">Normal</label>
						</p>
						<p class="d-flex">
							<input type="radio" id="breathing_pattern_shallow"
								formControlName="breathing_pattern_Normal" value="Shallow"
								(change)="handleInput($event, 'breath')">
							<label for="breathing_pattern_shallow" class="mb-0 mx-2">Shallow</label>
						</p>
						<p class="d-flex">
							<input type="radio" id="breathing_pattern_rapid" formControlName="breathing_pattern_Normal"
								value="Rapid" (change)="handleInput($event, 'breath')">
							<label for="breathing_pattern_rapid" class="mb-0 mx-2">Rapid</label>
						</p>
						<p class="d-flex">
							<input type="radio" id="breathing_pattern_labored"
								formControlName="breathing_pattern_Normal" value="Labored"
								(change)="handleInput($event, 'breath')">
							<label for="breathing_pattern_labored" class="mb-0 mx-2">Labored</label>
						</p>
					</div>
					<div class="col-sm-6 col-12">
						<label class="px-0">Comments:</label>
						<textarea formControlName="breathing_pattern_comments" id="breathing_pattern_comments"
							class="form-control" rows="4" placeholder="Comments" [class.is-invalid]="(assessment.submitted &&
							ptAssessment.get(['repository_assessment','breathing_pattern_comments'])?.invalid)
							||
							ptAssessment.get(['repository_assessment','breathing_pattern_comments'])?.invalid
							&&
							ptAssessment.get(['repository_assessment','breathing_pattern_comments']).touched">
						</textarea>
						<div class="invalid-feedback">Breathing Pattern comment is required</div>
					</div>

				</div>


				<!-- Oxygen therapy -->
				<label class="subHeading mt-sm-auto mt-3 px-0">Oxygen Therapy</label>
				<div class="row">
					<div class="col-sm-6 col-12 row">
						<div class="d-flex col-sm-12 col-6">


							<p class="d-flex">
								<input type="radio" id="oxygen_therapy_yes" value="Yes" formControlName="oxygen_therapy"
									(change)="handleInput($event, 'oxygen')" />
								<label for="oxygen_therapy_yes" class="mb-0 mx-2">Yes</label>
							</p>
						</div>

						<div class="d-flex col-sm-12 col-6">

							<p class="d-flex">
								<input type="radio" id="oxygen_therapy_no" value="No" formControlName="oxygen_therapy"
									(change)="handleInput($event, 'oxygen')" />
								<label for="oxygen_therapy_no" class="mb-0 mx-2">No</label>
							</p>
						</div>
						<div class="d-flex col-sm-12 col-6">
							<div class="form-group">
								<label class="px-0">LMP</label>
								<input type="text" id="oxygen_therapy_lmp" formControlName="lmp_oxygen"
									value="speech_none" class="form-control" placeholder="LMP">
							</div>
						</div>


						<div class="d-flex col-sm-12 col-6">
							<div class="form-group">
								<label class="px-0">SPO2</label>
								<input type="text" id="oxygen_therapy_spo2" formControlName="spo2_therapy"
									value="speech_none" class="form-control" placeholder="SPO2">
							</div>
						</div>
					</div>
					<div class="col-sm-6 col-12">
						<label class="px-0">Comments:</label>
						<textarea formControlName="oxygen_therapy_comments" id="oxygen_therapy_comments"
							class="form-control" rows="5" placeholder="Comments" [class.is-invalid]="(assessment.submitted &&
									ptAssessment.get(['repository_assessment','oxygen_therapy_comments'])?.invalid)
									||
									ptAssessment.get(['repository_assessment', 'oxygen_therapy_comments'])?.invalid
									&&
									ptAssessment.get(['repository_assessment', 'oxygen_therapy_comments']).touched"></textarea>
						<div class="invalid-feedback">Oxygen therapy comment is required</div>
					</div>


				</div>


				<!-- Secretion -->
				<div class="row my-3">
					<div class="col-sm-6 col-12 row">
						<label class="subHeading">Secretion:</label>
						<div class="col-6">
							<div class="d-flex">

								<p class="d-flex">
									<input type="radio" id="secretion_yes" value="Yes" formControlName="secretion"
										(change)="handleInput($event, 'secretion')" />
									<label for="secretion_yes" class="mb-0 mx-2">Yes</label>
								</p>
							</div>
							<div class="d-flex">

								<p class="d-flex">
									<input type="radio" id="secretion_no" value="No" formControlName="secretion"
										(change)="handleInput($event, 'secretion')" />
									<label for="secretion_no" class="mb-0 mx-2">No</label>
								</p>
							</div>
						</div>
						<div class="col-6">
							<div class="d-flex">
								<input type="checkbox" id="secretion_thick" formControlName="Thick_secretion"
									value="speech_none" class="mx-1">
								<label class="px-0">Thick</label>
							</div>


							<div class="d-flex">
								<input type="checkbox" id="secretion_loose" formControlName="Loose_secretion"
									value="speech_none" class="mx-1">
								<label class="px-0">Loose</label>
							</div>


							<div class="d-flex">
								<input type="checkbox" id="secretion_retention" formControlName="Retention_secretion"
									value="speech_none" class="mx-1">
								<label class="px-0">Retention</label>
							</div>
						</div>


					</div>
				</div>
			</div>
		</div>


		<div class="add-listings-box">
			<h3>
				Circulatory Assessment:
			</h3>
			<!-- Pulse -->
			<div class="from-group">
				<p class="d-flex col-12  align-items-center mb-2">
					<input type="checkbox" id="gastro" formControlName="gastro_circulatory" value="speech_none"
						(change)="handleInput($event, 'gastro')">
					<label for="speech_none" class="mb-0 mx-2 px-0">
						No Known Problems (WNL)
					</label>
				</p>
			</div>
			<label class="subHeading mb-2">Pulse:</label>
			<div class="form-group">
				<div class="row">


					<p class="d-flex col-sm-3 col-6 align-items-center mb-2">
						<input type="radio" id="pulse_regular" formControlName="pulse" value="Regular"
							[class.is-invalid]="(assessment.submitted &&
                        ptAssessment.get('pulse')?.invalid)
                        ||
                        ptAssessment.get('pulse')?.invalid
                        &&
                        ptAssessment.get('pulse').touched">
						<label for="pulse_regular" class="mb-0 mx-2">
							Regular
						</label>
					</p>
					<p class="d-flex col-sm-3 col-6 mb-2">
						<input type="radio" formControlName="pulse" id="pulse_irregular" value="Irregular">
						<label for="pulse_irregular" class="mb-0 mx-2 ">
							Irregular
						</label>
					</p>

					<div class="invalid-feedback">pulse is required</div>
				</div>
			</div>


			<!-- Blood Pressure -->
			<label class="subHeading mb-2">Blood Pressure:</label>
			<div class="form-group">
				<div class="row">

					<div class="col-sm-6 col-12 row mt-sm-0 mt-2">
						<p class="d-flex col-4">
							<input type="radio" id="blood_pressure_normal" formControlName="blood_pressure"
								value="Normal">
							<label for="blood_pressure_normal" class="mb-0 mx-2">Normal</label>
						</p>
						<p class="d-flex col-4">
							<input type="radio" id="blood_pressure_low" formControlName="blood_pressure" value="Low">
							<label for="blood_pressure_low" class="mb-0 mx-2">Low</label>
						</p>
						<p class="d-flex col-4">
							<input type="radio" id="blood_pressure_high" formControlName="blood_pressure" value="High">
							<label for="blood_pressure_high" class="mb-0 mx-2">High</label>
						</p>
					</div>
				</div>
			</div>


			<!-- Peripheral Pulse -->
			<label class="subHeading mb-2">Peripheral Pulse:</label>
			<div class="form-group">
				<div class="row">
					<p class="d-flex col-sm-3 col-6">
						<input type="radio" id="peripheral_pulse_bounding" formControlName="peripheral_pulse"
							value="bounding" class="mx-1">
						<label for="peripheral_pulse_bounding" class="mb-0 mx-2">Bounding</label>
					</p>
					<p class="d-flex col-sm-3 col-6">
						<input type="radio" id="peripheral_pulse_palpable" formControlName="peripheral_pulse"
							value="palpable" class="mx-1">
						<label for="peripheral_pulse_palpable" class="mb-0 mx-2">Palpable</label>
					</p>
					<p class="d-flex col-sm-3 col-6">
						<input type="radio" id="peripheral_pulse_diminished" formControlName="peripheral_pulse"
							value="diminished" class="mx-1">
						<label for="peripheral_pulse_diminished" class="mb-0 mx-2">Diminished</label>
					</p>
					<p class="d-flex col-sm-3 col-6">
						<input type="radio" id="peripheral_pulse_absent" formControlName="peripheral_pulse"
							value="absent" class="mx-1">
						<label for="peripheral_pulse_absent" class="mb-0 mx-2">Absent</label>
					</p>


				</div>
			</div>


			<!-- Edematious -->
			<div class="form-group">
				<div class="row">
					<div class="col-sm-6 col-8 row p-0 m-0">
						<label class="subHeading mb-sm-3 mb-0">Edematous:</label>
						<div class="col-sm-3 col-12">
							<p class="d-flex">
								<input type="radio" id="edematious_yes" value="Yes" formControlName="edematious"
									(change)="handleInput($event, 'edematious')" />
								<label for="edematious_yes" class="mb-0 mx-2">Yes</label>
							</p>
							<p class="d-flex">
								<input type="radio" id="edematious_no" value="No" formControlName="edematious"
									(change)="handleInput($event, 'edematious')" />
								<label for="edematious_no" class="mb-0 mx-2">No</label>
							</p>
						</div>
						<div class="col-sm-9 col-12">
							<input type="text" formControlName="edematious_text" class="form-control"
								placeholder="Edematous Remark" [class.is-invalid]="(assessment.submitted &&
							ptAssessment.get('edematious_text')?.invalid)
							||
							ptAssessment.get('edematious_text')?.invalid
							&&
							ptAssessment.get('edematious_text').touched">
							<div class="invalid-feedback">Edematous Remark is required</div>
						</div>

					</div>


					<!-- Scale -->

					<div class="col-sm-6 col-8 row p-0 m-0">
						<label class="subHeading mb-3">Scale:</label>
						<div class="col-sm-6 col-12">
							<div class="form-group">
								<p class="d-flex">
									<input type="radio" id="scale_one" formControlName="scale" value="one" class="mx-1">
									<label for="scale_one" class="mb-2 mx-2">1+</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="scale_two" formControlName="scale" value="two" class="mx-1">
									<label for="scale_two" class="mb-2 mx-2">2+</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="scale_three" formControlName="scale" value="three"
										class="mx-1">
									<label for="scale_three" class="mb-2 mx-2">3+</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="scale_four" formControlName="scale" value="four"
										class="mx-1">
									<label for="scale_four" class="mb-2 mx-2">4+</label>
								</p>
							</div>
						</div>
					</div>

				</div>
			</div>


			<!-- Capillary refill -->
			<div class="form-group">
				<div class="row">
					<div class="col-sm-6 col-12">
						<label class="subHeading mb-3 px-0">Capillary Refill:</label>
						<p class="d-flex">
							<input type="radio" id="capillary_refill_two_sec" formControlName="capillary_refill"
								value="2 Second" class="mx-1">
							<label for="capillary_refill_two_sec" class="mb-2 mx-2">2 Second</label>
						</p>
						<p class="d-flex">
							<input type="radio" id="capillary_refill_more_sec" formControlName="capillary_refill"
								value="more than 2 Seconds" class="mx-1">
							<label for="capillary_refill_more_sec" class="mb-2 mx-2">More than 2 Seconds</label>
						</p>
					</div>
				</div>
			</div>


			<!-- Skin temperature -->
			<label class="subHeading mb-3">Skin Temperature:</label>
			<div class="form-group" formGroupName="speech_impairment">
				<div class="row">
					<p class="d-flex col-sm-3 col-6 align-items-center mb-2">
						<input type="radio" id="skin_temperature_warm" formControlName="skin_temperature" value="warm">
						<label for="skin_temperature_warm" class="mb-0 mx-2 ">
							Warm
						</label>
					</p>
					<p class="d-flex col-sm-3 col-6 mb-2">
						<input type="radio" formControlName="skin_temperature" id="skin_temperature_cold" value="cold">
						<label for="skin_temperature_cold" class="mb-0 mx-2 ">
							Cold
						</label>
					</p>
					<p class="d-flex col-sm-3 col-6 mb-2 align-items-center">
						<input type="radio" id="skin_temperature_cynotic_pallor" formControlName="skin_temperature"
							value="pallor">
						<label for="skin_temperature_cynotic_pallor" class="mb-0 mx-2 ">
							Pallor
						</label>
					</p>
					<p class="d-flex col-sm-3 col-6 mb-2 align-items-center">
						<input type="radio" id="skin_temperature_cynotic" formControlName="skin_temperature"
							value="cynotic">
						<label for="skin_temperature_cynotic" class="mb-0 mx-2 ">
							Cynotic
						</label>
					</p>
				</div>
				<div class="row">
					<div class="col-12 my-3">
						<label class="px-0">Comments</label>
						<textarea formControlName="skin_temp_comment" id="skin_temp_comment" class="form-control"
							rows="3" placeholder="Comments"></textarea>
					</div>
				</div>
			</div>
		</div>


		<div class="add-listings-box pb-2">
			<h3>
				Gastro Intestinal Assessment:
			</h3>
			<div class="form-group">
				<p class="d-flex col-12  align-items-center mb-2">
					<input type="checkbox" id="gastro" formControlName="gastro" value="speech_none"
						(change)="handleInput($event, 'bowel')">
					<label for="speech_none" class="mb-0 mx-2 px-0">
						No Known Problems (WNL)
					</label>
				</p>
				<!-- Bowel sounds -->
				<label class="subHeading my-3 px-0">Bowel Sounds:</label>
				<div class="form-group">
					<div class="row">
						<p class="d-flex col-sm-3 col-6 align-items-center mb-2">
							<input type="radio" id="bowel_sound_normal" formControlName="bowel_sound" value="Normal">
							<label for="bowel_sound_normal" class="mb-0 mx-2 ">
								Normal
							</label>
						</p>
						<p class="d-flex col-sm-3 col-6 mb-2">
							<input type="radio" formControlName="bowel_sound" id="bowel_sound_hypoactive"
								value="Hypoactive">
							<label for="bowel_sound_hypoactive" class="mb-0 mx-2 ">
								Hypoactive
							</label>
						</p>
						<p class="d-flex col-sm-3 col-6 mb-2 align-items-center">
							<input type="radio" id="bowel_sound_hyperactive" formControlName="bowel_sound"
								value="Hyperactive">
							<label for="bowel_sound_hyperactive" class="mb-0 mx-2 ">
								Hyperactive
							</label>
						</p>
					</div>
					<div class="row">
						<div class="col-12 my-3">
							<label class="px-0">Comments</label>
							<textarea formControlName="bowel_sound_comment" class="form-control" rows="3"
								placeholder="Comments"></textarea>
						</div>
					</div>
				</div>


				<!-- Enternal feeling device -->
				<label class="subHeading my-3 px-0">
					External Feeding Device:
				</label>
				<div class="form-group">
					<div class="row mb-3">
						<div class="col-sm-6 col-12">

							<p class="d-flex">
								<input type="radio" id="enternal_feeling_yes" value="Yes"
									formControlName="enternal_feeling" (change)="handleInput($event, 'enternal')" />
								<label for="enternal_feeling_yes" class="mb-0 mx-2">Yes</label>
							</p>
							<p class="d-flex">
								<input type="radio" id="enternal_feeling_no" value="No"
									formControlName="enternal_feeling" (change)="handleInput($event, 'enternal')" />
								<label for="enternal_feeling_no" class="mb-0 mx-2">No</label>
							</p>
						</div>


						<div class="col-sm-6 col-12">
							<label class="px-0">Comment</label>
							<div class="form-group">
								<textarea formControlName="enternal_feeling_comment" class="form-control" rows="3"
									placeholder="Comment" [class.is-invalid]="(assessment.submitted &&
									ptAssessment.get('enternal_feeling_comment')?.invalid)
									||
									ptAssessment.get('enternal_feeling_comment')?.invalid
									&&
									ptAssessment.get('enternal_feeling_comment').touched"></textarea>
								<div class="invalid-feedback">External feeling comment is required</div>
							</div>
						</div>
					</div>
				</div>


				<!-- Nausea -->
				<div class="row">
					<p class="d-flex col-lg col-sm-3 col-6 align-items-center mb-2">
						<input type="radio" id="nausea" formControlName="nausea" value="Nausea">
						<label for="nausea" class="mb-0 mx-2">
							Nausea
						</label>
					</p>
					<p class="d-flex align-items-center col-lg col-sm-3 col-6 mb-2">
						<input type="radio" formControlName="nausea" id="dysphagia" value="Dysphagia">
						<label for="dysphagia" class="mb-0 mx-2">
							Dysphagia
						</label>
					</p>
					<p class="d-flex col-lg col-sm-3 col-6 mb-2 align-items-center">
						<input type="radio" id="difficulty_chewing" formControlName="Nausea"
							value="	Difficulty Chewing">
						<label for="difficulty_chewing" class="mb-0 mx-2">
							Difficulty Chewing
						</label>
					</p>
					<p class="d-flex col-lg col-sm-3 col-6 mb-2 align-items-center">
						<input type="radio" id="constipation" formControlName="nausea" value="Constipation">
						<label for="constipation" class="mb-0 mx-2">
							Constipation
						</label>
					</p>
					<p class="d-flex col-lg col-sm-3 col-6 mb-2 align-items-center">
						<input type="radio" id="diarrhea" formControlName="nausea" value="Diarrhea">
						<label for="diarrhea" class="mb-0 mx-2">
							Diarrhea
						</label>
					</p>
				</div>
			</div>
		</div>


		<div class="add-listings-box pb-2">
			<h3>Skin:</h3>
			<!-- Pressure Ulcer -->
			<label class="subHeading my-3 px-0">Pressure Ulcer:</label>
			<div class="form-group">
				<div class="row">

					<div class="d-flex col-sm-12 col-6">
						<div class="form-group">
							<label class="px-0">Location</label>
							<input type="text" id="pressure_ulcer_location" formControlName="pressure_ulcer"
								value="skin_warm" class="form-control" placeholder="Location">
						</div>
					</div>



					<div class="d-flex col-sm-12 col-6">
						<div class="form-group">
							<label class="px-0">Length</label>
							<input type="text" id="pressure_ulcer_length" formControlName="pressure_ulcer_length"
								value="skin_cold" class="form-control" placeholder="Length">
						</div>
					</div>

					<div class="d-flex col-sm-12 col-6">
						<div class="form-group">
							<label class="px-0">Width</label>
							<input type="text" id="pressure_ulcer_width" formControlName="pressure_ulcer_width"
								value="skin_pallor" class="form-control" placeholder="Width">
						</div>
					</div>



					<div class="d-flex col-sm-12 col-6">
						<div class="form-group">
							<label class="px-0">Depth</label>
							<input type="text" id="pressure_ulcer_depth" formControlName="pressure_ulcer_depth"
								value="skin_pallor" class="form-control" placeholder="Depth">
						</div>
					</div>
				</div>
			</div>


			<!-- swelling, scar -->
			<div class="form-group mb-sm-4 mb-3">
				<div class="row">
					<div class="col-sm-6 col-12">
						<label class="subHeading px-0">Swelling</label>
						<input type="text" formControlName="swelling" id="swelling" class="form-control"
							placeholder="Swelling">
					</div>
					<div class="col-sm-6 col-12">
						<label class="subHeading px-0 mt-sm-0 mt-2">Scar</label>
						<input type="text" formControlName="scar" id="scar" class="form-control" placeholder="Scar">
					</div>
				</div>
			</div>



			<div class="form-group">
				<label class="subHeading px-0">Medications: </label>
				<div formArrayName="assesment_json">
					<ng-container *ngFor="let control of getMedicationFormArray().controls; index as i; last as last">
						<div class="row" [formGroupName]="i">
							<div class="col-md-11 col-sm-10 col-12">
								<div class="form-group">
									<label class="px-0">Medications</label>
									<div class="pl-0 checkbox-list">
										<input type="text" class="form-control" placeholder="Medications"
											formControlName="medication" id="medications" />
									</div>
								</div>
							</div>


							<div class="col-md-1 col-sm-2 col-12 mt-2 d-flex justify-content-end align-items-center">
								<p-button type="button" icon="bx bx-plus" (click)="addMedication('add', i)"
									iconPos="right" class="mr-2"></p-button>
								<div class="remove">
									<p-button type="button" *ngIf="i!= 0" class="ml-2" icon="bx bx-minus"
										(click)="addMedication('delete', i)" iconPos="right"></p-button>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
			<!-- Psychological Information: -->
			<div class="add-listings-box">
				<h3>
					Psychological Information
				</h3>
				<div class="row">
					<div class="col-12">
						<div class="form-group">
							<label class="px-0">Mood:</label>
							<div class="form-check checkbox-list p-0">
								<div class="form-group" formGroupName="psychological_information">
									<div class="row">
										<p class="d-flex col-lg-3 col-sm-4 col-6 align-items-center mb-2">
											<input type="checkbox" id="mood_calm" formControlName="mood_calm"
												value="mother">
											<label for="mother" class="mb-0 mx-2 px-0">
												Calm and cooperative
											</label>
										</p>
										<p class="d-flex col-lg-3 col-sm-4 col-6 mb-2">
											<input type="checkbox" formControlName="mood_sleep_dis" id="mood_sleep_dis"
												value="nanny">
											<label for="nanny" class="mb-0 mx-2 px-0">
												Sleep Disturbance
											</label>
										</p>
										<p class="d-flex col-lg-3 col-sm-4 col-6 mb-2 align-items-center">
											<input type="checkbox" id="mood_abuse" formControlName="mood_abuse"
												value="other">
											<label for="other" class="mb-0 mx-2 px-0">
												Sign of neglect/abuse
											</label>
										</p>
										<p class="d-flex col-lg-3 col-sm-4 col-6 mb-2 align-items-center">
											<input type="checkbox" id="mood_anxious" formControlName="mood_anxious"
												value="other">
											<label for="other" class="mb-0 mx-2 px-0">
												Anxious
											</label>
										</p>
										<p class="d-flex col-lg-3 col-sm-4 col-6 mb-2 align-items-center">
											<input type="checkbox" id="mood_sad" formControlName="mood_sad"
												value="other">
											<label for="other" class="mb-0 mx-2 px-0">
												Sad
											</label>
										</p>
										<p class="d-flex col-lg-3 col-sm-4 col-6 mb-2 align-items-center">
											<input type="checkbox" id="mood_trearful" formControlName="mood_trearful"
												value="other">
											<label for="other" class="mb-0 mx-2 px-0">
												Tearful
											</label>
										</p>
										<p class="d-flex col-lg-3 col-sm-4 col-6 mb-2 align-items-center">
											<input type="checkbox" id="mood_ubcooperative"
												formControlName="mood_ubcooperative" value="other">
											<label for="other" class="mb-0 mx-2 px-0">
												Uncooperative
											</label>
										</p>
										<p class="d-flex col-lg-3 col-sm-4 col-6 mb-2 align-items-center">
											<input type="checkbox" id="mood_widrawal" formControlName="mood_widrawal"
												value="other">
											<label for="other" class="mb-0 mx-2 px-0">
												Widrawal
											</label>
										</p>
										<p class="d-flex col-lg-3 col-sm-4 col-6 mb-2 align-items-center">
											<input type="checkbox" id="mood_sucidal" formControlName="mood_sucidal"
												value="other">
											<label for="other" class="mb-0 mx-2 px-0">
												Suicidal
											</label>
										</p>
										<p class="d-flex col-lg-3 col-sm-4 col-6 mb-2 align-items-center">
											<input type="checkbox" id="mood_access" formControlName="mood_access"
												value="other">
											<label for="other" class="mb-0 mx-2 px-0">
												Unable to access
											</label>
										</p>
										<div class="col-lg-9 col-12 mb-2 align-items-center">
											<div class="row">
												<div class="col-4 d-flex align-items-center">
													<input type="checkbox" id="mood_others"
														formControlName="mood_others" value="other">
													<label for="other" class="mb-0 mx-2 px-0">
														Others
													</label>
												</div>
												<div class="col-sm-4 col-8">
													<input type="text" id="mood_other_comment"
														formControlName="mood_other_comment" class="form-control"
														placeholder="Others">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<!-- Musculoskeletal Assessment: -->
			<div class="add-listings-box">
				<h3>
					Musculoskeletal Assessment
				</h3>
				<div class="form-group" formGroupName="musculoskeletal_assessment">
					<p class="d-flex col-12 align-items-center mb-2">
						<input type="checkbox" id="musculoskeletal_problems" formControlName="musculoskeletal_problems"
							value="speech_none" (change)="handleInput($event, 'musco')">
						<label for="musculoskeletal_problems" class="mb-0 mx-2 px-0">
							No Known Problems (WNL)
						</label>
					</p>
					<div class="row m-0 p-0">
						<label class="subHeading mt-2 px-0">
							Normal Range of Motion of Extremities:
						</label>
						<div class="col-lg-6 col-sm-5 col-12 px-0">
							<p class="d-flex align-items-center mb-2">
								<input type="radio" id="motion_extreme_yes" formControlName="motion_extreme_yes"
									value="Yes" (change)="handleInput($event, 'motion')">
								<label for="motion_extreme_yes" class="mb-0 mx-2">
									Yes
								</label>
							</p>
							<p class="d-flex align-items-center mb-2">
								<input type="radio" id="motion_extreme_no" formControlName="motion_extreme_yes"
									value="No" (change)="handleInput($event, 'motion')">
								<label for="motion_extreme_no" class="mb-0 mx-2">No</label>
							</p>
						</div>
						<div class="col-lg-6 col-sm-7 col-12 px-0">
							<div class="row m-0 p-0">
								<div class="d-flex col-lg-5 col-6 px-0">
									<div>
										<input type="checkbox" id="upper_extreme" formControlName="upper_extreme"
											value="upper_extreme" (change)="handleInput($event, 'right')"
											[class.is-invalid]="(assessment.submitted && ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_right'])?.invalid) ||
											ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_right'])?.invalid &&
											ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_right']).touched">
										<label for="upper_extreme" class="mb-0 mx-2 px-0" style="display: contents;">
											Upper Extremities:
										</label>
										<div class="invalid-feedback">
											Upper Extremities is required
										</div>
									</div>

								</div>



								<div class="d-flex col">
									<input type="checkbox" id="upper_extreme_right"
										formControlName="upper_extreme_right" value="upper_extreme_right"
										(change)="handleInput($event, 'right')">
									<label for="upper_extreme_right" class="mb-0 mx-2 px-0">
										Right
									</label>

								</div>

								<div class="d-flex col">
									<input type="checkbox" id="upper_extreme_left" formControlName="upper_extreme_left"
										value="upper_extreme_left" (change)="handleInput($event, 'right')">
									<label for="upper_extreme_left" class="mb-0 mx-2 px-0">
										Left
									</label>
								</div>
							</div>



							<div class="row m-0 p-0">
								<div class="d-flex col-lg-5 col-6 px-0">
									<div>
										<input type="checkbox" id="lower_extreme" formControlName="lower_extreme"
											value="lower_extreme" (change)="handleInput($event, 'left')"
											[class.is-invalid]="(assessment.submitted &&
										ptAssessment.get([ 'musculoskeletal_assessment','lower_extreme_left'])?.invalid)
										||
										ptAssessment.get(['musculoskeletal_assessment','lower_extreme_left'])?.invalid
										&&
										ptAssessment.get([ 'musculoskeletal_assessment','lower_extreme_left']).touched">
										<label for="mother" class="mb-2 mx-2 px-0" style="display: contents;">
											Lower Extremities:
										</label>
										<div class="invalid-feedback">Lower Extermities is required</div>
									</div>
									
								</div>

								<p class="d-flex col">
									<input type="checkbox" id="lower_extreme_right"
										formControlName="lower_extreme_right" value="lower_extreme_right"
										(change)="handleInput($event, 'left')">
									<label for="mother" class="mb-0 mx-2  px-0">
										Right
									</label>
								</p>
								<p class="d-flex col">
									<input type="checkbox" id="lower_extreme_left" formControlName="lower_extreme_left"
										value="lower_extreme_left" (change)="handleInput($event, 'left')">
									<label for="mother" class="mb-0 mx-2  px-0">
										Left
									</label>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div class="add-listings-box">
				<h3>Motor Components</h3>
				<label class="subHeading mb-3">Joint and Muscle:</label>
				<div class="form-group table-responsive" formGroupName="joint_muscles">
					<table class="table table-bordered">
						<thead>
							<tr style="white-space: nowrap;">
								<th scope="col" class="text-center" rowspan="2"><label
										class="subHeading px-0">Joint</label>
								</th>
								<th scope="col" class="text-center" rowspan="2"><label
										class="subHeading px-0">Movement</label>
								</th>
								<th scope="col" class="text-center" rowspan="2"><label class="subHeading px-0">Norm
										ROM</label>
								</th>
								<th scope="col" class="text-center" colspan="2"><label class="subHeading px-0">
										ROM</label></th>
								<th scope="col" class="text-center" colspan="2"><label class="subHeading px-0">Tone
									</label>
								</th>
								<th scope="col" class="text-center" rowspan="2"><label
										class="subHeading px-0">Comments</label>
								</th>
								<th scope="col" class="text-center" colspan="2"><label class="subHeading px-0">Muscle
										Strength</label></th>
							</tr>
							<tr>
								<th class="text-center">R</th>
								<th class="text-center">L</th>
								<th class="text-center">R</th>
								<th class="text-center">L</th>
								<th class="text-center">R</th>
								<th class="text-center">L</th>
							</tr>
						</thead>
						<tbody>
							<!-- NECK ROW -->
							<!-- Flexion -->
							<tr>
								<td scope="row" rowspan="4"> <label class="px-0 ">Neck</label></td>
								<td>Flexion</td>
								<td>0-50</td>
								<td>
									<input type="checkbox" value="intact" id="neck_flexion_passive_r"
										formControlName="neck_flexion_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="neck_flexion_passive_l"
										formControlName="neck_flexion_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="neck_flexion_active_r"
										formControlName="neck_flexion_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="neck_flexion_active_l"
										formControlName="neck_flexion_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="nech_flexion_comment"
										formControlName="nech_flexion_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="neck_flexion_muscle_r"
										formControlName="neck_flexion_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="neck_flexion_muscle_l"
										formControlName="neck_flexion_muscle_l" />
								</td>

							</tr>

							<!-- Extension -->
							<tr>
								<td>Extension</td>
								<td>0-60</td>
								<td>
									<input type="checkbox" value="intact" id="neck_extension_passive_r"
										formControlName="neck_extension_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="neck_extension_passive_l"
										formControlName="neck_extension_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="neck_extension_active_r"
										formControlName="neck_extension_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="neck_extension_active_l"
										formControlName="neck_extension_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="neck_extension_comment"
										formControlName="neck_extension_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="neck_extension_muscle_r"
										formControlName="neck_extension_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="neck_extension_muscle_l"
										formControlName="neck_extension_muscle_l" />
								</td>

							</tr>

							<!-- Rotation -->
							<tr>
								<td>Rotation</td>
								<td>0-80</td>
								<td>
									<input type="checkbox" value="intact" id="neck_rotation_passive_r"
										formControlName="neck_rotation_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="neck_rotation_passive_l"
										formControlName="neck_rotation_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="neck_rotation_active_r"
										formControlName="neck_rotation_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="neck_rotation_active_l"
										formControlName="neck_rotation_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="neck_rotation_comment"
										formControlName="neck_rotation_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="neck_rotation_muscle_r"
										formControlName="neck_rotation_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="neck_rotation_muscle_l"
										formControlName="neck_rotation_muscle_l" />
								</td>

							</tr>

							<!-- Side flex -->
							<tr>
								<td>Side flex</td>
								<td>0-45</td>
								<td>
									<input type="checkbox" value="intact" id="neck_sideFlex_passive_r"
										formControlName="neck_sideFlex_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="neck_sideFlex_passive_l"
										formControlName="neck_sideFlex_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="neck_sideFlex_active_r"
										formControlName="neck_sideFlex_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="neck_sideFlex_active_l"
										formControlName="neck_sideFlex_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="neck_sideFlex_comment"
										formControlName="neck_sideFlex_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="neck_sideFlex_muscle_r"
										formControlName="neck_sideFlex_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="neck_sideFlex_muscle_l"
										formControlName="neck_sideFlex_muscle_l" />
								</td>

							</tr>

							<!-- SHOULDER ROW -->
							<!-- Flexion -->
							<tr>
								<td scope="row" rowspan="6"> <label class="px-0 ">Shoulder</label></td>
								<td>Flexion</td>
								<td>0-180</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_flexion_passive_r"
										formControlName="shoulder_flexion_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_flexion_passive_l"
										formControlName="shoulder_flexion_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_flexion_active_r"
										formControlName="shoulder_flexion_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_flexion_active_l"
										formControlName="shoulder_flexion_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id=""
										formControlName="shoulder_flexion_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_flexion_muscle_r"
										formControlName="shoulder_flexion_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_flexion_muscle_l"
										formControlName="shoulder_flexion_muscle_l" />
								</td>

							</tr>

							<!-- Extension -->
							<tr>
								<td>Extension</td>
								<td>0-60</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_extension_passive_r"
										formControlName="shoulder_extension_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_extension_passive_l"
										formControlName="shoulder_extension_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_extension_active_r"
										formControlName="shoulder_extension_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_extension_active_l"
										formControlName="shoulder_extension_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="shoulder_extension_comment"
										formControlName="shoulder_extension_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_extension_muscle_r"
										formControlName="shoulder_extension_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_extension_muscle_l"
										formControlName="shoulder_extension_muscle_l" />
								</td>

							</tr>

							<!-- Abduction -->
							<tr>
								<td>Abduction</td>
								<td>0-180</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_abduction_passive_r"
										formControlName="shoulder_abduction_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_abduction_passive_l"
										formControlName="shoulder_abduction_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_abduction_active_r"
										formControlName="shoulder_abduction_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_abduction_active_l"
										formControlName="shoulder_abduction_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id=""
										formControlName="shoulder_abduction_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_abduction_muscle_r"
										formControlName="shoulder_abduction_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_abduction_muscle_l"
										formControlName="shoulder_abduction_muscle_l" />
								</td>

							</tr>

							<!-- Adduction -->
							<tr>
								<td>Adduction</td>
								<td>0-90</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_adduction_passive_r"
										formControlName="shoulder_adduction_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_adduction_passive_l"
										formControlName="shoulder_adduction_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_adduction_active_r"
										formControlName="shoulder_adduction_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_adduction_active_l"
										formControlName="shoulder_adduction_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="shoulder_adduction_comment"
										formControlName="shoulder_adduction_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_adduction_muscle_r"
										formControlName="shoulder_adduction_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_adduction_muscle_l"
										formControlName="shoulder_adduction_muscle_l" />
								</td>

							</tr>

							<!-- Internal Rotation -->
							<tr>
								<td>Internal Rotation</td>
								<td>0-70</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_internal_rotation_passive_r"
										formControlName="shoulder_internal_rotation_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_internal_rotation_passive_l"
										formControlName="shoulder_internal_rotation_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_internal_rotation_active_r"
										formControlName="shoulder_internal_rotation_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_internal_rotation_active_l"
										formControlName="shoulder_internal_rotation_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="shoulder_internal_rotation_comment"
										formControlName="shoulder_internal_rotation_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_internal_rotation_muscle_r"
										formControlName="shoulder_internal_rotation_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_internal_rotation_muscle_l"
										formControlName="shoulder_internal_rotation_muscle_l" />
								</td>

							</tr>

							<!-- External Rotation -->
							<tr>
								<td>External Rotation</td>
								<td>0-90</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_external_rotation_passive_r"
										formControlName="shoulder_external_rotation_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_external_rotation_passive_l"
										formControlName="shoulder_external_rotation_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_external_rotation_active_r"
										formControlName="shoulder_external_rotation_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_external_rotation_active_l"
										formControlName="shoulder_external_rotation_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="shoulder_external_rotation_comment"
										formControlName="shoulder_external_rotation_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_external_rotation_muscle_r"
										formControlName="shoulder_external_rotation_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="shoulder_external_rotation_muscle_l"
										formControlName="shoulder_external_rotation_muscle_l" />
								</td>

							</tr>

							<!-- ELBOW ROW-->
							<!-- Flexion -->
							<tr>
								<td scope="row" rowspan="4"> <label class="px-0 ">Elbow</label></td>
								<td>Flexion</td>
								<td>0-50</td>
								<td>
									<input type="checkbox" value="intact" id="elbow_flexion_passive_r"
										formControlName="elbow_flexion_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="elbow_flexion_passive_l"
										formControlName="elbow_flexion_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="elbow_flexion_active_r"
										formControlName="elbow_flexion_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="elbow_flexion_active_l"
										formControlName="elbow_flexion_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="elbow_flexion_comment"
										formControlName="elbow_flexion_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="elbow_flexion_muscle_r"
										formControlName="elbow_flexion_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="elbow_flexion_muscle_l"
										formControlName="elbow_flexion_muscle_l" />
								</td>

							</tr>

							<!-- Extension -->
							<tr>
								<td>Extension</td>
								<td>0-60</td>
								<td>
									<input type="checkbox" value="intact" id="elbow_extension_passive_r"
										formControlName="elbow_extension_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="elbow_extension_passive_l"
										formControlName="elbow_extension_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="elbow_extension_active_r"
										formControlName="elbow_extension_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="elbow_extension_active_l"
										formControlName="elbow_extension_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="elbow_extension_comment"
										formControlName="elbow_extension_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="elbow_extension_muscle_r"
										formControlName="elbow_extension_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="elbow_extension_muscle_l"
										formControlName="elbow_extension_muscle_l" />
								</td>

							</tr>

							<!-- Supination -->
							<tr>
								<td>Supination</td>
								<td>0-80</td>
								<td>
									<input type="checkbox" value="intact" id="elbow_supination_passive_r"
										formControlName="elbow_supination_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="elbow_supination_passive_l"
										formControlName="elbow_supination_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="elbow_supination_active_r"
										formControlName="elbow_supination_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="elbow_supination_active_l"
										formControlName="elbow_supination_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="elbow_supination_comment"
										formControlName="elbow_supination_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="elbow_supination_muscle_r"
										formControlName="elbow_supination_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="elbow_supination_muscle_l"
										formControlName="elbow_supination_muscle_l" />
								</td>

							</tr>

							<!-- Pronation -->
							<tr>
								<td>Pronation</td>
								<td>0-80</td>
								<td>
									<input type="checkbox" value="intact" id="elbow_pronation_passive_r"
										formControlName="elbow_pronation_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="elbow_pronation_passive_l"
										formControlName="elbow_pronation_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="elbow_pronation_active_r"
										formControlName="elbow_pronation_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="elbow_pronation_active_l"
										formControlName="elbow_pronation_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id=""
										formControlName="elbow_pronation_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="elbow_pronation_muscle_r"
										formControlName="elbow_pronation_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="elbow_pronation_muscle_l"
										formControlName="elbow_pronation_muscle_l" />
								</td>

							</tr>

							<!--WRIST ROW -->
							<!-- Flexion -->
							<tr>
								<td scope="row" rowspan="4"> <label class="px-0 ">Wrist</label></td>
								<td>Flexion</td>
								<td>0-50</td>
								<td>
									<input type="checkbox" value="intact" id="wrist_flexion_passive_r"
										formControlName="wrist_flexion_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="wrist_flexion_passive_l"
										formControlName="wrist_flexion_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="wrist_flexion_active_r"
										formControlName="wrist_flexion_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="wrist_flexion_active_l"
										formControlName="wrist_flexion_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="wrist_flexion_comment"
										formControlName="wrist_flexion_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="wrist_flexion_muscle_r"
										formControlName="wrist_flexion_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="wrist_flexion_muscle_l"
										formControlName="wrist_flexion_muscle_l" />
								</td>

							</tr>

							<!-- Extension -->
							<tr>
								<td>Extension</td>
								<td>0-70</td>
								<td>
									<input type="checkbox" value="intact" id="wrist_extension_passive_r"
										formControlName="wrist_extension_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="wrist_extension_passive_l"
										formControlName="wrist_extension_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="wrist_extension_active_r"
										formControlName="wrist_extension_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="wrist_extension_active_l"
										formControlName="wrist_extension_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id=""
										formControlName="wrist_extension_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="wrist_extension_muscle_r"
										formControlName="wrist_extension_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="wrist_extension_muscle_l"
										formControlName="wrist_extension_muscle_l" />
								</td>

							</tr>

							<!-- Ulnar Deviation -->
							<tr>
								<td>Ulnar Deviation</td>
								<td>0-30</td>
								<td>
									<input type="checkbox" value="intact" id="wrist_ulnar_deviation_passive_r"
										formControlName="wrist_ulnar_deviation_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="wrist_ulnar_deviation_passive_l"
										formControlName="wrist_ulnar_deviation_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="wrist_ulnar_deviation_active_r"
										formControlName="wrist_ulnar_deviation_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="wrist_ulnar_deviation_active_l"
										formControlName="wrist_ulnar_deviation_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="wrist_ulnar_deviation_comment"
										formControlName="wrist_ulnar_deviation_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="wrist_ulnar_deviation_muscle_r"
										formControlName="wrist_ulnar_deviation_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="wrist_ulnar_deviation_muscle_l"
										formControlName="wrist_ulnar_deviation_muscle_l" />
								</td>

							</tr>

							<!-- Radial Deviation -->
							<tr>
								<td> Radial Deviation</td>
								<td>0-20</td>
								<td>
									<input type="checkbox" value="intact" id="wrist_radial_deviation_passive_r"
										formControlName="wrist_radial_deviation_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="wrist_radial_deviation_passive_l"
										formControlName="wrist_radial_deviation_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="wrist_radial_deviation_active_r"
										formControlName="wrist_radial_deviation_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="wrist_radial_deviation_active_l"
										formControlName="wrist_radial_deviation_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="wrist_radial_deviation_comment"
										formControlName="wrist_radial_deviation_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="wrist_radial_deviation_muscle_r"
										formControlName="wrist_radial_deviation_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="wrist_radial_deviation_muscle_l"
										formControlName="wrist_radial_deviation_muscle_l" />
								</td>

							</tr>

							<!-- DIGITS ROW -->
							<!-- Thumb MCP -->
							<tr>
								<td scope="row" rowspan="5"> <label class="px-0 ">Digits</label></td>
								<td>Thumb MCP Ext/flex</td>
								<td>10/30</td>
								<td>
									<input type="checkbox" value="intact" id="digits_thump_passive_r"
										formControlName="digits_thump_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="digits_thump_passive_l"
										formControlName="digits_thump_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="digits_thump_active_r"
										formControlName="digits_thump_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="digits_thump_active_l"
										formControlName="digits_thump_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="digits_thump_comment"
										formControlName="digits_thump_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="digits_thump_muscle_r"
										formControlName="digits_thump_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="digits_thump_muscle_l"
										formControlName="digits_thump_muscle_l" />
								</td>

							</tr>

							<!-- Thump IP -->
							<tr>
								<td>Thump IP Ext/Flex</td>
								<td>0/80</td>
								<td>
									<input type="checkbox" value="intact" id="digits_thump_ip_passive_r"
										formControlName="digits_thump_ip_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="digits_thump_ip_passive_l"
										formControlName="digits_thump_ip_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="digits_thump_ip_active_r"
										formControlName="digits_thump_ip_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="digits_thump_ip_active_l"
										formControlName="digits_thump_ip_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="digits_thump_ip_comment"
										formControlName="digits_thump_ip_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="digits_thump_ip_muscle_r"
										formControlName="digits_thump_ip_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="digits_thump_ip_muscle_l"
										formControlName="digits_thump_ip_muscle_l" />
								</td>

							</tr>

							<!-- MCP Ext/Flex -->
							<tr>
								<td>MCP Ext/Flex</td>
								<td>0/90</td>
								<td>
									<input type="checkbox" value="intact" id="digits_mcp_passive_r"
										formControlName="digits_mcp_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="digits_mcp_passive_l"
										formControlName="digits_mcp_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="digits_mcp_active_r"
										formControlName="digits_mcp_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="digits_mcp_active_l"
										formControlName="digits_mcp_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="digits_mcp_comment"
										formControlName="digits_mcp_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="digits_mcp_muscle_r"
										formControlName="digits_mcp_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="digits_mcp_muscle_l"
										formControlName="digits_mcp_muscle_l" />
								</td>

							</tr>

							<!-- PIP Ext/Flex -->
							<tr>
								<td>PIP Ext/Flex</td>
								<td>0/100</td>
								<td>
									<input type="checkbox" value="intact" id="digits_pip_passive_r"
										formControlName="digits_pip_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="digits_pip_passive_l"
										formControlName="digits_pip_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="digits_pip_active_r"
										formControlName="digits_pip_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="digits_pip_active_l"
										formControlName="digits_pip_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="digits_pip_comment"
										formControlName="digits_pip_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="digits_pip_muscle_r"
										formControlName="digits_pip_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="digits_pip_muscle_l"
										formControlName="digits_pip_muscle_l" />
								</td>

							</tr>

							<!-- DIP Ext/Flex -->
							<tr>
								<td>DIP Ext/Flex</td>
								<td>0/90</td>
								<td>
									<input type="checkbox" value="intact" id="digits_dip_passive_r"
										formControlName="digits_dip_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="digits_dip_passive_l"
										formControlName="digits_dip_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="digits_dip_active_r"
										formControlName="digits_dip_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="digits_dip_active_l"
										formControlName="digits_dip_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="digits_dip_comment"
										formControlName="digits_dip_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="digits_dip_muscle_r"
										formControlName="digits_dip_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="digits_dip_muscle_l"
										formControlName="digits_dip_muscle_l" />
								</td>

							</tr>

							<!-- HIP ROW -->
							<!-- Flexion -->
							<tr>
								<td scope="row" rowspan="6"> <label class="px-0 ">Hip</label></td>
								<td>Flexion</td>
								<td>0-120</td>
								<td>
									<input type="checkbox" value="intact" id="hip_flexion_passive_r"
										formControlName="hip_flexion_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_flexion_passive_l"
										formControlName="hip_flexion_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_flexion_active_r"
										formControlName="hip_flexion_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_flexion_active_l"
										formControlName="hip_flexion_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="hip_flexion_comment"
										formControlName="hip_flexion_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_flexion_muscle_r"
										formControlName="hip_flexion_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_flexion_muscle_l"
										formControlName="hip_flexion_muscle_l" />
								</td>

							</tr>

							<!-- Extension -->
							<tr>
								<td>Extension</td>
								<td>0-20</td>
								<td>
									<input type="checkbox" value="intact" id="hip_extension_passive_r"
										formControlName="hip_extension_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_extension_passive_l"
										formControlName="hip_extension_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_extension_active_r"
										formControlName="hip_extension_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_extension_active_l"
										formControlName="hip_extension_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="hip_extension_comment"
										formControlName="hip_extension_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_extension_muscle_r"
										formControlName="hip_extension_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_extension_muscle_l"
										formControlName="hip_extension_muscle_l" />
								</td>

							</tr>

							<!-- Abduction -->
							<tr>
								<td>Abduction</td>
								<td>0-45</td>
								<td>
									<input type="checkbox" value="intact" id="hip_abduction_passive_r"
										formControlName="hip_abduction_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_abduction_passive_l"
										formControlName="hip_abduction_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_abduction_active_r"
										formControlName="hip_abduction_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_abduction_active_l"
										formControlName="hip_abduction_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="hip_abduction_comment"
										formControlName="hip_abduction_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_abduction_muscle_r"
										formControlName="hip_abduction_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_abduction_muscle_l"
										formControlName="hip_abduction_muscle_l" />
								</td>

							</tr>

							<!--Adduction-->
							<tr>
								<td>Adduction</td>
								<td>0-20</td>
								<td>
									<input type="checkbox" value="intact" id="hip_adduction_passive_r"
										formControlName="hip_adduction_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_adduction_passive_l"
										formControlName="hip_adduction_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_adduction_active_r"
										formControlName="hip_adduction_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_adduction_active_l"
										formControlName="hip_adduction_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="hip_adduction_comment"
										formControlName="hip_adduction_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_adduction_muscle_r"
										formControlName="hip_adduction_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_adduction_muscle_l"
										formControlName="hip_adduction_muscle_l" />
								</td>

							</tr>

							<!--Internal Rotation-->
							<tr>
								<td>Internal Rotation</td>
								<td>0-45</td>
								<td>
									<input type="checkbox" value="intact" id="hip_internal_rotation_passive_r"
										formControlName="hip_internal_rotation_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_internal_rotation_passive_l"
										formControlName="hip_internal_rotation_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_internal_rotation_active_r"
										formControlName="hip_internal_rotation_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_internal_rotation_active_l"
										formControlName="hip_internal_rotation_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="hip_internal_rotation_comment"
										formControlName="hip_internal_rotation_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_internal_rotation_muscle_r"
										formControlName="hip_internal_rotation_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_internal_rotation_muscle_l"
										formControlName="hip_internal_rotation_muscle_l" />
								</td>

							</tr>

							<!--External Rotation-->
							<tr>
								<td>External Rotation</td>
								<td>0-45</td>
								<td>
									<input type="checkbox" value="intact" id="hip_external_rotation_passive_r"
										formControlName="hip_external_rotation_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_external_rotation_passive_l"
										formControlName="hip_external_rotation_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_external_rotation_active_r"
										formControlName="hip_external_rotation_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_external_rotation_active_l"
										formControlName="hip_external_rotation_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="hip_external_rotation_comment"
										formControlName="hip_external_rotation_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_external_rotation_muscle_r"
										formControlName="hip_external_rotation_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="hip_external_rotation_muscle_l"
										formControlName="hip_external_rotation_muscle_l" />
								</td>

							</tr>

							<!-- KNEE ROW -->
							<!-- Flexion -->
							<tr>
								<td scope="row" rowspan="2"> <label class=" px-0">Knee</label></td>
								<td>Flexion</td>
								<td>0-135</td>
								<td>
									<input type="checkbox" value="intact" id="knee_flexion_passive_r"
										formControlName="knee_flexion_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="knee_flexion_passive_l"
										formControlName="knee_flexion_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="knee_flexion_active_r"
										formControlName="knee_flexion_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="knee_flexion_active_l"
										formControlName="knee_flexion_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="knee_flexion_comment"
										formControlName="knee_flexion_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="knee_flexion_muscle_r"
										formControlName="knee_flexion_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="knee_flexion_muscle_l"
										formControlName="knee_flexion_muscle_l" />
								</td>

							</tr>

							<!-- Extension -->
							<tr>
								<td>Extension</td>
								<td>135-0</td>
								<td>
									<input type="checkbox" value="intact" id="knee_extension_passive_r"
										formControlName="knee_extension_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="knee_extension_passive_l"
										formControlName="knee_extension_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="knee_extension_active_r"
										formControlName="knee_extension_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="knee_extension_active_l"
										formControlName="knee_extension_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="knee_extension_comment"
										formControlName="knee_extension_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="knee_extension_muscle_r"
										formControlName="knee_extension_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="knee_extension_muscle_l"
										formControlName="knee_extension_muscle_l" />
								</td>
							</tr>

							<!-- ANKLE ROW -->
							<!-- PF -->
							<tr>
								<td scope="row" rowspan="6"> <label class=" px-0">Ankle</label></td>
								<td>PF</td>
								<td>0-45</td>
								<td>
									<input type="checkbox" value="intact" id="ankle_pf_passive_r"
										formControlName="ankle_pf_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="ankle_pf_passive_l"
										formControlName="ankle_pf_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="ankle_pf_active_r"
										formControlName="ankle_pf_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="ankle_pf_active_l"
										formControlName="ankle_pf_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="ankle_pf_comment"
										formControlName="ankle_pf_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="ankle_pf_muscle_r"
										formControlName="ankle_pf_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="ankle_pf_muscle_l"
										formControlName="ankle_pf_muscle_l" />
								</td>

							</tr>

							<!-- DF -->
							<tr>
								<td>DF</td>
								<td>0-20</td>
								<td>
									<input type="checkbox" value="intact" id="ankle_df_passive_r"
										formControlName="ankle_df_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="ankle_df_passive_l"
										formControlName="ankle_df_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="ankle_df_active_r"
										formControlName="ankle_df_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="ankle_df_active_l"
										formControlName="ankle_df_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="ankle_df_comment"
										formControlName="ankle_df_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="ankle_df_muscle_r"
										formControlName="ankle_df_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="ankle_df_muscle_l"
										formControlName="ankle_df_muscle_l" />
								</td>
							</tr>

							<!-- Inversion -->
							<tr>
								<td>Inversion</td>
								<td>0-35</td>
								<td>
									<input type="checkbox" value="intact" id="knee_inversion_passive_r"
										formControlName="knee_inversion_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="knee_inversion_passive_l"
										formControlName="knee_inversion_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="knee_inversion_active_r"
										formControlName="knee_inversion_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="knee_inversion_active_l"
										formControlName="knee_inversion_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="knee_inversion_comment"
										formControlName="knee_inversion_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="knee_inversion_muscle_r"
										formControlName="knee_inversion_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="knee_inversion_muscle_l"
										formControlName="knee_inversion_muscle_l" />
								</td>

							</tr>

							<!-- Eversion -->
							<tr>
								<td>Eversion</td>
								<td>0-15</td>
								<td>
									<input type="checkbox" value="intact" id="knee_eversion_passive_r"
										formControlName="knee_eversion_passive_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="knee_eversion_passive_l"
										formControlName="knee_eversion_passive_l" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="knee_eversion_active_r"
										formControlName="knee_eversion_active_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="knee_eversion_active_l"
										formControlName="knee_eversion_active_l" />
								</td>
								<td>
									<input type="text" class="form-control px-2" id="knee_eversion_comment"
										formControlName="knee_eversion_comment" placeholder="Comment" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="knee_eversion_muscle_r"
										formControlName="knee_eversion_muscle_r" />
								</td>
								<td>
									<input type="checkbox" value="intact" id="knee_eversion_muscle_l"
										formControlName="knee_eversion_muscle_l" />
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div class="row mt-3">
					<!-- Head Control -->
					<div class="col-sm-4 col-6">
						<div class="form-group">
							<label class="mr-3 px-0 ">Head Control:</label>
							<p class="d-flex">
								<input type="radio" id="head_control_yes" value="Yes" formControlName="head_control" />
								<label for="head_control_yes" class="mb-0 mx-2">Yes</label>
							</p>
							<p class="d-flex">
								<input type="radio" id="head_control_no" value="No" formControlName="head_control" />
								<label for="head_control_no" class="mb-0 mx-2">No</label>
							</p>
						</div>
					</div>

					<!-- Trunk Control -->
					<div class="col-sm-4 col-6">
						<div class="form-group mb-0">
							<label class="mr-3 px-0 ">Trunk Control:</label>
							<p class="d-flex">
								<input type="radio" id="trunk_control_yes" value="Yes"
									formControlName="trunk_control" />
								<label for="trunk_control_yes" class="mb-0 mx-2">Yes</label>
							</p>
							<p class="d-flex">
								<input type="radio" id="trunk_control_no" value="No" formControlName="trunk_control" />
								<label for="trunk_control_no" class="mb-0 mx-2">No</label>
							</p>
						</div>
					</div>
				</div>
			</div>

			<!-- MOBILITY & BALANCE -->
			<div class="add-listings-box pb-3">
				<h3>
					Mobility and Balance
				</h3>
				<div class="row subHeading">
					<div class="col-sm-3 col-6">I - Independent</div>
					<div class="col-sm-3 col-6">A - Assistance</div>
					<div class="col-sm-3 col-6">D - Dependent</div>
					<div class="col-sm-3 col-6">N - Not Tested</div>
				</div>
				<div class="form-group mb-0 table-responsive">
					<table class="table">
						<thead>
							<tr class="border-light">
								<th scope="col">
									<label class="subHeading px-0 ">Activity</label>
								</th>
								<th scope="col">
									<label class="subHeading px-0">I</label>
								</th>
								<th scope="col">
									<label class="subHeading px-0">A</label>
								</th>
								<th scope="col">
									<label class="subHeading px-0">D</label>
								</th>
								<th scope="col">
									<label class="subHeading px-0">N</label>
								</th>
								<th scope="col">
									<label class="subHeading px-0">Remark</label>
								</th>
							</tr>
						</thead>

						<tbody formGroupName="mobility_balance">
							<tr class="border-light" style="white-space: nowrap;">
								<td scope="row">
									<label class="px-0 ">Rolling</label>
								</td>
								<td>
									<input type="radio" value="1" id="rolling_I" formControlName="rolling_I"
										class="p-radiobutton" (change)="handleInput($event, 'rolling')">

								</td>
								<td>
									<input type="radio" value="2" id="rolling_A" formControlName="rolling_I"
										class="p-radiobutton" (change)="handleInput($event, 'rolling')">
								</td>
								<td>
									<input type="radio" value="3" id="rolling_D" formControlName="rolling_I"
										class="p-radiobutton" (change)="handleInput($event, 'rolling')">
								</td>
								<td>
									<input type="radio" value="4" id="rolling_N" formControlName="rolling_I"
										class="p-radiobutton" (change)="handleInput($event, 'rolling')">
								</td>
								<td>
									<input type="text" class="form-control px-2 remark" id="rolling_remark"
										placeholder="Remark" formControlName="rolling_remark" [class.is-invalid]="(assessment.submitted &&
										ptAssessment.get([ 'mobility_balance','rolling_remark'])?.invalid)
										||
										ptAssessment.get(['mobility_balance','rolling_remark'])?.invalid
										&&
										ptAssessment.get([ 'mobility_balance','rolling_remark']).touched">
									<div class="invalid-feedback">Rolling remark is required</div>
								</td>
							</tr>
							<tr class="border-light" style="white-space: nowrap;">
								<td scope="row">
									<label class=" px-0">Sitting</label>
								</td>
								<td>
									<input type="radio" value="1" id="sitting_I" formControlName="sitting_I"
										class="p-radiobutton" (change)="handleInput($event, 'sitting')">
								</td>
								<td>
									<input type="radio" value="2" id="sitting_A" formControlName="sitting_I"
										class="p-radiobutton" (change)="handleInput($event, 'sitting')">
								</td>
								<td>
									<input type="radio" value="3" id="sitting_D" formControlName="sitting_I"
										class="p-radiobutton" (change)="handleInput($event, 'sitting')">
								</td>
								<td>
									<input type="radio" value="4" id="sitting_N" formControlName="sitting_I"
										class="p-radiobutton" (change)="handleInput($event, 'sitting')">
								</td>
								<td>
									<input type="text" id="sitting_remark remark" class="form-control px-2"
										placeholder="Remark" formControlName="sitting_remark" [class.is-invalid]="(assessment.submitted &&
										ptAssessment.get([ 'mobility_balance','sitting_remark'])?.invalid)
										||
										ptAssessment.get(['mobility_balance','sitting_remark'])?.invalid
										&&
										ptAssessment.get([ 'mobility_balance','sitting_remark']).touched">
									<div class="invalid-feedback">Sitting remark is required</div>
								</td>
							</tr>
							<tr class="border-light" style="white-space: nowrap;">
								<td scope="row">
									<label class="px-0 ">Standing</label>
								</td>
								<td>
									<input type="radio" value="1" id="standing_I" formControlName="standing_I"
										class="p-radiobutton" (change)="handleInput($event, 'standing')">
								</td>
								<td>
									<input type="radio" value="2" id="standing_A" formControlName="standing_I"
										class="p-radiobutton" (change)="handleInput($event, 'standing')">
								</td>
								<td>
									<input type="radio" value="3" id="standing_D" formControlName="standing_I"
										class="p-radiobutton" (change)="handleInput($event, 'standing')">
								</td>
								<td>
									<input type="radio" value="4" id="standing_N" formControlName="standing_I"
										class="p-radiobutton" (change)="handleInput($event, 'standing')">
								</td>
								<td>
									<input type="text" class="form-control px-2 remark" id="standing_remark"
										placeholder="Remark" formControlName="standing_remark" [class.is-invalid]="(assessment.submitted &&
										ptAssessment.get([ 'mobility_balance','standing_remark'])?.invalid)
										||
										ptAssessment.get(['mobility_balance','standing_remark'])?.invalid
										&&
										ptAssessment.get([ 'mobility_balance','standing_remark']).touched">
									<div class="invalid-feedback">Standing remark is required</div>
								</td>
							</tr>
							<tr class="border-light" style="white-space: nowrap;">
								<td scope="row">
									<label class="px-0 ">Gait</label>
								</td>
								<td>
									<input type="radio" value="1" id="gait_I" formControlName="gait_I"
										class="p-radiobutton" (change)="handleInput($event, 'gait')">
								</td>
								<td>
									<input type="radio" value="2" id="gait_A" formControlName="gait_I"
										class="p-radiobutton" (change)="handleInput($event, 'gait')">
								</td>
								<td>
									<input type="radio" value="3" id="gait_D" formControlName="gait_I"
										class="p-radiobutton" (change)="handleInput($event, 'gait')">
								</td>
								<td>
									<input type="radio" value="4" id="gait_N" formControlName="gait_I"
										class="p-radiobutton" (change)="handleInput($event, 'gait')">
								</td>
								<td>
									<input type="text" class="form-control px-2 remark" id="gait_remark"
										placeholder="Remark" formControlName="gait_remark" [class.is-invalid]="(assessment.submitted &&
										ptAssessment.get([ 'mobility_balance','gait_remark'])?.invalid)
										||
										ptAssessment.get(['mobility_balance','gait_remark'])?.invalid
										&&
										ptAssessment.get([ 'mobility_balance','gait_remark']).touched">
									<div class="invalid-feedback">Gait remark is required</div>
								</td>
							</tr>
							<tr class="border-light" style="white-space: nowrap;">
								<td scope="row">
									<label class=" px-0">Balance</label>
								</td>
								<td>
									<input type="radio" value="1" id="balance_I" formControlName="balance_I"
										class="p-radiobutton" (change)="handleInput($event, 'balance')">
								</td>
								<td>
									<input type="radio" value="2" id="balance_A" formControlName="balance_I"
										class="p-radiobutton" (change)="handleInput($event, 'balance')">
								</td>
								<td>
									<input type="radio" value="3" id="balance_D" formControlName="balance_I"
										class="p-radiobutton" (change)="handleInput($event, 'balance')">
								</td>
								<td>
									<input type="radio" value="4" id="balance_N" formControlName="balance_I"
										class="p-radiobutton" (change)="handleInput($event, 'balance')">
								</td>
								<td>
									<input type="text" class="form-control px-2 remark" id="balance_remark"
										placeholder="Remark" formControlName="balance_remark" [class.is-invalid]="(assessment.submitted &&
										ptAssessment.get([ 'mobility_balance','balance_remark'])?.invalid)
										||
										ptAssessment.get(['mobility_balance','balance_remark'])?.invalid
										&&
										ptAssessment.get([ 'mobility_balance','balance_remark']).touched">
									<div class="invalid-feedback">Balance remark is required</div>
								</td>
							</tr>
							<tr class="border-light" style="white-space: nowrap;">
								<td scope="row">
									<label class=" px-0">Static Sitting</label>
								</td>
								<td>
									<input type="radio" value="1" id="static_sitting_I" class="p-radiobutton"
										formControlName="static_sitting_I" (change)="handleInput($event, 'static')">
								</td>
								<td>
									<input type="radio" value="2" id="static_sitting_A" class="p-radiobutton"
										formControlName="static_sitting_I" (change)="handleInput($event, 'static')">
								</td>
								<td>
									<input type="radio" value="3" id="static_sitting_D" class="p-radiobutton"
										formControlName="static_sitting_I" (change)="handleInput($event, 'static')">
								</td>
								<td>
									<input type="radio" value="4" id="static_sitting_N" class="p-radiobutton"
										formControlName="static_sitting_I" (change)="handleInput($event, 'static')">
								</td>
								<td>
									<input type="text" class="form-control px-2 remark" placeholder="Remark"
										id="static_sitting_remark" formControlName="static_sitting_remark"
										[class.is-invalid]="(assessment.submitted &&
										ptAssessment.get([ 'mobility_balance','static_sitting_remark'])?.invalid)
										||
										ptAssessment.get(['mobility_balance','static_sitting_remark'])?.invalid
										&&
										ptAssessment.get([ 'mobility_balance','static_sitting_remark']).touched">
									<div class="invalid-feedback">Static sitting remark is required</div>
								</td>
							</tr>
							<tr class="border-light" style="white-space: nowrap;">
								<td scope="row">
									<label class="px-0 ">Static Standing</label>
								</td>
								<td>
									<input type="radio" value="1" id="static_standing_I"
										formControlName="static_standing_I" class="p-radiobutton"
										(change)="handleInput($event, 'standingstatic')">
								</td>
								<td>
									<input type="radio" value="2" id="static_standing_A"
										formControlName="static_standing_I" class="p-radiobutton"
										(change)="handleInput($event, 'standingstatic')">
								</td>
								<td>
									<input type="radio" value="3" id="static_standing_D"
										formControlName="static_standing_I" class="p-radiobutton"
										(change)="handleInput($event, 'standingstatic')">
								</td>
								<td>
									<input type="radio" value="4" id="static_standing_N"
										formControlName="static_standing_I" class="p-radiobutton"
										(change)="handleInput($event, 'standingstatic')">
								</td>
								<td>
									<input type="text" class="form-control px-2 remark" id="static_standing_remark"
										placeholder="Remark" formControlName="static_standing_remark"
										[class.is-invalid]="(assessment.submitted &&
										ptAssessment.get([ 'mobility_balance','static_standing_remark'])?.invalid)
										||
										ptAssessment.get(['mobility_balance','static_standing_remark'])?.invalid
										&&
										ptAssessment.get([ 'mobility_balance','static_standing_remark']).touched">
									<div class="invalid-feedback">Static standing remark is required</div>
								</td>
							</tr>
							<tr class="border-light" style="white-space: nowrap;">
								<td scope="row">
									<label class="px-0 ">Dynamic Sitting</label>
								</td>
								<td>
									<input type="radio" value="1" id="dynamic_sitting_I" class="p-radiobutton"
										class="p-radiobutton" formControlName="dynamic_sitting_I"
										(change)="handleInput($event, 'dynamic')">
								</td>
								<td>
									<input type="radio" value="2" id="dynamic_sitting_A" class="p-radiobutton"
										class="p-radiobutton" formControlName="dynamic_sitting_I"
										(change)="handleInput($event, 'dynamic')">
								</td>
								<td>
									<input type="radio" value="3" id="dynamic_sitting_D" class="p-radiobutton"
										class="p-radiobutton" formControlName="dynamic_sitting_I"
										(change)="handleInput($event, 'dynamic')">
								</td>
								<td>
									<input type="radio" value="4" id="dynamic_sitting_N" class="p-radiobutton"
										class="p-radiobutton" formControlName="dynamic_sitting_I"
										(change)="handleInput($event, 'dynamic')">
								</td>
								<td>
									<input type="text" class="form-control px-2 remark" id="dynamic_sitting_remark"
										placeholder="Remark" formControlName="dynamic_sitting_remark"
										[class.is-invalid]="(assessment.submitted &&
										ptAssessment.get([ 'mobility_balance','dynamic_sitting_remark'])?.invalid)
										||
										ptAssessment.get(['mobility_balance','dynamic_sitting_remark'])?.invalid
										&&
										ptAssessment.get([ 'mobility_balance','dynamic_sitting_remark']).touched">
									<div class="invalid-feedback">Dynamic sitting remark is required</div>
								</td>
							</tr>
							<tr class="border-light" style="white-space: nowrap;">
								<td scope="row">
									<label class="px-0 ">Dynamic Standing</label>
								</td>
								<td>
									<input type="radio" value="1" id="dynamic_standing_I"
										formControlName="dynamic_standing_I" class="p-radiobutton"
										(change)="handleInput($event, 'dynamic standing')">
								</td>
								<td>
									<input type="radio" value="2" id="dynamic_standing_A"
										formControlName="dynamic_standing_I" class="p-radiobutton"
										(change)="handleInput($event, 'dynamic standing')">
								</td>
								<td>
									<input type="radio" value="3" id="dynamic_standing_D"
										formControlName="dynamic_standing_I" class="p-radiobutton"
										(change)="handleInput($event, 'dynamic standing')">
								</td>
								<td>
									<input type="radio" value="4" id="dynamic_standing_N"
										formControlName="dynamic_standing_I" class="p-radiobutton"
										(change)="handleInput($event, 'dynamic standing')">
								</td>
								<td>
									<input type="text" class="form-control px-2 remark" placeholder="Remark"
										id="dynamic_standing_remark" formControlName="dynamic_standing_remark"
										[class.is-invalid]="(assessment.submitted &&
										ptAssessment.get([ 'mobility_balance','dynamic_standing_remark'])?.invalid)
										||
										ptAssessment.get(['mobility_balance','dynamic_standing_remark'])?.invalid
										&&
										ptAssessment.get([ 'mobility_balance','dynamic_standing_remark']).touched">
									<div class="invalid-feedback">Dynamic standing remark is required</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>

			</div>


			<!-- Tranfer and Assisting Devices -->
			<div class="add-listings-box" formGroupName="transfer_assisting">
				<h3>
					Transfer and Assisting Devices:
				</h3>
				<div class="form-group">
					<div class="row mt-3">
						<!-- Head Control -->
						<label class="checkbox checkbox-outline col-12">
							<input type="radio" value="1" id="transfer_assisting_none"
								formControlName="transfer_assisting_none" name="transfer_assisting"
								(change)="handleInput($event, 'transferassisting')">
							None
						</label>
						<label class="checkbox checkbox-outline col-lg-2 col-sm-3 col-5">
							<input type="radio" value="2" id="transfer_assisting_crutches"
								formControlName="transfer_assisting_none" name="transfer_assisting"
								(change)="handleInput($event, 'transferassisting')" [class.is-invalid]="(assessment.submitted &&
								ptAssessment.get([ 'transfer_assisting','transfer_assisting_pair'])?.invalid)
								||
								ptAssessment.get(['transfer_assisting','transfer_assisting_pair'])?.invalid
								&&
								ptAssessment.get([ 'transfer_assisting','transfer_assisting_pair']).touched">
							Crutches
							<div class="invalid-feedback">Crutches is required</div>
						</label>

						<label class="checkbox checkbox-outline col-lg-2 col-sm-3 col">
							<input type="radio" value="One" id="transfer_assisting_none"
								formControlName="transfer_assisting_pair">
							One
						</label>
						<label class="checkbox checkbox-outline col-lg-2 col-sm-3 col">
							<input type="radio" value="Pair" id="transfer_assisting_pair"
								formControlName="transfer_assisting_pair">
							Pair
						</label>
						<label class="checkbox checkbox-outline col-12">
							<input type="radio" value="3" id="transfer_assisting_walker"
								formControlName="transfer_assisting_none" name="transfer_assisting"
								(change)="handleInput($event, 'transferassisting')">
							Walker
						</label>
						<label class="checkbox checkbox-outline col-12">
							<input type="radio" value="4" id="transfer_assisting_wheel_chair"
								formControlName="transfer_assisting_none" name="transfer_assisting"
								(change)="handleInput($event, 'transferassisting')">
							Wheel Chair
						</label>
						<label class="checkbox checkbox-outline col-lg-2 col-sm-3 col-4">
							<input type="radio" value="5" id="transfer_assisting_others"
								formControlName="transfer_assisting_others" name="transfer_assisting"
								(change)="handleInput($event, 'transferassisting')">
							Others
						</label>
						<div class="col-lg-10 col-sm-9 col-8">
							<input type="text" class="form-control px-2" id="transfer_assisting_others_description"
								placeholder="Others" formControlName="transfer_assisting_others_description"
								[class.is-invalid]="(assessment.submitted &&
								ptAssessment.get([ 'transfer_assisting','transfer_assisting_others_description'])?.invalid)
								||
								ptAssessment.get(['transfer_assisting','transfer_assisting_others_description'])?.invalid
								&&
								ptAssessment.get([ 'transfer_assisting','transfer_assisting_others_description']).touched">
							<div class="invalid-feedback">Other devices is required</div>
						</div>

					</div>
				</div>


				<div class="form-group">
					<div class="row">
						<div class="col-sm-6 col-12">
							<label class="subHeading p-0">Problems Noted:</label>
							<textarea [disabled] formControlName="problems_noted" placeholder="Problem Note"
								class="form-control px-2" id="problems_noted" rows="3"></textarea>
						</div>


						<div class="col-sm-6 col-12">
							<label class="subHeading p-0 my-3">Session Goals:</label>
							<div class="row">
								<div class="col-sm-5 col-12">
									<div class="form-group mb-0">

										<label class="checkbox checkbox-outline px-0">
											<input type="checkbox" value="Yes" id="short_term"
												formControlName="short_term" (change)="handleInput($event, 'short')">
											Short Term(1 Month)
										</label>
									</div>
								</div>
								<div class="col-sm-7 col-12">
									<div class="form-group">
										<input type="text" class="w-100 form-control" value="Yes"
											id="short_term_session" formControlName="short_term_session"
											placeholder="Short term session" [class.is-invalid]="(assessment.submitted &&
											ptAssessment.get([ 'transfer_assisting','short_term_session'])?.invalid)
											||
											ptAssessment.get(['transfer_assisting','short_term_session'])?.invalid
											&&
											ptAssessment.get([ 'transfer_assisting','short_term_session']).touched">
										<div class="invalid-feedback">Short term session is required</div>
									</div>


								</div>


								<div class="col-sm-5 col-12">
									<div class="form-group mb-0">
										<label class="checkbox checkbox-outline px-0 ">
											<input type="checkbox" value="Yes" id="long_term"
												formControlName="long_term" (change)="handleInput($event, 'long')">
											Long Term(3-6 Month)
										</label>

									</div>
								</div>


								<div class="col-sm-7 col-12">
									<div class="form-group">
										<input type="text" class="w-100 form-control" value="intact"
											formControlName="long_term_session" id="long_term_session"
											placeholder="Long term session" [class.is-invalid]="(assessment.submitted &&
											ptAssessment.get([ 'transfer_assisting','long_term_session'])?.invalid)
											||
											ptAssessment.get(['transfer_assisting','long_term_session'])?.invalid
											&&
											ptAssessment.get([ 'transfer_assisting','long_term_session']).touched">
										<div class="invalid-feedback">Long term session is required</div>
									</div>

								</div>
							</div>
						</div>


						<!-- scope of session -->
						<div class="col-sm-6 col-12">
							<div class="form-group">
								<label class="subHeading p-0">Scope of Session:</label>
								<p-dropdown [options]="sessionList" [filter]="true" [resetFilterOnHide]="true"
									optionValue="id" class="data-padding" optionLabel="session_title"
									filterBy="session_title" formControlName="scope_session"
									placeholder="Scope of session" id="scope_session">
								</p-dropdown>
							</div>
						</div>


						<!-- Recommended duration -->
						<div class="col-sm-6 col-12">
							<div class="form-group">
								<label class="subHeading p-0">
									Recommended Duration:
								</label>
								<p-dropdown [options]="recomended_duration" [filter]="true" [resetFilterOnHide]="true"
									optionValue="id" optionLabel="name" filterBy="name"
									formControlName="recommended_duration" id="recommended_duration"
									placeholder="Recommended duration">
								</p-dropdown>
							</div>
						</div>


						<!-- No. of PT Sessions per Month -->
						<div class="col-sm-6 col-12">
							<div class="form-group">
								<label class="subHeading p-0">
									No. of PT Sessions per Month:
								</label>
								<p-dropdown [options]="session_per_month" [filter]="true" [resetFilterOnHide]="true"
									optionValue="session_per_month" optionLabel="session_per_month"
									filterBy="session_per_month" id="no_pt_sesssion_per_month"
									formControlName="no_pt_sesssion_per_month" placeholder="No of PT session per month">
								</p-dropdown>
							</div>
						</div>


						<!-- Goals to be acheived -->
						<div class="col-sm-6 col-12">
							<div class="form-group">
								<label class="subHeading p-0">
									Goals to be Acheived:
								</label>
								<textarea formControlName="goals_acheived" placeholder="Goals to be acheived"
									class="form-control px-2" id="goals_acheived" rows="3"></textarea>
							</div>
						</div>
					</div>
				</div>
			</div>


			<!-- Sessions Technique -->
			<div class="add-listings-box">
				<h3>Sessions Technique:</h3>
				<div class="form-group" formGroupName="session_technique">
					<!-- Exercise (ROM) -->
					<div class="row">
						<div class="col-md-2 col-sm-3 col-12">
							<div class="form-group mb-0">
								<label class="checkbox checkbox-outline px-0">
									<input type="checkbox" value="sterilizeAll" id="exercise_checkbox"
										formControlName="exercise_checkbox" (change)="handleInput($event, 'exercise')">
									Exercise(ROM)
								</label>
							</div>
						</div>
						<div class="col-md-5 col-sm col-12">
							<p-dropdown [options]="duration" [filter]="true" [resetFilterOnHide]="true"
								optionValue="duration" optionLabel="duration" filterBy="duration" id="exercise_duration"
								formControlName="exercise_duration" placeholder="Duration" [class.is-invalid]="(assessment.submitted &&
								ptAssessment.get([ 'session_technique','exercise_duration'])?.invalid)
								||
								ptAssessment.get(['session_technique','exercise_duration'])?.invalid
								&&
								ptAssessment.get([ 'session_technique','exercise_duration']).touched">
							</p-dropdown>
							<div class="invalid-feedback">Exercise duration is required</div>
						</div>
						<div class="col-md-5 col-sm col-12 mt-sm-0 mt-2">
							<div class="form-group">
								<input type="text" class="w-100 form-control" value="intact" id="exercise_details"
									formControlName="exercise_details" placeholder="Details" [class.is-invalid]="(assessment.submitted &&
									ptAssessment.get([ 'session_technique','exercise_details'])?.invalid)
									||
									ptAssessment.get(['session_technique','exercise_details'])?.invalid
									&&
									ptAssessment.get([ 'session_technique','exercise_details']).touched">
								<div class="invalid-feedback">Exercise duration is required</div>
							</div>
						</div>


					</div>


					<!-- TENS -->
					<div class="row">
						<div class="col-md-2 col-sm-3 col-12">
							<div class="form-group mb-0">
								<label class="checkbox checkbox-outline px-0">
									<input type="checkbox" value="sterilizeAll" id="tens_checkbox"
										formControlName="tens_checkbox" (change)="handleInput($event, 'ten')">
									TENS
								</label>
							</div>
						</div>
						<div class="col-md-5 col-sm col-12">
							<p-dropdown [options]="duration" [filter]="true" [resetFilterOnHide]="true"
								optionValue="duration" optionLabel="duration" filterBy="duration"
								formControlName="tens_duration" id="tens_duration" placeholder="Duration"
								[class.is-invalid]="(assessment.submitted &&
								ptAssessment.get([ 'session_technique','tens_duration'])?.invalid)
								||
								ptAssessment.get(['session_technique','tens_duration'])?.invalid
								&&
								ptAssessment.get([ 'session_technique','tens_duration']).touched">
							</p-dropdown>
							<div class="invalid-feedback">Tens duration is required</div>
						</div>
						<div class="col-md-5 col-sm col-12 mt-sm-0 mt-2">
							<div class="form-group">
								<input type="text" class="w-100 form-control" value="intact" id="tens_details"
									formControlName="tens_details" placeholder="Details" [class.is-invalid]="(assessment.submitted &&
									ptAssessment.get([ 'session_technique','tens_details'])?.invalid)
									||
									ptAssessment.get(['session_technique','tens_details'])?.invalid
									&&
									ptAssessment.get([ 'session_technique','tens_details']).touched">
								<div class="invalid-feedback">Tens detail is required</div>
							</div>
						</div>


					</div>


					<!-- Altrasound -->
					<div class="row">
						<div class="col-md-2 col-sm-3 col-12">
							<div class="form-group mb-0">
								<label class="checkbox checkbox-outline px-0">
									<input type="checkbox" value="sterilizeAll" id="ultrasound_checkbox"
										formControlName="ultrasound_checkbox" (change)="handleInput($event, 'sound')">
									Ultrasound
								</label>
							</div>
						</div>
						<div class="col-md-5 col-sm col-12">
							<p-dropdown [options]="duration" [filter]="true" [resetFilterOnHide]="true"
								optionValue="duration" optionLabel="duration" filterBy="duration"
								formControlName="ultrasound_duration" id="ultrasound_duration" placeholder="Duration"
								[class.is-invalid]="(assessment.submitted &&
								ptAssessment.get([ 'session_technique','ultrasound_duration'])?.invalid)
								||
								ptAssessment.get(['session_technique','ultrasound_duration'])?.invalid
								&&
								ptAssessment.get([ 'session_technique','ultrasound_duration']).touched">
							</p-dropdown>
							<div class="invalid-feedback">Ultrasound duration is required</div>
						</div>
						<div class="col-md-5 col-sm col-12 mt-sm-0 mt-2">
							<div class="form-group">
								<input type="text" class="w-100 form-control" value="intact"
									formControlName="ultrasound_details" id="ultrasound_details" placeholder="Details"
									[class.is-invalid]="(assessment.submitted &&
									ptAssessment.get([ 'session_technique','ultrasound_details'])?.invalid)
									||
									ptAssessment.get(['session_technique','ultrasound_details'])?.invalid
									&&
									ptAssessment.get([ 'session_technique','ultrasound_details']).touched">
								<div class="invalid-feedback">Ultrasound details is required</div>
							</div>
						</div>


					</div>


					<!-- Hot Packs -->
					<div class="row">
						<div class="col-md-2 col-sm-3 col-12">
							<div class="form-group mb-0">
								<label class="checkbox checkbox-outline px-0">
									<input type="checkbox" value="sterilizeAll" id="hot_packs_checkbox"
										formControlName="hot_packs_checkbox" (change)="handleInput($event, 'pack')">
									Hot Packs
								</label>
							</div>
						</div>
						<div class="col-md-5 col-sm col-12">
							<p-dropdown [options]="duration" [filter]="true" [resetFilterOnHide]="true"
								optionValue="duration" optionLabel="duration" filterBy="duration"
								formControlName="hot_packs_duration" id="hot_packs_duration" placeholder="Duration"
								[class.is-invalid]="(assessment.submitted &&
								ptAssessment.get([ 'session_technique','hot_packs_duration'])?.invalid)
								||
								ptAssessment.get(['session_technique','hot_packs_duration'])?.invalid
								&&
								ptAssessment.get([ 'session_technique','hot_packs_duration']).touched">
							</p-dropdown>
							<div class="invalid-feedback">Hot packs duration is required</div>
						</div>
						<div class="col-md-5 col-sm col-12 mt-sm-0 mt-2">
							<div class="form-group">
								<input type="text" class="w-100 form-control" value="intact" id="hot_packs_details"
									formControlName="hot_packs_details" placeholder="Details" [class.is-invalid]="(assessment.submitted &&
									ptAssessment.get([ 'session_technique','hot_packs_details'])?.invalid)
									||
									ptAssessment.get(['session_technique','hot_packs_details'])?.invalid
									&&
									ptAssessment.get([ 'session_technique','hot_packs_details']).touched">
								<div class="invalid-feedback">Hot packs details is required</div>
							</div>
						</div>


					</div>


					<!-- Stimulation -->
					<div class="row">
						<div class="col-md-2 col-sm-3 col-12">
							<div class="form-group mb-0">
								<label class="checkbox checkbox-outline px-0">
									<input type="checkbox" value="sterilizeAll" id="stimulation_checkbox"
										formControlName="stimulation_checkbox"
										(change)="handleInput($event, 'stimulation')">
									Stimulation
								</label>
							</div>
						</div>
						<div class="col-md-5 col-sm col-12">
							<p-dropdown [options]="duration" [filter]="true" [resetFilterOnHide]="true"
								optionValue="duration" optionLabel="duration" filterBy="duration"
								formControlName="stimulation_duration" id="stimulation_duration" placeholder="Duration"
								[class.is-invalid]="(assessment.submitted &&
								ptAssessment.get([ 'session_technique','stimulation_duration'])?.invalid)
								||
								ptAssessment.get(['session_technique','stimulation_duration'])?.invalid
								&&
								ptAssessment.get([ 'session_technique','stimulation_duration']).touched">
							</p-dropdown>
							<div class="invalid-feedback">Stimulation duration is required</div>
						</div>
						<div class="col-md-5 col-sm col-12 mt-sm-0 mt-2">
							<div class="form-group">
								<input type="text" class="w-100 form-control" value="intact"
									formControlName="stimulation_details" id="stimulation_details" placeholder="Details"
									[class.is-invalid]="(assessment.submitted &&
									ptAssessment.get([ 'session_technique','stimulation_details'])?.invalid)
									||
									ptAssessment.get(['session_technique','stimulation_details'])?.invalid
									&&
									ptAssessment.get([ 'session_technique','stimulation_details']).touched">
								<div class="invalid-feedback">Stimulation details is required</div>
							</div>
						</div>


					</div>


					<!-- Vibrator -->
					<div class="row">
						<div class="col-md-2 col-sm-3 col-12">
							<div class="form-group mb-0">
								<label class="checkbox checkbox-outline px-0">
									<input type="checkbox" value="sterilizeAll" id="vibrator_checkbox"
										formControlName="vibrator_checkbox" (change)="handleInput($event, 'vibrator')">
									Vibrator
								</label>
							</div>
						</div>
						<div class="col-md-5 col-sm col-12">
							<p-dropdown [options]="duration" [filter]="true" [resetFilterOnHide]="true"
								optionValue="duration" optionLabel="duration" filterBy="duration"
								formControlName="vibrator_duration" id="vibrator_duration" placeholder="Duration"
								[class.is-invalid]="(assessment.submitted &&
								ptAssessment.get([ 'session_technique','vibrator_duration'])?.invalid)
								||
								ptAssessment.get(['session_technique','vibrator_duration'])?.invalid
								&&
								ptAssessment.get([ 'session_technique','vibrator_duration']).touched">
							</p-dropdown>
							<div class="invalid-feedback">Vibrator duration is required</div>
						</div>
						<div class="col-md-5 col-sm col-12 mt-sm-0 mt-2">
							<div class="form-group">
								<input type="text" class="w-100 form-control" value="intact"
									formControlName="vibrator_details" id="vibrator_details" placeholder="Details"
									[class.is-invalid]="(assessment.submitted &&
									ptAssessment.get([ 'session_technique','vibrator_details'])?.invalid)
									||
									ptAssessment.get(['session_technique','vibrator_details'])?.invalid
									&&
									ptAssessment.get([ 'session_technique','vibrator_details']).touched">
								<div class="invalid-feedback">Vibrator details is required</div>
							</div>
						</div>


					</div>


					<!-- Tapping -->
					<div class="row">
						<div class="col-md-2 col-sm-3 col-12">
							<div class="form-group mb-0">
								<label class="checkbox checkbox-outline px-0">
									<input type="checkbox" value="sterilizeAll" id="tapping_checkbox"
										formControlName="tapping_checkbox" (change)="handleInput($event, 'tapping')">
									Tapping
								</label>
							</div>
						</div>
						<div class="col-md-5 col-sm col-12">
							<p-dropdown [options]="duration" [filter]="true" [resetFilterOnHide]="true"
								optionValue="duration" optionLabel="duration" filterBy="duration" id="tapping_duration"
								formControlName="tapping_duration" placeholder="Duration" [class.is-invalid]="(assessment.submitted &&
								ptAssessment.get([ 'session_technique','tapping_duration'])?.invalid)
								||
								ptAssessment.get(['session_technique','tapping_duration'])?.invalid
								&&
								ptAssessment.get([ 'session_technique','tapping_duration']).touched">
							</p-dropdown>
							<div class="invalid-feedback">Tapping duration is required</div>
						</div>
						<div class="col-md-5 col-sm col-12 mt-sm-0 mt-2">
							<div class="form-group">
								<input type="text" class="w-100 form-control" value="intact" id="tapping_details"
									formControlName="tapping_details" placeholder="Details" [class.is-invalid]="(assessment.submitted &&
									ptAssessment.get([ 'session_technique','tapping_details'])?.invalid)
									||
									ptAssessment.get(['session_technique','tapping_details'])?.invalid
									&&
									ptAssessment.get([ 'session_technique','tapping_details']).touched">
								<div class="invalid-feedback">Tapping details is required</div>
							</div>
						</div>


					</div>


					<!-- Chest Therapy -->
					<div class="row">
						<div class="col-md-2 col-sm-3 col-12">
							<div class="form-group mb-0">
								<label class="checkbox checkbox-outline px-0">
									<input type="checkbox" value="sterilizeAll" id="chest_therapy_checkbox"
										formControlName="chest_therapy_checkbox"
										(change)="handleInput($event, 'chest')">
									Chest Therapy
								</label>
							</div>
						</div>
						<div class="col-md-5 col-sm col-12">
							<p-dropdown [options]="duration" [filter]="true" [resetFilterOnHide]="true"
								optionValue="duration" optionLabel="duration" filterBy="duration"
								id="chest_therapy_duration" formControlName="chest_therapy_duration"
								placeholder="Duration" [class.is-invalid]="(assessment.submitted &&
								ptAssessment.get([ 'session_technique','chest_therapy_duration'])?.invalid)
								||
								ptAssessment.get(['session_technique','chest_therapy_duration'])?.invalid
								&&
								ptAssessment.get([ 'session_technique','chest_therapy_duration']).touched">
							</p-dropdown>
							<div class="invalid-feedback">Chest Therapy duration is required</div>
						</div>
						<div class="col-md-5 col-sm col-12 mt-sm-0 mt-2">
							<div class="form-group">
								<input type="text" class="w-100 form-control" id="chest_therapy_details" value="intact"
									formControlName="chest_therapy_details" placeholder="Details" [class.is-invalid]="(assessment.submitted &&
									ptAssessment.get([ 'session_technique','chest_therapy_details'])?.invalid)
									||
									ptAssessment.get(['session_technique','chest_therapy_details'])?.invalid
									&&
									ptAssessment.get([ 'session_technique','chest_therapy_details']).touched">
								<div class="invalid-feedback">Chest Thearpy details is required</div>
							</div>
						</div>


					</div>
					<!-- Heat and Cold therapy -->
					<div class="row">
						<div class="col-md-2 col-sm-3 col-12">
							<div class="form-group mb-0">
								<label class="checkbox checkbox-outline px-0">
									<input type="checkbox" value="sterilizeAll" id="heat_and_Cold_therapy_checkbox"
										formControlName="heat_and_Cold_therapy_checkbox"
										(change)="handleInput($event, 'cold')">
									Heat and Cold therapy
								</label>
							</div>
						</div>
						<div class="col-md-5 col-sm col-12">
							<p-dropdown [options]="duration" [filter]="true" [resetFilterOnHide]="true"
								optionValue="duration" optionLabel="duration" filterBy="duration"
								id="heat_and_Cold_therapy_duration" formControlName="heat_and_Cold_therapy_duration"
								placeholder="Duration" [class.is-invalid]="(assessment.submitted &&
								ptAssessment.get([ 'session_technique','heat_and_Cold_therapy_duration'])?.invalid)
								||
								ptAssessment.get(['session_technique','heat_and_Cold_therapy_duration'])?.invalid
								&&
								ptAssessment.get([ 'session_technique','heat_and_Cold_therapy_duration']).touched">
							</p-dropdown>
							<div class="invalid-feedback">Heat and Cold therapy duration is required</div>
						</div>
						<div class="col-md-5 col-sm col-12 mt-sm-0 mt-2">
							<div class="form-group">
								<input type="text" class="w-100 form-control" id="heat_and_Cold_therapy_details"
									value="intact" formControlName="heat_and_Cold_therapy_details" placeholder="Details"
									[class.is-invalid]="(assessment.submitted &&
									ptAssessment.get([ 'session_technique','heat_and_Cold_therapy_details'])?.invalid)
									||
									ptAssessment.get(['session_technique','heat_and_Cold_therapy_details'])?.invalid
									&&
									ptAssessment.get([ 'session_technique','heat_and_Cold_therapy_details']).touched">
								<div class="invalid-feedback">Heat and Cold Therapy details is required</div>
							</div>
						</div>


					</div>
					<!-- Electrotherapy (TENS,NMES) -->
					<div class="row">
						<div class="col-md-2 col-sm-3 col-12">
							<div class="form-group mb-0">
								<label class="checkbox checkbox-outline px-0">
									<input type="checkbox" value="sterilizeAll" id="electrotherapy_checkbox"
										formControlName="electrotherapy_checkbox"
										(change)="handleInput($event, 'electro')">
									Electrotherapy (TENS,NMES)
								</label>
							</div>
						</div>
						<div class="col-md-5 col-sm col-12">
							<p-dropdown [options]="duration" [filter]="true" [resetFilterOnHide]="true"
								optionValue="duration" optionLabel="duration" filterBy="duration"
								id="electrotherapy_duration" formControlName="electrotherapy_duration"
								placeholder="Duration" [class.is-invalid]="(assessment.submitted &&
								ptAssessment.get([ 'session_technique','electrotherapy_duration'])?.invalid)
								||
								ptAssessment.get(['session_technique','electrotherapy_duration'])?.invalid
								&&
								ptAssessment.get([ 'session_technique','electrotherapy_duration']).touched">
							</p-dropdown>
							<div class="invalid-feedback">Electrotherapy duration is required</div>
						</div>
						<div class="col-md-5 col-sm col-12 mt-sm-0 mt-2">
							<div class="form-group">
								<input type="text" class="w-100 form-control" id="electrotherapy_details" value="intact"
									formControlName="electrotherapy_details" placeholder="Details" [class.is-invalid]="(assessment.submitted &&
									ptAssessment.get([ 'session_technique','electrotherapy_details'])?.invalid)
									||
									ptAssessment.get(['session_technique','electrotherapy_details'])?.invalid
									&&
									ptAssessment.get([ 'session_technique','electrotherapy_details']).touched">
								<div class="invalid-feedback">Electrotherapy details is required</div>
							</div>
						</div>


					</div>
					<!-- Excercises -->
					<div class="row">
						<div class="col-md-2 col-sm-3 col-12">
							<div class="form-group mb-0">
								<label class="checkbox checkbox-outline px-0">
									<input type="checkbox" value="sterilizeAll" id="excercises_checkbox"
										formControlName="excercises_checkbox" (change)="handleInput($event, 'cises')">
									Excercises
								</label>
							</div>
						</div>
						<div class="col-md-5 col-sm col-12">
							<p-dropdown [options]="duration" [filter]="true" [resetFilterOnHide]="true"
								optionValue="duration" optionLabel="duration" filterBy="duration"
								id="excercises_duration" formControlName="excercises_duration" placeholder="Duration"
								[class.is-invalid]="(assessment.submitted &&
								ptAssessment.get([ 'session_technique','excercises_duration'])?.invalid)
								||
								ptAssessment.get(['session_technique','excercises_duration'])?.invalid
								&&
								ptAssessment.get([ 'session_technique','excercises_duration']).touched">
							</p-dropdown>
							<div class="invalid-feedback">Excercises duration is required</div>
						</div>
						<div class="col-md-5 col-sm col-12 mt-sm-0 mt-2">
							<div class="form-group">
								<input type="text" class="w-100 form-control" id="excercises_details" value="intact"
									formControlName="excercises_details" placeholder="Details" [class.is-invalid]="(assessment.submitted &&
									ptAssessment.get([ 'session_technique','excercises_details'])?.invalid)
									||
									ptAssessment.get(['session_technique','excercises_details'])?.invalid
									&&
									ptAssessment.get([ 'session_technique','excercises_details']).touched">
								<div class="invalid-feedback">Excercises details is required</div>
							</div>
						</div>


					</div>
					<!-- Massage and manual techniques -->
					<div class="row">
						<div class="col-md-2 col-sm-3 col-12">
							<div class="form-group mb-0">
								<label class="checkbox checkbox-outline px-0">
									<input type="checkbox" value="sterilizeAll" id="manual_techniques_checkbox"
										formControlName="manual_techniques_checkbox"
										(change)="handleInput($event, 'manual')">
									Massage and manual techniques
								</label>
							</div>
						</div>
						<div class="col-md-5 col-sm col-12">
							<p-dropdown [options]="duration" [filter]="true" [resetFilterOnHide]="true"
								optionValue="duration" optionLabel="duration" filterBy="duration"
								id="manual_techniques_duration" formControlName="manual_techniques_duration"
								placeholder="Duration" [class.is-invalid]="(assessment.submitted &&
								ptAssessment.get([ 'session_technique','manual_techniques_duration'])?.invalid)
								||
								ptAssessment.get(['session_technique','manual_techniques_duration'])?.invalid
								&&
								ptAssessment.get([ 'session_technique','manual_techniques_duration']).touched">
							</p-dropdown>
							<div class="invalid-feedback">Manual techniques duration is required</div>
						</div>
						<div class="col-md-5 col-sm col-12 mt-sm-0 mt-2">
							<div class="form-group">
								<input type="text" class="w-100 form-control" id="manual_techniques_details"
									value="intact" formControlName="manual_techniques_details" placeholder="Details"
									[class.is-invalid]="(assessment.submitted &&
									ptAssessment.get([ 'session_technique','manual_techniques_details'])?.invalid)
									||
									ptAssessment.get(['session_technique','manual_techniques_details'])?.invalid
									&&
									ptAssessment.get([ 'session_technique','manual_techniques_details']).touched">
								<div class="invalid-feedback">Manual techniques details is required</div>
							</div>
						</div>


					</div>
					<!-- Breathing Relaxation techniques -->
					<div class="row">
						<div class="col-md-2 col-sm-3 col-12">
							<div class="form-group mb-0">
								<label class="checkbox checkbox-outline px-0">
									<input type="checkbox" value="sterilizeAll" id="breathing_techniques_checkbox"
										formControlName="breathing_techniques_checkbox"
										(change)="handleInput($event, 'relax')">
									Breathing Relaxation techniques
								</label>
							</div>
						</div>
						<div class="col-md-5 col-sm col-12">
							<p-dropdown [options]="duration" [filter]="true" [resetFilterOnHide]="true"
								optionValue="duration" optionLabel="duration" filterBy="duration"
								id="breathing_techniques_duration" formControlName="breathing_techniques_duration"
								placeholder="Duration" [class.is-invalid]="(assessment.submitted &&
								ptAssessment.get([ 'session_technique','breathing_techniques_duration'])?.invalid)
								||
								ptAssessment.get(['session_technique','breathing_techniques_duration'])?.invalid
								&&
								ptAssessment.get([ 'session_technique','breathing_techniques_duration']).touched">
							</p-dropdown>
							<div class="invalid-feedback">Breathing techniques duration is required</div>
						</div>
						<div class="col-md-5 col-sm col-12 mt-sm-0 mt-2">
							<div class="form-group">
								<input type="text" class="w-100 form-control" id="breathing_techniques_details"
									value="intact" formControlName="breathing_techniques_details" placeholder="Details"
									[class.is-invalid]="(assessment.submitted &&
									ptAssessment.get([ 'session_technique','breathing_techniques_details'])?.invalid)
									||
									ptAssessment.get(['session_technique','breathing_techniques_details'])?.invalid
									&&
									ptAssessment.get([ 'session_technique','breathing_techniques_details']).touched">
								<div class="invalid-feedback">Breathing techniques details is required</div>
							</div>
						</div>


					</div>
					<!-- Body Mechanics -->
					<div class="row">
						<div class="col-md-2 col-sm-3 col-12">
							<div class="form-group mb-0">
								<label class="checkbox checkbox-outline px-0">
									<input type="checkbox" value="sterilizeAll" id="body_mechanics_checkbox"
										formControlName="body_mechanics_checkbox"
										(change)="handleInput($event, 'body')">
									Body Mechanics
								</label>
							</div>
						</div>
						<div class="col-md-5 col-sm col-12">
							<p-dropdown [options]="duration" [filter]="true" [resetFilterOnHide]="true"
								optionValue="duration" optionLabel="duration" filterBy="duration"
								id="body_mechanics_duration" formControlName="body_mechanics_duration"
								placeholder="Duration" [class.is-invalid]="(assessment.submitted &&
								ptAssessment.get([ 'session_technique','body_mechanics_duration'])?.invalid)
								||
								ptAssessment.get(['session_technique','body_mechanics_duration'])?.invalid
								&&
								ptAssessment.get([ 'session_technique','body_mechanics_duration']).touched">
							</p-dropdown>
							<div class="invalid-feedback">Body mechanics duration is required</div>
						</div>
						<div class="col-md-5 col-sm col-12 mt-sm-0 mt-2">
							<div class="form-group">
								<input type="text" class="w-100 form-control" id="body_mechanics_details" value="intact"
									formControlName="body_mechanics_details" placeholder="Details" [class.is-invalid]="(assessment.submitted &&
									ptAssessment.get([ 'session_technique','body_mechanics_details'])?.invalid)
									||
									ptAssessment.get(['session_technique','body_mechanics_details'])?.invalid
									&&
									ptAssessment.get([ 'session_technique','body_mechanics_details']).touched">
								<div class="invalid-feedback">Body mechanics details is required</div>
							</div>
						</div>


					</div>
					<!-- Others -->
					<div class="row">
						<div class="col-md-2 col-sm-3 col-12">
							<div class="form-group mb-0">
								<label class="checkbox checkbox-outline px-0">
									<input type="checkbox" value="sterilizeAll" id="session_technique_others_checkbox"
										formControlName="session_technique_others_checkbox"
										(change)="handleInput($event, 'other')">
									Others
								</label>
							</div>
						</div>
						<div class="col-md-10 col-sm-9 col-12">
							<div class="form-group mb-3">
								<input type="text" class="w-100 form-control" id="session_technique_others_details"
									value="intact" formControlName="session_technique_others_details"
									placeholder="Details" [class.is-invalid]="(assessment.submitted &&
									ptAssessment.get([ 'session_technique','session_technique_others_details'])?.invalid)
									||
									ptAssessment.get(['session_technique','session_technique_others_details'])?.invalid
									&&
									ptAssessment.get([ 'session_technique','session_technique_others_details']).touched">
								<div class="invalid-feedback">Session technique others details is required</div>
							</div>
						</div>
					</div>
					<!-- Re assessment date -->
					<div class="row">
						<div class="col-sm-6 col-12">
							<div class="form-group">
								<div class="form-group">
									<label class="subHeading px-0">Re-assessment Date:</label>
									<div class="card flex justify-content-center">
										<p-calendar [showIcon]="true" [readonlyInput]="true" placeholder="DD/MM/YYYY"
											readonly id="re_date" formControlName="re_date" dateFormat="dd/mm/yy"
											[minDate]="selectedDate"></p-calendar>
									</div>
								</div>
							</div>


						</div>
					</div>
				</div>
			</div>
			<div class="add-listings-btn" style="text-align: right;">
				<button type="submit">Submit</button>
			</div>
		</div>
	</form>
</div>