<p-toast></p-toast>

<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">
    <app-dashboard-navbar></app-dashboard-navbar>
    <div class="dropdown profile-nav-item d-flex flex-column align-items-end">
        <a class="dropdown-toggle admin-btn" style="text-decoration: none;" role="button" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <div class="menu-profile">
                <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
                <span class="name">{{getname}}</span>
            </div>
        </a>
        <div class="dropdown-menu d-flex flex-column align-items-center">
            <div class="dropdown-header align">
                <div class="figure mb-3">
                    <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
                </div>
                <div class="info text-center">
                    <span class="name" style="white-space: break-spaces;">{{getname}}</span>
                    <p class="mb-3 email">{{getRole}}</p>
                </div>
            </div>
            <!-- <div class="dropdown-body">
                <ul class="profile-nav p-0 pt-3">
                    <li class="nav-item"><a routerLink="/dashboard-my-profile" class="nav-link"><i class='bx bx-user'></i> <span>Profile Dashboard</span></a></li>
                </ul>
            </div> -->
            <div class="dropdown-footer">
                <ul class="profile-nav">
                    <li class="nav-item"><a class="nav-link" (click)="logout()"><i class='bx bx-log-out'></i> <span
                                class="logout-text">Logout</span></a></li>
                </ul>
            </div>

        </div>
    </div>
    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">

        <h1 style="text-transform: capitalize;">{{ Content.title }}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item">
                {{ Content.subTitle }}
            </li>
            <li class="item" style="text-transform: capitalize;">{{ Content.title }}</li>
        </ol>
        <!-- <div class="spacer"></div> -->

    </div>
    <!-- End Breadcrumb Area -->

    <!-- Start Form -->
    <!-- Baby Child Registration listing SECTION -->

    <p-accordion>
        <p-accordionTab header="Patient Data">
            <div class="add-listings-btn mt-3">
                <p-accordion class="mt-3">
                    <app-initial-assessment-form></app-initial-assessment-form>
                </p-accordion>
            </div>
        </p-accordionTab>
        <p-accordionTab header="Nurse Care Plans">
            <div class="add-listings-btn mt-3">
                <p-accordion class="mt-3">
                    <app-daily-care-plan></app-daily-care-plan>
                </p-accordion>
            </div>
        </p-accordionTab>
        <p-accordionTab header="Growth Monitoring">
            <div class="add-listings-btn mt-3">
                <app-growth-monitoring-list></app-growth-monitoring-list>
            </div>
        </p-accordionTab>
        <p-accordionTab header="Extra Activity">
            <div class="add-listings-btn mt-3">
                <app-environment-and-child-safety-ass-list></app-environment-and-child-safety-ass-list>
            </div>
        </p-accordionTab>
        <!-- <p-accordionTab header="Feeding Assessment">
                <div class="add-listings-btn mt-3" >
                    <app-feeding-assessment-list></app-feeding-assessment-list>
                </div>
            </p-accordionTab> -->
        <p-accordionTab header="Nurse Tasks">
            <div class="add-listings-btn mt-3">
                <app-nanny-task-list></app-nanny-task-list>
            </div>
        </p-accordionTab>
        <p-accordionTab header="Health Monitoring">
            <div class="add-listings-btn mt-3">
                <app-health-monitoring-listing></app-health-monitoring-listing>
            </div>
        </p-accordionTab>
        <!-- <p-accordionTab header="Masters">
                <div class="add-listings-btn mt-3" >
                   <p-accordion>
                        <p-accordionTab header="Nurse Plans">
                            <div class="add-listings-btn mt-3" >
                                <app-nanny-plans></app-nanny-plans>
                            </div>
                        </p-accordionTab>
                        <p-accordionTab header="Urine">
                            <div class="add-listings-btn mt-3" >
                                <app-urine-master></app-urine-master>
                            </div>
                        </p-accordionTab>
                        <p-accordionTab header="Stool">
                            <div class="add-listings-btn mt-3" >
                                <app-stool-master></app-stool-master>
                            </div>
                        </p-accordionTab><p-accordionTab header="User Role">
                            <div class="add-listings-btn mt-3" >
                                <app-roles-permission></app-roles-permission>
                            </div>
                        </p-accordionTab>
                   </p-accordion>
                </div>
            </p-accordionTab> -->
    </p-accordion>
    <!-- BABY DETAILS SECTION END-->
    <!-- DAILY CARE SECTION-->

    <!-- DAILY CARE SECTION END-->
    <!-- End Form -->
    <app-copyrights></app-copyrights>
</div>