import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup , Validators,} from '@angular/forms';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { PtServiceService } from 'src/app/services/pt-service.service';

@Component({
  selector: 'app-initial-assessment-adult',
  templateUrl: './initial-assessment-adult.component.html',
  styleUrls: ['./initial-assessment-adult.component.scss'],
  providers: [MessageService]
})
export class InitialAssessmentAdultComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Initial Assessment Adult',
      subTitle: 'Dashboard'
    }
  ]
  duration: any = [];
  sessionList: any;
  peoplelist:any;
  timelist:any;
  placelist:any;
  swellingcheck:any;
  scarcheck:any;
  wormcheck:any;
  infectioncheck:any;
  woundcheck:any;
  paincheck:any;
  otherscheck:any;
  maxDate = new Date();


  selectedDate = new Date();
  recomended_duration = [
    {name:'1 Month', id:1},
    {name:'2 Months', id:2},
    {name:'3 Months', id:3},
    {name:'4 Months', id:4},
    {name:'5 Months', id:5},
    {name:'6 Months', id:6},
  ];
  session_per_month: any = [];
  initialAssessementAdult: FormGroup;
  constructor(private fb: FormBuilder, private pt_service: PtServiceService, private messageService: MessageService) {

    this.initialAssessementAdult = this.fb.group({
      date: new FormControl('', Validators.required),
      initial_assessment: new FormControl(null, Validators.required),
      history_present_illness: new FormControl(null, Validators.required),
      past_medical_history: new FormControl(null, Validators.required),
      assesment_json:this.fb.array([]),
      medications:new FormControl(null) ,

      pain_assessment_tool: new FormControl(null),
      pain_assessment_score: new FormControl(null),
      blood_pressure: new FormControl(null),
      temperature: new FormControl(null),
      heart_rate: new FormControl(null),
      spo2: new FormControl(null),
      rr: new FormControl(null),
      attachment_PEG: new FormControl(''),
      attachment_trache: new FormControl(''),
      attachment_NGT: new FormControl(''),
      attachment_FC1: new FormControl(''),
      attachment_colostomy: new FormControl(''),
      attachment_FC2: new FormControl(''),
      attachment_ventilator: new FormControl(''),
      attachment_canula: new FormControl(''),
      attachment_NA: new FormControl(''),
      others: new FormControl({
        value: null,
        disabled: true,
      }),
      repiratory_assessment: new FormControl(null),
      cough_remark: new FormControl({
        value: null,
        disabled: true,
      }),
      chest_secretion: new FormControl(null),
      chest_secretion_remark: new FormControl({
        value: null,
        disabled: true,
      }),
      intact: new FormControl(null),
      intact_light: new FormControl(''),
      impaired_light: new FormControl(''),
      absent_light: new FormControl(''),
      not_tested_light: new FormControl(''),
      light_touch_remark: new FormControl(null),
      intact_blunt: new FormControl(''),
      impaired_blunt: new FormControl(''),
      absent_blunt: new FormControl(''),
      not_tested_blunt: new FormControl(''),
      blunt_remark: new FormControl(null),
      intact_pressure: new FormControl(''),
      impaired_pressure: new FormControl(''),
      absent_pressure: new FormControl(''),
      not_tested_pressure: new FormControl(''),
      pressure_remark: new FormControl(null),
      intact_pin: new FormControl(''),
      impaired_pin: new FormControl(''),
      absent_pin: new FormControl(''),
      not_tested_pin: new FormControl(''),
      pin_remark: new FormControl(null),
      impaired: new FormControl(''),
      absent: new FormControl(''),
      tested: new FormControl(''),
      // light_touch_remark: new FormControl(''),
      oriented_people: new FormControl(''),
      oriented_people_remark: new FormControl({
        value: null,
        disabled: true,
      }),
      oriented_time: new FormControl(''),
      oriented_time_remark: new FormControl({
        value: null,
        disabled: true,
      }),
      oriented_place: new FormControl(''),
      oriented_place_remark: new FormControl({
        value: null,
        disabled: true,
      }),
      swelling: new FormControl(''),
      swelling_remark: new FormControl({
        value: null,
        disabled: true,
      }),
      scar: new FormControl(''),
      scar_remark: new FormControl({
        value: null,
        disabled: true,
      }),
      worm: new FormControl(''),
      worm_remark: new FormControl({
        value: null,
        disabled: true,
      }),
      infection: new FormControl(''),
      infection_remark: new FormControl({
        value: null,
        disabled: true,
      }),
      wound: new FormControl(''),
      wound_remark: new FormControl({
        value: null,
        disabled: true,
      }),
      pain: new FormControl(''),
      pain_remark: new FormControl({
        value: null,
        disabled: true,
      }),
      others_soft_tissue: new FormControl(''),
      others_remark: new FormControl({
        value: null,
        disabled: true,
      }),
      neck_flexion_passive_r: new FormControl(''),
      neck_flexion_passive_l: new FormControl(''),
      neck_flexion_active_r: new FormControl(''),
      neck_flexion_active_l: new FormControl(''),
      nech_flexion_comment: new FormControl(null),
      neck_flexion_muscle_r: new FormControl(''),
      neck_flexion_muscle_l: new FormControl(''),
      neck_extension_passive_r: new FormControl(''),
      neck_extension_passive_l: new FormControl(''),
      neck_extension_active_r: new FormControl(''),
      neck_extension_active_l: new FormControl(''),
      neck_extension_comment: new FormControl(null),
      neck_extension_muscle_r: new FormControl(''),
      neck_extension_muscle_l: new FormControl(''),
      neck_rotation_passive_r: new FormControl(''),
      neck_rotation_passive_l: new FormControl(''),
      neck_rotation_active_r: new FormControl(''),
      neck_rotation_active_l: new FormControl(''),
      neck_rotation_comment: new FormControl(null),
      neck_rotation_muscle_r: new FormControl(''),
      neck_rotation_muscle_l: new FormControl(''),
      neck_sideFlex_passive_r: new FormControl(''),
      neck_sideFlex_passive_l: new FormControl(''),
      neck_sideFlex_active_r: new FormControl(''),
      neck_sideFlex_active_l: new FormControl(''),
      neck_sideFlex_comment: new FormControl(null),
      neck_sideFlex_muscle_r: new FormControl(''),
      neck_sideFlex_muscle_l: new FormControl(''),
      shoulder_flexion_passive_r: new FormControl(''),
      shoulder_flexion_passive_l: new FormControl(''),
      shoulder_flexion_active_r: new FormControl(''),
      shoulder_flexion_active_l: new FormControl(''),
      shoulder_flexion_comment: new FormControl(null),
      shoulder_flexion_muscle_r: new FormControl(''),
      shoulder_flexion_muscle_l: new FormControl(''),
      shoulder_extension_passive_r: new FormControl(''),
      shoulder_extension_passive_l: new FormControl(''),
      shoulder_extension_active_r: new FormControl(''),
      shoulder_extension_active_l: new FormControl(''),
      shoulder_extension_comment: new FormControl(null),
      shoulder_extension_muscle_r: new FormControl(''),
      shoulder_extension_muscle_l: new FormControl(''),
      shoulder_abduction_passive_r: new FormControl(''),
      shoulder_abduction_passive_l: new FormControl(''),
      shoulder_abduction_active_r: new FormControl(''),
      shoulder_abduction_active_l: new FormControl(''),
      shoulder_abduction_comment: new FormControl(null),
      shoulder_abduction_muscle_r: new FormControl(''),
      shoulder_abduction_muscle_l: new FormControl(''),
      shoulder_adduction_passive_r: new FormControl(''),
      shoulder_adduction_passive_l: new FormControl(''),
      shoulder_adduction_active_r: new FormControl(''),
      shoulder_adduction_active_l: new FormControl(''),
      shoulder_adduction_comment: new FormControl(null),
      shoulder_adduction_muscle_r: new FormControl(''),
      shoulder_adduction_muscle_l: new FormControl(''),
      shoulder_internal_rotation_passive_r: new FormControl(''),
      shoulder_internal_rotation_passive_l: new FormControl(''),
      shoulder_internal_rotation_active_r: new FormControl(''),
      shoulder_internal_rotation_active_l: new FormControl(''),
      shoulder_internal_rotation_comment: new FormControl(null),
      shoulder_internal_rotation_muscle_r: new FormControl(''),
      shoulder_internal_rotation_muscle_l: new FormControl(''),
      shoulder_external_rotation_passive_r: new FormControl(''),
      shoulder_external_rotation_passive_l: new FormControl(''),
      shoulder_external_rotation_active_r: new FormControl(''),
      shoulder_external_rotation_active_l: new FormControl(''),
      shoulder_external_rotation_comment: new FormControl(null),
      shoulder_external_rotation_muscle_r: new FormControl(''),
      shoulder_external_rotation_muscle_l: new FormControl(''),
      elbow_flexion_passive_r: new FormControl(''),
      elbow_flexion_passive_l: new FormControl(''),
      elbow_flexion_active_r: new FormControl(''),
      elbow_flexion_active_l: new FormControl(''),
      elbow_flexion_comment: new FormControl(null),
      elbow_flexion_muscle_r: new FormControl(''),
      elbow_flexion_muscle_l: new FormControl(''),
      elbow_extension_passive_r: new FormControl(''),
      elbow_extension_passive_l: new FormControl(''),
      elbow_extension_active_r: new FormControl(''),
      elbow_extension_active_l: new FormControl(''),
      elbow_extension_comment: new FormControl(null),
      elbow_extension_muscle_r: new FormControl(''),
      elbow_extension_muscle_l: new FormControl(''),
      elbow_supination_passive_r: new FormControl(''),
      elbow_supination_passive_l: new FormControl(''),
      elbow_supination_active_r: new FormControl(''),
      elbow_supination_active_l: new FormControl(''),
      elbow_supination_comment: new FormControl(null),
      elbow_supination_muscle_r: new FormControl(''),
      elbow_supination_muscle_l: new FormControl(''),
      elbow_pronation_passive_r: new FormControl(''),
      elbow_pronation_passive_l: new FormControl(''),
      elbow_pronation_active_r: new FormControl(''),
      elbow_pronation_active_l: new FormControl(''),
      elbow_pronation_comment: new FormControl(null),
      elbow_pronation_muscle_r: new FormControl(''),
      elbow_pronation_muscle_l: new FormControl(''),
      wrist_flexion_passive_r: new FormControl(''),
      wrist_flexion_passive_l: new FormControl(''),
      wrist_flexion_active_r: new FormControl(''),
      wrist_flexion_active_l: new FormControl(''),
      wrist_flexion_comment: new FormControl(null),
      wrist_flexion_muscle_r: new FormControl(''),
      wrist_flexion_muscle_l: new FormControl(''),
      wrist_extension_passive_r: new FormControl(''),
      wrist_extension_passive_l: new FormControl(''),
      wrist_extension_active_r: new FormControl(''),
      wrist_extension_active_l: new FormControl(''),
      wrist_extension_comment: new FormControl(null),
      wrist_extension_muscle_r: new FormControl(''),
      wrist_extension_muscle_l: new FormControl(''),
      wrist_ulnar_deviation_passive_r: new FormControl(''),
      wrist_ulnar_deviation_passive_l: new FormControl(''),
      wrist_ulnar_deviation_active_r: new FormControl(''),
      wrist_ulnar_deviation_active_l: new FormControl(''),
      wrist_ulnar_deviation_comment: new FormControl(null),
      wrist_ulnar_deviation_muscle_r: new FormControl(''),
      wrist_ulnar_deviation_muscle_l: new FormControl(''),
      wrist_radial_deviation_passive_r: new FormControl(''),
      wrist_radial_deviation_passive_l: new FormControl(''),
      wrist_radial_deviation_active_r: new FormControl(''),
      wrist_radial_deviation_active_l: new FormControl(''),
      wrist_radial_deviation_comment: new FormControl(null),
      wrist_radial_deviation_muscle_r: new FormControl(''),
      wrist_radial_deviation_muscle_l: new FormControl(''),
      digits_thump_passive_r: new FormControl(''),
      digits_thump_passive_l: new FormControl(''),
      digits_thump_active_r: new FormControl(''),
      digits_thump_active_l: new FormControl(''),
      digits_thump_comment: new FormControl(null),
      digits_thump_muscle_r: new FormControl(''),
      digits_thump_muscle_l: new FormControl(''),
      digits_thump_ip_passive_r: new FormControl(''),
      digits_thump_ip_passive_l: new FormControl(''),
      digits_thump_ip_active_r: new FormControl(''),
      digits_thump_ip_active_l: new FormControl(''),
      digits_thump_ip_comment: new FormControl(null),
      digits_thump_ip_muscle_r: new FormControl(''),
      digits_thump_ip_muscle_l: new FormControl(''),
      digits_mcp_passive_r: new FormControl(''),
      digits_mcp_passive_l: new FormControl(''),
      digits_mcp_active_r: new FormControl(''),
      digits_mcp_active_l: new FormControl(''),
      digits_mcp_comment: new FormControl(null),
      digits_mcp_muscle_r: new FormControl(''),
      digits_mcp_muscle_l: new FormControl(''),
      digits_pip_passive_r: new FormControl(''),
      digits_pip_passive_l: new FormControl(''),
      digits_pip_active_r: new FormControl(''),
      digits_pip_active_l: new FormControl(''),
      digits_pip_comment: new FormControl(null),
      digits_pip_muscle_r: new FormControl(''),
      digits_pip_muscle_l: new FormControl(''),
      digits_dip_passive_r: new FormControl(''),
      digits_dip_passive_l: new FormControl(''),
      digits_dip_active_r: new FormControl(''),
      digits_dip_active_l: new FormControl(''),
      digits_dip_comment: new FormControl(null),
      digits_dip_muscle_r: new FormControl(''),
      digits_dip_muscle_l: new FormControl(''),
      hip_flexion_passive_r: new FormControl(''),
      hip_flexion_passive_l: new FormControl(''),
      hip_flexion_active_r: new FormControl(''),
      hip_flexion_active_l: new FormControl(''),
      hip_flexion_comment: new FormControl(null),
      hip_flexion_muscle_r: new FormControl(''),
      hip_flexion_muscle_l: new FormControl(''),
      hip_extension_passive_r: new FormControl(''),
      hip_extension_passive_l: new FormControl(''),
      hip_extension_active_r: new FormControl(''),
      hip_extension_active_l: new FormControl(''),
      hip_extension_comment: new FormControl(null),
      hip_extension_muscle_r: new FormControl(''),
      hip_extension_muscle_l: new FormControl(''),
      hip_abduction_passive_r: new FormControl(''),
      hip_abduction_passive_l: new FormControl(''),
      hip_abduction_active_r: new FormControl(''),
      hip_abduction_active_l: new FormControl(''),
      hip_abduction_comment: new FormControl(null),
      hip_abduction_muscle_r: new FormControl(''),
      hip_abduction_muscle_l: new FormControl(''),
      hip_adduction_passive_r: new FormControl(''),
      hip_adduction_passive_l: new FormControl(''),
      hip_adduction_active_r: new FormControl(''),
      hip_adduction_active_l: new FormControl(''),
      hip_adduction_comment: new FormControl(null),
      hip_adduction_muscle_r: new FormControl(''),
      hip_adduction_muscle_l: new FormControl(''),
      hip_internal_rotation_passive_r: new FormControl(''),
      hip_internal_rotation_passive_l: new FormControl(''),
      hip_internal_rotation_active_r: new FormControl(''),
      hip_internal_rotation_active_l: new FormControl(''),
      hip_internal_rotation_comment: new FormControl(null),
      hip_internal_rotation_muscle_r: new FormControl(''),
      hip_internal_rotation_muscle_l: new FormControl(''),
      hip_external_rotation_passive_r: new FormControl(''),
      hip_external_rotation_passive_l: new FormControl(''),
      hip_external_rotation_active_r: new FormControl(''),
      hip_external_rotation_active_l: new FormControl(''),
      hip_external_rotation_comment: new FormControl(null),
      hip_external_rotation_muscle_r: new FormControl(''),
      hip_external_rotation_muscle_l: new FormControl(''),
      knee_flexion_passive_r: new FormControl(''),
      knee_flexion_passive_l: new FormControl(''),
      knee_flexion_active_r: new FormControl(''),
      knee_flexion_active_l: new FormControl(''),
      knee_flexion_comment: new FormControl(null),
      knee_flexion_muscle_r: new FormControl(''),
      knee_flexion_muscle_l: new FormControl(''),
      knee_extension_passive_r: new FormControl(''),
      knee_extension_passive_l: new FormControl(''),
      knee_extension_active_r: new FormControl(''),
      knee_extension_active_l: new FormControl(''),
      knee_extension_comment: new FormControl(null),
      knee_extension_muscle_r: new FormControl(''),
      knee_extension_muscle_l: new FormControl(''),
      ankle_pf_passive_r: new FormControl(''),
      ankle_pf_passive_l: new FormControl(''),
      ankle_pf_active_r: new FormControl(''),
      ankle_pf_active_l: new FormControl(''),
      ankle_pf_comment: new FormControl(null),
      ankle_pf_muscle_r: new FormControl(''),
      ankle_pf_muscle_l: new FormControl(''),
      ankle_df_passive_r: new FormControl(''),
      ankle_df_passive_l: new FormControl(''),
      ankle_df_active_r: new FormControl(''),
      ankle_df_active_l: new FormControl(''),
      ankle_df_comment: new FormControl(null),
      ankle_df_muscle_r: new FormControl(''),
      ankle_df_muscle_l: new FormControl(''),
      knee_inversion_passive_r: new FormControl(''),
      knee_inversion_passive_l: new FormControl(''),
      knee_inversion_active_r: new FormControl(''),
      knee_inversion_active_l: new FormControl(''),
      knee_inversion_comment: new FormControl(null),
      knee_inversion_muscle_r: new FormControl(''),
      knee_inversion_muscle_l: new FormControl(''),
      knee_eversion_passive_r: new FormControl(''),
      knee_eversion_passive_l: new FormControl(''),
      knee_eversion_active_r: new FormControl(''),
      knee_eversion_active_l: new FormControl(''),
      knee_eversion_comment: new FormControl(null),
      knee_eversion_muscle_r: new FormControl(''),
      knee_eversion_muscle_l: new FormControl(''),
      head_control: new FormControl(''),
      trunk_control: new FormControl(''),
      rolling_I: new FormControl(''),
      rolling_A: new FormControl(''),
      rolling_D: new FormControl(''),
      rolling_N: new FormControl(''),
      rolling_remark: new FormControl(null),
      sitting_I: new FormControl(''),
      sitting_A: new FormControl(''),
      sitting_D: new FormControl(''),
      sitting_N: new FormControl(''),
      sitting_remark: new FormControl(null),
      standing_I: new FormControl(''),
      standing_A: new FormControl(''),
      standing_D: new FormControl(''),
      standing_N: new FormControl(''),
      standing_remark: new FormControl(null),
      gait_I: new FormControl(''),
      gait_A: new FormControl(''),
      gait_D: new FormControl(''),
      gait_N: new FormControl(''),
      gait_remark: new FormControl(null),
      balance_I: new FormControl(''),
      balance_A: new FormControl(''),
      balance_D: new FormControl(''),
      balance_N: new FormControl(''),
      balance_remark: new FormControl(null),
      static_sitting_I: new FormControl(''),
      static_sitting_A: new FormControl(''),
      static_sitting_D: new FormControl(''),
      static_sitting_N: new FormControl(''),
      static_sitting_remark: new FormControl(null),
      static_standing_I: new FormControl(''),
      static_standing_A: new FormControl(''),
      static_standing_D: new FormControl(''),
      static_standing_N: new FormControl(''),
      static_standing_remark: new FormControl(null),
      dynamic_sitting_I: new FormControl(''),
      dynamic_sitting_A: new FormControl(''),
      dynamic_sitting_D: new FormControl(''),
      dynamic_sitting_N: new FormControl(''),
      dynamic_sitting_remark: new FormControl(null),
      dynamic_standing_I: new FormControl(''),
      dynamic_standing_A: new FormControl(''),
      dynamic_standing_D: new FormControl(''),
      dynamic_standing_N: new FormControl(''),
      dynamic_standing_remark: new FormControl(null),
      transfer_assisting: new FormControl(''),
      transfer_assisting_none: new FormControl(''),
      transfer_assisting_crutches: new FormControl(null),
      transfer_assisting_one: new FormControl(''),
      transfer_assisting_pair: new FormControl(''),
      transfer_assisting_wheel_chair: new FormControl(''),
      transfer_assisting_others: new FormControl(''),
      transfer_assisting_others_description: new FormControl(null),
      problems_noted: new FormControl(null),
      short_term: new FormControl(''),
      short_term_session: new FormControl(null),
      long_term: new FormControl(''),
      long_term_session: new FormControl(null),
      transfer_assisting_walker: new FormControl(''),
      // neck_rotation_comment: new FormControl(null),
      scope_session: new FormControl(null),
      recommended_duration: new FormControl(null),
      no_pt_sesssion_per_month: new FormControl(null),
      goals_acheived: new FormControl(null),
      exercise_checkbox: new FormControl(''),
      exercise_duration: new FormControl(null),
      exercise_details: new FormControl(null),
      tens_checkbox: new FormControl(),
      tens_duration: new FormControl(null),
      tens_details: new FormControl(null),
      ultrasound_checkbox: new FormControl(''),
      ultrasound_duration: new FormControl(null),
      ultrasound_details: new FormControl(null),
      hot_packs_checkbox: new FormControl(''),
      hot_packs_duration: new FormControl(null),
      hot_packs_details: new FormControl(''),
      stimulation_checkbox: new FormControl(''),
      stimulation_duration: new FormControl(null),
      stimulation_details: new FormControl(null),
      vibrator_checkbox: new FormControl(''),
      vibrator_duration: new FormControl(null),
      vibrator_details: new FormControl(null),
      tapping_checkbox: new FormControl(''),
      tapping_duration: new FormControl(null),
      tapping_details: new FormControl(null),
      chest_therapy_checkbox: new FormControl(''),
      chest_therapy_duration: new FormControl(null),
      chest_therapy_details: new FormControl(null),
      session_technique_others_checkbox: new FormControl(''),
      session_technique_others_details: new FormControl(null),
      attachment_others: new FormControl(null),
      re_date: new FormControl(''),
    });
  }

  ngOnInit(): void {
    for (let i = 1; i <= 30; i++) {
      this.session_per_month.push({ session_per_month: i + " sessions" });
    }
    for (let i = 1; i <= 24; i++) {
      this.duration.push({ duration: i });
    }
    this.pt_service.ptGetScopeSession().then((res: any) => {
      console.log(res);
      this.sessionList = res?.data;
      console.log("sessionList:", res?.data);
    })
    this.addMedication('add', 0);
    this.initialAssessementAdult.controls.exercise_duration.disable();
    this.initialAssessementAdult.controls.exercise_details.disable();
    this.initialAssessementAdult.controls.tens_duration.disable();
    this.initialAssessementAdult.controls.tens_details.disable();
    this.initialAssessementAdult.controls.stimulation_details.disable();
    this.initialAssessementAdult.controls.stimulation_duration.disable();
    this.initialAssessementAdult.controls.chest_therapy_details.disable();
    this.initialAssessementAdult.controls.chest_therapy_duration.disable();
    this.initialAssessementAdult.controls.hot_packs_duration.disable();
    this.initialAssessementAdult.controls.hot_packs_details.disable();
    this.initialAssessementAdult.controls.tapping_details.disable();
    this.initialAssessementAdult.controls.tapping_duration.disable();
    this.initialAssessementAdult.controls.vibrator_duration.disable();
    this.initialAssessementAdult.controls.vibrator_details.disable();
    this.initialAssessementAdult.controls.ultrasound_duration.disable();
    this.initialAssessementAdult.controls.ultrasound_details.disable();
    this.initialAssessementAdult.controls.session_technique_others_details.disable();
    this.initialAssessementAdult.controls.transfer_assisting_one.disable();
    this.initialAssessementAdult.controls.transfer_assisting_pair.disable();
    this.initialAssessementAdult.controls.transfer_assisting_others_description.disable();
    this.initialAssessementAdult.controls.short_term_session.disable();
    this.initialAssessementAdult.controls.long_term_session.disable();
    this.initialAssessementAdult.controls.rolling_remark.disable();
    this.initialAssessementAdult.controls.sitting_remark.disable();
    this.initialAssessementAdult.controls.standing_remark.disable();
    this.initialAssessementAdult.controls.gait_remark.disable();
    this.initialAssessementAdult.controls.balance_remark.disable();
    this.initialAssessementAdult.controls.static_sitting_remark.disable();
    this.initialAssessementAdult.controls.static_standing_remark.disable();
    this.initialAssessementAdult.controls.dynamic_sitting_remark.disable();
    this.initialAssessementAdult.controls.dynamic_standing_remark.disable();
    this.initialAssessementAdult.controls.pin_remark.disable();
    this.initialAssessementAdult.controls.light_touch_remark.disable();
    this.initialAssessementAdult.controls.blunt_remark.disable();
    this.initialAssessementAdult.controls.pressure_remark.disable();
  }

  addMedication(string: any, index: any) {
    // console.log(index);
    let vaccinationArray = this.getMedicationFormArray();
    if (string === 'add') {
      let newVaccinationArray = new FormGroup({
        medication: new FormControl(''),

      });
      let vaccinationArray = this.getMedicationFormArray();
      vaccinationArray.insert(index + 1, newVaccinationArray);
    } else {
      vaccinationArray.removeAt(index);
    }
  }
  keyPress(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
    }
}
  /* -------------------------------------------------------------------------- */
  /*                        // submit form data function                        */
  /* -------------------------------------------------------------------------- */
  submitAssessment(submitAssessmentForm){

    window.scrollTo(0, 0);
    this.initialAssessementAdult.controls.oriented_people.setValue(this.peoplelist);
    this.initialAssessementAdult.controls.oriented_time.setValue(this.timelist);
    this.initialAssessementAdult.controls.oriented_place.setValue(this.placelist);
    this.initialAssessementAdult.controls.swelling.setValue(this.swellingcheck);
    this.initialAssessementAdult.controls.scar.setValue(this.scarcheck);
    this.initialAssessementAdult.controls.worm.setValue(this.wormcheck);
    this.initialAssessementAdult.controls.infection.setValue(this.infectioncheck);
    this.initialAssessementAdult.controls.wound.setValue(this.woundcheck);
    this.initialAssessementAdult.controls.pain.setValue(this.paincheck);
    this.initialAssessementAdult.controls.others.setValue(this.otherscheck);

    let stringifyData = JSON.stringify(this.initialAssessementAdult.value);
    if (submitAssessmentForm.valid) {
    let formValuesObject = {
      adult_assessment_json: stringifyData,
      date: this.selectedDate ? moment(this.selectedDate).format('YYYY-MM-DD') : null
    }
    this.pt_service.ptAdultAssessment(formValuesObject).then((res: any) => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: res?.message });
      this.initialAssessementAdult.reset();
      this.peoplelist = ''
      this.timelist=''
      this.placelist=''
      this.scarcheck=''
      this.swellingcheck=''
      this.wormcheck=''
      this.woundcheck=''
      this.paincheck=''
      this.otherscheck=''
      this.infectioncheck=''
    })
    .catch((error) => {
      console.log(error)
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error?.message });
    });
  }
  else {
    this.messageService.add({
      severity: 'error', summary: 'Error', detail: 'Please Fill All the Details!',
    });
    }
  }
  getMedicationFormArray() {
    return this.initialAssessementAdult.get('assesment_json') as FormArray;
  }
  handleInput(event: any, controlName: string): void {
    console.log(event.target.value)
    if(controlName == 'people'){
      // this.initialAssessementAdult.controls.oriented_people.setValue(event.target.value);
      this.peoplelist = event.target.value;
      console.log(event.target.value)
      if(event.target.value=='Yes'){
        this.initialAssessementAdult.controls.oriented_people_remark.enable();
        }
      else{
        this.initialAssessementAdult.controls.oriented_people_remark.disable();
      }
      if(event.target.value=='Yes'){
        this.initialAssessementAdult.controls['oriented_people_remark'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementAdult.controls['oriented_people_remark'].clearValidators();
        this.initialAssessementAdult.controls['oriented_people_remark'].setValue(null);
      }
      this.initialAssessementAdult.controls['oriented_people_remark'].updateValueAndValidity();
    }
    else if(controlName=='time'){
      // this.initialAssessementAdult.controls.oriented_time.setValue(event.target.value);
      this.timelist = event.target.value;
      console.log(event.target.value)
      if(event.target.value=='Yes'){
        this.initialAssessementAdult.controls.oriented_time_remark.enable();
        }
      else{
        this.initialAssessementAdult.controls.oriented_time_remark.disable();
      }
      if(event.target.value=='Yes'){
        this.initialAssessementAdult.controls['oriented_time_remark'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementAdult.controls['oriented_time_remark'].clearValidators();
        this.initialAssessementAdult.controls['oriented_time_remark'].setValue(null);
      }
      this.initialAssessementAdult.controls['oriented_time_remark'].updateValueAndValidity();
    }
    else if(controlName=='place'){
      this.placelist = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if(event.target.value=='Yes'){
        this.initialAssessementAdult.controls.oriented_place_remark.enable();
        }
      else{
        this.initialAssessementAdult.controls.oriented_place_remark.disable();
      }
      if(event.target.value=='Yes'){
        this.initialAssessementAdult.controls['oriented_place_remark'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementAdult.controls['oriented_place_remark'].clearValidators();
        this.initialAssessementAdult.controls['oriented_place_remark'].setValue(null);
      }
      this.initialAssessementAdult.controls['oriented_place_remark'].updateValueAndValidity();
    }
    else if(controlName=='swelling'){
      this.swellingcheck = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if(event.target.value=='Yes'){
        this.initialAssessementAdult.controls.swelling_remark.enable();
        }
      else{
        this.initialAssessementAdult.controls.swelling_remark.disable();
      }
      if(event.target.value=='Yes'){
        this.initialAssessementAdult.controls['swelling_remark'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementAdult.controls['swelling_remark'].clearValidators();
        this.initialAssessementAdult.controls['swelling_remark'].setValue(null);
      }
      this.initialAssessementAdult.controls['swelling_remark'].updateValueAndValidity();
    }
    else if(controlName=='scar'){
      this.scarcheck = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if(event.target.value=='Yes'){
        this.initialAssessementAdult.controls.scar_remark.enable();
        }
      else{
        this.initialAssessementAdult.controls.scar_remark.disable();
      }
      if(event.target.value=='Yes'){
        this.initialAssessementAdult.controls['scar_remark'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementAdult.controls['scar_remark'].clearValidators();
        this.initialAssessementAdult.controls['scar_remark'].setValue(null);
      }
      this.initialAssessementAdult.controls['scar_remark'].updateValueAndValidity();
    }
    else if(controlName=='infection'){
      this.infectioncheck = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if(event.target.value=='Yes'){
        this.initialAssessementAdult.controls.infection_remark.enable();
        }
      else{
        this.initialAssessementAdult.controls.infection_remark.disable();
      }
      if(event.target.value=='Yes'){
        this.initialAssessementAdult.controls['infection_remark'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementAdult.controls['infection_remark'].clearValidators();
        this.initialAssessementAdult.controls['infection_remark'].setValue(null);
      }
      this.initialAssessementAdult.controls['infection_remark'].updateValueAndValidity();
    }
    else if(controlName=='worm'){
      this.wormcheck = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if(event.target.value=='Yes'){
        this.initialAssessementAdult.controls.worm_remark.enable();
        }
      else{
        this.initialAssessementAdult.controls.worm_remark.disable();
      }
      if(event.target.value=='Yes'){
        this.initialAssessementAdult.controls['worm_remark'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementAdult.controls['worm_remark'].clearValidators();
        this.initialAssessementAdult.controls['worm_remark'].setValue(null);
      }
      this.initialAssessementAdult.controls['worm_remark'].updateValueAndValidity();
    }
    else if(controlName=='wound'){
      this.woundcheck = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if(event.target.value=='Yes'){
        this.initialAssessementAdult.controls.wound_remark.enable();
        }
      else{
        this.initialAssessementAdult.controls.wound_remark.disable();
      }
      if(event.target.value=='Yes'){
        this.initialAssessementAdult.controls['wound_remark'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementAdult.controls['wound_remark'].clearValidators();
        this.initialAssessementAdult.controls['wound_remark'].setValue(null);
      }
      this.initialAssessementAdult.controls['wound_remark'].updateValueAndValidity();
    }
    else if(controlName=='pain'){
      this.paincheck = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if(event.target.value=='Yes'){
        this.initialAssessementAdult.controls.pain_remark.enable();
        }
      else{
        this.initialAssessementAdult.controls.pain_remark.disable();
      }
      if(event.target.checked){
        this.initialAssessementAdult.controls['pain_remark'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementAdult.controls['pain_remark'].clearValidators();
        this.initialAssessementAdult.controls['pain_remark'].setValue(null);
      }
      this.initialAssessementAdult.controls['pain_remark'].updateValueAndValidity();
    }
    else if(controlName=='others'){
      this.otherscheck = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if(event.target.value=='Yes'){
        this.initialAssessementAdult.controls.others_remark.enable();
        }
      else{
        this.initialAssessementAdult.controls.others_remark.disable();
      }
      if(event.target.value=='Yes'){
        this.initialAssessementAdult.controls['others_remark'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementAdult.controls['others_remark'].clearValidators();
        this.initialAssessementAdult.controls['others_remark'].setValue(null);
      }
      this.initialAssessementAdult.controls['others_remark'].updateValueAndValidity();
    }
    // Check if the checkbox is checked
    // const value = event.target.checked ? 'yes' : 'no';

    // Set the value of the form control
    // this.initialAssessementAdult.get(oriented_people)?.setValue(event.target.value);
    if (controlName == 'cough') {
      if (event.target.value == 'Yes') {
        console.log('called yes');
        this.initialAssessementAdult.controls.cough_remark.enable();
      } else {
        console.log('called no');
        this.initialAssessementAdult.controls.cough_remark.disable();
        this.initialAssessementAdult.controls.cough_remark.setValue(
          null
        );
      }
      if(event.target.checked){
        this.initialAssessementAdult.controls['cough_remark'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementAdult.controls['cough_remark'].clearValidators();
      }
      this
      .initialAssessementAdult.controls['cough_remark'].updateValueAndValidity();
    }
    if(controlName == 'intactlight'){
      switch(event.target.value){
        case 1:
          this.initialAssessementAdult.controls.intact_light.setValue(1);
          break;
        case 2:
          this.initialAssessementAdult.controls.intact_light.setValue(2);
          break;
        case 3:
          this.initialAssessementAdult.controls.intact_light.setValue(3);
          break;
        case 4:
          this.initialAssessementAdult.controls.intact_light.setValue(4);
          break;
      }
    }
    if(controlName == 'intactblunt'){
      switch(event.target.value){
        case 1:
          this.initialAssessementAdult.controls.intact_blunt.setValue(1);
          break;
        case 2:
          this.initialAssessementAdult.controls.intact_blunt.setValue(2);
          break;
        case 3:
          this.initialAssessementAdult.controls.intact_blunt.setValue(3);
          break;
        case 4:
          this.initialAssessementAdult.controls.intact_blunt.setValue(4);
          break;
      }
    }
    if(controlName == 'intactpressure'){
      switch(event.target.value){
        case 1:
          this.initialAssessementAdult.controls.intact_pressure.setValue(1);
          break;
        case 2:
          this.initialAssessementAdult.controls.intact_pressure.setValue(2);
          break;
        case 3:
          this.initialAssessementAdult.controls.intact_pressure.setValue(3);
          break;
        case 4:
          this.initialAssessementAdult.controls.intact_pressure.setValue(4);
          break;
      }
    }
    if(controlName == 'intactpin'){
      switch(event.target.value){
        case 1:
          this.initialAssessementAdult.controls.intact_pin.setValue(1);
          break;
        case 2:
          this.initialAssessementAdult.controls.intact_pin.setValue(2);
          break;
        case 3:
          this.initialAssessementAdult.controls.intact_pin.setValue(3);
          break;
        case 4:
          this.initialAssessementAdult.controls.intact_pin.setValue(4);
          break;
      }
    }
    if(controlName == 'independent'){
      switch(event.target.value){
        case 1:
          this.initialAssessementAdult.controls.rolling_I.setValue(1);
          break;
        case 2:
          this.initialAssessementAdult.controls.rolling_I.setValue(2);
          break;
        case 3:
          this.initialAssessementAdult.controls.rolling_I.setValue(3);
          break;
        case 4:
          this.initialAssessementAdult.controls.rolling_I.setValue(4);
          break;
      }}
      if(controlName == 'independentsitting'){
        switch(event.target.value){
          case 1:
            this.initialAssessementAdult.controls.sitting_I.setValue(1);
            break;
          case 2:
            this.initialAssessementAdult.controls.sitting_I.setValue(2);
            break;
          case 3:
            this.initialAssessementAdult.controls.sitting_I.setValue(3);
            break;
          case 4:
            this.initialAssessementAdult.controls.sitting_I.setValue(4);
            break;
        }
    }
    if(controlName == 'independentstanding'){
      switch(event.target.value){
        case 1:
          this.initialAssessementAdult.controls.standing_I.setValue(1);
          break;
        case 2:
          this.initialAssessementAdult.controls.standing_I.setValue(2);
          break;
        case 3:
          this.initialAssessementAdult.controls.standing_I.setValue(3);
          break;
        case 4:
          this.initialAssessementAdult.controls.standing_I.setValue(4);
          break;
      }
  }
  if(controlName == 'independentgait'){
    switch(event.target.value){
      case 1:
        this.initialAssessementAdult.controls.gait_I.setValue(1);
        break;
      case 2:
        this.initialAssessementAdult.controls.gait_I.setValue(2);
        break;
      case 3:
        this.initialAssessementAdult.controls.gait_I.setValue(3);
        break;
      case 4:
        this.initialAssessementAdult.controls.gait_I.setValue(4);
        break;
    }
}
if(controlName == 'independentbalance'){
  switch(event.target.value){
    case 1:
      this.initialAssessementAdult.controls.balance_I.setValue(1);
      break;
    case 2:
      this.initialAssessementAdult.controls.balance_I.setValue(2);
      break;
    case 3:
      this.initialAssessementAdult.controls.balance_I.setValue(3);
      break;
    case 4:
      this.initialAssessementAdult.controls.balance_I.setValue(4);
      break;
  }
}
if(controlName == 'independentstatic'){
  switch(event.target.value){
    case 1:
      this.initialAssessementAdult.controls.static_sitting_I.setValue(1);
      break;
    case 2:
      this.initialAssessementAdult.controls.static_sitting_I.setValue(2);
      break;
    case 3:
      this.initialAssessementAdult.controls.static_sitting_I.setValue(3);
      break;
    case 4:
      this.initialAssessementAdult.controls.static_sitting_I.setValue(4);
      break;
  }
}
if(controlName == 'independentstaticstanding'){
  switch(event.target.value){
    case 1:
      this.initialAssessementAdult.controls.static_standing_I.setValue(1);
      break;
    case 2:
      this.initialAssessementAdult.controls.static_standing_I.setValue(2);
      break;
    case 3:
      this.initialAssessementAdult.controls.static_standing_I.setValue(3);
      break;
    case 4:
      this.initialAssessementAdult.controls.static_standing_I.setValue(4);
      break;
  }
}
if(controlName == 'independentdynamicsitting'){
  switch(event.target.value){
    case 1:
      this.initialAssessementAdult.controls.dynamic_sitting_I.setValue(1);
      break;
    case 2:
      this.initialAssessementAdult.controls.dynamic_sitting_I.setValue(2);
      break;
    case 3:
      this.initialAssessementAdult.controls.dynamic_sitting_I.setValue(3);
      break;
    case 4:
      this.initialAssessementAdult.controls.dynamic_sitting_I.setValue(4);
      break;
  }
}
if(controlName == 'independentdynamicstanding'){
  switch(event.target.value){
    case 1:
      this.initialAssessementAdult.controls.dynamic_standing_I.setValue(1);
      break;
    case 2:
      this.initialAssessementAdult.controls.dynamic_standing_I.setValue(2);
      break;
    case 3:
      this.initialAssessementAdult.controls.dynamic_standing_I.setValue(3);
      break;
    case 4:
      this.initialAssessementAdult.controls.dynamic_standing_I.setValue(4);
      break;
  }
}


    if (controlName === 'transferassisting') {
      this.initialAssessementAdult.get('transfer_assisting_pair').disable();
      this.initialAssessementAdult.get('transfer_assisting_pair').setValue(null);
      if (event.target.value === '2') {
        this.initialAssessementAdult.get('transfer_assisting_pair').enable();
        this.initialAssessementAdult.get('transfer_assisting_pair').setValidators([Validators.required]);
      }
      else {
        this.initialAssessementAdult.get('transfer_assisting_pair').disable();
        this.initialAssessementAdult.get('transfer_assisting_pair').setValue(null);
      }
      this.initialAssessementAdult.get('transfer_assisting_pair').updateValueAndValidity();
    }
    if(controlName == 'transferassisting'){
if(event.target.value == 5){
  this.initialAssessementAdult.controls.transfer_assisting_others_description.enable();
  this.initialAssessementAdult.controls['transfer_assisting_others_description'].setValidators([Validators.required]);

}else{this.initialAssessementAdult.controls.transfer_assisting_others_description.disable();
  this.initialAssessementAdult.controls.transfer_assisting_others_description.reset();
  this.initialAssessementAdult.controls['transfer_assisting_others_description'].clearValidators();
  this.initialAssessementAdult.controls['transfer_assisting_others_description'].setValue(null);}
  this.initialAssessementAdult.controls['transfer_assisting_others_description'].updateValueAndValidity();

  switch(event.target.value){
    case 1:
      this.initialAssessementAdult.controls.transfer_assisting.setValue(1);
      break;
    case 2:
      this.initialAssessementAdult.controls.transfer_assisting.setValue(2);
      break;
    case 3:
      this.initialAssessementAdult.controls.transfer_assisting.setValue(3);
      break;
    case 4:
      this.initialAssessementAdult.controls.transfer_assisting.setValue(4);
      break;
    case 5:
      this.initialAssessementAdult.controls.transfer_assisting.setValue(5);
      break;
  }
}
// if(controlName == 'shortterm'){
//   this.initialAssessementAdult.controls.short_term_session.enable();
//   this.initialAssessementAdult.controls['short_term_session'].setValidators([Validators.required]);
// }else{this.initialAssessementAdult.controls.short_term_session.disable();
//   this.initialAssessementAdult.controls.short_term_session.reset();
//   this.initialAssessementAdult.controls['short_term_session'].clearValidators();
//   this.initialAssessementAdult.controls['short_term_session'].setValue(null);}
//   this.initialAssessementAdult.controls['short_term_session'].updateValueAndValidity();

// if(controlName == 'longterm'){this.initialAssessementAdult.controls.long_term_session.enable();
//   this.initialAssessementAdult.controls['long_term_session'].setValidators([Validators.required]);
//   }  else{this.initialAssessementAdult.controls.long_term_session.disable();
//     this.initialAssessementAdult.controls.long_term_session.reset();

//     this.initialAssessementAdult.controls['long_term_session'].clearValidators();
//     this.initialAssessementAdult.controls['long_term_session'].setValue(null);}
//     this.initialAssessementAdult.controls['long_term_session'].updateValueAndValidity();
if (controlName == 'shortterm') {
  // this.timelist = event.target.value;
  console.log(event.target.value)
  if (event.target.checked) {
    this.initialAssessementAdult.controls.short_term_session.enable();
  }
  else {
    this.initialAssessementAdult.controls.short_term_session.disable();
  }
  if (event.target.checked) {
    this.initialAssessementAdult.controls['short_term_session'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementAdult.controls['short_term_session'].clearValidators();
    this.initialAssessementAdult.controls['short_term_session'].setValue(null);
  }
  this.initialAssessementAdult.controls['short_term_session'].updateValueAndValidity();
}
if (controlName == 'longterm') {
  // this.timelist = event.target.value;
  console.log(event.target.value)
  if (event.target.checked) {
    this.initialAssessementAdult.controls.long_term_session.enable();
    // this.initialAssessementAdult.get(['transfer_assisting', 'long_term_session']).setValidators([Validators.required]);
  }
  else {
    this.initialAssessementAdult.controls.long_term_session.disable();

  }
  if (event.target.checked) {
    this.initialAssessementAdult.controls['long_term_session'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementAdult.controls['long_term_session'].clearValidators();
    this.initialAssessementAdult.controls['long_term_session'].setValue(null);
  }
  this.initialAssessementAdult.controls['long_term_session'].updateValueAndValidity();
}

    if (controlName == 'secretion') {
      if (event.target.value == 'Yes') {
        console.log('called yes');
        this.initialAssessementAdult.controls.chest_secretion_remark.enable();
      } else {
        console.log('called no');
        this.initialAssessementAdult.controls.chest_secretion_remark.disable();
        this.initialAssessementAdult.controls.chest_secretion_remark.setValue(
          null
        );
      }
      if(event.target.checked){
        this.initialAssessementAdult.controls['chest_secretion_remark'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementAdult.controls['chest_secretion_remark'].clearValidators();
      }
      this.initialAssessementAdult.controls['chest_secretion_remark'].updateValueAndValidity();
    }
    else if (controlName == 'exercise') {
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.initialAssessementAdult.controls.exercise_duration.enable();
        this.initialAssessementAdult.controls.exercise_details.enable();
      }
        else {
          this.initialAssessementAdult.controls.exercise_duration.disable();
          this.initialAssessementAdult.controls.exercise_details.disable();
        }
        if(event.target.checked){
          this.initialAssessementAdult.controls['exercise_duration'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementAdult.controls['exercise_duration'].clearValidators();
          this.initialAssessementAdult.controls['exercise_duration'].setValue(null);
        }
        this.initialAssessementAdult.controls['exercise_duration'].updateValueAndValidity();
        if(event.target.checked){
          this.initialAssessementAdult.controls['exercise_details'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementAdult.controls['exercise_details'].clearValidators();
          this.initialAssessementAdult.controls['exercise_details'].setValue(null);
        }
        this.initialAssessementAdult.controls['exercise_details'].updateValueAndValidity();

      }



    else if (controlName == 'ten') {
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.initialAssessementAdult.controls.tens_duration.enable();
        this.initialAssessementAdult.controls.tens_details.enable();
      }
        else {
          this.initialAssessementAdult.controls.tens_duration.disable();
          this.initialAssessementAdult.controls.tens_details.disable();
        }
        if(event.target.checked){
          this.initialAssessementAdult.controls['tens_duration'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementAdult.controls['tens_details'].clearValidators();
          this.initialAssessementAdult.controls['tens_details'].setValue(null);
        }
        this.initialAssessementAdult.controls['tens_duration'].updateValueAndValidity();
        if(event.target.checked){
          this.initialAssessementAdult.controls['tens_details'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementAdult.controls['tens_duration'].clearValidators();
          this.initialAssessementAdult.controls['tens_duration'].setValue(null);
        }
        this.initialAssessementAdult.controls['tens_details'].updateValueAndValidity();

    } else if (controlName == 'ultrasound') {
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.initialAssessementAdult.controls.ultrasound_duration.enable();
        this.initialAssessementAdult.controls.ultrasound_details.enable();
      }
        else {
          this.initialAssessementAdult.controls.ultrasound_details.disable();
          this.initialAssessementAdult.controls.ultrasound_duration.disable();
        }
        if(event.target.checked){
          this.initialAssessementAdult.controls['ultrasound_duration'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementAdult.controls['ultrasound_duration'].clearValidators();
          this.initialAssessementAdult.controls['ultrasound_duration'].setValue(null);
        }
        this.initialAssessementAdult.controls['ultrasound_duration'].updateValueAndValidity();
        if(event.target.checked){
          this.initialAssessementAdult.controls['ultrasound_details'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementAdult.controls['ultrasound_details'].clearValidators();
          this.initialAssessementAdult.controls['ultrasound_details'].setValue(null);
        }
        this.initialAssessementAdult.controls['ultrasound_details'].updateValueAndValidity();

    } else if (controlName == 'packs') {
      // if (event.target.value == 'hotpacks') {
      //   console.log('hotpacks');
      //   this.hotpacks = !this.hotpacks;
      // }
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.initialAssessementAdult.controls.hot_packs_details.enable();
        this.initialAssessementAdult.controls.hot_packs_duration.enable();
      }
        else {
          this.initialAssessementAdult.controls.hot_packs_details.disable();
          this.initialAssessementAdult.controls.hot_packs_duration.disable();
        }
        if(event.target.checked){
          this.initialAssessementAdult.controls['hot_packs_duration'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementAdult.controls['hot_packs_duration'].clearValidators();
          this.initialAssessementAdult.controls['hot_packs_duration'].setValue(null);
        }
        this.initialAssessementAdult.controls['hot_packs_duration'].updateValueAndValidity();
        if(event.target.checked){
          this.initialAssessementAdult.controls['hot_packs_details'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementAdult.controls['hot_packs_details'].clearValidators();
          this.initialAssessementAdult.controls['hot_packs_details'].setValue(null);
        }
        this.initialAssessementAdult.controls['hot_packs_details'].updateValueAndValidity();
    } else if (controlName == 'stimulation') {
      // if (event.target.value == 'stimulation') {
      //   console.log('stimulation');
      //   this.stimulation = !this.stimulation;
      // }
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.initialAssessementAdult.controls.stimulation_details.enable();
        this.initialAssessementAdult.controls.stimulation_duration.enable();
      }
        else {
          this.initialAssessementAdult.controls.stimulation_duration.disable();
          this.initialAssessementAdult.controls.stimulation_details.disable();
        }
        if(event.target.checked){
          this.initialAssessementAdult.controls['stimulation_duration'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementAdult.controls['stimulation_duration'].clearValidators();
          this.initialAssessementAdult.controls['stimulation_duration'].setValue(null);
        }
        this.initialAssessementAdult.controls['stimulation_duration'].updateValueAndValidity();
        if(event.target.checked){
          this.initialAssessementAdult.controls['stimulation_details'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementAdult.controls['stimulation_details'].clearValidators();
          this.initialAssessementAdult.controls['stimulation_details'].setValue(null);
        }
        this.initialAssessementAdult.controls['stimulation_details'].updateValueAndValidity();
    } else if (controlName == 'vibrator') {
      // if (event.target.value == 'vibrator') {
      //   console.log('vibrator');
      //   this.vibrator = !this.vibrator;
      // }
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.initialAssessementAdult.controls.vibrator_duration.enable();
        this.initialAssessementAdult.controls.vibrator_details.enable();
      }
        else {
          this.initialAssessementAdult.controls.vibrator_details.disable();
          this.initialAssessementAdult.controls.vibrator_duration.disable();
        }
        if(event.target.checked){
          this.initialAssessementAdult.controls['vibrator_duration'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementAdult.controls['vibrator_duration'].clearValidators();
          this.initialAssessementAdult.controls['vibrator_duration'].setValue(null);
        }
        this.initialAssessementAdult.controls['vibrator_duration'].updateValueAndValidity();
        if(event.target.checked){
          this.initialAssessementAdult.controls['vibrator_details'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementAdult.controls['vibrator_details'].clearValidators();
          this.initialAssessementAdult.controls['vibrator_details'].setValue(null);
        }
        this.initialAssessementAdult.controls['vibrator_details'].updateValueAndValidity();
    } else if (controlName == 'tapping') {
      // if (event.target.value == 'tapping') {
      //   console.log('tapping');
      //   this.tapping = !this.tapping;
      // }
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.initialAssessementAdult.controls.tapping_duration.enable();
        this.initialAssessementAdult.controls.tapping_details.enable();
      }
        else {
          this.initialAssessementAdult.controls.tapping_duration.disable();
          this.initialAssessementAdult.controls.tapping_details.disable();
        }
        if(event.target.checked){
          this.initialAssessementAdult.controls['tapping_duration'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementAdult.controls['tapping_duration'].clearValidators();
          this.initialAssessementAdult.controls['tapping_duration'].setValue(null);

        }
        this.initialAssessementAdult.controls['tapping_duration'].updateValueAndValidity();
        if(event.target.checked){
          this.initialAssessementAdult.controls['tapping_details'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementAdult.controls['tapping_details'].clearValidators();
          this.initialAssessementAdult.controls['tapping_details'].setValue(null);
        }
        this.initialAssessementAdult.controls['tapping_details'].updateValueAndValidity();
    } else if (controlName == 'chest') {
      // if (event.target.value == 'therapy') {
      //   console.log('therapy');
      //   this.therapy = !this.therapy;
      // }
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.initialAssessementAdult.controls.chest_therapy_details.enable();
        this.initialAssessementAdult.controls.chest_therapy_duration.enable();
      }
        else {
          this.initialAssessementAdult.controls.chest_therapy_duration.disable();
          this.initialAssessementAdult.controls.chest_therapy_details.disable();
        }
        if(event.target.checked){
          this.initialAssessementAdult.controls['chest_therapy_duration'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementAdult.controls['chest_therapy_duration'].clearValidators();
          this.initialAssessementAdult.controls['chest_therapy_duration'].setValue(null);
        }
        this.initialAssessementAdult.controls['chest_therapy_duration'].updateValueAndValidity();
        if(event.target.checked){
          this.initialAssessementAdult.controls['chest_therapy_details'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementAdult.controls['chest_therapy_details'].clearValidators();
          this.initialAssessementAdult.controls['chest_therapy_details'].setValue(null);
        }
        this.initialAssessementAdult.controls['chest_therapy_details'].updateValueAndValidity();
    }
    else if (controlName=='other'){

      if(event.target.checked){
      this.initialAssessementAdult.controls.session_technique_others_details.enable();
      }
    else{
      this.initialAssessementAdult.controls.session_technique_others_details.disable();
    }
    if(event.target.checked){
      this.initialAssessementAdult.controls['session_technique_others_details'].setValidators([Validators.required]);
    }
    else {
      this.initialAssessementAdult.controls['session_technique_others_details'].clearValidators();
      this.initialAssessementAdult.controls['session_technique_others_details'].setValue(null);
    }
    this.initialAssessementAdult.controls['session_technique_others_details'].updateValueAndValidity();
  }
  else if (controlName=='box'){

    if(event.target.checked){
    this.initialAssessementAdult.controls.others.enable();
    }
  else{
    this.initialAssessementAdult.controls.others.disable();
  }
  if(event.target.checked){
    this.initialAssessementAdult.controls['others'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementAdult.controls['others'].clearValidators();
    this.initialAssessementAdult.controls['others'].setValue(null);
  }
  this.initialAssessementAdult.controls['others'].updateValueAndValidity();
}
if (controlName == 'independent') {
  if (event.target.checked) {
    console.log('called yes');
    this.initialAssessementAdult.controls.rolling_remark.enable();
  } else {
    console.log('called no');
    this.initialAssessementAdult.controls.rolling_remark.disable();
    this.initialAssessementAdult.controls.rolling_remark.setValue(
      null
    );
  }
  if(event.target.checked){
    this.initialAssessementAdult.controls['rolling_remark'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementAdult.controls['rolling_remark'].clearValidators();
  }
  this.initialAssessementAdult.controls['rolling_remark'].updateValueAndValidity();
}
if (controlName == 'independentsitting') {
  if (event.target.checked) {
    console.log('called yes');
    this.initialAssessementAdult.controls.sitting_remark.enable();
  } else {
    console.log('called no');
    this.initialAssessementAdult.controls.sitting_remark.disable();
    this.initialAssessementAdult.controls.sitting_remark.setValue(
      null
    );
  }
  if(event.target.checked){
    this.initialAssessementAdult.controls['sitting_remark'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementAdult.controls['sitting_remark'].clearValidators();
  }
  this.initialAssessementAdult.controls['sitting_remark'].updateValueAndValidity();
}
if (controlName == 'independentstanding') {
  if (event.target.checked) {
    console.log('called yes');
    this.initialAssessementAdult.controls.standing_remark.enable();
  } else {
    console.log('called no');
    this.initialAssessementAdult.controls.standing_remark.disable();
    this.initialAssessementAdult.controls.standing_remark.setValue(
      null
    );
  }
  if(event.target.checked){
    this.initialAssessementAdult.controls['standing_remark'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementAdult.controls['standing_remark'].clearValidators();
  }
  this.initialAssessementAdult.controls['standing_remark'].updateValueAndValidity();
}
if (controlName == 'independentgait') {
  if (event.target.checked) {
    console.log('called yes');
    this.initialAssessementAdult.controls.gait_remark.enable();
  } else {
    console.log('called no');
    this.initialAssessementAdult.controls.gait_remark.disable();
    this.initialAssessementAdult.controls.gait_remark.setValue(
      null
    );
  }
  if(event.target.checked){
    this.initialAssessementAdult.controls['gait_remark'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementAdult.controls['gait_remark'].clearValidators();
  }
  this.initialAssessementAdult.controls['gait_remark'].updateValueAndValidity();
}
if (controlName == 'independentbalance') {
  if (event.target.checked) {
    console.log('called yes');
    this.initialAssessementAdult.controls.balance_remark.enable();
  } else {
    console.log('called no');
    this.initialAssessementAdult.controls.balance_remark.disable();
    this.initialAssessementAdult.controls.balance_remark.setValue(
      null
    );
  }
  if(event.target.checked){
    this.initialAssessementAdult.controls['balance_remark'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementAdult.controls['balance_remark'].clearValidators();
  }
  this.initialAssessementAdult.controls['balance_remark'].updateValueAndValidity();
}
if (controlName == 'independentstatic') {
  if (event.target.checked) {
    console.log('called yes');
    this.initialAssessementAdult.controls.static_sitting_remark.enable();
  } else {
    console.log('called no');
    this.initialAssessementAdult.controls.static_sitting_remark.disable();
    this.initialAssessementAdult.controls.static_sitting_remark.setValue(
      null
    );
  }
  if(event.target.checked){
    this.initialAssessementAdult.controls['static_sitting_remark'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementAdult.controls['static_sitting_remark'].clearValidators();
  }
  this.initialAssessementAdult.controls['static_sitting_remark'].updateValueAndValidity();
}
if (controlName == 'independentstaticstanding') {
  if (event.target.checked) {
    console.log('called yes');
    this.initialAssessementAdult.controls.static_standing_remark.enable();
  } else {
    console.log('called no');
    this.initialAssessementAdult.controls.static_standing_remark.disable();
    this.initialAssessementAdult.controls.static_standing_remark.setValue(
      null
    );
  }
  if(event.target.checked){
    this.initialAssessementAdult.controls['static_standing_remark'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementAdult.controls['static_standing_remark'].clearValidators();
  }
  this.initialAssessementAdult.controls['static_standing_remark'].updateValueAndValidity();
}
if (controlName == 'independentdynamicsitting') {
  if (event.target.checked) {
    console.log('called yes');
    this.initialAssessementAdult.controls.dynamic_sitting_remark.enable();
  } else {
    console.log('called no');
    this.initialAssessementAdult.controls.dynamic_sitting_remark.disable();
    this.initialAssessementAdult.controls.dynamic_sitting_remark.setValue(
      null
    );
  }
  if(event.target.checked){
    this.initialAssessementAdult.controls['dynamic_sitting_remark'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementAdult.controls['dynamic_sitting_remark'].clearValidators();
  }
  this.initialAssessementAdult.controls['dynamic_sitting_remark'].updateValueAndValidity();
}
if (controlName == 'independentdynamicstanding') {
  if (event.target.checked) {
    console.log('called yes');
    this.initialAssessementAdult.controls.dynamic_standing_remark.enable();
  } else {
    console.log('called no');
    this.initialAssessementAdult.controls.dynamic_standing_remark.disable();
    this.initialAssessementAdult.controls.dynamic_standing_remark.setValue(
      null
    );
  }
  if(event.target.checked){
    this.initialAssessementAdult.controls['dynamic_standing_remark'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementAdult.controls['dynamic_standing_remark'].clearValidators();
  }
  this.initialAssessementAdult.controls['dynamic_standing_remark'].updateValueAndValidity();
}
if (controlName == 'intactlight') {
  if (event.target.checked) {
    console.log('called yes');
    this.initialAssessementAdult.controls.light_touch_remark.enable();
  } else {
    console.log('called no');
    this.initialAssessementAdult.controls.light_touch_remark.disable();
    this.initialAssessementAdult.controls.light_touch_remark.setValue(
      null
    );
  }
  if(event.target.checked){
    this.initialAssessementAdult.controls['light_touch_remark'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementAdult.controls['light_touch_remark'].clearValidators();
  }
  this.initialAssessementAdult.controls['light_touch_remark'].updateValueAndValidity();
}
if (controlName == 'intactblunt') {
  if (event.target.checked) {
    console.log('called yes');
    this.initialAssessementAdult.controls.blunt_remark.enable();
  } else {
    console.log('called no');
    this.initialAssessementAdult.controls.b.disable();
    this.initialAssessementAdult.controls.blunt_remark.setValue(
      null
    );
  }
  if(event.target.checked){
    this.initialAssessementAdult.controls['blunt_remark'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementAdult.controls['blunt_remark'].clearValidators();
  }
  this.initialAssessementAdult.controls['blunt_remark'].updateValueAndValidity();
}
if (controlName == 'intactpressure') {
  if (event.target.checked) {
    console.log('called yes');
    this.initialAssessementAdult.controls.pressure_remark.enable();
  } else {
    console.log('called no');
    this.initialAssessementAdult.controls.pressure_remark.disable();
    this.initialAssessementAdult.controls.pressure_remark.setValue(
      null
    );
  }
  if(event.target.checked){
    this.initialAssessementAdult.controls['pressure_remark'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementAdult.controls['pressure_remark'].clearValidators();
  }
  this.initialAssessementAdult.controls['pressure_remark'].updateValueAndValidity();
}
if (controlName == 'intactpin') {
  if (event.target.checked) {
    console.log('called yes');
    this.initialAssessementAdult.controls.pin_remark.enable();
  } else {
    console.log('called no');
    this.initialAssessementAdult.controls.pin_remark.disable();
    this.initialAssessementAdult.controls.pin_remark.setValue(
      null
    );
  }
  if(event.target.checked){
    this.initialAssessementAdult.controls['pin_remark'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementAdult.controls['pin_remark'].clearValidators();
  }
  this.initialAssessementAdult.controls['pin_remark'].updateValueAndValidity();
}

}
}

