import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MaikaListService } from 'src/app/services/maika-list.service';

@Component({
  selector: 'app-user-registration-listing',
  templateUrl: './user-registration-listing.component.html',
  styleUrls: ['./user-registration-listing.component.scss']
})
export class UserRegistrationListingComponent implements OnInit {

  breadcrumb = [
    {
      title: 'Initial Assessment ',

    }
  ]

  filterVal: string;
  userreglist:any=[];
  loading: any;
  constructor(private user_reg_list : MaikaListService,private spinner:NgxSpinnerService) { }

  getAllData(){
    this.spinner.show()
    this.user_reg_list.getRegistrationData().subscribe((res :any)=>{
      this.spinner.hide()
      console.log("Response",res);
      this.userreglist=res.data;
      console.log("User Registration",this.userreglist);
      this.userreglist.map((ele: any)=>{
        console.log(ele);
      })
    },(error: any) => {
      this.spinner.hide()

      // Handle any errors that occurred during the API call
    
      
    })
  }
  ngOnInit(): void {
    this.getAllData();
  }
  formatDateTime(date: string | undefined): string {
    if (date) {
      const formattedDate = new Date(date);
      console.log("formattedDate",formattedDate);
      const year = formattedDate.getFullYear();
      const month = ('0' + (formattedDate.getMonth() + 1)).slice(-2);
      const day = ('0' + formattedDate.getDate()).slice(-2);
      const hours = ('0' + formattedDate.getHours()).slice(-2);
      const minutes = ('0' + formattedDate.getMinutes()).slice(-2);
      const seconds = ('0' + formattedDate.getSeconds()).slice(-2);

      // return `${hours}:${minutes}:${seconds}`;
      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }
    return '-';
  }
  reset(dt) {
    dt.reset();
    this.filterVal = '';
  }
}
