import { Component, OnInit } from '@angular/core';
import { PtServiceService } from 'src/app/services/pt-service.service';

@Component({
  selector: 'app-pt-assesment-pedia-listing',
  templateUrl: './pt-assesment-pedia-listing.component.html',
  styleUrls: ['./pt-assesment-pedia-listing.component.scss']
})
export class PtAssesmentPediaListingComponent implements OnInit {
  breadcrumb = [
    {
      title: 'PT Initial Assessment Pedia',
      subTitle: 'Dashboard'
    }
  ]                         
  date:any;
  data: any;
  displayMaximizable: boolean = false;
  viewDetail: any = [];
  ptAssListData: any = [];
  medical:any=[];
  pediadata:any=[];
  loading: any;
  viewDetailJson:any;
  filterVal: string;
  medication:any=[];
  dateArray:any = [];
  assessmentJson: any;
  tooltipText: string = 'Initial  Adult Assessment';
  constructor(private pediaassesment :PtServiceService) { }

  ngOnInit(): void {
    this.getAllData()
  }
  details: any = [];
  showMaximizableDialog(data: any) {
    this.details = [];
    this.viewDetail = [];
    this.viewDetail.push(data)
   this.displayMaximizable = true;
   console.log("veiwdetails",this.viewDetail);
   this.ptAssListData.map((res: any) => {
    console.log("showwww", data, "......", res.data)
    if (res.data == data) {
      debugger
      console.log("view detail:", res.length);
      this.viewDetail.push(res);
      // this.relatives.push(JSON.parse(res?.relatives_name_json));
      // this.viewDetail.push(JSON.parse(res?.assesment_json));
      console.log("viewDetail",this.viewDetail);
      // this.medical = res?.pediadata;
      // console.log("medical",this.medical);
      this.assessmentJson =  JSON.parse(JSON.parse(this.viewDetail.pedia_assessment_json) );
      console.log(this.assessmentJson);
    }});
  }
 getAllData() {

  this.pediaassesment.ptpediaassessment().subscribe((res: any) => {
   
    this.ptAssListData = res?.data;
    console.log("pt ass data", res.data);
    this.ptAssListData?.map((ele: any,key:any) => {
        if(ele?.pedia_assessment_json) {
          if(typeof JSON.parse(ele?.pedia_assessment_json) == "object") {
            this.ptAssListData[key] = {...JSON.parse(ele?.pedia_assessment_json),...ele};
          };
          if(typeof JSON.parse(ele?.pedia_assessment_json) == "string") {
            this.ptAssListData[key] = {...JSON.parse(JSON.parse(ele?.pedia_assessment_json)),...ele};
          }
        }
    });
    console.log("Assessment JSON",this.assessmentJson)

    console.log('pediadata',this.pediadata);


  })
 }
 reset(dt) {
  dt.reset();
  this.filterVal = '';
}
}
