<p-toast></p-toast>

<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">
    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
        <h1 style="text-transform: capitalize;">{{ Content.title }}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item">
                {{ Content.subTitle }}
            </li>
            <li class="item" style="text-transform: capitalize;">{{ Content.title }}</li>
        </ol>
    </div>

    <div class="container" style="min-height: 500px;">
        <div class="row">
            <div class="col-6 col-md-4">
                <!-- Multi-select dropdown for selecting hashtags -->
                <div>
                    <p-multiSelect [options]="hastag" [(ngModel)]="selectedCities" [filter]="true" optionLabel="name" placeholder="Select Hashtags"></p-multiSelect>
                </div>
            </div>
            <div class="col-6 col-md-8">
                <!-- Button to open camera -->
                <div class="d-flex align-items-center justify-content-end camera-btn">
                    <button class="p-button" type="button" (click)="openCamera(true , $event)">
                        <!-- <button class="p-button" type="button" (click)="openDialog()"> -->

                        <i class="fas fa-camera"></i> <span class="ms-2">Open Camera</span> 
                    </button>
                </div>
            </div>
        </div>
        <!-- Card section -->
        <div class="row mt-3" *ngIf="showcard">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <!-- Image previews -->
                        <div class="imgPreviewWrapper d-flex flex-wrap">
                            <div class="img-preview" *ngFor="let img of capturedByCamera">
                                <a (click)="deletecapture(img)" class="delete-icon">✖</a>
                                <img [src]="img" alt="Preview">
                            </div>
                        </div>
                        <!-- Selected hashtags -->
                        <div class="selected-hashtags mt-2">
                            <p *ngFor="let data of selectedCities" class="hashtag">#{{data?.name}}</p>
                        </div>
                        <!-- Upload button -->
                        <div class="upload-button mt-2">
                            <p-button (click)="uploadImages()" class="btn-upload">Upload</p-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        
        <!-- Owl Carousel section -->
        <div class="feedback-slides mt-3">
            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide *ngFor="let data of babyImgDetails">
                    <div class="card">
                        <img [src]="urlsofImg + data.image_name" class="card-img-top" alt="...">
                        <div class="card-body p-2 captureImage">
                            <h6 class="card-title">{{ data.tags }}</h6>
                            <p class="card-text">
                                <small>Clicked By: {{ data.created_by_user_name }}</small><br>
                                <small>Created At: {{ data.created_at | date:'dd-MM-yyyy' || '-' }}</small>
                            </p>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
    <p-dialog header="Capture Photo" [(visible)]="openCameraforUpload" [modal]="true" [draggable]="false"
    [closable]="false" [resizable]="false" id="capturewin">
    <div>
        <button type="button" class="close-button" (click)="shutDownCamera()" aria-label="Close"></button>
        <video #video autoplay="true" playsinline="true"></video>
        <canvas #canvas style="display:none;"></canvas>
    </div>
    <div class="">
        <a href="" class="backButton" (click)="openCamera(null , $event)">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#e28593" d="M142.9 142.9c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5c0 0 0 0 0 0H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5c7.7-21.8 20.2-42.3 37.8-59.8zM16 312v7.6 .7V440c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l41.6-41.6c87.6 86.5 228.7 86.2 315.8-1c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.2 62.2-162.7 62.5-225.3 1L185 329c6.9-6.9 8.9-17.2 5.2-26.2s-12.5-14.8-22.2-14.8H48.4h-.7H40c-13.3 0-24 10.7-24 24z"/></svg>        </a>
        <a class="captureButton" (click)="capture()"><i class="fas fa-camera"></i>
        </a>
    </div>
    <div class="d-flex align-items-start">
        <div class=" imgPreview" *ngFor="let img of capturedByCamera">
            <a (click)="deletecapture(img)">✖</a>
            <img [src]="img" alt="">
        </div>
    </div>
</p-dialog>

<!-- <button (click)="openDialog()">Open Webcam Dialog</button>

<p-dialog header="Capture Photo" [(visible)]="openCameraforUpload" [modal]="true" [draggable]="false"
    [closable]="false" [resizable]="false" id="capturewin">
    <div>
        <button type="button" class="close-button" (click)="shutDownCamera()" aria-label="Close"></button>
        <webcam *ngIf="openCamera"
            (imageCapture)="handleImage($event)"
            [allowCameraSwitch]="true"
            [switchCamera]="switchCamera">
        </webcam>
    </div>
    <div>
        <a href="">
            <svg fill="#000000" class="backButton" version="1.1" id="Capa_1"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 368.553 368.553" xml:space="preserve">
            </svg>
        </a>
        <a class="captureButton" (click)="triggerSnapshot()"><i class="fas fa-camera"></i></a>
    </div>
    <div class="d-flex align-items-start">
        <div class="imgPreview" *ngFor="let img of capturedByCamera">
            <a (click)="deletecapture(img)">✖</a>
            <img [src]="img" alt="">
        </div>
    </div>
</p-dialog> -->



    <app-copyrights></app-copyrights>

</div>