<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">
  <app-dashboard-navbar></app-dashboard-navbar>

  <!-- Breadcrumb Area -->
  <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
    <h1>{{ Content.title }}</h1>
    <ol class="breadcrumb">
      <li class="item"><a routerLink="/">Home</a></li>
      <li class="item">{{ Content.subTitle }}
      </li>
      <li class="item">{{ Content.title }}</li>
    </ol>
  </div>
  <div class="add-listings-box">
    <div class="Master-view-box">
      <div class="theader d-flex justify-content-between">
        <div class="d-flex">
          <span class="p-input-icon-left ml-auto mr-2 w-sm-50 w-100%">
            <i class="pi pi-search"></i>
            <input pInputText type="text" class="form-control "
                style="text-align: left; padding-left: 20%; height: 45px !important" scriptInjection
                placeholder="Search Keyword" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                [(ngModel)]="filterVal" />
          </span>
          <p-button type="button" class="mx-2" (click)="reset(dt1)" icon="bx bx-reset"
              tooltipPosition="bottom"></p-button>
        </div>
        <div class="add-listings-btn d-flex" style="text-align: right;">
          <a routerLink="/portal/zero-to-three-month-growth-monitoring" class="nav-link">
            <button type="submit">Add</button>

          </a>
          <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="onPDFExport()"
              class="p-button-warning ms-2" pTooltip="PDF" tooltipPosition="bottom"></button>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-lg-12 col-md-12">
        <p-table #dt1 [value]="allData" dataKey="name" [globalFilterFields]="['name', 'date_of_birth', 'age']"
            [rowsPerPageOptions]="[10, 25, 50]" [rows]="10" [showCurrentPageReport]="true" responsiveLayout="scroll"
            [paginator]="true" currentPageReportTemplate="Showing {first} to
					{last} of {totalRecords} entries">
          <ng-template pTemplate="header">
            <tr>
              <th></th>
              <th style="min-width: 62px">S. No.</th>
              <th>Name</th>
              <th>Date Of Birth</th>
              <th>Days</th>
              <th>Months</th>
              <!-- <th>Age</th> -->
            </tr>

          </ng-template>
          <ng-template pTemplate="body" let-product let-expanded="expanded" let-i="rowIndex">
            <tr>
              <td>
                <button type="button" pButton pRipple [pRowToggler]="product"
                    class="p-button-text p-button-rounded p-button-plain"
                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                    (click)="getData(product.baby_id)"></button>
              </td>
              <td>{{i+1}}</td>
              <td>{{product.name | titlecase }}</td>
              <td>{{product.date_of_birth}}</td>
              <td>{{product.days}}</td>
              <td>{{product.months}}</td>
              <!-- <td>{{product.years}}</td> -->
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-product>
            <tr>
              <td colspan="7" class="inner-data">
                <div class="p-3 py-0">
                  <p-table [value]="product.development_milestones
                            " dataKey="id">
                    <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="id" class="serial-width">Sr No.</th>
              <th pSortableColumn="customer">Action</th>
              <th pSortableColumn="date">Date</th>

            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-order let-expanded="expanded" let-i="rowIndex">
            <tr>
              <td>{{i+1}}</td>
              <td><button pButton pRipple class="p-button-rounded p-button-text p-button-info" pTooltip="View"
                    tooltipPosition="bottom" (click)="showMaximizableDialog(order.id)"><i
                      class="far fa-eye"></i></button>
                <button pButton pRipple class="p-button-rounded p-button-icon bx bx-pencil" pTooltip="Edit"
                    tooltipPosition="bottom" (click)="editOnCLick(order.id)">
                </button>
              </td>

              <td>
                {{ order?.created_at?(order?.created_at | date:'dd-MM-yyyy' ): "-" }}
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="6">There are no order for this product yet.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      </td>
      </tr>
      </ng-template>
      </p-table>

    </div>
    <!----------------------------------------------------------- view dialog --------------------------------------------------------->
    <!-- ***************************************************************************************************************** -->
    <p-dialog header="Baby Details" [(visible)]="displayMaximizable" [modal]="true" [style]="{ width: '90vw' }"
        [draggable]="false" [resizable]="false">
      <!-- Start -->
      <ng-container>
        <div class="row">
          <div class="col-lg-12">
            <div class="my-profile-box">
              <!-- Baby detail -->
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label>Name :</label>
                    <input type="text" readonly class="form-control" value="{{ babyData?.name ?? '-' | titlecase  }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label>Gender :</label>
                    <input type="text" readonly class="form-control" value="{{ babyData?.gender ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label>Date of Birth :</label>
                    <input type="text" readonly class="form-control" value="{{ babyData?.date_of_birth ?? '-' }}" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label>Days :</label>
                    <input type="text" readonly class="form-control" value="{{ babyData?.days ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label>Months :</label>
                    <input type="text" readonly class="form-control" value="{{ babyData?.months ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label>Years :</label>
                    <input type="text" readonly class="form-control" value="{{ babyData?.years ?? '-' }}" />
                  </div>
                </div>
              </div>
              <!-- Vitamins/Medications -->

              <h3>Height Weight</h3>
              <div class="row" *ngFor="let data of health">
                <div class="col-xl-4 col-lg-12
                  col-md-12">
                  <div class="form-group">

                    <label>Time</label>
                    <input type="text" readonly class="form-control" value="{{ data?.time | date:'dd-MM-yyyy'??'-'}}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12
                  col-md-12">
                  <div class="form-group">


                    <label>Height</label>
                    <input type="text" readonly class="form-control" value="{{ data?.height ??'-'}}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12
                  col-md-12">
                  <div class="form-group">
                    <label>Weight</label>
                    <input type="text" readonly class="form-control" value="{{ data?.weight ??'-'
                     }}" />
                  </div>
                </div>
              </div>

              <!-- motor and coordination -->
              <h3>Motor And Coordination</h3>

              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Turns head side to side</label>
                    <input type="text" readonly class="form-control flex-grow-1" value="{{detail?.head ?? '-'}}" />

                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.head_description ?? '-' }}" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Holds up head during tummy time</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.hold_head ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.holdhead_description ?? '-' }}" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Clenches fists</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.clenches ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.clenches_description ?? '-' }}" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Gripping finger</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.gripping_finger ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.gripping_finger_description ?? '-' }}" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Pushes up on arms</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.pushes_up ?? '-' }}" />
                  </div>
                </div>

                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.pushes_up_description ?? '-' }}" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Lifts and holds head up</label>
                    <input type="text" readonly class="form-control flex-grow-1" value="{{ detail?.head_up ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.head_up_description ?? '-' }}" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Moves fists from closed to open</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.fists_move ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.fists_move_description ?? '-' }}" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Bring hands to mouth</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.bring_hands ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.bring_hands_description ?? '-' }}" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Moves legs and arms off of the surface when
                      excited</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.move_legs ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.move_legs_description ?? '-' }}" />
                  </div>
                </div>
              </div>
              <!-- motor and coordination end -->

              <!-- Sesory -->
              <h3>Sensory</h3>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Moves legs and arms off of the surface when
                      excited</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.move_legs ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.move_legs_description ?? '-' }}" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">When lying on back, visually tracks a moving toy from
                      side to side</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.moving_toy ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.moving_toy_description ?? '-' }}" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">When lying on back, keeps head centered to watch faces
                      or toys</label>
                    <input type="text" readonly class="form-control flex-grow-1" value="{{ detail?.lying ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.lying_description ?? '-' }}" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Able to calm with rocking, touching, and gentle
                      sounds</label>
                    <input type="text" readonly class="form-control flex-grow-1" value="{{ detail?.calm ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.calm_description ?? '-' }}" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Enjoys a variety of movements</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.enjoys_movements ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.enjoys_movements_description ?? '-' }}" />
                  </div>
                </div>
              </div>

              <!-- sensory end -->

              <!-- communication-->
              <h3>Communication</h3>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Quiets or smiles in response to sound or voice</label>
                    <input type="text" readonly class="form-control flex-grow-1" value="{{ detail?.quiets ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.quiets_description ?? '-' }}" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Turns head towards sound or voice</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.turns_head ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.turns_head_description ?? '-' }}" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Shows interest in faces</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.interest ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.interest_description ?? '-' }}" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Makes eye contact</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.eye_contact ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.eye_contact_description ?? '-' }}" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Cries differently for different needs</label>
                    <input type="text" readonly class="form-control flex-grow-1" value="{{ detail?.cries ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.cries_description ?? '-' }}" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Coos and smiles</label>
                    <input type="text" readonly class="form-control flex-grow-1" value="{{ detail?.smiles ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.smiles_description ?? '-' }}" />
                  </div>
                </div>
              </div>
              <!-- communication End-->

              <!--feeding -->
              <h3>Feeding</h3>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Latches onto while nursing or bottle feeding</label>
                    <input type="text" readonly class="form-control flex-grow-1" value="{{ detail?.latches ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.latches_description ?? '-' }}" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Tongue moves forward and back to suck</label>
                    <input type="text" readonly class="form-control flex-grow-1" value="{{ detail?.tounge ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.tounge_description ?? '-' }}" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Drinks 2-6 oz. of liquid per feeding, 6 time per
                      day</label>
                    <input type="text" readonly class="form-control flex-grow-1" value="{{ detail?.drinks ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.drinks_description ?? '-' }}" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Sucks and swallows well during feeding</label>
                    <input type="text" readonly class="form-control flex-grow-1" value="{{ detail?.sucks ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.sucks_description ?? '-' }}" />
                  </div>
                </div>
              </div>
              <!-- feeding end -->

              <!-- play and social skills -->
              <h3>Play And Social Skill</h3>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Enjoys playful face-to-face interaction with
                      people</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.enjoys_playful ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.enjoys_playful_description ?? '-' }}" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Coos in response to playful interaction</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.coos_reponse ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.coos_reponse_description ?? '-' }}" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Notices and responds to sounds</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.responds_sounds ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.responds_sounds_description ?? '-' }}" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Able to calm during car rides when not tired or
                      hungry</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.able_to_calm ?? '-' }}" />
                  </div>
                </div>

                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.able_to_calm_description ?? '-' }}" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Enjoys bath time</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.enjoys_bath ?? '-' }}" />
                  </div>
                </div>

                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.enjoys_baths_description ?? '-' }}" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Able to tolerate diaper changing without crying</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.tolerate_diaper_change ?? '-' }}" />
                  </div>
                </div>

                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.tolerate_diaper_change_description ?? '-' }}" />
                  </div>
                </div>
              </div>
              <!-- play and social skill end -->

              <!-- Self Expression -->
              <h3>Self-Expression</h3>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Comforted by cuddling</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.cuddling ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.cuddling_description ?? '-' }}" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Not upset with everyday sounds</label>
                    <input type="text" readonly class="form-control flex-grow-1" value="{{ detail?.upset ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.upset_description ?? '-' }}" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Happy when not hungry or tired</label>
                    <input type="text" readonly class="form-control flex-grow-1" value="{{ detail?.happy ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.happy_description ?? '-' }}" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Enjoys varied playful movement experiences such as
                      rocking, touching, and calm sounds</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.playful_movment ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.playful_movment_description ?? '-' }}" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Does not demonstrate frequent or long bouts of being
                      upset</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.demonstrate ?? '-' }}" />
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group d-flex flex-column align-items-start">
                    <label for="" class="label-height">Remark</label>
                    <input type="text" readonly class="form-control flex-grow-1"
                        value="{{ detail?.demonstrate_description ?? '-' }}" />
                  </div>
                </div>
              </div>
              <!-- self expression end -->
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template pTemplate="footer">
        <p-button (click)="displayMaximizable=
                  false" label="Ok" styleClass="p-button-text"></p-button>
      </ng-template>
    </p-dialog>
  </div>
</div>
<app-copyrights></app-copyrights>