import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService, ConfirmationService } from 'primeng/api';
import { TableService } from 'primeng/table';
import { MasterService } from 'src/app/services/master.service';

@Component({
  selector: 'app-moments-memories',
  templateUrl: './moments-memories.component.html',
  styleUrls: ['./moments-memories.component.scss']
})
export class MomentsMemoriesComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Moments-Memories',
      subTitle: 'Dashboard',
    },
  ];
  momentData: any = {};
  moment: any = [];
  filterval: any;
  loading: any;
  editMemories: any = [];
  constructor(
    private masterService: MasterService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.getAllData();
  }
  formSubmit(form:NgForm){
    this.spinner.show()
    console.log(form.valid, form.value);
    if (form.valid) {
      this.masterService
        .postMomentData(form.value)
        .then((res: any) => {
          this.spinner.hide()
          console.log(res.message);
          this.messageService.add({
            severity: 'success',
            detail: res?.message,
          });
          form.resetForm();
          this.momentData = {};
          this.getAllData();
        })
        .catch((error: HttpErrorResponse) => {
          this.spinner.hide()

          console.log(error);
        });
    }
  }
  // edit data 
  editMemoriesMaster(event: any) {
    this.editMemories[event.id] = { ...event };
    console.log('Edit', this.editMemories);
  }
  onRowEditSave(moment: any) {
    this.spinner.show()
    console.log('onRowEditSave', moment);
    this.loading = true;
    if (moment.tags != '' && moment.tags != null) {
      this.masterService
        .editMomentData(moment, moment.id)
        .subscribe((data: any) => {
          this.spinner.hide()

          this.messageService.add({
            severity: 'success',
            detail: data?.message,
          });
          this.loading = false;
        });
    } else {
      this.spinner.hide()

      this.messageService.add({
        severity: 'error',
        detail: 'Please Fill All the Details!',
      });
      this.loading = false;
    }
  }
  // delete
  deleteMaster(moment: any) {
    this.spinner.show()
    this.confirmationService.confirm({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.masterService
          .deletMomentData(moment.id, { tags: 1 })
          .subscribe((data: any) => {
            this.getAllData();
            this.spinner.hide()
            this.messageService.add({
              severity: 'success',
              detail: data?.message,
            });
          });
      },
    });
    this.spinner.hide()

  }
  // get all data
  getAllData() {
    this.spinner.show()
    this.masterService.getMomentData().then((res: any) => {
      this.spinner.hide()

      console.log('response', res);
      this.moment = res.data;
    });
  }
  // reset function
  reset(dt1:any) {
    dt1.reset();
    this.filterval = '';
  }
}
