import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import * as moment from 'moment';
import { CommonApiService } from 'src/app/services/common-api.service';
import { MasterService } from 'src/app/services/master.service';
import { NannyDailyMonitoringService } from 'src/app/services/nanny-daily-monitoring.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-nanny-daily-monitoring',
  templateUrl: './nanny-daily-monitoring.component.html',
  styleUrls: ['./nanny-daily-monitoring.component.scss'],
  providers: [MessageService],
})
export class NannyDailyMonitoringComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Nurse Daily Monitoring Form',
      subTitle: 'Dashboard',
    },
  ];
  formula_feeding: boolean = false;
  breast_feeding: boolean = false;
  tub: any = true;
  sponge: any = true;
  showNeedsTextarea: boolean = false;
  formula: boolean = false;
  selectedDate: Date = null;
  years: any = '';
  month: any = '';
  days: any = '';
  selectedNumber: any;
  ageList: any = [];
  All: any = [];
  One: any[];
  breast: boolean = true;
  nightsleeptime: boolean = true;
  naps: boolean = true;
  wakeup: boolean = true;
  intact: boolean = false;
  babyValues: any = [];
  babyGender: any;
  FeedingBreast: any;
  FeedingFormula: any;
  frequency: any = [
    { name: 1, value: 1 },
    { name: 2, value: 2 },
    { name: 3, value: 3 },
    { name: 4, value: 4 },
    { name: 5, value: 5 },
    { name: 6, value: 6 },
    { name: 7, value: 7 },
    { name: 8, value: 8 },
    { name: 9, value: 9 },
    { name: 10, value: 10 },
  ];
  isedit: boolean = false;
  nannyDailyMonitoring: FormGroup;
  foodComponents: any = [
    { name: 'Carbohydrates', value: 'carbohydrates' },
    { name: 'Proteins', value: 'proteins' },
    { name: 'Fats', value: 'fats' },
    { name: 'Vitamins', value: 'vitamins' },
    { name: 'Minerals', value: 'minerals' },
  ];

  urineValues: any = [];

  stoolValues: any = [];

  constructor(
    private fb: FormBuilder,
    private commonService: CommonApiService,
    private dailyService: NannyDailyMonitoringService,
    private masterService: MasterService,
    private messageService: MessageService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    this.nannyDailyMonitoring = this.fb.group({
      baby_details_id: new FormControl(null, [Validators.required]),
      breast_feeding: new FormControl(null),
      formula_feeding: new FormControl(null),
      sleeping: this.fb.group({
        wakeup_time: new FormControl(null),
        nap_time: new FormControl(null),
        sleep_time: new FormControl(null),
        nap_duration: new FormControl(null),
        sleep_duration: new FormControl(null),
      }),
      solid_food: this.fb.group({
        rice: new FormControl(null),
        rice_remark: new FormControl(null),
        barley: new FormControl(null),
        barley_remark: new FormControl(null),
        oats: new FormControl(null),
        oats_remark: new FormControl(null),
        beans: new FormControl(null),
        beans_remark: new FormControl(null),
        lentils: new FormControl(null),
        lentils_remark: new FormControl(null),
        pasta: new FormControl(null),
        pasta_remark: new FormControl(null),
        other: new FormControl(null),
        other_remark: new FormControl(null),
      }),
      solid_fruits: this.fb.group({
        banana: new FormControl(null),
        banana_remark: new FormControl(null),
        apple: new FormControl(null),
        apple_remark: new FormControl(null),
        avocado: new FormControl(null),
        avocado_remark: new FormControl(null),
        pears: new FormControl(null),
        pears_remark: new FormControl(null),
        mango: new FormControl(null),
        mango_remark: new FormControl(null),
        nectrain: new FormControl(null),
        nectrain_remark: new FormControl(null),
        figs: new FormControl(null),
        figs_remark: new FormControl(null),
        papaya: new FormControl(null),
        papaya_remark: new FormControl(null),
        kiwi: new FormControl(null),
        kiwi_remark: new FormControl(null),
        grapes: new FormControl(null),
        grapes_remark: new FormControl(null),
        orange: new FormControl(null),
        orange_remark: new FormControl(null),
        cherries: new FormControl(null),
        cherries_remark: new FormControl(null),
        peach: new FormControl(null),
        peach_remark: new FormControl(null),
        date: new FormControl(null),
        date_remark: new FormControl(null),
        other: new FormControl(null),
        other_remark: new FormControl(null),
      }),
      solid_vegetable: this.fb.group({
        pumpkin: new FormControl(null),
        pumpkin_remark: new FormControl(null),
        potato: new FormControl(null),
        potato_remark: new FormControl(null),
        carrots: new FormControl(null),
        carrots_remark: new FormControl(null),
        corn: new FormControl(null),
        corn_remark: new FormControl(null),
        marrow: new FormControl(null),
        marrow_remark: new FormControl(null),
        eggplant: new FormControl(null),
        eggplant_remark: new FormControl(null),
        leafy_veggies: new FormControl(null),
        leafy_veggies_remark: new FormControl(null),
        cruciferious: new FormControl(null),
        cruciferious_remark: new FormControl(null),
        other: new FormControl(null),
        other_remark: new FormControl(null),
      }),
      dairy_food: this.fb.group({
        yogurt: new FormControl(null),
        yogurt_remark: new FormControl(null),
        cheese: new FormControl(null),
        cheese_remark: new FormControl(null),
        butter: new FormControl(null),
        butter_remark: new FormControl(null),
        other: new FormControl(null),
        other_remark: new FormControl(null),
      }),
      solid_protein: this.fb.group({
        chicken: new FormControl(null),
        chicken_remark: new FormControl(null),
        turkey: new FormControl(null),
        turkey_remark: new FormControl(null),
        beef: new FormControl(null),
        beef_remark: new FormControl(null),
        fish: new FormControl(null),
        fish_remark: new FormControl(null),
        eggs: new FormControl(null),
        eggs_remark: new FormControl(null),
        other: new FormControl(null),
        other_remark: new FormControl(null),
      }),
      sleep_training_json: this.fb.group({
        cry_method: new FormControl(null),
        cry_method_remark: new FormControl(null),
        ferber_method: new FormControl(null),
        ferber_method_remark: new FormControl(null),
        check_method: new FormControl(null),
        check_method_remark: new FormControl(null),
        fading_method: new FormControl(null),
        fading_method_remark: new FormControl(null),
        no_tear: new FormControl(null),
        no_tear_remark: new FormControl(null),
      }),
      bed_time_routine_json: this.fb.group({
        bath: new FormControl(null),
        bath_remark: new FormControl(null),
        feed: new FormControl(null),
        feed_remark: new FormControl(null),
        sing: new FormControl(null),
        sing_remark: new FormControl(null),
        read_book: new FormControl(null),
        read_book_remark: new FormControl(null),
        avoid_screen: new FormControl(null),
        avoid_screen_remark: new FormControl(null),
        other: new FormControl(null),
        other_remark: new FormControl(null),
      }),
      bathing_json: new FormGroup({
        bathing_type: new FormControl(''),
        bathing_type_tub_frequency: new FormControl({
          value: '',
          disabled: true,
        }),
        bathing_type_sponge_frequency: new FormControl({
          value: '',
          disabled: true,
        }),
      }),
      feeding_breast: this.fb.array([]),
      feeding_formula: this.fb.array([]),
      diapering: this.fb.array([]),
      temperature_monitoring: new FormControl(null, [
        Validators.pattern('^[0-9]*$'),
      ]),
      vitamins_and_medications: this.fb.array([]),
      remarks: new FormControl(null),
      solid_food_feeding_json: new FormControl(null),
    });
  }

  ngOnInit() {
    this.getBabyDetails();

    this.getMastersValueForDropdown();

    if (localStorage.getItem('editedOrderId')) {
      this.getEditFormData();

      this.isedit = true;
    } else {
      console.log('add called');
      this.isedit = false;
      this.addBreastFeeding('add', 0);
      this.addFormulaFeeding('add', 0);
      this.addVitaminObject('add', 0);
      this.addDiaperingObject('add', 0);

      this.nannyDailyMonitoring.get(['solid_food', 'rice_remark']).disable();
      this.nannyDailyMonitoring.get(['solid_food', 'barley_remark']).disable();
      this.nannyDailyMonitoring.get(['solid_food', 'oats_remark']).disable();
      this.nannyDailyMonitoring.get(['solid_food', 'lentils_remark']).disable();
      this.nannyDailyMonitoring.get(['solid_food', 'pasta_remark']).disable();
      // this.nannyDailyMonitoring.get(['solid_food', 'other_remark']).disable();
      this.nannyDailyMonitoring.get(['solid_food', 'beans_remark']).disable();
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'banana_remark'])
        .disable();
      this.nannyDailyMonitoring.get(['solid_fruits', 'apple_remark']).disable();
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'avocado_remark'])
        .disable();
      this.nannyDailyMonitoring.get(['solid_fruits', 'pears_remark']).disable();
      this.nannyDailyMonitoring.get(['solid_fruits', 'mango_remark']).disable();
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'nectrain_remark'])
        .disable();
      this.nannyDailyMonitoring.get(['solid_fruits', 'figs_remark']).disable();
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'papaya_remark'])
        .disable();
      this.nannyDailyMonitoring.get(['solid_fruits', 'kiwi_remark']).disable();
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'grapes_remark'])
        .disable();
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'orange_remark'])
        .disable();
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'cherries_remark'])
        .disable();
      this.nannyDailyMonitoring.get(['solid_fruits', 'peach_remark']).disable();
      this.nannyDailyMonitoring.get(['solid_fruits', 'date_remark']).disable();
      // this.nannyDailyMonitoring.get(['solid_fruits', 'other_remark']).disable();
      this.nannyDailyMonitoring
        .get(['solid_vegetable', 'pumpkin_remark'])
        .disable();
      this.nannyDailyMonitoring
        .get(['solid_vegetable', 'potato_remark'])
        .disable();
      this.nannyDailyMonitoring
        .get(['solid_vegetable', 'carrots_remark'])
        .disable();
      this.nannyDailyMonitoring
        .get(['solid_vegetable', 'corn_remark'])
        .disable();
      this.nannyDailyMonitoring
        .get(['solid_vegetable', 'marrow_remark'])
        .disable();
      this.nannyDailyMonitoring
        .get(['solid_vegetable', 'eggplant_remark'])
        .disable();
      this.nannyDailyMonitoring
        .get(['solid_vegetable', 'leafy_veggies_remark'])
        .disable();
      this.nannyDailyMonitoring
        .get(['solid_vegetable', 'cruciferious_remark'])
        .disable();
      // this.nannyDailyMonitoring.get(['solid_vegetable', 'other_remark']).disable();
      this.nannyDailyMonitoring.get(['dairy_food', 'yogurt_remark']).disable();
      this.nannyDailyMonitoring.get(['dairy_food', 'cheese_remark']).disable();
      this.nannyDailyMonitoring.get(['dairy_food', 'butter_remark']).disable();
      // this.nannyDailyMonitoring.get(['dairy_food', 'other_remark']).disable();
      this.nannyDailyMonitoring
        .get(['solid_protein', 'chicken_remark'])
        .disable();
      this.nannyDailyMonitoring
        .get(['solid_protein', 'turkey_remark'])
        .disable();
      this.nannyDailyMonitoring.get(['solid_protein', 'beef_remark']).disable();
      this.nannyDailyMonitoring.get(['solid_protein', 'fish_remark']).disable();
      this.nannyDailyMonitoring.get(['solid_protein', 'eggs_remark']).disable();
      // this.nannyDailyMonitoring.get(['solid_protein', 'other_remark']).disable();
      this.nannyDailyMonitoring
        .get(['sleep_training_json', 'cry_method_remark'])
        .disable();
      this.nannyDailyMonitoring
        .get(['sleep_training_json', 'ferber_method_remark'])
        .disable();
      this.nannyDailyMonitoring
        .get(['sleep_training_json', 'check_method_remark'])
        .disable();
      this.nannyDailyMonitoring
        .get(['sleep_training_json', 'fading_method_remark'])
        .disable();
      this.nannyDailyMonitoring
        .get(['sleep_training_json', 'no_tear_remark'])
        .disable();
      this.nannyDailyMonitoring
        .get(['bed_time_routine_json', 'bath_remark'])
        .disable();
      this.nannyDailyMonitoring
        .get(['bed_time_routine_json', 'feed_remark'])
        .disable();
      this.nannyDailyMonitoring
        .get(['bed_time_routine_json', 'sing_remark'])
        .disable();
      this.nannyDailyMonitoring
        .get(['bed_time_routine_json', 'avoid_screen_remark'])
        .disable();
    }
  }
  getEditFormData() {
    this.spinner.show()
    const getEdit = localStorage.getItem('editedOrderId');
    console.log(getEdit);
    this.commonService.getDailyMonitoringEditApi(getEdit).then((res: any) => {
      this.spinner.hide()
      console.log('Response In Growth Monitoring', res);
      const changeGrowthMonitoring = res.data[0];
      const Daipering = JSON.parse(res.data[0].diapering);
      const Feeding = JSON.parse(res.data[0].feeding);
      this.FeedingFormula = JSON.parse(Feeding[1].feeding_formula);
      this.FeedingBreast = JSON.parse(Feeding[0].feeding_breast);
      const solidfood = JSON.parse(res.data[0].solid_food_feeding_json);
      const solidfoodcerals = JSON.parse(solidfood[0].cerals_grains);
      const solidfurits = JSON.parse(solidfood[1].fruits);
      const solidvegetable = JSON.parse(solidfood[2].vegetables);
      const solidDairy = JSON.parse(solidfood[3].dairy_food);
      const solidProtein = JSON.parse(solidfood[4].protein);
      const sleeptraining = JSON.parse(res.data[0].sleep_training_json);
      const bedtimeroutine = JSON.parse(res.data[0].bed_time_routine_json);
      const bathingJson = JSON.parse(res.data[0].bathing_json);
      const remarks = res.data[0].remarks;
      const temperature = res.data[0].temperature_monitoring;
      const sleeping = JSON.parse(res.data[0].sleeping);
      const wakeupTime = this.parseISOString(sleeping.wakeup_time) || null
      const napTime = this.parseISOString(sleeping.nap_time) || null
      const sleepTime = this.parseISOString(sleeping.sleep_time) || null
      const VitaminsMedication = JSON.parse(res.data[0].vitamins_and_medications);
      console.log('Change Growth Monitoring ', changeGrowthMonitoring);
      console.log('Daipering ', Daipering);
      console.log('Feeding ', Feeding);
      console.log('Feeding Breast', this.FeedingBreast);
      console.log('Feeding Formula', this.FeedingFormula);
      console.log('solid food', solidfood);
      // console.log("solid food cerals", solidfoodcerals);
      // console.log("solid furite", solidfurits);
      // console.log("solid vegetable", solidvegetable);
      // console.log("solid dairy", solidDairy);
      // console.log("solid protien", solidProtein);
      // console.log("Sleeping Training",sleeptraining);
      // console.log("Bed Time Routine",bedtimeroutine);
      // console.log("Bathing JSON",bathingJson);
      // console.log("Remarks",remarks)
      // console.log("Temprature",temperature)
      console.log('Sleeping', sleeping);
      console.log('Wake Up Time', wakeupTime);
      // // debugger;
      // console.log("change Growth Monitoring",changeGrowthMonitoring.baby_details_id);
      this.nannyDailyMonitoring.controls.baby_details_id.setValue(
        JSON.parse(changeGrowthMonitoring.baby_details_id)
      );
      // this.nannyDailyMonitoring.controls.baby_details_id.setValue(changeGrowthMonitoring.baby_details_id);
      this.babyGender = changeGrowthMonitoring.gender;
      this.selectedDate = changeGrowthMonitoring.date_of_birth;
      this.days = changeGrowthMonitoring.days;
      this.month = changeGrowthMonitoring.months;
      this.years = changeGrowthMonitoring.years;

      this.nannyDailyMonitoring
        .get(['solid_food', 'rice'])
        .setValue(solidfoodcerals.rice);
      this.nannyDailyMonitoring
        .get(['solid_food', 'rice_remark'])
        .setValue(solidfoodcerals.rice_remark);
      this.nannyDailyMonitoring
        .get(['solid_food', 'barley'])
        .setValue(solidfoodcerals.barley);
      this.nannyDailyMonitoring
        .get(['solid_food', 'barley_remark'])
        .setValue(solidfoodcerals.barley_remark);
      this.nannyDailyMonitoring
        .get(['solid_food', 'oats'])
        .setValue(solidfoodcerals.oats);
      this.nannyDailyMonitoring
        .get(['solid_food', 'oats_remark'])
        .setValue(solidfoodcerals.oats_remark);
      this.nannyDailyMonitoring
        .get(['solid_food', 'lentils'])
        .setValue(solidfoodcerals.lentils);
      this.nannyDailyMonitoring
        .get(['solid_food', 'lentils_remark'])
        .setValue(solidfoodcerals.lentils_remark);
      this.nannyDailyMonitoring
        .get(['solid_food', 'pasta'])
        .setValue(solidfoodcerals.pasta);
      this.nannyDailyMonitoring
        .get(['solid_food', 'pasta_remark'])
        .setValue(solidfoodcerals.pasta_remark);
      this.nannyDailyMonitoring
        .get(['solid_food', 'beans'])
        .setValue(solidfoodcerals.beans);
      this.nannyDailyMonitoring
        .get(['solid_food', 'beans_remark'])
        .setValue(solidfoodcerals.beans_remark);
      this.nannyDailyMonitoring
        .get(['solid_food', 'other'])
        .setValue(solidfoodcerals.other);
      this.nannyDailyMonitoring
        .get(['solid_food', 'other_remark'])
        .setValue(solidfoodcerals.other_remark);
      //  fruits
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'banana'])
        .setValue(solidfurits.banana);
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'banana_remark'])
        .setValue(solidfurits.banana_remark);
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'apple'])
        .setValue(solidfurits.apple);
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'apple_remark'])
        .setValue(solidfurits.apple_remark);
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'avocado'])
        .setValue(solidfurits.avocado);
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'avocado_remark'])
        .setValue(solidfurits.avocado_remark);
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'pears'])
        .setValue(solidfurits.pears);
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'pears_remark'])
        .setValue(solidfurits.pears_remark);
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'mango'])
        .setValue(solidfurits.mango);
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'mango_remark'])
        .setValue(solidfurits.mango_remark);
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'nectrain'])
        .setValue(solidfurits.nectrain);
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'nectrain_remark'])
        .setValue(solidfurits.nectrain_remark);
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'figs'])
        .setValue(solidfurits.figs);
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'figs_remark'])
        .setValue(solidfurits.figs_remark);
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'papaya'])
        .setValue(solidfurits.papaya);
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'papaya_remark'])
        .setValue(solidfurits.papaya_remark);
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'kiwi'])
        .setValue(solidfurits.kiwi);
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'kiwi_remark'])
        .setValue(solidfurits.kiwi_remark);
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'grapes'])
        .setValue(solidfurits.grapes);
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'grapes_remark'])
        .setValue(solidfurits.grapes_remark);
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'orange'])
        .setValue(solidfurits.orange);
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'orange_remark'])
        .setValue(solidfurits.orange_remark);
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'cherries'])
        .setValue(solidfurits.cherries);
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'cherries_remark'])
        .setValue(solidfurits.cherries_remark);
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'peach'])
        .setValue(solidfurits.peach);
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'peach_remark'])
        .setValue(solidfurits.peach_remark);
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'date'])
        .setValue(solidfurits.date);
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'other'])
        .setValue(solidfurits.other);
      this.nannyDailyMonitoring
        .get(['solid_fruits', 'other_remark'])
        .setValue(solidfurits.other_remark);
      // this.nannyDailyMonitoring.get(['solid_fruits', 'other_remark']).disable();
      this.nannyDailyMonitoring
        .get(['solid_vegetable', 'pumpkin'])
        .setValue(solidvegetable.pumpkin);
      this.nannyDailyMonitoring
        .get(['solid_vegetable', 'pumpkin_remark'])
        .setValue(solidvegetable.pumpkin_remark);
      this.nannyDailyMonitoring
        .get(['solid_vegetable', 'potato'])
        .setValue(solidvegetable.potato);
      this.nannyDailyMonitoring
        .get(['solid_vegetable', 'potato_remark'])
        .setValue(solidvegetable.potato_remark);
      this.nannyDailyMonitoring
        .get(['solid_vegetable', 'carrots'])
        .setValue(solidvegetable.carrots);
      this.nannyDailyMonitoring
        .get(['solid_vegetable', 'carrots_remark'])
        .setValue(solidvegetable.carrots_remark);
      this.nannyDailyMonitoring
        .get(['solid_vegetable', 'corn'])
        .setValue(solidvegetable.corn);
      this.nannyDailyMonitoring
        .get(['solid_vegetable', 'corn_remark'])
        .setValue(solidvegetable.corn_remark);
      this.nannyDailyMonitoring
        .get(['solid_vegetable', 'marrow'])
        .setValue(solidvegetable.marrow);
      this.nannyDailyMonitoring
        .get(['solid_vegetable', 'marrow_remark'])
        .setValue(solidvegetable.marrow_remark);
      this.nannyDailyMonitoring
        .get(['solid_vegetable', 'eggplant'])
        .setValue(solidvegetable.eggplant);
      this.nannyDailyMonitoring
        .get(['solid_vegetable', 'eggplant_remark'])
        .setValue(solidvegetable.eggplant_remark);
      this.nannyDailyMonitoring
        .get(['solid_vegetable', 'leafy_veggies'])
        .setValue(solidvegetable.leafy_veggies);
      this.nannyDailyMonitoring
        .get(['solid_vegetable', 'leafy_veggies_remark'])
        .setValue(solidvegetable.leafy_veggies_remark);
      this.nannyDailyMonitoring
        .get(['solid_vegetable', 'cruciferious'])
        .setValue(solidvegetable.cruciferious);
      this.nannyDailyMonitoring
        .get(['solid_vegetable', 'cruciferious_remark'])
        .setValue(solidvegetable.cruciferious_remark);
      this.nannyDailyMonitoring
        .get(['solid_vegetable', 'other'])
        .setValue(solidvegetable.other);
      this.nannyDailyMonitoring
        .get(['solid_vegetable', 'other_remark'])
        .setValue(solidvegetable.other_remark);
      // dairy product
      this.nannyDailyMonitoring
        .get(['dairy_food', 'yogurt'])
        .setValue(solidDairy.yogurt);
      this.nannyDailyMonitoring
        .get(['dairy_food', 'yogurt_remark'])
        .setValue(solidDairy.yogurt_remark);
      this.nannyDailyMonitoring
        .get(['dairy_food', 'cheese'])
        .setValue(solidDairy.cheese);
      this.nannyDailyMonitoring
        .get(['dairy_food', 'cheese_remark'])
        .setValue(solidDairy.cheese_remark);
      this.nannyDailyMonitoring
        .get(['dairy_food', 'butter'])
        .setValue(solidDairy.butter);
      this.nannyDailyMonitoring
        .get(['dairy_food', 'butter_remark'])
        .setValue(solidDairy.butter_remark);
      this.nannyDailyMonitoring
        .get(['dairy_food', 'other'])
        .setValue(solidDairy.other);
      this.nannyDailyMonitoring
        .get(['dairy_food', 'other_remark'])
        .setValue(solidDairy.other_remark);
      // solid protien
      this.nannyDailyMonitoring
        .get(['solid_protein', 'chicken'])
        .setValue(solidProtein.chicken);
      this.nannyDailyMonitoring
        .get(['solid_protein', 'chicken_remark'])
        .setValue(solidProtein.chicken_remark);
      this.nannyDailyMonitoring
        .get(['solid_protein', 'turkey'])
        .setValue(solidProtein.turkey);
      this.nannyDailyMonitoring
        .get(['solid_protein', 'turkey_remark'])
        .setValue(solidProtein.turkey_remark);
      this.nannyDailyMonitoring
        .get(['solid_protein', 'beef'])
        .setValue(solidProtein.beef);
      this.nannyDailyMonitoring
        .get(['solid_protein', 'beef_remark'])
        .setValue(solidProtein.beef_remark);
      this.nannyDailyMonitoring
        .get(['solid_protein', 'fish'])
        .setValue(solidProtein.fish);
      this.nannyDailyMonitoring
        .get(['solid_protein', 'fish_remark'])
        .setValue(solidProtein.fish_remark);
      this.nannyDailyMonitoring
        .get(['solid_protein', 'eggs'])
        .setValue(solidProtein.eggs);
      this.nannyDailyMonitoring
        .get(['solid_protein', 'eggs_remark'])
        .setValue(solidProtein.eggs_remark);
      this.nannyDailyMonitoring
        .get(['solid_protein', 'other'])
        .setValue(solidProtein.other);
      this.nannyDailyMonitoring
        .get(['solid_protein', 'other_remark'])
        .setValue(solidProtein.eggs_remark);

      // sleep training json
      this.nannyDailyMonitoring
        .get(['sleep_training_json', 'cry_method'])
        .setValue(sleeptraining.cry_method);
      this.nannyDailyMonitoring
        .get(['sleep_training_json', 'cry_method_remark'])
        .setValue(sleeptraining.cry_method_remark);
      this.nannyDailyMonitoring
        .get(['sleep_training_json', 'ferber_method'])
        .setValue(sleeptraining.ferber_method);
      this.nannyDailyMonitoring
        .get(['sleep_training_json', 'ferber_method_remark'])
        .setValue(sleeptraining.cry_method_remark);
      this.nannyDailyMonitoring
        .get(['sleep_training_json', 'check_method'])
        .setValue(sleeptraining.check_method);
      this.nannyDailyMonitoring
        .get(['sleep_training_json', 'check_method_remark'])
        .setValue(sleeptraining.check_method_remark);
      this.nannyDailyMonitoring
        .get(['sleep_training_json', 'fading_method'])
        .setValue(sleeptraining.fading_method);
      this.nannyDailyMonitoring
        .get(['sleep_training_json', 'fading_method_remark'])
        .setValue(sleeptraining.fading_method_remark);
      this.nannyDailyMonitoring
        .get(['sleep_training_json', 'no_tear'])
        .setValue(sleeptraining.no_tear);
      this.nannyDailyMonitoring
        .get(['sleep_training_json', 'no_tear_remark'])
        .setValue(sleeptraining.no_tear_remark);
      this.nannyDailyMonitoring
        .get(['bed_time_routine_json', 'bath'])
        .setValue(bedtimeroutine.bath);
      this.nannyDailyMonitoring
        .get(['bed_time_routine_json', 'bath_remark'])
        .setValue(bedtimeroutine.bath_remark);
      this.nannyDailyMonitoring
        .get(['bed_time_routine_json', 'feed'])
        .setValue(bedtimeroutine.feed);
      this.nannyDailyMonitoring
        .get(['bed_time_routine_json', 'feed_remark'])
        .setValue(bedtimeroutine.feed_remark);
      this.nannyDailyMonitoring
        .get(['bed_time_routine_json', 'read_book'])
        .setValue(bedtimeroutine.read_book);
      this.nannyDailyMonitoring
        .get(['bed_time_routine_json', 'read_book_remark'])
        .setValue(bedtimeroutine.read_book_remark);
      this.nannyDailyMonitoring
        .get(['bed_time_routine_json', 'sing'])
        .setValue(bedtimeroutine.sing);
      this.nannyDailyMonitoring
        .get(['bed_time_routine_json', 'sing_remark'])
        .setValue(bedtimeroutine.sing_remark);
      this.nannyDailyMonitoring
        .get(['bed_time_routine_json', 'avoid_screen'])
        .setValue(bedtimeroutine.avoid_screen);
      this.nannyDailyMonitoring
        .get(['bed_time_routine_json', 'avoid_screen_remark'])
        .setValue(bedtimeroutine.avoid_screen_remark);
      this.nannyDailyMonitoring
        .get(['bed_time_routine_json', 'other'])
        .setValue(bedtimeroutine.other);
      this.nannyDailyMonitoring
        .get(['bed_time_routine_json', 'other_remark'])
        .setValue(bedtimeroutine.other_remark);
      // bathing json
      this.nannyDailyMonitoring
        .get(['bathing_json', 'bathing_type'])
        .setValue(bathingJson.bathing_type);
      this.nannyDailyMonitoring
        .get(['bathing_json', 'bathing_type_tub_frequency'])
        .setValue(bathingJson.bathing_type_tub_frequency);
      this.nannyDailyMonitoring
        .get(['bathing_json', 'bathing_type_sponge_frequency'])
        .setValue(bathingJson.bathing_type_sponge_frequency);
      this.nannyDailyMonitoring.get(['remarks']).setValue(remarks);
      this.nannyDailyMonitoring
        .get(['temperature_monitoring'])
        .setValue(temperature);
      this.nannyDailyMonitoring
        .get(['sleeping', 'wakeup_time'])
        .setValue(wakeupTime);
      this.nannyDailyMonitoring
        .get(['sleeping', 'sleep_time'])
        .setValue(sleepTime);
      this.nannyDailyMonitoring
        .get(['sleeping', 'sleep_duration'])
        .setValue(sleeping.sleep_duration);
      this.nannyDailyMonitoring
        .get(['sleeping', 'nap_time'])
        .setValue(napTime);
      this.nannyDailyMonitoring
        .get(['sleeping', 'nap_duration'])
        .setValue(sleeping.nap_duration);
      this.nannyDailyMonitoring.get('formula_feeding').setValue(changeGrowthMonitoring.formula_feeding);
      this.nannyDailyMonitoring.get('breast_feeding').setValue(changeGrowthMonitoring.breast_feeding);
      if (changeGrowthMonitoring.breast_feeding == 1) {
        this.breast_feeding = true
      }
      if (changeGrowthMonitoring.formula_feeding == 1) {
        this.formula_feeding = true
      }
      // this.nannyDailyMonitoring.get(['breast_feeding']).setValue('breast_feeding');

      for (let i = 0; i < this.FeedingBreast.length; i++) {
        let breastArray = this.getBreastFeedingArray();

        // this.addBreastFeeding('add', i);
        let feedingFromApi = this.FeedingBreast[i] || {};
        console.log(this.parseISOString(feedingFromApi.feeding_time));

        let newArray = new FormGroup({
          feeding_no: new FormControl(i + 1),
          feeding_check: new FormControl(feedingFromApi.feeding_check || null),
          feeding_time: new FormControl(
            this.parseISOString(feedingFromApi.feeding_time) || null
          ),
          feeding_amount: new FormControl(
            feedingFromApi.feeding_amount || null,
            [Validators.pattern('^[0-9]*$')]
          ),
          feeding_remark: new FormControl(
            feedingFromApi.feeding_remark || null
          ),
        });

        breastArray.insert(i + 1, newArray);
      }

      for (let i = 0; i < this.FeedingFormula.length; i++) {
        let formulaArray = this.getFormulaFeedingArray();
        let feedingFormulaFromApi = this.FeedingFormula[i] || {};
        let newArray = new FormGroup({
          feeding_no: new FormControl(i + 1),
          feeding_check: new FormControl(feedingFormulaFromApi.feeding_check || null),
          feeding_time: new FormControl(
            this.parseISOString(feedingFormulaFromApi.feeding_time) || null
          ),
          feeding_amount: new FormControl(
            feedingFormulaFromApi.feeding_amount || null,
            [Validators.pattern('^[0-9]*$')]
          ),
          feeding_remark: new FormControl(
            feedingFormulaFromApi.feeding_remark || null
          ),
        });
        formulaArray.insert(i + 1, newArray);
      }

      for (let i = 0; i < Daipering.length; i++) {

        let diaperingArray = this.getDiaperingFormArray();
        let diaperFromApi = Daipering[i] || {};
        let isUrineDisabled = true;
        let isStoolDisabled = true;

       if(diaperFromApi.urine == true){
            isUrineDisabled = false
          }
          if(diaperFromApi.stool == true){
            isStoolDisabled = false
          }
          let newDiaperingArray = new FormGroup({
            no_nappy: new FormControl(i + 1),
            urine: new FormControl(diaperFromApi.urine),
            urine_des: new FormControl({ value: diaperFromApi.urine_des, disabled: isUrineDisabled }),
            stool: new FormControl(diaperFromApi.stool),
            stool_des: new FormControl({ value: diaperFromApi.stool_des, disabled: isStoolDisabled }),
          });

          diaperingArray.insert(i + 1, newDiaperingArray);

      }
      for (let i = 0; i < VitaminsMedication.length; i++) {

        let vitaminArray = this.getVitaminFormArray();
        let VitaminsMedicationFromApi = VitaminsMedication[i] || {};
        let newVitaminArray = new FormGroup({
          vitamin: new FormControl(VitaminsMedicationFromApi.vitamin),
        });
        vitaminArray.insert(i + 1, newVitaminArray);
      }
    }, (error: any) => {
      this.spinner.hide()
      // Handle any errors that occurred during the API call
    
    });
  }
  handleInput(event: any, value: string, index?: any) {
    if (value == 'bottlefeeding') {
      if (event.target.checked) {
        this.nannyDailyMonitoring.controls.bottle_time.enable();
        this.nannyDailyMonitoring.controls.bottle_amount.enable();
        this.nannyDailyMonitoring.controls.bottle_remark.enable();
        this.nannyDailyMonitoring.controls.bottle_food_component.enable();
      } else {
        this.nannyDailyMonitoring.controls.bottle_time.disable();
        this.nannyDailyMonitoring.controls.bottle_amount.disable();
        this.nannyDailyMonitoring.controls.bottle_remark.disable();
        this.nannyDailyMonitoring.controls.bottle_food_component.disable();
        this.nannyDailyMonitoring.controls.bottle_time.setValue('');
        this.nannyDailyMonitoring.controls.bottle_amount.setValue('');
        this.nannyDailyMonitoring.controls.bottle_remark.setValue('');
        this.nannyDailyMonitoring.controls.bottle_food_component.setValue('');
      }
    } else if (value == 'bathing') {
      if (event.target.value == 'sponge') {
        console.log('sponge');
        if (event.target.checked) {
          this.nannyDailyMonitoring.controls.bathing_json[
            'controls'
          ].bathing_type_sponge_frequency.enable();
          this.nannyDailyMonitoring.controls.bathing_json[
            'controls'
          ].bathing_type_tub_frequency.disable();
          this.nannyDailyMonitoring.controls.bathing_json[
            'controls'
          ].bathing_type_tub_frequency.setValue('');
        } else {
          this.nannyDailyMonitoring.controls.bathing_json[
            'controls'
          ].bathing_type_sponge_frequency.disable();
          this.nannyDailyMonitoring.controls.bathing_json[
            'controls'
          ].bathing_type_sponge_frequency.setValue('');
        }
        if (event.target.checked) {
          this.nannyDailyMonitoring
            .get(['bathing_json', 'bathing_type_tub_frequency'])
            .setValidators([Validators.required]);
        } else {
          this.nannyDailyMonitoring
            .get(['bathing_json', 'bathing_type_tub_frequency'])
            .clearValidators();
        }
        this.nannyDailyMonitoring
          .get(['bathing_json', 'bathing_type_tub_frequency'])
          .updateValueAndValidity();
      } else if (event.target.value == 'tub' && event.target.checked) {
        console.log('tub');
        if (event.target.checked) {
          this.nannyDailyMonitoring.controls.bathing_json[
            'controls'
          ].bathing_type_tub_frequency.enable();
          this.nannyDailyMonitoring.controls.bathing_json[
            'controls'
          ].bathing_type_sponge_frequency.disable();
          this.nannyDailyMonitoring.controls.bathing_json[
            'controls'
          ].bathing_type_sponge_frequency.setValue('');
        } else {
          this.nannyDailyMonitoring.controls.bathing_json[
            'controls'
          ].bathing_type_tub_frequency.disable();
          this.nannyDailyMonitoring.controls.bathing_json[
            'controls'
          ].bathing_type_tub_frequency.setValue('');
        }
        if (event.target.checked) {
          this.nannyDailyMonitoring
            .get(['bathing_json', 'bathing_type_sponge_frequency'])
            .setValidators([Validators.required]);
        } else {
          this.nannyDailyMonitoring
            .get(['bathing_json', 'bathing_type_sponge_frequency'])
            .clearValidators();
        }
        this.nannyDailyMonitoring
          .get(['bathing_json', 'bathing_type_sponge_frequency'])
          .updateValueAndValidity();
      }
    } else if (value == 'breastFeeding') {
      if (event.target.checked) {
        this.breast_feeding = true;
        // this.nannyDailyMonitoring.controls.breast_feeding.setValue(1);
      } else {
        this.breast_feeding = false;
        // this.nannyDailyMonitoring.controls.breast_feeding.setValue(0);
      }
    } else if (value == 'formulafeeding') {
      if (event.target.checked) {
        this.formula_feeding = true;
        console.log('Feeding is correct');
      } else {
        this.formula_feeding = false;
      }
    } else if (value == 'diaper') {
      console.log('.....', this.getDiaperingFormArray());
      if (event.target.value == 'urine_des') {
        if (event.target.checked) {
          console.log(this.getDiaperingFormArray().controls, index);
          this.getDiaperingFormArray().controls[index][
            'controls'
          ].urine_des.enable();
          // this.getDiaperingFormArray().controls.value.['controls'][event.target.value].enable();
        } else {
          this.getDiaperingFormArray().controls[index][
            'controls'
          ].urine_des.disable();
          this.getDiaperingFormArray().controls[index]['controls'][
            event.target.value
          ].setValue('');
        }
        if (event.target.checked) {
          this.getDiaperingFormArray().controls[index][
            'controls'
          ].urine_des.setValidators([Validators.required]);
        } else {
          this.getDiaperingFormArray().controls[index][
            'controls'
          ].urine_des.clearValidators();
        }
        this.getDiaperingFormArray().controls[index][
          'controls'
        ].urine_des.updateValueAndValidity();
      }
      if (event.target.value == 'stool_des') {
        if (event.target.checked) {
          console.log(
            'stool des',
            this.getDiaperingFormArray().controls,
            index
          );
          this.getDiaperingFormArray().controls[index][
            'controls'
          ].stool_des.enable();
          // this.getDiaperingFormArray().controls.value.['controls'][event.target.value].enable();
        } else {
          this.getDiaperingFormArray().controls[index][
            'controls'
          ].stool_des.disable();
          this.getDiaperingFormArray().controls[index]['controls'][
            event.target.value
          ].setValue('');
        }
      }
      if (event.target.checked) {
        this.getDiaperingFormArray().controls[index][
          'controls'
        ].stool_des.setValidators([Validators.required]);
      } else {
        this.getDiaperingFormArray().controls[index][
          'controls'
        ].stool_des.clearValidators();
      }
      this.getDiaperingFormArray().controls[index][
        'controls'
      ].stool_des.updateValueAndValidity();
    } else if (value === 'rice') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring.get(['solid_food', 'rice_remark']).enable();
      } else {
        this.nannyDailyMonitoring.get(['solid_food', 'rice_remark']).disable();
      }
    } else if (value === 'barley') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring.get(['solid_food', 'barley_remark']).enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_food', 'barley_remark'])
          .disable();
      }
    } else if (value === 'oats') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring.get(['solid_food', 'oats_remark']).enable();
      } else {
        this.nannyDailyMonitoring.get(['solid_food', 'oats_remark']).disable();
      }
    } else if (value === 'beans') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring.get(['solid_food', 'beans_remark']).enable();
      } else {
        this.nannyDailyMonitoring.get(['solid_food', 'beans_remark']).disable();
      }
    } else if (value === 'lentils') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['solid_food', 'lentils_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_food', 'lentils_remark'])
          .disable();
      }
    } else if (value === 'pasta') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring.get(['solid_food', 'pasta_remark']).enable();
      } else {
        this.nannyDailyMonitoring.get(['solid_food', 'pasta_remark']).disable();
      }
    }
    // else if (value === 'other') {
    //   console.log(event.target.checked)
    //   if(event.target.checked){
    //     this.nannyDailyMonitoring.get(['solid_food', 'other_remark']).enable();
    //   }
    //   else{
    //     this.nannyDailyMonitoring.get(['solid_food', 'other_remark']).disable();
    //   }
    // }
    else if (value === 'banana') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['solid_fruits', 'banana_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_fruits', 'banana_remark'])
          .disable();
      }
    } else if (value === 'apple') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['solid_fruits', 'apple_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_fruits', 'apple_remark'])
          .disable();
      }
    } else if (value === 'avacado') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['solid_fruits', 'avocado_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_fruits', 'avocado_remark'])
          .disable();
      }
    } else if (value === 'mango') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['solid_fruits', 'mango_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_fruits', 'mango_remark'])
          .disable();
      }
    } else if (value === 'pears') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['solid_fruits', 'pears_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_fruits', 'pears_remark'])
          .disable();
      }
    } else if (value === 'nectrain') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['solid_fruits', 'nectrain_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_fruits', 'nectrain_remark'])
          .disable();
      }
    } else if (value === 'figs') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring.get(['solid_fruits', 'figs_remark']).enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_fruits', 'figs_remark'])
          .disable();
      }
    } else if (value === 'papaya') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['solid_fruits', 'papaya_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_fruits', 'papaya_remark'])
          .disable();
      }
    } else if (value === 'kiwi') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring.get(['solid_fruits', 'kiwi_remark']).enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_fruits', 'kiwi_remark'])
          .disable();
      }
    } else if (value === 'grapes') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['solid_fruits', 'grapes_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_fruits', 'grapes_remark'])
          .disable();
      }
    } else if (value === 'orange') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['solid_fruits', 'orange_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_fruits', 'orange_remark'])
          .disable();
      }
    } else if (value === 'cherries') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['solid_fruits', 'cherries_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_fruits', 'cherries_remark'])
          .disable();
      }
    } else if (value === 'peach') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['solid_fruits', 'peach_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_fruits', 'peach_remark'])
          .disable();
      }
    } else if (value === 'date') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring.get(['solid_fruits', 'date_remark']).enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_fruits', 'date_remark'])
          .disable();
      }
    }
    // else if (value === 'other') {
    //   console.log(event.target.checked)
    //   if(event.target.checked){
    //     this.nannyDailyMonitoring.get(['solid_fruits', 'other_remark']).enable();
    //   }
    //   else{
    //     this.nannyDailyMonitoring.get(['solid_fruits', 'other_remark']).disable();
    //   }
    // }
    else if (value === 'pumpkin') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['solid_vegetable', 'pumpkin_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_vegetable', 'pumpkin_remark'])
          .disable();
      }
    } else if (value === 'potato') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['solid_vegetable', 'potato_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_vegetable', 'potato_remark'])
          .disable();
      }
    } else if (value === 'carrots') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['solid_vegetable', 'carrots_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_vegetable', 'carrots_remark'])
          .disable();
      }
    } else if (value === 'corn') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['solid_vegetable', 'corn_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_vegetable', 'corn_remark'])
          .disable();
      }
    } else if (value === 'marrow') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['solid_vegetable', 'marrow_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_vegetable', 'marrow_remark'])
          .disable();
      }
    } else if (value === 'eggplant') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['solid_vegetable', 'eggplant_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_vegetable', 'eggplant_remark'])
          .disable();
      }
    } else if (value === 'leafy_veggies') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['solid_vegetable', 'leafy_veggies_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_vegetable', 'leafy_veggies_remark'])
          .disable();
      }
    } else if (value === 'cruciferious') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['solid_vegetable', 'cruciferious_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_vegetable', 'cruciferious_remark'])
          .disable();
      }
    }
    // else if (value === 'other') {
    //   console.log(event.target.checked)
    //   if(event.target.checked){
    //     this.nannyDailyMonitoring.get(['solid_vegetable', 'other_remark']).enable();
    //   }
    //   else{
    //     this.nannyDailyMonitoring.get(['solid_vegetable', 'other_remark']).disable();
    //   }
    // }
    else if (value === 'yogurt') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring.get(['dairy_food', 'yogurt_remark']).enable();
      } else {
        this.nannyDailyMonitoring
          .get(['dairy_food', 'yogurt_remark'])
          .disable();
      }
    } else if (value === 'cheese') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring.get(['dairy_food', 'cheese_remark']).enable();
      } else {
        this.nannyDailyMonitoring
          .get(['dairy_food', 'cheese_remark'])
          .disable();
      }
    } else if (value === 'butter') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring.get(['dairy_food', 'butter_remark']).enable();
      } else {
        this.nannyDailyMonitoring
          .get(['dairy_food', 'butter_remark'])
          .disable();
      }
    }
    // else if (value === 'other') {
    //   console.log(event.target.checked)
    //   if(event.target.checked){
    //     this.nannyDailyMonitoring.get(['dairy_food', 'other_remark']).enable();
    //   }
    //   else{
    //     this.nannyDailyMonitoring.get(['dairy_food', 'other_remark']).disable();
    //   }
    // }
    else if (value === 'chicken') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['solid_protein', 'chicken_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_protein', 'chicken_remark'])
          .disable();
      }
    } else if (value === 'turkey') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['solid_protein', 'turkey_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_protein', 'turkey_remark'])
          .disable();
      }
    } else if (value === 'beef') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['solid_protein', 'beef_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_protein', 'beef_remark'])
          .disable();
      }
    } else if (value === 'fish') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['solid_protein', 'fish_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_protein', 'fish_remark'])
          .disable();
      }
    } else if (value === 'eggs') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['solid_protein', 'eggs_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['solid_protein', 'eggs_remark'])
          .disable();
      }
    }
    // else if (value === 'other') {
    //   console.log(event.target.checked)
    //   if(event.target.checked){
    //     this.nannyDailyMonitoring.get(['solid_protein', 'other_remark']).enable();
    //   }
    //   else{
    //     this.nannyDailyMonitoring.get(['solid_protein', 'other_remark']).disable();
    //   }
    // }
    else if (value === 'cry_method') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['sleep_training_json', 'cry_method_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['sleep_training_json', 'cry_method_remark'])
          .disable();
      }
    } else if (value === 'ferber_method') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['sleep_training_json', 'ferber_method_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['sleep_training_json', 'ferber_method_remark'])
          .disable();
      }
    } else if (value === 'check_method') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['sleep_training_json', 'check_method_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['sleep_training_json', 'check_method_remark'])
          .disable();
      }
    } else if (value === 'fading_method') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['sleep_training_json', 'fading_method_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['sleep_training_json', 'fading_method_remark'])
          .disable();
      }
    } else if (value === 'no_tear') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['sleep_training_json', 'no_tear_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['sleep_training_json', 'no_tear_remark'])
          .disable();
      }
    } else if (value === 'bath') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['bed_time_routine_json', 'bath_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['bed_time_routine_json', 'bath_remark'])
          .disable();
      }
    } else if (value === 'feed') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['bed_time_routine_json', 'feed_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['bed_time_routine_json', 'feed_remark'])
          .disable();
      }
    } else if (value === 'sing') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['bed_time_routine_json', 'sing_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['bed_time_routine_json', 'sing_remark'])
          .disable();
      }
    } else if (value === 'read_book') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['bed_time_routine_json', 'read_book_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['bed_time_routine_json', 'read_book_remark'])
          .disable();
      }
    } else if (value === 'avoid_screen') {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.nannyDailyMonitoring
          .get(['bed_time_routine_json', 'avoid_screen_remark'])
          .enable();
      } else {
        this.nannyDailyMonitoring
          .get(['bed_time_routine_json', 'avoid_screen_remark'])
          .disable();
      }
    }
  }

  // get baby details function
  getBabyDetails() {
    this.spinner.show()
    this.commonService.getBabyDetails().subscribe(
      (res: any) => {
        this.spinner.hide()
        console.log(res);
        this.babyValues = res?.data;
      },
      (error: HttpErrorResponse) => {
        this.spinner.hide()
        console.log(error);
      }
    );
  }

  getRemainingDetails(event: any) {
    let data = this.babyValues?.filter((res: any) => res?.id == event?.value);
    console.log(data[0].date_of_birth);
    let firstLetter = data[0]?.gender.toLowerCase();
    let capitalize =
      firstLetter.slice(0, 1).toUpperCase() +
      firstLetter.slice(1, firstLetter.length);
    this.babyGender = capitalize;
    this.selectedDate = data[0].date_of_birth;
    // Manually parse the date string in DD/MM/YYYY format
    const dateParts = data[0].date_of_birth.split('/');
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Months are 0-indexed in JavaScript
    const year = parseInt(dateParts[2], 10);

    const selectedDate = moment([year, month, day]);
    const today = moment();
    console.log(selectedDate);

    // Calculate the difference in years, months, and days
    const years = today.diff(selectedDate, 'years');
    selectedDate.add(years, 'years');

    const months = today.diff(selectedDate, 'months');
    selectedDate.add(months, 'months');

    const days = today.diff(selectedDate, 'days');

    this.years = years;
    this.month = months;
    this.days = days;
  }

  addFormulaFeeding(string: any, index: any) {
    let formulaArray = this.getFormulaFeedingArray();
    if (string == 'add') {
      console.log('feeding formula add');
      let newArray = new FormGroup({
        feeding_no: new FormControl(index + 1),
        feeding_check: new FormControl(null),
        feeding_time: new FormControl(null),
        feeding_amount: new FormControl(null),
        feeding_remark: new FormControl(null),
      });
      formulaArray.insert(index + 1, newArray);
      // console.log('formula feeding add', newArray);
    } else {
      console.log('feeding remove');
      formulaArray.removeAt(index);
    }
  }

  addBreastFeeding(string: any, index: any) {
    let breastArray = this.getBreastFeedingArray();
    if (string == 'add') {
      console.log('feeding add');
      let newArray = new FormGroup({
        feeding_no: new FormControl(index + 1),
        feeding_check: new FormControl(null),
        feeding_time: new FormControl(null),
        feeding_amount: new FormControl(null, [Validators.pattern('^[0-9]*$')]),
        feeding_remark: new FormControl(null),
      });
      breastArray.insert(index + 1, newArray);
      // console.log('breast feeding add', newArray);
    } else {
      console.log('feeding remove');
      breastArray.removeAt(index);
    }
  }
  private parseISOString(s: string): Date | null {
    if (!s) return null;
    return new Date(s);
  }

  /* -------------------------------------------------------------------------- */
  /*            //Soft Reloading            */
  /* -------------------------------------------------------------------------- */
  softReload() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  addDiaperingObject(string: any, index: any) {
    let diaperingArray = this.getDiaperingFormArray();
    if (string == 'add') {
      let newDiaperingArray = new FormGroup({
        no_nappy: new FormControl(index + 1),
        urine: new FormControl(null),
        urine_des: new FormControl({ value: null, disabled: true }),
        stool: new FormControl(null),
        stool_des: new FormControl({ value: null, disabled: true }),
      });
      diaperingArray.insert(index + 1, newDiaperingArray);
      console.log(diaperingArray?.value);
    } else {
      diaperingArray.removeAt(index);
    }
  }

  getVitaminFormArray() {
    return this.nannyDailyMonitoring.get(
      'vitamins_and_medications'
    ) as FormArray;
  }

  getDiaperingFormArray() {
    return this.nannyDailyMonitoring.get('diapering') as FormArray;
  }

  getBreastFeedingArray() {
    return this.nannyDailyMonitoring.get('feeding_breast') as FormArray;
  }

  getFormulaFeedingArray() {
    return this.nannyDailyMonitoring.get('feeding_formula') as FormArray;
  }

  addVitaminObject(string: any, index: any) {
    let vitaminArray = this.getVitaminFormArray();
    if (string == 'add') {
      let newVitaminArray = new FormGroup({
        vitamin: new FormControl(''),
      });
      vitaminArray.insert(index + 1, newVitaminArray);
      console.log(vitaminArray?.value);
    } else {
      vitaminArray.removeAt(index);
    }
  }

  async getMastersValueForDropdown() {
    await this.masterService
      .getUrineValues()
      .then((res: any) => {
        console.log('urineValues', res);
        this.urineValues = res?.data;
      })
      .catch((error) => {
        console.log(error);
      });

    await this.masterService
      .getStoolValues()
      .then((res: any) => {
        console.log(res);
        this.stoolValues = res?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  formSubmission(monitoringForm: any) {
    this.spinner.show()
    console.log(
      'feeding formula',
      this.nannyDailyMonitoring.controls.feeding_formula.value
    );
    const food_data = [
      {
        cerals_grains: JSON.stringify(
          this.nannyDailyMonitoring.controls.solid_food.value
        ),
      },
      {
        fruits: JSON.stringify(
          this.nannyDailyMonitoring.controls.solid_fruits.value
        ),
      },
      {
        vegetables: JSON.stringify(
          this.nannyDailyMonitoring.controls.solid_vegetable.value
        ),
      },
      {
        dairy_food: JSON.stringify(
          this.nannyDailyMonitoring.controls.dairy_food.value
        ),
      },
      {
        protein: JSON.stringify(
          this.nannyDailyMonitoring.controls.solid_protein.value
        ),
      },
    ];
    let feeding = [
      {
        feeding_breast: JSON.stringify(
          this.nannyDailyMonitoring.controls.feeding_breast.value
        ),
      },
      {
        feeding_formula: JSON.stringify(
          this.nannyDailyMonitoring.controls.feeding_formula.value
        ),
      },
    ];

    let data = JSON.parse(JSON.stringify(this.nannyDailyMonitoring.value));
    console.log('feeding data', data);
    data.feeding = JSON.stringify(data.feeding);
    data.feeding = JSON.stringify(feeding);
    data.solid_food_feeding_json = JSON.stringify(food_data);
    data.sleeping = JSON.stringify(data?.sleeping);
    data.diapering = JSON.stringify(data?.diapering);
    data.bed_time_routine_json = JSON.stringify(data?.bed_time_routine_json);
    data.sleep_training_json = JSON.stringify(data?.sleep_training_json);
    data.bathing_json = JSON.stringify(data?.bathing_json);
    data.vitamins_and_medications = JSON.stringify(
      data?.vitamins_and_medications
    );

    delete data['feeding_breast'];
    delete data['feeding_formula'];
    delete data['solid_food'];
    delete data['dairy_food'];
    delete data['solid_fruits'];
    delete data['solid_protein'];
    delete data['solid_vegetable'];
    Object.keys(this.nannyDailyMonitoring.controls).forEach((res) => {
      console.log(this.nannyDailyMonitoring.controls[res].status, res);
    });
    window.scroll(0, 0);
    if (monitoringForm.valid) {
      if (this.isedit == true) {
        const id = localStorage.getItem('editedOrderId');
        // this.spinner.show();
        this.dailyService
          .editNurseMonitoring(data, id)
          .then(
            (res: any) => {
              this.spinner.hide()

              console.log(`${res}--api called`);
              console.log('API Response:', res);
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: res?.message,
              });
              setTimeout(() => {
                this.softReload();
              }, 400);
              // this.spinner.hide();
            },
            (error) => {
              this.spinner.hide()

              // Handle any errors that occurred during the API call
              console.error('API Error:', error?.message);
            }
          )
          .catch((error) => {
            this.spinner.hide()

            console.log(error);
          });
      } else {
        // this.spinner.show();
        this.dailyService
          .postDailyMonitoring(data)
          .then(
            (res: any) => {
              this.spinner.hide()

              console.log(`${res}--api called`);
              console.log('API Response:', res);
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: res?.message,
              });
              setTimeout(() => {
                this.softReload();
              }, 400);
              // this.spinner.hide();
            },
            (error) => {
              this.spinner.hide()

              // Handle any errors that occurred during the API call
              console.error('API Error:', error?.message);
            }
          )
          .catch((error) => {
            this.spinner.hide()

            console.log(error);
          });
      }
      // console.log(this.nannyDailyMonitoring.value);
      // this.commonService
      //   .postEnviromentalChildSafety(this.nannyDailyMonitoring.value)
      //   .then((res: any) => {
      //     console.log(res);
      //     this.messageService.add({
      //       severity: 'success',
      //       summary: 'Success',
      //       detail: res?.message,
      //     });
      //   });
    } else {
      this.spinner.hide()

      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Fill All the Details!',
      });
    }
  }
}
