import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeedingAssesmentFormService {

  apiBaseUrl: string = environment.api_base_url;
  constructor(private http: HttpClient) { }
  /* -------------------------------------------------------------------------- */
  /*               // Post feeding assessment form data                         */
  /* -------------------------------------------------------------------------- */
  createFeedingAssessmentForm(data: any){
    return this.http.post(this.apiBaseUrl+ 'create-assesment', data).toPromise();
  }
}
