import { Component, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { CommonApiService } from './services/common-api.service';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent {
    location: any;
    routerSubscription: any;
    authModalState: boolean = false;
    constructor(private router: Router,
        public commanapiService: CommonApiService) {
       
    }

    ngOnInit(){
        this.recallJsFuntions();
        this.onSessionExpire();
    }
    onSessionExpire(){
        this.commanapiService.authModalState.subscribe((res:any)=>{
            this.authModalState = res;
        })
    }
    romoveToken() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('token');
        this.router.navigateByUrl('/');
        this.commanapiService.authModalState.next(false);
      }
    recallJsFuntions() {
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/main.js');
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }
}