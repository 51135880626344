<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->
<!-- import { DialogModule } from 'primeng/dialog'; -->

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">
    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
      <h1>{{ Content.title }}</h1>
      <ol class="breadcrumb">
        <li class="item"><a routerLink="/">Home</a></li>
        <li class="item">{{ Content.subTitle }}</li>
        <li class="item">{{ Content.title }}</li>
      </ol>
    </div>

    <!-- <div class="add-listings-box">
            <div class="row justify-content-between align-items-center">
                <div class="form-group col-4">
                    <label class="p-0">Name <span class="required">*</span></label>
                    <input type="text" name="name" class="form-control" placeholder="Name" (change)="getInput($event)" />
                </div>
                <div class="add-listings-btn mb-0 col-4 text-end">
                    <button type="submit" (click)="addTableData()">Add</button>
                </div>
            </div>
        </div> -->
    <div class="add-listings-box">
      <div class="Master-view-box">
        <div class="theader d-flex justify-content-between">
          <div class="d-flex w-100">
            <span class="p-input-icon-left ml-auto mr-2 w-100%">
              <i class="pi pi-search"></i>
              <input pInputText type="text" class="form-control" style="text-align: left; padding-left: 20%;height: 45px !important" scriptInjection placeholder="Search Keyword"
                (input)="dt1.filterGlobal($event.target.value, 'contains')" [(ngModel)]="filterVal" />
            </span>
            <button  pButton  type="button" class="mx-2" (click)="reset(dt1)" icon="bx bx-reset" tooltipPosition="bottom" style="height: 45px;"></button>
          </div>
          <div class="add-listings-btn" style="text-align: right">
            <a routerLink="/portal/initial-assessment-pedia" class="nav-link">
              <button type="submit">Add</button>
            </a>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-lg-12 col-md-12">
          <p-table #dt1 [value]="ptAssListData" dataKey="data" [rows]="10" [showCurrentPageReport]="true"
            [tableStyle]="{ 'min-width': '50rem', width: '100%' }" styleClass="p-datatable-sm p-datatable-customers"
            [rowsPerPageOptions]="[10, 25, 50]" responsiveLayout="scroll" [loading]="loading" [paginator]="true"
            currentPageReportTemplate="Showing {first} to
            {last} of {totalRecords} entries" [globalFilterFields]="['initial_assessment', 'history_present_illness', 'date']"
            editMode="row">
            <ng-template pTemplate="header">
              <tr>
                <th style="min-width: 62px">S. No.</th>
                <th>Action</th>
                <!-- <th>Date Of Birth</th> -->
                <!-- <th>ID</th> -->
                <th>Date</th>
                <th>Initial Assessment</th>
                <th>History of Present Illness</th>
              </tr>
            </ng-template>
            <!-- table to show data -->
            <ng-template pTemplate="body" let-list let-i="rowIndex">
              <tr>
                <td>
                  {{ i + 1 }}
                </td>
                <td>
                  <button pButton pRipple class="p-button-rounded p-button-text p-button-info" pTooltip="View"
                    tooltipPosition="bottom">
                    <i (click)="showMaximizableDialog(list)" class="far fa-eye"></i>
                  </button>
                </td>
                <!-- <td>{{ list?.baby_details_id ?? '-' }}</td> -->
                <td>{{ list?.date | date:'dd-MM-yyyy' ?? "-" }}</td>
                <td>{{ list?.initial_assessment
                  ?? "-"| titlecase}}</td>
                <td>{{ list?.history_present_illness?? "-" | titlecase}}</td>
                <!-- <td>{{ list?.years ?? "-" }}</td> -->
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="5">No Data Found.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <!-- ****************************************************view dialog********************************************************* -->
        <!-- <p-dialogheader="Baby Details" [(visible)]="displayMaximizable" [modal]="true" [style]="{ width: '90vw' }"
         [draggable]="false" [resizable]="false"></p-dialog> -->
        <!----------------------------------------------------------- view dialog --------------------------------------------------------->
        <!-- ***************************************************************************************************************** -->
        <p-dialog header="Pedia Details" [(visible)]="displayMaximizable" [modal]="true" [style]="{ width: '90vw' }"
          [draggable]="false" [resizable]="false">
          <!-- Start -->

          <!-- <ng-container *ngFor="let item of ptAssListData"> -->
          <ng-container *ngFor="let data of viewDetail">
            <div class="row">
              <div class="col-lg-12">
                <div class="my-profile-box">
                  <form>
                    <!-- Basic detail -->
                    <div class="row">
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label>Date :</label>
                          <input type="text" readonly class="form-control"
                          *ngIf="data?.date; else dash"
                          value="{{ data?.date | date:'dd-MM-yyyy' }}" />

                   <ng-template #dash>
                     <input type="text" readonly class="form-control" value="-" />
                   </ng-template>
                          <!-- <input type="text" readonly class="form-control" value="{{ data?.date | date:'dd-MM-yyyy' ?? '-' }}" /> -->
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label>Initial Assessment </label>
                          <input type="text" readonly class="form-control" value="{{ data?.initial_assessment
                              ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> History of Present Illness:</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.history_present_illness ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Past Medical History:</label>
                        <input type="text" readonly class="form-control" value="{{ data?.past_medical_history
                               ?? '-' }}" />
                      </div>
                    </div>
                    <div *ngFor="let item of data?.assesment_json" class="row">
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label>Medications:</label>
                          <!-- <ng-container *ngFor="let item of data?.assesment_json"> -->
                            <!-- <input type="text" readonly class="form-control" value="{{item?.medication
                              ?? '-' }}" /> -->
                          <!-- </ng-container> -->
<!--
                                <input type="text" readonly class="form-control"
                                  value="{{data?.medication?? ' -'}}"> -->
                                  <input type="text" readonly class="form-control"
                              *ngIf="
                              item?.medication !== null &&
                              item?.medication!== '' &&
                              item?.medication!==
                                undefined;
                              else elseBlock
                              " value="{{
                              item?.medication
                              }}" />
                            <ng-template #elseBlock>
                              <input type="text" readonly class="form-control" value="-" />
                            </ng-template>

                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Tool:</label>
                        <input type="text" readonly class="form-control" value="{{ data?.pain_assessment_tool
                              ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Score :</label>
                        <input type="text" readonly class="form-control" value="{{ data?.pain_assessment_score
                              ?? '-' }}" />
                        </div>
                      </div>
                    </div>




                    <!-- intial assissment -->
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Vital Signs: Pre-session</label>
                        <!-- <input type="text" readonly class="form-control" value="{{ data?.mode_delivery ?? '-' }}" /> -->
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Blood Pressure :</label>
                        <input type="text" readonly class="form-control" value="{{ data?.blood_pressure
                            ?? '-' }}" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label>Temperature:</label>
                          <input type="text" readonly class="form-control" value="{{ data?.temperature ?? '-' }}" />
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label>Heart Rate :</label>
                          <input type="text" readonly class="form-control" value="{{ data?.heart_rate ?? '-' }}" />
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label>SPO2 :</label>
                          <input type="text" readonly class="form-control" value="{{ data?.spo2 ?? '-' }}" />
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label>RR:</label>
                          <input type="text" readonly class="form-control" value="{{ data?.rr ?? '-' }}" />
                        </div>
                      </div>
                    </div>


                    <!-- Mother detail -->
                    <h3 class="fw-bold">Attachment:</h3>
                    <div class="row">
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label class="px-0">PEG:</label>
                          <input type="text" readonly class="form-control"
                            value="{{ data?.attachment_PEG == 1? 'Yes': '-'?? '-' }}" />
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label class="px-0">Trache:</label>
                          <input type="text" readonly class="form-control"
                            value="{{ data?.attachment_trache == 1? 'Yes': '-'?? '-' }}" />
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label class="px-0"> NGT:</label>
                          <input type="text" readonly class="form-control"
                            value="{{ data?.attachment_NGT== 1? 'Yes': '-' ?? '-' }}" />
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label class="px-0">FC1:</label>
                          <input type="text" readonly class="form-control"
                            value="{{ data?.attachment_FC1 == 1? 'Yes': '-'?? '-' }}" />
                        </div>
                      </div>




                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label>Colostomy :</label>
                          <input type="text" readonly class="form-control" value="{{ data?.attachment_colostomy== 1? 'Yes': '-'
                              ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>FC2 :</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.attachment_FC2 == 1? 'Yes': '-'?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Ventilator :</label>
                        <!-- <input type="text" readonly class="form-control" value="{{
                                data?.mother_para == 'null' ? '-' : data?.mother_para
                              }}" /> -->
                        <input type="text" readonly class="form-control"
                          value="{{ data?.attachment_ventilator == 1? 'Yes': '-'?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-122">
                      <div class="form-group">
                        <label class="px-0">O2 Canula:</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.attachment_canula == 1? 'Yes': '-'?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-122">
                      <div class="form-group">
                        <label class="px-0">NA:</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.attachment_canula == 1? 'Yes': '-'?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-122">
                      <div class="form-group">
                        <label class="px-0">Others:</label>
                        <input type="text" readonly class="form-control" value="{{ data?.others_box

                              == 1? 'Yes': '-'?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-122">
                      <div class="form-group">
                        <label class="px-0">Other Specify:</label>
                        <input type="text" readonly class="form-control" value="{{ data?.others

                              ?? '-' }}" />
                        </div>
                      </div>
                    </div>

                    <!-- Attachment-->
                    <h3 class="fw-bold">Respiratory Assessment:</h3>

                    <div class="row">
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label>Cough :</label>
                          <input type="text" readonly class="form-control"
                            value="{{ data?.repiratory_assessment ?? '-' }}" />
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label>Remark :</label>
                          <input type="text" readonly class="form-control" value="{{ data?.cough_remark ?? '-' }}" />
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label>Chest Secretions:</label>
                          <input type="text" readonly class="form-control" value="{{ data?.chest_secretion ?? '-' }}" />
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label>Remark:</label>
                          <input type="text" readonly class="form-control"
                            value="{{ data?.chest_secretion_remark ?? '-' }}" />
                        </div>
                      </div>
                    </div>
                    <h3 class="fw-bold">Cognition:</h3>
                    <!--
                      <div class="row">
                        <div class="col-xl-2 col-lg-4 col-md-4">
                          <div class="form-group">
                            <label>Neurosensory:</label>
                          </div>
                        </div>
                        <div class="col-xl-2 col-lg-4 col-md-4">
                          <div class="form-group">
                            <label>Intact :</label>
                            <input type="text" readonly class="form-control" value="{{ data?.cough_remark ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-2 col-lg-4 col-md-4">
                          <div class="form-group">
                            <label>Impaired:</label>
                            <input type="text" readonly class="form-control" value="{{ data?.chest_secretion ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-2 col-lg-4 col-md-4">
                          <div class="form-group">
                            <label>Absent</label>
                            <input type="text" readonly class="form-control" value="{{ data?.chest_secretion_remark ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl- col-lg-4 col-md-4">
                          <div class="form-group">
                            <label>Not tested</label>
                          </div>
                        </div>
                      </div>
                       -->
                    <div class="form-group table-responsive">
                      <table class="table">
                        <thead>
                          <tr class="border-light" style="white-space: nowrap;">
                            <th scope="col"><label class="subHeading px-0">Neurosensory:</label></th>
                            <th scope="col"><label class="subHeading px-0">Intact</label></th>
                            <th scope="col"><label class="subHeading px-0">Impaired</label></th>
                            <th scope="col"><label class="subHeading px-0">Absent</label></th>
                            <th scope="col"><label class="subHeading px-0">Not tested</label></th>
                            <th scope="col"><label class="subHeading px-0">Remark</label></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="border-light">
                            <td scope="row"> <label class=" px-0">Light Touch</label></td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{ data?.lightallvalue == 1? 'Yes': '-'?? '-' }}" /></td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{ data?.lightallvalue == 2? 'Yes': '-'?? '-' }}" /></td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{ data?.lightallvalue == 3? 'Yes': '-'?? '-' }}" /></td>
                            <td><input type="text" readonly class="form-control"
                                value="{{ data?.lightallvalue== 4? 'Yes': '-' ?? '-' }}" /></td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{ data?.light_touch_remark ?? '-' }}" /></td>
                          </tr>
                          <tr class="border-light">
                            <td scope="row"> <label class=" px-0">Blunt Touch/Deep</label></td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{ data?.blunttouchall == 1? 'Yes': '-'?? '-' }}" /></td>
                            <td><input type="text" readonly class="form-control"
                                value="{{ data?.blunttouchall == 2? 'Yes': '-'?? '-' }}" /></td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{ data?.blunttouchall == 3? 'Yes': '-'?? '-' }}" /></td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{ data?.blunttouchall == 4? 'Yes': '-'?? '-' }}" /></td>
                            <td><input type="text" readonly class="form-control"
                                value="{{ data?.blunt_remark ?? '-' }}" /></td>
                          </tr>
                          <tr class="border-light">
                            <td scope="row"> <label class="px-0 ">Pressure</label></td>
                            <td><input type="text" readonly class="form-control"
                                value="{{ data?.pressureall== 1? 'Yes': '-' ?? '-' }}" /></td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{ data?.pressureall== 2? 'Yes': '-' ?? '-' }}" /></td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{ data?.pressureall == 3? 'Yes': '-'?? '-' }}" /></td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{ data?.pressureall == 4? 'Yes': '-'?? '-' }}" /></td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{ data?.pressure_remark ?? '-' }}" /></td>
                          </tr>
                          <tr class="border-light">
                            <td scope="row"> <label class="px-0 ">Pin Prick</label></td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{ data?.pinprickall == 1? 'Yes': '-'?? '-' }}" /></td>
                            <td><input type="text" readonly class="form-control"
                                value="{{ data?.pinprickall == 2? 'Yes': '-'?? '-' }}" /></td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{ data?.pinprickall== 3? 'Yes': '-' ?? '-' }}" /></td>
                            <td><input type="text" readonly class="form-control"
                                value="{{ data?.pinprickall == 4? 'Yes': '-'?? '-' }}" /></td>
                            <td> <input type="text" readonly class="form-control" value="{{ data?.pin_remark ?? '-' }}" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="form-group">
                      <h3 class="fw-bold">Cognition:</h3>

                      <!-- Oriented to people -->
                      <div class="row">
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <label class="px-0">Oriented to people :</label>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <label>Oriented people </label>
                            <input type="text" readonly class="form-control"
                              value="{{data?.oriented_people ?? '-' }}" />

                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Oriented Remark </label>
                            <input type="text" readonly class="form-control"
                              value="{{ data?.oriented_people_remark ?? '-' }}" />
                          </div>
                        </div>


                      <!-- Oriented to time -->
                      <!-- <div class="row"> -->
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <label class="px-0">Oriented to time :</label>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <label>Oriented people </label>
                            <input type="text" readonly class="form-control"
                              value="{{data?.oriented_time ?? '-' }}" />

                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">

                            <label>Oriented time remark </label>
                            <input type="text" readonly class="form-control"
                              value="{{ data?.oriented_time_remark ?? '-' }}" />

                        </div>
                      <!-- </div> -->
                      <!-- <div class="row"> -->
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <label class="px-0">Oriented to place :</label>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <label>Oriented place</label>
                            <input type="text" readonly class="form-control"
                              value="{{data?.oriented_place ?? '-' }}" />

                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <label>Oriented place remark</label>
                            <input type="text" readonly class="form-control"
                              value="{{ data?.oriented_place_remark ?? '-' }}" />

                        </div>
                      </div>

                      <!-- </div> -->
                      <div class="add-listings-box">
                        <h3>Palpation</h3>
                        <div class="form-group">
                          <label class="subHeading px-0">Soft Tissues and Skin Assessment:</label>
                          <!-- Swelling -->
                          <div class="row">
                            <div class="col-xl-4 col-lg-12 col-md-12">
                              <div class="form-group">
                                <label>Swelling:</label>
                                <input type="text" readonly class="form-control"
                                value="{{data?.swelling ?? '-' }}" />
                              </div>
                            </div>
                            <div class="col-xl-4 col-lg-12 col-md-12">
                              <div class="form-group">
                                <label>Swelling remark: </label>
                                <input type="text" readonly class="form-control" value="{{ data?.swelling_remark
                                    ?? '-' }}" />
                            </div>
                          </div>
                          <div class="col-xl-4 col-lg-12 col-md-12">
                            <div class="form-group">
                              <label> Scar:</label>
                              <input type="text" readonly class="form-control"
                                value="{{data?.scar ?? '-' }}" />
                            </div>
                          </div>
                          <div class="col-xl-4 col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>Scar remark</label>
                              <input type="text" readonly class="form-control" value="{{ data?.scar_remark
                                     ?? '-' }}" />
                            </div>
                          </div>
                          <div class="col-xl-4 col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>Worm:</label>
                              <input type="text" readonly class="form-control"
                                value="{{data?.worm ?? '-' }}" />
                            </div>
                          </div>
                          <div class="col-xl-4 col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>Worm remark</label>
                              <input type="text" readonly class="form-control" value="{{ data?.worm_remark
                                    ?? '-' }}" />
                            </div>
                          </div>
                          <div class="col-xl-4 col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>Infection:</label>
                              <input type="text" readonly class="form-control"
                                value="{{data?.infection ?? '-' }}" />
                            </div>
                          </div>
                          <div class="col-xl-4 col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>Infection remark</label>
                              <input type="text" readonly class="form-control" value="{{ data?.infection_remark
                                    ?? '-' }}" />
                            </div>
                          </div>
                          <div class="col-xl-4 col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>Wound:</label>
                              <input type="text" readonly class="form-control"
                                value="{{data?.wound ?? '-' }}" />
                            </div>
                          </div>
                          <div class="col-xl-4 col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>Wound remark</label>
                              <input type="text" readonly class="form-control" value="{{ data?.wound_remark
                                    ?? '-' }}" />
                            </div>
                          </div>
                          <div class="col-xl-4 col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>Pain:</label>
                              <input type="text" readonly class="form-control"
                                value="{{data?.pain?? '-' }}" />
                            </div>
                          </div>
                          <div class="col-xl-4 col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>Pain remark</label>
                              <input type="text" readonly class="form-control" value="{{ data?.pain_remark
                                    ?? '-' }}" />
                            </div>
                          </div>
                          <div class="col-xl-4 col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>Other</label>
                              <input type="text" readonly class="form-control"
                                value="{{data?.others_soft_tissue ?? '-' }}" />
                            </div>
                          </div>
                          <div class="col-xl-4 col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>Other remark</label>
                              <input type="text" readonly class="form-control" value="{{ data?.others_remark
                                    ?? '-' }}" />
                              </div>
                            </div>
                          </div>
  <!--
                          <div class="row">
                            <div class="col-sm-3 col-6">
                              <label class="px-0">Swelling</label>
                            </div>
                            <div class="col-sm-2 col-3">
                              <p class="d-flex align-items-center">
                                <input type="text" readonly class="form-control"
                                  value="{{data?.swelling== 1? 'Yes': '-' ?? '-' }}" />
                              </p>
                            </div>
                            <div class="col-sm-5 col-12">
                              <div class="form-group">
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.swelling_remark ?? '-' }}" />
                              </div>
                            </div> -->


                          <!-- Scar -->

                            <!-- <div class="col-sm-3 col-6">
                              <label class="px-0">Scar</label>
                            </div>
                            <div class="col-sm-2 col-3">
                              <p class="d-flex align-items-center">
                                <input type="text" readonly class="form-control"
                                  value="{{data?.scar== 1? 'Yes': '-' ?? '-' }}" />
                              </p>
                            </div>
                            <div class="col-sm-5 col-12">
                              <div class="form-group">
                                <label>Swelling remark</label>
                                <input type="text" readonly class="form-control" value="{{ data?.scar_remark ?? '-' }}" />
                              </div>
                            </div> -->
                          <!-- </div> -->

                          <!-- Worm -->
                          <!-- <div class="row"> -->
                            <!-- <div class="col-sm-3 col-6">
                              <label class="px-0">Worm</label>
                            </div>
                            <div class="col-sm-2 col-3">
                              <p class="d-flex align-items-center">
                                <input type="text" readonly class="form-control"
                                  value="{{data?.worm== 1? 'Yes': '-' ?? '-' }}" />
                              </p>
                            </div>
                            <div class="col-sm-5 col-12">
                              <div class="form-group">
                                <label>worm remark</label>
                                <input type="text" readonly class="form-control" value="{{ data?.worm_remark ?? '-' }}" />
                              </div>
                            </div> -->
                          <!-- </div> -->

                          <!-- Infection -->
                          <!-- <div class="row"> -->
                            <!-- <div class="col-sm-3 col-6">
                              <label class="px-0">Infection</label>
                            </div>
                            <div class="col-sm-2 col-3">
                              <p class="d-flex align-items-center">
                                <input type="text" readonly class="form-control"
                                  value="{{data?.infection== 1? 'Yes': '-' ?? '-' }}" />
                              </p>
                            </div>
                            <div class="col-sm-5 col-12">
                              <p class="d-flex align-items-center">
                                <label>infection remark</label>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.infection_remark ?? '-' }}" />
                              </p>
                            </div> -->
                          <!-- </div> -->

                          <!-- Wound -->
                          <!-- <div class="row"> -->
                            <!-- <div class="col-sm-3 col-6">
                              <label class="px-0">Wound</label>
                            </div>
                            <div class="col-sm-2 col-3">
                              <p class="d-flex align-items-center">
                                <input type="text" readonly class="form-control"
                                  value="{{data?.wound== 1? 'Yes': '-' ?? '-' }}" />
                              </p>
                            </div>
                            <div class="col-sm-5 col-12">
                              <div class="form-group">
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.wound_remark ?? '-' }}" />
                              </div>
                            </div> -->
                        <!-- <div class="col-sm-3 col-6">
                              <label class="px-0">Worm</label>
                            </div>
                            <div class="col-sm-2 col-3">
                              <p class="d-flex align-items-center">
                                <input type="text" readonly class="form-control"
                                  value="{{data?.worm== 1? 'Yes': '-' ?? '-' }}" />
                              </p>
                            </div>
                            <div class="col-sm-5 col-12">
                              <div class="form-group">
                                <input type="text" readonly class="form-control" value="{{ data?.worm_remark ?? '-' }}" />
                              </div>
                            </div> -->
                          <!-- </div> -->

                          <!-- Pain -->
                          <!-- <div class="row"> -->
                            <!-- <div class="col-sm-3 col-6">
                              <label class="px-0">Pain</label>
                            </div>
                            <div class="col-sm-2 col-3">
                              <p class="d-flex align-items-center">
                                <input type="text" readonly class="form-control"
                                  value="{{data?.pain== 1? 'Yes': '-' ?? '-' }}" />
                            </div>
                            <div class="col-sm-5 col-12">
                              <p class="d-flex align-items-center">
                                <label>Pain remark</label>
                                <input type="text" readonly class="form-control" value="{{ data?.pain_remark ?? '-' }}" />
                              </p>
                            </div>
                          </div> -->


                          <!-- Others -->

                            <!-- <div class="col-sm-3 col-6">
                              <label class="px-0">Others</label>
                            </div>
                            <div class="col-sm-2 col-3">
                              <p class="d-flex align-items-center">
                                <input type="text" readonly class="form-control"
                                  value="{{data?.others== 1? 'Yes': '-' ?? '-' }}" />
                              </p>
                            </div>
                            <div class="col-sm-5 col-12">
                              <div class="form-group">
                                <label>other remark</label>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.others_remark ?? '-' }}" />
                              </div>
                            </div> -->
                          </div>
                        </div>
                      </div>

                    <!-- </div> -->
                    <div class="add-listings-box">
                      <h3>Motor Components</h3>
                      <label class="subHeading mb-3">Joint and Muscle:</label>
                      <div class="form-group table-responsive">
                        <table class="table table-bordered">
                          <thead>
                            <tr style="white-space: nowrap;">
                              <th scope="col" class="text-center" rowspan="2"><label class="subHeading px-0">Joint</label>
                              </th>
                              <th scope="col" class="text-center" rowspan="2"><label
                                  class="subHeading px-0">Movement</label>
                              </th>
                              <th scope="col" class="text-center" rowspan="2"><label class="subHeading px-0">Norm
                                  ROM</label>
                              </th>
                              <th scope="col" class="text-center" colspan="2"><label class="subHeading px-0">Passive
                                  ROM</label></th>
                              <th scope="col" class="text-center" colspan="2"><label class="subHeading px-0">Active
                                  ROM</label>
                              </th>
                              <th scope="col" class="text-center" rowspan="2"><label
                                  class="subHeading px-0">Comments</label>
                              </th>
                              <th scope="col" class="text-center" colspan="2"><label class="subHeading px-0">Muscle
                                  Strength</label></th>
                            </tr>
                            <tr>
                              <th class="text-center">R</th>
                              <th class="text-center">L</th>
                              <th class="text-center">R</th>
                              <th class="text-center">L</th>
                              <th class="text-center">R</th>
                              <th class="text-center">L</th>
                            </tr>
                          </thead>
                          <tbody>
                            <!-- NECK ROW -->
                            <!-- Flexion -->
                            <tr>
                              <td scope="row" rowspan="4"> <label class="px-0 ">Neck</label></td>
                              <td>Flexion</td>
                              <td>0-50</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.neck_flexion_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.neck_flexion_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.neck_flexion_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.neck_flexion_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.neck_flexion_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.neck_flexion_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.neck_flexion_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!-- Extension -->
                            <tr>
                              <td>Extension</td>
                              <td>0-60</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.neck_extension_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.neck_extension_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.neck_extension_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.neck_extension_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.neck_extension_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.neck_extension_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.neck_extension_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!-- Rotation -->
                            <tr>
                              <td>Rotation</td>
                              <td>0-80</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.neck_rotation_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.neck_rotation_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.neck_rotation_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.neck_rotation_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.neck_rotation_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.neck_rotation_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.neck_rotation_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!-- Side flex -->
                            <tr>
                              <td>Side flex</td>
                              <td>0-45</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.neck_sideFlex_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.neck_sideFlex_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.neck_sideFlex_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.neck_sideFlex_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.neck_sideFlex_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.neck_sideFlex_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.neck_sideFlex_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!-- SHOULDER ROW -->
                            <!-- Flexion -->
                            <tr>
                              <td scope="row" rowspan="6"> <label class="px-0 ">Shoulder</label></td>
                              <td>Flexion</td>
                              <td>0-180</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_flexion_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_flexion_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_flexion_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_flexion_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.shoulder_flexion_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_flexion_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_flexion_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!-- Extension -->
                            <tr>
                              <td>Extension</td>
                              <td>0-60</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_extension_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_extension_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_extension_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_extension_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.shoulder_extension_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_extension_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_extension_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!-- Abduction -->
                            <tr>
                              <td>Abduction</td>
                              <td>0-180</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_abduction_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_abduction_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_abduction_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_abduction_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.shoulder_abduction_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_abduction_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_abduction_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!-- Adduction -->
                            <tr>
                              <td>Adduction</td>
                              <td>0-90</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_adduction_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_adduction_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_adduction_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_adduction_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.shoulder_adduction_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_adduction_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_adduction_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!-- Internal Rotation -->
                            <tr>
                              <td>Internal Rotation</td>
                              <td>0-70</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_internal_rotation_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_internal_rotation_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_internal_rotation_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_internal_rotation_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.shoulder_internal_rotation_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_internal_rotation_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_internal_rotation_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!-- External Rotation -->
                            <tr>
                              <td>External Rotation</td>
                              <td>0-90</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_external_rotation_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_external_rotation_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_external_rotation_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_external_rotation_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.shoulder_external_rotation_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_external_rotation_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.shoulder_external_rotation_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!-- ELBOW ROW-->
                            <!-- Flexion -->
                            <tr>
                              <td scope="row" rowspan="4"> <label class="px-0 ">Elbow</label></td>
                              <td>Flexion</td>
                              <td>0-50</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.elbow_flexion_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.elbow_flexion_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.elbow_flexion_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.elbow_flexion_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.elbow_flexion_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.elbow_flexion_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.elbow_flexion_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                            </tr>

                            <!-- Extension -->
                            <tr>
                              <td>Extension</td>
                              <td>0-60</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.elbow_extension_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.elbow_extension_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.elbow_extension_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.elbow_extension_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.elbow_extension_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.elbow_extension_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.elbow_extension_muscle_l== 1? 'Yes': '-' ?? '-' }}" />

                            </tr>

                            <!-- Supination -->
                            <tr>
                              <td>Supination</td>
                              <td>0-80</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.elbow_supination_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.elbow_supination_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.elbow_supination_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.elbow_supination_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.elbow_supination_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.elbow_supination_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.elbow_supination_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!-- Pronation -->
                            <tr>
                              <td>Pronation</td>
                              <td>0-80</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.elbow_pronation_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.elbow_pronation_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.elbow_pronation_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.elbow_pronation_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.elbow_pronation_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.elbow_pronation_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.elbow_pronation_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!--WRIST ROW -->
                            <!-- Flexion -->
                            <tr>
                              <td scope="row" rowspan="4"> <label class="px-0 ">Wrist</label></td>
                              <td>Flexion</td>
                              <td>0-50</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.wrist_flexion_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.wrist_flexion_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.wrist_flexion_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.wrist_flexion_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.elbow_pronation_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.wrist_flexion_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.wrist_flexion_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!-- Extension -->
                            <tr>
                              <td>Extension</td>
                              <td>0-70</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.wrist_extension_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.wrist_extension_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.wrist_extension_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.wrist_extension_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.wrist_extension_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.wrist_extension_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.wrist_extension_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!-- Ulnar Deviation -->
                            <tr>
                              <td>Ulnar Deviation</td>
                              <td>0-30</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.wrist_ulnar_deviation_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.wrist_ulnar_deviation_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.wrist_ulnar_deviation_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.wrist_ulnar_deviation_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.wrist_ulnar_deviation_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.wrist_ulnar_deviation_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.wrist_ulnar_deviation_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!-- Radial Deviation -->
                            <tr>
                              <td> Radial Deviation</td>
                              <td>0-20</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.wrist_radial_deviation_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.wrist_radial_deviation_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.wrist_radial_deviation_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.wrist_radial_deviation_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.wrist_radial_deviation_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.wrist_radial_deviation_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.wrist_radial_deviation_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!-- DIGITS ROW -->
                            <!-- Thumb MCP -->
                            <tr>
                              <td scope="row" rowspan="5"> <label class="px-0 ">Digits</label></td>
                              <td>Thumb MCP Ext/flex</td>
                              <td>10/30</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_thump_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_thump_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_thump_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_thump_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.digits_thump_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_thump_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_thump_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!-- Thump IP -->
                            <tr>
                              <td>Thump IP Ext/Flex</td>
                              <td>0/80</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_thump_ip_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_thump_ip_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_thump_ip_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_thump_ip_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.digits_thump_ip_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_thump_ip_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_thump_ip_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!-- MCP Ext/Flex -->
                            <tr>
                              <td>MCP Ext/Flex</td>
                              <td>0/90</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_mcp_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_mcp_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_mcp_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_mcp_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.digits_mcp_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_mcp_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_mcp_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!-- PIP Ext/Flex -->
                            <tr>
                              <td>PIP Ext/Flex</td>
                              <td>0/100</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_pip_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_pip_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_pip_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_pip_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.digits_pip_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_pip_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_pip_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!-- DIP Ext/Flex -->
                            <tr>
                              <td>DIP Ext/Flex</td>
                              <td>0/90</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_dip_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_dip_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_dip_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_dip_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.digits_dip_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_dip_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.digits_dip_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!-- HIP ROW -->
                            <!-- Flexion -->
                            <tr>
                              <td scope="row" rowspan="6"> <label class="px-0 ">Hip</label></td>
                              <td>Flexion</td>
                              <td>0-120</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_flexion_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_flexion_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_flexion_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_flexion_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.hip_flexion_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_flexion_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_flexion_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!-- Extension -->
                            <tr>
                              <td>Extension</td>
                              <td>0-20</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_extension_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_extension_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_extension_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_extension_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.hip_extension_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_extension_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_extension_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!-- Abduction -->
                            <tr>
                              <td>Abduction</td>
                              <td>0-45</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_abduction_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_abduction_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_abduction_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_abduction_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.hip_abduction_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_abduction_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_abduction_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!--Adduction-->
                            <tr>
                              <td>Adduction</td>
                              <td>0-20</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_adduction_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_adduction_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_adduction_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_adduction_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.hip_adduction_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_adduction_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_adduction_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!--Internal Rotation-->
                            <tr>
                              <td>Internal Rotation</td>
                              <td>0-45</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_internal_rotation_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_internal_rotation_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_internal_rotation_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_internal_rotation_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.hip_internal_rotation_comment ?? '-' }}" />
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_internal_rotation_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data.hip_internal_rotation_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!--External Rotation-->
                            <tr>
                              <td>External Rotation</td>
                              <td>0-45</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_external_rotation_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_external_rotation_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_external_rotation_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_external_rotation_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.hip_external_rotation_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_external_rotation_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.hip_external_rotation_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!-- KNEE ROW -->
                            <!-- Flexion -->
                            <tr>
                              <td scope="row" rowspan="2"> <label class=" px-0">Knee</label></td>
                              <td>Flexion</td>
                              <td>0-135</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.knee_flexion_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.knee_flexion_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.knee_flexion_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.knee_flexion_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.knee_flexion_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.knee_flexion_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.knee_flexion_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!-- Extension -->
                            <tr>
                              <td>Extension</td>
                              <td>135-0</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.knee_extension_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.knee_extension_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.knee_flexion_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.knee_flexion_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.digits_dip_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.knee_flexion_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.knee_flexion_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                            </tr>

                            <!-- ANKLE ROW -->
                            <!-- PF -->
                            <tr>
                              <td scope="row" rowspan="6"> <label class=" px-0">Ankle</label></td>
                              <td>PF</td>
                              <td>0-45</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.ankle_pf_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.ankle_pf_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.ankle_pf_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.ankle_pf_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.ankle_pf_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.ankle_pf_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.ankle_pf_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!-- DF -->
                            <tr>
                              <td>DF</td>
                              <td>0-20</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.ankle_df_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.ankle_df_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.ankle_df_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.ankle_df_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.ankle_df_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.ankle_df_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.ankle_df_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                            </tr>

                            <!-- Inversion -->
                            <tr>
                              <td>Inversion</td>
                              <td>0-35</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.knee_inversion_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.knee_inversion_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.knee_inversion_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.knee_inversion_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.knee_inversion_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.knee_inversion_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.knee_inversion_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>

                            </tr>

                            <!-- Eversion -->
                            <tr>
                              <td>Eversion</td>
                              <td>0-15</td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.knee_eversion_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.knee_eversion_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.knee_eversion_active_r== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.knee_eversion_active_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{ data?.knee_eversion_comment ?? '-' }}" />
                              </td>
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.knee_eversion_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                              <td>
                                <input type="text" readonly class="form-control"
                                  value="{{data?.knee_eversion_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- <div class="row">
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Head Control:
                            </label>
                            <input type="text" readonly class="form-control"
                              value="{{data?.head_control== 1? 'Yes': '-' ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Trunk Control:</label>
                            <input type="text" readonly class="form-control"
                              value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                          </div>
                        </div>
                      </div> -->
                    </div>
                    <label class="subHeading mb-3">Deformities:</label>
                    <div class="form-group">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col" class="text-center" rowspan="2">
                                            <label class="subHeading p-0">Extermeties</label>
                                        </th>
                                        <th scope="col" class="text-center" colspan="2">
                                            <label class="subHeading p-0">Muscle Tone</label>
                                        </th>
                                        <th scope="col" class="text-center" rowspan="2" colspan="2">
                                            <label class="subHeading p-0">Remarks</label>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th class="text-center half-width">L</th>
                                        <th class="text-center half-width">R</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Shoulder</td>

                                        <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{ data?.extermeties_shoulder_muscle_tone_l ?? '-' }}" />
                                        </td>
                                        <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{ data?.extermeties_shoulder_muscle_tone_r
                                            ?? '-' }}" />
                                        </td>
                                        <td colspan="2">
                                          <input type="text" readonly class="form-control"
                                          value="{{ data?.extermeties_shoulder_muscle_tone_remark ?? '-' }}" />
                                    </tr>
                                    <tr>
                                        <td>Elbow</td>

                                        <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{ data?.extermeties_elbow_muscle_tone_l ?? '-' }}" />
                                        </td>
                                        <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{ data?.extermeties_elbow_muscle_tone_r ?? '-' }}" />
                                        </td>
                                        <td colspan="2">
                                          <input type="text" readonly class="form-control"
                                          value="{{ data?.extermeties_elbow_muscle_tone_remark ?? '-' }}" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Wrist</td>
                                        <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{ data?.extermeties_wrist_muscle_tone_l ?? '-' }}" />
                                        </td>
                                        <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{ data?.extermeties_wrist_muscle_tone_r ?? '-' }}" />
                                        </td>
                                        <td colspan="2">
                                          <input type="text" readonly class="form-control"
                                          value="{{ data?.extermeties_wrist_muscle_tone_remark ?? '-' }}" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Hip</td>
                                        <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{ data?.extermeties_hip_muscle_tone_l ?? '-' }}" />
                                        </td>
                                        <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{ data?.extermeties_hip_muscle_tone_r ?? '-' }}" />
                                        </td>
                                        <td colspan="2">
                                          <input type="text" readonly class="form-control"
                                          value="{{ data?.extermeties_hip_muscle_tone_remark ?? '-' }}" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Knee</td>
                                        <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{ data?.extermeties_knee_muscle_tone_l ?? '-' }}" />
                                        </td>
                                        <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{ data?.extermeties_knee_muscle_tone_r ?? '-' }}" />
                                        </td>
                                        <td colspan="2">
                                          <input type="text" readonly class="form-control"
                                          value="{{ data?.extermeties_knee_muscle_tone_remark ?? '-' }}" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Ankle</td>
                                        <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{ data?.extermeties_ankle_muscle_tone_l ?? '-' }}" />
                                        </td>
                                        <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{ data?.extermeties_ankle_muscle_tone_r ?? '-' }}" />
                                        </td>
                                        <td colspan="2">
                                          <input type="text" readonly class="form-control"
                                          value="{{ data?.extermeties_ankle_muscle_tone_remark ?? '-' }}" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Trunk</td>
                                        <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{ data?.extermeties_trunk_muscle_tone_l ?? '-' }}" />
                                        </td>
                                        <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{ data?.extermeties_trunk_muscle_tone_r ?? '-' }}" />
                                        </td>
                                        <td colspan="2">
                                          <input type="text" readonly class="form-control"
                                          value="{{ data?.extermeties_trunk_muscle_tone_remark ?? '-' }}" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <label class="subHeading mb-3">Gross Motor Skills:</label>
                    <div class="form-group">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col" class="text-center">
                                            <label class="subHeading p-0">Age</label>
                                        </th>
                                        <th scope="col" class="text-center">
                                            <label class="subHeading p-0">Activity</label>
                                        </th>
                                        <th scope="col" class="text-center">
                                            <label class="subHeading p-0">Yes</label>
                                        </th>
                                        <!-- <th scope="col" class="text-center">
                                            <label class="subHeading p-0">No</label>
                                        </th> -->
                            <th scope="col" class="text-center">
                              <label class="subHeading p-0">Remarks</label>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>3 Months</td>
                            <td>Neck Holding</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.three_months_neck ?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_cont== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.three_months_neck_remark?? '-' }}" />
                            </td>
                          </tr>
                          <tr>
                            <td>5 Months</td>
                            <td>Roll Over</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.five_months_roll_over?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.five_months_roll_over_remark ?? '-' }}" />
                            </td>
                          </tr>
                          <tr>
                            <td>6 Months</td>
                            <td>Sits in tripod fashion</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.six_months_tripod_fashion?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.six_months_tripod_fashion_remark ?? '-' }}" />
                            </td>
                          </tr>
                          <tr>
                            <td>8 Months</td>
                            <td>Sitting without support</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.eight_months_sitting?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.eight_months_sitting_remark ?? '-' }}" />
                            </td>
                          </tr>
                          <tr>
                            <td>9 Months</td>
                            <td>Stands with support</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.nine_months_stand ?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.nine_months_stand_remark ??'-' }}" />
                            </td>
                          </tr>
                          <tr>
                            <td>12 Months</td>
                            <td>Stands without support</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.twelve_months_stand?? '-' }}" />
                            </td>                           <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.twelve_months_stand_remark?? '-' }}" />
                            </td>
                          </tr>
                          <tr>
                            <td>15 Months</td>
                            <td>Walk Alone</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.fifteen_months_walk ?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.fifteen_months_walk_remark?? '-' }}" />
                            </td>
                          </tr>
                          <tr>
                            <td>18 Months</td>
                            <td>Run</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.eighteen_months_run ?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.eighteen_months_run_remark ?? '-' }}" />
                            </td>
                          </tr>
                          <tr >
                            <td>2 Years</td>
                            <td>Walk up and downstairs</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.two_years_walk_up ?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.two_years_walk_up_remark ??'-' }}" />
                            </td>
                          </tr>
                          <tr>
                            <td>3 Years</td>
                            <td>Rides Tricycle</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.three_years_rides ?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.three_years_rides_remark ?? '-' }}" />
                            </td>
                          </tr>
                          <tr>
                            <td>4 Years</td>
                            <td>Alternate feet going upstairs/downstairs</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.four_years_upstairs ?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.four_years_upstairs_remark ?? '-' }}" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <label class="subHeading mb-3">Fine Motor Skills:</label>
                  <div class="form-group">
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col" class="text-center">
                              <label class="subHeading p-0">Age</label>
                            </th>
                            <th scope="col" class="text-center">
                              <label class="subHeading p-0">Activity</label>
                            </th>
                            <th scope="col" class="text-center">
                              <label class="subHeading p-0">Yes</label>
                            </th>
                            <!-- <th scope="col" class="text-center">
                                            <label class="subHeading p-0">No</label>
                                        </th> -->
                            <th scope="col" class="text-center">
                              <label class="subHeading p-0">Remarks</label>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>4 Months</td>
                            <td>Bidextrous reach</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.four_months_bidextrous ?? '-' }}" />
                            </td>                           <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.four_months_bidextrous_remark?? '-' }}" />
                            </td>
                          </tr>
                          <tr>
                            <td>6 Months</td>
                            <td>Transfer Objects</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.six_months_transfer?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.six_months_transfer_remark ?? '-' }}" />
                            </td>
                          </tr>
                          <tr>
                            <td>9 Months</td>
                            <td> Immature pincer grasp</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.nine_months_immature ?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.nine_months_immature_remark ?? '-' }}" />
                            </td>
                          </tr>
                          <tr>
                            <td>12 Months</td>
                            <td>Mature pincer grasp</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.twelve_months_mature ?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.twelve_months_mature_remark ?? '-' }}" />
                            </td>
                          </tr>
                            <tr>
                            <td>15 Months</td>
                            <td> Tower of 2 blocks</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.fifteen_months_tower?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.fifteen_months_tower_remark ??'-' }}" />
                            </td>
                          </tr>
                          <tr>
                            <td>18 Months</td>
                            <td> Tower of 3 blocks</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.eighteen_months_tower ?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.eighteen_months_tower_remark?? '-' }}" />
                            </td>
                          </tr>
                          <tr>
                            <td>2 Years</td>
                            <td> Tower of 6 blocks</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.two_years_tower ?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.two_years_tower_remark?? '-' }}" />
                            </td>
                          </tr>
                          <tr>
                            <td>3 Years</td>
                            <td>Tower of 9 blocks</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.three_years_tower?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.three_years_tower_remark ?? '-' }}" />
                            </td>
                          </tr>
                            <tr>
                            <td>4 Years</td>
                            <td>Copies cross; bridge with block</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.four_years_cross ?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.four_years_cross_remark ??'-' }}" />
                            </td>
                          </tr>
                          <tr>
                            <td>5 Years</td>
                            <td>Copies with triangle</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.five_years_triangle ?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.five_years_triangle_remark ?? '-' }}" />
                            </td>
                          </tr>
                          <tr>
                            <td>4 Years</td>
                            <td>Alternate feet going upstairs/downstairs</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.four_years_upstairs ?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.four_years_upstairs_remark ?? '-' }}" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <label class="subHeading mb-3">Cognition and Adaptive Skills:</label>
                  <div class="form-group">
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col" class="text-center">
                              <label class="subHeading p-0">Age</label>
                            </th>
                            <th scope="col" class="text-center">
                              <label class="subHeading p-0">Activity</label>
                            </th>
                            <th scope="col" class="text-center">
                              <label class="subHeading p-0">Yes</label>
                            </th>
                            <!-- <th scope="col" class="text-center">
                                            <label class="subHeading p-0">No</label>
                                        </th> -->
                            <th scope="col" class="text-center">
                              <label class="subHeading p-0">Remarks</label>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>2 Months</td>
                            <td> Social smile</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.two_months_smile ?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.two_months_smile_remark?? '-' }}" />
                            </td>
                          </tr>
                          <tr>
                            <td>3 Months</td>
                            <td>Recognize mother</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.three_months_reco_mother?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.three_months_reco_mother_remark ?? '-' }}" />
                            </td>
                          </tr>
                          <tr>
                            <td>4 Months</td>
                            <td> Stranger anxiety</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.four_months_anxiety?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.four_months_anxiety_remark ?? '-' }}" />
                            </td>
                          </tr>
                          <tr>
                            <td>9 Months</td>
                            <td> Waves 'bye-bye'</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.nine_months_waves?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.nine_months_waves_remark ?? '-' }}" />
                            </td>
                          </tr>
                          <tr>
                            <td>12 Months</td>
                            <td>Comes when called</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.twelve_months_comes?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-conl"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.twelve_months_comes_remark ??'-' }}" />
                            </td>
                          </tr>
                          <tr>
                            <td>18 Months</td>
                            <td>Copies of Task</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.eighteen_months_task?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.eighteen_months_task_remark?? '-' }}" />
                            </td>
                          </tr>
                          <tr>
                            <td>2 Years</td>
                            <td> Ask for food, drink, toilet</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.two_years_askfor?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.two_years_askfor_remark?? '-' }}" />
                            </td>
                          </tr>
                          <tr>
                            <td>3 Years</td>
                            <td>Knows full name</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.three_years_name ?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.three_years_name_remark ?? '-' }}" />
                            </td>
                          </tr>
                          <tr>
                            <td>4 Years</td>
                            <td> Goes to toilet alone</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.four_years_toilet ?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.four_years_toilet_remark ??'-' }}" />
                            </td>
                          </tr>
                          <tr>
                            <td>5 Years</td>
                            <td>Can dress and undress</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.five_years_dress?? '-' }}" />
                            </td>
                            <!-- <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.trunk_control== 1? 'Yes': '-' ?? '-' }}" />
                                        </td> -->
                                        <td>
                                          <input type="text" readonly class="form-control"
                                          value="{{data?.five_years_dress_remark ?? '-' }}" />
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label>Language Skills:</label>
                          <!-- <input type="text" readonly class="form-control" value="{{ data?.language_normal ?? '-' }}" /> -->
                          <input type="text" readonly class="form-control" *ngIf="
                          data?.language_normal !== null &&
                            data?.language_normal !== '' &&
                            data?.language_normal !==
                              undefined;
                          else elseBlock
                        " value="{{
                          data?.language_normal
                        }}" />
                        <ng-template #elseBlock>
                          <input type="text" readonly class="form-control" value="-" />
                        </ng-template>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Remark</label>
                        <input type="text" readonly class="form-control" value="{{ data?.language_remark
                              ?? '-' }}" />
                      </div>
                    </div>
                  </div>
                  <!-- <div class="add-listings-box"> -->
                  <label class="subHeading mb-3">Transfer and Assisting Devices:</label>
                  <div class="form-group">
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>Transfer and Assisting Devices</th>
                            <th>Yes</th>
                            <!-- <th>No</th> -->
                            <th>Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Orthosis</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.orthosis ?? '-' }}" />
                            </td>
                            <!-- <td>
                                            <input type="text" readonly class="form-control"
                                            value="{{data?.rolling_I== 1? 'Yes': '-' ?? '-' }}" />
                                          </td> -->
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.orthosis_remark ?? '-' }}" />
                            </td>
                          </tr>
                          <tr>
                            <td>Splint</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.splint ?? '-' }}" />
                            </td>
                            <!-- <td>
                                            <input type="text" readonly class="form-control"
                                            value="{{data?.rolling_I== 1? 'Yes': '-' ?? '-' }}" />
                                          </td> -->
                                          <td>
                                            <input type="text" readonly class="form-control"
                                            value="{{data?.splint_remark ?? '-' }}" />
                                          </td>
                                      </tr>
                                      <tr>
                                          <td>Wheelchair</td>
                                          <td>
                                            <input type="text" readonly class="form-control"
                                  value="{{data?.wheelchair ?? '-' }}" />
                                          </td>
                                          <!-- <td>
                                            <input type="text" readonly class="form-control"
                                            value="{{data?.rolling_I== 1? 'Yes': '-' ?? '-' }}" />
                                          </td> -->
                                          <td>
                                            <input type="text" readonly class="form-control"
                                            value="{{data?.wheelchair_remark ?? '-' }}" />
                                          </td>
                                      </tr>
                                      <tr>
                                          <td>Cruthces</td>
                                          <td>
                                            <input type="text" readonly class="form-control"
                                            value="{{data?.cruthces ?? '-' }}" />
                                          </td>
                                          <!-- <td>
                                            <input type="text" readonly class="form-control"
                                  value="{{data?.rolling_I== 1? 'Yes': '-' ?? '-' }}" />
                                          </td> -->
                                          <td>
                                            <input type="text" readonly class="form-control"
                                            value="{{data?.cruthces_remark ?? '-' }}" />
                                          </td>
                                      </tr>
                                      <tr>
                                          <td>Others</td>
                                          <td>
                                            <input type="text" readonly class="form-control"
                                            value="{{data?.other  ?? '-' }}" />
                                          </td>
                                          <!-- <td>
                                            <input type="text" readonly class="form-control"
                                            value="{{data?.rolling_I== 1? 'Yes': '-' ?? '-' }}" />
                                          </td> -->
                                          <td>
                                            <input type="text" readonly class="form-control"
                                            value="{{data?.other_remark ?? '-' }}" />
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                      </div>
                    <!-- </div> -->
                    <!-- <div class="add-listings-box pb-2">
                      <h3>Mobility And Balance</h3>
                      <div class="form-group table-responsive mb-0">
                        <table class="table">
                          <thead>
                            <tr class="border-light">
                              <th scope="col"><label class="subHeading px-0">Activity</label></th>
                              <th scope="col"><label class="subHeading px-0">I</label></th>
                              <th scope="col"><label class="subHeading px-0">A</label></th>
                              <th scope="col"><label class="subHeading px-0">D</label></th>
                              <th scope="col"><label class="subHeading px-0">N</label></th>
                              <th scope="col"><label class="subHeading px-0">Remark</label></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="border-light">
                              <td scope="row"> <label class=" px-0">Rolling</label></td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.rolling_I== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.rolling_A== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.rolling_D== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.rolling_N== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td><input type="text" readonly class="form-control"
                                  value="{{data?.rolling_remark?? '-' }}" /></td>
                            </tr>
                            <tr class="border-light">
                              <td scope="row"> <label class=" px-0">Sitting</label></td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.sitting_I== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.sitting_A== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td><input type="text" readonly class="form-control"
                                  value="{{data?.sitting_D== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td><input type="text" readonly class="form-control"
                                  value="{{data?.sitting_N== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.sitting_remark ?? '-' }}" /></td>
                            </tr>
                            <tr class="border-light">
                              <td scope="row"> <label class=" px-0">Standing</label></td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.standing_I== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.standing_A== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.standing_D== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.standing_N== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.standing_remark?? '-' }}" /></td>
                            </tr>
                            <tr class="border-light">
                              <td scope="row"> <label class=" px-0">Gait</label></td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.gait_I== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.gait_A== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.gait_D== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td><input type="text" readonly class="form-control"
                                  value="{{data?.gait_N== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td><input type="text" readonly class="form-control" value="{{data?.gait_remark??  '-'}}" />
                              </td>
                            </tr>
                            <tr class="border-light">
                              <td scope="row"> <label class=" px-0">Balance</label></td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.balance_I== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td><input type="text" readonly class="form-control"
                                  value="{{data?.balance_A== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.balance_D== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.balance_N== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td><input type="text" readonly class="form-control" value="{{ data?.balance_remark
                                        ?? '-' }}" /></td>
                            </tr>
                            <tr class="border-light">
                              <td scope="row"> <label class=" px-0">Static Sitting</label></td>
                              <td><input type="text" readonly class="form-control"
                                  value="{{data?.static_sitting_I== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.static_sitting_A== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.static_sitting_D== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td><input type="text" readonly class="form-control"
                                  value="{{data?.static_sitting_N== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td> <input type="text" readonly class="form-control" value="{{ data?.static_sitting_reamrk
                                        ?? '-' }}" /></td>
                            </tr>
                            <tr class="border-light">
                              <td scope="row"> <label class="px-0 ">Static Standing</label></td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.static_standing_I== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.static_standing_A== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.static_standing_D== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.static_standing_N== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td> <input type="text" readonly class="form-control" value="{{ data?.static_standing_reamrk
                                        ?? '-' }}" /></td>
                            </tr>
                            <tr class="border-light">
                              <td scope="row"> <label class=" px-0">Dynamic Sitting</label></td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.dynamic_sitting_I== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td><input type="text" readonly class="form-control"
                                  value="{{data?.dynamic_sitting_A== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td><input type="text" readonly class="form-control"
                                  value="{{data?.dynamic_sitting_D== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.dynamic_sitting_N== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td> <input type="text" readonly class="form-control" value="{{ data?.dynamic_sitting_remark
                                        ?? '-' }}" /> </td>
                            </tr>
                            <tr class="border-light">
                              <td scope="row"> <label class="px-0 ">Dynamic Standing</label></td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.dynamic_standing_I== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.dynamic_standing_A== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td><input type="text" readonly class="form-control"
                                  value="{{data?.dynamic_standing_D== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td> <input type="text" readonly class="form-control"
                                  value="{{data?.dynamic_standing_N== 1? 'Yes': '-' ?? '-' }}" />
                              </td>
                              <td> <input type="text" readonly class="form-control" value="{{ data?.dynamic_standing_remark
                                        ?? '-' }}" /></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="row subHeading">
                        <div class="col-sm-3 col-6">I - Independent</div>
                        <div class="col-sm-3 col-6">A - Assistace</div>
                        <div class="col-sm-3 col-6">D - Dependent</div>
                        <div class="col-sm-3 col-6">N - Not Tested</div>
                      </div>
                    </div> -->
                    <!-- <div class="add-listings-box">
                      <h3>Transfer And Assisting Devices:</h3>
                      <div class="form-group">
                        <div class="row mt-3">
                          Head Control
                          <label class="checkbox checkbox-outline col-12">
                            <input type="text" readonly class="form-control"
                              value="{{data?.transfer_assisting_none == 1? 'Yes': '-' ?? '-' }}" />
                            None
                          </label>
                          <label class="checkbox checkbox-outline col-lg-2 col-sm-3 col-5">
                            <input type="text" readonly class="form-control"
                              value="{{data?.transfer_assisting_crutches== 1? 'Yes': '-' ?? '-' }}" />
                            Crutches
                          </label>
                          <label class="checkbox checkbox-outline col-lg-2 col-sm-3 col">
                            <input type="text" readonly class="form-control"
                              value="{{data?.transfer_assisting_one== 1? 'Yes': '-' ?? '-' }}" />
                            One
                          </label>
                          <label class="checkbox checkbox-outline col-lg-2 col-sm-3 col">
                            <input type="text" readonly class="form-control"
                              value="{{data?.transfer_assisting_pair== 1? 'Yes': '-' ?? '-' }}" />
                            Pair
                          </label>

                          <label class="checkbox checkbox-outline col-12">
                            <input type="text" readonly class="form-control"
                              value="{{data?.transfer_assisting_walker== 1? 'Yes': '-' ?? '-' }}" />
                            Walker
                          </label>
                          <label class="checkbox checkbox-outline col-12">
                            <input type="text" readonly class="form-control"
                              value="{{data?.transfer_assisting_wheel_chair== 1? 'Yes': '-' ?? '-' }}" />
                            Wheel Chair
                          </label>

                          <label class="checkbox checkbox-outline col-lg-2 col-sm-3 col-4">
                            <input type="text" readonly class="form-control"
                              value="{{data?.transfer_assisting_others== 1? 'Yes': '-' ?? '-' }}" />
                            Others
                          </label>
                          <div class="col-lg-10 col-sm-9 col-8">
                            <input type="text" readonly class="form-control"
                              value="{{data?.transfer_assisting_others_description ?? '-' }}" />
                          </div>
                        </div>
                      </div> -->
                      <div class="row">
                        <!-- <h3>Transfer And Assisting Devices:</h3>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>None :</label>
                            <input type="text" readonly class="form-control"
                              value="{{data?.transfer_assisting_none == 1? 'Yes': '-' ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Crutches </label>
                            <input type="text" readonly class="form-control"
                              value="{{data?.transfer_assisting_crutches== 1? 'Yes': '-' ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label> One:</label>
                            <input type="text" readonly class="form-control"
                            value="{{data?.transfer_assisting_one== 1? 'Yes': '-' ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label> Pair:</label>
                            <input type="text" readonly class="form-control"
                            value="{{data?.transfer_assisting_pair== 1? 'Yes': '-' ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Walker:</label>
                            <input type="text" readonly class="form-control"
                              value="{{data?.transfer_assisting_walker== 1? 'Yes': '-' ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Wheel Chair</label>
                            <input type="text" readonly class="form-control"
                              value="{{data?.transfer_assisting_wheel_chair== 1? 'Yes': '-' ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Other:</label>
                            <input type="text" readonly class="form-control"
                            value="{{data?.transfer_assisting_others== 1? 'Yes': '-' ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Other remark :</label>
                            <input type="text" readonly class="form-control"
                            value="{{data?.transfer_assisting_others_description ?? '-' }}" />
                          </div>
                        </div> -->
                        <div class="col-xl-6 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Problem Noted :</label>
                            <input type="text" readonly class="form-control" value="{{data?.problems_noted ?? '-' }}" />
                          </div>
                        </div>
                      <div class="row">
                        <div class="col-xl-3 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label> Short Term(1 Month) :</label>
                            <input type="text" readonly class="form-control"
                                      value="{{data?.short_term== 1? 'Yes': '-' ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label> Short Term Session  :</label>
                            <input type="text" readonly class="form-control" value="{{data?.short_term_session ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label> Long Term(3-6 Month)  :</label>
                            <input type="text" readonly class="form-control"
                            value="{{data?.long_term== 1? 'Yes': '-' ?? '-' }}" />

                          </div>
                        </div>
                        <div class="col-xl-3 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Long Term Session  :</label>
                            <input type="text" readonly class="form-control" value="{{data?.long_term_session ?? '-' }}" />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-12">
                        <div class="form-group">
                          <label class="subHeading p-0">Scope of Session:</label>
                          <input type="text" readonly class="form-control" value="{{data?.scope_session ?? '-' }}" />
                        </div>
                      </div>
                      <div class="col-sm-6 col-12">
                        <div class="form-group">
                          <label class="subHeading p-0">Recommended Duration:</label>
                          <input type="text" readonly class="form-control"
                            value="{{data?.recommended_duration?? '-' }}" />
                        </div>
                      </div>

                      <!-- No. of PT Sessions per Month -->
                      <div class="col-sm-6 col-12">
                        <div class="form-group">
                          <label class="subHeading p-0">No. of PT Sessions per Month:</label>
                          <input type="text" readonly class="form-control"
                            value="{{data.no_pt_sesssion_per_month ?? '-' }}" />
                        </div>
                      </div>

                      <!-- Goals to be acheived -->
                      <div class="col-sm-6 col-12">
                        <div class="form-group">
                          <label class="subHeading p-0">Goals to be Acheived: </label>
                          <input type="text" readonly class="form-control" value="{{data?.goals_acheived?? '-' }}" />
                        </div>
                      </div>
                    </div>

  <!--
                      <div class="form-group">
                        <div class="row">
                          <div class="col-sm-6 col-12">
                            <label class="subHeading p-0 my-3">Problems Noted: </label>
                            <input type="text" readonly class="form-control" value="{{data?.problems_noted ?? '-' }}" />
                          </div>

                          <div class="col-sm-6 col-12">
                            <label class="subHeading p-0 my-3">Session Goals: </label>
                            <div class="row">
                              <div class="col-sm-5 col-12">
                                <div class="form-group mb-0">
                                  <label class="checkbox checkbox-outline px-0">
                                    <input type="text" readonly class="form-control"
                                      value="{{data?.short_term== 1? 'Yes': '-' ?? '-' }}" />
                                    Short Term(1 Month)
                                  </label>
                                </div>
                              </div>
                              <div class="col-sm-7 col-12">
                                <div class="form-group">
                                  <input type="text" readonly class="form-control"
                                    value="{{data?.short_term_session ?? '-' }}" />
                                </div>
                              </div>

                              <div class="col-sm-5 col-12">
                                <div class="form-group mb-0">
                                  <label class="checkbox checkbox-outline px-0 ">
                                    Long Term(3-6 Month)
                                    <input type="text" readonly class="form-control"
                                      value="{{data?.long_term== 1? 'Yes': '-' ?? '-' }}" />

                                  </label>
                                </div>
                              </div> -->
  <!--
                              <div class="col-sm-7 col-12">
                                <div class="form-group">
                                  <input type="text" readonly class="form-control"
                                    value="{{data?.long_term_session?? '-' }}" />
                                </div>
                              </div>
                              <div class="col-sm-6 col-12">
                                <div class="form-group">
                                  <label class="subHeading p-0">Recommended Duration:</label>
                                  <input type="text" readonly class="form-control"
                                    value="{{data?.recommended_duration?? '-' }}" />
                                </div>
                              </div> -->

                              <!-- No. of PT Sessions per Month -->
                              <!-- <div class="col-sm-6 col-12">
                                <div class="form-group">
                                  <label class="subHeading p-0">No. of PT Sessions per Month:</label>
                                  <input type="text" readonly class="form-control"
                                    value="{{data?.no_pt_sesssion_per_month ?? '-' }}" />
                                </div>
                              </div> -->

                              <!-- Goals to be acheived -->
                              <!-- <div class="col-sm-6 col-12">
                                <div class="form-group">
                                  <label class="subHeading p-0">Goals to be Acheived: </label>
                                  <input type="text" readonly class="form-control" value="{{data?.goals_acheived?? '-' }}" />
                                </div>
                              </div>
                            </div>
                          </div> -->

                          <!-- scope of session -->
                          <!-- <div class="col-sm-6 col-12">
                            <div class="form-group">
                              <label class="subHeading p-0">Scope of Session:</label>
                              <input type="text" readonly class="form-control" value="{{data?.scope_session ?? '-' }}" />
                            </div>
                          </div> -->

                          <!-- Recommended duration -->
                          <!-- <div class="col-sm-6 col-12">
                            <div class="form-group">
                              <label class="subHeading p-0">Recommended Duration:</label>
                              <input type="text" readonly class="form-control"
                                value="{{data?.recommended_duration?? '-' }}" />
                            </div>
                          </div> -->

                          <!-- No. of PT Sessions per Month -->
                          <!-- <div class="col-sm-6 col-12">
                            <div class="form-group">
                              <label class="subHeading p-0">No. of PT Sessions per Month:</label>
                              <input type="text" readonly class="form-control"
                                value="{{data?.no_pt_sesssion_per_month ?? '-' }}" />
                            </div>
                          </div> -->

                          <!-- Goals to be acheived -->
                          <!-- <div class="col-sm-6 col-12">
                            <div class="form-group">
                              <label class="subHeading p-0">Goals to be Acheived: </label>
                              <input type="text" readonly class="form-control" value="{{data?.goals_acheived?? '-' }}" />
                            </div>
                          </div>
                        </div>
                      </div> -->
                    <!-- </div> -->
                    <h3 class="fw-bold">Sessions Technique:</h3>
                    <div class="row">
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label> Exercise(ROM) :</label>
                          <input type="text" readonly class="form-control"
                            value="{{data?.exercise_checkbox== 1? 'Yes': '-' ?? '-' }}" />
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label>Duration:</label>
                          <input type="text" readonly class="form-control"
                            value="{{ data?.exercise_duration  ?? '-' }}" />
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label> Details :</label>
                          <!-- <input type="text" readonly class="form-control" value="{{
                                data?.mother_para == 'null' ? '-' : data?.mother_para
                              }}" /> -->
                        <input type="text" readonly class="form-control" value="{{ data?.exercise_details
                                ?? '-' }}" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> TENS :</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.tens_checkbox == 1? 'Yes': '-'?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Duration:</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.tens_duration ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Details :</label>
                        <!-- <input type="text" readonly class="form-control" value="{{
                                data?.mother_para == 'null' ? '-' : data?.mother_para
                              }}" /> -->
                        <input type="text" readonly class="form-control"
                          value="{{ data?.tens_details ?? '-' }}" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Ultrasound:</label>
                        <input type="text" readonly class="form-control" value="{{ data?.ultrasound_checkbox == 1? 'Yes': '-'
       ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Duration:</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.ultrasound_duration ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Details :</label>
                        <!-- <input type="text" readonly class="form-control" value="{{
                                  data?.mother_para == 'null' ? '-' : data?.mother_para
                                }}" /> -->
                        <input type="text" readonly class="form-control"
                          value="{{ data?.ultrasound_details ?? '-' }}" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Hot Packs :</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.hot_packs_checkbox== 1? 'Yes': '-'?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Duration:</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.hot_packs_duration ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Details :</label>
                        <!-- <input type="text" readonly class="form-control" value="{{
                                  data?.mother_para == 'null' ? '-' : data?.mother_para
                                }}" /> -->
                        <input type="text" readonly class="form-control"
                          value="{{ data?.hot_packs_details ?? '-' }}" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Stimulation :</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.stimulation_checkbox == 1? 'Yes': '-'?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Duration:</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.stimulation_duration ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Details :</label>
                        <!-- <input type="text" readonly class="form-control" value="{{
                                  data?.mother_para == 'null' ? '-' : data?.mother_para
                                }}" /> -->
                        <input type="text" readonly class="form-control"
                          value="{{ data?.stimulation_details ?? '-' }}" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Vibrator :</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.vibrator_checkbox == 1? 'Yes': '-'?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Duration:</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.vibrator_duration ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Details :</label>
                        <!-- <input type="text" readonly class="form-control" value="{{
                                  data?.mother_para == 'null' ? '-' : data?.mother_para
                                }}" /> -->
                        <input type="text" readonly class="form-control"
                          value="{{ data?.vibrator_details ?? '-' }}" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Tapping :</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.tapping_checkbox == 1? 'Yes': '-'?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Duration:</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.tapping_duration ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Details :</label>
                        <!-- <input type="text" readonly class="form-control" value="{{
                                  data?.mother_para == 'null' ? '-' : data?.mother_para
                                }}" /> -->
                        <input type="text" readonly class="form-control"
                          value="{{ data?.tapping_details ?? '-' }}" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Chest Therapy :</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.chest_therapy_checkbox == 1? 'Yes': '-'?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Duration:</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.chest_therapy_duration ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Details :</label>
                        <!-- <input type="text" readonly class="form-control" value="{{
                                  data?.mother_para == 'null' ? '-' : data?.mother_para
                                }}" /> -->
                          <input type="text" readonly class="form-control"
                            value="{{ data?.chest_therapy_details ?? '-' }}" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Others:</label>
                            <input type="text" readonly class="form-control"
                              value="{{ data?.session_technique_others_checkbox == 1? 'Yes': '-'?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Others details:</label>
                            <input type="text" readonly class="form-control"
                              value="{{ data?.session_technique_others_details ?? '-' }}" />
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label>Re-assessment Date::</label>
                          <input type="text" readonly class="form-control"
         *ngIf="data?.re_date; else dash"
         value="{{ data?.re_date | date:'dd-MM-yyyy' }}" />

  <ng-template #dash>
    <input type="text" readonly class="form-control" value="-" />
  </ng-template>
                          <!-- <input type="text" readonly class="form-control"
                            value="{{ data?.re_date | date:'dd-MM-yyyy'  ?? '-' }}" /> -->
                        </div>
                      </div>
                    </div>



                    <!-- Vital Signs -->

                    <!-- <div class="row" *ngFor="let sib of sibling"> -->

                    <!-- <div class="row">
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Temp :</label>
                            <input type="text" readonly class="form-control" value="{{ sib?.name ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Pulse (bpm) :</label>
                            <input type="text" readonly class="form-control" value="{{ sib?.name ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Resp (bpm) :</label>
                            <input type="text" readonly class="form-control" value="{{ sib?.name ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>BP (mmhg) :</label>
                            <input type="text" readonly class="form-control" value="{{ sib?.name ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>SPO2 :</label>
                            <input type="text" readonly class="form-control" value="{{ sib?.name ?? '-' }}" />
                          </div>
                        </div>
                      </div>
                      <h4 class="fw-bold">Post Session:</h4>
                        <div class="row">
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Temp :</label>
                            <input type="text" readonly class="form-control" value="{{ sib?.name ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Pulse (bpm) :</label>
                            <input type="text" readonly class="form-control" value="{{ sib?.name ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Resp (bpm) :</label>
                            <input type="text" readonly class="form-control" value="{{ sib?.name ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>BP (mmhg) :</label>
                            <input type="text" readonly class="form-control" value="{{ sib?.name ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>SPO2 :</label>
                            <input type="text" readonly class="form-control" value="{{ sib?.name ?? '-' }}" />
                          </div>
                        </div>
                      </div> -->



                    <!--If in pain,pain assessment complete: -->
                    <!-- <h3 class="fw-bold">Pain Assessment</h3>
                      <div class="row" *ngFor="let relative of relatives">
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Pain Status :</label>
                            <input type="text" readonly class="form-control" value="{{ relative?.name?? '-' }}" />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xl-4 col-lg-12col-md-12">
                          <div class="form-group">
                            <label>Pain Tool :</label>
                            <input type="text" readonly class="form-control" value="{{ data?.no_of_maids ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Pain Score :</label>
                            <input type="text" readonly class="form-control" value="{{ data?.family_religion ?? '-' }}" />
                          </div>
                        </div>

                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Type of House :</label>

                            <input type="text" readonly class="form-control"
                              value="{{ data?.type_of_house == 'null' ? '-' : data?.type_of_house }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>House no :</label>
                            <input type="text" readonly class="form-control"
                              value="{{ data?.house_no == 'null' ? '-' : data?.house_no }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>House Location :</label>
                            <a style="font-weight: 600; color: blue;" href="{{ data?.house_location == 'null'? '-' : data?.house_location }}" target="_blank">{{
                              data?.house_location == 'null'? '-' : data?.house_location }}</a>
                          </div>
                        </div>

                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Report Name :</label>
                            <input type="text" readonly class="form-control" value="{{ data?.report_name ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Contact Number :</label>
                            <input type="text" readonly class="form-control" value="{{ data?.contact_no ?? '-' }}" />
                          </div>
                        </div>

                        <div class="col-xl-4 col-lg-12 col-md-12">
                                                <div class="form-group">
                                                    <label>Upload photo :</label>
                                                    <input type="text" readonly class="form-control"
                                                        value="{{data?.upload_photo ?? '-'}}" />
                                                </div>
                                            </div>

                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Uploaded photo:</label>
                            <img *ngIf="data?.upload_photo" [src]="getImageUrl(data.upload_photo)" alt="Uploaded Photo"
                              class="img-fluid" style="width: 150px" />
                            <span *ngIf="!data?.upload_photo">No photo uploaded</span>
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Need Mental Support for Mother? :</label>
                            <input type="text" readonly class="form-control"
                              value="{{ data?.mother_mental_supp ?? '-' }}" />
                          </div>
                        </div>

                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Specify :</label>
                            <input type="text" readonly class="form-control"
                              value="{{ data?.mental_supp_specify ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12"> -->
                  <!-- <div class="form-group">
                                                    <label>Appoinment and Vaccines responsibility :</label>
                                                    <input type="text" readonly class="form-control"
                                                        value="{{data?.appointment_vaccine_responsibility ?? '-'}}" />
                                                </div> -->
                  <!-- <ng-container>
                            <div class="form-group">
                              <label for="">Appoinment and Vaccines responsibility :</label>
                              <input type="text" readonly class="form-control" *ngIf="
                                  data?.appointment_vaccine_responsibility !==
                                    null &&
                                    data?.appointment_vaccine_responsibility !==
                                      '' &&
                                    data?.appointment_vaccine_responsibility !==
                                      undefined;
                                  else elseBlock
                                " value="{{data?.appointment_vaccine_responsibility === 'true'
                                ? 'Yes'
                                : data?.appointment_vaccine_responsibility === 'false'
                                  ? 'No'
                                  : '-'
                                }}" />
                              <ng-template #elseBlock>
                                <input type="text" readonly class="form-control" value="-" />
                              </ng-template>
                            </div>
                          </ng-container>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12"> -->
                  <!-- <div class="form-group">
                                                    <label>Medication and Administration :</label>
                                                    <input type="text" readonly class="form-control"
                                                        value="{{data?.medication_admin_responsibility ?? '-'}}" />
                                                </div> -->
                  <!-- <ng-container>
                            <div class="form-group">
                              <label for="">Medication and Administration :</label>
                              <input type="text" readonly class="form-control" *ngIf="
                                  data?.medication_admin_responsibility !== null &&
                                    data?.medication_admin_responsibility !== '' &&
                                    data?.medication_admin_responsibility !==
                                      undefined;
                                  else elseBlock
                                " value="{{
                                  data?.medication_admin_responsibility === 'true'
                                    ? 'Yes'
                                    :data?.medication_admin_responsibility === 'false'? 'No':'-'
                                }}" />
                              <ng-template #elseBlock>
                                <input type="text" readonly class="form-control" value="-" />
                              </ng-template>
                            </div>
                          </ng-container>
                        </div> -->

                    <!-- <div class="col-xl-4 col-lg-12 col-md-12"> -->
                    <!-- <ng-container>
                            <div class="form-group">
                              <label for="">Baby's supplies responsibility :</label>
                              <input type="text" readonly class="form-control" *ngIf="
                                  data?.baby_supplies_responsibility !== null &&
                                  data?.baby_supplies_responsibility !== '' &&
                                  data?.baby_supplies_responsibility !==
                                      undefined;
                                  else elseBlock
                                " value="{{
                                    data?.baby_supplies_responsibility === 'true'
                                    ? 'Yes'
                                    :data?.baby_supplies_responsibility === 'false'? 'No':'-'
                                }}" />
                              <ng-template #elseBlock>
                                <input type="text" readonly class="form-control" value="-" />
                              </ng-template>
                            </div>
                          </ng-container> -->
                  <!-- <div class="form-group">
                                                    <label>Baby's supplies responsibility :</label>
                                                    <input type="text" readonly class="form-control"
                                                        value="{{data?.baby_supplies_responsibility ?data?.baby_supplies_responsibility: '-'}}" />
                                                </div> -->


                    <!-- </div> -->
                    <!-- <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Requires changes in the environment? :</label>
                            <input type="text" readonly class="form-control"
                              value="{{ data?.environment_changes == 'null' ? '-' : data?.environment_changes }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Identify requirements :</label>
                            <input type="text" readonly class="form-control" value="{{
                                data?.environment_identify_require ?data?.environment_identify_require : '-'
                              }}" />
                          </div>
                        </div>
                      </div> -->
                </form>
              </div>
            </div>
          </div>
        </ng-container>
        <!-- </ng-container> -->
        <ng-template pTemplate="footer">
          <p-button  (click)="displayMaximizable = false" label="Ok"
            styleClass="p-button-text"></p-button>
        </ng-template>
      </p-dialog>
    </div>
  </div>
  <app-copyrights></app-copyrights>
</div>
