import { Form } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { MaikaListService } from 'src/app/services/maika-list.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MasterService } from 'src/app/services/master.service';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { CanvasJSAngularChartsModule } from '@canvasjs/angular-charts';
import { ChartModule } from 'primeng/chart';

@Component({
  selector: 'app-growth-monitoring-list',
  templateUrl: './growth-monitoring-list.component.html',
  styleUrls: ['./growth-monitoring-list.component.scss'],
  
})
export class GrowthMonitoringListComponent implements OnInit {
  @ViewChild('chartContainer') chartContainer: ElementRef;
  breadcrumb = [
    {
      title: 'Growth Monitoring List',
      subTitle: 'Dashboard',
    },
  ];
  accordionOpenStates: boolean[] = [];
  allData: any = [];
  babyData: any = {};
  permissions: any = [];
  ImageUrl:string =environment.imgUrl;
  detail: any = [];
  filterVal: string;
  displayMaximizable: boolean = false;
  filteredPDFData: any[];
  pdfData: any[];
  exportColumns: any = [];
  cols: any;
  displayThree_year_above: boolean = false;
  motor_coordination: any
  sensory: any
  communication: any
  permissionData: any = [];
  createdat:any
  updatedat:any
  updatedby:any
  createdby:any
  createdtime:any
  feeding: any
  self_exp: any
  play: any
  displayfour_months_to_one_year: boolean = false;
  displayOneToThreeYear: boolean = false;
  health_json: any;
  babyImages:any
  isExpanded: boolean = false;
  // chartOptions: { animationEnabled: boolean; title: { text: string; }; data: { type: string; dataPoints: { label: string; y: number; }[]; }[]; };
  chartOptions:any
  data: { labels: string[]; datasets: { label: string; data: number[]; fill: boolean; borderColor: string; tension: number; }[]; };
  options: { maintainAspectRatio: boolean; aspectRatio: number; plugins: { legend: { labels: { color: string; }; }; }; scales: { x: { ticks: { color: string; }; grid: { color: string; drawBorder: boolean; }; }; y: { ticks: { color: string; }; grid: { color: string; drawBorder: boolean; }; }; }; };

  constructor(    private masterService: MasterService,
    private nanny_task_service: MaikaListService, private router: Router, private spinner: NgxSpinnerService,    private messageService: MessageService,
    ) { 

    


      this.getAllUserPermission();
    }

 

    
  ngOnInit(): void {

    this.getAllData();
    this.cols = [
      { field: 'sr_no', header: 'Sr No' },
      { field: 'name', header: 'Name' },
      { field: 'date_of_birth', header: 'Date of Birth' },
      { field: 'days', header: 'Age' },
      { field: 'months', header: 'Months' },
    ];
    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    
  }
  getAllUserPermission() {
    let id = localStorage.getItem('user_id');
    this.masterService.getUserPremissionById(id).then((res: any) => {

      this.permissionData = res.data;

      for (let i = 0; i < this.permissionData.length; i++) {
        
        this.permissions.push(this.permissionData[i].permission_id);
      }
    })
  }
  checkPermission(permission: number) {
   
    return this.permissions.includes(permission);
  }
  addGrowthMonitoring(){
    let dob, growthMonitoringLink;
  
    // get DOB of baby from local storage to create care plan link according to their age 
  
    dob = localStorage.getItem('dob').split('/');
    let today = new Date();
  
    
  
    let dateOfBirth = new Date(dob[2], dob[1] - 1, dob[0]);
  
  
    const timeDiffMilliseconds = today.getTime() - dateOfBirth.getTime();
  
    const daysDiff = Math.floor(timeDiffMilliseconds / (1000 * 3600 * 24));
  
  
    if (daysDiff <= 119) {
      growthMonitoringLink = '/portal/zero-to-three-month-growth-monitoring';
    }
    // up to 4 months  to 1 year
    else if (daysDiff <= 365 && daysDiff > 119) {
      growthMonitoringLink = '/portal/four-to-one-year-growth-monitoring';
    }
    // 1 year to 3 year
    else if (daysDiff <= 1095 && daysDiff > 365) {
      growthMonitoringLink = '/portal/one-year-to-three-year-growth-monitoring';
    }
  
    // up to 3 year 
    else if (daysDiff > 1095) {
      growthMonitoringLink = '/portal/three-year-and-above-year-growth-monitoring';
    }
  
  
    // route on form  
    this.router.navigateByUrl(growthMonitoringLink);  
  }
  // this function is for open graph on large screen   14-03-2024
  toggleSize() {
    this.isExpanded = !this.isExpanded;
   
  }
  getAllData() {
    // ;
     this.spinner.show();
    let id = localStorage.getItem('babyId');
    this.nanny_task_service.getMonitorList(id)
      .then((res: any) => {
                this.spinner.hide();

        this.allData = res?.data;
        
        // Initialize accordionOpenStates with false values
        this.accordionOpenStates = new Array(this.allData.length).fill(false);
      },
      (error: any) => {
        this.spinner.hide()
        // Handle any errors that occurred during the API call
      
       
      });
  }

  getData(id: any) {
    this.allData?.map((res: any) => {
      if (res.baby_id == id) {
        this.babyData = res;
        this.createdtime=this.babyData.development_milestones[0]?.created_at;
        
        setTimeout(() => {
          this.removeCSs();
        }, 1500);
        // this.babyData?.development_milestones.map((res: any) => {
        //   let data = JSON.parse(JSON.parse(res?.development_milestone))
        //   this.parsed.push(data);
        // });
      }
    });
  }

  details: any = [];
  showData(id: any) {
    this.createdtime=this.babyData.development_milestones[0]?.created_at;
    if(this.babyData?.growth_monitoring_period_title == "0 - 3 Months"){
      this.viewZero_to_three_months(id);
    }
    else if(this.babyData?.growth_monitoring_period_title == "3 Years Old and Above"){
      this.ViewThree_year_above(id);
    }
    else if(this.babyData?.growth_monitoring_period_title == "4 Months - 1 Year Old"){
      this.viewFour_months_to_one_year(id);
    }
    else if(this.babyData?.growth_monitoring_period_title == "1 - 3 Years Old"){
      this.viewOneYearToThreeYear(id);
    }
  }

  viewZero_to_three_months(id: any) {
    this.detail = [];
    this.babyData?.development_milestones?.map((res: any) => {
      if (res.id == id) {
        this.detail = (JSON.parse(res?.development_milestone))
        this.health_json=res?.physical_assessment_details_json
        this.babyImages=res?.growth_images_json
        this.createGraph();
        this.createdat=res?.created_at;
        this.updatedat=res?.update_at;
        this.createdby=res?.created_by;
        this.updatedby=res?.updated_by;
        }
    });
    this.displayMaximizable = true;
  }

  viewFour_months_to_one_year(id: any) {
    this.detail = [];
    // ;
   
    this.babyData?.development_milestones?.map((res: any) => {
      if (res.id == id) {
        this.createdat=res?.created_at;
        this.updatedat=res?.update_at;
        this.createdby=res?.created_by;
        this.updatedby=res?.updated_by;
        this.detail = (JSON.parse(res?.development_milestone));
        this.motor_coordination = this.detail?.motor_coordination;
        this.health_json=res?.physical_assessment_details_json
        this.babyImages=res?.growth_images_json
        this.sensory = this.detail?.sensory;
        this.communication = this.detail?.communication;
        this.feeding = this.detail?.feeding;
        this.self_exp = this.detail?.self_expression;
        this.play = this.detail?.play_and_social_skills;
        this.createGraph();
      }
    });

    this.displayfour_months_to_one_year = true;
  }

  viewOneYearToThreeYear(id: any) {
    this.detail = [];
    // ;
    this.babyData?.development_milestones?.map((res: any) => {
      if (res.id == id) {
        this.createdat=res?.created_at;
        this.updatedat=res?.update_at;
        this.createdby=res?.created_by;
        this.updatedby=res?.updated_by;
        this.detail = (JSON.parse(res?.development_milestone));
        this.motor_coordination = this.detail.motor_coordination;
        this.sensory = this.detail.sensory;
        this.health_json=res?.physical_assessment_details_json
        this.babyImages=res?.growth_images_json
        this.communication = this.detail.communication;
        this.createGraph();

      }
    });
    this.displayOneToThreeYear = true;
  }
  ViewThree_year_above(id: any) {
    this.detail = [];

    // ;
    this.babyData?.development_milestones?.map((res: any) => {
      if (res.id == id) {

        this.detail = (JSON.parse(res?.development_milestone));
        this.createdat=res?.created_at;
        this.updatedat=res?.update_at;
        this.createdby=res?.created_by;
        this.updatedby=res?.updated_by;
        this.health_json=res?.physical_assessment_details_json
        this.babyImages=res?.growth_images_json
        this.createGraph();

        // this.motor_coordination = this.detail.motor_coordination;
        // this.sensory = this.detail.sensory;
        // this.communication = this.detail.communication;
      }
    });
    this.displayThree_year_above = true;
  }
  editOnCLick(id: any) {
    let FormLink = "";
    if(this.babyData?.growth_monitoring_period_title == "0 - 3 Months"){
      FormLink = "/portal/zero-to-three-month-growth-monitoring"
    }
    else if(this.babyData?.growth_monitoring_period_title == "3 Years Old and Above"){
      FormLink = "/portal/three-year-and-above-year-growth-monitoring"
    }
    else if(this.babyData?.growth_monitoring_period_title == "4 Months - 1 Year Old"){
      FormLink = "/portal/four-to-one-year-growth-monitoring"
    }
    else if(this.babyData?.growth_monitoring_period_title == "1 - 3 Years Old"){
      FormLink = "/portal/one-year-to-three-year-growth-monitoring"
    }
    localStorage.setItem('editedOrderId', id);
    this.router.navigateByUrl(FormLink);
  }
  
  /* -------------------------------------------------------------------------- */
  /*                        // reset table data function                        */
  /* -------------------------------------------------------------------------- */
  reset(dt) {
    dt.reset();
    this.filterVal = '';
  }
  onPDFExport() {
    this.filteredPDFData = [];
    this.pdfData = [];
    this.filteredPDFData = this.allData;
  
    for (let i = 0; i < this.filteredPDFData.length; i++) {
      this.pdfData.push({
        sr_no: i + 1,
        name: this.filteredPDFData[i]?.name ?? '-',
        date_of_birth: this.filteredPDFData[i]?.date_of_birth ?? '-',
        days: this.filteredPDFData[i]?.days ?? '-',
        months: this.filteredPDFData[i]?.months ?? '-',
      });
    }
  
    this.nanny_task_service.exportPdf(
      this.pdfData,
      this.exportColumns,
      'zeo-to-three-month.pdf'
    );
  }
  

  createGraph() {
    const root = document.documentElement;
    const documentStyle = getComputedStyle(root);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    const heightData = new Array(12).fill(0);
    const weightData = new Array(12).fill(0);

    if (this.health_json) {
        this.health_json.forEach(dataItem => {
            if (dataItem && dataItem.time) {
                const monthIndex = new Date(dataItem.time).getMonth(); 
                const height = parseInt(dataItem.height); 
                const weight = parseInt(dataItem.weight); 

                if (!isNaN(height)) {
                    heightData[monthIndex] = height;
                }

                if (!isNaN(weight)) {
                    weightData[monthIndex] = weight;
                }
            }
        });
    }

    this.data = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        datasets: [
            {
                label: 'Height',
                data: heightData,
                fill: false,
                borderColor: documentStyle.getPropertyValue('--blue-500'),
                tension: 0.4
            },
            {
                label: 'Weight',
                data: weightData,
                fill: false,
                borderColor: documentStyle.getPropertyValue('--red-500'),
                tension: 0.4
            },
        ]
    };

    this.options = {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
            legend: {
                labels: {
                    color: textColor
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder,
                    drawBorder: false
                }
            },
            y: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder,
                    drawBorder: false
                }
            }
        }
    };
}
removeCSs(){
  var i , getId;
   for(i=0; i < 10000 ; i++ )
    {
      var id = `pr_id_${i}`
      getId=document.getElementById(id);

      if(getId){
        const tdElement = getId.querySelector('.p-datatable-tbody');
        const tr = tdElement?.querySelector('tr');
        const td= tr?.querySelector('td')
       if(td){
        getId.removeAttribute('id');     
        }
       
      }
    }
}
}
