<p-toast></p-toast>
<div class="loginRegisterModal">
    <div class="modal1">
        <div class="modal1-dialog modal1-dialog-centered">
            <div class="modal1-content">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <button class="nav-link active" id="login-tab" data-bs-toggle="tab" data-bs-target="#login"
                            type="button" role="tab" aria-controls="login" aria-selected="true">Login</button>
                    </li>
                    <!-- <li class="nav-item">
                        <button class="nav-link" id="register-tab" data-bs-toggle="tab" data-bs-target="#register"
                            type="button" role="tab" aria-controls="register" aria-selected="true">Register</button>
                    </li> -->
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="login" role="tabpanel">
                        <div class="vesax-login">
                            <form [formGroup]="loginForm" (ngSubmit)="saveLoginForm()" #login="ngForm"
                                *ngIf="loginVisiable">
                                <div class="form-group">
                                    <input type="text" placeholder="Username or Email" formControlName="email"
                                        class="form-control form-input" [class.is-invalid]="(login.submitted &&
                                    loginForm.get('email')?.invalid)
                                    ||
                                    loginForm.get('email')?.invalid
                                    &&
                                    loginForm.get('email').touched">
                                    <div class="invalid-feedback">
                                        Please enter username or email
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input type="{{ showPassword ? 'text' : 'password' }}" placeholder="Password"
                                        formControlName="password" class="form-control form-input" [class.is-invalid]="(login.submitted &&
                                    loginForm.get('password')?.invalid)
                                    ||
                                    loginForm.get('password')?.invalid
                                    &&
                                    loginForm.get('password').touched">
                                    <i *ngIf="!showPassword" class="far fa-eye eye-icon"
                                        (click)="togglePasswordVisibility()"></i>
                                    <i *ngIf="showPassword" class="fa fa-eye-slash eye-icon"
                                        (click)="togglePasswordVisibility()"></i>
                                    <div class="invalid-feedback">
                                        Please enter password
                                    </div>
                                </div>
                                <button type="submit" class="login-form">Login Now</button>
                            </form>
                            <span class="dont-account">
                                {{ showForgetPasswordLink ? 'Forget Password' : 'Login Again' }}: <a
                                    (click)="forgotPassword()">Click Here</a></span>
                            <form *ngIf="visible" [formGroup]="forgotPasswordForm">
                                <div class="form-group">
                                    <input type="email" placeholder="Enter your email" formControlName="email"
                                        class="form-control form-input">
                                    <!-- Add error message for email field -->
                                    <div *ngIf="visible && loginForm.get('email').errors?.required"
                                        class="invalid-feedback">
                                        Email is required.
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="verifyOTP">
                                    <input type="email" placeholder="Enter OTP here" formControlName="otp"
                                        class="form-control form-input">
                                    <!-- Add error message for email field -->
                                    <div *ngIf="visible && loginForm.get('email').errors?.required"
                                        class="invalid-feedback">
                                        Email is required.
                                    </div>
                                </div>
                                <div *ngIf="sentOTP">
                                    <button class="login-form" (click)="sendotp(forgotPasswordForm.value.email)">Send
                                        OTP</button>
                                </div>
                                <div *ngIf="verifyOTP">
                                    <button class="login-form"
                                        (click)="verify(forgotPasswordForm.value.email,forgotPasswordForm.value.otp)">Verify</button>
                                </div>
                            </form>

                        </div>
                    </div>
                    <!-- <div class="tab-pane fade" id="register" role="tabpanel">
                        <div class="vesax-register">
                             <form [formGroup]="registerForm" (ngSubmit)="saveRegisterForm()" #register="ngForm">
                                <div class="form-group">
                                    <input formControlName="name" type="text" placeholder="Username"
                                        class="form-control form-input">
                                </div>
                                <div class="form-group">
                                    <input formControlName="email" type="email" placeholder="Email"
                                        class="form-control form-input">
                                </div>
                                <div class="form-group">
                                    <input formControlName="password" type="password" placeholder="Password"
                                        class="form-control form-input">
                                </div>
                                <div class="form-group">
                                    <input formControlName="mobile" type="number" maxlength="10"
                                        placeholder="Contact No" class="form-control form-input">
                                </div>
                                <button type="submit" class="login-form">Register Now</button>
                            </form> -->
                    <!-- <span class="already-account">Already have an account? <a href="#">Login Now</a></span> -->
                </div>
            </div>
        </div>
    </div>
</div>