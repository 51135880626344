import { Component, OnInit } from '@angular/core';
import { MaikaListService } from 'src/app/services/maika-list.service';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MasterService } from 'src/app/services/master.service';
@Component({
  selector: 'app-nanny-task-list',
  templateUrl: './nanny-task-list.component.html',
  styleUrls: ['./nanny-task-list.component.scss'],
})
export class NannyTaskListComponent implements OnInit {
  parsedData: any = [];
  datalength: any;
  breadcrumb = [
    {
      title: 'Nurse Task',
      subTitle: 'Dashboard',
    },
  ];

  babyData: any = {};
  escort: any = {};
  playing: any = {};
  food: any = {};
  bathing: any = [];
  feeding: any = {};
  viewDetails: any[] = [];
  filterVal: string;
  loading: any;
  getNusreData: any;
  nurce_care_link: string = '';
  tooltipText: string = 'Nanny Task List';
  diaper: any = [];
  skin: any = [];
  vitamins: any = [];
  vaccination: any = [];
  hasTodaysEntry: boolean = false;
  displayZeroToThreeMonth: boolean = false;
  viewDetail: any = {};
  breast_feeding: any = []
  dataonzeroposition: any;
  tub: any;
  lunch: any;
  dinner: any;
  regular: any;
  update: any;
  training: any;
  displayMaximizable3: any;
  changing_colthes_frequency: any;
  school: any;
  ViewDialogeData: any;
  ShowZeroToThreeMonths: boolean = false;
  showoneToThreeyears: boolean = false;
  ShowFourMonthToYear: boolean = false;
  showThreeYearToAbove: boolean = false;
  permissions: any = [];
  createdby:any;
  updatedby:any;
  viewtime:any;
  updatedtime:any;
  permissionData: any = [];
  constructor(private nanny_task_service: MaikaListService, private spinner: NgxSpinnerService, private router: Router, private masterService: MasterService) {
    this.getAllUserPermission();
  }
  // fn to get the data from backend
  // getAllData() {
  //   // this.spinner.show();
  //   this.nanny_task_service.getNannyTaskList().subscribe((res: any) => {
  //     this.nannyTaskData = res.data;
  //     this.datalength = res?.data?.length;
  //     this.nannyTaskData.map((ele: any) => {
  //       //parsing the data
  //       if (ele?.nanny_task_json) {
  //         this.parsedData.push(JSON.parse(ele.nanny_task_json));
  //       }
  //     });
  //     // this.spinner.hide();
  //   });
  // }
  getAllNurseData() {
    this.spinner.show()
    const id = localStorage.getItem('babyId');
    this.nanny_task_service.getNurseTaskDataById(id).then((res: any) => {
      this.spinner.hide()
      this.getNusreData = res.data;
      console.log("NURSE DATA", this.getNusreData);
    },
      (error: any) => {
        this.spinner.hide()
        // Handle any errors that occurred during the API call
      

      })

    // this.nanny_task_service.getAllNurseTaskSData().subscribe((res: any) => {
    //   this.getNusreData = res.data;
    //   console.log("NURSE DATA", this.getNusreData);

    // })
  }
  ngOnInit(): void {
    // this.getAllData();
    this.getAllNurseData()
  }

  // getData(id:any){
  //   this.getNusreData?.map((res: any) => {
  //     if(res.baby_id === id){
  //       console.log('show....', res.baby_id, 'id', id);
  //       this.babyData = res;
  //       console.log("babyyyy:",  this.babyData);
  //       this.nannyTaskPlanDetails=JSON.parse(this.babyData?.nanny_tasks_plan_details);
  //       console.log("Childddd",this.nannyTaskPlanDetails);
  //       const valueNurseTask =this.nannyTaskPlanDetails;
  //       console.log("Value Task Nurse",valueNurseTask);
  //     }
  //   })
  // }

  // getData(id: any) {
  //   // 
  //   // this.rowNurseTasks = []; // Initialize the array

  //   this.getNusreData?.forEach((res: any) => {
  //     if (res.baby_id == id) {
  //       console.log('show....', res.baby_id, 'id', id);
  //       this.viewDetails= res;
  //       console.log("viewDetails",this.viewDetails);
  //       // Parse the JSON string into an array

  //       // this.showNurseDetails.push(nannyTaskDetails)
  //       // Push each nurse task object into the rowNurseTasks array
  //       // nannyTaskDetails.forEach((task: any) => {
  //       //     this.showNurseDetails .push(task);
  //       // });

  //     }
  //   });
  //   // console.log("Show Nurse Details", this.showNurseDetails);
  //   // console.log(this.rowNurseTasks);
  // }
  getData(id: any) {
    this.viewDetails = []; // Initialize the array
    this.getNusreData?.forEach((res: any) => {
      if (res.baby_id == id) {
        console.log('show....', res.baby_id, 'id', id);
        this.viewDetails.push(res); // Push the object into the array
        console.log("viewDetails", this.viewDetails);
        this.createDummyEntry();
        setTimeout(() => {
          this.removeCSs();
        }, 1500);
        
      }
    });
  }

  createDummyEntry() {
    this.hasTodaysEntry = false;
    console.log("createDummyEntry called")
    this.viewDetails[0].nanny_tasks_plan_details.forEach((res: any) => {
      let today = new Date();
      let entryDate = new Date(res.created_at);
      console.log("entry",entryDate);
      
      if (today.getDate() == entryDate.getDate() && today.getMonth() == entryDate.getMonth() && today.getFullYear() == entryDate.getFullYear()) {
        console.log('entry is paresent');
        this.hasTodaysEntry = true;
      }
    });

    if (!this.hasTodaysEntry) {
      let entryDate = new Date();
      entryDate.setHours(0);
      entryDate.setMinutes(0);
      entryDate.setSeconds(0);
      let dummyEntry = {
        created_at: entryDate,
      }
      this.viewDetails[0].nanny_tasks_plan_details.unshift(dummyEntry);
      console.log("today entry inserted", this.viewDetails);
    }


    // this.removeCSs()

  }
  getAllUserPermission() {
    let id = localStorage.getItem('user_id');
    this.masterService.getUserPremissionById(id).then((res: any) => {
      console.log("Response of Health Monitoring", res);

      this.permissionData = res.data;

      for (let i = 0; i < this.permissionData.length; i++) {
        
        this.permissions.push(this.permissionData[i].permission_id);
      }
      console.log("all permissions", this.permissions);
    })
  }



  checkPermission(permission: number) {
   
    return this.permissions.includes(permission);
  }
  detail: any = [];
  showView(id: any) {
    this.viewDetails.forEach((res: any) => {
      res.nanny_tasks_plan_details?.forEach((task: any) => {
        if (task.nanny_task_id == id) {
          console.log("viewDetails", this.viewDetails);
          this.createdby= task.created_by;
          console.log("time",this.viewtime);
          this.updatedby= task.updated_by;
          console.log("time",this.updatedtime);
          this.viewtime= task.created_at;
          console.log("time",this.viewtime);
          this.updatedtime= task.updated_at;
          console.log("time",this.updatedtime);
          this.ViewDialogeData = task.nanny_task_details_json;
          this.viewDetail = this.viewDetails[0];
          console.log("taskData", this.ViewDialogeData);
          if (task.care_plan_id == 1) {
            console.log("Zero to thre months");
            this.ShowZeroToThreeMonths = true;

          }
          else if (task.care_plan_id == 2) {
            this.ShowFourMonthToYear = true;
            console.log("four months to 1 year");
          }
          else if (task.care_plan_id == 3) {
            console.log("1 to 3 year");
            this.showoneToThreeyears = true;
          }
          else if (task.care_plan_id == 4) {
            this.showThreeYearToAbove = true;
            console.log("above 3 year");
          }
        }
      })
    })
  }


  formatDateTime(date: string | undefined): string {
    if (date) {
      const formattedDate = new Date(date);
      const hours = formattedDate.getHours();
      const minutes = ('0' + formattedDate.getMinutes()).slice(-2);
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const displayHours = hours % 12 || 12; // Convert 0 to 12

      return `${displayHours}:${minutes} ${ampm}`;
    }
    return '-';
  }


  editOnCLick(babyId, id) {
    let dob, nurce_care_link;
    console.log("idsss", babyId);
    localStorage.setItem('nurseTaskEditID', babyId)
    this.getNusreData?.forEach((res: any) => {
      if (res.baby_id == babyId) {
        console.log("showwww", babyId, "......", res.date_of_birth)

        dob = res.date_of_birth.split('/');
      }
    });

    let today = new Date();


    console.log("Date of Birth", dob)


    let dateOfBirth = new Date(dob[2], dob[1] - 1, dob[0]);

    console.log("dateOfBirth", dateOfBirth);

    const timeDiffMilliseconds = today.getTime() - dateOfBirth.getTime();

    const daysDiff = Math.floor(timeDiffMilliseconds / (1000 * 3600 * 24));

    console.log('Difference in days:', daysDiff);

    if (daysDiff <= 119) {
      nurce_care_link = '/portal/edit-zero-to-three-months';
    }
    // up to 4 months  to 1 year
    else if (daysDiff <= 365 && daysDiff > 119) {
      nurce_care_link = '/portal/edit-four-to-one-year';
    }
    // 1 year to 3 year
    else if (daysDiff <= 1095 && daysDiff > 365) {
      nurce_care_link = '/portal/edit-one-year-to-three-year';
    }

    // up to 3 year 
    else if (daysDiff > 1095) {
      nurce_care_link = '/portal/edit-three-years-and-above';
    }

    if (this.hasTodaysEntry) {
      localStorage.setItem('nurseTaskEditID', id);
    }
    else {
      localStorage.removeItem('nurseTaskEditID');
    }

    console.log('id', id);
    console.log("nurce_care_link", nurce_care_link);
    this.router.navigateByUrl(nurce_care_link);
  }
  reset(dt) {
    dt.reset();
    this.filterVal = '';
  }
  softReload() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['portal/patient-view']);
    });
  }

  

  removeCSs(){
    var i , getId;
     for(i=0; i < 10000 ; i++ )
      {
        var id = `pr_id_${i}`
        getId=document.getElementById(id);

        if(getId){
          const tdElement = getId.querySelector('.p-datatable-tbody');
          const tr = tdElement?.querySelector('tr');
          const td= tr?.querySelector('td')
         if(td){
          getId.removeAttribute('id');     
          }
         
        }
      }
  }
}