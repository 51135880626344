import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-navbar',
  templateUrl: './dashboard-navbar.component.html',
  styleUrls: ['./dashboard-navbar.component.scss']
})
export class DashboardNavbarComponent implements OnInit {
  getToken: any;
  getRole: any;
  getname: any;
  constructor(private location: Location, private router: Router,) { }

  ngOnInit(): void {
    this.getname = localStorage.getItem('name');
    this.getToken = localStorage.getItem('token');
    this.getRole = localStorage.getItem('role')

  }
  goBack() {
    this.router.navigate(['/portal/first-screen']);
  }
  logout() {
    localStorage.clear();
    // window.location.reload();
    this.router.navigate(['/']);
  }
}
