<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->
<!-- import { DialogModule } from 'primeng/dialog'; -->

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">
	<app-dashboard-navbar></app-dashboard-navbar>

	<!-- Breadcrumb Area -->
	<div *ngFor="let Content of breadcrumb" class="breadcrumb-area">
		<h1>{{ Content.title }}</h1>
		<ol class="breadcrumb">
			<li class="item">
				<a routerLink="/">Home</a>
			</li>
			<li class="item">
				{{ Content.subTitle }}
			</li>
			<li class="item">{{ Content.title }}</li>
		</ol>
	</div>

	<!-- <div class="add-listings-box">
        <div class="row justify-content-between align-items-center">
            <div class="form-group col-4">
                <label class="p-0">Name <span class="required">*</span></label>
                <input type="text" name="name" class="form-control" placeholder="Name" (change)="getInput($event)" />
            </div>
            <div class="add-listings-btn mb-0 col-4 text-end">
                <button type="submit" (click)="addTableData()">Add</button>
            </div>
        </div>
    </div> -->
	<div class="add-listings-box">
		<div class="Master-view-box">
			<div class="theader d-flex justify-content-between">
				<div class="d-flex w-100">
					<span class="p-input-icon-left ml-auto mr-2 w-100%">
						<i class="pi pi-search"></i>
						<input (input)="dt1.filterGlobal($event.target.value, 'contains')" pInputText type="text"
							class="form-control text-center" scriptInjection placeholder="Search Keyword"
							[(ngModel)]="filterVal">
					</span>
					<button type="button" class="mx-2" (click)="reset(dt1)" icon="bx bx-reset"
						tooltipPosition="bottom" pButton style="height: 45px;"></button>
				</div>
				<div class="add-listings-btn" style="text-align: right">
					<a routerLink="/portal/pt-assessent" class="nav-link">
						<button type="submit">Add</button>
					</a>
				</div>
			</div>
		</div>

		<div class="row mt-4">
			<div class="col-lg-12 col-md-12">
				<p-table #dt1 [value]="ptAssListData" [rows]="10" [showCurrentPageReport]="true"
					[tableStyle]="{ 'min-width': '50rem', width: '100%' }" [rowsPerPageOptions]="[10, 25, 50]"
					[loading]="loading" [paginator]="true"
					[globalFilterFields]="['name', 'code', 'date_of_birth','gender']" dataKey="id"
					styleClass="p-datatable-sm p-datatable-customers" responsiveLayout="scroll"
					currentPageReportTemplate="Showing {first} to
					{last} of {totalRecords} entries"editMode="row">
					<ng-template pTemplate="header">
						<tr>
							<th style="min-width: 62px">S. No.</th>
							<th>Action</th>
							<!-- <th>Date Of Birth</th> -->
							<!-- <th>ID</th> -->
							<th>Name</th>
							<th>Code</th>
							<th>Gender</th>
						</tr>
					</ng-template>
					<!-- table to show data -->
					<ng-template pTemplate="body" let-list let-i="rowIndex">
						<tr>
							<td>{{ i + 1 }}</td>
							<td>
								<button pButton pRipple class="p-button-rounded p-button-text p-button-info"
									pTooltip="View" tooltipPosition="bottom">
									<i (click)="showMaximizableDialog(list.id)" class="far fa-eye"></i>
								</button>
							</td>
							<!-- <td>{{ list?.baby_details_id ?? '-' }}</td> -->
							<td>
								{{ list?.name ??  "-" | titlecase}}
							</td>
							<td>
								{{ list?.code ?? "-"  | titlecase}}
							</td>
							<td>
								{{ list?.gender?? "-" | titlecase }}
							</td>
							<!-- <td>{{ list?.years ?? "-" }}</td> -->
						</tr>
					</ng-template>
					<ng-template pTemplate="emptymessage">
						<tr>
							<td colspan="5">No Data Found.</td>
						</tr>
					</ng-template>
				</p-table>
			</div>
			<!-- ****************************************************view dialog********************************************************* -->
			<!-- <p-dialogheader="Baby Details" [(visible)]="displayMaximizable" [modal]="true" [style]="{ width: '90vw' }"
     [draggable]="false" [resizable]="false"></p-dialog> -->
			<!-- --------------------------------------------------------- view dialog ------------------------------------------------------- -->
			<!-- ***************************************************************************************************************** -->
			<p-dialog [(visible)]="displayMaximizable" [modal]="true" [style]="{ width: '90vw' }" [draggable]="false"
				[resizable]="false" header="Patient Details">
				<!-- Start -->
				<ng-container *ngFor="let data of viewDetail">
					<div class="row">
						<div class="col-lg-12">
							<form class="my-profile-box">
								<div>
									<!-- Basic detail -->
									<div class="row">
										<div class="col-xl-4 col-lg-12 col-md-12">
											<div class="form-group">
												<label>Name :</label>
												<input type="text" readonly class="form-control"
													value="{{ data?.name ??  '-'| titlecase  }}">
											</div>
										</div>
										<div class="col-xl-4 col-lg-12 col-md-12">
											<div class="form-group">
												<label>Code :</label>
												<input type="text" readonly class="form-control"
													value="{{ data?.code ?? '-' }}">
											</div>
										</div>
										<div class="col-xl-4 col-lg-12 col-md-12">
											<div class="form-group">
												<label>Gender :</label>
												<input type="text" readonly class="form-control"
													value="{{ data?.gender ?? '-'| titlecase }}">
											</div>
										</div>
										<div class="col-xl-4 col-lg-12 col-md-12">
											<div class="form-group">
												<label>Age :</label>
												<input type="text" readonly class="form-control"
													value="{{ data?.age ?? '-' }}">
											</div>
										</div>
										<div class="col-xl-4 col-lg-12 col-md-12">
											<div class="form-group">
												<label>Date of Birth :</label>
												<input type="text" readonly class="form-control"
													value="{{ data?.dob| date:'dd-MM-yyyy' ?? '-' }}">
											</div>
										</div>
									</div>

									<div *ngFor="let item of assessment">
										<!-- intial assissment -->
										<!-- <div class="col-xl-4 col-lg-12 col-md-12">
											<div class="form-group">
												<label>Initial Assessment</label>
												<input type="text" readonly class="form-control" value="{{ data?.mode_delivery ?? '-' }}" />
											</div>
										</div> -->
										<h3 class="fw-bold">Initial Assessment:</h3>
										<!-- <div class="col-xl-4 col-lg-12 col-md-12">
											<div class="form-group">
												<label>
													Degree of Physical Activity :
												</label>
												
											</div>
										</div> -->
										<div class="row">
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Degree of Physical Activity:</label>
													<!-- <div>{{ viewDetail | json }}</div> -->
													<input type="text" readonly class="form-control"
														value="{{item?.physical_activity?.bedbound ??'-'| titlecase}}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label class="px-0">Medical Diagnosis</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.medical_diagnosis ?? '-' | titlecase}}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label class="px-0">Chief Complaint</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.chief_complain ?? '-'| titlecase }}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label class="px-0">
														Consciousness/Mental State Remarks
													</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.mental_status_remarks ?? '-' | titlecase}}">
												</div>
											</div>
										</div>

										<!-- Mother detail -->
										<!-- <h3 class="fw-bold">Mother Detail</h3> -->


										<div class="row">
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Weight :</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.weight ?? '-' | titlecase}}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Weight Remarks :</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.weight_remarks ?? '-'| titlecase }}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Height :</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.height ?? '-'| titlecase }}">
												</div>
											</div>
										</div>

										<!-- Attachment -->
										<h3 class="fw-bold">Attachment</h3>

										<div class="row">
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>NGT :</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.attachment?.ngt ? 'Yes' : '-' }}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Colostomy :</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.attachment?.colostomy? 'Yes' : '-' }}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>PEGTUBE :</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.attachment?.pegtube ? 'Yes' : '-' }}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>FC :</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.attachment?.fc ? 'Yes' : '-'}}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Tracheostomy :</label>
													<input type="text" readonly class="form-control"
														value="{{item?.attachment?.tracheostomy ? 'Yes' : '-'}}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>
														Mechanical Ventilator :
													</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.attachment?.mechanical_ventilator ? 'Yes' : '-' }}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>NA :</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.attachment?.na? 'Yes':'-' }}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Others :</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.attachment?.others? 'Yes' : '-' }}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>
														Attachment Remarks :
													</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.attachment_remarks??'-' | titlecase }}">
												</div>
											</div>
										</div>


										<!-- Vital Signs -->
										<h3 class="fw-bold">Vital Signs</h3>
										<!-- <div class="row" *ngFor="let sib of sibling"> -->
										<h3 class="fw-bold">Pre Session:</h3>
										<div class="row">
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Temp :</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.vital_signs?.pre_temp??'-' }}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Pulse (bpm) :</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.vital_signs?.pre_pulse??'-' }}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Resp (bpm) :</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.vital_signs?.pre_resp??  '-' }}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>BP (mmhg) :</label>
													<input type="text" readonly class="form-control"
														value="{{item?.vital_signs?.pre_bp??  '-'}}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>SPO2 :</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.vital_signs?.pre_sp??  '-'}}">
												</div>
											</div>
										</div>
										<h3 class="fw-bold">Post Session:</h3>
										<div class="row">
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Temp :</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.vital_signs?.post_temp??'-'}}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Pulse (bpm) :</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.vital_signs?.post_pulse?? '-' }}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Resp (bpm) :</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.vital_signs?.post_resp?? '-'}}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>BP (mmhg) :</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.vital_signs?.post_bp?? '-' }}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>SPO2 :</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.vital_signs?.post_sp??'-'  }}">
												</div>
											</div>
										</div>

										<!-- If in pain,pain assessment complete: -->
										<h3 class="fw-bold">Pain Assessment</h3>
										<div class="row">
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Pain Status :</label>
													<input type="text" readonly class="form-control"
														value="{{item?.pain_assessment?.pain_yes?? '-'}}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12col-md-12">
												<div class="form-group">
													<label>Pain Tool :</label>
													<input type="text" readonly class="form-control"
														value="{{  item?.pain_assessment?.pain_yes_remark?? '-' }}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Pain Score :</label>
													<input type="text" readonly class="form-control"
														value="{{  item?.pain_assessment?.pain_no_remark??  '-' }}">
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-xl-4 col-lg-12col-md-12">
												<div class="form-group">
													<label>Onset :</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.onset ?? '-' }}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12col-md-12">
												<div class="form-group">
													<label>Location :</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.location ?? '-' }}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12col-md-12">
												<div class="form-group">
													<label>Character :</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.character ?? '-' }}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12col-md-12">
												<div class="form-group">
													<label>
														Aggravating factor :
													</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.aggravating_factor ?? '-' }}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12col-md-12">
												<div class="form-group">
													<label>Relieving Factor :</label>
													<input type="text" readonly class="form-control"
														value="{{ item ?.relieving_factor ?? '-' }}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12col-md-12">
												<div class="form-group">
													<label>Timing :</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.timing ?? '-' }}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12col-md-12">
												<div class="form-group">
													<label>Severity :</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.severity ?? '-' }}">
												</div>
											</div>
										</div>
										<div class="row">
											<h3 class="fw-bold">Mental Status</h3>
											<div class="col-xl-4 col-lg-12col-md-12">
												<div class="form-group">
													<label>Mental Status</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.mental_status?.alert ?? '-' }}">
												</div>
											</div>
											<!-- <div class="col-xl-4 col-lg-12col-md-12">
												<div class="form-group">
													<label>Oriented</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.mental_status?.oriented ? 'Yes' : '-'  }}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12col-md-12">
												<div class="form-group">
													<label>Confused</label>
													<input type="text" readonly class="form-control"
														value="{{item?.mental_status?.confused ? 'Yes' : '-'  }}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12col-md-12">
												<div class="form-group">
													<label>Restless</label>
													<input type="text" readonly class="form-control"
														value="{{item?.mental_status?.restless ? 'Yes' : '-'  }}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12col-md-12">
												<div class="form-group">
													<label>Stupurous</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.mental_status?.stupurous ? 'Yes' : '-'   }}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12col-md-12">
												<div class="form-group">
													<label>Unresponsive</label>
													<input type="text" readonly class="form-control"
														value="{{item?.mental_status?.unresponsive ? 'Yes' : '-'  }}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12col-md-12">
												<div class="form-group">
													<label>Lethargic</label>
													<input type="text" readonly class="form-control"
														value="{{item?.mental_status?.lethargic ? 'Yes' : '-'}}">
												</div>
											</div> -->
										</div>
										<div class="row">
											<h3 class="fw-bold">Seizure Disorder</h3>
											<div class="col-xl-4 col-lg-12col-md-12">
												<div class="form-group">
													<label>Seizure Status</label>
													<input type="text" readonly class="form-control"
														value="{{item?.seizure_yes == 1 ? 'Yes' : (item?.seizure_yes == 0 ? 'No' : '-')}}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12col-md-12">
												<div class="form-group">
													<label>Seizure Remarks</label>
													<input type="text" readonly class="form-control"
														value="{{item?.seizure_comment ?? '-'}}">
												</div>
											</div>
										</div>
										<div class="row">

											<h3 class="fw-bold">Sensory Information</h3>
											<div class="col-xl-4 col-lg-12col-md-12">
												<div class="form-group">
													<label>Sensory Information</label>
													<input type="text" readonly class="form-control"
														value="{{item?.sensory_information?.paralysis??'-'}}">
												</div>
											</div>
											<!-- <div class="col-xl-4 col-lg-12col-md-12">
												<div class="form-group">
													<label>Numbness</label>
													<input type="text" readonly class="form-control"
														value="{{item?.sensory_information?.numbness?'Yes' : '-'}}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12col-md-12">
												<div class="form-group">
													<label>Tingling</label>
													<input type="text" readonly class="form-control"
														value="{{item?.sensory_information?.tingling?'Yes' : '-'}}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12col-md-12">
												<div class="form-group">
													<label>Dizziness</label>
													<input type="text" readonly class="form-control"
														value="{{item?.sensory_information?.dizziness?'Yes' : '-'}}">
												</div>
											</div>
											<div class="col-xl-4 col-lg-12col-md-12">
												<div class="form-group">
													<label>Unable to assess</label>
													<input type="text" readonly class="form-control"
														value="{{item?.sensory_information?.unable_assess?'Yes' : '-'}}">
												</div>
											</div> -->
										</div>
										<div class="form-group table-responsive">
											<table class="table">
											  <thead>
												<tr class="border-light" style="white-space: nowrap;">
												  <th scope="col"><label class="subHeading px-0">Light Touch:</label></th>
												  <th scope="col"><label class="subHeading px-0">Blunt Touch/Deep</label></th>
												  <!-- <th scope="col"><label class="subHeading px-0">Pressure</label></th> -->
												  <th scope="col"><label class="subHeading px-0">Pin Prick</label></th>
												  <!-- <th scope="col"><label class="subHeading px-0">Pin Prick</label></th> -->
												</tr>
											  </thead>
											  <tbody>
												<tr class="border-light">
						
												  <td> <input type="text" readonly class="form-control"
													value="{{ item?.cognition?.light_intact == 1 ? 'Intact' : item?.cognition?.light_intact == 2 ? 'Impaired' : item?.cognition?.light_intact == 3 ? 'Absent' : item?.cognition?.light_intact == 4 ? 'Not tested ' : '-' }}" />
												  </td>
												  <td> <input type="text" readonly class="form-control"
													value="{{ item?.cognition?.blunt_intact == 1 ? 'Intact' : item?.cognition?.blunt_intact == 2 ? 'Impaired' : item?.cognition?.blunt_intact == 3 ? 'Absent' : item?.cognition?.blunt_intact == 4 ? 'Not tested ' : '-' }}" />
												  </td>
												  
												  <td> <input type="text" readonly class="form-control"
													value="{{ item?.cognition?.pin_intact == 1 ? 'Intact' : item?.cognition?.pin_intact == 2 ? 'Impaired' : item?.cognition?.pin_intact == 3 ? 'Absent' : item?.cognition?.pin_intact == 4 ? 'Not tested ' : '-' }}" />
												  </td>
												</tr>
												<tr class="border-light">
												  <td>
													<label for="" class="subHeading px-0">Remark</label>
												  <input type="text" readonly class="form-control"
													  value="{{ item?.cognition?.light_remarks ?? '-' }}" />
												  </td>
												  <td>
													<label for="" class="subHeading px-0">Remark</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.cognition?.blunt_remarks ?? '-' }}" />
													</td>
													
													  <td>
														<label for="" class="subHeading px-0">Remark</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.cognition?.pin_remarks ?? '-' }}" />
														</td>
												</tr>  </tbody>
											</table>
										  </div>
										<div class="row">
											<!-- <h3 class="fw-bold">Cognition</h3>
											<label class="fw-bold">Light Touch</label>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Light Touch</label>
													<input type="text" readonly class="form-control"
														value="{{ item?.cognition?.light_intact == 1 ? 'intact' :'-' }}" />
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Impaired</label>
													<input type="text" readonly class="form-control"
														value="{{item?.cognition?.light_intact == 2 ? 'impaired' : '-' }}" />
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Absent</label>
													<input type="text" readonly class="form-control"
														value="{{item?.cognition?.light_intact == 3 ? 'impaired' : '-' }}" />
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<input type="text" readonly class="form-control"
														value="{{item?.cognition?.light_intact == 4 ? 'impaired' : '-' }}" />
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Remarks</label>
													<input type="text" readonly class="form-control"
														value="{{item?.cognition?.light_remarks?? '-'}}">
												</div>
											</div>
											<label class="fw-bold">
												Light Blunt touch/deep pressure
											</label>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Light Blunt touch/deep pressure</label>
													<input type="text" readonly class="form-control"
														value="{{item?.cognition?.blunt_intact?? '-'}}">
												</div>
											</div>
										
											<div class="col-xl-4 col-lg-12col-md-12">
												<div class="form-group">
													<label>Remarks</label>
													<input type="text" readonly class="form-control"
														value="{{item?.cognition?.blunt_remarks?? '-'}}">
												</div>
											</div>
											<label class="fw-bold">Pin Prick</label>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Pin Prick</label>
													<input type="text" readonly class="form-control"
														value="{{item?.cognition?.pin_intact??'-'}}">
												</div>
											</div>
											
											<div class="col-xl-4 col-lg-12col-md-12">
												<div class="form-group">
													<label>Remarks</label>
													<input type="text" readonly class="form-control"
														value="{{item?.cognition?.pin_remarks?? '-'}}">
												</div>
											</div> -->
											<h3>Oriented to people</h3>
											<div class="row">
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>People Status</label>
														<input type="text" readonly class="form-control"
															value="{{item?.cognition?.cognition_oriented?.people_yes??'-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>People Remarks</label>
														<input type="text" readonly class="form-control"
															value="{{item?.cognition?.cognition_oriented?.people_remark??'-'}}">
													</div>
												</div>
											</div>
											<h3>Oriented to time</h3>
											<div class="row">
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Time Status</label>
														<input type="text" readonly class="form-control"
															value="{{item?.cognition?.cognition_oriented?.time_yes??'-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Time Remarks</label>
														<input type="text" readonly class="form-control"
															value="{{item?.cognition?.cognition_oriented?.time_remark??'-'}}">
													</div>
												</div>
											</div>
											<h3>Oriented to place</h3>
											<div class="row">
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Place Status</label>
														<input type="text" readonly class="form-control"
															value="{{item?.cognition?.cognition_oriented?.place_yes??'-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Place Remarks</label>
														<input type="text" readonly class="form-control"
															value="{{item?.cognition?.cognition_oriented?.place_remark??'-'}}">
													</div>
												</div>
											</div>
											<h3 class="fw-bold">Visual Impairment</h3>
											<div class="row">
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>None</label>
														<input type="text" readonly class="form-control"
															value="{{item?.visual_impairment?.visual_none== 1? 'Yes' : '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Glasses</label>
														<input type="text" readonly class="form-control"
															value="{{item?.visual_impairment?.visual_none==2? 'Yes' : '-'}}">
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Contact lenses</label>
														<input type="text" readonly class="form-control"
															value="{{item?.visual_impairment?.visual_none==3? 'Yes' : '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Left</label>
														<input type="text" readonly class="form-control"
															value="{{item?.visual_impairment?.visual_contact_left? 'Yes' : '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Right</label>
														<input type="text" readonly class="form-control"
															value="{{item?.visual_impairment?.visual_contact_right?'Yes': '-'}}">
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Blind</label>
														<input type="text" readonly class="form-control"
															value="{{item?.visual_impairment?.visual_none==4? 'Yes' : '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Left</label>
														<input type="text" readonly class="form-control"
															value="{{item?.visual_impairment?.visual_blind_left? 'Yes' : '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Right</label>
														<input type="text" readonly class="form-control"
															value="{{item?.visual_impairment?.visual_blind_right? 'Yes': '-'}}">
													</div>
												</div>
											</div>

											<h3 class="fw-bold">Hearing Impairement</h3>
											<div class="row">
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>None</label>
														<input type="text" readonly class="form-control"
															value="{{item?.hearing_impairment?.hearing_none==1? 'Yes' : '-'}}">
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Impaired</label>
														<input type="text" readonly class="form-control"
															value="{{item?.hearing_impairment?.hearing_none==2? 'Yes' : '-'}}">
													</div>
												</div>

												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Left</label>
														<input type="text" readonly class="form-control"
															value="{{item?.hearing_impairment?.hearing_impaired_left? 'Yes' : '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Right</label>
														<input type="text" readonly class="form-control"
															value="{{item?.hearing_impairment?.hearing_impaired_right? 'Yes' : '-'}}">
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Hearing Aid</label>
														<input type="text" readonly class="form-control"
															value="{{item?.hearing_impairment?.hearing_none==3? 'Yes' : '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Left</label>
														<input type="text" readonly class="form-control"
															value="{{item?.hearing_impairment?.hearing_aid_left? 'Yes' : '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Right</label>
														<input type="text" readonly class="form-control"
															value="{{item?.hearing_impairment?.hearing_aid_right? 'Yes': '-'}}">
													</div>
												</div>
											</div>

											<div class="row">
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Deaf</label>
														<input type="text" readonly class="form-control"
															value="{{item?.hearing_impairment?.hearing_none==4? 'Yes' : '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Left</label>
														<input type="text" readonly class="form-control"
															value="{{item?.hearing_impairment?.hearing_deaf_left? 'Yes' : '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Right</label>
														<input type="text" readonly class="form-control"
															value="{{item?.hearing_impairment?.hearing_deaf_right? 'Yes': '-'}}">
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Unable to Assess</label>
														<input type="text" readonly class="form-control"
															value="{{item?.hearing_impairment?.hearing_none==5? 'Yes' : '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Left</label>
														<input type="text" readonly class="form-control"
															value="{{item?.hearing_impairment?.hearing_unable_left? 'Yes' : '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Right</label>
														<input type="text" readonly class="form-control"
															value="{{item?.hearing_impairment?.hearing_unable_right? 'Yes': '-'}}">
													</div>
												</div>
											</div>
											<!-- Speech Impairment -->
											<div class="row">
												<h3 class="fw-bold">Speech Impairment</h3>

												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>None</label>
														<input type="text" readonly class="form-control"
															value="{{item?.speech_impairment?.speech_none? 'Yes': '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Slurred</label>
														<input type="text" readonly class="form-control"
															value="{{item?.speech_impairment?.speech_slurred? 'Yes': '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Stuttered</label>
														<input type="text" readonly class="form-control"
															value="{{item?.speech_impairment?.speech_stuttered? 'Yes': '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Mute</label>
														<input type="text" readonly class="form-control"
															value="{{item?.speech_impairment?.speech_mute? 'Yes': '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Unable to Assess</label>
														<input type="text" readonly class="form-control"
															value="{{item?.speech_impairment?.speech_unable? 'Yes': '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">

													<div class="form-group">
														<label> Other Remarks</label>
														<input type="text" readonly class="form-control"
															value="{{item?.speech_impairment?.speech_remarks?? '-'}}">
													</div>

												</div>
											</div>

											<!-- Respiratory Assessment -->
											<div class="row">
												<h3 class="fw-bold">Respiratory Assessment</h3>

												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>
															No known Problems (WNL)</label>
														<input type="text" readonly class="form-control"
															value="{{item?.repository_assessment?.repository_none? 'Yes': '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Cough</label>
														<input type="text" readonly class="form-control"
															value="{{item?.repository_assessment?.repository_cough? 'Yes': '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Productive</label>
														<input type="text" readonly class="form-control"
															value="{{item?.repository_assessment?.repository_productive? 'Yes': '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Non Productive</label>
														<input type="text" readonly class="form-control"
															value="{{item?.repository_assessment?.repository_dyspnea? 'Yes': '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Dyspnea</label>
														<input type="text" readonly class="form-control"
															value="{{item?.repository_assessment?.repository_nonproductive? 'Yes': '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Orthopnea</label>
														<input type="text" readonly class="form-control"
															value="{{item?.repository_assessment?.repository_Orthopnea? 'Yes': '-'}}">
													</div>
												</div>

												<!-- breathing sound -->

												<!-- <label class="fw-bold h-2">Breathing Sound:</label> -->
												<div class="row">
													<h3 class="fw-bold">Breathing Sound:</h3>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Clear:</label>
															<!-- <input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.breath_sound_clear? 'Yes': '-'}}"> -->
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Right</label>
															<input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.breath_sound_clear? 'Yes': '-'}}">
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Left</label>
															<input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.breath_sound_left? 'Yes': '-'}}">
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Crackles:</label>
															<!-- <input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.breath_sound_crackles? 'Yes': '-'}}"> -->
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Right</label>
															<input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.breath_sound_crackles? 'Yes': '-'}}">
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Left</label>
															<input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.breath_sound_crackles_left? 'Yes': '-'}}">
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Wheezing:</label>
															<!-- <input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.breath_sound_wheezing? 'Yes': '-'}}"> -->
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Right</label>
															<input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.breath_sound_wheezing? 'Yes': '-'}}">
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Left</label>
															<input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.breath_sound_wheezing_left? 'Yes': '-'}}">
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Diminish:</label>
															<!-- <input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.breath_sound_diminish? 'Yes': '-'}}"> -->
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Right</label>
															<input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.breath_sound_diminish? 'Yes': '-'}}">
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Left</label>
															<input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.breath_sound_diminish_left? 'Yes': '-'}}">
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Rhonchi:</label>
															<!-- <input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.breath_sound_rhonchi? 'Yes': '-'}}"> -->
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Right</label>
															<input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.breath_sound_rhonchi? 'Yes': '-'}}">
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Left</label>
															<input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.breath_sound_rhonchi_left? 'Yes': '-'}}">
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Stridor:</label>
															<!-- <input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.breath_sound_stridor? 'Yes': '-'}}"> -->
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Right</label>
															<input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.breath_sound_stridor? 'Yes': '-'}}">
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Left</label>
															<input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.breath_sound_stridor_left? 'Yes': '-'}}">
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Absent:</label>
															<!-- <input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.breath_sound_absent? 'Yes': '-'}}"> -->
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Right</label>
															<input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.breath_sound_absent? 'Yes': '-'}}">
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Left</label>
															<input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.breath_sound_absent_left? 'Yes': '-'}}">
														</div>
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Comments</label>
														<input type="text" readonly class="form-control"
															value="{{item?.repository_assessment?.breath_sound_comments?? '-'}}">
													</div>
												</div>

												<!-- Breathing Pattern -->
												<div class="row">
													<h3 class="fw-bold">Breathing Pattern</h3>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Breathing Pattern</label>
															<input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.breathing_pattern_Normal??'-'}}">
														</div>
													</div>
													<!-- <div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Shallow</label>
															<input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.breathing_pattern_Shallow? 'Yes': '-'}}">
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Rapid</label>
															<input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.breathing_pattern_Rapid? 'Yes': '-'}}">
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Labored</label>
															<input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.breathing_pattern_Labored? 'Yes': '-'}}">
														</div>
													</div> -->
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Comments</label>
															<input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.breathing_pattern_comments?? '-'}}">
														</div>
													</div>
												</div>

												<!-- oxygen therapy -->
												<!-- <h6 class="fw-bold">Oxygen Therapy</h6> -->
												<div class="row">
													<h3 class="fw-bold">Oxygen Therapy</h3>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Oxygen Therapy Status</label>
															<input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.oxygen_therapy??'-'}}">
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>LMP</label>
															<input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.lmp_oxygen? 'Yes': '-'}}">
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>SPO2</label>
															<input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.spo2_therapy? 'Yes': '-'}}">
														</div>
													</div>

													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Oxygen Remarks</label>
															<input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.oxygen_therapy_comments?? '-'}}">
														</div>
													</div>
												</div>
												<!-- Secretion -->

												<div class="row">
													<h3 class="fw-bold">Secretion</h3>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Secretion Status</label>
															<input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.secretion?? '-'}}">
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Thick</label>
															<input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.Thick_secretion? 'Yes': '-'}}">
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Loose</label>
															<input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.Loose_secretion? 'Yes': '-'}}">
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Retention</label>
															<input type="text" readonly class="form-control"
																value="{{item?.repository_assessment?.Retention_secretion? 'Yes': '-'}}">
														</div>
													</div>
												</div>
											</div>

											<!-- Circulatory Assessment -->

											<div class="row">
												<h3 class="fw-bold">Circulatory Assessment</h3>
												<div class="row">
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>No Known Problems (WNL)</label>
															<input type="text" readonly class="form-control"
																value="{{item?.gastro_circulatory? 'Yes': '-'}}">
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Pulse </label>
															<input type="text" readonly class="form-control"
																value="{{item?.pulse?? '-'| titlecase}}">
															<!-- <input type="text" readonly class="form-control" *ngIf="
													item?.pulse !== null &&
													item?.pulse !== '' &&
													item?.pulse!==
														undefined;
													else elseBlock
												  " value="{{
													item?.pulse
												  }}" /> -->
															<ng-template #elseBlock>
																<input type="text" readonly class="form-control"
																	value="-" />
															</ng-template>
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Blood Pressure</label>
															<input type="text" readonly class="form-control" *ngIf="
													item?.blood_pressure !== null &&
													item?.blood_pressure !== '' &&
													item?.blood_pressure!==
														undefined;
													else elseBlock
												  " value="{{
													item?.blood_pressure
												  }}" />
															<ng-template #elseBlock>
																<input type="text" readonly class="form-control"
																	value="-" />
															</ng-template>
															<!-- <input type="text" readonly class="form-control"
																value="{{item?.blood_pressure?? '-'}}"> -->
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Peripheral Pulse</label>
															<input type="text" readonly class="form-control"
																value="{{item?.peripheral_pulse?? '-' | titlecase}}">
															<ng-template #elseBlock>
																<input type="text" readonly class="form-control"
																	value="-" />
															</ng-template>
															<!-- <input type="text" readonly class="form-control"
																value="{{item?.peripheral_pulse?? '-'}}"> -->
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Edematous</label>
															<input type="text" readonly class="form-control"
																value="{{item?.edematious?? '-'| titlecase}}">
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Edematous Remarks</label>
															<input type="text" readonly class="form-control"
																value="{{item?.edematious_text??'-'| titlecase}}">
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Edematous Scale</label>
															<!-- <input type="text" readonly class="form-control" *ngIf="
															item?.scale !== null &&
															item?.scale !== '' &&
															item?.scale!==
																undefined;
															else elseBlock
														  " value="{{
															item?.scale
														  }}" />
															<ng-template #elseBlock>
																<input type="text" readonly class="form-control"
																	value="-" />
															</ng-template> -->
															<input type="text" readonly class="form-control"
																value="{{item?.scale??'-' | titlecase}}">
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Capillary Refill</label>
															<!-- <input type="text" readonly class="form-control" *ngIf="
													item?.capillary_refill!== null &&
													item?.capillary_refill!== '' &&
													item?.capillary_refill!==
														undefined;
													else elseBlock
												  " value="{{
													item?.capillary_refill
												  }}" />
															<ng-template #elseBlock>
																<input type="text" readonly class="form-control"
																	value="-" />
															</ng-template> -->
															<input type="text" readonly class="form-control"
																value="{{item?.capillary_refill?? '-' | titlecase}}">
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Skin Temperature</label>
															<!-- <input type="text" readonly class="form-control" *ngIf="
													item?.speech_impairment?.skin_temperature !== null &&
													item?.speech_impairment?.skin_temperature !== '' &&
													item?.speech_impairment?.skin_temperature!==
														undefined;
													else elseBlock
												  " value="{{
													item?.speech_impairment?.skin_temperature
												  }}" />
															<ng-template #elseBlock>
																<input type="text" readonly class="form-control"
																	value="-" />
															</ng-template> -->
															<input type="text" readonly class="form-control"
																value="{{item?.speech_impairment?.skin_temperature?? '-' | titlecase}}">
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Comments</label>
															<input type="text" readonly class="form-control"
																value="{{item?.speech_impairment?.speech_remarks?? '-' | titlecase}}">
														</div>
													</div>
												</div>



											</div>

											<div class="row">
												<h3 class="fw-bold">Gastro Intestinal Assessment</h3>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>No Known Problems (WNL)</label>
														<input type="text" readonly class="form-control"
															value="{{item?.gastro? 'Yes': '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Bowel Sounds</label>
														<!-- <input type="text" readonly class="form-control" *ngIf="
													item?.bowel_sound !== null &&
													item?.bowel_sound !== '' &&
													item?.bowel_sound!==
														undefined;
													else elseBlock
												  " value="{{
													item?.bowel_sound
												  }}" />
														<ng-template #elseBlock>
															<input type="text" readonly class="form-control"
																value="-" />
														</ng-template> -->
														<input type="text" readonly class="form-control"
															value="{{item?.bowel_sound?? '-'}}">
													</div>
												</div>

												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Comments</label>
														<input type="text" readonly class="form-control"
															value="{{item?.bowel_sound_comment?? '-'}}">
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>External Feeding Device</label>
														<input type="text" readonly class="form-control"
															value="{{item?.enternal_feeling?? '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Comments</label>
														<input type="text" readonly class="form-control"
															value="{{item?.enternal_feeling_comment?? '-'}}">
													</div>
												</div>


												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>External Feeding Device Name</label>
														<input type="text" readonly class="form-control"
															value="{{item?.nausea?? '-'}}">
													</div>
												</div>
												<!-- <div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Dysphagia</label>
														<input type="text" readonly class="form-control"
															value="{{item?.dysphagia? 'Yes': '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Difficulty Chewing</label>
														<input type="text" readonly class="form-control"
															value="{{item?.difficulty_chewing? 'Yes': '-'}}">
													</div>
												</div>

												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Constipation</label>
														<input type="text" readonly class="form-control"
															value="{{item?.constipation? 'Yes': '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Diarrhea</label>
														<input type="text" readonly class="form-control"
															value="{{item?.diarrhea? 'Yes': '-'}}">
													</div>
												</div> -->

											</div>
											<!-- Skin -->

											<div class="row">
												<h3 class="fw-bold">Skin</h3>
												<label class="fw-bold">Pressure Ulcer</label>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Location</label>
														<input type="text" readonly class="form-control"
															value="{{item?.pressure_ulcer?? '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Length</label>
														<input type="text" readonly class="form-control"
															value="{{item?.pressure_ulcer_length??'-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Width</label>
														<input type="text" readonly class="form-control"
															value="{{item?.pressure_ulcer_width?? '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Depth</label>
														<input type="text" readonly class="form-control"
															value="{{item?.pressure_ulcer_depth?? '-'}}">
													</div>
												</div>

												<div class="col-xl-4 col-lg-12col-md-12">
													<div class="form-group">
														<label>Swelling</label>
														<input type="text" readonly class="form-control"
															value="{{item?.swelling ?? '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12col-md-12">
													<div class="form-group">
														<label>Scars</label>
														<input type="text" readonly class="form-control"
															value="{{item?.scar?? '-'}}">
													</div>
												</div>
												<div *ngFor="let data of assessmentJson" class="row">
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Medications</label>
															<!-- <ng-container *ngFor="let data of assessmentJson"> -->
															<!-- <input type="text" readonly class="form-control"
															value="{{data?.medication?? ' -'}}"> -->
															<input type="text" readonly class="form-control" *ngIf="
													data?.medication !== null &&
													data?.medication!== '' &&
													data?.medication!==
														undefined;
													else elseBlock
												  " value="{{
													data?.medication
												  }}" />
															<ng-template #elseBlock>
																<input type="text" readonly class="form-control"
																	value="-" />
															</ng-template>
															<!-- </ng-container> -->
														</div>
													</div>
												</div>
											</div>
											<!-- Psychological Information -->

											<div class="row">
												<h3 class="fw-bold h-2">Psychological Information</h3>
												<label class="fw-bold">Mood</label>
												<div class="col-xl-4 col-lg-12col-md-12">
													<div class="form-group">
														<label>Calm and cooperative</label>
														<input type="text" readonly class="form-control"
															value="{{item?.psychological_information?.mood_calm? 'Yes': '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12col-md-12">
													<div class="form-group">
														<label>Sleep Disturbance</label>
														<input type="text" readonly class="form-control"
															value="{{item?.psychological_information?.mood_sleep_dis? 'Yes': '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12col-md-12">
													<div class="form-group">
														<label>Sign of neglect/abuse

														</label>
														<input type="text" readonly class="form-control"
															value="{{item?.psychological_information?.mood_abuse? 'Yes': '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12col-md-12">
													<div class="form-group">
														<label>Anxious</label>
														<input type="text" readonly class="form-control"
															value="{{item?.psychological_information?.mood_anxious? 'Yes': '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12col-md-12">
													<div class="form-group">
														<label>Sad</label>
														<input type="text" readonly class="form-control"
															value="{{item?.psychological_information?.mood_sad? 'Yes': '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12col-md-12">
													<div class="form-group">
														<label>Tearful</label>
														<input type="text" readonly class="form-control"
															value="{{item?.psychological_information?.mood_trearful? 'Yes': '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12col-md-12">
													<div class="form-group">
														<label>Uncooperative</label>
														<input type="text" readonly class="form-control"
															value="{{item?.psychological_information?.mood_ubcooperative? 'Yes': '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12col-md-12">
													<div class="form-group">
														<label>Withdrawal
														</label>
														<input type="text" readonly class="form-control"
															value="{{item?.psychological_information?.mood_widrawal? 'Yes': '-'}}">
													</div>
												</div>

												<div class="col-xl-4 col-lg-12col-md-12">
													<div class="form-group">
														<label>Suicidal</label>
														<input type="text" readonly class="form-control"
															value="{{item?.psychological_information?.mood_sucidal? 'Yes': '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12col-md-12">
													<div class="form-group">
														<label>
															Unable to access</label>
														<input type="text" readonly class="form-control"
															value="{{item?.psychological_information?.mood_access? 'Yes': '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12col-md-12">
													<div class="form-group">
														<label>Others</label>
														<input type="text" readonly class="form-control"
															value="{{item?.psychological_information?.mood_others? 'Yes': '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12col-md-12">
													<div class="form-group">
														<label>Others remark</label>
														<input type="text" readonly class="form-control"
															value="{{item?.psychological_information?.mood_other_comment?? '-'}}">
													</div>
												</div>
											</div>
											<div class="row">
												<h6 class="fw-bold">Musculoskeletal Assessment</h6>
												<div class="col-xl-4 col-lg-12col-md-12">
													<div class="form-group">
														<label>No Known Problems (WNL)</label>
														<input type="text" readonly class="form-control"
															value="{{item?.musculoskeletal_assessment?.musculoskeletal_problems? 'Yes': '-'}}">
													</div>
												</div>

												<div class="col-xl-4 col-lg-12col-md-12">
													<div class="form-group">
														<label>Normal Range of Motion of Extremities</label>
														<input type="text" readonly class="form-control"
															value="{{item?.musculoskeletal_assessment?.motion_extreme_yes?? '-'}}">
													</div>
												</div>
											</div>
											<div class="row">
												<h3 class="fw-bold">Upper Extremities</h3>
												<div class="col-xl-4 col-lg-12col-md-12">
													<div class="form-group">
														<label>Right</label>
														<input type="text" readonly class="form-control"
															value="{{item?.musculoskeletal_assessment?.upper_extreme_right? 'Yes': '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12col-md-12">
													<div class="form-group">
														<label>Left</label>
														<input type="text" readonly class="form-control"
															value="{{item?.musculoskeletal_assessment?.upper_extreme_left?'Yes': '-'}}">
													</div>
												</div>
											</div>
											<div class="row">
												<h3 class="fw-bold">Lower Extremities</h3>
												<div class="col-xl-4 col-lg-12col-md-12">
													<div class="form-group">
														<label>Right</label>
														<input type="text" readonly class="form-control"
															value="{{item?.musculoskeletal_assessment?.lower_extreme_right? 'Yes': '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12col-md-12">
													<div class="form-group">
														<label>Left</label>
														<input type="text" readonly class="form-control"
															value="{{item?.musculoskeletal_assessment?.lower_extreme_left? 'Yes': '-'}}">
													</div>
												</div>
											</div>
											<div class="add-listings-box">
												<h3>Motor Components</h3>
												<label class="subHeading mb-3">Joint and Muscle:</label>
												<div class="form-group table-responsive">
													<table class="table table-bordered">
														<thead>
															<tr style="white-space: nowrap;">
																<th scope="col" class="text-center" rowspan="2"><label
																		class="subHeading px-0">Joint</label>
																</th>
																<th scope="col" class="text-center" rowspan="2"><label
																		class="subHeading px-0">Movement</label>
																</th>
																<th scope="col" class="text-center" rowspan="2"><label
																		class="subHeading px-0">Norm
																		ROM</label>
																</th>
																<th scope="col" class="text-center" colspan="2"><label
																		class="subHeading px-0">
																		ROM</label></th>
																<th scope="col" class="text-center" colspan="2"><label
																		class="subHeading px-0">Tone
																	</label>
																</th>
																<th scope="col" class="text-center" rowspan="2"><label
																		class="subHeading px-0">Comments</label>
																</th>
																<th scope="col" class="text-center" colspan="2"><label
																		class="subHeading px-0">Muscle
																		Strength</label></th>
															</tr>
															<tr>
																<th class="text-center">R</th>
																<th class="text-center">L</th>
																<th class="text-center">R</th>
																<th class="text-center">L</th>
																<th class="text-center">R</th>
																<th class="text-center">L</th>
															</tr>
														</thead>
														<tbody>
															<!-- NECK ROW -->
															<!-- Flexion -->
															<tr>
																<td scope="row" rowspan="4"> <label
																		class="px-0 ">Neck</label></td>
																<td>Flexion</td>
																<td>0-50</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.neck_flexion_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.neck_flexion_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.neck_flexion_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.neck_flexion_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.nech_flexion_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.neck_flexion_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.neck_flexion_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!-- Extension -->
															<tr>
																<td>Extension</td>
																<td>0-60</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.neck_extension_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.neck_extension_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.neck_extension_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.neck_extension_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.neck_extension_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.neck_extension_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.neck_extension_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!-- Rotation -->
															<tr>
																<td>Rotation</td>
																<td>0-80</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.neck_rotation_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.neck_rotation_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.neck_rotation_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.neck_rotation_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.neck_rotation_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.neck_rotation_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.neck_rotation_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!-- Side flex -->
															<tr>
																<td>Side flex</td>
																<td>0-45</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.neck_sideFlex_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.neck_sideFlex_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.neck_sideFlex_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.neck_sideFlex_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.neck_sideFlex_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.neck_sideFlex_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.neck_sideFlex_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!-- SHOULDER ROW -->
															<!-- Flexion -->
															<tr>
																<td scope="row" rowspan="6"> <label
																		class="px-0 ">Shoulder</label></td>
																<td>Flexion</td>
																<td>0-180</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_flexion_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_flexion_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_flexion_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_flexion_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.shoulder_flexion_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_flexion_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_flexion_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!-- Extension -->
															<tr>
																<td>Extension</td>
																<td>0-60</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_extension_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_extension_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_extension_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_extension_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.shoulder_extension_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_extension_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_extension_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!-- Abduction -->
															<tr>
																<td>Abduction</td>
																<td>0-180</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_abduction_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_abduction_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_abduction_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_abduction_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.shoulder_abduction_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_abduction_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_abduction_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!-- Adduction -->
															<tr>
																<td>Adduction</td>
																<td>0-90</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_adduction_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_adduction_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_adduction_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_adduction_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.shoulder_adduction_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_adduction_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_adduction_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!-- Internal Rotation -->
															<tr>
																<td>Internal Rotation</td>
																<td>0-70</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_internal_rotation_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_internal_rotation_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_internal_rotation_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_internal_rotation_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.shoulder_internal_rotation_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_internal_rotation_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_internal_rotation_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!-- External Rotation -->
															<tr>
																<td>External Rotation</td>
																<td>0-90</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_external_rotation_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_external_rotation_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_external_rotation_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_external_rotation_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.shoulder_external_rotation_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_external_rotation_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.shoulder_external_rotation_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!-- ELBOW ROW-->
															<!-- Flexion -->
															<tr>
																<td scope="row" rowspan="4"> <label
																		class="px-0 ">Elbow</label></td>
																<td>Flexion</td>
																<td>0-50</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.elbow_flexion_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.elbow_flexion_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.elbow_flexion_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.elbow_flexion_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.elbow_flexion_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.elbow_flexion_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.elbow_flexion_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
															</tr>

															<!-- Extension -->
															<tr>
																<td>Extension</td>
																<td>0-60</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.elbow_extension_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.elbow_extension_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.elbow_extension_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.elbow_extension_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.elbow_extension_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.elbow_extension_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.elbow_extension_muscle_l== 1? 'Yes': '-' ?? '-' }}" />

															</tr>

															<!-- Supination -->
															<tr>
																<td>Supination</td>
																<td>0-80</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.elbow_supination_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.elbow_supination_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.elbow_supination_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.elbow_supination_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.elbow_supination_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.elbow_supination_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.elbow_supination_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!-- Pronation -->
															<tr>
																<td>Pronation</td>
																<td>0-80</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.elbow_pronation_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.elbow_pronation_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.elbow_pronation_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.elbow_pronation_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.elbow_pronation_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.elbow_pronation_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.elbow_pronation_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!--WRIST ROW -->
															<!-- Flexion -->
															<tr>
																<td scope="row" rowspan="4"> <label
																		class="px-0 ">Wrist</label></td>
																<td>Flexion</td>
																<td>0-50</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.wrist_flexion_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.wrist_flexion_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.wrist_flexion_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.wrist_flexion_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.wrist_flexion_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.wrist_flexion_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.wrist_flexion_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!-- Extension -->
															<tr>
																<td>Extension</td>
																<td>0-70</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.wrist_extension_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.wrist_extension_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.wrist_extension_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.wrist_extension_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.wrist_extension_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.wrist_extension_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.wrist_extension_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!-- Ulnar Deviation -->
															<tr>
																<td>Ulnar Deviation</td>
																<td>0-30</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.wrist_ulnar_deviation_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.wrist_ulnar_deviation_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.wrist_ulnar_deviation_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.wrist_ulnar_deviation_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.wrist_ulnar_deviation_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.wrist_ulnar_deviation_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.wrist_ulnar_deviation_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!-- Radial Deviation -->
															<tr>
																<td> Radial Deviation</td>
																<td>0-20</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.wrist_radial_deviation_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.wrist_radial_deviation_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.wrist_radial_deviation_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.wrist_radial_deviation_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.wrist_radial_deviation_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.wrist_radial_deviation_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.wrist_radial_deviation_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!-- DIGITS ROW -->
															<!-- Thumb MCP -->
															<tr>
																<td scope="row" rowspan="5"> <label
																		class="px-0 ">Digits</label></td>
																<td>Thumb MCP Ext/flex</td>
																<td>10/30</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_thump_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_thump_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_thump_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_thump_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.digits_thump_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_thump_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_thump_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!-- Thump IP -->
															<tr>
																<td>Thump IP Ext/Flex</td>
																<td>0/80</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_thump_ip_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_thump_ip_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_thump_ip_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_thump_ip_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.digits_thump_ip_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_thump_ip_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_thump_ip_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!-- MCP Ext/Flex -->
															<tr>
																<td>MCP Ext/Flex</td>
																<td>0/90</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_mcp_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_mcp_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_mcp_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_mcp_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.digits_mcp_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_mcp_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_mcp_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!-- PIP Ext/Flex -->
															<tr>
																<td>PIP Ext/Flex</td>
																<td>0/100</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_pip_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_pip_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_pip_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_pip_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.digits_pip_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_pip_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_pip_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!-- DIP Ext/Flex -->
															<tr>
																<td>DIP Ext/Flex</td>
																<td>0/90</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_dip_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_dip_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_dip_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_dip_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.digits_dip_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_dip_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.digits_dip_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!-- HIP ROW -->
															<!-- Flexion -->
															<tr>
																<td scope="row" rowspan="6"> <label
																		class="px-0 ">Hip</label></td>
																<td>Flexion</td>
																<td>0-120</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_flexion_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_flexion_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_flexion_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_flexion_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.hip_flexion_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_flexion_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_flexion_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!-- Extension -->
															<tr>
																<td>Extension</td>
																<td>0-20</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_extension_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_extension_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_extension_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_extension_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.hip_extension_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_extension_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_extension_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!-- Abduction -->
															<tr>
																<td>Abduction</td>
																<td>0-45</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_abduction_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_abduction_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_abduction_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_abduction_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.hip_abduction_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_abduction_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_abduction_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!--Adduction-->
															<tr>
																<td>Adduction</td>
																<td>0-20</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_adduction_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_adduction_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_adduction_active_r== 1? 'Yes': '-' ?? '-' }}" />
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_adduction_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.hip_adduction_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_adduction_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_adduction_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!--Internal Rotation-->
															<tr>
																<td>Internal Rotation</td>
																<td>0-45</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_internal_rotation_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_internal_rotation_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_internal_rotation_active_r== 1? 'Yes': '-' ?? '-' }}" />
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_internal_rotation_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.hip_internal_rotation_comment ?? '-' }}" />
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_internal_rotation_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_internal_rotation_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!--External Rotation-->
															<tr>
																<td>External Rotation</td>
																<td>0-45</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_external_rotation_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_external_rotation_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_external_rotation_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_external_rotation_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.hip_external_rotation_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_external_rotation_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.hip_external_rotation_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!-- KNEE ROW -->
															<!-- Flexion -->
															<tr>
																<td scope="row" rowspan="2"> <label
																		class=" px-0">Knee</label></td>
																<td>Flexion</td>
																<td>0-135</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.knee_flexion_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.knee_flexion_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.knee_flexion_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.knee_flexion_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.knee_flexion_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.knee_flexion_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.knee_flexion_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!-- Extension -->
															<tr>
																<td>Extension</td>
																<td>135-0</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.knee_extension_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.knee_extension_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.knee_extension_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.knee_extension_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.knee_extension_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.knee_extension_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.knee_extension_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
															</tr>

															<!-- ANKLE ROW -->
															<!-- PF -->
															<tr>
																<td scope="row" rowspan="6"> <label
																		class=" px-0">Ankle</label></td>
																<td>PF</td>
																<td>0-45</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.ankle_pf_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.ankle_pf_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.ankle_pf_active_r== 1? 'Yes': '-' ?? '-' }}" />
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.ankle_pf_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.ankle_pf_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.ankle_pf_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.ankle_pf_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!-- DF -->
															<tr>
																<td>DF</td>
																<td>0-20</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.ankle_df_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.ankle_df_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.ankle_df_active_r== 1? 'Yes': '-' ?? '-' }}" />
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.ankle_df_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.ankle_df_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.ankle_df_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.ankle_df_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
															</tr>

															<!-- Inversion -->
															<tr>
																<td>Inversion</td>
																<td>0-35</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.knee_inversion_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.knee_inversion_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.knee_inversion_active_r== 1? 'Yes': '-' ?? '-' }}" />
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.knee_inversion_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.knee_inversion_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.knee_inversion_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.knee_inversion_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>

															</tr>

															<!-- Eversion -->
															<tr>
																<td>Eversion</td>
																<td>0-15</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.knee_eversion_passive_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.knee_eversion_passive_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.knee_eversion_active_r== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.knee_eversion_active_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{ item?.joint_muscles?.knee_eversion_comment ?? '-' }}" />
																</td>
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.knee_eversion_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
																<td>
																	<input type="text" readonly class="form-control"
																		value="{{item?.joint_muscles?.knee_eversion_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
																</td>
															</tr>
														</tbody>
													</table>
												</div>
												<div class="row">
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Head Control:
															</label>
															<input type="text" readonly class="form-control"
																value="{{item?.head_control?? '-' }}" />
														</div>
													</div>
													<div class="col-xl-4 col-lg-12 col-md-12">
														<div class="form-group">
															<label>Trunk Control:</label>
															<input type="text" readonly class="form-control"
																value="{{item?.trunk_control ?? '-' }}" />
														</div>
													</div>
												</div>
											</div>
											
											<!-- Mobility and Balance -->


											<div class="row">
												<h3 class="fw-bold">Mobility and Balance</h3>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>I - Independent</label>
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>A - Assistance</label>
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>D - Dependent</label>
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>N - Not Tested</label>
													</div>
												</div>
											</div>
											<div class="row">
												<label class="fw-bold">Rolling</label>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Independent</label>
														<input type="text" readonly class="form-control"
														value="{{ item?.mobility_balance?.rolling_I == 1 ?'Independent' :  '-' }}" >
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Assistance</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.rolling_I == 2 ? 'Assistance': '-' }}">
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Dependent</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.rolling_I == 3 ? 'Dependent' :  '-' }}">
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Not Tested</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.rolling_I == 4 ? 'Not tested' : '-' }}">
													</div>
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Remarks</label>
													<input type="text" readonly class="form-control"
														value="{{item?.mobility_balance?.rolling_remark?? '-'}}">
												</div>
											</div>
											<div class="row">
												<label class="fw-bold">Sitting</label>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Independent</label>
														<input type="text" readonly class="form-control"
														value="{{ item?.mobility_balance?.sitting_I == 1 ?'Independent' :  '-' }}" >
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Assistance</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.sitting_I == 2 ? 'Assistance' :  '-' }}">
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Dependent</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.sitting_I == 3 ? 'Dependent' :  '-' }}">
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Not Tested</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.sitting_I == 4 ? 'Not Tested' :  '-' }}">
													</div>
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Remarks</label>
													<input type="text" readonly class="form-control"
														value="{{item?.mobility_balance?.sitting_remark?? '-'}}">
												</div>
											</div>
											<div class="row">
												<h3 class="fw-bold">Standing</h3>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Independent</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.standing_I == 1 ? 'Independent':  '-' }}">
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Assistance</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.standing_I == 2 ? 'Assistance':  '-' }}">
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Dependent</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.standing_I == 3 ? 'Dependent' :  '-' }}">
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Not Tested</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.standing_I == 4 ? 'Not tested' :  '-' }}">
													</div>
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Remarks</label>
													<input type="text" readonly class="form-control"
														value="{{item?.mobility_balance?.standing_remark?? '-'}}">
												</div>
											</div>
											<div class="row">
												<h3 class="fw-bold">Gait</h3>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Independent</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.gait_I == 1 ? 'Independent' :  '-' }}">
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Assistance</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.gait_I == 2 ? 'Assistance' :  '-' }}">
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Dependent</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.gait_I == 3 ? 'Dependent' :  '-' }}">
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Not Tested</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.gait_I == 4 ? 'Not tested' :  '-' }}">
													</div>
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Remarks</label>
													<input type="text" readonly class="form-control"
														value="{{item?.mobility_balance?.gait_remark?? '-'}}">
												</div>
											</div>
											<div class="row">
												<h3 class="fw-bold">Balance</h3>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Independent</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.balance_I == 1 ? 'Independent' :  '-' }}">
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Assistance</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.balance_I == 2 ? 'Assistance' :  '-' }}">
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Dependent</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.balance_I == 3 ? 'Dependent' :  '-' }}">
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Not Tested</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.balance_I == 4 ? 'Not Tested' :  '-' }}">
													</div>
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Remarks</label>
													<input type="text" readonly class="form-control"
														value="{{item?.mobility_balance?.balance_remark??'-'}}">
												</div>
											</div>
											<div class="row">
												<h3 class="fw-bold">Static Sitting</h3>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Independent</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.static_sitting_I == 1 ? 'Independent' :  '-' }}">
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Assistance</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.static_sitting_I == 2 ? 'Assistance' :  '-' }}">
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Dependent</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.static_sitting_I == 3 ? 'Dependent' :  '-' }}">
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Not Tested</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.static_sitting_I == 4 ? 'Not tested' :  '-' }}">
													</div>
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Remarks</label>
													<input type="text" readonly class="form-control"
														value="{{item?.mobility_balance?.static_sitting_remark??'-'}}">
												</div>
											</div>
											<div class="row">
												<h3 class="fw-bold">Static Standing</h3>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Independent</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.static_standing_I == 1 ? 'Independent' :  '-' }}">
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Assistance</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.static_standing_I == 2 ? 'Assistance' :  '-' }}">
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Dependent</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.static_standing_I == 3 ? 'Dependent' :  '-' }}">
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Not Tested</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.static_standing_I == 4 ? 'Not Tested' :  '-' }}">
													</div>
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Remarks</label>
													<input type="text" readonly class="form-control"
														value="{{item?.mobility_balance?.static_standing_remark??'-'}}">
												</div>
											</div>
											<div class="row">
												<h3 class="fw-bold">Dynamic Sitting</h3>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Independent</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.dynamic_sitting_I == 1 ? 'Independent':  '-' }}">
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Assistance</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.dynamic_sitting_I == 2 ? 'Assistance' :  '-' }}">
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Dependent</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.dynamic_sitting_I == 3 ? 'Dependent' :  '-' }}">
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Not Tested</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.dynamic_sitting_I == 4 ? 'Not tested' :  '-' }}">
													</div>
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Remarks</label>
													<input type="text" readonly class="form-control"
														value="{{item?.mobility_balance?.dynamic_sitting_remark?? '-'}}">
												</div>
											</div>
											<div class="row">
												<h3 class="fw-bold">Dynamic Standing</h3>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Independent</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.dynamic_standing_I == 1 ? 'Independent' :'-' }}">
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Assistance</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.dynamic_standing_I == 2 ? 'Assistance':  '-' }}">
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Dependent</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.dynamic_standing_I == 3 ? 'Dependent' :  '-' }}">
													</div>
												</div>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>Not Tested</label>
														<input type="text" readonly class="form-control"
														value="{{  item?.mobility_balance?.dynamic_standing_I == 4 ? 'Not tested' :  '-' }}">
													</div>
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label>Remarks</label>
													<input type="text" readonly class="form-control"
														value="{{item?.mobility_balance?.dynamic_standing_remark?? '-'}}">
												</div>
											</div>

											<!-- Transfer and Assisting Devices -->

											<div class="row">
												<h3 class="fw-bold">
													Transfer and Assisting Devices
												</h3>
												<div class="col-xl-3 col-lg-6 col-md-6">
													<div class="form-group">
														<label>None</label>
														<input type="text" readonly class="form-control"
															value="{{item?.transfer_assisting?.transfer_assisting_none==1?'Yes' : '-'}}">
													</div>
												</div>
											</div>
											<div class="row">
												<!-- <h3 class="fw-bold">Crutches</h3> -->
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Crutches</label>
														<input type="text" readonly class="form-control"
															value="{{item?.transfer_assisting?.transfer_assisting_none==2?'Yes' : '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>One/Pair</label>
														<input type="text" readonly class="form-control"
															value="{{item?.transfer_assisting?.transfer_assisting_pair??  '-'}}">
													</div>
												</div>
												<!-- <div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Pair</label>
														<input type="text" readonly class="form-control"
															value="{{item?.transfer_assisting?.transfer_assisting_pair?'Yes' : '-'}}">
													</div>
												</div> -->
											</div>
											<div class="row">
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Walker</label>
														<input type="text" readonly class="form-control"
															value="{{item?.transfer_assisting?.transfer_assisting_none==3?'Yes' : '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Wheel Chair</label>
														<input type="text" readonly class="form-control"
															value="{{item?.transfer_assisting?.transfer_assisting_none==4?'Yes' : '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Other</label>
														<input type="text" readonly class="form-control"
															value="{{item?.transfer_assisting?.transfer_assisting_none==5?'Yes': '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Other remark</label>
														<input type="text" readonly class="form-control"
															value="{{item?.transfer_assisting?.transfer_assisting_others_description?? '-'}}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label>Problems Noted</label>
														<input type="text" readonly class="form-control"
															value="{{item?.transfer_assisting?.problems_noted?? '-'}}">
													</div>
												</div>
												<h3 class="fw-bold">Session Goals</h3>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Short Term(1 Month)</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.transfer_assisting?.short_term ?'Yes': '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Short term session</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.transfer_assisting?.short_term_session ?? '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Long Term(3-6 Month)</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.transfer_assisting?.long_term ?'Yes': '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Long term session</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.transfer_assisting?.long_term_session ?? '-' }}">
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Recommended Duration</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.transfer_assisting?.recommended_duration ?? '-' }}">
													</div>
												</div>


												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">No. of PT Sessions per Month</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.transfer_assisting?.scope_session ?? '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Goals to be Acheived</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.transfer_assisting?. goals_acheived?? '-' }}">
													</div>
												</div>
											</div>


											<!-- Sessions Technique -->
											<h3 class="fw-bold">Sessions Technique:</h3>
											<div class="row">
												<!-- <label class="fw bold">Exercise(ROM)</label> -->
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Exercise(ROM):</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.exercise_checkbox ?'Yes': '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Duration</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.exercise_duration ?? '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Details</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.exercise_details ?? '-' }}">
													</div>
												</div>
											</div>
											<div class="row">
												<!-- <label class="fw bold">TENS</label> -->
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">TENS:</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.tens_checkbox ?'Yes': '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Duration</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.tens_duration ?? '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Details</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.tens_details ?? '-' }}">
													</div>
												</div>
											</div>
											<div class="row">
												<!-- <label class="fw bold">Altrasound</label> -->
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Ultrasound:</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.ultrasound_checkbox ?'Yes': '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Duration</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.ultrasound_duration ?? '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Details</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.ultrasound_details ?? '-' }}">
													</div>
												</div>
											</div>
											<div class="row">
												<!-- <label class="fw bold">Hot Packs</label> -->
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Hot Packs:</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.hot_packs_checkbox ?'Yes': '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Duration</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.hot_packs_duration ?? '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Details</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.hot_packs_details ?? '-' }}">
													</div>
												</div>
											</div>
											<div class="row">
												<!-- <label class="fw bold">Stimulation</label> -->

												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Stimulation:</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.stimulation_checkbox ?'Yes': '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Duration</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.stimulation_duration ?? '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Details</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.stimulation_details ?? '-' }}">
													</div>
												</div>
											</div>
											<div class="row">
												<!-- <label class="fw bold">Vibrator</label> -->

												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Vibrator:</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.vibrator_checkbox ?'Yes': '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Duration</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.vibrator_duration ?? '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Details</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.vibrator_details ?? '-' }}">
													</div>
												</div>

											</div>
											<div class="row">
												<!-- <label class="fw bold">Tapping</label> -->
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Tapping:</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.tapping_checkbox ?'Yes': '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Duration</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.tapping_duration ?? '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Details</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.tapping_details ?? '-' }}">
													</div>
												</div>
											</div>
											<div class="row">
												<!-- <label class="fw bold">Chest Therapy</label> -->
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Chest Therapy:</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.chest_therapy_checkbox ?'Yes': '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Duration</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.chest_therapy_duration?? '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Details</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.chest_therapy_details ?? '-' }}">
													</div>
												</div>
											</div>
											<div class="row">
												<!-- <label class="fw bold">Heat and Cold therapy</label> -->
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Heat and Cold therapy:</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.heat_and_Cold_therapy_checkbox ?'Yes': '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Duration</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.heat_and_Cold_therapy_duration ?? '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Details</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.heat_and_Cold_therapy_details ?? '-' }}">
													</div>
												</div>
											</div>
											<div class="row">
												<!-- <label class="fw bold">Electrotherapy (TENS,NMES)</label> -->
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Electrotherapy (TENS,NMES):</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.electrotherapy_checkbox ?'Yes': '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Duration</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.electrotherapy_duration ?? '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Details</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique ?.electrotherapy_details?? '-' }}">
													</div>
												</div>
											</div>
											<div class="row">
												<!-- <label class="fw bold">Excercises</label> -->
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Excercises:</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.excercises_checkbox ?'Yes': '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-122">
													<div class="form-group">
														<label class="px-0">Duration</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.excercises_duration ?? '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Details</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.excercises_details ?? '-' }}">
													</div>
												</div>
											</div>
											<div class="row">
												<!-- <label class="fw bold">Massage and manual techniques</label> -->
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Massage and manual techniques:</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.manual_techniques_checkbox ?'Yes': '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Duration</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.manual_techniques_duration ?? '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Details</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.manual_techniques_details ?? '-' }}">
													</div>
												</div>
											</div>

											<div class="row">
												<!-- <label class="fw bold">Breathing Relaxation techniques</label> -->
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Breathing Relaxation techniques:</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.breathing_techniques_checkbox ?'Yes': '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-122">
													<div class="form-group">
														<label class="px-0">Duration</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.breathing_techniques_duration ?? '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Details</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.breathing_techniques_details ?? '-' }}">
													</div>
												</div>
											</div>
											<div class="row">
												<!-- <label class="fw bold">Body Mechanics</label> -->
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Body Mechanics:</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.body_mechanics_checkbox ?'Yes': '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Duration</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.body_mechanics_duration ?? '-' }}">
													</div>
												</div>
												<div class="col-xl-4 col-lg-12 col-md-12">
													<div class="form-group">
														<label class="px-0">Details</label>
														<input type="text" readonly class="form-control"
															value="{{ item?.session_technique?.body_mechanics_details ?? '-' }}">
													</div>
												</div>
											</div>
											<div class="col-xl-4 col-lg-12 col-md-12">
												<div class="form-group">
													<label class="px-0">Re-assessment Date</label>
													<input type="text" readonly class="form-control"
														*ngIf=" item?.session_technique?.re_date; else dash"
														value="{{  item?.session_technique?.re_date | date:'dd-MM-yyyy' }}" />

													<ng-template #dash>
														<input type="text" readonly class="form-control" value="-" />
													</ng-template>
													<!-- <input type="text" readonly class="form-control"
														value="{{ item?.session_technique?.re_date| date:'shortDate'  ?? '-' }}"> -->
												</div>
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>

				</ng-container>
				<ng-template pTemplate="footer">
					<p-button (click)="displayMaximizable = false"  label="Ok"
						styleClass="p-button-text"></p-button>
				</ng-template>
			</p-dialog>
		</div>
	</div>
	<app-copyrights></app-copyrights>
</div>