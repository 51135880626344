<p-toast></p-toast>

<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">
  <app-dashboard-navbar></app-dashboard-navbar>
  <div class="dropdown profile-nav-item d-flex flex-column align-items-end">
    <a class="dropdown-toggle admin-btn" style="text-decoration: none;" role="button" data-bs-toggle="dropdown"
      aria-haspopup="true" aria-expanded="false">
      <div class="menu-profile">
        <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
        <span class="name">{{getname}}</span>
      </div>
    </a>
    <div class="dropdown-menu d-flex flex-column align-items-center">
      <div class="dropdown-header align">
        <div class="figure mb-3">
          <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
        </div>
        <div class="info text-center">
          <span class="name" style="white-space: break-spaces;">{{getname}}</span>
          <p class="mb-3 email">{{getRole}}</p>
        </div>
      </div>
      <!-- <div class="dropdown-body">
            <ul class="profile-nav p-0 pt-3">
                <li class="nav-item"><a routerLink="/dashboard-my-profile" class="nav-link"><i class='bx bx-user'></i> <span>Profile Dashboard</span></a></li>
            </ul>
        </div> -->
      <div class="dropdown-footer">
        <ul class="profile-nav">
          <li class="nav-item"><a class="nav-link" (click)="logout()"><i class='bx bx-log-out'></i> <span
                class="logout-text">Logout</span></a></li>
        </ul>
      </div>

    </div>
  </div>
  <!-- Breadcrumb Area -->
  <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
    <h1>{{ Content.title }}</h1>
    <ol class="breadcrumb">
      <li class="item"><a routerLink="/">Home</a></li>
      <li class="item">
        {{ Content.subtitle }}
      </li>
      <li class="item">{{ Content.title }}</li>
    </ol>


  </div>
  <!-- End Breadcrumb Area -->
  <!-- Start Form -->
  <form [formGroup]="zeroToThreeMonthsForm" (ngSubmit)="saveBabyPlanForm(formSubmitted)" #formSubmitted="ngForm">
    <!-- BABY DETAILS SECTION -->
    <div class="add-listings-box pb-2">
      <h3>Baby Details</h3>
      <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
          <div class="form-group">
            <label class="px-0">Name<span class="required">*</span> </label>
            <div class="card flex justify-content-center">
              <p-dropdown [options]="babyDetails" (onChange)="getBabyDetails($event)" optionValue="id"
                optionLabel="name" [filter]="true" filterBy="name" formControlName="baby_details_id" readonly="true"
                placeholder="Select Baby's Name" [resetFilterOnHide]="true" [class.is-invalid]="(formSubmitted.submitted &&
                zeroToThreeMonthsForm.get('baby_details_id')?.invalid)
                ||
                zeroToThreeMonthsForm.get('baby_details_id')?.invalid
                &&
                zeroToThreeMonthsForm.get('baby_details_id').touched">
              </p-dropdown>
              <div class="invalid-feedback">Name is required</div>
            </div>
          </div>
        </div>
        <!-- Select Gender Start-->
        <div class="col-lg-4 col-sm-6 col-12">
          <div class="form-group">
            <label class="px-0">Gender </label>
            <div class="card flex justify-content-center">
              <!-- <p-dropdown optionLabel="name" optionValue="value" readonly
                [(ngModel)]="babyGender" [ngModelOptions]="{standalone: true}" placeholder="Select Baby's Gender"
                [resetFilterOnHide]="true" [readonly]="true">
              </p-dropdown> -->
              <input type="text" class="form-control" [(ngModel)]="babyGender" [ngModelOptions]="{standalone: true}"
                placeholder="Select Baby's Gender" [readonly]="true">
            </div>
          </div>
        </div>
        <!-- Select Gender End-->
        <!-- Select DOB Start-->
        <div class="col-lg-4 col-sm-6 col-12">
          <div class="form-group">
            <label class="px-0">Date of birth</label>
            <div class="card flex justify-content-center">
              <!-- <p-calendar readonly [(ngModel)]="selectedDate" [maxDate]="selectedDate"
                [ngModelOptions]="{standalone: true}" readonly [showIcon]="true" [readonlyInput]="true"
                placeholder="DD/MM/YYYY"></p-calendar> -->
              <input type="text" name="name" class="form-control" placeholder="DD/MM/YYYY" [value]="selectedDate"
                readonly />

            </div>
          </div>
        </div>
        <!-- Select DOB End-->
        <!-- Days, Months, Years Start-->
        <div class="col-lg-4 col-sm-6 col-12">
          <div class="form-group">
            <label class="px-0">Days</label>
            <input type="text" name="name" class="form-control" placeholder="Days" [value]="days" readonly />
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12">
          <div class="form-group">
            <label class="px-0">Months</label>
            <input type="text" name="name" class="form-control" placeholder="Months" [value]="month" readonly />
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12">
          <div class="form-group">
            <label class="px-0">Years</label>
            <input type="text" name="name" class="form-control" placeholder="Years" [value]="years" readonly />
          </div>
        </div>
        <!-- Days, Months, Years End-->
      </div>
    </div>
    <!-- new fields creation fielding Assesment -->
    <ng-container *ngIf="flag">
      <p-accordion>
        <p-accordionTab header="Feeding Assesment">
          <div class="add-listings-box">
            <h3>Assesment:</h3>
            <!-- Ques 1 START-->
            <div class="row">
              <div class="col-sm-6 col-12">
                <div class="form-group">
                  <label class="px-0">Baby is not interested when offered to breastfeed, sleepy.</label>
                  <p class="d-flex">
                    <input type="radio" id="interested-yes" value="Yes" formControlName="answer"
                      (change)="handleInput($event, 'interestedSleepy')">
                    <label for="interested-yes">Yes</label>
                  </p>
                  <p>
                    <input type="radio" id="interested-no" value="No" formControlName="answer"
                      (change)="handleInput($event, 'interestedSleepy')">
                    <label for="interested-no">No</label>
                  </p>

                </div>
              </div>
              <!-- Ques 1 END-->
              <!-- Ques 2 START-->

              <div class="col-sm-6 col-12">
                <div class="form-group">
                  <label class="px-0">Baby is showing feeding cues but not attaching.</label>

                  <p class="d-flex">
                    <input type="radio" id="feeding-clues-yes" value="Yes" formControlName="attach"
                      (change)="handleInput($event, 'cluesButNoAttachment')">
                    <label for="feeding-clues-yes">Yes</label>
                  </p>
                  <p>
                    <input type="radio" id="feeding-clues-no" value="No" formControlName="attach"
                      (change)="handleInput($event, 'cluesButNoAttachment')">
                    <label for="feeding-clues-no">No</label>
                  </p>
                </div>
              </div>
              <!-- Ques 2 END-->
              <!-- Ques 3 START-->

              <div class="col-sm-6 col-12">
                <div class="form-group">
                  <label class="px-0">Attaches at the breast but quickly falls asleep.</label>
                  <p class="d-flex">
                    <input type="radio" id="quickly-fall-yes" value="Yes" formControlName="Asleep"
                      (change)="handleInput($event, 'attachesButFallsAsleep')">
                    <label for="quickly-fall-yes">Yes</label>
                  </p>
                  <p>
                    <input type="radio" id="quickly-fall-no" value="No" formControlName="Asleep"
                      (change)="handleInput($event, 'attachesButFallsAsleep')">
                    <label for="quickly-fall-no">No</label>
                  </p>

                </div>
              </div>

              <!-- Ques 3 END-->
              <!-- Ques 4 START-->

              <div class="col-sm-6 col-12">
                <div class="form-group">
                  <label class="px-0">Attaches for short bursts with long pause.</label>
                  <p class="d-flex">
                    <input type="radio" id="short-burst-yes" value="Yes" formControlName="LongPause"
                      (change)="handleInput($event, 'attachShortLongPause')">
                    <label for="short-burst-yes">Yes</label>
                  </p>
                  <p>
                    <input type="radio" id="short-burst-no" value="No" formControlName="LongPause"
                      (change)="handleInput($event, 'attachShortLongPause')">
                    <label for="short-burst-no">No</label>
                  </p>

                </div>

              </div>
              <!-- Ques 4 END-->
              <!-- Ques 5 START-->

              <div class="col-sm-6 col-12">
                <div class="form-group">
                  <label class="px-0">Attaches well with long rhythmical sucking and swallowing for
                    a short feed.</label>
                  <p class="d-flex">
                    <input type="radio" id="rhythmical-yes" value="Yes" formControlName="rhythmical"
                      (change)="handleInput($event, 'longRhythmShortFeed')">
                    <label for="rhythmical-yes">Yes</label>
                  </p>
                  <p>
                    <input type="radio" id="rhythmical-no" value="No" formControlName="rhythmical"
                      (change)="handleInput($event, 'longRhythmShortFeed')">
                    <label for="rhythmical-no">No</label>
                  </p>

                </div>


              </div>
              <!-- Ques 5 END-->
              <!-- Ques 6 START-->

              <div class="col-sm-6 col-12">
                <div class="form-group">
                  <label class="px-0">Attaches well for a sustained period with long rhythmical
                    sucking and swallowing.</label>
                  <p class="d-flex">
                    <input type="radio" id="sustain-period-yes" value="Yes" formControlName="swallowing"
                      (change)="handleInput($event, 'longRhythmSustainedPeriod')">
                    <label for="sustain-period-yes">Yes</label>
                  </p>
                  <p>
                    <input type="radio" id="sustain-period-no" value="No" formControlName="swallowing"
                      (change)="handleInput($event, 'longRhythmSustainedPeriod')">
                    <label for="sustain-period-no">No</label>
                  </p>

                </div>

              </div>
              <!-- Ques 6 END-->
              <!-- Ques 7 START-->

              <div class="col-sm-6 col-12">
                <div class="form-group">
                  <label class="px-0">Normal skin color and tone.</label>
                  <p class="d-flex">
                    <input type="radio" id="skin-color-yes" value="Yes" formControlName="Normal_skin"
                      (change)="handleInput($event, 'normalSkinAndTone')">
                    <label for="skin-color-yes">Yes</label>
                  </p>
                  <p>
                    <input type="radio" id="skin-color-no" value="No" formControlName="Normal_skin"
                      (change)="handleInput($event, 'normalSkinAndTone')">
                    <label for="skin-color-no">No</label>
                  </p>

                </div>



              </div>
              <!-- Ques 7 END-->
              <!-- Ques 8 START-->

              <div class="col-sm-6 col-12">
                <div class="form-group">
                  <label class="px-0">Gaining weight appropriately.</label>
                  <p class="d-flex">
                    <input type="radio" id="weight-yes" value="Yes" formControlName="weight"
                      (change)="handleInput($event, 'appropriateWeightGain')">
                    <label for="weight-yes">Yes</label>
                  </p>
                  <p>
                    <input type="radio" id="weight-no" value="No" formControlName="weight"
                      (change)="handleInput($event, 'appropriateWeightGain')">
                    <label for="weight-no">No</label>
                  </p>

                </div>
              </div>
            </div>
            <!-- Ques 8 END-->
            <!-- Other Concerns START-->
            <div class="row">
              <label class="subHeading mb-3">Other Concerns.</label>
              <div class="col-12">
                <div class="form-group d-flex justify-content-between">
                  <textarea name="identify" class="form-control" id="kt_autosize_1" rows="5"
                    formControlName="Other_concerns"></textarea>
                </div>
              </div>
            </div>
          </div>
        </p-accordionTab>
        <!-- BABY DETAILS SECTION END-->
        <!-- DAILY CARE SECTION-->

        <p-accordionTab header="Milk Feeding">
          <div class="add-listings-box pb-0">

            <h3>Daily Care</h3>
            <div class="subHeading mb-3">Feeding: <span class="fw-normal">Prepared By</span></div>
            <div class=" ">
              <div class="form-check checkbox-list p-0 mb-3">
                <div class="row">
                  <div class="col-auto">
                    <label class="checkbox checkbox-outline px-0" for="mother">
                      <input type="checkbox" id="mother" formControlName="feeding_prepared_mother" value="mother">
                      <span></span>
                      Mother
                    </label>
                  </div>
                  <div class="col-auto">
                    <label class="checkbox checkbox-outline px-0" for="nanny">
                      <input type="checkbox" formControlName="feeding_prepared_nanny" id="nanny" value="nanny">
                      <span></span>
                      Nurse
                    </label>
                    <!-- <input type="checkbox" formControlName="feeding_prepared_nanny" id="nanny"
                      value="nanny">
                    <label for="nanny" class="mb-0 mx-2">Nurse</label> -->
                  </div>
                  <div class="col-auto">

                    <label class="checkbox checkbox-outline px-0" for="nanny" for="other">
                      <input type="checkbox" id="other" formControlName="feeding_prepared_other" value="other">
                      <span></span>
                      Other
                    </label>
                    <!-- <input type="checkbox" id="other" formControlName="feeding_prepared_other"
                      value="other">
                    <label for="other" class="mb-0 mx-2">Other</label> -->
                  </div>
                </div>


                <!-- <div class="form-group">
                  <div class=" ">
                    <div class="form-check checkbox-list p-0">
                      <div class="form-check checkbox-list p-0">
                        <div class="row">
                          <div class="form-group col-6">
                            <div class=" mb-3 d-flex justify-content-between">
                             
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->

              </div>
            </div>
            <div class="row">
              <div class="form-group mb-0">
                <label class="subHeading px-0">Feeding </label>
                <div class=" ">
                  <div class="form-check checkbox-list p-0">
                    <div class="row">
                      <div class="col-md col-lg-2">
                        <label class="checkbox checkbox-outline px-0  mt-md-2">
                          <input type="checkbox" value="breastFeeding" name="checkboxes"
                            (change)="handleInput($event, 'feeding')" formControlName="feeding_breast" />
                          <span></span>
                          Breast
                        </label>
                      </div>
                      <div class="col-md-5">
                        <div class="form-group">
                          <label class="p-0">Breast frequency: </label>
                          <div class="card flex justify-content-center">
                            <p-dropdown [options]="BreastfrequencyList" [disabled]="feedingBreast"
                              (onChange)="addbreastfield()" formControlName="feeding_frequency_breast"
                              optionLabel="name" optionValue="value" [filter]="true" filterBy="value"
                              placeholder="Frequency" [resetFilterOnHide]="true" [class.is-invalid]="(formSubmitted.submitted &&
                                zeroToThreeMonthsForm.get('feeding_frequency_breast')?.invalid)
                                ||
                                zeroToThreeMonthsForm.get('feeding_frequency_breast')?.invalid
                                &&
                                zeroToThreeMonthsForm.get('feeding_frequency_breast').touched">
                            </p-dropdown>
                            <div class="invalid-feedback">Frequency is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-5">
                        <div class="form-group">
                          <label class="px-0">Remark</label>
                          <input type="text" name="name" class="form-control" placeholder="Remark"
                            formControlName="breast_remark" [disabled]="feedingBreast" />

                        </div>
                      </div>
                      <div *ngIf="isBreast" class="add-listings-box pt-3 mb-3">
                        <div class="row" formArrayName="breast_feeding">
                          <div *ngFor="let control of getbreastarray().controls; let i = index" [formGroupName]="i"
                            class="col-6 col-md-4 col-lg-4">
                            <!-- <div class="row"> -->
                            <!-- <div class="col-md-2">
                                  <div class="form-group">
                                    <div class="col-md col-lg-2 mt-3">
                                      <label class="checkbox checkbox-outline px-0  mt-md-2">
                                        <input type="checkbox" value="breastFeeding" name="checkboxes"
                                            formControlName="feeding_breast_checkbox" />
                                        Done?
                                      </label>
                                    </div>
                                  </div>
                                </div> -->
                            <!-- <div class="col-md-5"> -->
                            <div class="form-group">
                              <label class="p-0">Time: </label>
                              <div class="card flex justify-content-center">
                                <p-calendar placeholder="Start Time" formControlName="feeding_breast_time"
                                  [timeOnly]="true" [hourFormat]="12" inputId="time" [disabled]="feedingBreast"
                                  [class.is-invalid]="(formSubmitted.submitted &&
                                          zeroToThreeMonthsForm.get('feeding_breast_time')?.invalid)
                                          ||
                                            zeroToThreeMonthsForm.get('feeding_breast_time')?.invalid
                                          &&
                                            zeroToThreeMonthsForm.get('feeding_breast_time').touched">
                                </p-calendar>
                                <div class="invalid-feedback">Start Time is required</div>
                              </div>
                            </div>
                            <!-- </div> -->
                            <!-- <div class="col-md-5">
                                  <div class="form-group">
                                    <div class="card flex justify-content-center">
                                      <label class="px-0">Remark</label>
                                      <input type="text" name="remark" class="form-control" placeholder="Remark"
                                          id="remark" formControlName="breast_feeding_remark" />
                                    </div>
                                  </div>
                                </div> -->
                            <!-- </div> -->

                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-2 col-md-4">
                        <label class="checkbox checkbox-outline px-0  mt-md-2">
                          <input type="checkbox" value="formulaFeeding" name="checkboxes"
                            (change)="handleInput($event, 'feeding')" formControlName="feeding_type" />
                          <span></span>
                          Formula
                        </label>
                      </div>

                      <!-- Formula feeding dropdown -->
                      <div class="col-lg-4 col-md-4">
                        <div class="form-group">
                          <label class="p-0">Formula frequency (every hr​): </label>
                          <div class="card flex justify-content-center">
                            <p-dropdown [options]="frequency" formControlName="feeding_frequency"
                              [(ngModel)]="selectedFormulaFeeding" (ngModelChange)="createfields()"
                              [disabled]="feedingFormula" optionLabel="frequency" [filter]="true" filterBy="frequency"
                              placeholder="Frequency" [resetFilterOnHide]="true" [class.is-invalid]="(formSubmitted.submitted &&
                                  zeroToThreeMonthsForm.get('feeding_frequency')?.invalid)
                                  ||
                                  zeroToThreeMonthsForm.get('feeding_frequency')?.invalid
                                  &&
                                  zeroToThreeMonthsForm.get('feeding_frequency').touched">
                              <ng-template pTemplate="selectedItem">
                                <div class="flex align-items-center gap-2" *ngIf="selectedFormulaFeeding">
                                  <div>{{ selectedFormulaFeeding.frequency }}</div>
                                </div>
                              </ng-template>
                              <ng-template let-frequency pTemplate="item">
                                <div class="flex align-items-center gap-2">
                                  <div>{{ frequency.frequency }}</div>
                                </div>
                              </ng-template>
                            </p-dropdown>
                            <div class="invalid-feedback"> Frequency is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-4">
                        <div class="form-group">
                          <label class="p-0">Start Time:</label>
                          <div class="card flex justify-content-center">
                            <p-calendar placeholder="Time" formControlName="feeding_time" [timeOnly]="true"
                              [hourFormat]="12" inputId="time" (ngModelChange)="createfields()"
                              [disabled]="feedingFormula" [disabled]="feedingFormula" [class.is-invalid]="(formSubmitted.submitted &&
                                  zeroToThreeMonthsForm.get('feeding_time')?.invalid)
                                  ||
                                  zeroToThreeMonthsForm.get('feeding_time')?.invalid
                                  &&
                                  zeroToThreeMonthsForm.get('feeding_time').touched"></p-calendar>
                            <div class="invalid-feedback"> Time is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-4">
                        <div class="form-group">
                          <label class="p-0">End Time: </label>
                          <div class="card flex justify-content-center">
                            <p-calendar placeholder="Time" formControlName="feeding_breast_time_end" [timeOnly]="true"
                              [hourFormat]="12" (ngModelChange)="createfields()" inputId="time"
                              [disabled]="feedingFormula" [disabled]="feedingFormula" [class.is-invalid]="(formSubmitted.submitted &&
                                    zeroToThreeMonthsForm.get('feeding_breast_time_end')?.invalid)
                                    ||
                                    zeroToThreeMonthsForm.get('feeding_breast_time_end')?.invalid
                                    &&
                                    zeroToThreeMonthsForm.get('feeding_breast_time_end').touched">
                            </p-calendar>
                            <div class="invalid-feedback">End Time is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-2"></div>
                      <div class="col-lg-4 col-md-2">
                        <div class="form-group">
                          <label class="p-0">Amount (ml): </label>
                          <div class="card flex justify-content-center">
                            <input type="text" name="name" class="form-control" placeholder="Amount(ml)"
                              formControlName="feeding_amount_per" [class.is-invalid]="(formSubmitted.submitted &&
                              zeroToThreeMonthsForm.get('feeding_amount_per')?.invalid) 
                              ||
                              zeroToThreeMonthsForm.get('feeding_amount_per')?.invalid
                              &&
                              zeroToThreeMonthsForm.get('feeding_amount_per').touched" />
                            <div class="invalid-feedback">Amount is required</div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3 col-md-2">
                        <div class="form-group ml-0 col-12 p-0 mb-0">
                          <label class="p-0">Type of formula: </label>
                          <div class="col-12 p-0">
                            <input type="text" name="name" class="form-control" placeholder="Formula"
                              formControlName="type_of_formula" [class.is-invalid]="(formSubmitted.submitted &&
                                  zeroToThreeMonthsForm.get('type_of_formula')?.invalid)
                                  ||
                                  zeroToThreeMonthsForm.get('type_of_formula')?.invalid
                                  &&
                                  zeroToThreeMonthsForm.get('type_of_formula').touched" />
                            <div class="invalid-feedback">Type of formula is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-2">
                        <div class="form-group">
                          <label class="px-0">Remark</label>
                          <input type="text" name="formula_remark" class="form-control" placeholder="Remark"
                            formControlName="formula_remark" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- formula feeding feeding time  -->
            <div *ngIf="isFormula">
              <div class="row justify-content-end mx-0">
                <div class="add-listings-box pt-3 mb-3 col-lg-12">
                  <div formArrayName="formula_calculation" class="row">
                    <div *ngFor="let control of getFormulaCalculationControls().controls; let i = index"
                      [formGroupName]="i" class="col-lg-4">
                      <!-- <div class="row"> -->
                      <!-- <div class="col-md col-lg-2 mt-3">
                          <label class="checkbox checkbox-outline px-0  mt-md-2">
                            <input type="checkbox" value="formulaFeeding" name="checkboxes"
                                formControlName="feeding_checkbox" />
                            <span></span>
                            Done?
                          </label>
                        </div> -->
                      <!-- <div class="col-lg-3 col-sm-6 col-12"> -->
                      <div class="form-group">
                        <label class="px-0">Time</label>
                        <p-calendar placeholder="Time" formControlName="formula_timeArray" [hourFormat]="12"
                          [timeOnly]="true" inputId="time" disabled="true"></p-calendar>
                      </div>
                      <!-- </div> -->
                      <!-- <div class="col-lg-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="px-0">Time Feeding By Nurse</label>
                            <p-calendar placeholder="Actual Time" formControlName="nurse_feeding" [hourFormat]="12"
                                [timeOnly]="true" inputId="time"></p-calendar>
                          </div>
                        </div> -->
                      <!-- <div class="col-lg-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="px-0">Amount (ml)</label>
                            <input type="text" name="amount" class="form-control" placeholder="Amount"
                                formControlName="amount" />
                          </div>
                        </div> -->
                      <!-- <div class="col-lg-2"></div>
                        <div class="col-lg-3 col-sm-12 col-12">
                          <div class="form-group">
                            <label class="px-0">Name of formula</label>
                            <input type="text" name="remark" class="form-control" placeholder="Name of formula"
                                formControlName="remark" />
                          </div>
                        </div> -->
                      <!-- <div class="col-lg-3 col-sm-12 col-12">
                          <div class="form-group">
                            <label class="px-0">Remark</label>
                            <input type="text" name="remark" class="form-control" placeholder="Remark" id="remark"
                                formControlName="formula_remark" />
                          </div>
                        </div> -->
                      <!-- </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Bottle Sterilization Start-->
            <div class="row">
              <label class="subHeading mb-3">Bottle Sterilization </label>
              <div class="col-sm-4 col-12">
                <div class="form-group">
                  <div class=" ">
                    <div class="form-check checkbox-list p-0">
                      <div class=" mt-md-2">
                        <label class="checkbox checkbox-outline px-0">
                          <input type="checkbox" value="all" name="checkboxes"
                            (change)="handleInput($event, 'sterilization')"
                            formControlName="bottle_sterilization_all" />
                          All
                        </label>
                        <label class="checkbox checkbox-outline mt-sm-0 px-0">
                          <input type="checkbox" value="one" name="checkboxes"
                            (change)="handleInput($event, 'sterilization')"
                            formControlName="bottle_sterilization_onetime" />
                          One at a time
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Bottle Sterilization End-->

              <!-- Sterilization Time Start-->

              <div class="col-sm-4 col-12">
                <div class="form-group">
                  <label class="px-0">All Sterilization Time </label>
                  <div class="form-check checkbox-list p-0">
                    <p-calendar placeholder="Select Sterilization Time" [timeOnly]="true" [hourFormat]="12"
                      inputId="time" formControlName="bottle_sterilization_all_time" [disabled]="sterilizingAll"
                      [class.is-invalid]="(formSubmitted.submitted &&
                      zeroToThreeMonthsForm.get('bottle_sterilization_all_time')?.invalid)
                      ||
                      zeroToThreeMonthsForm.get('bottle_sterilization_all_time')?.invalid
                      &&
                      zeroToThreeMonthsForm.get('bottle_sterilization_all_time').touched">
                    </p-calendar>
                    <div class="invalid-feedback">All Sterilization Time is required</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-4 col-12">
                <div class="form-group">
                  <label class="px-0">One At a Time (Reference Time) </label>
                  <div class="form-check checkbox-list p-0">
                    <p-calendar placeholder="Select Sterilization Time" [timeOnly]="true" [hourFormat]="12"
                      inputId="time" formControlName="bottle_sterilization_one_at_a_time" [disabled]="sterilizingOne"
                      [class.is-invalid]="(formSubmitted.submitted &&
                      zeroToThreeMonthsForm.get('bottle_sterilization_one_at_a_time')?.invalid)
                      ||
                      zeroToThreeMonthsForm.get('bottle_sterilization_one_at_a_time')?.invalid
                      &&
                      zeroToThreeMonthsForm.get('bottle_sterilization_one_at_a_time').touched">
                    </p-calendar>
                    <div class="invalid-feedback">All Sterilization Time is required</div>
                  </div>
                </div>
              </div>
              <!-- Bottle Sterilization one at a time auto fill fields -->
              <div class="col-12">
                <div *ngIf="isFormula && !sterilizingOne">
                  <div class="row">
                    <div class="add-listings-box pt-3 mb-3">
                      <div formArrayName="bottle_sterilization" class="row">
                        <div *ngFor="let control of getbottle_sterilizationControls().controls; let i = index"
                          [formGroupName]="i" class="col-lg-4">
                          <!-- <div class="row">  -->
                          <!-- <div class="col-lg-2 col-md-2 mt-3">
                              <label class="checkbox checkbox-outline px-0  mt-md-2">
                                <input type="checkbox" value="true" name="checkboxes"
                                    formControlName="done_sterilization" />
                                <span></span>
                                Done?
                              </label>
                            </div> -->
                          <!-- <div class="col-lg-3 col-sm-6 col-12"> -->
                          <div class="form-group">
                            <label class="px-0">Time</label>
                            <p-calendar placeholder="Time" formControlName="sterilization_timeArray" [timeOnly]="true"
                              inputId="time" [hourFormat]="12" [disabled]="true"></p-calendar>
                          </div>
                          <!-- </div> -->
                          <!-- <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                <label class="px-0">Actual Time</label>
                                <p-calendar placeholder="Time" formControlName="actual_sterilization_timeArray"
                                    [timeOnly]="true" inputId="time" [hourFormat]="12"></p-calendar>
                              </div>
                            </div> -->
                          <!-- <div class="col-lg-4 col-sm-6 col-12">
                              <div class="form-group">
                                <label class="px-0">Remark</label>
                                <input type="text" name="name" class="form-control" placeholder="Remark"
                                    [formControl]="control.get('sterilization_remark')" />
                              </div>
                            </div> -->
                          <!-- </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-accordionTab>
        <!-- Sterilization Time End-->

        <!-- Food / Medicine Allergy Start-->

        <p-accordionTab header="Allergy">
          <div class="add-listings-box pb-0">
            <h3>Food / Medicine Allergy</h3>

            <div class="row">
              <!-- <label class="subHeading mb-sm-3 mb-0">Food / Medicine Allergy </label> -->
              <div class="col-lg-3 col-md-4">
                <div class="form-group mb-0">
                  <div class="form-check checkbox-list p-0">
                    <div>

                      <!-- <p class="d-flex"> -->
                      <!-- <input type="radio" id="allergy_yes" value="1" (change)="handleInput($event, 'fnmAllergy')"
                      formControlName="food_medicine_allergy" />
                    <label for="allergy_yes" class="mb-0 mx-2">Yes</label> -->
                      <div class="d-flex">
                        <p class="me-2">
                          <input type="radio" id="allergy_yes" value="1" formControlName="food_medicine_allergy"
                            (change)="handleInput($event, 'fnmAllergy')" name="food_medicine_allergy">
                          <label for="allergy_yes" class="mb-0 mx-2">Yes</label>
                        </p>
                        <p class="d-flex">
                          <input type="radio" id="allergy_no" value="0" formControlName="food_medicine_allergy"
                            (change)="handleInput($event, 'fnmAllergy')" name="food_medicine_allergy">
                          <label for="allergy_no" class="mb-0 mx-2">No</label>
                        </p>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <!-- <label class="p-0">Specify Product: </label> -->
                  <div class="col-12 p-0">
                    <textarea name="food_medicine_allergy_specify_prod" class="form-control"
                      placeholder="Specify Product" formControlName="food_medicine_allergy_specify_prod" rows="2"
                      [class.is-invalid]="(formSubmitted.submitted &&
                      zeroToThreeMonthsForm.get('food_medicine_allergy_specify_prod')?.invalid)
                      ||
                      zeroToThreeMonthsForm.get('food_medicine_allergy_specify_prod')?.invalid
                      &&
                      zeroToThreeMonthsForm.get('food_medicine_allergy_specify_prod').touched"></textarea>
                    <div class="invalid-feedback">Specify product is required</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-accordionTab>

        <!-- Food / Medicine Allergy End-->

        <!-- Diaper Change start  -->
        <p-accordionTab header="Diaper Change">
          
          <div class="add-listings-box pb-0">
            <div class="row">
              <div class="col-lg-2 col-md-4">
                <label class="checkbox checkbox-outline px-0  mt-md-2">
                  <input type="checkbox" value="formulaFeeding" name="checkboxes" formControlName="diaper_type" />
                  <span></span>
                  Diaper Formula
                </label>
              </div>

              <!-- Formula feeding dropdown -->
              <div class="col-lg-4 col-md-4">
                <div class="form-group">
                  <label class="p-0">Diaper frequency (every hr​): </label>
                  <div class="card flex justify-content-center">
                    <p-dropdown [options]="frequencyList" formControlName="diaper_change_frequency"
                      (ngModelChange)="creatediaperfields()" optionLabel="name" optionValue="value" [filter]="true"
                      filterBy="value" placeholder="Frequency" [resetFilterOnHide]="true" [class.is-invalid]="(formSubmitted.submitted &&
                        zeroToThreeMonthsForm.get('diaper_change_frequency')?.invalid)
                        ||
                        zeroToThreeMonthsForm.get('diaper_change_frequency')?.invalid
                        &&
                        zeroToThreeMonthsForm.get('diaper_change_frequency').touched">

                    </p-dropdown>
                    <div class="invalid-feedback"> Frequency is required</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4">
                <div class="form-group">
                  <label class="p-0">Start Time:</label>
                  <div class="card flex justify-content-center">
                    <p-calendar placeholder="Time" formControlName="diaper_label" [timeOnly]="true" [hourFormat]="12"
                      inputId="time" (ngModelChange)="creatediaperfields()" [class.is-invalid]="(formSubmitted.submitted &&
                        zeroToThreeMonthsForm.get('diaper_label')?.invalid)
                        ||
                        zeroToThreeMonthsForm.get('diaper_label')?.invalid
                        &&
                        zeroToThreeMonthsForm.get('diaper_label').touched"></p-calendar>
                    <div class="invalid-feedback"> Time is required</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4">
                <div class="form-group">
                  <label class="p-0">End Time: </label>
                  <div class="card flex justify-content-center">
                    <p-calendar placeholder="Time" formControlName="diaper_label_end_time" [timeOnly]="true"
                      [hourFormat]="12" (ngModelChange)="creatediaperfields()" inputId="time" [class.is-invalid]="(formSubmitted.submitted &&
                          zeroToThreeMonthsForm.get('diaper_label_end_time')?.invalid)
                          ||
                          zeroToThreeMonthsForm.get('diaper_label_end_time')?.invalid
                          &&
                          zeroToThreeMonthsForm.get('diaper_label_end_time').touched">
                    </p-calendar>
                    <div class="invalid-feedback">End Time is required</div>
                  </div>
                </div>
              </div>
              <!-- <div class="col-2"></div>
            <div class="col-lg-4 col-md-2">
              <div class="form-group">
                <label class="p-0">Amount (ml): </label>
                <div class="card flex justify-content-center">
                  <input type="text" name="name" class="form-control" placeholder="Amount(ml)"
                      formControlName="feeding_amount_per" [class.is-invalid]="(formSubmitted.submitted &&
                    zeroToThreeMonthsForm.get('feeding_amount_per')?.invalid) 
                    ||
                    zeroToThreeMonthsForm.get('feeding_amount_per')?.invalid
                    &&
                    zeroToThreeMonthsForm.get('feeding_amount_per').touched" />
                  <div class="invalid-feedback">Amount is required</div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-2">
              <div class="form-group ml-0 col-12 p-0 mb-0">
                <label class="p-0">Type of formula: </label>
                <div class="col-12 p-0">
                  <input type="text" name="name" class="form-control" placeholder="Formula"
                      formControlName="type_of_formula" [class.is-invalid]="(formSubmitted.submitted &&
                        zeroToThreeMonthsForm.get('type_of_formula')?.invalid)
                        ||
                        zeroToThreeMonthsForm.get('type_of_formula')?.invalid
                        &&
                        zeroToThreeMonthsForm.get('type_of_formula').touched" />
                  <div class="invalid-feedback">Type of formula is required</div>
                </div>
              </div>
            </div> -->
              <!-- <div class="col-lg-3 col-md-2">
              <div class="form-group">
                <label class="px-0">Remark</label>
                <input type="text" name="formula_remark" class="form-control" placeholder="Remark"
                    formControlName="formula_remark" />
              </div>
            </div> -->
            </div>
          </div>
          <div *ngIf="isdiaper">
            <div class="row justify-content-end mx-0">
              <div class="add-listings-box pt-3 mb-3 col-lg-12">
                <div formArrayName="diaper_calculation" class="row">
                  <div *ngFor="let control of getdiaperCalculationControls().controls; let i = index"
                    [formGroupName]="i" class="col-lg-4">
                    <!-- <div class="row"> -->
                    <!-- <div class="col-md col-lg-2 mt-3">
                        <label class="checkbox checkbox-outline px-0  mt-md-2">
                          <input type="checkbox" value="formulaFeeding" name="checkboxes"
                              formControlName="feeding_checkbox" />
                          <span></span>
                          Done?
                        </label>
                      </div> -->
                    <!-- <div class="col-lg-3 col-sm-6 col-12"> -->
                    <div class="form-group">
                      <label class="px-0">Time</label>
                      <p-calendar placeholder="Time" formControlName="diaper_timeArray" [hourFormat]="12"
                        [timeOnly]="true" inputId="time" disabled="true"></p-calendar>
                    </div>
                    <!-- </div> -->
                    <!-- <div class="col-lg-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label class="px-0">Time Feeding By Nurse</label>
                          <p-calendar placeholder="Actual Time" formControlName="nurse_feeding" [hourFormat]="12"
                              [timeOnly]="true" inputId="time"></p-calendar>
                        </div>
                      </div> -->
                    <!-- <div class="col-lg-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label class="px-0">Amount (ml)</label>
                          <input type="text" name="amount" class="form-control" placeholder="Amount"
                              formControlName="amount" />
                        </div>
                      </div> -->
                    <!-- <div class="col-lg-2"></div>
                      <div class="col-lg-3 col-sm-12 col-12">
                        <div class="form-group">
                          <label class="px-0">Name of formula</label>
                          <input type="text" name="remark" class="form-control" placeholder="Name of formula"
                              formControlName="remark" />
                        </div>
                      </div> -->
                    <!-- <div class="col-lg-3 col-sm-12 col-12">
                        <div class="form-group">
                          <label class="px-0">Remark</label>
                          <input type="text" name="remark" class="form-control" placeholder="Remark" id="remark"
                              formControlName="formula_remark" />
                        </div>
                      </div> -->
                    <!-- </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="add-listings-box p-0 border-0">
            <div class="row">
              <div class="col-sm-4 col-12">
                <div class="form-group">
                  <label class="px-0">Frequency </label>
                  <div class="form-check checkbox-list p-0">
                    <p-dropdown [options]="frequencyList" formControlName="diaper_change_frequency" optionLabel="name"
                        optionValue="value" [filter]="true" filterBy="value" placeholder="Frequency"
                        (ngModelChange)="DiaperChange($event)" [resetFilterOnHide]="true" [class.is-invalid]="(formSubmitted.submitted &&
                      zeroToThreeMonthsForm.get('diaper_change_frequency')?.invalid)
                      ||
                      zeroToThreeMonthsForm.get('diaper_change_frequency')?.invalid
                      &&
                      zeroToThreeMonthsForm.get('diaper_change_frequency').touched">
                    </p-dropdown>
                    <div class="invalid-feedback">Frequency is required</div>
                  </div>
                </div>
              </div>
             
              <div class="col-sm-8">
                <label for="" class="d-none d-md-block">&nbsp;</label>
                <div class="row" formArrayName="diaper_changes">
                  <div *ngFor="let control of getDiaperChangeControlsfrequency().controls; let i = index"
                      [formGroupName]="i" class="col-6 col-md-4 col-lg-3">
                    <div class="form-group">

                      <div class="form-check px-0 checkbox-list">
                        <label class="checkbox checkbox-outline px-0">
                        
                          <span></span>
                          {{ getTimeLabel(i) }}
                        </label>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
          
            </div>
          </div> -->

        </p-accordionTab>


        <!-- Bathing section start-->
        <!-- Spong bath -->
        <p-accordionTab header="Bathing">
          <div class="add-listings-box pb-0">
            <h3>Bathing </h3>
            <div class="row">
              <!-- <label class="subHeading mb-sm-4 mb-2">Bathing </label> -->
              <div class="form-group mb-2 col-md-3 col-lg-2 col-12 d-flex mt-md-2">
                <div class="form-check px-0 checkbox-list">
                  <label class="checkbox checkbox-outline px-0">
                    <input type="checkbox" value="Sponge" (change)="handleInput($event, 'bathing')"
                      formControlName="bathing_sponge" />
                    <span></span>
                    Sponge
                  </label>
                </div>
              </div>
              <!-- Sponge -->
              <div class="col">
                <div class="row">
                  <div class="col-md-6 form-group">
                    <label class="px-0">Frequency </label>
                    <div class="form-check checkbox-list p-0">
                      <p-dropdown [options]="frequencyList" (onChange)="addfield()"
                        formControlName="bathing_frequency_sponge" optionLabel="name" optionValue="value"
                        filterBy="value" placeholder="Frequency" [filter]="true" [resetFilterOnHide]="true"
                        [disabled]="Sponge" [class.is-invalid]="(formSubmitted.submitted &&
                          zeroToThreeMonthsForm.get('bathing_frequency_sponge')?.invalid)
                          ||
                          zeroToThreeMonthsForm.get('bathing_frequency_sponge')?.invalid
                          &&
                          zeroToThreeMonthsForm.get('bathing_frequency_sponge').touched">
                      </p-dropdown>
                      <div class="invalid-feedback">Frequency is required</div>
                    </div>
                  </div>
                </div>

                <div *ngIf="isBathing">
                  <div class="row" formArrayName="bathing_time">

                    <div *ngFor="let control of getbathingarray().controls; let i = index" [formGroupName]="i"
                      class="col-lg-4 col-sm-6 col-12">
                      <!-- <div class="col-md col-lg-2">
                        <div class="form-group">
                          <label class="checkbox checkbox-outline px-0  mt-md-2">
                            <input type="checkbox" value="breastFeeding" name="checkboxes"
                                formControlName="sponge_checkbox" />
                            Done?
                          </label>
                        </div>
                      </div> -->
                      <!-- <div class="col-lg-4 col-sm-6 col-12"> -->
                      <div class="form-group">
                        <label class="px-0">Time</label>
                        <div class="form-check checkbox-list p-0">
                          <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time"
                            formControlName="bathing_sponge_time" [disabled]="Sponge"></p-calendar>
                          <div class="invalid-feedback">Time is required</div>
                        </div>
                      </div>
                      <!-- </div> -->
                      <!-- <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group">
                          <label class="px-0">Remark</label>
                          <input type="text" name="name" class="form-control" placeholder="Remark"
                              [formControl]="control.get('spongeBathing_remark')" />
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <!-- Tub bath -->
            <div class="row">
              <div class="form-group mb-2 col-md-3 col-lg-2 col-12 d-flex mt-md-2">
                <div class="form-check px-0 checkbox-list">
                  <label class="checkbox checkbox-outline px-0">
                    <input type="checkbox" value="tubBath" (change)="handleInput($event, 'bathing')"
                      formControlName="bathing_tub" />
                    <span></span>
                    Tub Bath
                  </label>
                </div>
              </div>

              <div class="col">
                <div class="row">
                  <div class="form-group col-md-6">
                    <label class="px-0">Frequency </label>
                    <!-- {{selectedTubFrequency | json}} -->
                    <div class="form-check checkbox-list p-0">
                      <p-dropdown [options]="frequencyList" formControlName="bathing_tub_frequency" [filter]="true"
                        filterBy="value" placeholder="Frequency" optionLabel="name" optionValue="value"
                        (onChange)="addtubfield()" [resetFilterOnHide]="true" [disabled]="Tub" [class.is-invalid]="(formSubmitted.submitted &&
                    zeroToThreeMonthsForm.get('bathing_tub_frequency')?.invalid)
                    ||
                    zeroToThreeMonthsForm.get('bathing_tub_frequency')?.invalid
                    &&
                    zeroToThreeMonthsForm.get('bathing_tub_frequency').touched">


                      </p-dropdown>
                      <div class="invalid-feedback">Frequency is required</div>
                    </div>
                  </div>
                </div>



                <div *ngIf="isBathingtub">
                  <div class="row" formArrayName="tub_bathing">
                    <div *ngFor="let control of getbathingtubarray().controls; let i = index" [formGroupName]="i"
                      class="col-lg-4 col-sm-6 col-12">
                      <!-- <div class="form-group">
                        <div class="col-md col-lg-2">
                          <label class="checkbox checkbox-outline px-0  mt-md-2">
                            <input type="checkbox" value="true" name="checkboxes" formControlName="tub_checkbox" />
                            Time
                          </label>
                        </div>
                        <div class="form-check checkbox-list p-0">
                          <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time"
                              formControlName="bathing_tub_time"></p-calendar>
                          <div class="invalid-feedback">Time is required</div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="px-0">Remark</label>
                            <input type="text" name="name" class="form-control" placeholder="Remark"
                                [formControl]="control.get('tubBathing_remark')" />
                          </div>
                        </div>
                      </div> -->
                      <!-- <div class="col-md col-lg-2">
                        <div class="form-group">
                          <label class="checkbox checkbox-outline px-0  mt-md-2">
                            <input type="checkbox" value="breastFeeding" name="checkboxes"
                                formControlName="tub_checkbox" />
                            Done?
                          </label>
                        </div>
                      </div> -->
                      <!-- <div class="col-lg-4 col-sm-6 col-12"> -->
                      <div class="form-group">
                        <label class="px-0">Time</label>
                        <div class="form-check checkbox-list p-0">
                          <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time"
                            formControlName="bathing_tub_time" [disabled]="Tub"></p-calendar>
                          <div class="invalid-feedback">Time is required</div>
                        </div>
                      </div>
                      <!-- </div> -->
                      <!-- <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group">
                          <label class="px-0">Remark</label>
                          <input type="text" name="name" class="form-control" placeholder="Remark"
                              [formControl]="control.get('tubBathing_remark')" />
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </p-accordionTab>

        <!-- *************** -->
        <!-- <div class="row">
          <label class="subHeading mb-3">Bathing </label>
          <div class="col-lg-4 col-6">
            <div class="form-group">
              <div class=" ">
                <div class="form-check checkbox-list p-0">
                  <div class="row py-3">
                    <label class="checkbox checkbox-outline col-lg-5 col-sm-6 col-12">
                      <input type="checkbox" value="spongeBath" name="checkboxes"
                        (change)="handleInput($event, 'bathing')" formControlName="bathing_type" />
                      Sponge Bath
                    </label>

                    <label class="checkbox checkbox-outline col-lg-5 col-sm-6 col-12 mt-sm-0 mt-3">
                      <input type="checkbox" value="tubBath" name="checkboxes" (change)="handleInput($event, 'bathing')"
                        formControlName="bathing_type" />
                      Tub Bath
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <!-- <div class="row">
          <div class="col-lg-4 col-6">
            <div class="form-group">
              <label class="px-0">Frequency </label>
              <div class="form-check checkbox-list p-0">
                <p-dropdown [options]="frequency" [(ngModel)]="selectedBathFrequency"
                  formControlName="bathing_frequency" optionLabel="frequency" [filter]="true" filterBy="frequency"
                  placeholder="Frequency" [resetFilterOnHide]="true">
                  <ng-template pTemplate="selectedItem">
                    <div class="flex align-items-center gap-2" *ngIf="selectedBathFrequency">
                      <div>{{ selectedBathFrequency.frequency }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-frequency pTemplate="item">
                    <div class="flex align-items-center gap-2">
                      <div>{{ frequency.frequency }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div> -->

        <!-- <div class="col-lg-4 col-6">
            <div class="form-group">
              <label class="px-0">Time </label>
              <div class="form-check checkbox-list p-0">
                <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time"
                  formControlName="bathing_time"></p-calendar>
              </div>
            </div>
          </div>
        </div> -->
        <!-- Bathing section end-->

        <!-- Skin Care Section START-->
        <p-accordionTab header="Skin Care">
          <div class="add-listings-box p-0 border-0">
            <label class="subHeading mb-3">Skin Care </label>
            <div formArrayName="skin_care">
              <ng-container *ngFor="let control of getSkinFormArray().controls; index as i; last as last">
                <div class="row" [formGroupName]="i">
                  <div class="col-lg-4">
                    <div class="form-group mb-0">
                      <div class=" ">
                        <div class="form-check checkbox-list p-0">
                          <div class="">
                            <label class="checkbox checkbox-outline px-0">
                              <input type="checkbox" value name="checkboxes" (change)="handleInput($event, '')"
                                formControlName="skin_care_lotion" />
                              Lotion
                            </label>

                            <label class="checkbox checkbox-outline mt-sm-0 mt-3 px-0">
                              <input type="checkbox" value name="checkboxes"
                                (change)="handleInput($event, 'sterilization')" formControlName="skin_care_baby_oil" />
                              Baby Oil
                            </label>

                            <label class="checkbox checkbox-outline mt-sm-0 mt-3 px-0">
                              <input type="checkbox" value="sterilizeOne" name="checkboxes"
                                (change)="handleInput($event, 'sterilization')" formControlName="skin_care_others" />
                              Other
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg">
                    <div class="form-group">
                      <!-- <label class="p-0">Specifiy Product: </label> -->
                      <div class="col-12 p-0">
                        <textarea name="name" class="form-control" placeholder="Specify Product"
                          formControlName="skin_care_specify_produ" rows="2" [class.is-invalid]="(formSubmitted.submitted &&
                  zeroToThreeMonthsForm.get('skin_care_specify_produ')?.invalid)
                  ||
                  zeroToThreeMonthsForm.get('skin_care_specify_produ')?.invalid
                  &&
                  zeroToThreeMonthsForm.get('skin_care_specify_produ').touched"></textarea>
                        <div class="invalid-feedback">Time is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-auto d-flex mb-3 justify-content-end">
                    <p-button type="button" (click)="addSkinObject('add', i)" icon="bx bx-plus" class="secondary-btn"
                      iconPos="right"></p-button>
                    <div class="remove">
                      <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2" icon="bx bx-minus"
                        (click)="addSkinObject('delete', i)" iconPos="right"></p-button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <label class="subHeading mb-3">Wipes </label>
            <div class="row">
              <div class="col-lg-4">
                <div class="form-group mb-0">
                  <div class="d-flex">
                    <p class="me-3">
                      <input type="radio" id="wipes_yes" value="1" (change)="handleInput($event, 'wipes')"
                        formControlName="skin_care_Wipes" />
                      <label for="wipes_yes" class="mb-0 mx-2">Yes</label>
                    </p>
                    <p class="d-flex">
                      <input type="radio" id="wipes_no" value="0" (change)="handleInput($event, 'wipes')"
                        formControlName="skin_care_Wipes" />
                      <label for="wipes_no" class="mb-0 mx-2">No</label>
                    </p>
                  </div>
                </div>

                <!-- *********** -->
                <!-- <div class="form-group">
              <div class=" ">
                <div class="form-check checkbox-list p-0 mt-2">
                  <label class="checkbox checkbox-outline">
                    <input type="checkbox" value="Yes" name="checkboxes" (change)="handleInput($event, 'wipes')"
                      formControlName="skin_care_Wipes" />
                    Yes
                  </label>

                  <label class="checkbox checkbox-outline mt-sm-0 mt-3">
                    <input type="checkbox" value="No" name="checkboxes" (change)="handleInput($event, 'wipes')"
                      formControlName="skin_care_Wipes" />
                    No
                  </label>
                </div>
              </div>
            </div> -->
              </div>

              <div class="col-lg-8">
                <div class="form-group mb-0">
                  <!-- <label class="p-0">Special Instructions: </label> -->
                  <div class="col-12 p-0">
                    <textarea name="name" class="form-control" placeholder="Special Instructions"
                      formControlName="skin_care_special_instructions" rows="2" [class.is-invalid]="(formSubmitted.submitted &&
                        zeroToThreeMonthsForm.get('skin_care_special_instructions')?.invalid)
                        ||
                        zeroToThreeMonthsForm.get('skin_care_special_instructions')?.invalid
                        &&
                        zeroToThreeMonthsForm.get('skin_care_special_instructions').touched"></textarea>
                    <div class="invalid-feedback">Special Instructions is required</div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Skin Care Section END-->

            <!-- Changing of clothes section START-->
            <div class="row">
              <label class="subHeading mb-2 mt-2">Changing of Clothes </label>
              <!-- <div class="col-lg-4">
                <div class="form-group mb-0">
                  <label class="px-0">Frequency </label>
                  <div class="form-check checkbox-list p-0">
                    <p-dropdown [options]="frequency" [(ngModel)]="selectedClothesFrequency"
                        formControlName="changing_colthes_frequency" optionLabel="frequency" [filter]="true"
                        filterBy="frequency" placeholder="Frequency" [resetFilterOnHide]="true">
                      <ng-template pTemplate="selectedItem">
                        <div class="flex align-items-center gap-2" *ngIf="selectedClothesFrequency">
                          <div>{{ selectedClothesFrequency.frequency }}</div>
                        </div>
                      </ng-template>
                      <ng-template let-frequency pTemplate="item">
                        <div class="flex align-items-center gap-2">
                          <div>{{ frequency.frequency }}</div>
                        </div>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>
              </div> -->

              <div class="col">
                <div class="form-group">
                  <label class="px-0">Special Instructions: </label>
                  <div class="col-12 p-0">
                    <textarea name="name" class="form-control" placeholder="Special Instructions"
                      formControlName="changing_colthes_spiecal_instructions" rows="2"> </textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" formArrayName="changing_clothes">
              <ng-container *ngFor="let control of getClothesFormArray().controls; index as i; last as last">
                <div class="row" [formGroupName]="i">

                  <!-- <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                      <div class="form-check checkbox-list p-0">
                        <label class="checkbox checkbox-outline mt-sm-0 mt-3 px-0">
                          <input type="checkbox" value="withMother" name="checkboxes"
                              formControlName="changing_colthes" />
                          Changing Clothes
                        </label>
                      </div>
                    </div>
                  </div> -->

                  <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                      <label class="px-0">Changing Time </label>
                      <div class="form-check checkbox-list p-0">
                        <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time"
                          formControlName="Changing_time" [class.is-invalid]="(formSubmitted.submitted &&
                                     zeroToThreeMonthsForm.get('Changing_time')?.invalid) ||
                                    zeroToThreeMonthsForm.get('Changing_time')?.invalid &&
                                    zeroToThreeMonthsForm.get('Changing_time').touched">
                        </p-calendar>
                        <div class="invalid-feedback">Time is required</div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                      <label class="px-0">Remark</label>
                      <input type="text" name="name" class="form-control" placeholder="Remark"
                          [formControl]="control.get('clothe_remark')" />
                    </div>
                  </div> -->
                  <div class="col-auto d-flex mb-3 align-items-end">
                    <p-button type="button" icon="bx bx-plus" (click)="addClothesObject('add', i)" iconPos="right"
                      class=" secondary-btn mr-2"></p-button>
                    <div class="remove">
                      <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2" icon="bx bx-minus"
                        (click)="addClothesObject('delete', i)" iconPos="right">
                      </p-button>
                    </div>
                  </div>

                </div>
              </ng-container>
            </div>

          </div>
        </p-accordionTab>

        <!-- Changing of clothes section END-->

        <!-- Sleeping Section START-->
        <p-accordionTab header="Sleeping">
          <div class="add-listings-box pb-0">
            <h3>Sleeping</h3>
            <div class="row">
              <div class="row" formArrayName="sleeping_time">
                <ng-container *ngFor="let control of getSleepArray().controls; index as i; last as last">
                  <div class="row" [formGroupName]="i">
                    <!-- <label class="subHeading mb-3">Sleeping </label> -->
                    <div class="col-lg-4 col-sm-6 col-12">
                      <div class="form-group">
                        <div class="form-check checkbox-list p-0">
                          <!-- <div class="row py-3"> -->
                          <label class="checkbox checkbox-outline col-lg-5 col-sm-6 col-12 px-0 mt-sm-4 mt-0">
                            <!-- <input type="checkbox" value="withMother" name="checkboxes"
                                (change)="handleInput($event, 'sleepingWith')" formControlName="sleeping_mother" /> -->
                            With Mother
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12">
                      <div class="form-group">
                        <label class="px-0">Time </label>
                        <div class="form-check checkbox-list p-0">
                          <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time"
                            formControlName="sleeping_mother_time" [class.is-invalid]="(formSubmitted.submitted &&
                              zeroToThreeMonthsForm.get('sleeping_mother_time')?.invalid)
                              ||
                              zeroToThreeMonthsForm.get('sleeping_mother_time')?.invalid
                              &&
                              zeroToThreeMonthsForm.get('sleeping_mother_time').touched"></p-calendar>
                          <div class="invalid-feedback">Time is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-auto d-flex mb-3 align-items-end">
                      <p-button type="button" icon="bx bx-plus" (click)="addsleeptimeObject('add', i)" iconPos="right"
                        class=" secondary-btn mr-2"></p-button>
                      <div class="remove">
                        <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2" icon="bx bx-minus"
                          (click)="addsleeptimeObject('delete', i)" iconPos="right">
                        </p-button>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="row">
              <div class="row" formArrayName="nanny_time">
                <ng-container *ngFor="let control of getSleepnannyArray().controls; index as i; last as last">
                  <div class="row" [formGroupName]="i">
                    <div class="col-lg-4 col-sm-6 col-12">
                      <div class="form-group">
                        <div class="form-check checkbox-list p-0">
                          <label class="checkbox checkbox-outline col-lg-5 col-sm-6 col-12 mt-sm-4 mt-3 px-0">
                            <!-- <input type="checkbox" value="withNanny" name="checkboxes"
                                (change)="handleInput($event, 'sleepingWith')" formControlName="sleeping_nanny" /> -->
                            With Nanny
                          </label>
                          <!-- </div> -->
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12">
                      <div class="form-group">
                        <label class="px-0">Time </label>
                        <div class="form-check checkbox-list p-0">
                          <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time"
                            formControlName="sleeping_nanny_time" [class.is-invalid]="(formSubmitted.submitted &&
                            zeroToThreeMonthsForm.get('sleeping_nanny_time')?.invalid)
                            ||
                            zeroToThreeMonthsForm.get('sleeping_nanny_time')?.invalid
                            &&
                            zeroToThreeMonthsForm.get('sleeping_nanny_time').touched"></p-calendar>
                          <div class="invalid-feedback">Time is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-auto d-flex mb-3 align-items-end">
                      <p-button type="button" icon="bx bx-plus" (click)="addnannyObject('add', i)" iconPos="right"
                        class=" secondary-btn mr-2"></p-button>
                      <div class="remove">
                        <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2" icon="bx bx-minus"
                          (click)="addnannyObject('delete', i)" iconPos="right">
                        </p-button>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </p-accordionTab>

        <!-- Temperature -->
        <p-accordionTab header="Vitamins">
          <div class="add-listings-box">
            <!-- <div class="row">
              <label class="subHeading mb-3">Temperature Monitoring( In hours)</label>
              <div class="col-md">
                <div class="form-group">
                  <div class="form-check checkbox-list p-0">
                    <p-dropdown [options]="frequencyList" formControlName="temperature_monitoring_frequency"
                        optionLabel="frequency" [filter]="true" filterBy="value" placeholder="Frequency"
                        [resetFilterOnHide]="true" optionLabel="name" optionValue="value">
                    
                    </p-dropdown>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- Temperature Section End-->

            <!-- Vitamins & Medications Start-->
            <!-- Kindly ADD multiple selection of this Section-->
            <div class="row">
              <label class="subHeading mb-3">Vitamins/Medications</label>
              <div formArrayName="vitamins_medications_json">
                <ng-container *ngFor="let control of getVitaminFormArray().controls; index as i; last as last">
                  <div class="row" [formGroupName]="i">
                    <div class="col-md">
                      <div class="form-group">
                        <label class="px-0">Medications</label>
                        <div class="pl-0 checkbox-list">
                          <input type="text" formControlName="vitamin" class="form-control"
                            placeholder="Vitamins/ Medications" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md">
                      <div class="form-group">
                        <label class="px-0">Frequency</label>
                        <div class="card flex justify-content-center">
                          <p-dropdown id="vitamin_frequency" [options]="frequencyList" formControlName="frequency"
                            filterBy="value" [filter]="true" [resetFilterOnHide]="true" optionLabel="name"
                            optionValue="value" (onChange)="addmedicinefield(i)" placeholder="Select Frequency">
                          </p-dropdown>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="ismedicine">
                      <div class="row" formArrayName="medicine_time" class="row">
                        <div *ngFor="let control of getmedicinearray(i).controls; index as j" class="col-lg-4"
                          [formGroupName]="j">
                          <!-- <div class="row"> -->
                          <!-- <div class="col-md-3">
                                <label class="checkbox checkbox-outline col-lg-5 col-sm-6 col-12 mt-sm-4 mt-3 px-0">
                                  <input type="checkbox" value="withvitamin" name="checkboxes"
                                      formControlName="vitamin_checkbox" />
                                  Done
                                </label>
                                </div>
                              </div> -->
                          <div class="form-group">
                            <label class="px-0">Time</label>
                            <p-calendar placeholder="Time" formControlName="time" [timeOnly]="true" [hourFormat]="12"
                              inputId="time"></p-calendar>
                          </div>
                          <!-- <div class="col-md col-sm-6 col-12">
                                <div class="form-group">
                                  <label class="px-0">Remark</label>
                                  <input type="text" name="name" class="form-control" placeholder="Remark"
                                      [formControl]="control.get('vitamin_remark')" />
                                </div>
                              </div> -->
                          <!-- </div> -->

                        </div>
                      </div>
                    </div>
                    <div class="col-md-auto d-flex mb-3  align-items-end">
                      <p-button type="button" icon="bx bx-plus" (click)="addVitaminObject('add', i)" iconPos="right"
                        class="secondary-btn"></p-button>
                      <div class="remove">
                        <p-button type="button" *ngIf="i!= 0" class="ms-2 secondary-btn" icon="bx bx-minus"
                          (click)="addVitaminObject('delete', i)" iconPos="right"></p-button>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

            <!-- <div class="row">
              <label class="subHeading mb-3">Vaccinations</label>
              <div formArrayName="vaccinations_json">
                <ng-container *ngFor="let control of getVaccinationFormArray().controls; index as i; last as last">
                  <div class="row" [formGroupName]="i">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="px-0">Vaccinations</label>
                        <div class="pl-0 checkbox-list">
                          <input type="text" formControlName="vaccination" class="form-control"
                            placeholder="Vaccinations" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group mb-0">
                        <label class="px-0">Date of Administration</label>
                        <div class="card flex justify-content-center">
                          <p-calendar placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                            formControlName="date_administration" [showIcon]="true"></p-calendar>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 d-flex justify-content-end align-items-end mt-sm-0 mt-3">
                      <div class="form-group mb-0">
                        <div class="d-flex justify-content-end">
                          <p-button type="button" icon="bx bx-plus" (click)="addVaccinationObject('add', i)"
                            iconPos="right" class="secondary-btn  "></p-button>
                          <div class="remove">
                            <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2" icon="bx bx-minus"
                              (click)="addVaccinationObject('delete', i)" iconPos="right"></p-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div> -->
          </div>
        </p-accordionTab>

        <!-- Remarks -->
        <p-accordionTab header="Remarks">
          <div class="add-listings-box">
            <div class="row">
              <label class="subHeading mb-3">Remarks</label>
              <div class="col-12">
                <div class="form-group">
                  <div class="form-check checkbox-list p-0">
                    <textarea name="name" class="form-control" placeholder="Enter Remarks" formControlName="remarks"
                      rows="5"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>
      <!-- Add + Button for multiple selection -->
      <!-- Vaccinations End-->


    </ng-container>
    <div class="add-listings-btn" style="text-align: right;" *ngIf="checkPermission(9)">
      <button type="submit" [disabled]="!flag">Submit</button>
    </div>
    <!-- DAILY CARE SECTION END-->
  </form>
  <!-- End Form -->
  <app-copyrights></app-copyrights>
</div>