<p-toast></p-toast>

<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">
    <app-dashboard-navbar></app-dashboard-navbar>
    <div class="dropdown profile-nav-item d-flex flex-column align-items-end">
        <a class="dropdown-toggle admin-btn" style="text-decoration: none;" role="button" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <div class="menu-profile">
                <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
                <span class="name">{{getname}}</span>
            </div>
        </a>
        <div class="dropdown-menu d-flex flex-column align-items-center">
            <div class="dropdown-header align">
                <div class="figure mb-3">
                    <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
                </div>
                <div class="info text-center">
                    <span class="name" style="white-space: break-spaces;">{{getname}}</span>
                    <p class="mb-3 email">{{getRole}}</p>
                </div>
            </div>
            <!-- <div class="dropdown-body">
				<ul class="profile-nav p-0 pt-3">
					<li class="nav-item"><a routerLink="/dashboard-my-profile" class="nav-link"><i class='bx bx-user'></i> <span>Profile Dashboard</span></a></li>
				</ul>
			</div> -->
            <div class="dropdown-footer">
                <ul class="profile-nav">
                    <li class="nav-item"><a class="nav-link" (click)="logout()"><i class='bx bx-log-out'></i> <span
                                class="logout-text">Logout</span></a></li>
                </ul>
            </div>

        </div>
    </div>
    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
        <h1>{{ Content.title }}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item">
                {{ Content.subTitle }}
            </li>
            <li class="item">{{ Content.title }}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

    <!-- Start Form -->
    <form [formGroup]="extraActivities" (ngSubmit)="saveeExtraActivities(formSubmitted)" #formSubmitted="ngForm">
        <!-- BABY DETAILS SECTION -->
        <p-accordion class="mt-3">
            <p-accordionTab header="Child Safety Assesment">
                <div class="add-listings-box">
                    <h3>Baby Details</h3>
                    <div class="row">
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="px-0">Name<span class="required">*</span></label>
                                <div class="card flex justify-content-center">
                                    <p-dropdown [options]="babyDetails" (onChange)="getBabyDetails($event)"
                                        optionValue="id" optionLabel="name" [filter]="true" filterBy="name"
                                        formControlName="baby_details_id" placeholder="Select Baby's Name"
                                        [resetFilterOnHide]="true" [class.is-invalid]="(formSubmitted.submitted &&
                                extraActivities.get('baby_details_id')?.invalid)
                                ||
                                extraActivities.get('baby_details_id')?.invalid
                                &&
                                extraActivities.get('baby_details_id').touched">
                                    </p-dropdown>
                                    <div class="invalid-feedback">Name is required</div>
                                </div>
                            </div>
                        </div>
                        <!-- Select Gender Start-->
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="px-0">Gender </label>
                                <div class="card flex justify-content-center">
                                    <input type="text" class="form-control" [(ngModel)]="babyGender"
                                        [ngModelOptions]="{standalone: true}" placeholder="Select Baby's Gender"
                                        [readonly]="true">
                                </div>
                            </div>
                        </div>
                        <!-- Select Gender End-->
                        <!-- Select DOB Start-->
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="px-0">Date of birth</label>
                                <div class="card flex justify-content-center">
                                    <!-- <p-calendar readonly [(ngModel)]="selectedDate" [maxDate]="selectedDate"
                                [ngModelOptions]="{standalone: true}" readonly [showIcon]="true" [readonlyInput]="true"
                                placeholder="DD/MM/YYYY"></p-calendar> -->
                                    <input type="text" name="name" class="form-control" placeholder="DD/MM/YYYY"
                                        [value]="selectedDate" readonly />
                                </div>
                            </div>
                        </div>
                        <!-- Select DOB End-->
                        <!-- Days, Months, Years Start-->
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="px-0">Days</label>
                                <input type="text" name="name" class="form-control" placeholder="Days" [value]="days"
                                    readonly />
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="px-0">Months</label>
                                <input type="text" name="name" class="form-control" placeholder="Months" [value]="month"
                                    readonly />
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="px-0">Years</label>
                                <input type="text" name="name" class="form-control" placeholder="Years" [value]="years"
                                    readonly />
                            </div>
                        </div>
                        <!-- Days, Months, Years End-->
                    </div>
                </div>
                <p-accordion>
                    <p-accordionTab header="Child's Room">
                        <div class="add-listings-box">
                            <div formArrayName="childroom">
                                <div
                                    *ngFor="let control of getcontainer('childroom').controls; index as j; last as last">
                                    <div class="row" [formGroupName]="j">
                                        <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="p-0">Requires Changes?</label>
                                                <div class="d-flex">
                                                    <p class="me-3">
                                                        <input type="radio" id="requireChanges_yes_{{j}}"
                                                            formControlName="requireChanges_{{j}}" value="yes"
                                                            name="requireChanges_{{j}}" />
                                                        <label for="requireChanges_yes_{{j}}">Yes</label>
                                                    </p>
                                                    <p class="d-flex">
                                                        <input type="radio" id="requireChanges_no_{{j}}"
                                                            formControlName="requireChanges_{{j}}" value="no"
                                                            name="requireChanges_{{j}}" />
                                                        <label for="requireChanges_no_{{j}}">No</label>
                                                    </p>

                                                </div>
                                                <div>
                                                    <!-- <div>
                                                        Field is required
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="px-0">Identify Requirements?</label>
                                                <input type="text" formControlName="requirement" name="requirement"
                                                    class="form-control" placeholder="Enter Requirements" />
                                            </div>
                                        </div>

                                        <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="p-0">Any Loose Part Or Sharp Edges?</label>
                                                <div class="d-flex">
                                                    <p class="me-3">
                                                        <input type="radio" id="loosePart_yes_{{j}}"
                                                            formControlName="loosePart_{{j}}" value="yes"
                                                            name="loosePart_{{j}}" />
                                                        <label for="loosePart_yes_{{j}}">Yes</label>
                                                    </p>
                                                    <p class="d-flex">
                                                        <input type="radio" id="loosePart_no_{{j}}"
                                                            formControlName="loosePart_{{j}}" value="no"
                                                            name="loosePart_{{j}}" />
                                                        <label for="loosePart_no_{{j}}">No</label>
                                                    </p>
                                                </div>
                                                <div>
                                                    <!-- <div>
                                                        Field is required
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="px-0">Identify Parts?</label>
                                                <input type="text" formControlName="identifyParts" name="identifyParts"
                                                    class="form-control" placeholder="Enter Identify Parts" />
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="p-0">Washroom(Fall Risk)?</label>
                                                <div class="d-flex">

                                                    <p class="me-3">
                                                        <input type="radio" id="Washroom_fallRisk_yes_{{j}}"
                                                            formControlName="Washroom_fallRisk_{{j}}" value="yes"
                                                            name="Washroom_fallRisk_{{j}}" />
                                                        <label for="Washroom_fallRisk_yes_{{j}}">Yes</label>
                                                    </p>
                                                    <p class="d-flex">
                                                        <input type="radio" id="Washroom_fallRisk_no_{{j}}"
                                                            formControlName="Washroom_fallRisk_{{j}}" value="no"
                                                            name="Washroom_fallRisk_{{j}}" />
                                                        <label for="Washroom_fallRisk_no_{{j}}">No</label>
                                                    </p>
                                                </div>
                                                <div>
                                                    <!-- <div>
                                                        Field is required
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="px-0">Identify Risk</label>
                                                <input type="text" formControlName="washroom_risk" name="identifyParts"
                                                    class="form-control" placeholder="Enter Identify Risks" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 d-flex mb-3 justify-content-end">
                                        <p-button type="button" icon="bx bx-plus" (click)="addchildroom('add', j+1)"
                                            iconPos="right" class="secondary-btn mr-2"></p-button>

                                        <div class="remove">
                                            <p-button type="button" *ngIf="j!= 0" class="secondary-btn ml-2"
                                                icon="bx bx-minus" (click)="addchildroom('delete', j)"
                                                iconPos="right"></p-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-accordionTab>
                    <!-- Accessories -->
                    <p-accordionTab header="Accessories">
                        <div class="add-listings-box">
                            <div formArrayName="accessories">
                                <div
                                    *ngFor="let control of getcontainer('accessories').controls; index as j; last as last">
                                    <div class="row" [formGroupName]="j">
                                        <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="p-0">Baby Stroller Available?</label>
                                                <div class="d-flex">

                                                    <p class="me-3">
                                                        <input type="radio" id="baby_stroller_yes_{{j}}"
                                                            formControlName="baby_stroller_{{j}}" value="yes"
                                                            name="baby_stroller_{{j}}" />
                                                        <label for="baby_stroller_yes_{{j}}">Yes</label>
                                                    </p>
                                                    <p class="d-flex">
                                                        <input type="radio" id="baby_stroller_no_{{j}}"
                                                            formControlName="baby_stroller_{{j}}" value="no"
                                                            name="baby_stroller_{{j}}" />
                                                        <label for="baby_stroller_no_{{j}}">No</label>
                                                    </p>
                                                </div>
                                                <div>
                                                    <!-- <div>
                                                        Field is required
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="px-0">Remarks</label>
                                                <input type="text" formControlName="baby_stroller_remark"
                                                    name="baby_stroller_remark" class="form-control"
                                                    placeholder="Enter Remark" />
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="p-0">Toys</label>
                                                <div class="d-flex">
                                                    <p class="me-3">
                                                        <input type="radio" id="toys_yes_{{j}}"
                                                            formControlName="toys_{{j}}" value="yes"
                                                            name="toys_{{j}}" />
                                                        <label for="toys_yes_{{j}}">Yes</label>
                                                    </p>
                                                    <p class="d-flex">
                                                        <input type="radio" id="toys_no_{{j}}"
                                                            formControlName="toys_{{j}}" value="no" name="toys_{{j}}" />
                                                        <label for="toys_no_{{j}}">No</label>
                                                    </p>
                                                </div>
                                                <div>
                                                    <!-- <div>
                                                        Field is required
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div formArrayName="toys_remarks">
                                                <div *ngFor="let control of getremark(j).controls; index as i;">
                                                    <div class="row" [formGroupName]="i">
                                                        <div class="form-group">
                                                            <label class="px-0">Remark</label>
                                                            <input type="text" formControlName="toys_remark"
                                                                name="toys_remark" class="form-control"
                                                                placeholder="Enter Remark" />
                                                        </div>
                                                        <div class="col-12 d-flex mb-3 justify-content-end">
                                                            <p-button type="button" icon="bx bx-plus"
                                                                (click)="addremark('add',i,j)" iconPos="right"
                                                                class="secondary-btn mr-2"></p-button>
                                                            <div class="remove">
                                                                <p-button type="button" *ngIf="i!= 0"
                                                                    class="secondary-btn ml-2" icon="bx bx-minus"
                                                                    (click)="addremark('delete',i,j)"
                                                                    iconPos="right"></p-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="p-0">Child seat available in the car?</label>
                                                <div class="d-flex">
                                                    <p class="me-3">
                                                        <input type="radio" id="child_seat_yes_{{j}}"
                                                            formControlName="child_seat_{{j}}" value="yes"
                                                            name="child_seat_{{j}}" />
                                                        <label for="child_seat_yes_{{j}}">Yes</label>
                                                    </p>
                                                    <p class="d-flex">
                                                        <input type="radio" id="child_seat_no_{{j}}"
                                                            formControlName="child_seat_{{j}}" value="no"
                                                            name="child_seat_{{j}}" />
                                                        <label for="child_seat_no_{{j}}">No</label>
                                                    </p>
                                                </div>
                                                <div>
                                                    <!-- <div>
                                                        Field is required
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="px-0">Remark</label>
                                                <input type="text" formControlName="child_seat_remark"
                                                    name="child_seat_remark" class="form-control"
                                                    placeholder="Enter Remark" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 d-flex mb-3 justify-content-end">
                                        <p-button type="button" icon="bx bx-plus" (click)="addAccessories('add', j+1)"
                                            iconPos="right" class="secondary-btn mr-2"></p-button>
                                        <div class="remove">
                                            <p-button type="button" *ngIf="j!= 0" class="secondary-btn ml-2"
                                                icon="bx bx-minus" (click)="addAccessories('delete', j)"
                                                iconPos="right"></p-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-accordionTab>
                    <p-accordionTab header="Amenities">
                        <div class="add-listings-box">
                            <div formArrayName="amenities">
                                <div
                                    *ngFor="let control of getcontainer('amenities').controls; index as j; last as last">
                                    <div class="row" [formGroupName]="j">
                                        <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="p-0">Outdoor Playground</label>
                                                <div class="d-flex">
                                                    <p class="me-3">
                                                        <input type="radio" id="outdoor_playground_yes_{{j}}"
                                                            formControlName="outdoor_playground_{{j}}" value="yes"
                                                            name="outdoor_playground_{{j}}" />
                                                        <label for="outdoor_playground_yes_{{j}}">Yes</label>
                                                    </p>
                                                    <p class="d-flex">
                                                        <input type="radio" id="outdoor_playground_no_{{j}}"
                                                            formControlName="outdoor_playground_{{j}}" value="no"
                                                            name="outdoor_playground_{{j}}" />
                                                        <label for="outdoor_playground_no_{{j}}">No</label>
                                                    </p>
                                                </div>
                                                <div>
                                                    <!-- <div>
                                                        Field is required
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="px-0">Remarks</label>
                                                <input type="text" formControlName="playground_remark"
                                                    name="baby_stroller_remark" class="form-control"
                                                    placeholder="Enter Remark" />
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="p-0">Slides</label>
                                                <div class="d-flex">
                                                    <p class="me-3">
                                                        <input type="radio" id="slides_yes_{{j}}"
                                                            formControlName="slides_{{j}}" value="yes"
                                                            name="slides_{{j}}" />
                                                        <label for="slides_yes_{{j}}">Yes</label>
                                                    </p>
                                                    <p class="d-flex">
                                                        <input type="radio" id="slides_no_{{j}}"
                                                            formControlName="slides_{{j}}" value="no"
                                                            name="slides_{{j}}" />
                                                        <label for="slides_no_{{j}}">No</label>
                                                    </p>
                                                    <div>
                                                    </div>
                                                    <!-- <div>
                                                        Field is required
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="px-0">Remarks</label>
                                                <input type="text" formControlName="slides_remark" name="slides_remark"
                                                    class="form-control" placeholder="Enter Remark" />
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="p-0">Swimming Pool</label>
                                                <div class="d-flex">
                                                    <p class="me-3">
                                                        <input type="radio" id="swimming_pool_yes_{{j}}"
                                                            formControlName="swimming_pool_{{j}}" value="yes"
                                                            name="swimming_pool_{{j}}" />
                                                        <label for="swimming_pool_yes_{{j}}">Yes</label>
                                                    </p>
                                                    <p class="d-flex">
                                                        <input type="radio" id="swimming_pool_no_{{j}}"
                                                            formControlName="swimming_pool_{{j}}" value="no"
                                                            name="swimming_pool_{{j}}" />
                                                        <label for="swimming_pool_no_{{j}}">No</label>
                                                    </p>
                                                </div>
                                                <div>
                                                    <!-- <div>
                                                        Field is required
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="px-0">Remarks</label>
                                                <input type="text" formControlName="swimming_pool_remark"
                                                    name="baby_stroller_remark" class="form-control"
                                                    placeholder="Enter Remark" />
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="p-0">Play</label>
                                                <div class="d-flex">
                                                    <p class="me-3">
                                                        <input type="radio" id="play_yes_{{j}}"
                                                            formControlName="play_{{j}}" value="yes"
                                                            name="play_{{j}}" />
                                                        <label for="play_yes_{{j}}">Yes</label>
                                                    </p>
                                                    <p class="d-flex">
                                                        <input type="radio" id="play_no_{{j}}"
                                                            formControlName="play_{{j}}" value="no" name="play_{{j}}" />
                                                        <label for="play_no_{{j}}">No</label>
                                                    </p>
                                                    <div>
                                                    </div>
                                                    <!-- <div>
                                                        Field is required
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="px-0">Remarks</label>
                                                <input type="text" formControlName="play_remark" name="play_remark"
                                                    class="form-control" placeholder="Enter Remark" />
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="p-0">Tricycle</label>
                                                <div class="d-flex">
                                                    <p class="me-3">
                                                        <input type="radio" id="tricycle_yes_{{j}}"
                                                            formControlName="tricycle_{{j}}" value="yes"
                                                            name="tricycle_{{j}}" />
                                                        <label for="tricycle_yes_{{j}}">Yes</label>
                                                    </p>
                                                    <p class="d-flex">
                                                        <input type="radio" id="tricycle_no_{{j}}"
                                                            formControlName="tricycle_{{j}}" value="no"
                                                            name="tricycle_{{j}}" />
                                                        <label for="tricycle_no_{{j}}">No</label>
                                                    </p>
                                                    <div>
                                                    </div>
                                                    <!-- <div>
                                                        Field is required
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="px-0">Remarks</label>
                                                <input type="text" formControlName="tricycle_remark"
                                                    name="tricycle_remark" class="form-control"
                                                    placeholder="Enter Remark" />
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="p-0">Scooter</label>
                                                <div class="d-flex">
                                                    <p class="me-3">
                                                        <input type="radio" id="scooter_yes_{{j}}"
                                                            formControlName="scooter_{{j}}" value="yes"
                                                            name="scooter_{{j}}" />
                                                        <label for="scooter_yes_{{j}}">Yes</label>
                                                    </p>
                                                    <p class="d-flex">
                                                        <input type="radio" id="scooter_no_{{j}}"
                                                            formControlName="scooter_{{j}}" value="no"
                                                            name="scooter_{{j}}" />
                                                        <label for="scooter_no_{{j}}">No</label>
                                                    </p>
                                                    <div>
                                                    </div>
                                                    <!-- <div>
                                                        Field is required
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="px-0">Remarks</label>
                                                <input type="text" formControlName="scooter_remark"
                                                    name="scooter_remark" class="form-control"
                                                    placeholder="Enter Remark" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 d-flex mb-3 justify-content-end">
                                        <p-button type="button" icon="bx bx-plus" (click)="addAmenities('add', j+1)"
                                            iconPos="right" class="secondary-btn mr-2"></p-button>
                                        <div class="remove">
                                            <p-button type="button" *ngIf="j!= 0" class=" secondary-btn ml-2"
                                                icon="bx bx-minus" (click)="addAmenities('delete', j)"
                                                iconPos="right"></p-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-accordionTab>
                    <p-accordionTab header="Other">
                        <div class="add-listings-box">
                            <div formArrayName="others">
                                <div *ngFor="let control of getcontainer('others').controls; index as j; last as last">
                                    <div class="row" [formGroupName]="j">
                                        <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="p-0">Pets</label>
                                                <div class="d-flex">
                                                    <p class="me-3">
                                                        <input type="radio" id="pets_yes_{{j}}"
                                                            formControlName="pets_{{j}}" value="yes"
                                                            name="pets_{{j}}" />
                                                        <label for="pets_yes_{{j}}">Yes</label>
                                                    </p>
                                                    <p class="d-flex">
                                                        <input type="radio" id="pets_no_{{j}}"
                                                            formControlName="pets_{{j}}" value="no" name="pets_{{j}}" />
                                                        <label for="pets_no_{{j}}">No</label>
                                                    </p>
                                                </div>
                                                <div>
                                                    <!-- <div>
                                                        Field is required
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="px-0">Remarks</label>
                                                <input type="text" formControlName="pets_remark"
                                                    name="baby_stroller_remark" class="form-control"
                                                    placeholder="Enter Remark" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 d-flex mb-3 justify-content-end">
                                        <p-button type="button" icon="bx bx-plus" (click)="addothers('add', j+1)"
                                            iconPos="right" class="secondary-btn mr-2"></p-button>
                                        <div class="remove">
                                            <p-button type="button" *ngIf="j!= 0" class="secondary-btn ml-2"
                                                icon="bx bx-minus" (click)="addothers('delete', j)"
                                                iconPos="right"></p-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-accordionTab>
                </p-accordion>
            </p-accordionTab>
            <p-accordionTab header="Outdoor Activities">
                <div class="add-listings-box">
                    <div formArrayName="OutdoorActivitys">
                        <div *ngFor="let control of getcontainer('outdoorActivity').controls; index as j; last as last">
                            <div class="row" [formGroupName]="j">
                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label class="p-0">Park</label>
                                        <div class="d-flex">
                                            <p class="me-3">
                                                <input type="radio" id="park_yes_{{j}}" formControlName="park_{{j}}"
                                                    value="yes" name="park_{{j}}" />
                                                <label for="park_yes_{{j}}">Yes</label>
                                            </p>
                                            <p class="d-flex">
                                                <input type="radio" id="park_no_{{j}}" formControlName="park_{{j}}"
                                                    value="no" name="park_{{j}}" />
                                                <label for="park_no_{{j}}">No</label>
                                            </p>
                                        </div>
                                        <div>
                                            <!-- <div>
                                                Field is required
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="px-0">Remarks</label>
                                        <input type="text" formControlName="park_remark" name="park_remark"
                                            class="form-control" placeholder="Enter Remark" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label class="p-0">Mall</label>
                                        <div class="d-flex">
                                            <p class="me-3">
                                                <input type="radio" id="mall_yes_{{j}}" formControlName="mall_{{j}}"
                                                    value="yes" name="mall_{{j}}" />
                                                <label for="mall_yes_{{j}}">Yes</label>
                                            </p>
                                            <p class="d-flex">
                                                <input type="radio" id="mall_no_{{j}}" formControlName="mall_{{j}}"
                                                    value="no" name="mall_{{j}}" />
                                                <label for="mall_no_{{j}}">No</label>
                                            </p>
                                        </div>
                                        <div>
                                            <!-- <div>
                                                Field is required
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="px-0">Remarks</label>
                                        <input type="text" formControlName="mall_remark" name="mall_remark"
                                            class="form-control" placeholder="Enter Remark" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label class="p-0">Supermarket</label>
                                        <div class="d-flex">
                                            <p class="me-3">
                                                <input type="radio" id="supermarket_yes_{{j}}"
                                                    formControlName="supermarket_{{j}}" value="yes"
                                                    name="supermarket_{{j}}" />
                                                <label for="supermarket_yes_{{j}}">Yes</label>
                                            </p>
                                            <p class="d-flex">
                                                <input type="radio" id="supermarket_no_{{j}}"
                                                    formControlName="supermarket_{{j}}" value="no"
                                                    name="supermarket_{{j}}" />
                                                <label for="supermarket_no_{{j}}">No</label>
                                            </p>
                                            <div>
                                            </div>
                                            <!-- <div>
                                                Field is required
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="px-0">Remarks</label>
                                        <input type="text" formControlName="supermarket_remark"
                                            name="supermarket_remark" class="form-control" placeholder="Enter Remark" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex mb-3 justify-content-end">
                                <p-button type="button" icon="bx bx-plus" (click)="addOutdoorActivity('add', j+1)"
                                    iconPos="right" class="secondary-btn mr-2"></p-button>
                                <div class="remove">
                                    <p-button type="button" *ngIf="j!= 0" class="secondary-btn ml-2" icon="bx bx-minus"
                                        (click)="addOutdoorActivity('delete', j)" iconPos="right"></p-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </p-accordionTab>

            <p-accordionTab header="Remarks">
                <div class="add-listings-box">
                    <div class="row">
                        <label class="subHeading mb-3">Remarks</label>
                        <div class="col-12">
                            <div class="form-group">
                                <div class="form-check checkbox-list p-0">
                                    <textarea name="name" class="form-control" placeholder="Enter Remarks"
                                        formControlName="remarks" rows="5"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </p-accordionTab>
        </p-accordion>
        <!-- BABY DETAILS SECTION END-->
        <!-- DAILY CARE SECTION-->

        <div class="add-listings-btn" style="text-align: right;" *ngIf="checkPermission(33)">
            <button type="submit" [disabled]="!flag">Submit</button>
        </div>
        <!-- DAILY CARE SECTION END-->
    </form>
    <!-- End Form -->
    <app-copyrights></app-copyrights>
</div>