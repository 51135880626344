<!-- permission-for-roles.component.html -->
<div class="main-content d-flex flex-column">
    <p-toast></p-toast>
    <app-dashboard-navbar></app-dashboard-navbar>
  
    <div class="breadcrumb-area">
      <h1>Permission For Roles</h1>
      <ol class="breadcrumb">
        <li class="item"><a routerLink="/">Home</a></li>
        <li class="item">Master</li>
        <li class="item">Permission For Roles</li>
      </ol>
    </div>
  
    <div class="add-listings-box">
      <div class="Master-view-box" style="display:flex;">
        <div class="theader">
          <div calss="d-flex w-100">
            <span class="p-input-icon-left ml-auto mr-2 w-100%">
              <i class="pi pi-search"></i>
              <input [(ngModel)]="filterval" (input)="dt1.filterGlobal($event.target.value, 'contains')" pInputText type="text" class="form-control text-center" scriptInjection placeholder="Search Keyword" />
            </span>
            <p-button (click)="reset(dt1)" type="button" class="mx-2" icon="bx bx-reset" (click)="reset(dt1)"></p-button>
          </div>
        </div>
        <div>
          <p-button (click)="savePermission()">Save</p-button>
        </div>
      </div>
  
      <div class="row mt-4">
        <div class="col-lg-12 col-md-12">
          <p-table #dt1 [value]="rolesData" [rows]="10" [showCurrentPageReport]="true" [tableStyle]="{ 'min-width': '50rem', width: '100%' }" [rowsPerPageOptions]="[10, 25, 50]" [paginator]="true" [globalFilterFields]="['name']" dataKey="id" styleClass="p-datatable-sm p-datatable-customers" responsiveLayout="scroll" currentPageReportTemplate="{totalRecords} of records" editMode="row">
            <ng-template pTemplate="header">
              <tr>
                <th style="max-width: 25px; text-align: center">Sr. No.</th>
                <th style="min-width: 130px" pSortableColumn="name">Name</th>
                <th style="min-width: 70px; text-align: center">Create</th>
                <th style="min-width: 70px; text-align: center">Read</th>
                <th style="min-width: 70px; text-align: center">Update</th>
                <th style="min-width: 70px; text-align: center">Delete</th>
              </tr>
            </ng-template>
  
            <ng-template pTemplate="body" let-action let-i="rowIndex" let-editing="editing">
              <tr [pEditableRow]="user">
                <td style="text-align: center">{{ i + 1 }}</td>
                <td>
                  <tr style="min-width: 130px" id="action.id">{{ action.name }}</tr>
                  <td style="min-width: 70px; text-align: center;" class="d-flex justify-content-center align-items-center">
                    <input type="checkbox" id="{{ action.create }}" (change)="onCheckboxChange(action, 'create')" [checked]="isChecked(action, 'create')">
                  </td>
                  <td style="min-width: 70px; text-align: center;">
                    <input type="checkbox" id="{{ action.read }}" (change)="onCheckboxChange(action, 'read')" [checked]="isChecked(action, 'read')">
                  </td>
                  <td style="min-width: 70px; text-align: center;">
                    <input type="checkbox" id="{{ action.update }}" (change)="onCheckboxChange(action, 'update')" [checked]="isChecked(action, 'update')">
                  </td>
                  <td style="min-width: 70px; text-align: center;">
                    <input type="checkbox" id="{{ action.delete }}" (change)="onCheckboxChange(action, 'delete')" [checked]="isChecked(action, 'delete')">
                  </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="5">No Data Found.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
  