<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->
<p-toast></p-toast>
<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">
  <app-dashboard-navbar></app-dashboard-navbar>

  <!-- Breadcrumb Area -->
  <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
    <h1>{{ Content.title }}</h1>
    <ol class="breadcrumb">
      <li class="item"><a routerLink="/">Home</a></li>
      <li class="item">
        {{ Content.subtitle }}
      </li>
      <li class="item">{{ Content.title }}</li>
    </ol>
  </div>
  <!-- End Breadcrumb Area -->

  <!-- Start -->
  <form [formGroup]="feedingAssesmentForm" (ngSubmit)="submitFeedingAssessment(formSubmitted)" #formSubmitted="ngForm">
    <div class="add-listings-box">
      <h3>Baby Details</h3>
      <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
          <div class="form-group">
            <label class="px-0">Name<span class="required">*</span></label>
            <div class="card flex justify-content-center">
              <p-dropdown [options]="babyDetails" (onChange)="getBabyDetails($event)" optionValue="id"
                optionLabel="name" [filter]="true" filterBy="name" formControlName="baby_details_id"
                placeholder="Select Baby's Name" [resetFilterOnHide]="true" [class.is-invalid]="(formSubmitted.submitted &&
                feedingAssesmentForm.get('baby_details_id')?.invalid)
                ||
                feedingAssesmentForm.get('baby_details_id')?.invalid
                &&
                feedingAssesmentForm.get('baby_details_id').touched">
              </p-dropdown>
              <div class="invalid-feedback">Name is required</div>
            </div>
          </div>
        </div>
        <!-- Select Gender Start-->
        <div class="col-lg-4 col-sm-6 col-12">
          <div class="form-group">
            <label class="px-0">Gender</label>
            <div class="card flex justify-content-center">
              <!-- <p-dropdown [options]="genderArray" [(ngModel)]="selectedGender" [ngModelOptions]="{standalone: true}"
                optionLabel="name" [filter]="true" filterBy="name" [showClear]="true" placeholder="Select Baby's Gender"
                [resetFilterOnHide]="true">
              </p-dropdown> -->
              <input type="text" class="form-control" [(ngModel)]="babyGender" [ngModelOptions]="{standalone: true}"
                placeholder="Select Baby's Gender" required [readonly]="true">
            </div>
          </div>
        </div>
        <!-- Select Gender End-->
        <!-- Select DOB Start-->
        <div class="col-lg-4 col-sm-6 col-12">
          <div class="form-group">
            <label class="px-0">Date of birth</label>
            <div class="card flex justify-content-center">
              <!-- <p-calendar [(ngModel)]="selectedDate" [ngModelOptions]="{ standalone: true }" [maxDate]="currentDate"
                [showIcon]="true" (onSelect)="calculateAgeAndYears()" placeholder="DD/MM/YYYY"
                [readonlyInput]="true"></p-calendar> -->
                <input type="text" name="name" class="form-control" placeholder="DD/MM/YYYY" [value]="selectedDate" readonly />
            </div>
          </div>
        </div>
        <!-- Select DOB End-->
        <!-- Days, Months, Years Start-->
        <div class="col-lg-4 col-sm-6 col-12">
          <div class="form-group">
            <label class="px-0">Days</label>
            <input type="text" name="name" class="form-control" placeholder="Days" [value]="days" readonly />
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12">
          <div class="form-group">
            <label class="px-0">Months</label>
            <input type="text" name="name" class="form-control" placeholder="Months" [value]="month" readonly />
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12">
          <div class="form-group">
            <label class="px-0">Years</label>
            <input type="text" name="name" class="form-control" placeholder="Years" [value]="years" readonly />
          </div>
        </div>
        <!-- Days, Months, Years End-->
      </div>
    </div>
    <!-- BABY DETAILS SECTION END-->
    <!-- ASSESMENT SECTION START-->
    <div class="add-listings-box">
      <h3>Assesment:</h3>
      <!-- Ques 1 START-->
      <div class="row">
        <div class="col-sm-6 col-12" formGroupName="Baby_is_not_interested_when_offered_to_breastfeed_sleepy">
          <div class="form-group">
            <label class="px-0">Baby is not interested when offered to breastfeed, sleepy.</label>
            <p>
              <input type="radio" id="interested-no" value="NO" formControlName="answer"
                (change)="handleInput($event, 'interestedSleepy')">
              <label for="interested-no">No</label>
            </p>
            <p class="d-flex">
              <input type="radio" id="interested-yes" value="YES" formControlName="answer"
                (change)="handleInput($event, 'interestedSleepy')">
              <label for="interested-yes">Yes</label>
            </p>
          </div>

        </div>
        <!-- Ques 1 END-->
        <!-- Ques 2 START-->

        <div class="col-sm-6 col-12" formGroupName="Baby_is_showing_feeding_clues_but_not_attaching">
          <div class="form-group">
            <label class="px-0">Baby is showing feeding cues but not attaching.</label>
            <p>
              <input type="radio" id="feeding-clues-no" value="No" formControlName="answer"
                (change)="handleInput($event, 'cluesButNoAttachment')">
              <label for="feeding-clues-no">No</label>
            </p>
            <p class="d-flex">
              <input type="radio" id="feeding-clues-yes" value="Yes" formControlName="answer"
                (change)="handleInput($event, 'cluesButNoAttachment')">
              <label for="feeding-clues-yes">Yes</label>
            </p>
          </div>

        </div>
        <!-- Ques 2 END-->
        <!-- Ques 3 START-->

        <div class="col-sm-6 col-12" formGroupName="Attaches_at_the_breast_but_quickly_falls_asleep">
          <div class="form-group">
            <label class="px-0">Attaches at the breast but quickly falls asleep.</label>
            <p>
              <input type="radio" id="quickly-fall-no" value="No" formControlName="answer"
                (change)="handleInput($event, 'attachesButFallsAsleep')">
              <label for="quickly-fall-no">No</label>
            </p>
            <p class="d-flex">
              <input type="radio" id="quickly-fall-yes" value="Yes" formControlName="answer"
                (change)="handleInput($event, 'attachesButFallsAsleep')">
              <label for="quickly-fall-yes">Yes</label>
            </p>
          </div>
        </div>

        <!-- Ques 3 END-->
        <!-- Ques 4 START-->

        <div class="col-sm-6 col-12" formGroupName="Attaches_for_short_bursts_with_long_pause">
          <div class="form-group">
            <label class="px-0">Attaches for short bursts with long pause.</label>
            <p>
              <input type="radio" id="short-burst-no" value="No" formControlName="answer"
                (change)="handleInput($event, 'attachShortLongPause')">
              <label for="short-burst-no">No</label>
            </p>
            <p class="d-flex">
              <input type="radio" id="short-burst-yes" value="Yes" formControlName="answer"
                (change)="handleInput($event, 'attachShortLongPause')">
              <label for="short-burst-yes">Yes</label>
            </p>
          </div>

        </div>
        <!-- Ques 4 END-->
        <!-- Ques 5 START-->

        <div class="col-sm-6 col-12"
          formGroupName="Attaches_well_with_long_rhythmical_sucking_and_swallowing_for_a_short_feed">
          <div class="form-group">
            <label class="px-0">Attaches well with long rhythmical sucking and swallowing for
              a short feed.</label>
            <p>
              <input type="radio" id="rhythmical-no" value="No" formControlName="answer"
                (change)="handleInput($event, 'longRhythmShortFeed')">
              <label for="rhythmical-no">No</label>
            </p>
            <p class="d-flex">
              <input type="radio" id="rhythmical-yes" value="Yes" formControlName="answer"
                (change)="handleInput($event, 'longRhythmShortFeed')">
              <label for="rhythmical-yes">Yes</label>
            </p>
          </div>


        </div>
        <!-- Ques 5 END-->
        <!-- Ques 6 START-->

        <div class="col-sm-6 col-12"
          formGroupName="Attaches_well_for_a_sustained_period_with_long_rhythmical_sucking_and_swallowing">
          <div class="form-group">
            <label class="px-0">Attaches well for a sustained period with long rhythmical
              sucking and swallowing.</label>
            <p>
              <input type="radio" id="sustain-period-no" value="No" formControlName="answer"
                (change)="handleInput($event, 'longRhythmSustainedPeriod')">
              <label for="sustain-period-no">No</label>
            </p>
            <p class="d-flex">
              <input type="radio" id="sustain-period-yes" value="Yes" formControlName="answer"
                (change)="handleInput($event, 'longRhythmSustainedPeriod')">
              <label for="sustain-period-yes">Yes</label>
            </p>
          </div>

        </div>
        <!-- Ques 6 END-->
        <!-- Ques 7 START-->

        <div class="col-sm-6 col-12" formGroupName="Normal_skin_color_and_tone">
          <div class="form-group">
            <label class="px-0">Normal skin color and tone.</label>
            <p>
              <input type="radio" id="skin-color-no" value="No" formControlName="answer"
                (change)="handleInput($event, 'normalSkinAndTone')">
              <label for="skin-color-no">No</label>
            </p>
            <p class="d-flex">
              <input type="radio" id="skin-color-yes" value="Yes" formControlName="answer"
                (change)="handleInput($event, 'normalSkinAndTone')">
              <label for="skin-color-yes">Yes</label>
            </p>
          </div>



        </div>
        <!-- Ques 7 END-->
        <!-- Ques 8 START-->

        <div class="col-sm-6 col-12" formGroupName="Gaining_weight_appropriately">
          <div class="form-group">
            <label class="px-0">Gaining weight appropriately.</label>
            <p>
              <input type="radio" id="weight-no" value="No" formControlName="answer"
                (change)="handleInput($event, 'appropriateWeightGain')">
              <label for="weight-no">No</label>
            </p>
            <p class="d-flex">
              <input type="radio" id="weight-yes" value="Yes" formControlName="answer"
                (change)="handleInput($event, 'appropriateWeightGain')">
              <label for="weight-yes">Yes</label>
            </p>
          </div>
        </div>
      </div>
      <!-- Ques 8 END-->
      <!-- Other Concerns START-->
      <div class="row">
        <label class="subHeading mb-3">Other Concerns.</label>
        <div class="col-12">
          <div class="form-group d-flex justify-content-between">
            <textarea name="identify" class="form-control" id="kt_autosize_1" rows="5"
              formControlName="Other_concerns"></textarea>
          </div>
        </div>
      </div>
    </div>
    <!-- Other Concerns END-->
    <div class="add-listings-btn" style="text-align: right;">
      <button type="submit">Submit</button>
    </div>
    <!-- ASSESMENT SECTION END-->
  </form>
  <!-- End -->
  <app-copyrights></app-copyrights>
</div>