import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { LoginRegisterRoutingModule } from './login-register-routing.module';
import { LoginRegisterComponent } from './login-register.component';
import { DialogModule } from 'primeng/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { UserRegistrationListingComponent } from './user-registration-listing/user-registration-listing.component';
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
@NgModule({
  declarations: [
    LoginRegisterComponent,
    UserRegistrationComponent,
    UserRegistrationListingComponent,
    ForgetPasswordComponent,
  ],
  imports: [
    TableModule,
    CommonModule,
    LoginRegisterRoutingModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    ToastModule,
    ButtonModule,
    DropdownModule,
    AccordionModule,
    ConfirmDialogModule
  ]
})
export class LoginRegisterModule { }
