import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject,Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CommonApiService {

  apiBaseUrl: string = environment.api_base_url;
  authModalState = new BehaviorSubject(false);
  constructor(private http: HttpClient) { }
  /* -------------------------------------------------------------------------- */
  /*               // get baby details function                    */
  /* -------------------------------------------------------------------------- */
  getBabyDetails() {
    return this.http.get(this.apiBaseUrl + 'baby-details')
  }

  // refresh token api
  refreshToken(): Observable<any> {
    return this.http.get('refresh');
  }
  /* -------------------------------------------------------------------------- */
  /*               // for all baby care plans api                   */
  /* -------------------------------------------------------------------------- */
  postBabyCarePlanDetail(data?: any) {
    return this.http.post(this.apiBaseUrl + 'nanny-plandetails-create', data).toPromise();
  }
  /* -------------------------------------------------------------------------- */
  /*               // get nanny care plans function                    */
  /* -------------------------------------------------------------------------- */
  getNannyCarePlans() {
    return this.http.get(this.apiBaseUrl + 'get-all-nannyplan')
  }

  postEnviromentalChildSafety(object: any) {
    return this.http.post(this.apiBaseUrl + 'environment-child-safety', object).toPromise()
  }
  /* -------------------------------------------------------------------------- */
  /*               // get urine dropdown values from master                     */
  /* -------------------------------------------------------------------------- */
  getUrineValues() {
    return this.http.get(this.apiBaseUrl + 'urine-description').toPromise();
  }
  /* -------------------------------------------------------------------------- */
  /*               // get stool dropdown values from master                    */
  /* -------------------------------------------------------------------------- */
  getStoolValues() {
    return this.http.get(this.apiBaseUrl + 'get-stool-description').toPromise();
  }
  /* -------------------------------------------------------------------------- */
  /*               // Get Edit from of growth monitoring form data                         */
  /* -------------------------------------------------------------------------- */

  getEditGrowthMonitoring(id: any) {
    // debugger
    return this.http.get(this.apiBaseUrl + 'development-milestones/get/' + `${id}`).toPromise();
  }
  /* -------------------------------------------------------------------------- */
  /*               // Edit api on daily task                   */
  /* -------------------------------------------------------------------------- */
  getDailyMonitoringEditApi(id: any) {
    return this.http.get(this.apiBaseUrl + 'daily-monitoring/' + `${id}`).toPromise();
  }
  // /nanny-task-details/view/:id
  // nurse care plan edit get
  getEditNursecare(id: any) {
    // debugger
    return this.http.get(this.apiBaseUrl + 'nanny-task-details/view/' + `${id}`).toPromise();
  }

  getEditNurseDailyTask(id: any) {
    // debugger
    return this.http.get(this.apiBaseUrl + 'nanny-task/' + `${id}`).toPromise();
  }
  /* -------------------------------------------------------------------------- */
  /*               // GEt API of health monitoring                  */
  /* -------------------------------------------------------------------------- */
  getHealthMonitoring() {
    return this.http.get(this.apiBaseUrl + 'health-monitoring').toPromise();
  }
  /* -------------------------------------------------------------------------- */
  /*               // Post API of health monitoring                  */
  /* -------------------------------------------------------------------------- */
  postHealthMonitoring(data: any) {
    return this.http.post(this.apiBaseUrl + 'health-monitoring', data).toPromise();
  }
  getByIdHealthMonitoring(id: any) {
    return this.http.get(this.apiBaseUrl + 'health-monitoring/' + `${id}`).toPromise();
  }

  getEditChildSafety(id: any) {
    return this.http.get(this.apiBaseUrl + 'environment-child-safety/' + `${id}`).toPromise();
  }
  
  putEditChildSafety(id: any, data:any) {
    return this.http.put(this.apiBaseUrl + 'environment-child-safety/' + `${id}`, data).toPromise();
  }
    /* -------------------------------------------------------------------------- */
  /*               // get health monitoring data by baby id             */
  /* -------------------------------------------------------------------------- */
  getHealthMonitoringByBabyId(id: any) {
    return this.http.get(this.apiBaseUrl + 'health-monitoring/baby/' + `${id}`).toPromise();
  }
   /* -------------------------------------------------------------------------- */
  /*               // put API of zero to three month                */
  /* -------------------------------------------------------------------------- */
  editNannyCarePlans(data?: any, id?:any) {
    console.log(data)
    return this.http.put(this.apiBaseUrl + `nanny-plandetails/update/${id}`,data).toPromise();
  }
  putHealthMonitoring(data: any, id:any) {
    console.log("ids", id);
    return this.http.put(this.apiBaseUrl + `health-monitoring/${id}`,data).toPromise();
  }
  getEditNursetask(id: any) {
    // debugger
    return this.http.get(this.apiBaseUrl + 'nanny-plan-details/' + `${id}`).toPromise();
  }

  getEditNursetaskByBaby(id: any) {
    return this.http.get(this.apiBaseUrl + 'nanny-plan-details/baby/' + `${id}`).toPromise();
  }

  putEditNursetask(data: any, id:any) {
    console.log("ids", id);
    return this.http.put(this.apiBaseUrl + `nanny-plandetails/update/${id}`,data).toPromise();
  }
  putEditNurseDailytask(data: any, id:any) {
    console.log("ids", id);
    return this.http.put(this.apiBaseUrl + `nanny-task/update/${id}`,data).toPromise();
  }
  /* -------------------------------------------------------------------------- */
  /*               // for all daily task api                   */
  /* -------------------------------------------------------------------------- */
  postBabyDailyTaskDetail(data?: any) {
    return this.http.post(this.apiBaseUrl + 'create-nanny-task', data).toPromise();
  }

  /* -------------------------------------------------------------------------- */
  /*               // final submit api for nanny task              */
  /* -------------------------------------------------------------------------- */
  nannyfinaltasksubmit( id?:any,data?:any) {
    return this.http.put(this.apiBaseUrl + 'nanny-task-final-submit/baby/' + id ,data ).toPromise();
  }
   /* -------------------------------------------------------------------------- */
  /*               // get audit log api                                          */
  /* -------------------------------------------------------------------------- */
  getauditlog() {
    return this.http.get(this.apiBaseUrl + '/get-audit-logs ' );
  }
}
