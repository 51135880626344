import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  forgetPassword:FormGroup;
  constructor(private fb: FormBuilder, private auth_service : AuthenticationService, private messageService: MessageService, private route: Router, private spinner: NgxSpinnerService) {
    
 
    this.forgetPassword = this.fb.group({
      newPassword:new FormControl('',Validators.required),
      confirmPasword:new FormControl('',Validators.required)
    })
  }

  ngOnInit(): void {
  }
// matchPassword(){
//   if(this.forgetPassword.get('newPassword')?.value === this.forgetPassword.get('confirmPasword')?.value){
//    let obj ={
//     email: localStorage.getItem('emailOTP'),
//     password: this.forgetPassword.get('confirmPasword')?.value
//    }
//     this.auth_service.matchPassword(obj).then((response:any)=>
//     {
//       console.log("Match Password Response",response);
//       if  (response.code == 200){
//         this.route.navigateByUrl('')
//       }
//     })
//   }
//   else{
//     return false 
//   }
// }
matchPassword() {
  if (this.forgetPassword.valid) {
    if (this.forgetPassword.get('newPassword')?.value === this.forgetPassword.get('confirmPasword')?.value) {
      let obj = {
        email: localStorage.getItem('emailOTP'),
        password: this.forgetPassword.get('confirmPasword')?.value
      };
      this.auth_service.matchPassword(obj).then((response: any) => {
        console.log('Match Password Response', response);
        if (response.code === 200) {
          this.route.navigateByUrl('');
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Password reset successfully' });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: response.message });
        }
      });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Passwords do not match' });
    }
  } else {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill all required fields' });
  }
}
}
