import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  apiBaseUrl: string = environment.api_base_url;

  constructor(private http: HttpClient) { }
  /* -------------------------------------------------------------------------- */
  /*                           // LOGIN function function                        */
  /* -------------------------------------------------------------------------- */
  loginApi(formData: any) {
    return this.http.post(this.apiBaseUrl + 'login', formData).toPromise();
  }

  GetParentBabyId(id){
    return this.http.get(this.apiBaseUrl + 'parent-baby/' + `${id}`).toPromise();

  }
  /* -------------------------------------------------------------------------- */
  /*                           // REGISTER function function                    */
  /* -------------------------------------------------------------------------- */
  registerApi(formData: any) {
    return this.http.post(this.apiBaseUrl + 'register', formData).toPromise();
  }
/* -------------------------------------------------------------------------- */
  /*                           // forget password api                   */
  /* -------------------------------------------------------------------------- */
  sendEmailForOtp(formData: any) {
    return this.http.post(this.apiBaseUrl + 'forget-password', formData).toPromise();
  }
  /* -------------------------------------------------------------------------- */
  /*                           // verify otp api                   */
  /* -------------------------------------------------------------------------- */
  
  verifyOtp(formData: any) {
    return this.http.post(this.apiBaseUrl + 'reset-password', formData).toPromise();
  }
   /* -------------------------------------------------------------------------- */
  /*                           // password match update                  */
  /* -------------------------------------------------------------------------- */
  matchPassword(formData: any) {
    return this.http.post(this.apiBaseUrl + 'update-password', formData).toPromise();
  }
}
