<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item">{{Content.subTitle}}</li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>

    <!-- <div class="add-listings-box">
        <div class="row justify-content-between align-items-center">
            <div class="form-group col-4">
                <label class="p-0">Name <span class="required">*</span></label>
                <input type="text" name="name" class="form-control" placeholder="Name" (change)="getInput($event)" />
            </div>
            <div class="add-listings-btn mb-0 col-4 text-end">
                <button type="submit" (click)="addTableData()">Add</button>
            </div>
        </div>
    </div> -->
    <div class="add-listings-box">
        <div class="Master-view-box">
            <div class="theader">
                <div class="d-flex">
                    <span class="p-input-icon-left ml-auto mr-2 w-100%">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" class="form-control text-center" scriptInjection
                            placeholder="Search Keyword" />
                    </span>
                    <p-button type="button" class="mx-2" icon="bx bx-reset" tooltipPosition="bottom"></p-button>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-lg-12 col-md-12">
                <p-table #dt1 dataKey="id" [rows]="10" [lazy]="true" [showCurrentPageReport]="true" styleClass="p-datatable-sm
                p-datatable-customers" responsiveLayout="scroll" [totalRecords]="datalength"
                    [rowsPerPageOptions]="[10, 25, 50]" [paginator]="true" currentPageReportTemplate="{totalRecords} of {{datalength}}
                records" [globalFilterFields]="['event_action',
                'user.name', 'user.email', 'user.contact_no', 'role.name']" [value]="parsedData">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="min-width: 62px">S. No.</th>
                            <th>Name</th>
                            <th>Blood Pressure</th>
                            <th>Heart Rate</th>
                            <th>Temperature</th>
                        </tr>


                    </ng-template>
                    <!-- tables to show data -->
                    <ng-template pTemplate="body" let-list let-i="rowIndex">
                        <tr>
                            <td>
                                {{ i + 1 }}
                            </td>
                            <td>{{ list?.name ?? '-'  | titlecase }}</td>
                            <td>{{ list?.blood_pressure ?? '-' }}</td>
                            <td>{{ list?.heart_rate ?? '-' }}</td>
                            <td>{{ list?.temperature ?? '-' }}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8">No Data Found.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>