import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { MaikaListService } from 'src/app/services/maika-list.service';
import { CommonApiService } from 'src/app/services/common-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-nurse-summary',
  templateUrl: './nurse-summary.component.html',
  styleUrls: ['./nurse-summary.component.scss'],
  providers: [MessageService],
})
export class NurseSummaryComponent implements OnInit {
  breadcrumb = [

    {
      title: 'Patient Summary',
      subTitle: 'Dashboard',
    }
  ]


  babyAge: string = ''
  calendar1: null
  calendar12: null
  calendar11: null
  calendar2: null
  calendar3: null
  calendar4: null
  calendar5: null
  calendar6: null
  calendar7: null
  calendar8: null
  calendar9: null
  calendar10: null
  calendar13: null
  calendar14: null
  error: boolean = false;
  getNurseData: any;
  viewDetails: any[] = [];
  insideViewData: any;
  hasTodaysEntry: boolean = true;
  selectedDate: string;
  date: string;
  newData: any;
  dataOnString: any=null;
  constructor(
    private maikaservices: MaikaListService,
    private router: Router,
    private common_service: CommonApiService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
  ) { }
  insideViewDetails: any;
  getAllNurseData() {
    this.spinner.show()
    this.insideViewDetails = [];
    const id = localStorage.getItem('babyId');
    // call api for get todays nanny task 
    this.maikaservices.grtNurseSummaryData(id).then((res: any) => {
      this.spinner.hide()
      this.getNurseData = res.data;
      localStorage.removeItem('nurseTaskEditID');
      // Check if res.data is an array, if so, push its elements into viewDetails
      if (Array.isArray(this.getNurseData)) {
        this.viewDetails.push(...this.getNurseData);
      } else {
        this.viewDetails.push(this.getNurseData);
      }
      // this.insideViewDetails = this.viewDetails;
      // this.viewDetails?.forEach((res:any)=>{
      this.insideViewData = res?.data[0]?.nanny_task_details_json;
      // this.insideViewDetails = this.insideViewData;


    }).catch((error: any) => {              // call api for get last nanny plan if todays entry is not present
      this.hasTodaysEntry = false;    // set false if todays entry is not present use in add task buttons
      // Handle error response
      this.common_service.getEditNursetaskByBaby(id).then((res: any) => {
        this.spinner.hide()

        this.getNurseData = res.data;
        localStorage.removeItem('nurseTaskEditID');
        // Check if res.data is an array, if so, push its elements into viewDetails
        if (Array.isArray(this.getNurseData)) {
          this.viewDetails.push(...this.getNurseData);
        } else {
          this.viewDetails.push(this.getNurseData);
        }
        // this.insideViewDetails = this.viewDetails;
        // this.viewDetails?.forEach((res:any)=>{
        this.insideViewData = res?.data[0]?.nanny_task_details_json;
        // this.insideViewDetails = this.insideViewData;

      })
      this.spinner.hide()
    });
    // })

  }
  // function call on add task button click
  editOnCLick() {
    // alert("Error 404: Resource not found");
    let dob, nursce_care_link;

    // get DOB of baby from local storage to create care plan link according to their age 

    dob = localStorage.getItem('dob').split('/');
    let today = new Date();




    let dateOfBirth = new Date(dob[2], dob[1] - 1, dob[0]);


    const timeDiffMilliseconds = today.getTime() - dateOfBirth.getTime();

    const daysDiff = Math.floor(timeDiffMilliseconds / (1000 * 3600 * 24));


    if (daysDiff <= 119) {
      nursce_care_link = '/portal/edit-zero-to-three-months';
    }
    // up to 4 months  to 1 year
    else if (daysDiff <= 365 && daysDiff > 119) {
      nursce_care_link = '/portal/edit-four-to-one-year';
    }
    // 1 year to 3 year
    else if (daysDiff <= 1095 && daysDiff > 365) {
      nursce_care_link = '/portal/edit-one-year-to-three-year';
    }

    // up to 3 year 
    else if (daysDiff > 1095) {
      nursce_care_link = '/portal/edit-three-years-and-above';
    }


    const id = localStorage.getItem('babyId');
    const nurseId = localStorage.getItem('babyId');

    // set nanny task id to local storage if todays entry is present
    if (this.hasTodaysEntry) {
      let nanny_task_id = this.getNurseData[0].nanny_task_id;
      
      localStorage.setItem('nurseTaskEditID', nanny_task_id);
      this.router.navigateByUrl(nursce_care_link);
    }
    // remove nanny task id from local storage if todays entry is not present
    else {
      
      this.messageService.add({
        severity: 'info',
        summary: 'Info',
        detail: 'please create Nurse Task',
      });
      // alert('please create ncp');
    }
    // route on form  
  }

  // function for crearte growth monitoring link
  addGrowthMonitoring() {
    let dob, growthMonitoringLink;

    // get DOB of baby from local storage to create care plan link according to their age 

    dob = localStorage.getItem('dob').split('/');
    let today = new Date();




    let dateOfBirth = new Date(dob[2], dob[1] - 1, dob[0]);


    const timeDiffMilliseconds = today.getTime() - dateOfBirth.getTime();

    const daysDiff = Math.floor(timeDiffMilliseconds / (1000 * 3600  *24));


    if (daysDiff <= 90) {
      growthMonitoringLink = '/portal/zero-to-three-month-growth-monitoring';
    }
    // up to 4 months  to 1 year
    else if (daysDiff <= 365 && daysDiff > 90) {
      growthMonitoringLink = '/portal/four-to-one-year-growth-monitoring';
    }
    // 1 year to 3 year
    else if (daysDiff <= 1095 && daysDiff > 365) {
      growthMonitoringLink = '/portal/one-year-to-three-year-growth-monitoring';
    }

    // up to 3 year 
    else if (daysDiff > 1095) {
      growthMonitoringLink = '/portal/three-year-and-above-year-growth-monitoring';
    }


    const id = localStorage.getItem('babyId');


    // route on form  
    this.router.navigateByUrl(growthMonitoringLink);
  }

  // formatDateTime(date: string | undefined): string {
  //   if (date) {
  //     const formattedDate = new Date(date);
  //     // const year = formattedDate.getFullYear();
  //     // const month = ('0' + (formattedDate.getMonth() + 1)).slice(-2);
  //     // const day = ('0' + formattedDate.getDate()).slice(-2);
  //     const hours = ('0' + formattedDate.getHours()).slice(-2);
  //     const minutes = ('0' + formattedDate.getMinutes()).slice(-2);
  //     const seconds = ('0' + formattedDate.getSeconds()).slice(-2);

  //     return `${hours}:${minutes}:${seconds}`;
  //     // return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  //   }
  //   return '-';


  // }
  formatDateTime(date: string | undefined): string {
    if (date) {
      const formattedDate = new Date(date);
      const hours = formattedDate.getHours();
      const minutes = ('0' + formattedDate.getMinutes()).slice(-2);
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const displayHours = hours % 12 || 12; // Convert 0 to 12

      return `${displayHours}:${minutes} ${ampm}`;
    }
    return '-';
  }

  ngOnInit(): void {
    this.getAllNurseData();
  }
  // getDataInsideData(){

  //   this.viewDetails?.forEach((res:any)=>
  //   {
  //     this.insideViewData =res?.nanny_task_details_json;
  //   })
  // }

  fillExtraActivites() {
    const BabyId = localStorage.getItem('babyId');
    localStorage.setItem('create', BabyId);

    this.router.navigateByUrl('/portal/extra-activities');
  }


  getDataByDate(event: any , string?: any) {
    this.dataOnString =string
    this.calendar10
    this.spinner.show()
    this.newData = [];
    this.date = `${event.getDate()}-${event.getMonth() + 1}-${event.getFullYear()}`
    const payload = {
      babyId: localStorage.getItem('babyId'),
      date: `${event.getFullYear()}-${event.getMonth() + 1}-${event.getDate()}`
    };
    this.maikaservices.getNurseTaskByDate(payload).then((res: any) => {
      this.spinner.hide()

      this.newData = res.data[0].nanny_task_details_json;
      this.error = false
    }).catch((error: any) => {
      this.error = true
      this.spinner.hide()
    });
  }
}
