import { Component, OnInit } from '@angular/core';
import { PtServiceService} from 'src/app/services/pt-service.service'
import { DialogModule } from 'primeng/dialog';
@Component({
  selector: 'app-pt-daily-task-listing',
  templateUrl: './pt-daily-task-listing.component.html',
  styleUrls: ['./pt-daily-task-listing.component.scss']
})
export class PtDailyTaskListingComponent implements OnInit {
  breadcrumb = [
    {
      title: 'PT Daily Notes',
      subTitle: 'Dashboard'
    }
  ]

  displayMaximizable: boolean = false;
  viewDetail: any = [];
  dailynotesdata: any = []
  loading: any;
  notesdata:any = [];
  tooltipText: string = 'Daily Notes';
  viewDetailJson: any;
  filterVal: string;
  constructor(private dailynotes:  PtServiceService) { }
  ngOnInit(): void {
    this. getAllData()
   }
   details: any = [];
   showMaximizableDialog(data: any) {
     this.details = [];
     this.viewDetail = [];
     this.viewDetail.push(data)
    this.displayMaximizable = true;
    console.log(this.viewDetailJson);
  }
  getAllData() {

    this.dailynotes.ListingdailyNotes().subscribe((res: any) => {
      this.dailynotesdata = res?.data;
      this.dailynotesdata?.map((ele: any,key:any) => {
        if(ele?.daily_notes_json) {
          if(typeof JSON.parse(ele?.daily_notes_json) == "object") {
            this.dailynotesdata[key] = {...JSON.parse(ele?.daily_notes_json),...ele};
          }; 
          if(typeof JSON.parse(ele?.daily_notes_json) == "string") {
            this.dailynotesdata[key] = {...JSON.parse(JSON.parse(ele?.daily_notes_json)),...ele};
          }
        }

      });
    })
  }
  reset(dt) {
    dt.reset();
    this.filterVal = '';
  }
  }
