import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Form, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonApiService } from 'src/app/services/common-api.service';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-environment-and-chiled-safty-assessment',
  templateUrl: './environment-and-chiled-safty-assessment.component.html',
  styleUrls: ['./environment-and-chiled-safty-assessment.component.scss'],
  providers: [MessageService]
})
export class EnvironmentAndChiledSaftyAssessmentComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Child Safety Assessment',
      subTitle: 'Dashboard'
    }
  ]
  // EnvironmentAndChiledSaftyAssessment:FormGroup;
  babyDetails: any = [];
  babyGender: string;
  showNeedsTextarea: boolean = false;
  formula: boolean = false;
  selectedDate: Date = null; // Initialize with today's date
  selectedNumber: any;
  ageList: any = [];
  years: any;
  month: any;
  days: any;
  Yes: boolean = true;
  Risks: boolean = true;
  Yesrisk: boolean = true;
  Yespart: boolean = true;
  requireChanges: boolean = true;
  toys: boolean = true;
  pets: boolean = true;
  // genderArray: any = [
  //   { name: 'Male', value: 'male' },
  //   { name: 'Female', value: 'female' },
  // ];
  constructor(private fb: FormBuilder, private commonApi: CommonApiService, private messageService: MessageService, private router: Router, private spinner:NgxSpinnerService) { }

  calculateAgeAndYears() {
    if (this.selectedDate) {
      const today = new Date();
      const selectedDate = new Date(this.selectedDate);
      const time = today.getTime() - selectedDate.getTime();
      const date = time / (1000 * 3600 * 24);

      var diff = Math.floor(today.getTime() - selectedDate.getTime());
      var day = 1000 * 60 * 60 * 24;
      this.days = Math.floor(diff / day);
      this.month = Math.floor(this.days / 31);
      this.years = Math.floor(this.month / 12);
      let total_months = this.years * 12;
      let total_days = this.month * 31;
      this.month = this.month - total_months;
      this.days = this.days - total_days;
    } else {
      this.month = '';
      this.years = '';
      this.years = '';
    }
  }

  ngOnInit(): void {
    this.calculateAgeAndYears();
    this.spinner.show()
    this.commonApi.getBabyDetails().subscribe((res: any) => {
      this.spinner.hide();
      this.babyDetails = res?.data;
    });
  }

  EnvironmentAndChiledSaftyAssessment = this.fb.group({
    baby_details_id: new FormControl(null, [Validators.required]),
    gender: new FormControl(null),
    child_room_change: new FormGroup({
      answer: new FormControl(null),
      question: new FormControl('Requires Changes?'),
      identify_requirements: new FormControl({ value: '', disabled: true }),
    }),

    washroom_fall_risk: new FormGroup({
      answer: new FormControl(null),
      question: new FormControl('Washroom(Fall Risk)'),
      washroom_identify_risks: new FormControl({ value: '', disabled: true }),
    }),

    stroller_available: new FormControl(null),
    outdoor_playground: new FormControl(null),

    any_loose_parts_sharp_edges: new FormGroup({
      answer: new FormControl(null),
      question: new FormControl('Any Loose Part Or Sharp Edges?'),
      identify_parts: new FormControl({ value: '', disabled: true }),
    }),

    play: new FormControl(null),
    tricycle: new FormControl(null),
    scooter: new FormControl(null),
    slides: new FormControl(null),
    swimming_pool: new FormControl(null),

    toyes: new FormGroup({
      toyes: new FormControl(null),
      kind: new FormControl({ value: null, disabled: true }),
    }),

    pets: new FormGroup({
      pets: new FormControl(null),
      kind: new FormControl({ value: null, disabled: true }),
    }),

    child_seat_available_car: new FormControl(null),
    other_conserns: new FormControl(null),
  });

  getBabyDetails(event: any) {
    let data = this.babyDetails?.filter((res: any) => res?.id == event?.value);
    let firstLetter = data[0]?.gender.toLowerCase();
    let capitalize =
      firstLetter.slice(0, 1).toUpperCase() +
      firstLetter.slice(1, firstLetter.length);
    this.babyGender = capitalize;
    this.selectedDate = data[0].date_of_birth
    // Manually parse the date string in DD/MM/YYYY format
    const dateParts = data[0].date_of_birth.split('/');
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Months are 0-indexed in JavaScript
    const year = parseInt(dateParts[2], 10);

    const selectedDate = moment([year, month, day]);
    const today = moment();

    // Calculate the difference in years, months, and days
    const years = today.diff(selectedDate, 'years');
    selectedDate.add(years, 'years');

    const months = today.diff(selectedDate, 'months');
    selectedDate.add(months, 'months');

    const days = today.diff(selectedDate, 'days');

    this.years = years;
    this.month = months;
    this.days = days;
  }
  softReload() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  handleInput(event: any, value: string) {
    if (value == 'requirechanges') {
      if (event.target.value == '1' && event.target.checked) {
        this.EnvironmentAndChiledSaftyAssessment.controls.child_room_change.controls.identify_requirements.enable();
      }
      else if (event.target.value == '0' && event.target.checked) {
        this.EnvironmentAndChiledSaftyAssessment.controls.child_room_change.controls.identify_requirements.disable();
        this.EnvironmentAndChiledSaftyAssessment.controls.child_room_change.controls.identify_requirements.setValue('');
      }
    }
    else if (value == 'washroom') {
      if (event.target.value == '1' && event.target.checked) {
        
        this.EnvironmentAndChiledSaftyAssessment.controls.washroom_fall_risk.controls.washroom_identify_risks.enable();
      }
      else if (event.target.value == '0' && event.target.checked) {
        
        this.EnvironmentAndChiledSaftyAssessment.controls.washroom_fall_risk.controls.washroom_identify_risks.disable();
        this.EnvironmentAndChiledSaftyAssessment.controls.washroom_fall_risk.controls.washroom_identify_risks.setValue('');
      }
    }
    else if (value == 'loose_part') {
      if (event.target.value == '1' && event.target.checked) {
        
        this.EnvironmentAndChiledSaftyAssessment.controls.any_loose_parts_sharp_edges.controls.identify_parts.enable();
      }
      else if (event.target.value == '0' && event.target.checked) {
        
        this.EnvironmentAndChiledSaftyAssessment.controls.any_loose_parts_sharp_edges.controls.identify_parts.disable();
        this.EnvironmentAndChiledSaftyAssessment.controls.any_loose_parts_sharp_edges.controls.identify_parts.setValue('');
      }
    }
    else if (value == 'toys') {
      if (event.target.value == '1' && event.target.checked) {
        
        this.EnvironmentAndChiledSaftyAssessment.controls.toyes.controls.kind.enable();
      }
      else if (event.target.value == '0' && event.target.checked) {
        
        this.EnvironmentAndChiledSaftyAssessment.controls.toyes.controls.kind.disable();
        this.EnvironmentAndChiledSaftyAssessment.controls.toyes.controls.kind.setValue('');
      }
    }
    else if (value == 'pets') {
      if (event.target.value == '1' && event.target.checked) {
        
        this.EnvironmentAndChiledSaftyAssessment.controls.pets.controls.kind.enable();
      }
      else if (event.target.value == '0' && event.target.checked) {
        
        this.EnvironmentAndChiledSaftyAssessment.controls.pets.controls.kind.disable();
        this.EnvironmentAndChiledSaftyAssessment.controls.pets.controls.kind.setValue('');
      }
    }
    // else if (value=='stroller_available'){
    // }
  }

  submitEnviorment(assessment: any) {
    this.spinner.show()
    window.scroll(0, 0)
    if (assessment.valid) {
      // this.spinner.show();
      this.commonApi.postEnviromentalChildSafety(this.EnvironmentAndChiledSaftyAssessment.value)
        .then((res: any) => {
          this.spinner.hide()

          this.messageService.add({
            severity: 'success', summary: 'Success', detail: res?.message,
          });
          setTimeout(() => {
            this.softReload();
          }, 400);
          // this.spinner.hide();
        });

    } else {
      this.spinner.hide()

      this.messageService.add({
        severity: 'error', summary: 'Error', detail: 'Please Fill All the Details!',
      });
    }
  }
}
