<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->
<p-toast></p-toast>
<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">
  <app-dashboard-navbar></app-dashboard-navbar>

  <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
    <h1>{{ Content.title }}</h1>
    <ol class="breadcrumb">
      <li class="item"><a routerLink="/">Home</a></li>
      <li class="item">{{ Content.subTitle }}
      </li>
      <li class="item">{{ Content.title }}</li>
    </ol>
  </div>
  <form [formGroup]="nannyDailyMonitoring" (ngSubmit)="formSubmission(monitoringForm)" #monitoringForm="ngForm">
    <!-- BABY DETAILS SECTION -->
    <div class="add-listings-box">
      <h3>Baby Details</h3>
      <div class="row">
        <div class="col-lg-4 col-sm-6 col-12">
          <div class="form-group">
            <label class="px-0">Name<span class="required">*</span></label>
            <div class="card flex justify-content-center">
              <p-dropdown [options]="babyValues" (onChange)="getRemainingDetails($event)" optionLabel="name"
                formControlName="baby_details_id" [filter]="true" filterBy="name" optionValue="id"
                placeholder="Select Baby's Name" [resetFilterOnHide]="true" [class.is-invalid]="
                  (monitoringForm.submitted &&
                    nannyDailyMonitoring.get('baby_details_id')?.invalid) ||
                  (nannyDailyMonitoring.get('baby_details_id')?.invalid &&
                    nannyDailyMonitoring.get('baby_details_id').touched)
                ">
              </p-dropdown>
              <div class="invalid-feedback">Name is required</div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12">
          <div class="form-group">
            <label class="px-0">Gender</label>
            <div class="card flex justify-content-center">
              <!-- <p-dropdown [options]="genderArray" optionLabel="name" optionValue="value"
                                [(ngModel)]="babyGender" [ngModelOptions]="{standalone: true}"
                                placeholder="Select Baby's Gender" [resetFilterOnHide]="true" required
                                [readonly]="true">
                            </p-dropdown> -->
              <input type="text" class="form-control" [(ngModel)]="babyGender" [ngModelOptions]="{ standalone: true }"
                placeholder="Select Baby's Gender" [readonly]="true" />
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12">
          <div class="form-group">
            <label class="px-0">Date of birth</label>
            <div class="card flex justify-content-center">
              <!-- <p-calendar [(ngModel)]="selectedDate" [ngModelOptions]="{standalone: true}"
                                [showIcon]="true" [readonlyInput]="true" placeholder="DD-MM-YYYY"></p-calendar> -->
              <input type="text" name="name" class="form-control" placeholder="DD/MM/YYYY" [value]="selectedDate"
                readonly />
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12">
          <div class="form-group">
            <label class="px-0">Days</label>
            <input type="text" name="name" class="form-control" placeholder="Days" [value]="days" readonly />
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12">
          <div class="form-group">
            <label class="px-0">Months</label>
            <input type="text" name="name" class="form-control" placeholder="Months" [value]="month" readonly />
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-12">
          <div class="form-group">
            <label class="px-0">Years</label>
            <input type="text" name="name" class="form-control" placeholder="Years" [value]="years" readonly />
          </div>
        </div>
      </div>
    </div>

    <!-- feeding -->

    <div class="add-listings-box">
      <h3>Feeding</h3>
      <div class="form-group mb-3">
        <div class="form-check pl-0 checkbox-list px-0">
          <label class="checkbox checkbox-outline px-0">
            <input type="checkbox" value="breast" formControlName="breast_feeding"
              (change)="handleInput($event, 'breastFeeding')" />
            <span></span>
            Breast Feeding
          </label>
        </div>
      </div>
      <div class="form-group table-responsive" [ngClass]="breast_feeding ? 'd-block' : 'd-none'"
        formArrayName="feeding_breast">
        <table class="table">
          <thead>
            <tr class="border-light" style="white-space: nowrap">
              <!-- <th scope="col"><label class="subHeading px-0">Feeding</label></th> -->
              <th scope="col" class="d-flex">
                <label class="subHeading px-0">Feeding</label>
              </th>
              <!-- <th></th> -->
              <th scope="col"><label class="subHeading px-0">Time</label></th>
              <th scope="col"><label class="subHeading px-0">Amount</label></th>
              <th scope="col"><label class="subHeading px-0">Remark</label></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <!-- Table record -->
            <ng-container *ngFor="
                let control of getBreastFeedingArray().controls;
                index as i;
                last as last
              ">
              <tr [formGroupName]="i" class="border-light">
                <td scope="row" class="d-flex">
                  <label class="px-0">Feeding {{ i + 1 }}</label>
                </td>
                <!-- <td>
                  <input
                    class="my-3"
                    type="checkbox"
                    value="feeding"
                    id="intact_light"
                    formControlName="feeding_check"
                  />
                </td> -->
                <!-- <td> <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Time"
                                        formControlName="feeding_time" /></td> -->
                <td>
                  <p-calendar placeholder="Time" formControlName="feeding_time" [timeOnly]="true" [hourFormat]="12"
                    inputId="time"></p-calendar>
                </td>
                <td>
                  <input class="my-3" type="number" class="form-control px-2 remark" placeholder="Amount(ml)"
                    formControlName="feeding_amount" [class.is-invalid]="(monitoringForm.submitted &&
                    nannyDailyMonitoring.get('feeding_amount')?.invalid)
                    ||
                    nannyDailyMonitoring.get('feeding_amount')?.invalid
                    &&
                    nannyDailyMonitoring.get('feeding_amount').touched" />
                  <div class="invalid-feedback">
                    Amount must be in ML
                  </div>
                </td>
                <td>
                  <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                    formControlName="feeding_remark" />
                </td>
                <td>
                  <div class="d-flex justify-content-end align-items-center p-0">
                    <p-button type="button" icon="bx bx-plus" (click)="addBreastFeeding('add', i)" iconPos="right"
                      class="mr-sm-2 mr-0 mx-2"></p-button>
                    <div class="remove">
                      <p-button type="button" *ngIf="i != 0" class="mx-2" icon="bx bx-minus"
                        (click)="addBreastFeeding('delete', i)" iconPos="right"></p-button>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>

      <!-- Formula Feeding -->
      <div class="form-group mb-3">
        <div class="form-check pl-0 checkbox-list px-0">
          <label class="checkbox checkbox-outline px-0">
            <input type="checkbox" value="formula" formControlName="formula_feeding"
              (change)="handleInput($event, 'formulafeeding')" />
            <span></span>
            Formula Feeding
          </label>
        </div>
        <!-- </div> <input type="checkbox" value="breast" formControlName="breast_feeding"
      (change)="handleInput($event, 'breastFeeding')" /> -->
        <div class="form-group table-responsive" [ngClass]="formula_feeding ? 'd-block' : 'd-none'">
          <table class="table">
            <thead>
              <tr class="border-light" style="white-space: nowrap">
                <!-- <th scope="col"><label class="subHeading px-0">Feeding</label></th> -->
                <th scope="col" class="d-flex">
                  <label class="subHeading px-0">Feeding</label>
                </th>
                <!-- <th></th> -->
                <th scope="col"><label class="subHeading px-0">Time</label></th>
                <th scope="col"><label class="subHeading px-0">Amount</label></th>
                <th scope="col"><label class="subHeading px-0">Remark</label></th>
                <th></th>
              </tr>
            </thead>
            <tbody formArrayName="feeding_formula">
              <!-- Table record -->
              <ng-container *ngFor="
                let control of getFormulaFeedingArray().controls;
                index as i;
                last as last
              ">
                <tr class="border-light" [formGroupName]="i">
                  <td scope="row" class="d-flex">
                    <label class="px-0">Meal {{ i + 1 }}</label>
                  </td>

                  <!-- <td> <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Time"
                                        formControlName="feeding_time" /></td> -->
                  <td>
                    <p-calendar placeholder="Time" formControlName="feeding_time" [timeOnly]="true" [hourFormat]="12"
                      inputId="time"></p-calendar>
                  </td>
                  <td>
                    <input class="my-3" type="number" class="form-control px-2 remark" placeholder="Amount(ml)"
                      formControlName="feeding_amount" [class.is-invalid]="(monitoringForm.submitted &&
                    nannyDailyMonitoring.get('feeding_amount')?.invalid)
                    ||
                    nannyDailyMonitoring.get('feeding_amount')?.invalid
                    &&
                    nannyDailyMonitoring.get('feeding_amount').touched" />
                    <div class="invalid-feedback">
                      Amount must be in ML
                    </div>
                  </td>
                  <td>
                    <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                      formControlName="feeding_remark" />
                  </td>
                  <td>
                    <div class="d-flex justify-content-end align-items-center p-0">
                      <p-button type="button" icon="bx bx-plus" (click)="addFormulaFeeding('add', i)" iconPos="right"
                        class="mr-sm-2 mr-0 mx-2"></p-button>
                      <div class="remove ">
                        <p-button type="button" *ngIf="i != 0" class="mx-2" icon="bx bx-minus"
                          (click)="addFormulaFeeding('delete', i)" iconPos="right"></p-button>
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Solid food feeding -->
      <div class="add-listings-box">
        <h3>Solid Food Feeding</h3>
        <!-- Cerals & grains -->
        <!-- <label class="subHeading py-3">Cerals & Grains</label> -->
        <div formGroupName="solid_food">
          <div class="form-group">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr class="border-light" style="white-space: nowrap">
                    <th scope="col">
                      <label class="subHeading p-0">Cerals & Grains</label>
                    </th>
                    <th scope="col"></th>
                    <th scope="col">
                      <label class="subHeading p-0">Remark</label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Rice -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Rice</label>
                    </td>
                    <td>
                      <input class="my-3" type="checkbox" value="intact" formControlName="rice" id="rice"
                        (change)="handleInput($event, 'rice')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="rice_remark" id="rice_remark" />
                    </td>
                  </tr>
                  <!-- Barley -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Barley</label>
                    </td>
                    <td>
                      <input class="my-3" type="checkbox" value="intact" formControlName="barley" id="barley"
                        (change)="handleInput($event, 'barley')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="barley_remark" id="barley_remark" />
                    </td>
                  </tr>

                  <!-- Oats -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Oats</label>
                    </td>
                    <td>
                      <input class="my-3" type="checkbox" value="intact" formControlName="oats" id="oats"
                        (change)="handleInput($event, 'oats')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="oats_remark" id="oats_remark" />
                    </td>
                  </tr>

                  <!-- Beans -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Beans</label>
                    </td>
                    <td>
                      <input class="my-3" type="checkbox" value="intact" formControlName="beans" id="beans"
                        (change)="handleInput($event, 'beans')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="beans_remark" id="beans_remark" />
                    </td>
                  </tr>

                  <!-- Lentils -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Lentils</label>
                    </td>
                    <td>
                      <input class="my-3" type="checkbox" value="intact" formControlName="lentils" id="lentils"
                        (change)="handleInput($event, 'lentils')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="lentils_remark" id="lentils_remark" />
                    </td>
                  </tr>

                  <!-- Pasta -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Pasta</label>
                    </td>
                    <td>
                      <input class="my-3" type="checkbox" value="intact" formControlName="pasta" id="pasta"
                        (change)="handleInput($event, 'pasta')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="pasta_remark" id="pasta_remark" />
                    </td>
                  </tr>

                  <!-- Others -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Others</label>
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Others"
                        formControlName="other" id="other" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="other_remark" id="other_remark" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- Cerals & grains End -->

        <!-- Fruits -->
        <!-- <label class="subHeading py-3">Fruits</label> -->
        <div formGroupName="solid_fruits">
          <div class="form-group">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr class="border-light" style="white-space: nowrap">
                    <th scope="col">
                      <label class="subHeading p-0">Fruits</label>
                    </th>
                    <th scope="col"></th>
                    <th scope="col">
                      <label class="subHeading p-0">Remark</label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Banana -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Banana</label>
                    </td>
                    <td>
                      <input class="my-3" type="checkbox" value="intact" formControlName="banana" id="banana"
                        (change)="handleInput($event, 'banana')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="banana_remark" id="banana_remark" />
                    </td>
                  </tr>
                  <!-- Apple -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Apple</label>
                    </td>
                    <td>
                      <input class="my-3" type="checkbox" value="intact" formControlName="apple" id="apple"
                        (change)="handleInput($event, 'apple')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="apple_remark" id="apple_remark" />
                    </td>
                  </tr>

                  <!-- Avocado -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Avocado</label>
                    </td>
                    <td>
                      <input class="my-3" type="checkbox" value="intact" formControlName="avocado" id="avocado"
                        (change)="handleInput($event, 'avacado')" />
                    </td> 
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="avocado_remark" id="avocado_remark" />
                    </td>
                  </tr>

                  <!-- Pears -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Pears</label>
                    </td>
                    <td>
                      <input class="my-3" type="checkbox" value="intact" formControlName="pears" id="pears"
                        (change)="handleInput($event, 'pears')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="pears_remark" id="pears_remark" />
                    </td>
                  </tr>

                  <!-- Mango -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Mango</label>
                    </td>
                    <td>
                      <input class="my-3" type="checkbox" value="intact" formControlName="mango" id="mango"
                        (change)="handleInput($event, 'mango')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="mango_remark" id="mango_remark" />
                    </td>
                  </tr>

                  <!-- Nectrain -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Nectrain</label>
                    </td>
                    <td>
                      <input class="my-3" type="checkbox" value="intact" formControlName="nectrain" id="nectrain"
                        (change)="handleInput($event, 'nectrain')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="nectrain_remark" id="nectrain_remark" />
                    </td>
                  </tr>

                  <!-- Figs -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Figs</label>
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="checkbox" value="intact" formControlName="figs" id="figs"
                        (change)="handleInput($event, 'figs')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="figs_remark" id="figs_remark" />
                    </td>
                  </tr>

                  <!-- Papaya -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Papaya</label>
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="checkbox" value="intact" formControlName="papaya" id="papaya"
                        (change)="handleInput($event, 'papaya')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="papaya_remark" id="papaya_remark" />
                    </td>
                  </tr>

                  <!-- Kiwi -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Kiwi</label>
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="checkbox" value="intact" formControlName="kiwi" id="kiwi"
                        (change)="handleInput($event, 'kiwi')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="kiwi_remark" id="kiwi_remark" />
                    </td>
                  </tr>

                  <!-- Grapes -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Grapes</label>
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="checkbox" value="intact" formControlName="grapes" id="grapes"
                        (change)="handleInput($event, 'grapes')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="grapes_remark" id="grapes_remark" />
                    </td>
                  </tr>

                  <!-- Orange -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Orange</label>
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="checkbox" value="intact" formControlName="orange" id="orange"
                        (change)="handleInput($event, 'orange')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="orange_remark" id="orange_remark" />
                    </td>
                  </tr>

                  <!-- Cherries -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Cherries</label>
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="checkbox" value="intact" formControlName="cherries" id="cherries"
                        (change)="handleInput($event, 'cherries')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="cherries_remark" id="cherries_remark" />
                    </td>
                  </tr>
                  <!-- Peach -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Peach</label>
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="checkbox" value="intact" formControlName="peach" id="peach"
                        (change)="handleInput($event, 'peach')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="peach_remark" id="peach_remark" />
                    </td>
                  </tr>

                  <!-- Date -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Date</label>
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="checkbox" value="intact" formControlName="date" id="date"
                        (change)="handleInput($event, 'date')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="date_remark" id="date_remark" />
                    </td>
                  </tr>

                  <!-- Others -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Others</label>
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="checkbox" value="intact" formControlName="other" id="other" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="other_remark" id="other_remark" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- Fruits End -->

        <!-- Vegetables -->
        <div formGroupName="solid_vegetable">
          <div class="form-group">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr class="border-light" style="white-space: nowrap">
                    <th scope="col">
                      <label class="subHeading p-0">Vegables</label>
                    </th>
                    <th scope="col"></th>
                    <th scope="col">
                      <label class="subHeading p-0">Remark</label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Pumpkin -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Pumpkin</label>
                    </td>
                    <td>
                      <input class="my-3" type="checkbox" value="intact" formControlName="pumpkin" id="pumpkin"
                        (change)="handleInput($event, 'pumpkin')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="pumpkin_remark" id="pumpkin_remark" />
                    </td>
                  </tr>
                  <!-- Potato -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Potato</label>
                    </td>
                    <td>
                      <input class="my-3" type="checkbox" value="intact" formControlName="potato" id="potato"
                        (change)="handleInput($event, 'potato')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="potato_remark" id="potato_remark" />
                    </td>
                  </tr>

                  <!-- Carrots -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Carrots</label>
                    </td>
                    <td>
                      <input class="my-3" type="checkbox" value="intact" formControlName="carrots" id="carrots"
                        (change)="handleInput($event, 'carrots')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="carrots_remark" id="carrots_remark" />
                    </td>
                  </tr>

                  <!-- Corn -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Corn</label>
                    </td>
                    <td>
                      <input class="my-3" type="checkbox" value="intact" formControlName="corn" id="corn"
                        (change)="handleInput($event, 'corn')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="corn_remark" id="corn_remark" />
                    </td>
                  </tr>

                  <!-- Marrow -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Marrow</label>
                    </td>
                    <td>
                      <input class="my-3" type="checkbox" value="intact" formControlName="marrow" id="marrow"
                        (change)="handleInput($event, 'marrow')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="marrow_remark" id="marrow_remark" />
                    </td>
                  </tr>

                  <!-- Eggplant -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Eggplant</label>
                    </td>
                    <td>
                      <input class="my-3" type="checkbox" value="intact" formControlName="eggplant" id="eggplant"
                        (change)="handleInput($event, 'eggplant')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="eggplant_remark" id="eggplant_remark" />
                    </td>
                  </tr>

                  <!-- Leafy Veggies -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Leafy Veggies</label>
                    </td>
                    <td>
                      <input class="my-3" type="checkbox" value="intact" formControlName="leafy_veggies"
                        id="leafy_veggies" (change)="handleInput($event, 'leafy_veggies')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="leafy_veggies_remark" id="leafy_veggies_remark" />
                    </td>
                  </tr>

                  <!-- Cruciferous Veggies -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Cruciferous Veggies</label>
                    </td>
                    <td>
                      <input class="my-3" type="checkbox" value="intact" formControlName="cruciferious"
                        id="cruciferious" (change)="handleInput($event, 'cruciferious')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="cruciferious_remark" id="cruciferious_remark" />
                    </td>
                  </tr>

                  <!-- Others -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Others</label>
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Others"
                        formControlName="other" id="other" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="other_remark" id="other_remark" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- Vegitables End -->

        <!-- Dairy Food -->
        <div formGroupName="dairy_food">
          <div class="form-group">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr class="border-light" style="white-space: nowrap">
                    <th scope="col">
                      <label class="subHeading p-0">Dairy Foods</label>
                    </th>
                    <th scope="col"></th>
                    <th scope="col">
                      <label class="subHeading p-0">Remark</label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Yogurt -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Yogurt</label>
                    </td>
                    <td>
                      <input class="my-3" type="checkbox" value="intact" formControlName="yogurt" id="yogurt"
                        (change)="handleInput($event, 'yogurt')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="yogurt_remark" id="yogurt_remark" />
                    </td>
                  </tr>
                  <!-- Cheese -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Cheese</label>
                    </td>
                    <td>
                      <input class="my-3" type="checkbox" value="intact" formControlName="cheese" id="cheese"
                        (change)="handleInput($event, 'cheese')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="cheese_remark" id="cheese_remark" />
                    </td>
                  </tr>

                  <!-- Butter -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Butter</label>
                    </td>
                    <td>
                      <input class="my-3" type="checkbox" value="intact" formControlName="butter" id="butter"
                        (change)="handleInput($event, 'butter')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="butter_remark" id="butter_remark" />
                    </td>
                  </tr>

                  <!-- Others -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Others</label>
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Others"
                        formControlName="other" id="other" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="other_remark" id="other_remark" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- Dairy Food End -->

        <!-- Proteins -->
        <div formGroupName="solid_protein">
          <div class="form-group">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr class="border-light" style="white-space: nowrap">
                    <th scope="col">
                      <label class="subHeading p-0">Proteins</label>
                    </th>
                    <th scope="col"></th>
                    <th scope="col">
                      <label class="subHeading p-0">Remark</label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Chicken -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Chicken</label>
                    </td>
                    <td>
                      <input class="my-3" type="checkbox" value="intact" formControlName="chicken" id="chicken"
                        (change)="handleInput($event, 'chicken')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="chicken_remark" id="chicken_remark" />
                    </td>
                  </tr>
                  <!-- Turkey -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Turkey</label>
                    </td>
                    <td>
                      <input class="my-3" type="checkbox" value="intact" formControlName="turkey" id="turkey"
                        (change)="handleInput($event, 'turkey')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="turkey_remark" id="turkey_remark" />
                    </td>
                  </tr>

                  <!-- Beef -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Beef</label>
                    </td>
                    <td>
                      <input class="my-3" type="checkbox" value="intact" formControlName="beef" id="beef"
                        (change)="handleInput($event, 'beef')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="beef_remark" id="beef_remark" />
                    </td>
                  </tr>

                  <!-- Fish -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Fish</label>
                    </td>
                    <td>
                      <input class="my-3" type="checkbox" value="intact" formControlName="fish" id="fish"
                        (change)="handleInput($event, 'fish')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="fish_remark" id="fish_remark" />
                    </td>
                  </tr>

                  <!-- Eggs -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Eggs</label>
                    </td>
                    <td>
                      <input class="my-3" type="checkbox" value="intact" formControlName="eggs" id="eggs"
                        (change)="handleInput($event, 'eggs')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="eggs_remark" id="eggs_remark" />
                    </td>
                  </tr>

                  <!-- Others -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Others</label>
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Others"
                        formControlName="other" id="other" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="other_remark" id="other_remark" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- Proteins End -->
      </div>

      <!-- Sleeping-->
      <div class="add-listings-box">
        <h3>Sleeping</h3>
        <div formGroupName="sleeping">
          <div class="row">
            <div class="form-group mb-2 col-lg-4 col-sm-6 col-12">
              <div class="form-check pl-0 checkbox-list px-0">
                <label class="checkbox checkbox-outline px-0"> Wake Up </label>
              </div>
            </div>
            <!-- wakeup -->
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="form-group">
                <label class="px-0">Time </label>
                <div class="card flex justify-content-center">
                  <p-calendar placeholder="Time" formControlName="wakeup_time" [timeOnly]="true" [hourFormat]="12"
                    inputId="time"></p-calendar>
                </div>
              </div>
            </div>
          </div>
          <!--naps -->
          <div class="row">
            <div class="form-group mb-2 col-lg-4 col-sm-6 col-12">
              <div class="form-check pl-0 checkbox-list px-0">
                <label class="checkbox checkbox-outline px-0 mt-sm-5 mt-3">
                  Naps
                </label>
              </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-12">
              <div class="form-group">
                <label class="px-0">Time</label>
                <div class="card flex justify-content-center">
                  <p-calendar placeholder="Time" formControlName="nap_time" [timeOnly]="true" [hourFormat]="12"
                    inputId="time"></p-calendar>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="form-group">
                <label class="px-0">Duration </label>
                <div class="card flex justify-content-center">
                  <input type="text" formControlName="nap_duration" class="form-control" placeholder="Duration" />
                </div>
              </div>
            </div>
          </div>
          <!-- Shower -->
          <div class="row">
            <div class="form-group mb-2 col-lg-4 col-sm-6 col-12">
              <div class="form-check pl-0 checkbox-list px-0">
                <label class="checkbox checkbox-outline px-0 mt-sm-5 mt-3">
                  Night Sleep Time
                </label>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="form-group">
                <label class="px-0">Time </label>
                <div class="card flex justify-content-center">
                  <p-calendar placeholder="Time" formControlName="sleep_time" [timeOnly]="true" [hourFormat]="12"
                    inputId="time"></p-calendar>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="form-group">
                <label class="px-0">Duration </label>
                <div class="card flex justify-content-center">
                  <input type="text" formControlName="sleep_duration" class="form-control" placeholder="Duration" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Sleep Training -->
        <div formGroupName="sleep_training_json">
          <div class="form-group mt-3">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr class="border-light" style="white-space: nowrap">
                    <th scope="col">
                      <label class="subHeading p-0">Sleep Training</label>
                    </th>
                    <th scope="col"></th>
                    <th scope="col">
                      <label class="subHeading p-0">Remark</label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Cry Out Method -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Cry Out Method</label>
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="checkbox" value="intact" formControlName="cry_method" id="cry_method"
                        (change)="handleInput($event, 'cry_method')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="cry_method_remark" id="cry_method_remark" />
                    </td>
                  </tr>
                  <!-- Ferber Method -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Ferber Method</label>
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="checkbox" value="intact" formControlName="ferber_method"
                        id="ferber_method" (change)="handleInput($event, 'ferber_method')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="ferber_method_remark" id="ferber_method_remark" />
                    </td>
                  </tr>

                  <!-- Check and Console Method -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Check and Console Method</label>
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="checkbox" value="intact" formControlName="check_method"
                        id="check_method" (change)="handleInput($event, 'check_method')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="check_method_remark" id="check_method_remark" />
                    </td>
                  </tr>

                  <!-- Fading/ Chair Method -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Fading/ Chair Method</label>
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="checkbox" value="intact" formControlName="fading_method"
                        id="fading_method" (change)="handleInput($event, 'fading_method')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="fading_method_remark" id="fading_method_remark" />
                    </td>
                  </tr>

                  <!-- "No Tears" Method -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">"No Tears" Method</label>
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="checkbox" value="intact" formControlName="no_tear" id="no_tear"
                        (change)="handleInput($event, 'no_tear')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="no_tear_remark" id="no_tear_remark" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- sleep training End -->

        <!-- Bed Time -->
        <div formGroupName="bed_time_routine_json">
          <div class="form-group mt-3">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr class="border-light" style="white-space: nowrap">
                    <th scope="col">
                      <label class="subHeading p-0">Bed Time</label>
                    </th>
                    <th scope="col"></th>
                    <th scope="col">
                      <label class="subHeading p-0">Remark</label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Bath -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Bath</label>
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="checkbox" value="intact" formControlName="bath" id="bath"
                        (change)="handleInput($event, 'bath')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="bath_remark" id="bath_remark" />
                    </td>
                  </tr>
                  <!-- Feed -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Feed</label>
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="checkbox" value="intact" formControlName="feed" id="feed"
                        (change)="handleInput($event, 'feed')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="feed_remark" id="feed_remark" />
                    </td>
                  </tr>

                  <!-- Sing a song -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Sing a song</label>
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="checkbox" value="intact" formControlName="sing" id="sing"
                        (change)="handleInput($event, 'sing')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="sing_remark" id="sing_remark" />
                    </td>
                  </tr>

                  <!-- Read a book aloud -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Read a book aloud</label>
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="checkbox" value="intact" formControlName="read_book" id="read_book"
                        (change)="handleInput($event, 'read_book')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="read_book_remark" id="read_book_remark" />
                    </td>
                  </tr>

                  <!-- Avoiding screen time -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Avoiding screen time</label>
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="checkbox" value="intact" formControlName="avoid_screen"
                        id="avoid_screen" (change)="handleInput($event, 'avoid_screen')" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="avoid_screen_remark" id="avoid_screen_remark" />
                    </td>
                  </tr>

                  <!-- Others -->
                  <tr class="border-light">
                    <td>
                      <label class="my-3 p-0">Others</label>
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Others"
                        formControlName="other" id="other" />
                    </td>
                    <td class="col-4">
                      <input class="my-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                        formControlName="other_remark" id="other_remark" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- Bed Time End -->
      </div>

      <!-- Sleeping End -->

      <!-- Bathing -->
      <div class="add-listings-box">
        <h3>Bathing</h3>
        <div formGroupName="bathing_json">
          <div class="row">
            <div class="col-lg-4 col-sm-6 col-12 d-flex align-items-center">
              <div class="form-group mb-0">
                <!-- <label class="subHeading py-3 px-0 mb-0">Bathing:</label> -->
                <div class="form-check p-0 checkbox-list">
                  <label class="checkbox checkbox-outline p-0">
                    <p>
                      <input formControlName="bathing_type" type="radio" value="Sponge" id="sponge"
                        (change)="handleInput($event, 'bathing')" />
                      <label for="sponge">Sponge</label>
                    </p>
                  </label>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-12">
              <div class="form-group">
                <label class="p-0">Frequency:</label>
                <div class="card flex justify-content-center">
                  <p-dropdown formControlName="bathing_type_sponge_frequency" [options]="frequency" optionLabel="name"
                    [filter]="true" filterBy="frequency" placeholder="Frequency" [resetFilterOnHide]="true"
                    [class.is-invalid]="(monitoringForm.submitted &&
                     nannyDailyMonitoring.get(['bathing_json','bathing_type_sponge_frequency'])?.invalid)
                    ||
                    nannyDailyMonitoring.get(['bathing_json','bathing_type_sponge_frequency'])?.invalid
                    &&
                    nannyDailyMonitoring.get(['bathing_json','bathing_type_sponge_frequency']).touched">
                  </p-dropdown>
                  <div class="invalid-feedback">
                    Frequency is required
                  </div>
                </div>
              </div>
            </div>
            <!-- Tub bathing -->
            <div class="row m-0 p-0">
              <div class="col-lg-4 col-sm-6 col-12 d-flex align-items-center">
                <div class="form-group mb-0">
                  <div class="form-check p-0 checkbox-list">
                    <label class="checkbox checkbox-outline p-0">
                      <p>
                        <input formControlName="bathing_type" type="radio" value="Tub" id="tub"
                          (change)="handleInput($event, 'bathing')" />
                        <label for="tub">Tub</label>
                      </p>
                    </label>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-sm-6 col-12">
                <div class="form-group">
                  <label class="p-0">Frequency:</label>
                  <div class="card flex justify-content-center">
                    <p-dropdown formControlName="bathing_type_tub_frequency" [options]="frequency" [disabled]="tub"
                      optionLabel="name" [filter]="true" filterBy="frequency" placeholder="Frequency"
                      [resetFilterOnHide]="true" [class.is-invalid]="(monitoringForm.submitted &&
                    nannyDailyMonitoring.get(['bathing_json','bathing_type_tub_frequency'])?.invalid)
                   ||
                   nannyDailyMonitoring.get(['bathing_json','bathing_type_tub_frequency'])?.invalid
                   &&
                   nannyDailyMonitoring.get(['bathing_json','bathing_type_tub_frequency']).touched">
                    </p-dropdown>
                    <div class="invalid-feedback">
                      Frequency is required
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- diapering -->

      <div class="add-listings-box">
        <h3>Diapering</h3>
        <div formArrayName="diapering">
          <ng-container *ngFor="
            let control of getDiaperingFormArray().controls;
            index as i;
            last as last
          ">
            <div class="row" [formGroupName]="i">
              <!-- <div class="col-sm-1">
                            {{i + 1}}
                        </div> -->
              <div class="form-group mb-0 col-lg-4 col-sm-6 col-12">
                <div class="form-check pl-0 checkbox-list px-0">
                  <label class="checkbox checkbox-outline px-0">
                    <input type="checkbox" value="urine_des" formControlName="urine"
                      (change)="handleInput($event, 'diaper', i)" />
                    <span></span>
                    Urine
                  </label>
                  <div class="form-group">
                    <label class="px-0">Description</label>
                    <div class="card flex justify-content-center">
                      <p-dropdown [options]="urineValues" formControlName="urine_des" optionLabel="name_description"
                        [filter]="true" filterBy="name" optionValue="name_description" placeholder="Select Value"
                        [resetFilterOnHide]="true"   [class.is-invalid]="(control.get('urine_des').invalid)|| (control.get('urine_des').invalid&& control.get('urine_des').touched)">
                       
                      </p-dropdown>
                      <div class="invalid-feedback">
                    Description is required
                </div>
                      

                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group mb-0 col-lg-4 col-sm-6 col-12">
                <div class="form-check pl-0 checkbox-list px-0">
                  <label class="checkbox checkbox-outline px-0">
                    <input type="checkbox" value="stool_des" formControlName="stool"
                      (change)="handleInput($event, 'diaper', i)" />
                    <span></span>
                    Stool
                  </label>
                  <div class="form-group">
                    <label class="px-0">Description</label>
                    <div class="card flex justify-content-center">
                      <p-dropdown [options]="stoolValues" formControlName="stool_des" optionLabel="name_description"
                        [filter]="true" filterBy="name" optionValue="name_description" placeholder="Select Value"
                        [resetFilterOnHide]="true"  [class.is-invalid]="(control.get('stool_des').invalid)|| (control.get('stool_des').invalid&& control.get('stool_des').touched)">
                      </p-dropdown>
                      <div class="invalid-feedback">
                        Description is required
                      </div>
                    </div>
                    <!-- (monitoringForm.submitted &&
                    nannyDailyMonitoring.get('baby_details_id')?.invalid) ||
                  (nannyDailyMonitoring.get('baby_details_id')?.invalid &&
                    nannyDailyMonitoring.get('baby_details_id').touched) -->
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-12 d-flex justify-content-end align-items-center">
                <p-button type="button" icon="bx bx-plus" (click)="addDiaperingObject('add', i)" iconPos="right"
                  class="mx-2"></p-button>
                <div class="remove">
                  <p-button type="button" *ngIf="i != 0" class="mx-0" icon="bx bx-minus"
                    (click)="addDiaperingObject('delete', i)" iconPos="right"></p-button>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- temp monitoring -->

      <div class="add-listings-box">
        <h3>Temperature Monitoring</h3>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <div class="card flex justify-content-center">
                <input type="text" formControlName="temperature_monitoring" class="form-control"
                  placeholder="Temprature(°C)" [class.is-invalid]="(monitoringForm.submitted &&
                nannyDailyMonitoring.get('temperature_monitoring')?.invalid)
                ||
                nannyDailyMonitoring.get('temperature_monitoring')?.invalid
                &&
                nannyDailyMonitoring.get('temperature_monitoring').touched" />
                <div class="invalid-feedback">
                  Invalid Temperature, must be in °C
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- vitamins/medication  -->

      <div class="add-listings-box">
        <h3>Vitamins/Medications</h3>
        <div formArrayName="vitamins_and_medications">
          <ng-container *ngFor="
            let control of getVitaminFormArray().controls;
            index as i;
            last as last
          ">
            <div class="row" [formGroupName]="i">
              <div class="col-md-11 col-sm-10 col-12">
                <div class="form-group">
                  <label class="px-0">Vitamins/ Medications</label>
                  <div class="pl-0 checkbox-list">
                    <input type="text" formControlName="vitamin" class="form-control"
                      placeholder="Vitamins/ Medications" />
                  </div>
                </div>
              </div>
              <div class="col-md-1 col-sm-2 col-12 mt-2 d-flex justify-content-end align-items-center">
                <p-button type="button" icon="bx bx-plus" (click)="addVitaminObject('add', i)" iconPos="right"
                  class="mr-2"></p-button>
                <div class="remove">
                  <p-button type="button" *ngIf="i != 0" class="ml-2" icon="bx bx-minus"
                    (click)="addVitaminObject('delete', i)" iconPos="right"></p-button>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- REMARKS/NOTES  -->

      <div class="add-listings-box">
        <h3>Remarks/Notes</h3>
        <div class="row">
          <div class="col-lg-12 col-12">
            <div class="form-group">
              <div class="form-group">
                <div class="form-group">
                  <textarea formControlName="remarks" class="form-control" rows="8" placeholder="Remarks"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="add-listings-btn" style="text-align: right">
        <button type="submit">Submit</button>
      </div>
    </div>
  </form>
  <app-copyrights></app-copyrights>
</div>