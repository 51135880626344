<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->


<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">
    <app-dashboard-navbar></app-dashboard-navbar>


    <!-- Breadcrumb Area -->
    <div *ngFor="let Content of breadcrumb" class="breadcrumb-area">
        <h1>{{ Content.title }}</h1>
        <ol class="breadcrumb">
            <li class="item">
                <a routerLink="/">Home</a>
            </li>
            <li class="item">
                {{ Content.subTitle }}
            </li>
            <li class="item">{{ Content.title }}</li>
        </ol>
    </div>


    <!-- <div class="add-listings-box">
      <div class="row justify-content-between align-items-center">
          <div class="form-group col-4">
              <label class="p-0">Name <span class="required">*</span></label>
              <input type="text" name="name" class="form-control" placeholder="Name" (change)="getInput($event)" />
          </div>
          <div class="add-listings-btn mb-0 col-4 text-end">
              <button type="submit" (click)="addTableData()">Add</button>
          </div>
      </div>
  </div> -->
    <div class="add-listings-box">
        <div class="Master-view-box">
            <div class="theader d-flex justify-content-between flex-wrap">
                <div class="d-flex mb-2">
                    <span class="p-input-icon-left ml-auto mr-2 w-100%">
                        <i class="pi pi-search"></i>
                        <input (input)="dt1.filterGlobal($event.target.value, 'contains')" pInputText type="text"
                            class="form-control" style="text-align: left; padding-left: 20%; height: 45px !important"
                            scriptInjection placeholder="Search Keyword" [(ngModel)]="filterVal">
                    </span>
                    <p-button type="button" class="mx-2" icon="bx bx-reset" tooltipPosition="bottom"
                        (click)="reset(dt1)"></p-button>
                </div>



                <!-- <div class="add-listings-btn" style="text-align: right">
					<a routerLink="/portal/nanny-task" class="nav-link">
						<button type="submit">Add</button>
					</a>
				</div> -->
            </div>
        </div>


        <div class="row mt-4">
            <div class="col-lg-12 col-md-12">
                <p-table #dt1 [value]="getNusreData" [rows]="10" [showCurrentPageReport]="true"
                    [tableStyle]="{ 'min-width': '50rem', width: '100%' }" [rowsPerPageOptions]="[10, 25, 50]"
                    [loading]="loading" [paginator]="true" [globalFilterFields]="['name', 'date_of_birth', 'age']"
                    dataKey="baby_id" styleClass="p-datatable-sm p-datatable-customers" responsiveLayout="scroll"
                    currentPageReportTemplate="Showing {first} to
					{last} of {totalRecords} entries">
                    <ng-template pTemplate="header">
                        <tr>
                            <th></th>
                            <th style="min-width: 62px">S. No.</th>
                            <th>Name</th>
                            <th>Date Of Birth</th>
                            <th>Days</th>
                            <th>Months</th>
                            <th>Years</th>
                        </tr>
                    </ng-template>


                    <!-- Expandable icon -->
                    <ng-template pTemplate="body" let-product let-expanded="expanded" let-i="rowIndex">
                        <tr>
                            <td>
                                <button [pRowToggler]="product" [icon]="
                                    expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
                                " (click)="getData(product.baby_id)" type="button" pButton pRipple
                                    class="p-button-text p-button-rounded p-button-plain"></button>
                            </td>
                            <td>{{ i + 1 }}</td>
                            <td>
                                {{ product?.name ?? "-" | titlecase }}
                            </td>
                            <td>
                                {{ product?.date_of_birth ?? "-" }}
                            </td>
                            <td>
                                {{product?.days ?? "-" }}
                            </td>
                            <td>
                                {{product?.months ?? "-" }}
                            </td>
                            <td>
                                {{product?.years ?? "-" }}
                            </td>
                            <!-- <td>
								<div class="add-listings-btn" style="text-align: right">
									<a routerLink="/portal/nanny-task" class="nav-link">
										<button type="submit">Add</button>
									</a>
								</div>
							</td> -->
                        </tr>
                    </ng-template>
                    <!-- table to show data -->
                    <!-- <ng-template pTemplate="body" let-list let-i="rowIndex">
                      <tr>
                          <td>
                              {{ i + 1 }}
                          </td>
                          <td><button pButton pRipple class="p-button-rounded p-button-text p-button-info"
                                  pTooltip="View" tooltipPosition="bottom"><i (click)="showMaximizableDialog(list.id)"
                                      class="far fa-eye"> click</i></button></td>
                          <td>{{ list?.name ?? '-' }}</td>
                          <td>{{ list?.date_of_birth ?? '-' }}</td>
                          <td>{{ list?.age ?? '-' }}</td>
                      </tr>
                  </ng-template> -->

                    <!-- Expanded table data -->
                    <ng-template pTemplate="rowexpansion" let-product let-i="rowIndex">
                        <!-- <p>[{{ list.nanny_tasks_plan_details }}]</p> -->
                        <tr>
                            <td colspan="7" class="inner-data">
                                <div class="p-3 py-0">
                                    <p-table [value]="product.nanny_tasks_plan_details" dataKey="baby_id">
                                        <ng-template pTemplate="header">
                                            <!-- Add the header columns for nurse task details -->
                        <tr>
                            <th pSortableColumn="id">S. No</th>
                            <th pSortableColumn="customer">Action</th>
                            <th pSortableColumn="date">Date</th>
                            <!-- Add columns for nurse task details here -->
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-order let-ind="rowIndex">
                        <tr>
                            <td>{{ ind + 1 }}</td>
                            <td>
                                <button pButton pRipple class="p-button-rounded p-button-text p-button-info"
                                    pTooltip="View" tooltipPosition="bottom" (click)="showView(order.nanny_task_id)">
                                    <i class="far fa-eye"></i>
                                </button>
                                <button *ngIf="ind == 0 && checkPermission(31)" pButton pRipple
                                    class="p-button-rounded p-button-text p-button-info view-button" pTooltip="Edit"
                                    tooltipPosition="bottom"
                                    (click)="editOnCLick(product.baby_id,order.nanny_task_id)"><i
                                        class="far fa-pencil"></i>
                                </button>
                            </td>
                            <td>
                                {{ order?.created_at || order?.nanny_task_created_at | date: 'dd-MM-yyyy' || "-" }}
                            </td>
                            <!-- Bind nurse task details here -->
                            <!-- For example, assuming order contains nurse task details -->
                            <!-- <td>{{ order?.nurseTaskDetailField }}</td> -->
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="6">
                                There are no nurse tasks for this product yet.
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            </td>
            </tr>
            </ng-template>


            </p-table>
        </div>
    </div>
    <!----------------------------------------------------------- view dialog --------------------------------------------------------->
    <!-- ***************************************************************************************************************** -->
    <p-dialog header="Zero to Three Months Baby Details" [(visible)]="ShowZeroToThreeMonths" [modal]="true"
        [style]="{ width: '90vw' }" [draggable]="false" [resizable]="false" [closable]="false">
        <!-- Start -->
        <ng-container>
            <div class="row viewSummary ">
                <div class="col-lg-12">
                    <div class="my-profile-box">
                        <form>
                            <!-- Baby detail -->
                            <!-- accordian on listing -->
                            <p-accordion>
                                <p-accordionTab header="Baby Details">
                                    <div class="row peach-bg">
                                        <!-- <h3>Baby Details</h3> -->
                                        <div class="my-3">
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Name :</label>
                                                        <div type="text" readonly>
                                                            {{viewDetail?.name ?? '-' | titlecase }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12
                col-md-12">
                                                    <div class="form-group">
                                                        <label>Gender :</label>
                                                        <div type="text" readonly>
                                                            {{viewDetail?.gender ?? '-' | titlecase }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Date of Birth :</label>
                                                        <div type="text" readonly>
                                                            {{viewDetail?.date_of_birth ?? '-' | titlecase }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Days :</label>
                                                        <div type="text" readonly>
                                                            {{viewDetail?.days ?? '-' }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Months :</label>
                                                        <div type="text" readonly>
                                                            {{viewDetail?.months ?? '-'  }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Years :</label>
                                                        <div type="text" readonly>
                                                            {{viewDetail?.years ?? '-'   }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
            col-md-12">
                                                    <!-- <div class="form-group">
                <label for="">Food/Medicine Allergy</label>
                <input type="text" readonly class="form-control" value="{{ viewDetail?.food_medicine_allergy== 1? 'Yes': '-' ?? '-'
            }}" />
            </div> -->
                                                    <ng-container>
                                                        <div class="form-group">
                                                            <label for="">Food/Medicine Allergy</label>
                                                            <div type="text" readonly *ngIf="ViewDialogeData?.food_medicine_allergy !==
                                                null && ViewDialogeData?.food_medicine_allergy
                                                !== '' && ViewDialogeData?.food_medicine_allergy
                                                !== undefined; else elseBlock">

                                                                {{ ViewDialogeData?.food_medicine_allergy == '1'? 'Yes': 'No'}}
                                                            </div>
                                                            <ng-template #elseBlock>
                                                                <div type="text" readonly>
                                                                    {{"-"}}
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                    </ng-container>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Food Component</label>
                                                        <div type="text" readonly>
                                                            {{ViewDialogeData?.food_medicine_allergy_specify_prod??'-' }}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- feeding assesment listing  -->
                                <p-accordionTab header="Feeding Assesment">
                                    <div class="row peach-bg">
                                        <div class="my-3">
                                            <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                            <div class="row">
                                                <!-- <label class="subHeading my-2">Assesment</label> -->
                                                <div class="col-xl-6 col-lg-12
            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Baby is not interested when offered to
                                                            breastfeed,
                                                            sleepy</label>
                                                        <div type="text" readonly>
                                                            {{ViewDialogeData?.answer ?? '-' | titlecase }}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Baby is showing feeding cues but not
                                                            attaching</label>
                                                        <div type="text" readonly>
                                                            {{ViewDialogeData?.attach ?? '-' | titlecase }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">

                                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Attaches at the breast but quickly falls
                                                            asleep</label>
                                                        <div type="text" readonly>
                                                            {{ViewDialogeData?.Asleep ?? '-' | titlecase }}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Attaches for short bursts with long
                                                            pause</label>
                                                        <div type="text" readonly>
                                                            {{ViewDialogeData?.LongPause ?? '-' | titlecase }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">

                                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Attaches well with long rhythmical sucking and
                                                            swallowing
                                                            for a short feed</label>
                                                        <div type="text" readonly>
                                                            {{ViewDialogeData?.rhythmical ?? '-' | titlecase }}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Attaches well for a sustained period with long
                                                            rhythmical
                                                            sucking and swallowing</label>
                                                        <div type="text" readonly>
                                                            {{ViewDialogeData?.swallowing ?? '-' | titlecase }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">

                                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Normal skin color and tone</label>
                                                        <div type="text" readonly>
                                                            {{ViewDialogeData?.Normal_skin ?? '-' | titlecase }}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Gaining weight appropriately</label>
                                                        <div type="text" readonly>
                                                            {{ViewDialogeData?.weight ?? '-' | titlecase }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Other Concerns</label>
                                                        <div type="text" readonly>
                                                            {{ViewDialogeData?.Other_concerns ?? '-' | titlecase }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- DAILY CARE -->
                                <p-accordionTab header="Daily Cares">


                                    <div class="row peach-bg">
                                        <div class="my-3">
                                            <!-- <label class="subHeading my-2">Feeding: Prepared By</label> -->
                                            <div type="text">
                                                Feeding: Prepared By
                                                {{
                                            (ViewDialogeData?.feeding_prepared_mother == 1 ? 'Mother' : '') +
                                            (ViewDialogeData?.feeding_prepared_mother == 1 &&
                                            ViewDialogeData?.feeding_prepared_nanny == 1 ? ' and ' : '') +
                                            (ViewDialogeData?.feeding_prepared_nanny == 1 ? 'Nurse' : '') +
                                            (ViewDialogeData?.feeding_prepared_other == 1 &&
                                            (ViewDialogeData?.feeding_prepared_mother == 1 ||
                                            ViewDialogeData?.feeding_prepared_nanny == 1) ? ', ' : '') +
                                            (ViewDialogeData?.feeding_prepared_other == 1 ? 'Other' : '')
                                            }}
                                            </div>
                                        </div>
                                    </div>

                                </p-accordionTab>
                                <p-accordionTab header="Milk Feeding">
                                    <div class="add-listings-box py-0 peach-bg">
                                        <h3 class="mb-0">Breast Feeding Information</h3>
                                        <!-- <div class="row">
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <div class="form-group">
                                                    <label for="">Breast</label>
                                                    <div type="text" readonly>{{ ViewDialogeData?.feeding_breast == 1? 'Yes': '-'
                                            }}</div>
                                                </div>
                                            </div>

                                            <div class="col-xl-4 col-lg-12
        col-md-12">
                                                <div class="form-group">
                                                    <label for="">Frequency</label>
                                                    <div type="text" readonly>
                                                        {{ViewDialogeData?.feeding_frequency_breast?? '-'}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngFor="let time of ViewDialogeData?. breast_feeding ">
                                                <div class="col-xl-4 col-lg-12
            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Done or Not</label>
                                                        <div type="text" readonly>{{ time?.feeding_breast_checkbox == 1? 'Yes': '-'
                                            }}</div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12
    col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Reference Time</label>
                                                        <div type="text" readonly>
                                                            {{formatDateTime(time?.feeding_breast_time)}}
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12
    col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Time</label>
                                                        <div type="text" readonly>
                                                            {{formatDateTime(time?.feeding_breast_actual_time)}}
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12
            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Remark</label>
                                                        <div type="text" readonly>
                                                            {{time?.breast_feeding_remark??'-' }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="dataBydate">
                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>
                                                    <th>Breast</th>
                                                    <th>Frequency</th>
                                                    <th>Reference Time</th>
                                                    <th>Time</th>
                                                    <th>Done or Not</th>
                                                    <th>Remark</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let time of ViewDialogeData?.breast_feeding; let i = index">
                                                    <td>{{ ViewDialogeData?.feeding_breast == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ ViewDialogeData?.feeding_frequency_breast ?? '-' }}</td>
                                                    <td>{{ formatDateTime(time?.feeding_breast_time) }}</td>
                                                    <td>{{ formatDateTime(time?.feeding_breast_actual_time) }}</td>
                                                    <td>{{ time?.feeding_breast_checkbox == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ time?.breast_feeding_remark ?? '-' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    </div>
                                    <div class="add-listings-box py-0 peach-bg">
                                        <h3 class="mb-0">Formula Feeding Information Summary</h3>
                                        <div class="dataBydate">
                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>
                                                    <th>Formula</th>
                                                    <th>Frequency</th>
                                                    <th>Start Time</th>
                                                    <th>End Time</th>
                                                    <th>Amount</th>
                                                    <th>Type of Formula</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{{ ViewDialogeData?.feeding_type == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ ViewDialogeData?.feeding_frequency?.frequency ?? '-' }}</td>
                                                    <td>{{ formatDateTime(ViewDialogeData?.feeding_time) }}</td>
                                                    <td>{{ formatDateTime(ViewDialogeData?.feeding_breast_time_end) }}
                                                    </td>
                                                    <td>{{ ViewDialogeData?.feeding_amount_per ?? '-' }}</td>
                                                    <td>{{ ViewDialogeData?.type_of_formula ?? '-' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                        <div class="add-listings-box py-0 my-3">
                                            <h3 class="mb-0">Formula Feeding Time and Amount </h3>
                                            <div class="dataBydate">
                                            <table class="table font-size-13">
                                                <thead>
                                                    <tr>
                                                        <th>Reference Time</th>
                                                        <th>Actual Time</th>
                                                        <th>Done or Not</th>
                                                        <th>Amount</th>
                                                        <th>It Is Same Formula?</th>
                                                        <th>Name of Formula</th>
                                                        <th>Remark</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let data of ViewDialogeData?.formula_calculation">
                                                        <td>{{ formatDateTime(data?.formula_timeArray) }}</td>
                                                        <td>{{ formatDateTime(data?.nurse_feeding) }}</td>
                                                        <td>{{ data?.feeding_checkbox == 1 ? 'Yes' : '-' }}</td>
                                                        <td>{{ data?.amount ?? '-' }}</td>
                                                        <td>{{ data?.is_same_formula ?? '-' }}</td>
                                                        <td>{{ data?.remark ?? '-' }}</td>
                                                        <td>{{ data?.formula_remark ?? '-' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- DAILY CARE END-->

                                <!-- Bottle Sterilization -->
                                <p-accordionTab header="Bottle Sterilization">
                                    <div class="add-listings-box py-0">

                                        <h3>Bottle Sterilization</h3>
                                        <div class="add-listings-box py-0 peach-bg">
                                            <h3 class="mb-0">All at a time Bottle Sterilization </h3>
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Done or Not</label>
                                                        <div type="text" readonly>{{ ViewDialogeData?.bottle_sterilization_all == 1? 'Yes': '-'
                                                }}</div>
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
        col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Time</label>
                                                        <div type="text" readonly>
                                                            {{formatDateTime(ViewDialogeData?.bottle_sterilization_all_time)}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="add-listings-box py-0 my-3 peach-bg">
                                            <h3 class="mb-0">One at a time Bottle Sterilization</h3>
                                            <!-- Displaying the first row outside of the ngFor loop -->
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="">Bottle Sterilization</label>
                                                        <div type="text" readonly>
                                                            {{ ViewDialogeData?.bottle_sterilization_onetime == 1 ? 'Yes' : '-' }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Displaying the data in a table format using ngFor loop -->
                                            <div class="dataBydate">
                                            <table class="table font-size-13">
                                                <thead>
                                                    <tr>
                                                        <th>Time</th>
                                                        <th>Actual Time</th>
                                                        <th>Done or Not</th>
                                                        <th>Remark</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let data of ViewDialogeData?.bottle_sterilization">

                                                        <td>{{ formatDateTime(data?.sterilization_timeArray) }}</td>
                                                        <td>{{ formatDateTime(data?.actual_sterilization_timeArray) }}
                                                        </td>
                                                        <td>{{ data?.done_sterilization == 1 ? 'Yes' : '-' }}</td>
                                                        <td>{{ data?.sterilization_remark ?? '-' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Bottle Sterilization End-->

                                <!-- Food/Medicine Allergy -->
                                <p-accordionTab header="Food/Medicine Allergy"
                                    [class.allergyhighlight]="ViewDialogeData?.food_medicine_allergy == '1'">
                                    <div class="row peach-bg">
                                        <div class="my-3">
                                            <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                            <!-- <h3>Food/Medicine Allergy</h3> -->
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
            col-md-12">
                                                    <!-- <div class="form-group">
                <label for="">Food/Medicine Allergy</label>
                <input type="text" readonly class="form-control" value="{{ viewDetail?.food_medicine_allergy== 1? 'Yes': '-' ?? '-'
            }}" />
            </div> -->
                                                    <ng-container>
                                                        <div class="form-group">
                                                            <label for="">Food/Medicine Allergy</label>
                                                            <div type="text" readonly *ngIf="ViewDialogeData?.food_medicine_allergy !==
                                                null && ViewDialogeData?.food_medicine_allergy
                                                !== '' && ViewDialogeData?.food_medicine_allergy
                                                !== undefined; else elseBlock">

                                                                {{ ViewDialogeData?.food_medicine_allergy == '1'? 'Yes': 'No'}}
                                                            </div>
                                                            <ng-template #elseBlock>
                                                                <div type="text" readonly>
                                                                    {{"-"}}
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                    </ng-container>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Food Component</label>
                                                        <div type="text" readonly>
                                                            {{ViewDialogeData?.food_medicine_allergy_specify_prod??'-' }}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Food/Medicine Allergy End-->
                                <p-accordionTab header="Bathing">
                                    <div class="add-listings-box py-0 peach-bg">
                                        <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                        <h3>Bathing</h3>
                                        <div class="row">
                                            <!-- Sponge -->
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <div >
                                                    <label for="">Sponge</label>
                                                    <div type="text" readonly>{{ ViewDialogeData?.bathing_sponge == 1? 'Yes': '-'
                                            }}</div>

                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <div class="form-group">
                                                    <label for="">Frequency</label>
                                                    <div type="text" readonly>
                                                        {{ViewDialogeData?.bathing_frequency_sponge??'-' }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="dataBydate">
                                            <table class="table font-size-13">
                                                <thead>
                                                    <tr>
                                                        <th>Reference Time</th>
                                                        <th>Actual Time</th>
                                                        <th>Done or Not</th>
                                                        <th>Remark</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let time of ViewDialogeData?.bathing_time">

                                                        <td>{{ formatDateTime(time?.bathing_sponge_time) }}</td>
                                                        <td>{{ formatDateTime(time?.bathing_sponge_actual_time) }}</td>
                                                        <td>{{ time?.sponge_checkbox == 1 ? 'Yes' : '-' }}</td>
                                                        <td>{{ time?.spongeBathing_remark ?? '-' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        </div>
                                        <!-- Tub -->
                                        <div class="row">
                                            <div class="col-xl-4 col-lg-12
col-md-12">
                                                <div class="form-group">
                                                    <label for="">Tub</label>
                                                    <div type="text" readonly>{{ ViewDialogeData?.bathing_tub == 1? 'Yes': '-'
                                            }}</div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12 col-md-12">
                                                <div class="form-group">
                                                    <label for="">Frequency</label>
                                                    <div type="text" readonly>
                                                        {{ViewDialogeData?.bathing_tub_frequency??'-' }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="dataBydate">
                                            <table class="table font-size-13">
                                                <thead>
                                                    <tr>


                                                        <th>Reference Time</th>
                                                        <th>Actual Time</th>
                                                        <th>Done or Not</th>
                                                        <th>Remark</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let time of ViewDialogeData?.tub_bathing">
                                                        <td>{{ formatDateTime(time?.bathing_tub_time) }}</td>
                                                        <td>{{ formatDateTime(time?.bathing_tub_actual_time) }}</td>
                                                        <td>{{ time?.tub_checkbox == 1 ? 'Yes' : '-' }}</td>
                                                        <td>{{ time?.tubBathing_remark ?? '-' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- BATHING END -->
                                <!-- diaper change -->
                                <p-accordionTab header="Diaper Change">
                                    <div class="add-listings-box py-0 peach-bg">
                                    <div class="row peach-bg">
                                        <div class="my-3">
                                           
                                            <table class="table font-size-13">
                                                <thead>
                                                    <tr>
                                                        <th>Frequency</th>
                                                        <th>Reference Time</th>
                                                        <th>Actual Time</th>
                                                        <th>Done or Not</th>
                                                        <th>Remark</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let data of ViewDialogeData?.diaper_calculation">
                                                        <td>{{ (ViewDialogeData?.diaper_change_frequency) }}</td>
                                                        <td>{{ formatDateTime(data?.diaper_timeArray) }}</td>
                                                        <td>{{ formatDateTime(data?.diaper_actual_time) }}</td>
                                                        <td>{{ data?.diaper_checkbox == 1 ? 'Yes' : '-' }}</td>
                        
                                                        <td>{{ data?.diaperRemark ?? '-' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <h3>Additional Diaper Change</h3>
                                            <table class="table font-size-13">
                                              
                                                <thead>
                                                    <tr>
                                                        <th>Diaper Change</th>
                                                        <th>Change Time</th>
                                                        <th>Remark</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let change of ViewDialogeData?.diaper_change_times">
                                                        <td>
                                                            <div class="form-group">

                                                                <div type="text" readonly>
                                                                    {{ change?.change ? 'Yes' : 'No' }}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-group">

                                                                <div type="text" readonly>
                                                                    {{ formatDateTime(change?.time) }}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-group">

                                                                <div type="text" readonly>
                                                                    {{ change?.diaper_remark ?? '-' }}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Skin Cares -->
                                <p-accordionTab header="Skin Care">
                                    <div class="row peach-bg">
                                        <div class="my-3">
                                            <!--  <h3 class="mb-0">Assesment Information Summary</h3> -->
                                            <!-- <h3>Skin Cares</h3> -->
                                            <div class="row" *ngFor="let care of ViewDialogeData?.skin_care">
                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group viewSummary">
                                                        <label for="">Skin care product for baby:</label>
                                                        <div type="text"> {{
                                                (care?.skin_care_lotion == 1 ? 'Lotion' : '') +
                                                (care?.skin_care_lotion == 1 &&
                                                care?.skin_care_baby_oil == 1 ? ' and ' : '') +
                                                (care?.skin_care_baby_oil == 1 ? 'Baby Oil' : '') +
                                                (care?.skin_care_others == 1 &&
                                                (care?.skin_care_lotion == 1 ||
                                                care?.skin_care_baby_oil == 1) ? ', ' : '') +
                                                (care?.skin_care_others == 1 ? 'Others' : '')
                                                }}</div>

                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                col-md-12">
                                                    <div class="form-group">
                                                        <label>Specify Product</label>
                                                        <div type="text" readonly>{{ care?.skin_care_specify_produ ?care?. skin_care_specify_produ:'-'
                }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Skin Cares End-->

                                <!-- Wipes -->
                                <p-accordionTab header="Wipes">
                                    <div class="row peach-bg">
                                        <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                        <!-- <h3>Wipes</h3> -->
                                        <div class="row my-3">
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <!-- <div class="form-group">
                <label>Wipes</label>
                <input type="text" readonly class="form-control" value="{{ viewDetail?.skin_care_Wipes== 1? 'Yes': '-' ?? '-'
                }}" />
            </div> -->
                                                <ng-container>
                                                    <div class="form-group">
                                                        <label for="">Wipes</label>
                                                        <div type="text" readonly>

                                                            {{ ViewDialogeData?.skin_care_Wipes == '1'? 'Yes': 'No'}}
                                                        </div>
                                                        <ng-template #elseBlock>
                                                            <div type="text" readonly>
                                                                {{"-"}}
                                                            </div>
                                                        </ng-template>
                                                    </div>
                                                </ng-container>
                                            </div>

                                            <div class="col-xl-4 col-lg-12
                col-md-12">
                                                <div class="form-group">
                                                    <label>Special Instruction</label>
                                                    <div type="text" readonly>{{ ViewDialogeData?.skin_care_special_instructions ?? '-'
                }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Wipes End-->

                                <!-- Changing Clothes -->
                                <p-accordionTab header="Changing Clothes">
                                    <div class="add-listings-box py-0 peach-bg">
                                        <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                        <!-- <h3>Changing Clothes</h3> -->
                                        <div class="row my-3 dataBydate">


                                            <div class="col-xl-4 col-lg-12 col-md-12">
                                                <div class="form-group">
                                                    <label>Special Instructions</label>
                                                    <div type="text" readonly>
                                                        {{ ViewDialogeData?.changing_colthes_spiecal_instructions ?? '-'}}
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="dataBydate">
                                            <table class="table font-size-13">
                                                <thead>
                                                    <tr>
                                                        <th>Changing Clothes</th>
                                                        <th>Changing Time</th>
                                                        <th>Actual Time</th>
                                                        <th>Remarks</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let change of ViewDialogeData?.changing_clothes">
                                                        <td>{{ change?.changing_colthes == 1 ? 'Yes' : '-' }}</td>
                                                        <td>{{ formatDateTime(change?.Changing_time) }}</td>
                                                        <td>{{ formatDateTime(change?.Changing_actual_time) }}</td>
                                                        <td>{{ change?.clothe_remark ?? '-' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Changing Clothes End-->

                                <!-- Sleeping -->
                                <p-accordionTab header="Sleeping">
                                    <div class="add-listings-box py-0 peach-bg">
                                        <div class="row my-3 dataBydate">
                                            <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                            <!-- <h3>Sleeping</h3> -->
                                            <div class="dataBydate">
                                            <table class="table font-size-13">
                                                <thead>
                                                    <tr>
                                                        <th>With Mother</th>
                                                        <th>Reference Time</th>
                                                        <th>Actual Time</th>
                                                        <th>Remarks</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let sleeping of ViewDialogeData?.sleeping_time">
                                                        <td>{{ sleeping?.sleeping_mother == 1 ? 'Yes' : '-' }}</td>
                                                        <td>{{ formatDateTime(sleeping?.sleeping_mother_time) }}</td>
                                                        <td>{{ formatDateTime(sleeping?.sleeping_mother_actual_time) }}
                                                        </td>
                                                        <td>{{ sleeping?.sleeping_mother_remark ?? '-' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="dataBydate">
                                            <table class="table font-size-13">
                                                <thead>
                                                    <tr>
                                                        <th>With Nurse</th>
                                                        <th>Reference Time</th>
                                                        <th>Actual Time</th>
                                                        <th>Remarks</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let sleeping of ViewDialogeData?.nanny_time">
                                                        <td>{{ sleeping?.sleeping_nanny == 1 ? 'Yes' : '-' }}</td>
                                                        <td>{{ formatDateTime(sleeping?.sleeping_nanny_time) }}</td>
                                                        <td>{{ formatDateTime(sleeping?.sleeping_nanny_actual_time) }}
                                                        </td>
                                                        <td>{{ sleeping?.sleeping_nanny_remark ?? '-' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Sleeping End-->

                                <!-- Temperature Monitoring -->
                                <!-- <p-accordionTab header="Temperature Monitoring">
            <h3>Temperature Monitoring</h3>
            <div class="row">
                <div class="col-xl-4 col-lg-12
                col-md-12">
                    <div class="form-group">
                        <label>Frequency</label>
                        <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.temperature_monitoring_frequency?? '-'
                }}" />
                    </div>
                </div>
            </div>
        </p-accordionTab> -->
                                <!-- Temperature Monitoring End-->

                                <!-- Vitamins/Medications -->
                                <p-accordionTab header="Vitamins">
                                    <div class="add-listings-box py-0 peach-bg">
                                        <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                        <!-- <h3>Vitamins/Medications</h3> -->
                                        <div class="row my-3"
                                            *ngFor="let vitamin of ViewDialogeData?.vitamins_medications_json">
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <div class="form-group">


                                                    <label>Medications</label>
                                                    <div type="text" readonly>
                                                        {{ vitamin?.vitamin ? vitamin?.vitamin : '-'}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <div class="form-group">
                                                    <label>Frequency</label>
                                                    <div type="text" readonly>{{ vitamin?.frequency ?  vitamin?.frequency : '-'
                }}</div>
                                                </div>
                                            </div>
                                            <div class="dataBydate">
                                            <table class="table font-size-13">
                                                <thead>
                                                    <tr>

                                                        <th>Time</th>
                                                        <th>Actual Time</th>
                                                        <th>Done or not</th>
                                                        <th>Remarks</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let times of vitamin.medicine_time">
                                                        <td> {{ formatDateTime(times?.time) }}
                                                        </td>
                                                        <td> {{ formatDateTime(times?.actual_time) }}
                                                        </td>
                                                        <td>{{ times?.vitamin_checkbox == 1 ? 'Yes' : '-' }}</td>
                                                        <td>{{ times?.vitamin_remark ? times?.vitamin_remark : '-' }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Vitamins/Medications End-->

                                <!-- Vaccinations -->
                                <!-- <p-accordionTab header="Vaccinations">
            <h3>Vaccinations</h3>
            <div class="row" *ngFor="let vaccine of vaccination">
                <div class="col-xl-4 col-lg-12
                col-md-12">
                    <div class="form-group">
                        <label>Vaccinations</label>
                        <input type="text" readonly class="form-control" value="{{ vaccine?.vaccination ? vaccine?.vaccination: '-'
                }}" />
                    </div>
                </div>

                <div class="col-xl-4 col-lg-12
                col-md-12">
                    <div class="form-group">
                        <label>Date of Administration</label>
                        <input type="text" readonly class="form-control" value="{{ vaccine?.date_administration ? (vaccine?.date_administration | date: 'dd/MM/yyyy' ): '-'
                }}" />
                    </div>
                </div>
            </div>
        </p-accordionTab> -->

                                <!-- Vaccinations End-->

                                <!-- Remark -->
                                <p-accordionTab header="Remark">
                                    <div class="row peach-bg">
                                        <div class="my-3">
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
                col-md-12">
                                                    <div class="form-group">

                                                        <div type="text" readonly>{{ ViewDialogeData?.remarks ?? '-'
                    }}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">

                                                <label for="">Nurse Remarks</label>
                                                <div class="col-md-3 form-group"
                                                    *ngFor="let change of ViewDialogeData?.nurse_remarks let index = index">
                                                    <div class="input-group2 form-group">
                                                        <span> {{ index+1 }}. </span>
                                                        <div type="formbox" readonly>
                                                            {{ change?.nurse_remark ?? '-' }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                            </p-accordion>
                            <!-- Remark End-->
                        </form>
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- <ng-template pTemplate="footer" let-list let-i="rowIndex">
<div class="row">
<div class="col-xl-2 col-lg-12
col-md-12">

<div class="form-group">
<label>created at</label>
<input type="text" readonly class="form-control"
    value="{{ viewDetail?.created_at ?? '-' }}" />
</div>
</div>
<div class="col-xl-2 col-lg-12
col-md-12">

<div class="form-group">
<label>updated at</label>
<input type="text" readonly class="form-control"
    value="{{ viewDetail?.updated_at ?? '-' }}" />
</div>
</div>
<div class="col-xl-2 col-lg-12
col-md-12">

<div class="form-group">
<label>updated at</label>
<input type="text" readonly class="form-control"
    value="{{ viewDetail?.updated_at ?? '-' }}" />
</div>
</div>

</div>
<button pButton pRipple class="p-button-rounded p-button-icon bx bx-pencil" pTooltip="Edit"
tooltipPosition="bottom" (click)="editOnClick(edit)"></button>
<p-button icon="pi pi-check" (click)="displayMaximizable = false" label="Ok"
styleClass="p-button-text"></p-button>


</ng-template> -->

        <!-- <ng-template pTemplate="footer" let-list let-i="rowIndex">
<div class="row">
<div class="col-xl-2 col-lg-12
col-md-12">

<div class="form-group">
<label>created at</label>
<input type="text" readonly class="form-control"
    value="{{ viewDetail?.created_at ?? '-' }}" />
</div>
</div>
<div class="col-xl-2 col-lg-12
col-md-12">

<div class="form-group">
<label>updated at</label>
<input type="text" readonly class="form-control"
    value="{{ viewDetail?.updated_at ?? '-' }}" />
</div>
</div>
<div class="col-xl-2 col-lg-12
col-md-12">

<div class="form-group">
<label>updated at</label>
<input type="text" readonly class="form-control"
    value="{{ viewDetail?.updated_at ?? '-' }}" />
</div>
</div>
<div class="col-xl-2 col-lg-12
col-md-12">

<div class="form-group">
<label>updated at</label>
<input type="text" readonly class="form-control"
    value="{{ viewDetail?.updated_at ?? '-' }}" />
</div>
</div>
<div class="col-xl-2 col-lg-12
col-md-12">
<button pButton pRipple class="p-button-rounded p-button-icon bx bx-pencil" pTooltip="Edit"
tooltipPosition="bottom" (click)="editOnCLick(edit)">
</button>
<p-button icon="pi pi-check" (click)="displayMaximizable=
false" label="Ok" styleClass="p-button-text"></p-button>
</div>
</div>
</ng-template> -->
        <!-- <ng-template pTemplate="footer" let-list let-i="rowIndex">
            <div>
                <div style="text-align: left; margin-top: 2px; padding-top:10px; padding-bottom:10px; float:left">
                    <table class="table-stamp">
                        <tr>
                            <td><b>Created By:</b> {{ viewDetail?.created_by_user_name}}</td>
                            <td><b>Time:</b>{{ viewDetail.created_at.substring(0, 19).replace('T', ' ') }}</td>
                            <td><b>Updated By:</b> {{ viewDetail?.updated_by_user_name}}</td>
                            <td><b>Time:</b>{{ viewDetail.updated_at.substring(0, 19).replace('T', ' ') }}</td>
                        </tr>
                    </table>
                </div>

                <div class="col-6 col-md-auto align-self-end ">
                    <div class="form-group">
                      
                        <p-button (click)="ShowZeroToThreeMonths=false" label="Ok"
                            styleClass="p-button-text"></p-button>
                    </div>
                </div>
            </div>
        </ng-template> -->
        <ng-template pTemplate="footer" let-list let-i="rowIndex">
            <div class="row ">
                <div class="col-md col-sm">
                    <table class="table-stamp w-100 text-start">
                        <tr class="row">
                            <td class="col-sm-6 col-lg-3" ><b>Created By:</b> {{ createdby}}</td>
                            <td class="col-sm-6 col-lg-3" ><b>Created Date&Time:</b>{{ viewtime.substring(0, 16).replace('T', ' ') }}
                            </td>
                            <td class="col-sm-6 col-lg-3" ><b>Updated By:</b> {{ updatedby??'-'}}</td>
                            <td class="col-sm-6 col-lg-3">
                                <b>Updated Date&Time:</b>
                                 <span *ngIf="updatedtime; else noData">{{ updatedtime.substring(0, 16).replace('T', ' ') }}</span>
                                <ng-template #noData> -</ng-template>
                              </td>
                        </tr>
                    </table>
                </div>
                <div class="col-md-auto"> <p-button (click)="softReload(); ShowZeroToThreeMonths=false" label="Ok"
                        styleClass="p-button-text"></p-button> </div>
            </div>


        </ng-template>



        <!-- <ng-template pTemplate="footer">

</ng-template> -->
    </p-dialog>
    <!----------------------------------------------------------- view 4m-1y dialog --------------------------------------------------------->
    <p-dialog header="Four Months to One Year
    Baby Details" [(visible)]="ShowFourMonthToYear" [modal]="true" [style]="{ width: '90vw' }" [draggable]="false"
        [resizable]="false" [closable]="false">
        <!-- Start -->
        <ng-container>
            <div class="row viewSummary ">
                <div class="col-lg-12">
                    <div class="my-profile-box">
                        <form>
                            <!-- Baby detail -->
                            <!-- accordion on listing -->
                            <p-accordion>
                                <p-accordionTab header="Baby Details">
                                    <div class="row peach-bg">
                                        <div class="my-3">
                                            <!-- <h3>Baby Details</h3> -->
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Name :</label>
                                                        <!-- <input type="text" readonly class="form-control"
                                                                value="{{viewDetail?.name ?? '-' | titlecase }}" /> -->
                                                        <div type="text" readonly>{{viewDetail?.name ?? '-' | titlecase
                                                                }}</div>

                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                    <div class="form-group">
                                                        <label>Gender :</label>
                                                        <!-- <input type="text" readonly class="form-control"
                                                                value="{{viewDetail?.gender ?? '-'}}" /> -->

                                                        <div type="text" readonly>{{viewDetail?.gender ?? '-' |
                                                                titlecase }}</div>

                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Date of Birth :</label>
                                                        <!-- <input type="text" readonly class="form-control"
                                                                value="{{viewDetail?.date_of_birth ?? '-'}}" /> -->
                                                        <div type="text" readonly>{{viewDetail?.date_of_birth ?? '-' |
                                                                titlecase }}</div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Days :</label>
                                                        <!-- <input type="text" readonly class="form-control"
                                                                value="{{viewDetail?.days ?? '-'}}" /> -->

                                                        <div type="text" readonly>{{viewDetail?.days ?? '-' | titlecase
                                                                }}</div>

                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Months :</label>
                                                        <!-- <input type="text" readonly class="form-control"
                                                                value="{{viewDetail?.months ?? '-'}}" /> -->

                                                        <div type="text" readonly>{{viewDetail?.months ?? '-' |
                                                                titlecase }}</div>

                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Years :</label>
                                                        <!-- <input type="text" readonly class="form-control"
                                                                value="{{viewDetail?.years ?? '-'}}" /> -->

                                                        <div type="text" readonly>{{viewDetail?.years ?? '-'}}</div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-4 col-lg-12
                col-md-12">
                                                        <!-- <div class="form-group">
                    <label for="">Food/Medicine Allergy</label>
                    <input type="text" readonly class="form-control" value="{{ viewDetail?.food_medicine_allergy== 1? 'Yes': '-' ?? '-'
                }}" />
                </div> -->
                                                        <ng-container>
                                                            <div class="form-group">
                                                                <label for="">Food/Medicine Allergy</label>
                                                                <div type="text" readonly *ngIf="ViewDialogeData?.food_medicine_allergy !==
                                                    null && ViewDialogeData?.food_medicine_allergy
                                                    !== '' && ViewDialogeData?.food_medicine_allergy
                                                    !== undefined; else elseBlock">

                                                                    {{ ViewDialogeData?.food_medicine_allergy == '1'? 'Yes': 'No'}}
                                                                </div>
                                                                <ng-template #elseBlock>
                                                                    <div type="text" readonly>
                                                                        {{"-"}}
                                                                    </div>
                                                                </ng-template>
                                                            </div>
                                                        </ng-container>
                                                    </div>

                                                    <div class="col-xl-4 col-lg-12
                col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Food Component</label>
                                                            <div type="text" readonly>
                                                                {{ViewDialogeData?.food_medicine_allergy_specify_prod??'-' }}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-accordionTab>


                                <p-accordionTab header="Feeding Assesment">
                                    <!-- feeding assesment listing  -->
                                    <div class="row peach-bg">
                                        <div class="my-3">
                                            <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->

                                            <div class="row">

                                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <!-- <label for="">Baby is not interested when offered to
                                                        breastfeed,
                                                        sleepy</label> -->
                                                        <label for="">Baby shows disinterest in breastfeeding when
                                                            sleepy</label>

                                                        <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.answer?? '-'
                                                              }}" /> -->
                                                        <div type="text" readonly>{{ViewDialogeData?.answer ??
                                                        '-' | titlecase }}</div>

                                                    </div>
                                                </div>

                                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <!-- <label for="">Baby is showing feeding cues but not
                                                        attaching</label> -->
                                                        <label for="">Baby exhibits hunger signs but isn't latching
                                                            on</label>

                                                        <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.attach??'-'
                                                               }}" /> -->
                                                        <div type="text" readonly>{{ViewDialogeData?.attach??
                                                        '-' | titlecase }}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">

                                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <!-- <label for="">Attaches at the breast but quickly falls
                                                    asleep</label> -->
                                                        <label for="">Baby latches onto the breast but quickly falls
                                                            asleep</label>
                                                        <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.Asleep?? '-'
                                                      }}" /> -->
                                                        <div type="text" readonly>{{ViewDialogeData?.Asleep??
                                                    '-' | titlecase }}</div>
                                                    </div>
                                                </div>

                                                <div class="col-xl-6 col-lg-12
                                             col-md-12">
                                                    <div class="form-group">
                                                        <!-- <label for="">Attaches for short bursts with long
                                                    pause</label> -->
                                                        <label for="">Baby briefly attaches with prolonged
                                                            breaks</label>

                                                        <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.LongPause??'-'
                                                    }}" /> -->
                                                        <div type="text" readonly>{{ViewDialogeData?.LongPause??
                                                    '-' | titlecase }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">

                                                <div class="col-xl-6 col-lg-12
                                                col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Attaches well with long rhythmical sucking and
                                                            swallowing
                                                            for a short feed</label>
                                                        <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.rhythmical?? '-'
                                            }}" /> -->

                                                        <div type="text" readonly>{{ViewDialogeData?.rhythmical??
                                                    '-' | titlecase }}</div>
                                                    </div>
                                                </div>

                                                <div class="col-xl-6 col-lg-12
                                                col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Attaches well for a sustained period with long
                                                            rhythmical
                                                            sucking and swallowing</label>
                                                        <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.swallowing??'-'
                                                    }}" /> -->
                                                        <div type="text" readonly>{{ViewDialogeData?.swallowing??
                                                    '-' | titlecase }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">

                                                <div class="col-xl-6 col-lg-12
                                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Normal skin color and tone</label>
                                                        <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.Normal_skin?? '-'
                                                      }}" /> -->

                                                        <div type="text" readonly>
                                                            {{ViewDialogeData?.Normal_skin??
                                                    '-' | titlecase }}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-xl-6 col-lg-12
                                                 col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Gaining weight appropriately</label>
                                                        <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.weight??'-'
                                                       }}" /> -->

                                                        <div type="text" readonly>{{ViewDialogeData?.weight??
                                                    '-' | titlecase }}</div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-lg-12
                                                                   col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Other Concerns</label>
                                                        <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.Other_concerns??'-'
                                                  }}" /> -->
                                                        <div type="text" readonly>
                                                            {{ViewDialogeData?.Other_concerns??
                                                    '-' | titlecase }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <p-accordionTab header="Daily Cares">
                                    <!-- <div class="add-listings-box py-0">
                                       
                                        <h3>Daily Cares</h3> -->
                                    <div class="row viewSummary peach-bg">
                                        <div class="my-3">
                                            <!--  <label for="">Feeding:Prepared by</label> -->
                                            <div type="text">Feeding:Prepared by
                                                {{
                                                (ViewDialogeData?.feeding_prepared_mother == 1 ? 'Mother' : '') +
                                                (ViewDialogeData?.feeding_prepared_mother == 1 &&
                                                ViewDialogeData?.feeding_prepared_nanny == 1 ? ' and ' : '') +
                                                (ViewDialogeData?.feeding_prepared_nanny == 1 ? 'Nurse' : '') +
                                                (ViewDialogeData?.feeding_prepared_other == 1 &&
                                                (ViewDialogeData?.feeding_prepared_mother == 1 ||
                                                ViewDialogeData?.feeding_prepared_nanny == 1) ? ', ' : '') +
                                                (ViewDialogeData?.feeding_prepared_other == 1 ? 'Other' : '')
                                                }}
                                            </div>
                                        </div>
                                        <!-- </div> -->
                                    </div>
                                </p-accordionTab>
                                <!-- MILK -->
                                <p-accordionTab header="Milk Feeding">
                                    <div class="add-listings-box py-0 peach-bg">
                                        <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                        <h3>Milk</h3>
                                        <div class="dataBydate">
                                        <table class="table font-size-13 ">
                                            <thead>
                                                <tr>
                                                    <th>Breast</th>
                                                    <th>Frequency</th>
                                                    <th>Reference Time</th>
                                                    <th>Time</th>
                                                    <th>Done or Not</th>
                                                    <th>Remark</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let time of ViewDialogeData?.breast_feeding; let i = index">
                                                    <td>{{ ViewDialogeData?.feeding_breast == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ ViewDialogeData?.feeding_frequency_breast ?? '-' }}</td>
                                                    <td>{{ formatDateTime(time?.feeding_breast_time) }}</td>
                                                    <td>{{ formatDateTime(time?.feeding_breast_actual_time) }}</td>
                                                    <td>{{ time?.feeding_breast_checkbox == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ time?.breast_feeding_remark ?? '-' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    </div>
                                    <div class="add-listings-box py-0 peach-bg">
                                        <h3>Formula Feeding Information Summary</h3>
                                        <div class="dataBydate">
                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>
                                                    <th>Formula</th>
                                                    <th>Frequency</th>
                                                    <th>Start Time</th>
                                                    <th>End Time</th>
                                                    <th>Amount</th>
                                                    <th>Type of Formula</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{{ ViewDialogeData?.feeding_type == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ ViewDialogeData?.feeding_frequency?? '-' }}</td>
                                                    <td>{{ formatDateTime(ViewDialogeData?.feeding_time) }}</td>
                                                    <td>{{ formatDateTime(ViewDialogeData?.formula_feeding_time) }}
                                                    </td>
                                                    <td>{{ ViewDialogeData?.feeding_amount_per ?? '-' }}</td>
                                                    <td>{{ ViewDialogeData?.type_of_formula ?? '-' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                        <div class="add-listings-box py-0">
                                            <h3>Formula Feeding Time and Amount</h3>
                                            <div class="dataBydate">
                                            <table class="table font-size-13">
                                                <thead>
                                                    <tr>
                                                        <th>Reference Time</th>
                                                        <th>Actual Time</th>
                                                        <th>Done or Not</th>
                                                        <th>Amount</th>
                                                        <th>It Is Same Formula?</th>
                                                        <th>Name of Formula</th>
                                                        <th>Remark</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let data of ViewDialogeData?.formula_calculation">
                                                        <td>{{ formatDateTime(data?.formula_timeArray) }}</td>
                                                        <td>{{ formatDateTime(data?.nurse_feeding) }}</td>
                                                        <td>{{ data?.feeding_checkbox == 1 ? 'Yes' : '-' }}</td>
                                                        <td>{{ data?.amount ?? '-' }}</td>
                                                        <td>{{ data?.is_same_formula ?? '-' }}</td>
                                                        <td>{{ data?.remark ?? '-' }}</td>
                                                        <td>{{ data?.formula_remark ?? '-' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- MILK END-->

                                <!-- Bottle Sterilization -->
                                <p-accordionTab header="Bottle Sterilization">
                                    <div class="add-listings-box py-0 peach-bg">
                                        <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                        <h3>Bottle Sterilization</h3>
                                        <div class="row">
                                            <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                <div class="form-group">
                                                    <label for="">All</label>
                                                    <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.bottle_sterilization_all== 1? 'Yes': '-' ?? '-'
                                }}" /> -->

                                                    <div type="text" readonly>{{
                                                                    ViewDialogeData?.bottle_sterilization_all== 1? 'Yes':
                                                                    '-' ?? '-'
                                                                    }}</div>
                                                </div>
                                            </div>

                                            <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                <div class="form-group">
                                                    <label for="">Time</label>
                                                    <!-- <input type="text" readonly class="form-control"
                                                        [value]="formatDateTime(ViewDialogeData?.bottle_sterilization_all_time)" /> -->

                                                    <div type="text" readonly>
                                                        {{formatDateTime(ViewDialogeData?.bottle_sterilization_all_time)}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row">
                                            <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                <div class="form-group">
                                                    <label for="">One At A time</label>
                                                    <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.bottle_sterilization_onetime== 1? 'Yes': '-' ?? '-'
                                }}" /> -->
                                                    <div type="text" readonly>{{
                                                                    ViewDialogeData?.bottle_sterilization_onetime== 1?
                                                                    'Yes': '-' ?? '-'
                                                                    }}</div>
                                                </div>
                                            </div>
                                            <div class="dataBydate">
                                            <table class="table font-size-13">
                                                <thead>
                                                    <tr>
                                                        <th>Time</th>
                                                        <th>Actual Time</th>
                                                        <th>Done or Not</th>
                                                        <th>Remark</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let data of ViewDialogeData?.bottle_sterilization">

                                                        <td>{{ formatDateTime(data?.sterilization_timeArray) }}</td>
                                                        <td>{{ formatDateTime(data?.actual_sterilization_timeArray) }}
                                                        </td>
                                                        <td>{{ data?.done_sterilization == 1 ? 'Yes' : '-' }}</td>
                                                        <td>{{ data?.sterilization_remark ?? '-' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Bottle Sterilization End-->

                                <!--Solid -->
                                <p-accordionTab header="Solid Food">
                                    <div class="add-listings-box py-0 peach-bg">
                                        <h3>Solid</h3>

                                        <!-- Snack -->
                                        <div class="dataBydate">
                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>
                                                    <th>Snack</th>
                                                    <th>Reference Time</th>
                                                    <th>Actual Time</th>
                                                    <th>Food Component</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{{ ViewDialogeData?.solid_other_snack == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ formatDateTime(ViewDialogeData?.solid_other_snack_time) }}
                                                    </td>
                                                    <td>{{ formatDateTime(ViewDialogeData?.solid_other_snack_actual_time) }}
                                                    </td>
                                                    <td>{{ ViewDialogeData?.soild_other_snack_food_components ?? '-' }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                        <!-- Regular Meal -->
                                        <div class="dataBydate">
                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>
                                                    <th>Meal</th>
                                                    <th>Reference Time</th>
                                                    <th>Actual Time</th>
                                                    <th>Food Component</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let food of ViewDialogeData?.regular_food">
                                                    <td>{{ food?.regular_meal_type == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ food?.regular_meal_time ? (food?.regular_meal_time | date: 'h:mm:ss') : '-' }}
                                                    </td>
                                                    <td>{{ food?.regular_meal_actual_time ? (food?.regular_meal_actual_time | date: 'h:mm:ss') : '-' }}
                                                    </td>
                                                    <td>{{ food?.regular_meal_food_components ? food?.regular_meal_food_components : '-' }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                        <!-- Lunch -->
                                        <div class="dataBydate">
                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>
                                                    <th>Lunch</th>
                                                    <th>Reference Time</th>
                                                    <th>Actual Time</th>
                                                    <th>Food Component</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let food of ViewDialogeData?.lunch_food">
                                                    <td>{{ food?.regular_meal_lunch == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ food?.regular_meal_lunch_time ? (food?.regular_meal_lunch_time | date: 'h:mm:ss') : '-' }}
                                                    </td>
                                                    <td>{{ food?.regular_meal_lunch_actual_time ? (food?.regular_meal_lunch_actual_time | date: 'h:mm:ss') : '-' }}
                                                    </td>
                                                    <td>{{ food?.regular_meal_lunch_food_components ? food?.regular_meal_lunch_food_components : '-' }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                        <!-- Dinner -->
                                        <div class="dataBydate">
                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>
                                                    <th>Dinner</th>
                                                    <th>Reference Time</th>
                                                    <th>Actual Time</th>
                                                    <th>Food Component</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let food of ViewDialogeData?.dinner_food">
                                                    <td>{{ food?.regular_meal_dinner == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ food?.regular_meal_dinner_time ? (food?.regular_meal_dinner_time | date: 'h:mm:ss') : '-' }}
                                                    </td>
                                                    <td>{{ food?.regular_meal_dinner_actual_time ? (food?.regular_meal_dinner_actual_time | date: 'h:mm:ss') : '-' }}
                                                    </td>
                                                    <td>{{ food?.regular_meal_dinner_food_components ? food?.regular_meal_dinner_food_components : '-' }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    </div>

                                </p-accordionTab>
                                <!-- Solid End-->

                                <!-- Food/Medicine Allergy -->
                                <p-accordionTab header="Food/Medicine Allergy"
                                    [class.allergyhighlight]="ViewDialogeData?.food_medicine_allergy == '1'">
                                    <div class="row peach-bg">
                                        <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                        <!-- <h3>Food/Medicine Allergy</h3> -->
                                        <div class="row my-3">
                                            <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                <ng-container>
                                                    <div class="form-group">
                                                        <label for="">Food/Medicine Allergy</label>
                                                        <!-- <input type="text" readonly class="form-control"
                                                            *ngIf="ViewDialogeData?.food_medicine_allergy !== null && ViewDialogeData?.food_medicine_allergy !== '' && ViewDialogeData?.food_medicine_allergy !== undefined; else elseBlock"
                                                            value="{{ ViewDialogeData?.food_medicine_allergy == '1'? 'Yes': 'No'}}" /> -->

                                                        <div type="text" readonly
                                                            *ngIf="ViewDialogeData?.food_medicine_allergy !== null && ViewDialogeData?.food_medicine_allergy !== '' && ViewDialogeData?.food_medicine_allergy !== undefined; else elseBlock">
                                                            {{ViewDialogeData?.food_medicine_allergy == '1'?
                                                                        'Yes': 'No'
                                                                        }}
                                                        </div>
                                                        <ng-template #elseBlock>
                                                            <input type="text" readonly class="form-control"
                                                                value="-" />
                                                        </ng-template>
                                                    </div>
                                                </ng-container>
                                            </div>

                                            <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                <div class="form-group">
                                                    <label for="">Food Component</label>
                                                    <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.food_medicine_allergy_specify_prod ?? '-'
                        }}" /> -->
                                                    <div type="text" readonly>{{
                                                                    ViewDialogeData?.food_medicine_allergy_specify_prod
                                                                    ?? '-'
                                                                    }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Food/Medicine Allergy End-->

                                <!-- Bathing -->
                                <p-accordionTab header="Bathing">
                                    <div class="add-listings-box py-0 peach-bg">
                                        <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                        <h3>Bathing</h3>
                                        <div class="row">
                                            <!-- Sponge -->
                                            <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                <div class="form-group">
                                                    <label for="">Sponge</label>
                                                    <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.bathing_sponge== 1? 'Yes': '-' ?? '-'
                        }}" /> -->

                                                    <div type="text" readonly>{{
                                                                    ViewDialogeData?.bathing_sponge== 1? 'Yes': '-' ??
                                                                    '-'
                                                                    }}</div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                <div class="form-group">
                                                    <label for="">Frequency</label>
                                                    <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.bathing_frequency_sponge ?? '-'
                        }}" /> -->
                                                    <div type="text" readonly>{{
                                                                    ViewDialogeData?.bathing_frequency_sponge ?? '-'
                                                                    }}</div>
                                                </div>
                                            </div>
                                            <div class="dataBydate">
                                            <table class="table font-size-13 dataBydate">
                                                <thead>
                                                    <tr>
                                                        <th>Reference Time</th>
                                                        <th>Actual Time</th>
                                                        <th>Done or Not</th>
                                                        <th>Remark</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let time of ViewDialogeData?.bathing_time">

                                                        <td>{{ formatDateTime(time?.bathing_sponge_time) }}</td>
                                                        <td>{{ formatDateTime(time?.bathing_sponge_actual_time) }}</td>
                                                        <td>{{ time?.sponge_checkbox == 1 ? 'Yes' : '-' }}</td>
                                                        <td>{{ time?.spongeBathing_remark ?? '-' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        </div>


                                        <!-- Tub -->
                                        <div class="row">
                                            <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                <div class="form-group">
                                                    <label for="">Tub</label>
                                                    <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.bathing_tub == 1? 'Yes': '-'?? '-'
                        }}" /> -->

                                                    <div type="text" readonly>{{ ViewDialogeData?.bathing_tub
                                                                    == 1? 'Yes': '-'?? '-'
                                                                    }}</div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                <div class="form-group">
                                                    <label for="">Frequency</label>
                                                    <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.bathing_tub_frequency ?? '-'
                        }}" /> -->

                                                    <div type="text" readonly>{{
                                                                    ViewDialogeData?.bathing_tub_frequency ?? '-'
                                                                    }}</div>
                                                </div>
                                            </div>
                                            <div class="dataBydate">
                                            <table class="table font-size-13 dataBydate">
                                                <thead>
                                                    <tr>


                                                        <th>Reference Time</th>
                                                        <th>Actual Time</th>
                                                        <th>Done or Not</th>
                                                        <th>Remark</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let time of ViewDialogeData?.tub_bathing">
                                                        <td>{{ formatDateTime(time?.bathing_tub_time) }}</td>
                                                        <td>{{ formatDateTime(time?.bathing_tub_actual_time) }}</td>
                                                        <td>{{ time?.tub_checkbox == 1 ? 'Yes' : '-' }}</td>
                                                        <td>{{ time?.tubBathing_remark ?? '-' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        </div>

                                        <!-- Shower -->
                                        <div class="row">
                                            <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                <div class="form-group">
                                                    <label for="">Shower</label>
                                                    <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.bathing_shower== 1? 'Yes': '-' ?? '-'
                        }}" /> -->

                                                    <div type="text" readonly>{{
                                                                    ViewDialogeData?.bathing_shower== 1? 'Yes': '-' ??
                                                                    '-'
                                                                    }}</div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                <div class="form-group">
                                                    <label for="">Frequency</label>
                                                    <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.bathing_shower_frequency ?? '-'
                        }}" /> -->

                                                    <div type="text" readonly>{{
                                                                    ViewDialogeData?.bathing_shower_frequency ?? '-'
                                                                    }}</div>
                                                </div>
                                            </div>
                                            <div class="dataBydate">
                                            <table class="table font-size-13">
                                                <thead>
                                                    <tr>


                                                        <th>Reference Time</th>
                                                        <th>Actual Time</th>
                                                        <th>Done or Not</th>
                                                        <th>Remark</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let time of ViewDialogeData?.shower_bathing">
                                                        <td>{{ formatDateTime(time?.bathing_shower_time) }}</td>
                                                        <td>{{ formatDateTime(time?.bathing_shower_actual_time) }}</td>
                                                        <td>{{ time?.shower_checkbox == 1 ? 'Yes' : '-' }}</td>
                                                        <td>{{ time?.shower_remark ?? '-' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Bathing End -->
                                <p-accordionTab header="Diaper Change">
                                    <div class="add-listings-box py-0 peach-bg">
                                    <div class="row peach-bg">
                                        <div class="my-3">
                                           
                                            <table class="table font-size-13">
                                                <thead>
                                                    <tr>
                                                        <th>Frequency</th>
                                                        <th>Reference Time</th>
                                                        <th>Actual Time</th>
                                                        <th>Done or Not</th>
                                                        <th>Remark</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let data of ViewDialogeData?.diaper_calculation">
                                                        <td>{{ (ViewDialogeData?.diaper_change_frequency) }}</td>
                                                        <td>{{ formatDateTime(data?.diaper_timeArray) }}</td>
                                                        <td>{{ formatDateTime(data?.diaper_actual_time) }}</td>
                                                        <td>{{ data?.diaper_checkbox == 1 ? 'Yes' : '-' }}</td>
                        
                                                        <td>{{ data?.diaperRemark ?? '-' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <h3>Additional Diaper Change</h3>
                                            <table class="table font-size-13">
                                              
                                                <thead>
                                                    <tr>
                                                        <th>Diaper Change</th>
                                                        <th>Change Time</th>
                                                        <th>Remark</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let change of ViewDialogeData?.diaper_change_times">
                                                        <td>
                                                            <div class="form-group">

                                                                <div type="text" readonly>
                                                                    {{ change?.change ? 'Yes' : 'No' }}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-group">

                                                                <div type="text" readonly>
                                                                    {{ formatDateTime(change?.time) }}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-group">

                                                                <div type="text" readonly>
                                                                    {{ change?.diaper_remark ?? '-' }}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Skin Cares -->
                                <p-accordionTab header="Skin Cares">
                                    <div class="row peach-bg">
                                        <div class="my-3">
                                            <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                            <!-- <h3>Skin Cares</h3> -->
                                            <div class="row" *ngFor="let care of ViewDialogeData?.skin_care ">
                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group viewSummary">
                                                        <label for="">Skin care product for baby:</label>
                                                        <div type="text"> {{
                                                (care?.skin_care_lotion == 1 ? 'Lotion' : '') +
                                                (care?.skin_care_lotion == 1 &&
                                                care?.skin_care_baby_oil == 1 ? ' and ' : '') +
                                                (care?.skin_care_baby_oil == 1 ? 'Baby Oil' : '') +
                                                (care?.skin_care_others == 1 &&
                                                (care?.skin_care_lotion == 1 ||
                                                care?.skin_care_baby_oil == 1) ? ', ' : '') +
                                                (care?.skin_care_others == 1 ? 'Others' : '')
                                                }}</div>

                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                    <div class="form-group">
                                                        <label>Specify Product</label>
                                                        <!-- <input type="text" readonly class="form-control" value="{{ care?.skin_care_specify_produ ? care?.skin_care_specify_produ :'-'
                        }}" /> -->

                                                        <div type="text" readonly>{{ care?.skin_care_specify_produ ?
                                                                    care?.skin_care_specify_produ :'-'
                                                                    }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Skin Cares End-->

                                <!-- Wipes -->
                                <p-accordionTab header="Wipes">
                                    <div class="row peach-bg">
                                        <div class="my-3">
                                            <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                            <!-- <h3>Wipes</h3> -->
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                    <!-- <div class="form-group">
                            <label>Wipes</label>
                            <input type="text" readonly class="form-control" value="{{ data?.skin_care_Wipes== 1? 'Yes': '-' ?? '-'
                        }}" />
                        </div> -->

                                                    <ng-container>
                                                        <div class="form-group">
                                                            <label for="">Wipes</label>
                                                            <!-- <input type="text" readonly class="form-control"
                                                            *ngIf="ViewDialogeData?.skin_care_Wipes !== null && ViewDialogeData?.skin_care_Wipes !== ''  && ViewDialogeData?.skin_care_Wipes !== undefined; else elseBlock"
                                                            value="{{ ViewDialogeData?.skin_care_Wipes == '1'? 'Yes': 'No'}}" /> -->

                                                            <div type="text" readonly
                                                                *ngIf="ViewDialogeData?.skin_care_Wipes !== null && ViewDialogeData?.skin_care_Wipes !== ''  && ViewDialogeData?.skin_care_Wipes !== undefined; else elseBlock">
                                                                {{ ViewDialogeData?.skin_care_Wipes == '1'?
                                                                        'Yes': 'No'}}
                                                            </div>
                                                            <ng-template #elseBlock>
                                                                <input type="text" readonly class="form-control"
                                                                    value="-" />
                                                            </ng-template>
                                                        </div>
                                                    </ng-container>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                    <div class="form-group">
                                                        <label>Special Instruction</label>
                                                        <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.skin_care_special_instructions ?? '-'
                        }}" /> -->

                                                        <div type="text" readonly>{{
                                                                    ViewDialogeData?.skin_care_special_instructions ??
                                                                    '-'
                                                                    }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Wipes End-->

                                <!-- Changing Clothes -->
                                <p-accordionTab header="Changing Clothes">
                                    <div class="add-listings-box py-0 peach-bg">
                                        <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                        <h3>Changing Clothes</h3>
                                        <div class="row">


                                            <div class="col-xl-4 col-lg-12 col-md-12">
                                                <div class="form-group">
                                                    <label>Special Instructions</label>
                                                    <div type="text" readonly>
                                                        {{ ViewDialogeData?.changing_colthes_spiecal_instructions ?? '-'}}
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="dataBydate">
                                            <table class="table font-size-13">
                                                <thead>
                                                    <tr>
                                                        <th>Changing Clothes</th>
                                                        <th>Changing Time</th>
                                                        <th>Actual Time</th>
                                                        <th>Remarks</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let change of ViewDialogeData?.changing_clothes">
                                                        <td>{{ change?.changing_colthes == 1 ? 'Yes' : '-' }}</td>
                                                        <td>{{ formatDateTime(change?.Changing_time) }}</td>
                                                        <td>{{ formatDateTime(change?.Changing_actual_time) }}</td>
                                                        <td>{{ change?.clothe_remark ?? '-' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Changing Clothes End-->

                                <!-- Sleeping -->
                                <p-accordionTab header="Sleeping">
                                    <div class="add-listings-box py-0 peach-bg">
                                        <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                        <h3>Sleeping</h3>
                                        <div class="dataBydate">
                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>
                                                    <th>With Mother</th>
                                                    <th>Reference Time</th>
                                                    <th>Actual Time</th>
                                                    <th>Remarks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let sleeping of ViewDialogeData?.sleeping_time">
                                                    <td>{{ sleeping?.sleeping_mother == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ formatDateTime(sleeping?.sleeping_mother_time) }}</td>
                                                    <td>{{ formatDateTime(sleeping?.sleeping_mother_actual_time) }}</td>
                                                    <td>{{ sleeping?.sleeping_mother_remark ?? '-' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                        <div class="dataBydate">
                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>
                                                    <th>With Nurse</th>
                                                    <th>Reference Time</th>
                                                    <th>Actual Time</th>
                                                    <th>Remarks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let sleeping of ViewDialogeData?.nanny_time">
                                                    <td>{{ sleeping?.sleeping_nanny == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ formatDateTime(sleeping?.sleeping_nanny_time) }}</td>
                                                    <td>{{ formatDateTime(sleeping?.sleeping_nanny_actual_time) }}</td>
                                                    <td>{{ sleeping?.sleeping_nanny_remark ?? '-' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Sleeping End-->

                                <!-- Sleep Training-->
                                <p-accordionTab header="Sleep Training">
                                    <div class="row peach-bg">
                                        <div class="my-3">
                                            <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                            <!-- <h3>Sleep Training</h3> -->
                                            <div class="row">
                                                <!-- <label class="subHeading my-2">Assesment</label> -->
                                                <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Sleep Training
                                                        </label>
                                                        <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.sleep_train?? '-'
                                }}" /> -->

                                                        <div type="text" readonly>
                                                            {{ViewDialogeData?.sleep_train?? '-'
                                                                    }}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Wake up time (morning)</label>
                                                        <!-- <input type="text" readonly class="form-control" [value]="formatDateTime( ViewDialogeData?.walk_time)
                            " /> -->

                                                        <div type="text" readonly>{{formatDateTime(
                                                                    ViewDialogeData?.walk_time)
                                                                    }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">

                                                <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Nap time</label>
                                                        <!-- <input type="text" readonly class="form-control" [value]="formatDateTime (ViewDialogeData?.nap_time)
                                " /> -->

                                                        <div type="text" readonly>{{formatDateTime
                                                                    (ViewDialogeData?.nap_time)
                                                                    }}</div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Sleep time (evening)</label>
                                                        <!-- <input type="text" readonly class="form-control" [value]="formatDateTime (ViewDialogeData?.sleep_time)
                            " /> -->

                                                        <div type="text" readonly>{{formatDateTime
                                                                    (ViewDialogeData?.sleep_time)
                                                                    }}</div>
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Stroller</label>
                                                        <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.stroller??'-'
                                }}" /> -->

                                                        <div type="text" readonly>{{
                                                                    ViewDialogeData?.stroller??'-'
                                                                    }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">

                                                <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Can the baby get himself back to sleep when
                                                            interrupted
                                                            during short naps?</label>
                                                        <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.interrupt?? '-'
                                }}" /> -->

                                                        <div type="text" readonly>{{ ViewDialogeData?.interrupt??
                                                                    '-'
                                                                    }}</div>
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Needs dummy to help soothe the baby during
                                                            sleep</label>
                                                        <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.need_dummy??'-'
                                }}" /> -->
                                                        <div type="text" readonly>
                                                            {{ViewDialogeData?.need_dummy??'-' }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">

                                                <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Pacifier</label>
                                                        <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.pacifier?? '-'
                                }}" /> -->

                                                        <div type="text" readonly>{{ViewDialogeData?.pacifier??
                                                                    '-'
                                                                    }}</div>
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Songs before sleep
                                                        </label>
                                                        <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.song_sleep??'-'
                                }}" /> -->
                                                        <div type="text" readonly>{{
                                                                    ViewDialogeData?.song_sleep??'-'
                                                                    }}</div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Reading Sleep</label>
                                                        <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.reading_sleep??'-'
                                }}" /> -->
                                                        <div type="text" readonly>{{
                                                                    ViewDialogeData?.reading_sleep??'-'
                                                                    }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Ponits End -->

                                <!-- Temperature Monitoring -->
                                <p-accordionTab header="Vitamins/Medications">
                                    <!-- <h3>Temperature Monitoring</h3>
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12
                        col-md-12">
                                        <div class="form-group">
                                            <label>Frequency</label>
                                            <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.temperature_monitoring_frequency ??  '-'
                            }}" />
                                        </div>
                                    </div>
                                </div> -->
                                    <!-- Temperature Monitoring End-->

                                    <!-- Vitamins/Medications -->
                                    <div class="add-listings-box py-0 peach-bg">
                                        <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                        <h3>Vitamins/Medications</h3>
                                        <div class="row"
                                            *ngFor="let vitamin of ViewDialogeData?.vitamins_medications_json">
                                            <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                <div class="form-group">
                                                    <label>Medications</label>
                                                    <!-- <input type="text" readonly class="form-control" value="{{ vitamin?.vitamin ? vitamin?.vitamin : '-'
                            }}" /> -->

                                                    <div type="text" readonly>{{ vitamin?.vitamin ?
                                                                    vitamin?.vitamin : '-'
                                                                    }}</div>
                                                </div>
                                            </div>

                                            <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                <div class="form-group">
                                                    <label>Frequency</label>
                                                    <!-- <input type="text" readonly class="form-control" value="{{ vitamin?.frequency ? vitamin?.frequency : '-'
                            }}" /> -->

                                                    <div type="text" readonly>{{ vitamin?.frequency ?
                                                                    vitamin?.frequency : '-' }}</div>
                                                </div>
                                            </div>
                                            <div class="dataBydate">
                                            <table class="table font-size-13">
                                                <thead>
                                                    <tr>

                                                        <th>Time</th>
                                                        <th>Actual Time</th>
                                                        <th>Done or not</th>
                                                        <th>Remarks</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let times of vitamin.medicine_time">
                                                        <td> {{ formatDateTime(times?.time) }}
                                                        </td>
                                                        <td> {{ formatDateTime(times?.actual_time) }}
                                                        </td>
                                                        <td>{{ times?.vitamin_checkbox == 1 ? 'Yes' : '-' }}</td>
                                                        <td>{{ times?.vitamin_remark ? times?.vitamin_remark : '-' }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        </div>
                                        <!-- Vitamins/Medications End-->

                                        <!-- Vaccinations -->
                                        <!-- <h3>Vaccinations</h3>
                                <div class="row" *ngFor="let vaccine of vaccination">
                                    <div class="col-xl-4 col-lg-12
                        col-md-12">
                                        <div class="form-group">
                                            <label>Vaccinations</label>
                                            <input type="text" readonly class="form-control" value="{{ vaccine?.vaccination ? vaccine?.vaccination :'-'
                            }}" />
                                        </div>
                                    </div>
    
                                    <div class="col-xl-4 col-lg-12
                        col-md-12">
                                        <div class="form-group">
                                            <label>Date of Administration</label>
                                            <input type="text" readonly class="form-control" value="{{ vaccine?.date_administration ?(vaccine?.date_administration | date: 'dd/MM/yyyy'): '-'
                            }}" />
                                        </div>
                                    </div>
                                </div> -->
                                    </div>
                                </p-accordionTab>
                                <!-- Vaccinations End-->

                                <!-- Remark -->
                                <p-accordionTab header="Remark">
                                    <div class="row peach-bg">
                                        <div class="my-4">
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
                col-md-12">
                                                    <div class="form-group">

                                                        <div type="text" readonly>{{ ViewDialogeData?.remarks ?? '-'
                    }}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">

                                                <label for="">Nurse Remarks</label>
                                                <div class="col-md-3 form-group"
                                                    *ngFor="let change of ViewDialogeData?.nurse_remarks let index = index">
                                                    <div class="input-group2  form-group ">
                                                        <span> {{ index+1 }}. </span>
                                                        <div type="formbox" readonly>
                                                            {{ change?.nurse_remark ?? '-' }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Remark End-->
                            </p-accordion>
                        </form>
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- <ng-template pTemplate="footer" let-list let-i="rowIndex">
            <div>

                <div style="text-align: left; margin-top: 2px; padding-top:10px; padding-bottom:10px; float:left">
                    <table class="table-stamp">
                        <tr>
                            <td><b>Created By:</b> {{ viewDetail?.created_by_user_name}}</td>
                            <td><b>Time:</b>{{ viewDetail.created_at.substring(0, 19).replace('T', ' ') }}</td>
                            <td><b>Updated By:</b> {{ viewDetail?.updated_by_user_name}}</td>
                            <td><b>Time:</b>{{ viewDetail.updated_at.substring(0, 19).replace('T', ' ') }}</td>
                        </tr>
                    </table>
                </div>

                <div class="col-6 col-md-auto align-self-end ">
                    <div class="form-group">
                       
                        <p-button (click)="ShowFourMonthToYear=false" label="Ok" styleClass="p-button-text"></p-button>
                    </div>
                </div>

            </div>
        </ng-template> -->
        <ng-template pTemplate="footer" let-list let-i="rowIndex">
            <div class="row ">
                <div class="col-md">
                    <table class="table-stamp w-100 text-start">
                        <tr class="row">
                            <td class="col-sm-6 col-lg-3" ><b>Created By:</b> {{ createdby}}</td>
                            <td class="col-sm-6 col-lg-3" ><b>Created Date&Time:</b>{{ viewtime.substring(0, 16).replace('T', ' ') }}
                            </td>
                            <td class="col-sm-6 col-lg-3" ><b>Updated By:</b> {{ updatedby??'-'}}</td>
                            <td class="col-sm-6 col-lg-3">
                                <b>Updated Date&Time:</b>
                                 <span *ngIf="updatedtime; else noData">{{ updatedtime.substring(0, 16).replace('T', ' ') }}</span>
                                <ng-template #noData> -</ng-template>
                              </td>
                              
                        </tr>
                    </table>
                </div>
                <div class="col-md-auto"> <p-button (click)="softReload();ShowFourMonthToYear=false" label="Ok"
                        styleClass="p-button-text"></p-button> </div>
            </div>


        </ng-template>
    </p-dialog>
    <!----------------------------------------------------------- one to three year --------------------------------------------------------->
    <!-- ***************************************************************************************************************** -->
    <p-dialog header="One Year to Three Years Baby Details" [(visible)]="showoneToThreeyears" [modal]="true"
        [style]="{ width: '90vw' }" [draggable]="false" [resizable]="false" [closable]="false">
        <!-- Start -->
        <ng-container>
            <div class="row viewSummary ">
                <div class="col-lg-12">
                    <div class="my-profile-box">
                        <form>
                            <!-- Baby detail -->
                            <!-- accordian on listing -->
                            <p-accordion>
                                <p-accordionTab header="Baby Details">
                                    <div class="row peach-bg">
                                        <div class="my-4">
                                            <!-- <h3>Baby Details</h3> -->
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Name :</label>
                                                        <!-- <input type="text" readonly class="form-control"
                                                        value="{{viewDetail?.name ?? '-' | titlecase }}" /> -->
                                                        <div type="text" readonly>{{viewDetail?.name ?? '-' | titlecase
                                                            }}</div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12
                                col-md-12">
                                                    <div class="form-group">
                                                        <label>Gender :</label>
                                                        <!-- <input type="text" readonly class="form-control"
                                                        value="{{viewDetail?.gender ?? '-'}}" /> -->
                                                        <div type="text" readonly>{{viewDetail?.gender ?? '-' |
                                                            titlecase }}</div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Date of Birth :</label>
                                                        <!-- <input type="text" readonly class="form-control"
                                                        value="{{viewDetail?.date_of_birth ?? '-'}}" /> -->
                                                        <div type="text" readonly>{{viewDetail?.date_of_birth ?? '-' |
                                                            titlecase }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Days :</label>
                                                        <!-- <input type="text" readonly class="form-control"
                                                        value="{{viewDetail?.days ?? '-'}}" /> -->

                                                        <div type="text" readonly>{{viewDetail?.days ?? '-' | titlecase
                                                            }}</div>

                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Months :</label>
                                                        <!-- <input type="text" readonly class="form-control"
                                                        value="{{viewDetail?.months ?? '-'}}" /> -->

                                                        <div type="text" readonly>{{viewDetail?.months ?? '-' |
                                                            titlecase }}</div>

                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Years :</label>
                                                        <!-- <input type="text" readonly class="form-control"
                                                        value="{{viewDetail?.years ?? '-'}}" /> -->
                                                        <div type="text" readonly>{{viewDetail?.years ?? '-'}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
            col-md-12">
                                                    <!-- <div class="form-group">
                <label for="">Food/Medicine Allergy</label>
                <input type="text" readonly class="form-control" value="{{ viewDetail?.food_medicine_allergy== 1? 'Yes': '-' ?? '-'
            }}" />
            </div> -->
                                                    <ng-container>
                                                        <div class="form-group">
                                                            <label for="">Food/Medicine Allergy</label>
                                                            <div type="text" readonly *ngIf="ViewDialogeData?.food_medicine_allergy !==
                                                null && ViewDialogeData?.food_medicine_allergy
                                                !== '' && ViewDialogeData?.food_medicine_allergy
                                                !== undefined; else elseBlock">

                                                                {{ ViewDialogeData?.food_medicine_allergy == '1'? 'Yes': 'No'}}
                                                            </div>
                                                            <ng-template #elseBlock>
                                                                <div type="text" readonly>
                                                                    {{"-"}}
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                    </ng-container>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Food Component</label>
                                                        <div type="text" readonly>
                                                            {{ViewDialogeData?.food_medicine_allergy_specify_prod??'-' }}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- DAILY CARE -->

                                <p-accordionTab header="Daily Cares">
                                    <!-- <div class="add-listings-box py-0">
                                       
                                        <h3>Daily Cares</h3> -->
                                    <div class="row viewSummary peach-bg">
                                        <div class="my-4">
                                            <!-- <label for=""></label> -->
                                            <div type="text">Feeding: Prepared by
                                                {{
                                                (ViewDialogeData?.feeding_prepared_mother == 1 ? 'Mother' : '') +
                                                (ViewDialogeData?.feeding_prepared_mother == 1 &&
                                                ViewDialogeData?.feeding_prepared_nanny == 1 ? ' and ' : '') +
                                                (ViewDialogeData?.feeding_prepared_nanny == 1 ? 'Nurse' : '') +
                                                (ViewDialogeData?.feeding_prepared_other == 1 &&
                                                (ViewDialogeData?.feeding_prepared_mother == 1 ||
                                                ViewDialogeData?.feeding_prepared_nanny == 1) ? ', ' : '') +
                                                (ViewDialogeData?.feeding_prepared_other == 1 ? 'Other' : '')
                                                }}
                                            </div>
                                            <!-- </div> -->
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- DAILY CARE END -->

                                <!-- MILK -->
                                <p-accordionTab header="Milk">
                                    <div class="add-listings-box py-0 peach-bg">
                                        <h3 class="mb-0 h6">Milk </h3>
                                        <!-- <h3>Milk</h3> -->
                                        <div class="add-listings-box py-0">
                                            <h3>Breast Feeding Summary</h3>
                                            <div class="dataBydate">
                                            <table class="table font-size-13">
                                                <thead>
                                                    <tr>
                                                        <th>Breast</th>
                                                        <th>Frequency</th>
                                                        <th>Reference Time</th>
                                                        <th>Time</th>
                                                        <th>Done or Not</th>
                                                        <th>Remark</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let time of ViewDialogeData?.breast_feeding; let i = index">
                                                        <td>{{ ViewDialogeData?.feeding_breast == 1 ? 'Yes' : '-' }}
                                                        </td>
                                                        <td>{{ ViewDialogeData?.feeding_frequency_breast ?? '-' }}</td>
                                                        <td>{{ formatDateTime(time?.feeding_breast_time) }}</td>
                                                        <td>{{ formatDateTime(time?.feeding_breast_actual_time) }}</td>
                                                        <td>{{ time?.feeding_breast_checkbox == 1 ? 'Yes' : '-' }}</td>
                                                        <td>{{ time?.breast_feeding_remark ?? '-' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        </div>
                                        <div class="add-listings-box py-0">
                                            <h3>Formula Feeding Information Summary</h3>
                                            <div class="dataBydate">
                                            <table class="table font-size-13">
                                                <thead>
                                                    <tr>
                                                        <th>Formula</th>
                                                        <th>Frequency</th>
                                                        <th>Start Time</th>
                                                        <th>End Time</th>
                                                        <th>Amount</th>
                                                        <th>Type of Formula</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{{ ViewDialogeData?.feeding_type == 1 ? 'Yes' : '-' }}</td>
                                                        <td>{{ ViewDialogeData?.feeding_frequency?? '-' }}</td>
                                                        <td>{{ formatDateTime(ViewDialogeData?.feeding_time) }}</td>
                                                        <td>{{ formatDateTime(ViewDialogeData?.formula_feeding_time) }}
                                                        </td>
                                                        <td>{{ ViewDialogeData?.feeding_amount_per ?? '-' }}</td>
                                                        <td>{{ ViewDialogeData?.type_of_formula ?? '-' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                            <div class="add-listings-box py-0">
                                                <h3>Formula Feeding Time and Amount</h3>
                                                <div class="dataBydate">
                                                <table class="table font-size-13">
                                                    <thead>
                                                        <tr>
                                                            <th>Reference Time</th>
                                                            <th>Actual Time</th>
                                                            <th>Done or Not</th>
                                                            <th>Amount</th>
                                                            <th>It Is Same Formula?</th>
                                                            <th>Name of Formula</th>
                                                            <th>Remark</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let data of ViewDialogeData?.formula_calculation">
                                                            <td>{{ formatDateTime(data?.formula_timeArray) }}</td>
                                                            <td>{{ formatDateTime(data?.nurse_feeding) }}</td>
                                                            <td>{{ data?.feeding_checkbox == 1 ? 'Yes' : '-' }}</td>
                                                            <td>{{ data?.amount ?? '-' }}</td>
                                                            <td>{{ data?.is_same_formula ?? '-' }}</td>
                                                            <td>{{ data?.remark ?? '-' }}</td>
                                                            <td>{{ data?.formula_remark ?? '-' }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- MILK END-->

                                <!-- Bottle Sterilization -->
                                <p-accordionTab header="Bottle Sterilization">
                                    <div class="add-listings-box py-0 peach-bg">
                                        <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                        <h3>Bottle Sterilization</h3>
                                        <div class="row">
                                            <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                <div class="form-group">
                                                    <label for="">All</label>
                                                    <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.bottle_sterilization_all== 1? 'Yes': '-' ?? '-'
                                }}" /> -->

                                                    <div type="text" readonly>{{
                                                                    ViewDialogeData?.all_time== 1? 'Yes':
                                                                    '-' ?? '-'
                                                                    }}</div>
                                                </div>
                                            </div>

                                            <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                <div class="form-group">
                                                    <label for="">Time</label>
                                                    <!-- <input type="text" readonly class="form-control"
                                                        [value]="formatDateTime(ViewDialogeData?.bottle_sterilization_all_time)" /> -->

                                                    <div type="text" readonly>
                                                        {{formatDateTime(ViewDialogeData?.bottle_sterilization_all)}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row">
                                            <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                <div class="form-group">
                                                    <label for="">One At A time</label>
                                                    <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.bottle_sterilization_onetime== 1? 'Yes': '-' ?? '-'
                                }}" /> -->
                                                    <div type="text" readonly>{{
                                                                    ViewDialogeData?.onetime== 1?
                                                                    'Yes': '-' ?? '-'
                                                                    }}</div>
                                                </div>
                                            </div>
                                            <div class="dataBydate">
                                            <table class="table font-size-13">
                                                <thead>
                                                    <tr>
                                                        <th>Time</th>
                                                        <th>Actual Time</th>
                                                        <th>Done or Not</th>
                                                        <th>Remark</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let data of ViewDialogeData?.bottle_sterilization">

                                                        <td>{{ formatDateTime(data?.sterilization_timeArray) }}</td>
                                                        <td>{{ formatDateTime(data?.actual_sterilization_timeArray) }}
                                                        </td>
                                                        <td>{{ data?.done_sterilization == 1 ? 'Yes' : '-' }}</td>
                                                        <td>{{ data?.sterilization_remark ?? '-' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                </p-accordionTab>

                                <!-- Bottle Sterilization End-->

                                <!--Solid -->
                                <p-accordionTab header="Solid Food">
                                    <div class="add-listings-box py-0 peach-bg">
                                        <h3>Solid</h3>

                                        <!-- Snack -->
                                        <div class="dataBydate">
                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>
                                                    <th>Snack</th>
                                                    <th>Reference Time</th>
                                                    <th>Actual Time</th>
                                                    <th>Food Component</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{{ ViewDialogeData?.solid_other_snack == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ formatDateTime(ViewDialogeData?.solid_other_snack_time) }}
                                                    </td>
                                                    <td>{{ formatDateTime(ViewDialogeData?.solid_other_snack_actual_time) }}
                                                    </td>
                                                    <td>{{ ViewDialogeData?.soild_other_snack_food_components ?? '-' }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                        <!-- Regular Meal -->
                                        <div class="dataBydate">
                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>
                                                    <th>Meal</th>
                                                    <th>Reference Time</th>
                                                    <th>Actual Time</th>
                                                    <th>Food Component</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let food of ViewDialogeData?.regular_food">
                                                    <td>{{ food?.regular_meal_type == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ food?.regular_meal_time ? (food?.regular_meal_time | date: 'h:mm:ss') : '-' }}
                                                    </td>
                                                    <td>{{ food?.regular_meal_actual_time ? (food?.regular_meal_actual_time | date: 'h:mm:ss') : '-' }}
                                                    </td>
                                                    <td>{{ food?.regular_meal_food_components ? food?.regular_meal_food_components : '-' }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                        <!-- Lunch -->
                                        <div class="dataBydate">
                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>
                                                    <th>Lunch</th>
                                                    <th>Reference Time</th>
                                                    <th>Actual Time</th>
                                                    <th>Food Component</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let food of ViewDialogeData?.lunch_food">
                                                    <td>{{ food?.regular_meal_lunch == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ food?.regular_meal_lunch_time ? (food?.regular_meal_lunch_time | date: 'h:mm:ss') : '-' }}
                                                    </td>
                                                    <td>{{ food?.regular_meal_lunch_actual_time ? (food?.regular_meal_lunch_actual_time | date: 'h:mm:ss') : '-' }}
                                                    </td>
                                                    <td>{{ food?.regular_meal_lunch_food_components ? food?.regular_meal_lunch_food_components : '-' }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                        <!-- Dinner -->
                                        <div class="dataBydate">
                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>
                                                    <th>Dinner</th>
                                                    <th>Reference Time</th>
                                                    <th>Actual Time</th>
                                                    <th>Food Component</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let food of ViewDialogeData?.dinner_food">
                                                    <td>{{ food?.regular_meal_dinner == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ food?.regular_meal_dinner_time ? (food?.regular_meal_dinner_time | date: 'h:mm:ss') : '-' }}
                                                    </td>
                                                    <td>{{ food?.regular_meal_dinner_actual_time ? (food?.regular_meal_dinner_actual_time | date: 'h:mm:ss') : '-' }}
                                                    </td>
                                                    <td>{{ food?.regular_meal_dinner_food_components ? food?.regular_meal_dinner_food_components : '-' }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    </div>

                                </p-accordionTab>
                                <!-- Solid End-->

                                <!-- Food/Medicine Allergy -->
                                <p-accordionTab header="Food/Medicine Allergy"
                                    [class.allergyhighlight]="ViewDialogeData?.food_medicine_allergy == '1'">
                                    <div class="row peach-bg">
                                        <div class="my-3">
                                            <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                            <!-- <h3>Food/Medicine Allergy</h3> -->
                                            <div class="my-3">
                                                <div class="row">
                                                    <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                        <!-- <div class="form-group">
                                <label for="">Food/Medicine Allergy</label>
                                <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.food_medicine_allergy== 1? 'Yes': '-' ?? '-'
                                }}" />
                            </div> -->
                                                        <ng-container>
                                                            <div class="form-group">
                                                                <label for="">Food/Medicine Allergy</label>
                                                                <!-- <input type="text" readonly class="form-control"
                                                                *ngIf="ViewDialogeData?.food_medicine_allergy !== null && ViewDialogeData?.food_medicine_allergy !== '' && ViewDialogeData?.food_medicine_allergy !== undefined; else elseBlock"
                                                                value="{{ ViewDialogeData?.food_medicine_allergy == '1'? 'Yes': 'No'}}" /> -->


                                                                <div type="text" readonly
                                                                    *ngIf="ViewDialogeData?.food_medicine_allergy !== null && ViewDialogeData?.food_medicine_allergy !== '' && ViewDialogeData?.food_medicine_allergy !== undefined; else elseBlock">
                                                                    {{ViewDialogeData?.food_medicine_allergy == '1'?
                                                                    'Yes': 'No'
                                                                    }}
                                                                </div>
                                                                <ng-template #elseBlock>
                                                                    <!-- <input type="text" readonly class="form-control"
                                                                    value="-" /> -->
                                                                    <div type="text" readonly>-</div>
                                                                </ng-template>
                                                            </div>
                                                        </ng-container>
                                                    </div>

                                                    <div class="col-xl-4 col-lg-12
                                col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Food Component</label>
                                                            <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.food_medicine_allergy_specify_prod ?? '-'
                                }}" /> -->

                                                            <div type="text" readonly>{{
                                                                ViewDialogeData?.food_medicine_allergy_specify_prod
                                                                ?? '-'
                                                                }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Food/Medicine Allergy End-->
                                <p-accordionTab header="Diaper Change">
                                    <div class="add-listings-box py-0 peach-bg">
                                        <h3>Diaper Summary</h3>
                                        <div class="add-listings-box py-0">

                                            <div class="dataBydate">
                                            <table class="table font-size-13">
                                                <thead>
                                                    <tr>
                                                        <th>Frequency</th>
                                                        <th>Reference Time</th>
                                                        <th>Actual Time</th>
                                                        <th>Done or Not</th>
                                                        <th>Remark</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let data of ViewDialogeData?.diaper_calculation">
                                                        <td>{{ (ViewDialogeData?.diaper_change_frequency) }}</td>
                                                        <td>{{ formatDateTime(data?.diaper_timeArray) }}</td>
                                                        <td>{{ formatDateTime(data?.diaper_actual_time) }}</td>
                                                        <td>{{ data?.diaper_checkbox == 1 ? 'Yes' : '-' }}</td>
                        
                                                        <td>{{ data?.diaperRemark ?? '-' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                            <p-accordionTab header="Diaper Change">
                                    <div class="add-listings-box py-0 peach-bg">
                                    <div class="row peach-bg">
                                        <div class="my-3">
                                           
                                            <table class="table font-size-13">
                                                <thead>
                                                    <tr>
                                                        <th>Frequency</th>
                                                        <th>Reference Time</th>
                                                        <th>Actual Time</th>
                                                        <th>Done or Not</th>
                                                        <th>Remark</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let data of ViewDialogeData?.diaper_calculation">
                                                        <td>{{ (ViewDialogeData?.diaper_change_frequency) }}</td>
                                                        <td>{{ formatDateTime(data?.diaper_timeArray) }}</td>
                                                        <td>{{ formatDateTime(data?.diaper_actual_time) }}</td>
                                                        <td>{{ data?.diaper_checkbox == 1 ? 'Yes' : '-' }}</td>
                        
                                                        <td>{{ data?.diaperRemark ?? '-' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <h3>Additional Diaper Change</h3>
                                            <table class="table font-size-13">
                                              
                                                <thead>
                                                    <tr>
                                                        <th>Diaper Change</th>
                                                        <th>Change Time</th>
                                                        <th>Remark</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let change of ViewDialogeData?.diaper_change_times">
                                                        <td>
                                                            <div class="form-group">

                                                                <div type="text" readonly>
                                                                    {{ change?.change ? 'Yes' : 'No' }}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-group">

                                                                <div type="text" readonly>
                                                                    {{ formatDateTime(change?.time) }}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="form-group">

                                                                <div type="text" readonly>
                                                                    {{ change?.diaper_remark ?? '-' }}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Bathing -->
                                <p-accordionTab header="Bathing">
                                    <div class="add-listings-box py-0 peach-bg">
                                        <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                        <h3>Bathing</h3>
                                        <!-- Tub -->
                                        <div class="row">
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <div class="form-group">
                                                    <label for="">Tub</label>
                                                    <div type="text" readonly>{{ ViewDialogeData?.bathing_tub == 1? 'Yes': '-'
                                            }}</div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <div class="form-group">
                                                    <label for="">Frequency</label>
                                                    <div type="text" readonly>
                                                        {{ViewDialogeData?.bathing_tub_frequency??'-' }}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                       <div class="dataBydate">
                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>


                                                    <th>Reference Time</th>
                                                    <th>Actual Time</th>
                                                    <th>Done or Not</th>
                                                    <th>Remark</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let time of ViewDialogeData?.tub_bathing">
                                                    <td>{{ formatDateTime(time?.bathing_tub_time) }}</td>
                                                    <td>{{ formatDateTime(time?.bathing_tub_actual_time) }}</td>
                                                    <td>{{ time?.tub_checkbox == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ time?.tubBathing_remark ?? '-' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                        <!-- <div class="col-xl-4 col-lg-12
            col-md-12" *ngIf="ViewDialogeData?.bathing_tub_time !== '00:00:00'">
            <div class="form-group">
                <label>Time</label>
                <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.bathing_tub_time  ??'-'
                }}"  />
            </div>
        </div> -->




                                        <!-- Shower -->
                                        <div class="row">
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <div class="form-group">
                                                    <label for="">Shower</label>
                                                    <div type="text" readonly>{{ ViewDialogeData?.bathing_shower == 1? 'Yes': '-'
                                            }}</div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <div class="form-group">
                                                    <label for="">Frequency</label>
                                                    <div type="text" readonly>
                                                        {{ViewDialogeData?.bathing_shower_frequency?.frequency??' -' }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dataBydate">
                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>


                                                    <th>Reference Time</th>
                                                    <th>Actual Time</th>
                                                    <th>Done or Not</th>
                                                    <th>Remark</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let time of ViewDialogeData?.tub_bathing">
                                                    <td>{{ formatDateTime(time?.bathing_shower_time) }}</td>
                                                    <td>{{ formatDateTime(time?.bathing_shower_actual_time) }}</td>
                                                    <td>{{ time?.shower_checkbox == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ time?.shower_remark ?? '-' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>

                                        <!-- <div class="col-xl-4 col-lg-12
            col-md-12" *ngIf="ViewDialogeData?.bathing_shower_time !== '00:00:00'">
            <div class="form-group">
                <label>Time</label>
                <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.bathing_shower_time  ??'-'
            }}"  />
            </div>
        </div> -->



                                    </div>
                                </p-accordionTab>
                                <!-- <h3>Bathing</h3> -->
                                <!-- Bathing End -->

                                <!-- potty training -->
                                <p-accordionTab header="Potty Training">
                                    <div class="add-listings-box py-0 peach-bg">
                                        <h3>Potty Training</h3>
                                        <div class="row" *ngFor="let train of ViewDialogeData?.child_training">
                                            <div class="col-xl-4 col-lg-12
        col-md-12">
                                                <div class="form-group">
                                                    <label for="">Potty Training</label>
                                                    <div type="text" readonly>{{ train?.potty_training== 1? 'Yes': '-' ?? '-'
            }}</div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
                col-md-12">
                                                <div class="form-group">
                                                    <label>Special Instructions</label>
                                                    <div type="text" readonly>{{ train?.training_child ?? '-'
                }}</div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="dataBydate">
                                        <table class="table font-size-13 dataBydate">
                                            <thead>
                                                <tr>

                                                    <th>Changing Time</th>
                                                    <th>Actual Time</th>
                                                    <th>Done Or Not</th>
                                                    <th>Remarks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let train of ViewDialogeData?.child_training">
                                                    <td>{{ formatDateTime(train?.time_training_child) }}</td>
                                                    <td>{{ formatDateTime(train?.time_training_actual_child) }}</td>
                                                    <td>{{ train?.done_training_child == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ train?.training_child_remark ?? '-' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                        <!-- <div class="row" *ngFor="let train of ViewDialogeData?.child_training">
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <div class="form-group">
                                                    <label>Done or not</label>
                                                    <div type="text" readonly> {{ train?.done_training_child== 1? 'Yes': '-' ?? '-'
                                                }} </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <div class="form-group">
                                                    <label for="">Time </label>
                                                    <div type="text" readonly>
                                                        {{formatDateTime(train?.time_training_child)}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                <div class="form-group">
                                                    <label for="">Actual Time </label>
                                                    <div type="text" readonly>
                                                        {{formatDateTime(train?.time_training_actual_child)}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
                col-md-12">
                                                <div class="form-group">
                                                    <label>Remark</label>
                                                    <div type="text" readonly>{{ train?.training_child_remark ?? '-'
                }}</div>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </p-accordionTab>
                                <!-- potty training End-->

                                <!-- Skin Cares -->
                                <p-accordionTab header="Skin Cares">
                                    <div class="row peach-bg">
                                        <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                        <div class="my-3">
                                            <!-- <h3>Skin Cares</h3> -->
                                            <div class="row" *ngFor="let care of ViewDialogeData?.skin_care">

                                                <div class="col-xl-4 col-lg-12
                                col-md-12">

                                                    <div class="form-group viewSummary">
                                                        <label for="">Skin care product for baby:</label>
                                                        <div type="text"> {{
                                                        (care?.skin_care_lotion == 1 ? 'Lotion' : '') +
                                                        (care?.skin_care_lotion == 1 &&
                                                        care?.skin_care_baby_oil == 1 ? ' and ' : '') +
                                                        (care?.skin_care_baby_oil == 1 ? 'Baby Oil' : '') +
                                                        (care?.skin_care_others == 1 &&
                                                        (care?.skin_care_lotion == 1 ||
                                                        care?.skin_care_baby_oil == 1) ? ', ' : '') +
                                                        (care?.skin_care_others == 1 ? 'Others' : '')
                                                        }}</div>


                                                    </div>
                                                    <div class="form-group">
                                                        <label>Specify Product</label>
                                                        <!-- <input type="text" readonly class="form-control" value="{{ care?.skin_care_specify_produ ? care?.skin_care_specify_produ :'-'
                                }}" /> -->
                                                        <div type="text" readonly>{{ care?.skin_care_specify_produ ?
                                                                care?.skin_care_specify_produ :'-'
                                                                }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Skin Cares End-->

                                <!-- Wipes -->
                                <p-accordionTab header="Wipes">
                                    <div class="row peach-bg">
                                        <div class="my-3">
                                            <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                            <!-- <h3>Wipes</h3> -->
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                    <!-- <div class="form-group">
                                <label>Wipes</label>
                                <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.skin_care_Wipes== 1? 'Yes': '-' ?? '-'
                                }}" />
                            </div> -->
                                                    <ng-container>
                                                        <div class="form-group">
                                                            <label for="">Wipes</label>
                                                            <!-- <input type="text" readonly class="form-control"
                                        *ngIf="ViewDialogeData?.skin_care_Wipes !== null && ViewDialogeData?.skin_care_Wipes !== ''  && ViewDialogeData?.skin_care_Wipes !== undefined; else elseBlock"
                                        value="{{ ViewDialogeData?.skin_care_Wipes == '1'? 'Yes': 'No'}}" />
                                    <ng-template #elseBlock>
                                        <input type="text" readonly class="form-control" value="-" />
                                    </ng-template> -->
                                                            <!-- <input type="text" readonly class="form-control"
                                                                *ngIf="ViewDialogeData?.skin_care_Wipes !== null && ViewDialogeData?.skin_care_Wipes !== '' && ViewDialogeData?.skin_care_Wipes !== 0; else elseBlock"
                                                                value="{{ ViewDialogeData?.skin_care_Wipes == '1' ? 'Yes' : 'No'}}" /> -->
                                                            <div type="text" readonly
                                                                *ngIf="ViewDialogeData?.skin_care_Wipes !== null && ViewDialogeData?.skin_care_Wipes !== ''  && ViewDialogeData?.skin_care_Wipes !== undefined; else elseBlock">
                                                                {{ ViewDialogeData?.skin_care_Wipes == '1'?
                                                                    'Yes': 'No'}}
                                                            </div>

                                                            <ng-template #elseBlock>
                                                                <!-- <input type="text" readonly class="form-control"
                                                                    value="-" /> -->
                                                                <div type="text" readonly>-</div>
                                                            </ng-template>

                                                        </div>
                                                    </ng-container>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                col-md-12">
                                                    <div class="form-group">
                                                        <label>Special Instruction</label>
                                                        <!-- <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.skin_care_special_instructions ?? '-'
                                }}" /> -->

                                                        <div type="text" readonly>{{
                                                                ViewDialogeData?.skin_care_special_instructions ??
                                                                '-'
                                                                }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Wipes End-->

                                <!-- Changing Clothes -->
                                <p-accordionTab header="Changing Clothes">
                                    <div class="add-listings-box py-0 peach-bg">
                                        <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                        <h3>Changing Clothes</h3>
                                        <div class="row">


                                            <div class="col-xl-4 col-lg-12 col-md-12">
                                                <div class="form-group">
                                                    <label>Special Instructions</label>
                                                    <div type="text" readonly>
                                                        {{ ViewDialogeData?.changing_colthes_spiecal_instructions ?? '-'}}
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="dataBydate">
                                            <table class="table font-size-13 dataBydate">
                                                <thead>
                                                    <tr>
                                                        <th>Changing Clothes</th>
                                                        <th>Changing Time</th>
                                                        <th>Actual Time</th>
                                                        <th>Remarks</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let change of ViewDialogeData?.changing_clothes">
                                                        <td>{{ change?.changing_colthes == 1 ? 'Yes' : '-' }}</td>
                                                        <td>{{ formatDateTime(change?.Changing_time) }}</td>
                                                        <td>{{ formatDateTime(change?.Changing_actual_time) }}</td>
                                                        <td>{{ change?.clothe_remark ?? '-' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Changing Clothes End-->
                                <!-- sleep training -->
                                <p-accordionTab header=" Sleep Time">
                                    <div class="row peach-bg">
                                        <div class="row my-4">
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
                        col-md-12">

                                                    <label>Sleep Training
                                                    </label>
                                                    <div type="text" readonly>
                                                        {{ViewDialogeData?.sleeping_training_checkbox ??'-'}}
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- <h3>Sleep Time</h3> -->
                                            <div class="row" *ngFor="let time of ViewDialogeData?.sleeping_training">
                                                <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                    <div class="form-group">
                                                        <label> Sleep Time</label>
                                                        <div type="text" readonly>
                                                            {{formatDateTime(time?.sleeping_time) }}
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12
                                                col-md-12">
                                                    <div class="form-group">
                                                        <label> Sleep Remark</label>

                                                        <div type="text" readonly>
                                                            {{ time?.sleeping_remark ?? '-'}}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Sleeping Independently -->
                                <p-accordionTab header="Sleeping Independently">


                                    <div class="add-listings-box py-0 peach-bg">
                                        <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                        <h3>Sleeping</h3>
                                        <div class="row">
                                            <div class="col-xl-4 col-lg-12
                col-md-12">

                                                <ng-container>
                                                    <div class="form-group">
                                                        <label for="">Sleeping Independently</label>
                                                        <div type="text" readonly
                                                            *ngIf="ViewDialogeData?.sleeping_independently !== null && ViewDialogeData?.sleeping_independently !== ''  && ViewDialogeData?.sleeping_independently !== undefined; else elseBlock">
                                                            {{ ViewDialogeData?.sleeping_independently == '1'? 'Yes': 'No'}}
                                                        </div>
                                                        <ng-template #elseBlock>
                                                            <div type="text" readonly>
                                                                {{"-" }}
                                                            </div>
                                                        </ng-template>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <!-- <div class="add-listings-box py-0"> -->
                                        <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                        <div class="dataBydate">
                                        <table class="table font-size-13 dataBydate">
                                            <thead>
                                                <tr>
                                                    <th>With Mother</th>
                                                    <th>Reference Time</th>
                                                    <th>Actual Time</th>
                                                    <th>Remarks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let sleeping of ViewDialogeData?.sleeping_time">
                                                    <td>{{ sleeping?.sleeping_mother == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ formatDateTime(sleeping?.sleeping_mother_time) }}</td>
                                                    <td>{{ formatDateTime(sleeping?.sleeping_mother_actual_time) }}</td>
                                                    <td>{{ sleeping?.sleeping_mother_remark ?? '-' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="dataBydate">
                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>
                                                    <th>With Nurse</th>
                                                    <th>Reference Time</th>
                                                    <th>Actual Time</th>
                                                    <th>Remarks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let sleeping of ViewDialogeData?.nanny_time">
                                                    <td>{{ sleeping?.sleeping_nanny == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ formatDateTime(sleeping?.sleeping_nanny_time) }}</td>
                                                    <td>{{ formatDateTime(sleeping?.sleeping_nanny_actual_time) }}</td>
                                                    <td>{{ sleeping?.sleeping_nanny_remark ?? '-' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Sleeping Independently End -->

                                <!-- Temperature Monitoring -->
                                <p-accordionTab header="Vitamins/Medications">
                                    <div class="add-listings-box py-0 peach-bg">
                                        <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->

                                        <!-- Temperature Monitoring End-->

                                        <!-- Vitamins/Medications -->
                                        <h3>Vitamins/Medications</h3>
                                        <div class="row"
                                            *ngFor="let vitamin of ViewDialogeData?.vitamins_medications_json">
                                            <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                <div class="form-group">
                                                    <label>Medications</label>
                                                    <!-- <input type="text" readonly class="form-control" value="{{ vitamin?.vitamin ?  vitamin?.vitamin: '-'
                                }}" /> -->

                                                    <div type="text" readonly>{{ vitamin?.vitamin ?
                                                                vitamin?.vitamin : '-'
                                                                }}</div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                <div class="form-group">
                                                    <label>Frequency</label>
                                                    <!-- <input type="text" readonly class="form-control" value="{{ vitamin?.frequency ? vitamin?.frequency : '-'
                                }}" /> -->
                                                    <div type="text" readonly>{{ vitamin?.frequency ?
                                                                vitamin?.frequency : '-' }}</div>

                                                </div>
                                            </div>
                                            <table class="table font-size-13 dataBydate">
                                                <thead>
                                                    <tr>

                                                        <th>Time</th>
                                                        <th>Actual Time</th>
                                                        <th>Done or not</th>
                                                        <th>Remarks</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let times of vitamin.medicine_time">
                                                        <td> {{ formatDateTime(times?.time) }}
                                                        </td>
                                                        <td> {{ formatDateTime(times?.actual_time) }}
                                                        </td>
                                                        <td>{{ times?.vitamin_checkbox == 1 ? 'Yes' : '-' }}</td>
                                                        <td>{{ times?.vitamin_remark ? times?.vitamin_remark : '-' }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <!-- Vitamins/Medications End-->

                                        <!-- Vaccinations -->

                                    </div>
                                </p-accordionTab>
                                <!-- Vaccinations End-->

                                <!-- Playing with child outside? -->
                                <p-accordionTab header="Activities">
                                    <div class="add-listings-box py-0 peach-bg">
                                        <h3>Activities </h3>
                                        <!-- <h3>Playing with child outside</h3> -->
                                        <div class="row" *ngFor="let child of ViewDialogeData?.outside_child">
                                            <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                <!-- <div class="form-group">
                                <label for="">Playing with child outside</label>
                                <input type="text" readonly class="form-control" value="{{ viewDetail.play_child_outside== 1 'Yes': '-'  '-'
                                }}" />
                            </div> -->
                                                <ng-container>
                                                    <div class="form-group">
                                                        <label for="">Playing with child outside</label>
                                                        <!-- <input type="text" readonly class="form-control"
                                                            *ngIf="child?.play_child_outside !== null && child?.play_child_outside !== ''  && child?.play_child_outside !== undefined; else elseBlock"
                                                            value="{{ child?.play_child_outside == '1'? 'Yes': 'No'}}" /> -->

                                                        <div type="text" readonly
                                                            *ngIf="child?.play_child_outside !== null && child?.play_child_outside !== ''  && child?.play_child_outside !== undefined; else elseBlock">
                                                            {{ child?.play_child_outside == '1'? 'Yes': 'No'}}
                                                        </div>
                                                        <ng-template #elseBlock>
                                                            <!-- <input type="text" readonly class="form-control"
                                                                value="-" /> -->
                                                            <div type="text" readonly>-</div>
                                                        </ng-template>
                                                    </div>
                                                </ng-container>
                                            </div>

                                            <div class="col-xl-4 col-lg-12
                                col-md-12">
                                                <div class="form-group">
                                                    <label for="">Special Instructions</label>
                                                    <!-- <input type="text" readonly class="form-control" value="{{ child?.play_child_outside_special_Instructions? child?.play_child_outside_special_Instructions: '-'
                                }}" /> -->
                                                    <div type="text" readonly>{{
                                                            child?.play_child_outside_special_Instructions?
                                                            child?.play_child_outside_special_Instructions: '-'
                                                            }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dataBydate">
                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>

                                                    <th>Reference Time</th>
                                                    <th>Actual Time</th>
                                                    <th>Done Or Not</th>
                                                    <th>Remarks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let child of ViewDialogeData?.outside_child">
                                                    <td>{{ formatDateTime(child?.play_child_outside_time) }}</td>
                                                    <td>{{ formatDateTime(child?.play_child_outside_actual_time) }}</td>
                                                    <td>{{ child?.play_child_outside_done == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ child?.play_child_outside_remark ?? '-' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                        <!-- Playing with child outside? End-->

                                        <!-- Escort child outside? -->

                                        <!-- <h3>Escort child outside</h3> -->
                                        <div class="row" *ngFor="let child of ViewDialogeData?.escort_child">
                                            <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                <!-- <div class="form-group">
                                <label for="">Escort child outside?</label>
                                <input type="text" readonly class="form-control" value="{{ viewDetail?.escort_child_outside== 1? 'Yes': '-' ?? '-'
                                }}" />
                            </div> -->
                                                <ng-container>
                                                    <div class="form-group">
                                                        <label for="">Escort child outside</label>
                                                        <!-- <input type="text" readonly class="form-control"
                                                            *ngIf="child?.escort_child_outside !== null && child?.escort_child_outside !== ''  && child?.escort_child_outside !== undefined; else elseBlock"
                                                            value="{{ child?.escort_child_outside == '1'? 'Yes': 'No'}}" /> -->
                                                        <div type="text" readonly
                                                            *ngIf="child?.escort_child_outside !== null && child?.escort_child_outside !== ''  && child?.escort_child_outside !== undefined; else elseBlock">
                                                            {{ child?.escort_child_outside == '1'? 'Yes': 'No'}}
                                                        </div>
                                                        <ng-template #elseBlock>
                                                            <!-- <input type="text" readonly class="form-control"
                                                                value="-" /> -->
                                                            <div readonly>-</div>
                                                        </ng-template>
                                                    </div>
                                                </ng-container>
                                            </div>

                                            <div class="col-xl-4 col-lg-12
                                col-md-12">
                                                <div class="form-group">
                                                    <label for="">Special Instructions</label>
                                                    <!-- <input type="text" readonly class="form-control" value="{{ child?.escort_child_outside_special_Instructions?child?.escort_child_outside_special_Instructions: '-'
                                }}" /> -->
                                                    <div type="text" readonly>{{
                                                            child?.escort_child_outside_special_Instructions?
                                                            child?.escort_child_outside_special_Instructions: '-'
                                                            }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dataBydate">
                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>

                                                    <th>Reference Time</th>
                                                    <th>Actual Time</th>
                                                    <th>Done Or Not</th>
                                                    <th>Remarks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let child of ViewDialogeData?.escort_child">
                                                    <td>{{ formatDateTime(child?.escort_child_outside_time) }}</td>
                                                    <td>{{ formatDateTime(child?.escort_child_outside_actual_time) }}
                                                    </td>
                                                    <td>{{ child?.escort_child_outside_done == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ child?.escort_child_outside_remark ?? '-' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                         </div>
                                        <!-- <div class="row" *ngFor="let child of ViewDialogeData?.escort_child">
                                            <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                <div class="form-group">
                                                    <label for="">Done or Not</label>
                                                    <div type="text" readonly>{{ child?.escort_child_outside_done == 1? 'Yes': '-'
                                                        }}</div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
                col-md-12">
                                                <div class="form-group">
                                                    <label for="">Time</label>
                                                    <div type="text" readonly>
                                                        {{formatDateTime(child?.other_child_outside_time)}}
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                <div class="form-group">
                                                    <label for="">Remark</label>
                                                    <div type="text" readonly>
                                                        {{child?.escort_child_outside_remark??'-' }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->

                                        <!-- Escort child outside? End-->
                                        <!-- activity name -->
                                        <!-- <h3>Enter activities name here</h3> -->
                                        <div class="row" *ngFor="let child of ViewDialogeData?.activities_name">
                                            <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                <!-- <div class="form-group">
                                <label for="">Escort child outside?</label>
                                <input type="text" readonly class="form-control" value="{{ viewDetail?.escort_child_outside== 1? 'Yes': '-' ?? '-'
                                }}" />
                            </div> -->
                                                <ng-container>
                                                    <div class="form-group">
                                                        <label for="">Activity</label>
                                                        <!-- <input type="text" readonly class="form-control"
                                                            *ngIf="child?.other_activity !== null && child?.other_activity !== ''  && child?.other_activity !== undefined; else elseBlock"
                                                            value="{{ child?.other_activity == '1'? 'Yes': 'No'}}" /> -->

                                                        <div type="text" readonly
                                                            *ngIf="child?.other_activity !== null && child?.other_activity !== ''  && child?.other_activity !== undefined; else elseBlock">
                                                            {{ child?.other_activity == '1'? 'Yes': 'No'}}
                                                        </div>
                                                        <ng-template #elseBlock>
                                                            <!-- <input type="text" readonly class="form-control" -->
                                                            <!-- value="-" /> -->
                                                            <div>-</div>
                                                        </ng-template>
                                                    </div>
                                                </ng-container>
                                            </div>

                                            <div class="col-xl-4 col-lg-12
                                col-md-12">
                                                <div class="form-group">
                                                    <label for="">Activity Name</label>
                                                    <!-- <input type="text" readonly class="form-control" value="{{ child?.activities_child_outside_special_Instructions?child?.activities_child_outside_special_Instructions: '-'
                                }}" /> -->
                                                    <div type="text" readonly>{{
                                                            child?.activities_child_outside_special_Instructions?child?.activities_child_outside_special_Instructions:
                                                            '-'
                                                            }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dataBydate">
                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>

                                                    <th>Reference Time</th>
                                                    <th>Actual Time</th>
                                                    <th>Done Or Not</th>
                                                    <th>Remarks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let child of ViewDialogeData?.activities_name">
                                                    <td>{{ formatDateTime(child?.other_child_outside_time) }}</td>
                                                    <td>{{ formatDateTime(child?.other_child_outside_actual_time) }}
                                                    </td>
                                                    <td>{{ child?.other_child_outside_done == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ child?.other_child_outside_remark ?? '-' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                        <!-- <div class="row" *ngFor="let child of ViewDialogeData?.activities_name">
                                            <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                <div class="form-group">
                                                    <label for="">Done or Not</label>
                                                    <div type="text" readonly>{{ child?.other_child_outside_done == 1? 'Yes': '-'
                                                        }}</div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
                col-md-12">
                                                <div class="form-group">
                                                    <label for="">Time</label>
                                                    <div type="text" readonly>
                                                        {{formatDateTime(child?.other_child_outside_time)}}
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                <div class="form-group">
                                                    <label for="">Remark</label>
                                                    <div type="text" readonly>
                                                        {{child?.other_child_outside_remark??'-' }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </p-accordionTab>
                                <!-- EscoRemark -->
                                <p-accordionTab header="Remark">
                                    <div class="row peach-bg">
                                        <div class="my-3">
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
                col-md-12">
                                                    <div class="form-group">

                                                        <div type="text" readonly>{{ ViewDialogeData?.remarks ?? '-'
                    }}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">

                                                <label for="">Nurse Remarks</label>
                                                <div class="col-md-3 form-group"
                                                    *ngFor="let change of ViewDialogeData?.nurse_remarks let index = index">
                                                    <div class="input-group2 form-group ">
                                                        <span> {{ index+1 }}. </span>
                                                        <div type="formbox" readonly>
                                                            {{ change?.nurse_remark ?? '-' }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                            </p-accordion>
                            <!-- Remark End-->
                        </form>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-template pTemplate="footer" let-list let-i="rowIndex">
            <div class="row ">
                <div class="col-md">
                   <table class="table-stamp w-100 text-start">
                        <tr class="row">
                            <td class="col-sm-6 col-lg-3" ><b>Created By:</b> {{ createdby}}</td>
                            <td class="col-sm-6 col-lg-3" ><b>Created Date&Time:</b>{{ viewtime.substring(0, 16).replace('T', ' ') }}
                            </td>
                            <td class="col-sm-6 col-lg-3" ><b>Updated By:</b> {{ updatedby??'-'}}</td>
                            <td class="col-sm-6 col-lg-3">
                                <b>Updated Date&Time:</b>
                                 <span *ngIf="updatedtime; else noData">{{ updatedtime.substring(0, 16).replace('T', ' ') }}</span>
                                <ng-template #noData> -</ng-template>
                              </td>
                        </tr>
                    </table>
                </div>
                <div class="col-md-auto"> <p-button (click)="showoneToThreeyears=false;softReload();" label="Ok"
                        styleClass="p-button-text"></p-button> </div>
            </div>


        </ng-template>
    </p-dialog>
    <!----------------------------------------------------------- three year to above--------------------------------------------------------->
    <!-- ***************************************************************************************************************** -->
    <p-dialog header="Three Years to Above Baby Details" [(visible)]="showThreeYearToAbove" [modal]="true"
        [style]="{ width: '90vw' }" [draggable]="false" [resizable]="false" [closable]="false">
        <!-- Start -->
        <ng-container>
            <div class="row viewSummary ">
                <div class="col-lg-12">
                    <div class="my-profile-box">
                        <form>
                            <!-- Baby detail -->
                            <!-- accordian on listing -->
                            <p-accordion>
                                <p-accordionTab header="Baby Details">
                                    <div class="row peach-bg">
                                        <div class="my-4">
                                            <!-- <h3>Baby Details</h3> -->
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Name :</label>
                                                        <div type="text" readonly>
                                                            {{viewDetail?.name ?? '-' | titlecase }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12
                col-md-12">
                                                    <div class="form-group">
                                                        <label>Gender :</label>
                                                        <div type="text" readonly>
                                                            {{viewDetail?.gender ?? '-' | titlecase }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Date of Birth :</label>
                                                        <div type="text" readonly>
                                                            {{viewDetail?.date_of_birth ?? '-' | titlecase }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Days :</label>
                                                        <div type="text" readonly>
                                                            {{viewDetail?.days ?? '-'  }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Months :</label>
                                                        <div type="text" readonly>
                                                            {{viewDetail?.months ?? '-' }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Years :</label>
                                                        <div type="text" readonly>
                                                            {{viewDetail?.years ?? '-'  }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
            col-md-12">
                                                    <!-- <div class="form-group">
                <label for="">Food/Medicine Allergy</label>
                <input type="text" readonly class="form-control" value="{{ viewDetail?.food_medicine_allergy== 1? 'Yes': '-' ?? '-'
            }}" />
            </div> -->
                                                    <ng-container>
                                                        <div class="form-group">
                                                            <label for="">Food/Medicine Allergy</label>
                                                            <div type="text" readonly *ngIf="ViewDialogeData?.food_medicine_allergy !==
                                                null && ViewDialogeData?.food_medicine_allergy
                                                !== '' && ViewDialogeData?.food_medicine_allergy
                                                !== undefined; else elseBlock">

                                                                {{ ViewDialogeData?.food_medicine_allergy == '1'? 'Yes': 'No'}}
                                                            </div>
                                                            <ng-template #elseBlock>
                                                                <div type="text" readonly>
                                                                    {{"-"}}
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                    </ng-container>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Food Component</label>
                                                        <div type="text" readonly>
                                                            {{ViewDialogeData?.food_medicine_allergy_specify_prod??'-' }}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- DAILY CARE -->

                                <div class="row">
                                    <p-accordionTab header="Daily Cares">
                                        <div class="row peach-bg">
                                            <div class="my-3">
                                                <!-- <label for=""></label> -->
                                                <div class="row">
                                                    <!-- <label class="subHeading my-2">Feeding: Prepared By</label> -->
                                                    <div type="text">Feeding: Prepared by
                                                        {{
                                            (ViewDialogeData?.feeding_prepared_mother == 1 ? 'Mother' : '') +
                                            (ViewDialogeData?.feeding_prepared_mother == 1 &&
                                            ViewDialogeData?.feeding_prepared_nanny == 1 ? ' and ' : '') +
                                            (ViewDialogeData?.feeding_prepared_nanny == 1 ? 'Nurse' : '') +
                                            (ViewDialogeData?.feeding_prepared_other == 1 &&
                                            (ViewDialogeData?.feeding_prepared_mother == 1 ||
                                            ViewDialogeData?.feeding_prepared_nanny == 1) ? ', ' : '') +
                                            (ViewDialogeData?.feeding_prepared_other == 1 ? 'Other' : '')
                                            }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </p-accordionTab>
                                    <p-accordionTab header="Solid Food">
                                        <div class="add-listings-box py-0 peach-bg">
                                            <div class="my-3">
                                                <!-- <h3>Solid</h3> -->

                                                <!-- Snack -->
                                                <div class="dataBydate">
                                                <table class="table font-size-13">
                                                    <thead>
                                                        <tr>
                                                            <th>Snack</th>
                                                            <th>Reference Time</th>
                                                            <th>Actual Time</th>
                                                            <th>Food Component</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{{ ViewDialogeData?.solid_other_snack == 1 ? 'Yes' : '-' }}
                                                            </td>
                                                            <td>{{ formatDateTime(ViewDialogeData?.solid_other_snack_time) }}
                                                            </td>
                                                            <td>{{ formatDateTime(ViewDialogeData?.solid_other_snack_actual_time) }}
                                                            </td>
                                                            <td>{{ ViewDialogeData?.soild_other_snack_food_components ?? '-' }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                                <!-- Regular Meal -->
                                                <div class="dataBydate">
                                                <table class="table font-size-13">
                                                    <thead>
                                                        <tr>
                                                            <th>Meal</th>
                                                            <th>Reference Time</th>
                                                            <th>Actual Time</th>
                                                            <th>Food Component</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let food of ViewDialogeData?.regular_food">
                                                            <td>{{ food?.regular_meal_type == 1 ? 'Yes' : '-' }}</td>
                                                            <td>{{ food?.regular_meal_time ? (food?.regular_meal_time | date: 'h:mm:ss') : '-' }}
                                                            </td>
                                                            <td>{{ food?.regular_meal_actual_time ? (food?.regular_meal_actual_time | date: 'h:mm:ss') : '-' }}
                                                            </td>
                                                            <td>{{ food?.regular_meal_food_components ? food?.regular_meal_food_components : '-' }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                                <!-- Lunch -->
                                                <div class="dataBydate">
                                                <table class="table font-size-13">
                                                    <thead>
                                                        <tr>
                                                            <th>Lunch</th>
                                                            <th>Reference Time</th>
                                                            <th>Actual Time</th>
                                                            <th>Food Component</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let food of ViewDialogeData?.lunch_food">
                                                            <td>{{ food?.regular_meal_lunch == 1 ? 'Yes' : '-' }}</td>
                                                            <td>{{ food?.regular_meal_lunch_time ? (food?.regular_meal_lunch_time | date: 'h:mm:ss') : '-' }}
                                                            </td>
                                                            <td>{{ food?.regular_meal_lunch_actual_time ? (food?.regular_meal_lunch_actual_time | date: 'h:mm:ss') : '-' }}
                                                            </td>
                                                            <td>{{ food?.regular_meal_lunch_food_components ? food?.regular_meal_lunch_food_components : '-' }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                                <!-- Dinner -->
                                                <div class="dataBydate">
                                                <table class="table font-size-13">
                                                    <thead>
                                                        <tr>
                                                            <th>Dinner</th>
                                                            <th>Reference Time</th>
                                                            <th>Actual Time</th>
                                                            <th>Food Component</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let food of ViewDialogeData?.dinner_food">
                                                            <td>{{ food?.regular_meal_dinner == 1 ? 'Yes' : '-' }}</td>
                                                            <td>{{ food?.regular_meal_dinner_time ? (food?.regular_meal_dinner_time | date: 'h:mm:ss') : '-' }}
                                                            </td>
                                                            <td>{{ food?.regular_meal_dinner_actual_time ? (food?.regular_meal_dinner_actual_time | date: 'h:mm:ss') : '-' }}
                                                            </td>
                                                            <td>{{ food?.regular_meal_dinner_food_components ? food?.regular_meal_dinner_food_components : '-' }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            </div>
                                        </div>
                                    </p-accordionTab>
                                </div>

                                <!-- DAILY CARE END -->
                                <!-- <p-accordionTab header="Diaper Change">
                                    <div class="col-xl-4 col-lg-12
                                    col-md-12">
                                        <div class="form-group">
                                            <label>Frequency</label>
                                            <div type="text" readonly>{{ ViewDialogeData?.diaper_change_frequency ?? '-'
                                    }}</div>
                                        </div>
                                    </div>
                                    <div class="row" *ngFor="let change of ViewDialogeData?.diaper_changes">
                                        <div class="col-xl-4 col-lg-12
                col-md-12">
                                            <div class="form-group">
                                                <label for="">Diaper Check</label>
                                                <div type="text" readonly>
                                                    {{ change?.isDiaperChanged ? 'Yes' : 'No' }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-12
            col-md-12">
                                            <div class="form-group">
                                                <label for="">Change Time </label>
                                                <div type="text" readonly>
                                                    {{formatDateTime(change?.diaper_label)}}
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="row" *ngFor="let change of ViewDialogeData?.diaper_change_times">
                                        <div class="col-xl-4 col-lg-12
                col-md-12">
                                            <div class="form-group">
                                                <label for="">Diaper Change</label>
                                                <div type="text" readonly>
                                                    {{ change?.change ? 'Yes' : 'No' }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-12
            col-md-12">
                                            <div class="form-group">
                                                <label for="">Change Time </label>
                                                <div type="text" readonly>
                                                    {{formatDateTime(change?.time)}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-12
                col-md-12">
                                            <div class="form-group">
                                                <label>Remark</label>
                                                <div type="text" readonly>{{ change?.diaper_remark ?? '-'
                }}</div>
                                            </div>
                                        </div>
                                    </div>

                                </p-accordionTab> -->

                                <!-- Food/Medicine Allergy -->
                                <p-accordionTab header="Food/Medicine Allergy"
                                    [class.allergyhighlight]="ViewDialogeData?.food_medicine_allergy == '1'">
                                    <div class="row peach-bg">
                                        <div class="my-3">
                                            <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                            <!-- <h3>Food/Medicine Allergy</h3> -->
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
            col-md-12">
                                                    <!-- <div class="form-group">
                <label for="">Food/Medicine Allergy</label>
                <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.food_medicine_allergy== 1? 'Yes': '-' ?? '-'
                }}" />
            </div> -->
                                                    <ng-container>
                                                        <div class="form-group">
                                                            <label for="">Food/Medicine Allergy</label>
                                                            <div type="text" readonly *ngIf="ViewDialogeData?.food_medicine_allergy !==
                                                null && ViewDialogeData?.food_medicine_allergy
                                                !== '' && ViewDialogeData?.food_medicine_allergy
                                                !== undefined; else elseBlock">

                                                                {{ ViewDialogeData?.food_medicine_allergy == '1'? 'Yes': 'No'}}
                                                            </div>
                                                            <ng-template #elseBlock>
                                                                <div type="text" readonly>
                                                                    {{"-"}}
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                    </ng-container>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Food Component</label>
                                                        <div type="text" readonly>
                                                            {{ViewDialogeData?.food_medicine_allergy_specify_prod??'-' }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Food/Medicine Allergy End-->

                                <!-- Bathing -->
                                <p-accordionTab header="Bathing">
                                    <div class="add-listings-box py-0 peach-bg">
                                        <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                        <h3>Bathing</h3>
                                        <!-- Tub -->
                                        <div class="row">
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <div class="form-group">
                                                    <label for="">Tub</label>
                                                    <div type="text" readonly>{{ ViewDialogeData?.bathing_tub == 1? 'Yes': '-'
                                            }}</div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <div class="form-group">
                                                    <label for="">Frequency</label>
                                                    <div type="text" readonly>
                                                        {{ViewDialogeData?.bathing_tub_frequency?.frequency??'-' }}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="dataBydate">
                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>


                                                    <th>Reference Time</th>
                                                    <th>Actual Time</th>
                                                    <th>Done or Not</th>
                                                    <th>Remark</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let time of ViewDialogeData?.tub_bathing">
                                                    <td>{{ formatDateTime(time?.bathing_tub_time) }}</td>
                                                    <td>{{ formatDateTime(time?.bathing_tub_actual_time) }}</td>
                                                    <td>{{ time?.tub_checkbox == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ time?.tubBathing_remark ?? '-' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                        <!-- <div class="col-xl-4 col-lg-12
            col-md-12" *ngIf="ViewDialogeData?.bathing_tub_time !== '00:00:00'">
            <div class="form-group">
                <label>Time</label>
                <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.bathing_tub_time  ??'-'
                }}"  />
            </div>
        </div> -->




                                        <!-- Shower -->
                                        
                                        <div class="row">
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <div class="form-group">
                                                    <label for="">Shower</label>
                                                    <div type="text" readonly>{{ ViewDialogeData?.bathing_shower == 1? 'Yes': '-'
                                            }}</div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <div class="form-group">
                                                    <label for="">Frequency</label>
                                                    <div type="text" readonly>
                                                        {{ViewDialogeData?.bathing_shower_frequency?.frequency??' -' }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dataBydate">
                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>


                                                    <th>Reference Time</th>
                                                    <th>Actual Time</th>
                                                    <th>Done or Not</th>
                                                    <th>Remark</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let time of ViewDialogeData?.tub_bathing">
                                                    <td>{{ formatDateTime(time?.bathing_shower_time) }}</td>
                                                    <td>{{ formatDateTime(time?.bathing_shower_actual_time) }}</td>
                                                    <td>{{ time?.shower_checkbox == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ time?.shower_remark ?? '-' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                        <!-- <div class="col-xl-4 col-lg-12
            col-md-12" *ngIf="ViewDialogeData?.bathing_shower_time !== '00:00:00'">
            <div class="form-group">
                <label>Time</label>
                <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.bathing_shower_time  ??'-'
            }}"  />
            </div>
        </div> -->



                                    </div>
                                </p-accordionTab>
                                <!-- Bathing End -->

                                <!-- potty training -->
                                <p-accordionTab header="Potty Training">
                                    <div class="add-listings-box py-0 peach-bg">
                                        <h3>Potty Training</h3>
                                        <div class="row" *ngFor="let train of ViewDialogeData?.child_training">
                                            <div class="col-xl-4 col-lg-12
        col-md-12">
                                                <div class="form-group">
                                                    <label for="">Potty Training</label>
                                                    <div type="text" readonly>{{ train?.potty_training== 1? 'Yes': '-' ?? '-'
            }}</div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
                col-md-12">
                                                <div class="form-group">
                                                    <label>Special Instructions</label>
                                                    <div type="text" readonly>{{ train?.training_child ?? '-'
                }}</div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="dataBydate">
                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>

                                                    <th>Changing Time</th>
                                                    <th>Actual Time</th>
                                                    <th>Done Or Not</th>
                                                    <th>Remarks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let train of ViewDialogeData?.child_training">
                                                    <td>{{ formatDateTime(train?.time_training_child) }}</td>
                                                    <td>{{ formatDateTime(train?.time_training_actual_child) }}</td>
                                                    <td>{{ train?.done_training_child == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ train?.training_child_remark ?? '-' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                        <!-- <div class="row" *ngFor="let train of ViewDialogeData?.child_training">
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <div class="form-group">
                                                    <label>Done or not</label>
                                                    <div type="text" readonly> {{ train?.done_training_child== 1? 'Yes': '-' ?? '-'
                                                }} </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <div class="form-group">
                                                    <label for="">Time </label>
                                                    <div type="text" readonly>
                                                        {{formatDateTime(train?.time_training_child)}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                <div class="form-group">
                                                    <label for="">Actual Time </label>
                                                    <div type="text" readonly>
                                                        {{formatDateTime(train?.time_training_actual_child)}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
                col-md-12">
                                                <div class="form-group">
                                                    <label>Remark</label>
                                                    <div type="text" readonly>{{ train?.training_child_remark ?? '-'
                }}</div>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </p-accordionTab>
                                <!-- potty training End-->

                                <!-- Skin Cares -->
                                <p-accordionTab header="Skin Cares">
                                    <div class="row peach-bg">
                                        <div class="my-3">
                                            <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                            <!-- <h3>Skin Cares</h3> -->
                                            <div class="row" *ngFor="let care of ViewDialogeData?.skin_care">
                                                <!-- Lotion -->
                                                <div class="col-xl-4 col-lg-12
            col-md-12">
                                                    <div class="form-group">
                                                        <label>Lotion</label>
                                                        <div type="text" readonly>{{ care?.skin_care_lotion == 1? 'Yes': '-'
                                            }}</div>
                                                    </div>
                                                </div>

                                                <!-- Baby oil -->
                                                <div class="col-xl-4 col-lg-12
            col-md-12">
                                                    <div class="form-group">
                                                        <label>Baby Oil</label>
                                                        <div type="text" readonly>{{ care?.skin_care_baby_oil == 1? 'Yes': '-'
                                            }}</div>
                                                    </div>
                                                </div>

                                                <!-- Others -->
                                                <div class="col-xl-4 col-lg-12
            col-md-12">
                                                    <div class="form-group">
                                                        <label>Others</label>
                                                        <div type="text" readonly>{{ care?.skin_care_others == 1? 'Yes': '-'
                                            }}</div>
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                col-md-12">
                                                    <div class="form-group">
                                                        <label>Specify Product</label>
                                                        <div type="text" readonly>{{ care?.skin_care_specify_produ ?care?. skin_care_specify_produ:'-'
                                            }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Skin Cares End-->

                                <!-- Wipes -->
                                <p-accordionTab header="Wipes">
                                    <div class="row peach-bg">
                                        <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                        <!-- <h3>Wipes</h3> -->
                                        <div class="my-3">
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
            col-md-12">
                                                    <!-- <div class="form-group">
                <label>Wipes</label>
                <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.skin_care_Wipes== 1? 'Yes': '-' ?? '-'
                }}" />
            </div> -->
                                                    <ng-container>
                                                        <div class="form-group">
                                                            <label for="">Wipes</label>
                                                            <div type="text" readonly>

                                                                {{ ViewDialogeData?.skin_care_Wipes == '1'? 'Yes': 'No'}}
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                col-md-12">
                                                    <div class="form-group">
                                                        <label>Special Instruction</label>
                                                        <div type="text" readonly>{{ ViewDialogeData?.skin_care_special_instructions ?? '-'
                                            }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Wipes End-->

                                <!-- Changing Clothes -->
                                <!-- <h3>Changing Clothes</h3>
    <div class="row">
        <div class="col-xl-4 col-lg-12
            col-md-12">
            <div class="form-group">
                <label>Frequency</label>
                <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.changing_colthes_frequency ?? '-'
                }}" />
            </div>
        </div> -->
                                <p-accordionTab header="Changing Clothes">
                                    <div class="add-listings-box py-0 peach-bg">
                                        <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                        <h3>Changing Clothes</h3>
                                        <div class="row">


                                            <div class="col-xl-4 col-lg-12 col-md-12">
                                                <div class="form-group">
                                                    <label>Special Instructions</label>
                                                    <div type="text" readonly>
                                                        {{ ViewDialogeData?.changing_colthes_spiecal_instructions ?? '-'}}
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="dataBydate">
                                            <table class="table font-size-13">
                                                <thead>
                                                    <tr>
                                                        <th>Changing Clothes</th>
                                                        <th>Changing Time</th>
                                                        <th>Actual Time</th>
                                                        <th>Remarks</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let change of ViewDialogeData?.changing_clothes">
                                                        <td>{{ change?.changing_colthes == 1 ? 'Yes' : '-' }}</td>
                                                        <td>{{ formatDateTime(change?.Changing_time) }}</td>
                                                        <td>{{ formatDateTime(change?.Changing_actual_time) }}</td>
                                                        <td>{{ change?.clothe_remark ?? '-' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Changing Clothes End-->


                                <!-- Sleeping Independently -->
                                <p-accordionTab header="Sleeping Independently">


                                    <div class="add-listings-box py-0 peach-bg">
                                        <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                        <h3>Sleeping</h3>
                                        <div class="row">
                                            <div class="col-xl-4 col-lg-12
                col-md-12">
                                                <!-- <div class="form-group">
                    <label>Sleeping Independently</label>
                    <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.sleeping_independently== 1? 'Yes': '-' ?? '-'
                    }}" />
                </div> -->
                                                <ng-container>
                                                    <div class="form-group">
                                                        <label for="">Sleeping Independently</label>
                                                        <div type="text" readonly
                                                            *ngIf="ViewDialogeData?.sleeping_independently !== null && ViewDialogeData?.sleeping_independently !== ''  && ViewDialogeData?.sleeping_independently !== undefined; else elseBlock">
                                                            {{ ViewDialogeData?.sleeping_independently == '1'? 'Yes': 'No'}}
                                                        </div>
                                                        <ng-template #elseBlock>
                                                            <div type="text" readonly>
                                                                {{"-" }}
                                                            </div>
                                                        </ng-template>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="dataBydate">
                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>
                                                    <th>With Mother</th>
                                                    <th>Reference Time</th>
                                                    <th>Actual Time</th>
                                                    <th>Remarks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let sleeping of ViewDialogeData?.sleeping_time">
                                                    <td>{{ sleeping?.sleeping_mother == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ formatDateTime(sleeping?.sleeping_mother_time) }}</td>
                                                    <td>{{ formatDateTime(sleeping?.sleeping_mother_actual_time) }}</td>
                                                    <td>{{ sleeping?.sleeping_mother_remark ?? '-' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="dataBydate">
                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>
                                                    <th>With Nurse</th>
                                                    <th>Reference Time</th>
                                                    <th>Actual Time</th>
                                                    <th>Remarks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let sleeping of ViewDialogeData?.nanny_time">
                                                    <td>{{ sleeping?.sleeping_nanny == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ formatDateTime(sleeping?.sleeping_nanny_time) }}</td>
                                                    <td>{{ formatDateTime(sleeping?.sleeping_nanny_actual_time) }}</td>
                                                    <td>{{ sleeping?.sleeping_nanny_remark ?? '-' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Sleeping Independently End -->

                                <!-- Temperature Monitoring -->
                                <p-accordionTab header="Vitamins/Medications">
                                    <div class="add-listings-box py-0 peach-bg">
                                        <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->

                                        <!-- Temperature Monitoring End-->

                                        <!-- Vitamins/Medications -->
                                        <h3>Vitamins/Medications</h3>
                                        <div class="row"
                                            *ngFor="let vitamin of ViewDialogeData?.vitamins_medications_json ">
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <div class="form-group">
                                                    <label>Medications</label>
                                                    <div type="text" readonly>{{ vitamin?.vitamin ? vitamin?.vitamin :  '-'
                }}</div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <div class="form-group">
                                                    <label>Frequency</label>
                                                    <div type="text" readonly>{{ vitamin?.frequency ? vitamin?.frequency :'-'
                }}</div>
                                                </div>
                                            </div>
                                            <table class="table font-size-13">
                                                <thead>
                                                    <tr>

                                                        <th>Time</th>
                                                        <th>Actual Time</th>
                                                        <th>Done or not</th>
                                                        <th>Remarks</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let times of vitamin.medicine_time">
                                                        <td> {{ formatDateTime(times?.time) }}
                                                        </td>
                                                        <td> {{ formatDateTime(times?.actual_time) }}
                                                        </td>
                                                        <td>{{ times?.vitamin_checkbox == 1 ? 'Yes' : '-' }}</td>
                                                        <td>{{ times?.vitamin_remark ? times?.vitamin_remark : '-' }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <!-- Vitamins/Medications End-->

                                        <!-- Vaccinations -->

                                    </div>
                                </p-accordionTab>
                                <!-- Vaccinations End-->

                                <!-- Playing with child outside? -->
                                <p-accordionTab header="Activities">
                                    <div class="add-listings-box py-0 peach-bg">
                                        <h3>Activities</h3>
                                        <div class="row" *ngFor="let child of  ViewDialogeData?.outside_child
                                ">
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <!-- <div class="form-group">
                <label for="">Playing with child outside?</label>
                <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.play_child_outside== 1? 'Yes': '-' ?? '-'
                }}" />
            </div> -->
                                                <ng-container>
                                                    <div class="form-group">
                                                        <label for="">Playing with child outside</label>
                                                        <div type="text" readonly
                                                            *ngIf="child?.play_child_outside !== null && child?.play_child_outside !== ''  && child?.play_child_outside !== undefined; else elseBlock">
                                                            {{ child?.play_child_outside == '1'? 'Yes': 'No'}}
                                                        </div>
                                                        <ng-template #elseBlock>
                                                            <div type="text" readonly>
                                                                {{"-"}}
                                                            </div>
                                                        </ng-template>
                                                    </div>
                                                </ng-container>
                                            </div>

                                            <div class="col-xl-4 col-lg-12
                col-md-12">
                                                <div class="form-group">
                                                    <label for="">Special Instructions</label>
                                                    <div type="text" readonly>{{ child?.play_child_outside_special_Instructions ?child?.play_child_outside_special_Instructions : '-'
                                        }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dataBydate">
                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>

                                                    <th>Reference Time</th>
                                                    <th>Actual Time</th>
                                                    <th>Done Or Not</th>
                                                    <th>Remarks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let child of ViewDialogeData?.outside_child">
                                                    <td>{{ formatDateTime(child?.play_child_outside_time) }}</td>
                                                    <td>{{ formatDateTime(child?.play_child_outside_actual_time) }}</td>
                                                    <td>{{ child?.play_child_outside_done == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ child?.play_child_outside_remark ?? '-' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                        <!-- <div class="row" *ngFor="let child of  ViewDialogeData?.outside_child
                                            ">
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <div class="form-group">
                                                    <label for="">Done or Not</label>
                                                    <div type="text" readonly>{{ child?.play_child_outside_done == 1? 'Yes': '-'
                                            }}</div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
    col-md-12">
                                                <div class="form-group">
                                                    <label for="">Time</label>
                                                    <div type="text" readonly>
                                                        {{formatDateTime(child?.play_child_outside_time)}}
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <div class="form-group">
                                                    <label for="">Remark</label>
                                                    <div type="text" readonly>
                                                        {{child?.play_child_outside_remark??'-' }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- Playing with child outside? End-->

                                        <!-- Escort child outside? -->

                                        <div class="row" *ngFor="let child of ViewDialogeData?.escort_child">
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <ng-container>
                                                    <div class="form-group">
                                                        <label for="">Escort child outside</label>
                                                        <div type="text" readonly
                                                            *ngIf="child?.escort_child_outside !== null && child?.escort_child_outside !== ''  && child?.escort_child_outside !== undefined; else elseBlock">

                                                            {{ child?.escort_child_outside == '1'? 'Yes': 'No'}}
                                                        </div>
                                                        <ng-template #elseBlock>
                                                            <div type="text" readonly>
                                                                {{"-"}}
                                                            </div>
                                                        </ng-template>
                                                    </div>
                                                </ng-container>
                                            </div>

                                            <div class="col-xl-4 col-lg-12
                col-md-12">
                                                <div class="form-group">
                                                    <label for="">Special Instructions</label>
                                                    <div type="text" readonly>{{ child?.escort_child_outside_special_Instructions ?child?.escort_child_outside_special_Instructions : '-'
                                        }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dataBydate">
                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>

                                                    <th>Reference Time</th>
                                                    <th>Actual Time</th>
                                                    <th>Done Or Not</th>
                                                    <th>Remarks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let child of ViewDialogeData?.escort_child">
                                                    <td>{{ formatDateTime(child?.escort_child_outside_time) }}</td>
                                                    <td>{{ formatDateTime(child?.escort_child_outside_actual_time) }}
                                                    </td>
                                                    <td>{{ child?.escort_child_outside_done == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ child?.escort_child_outside_remark ?? '-' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                        <!-- <div class="row" *ngFor="let child of ViewDialogeData?.escort_child">
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <div class="form-group">
                                                    <label for="">Done or Not</label>
                                                    <div type="text" readonly>{{ child?.escort_child_outside_done == 1? 'Yes': '-'
                                            }}</div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
    col-md-12">
                                                <div class="form-group">
                                                    <label for="">Time</label>
                                                    <div type="text" readonly>
                                                        {{formatDateTime(child?.escort_child_outside_time)}}
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <div class="form-group">
                                                    <label for="">Remark</label>
                                                    <div type="text" readonly>
                                                        {{child?.escort_child_outside_remark??'-' }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- Escort child outside? End-->
                                        <div class="row" *ngFor="let child of ViewDialogeData?.activities_name">
                                            <div class="col-xl-4 col-lg-12
                                    col-md-12">


                                                <ng-container>
                                                    <div class="form-group">
                                                        <label for="">Activity</label>
                                                        <!-- <input type="text" readonly class="form-control"
                                            *ngIf="child?.other_activity !== null && child?.other_activity !== ''  && child?.other_activity !== undefined; else elseBlock"
                                            value="{{ child?.other_activity == '1'? 'Yes': 'No'}}" /> -->

                                                        <div type="text" readonly
                                                            *ngIf="child?.other_activity !== null && child?.other_activity !== ''  && child?.other_activity !== undefined; else elseBlock">
                                                            {{ child?.other_activity == '1'? 'Yes': 'No'}}
                                                        </div>
                                                        <ng-template #elseBlock>
                                                            <!-- <input type="text" readonly class="form-control" -->
                                                            <!-- value="-" /> -->
                                                            <div>-</div>
                                                        </ng-template>
                                                    </div>
                                                </ng-container>
                                            </div>

                                            <div class="col-xl-4 col-lg-12
                                       col-md-12">
                                                <div class="form-group">
                                                    <label for="">Special Instructions</label>
                                                    <div type="text" readonly>{{
                                                    child?.activities_child_outside_special_Instructions?child?.activities_child_outside_special_Instructions:
                                                    '-'
                                                    }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="dataBydate">
                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>

                                                    <th>Reference Time</th>
                                                    <th>Actual Time</th>
                                                    <th>Done Or Not</th>
                                                    <th>Remarks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let child of ViewDialogeData?.activities_name">
                                                    <td>{{ formatDateTime(child?.other_child_outside_time) }}</td>
                                                    <td>{{ formatDateTime(child?.other_child_outside_actual_time) }}
                                                    </td>
                                                    <td>{{ child?.other_child_outside_done == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ child?.other_child_outside_remark ?? '-' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                        <!-- <div class="row" *ngFor="let child of ViewDialogeData?.activities_name">
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <div class="form-group">
                                                    <label for="">Done</label>
                                                    <div type="text" readonly>{{ child?.other_child_outside_done == 1? 'Yes': '-'
                                            }}</div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
    col-md-12">
                                                <div class="form-group">
                                                    <label for="">Time</label>
                                                    <div type="text" readonly>
                                                        {{formatDateTime(child?.other_child_outside_time)}}
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <div class="form-group">
                                                    <label for="">Remark</label>
                                                    <div type="text" readonly>
                                                        {{child?.other_child_outside_remark??'-' }}
                                                    </div>
                                                </div>
                                            </div>

                                        </div> -->
                                        <!-- Escort child to school? -->
                                        <div class="row" *ngFor="let child of ViewDialogeData?.school_name">
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <!-- <div class="form-group">
                <label for="">Escort child to school?</label>
                <input type="text" readonly class="form-control" value="{{ ViewDialogeData?.escort_child_school== 1? 'Yes': '-' ?? '-'
                }}" />
            </div> -->
                                                <ng-container>
                                                    <div class="form-group">
                                                        <label for="">Escort child to school</label>
                                                        <div type="text" readonly
                                                            *ngIf="child?.escort_child_school !== null && child?.escort_child_school !== ''  && child?.escort_child_school !== undefined; else elseBlock">

                                                            {{ child?.escort_child_school == '1'? 'Yes': 'No'}}
                                                        </div>
                                                        <ng-template #elseBlock>
                                                            <div type="text" readonly>
                                                                {{"-"}}
                                                            </div>
                                                        </ng-template>
                                                    </div>
                                                </ng-container>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
                col-md-12">
                                                <div class="form-group">
                                                    <label for="">Special Instructions</label>
                                                    <div type="text" readonly>{{ child?.escort_child_school_special_instructions ?child?.escort_child_school_special_instructions : '-'
                }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="dataBydate">
                                            <table class="table font-size-13">
                                                <thead>
                                                    <tr>

                                                        <th>Reference Time</th>
                                                        <th>Actual Time</th>
                                                        <th>Done Or Not</th>
                                                        <th>Remarks</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let child of ViewDialogeData?.school_name">
                                                        <td>{{ formatDateTime(child?.school_child_outside_time) }}</td>
                                                        <td>{{ formatDateTime(child?.school_child_outside_actual_time) }}
                                                        </td>
                                                        <td>{{ child?.school_child_outside_done == 1 ? 'Yes' : '-' }}
                                                        </td>
                                                        <td>{{ child?.school_child_outside_remark ?? '-' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                            <!-- <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <div class="form-group">
                                                    <label for="">Done</label>
                                                    <div type="text" readonly>{{ child?.school_child_outside_done == 1? 'Yes': '-'
                                            }}</div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
    col-md-12">
                                                <div class="form-group">
                                                    <label for="">Time</label>
                                                    <div type="text" readonly>
                                                        {{formatDateTime(child?.school_child_outside_time)}}
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-12
            col-md-12">
                                                <div class="form-group">
                                                    <label for="">Remark</label>
                                                    <div type="text" readonly>
                                                        {{child?.school_child_outside_remark??'-' }}
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </p-accordionTab>
                                <!-- Escort child to school? End-->

                                <!-- Remark -->
                                <p-accordionTab header="Remark">
                                    <div class="row peach-bg">
                                        <div class="my-3">
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
                col-md-12">
                                                    <div class="form-group">

                                                        <div type="text" readonly>{{ ViewDialogeData?.remarks ?? '-'
                    }}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">

                                                <label for="">Nurse Remarks</label>
                                                <div class="col-md-3 form-group"
                                                    *ngFor="let change of ViewDialogeData?.nurse_remarks let index = index">
                                                    <div class="input-group2 form-group ">
                                                        <span> {{ index+1 }}. </span>
                                                        <div type="formbox" readonly>
                                                            {{ change?.nurse_remark ?? '-' }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </p-accordionTab>
                            </p-accordion>
                            <!-- Remark End-->
                        </form>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template pTemplate="footer" let-list let-i="rowIndex">
            <div class="row ">
                <div class="col-md">
                    <table class="table-stamp w-100 text-start">
                        <tr class="row">
                            <td class="col-sm-6 col-lg-3" ><b>Created By:</b> {{ createdby}}</td>
                            <td class="col-sm-6 col-lg-3" ><b>Created Date&Time:</b>{{ viewtime.substring(0, 16).replace('T', ' ') }}
                            </td>
                            <td class="col-sm-6 col-lg-3" ><b>Updated By:</b> {{ updatedby??'-'}}</td>
                            <td class="col-sm-6 col-lg-3">
                                <b>Updated Date&Time:</b>
                                 <span *ngIf="updatedtime; else noData">{{ updatedtime.substring(0, 16).replace('T', ' ') }}</span>
                                <ng-template #noData> -</ng-template>
                              </td>
                        </tr>
                    </table>
                </div>
                <div class="col-md-auto"> <p-button (click)="showThreeYearToAbove=false;softReload();" label="Ok"
                        styleClass="p-button-text"></p-button> </div>
            </div>


        </ng-template>



    </p-dialog>

</div>
<app-copyrights></app-copyrights>
</div>