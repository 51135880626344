import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { MessageService, ConfirmationService } from 'primeng/api';
import { MasterService } from 'src/app/services/master.service';
import { TableService } from 'primeng/table';
@Component({
  selector: 'app-urine-master',
  templateUrl: './urine-master.component.html',
  styleUrls: ['./urine-master.component.scss'],
  providers: [MessageService, ConfirmationService, TableService],
})
export class UrineMasterComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Urine',
      subTitle: 'Dashboard'
    }
  ]
  filterval: any;
  editUrine: any;
  loading: any;
  member: any;
  tooltipText: string = 'Nanny Care Plan';
  urineFormData: any = {};
  urineData: any = []
  nannyPlans: FormGroup;
  constructor(
    private masterService: MasterService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit() {
    this.getAllData();
  }

  formSubmit(form: NgForm) {
    console.log(form.valid, form.value);
    if (form.valid) {
      this.masterService.postUrineFormData(form.value).then((res: any) => {
        this.messageService.add({
          severity: 'success',
          detail: res?.message,
        });
        form.resetForm();
        this.urineFormData = {}
        this.getAllData();
      }).catch((error: HttpErrorResponse) => {
        console.log(error);
      })
    }
  }

  getAllData() {
    this.masterService.getUrineValues().then((res: any) => {
     
      this.urineData = res.data;
    })
  }
  /********************************************************************
                Edit Care Plan
********************************************************************/

  editStoolMaster(event: any) {
    this.editUrine[event.id] = { ...event };
    console.log('Edit', this.editUrine);
  }

  onRowEditSave(urine: any) {
    console.log('onRowEditSave', urine);
    this.loading = true;
    if (urine.name_description != '' && urine.name_description != null) {
      this.masterService
        .editUrineFormData(urine, urine.id)
        .subscribe((data: any) => {
          this.messageService.add({
            severity: 'success',
            detail: data?.message,
          });
          this.loading = false;
          this.getAllData()
        });
    } else {
      this.messageService.add({
        severity: 'error',
        detail: 'Please Fill All the Details!',
      });
      this.loading = false;
    }
  }

  /********************************************************************
                Delete Care Plan
  ********************************************************************/

  deleteMaster(urine: any) {
    console.log("delete master", urine)
    this.confirmationService.confirm({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.masterService
          .deleteUrineFormData(urine.id, { is_deleted: 1 })
          .subscribe((data: any) => {
            this.getAllData();
            this.messageService.add({
              severity: 'success',
              detail: data?.message,
            });
          });
      },
    });
  }
  reset(dt1:any) {
    dt1.reset();
    this.filterval = '';
  }
}
