import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class PtServiceService {

  apiBaseUrl: string = environment.api_base_url;
  constructor(private http: HttpClient) { }
  /* -------------------------------------------------------------------------- */
  /*               // For all data of PT daily notes            */
  /* -------------------------------------------------------------------------- */
  dailyNotesData(data: any): Promise<any>  {
    return this.http.post(this.apiBaseUrl + 'daily-notes', data).toPromise();
  }
  ListingdailyNotes(){
    return this.http.get(this.apiBaseUrl + 'daily-notes');
  }
  /* -------------------------------------------------------------------------- */
  /*               // For PT Initial Assessment Adult            */
  /* -------------------------------------------------------------------------- */
  ptAdultAssessment(data: any): Promise<any> {
    return this.http.post(this.apiBaseUrl + 'adult-assessment', data).toPromise();
  }
  ptadultassessment(){
    return this.http.get(this.apiBaseUrl + 'adult-assessment');
  }
  /* -------------------------------------------------------------------------- */
  /*               // For PT Initial Assessment Pedia            */
  /* -------------------------------------------------------------------------- */
  ptPediaAssessment(data: any): Promise<any> {
    return this.http.post(this.apiBaseUrl + 'pedia-assessment', data).toPromise();
  }
  ptpediaassessment(){
    return this.http.get(this.apiBaseUrl + 'pedia-assessment');
  }
  /* -------------------------------------------------------------------------- */
  /*               // For PT Scope SessionGet API            */
  /* -------------------------------------------------------------------------- */
  ptGetScopeSession(): Promise<any> {
    return this.http.get(this.apiBaseUrl + 'scope-of-session').toPromise();
  }
  /* -------------------------------------------------------------------------- */
  /*               // For PT Initial Assessment Form API            */
  /* -------------------------------------------------------------------------- */
  ptInitialAssessment(formData: any): Promise<any> {
    return this.http.post(this.apiBaseUrl + 'physio-assesment', formData).toPromise();
  }
  ptListingInitialAssessment(){
    return this.http.get(this.apiBaseUrl + 'physio-assesment');
  }

}
