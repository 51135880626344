import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { MaikaListService } from 'src/app/services/maika-list.service';

@Component({
  selector: 'app-daily-notes-list',
  templateUrl: './daily-notes-list.component.html',
  styleUrls: ['./daily-notes-list.component.scss']
})

export class DailyNotesListComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Daily Notes',
      subTitle: 'Dashboard'
    }
  ]
  dailyNotesData: any = []
  loading: any;
  tooltipText: string = 'Daily Notes';
  parsedData: any = [];
  datalength: any;
  constructor(private daily_notes_service: MaikaListService,
    private messageService: MessageService,
    private spinner: NgxSpinnerService,
    ) { }

  // fn to get the data from backend
  getAllData() {
     this.spinner.show();
    this.daily_notes_service.getDailyNotesList().subscribe((res: any) => {
      this.spinner.hide()
     
      this.dailyNotesData = res.data;
      this.datalength = res?.data?.length;
      console.log("daily notes data", res.data);
      this.dailyNotesData.map((ele: any) => {
        // parsing data
        this.parsedData.push(JSON.parse(ele.daily_notes_json))

        console.log(this.parsedData)
      })
    },(error: any) => {
      this.spinner.hide()
      // Handle any errors that occurred during the API call
    
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error?.message,
      });
    })
  }

  ngOnInit(): void {
    this.getAllData();
  }

}
