<p-toast></p-toast>

<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">
  <app-dashboard-navbar></app-dashboard-navbar>

  <div class="dropdown profile-nav-item d-flex flex-column align-items-end">
    <a class="dropdown-toggle admin-btn" style="text-decoration: none;" role="button" data-bs-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
      <div class="menu-profile">
        <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
        <span class="name">{{getname}}</span>
      </div>
    </a>
    <div class="dropdown-menu d-flex flex-column align-items-center">
      <div class="dropdown-header align">
        <div class="figure mb-3">
          <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
        </div>
        <div class="info text-center">
          <span class="name" style="white-space: break-spaces;">{{getname}}</span>
          <p class="mb-3 email">{{getRole}}</p>
        </div>
      </div>
      <!-- <div class="dropdown-body">
            <ul class="profile-nav p-0 pt-3">
                <li class="nav-item"><a routerLink="/dashboard-my-profile" class="nav-link"><i class='bx bx-user'></i> <span>Profile Dashboard</span></a></li>
            </ul>
        </div> -->
      <div class="dropdown-footer">
        <ul class="profile-nav">
          <li class="nav-item"><a class="nav-link" (click)="logout()"><i class='bx bx-log-out'></i> <span
                  class="logout-text">Logout</span></a></li>
        </ul>
      </div>

    </div>
  </div>
  <!-- Breadcrumb Area -->
  <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
    <h1>{{ Content.title }}</h1>
    <ol class="breadcrumb">
      <li class="item"><a routerLink="/">Home</a></li>
      <li class="item">
        {{ Content.subtitle }}
      </li>
      <li class="item">{{ Content.title }}</li>
    </ol>
  </div>
  <!-- End Breadcrumb Area -->

  <!-- Start Form -->
  <form [formGroup]="zeroToThreeMonthsForm" (ngSubmit)="saveBabyPlanForm(formSubmitted)" #formSubmitted="ngForm">
    <!-- BABY DETAILS SECTION -->

    <!-- new fields creation fielding Assesment -->
    <ng-container *ngIf="flag">
      <p-accordion>

        <!-- BABY DETAILS SECTION END-->
        <!-- DAILY CARE SECTION-->

        <p-accordionTab header="Milk Feeding">
          <div class="add-listings-box pb-0">

            <h3>Daily Care</h3>

            <div class="row">
              <div class="form-group mb-0">
                <label class="subHeading px-0">Breast Feeding </label>
                <div class=" ">
                  <div class="form-check checkbox-list p-0">
                    <div class="row">

                      <div class=" col-md">
                        <div *ngIf="isBreast">
                          <div class="row" formArrayName="breast_feeding">
                            <div *ngFor="let control of getbreastarray().controls; let i = index" [formGroupName]="i"
                                class="col-6 col-md-4 col-lg-12">
                              <div class="row">
                                <div class="col-md-2">
                                  <div class="form-group">
                                    <div class="col-md-2 col-lg-2 mt-3">
                                      <label class="checkbox checkbox-outline px-0  mt-md-2">
                                        <input type="checkbox" value="breastFeeding" name="checkboxes"
                                            formControlName="feeding_breast_checkbox" />
                                        Done?
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-3">
                                  <div class="form-group">
                                    <label class="p-0">Reference Time: </label>
                                    <div class="card flex justify-content-center">
                                      <p-calendar placeholder="Start Time" formControlName="feeding_breast_time"
                                          [timeOnly]="true" [hourFormat]="12" inputId="time" [disabled]="true"
                                          [class.is-invalid]="(formSubmitted.submitted &&
                                          zeroToThreeMonthsForm.get('feeding_breast_time')?.invalid)
                                          ||
                                            zeroToThreeMonthsForm.get('feeding_breast_time')?.invalid
                                          &&
                                            zeroToThreeMonthsForm.get('feeding_breast_time').touched">
                                      </p-calendar>
                                      <div class="invalid-feedback">Start Time is required</div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-3">
                                  <div class="form-group">
                                    <label class="p-0">Actual Time: </label>
                                    <div class="card flex justify-content-center">
                                      <p-calendar placeholder="Start Time" formControlName="feeding_breast_actual_time"
                                          [timeOnly]="true" [hourFormat]="12" inputId="time" [disabled]="feedingBreast"
                                          [class.is-invalid]="(formSubmitted.submitted &&
                                          zeroToThreeMonthsForm.get('feeding_breast_actual_time')?.invalid)
                                          ||
                                            zeroToThreeMonthsForm.get('feeding_breast_actual_time')?.invalid
                                          &&
                                            zeroToThreeMonthsForm.get('feeding_breast_actual_time').touched">
                                      </p-calendar>
                                      <div class="invalid-feedback">Start Time is required</div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <div class="card flex justify-content-center">
                                      <label class="px-0">Remark</label>
                                      <input type="text" name="remark" class="form-control" placeholder="Remark"
                                          id="remark" formControlName="breast_feeding_remark" />
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <label class="subHeading px-0">Formula Feeding </label>

                  </div>
                </div>
              </div>
            </div>
            <!-- formula feeding feeding time  -->
            <div *ngIf="isFormula">
              <div class="row justify-content-end mx-0">
                <div class="add-listings-box pt-3 mb-3 col-lg-12">
                  <div class="row">
                    <!-- Formula Feeding Summary -->
                    <div class="col-xl-2 col-lg-4 col-md-6">
                      <div class="form-group summary2-col">
                        <label for="">Formula</label>
                        <div type="text" readonly>
                          {{ zeroToThreeMonthsForm.value.feeding_type === true ? 'Yes' : '-' }}
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-2 col-lg-4 col-md-6">
                      <div class="form-group summary2-col">
                        <label for="">Frequency</label>
                        <div type="text" readonly>{{ zeroToThreeMonthsForm.value.feeding_frequency ?.frequency??'-' }}
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-2 col-lg-4 col-md-6">
                      <div class="form-group summary2-col">
                        <label for="">Start Time</label>
                        <div type="text" readonly>{{ formatDateTime(zeroToThreeMonthsForm.value.feeding_time) }}</div>
                      </div>
                    </div>

                    <div class="col-xl-2 col-lg-4 col-md-6">
                      <div class="form-group summary2-col">
                        <label for="">End Time</label>
                        <div type="text" readonly>
                          {{ formatDateTime(zeroToThreeMonthsForm.value.feeding_breast_time_end) }}
                        </div>
                      </div>
                    </div>

                    <div class="col-xl-2 col-lg-4 col-md-6">
                      <div class="form-group summary2-col">
                        <label for="">Amount</label>
                        <div type="text" readonly>{{ zeroToThreeMonthsForm.value.feeding_amount_per ?? '-' }}</div>
                      </div>
                    </div>

                    <div class="col-xl-2 col-lg-4 col-md-6">
                      <div class="form-group summary2-col">
                        <label for="">Type of Formula</label>
                        <div type="text" readonly>{{ zeroToThreeMonthsForm.value.type_of_formula ?? '-' }}</div>
                      </div>
                    </div>


                  </div>
                  <div formArrayName="formula_calculation">
                    <div *ngFor="let control of getFormulaCalculationControls().controls; let i = index"
                        [formGroupName]="i" class="col-lg-12">
                      <div class="row">
                        <div class="col-lg-2 col-md-4">
                          <label class="checkbox checkbox-outline px-0  mt-md-2">
                            <input type="checkbox" value="formulaFeeding" name="checkboxes"
                                formControlName="feeding_checkbox" />
                            <span></span>
                            Done?
                          </label>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="px-0">Reference Time</label>
                            <p-calendar placeholder="Time" formControlName="formula_timeArray" [hourFormat]="12"
                                [timeOnly]="true" inputId="time" disabled="true"></p-calendar>
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="px-0">Time Feeding By Nurse</label>
                            <p-calendar placeholder="Actual Time" formControlName="nurse_feeding" [hourFormat]="12"
                                [timeOnly]="true" inputId="time"></p-calendar>
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="px-0">Amount (ml)</label>
                            <input type="text" name="amount" class="form-control" placeholder="Amount"
                                formControlName="amount" />
                          </div>
                        </div>
                        <div class="col-lg-2"></div>
                        <div class="col-lg-3 col-sm-12 col-12">
                          <div class="form-group">
                            <label class="px-0">Is It Same Formula?</label>
                            <div class="form-check checkbox-list p-0">
                              <p-dropdown [options]="FormulaValue" formControlName="is_same_formula" optionLabel="name"
                                  optionValue="value" [filter]="true" filterBy="value" placeholder=""
                                  (ngModelChange)="CheckFormula($event, control)" [resetFilterOnHide]="true">
                              </p-dropdown>
                              <div class="invalid-feedback">Frequency is required</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-12 col-12">
                          <div class="form-group">
                            <label class="px-0">Name of formula</label>
                            <input type="text" name="remark" class="form-control" placeholder="Name of formula"
                                formControlName="remark" disabled />
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-12 col-12">
                          <div class="form-group">
                            <label class="px-0">Remark</label>
                            <input type="text" name="remark" class="form-control" placeholder="Remark" id="remark"
                                formControlName="formula_remark" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Bottle Sterilization Start-->
            <div class="row">
              <label class="subHeading mb-3">Bottle Sterilization </label>
              <!-- Bottle Sterilization End-->

              <!-- Sterilization Time Start-->

              <div >
                <label class="subHeading mb-3">All</label>
                <!-- <div class="form-group">
                  <label class="px-0">All Sterilization Time </label>
                  <div class="form-check checkbox-list p-0">
                    <p-calendar placeholder="Select Sterilization Time" [timeOnly]="true" [hourFormat]="12"
                        inputId="time" formControlName="bottle_sterilization_all_time" [disabled]="sterilizingAll"
                        [class.is-invalid]="(formSubmitted.submitted &&
                      zeroToThreeMonthsForm.get('bottle_sterilization_all_time')?.invalid)
                      ||
                      zeroToThreeMonthsForm.get('bottle_sterilization_all_time')?.invalid
                      &&
                      zeroToThreeMonthsForm.get('bottle_sterilization_all_time').touched">
                    </p-calendar>
                    <div class="invalid-feedback">All Sterilization Time is required</div>
                  </div>
                </div> -->
                <div class="row">
                  <div class="col-lg-2 col-md-2 mt-3">
                    <label class="checkbox checkbox-outline px-0  mt-md-2">
                      <input type="checkbox" value="true" name="checkboxes"
                          formControlName="done_sterilization_onetime" />
                      <span></span>
                      Done?
                    </label>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label class="px-0">Reference Time</label>
                      <p-calendar placeholder="Time" formControlName="bottle_sterilization_all_time"
                          [timeOnly]="true" inputId="time" [hourFormat]="12" readonly></p-calendar>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label class="px-0">Actual Time</label>
                      <p-calendar placeholder="Time" formControlName="actual_sterilization_all_time"
                          [timeOnly]="true" inputId="time" [hourFormat]="12"></p-calendar>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                      <label class="px-0">Remark</label>
                      <input type="text" name="name" class="form-control" placeholder="Remark"
                         formControlName="onetime_Remark" />
                    </div>
                  </div>
                </div>
              </div>

              <!-- Bottle Sterilization one at a time auto fill fields -->
              <div class="col-12">
                <label class="subHeading mb-3">One At A Time </label>
                <div *ngIf="isFormula && !sterilizingOne">
                  <div class="row">
                    <div class="add-listings-box">
                      <div formArrayName="bottle_sterilization">
                        <div *ngFor="let control of getbottle_sterilizationControls().controls; let i = index"
                            [formGroupName]="i" class="col-lg-12">
                          <div class="row">
                            <div class="col-lg-2 col-md-2 mt-3">
                              <label class="checkbox checkbox-outline px-0  mt-md-2">
                                <input type="checkbox" value="true" name="checkboxes"
                                    formControlName="done_sterilization" />
                                <span></span>
                                Done?
                              </label>
                            </div>
                            <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                <label class="px-0">Reference Time</label>
                                <p-calendar placeholder="Time" formControlName="sterilization_timeArray"
                                    [timeOnly]="true" inputId="time" [hourFormat]="12" [disabled]="true"></p-calendar>
                              </div>
                            </div>
                            <div class="col-lg-3 col-sm-6 col-12">
                              <div class="form-group">
                                <label class="px-0">Actual Time</label>
                                <p-calendar placeholder="Time" formControlName="actual_sterilization_timeArray"
                                    [timeOnly]="true" inputId="time" [hourFormat]="12"></p-calendar>
                              </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-12">
                              <div class="form-group">
                                <label class="px-0">Remark</label>
                                <input type="text" name="name" class="form-control" placeholder="Remark"
                                    [formControl]="control.get('sterilization_remark')" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-accordionTab>
        <!-- Sterilization Time End-->

        <!-- Food / Medicine Allergy Start-->



        <!-- Food / Medicine Allergy End-->

        <!-- Diaper Change start  -->
        <!-- <p-accordionTab header="Diaper Change">
          <div class="add-listings-box p-0 border-0">
            <div class="row">

              <div class="col-sm-8">
                <label for="" class="d-none d-md-block">&nbsp;</label>
                <div class="row" formArrayName="diaper_changes">
                  <div *ngFor="let control of getDiaperChangeControlsfrequency().controls; let i = index"
                      [formGroupName]="i" class="col-6 col-md-4 col-lg-3">
                    <div class="form-group">

                      <div class="form-check px-0 checkbox-list">
                        <label class="checkbox checkbox-outline px-0">
                          <input type="checkbox" value="yes" formControlName="isDiaperChanged" />
                          <span></span>
                          {{ getTimeLabel(i) }}
                        </label>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>



            <div class="row" formArrayName="diaper_change_times">
              <ng-container *ngFor="let control of getDiaperChangeControls().controls; index as i; last as last">
                <div class="row" [formGroupName]="i">

                  <div class="col-lg-2 col-sm-6 col-12 mt-3">
                    <div class="form-group">
                      <div class="form-check checkbox-list p-0">
                        <label class="checkbox checkbox-outline mt-sm-0 mt-3 px-0">
                          <input type="checkbox" value="withMother" name="checkboxes" formControlName="change" />
                          Changed?
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                      <label class="px-0">Changing Time </label>
                      <div class="form-check checkbox-list p-0">
                        <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time"
                            formControlName="time" [class.is-invalid]="(formSubmitted.submitted &&
                                     zeroToThreeMonthsForm.get('time')?.invalid) ||
                                    zeroToThreeMonthsForm.get('time')?.invalid &&
                                    zeroToThreeMonthsForm.get('time').touched">
                        </p-calendar>
                        <div class="invalid-feedback">Time is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                      <label class="px-0">Remark</label>
                      <input type="text" name="name" class="form-control" placeholder="Remark"
                          [formControl]="control.get('diaper_remark')" />
                    </div>
                  </div>
                  <div class="col-auto d-flex mb-3 align-items-end">
                    <p-button type="button" icon="bx bx-plus" (click)="adddiaperObject('add', i)" iconPos="right"
                        class=" secondary-btn mr-2"></p-button>
                    <div class="remove">
                      <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2" icon="bx bx-minus"
                          (click)="adddiaperObject('delete', i)" iconPos="right">
                      </p-button>
                    </div>
                  </div>

                </div>
              </ng-container>
            </div>
          </div>
        </p-accordionTab> -->

        <p-accordionTab header="Diaper Change">
          <div class="add-listings-box p-0 border-0">
            <div class="row">
              <!-- diaper times Start -->
              <div class="col-sm-8 col-md-12">
                <label for="" class="d-none d-md-block">&nbsp;</label>
                <div class="row" formArrayName="diaper_calculation">
                  <div *ngFor="let control of getdiaperCalculationControls().controls; let i = index"
                      [formGroupName]="i" class="row">

                    <!-- <div class="form-check px-0 checkbox-list">
                                      <label class="checkbox checkbox-outline px-0">
                                          <input type="checkbox" value="yes"
                                              formControlName="isDiaperChanged" />
                                          <span></span>
                                          {{ getTimeLabel(i) }}
                                      </label>
                                  </div> -->

                    <div class="col-lg-2 col-sm-6 col-12 d-flex align-items-end">
                      <div class="form-group">
                        <div class="form-check checkbox-list p-0">
                          <label class="checkbox checkbox-outline mt-sm-0 mt-3 px-0">
                            <input type="checkbox" value="withMother" name="checkboxes"
                                formControlName="diaper_checkbox" />
                            Changed?
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-12">
                      <div class="form-group">
                        <label class="px-0">Changing Time</label>
                        <div class="form-check checkbox-list p-0">
                          <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time" readonly
                          formControlName="diaper_timeArray"></p-calendar>
                          <div class="invalid-feedback">Time is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-12">
                      <div class="form-group">
                        <label class="px-0">Actual Time</label>
                        <div class="form-check checkbox-list p-0">
                          <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time"
                              formControlName="diaper_actual_time"></p-calendar>
                          <div class="invalid-feedback">Time is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-12">
                      <div class="form-group">
                        <label class="px-0">Remark</label>
                        <input type="text" name="name" class="form-control" placeholder="Remark"
                            formControlName="diaperRemark" />
                      </div>
                    </div>

                  </div>
                </div>

              </div>
              <!-- diaper times end  -->
            </div>

            <div class="row" formArrayName="diaper_change_times">
              <label class="subHeading mb-2 mt-2">Additional Diaper Change </label>

              <ng-container *ngFor="let control of getDiaperChangeControls().controls; index as i; last as last">
                <div class="row" [formGroupName]="i">

                  <div class="col-lg-2 col-sm-6 col-12 mt-3">
                    <div class="form-group">
                      <div class="form-check checkbox-list p-0">
                        <label class="checkbox checkbox-outline mt-sm-0 mt-3 px-0">
                          <input type="checkbox" value="withMother" name="checkboxes" formControlName="change"
                              (change)="addValidator(i,$event,'addDiaper')" />
                          Changed?
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                      <label class="px-0">Changing Time </label>
                      <div class="form-check checkbox-list p-0">
                        <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time"
                            formControlName="time" [class.is-invalid]="(formSubmitted.submitted &&
                                             zeroToThreeMonthsForm.get('time')?.invalid) ||
                                            zeroToThreeMonthsForm.get('time')?.invalid &&
                                            zeroToThreeMonthsForm.get('time').touched">
                        </p-calendar>
                        <div class="error" *ngIf="control?.status !== 'VALID' && control?.status !== 'DISABLED'">Time is
                          required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                      <label class="px-0">Remark</label>
                      <input type="text" name="name" class="form-control" placeholder="Remark"
                          [formControl]="control.get('diaper_remark')" />
                    </div>
                  </div>
                  <div class="col-auto d-flex mb-3 align-items-end">
                    <p-button type="button" icon="bx bx-plus" (click)="adddiaperObject('add', i)" iconPos="right"
                        class=" secondary-btn mr-2"></p-button>
                    <div class="remove">
                      <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2" icon="bx bx-minus"
                          (click)="adddiaperObject('delete', i)" iconPos="right">
                      </p-button>
                    </div>
                  </div>

                </div>
              </ng-container>
            </div>
          </div>
        </p-accordionTab>
        <!-- Bathing section start-->
        <!-- Spong bath -->
        <p-accordionTab header="Bathing">
          <div class="add-listings-box pb-0">
            <h3>Bathing </h3>
            <div class="row">
              <!-- <label class="subHeading mb-sm-4 mb-2">Bathing </label> -->

              <!-- Sponge -->
              <div class="col">
                <label class="subHeading px-0">Sponge</label>


                <div *ngIf="isBathing">
                  <div class="row" formArrayName="bathing_time">

                    <div *ngFor="let control of getbathingarray().controls; let i = index" [formGroupName]="i"
                        class="row">
                      <div class="form-check checkbox-listb d-flex align-items-center col-md-2 col-lg-2 form-group">
                        <div>
                          <label>
                            <input type="checkbox" value="breastFeeding" name="checkboxes"
                                formControlName="sponge_checkbox" />
                            Done?
                          </label>
                        </div>
                      </div>
                      <div class="justify-content-center col-md-3 form-group">
                        <div>
                          <label class="px-0">Reference Time</label>
                          <div class="form-check checkbox-list p-0">
                            <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time"
                                disabled="true" formControlName="bathing_sponge_time"></p-calendar>
                            <div class="invalid-feedback">Time is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="justify-content-center col-md-3 form-group">
                        <div>
                          <label class="px-0">Actual Time</label>
                          <div class="form-check checkbox-list p-0">
                            <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time"
                                formControlName="bathing_sponge_actual_time"></p-calendar>
                            <div class="invalid-feedback">Time is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-lg-4">
                        <div class="form-group">
                          <label class="px-0">Remark</label>
                          <input type="text" name="name" class="form-control" placeholder="Remark"
                              [formControl]="control.get('spongeBathing_remark')" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <!-- Tub bath -->
            <div class="row">
              <div class="col">
                <label class="subHeading px-0">Tub Bath</label>
                <div *ngIf="isBathingtub">
                  <div class="row" formArrayName="tub_bathing">
                    <div *ngFor="let control of getbathingtubarray().controls; let i = index" [formGroupName]="i">
                      <div class="row">
                        <div class="form-check checkbox-list d-flex align-items-center col-md-2 col-lg-2 form-group">
                          <label>
                            <input type="checkbox" value="true" name="checkboxes" formControlName="tub_checkbox" />
                            Done?
                          </label>
                        </div>
                        <div class="justify-content-center col-md-3 form-group">
                          <label class="px-0">Reference Time</label>
                          <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time"
                              disabled="true" formControlName="bathing_tub_time"></p-calendar>
                          <div class="invalid-feedback">Time is required</div>
                        </div>
                        <div class="justify-content-center col-md-3 form-group">
                          <label class="px-0">Actual Time</label>
                          <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time"
                              formControlName="bathing_tub_actual_time"></p-calendar>
                          <div class="invalid-feedback">Time is required</div>
                        </div>
                        <div class="col-md-4 col-lg-4">
                          <div class="form-group">
                            <label class="px-0">Remark</label>
                            <input type="text" name="name" class="form-control" placeholder="Remark"
                                [formControl]="control.get('tubBathing_remark')" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </p-accordionTab>

        <!-- *************** -->
        <!-- <div class="row">
          <label class="subHeading mb-3">Bathing </label>
          <div class="col-lg-4 col-6">
            <div class="form-group">
              <div class=" ">
                <div class="form-check checkbox-list p-0">
                  <div class="row py-3">
                    <label class="checkbox checkbox-outline col-lg-5 col-sm-6 col-12">
                      <input type="checkbox" value="spongeBath" name="checkboxes"
                        (change)="handleInput($event, 'bathing')" formControlName="bathing_type" />
                      Sponge Bath
                    </label>

                    <label class="checkbox checkbox-outline col-lg-5 col-sm-6 col-12 mt-sm-0 mt-3">
                      <input type="checkbox" value="tubBath" name="checkboxes" (change)="handleInput($event, 'bathing')"
                        formControlName="bathing_type" />
                      Tub Bath
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <!-- <div class="row">
          <div class="col-lg-4 col-6">
            <div class="form-group">
              <label class="px-0">Frequency </label>
              <div class="form-check checkbox-list p-0">
                <p-dropdown [options]="frequency" [(ngModel)]="selectedBathFrequency"
                  formControlName="bathing_frequency" optionLabel="frequency" [filter]="true" filterBy="frequency"
                  placeholder="Frequency" [resetFilterOnHide]="true">
                  <ng-template pTemplate="selectedItem">
                    <div class="flex align-items-center gap-2" *ngIf="selectedBathFrequency">
                      <div>{{ selectedBathFrequency.frequency }}</div>
                    </div>
                  </ng-template>
                  <ng-template let-frequency pTemplate="item">
                    <div class="flex align-items-center gap-2">
                      <div>{{ frequency.frequency }}</div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div> -->

        <!-- <div class="col-lg-4 col-6">
            <div class="form-group">
              <label class="px-0">Time </label>
              <div class="form-check checkbox-list p-0">
                <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time"
                  formControlName="bathing_time"></p-calendar>
              </div>
            </div>
          </div>
        </div> -->
        <!-- Bathing section end-->

        <!-- Skin Care Section START-->
        <p-accordionTab header="Skin Care">
          <div class="add-listings-box p-0 border-0">
            <label class="subHeading mb-3">Skin Care </label>
            <div formArrayName="skin_care">
              <ng-container *ngFor="let control of getSkinFormArray().controls; index as i; last as last">
                <div class="row" [formGroupName]="i">
                  <div class="col-lg-4">
                    <div class="form-group mb-0">
                      <div class=" ">
                        <div class="form-check checkbox-list p-0">
                          <div class="">
                            <label class="checkbox checkbox-outline px-0">
                              <input type="checkbox" value name="checkboxes" (change)="handleInput($event, '')"
                                  formControlName="skin_care_lotion" />
                              Lotion
                            </label>

                            <label class="checkbox checkbox-outline mt-sm-0 mt-3 px-0">
                              <input type="checkbox" value name="checkboxes"
                                  (change)="handleInput($event, 'sterilization')"
                                  formControlName="skin_care_baby_oil" />
                              Baby Oil
                            </label>

                            <label class="checkbox checkbox-outline mt-sm-0 mt-3 px-0">
                              <input type="checkbox" value="sterilizeOne" name="checkboxes"
                                  (change)="handleInput($event, 'sterilization')" formControlName="skin_care_others" />
                              Other
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg">
                    <div class="form-group">
                      <!-- <label class="p-0">Specifiy Product: </label> -->
                      <div class="col-12 p-0">
                        <textarea name="name" class="form-control" placeholder="Specify Product"
                            formControlName="skin_care_specify_produ" rows="2" [class.is-invalid]="(formSubmitted.submitted &&
                  zeroToThreeMonthsForm.get('skin_care_specify_produ')?.invalid)
                  ||
                  zeroToThreeMonthsForm.get('skin_care_specify_produ')?.invalid
                  &&
                  zeroToThreeMonthsForm.get('skin_care_specify_produ').touched"></textarea>
                        <div class="invalid-feedback">Time is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-auto d-flex mb-3 justify-content-end">
                    <p-button type="button" (click)="addSkinObject('add', i)" icon="bx bx-plus" class="secondary-btn"
                        iconPos="right"></p-button>
                    <div class="remove">
                      <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2" icon="bx bx-minus"
                          (click)="addSkinObject('delete', i)" iconPos="right"></p-button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>


            <!-- Skin Care Section END-->

            <!-- Changing of clothes section START-->
            <div class="row">
              <label class="subHeading mb-2 mt-2">Changing of Clothes </label>
              <!-- <div class="col-lg-4">
                <div class="form-group mb-0">
                  <label class="px-0">Frequency </label>
                  <div class="form-check checkbox-list p-0">
                    <p-dropdown [options]="frequency" [(ngModel)]="selectedClothesFrequency"
                        formControlName="changing_colthes_frequency" optionLabel="frequency" [filter]="true"
                        filterBy="frequency" placeholder="Frequency" [resetFilterOnHide]="true">
                      <ng-template pTemplate="selectedItem">
                        <div class="flex align-items-center gap-2" *ngIf="selectedClothesFrequency">
                          <div>{{ selectedClothesFrequency.frequency }}</div>
                        </div>
                      </ng-template>
                      <ng-template let-frequency pTemplate="item">
                        <div class="flex align-items-center gap-2">
                          <div>{{ frequency.frequency }}</div>
                        </div>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>
              </div> -->

            </div>
            <div class="row" formArrayName="changing_clothes">
              <ng-container *ngFor="let control of getClothesFormArray().controls; index as i; last as last">
                <div class="row" [formGroupName]="i">

                  <div class="col-lg-2 col-sm-6 col-12">
                    <div class="form-group">
                      <div class="form-check checkbox-list p-0">
                        <label class="checkbox checkbox-outline mt-sm-0 mt-3 px-0">
                          <input type="checkbox" value="withMother" name="checkboxes"
                              formControlName="changing_colthes" />
                          Changing Clothes
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label class="px-0">Reference Time </label>
                      <div class="form-check checkbox-list p-0">
                        <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time"
                            disabled="true" formControlName="Changing_time" [class.is-invalid]="(formSubmitted.submitted &&
                                     zeroToThreeMonthsForm.get('Changing_time')?.invalid) ||
                                    zeroToThreeMonthsForm.get('Changing_time')?.invalid &&
                                    zeroToThreeMonthsForm.get('Changing_time').touched">
                        </p-calendar>
                        <div class="invalid-feedback">Time is required</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 col-sm-6 col-12">
                    <div class="form-group">
                      <label class="px-0">Actual Time </label>
                      <div class="form-check checkbox-list p-0">
                        <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time"
                            formControlName="Changing_actual_time" [class.is-invalid]="(formSubmitted.submitted &&
                                     zeroToThreeMonthsForm.get('Changing_actual_time')?.invalid) ||
                                    zeroToThreeMonthsForm.get('Changing_actual_time')?.invalid &&
                                    zeroToThreeMonthsForm.get('Changing_actual_time').touched">
                        </p-calendar>
                        <div class="invalid-feedback">Time is required</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                      <label class="px-0">Remark</label>
                      <input type="text" name="name" class="form-control" placeholder="Remark"
                          [formControl]="control.get('clothe_remark')" />
                    </div>
                  </div>
                  <div class="col-auto d-flex mb-3 align-items-end">
                    <p-button type="button" icon="bx bx-plus" (click)="addClothesObject('add', i)" iconPos="right"
                        class=" secondary-btn mr-2"></p-button>
                    <div class="remove">
                      <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2" icon="bx bx-minus"
                          (click)="addClothesObject('delete', i)" iconPos="right">
                      </p-button>
                    </div>
                  </div>

                </div>
              </ng-container>
            </div>

          </div>
        </p-accordionTab>

        <!-- Changing of clothes section END-->

        <!-- Sleeping Section START-->
        <p-accordionTab header="Sleeping">
          <div class="add-listings-box pb-0">
            <h3>Sleeping</h3>
            <div class="row">
              <div class="row" formArrayName="sleeping_time">
                <ng-container *ngFor="let control of getSleepArray().controls; index as i; last as last">
                  <div class="row" [formGroupName]="i">
                    <!-- <label class="subHeading mb-3">Sleeping </label> -->
                    <div class="col-lg-2 col-sm-6 col-12">
                      <div class="form-group">
                        <div class="form-check checkbox-list p-0">
                          <!-- <div class="row py-3"> -->
                          <label class="checkbox checkbox-outline px-0 mt-sm-2">
                            <input type="checkbox" value="withMother" name="checkboxes"
                                (change)="handleInput($event, 'sleepingWith')" formControlName="sleeping_mother" />
                            With Mother
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-12">
                      <div class="form-group">
                        <label class="px-0">Reference Time </label>
                        <div class="form-check checkbox-list p-0">
                          <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time"
                              disabled="true" formControlName="sleeping_mother_time" [class.is-invalid]="(formSubmitted.submitted &&
                              zeroToThreeMonthsForm.get('sleeping_mother_time')?.invalid)
                              ||
                              zeroToThreeMonthsForm.get('sleeping_mother_time')?.invalid
                              &&
                              zeroToThreeMonthsForm.get('sleeping_mother_time').touched"></p-calendar>
                          <div class="invalid-feedback">Time is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-12">
                      <div class="form-group">
                        <label class="px-0">Actual Time </label>
                        <div class="form-check checkbox-list p-0">
                          <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time"
                              formControlName="sleeping_mother_actual_time" [class.is-invalid]="(formSubmitted.submitted &&
                              zeroToThreeMonthsForm.get('sleeping_mother_actual_time')?.invalid)
                              ||
                              zeroToThreeMonthsForm.get('sleeping_mother_actual_time')?.invalid
                              &&
                              zeroToThreeMonthsForm.get('sleeping_mother_actual_time').touched"></p-calendar>
                          <div class="invalid-feedback">Time is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                      <div class="form-group">
                        <label class="px-0">Remark</label>
                        <input type="text" name="sleeping_mother_remark" class="form-control" placeholder="Remark"
                            formControlName="sleeping_mother_remark" />
                      </div>
                    </div>
                    <div class="col-lg-1 col-md-1 d-flex mb-3 align-items-end">
                      <p-button type="button" icon="bx bx-plus" (click)="addsleeptimeObject('add', i)" iconPos="right"
                          class=" secondary-btn mr-2"></p-button>
                      <div class="remove">
                        <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2" icon="bx bx-minus"
                            (click)="addsleeptimeObject('delete', i)" iconPos="right">
                        </p-button>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="row">
              <div class="row" formArrayName="nanny_time">
                <ng-container *ngFor="let control of getSleepnannyArray().controls; index as i; last as last">
                  <div class="row" [formGroupName]="i">
                    <div class="col-lg-2 col-sm-6 col-12">
                      <div class="form-group">
                        <div class="form-check checkbox-list p-0">
                          <label class="checkbox checkbox-outline mt-sm-2 mt-3 px-0">
                            <input type="checkbox" value="withNanny" name="checkboxes"
                                (change)="handleInput($event, 'sleepingWith')" formControlName="sleeping_nanny" />
                            With Nanny
                          </label>
                          <!-- </div> -->
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-12">
                      <div class="form-group">
                        <label class="px-0">Reference Time: </label>
                        <div class="form-check checkbox-list p-0">
                          <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time"
                              disabled="true" formControlName="sleeping_nanny_time" [class.is-invalid]="(formSubmitted.submitted &&
                            zeroToThreeMonthsForm.get('sleeping_nanny_time')?.invalid)
                            ||
                            zeroToThreeMonthsForm.get('sleeping_nanny_time')?.invalid
                            &&
                            zeroToThreeMonthsForm.get('sleeping_nanny_time').touched"></p-calendar>
                          <div class="invalid-feedback">Time is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-12">
                      <div class="form-group">
                        <label class="px-0">Actual Time: </label>
                        <div class="form-check checkbox-list p-0">
                          <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time"
                              formControlName="sleeping_nanny_actual_time" [class.is-invalid]="(formSubmitted.submitted &&
                            zeroToThreeMonthsForm.get('sleeping_nanny_actual_time')?.invalid)
                            ||
                            zeroToThreeMonthsForm.get('sleeping_nanny_actual_time')?.invalid
                            &&
                            zeroToThreeMonthsForm.get('sleeping_nanny_actual_time').touched"></p-calendar>
                          <div class="invalid-feedback">Time is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                      <div class="form-group">
                        <label class="px-0">Remark</label>
                        <input type="text" name="sleeping_mother_remark" class="form-control" placeholder="Remark"
                            formControlName="sleeping_nanny_remark" />
                      </div>
                    </div>
                    <div class="col-lg-1 col-md-1 d-flex mb-3 align-items-end">
                      <p-button type="button" icon="bx bx-plus" (click)="addnannyObject('add', i)" iconPos="right"
                          class=" secondary-btn mr-2"></p-button>
                      <div class="remove">
                        <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2" icon="bx bx-minus"
                            (click)="addnannyObject('delete', i)" iconPos="right">
                        </p-button>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </p-accordionTab>

        <!-- Temperature -->
        <p-accordionTab header="Vitamins/Medications">
          <div class="add-listings-box">


            <!-- Vitamins & Medications Start-->
            <!-- Kindly ADD multiple selection of this Section-->
            <div class="row">
              <label class="subHeading mb-3">Vitamins/Medications</label>
              <div formArrayName="vitamins_medications_json">
                <ng-container *ngFor="let control of getVitaminFormArray().controls; index as i; last as last">
                  <div class="row" [formGroupName]="i">
                    <div class="col-md-4 form-group">
                      <label>Medication: {{ control?.value?.vitamin }} and frequency
                        {{ control?.value?.frequency }}</label>
                    </div>
                    <div *ngIf="ismedicine">
                      <div class="row" formArrayName="medicine_time">
                        <div *ngFor="let control of getmedicinearray(i).controls; index as j">
                          <div [formGroupName]="j">
                            <div class="row">
                              <div
                                  class="form-check checkbox-list col-md-2 col-lg-2 form-group d-flex align-items-center">
                                <label class="">
                                  <input type="checkbox" value="withvitamin" name="vitamin_checkbox"
                                      formControlName="vitamin_checkbox" />
                                  Done?
                                </label>
                                <!-- </div> -->
                              </div>
                              <div class="justify-content-center col-md-3 form-group">
                                <label class="px-0">Reference Time</label>
                                <p-calendar placeholder="Time" formControlName="time" [timeOnly]="true" disabled="true"
                                    [hourFormat]="12" inputId="time"></p-calendar>
                              </div>
                              <div class="justify-content-center col-md-3 form-group">
                                <label class="px-0">Actual Time</label>
                                <p-calendar placeholder="Time" formControlName="actual_time" [timeOnly]="true"
                                    [hourFormat]="12" inputId="time"></p-calendar>
                              </div>
                              <div class="col-md-4 col-lg-4">
                                <div class="form-group">
                                  <label class="px-0">Remark</label>
                                  <input type="text" name="name" class="form-control" placeholder="Remark"
                                      [formControl]="control.get('vitamin_remark')" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

            <!-- <div class="row">
              <label class="subHeading mb-3">Vaccinations</label>
              <div formArrayName="vaccinations_json">
                <ng-container *ngFor="let control of getVaccinationFormArray().controls; index as i; last as last">
                  <div class="row" [formGroupName]="i">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="px-0">Vaccinations</label>
                        <div class="pl-0 checkbox-list">
                          <input type="text" formControlName="vaccination" class="form-control"
                            placeholder="Vaccinations" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group mb-0">
                        <label class="px-0">Date of Administration</label>
                        <div class="card flex justify-content-center">
                          <p-calendar placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                            formControlName="date_administration" [showIcon]="true"></p-calendar>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 d-flex justify-content-end align-items-end mt-sm-0 mt-3">
                      <div class="form-group mb-0">
                        <div class="d-flex justify-content-end">
                          <p-button type="button" icon="bx bx-plus" (click)="addVaccinationObject('add', i)"
                            iconPos="right" class="secondary-btn  "></p-button>
                          <div class="remove">
                            <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2" icon="bx bx-minus"
                              (click)="addVaccinationObject('delete', i)" iconPos="right"></p-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div> -->
          </div>
        </p-accordionTab>

        <!-- Remarks -->
        <p-accordionTab header="Remarks">
          <div class="add-listings-box">
            <div class="row">
              <label class="subHeading mb-3">Remarks</label>
              <div class="col-12">
                <div class="form-group">
                  <div class="form-check checkbox-list p-0">
                    <textarea readonly style="background-color: #ffff84 !important" name="name" class="form-control"
                        placeholder="Enter Remarks" formControlName="remarks" rows="5"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <label class="subHeading mb-3">Add Remarks</label>
            <div formArrayName="nurse_remarks">
              <ng-container *ngFor="let control of getNurseRemarks().controls; index as i; last as last">
                <div class="row" [formGroupName]="i">
                  <div class="col-lg">
                    <div class="form-group">
                      <!-- <label class="p-0">Specifiy Product: </label> -->
                      <div class="col-12 p-0">
                        <textarea name="name" class="form-control" placeholder="Remark" formControlName="nurse_remark"
                            rows="2"></textarea>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg">
                    <div class="form-group">
                      <div class="col-12 p-0">
                        <textarea name="name" class="form-control" placeholder="Time"
                            formControlName="nurse_remark_time" readonly rows="2"></textarea>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-auto d-flex mb-3 align-items-center">
                    <p-button type="button" (click)="addNurseRemark('add', i)" icon="bx bx-plus" class="secondary-btn"
                        iconPos="right"></p-button>
                    <div class="remove">
                      <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2" icon="bx bx-minus"
                          (click)="addNurseRemark('delete', i)" iconPos="right"></p-button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>
      <!-- Add + Button for multiple selection -->
      <!-- Vaccinations End-->


    </ng-container>
    <!-- <div class="add-listings-btn" style="text-align: right;" *ngIf="checkPermission(31)">
      <button type="submit" [disabled]="!flag">Submit</button>
    </div> -->
    <div class="add-listings-btn d-flex justify-content-end">
      <div class="me-2">
        <button type="submit" [disabled]="!flag" *ngIf="checkPermission(31)">Save</button>
      </div>
      <div>
        <button type="button" (click)="nannyTaskOnFinalSubmit()">Submit</button>
      </div>
    </div>
    <!-- DAILY CARE SECTION END-->
  </form>
  <!-- End Form -->
  <app-copyrights></app-copyrights>
</div>