<p-dialog header="Session Timeout" [(visible)]="authModalState" [breakpoints]="{'850px' : '90vw'}" [style]="{width: '35vw'}" [modal]="true"
  [draggable]="false" [closable]="false" [resizable]="false">
  <p class="d-flex align-items-center"><span class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem;"></span>
    Your session has been expired. Please
    Login again!</p>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="romoveToken()" label="Ok"></p-button>
  </ng-template>
</p-dialog>
<router-outlet></router-outlet>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<p-confirmDialog
    [style]="{ width: '40vw' }"
    [baseZIndex]="10000"
  ></p-confirmDialog>
