import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MaikaListService } from 'src/app/services/maika-list.service';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-memories-moments',
  templateUrl: './memories-moments.component.html',
  styleUrls: ['./memories-moments.component.scss'],
  providers: [MessageService]

})
export class MemoriesMomentsComponent implements OnInit,  OnDestroy{
   public triggerObservable: Subject<void> = new Subject<void>();
    @ViewChild('video') videoElement: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  stream: MediaStream;
  capturedImg: File
  ImageName="Captured-Image"
  openCameraforUpload: boolean = false
  IndexforImage=0
  imagePreview:boolean=false
  imgSrcForPreview: any;
  capturedByCamera:any = [];
  selectedFile: File | null = null;
  babyImgArray: any = []
  hastag: any = [];
  selectedCities:any
  babyImgDetails: any;
  urlsofImg: string = environment.hastagImgUrl;
  showcard:boolean=false
  capturedImage: string;
  openCameraa: boolean;
  camera: boolean;

  constructor(private api:MaikaListService,  private messageService: MessageService , private spinner: NgxSpinnerService) { }
  breadcrumb = [
    {
      title: localStorage.getItem('baby_name'),
      subTitle: 'Dashboard',
    },
  ];
  ngOnInit(): void {
    this.getHastags();
    this.getBabyImages();
  }

  
  openCamera(frontFacing?: boolean , event ? :Event) {
     if(frontFacing){
       this.camera=frontFacing
     }
     else{
       this.camera = this.camera == true ? false : true 
     }
    if (event) {
      event.preventDefault();
   }
  const video = this.videoElement.nativeElement;
  if (video.srcObject) {
    const stream = video.srcObject as MediaStream;
    const tracks = stream.getTracks();
    tracks.forEach(track => track.stop()); 
    video.srcObject = null; 
  }
    const constraints = {
      video: {
        facingMode: this.camera ? 'user' : 'environment' 
      }
    };
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia(constraints)
        .then(stream => {
          this.stream = stream;
          this.videoElement.nativeElement.srcObject = stream;
          this.openCameraforUpload = true;
        })
        .catch(error => {
        });
    }
  }
  ngOnDestroy(): void {
    this.shutDownCamera();
  }
  shutDownCamera(){
    this.openCameraforUpload=false;
    const video = this.videoElement.nativeElement;
  if (video.srcObject) {
    const stream = video.srcObject as MediaStream;
    const tracks = stream.getTracks();
    tracks.forEach(track => track.stop()); 
    video.srcObject = null; 
    this.openCameraforUpload = false
  }
  }

  
  arrayBuffersEqual(buf1: ArrayBuffer, buf2: ArrayBuffer): boolean {
    if (buf1.byteLength !== buf2.byteLength) return false;
    const dv1 = new DataView(buf1);
    const dv2 = new DataView(buf2);
    for (let i = 0; i < buf1.byteLength; i++) {
      if (dv1.getUint8(i) !== dv2.getUint8(i)) return false;
    }
    return true;
  }
  deletecapture(imgSrc: any) {
    const byteString = atob(imgSrc.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: 'image/png' });

    const deleteIMG = new File([blob], `captured-image${this.IndexforImage}.png`, { type: 'image/png' });
    this.babyImgArray.forEach((file, index) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const arrayBuffer = e.target.result;

        if (this.arrayBuffersEqual(arrayBuffer, ab)) {
          this.babyImgArray.splice(index, 1); 
        }
      };
      reader.readAsArrayBuffer(file);
    });
    this.capturedByCamera = this.capturedByCamera.filter(item => item !== imgSrc);
    this.imagePreview = false;
  }
  capture() {
    this.showcard=true
    ++this.IndexforImage
   const context = this.canvas.nativeElement.getContext('2d');
   const canvasWidth = this.canvas.nativeElement.width;
   const canvasHeight = this.canvas.nativeElement.height;
   context.drawImage(this.videoElement.nativeElement, 0, 0, canvasWidth, canvasHeight);
   const imageData = this.canvas.nativeElement.toDataURL('image/png');
   this.capturedByCamera.push(imageData)
   const byteString = atob(imageData.split(',')[1]);
   const ab = new ArrayBuffer(byteString.length);
   const ia = new Uint8Array(ab);
   for (let i = 0; i < byteString.length; i++) {
     ia[i] = byteString.charCodeAt(i);
   }
   const blob = new Blob([ab], { type: 'image/png' });
   this.capturedImg = new File([blob], `captured-image${this.IndexforImage}.png`, { type: 'image/png' });
   this.uploadPhoto(null, this.capturedImg)
 }

 uploadImages() {
  const babayId = localStorage.getItem("babyId")
  let formData = new FormData;
  formData.append('baby_id' , babayId)
  this.babyImgArray.forEach((file) => {
    formData.append('tags[]', file.name);
  });
  this.babyImgArray.forEach((file) => {
    formData.append('media', file);
  });
  this.spinner.show()

  this.api.postbabyimgUsingHastag(formData).then(
    (res: any) => {
      this.spinner.hide()
      this.showcard=false;
      this.selectedCities=[];
      this.babyImgArray=[];
      this.capturedByCamera=[];  
      this.messageService.add({ severity: 'success', summary: 'Success', detail: res?.message });
 
    },
    (error: any) => {
      this.spinner.hide()
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please Reupload' });
    })

}
 uploadPhoto(event: any, captureImg?: any) {
    
  if (captureImg) {
    this.selectedFile = captureImg;
  }
  else {
    this.selectedFile = event.target.files[0];
  }
  if (event &&
    event.target.files[0].type == 'image/jpeg' ||
    'image/jpg' ||
    'image/png' ||
    'application/pdf'
  ) {
    if (event) {
      this.babyImgArray.push(event.target.files[0]);
    }
    else {
      this.babyImgArray.push(captureImg);
      
    }
    this.saveImageToDeviceGallery(this.selectedFile)
  }
 
}
saveImageToDeviceGallery(imageData) {
  const a = document.createElement('a');
  const blobUrl = URL.createObjectURL(imageData);
  a.href = blobUrl;
  a.download = 'image.png';
  a.click();
  URL.revokeObjectURL(blobUrl);
}



previewImage(imgSrc:any){
  this.imagePreview=true
  this.imgSrcForPreview=imgSrc
 }

// stopCamera() {
//   const video = this.videoElement.nativeElement;
//   if (video.srcObject) {
//     const stream = video.srcObject as MediaStream;
//     const tracks = stream.getTracks();
//     tracks.forEach(track => track.stop()); // Stop tracks to close the camera
//     video.srcObject = null; // Reset the srcObject to release the camera resource
//     this.openCameraforUpload = false
//   }
// }

getHastags(){
  this.api.getHastag().then((res: any) => {
      res.data.forEach((item) => {
        this.hastag.push({ name: item.tags, value: item.tags });
    });
    
  }).catch((error: any) => {
     this.spinner.hide()
     
  })
}
getBabyImages(){
  const babayId = localStorage.getItem("babyId")
  this.api.getBabyImagesUsingHastag(babayId).then((res: any) => {
      this.babyImgDetails=res.data[0].hashtag_moments_data;
      // this.hastag.push({ name: item.tags, value: item.tags });
  
}).catch((error: any) => {
  // this.spinner.hide()
})
}


customOptions: OwlOptions = {
  loop: true,
  nav: true,
  dots: true,
  // autoplayHoverPause: true,
  // autoplay: true,
  margin: 30,
  navText: [
      "<i class='flaticon-left-chevron'></i>",
      "<i class='flaticon-right-chevron'></i>"
  ],
  responsive: {
      0: {
          items: 1,
      },
      768: {
          items: 3,
      },
      1200: {
          items: 4,
      }
  },



}




}
