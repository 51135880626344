<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">
  <app-dashboard-navbar></app-dashboard-navbar>

  <!-- Breadcrumb Area -->
  <div *ngFor="let Content of breadcrumb" class="breadcrumb-area">
    <h1>{{ Content.title }}</h1>
    <ol class="breadcrumb">
      <li class="item">
        <a routerLink="/">Home</a>
      </li>
      <li class="item">
        {{ Content.subTitle }}
      </li>
      <li class="item">{{ Content.title }}</li>
    </ol>
  </div>

  <div class="add-listings-box">
    <div class="Master-view-box">
      <div class="theader d-flex justify-content-between">
        <div class="d-flex">
          <span class="p-input-icon-left ml-auto mr-2 w-100%">
            <i class="pi pi-search"></i>
            <input
              (input)="dt1.filterGlobal($event.target.value, 'contains')"
              pInputText
              type="text" style="text-align: left; padding-left: 20%; height: 45px !important"
              class="form-control  search-input"
              scriptInjection
              placeholder="Search Keyword"
            />
          </span>
          <p-button
            type="button"
            class="mx-2"
            icon="bx bx-reset"
            tooltipPosition="bottom"
          ></p-button>
        </div>
        <div class="add-listings-btn d-flex mb-0" style="text-align: right">
          <a routerLink="/portal/nanny-daily-monitoring" class="nav-link">
            <button type="submit">Add</button>
          </a>
          <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="onPDFExport()"
          class="p-button-warning ms-2" pTooltip="PDF" tooltipPosition="bottom"></button>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-12 col-md-12">
        <p-table
          #dt1
          [value]="NannyDailyMonitoringList"
          [rows]="10"
          [showCurrentPageReport]="true"
          [tableStyle]="{ 'min-width': '50rem', width: '100%' }"
          [rowsPerPageOptions]="[10, 25, 50]"
          [loading]="loading"
          [paginator]="true"
          [globalFilterFields]="['name', 'date_of_birth', 'age']"
          dataKey="id"
          styleClass="p-datatable-sm p-datatable-customers"
          responsiveLayout="scroll"
          currentPageReportTemplate="Showing {first} to
					{last} of {totalRecords} entries"
        >
          <ng-template pTemplate="header">
            <tr>
              <th></th>
              <th style="min-width: 62px">S. No.</th>
              <th>Name</th>
              <th>Date Of Birth</th>
              <th>Days</th>
              <th>Months</th>
              <th>Years</th>
            </tr>
          </ng-template>

          <!-- Expandable icon -->
          <ng-template
            pTemplate="body"
            let-product
            let-expanded="expanded"
            let-i="rowIndex"
          >
            <tr>
              <td >
                <button
                  [pRowToggler]="product"
                  [icon]="
                    expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
                  "
                  type="button"
                  pButton
                  pRipple
                  class="p-button-text p-button-rounded p-button-plain"(click)="getData(product.id)"
                ></button>

              </td>
              <td>{{ i + 1 }}</td>
              <td>
                {{ product?.name ?? "-" | titlecase  }}
              </td>
              <td>
                {{ product?.date_of_birth ?? "-" }}
              </td>
              <td>
                {{ product?.days ?? "-" }}
              </td>
              <td>
                {{ product?.months ?? "-" }}
              </td>
              <td>
                {{ product?.years ?? "-" }}
              </td>
            </tr>
          </ng-template>
          <!-- table to show data -->
          <!-- <ng-template pTemplate="body" let-list let-i="rowIndex">
              <tr>
                  <td>
                      {{ i + 1 }}
                  </td>
                  <td><button pButton pRipple class="p-button-rounded p-button-text p-button-info"
                          pTooltip="View" tooltipPosition="bottom"><i (click)="showMaximizableDialog(list.id)"
                              class="far fa-eye"> click</i></button></td>
                  <td>{{ list?.name ?? '-' }}</td>
                  <td>{{ list?.date_of_birth ?? '-' }}</td>
                  <td>{{ list?.age ?? '-' }}</td>
              </tr>
          </ng-template> -->

          <!-- Expanded table data -->
          <ng-template pTemplate="rowexpansion" let-product>
            <tr>
              <td colspan="7" class="inner-data">
                <div class="p-3 py-0">
                  <p-table [value]="product.daily_monitoring" dataKey="id">
                    <ng-template pTemplate="header">
                      <tr>
                        <th pSortableColumn="id">Sr. No</th>
                        <th pSortableColumn="customer">Action</th>
                        <th pSortableColumn="date">Date</th>
                        <!-- <th pSortableColumn="amount">Amount <p-sortIcon field="amount"></p-sortIcon></th>
                                      <th pSortableColumn="status">Status <p-sortIcon field="status"></p-sortIcon></th>
                                      <th style="width: 4rem"></th> -->
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-order let-ind="rowIndex">
                      <tr>
                        <td>{{ ind + 1 }}</td>
                        <td >
                          <button
                            pButton
                            pRipple
                            class="p-button-rounded p-button-text p-button-info"
                            pTooltip="View"
                            tooltipPosition="bottom"
                            (click)="showMaximizableDialog(order.id)"
                          >
                            <i class="far fa-eye"></i>
                          </button>
                          <button pButton pRipple class="p-button-rounded p-button-icon" pTooltip="Edit" tooltipPosition="bottom" (click)="editOnCLick(order.id)"><i class="bx bx-pencil"></i>
                          </button>
                        </td>
                        <td>
                          {{ order?.created_at | date : 'dd-MM-yyyy' || "-" }}
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td colspan="6">
                          There are no order for this product yet.
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="5">No Data Found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

    <!-- --------------------------------------------------------- view dialog ------------------------------------------------------- -->
    <!-- ***************************************************************************************************************** -->
    <p-dialog
      [(visible)]="displayMaximizable"
      [modal]="true"
      [style]="{ width: '90vw' }"
      [draggable]="false"
      [resizable]="false"
      header="Baby Details"
    >
      <!-- Start -->
      <ng-container>
        <div *ngFor="let temp of data" class="row">
          <div class="col-lg-12">
            <div class="my-profile-box">
              <form>
                <!-- Baby detail -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Name :</label>
                      <input
                        type="text"
                        readonly
                        class="form-control"
                        value="{{ babyData?.name ?? '-' | titlecase }}"
                      />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Gender :</label>
                      <input
                        type="text"
                        readonly
                        class="form-control"
                        value="{{ babyData?.gender ?? '-' }}"
                      />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Date of Birth :</label>
                      <input
                        type="text"
                        readonly
                        class="form-control"
                        value="{{ babyData?.date_of_birth ?? '-' }}"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Days :</label>
                      <input
                        type="text"
                        readonly
                        class="form-control"
                        value="{{ babyData?.days ?? '-' }}"
                      />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Months :</label>
                      <input
                        type="text"
                        readonly
                        class="form-control"
                        value="{{ babyData?.months ?? '-' }}"
                      />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Years :</label>
                      <input
                        type="text"
                        readonly
                        class="form-control"
                        value="{{ babyData?.years ?? '-' }}"
                      />
                    </div>
                  </div>
                </div>

                <!-- Feeding -->
                <h3 class="fw-bold">Feeding</h3>
                <div *ngFor="let food of feeding_json" class="row">
                  <!-- Breast -->
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <!-- <div class="form-group">
                    <label>Breast Feeding : </label>
                    <input type="text" readonly class="form-control" [value]="food?.feeding_no ?? '-'" />
                  </div> -->
                    <ng-container>
                      <div class="form-group">
                        <label for>Breast Feeding :</label>
                        <input
                          type="text"
                          readonly
                          class="form-control"
                          value="{{daily_monitoring?.breast_feeding ==true ? 'Yes' :'-' }}"

                        />

                      </div>
                    </ng-container>
                  </div>

                  <!-- Breast -->
                  <!-- <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label>Feeding </label>
                    <input type="text" readonly class="form-control" [value]="food?.feeding_check ?? '-'" />
                  </div>

                </div> -->

                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Time</label>
                      <input
                      [value]="food?.feeding_time ? (food?.feeding_time | date : 'h:mm:ss') : '-'"
                        type="text"
                        readonly
                        class="form-control"
                      />
                    </div>
                  </div>

                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Amount</label>
                      <input
                        [value]="food?.feeding_amount ?food?.feeding_amount: '-'"
                        type="text"
                        readonly
                        class="form-control"
                      />
                    </div>
                  </div>

                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Remarks</label>
                      <input
                        [value]="food?.feeding_remark ?? '-'"
                        type="text"
                        readonly
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>

                <div *ngFor="let formula of feeding_formula_json" class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <!-- <div class="form-group">
                    <label>Formula Feeding :</label>
                    <input type="text" readonly class="form-control" value="{{Formula ?? '-'}}" />
                  </div> -->
                    <ng-container>
                      <div class="form-group">
                        <label for>Formula Feeding :</label>
                        <input
                          type="text"
                          readonly
                          class="form-control"
                          value="{{daily_monitoring?.formula_feeding ==true ? 'Yes' :'-' }}"

                        />
                       
                      </div>
                    </ng-container>
                  </div>

                  <!-- Formula -->
                  <!-- <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label>Feeding </label>
                    <input type="text" readonly class="form-control" [value]="formula?.feeding_check ?? '-'" />
                  </div>

                </div> -->

                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Time</label>
                      <input
                        [value]="
                          formula?.feeding_time?(formula?.feeding_time | date : 'h:mm:ss'): '-'
                        "
                        type="text"
                        readonly
                        class="form-control"
                      />
                    </div>
                  </div>

                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Amount</label>
                      <input
                        [value]="formula?.feeding_amount ?? '-'"
                        type="text"
                        readonly
                        class="form-control"
                      />
                    </div>
                  </div>

                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Remarks</label>
                      <input
                        [value]="formula?.feeding_remark ?? '-'"
                        type="text"
                        readonly
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>

                <h3>Solid Food Feeding</h3>
                <!-- cerals and grains -->

                <div class="form-group">
                  <label>Cerals and grains</label>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>rice</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ cerals_grains?.rice ? 'Yes' : '-' }}"
                        />
                        <!-- {{ food?.breast_feeding === 1 ? 'Yes' : (food?.breast_feeding === 0 ? 'No' : '-') }}
                          {{cerals_grains?.rice ? 'Yes' : (cerals_grains?.rice === false ? 'No' : '-') }}
                        {{cerals_grains?.rice ? 'Yes' : 'No' || '-'}}-->
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            cerals_grains?.rice_remark
                              ? cerals_grains?.rice_remark
                              : '-'
                          }}"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>barley</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            cerals_grains?.barley ? 'Yes' : '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ cerals_grains?.barley_remark ? cerals_grains?.barley_remark: '-' }}"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>oats</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            cerals_grains?.oats ? 'Yes' : '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ cerals_grains?.oats_remark ? cerals_grains?.oats_remark: '-' }}"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>beans</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          value="{{
                            cerals_grains?.beans ? 'Yes' :  '-'
                          }}"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          value="{{ cerals_grains?.beans_remark ? cerals_grains?.beans_remark : '-' }}"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>lentils</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          value="{{
                            cerals_grains?.lentils ? 'Yes' : '-'
                          }}"
                          readonly
                        />
                      </div>
                    </div>

                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          value="{{ cerals_grains?.lentils_remark ?cerals_grains?.lentils_remark : '-' }}"
                          readonly
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>pasta</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          value="{{
                            cerals_grains?.pasta ? 'Yes' :  '-'
                          }}"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          value="{{ cerals_grains?.pasta_remark ?cerals_grains?.pasta_remark: '-' }}"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>others</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          value="{{ cerals_grains?.other ?cerals_grains?.other: '-' }}"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          value="{{ cerals_grains?.other_remark ? cerals_grains?.other_remark: '-' }}"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <!-- cerals and grains end -->

                <!-- fruits -->

                <div class="form-group">
                  <label>Fruits</label>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Banana</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            fruits?.banana == true ? 'Yes' :  '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ fruits?.banana_remark ? fruits?.banana_remark: '-' }}"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Apple</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            fruits?.apple == true ? 'Yes' : '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ fruits?.apple_remark ?fruits?.apple_remark: '-' }}"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Avocado</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            fruits?.avocado == true ? 'Yes' :'-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ fruits?.avocado_remark ?fruits?.avocado_remark: '-' }}"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Pears</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            fruits?.pears == true ? 'Yes' :'-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ fruits?.pears_remark ?fruits?.pears_remark : '-' }}"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Mango</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            fruits?.mango == true ? 'Yes' : '-'
                          }}"
                        />
                      </div>
                    </div>

                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ fruits?.mango_remark ?fruits?.mango_remark: '-' }}"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Nectrain</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            fruits?.nectrain == true ? 'Yes' : '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ fruits?.nectrain_remark ? fruits?.nectrain_remark : '-' }}"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Figs</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            fruits?.figs == true ? 'Yes' :  '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ fruits?.figs_remark ?fruits?.figs_remark: '-' }}"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Papaya</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            fruits?.papaya == true ? 'Yes' : '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ fruits?.papaya_remark ?fruits?.papaya_remark: '-' }}"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Kiwi</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            fruits?.kiwi == true ? 'Yes' :'-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ fruits?.kiwi_remark ?fruits?.kiwi_remark : '-' }}"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Grapes</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            fruits?.grapes == true ? 'Yes' : '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ fruits?.grapes_remark ?fruits?.grapes_remark :'-' }}"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Orange</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            fruits?.orange == true ? 'Yes' :'-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ fruits?.orange_remark ?fruits?.orange_remark : '-' }}"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Cherries</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            fruits?.cherries == true ? 'Yes' : '-'
                          }}"
                        />
                      </div>
                    </div>

                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ fruits?.cherries_remark ?fruits?.cherries_remark: '-' }}"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Peach</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            fruits?.peach == true ? 'Yes' : '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ fruits?.peach_remark ?fruits?.peach_remark : '-' }}"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Date</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            fruits?.date == true ? 'Yes' :  '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ fruits?.date_remark ?fruits?.date_remark: '-' }}"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Others</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            fruits?.other == true ? 'Yes' :  '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ fruits?.other_remark ?fruits?.other_remark: '-' }}"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <!-- fruits end -->

                <!-- vegetables -->
                <div class="row">
                  <div class="form-group">
                    <label>Vegetables</label>
                    <div class="row">
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div
                          class="form-group d-flex flex-column align-items-start"
                        >
                          <label for>Pumpkin</label>
                          <input
                            type="text"
                            readonly
                            class="form-control flex-grow-1"
                            readonly
                            value="{{
                              vegetables?.pumpkin == true ? 'Yes' : '-'
                            }}"
                          />
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div
                          class="form-group d-flex flex-column align-items-start"
                        >
                          <label for>Remark</label>
                          <input
                            type="text"
                            readonly
                            class="form-control flex-grow-1"
                            readonly
                            value="{{ vegetables?.pumpkin_remark ?vegetables?.pumpkin_remark : '-' }}"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div
                          class="form-group d-flex flex-column align-items-start"
                        >
                          <label for>Potato</label>
                          <input
                            type="text"
                            readonly
                            class="form-control flex-grow-1"
                            readonly
                            value="{{
                              vegetables?.potato == true ? 'Yes' :  '-'
                            }}"
                          />
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div
                          class="form-group d-flex flex-column align-items-start"
                        >
                          <label for>Remark</label>
                          <input
                            type="text"
                            readonly
                            class="form-control flex-grow-1"
                            readonly
                            value="{{ vegetables?.potato_remark ?vegetables?.potato_remark: '-' }}"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div
                          class="form-group d-flex flex-column align-items-start"
                        >
                          <label for>Carrots</label>
                          <input
                            type="text"
                            readonly
                            class="form-control flex-grow-1"
                            readonly
                            value="{{
                              vegetables?.carrots == true ? 'Yes' : '-'
                            }}"
                          />
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div
                          class="form-group d-flex flex-column align-items-start"
                        >
                          <label for>Remark</label>
                          <input
                            type="text"
                            readonly
                            class="form-control flex-grow-1"
                            readonly
                            value="{{ vegetables?.carrots_remark ?vegetables?.carrots_remark : '-' }}"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div
                          class="form-group d-flex flex-column align-items-start"
                        >
                          <label for>Corn</label>
                          <input
                            type="text"
                            readonly
                            class="form-control flex-grow-1"
                            readonly
                            value="{{
                              vegetables?.corn == true ? 'Yes' : '-'
                            }}"
                          />
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div
                          class="form-group d-flex flex-column align-items-start"
                        >
                          <label for>Remark</label>
                          <input
                            type="text"
                            readonly
                            class="form-control flex-grow-1"
                            readonly
                            value="{{ vegetables?.corn_remark ? vegetables?.corn_remark:'-' }}"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div
                          class="form-group d-flex flex-column align-items-start"
                        >
                          <label for>Marrow</label>
                          <input
                            type="text"
                            readonly
                            class="form-control flex-grow-1"
                            readonly
                            value="{{
                              vegetables?.marrow == true ? 'Yes' :  '-'
                            }}"
                          />
                        </div>
                      </div>

                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div
                          class="form-group d-flex flex-column align-items-start"
                        >
                          <label for>Remark</label>
                          <input
                            type="text"
                            readonly
                            class="form-control flex-grow-1"
                            readonly
                            value="{{ vegetables?.marrow_remark ?vegetables?.marrow_remark: '-' }}"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div
                          class="form-group d-flex flex-column align-items-start"
                        >
                          <label for>Eggplant</label>
                          <input
                            type="text"
                            readonly
                            class="form-control flex-grow-1"
                            readonly
                            value="{{
                              vegetables?.eggplant == true ? 'Yes' : '-'
                            }}"
                          />
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div
                          class="form-group d-flex flex-column align-items-start"
                        >
                          <label for>Remark</label>
                          <input
                            type="text"
                            readonly
                            class="form-control flex-grow-1"
                            readonly
                            value="{{ vegetables?.eggplant_remark ?vegetables?.eggplant_remark: '-' }}"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div
                          class="form-group d-flex flex-column align-items-start"
                        >
                          <label for>Leafy Veggies</label>
                          <input
                            type="text"
                            readonly
                            class="form-control flex-grow-1"
                            readonly
                            value="{{
                              vegetables?.leafy_veggies == true
                                ? 'Yes'
                                :  '-'
                            }}"
                          />
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div
                          class="form-group d-flex flex-column align-items-start"
                        >
                          <label for>Remark</label>
                          <input
                            type="text"
                            readonly
                            class="form-control flex-grow-1"
                            readonly
                            value="{{
                              vegetables?.leafy_veggies_remark ?vegetables?.leafy_veggies_remark: '-'
                            }}"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div
                          class="form-group d-flex flex-column align-items-start"
                        >
                          <label for>Cruciferous Veggies</label>
                          <input
                            type="text"
                            readonly
                            class="form-control flex-grow-1"
                            readonly
                            value="{{
                              vegetables?.cruciferious == true
                                ? 'Yes'
                                : '-'
                            }}"
                          />
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div
                          class="form-group d-flex flex-column align-items-start"
                        >
                          <label for>Remark</label>
                          <input
                            type="text"
                            readonly
                            class="form-control flex-grow-1"
                            readonly
                            value="{{ vegetables?.cruciferious_remark ?vegetables?.cruciferious_remark: '-' }}"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div
                          class="form-group d-flex flex-column align-items-start"
                        >
                          <label for>Others</label>
                          <input
                            type="text"
                            readonly
                            class="form-control flex-grow-1"
                            readonly
                            value="{{ vegetables?.other ? vegetables?.other : '-' }}"
                          />
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div
                          class="form-group d-flex flex-column align-items-start"
                        >
                          <label for>Remark</label>
                          <input
                            type="text"
                            readonly
                            class="form-control flex-grow-1"
                            readonly
                            value="{{ vegetables?.other_remark ? vegetables?.other_remark : '-' }}"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- vegetables end -->

                <!-- dairy foods -->

                <div class="form-group">
                  <label>Dairy Foods</label>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Yoghurt</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            dairy_food?.yogurt == true ? 'Yes' :  '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ dairy_food?.yogurt_remark ? dairy_food?.yogurt_remark : '-' }}"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Cheese</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            dairy_food?.cheese == true ? 'Yes' :  '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ dairy_food?.cheese_remark ?  dairy_food?.cheese_remark : '-' }}"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Butter</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            dairy_food?.butter == true ? 'Yes' :  '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ dairy_food?.butter_remark ? dairy_food?.butter_remark: '-' }}"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Others</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ dairy_food?.other ?dairy_food?.other: '-' }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ dairy_food?.other_remark ?dairy_food?.other_remark: '-' }}"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- dairy foods end -->

                <!-- proteins -->

                <div class="form-group">
                  <label>Proteins</label>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Chicken</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            protein?.chicken == true ? 'Yes' : '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ protein?.chicken_remark ?protein?.chicken_remark : '-' }}"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Turkey</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            protein?.turkey == true ? 'Yes' :  '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ protein?.turkey_remark ?protein?.turkey_remark : '-' }}"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Beef</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            protein?.beef == true ? 'Yes' : '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ protein?.beef_remark ?protein?.beef_remark :'-' }}"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Fish</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            protein?.fish == true ? 'Yes' :  '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ protein?.fish_remark ?protein?.fish_remark: '-' }}"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Eggs</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            protein?.eggs == true ? 'Yes' :  '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ protein?.eggs_remark ? protein?.eggs_remark: '-' }}"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Others</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ protein?.other ? protein?.other : '-' }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ protein?.other_remark ?protein?.other_remark: '-' }}"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- proteins end -->

                <!-- sleeping -->

                <h3 class="fw-bold">Sleeping</h3>
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label for="wakeUpTime">Wake up</label>
                    </div>
                  </div>

                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label for="time">Time</label>
                      <input
                        type="text"
                        class="form-control"
                        readonly
                        id="time"
                        value="{{
                          sleeping?.wakeup_time ?(sleeping?.wakeup_time | date : 'h:mm:ss a' ): '-'
                        }}"
                      />
                      <!-- formula?.feeding_time?(formula?.feeding_time | date : 'HH:mm:ss' ): '-' -->
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label for="wakeUpTime">Naps</label>
                    </div>
                  </div>

                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label for="time">Time</label>
                      <input
                        type="text"
                        class="form-control"
                        readonly
                        id="time"
                        value="{{
                         sleeping?.nap_time?( sleeping?.nap_time | date : 'h:mm:ss a'): '-'
                        }}"
                      />
                    </div>
                  </div>

                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label for="duration">Duration</label>
                      <input
                        type="text"
                        class="form-control"
                        readonly
                        id="duration"
                        value="{{ sleeping?.nap_duration ? sleeping?.nap_duration : '-' }}"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label for="wakeUpTime">Sleep time</label>
                    </div>
                  </div>

                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label for="time">Time</label>
                      <input
                        type="text"
                        class="form-control"
                        readonly
                        id="time"
                        value="{{
                          sleeping?.sleep_time?(sleeping?.sleep_time | date : 'h:mm:ss a' ): '-'
                        }}"
                      />
                    </div>
                  </div>

                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label for="duration">Duration</label>
                      <input
                        type="text"
                        class="form-control"
                        readonly
                        id="duration"
                        value="{{ sleeping?.sleep_duration ? sleeping?.sleep_duration: '-' }}"
                      />
                    </div>
                  </div>
                </div>

                <!-- sleep training -->

                <h3>Sleep Training</h3>
                <div class="form-group">
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Cry Out Method</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            sleep_training?.cry_method == true
                              ? 'Yes'
                              :  '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ sleep_training?.cry_method_remark ? sleep_training?.cry_method_remark: '-' }}"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Ferber Method</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            sleep_training?.ferber_method == true
                              ? 'Yes'
                              :  '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            sleep_training?.ferber_method_remark ?sleep_training?.ferber_method_remark: '-'
                          }}"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for> Check and Console Method </label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            sleep_training?.check_method == true
                              ? 'Yes'
                              : '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            sleep_training?.check_method_remark ?sleep_training?.check_method_remark:'-'
                          }}"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for> Fading/ Chair Method </label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            sleep_training?.fading_method == true
                              ? 'Yes'
                              :  '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            sleep_training?.fading_method_remark ? sleep_training?.fading_method_remark: '-'
                          }}"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>"No Tears" Method</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            sleep_training?.no_tear == true
                              ? 'Yes'
                              :  '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ sleep_training?.no_tear_remark ?sleep_training?.no_tear_remark : '-' }}"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- sleep training end -->

                <!-- bed time -->

                <h3>Bed Time</h3>
                <div class="form-group">
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Bath</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            bed_time_routine?.bath == true ? 'Yes' :  '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ bed_time_routine?.bath_remark ? bed_time_routine?.bath_remark: '-' }}"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Feed</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            bed_time_routine?.feed == true ? 'Yes' :  '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ bed_time_routine?.feed_remark ?bed_time_routine?.feed_remark: '-' }}"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Sing a song</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            bed_time_routine?.sing == true ? 'Yes' :  '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ bed_time_routine?.sing_remark ?bed_time_routine?.sing_remark: '-' }}"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Read a book aloud</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            bed_time_routine?.read_book == true
                              ? 'Yes'
                              :  '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            bed_time_routine?.read_book_remark ?bed_time_routine?.read_book_remark: '-'
                          }}"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for> Avoiding screen time </label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            bed_time_routine?.avoid_screen == true
                              ? 'Yes'
                              :  '-'
                          }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{
                            bed_time_routine?.avoid_screen_remark ?bed_time_routine?.avoid_screen_remark: '-'
                          }}"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Others</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ bed_time_routine?.other ?bed_time_routine?.other: '-' }}"
                        />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div
                        class="form-group d-flex flex-column align-items-start"
                      >
                        <label for>Remark</label>
                        <input
                          type="text"
                          readonly
                          class="form-control flex-grow-1"
                          readonly
                          value="{{ bed_time_routine?.other_remark ? bed_time_routine?.other_remark: '-' }}"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- bed time end -->

                <!-- Bathing -->
                <h3 class="fw-bold">Bathing</h3>
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Bathing Type :</label>
                      <input
                        type="text"
                        readonly
                        class="form-control"
                        value="{{ bathing?.bathing_type ?bathing?.bathing_type: '-' }}"
                      />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Frequency :</label>
                      <input
                        type="text"
                        readonly
                        class="form-control"
                        value="{{ bathing_frequency?.value ? bathing_frequency?.value: '-' }}"
                      />
                    </div>
                  </div>
                </div>

                <!-- diapering -->
                <h3 class="fw-bold">Diapering</h3>
                <div *ngFor="let daiper of diapering" class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Urine</label>
                      <input
                        type="text"
                        readonly
                        class="form-control"
                        value="{{
                          daiper?.urine == true ? 'Yes' : '-'
                        }}"
                      />
                    </div>
                    <div class="subheading">
                      <div class="form-group">
                        <label>Description</label>
                        <input
                          type="text"
                          readonly
                          class="form-control"
                          value="{{ daiper?.urine_des ?daiper?.urine_des: '-' }}"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Stool</label>
                      <input
                        type="text"
                        readonly
                        class="form-control"
                        value="{{
                          daiper?.stool == true ? 'Yes' :  '-'
                        }}"
                      />
                    </div>
                    <div class="subheading">
                      <div class="form-group">
                        <label>Description</label>
                        <input
                          type="text"
                          readonly
                          class="form-control"
                          value="{{ daiper?.stool_des ?daiper?.stool_des: '-' }}"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- diapering end -->

                <!-- temperature -->
                <h3 class="fw-bold">Temperature Monitoring</h3>
                <div class="form-group">
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <input
                        type="text"
                        readonly
                        class="form-control"
                        value="{{ temp?.temperature_monitoring ? temp?.temperature_monitoring : '-' }}"
                      />
                    </div>
                  </div>
                </div>

                <!-- vitamins -->

                <h3 class="fw-bold">Vitamins/Medications</h3>
                <div
                  *ngFor="let vitamin of vitamins_and_medications"
                  class="row"
                >
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label> Vitamins/Medications </label>
                      <input
                        type="text"
                        readonly
                        class="form-control"
                        value="{{ vitamin?.vitamin ?vitamin?.vitamin: '-' }}"
                      />
                    </div>
                  </div>
                </div>

                <!-- remarks -->

                <h3 class="fw-bold">Remarks/Notes</h3>

                <div class="row">
                  <div class="form-group">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <input
                        type="text"
                        readonly
                        class="form-control"
                        value="{{ temp?.remarks ?temp?.remarks : '-' }}"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template pTemplate="footer">
        <p-button
          (click)="displayMaximizable = false"
         
          label="Ok"
          styleClass="p-button-text"
        ></p-button>
      </ng-template>
    </p-dialog>
  </div>
  <app-copyrights></app-copyrights>
</div>
