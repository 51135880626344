<p-toast></p-toast>

<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">
    <app-dashboard-navbar></app-dashboard-navbar>
    <div class="dropdown profile-nav-item d-flex flex-column align-items-end">
        <a class="dropdown-toggle admin-btn" style="text-decoration: none;" role="button" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <div class="menu-profile">
                <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
                <span class="name">{{getname}}</span>
            </div>
        </a>
        <div class="dropdown-menu d-flex flex-column align-items-center">
            <div class="dropdown-header align">
                <div class="figure mb-3">
                    <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
                </div>
                <div class="info text-center">
                    <span class="name" style="white-space: break-spaces;">{{getname}}</span>
                    <p class="mb-3 email">{{getRole}}</p>
                </div>
            </div>
            <!-- <div class="dropdown-body">
                <ul class="profile-nav p-0 pt-3">
                    <li class="nav-item"><a routerLink="/dashboard-my-profile" class="nav-link"><i class='bx bx-user'></i> <span>Profile Dashboard</span></a></li>
                </ul>
            </div> -->
            <div class="dropdown-footer">
                <ul class="profile-nav">
                    <li class="nav-item"><a class="nav-link" (click)="logout()"><i class='bx bx-log-out'></i> <span
                                class="logout-text">Logout</span></a></li>
                </ul>
            </div>

        </div>
    </div>
    <!-- Breadcrumb Area -->

    <!-- <div class="breadcrumb-area ">
        <h1 style="display: contents">{{ (getname + ' (' + getRole + ')') | titlecase }}</h1>

    </div> -->

    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">

        <h1>{{ Content.title }}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item">
                {{ Content.subTitle }}
            </li>
            <li class="item">{{ Content.title }}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

    <!-- Start Form -->
    <!-- Baby Child Registration listing SECTION -->

    <div class="add-listings-btn mt-3">
        <app-initial-ass-list></app-initial-ass-list>
    </div>

    <!-- BABY DETAILS SECTION END-->
    <!-- DAILY CARE SECTION-->

    <!-- DAILY CARE SECTION END-->
    <!-- End Form -->
    <app-copyrights></app-copyrights>
</div>