<div class="main-content d-flex flex-column">
  <p-toast></p-toast>
  <p-confirmDialog [style]="{ width: '40vw' }" [baseZIndex]="10000"></p-confirmDialog>

  <app-dashboard-navbar></app-dashboard-navbar>
  <!-- their is title of the page -->
  <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
    <h1>{{ Content.title }}</h1>
    <ol class="breadcrumb">
      <li class="item"><a routerLink="/">Home</a></li>
      <li class="item">{{ Content.subTitle }}</li>
      <li class="item">{{ Content.title }}</li>
    </ol>
  </div>
  <div class="add-listings-box">
    <form #userForm="ngForm" (ngSubmit)="formSubmit(userForm)">
      <div class="row justify-content-between align-items-center">
        <div class="form-group col-md-4 col-sm-6 col-8">
          <label class="p-0 mb-2">
            Name
            <span class="required">*</span>
          </label>
          <input #name="ngModel" [(ngModel)]="userFormData.name" type="text" name="name" class="form-control"
            placeholder="Name" required />
          <div *ngIf="userFormData.submitted || name.touched" class="Is-invalid text-danger"
            style="font-weight: 500; font-size: 13.5px">
            <div *ngIf="name.errors?.['required']">Name is Required.</div>
          </div>
        </div>
        <div class="add-listings-btn mb-0 col-4 text-end">
          <button type="submit">Add</button>
        </div>
      </div>
    </form>
  </div>
  <div class="add-listings-box">
    <div class="Master-view-box">
      <div class="theader">
        <div class="d-flex">
          <span class="p-input-icon-left ml-auto mr-2 w-100%">
            <i class="pi pi-search"></i>
            <input [(ngModel)]="filterval" (input)="dt1.filterGlobal($event.target.value, 'contains')" pInputText
              type="text" class="form-control text-center" scriptInjection placeholder="Search Keyword" />
          </span>
          <p-button (click)="reset(dt1)" type="button" class="mx-2" icon="bx bx-reset"
            tooltipPosition="bottom"></p-button>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-12 col-md-12">
        <p-table #dt1 [value]="rolesData" [rows]="10" [showCurrentPageReport]="true"
          [tableStyle]="{ 'min-width': '50rem', width: '100%' }" [rowsPerPageOptions]="[10, 25, 50]" [loading]="loading"
          [paginator]="true" [globalFilterFields]="['name']" dataKey="id"
          styleClass="p-datatable-sm p-datatable-customers" responsiveLayout="scroll"
          currentPageReportTemplate="{totalRecords} of records" editMode="row">
          <ng-template pTemplate="header" style="min-height: 500px">
            <tr>
              <th style="max-width: 25px; text-align: center">Sr. No.</th>

              <th style="min-width: 130px" pSortableColumn="name">Name</th>
              <th style="min-width: 70px; text-align: center">Action</th>
              <th style="min-width: 70px; text-align: center">Permisssion</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-user let-i="rowIndex" let-editing="editing">
            <tr [pEditableRow]="user">
              <td style="text-align: center">{{ i + 1 }}</td>
              <td style="min-width: 130px">
                <p-cellEditor editingRowKeys="plan.id">
                  <ng-template pTemplate="input">
                    <input [(ngModel)]="user.name" pInputText type="text" scriptInjection required />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ user?.name }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td style="text-align: center; min-width: 70px" class="d-flex justify-content-center align-items-center">
                <button *ngIf="!editing" (click)="editMemberCategoryMaster(user)" pButton pRipple icon="bx bx-pencil"
                  class="p-button-rounded p-button-text p-button-success mr-2" pInitEditableRow></button>

                <!-- <button
                  *ngIf="!editing"
                  (click)="deleteMaster(urine)"
                  pButton
                  pRipple
                  icon="bx bx-trash"
                  class="p-button-rounded p-button-text p-button-danger"
                ></button> -->

                <button *ngIf="editing" (click)="onRowEditSave(user)" pButton pRipple type="button" pSaveEditableRow
                  icon="pi pi-check" class="p-button-rounded p-button-success p-button-text mr-2"></button>
                <button *ngIf="editing" (click)="onRowEditCancel(urine, i)" pButton pRipple type="button"
                  pCancelEditableRow icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text"></button>
              </td>
              <td style="text-align: center; min-width: 70px">
                <button pButton pRipple class="p-button-rounded" (click)="navigateToPermissionForRoles(user.id)"><i class="bx bx-lock"></i></button></td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="5">No Data Found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>