import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { PtServiceService } from 'src/app/services/pt-service.service';

@Component({
  selector: 'app-initial-assesseemnt-pedia',
  templateUrl: './initial-assesseemnt-pedia.component.html',
  styleUrls: ['./initial-assesseemnt-pedia.component.scss'],
  providers: [MessageService]
})
export class InitialAssesseemntPediaComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Initial Assessment Pedia',
      subTitle: 'Dashboard'
    }
  ]

  selectedDate = new Date();
  // recomended_duration = ['1 Month', '2 Months', '3 Months', '4 Months', '5 Months', '6 Months'];
  recomended_duration = [
    { month: '1 Month', id: 1 },
    { month: '2 Months', id: 2 },
    { month: '3 Months', id: 3 },
    { month: '4 Months', id: 4 },
    { month: '5 Months', id: 5 },
    { month: '6 Months', id: 6 },
  ];
  session_per_month: any = [];
  duration: any = [];
  sessionList: any = [];
  peoplelist:any;
  timelist:any;
  placelist:any;
  necklist:any;
  walklist:any;
  runlist:any;
  standlist:any;
  sittinglist:any;
  rolllist:any;
  tripodlist:any;
  walkalonelist:any;
  rideslist:any;
  upstairlist:any;
  supportlist:any;
  eighteenlist :any;
  dresslist:any;
  motherlist:any;
  waveslist:any;
  toiletlist:any;
  askforlist:any;
  anxietylist:any;
  twolist:any;
  immaturelist:any;
  maturelist:any;
  towerlist:any;
 list:any;
 crutcheslist:any;
 wheelchairlist:any;
 splintlist:any;
 remarklist:any;
 orthosislist:any;
 smilelist:any;
bidextrouslist:any;
trianglelist:any;
namelist:any;
tasklist:any;
comeslist:any;
crosslist:any;
fifteenlist:any;
transferlist:any;
otherscheck:any;
languagearray:any=[];
initialAssessementPedia: FormGroup;

  constructor(private fb: FormBuilder, private pt_service: PtServiceService, private messageService: MessageService) {
    this.initialAssessementPedia = this.fb.group({
      assesment_json:this.fb.array([]),
      date: new FormControl('',Validators.required),
      initial_assessment: new FormControl(null,Validators.required),
      history_present_illness: new FormControl(null,Validators.required),
      past_medical_history: new FormControl(null,Validators.required),
      medication: new FormControl(null),
      pain_assessment_tool: new FormControl(null),
      pain_assessment_score: new FormControl(null),
      blood_pressure: new FormControl(null),
      temperature: new FormControl(null),
      heart_rate: new FormControl(null),
      spo2: new FormControl(null),
      rr: new FormControl(null),
      attachment_PEG: new FormControl(''),
      attachment_trache: new FormControl(''),
      attachment_NGT: new FormControl(''),
      attachment_FC1: new FormControl(''),
      attachment_colostomy: new FormControl(''),
      attachment_FC2: new FormControl(''),
      attachment_ventilator: new FormControl(''),
      attachment_canula: new FormControl(''),
      attachment_NA: new FormControl(''),
      others: new FormControl(null),
      repiratory_assessment: new FormControl(null),
      cough_remark: new FormControl({
        value: null,
        disabled: true,
      }),
      chest_secretion: new FormControl(null),
      chest_secretion_remark: new FormControl({
        value: null,
        disabled: true,
      }),
      pinprickall:new FormControl(''),
      pressureall: new FormControl(''),
      blunttouchall: new FormControl(''),
      lightallvalue: new FormControl(''),
      intact: new FormControl(''),
      impaired: new FormControl(''),
      absent: new FormControl(''),
      tested: new FormControl(''),
      light_touch_remark: new FormControl(null),
      blunt_remark: new FormControl(null),
      pressure_remark: new FormControl(null),
      pin_remark: new FormControl(null),
      oriented_people: new FormControl(''),
      oriented_people_remark: new FormControl({
        value: null,
        disabled: true,
      }),
      oriented_time: new FormControl(''),
      oriented_time_remark: new FormControl({
        value: null,
        disabled: true,
      }),
      oriented_place: new FormControl(''),
      oriented_place_remark: new FormControl({
        value: null,
        disabled: true,
      }),
      swelling: new FormControl(null),
      swelling_remark: new FormControl({
        value: null,
        disabled: true,
      }),
      scar: new FormControl(null),
      scar_remark: new FormControl({
        value: null,
        disabled: true,
      }),
      worm: new FormControl(null),
      worm_remark: new FormControl({
        value: null,
        disabled: true,
      }),
      infection: new FormControl(null),
      infection_remark: new FormControl({
        value: null,
        disabled: true,
      }),
      wound: new FormControl(null),
      wound_remark: new FormControl({
        value: null,
        disabled: true,
      }),
      pain: new FormControl(null),
      pain_remark: new FormControl({
        value: null,
        disabled: true,
      }),
      others_soft_tissue: new FormControl(null),
      others_remark: new FormControl({
        value: null,
        disabled: true,
      }),
      neck_flexion_passive_r: new FormControl(''),
      neck_flexion_passive_l: new FormControl(''),
      neck_flexion_active_r: new FormControl(''),
      neck_flexion_active_l: new FormControl(''),
      neck_flexion_comment: new FormControl(null),
      neck_flexion_muscle_r: new FormControl(''),
      neck_flexion_muscle_l: new FormControl(''),
      neck_extension_passive_r: new FormControl(''),
      neck_extension_passive_l: new FormControl(''),
      neck_extension_active_r: new FormControl(''),
      neck_extension_active_l: new FormControl(''),
      neck_extension_muscle_r: new FormControl(''),
      neck_extension_muscle_l: new FormControl(''),
      neck_rotation_passive_r: new FormControl(''),
      neck_rotation_passive_l: new FormControl(''),
      neck_rotation_active_r: new FormControl(''),
      neck_rotation_active_l: new FormControl(''),
      neck_rotation_comment: new FormControl(null),
      neck_rotation_muscle_r: new FormControl(''),
      neck_rotation_muscle_l: new FormControl(''),
      neck_extension_comment: new FormControl(null),
      neck_sideFlex_passive_r: new FormControl(''),
      neck_sideFlex_passive_l: new FormControl(''),
      neck_sideFlex_active_r: new FormControl(''),
      neck_sideFlex_active_l: new FormControl(''),
      neck_sideFlex_comment: new FormControl(null),
      neck_sideFlex_muscle_l: new FormControl(''),
      shoulder_flexion_passive_r: new FormControl(''),
      shoulder_flexion_passive_l: new FormControl(''),
      shoulder_flexion_active_r: new FormControl(''),
      shoulder_flexion_active_l: new FormControl(''),
      shoulder_flexion_comment: new FormControl(null),
      shoulder_flexion_muscle_r: new FormControl(''),
      shoulder_flexion_muscle_l: new FormControl(''),
      neck_sideFlex_muscle_r: new FormControl(''),
      shoulder_extension_passive_r: new FormControl(''),
      shoulder_extension_passive_l: new FormControl(''),
      shoulder_extension_active_r: new FormControl(''),
      shoulder_extension_active_l: new FormControl(''),
      shoulder_extension_comment: new FormControl(null),
      shoulder_extension_muscle_r: new FormControl(''),
      shoulder_extension_muscle_l: new FormControl(''),
      shoulder_abduction_passive_r: new FormControl(''),
      shoulder_abduction_passive_l: new FormControl(''),
      shoulder_abduction_active_r: new FormControl(''),
      shoulder_abduction_active_l: new FormControl(''),
      shoulder_abduction_comment: new FormControl(null),
      shoulder_abduction_muscle_r: new FormControl(''),
      shoulder_abduction_muscle_l: new FormControl(''),
      shoulder_adduction_passive_r: new FormControl(''),
      shoulder_adduction_passive_l: new FormControl(''),
      shoulder_adduction_active_r: new FormControl(''),
      shoulder_adduction_active_l: new FormControl(''),
      shoulder_adduction_comment: new FormControl(null),
      shoulder_adduction_muscle_r: new FormControl(''),
      shoulder_adduction_muscle_l: new FormControl(''),
      shoulder_internal_rotation_passive_r: new FormControl(''),
      shoulder_internal_rotation_passive_l: new FormControl(''),
      shoulder_internal_rotation_active_r: new FormControl(''),
      shoulder_internal_rotation_active_l: new FormControl(''),
      shoulder_internal_rotation_comment: new FormControl(null),
      shoulder_internal_rotation_muscle_r: new FormControl(''),
      shoulder_internal_rotation_muscle_l: new FormControl(''),
      shoulder_external_rotation_passive_r: new FormControl(''),
      shoulder_external_rotation_passive_l: new FormControl(''),
      shoulder_external_rotation_active_r: new FormControl(''),
      shoulder_external_rotation_active_l: new FormControl(''),
      shoulder_external_rotation_comment: new FormControl(null),
      shoulder_external_rotation_muscle_r: new FormControl(''),
      shoulder_external_rotation_muscle_l: new FormControl(''),
      elbow_flexion_passive_r: new FormControl(''),
      elbow_flexion_passive_l: new FormControl(''),
      elbow_flexion_active_r: new FormControl(''),
      elbow_flexion_active_l: new FormControl(''),
      elbow_flexion_comment: new FormControl(null),
      elbow_flexion_muscle_r: new FormControl(''),
      elbow_flexion_muscle_l: new FormControl(''),
      elbow_extension_passive_r: new FormControl(''),
      elbow_extension_passive_l: new FormControl(''),
      elbow_extension_active_r: new FormControl(''),
      elbow_extension_active_l: new FormControl(''),
      elbow_extension_comment: new FormControl(null),
      elbow_extension_muscle_r: new FormControl(''),
      elbow_extension_muscle_l: new FormControl(''),
      elbow_supination_passive_r: new FormControl(''),
      elbow_supination_passive_l: new FormControl(''),
      elbow_supination_active_r: new FormControl(''),
      elbow_supination_active_l: new FormControl(''),
      elbow_supination_comment: new FormControl(null),
      elbow_supination_muscle_r: new FormControl(''),
      elbow_supination_muscle_l: new FormControl(''),
      elbow_pronation_passive_r: new FormControl(''),
      elbow_pronation_passive_l: new FormControl(''),
      elbow_pronation_active_r: new FormControl(''),
      elbow_pronation_active_l: new FormControl(''),
      elbow_pronation_comment: new FormControl(null),
      elbow_pronation_muscle_r: new FormControl(''),
      elbow_pronation_muscle_l: new FormControl(null),
      wrist_flexion_passive_r: new FormControl(''),
      wrist_flexion_passive_l: new FormControl(''),
      wrist_flexion_active_r: new FormControl(''),
      wrist_flexion_active_l: new FormControl(''),
      wrist_flexion_comment: new FormControl(null),
      wrist_flexion_muscle_r: new FormControl(''),
      wrist_flexion_muscle_l: new FormControl(''),
      wrist_extension_passive_r: new FormControl(''),
      wrist_extension_passive_l: new FormControl(''),
      wrist_extension_active_r: new FormControl(''),
      wrist_extension_active_l: new FormControl(''),
      wrist_extension_comment: new FormControl(null),
      wrist_extension_muscle_r: new FormControl(''),
      wrist_extension_muscle_l: new FormControl(''),
      wrist_ulnar_deviation_passive_r: new FormControl(''),
      wrist_ulnar_deviation_passive_l: new FormControl(''),
      wrist_ulnar_deviation_active_r: new FormControl(''),
      wrist_ulnar_deviation_active_l: new FormControl(''),
      wrist_ulnar_deviation_comment: new FormControl(null),
      wrist_ulnar_deviation_muscle_r: new FormControl(''),
      wrist_ulnar_deviation_muscle_l: new FormControl(''),
      wrist_radial_deviation_passive_r: new FormControl(''),
      wrist_radial_deviation_passive_l: new FormControl(''),
      wrist_radial_deviation_active_r: new FormControl(''),
      wrist_radial_deviation_active_l: new FormControl(''),
      wrist_radial_deviation_comment: new FormControl(null),
      wrist_radial_deviation_muscle_r: new FormControl(''),
      wrist_radial_deviation_muscle_l: new FormControl(''),
      digits_thump_passive_r: new FormControl(''),
      digits_thump_passive_l: new FormControl(''),
      digits_thump_active_r: new FormControl(''),
      digits_thump_active_l: new FormControl(''),
      digits_thump_comment: new FormControl(null),
      digits_thump_muscle_r: new FormControl(''),
      digits_thump_muscle_l: new FormControl(''),
      digits_thump_ip_passive_r: new FormControl(''),
      digits_thump_ip_passive_l: new FormControl(''),
      digits_thump_ip_active_r: new FormControl(''),
      digits_thump_ip_active_l: new FormControl(''),
      digits_thump_ip_comment: new FormControl(null),
      digits_thump_ip_muscle_r: new FormControl(''),
      digits_thump_ip_muscle_l: new FormControl(''),
      digits_mcp_passive_r: new FormControl(''),
      digits_mcp_passive_l: new FormControl(''),
      digits_mcp_active_r: new FormControl(''),
      digits_mcp_active_l: new FormControl(''),
      digits_mcp_comment: new FormControl(null),
      digits_mcp_muscle_r: new FormControl(),
      digits_mcp_muscle_l: new FormControl(''),
      digits_pip_passive_r: new FormControl(''),
      digits_pip_passive_l: new FormControl(''),
      digits_pip_active_r: new FormControl(''),
      digits_pip_active_l: new FormControl(''),
      digits_pip_comment: new FormControl(null),
      digits_pip_muscle_r: new FormControl(''),
      digits_pip_muscle_l: new FormControl(''),
      digits_dip_passive_r: new FormControl(''),
      digits_dip_passive_l: new FormControl(''),
      digits_dip_active_r: new FormControl(''),
      digits_dip_active_l: new FormControl(''),
      digits_dip_comment: new FormControl(null),
      digits_dip_muscle_r: new FormControl(''),
      digits_dip_muscle_l: new FormControl(''),
      hip_flexion_passive_r: new FormControl(''),
      hip_flexion_passive_l: new FormControl(''),
      hip_flexion_active_r: new FormControl(''),
      hip_flexion_active_l: new FormControl(''),
      hip_flexion_comment: new FormControl(null),
      hip_flexion_muscle_r: new FormControl(''),
      hip_flexion_muscle_l: new FormControl(''),
      hip_extension_passive_r: new FormControl(''),
      hip_extension_passive_l: new FormControl(''),
      hip_extension_active_r: new FormControl(''),
      hip_extension_active_l: new FormControl(''),
      hip_extension_comment: new FormControl(null),
      hip_extension_muscle_r: new FormControl(''),
      hip_extension_muscle_l: new FormControl(''),
      hip_abduction_passive_r: new FormControl(''),
      hip_abduction_passive_l: new FormControl(''),
      hip_abduction_active_r: new FormControl(''),
      hip_abduction_active_l: new FormControl(''),
      hip_abduction_comment: new FormControl(null),
      hip_abduction_muscle_r: new FormControl(''),
      hip_abduction_muscle_l: new FormControl(''),
      hip_adduction_passive_r: new FormControl(''),
      hip_adduction_passive_l: new FormControl(''),
      hip_adduction_active_r: new FormControl(''),
      hip_adduction_active_l: new FormControl(''),
      hip_adduction_comment: new FormControl(null),
      hip_adduction_muscle_r: new FormControl(''),
      hip_adduction_muscle_l: new FormControl(''),
      hip_internal_rotation_passive_r: new FormControl(''),
      hip_internal_rotation_passive_l: new FormControl(''),
      hip_internal_rotation_active_r: new FormControl(''),
      hip_internal_rotation_active_l: new FormControl(''),
      hip_internal_rotation_comment: new FormControl(null),
      hip_internal_rotation_muscle_r: new FormControl(''),
      hip_internal_rotation_muscle_l: new FormControl(''),
      hip_external_rotation_passive_r: new FormControl(''),
      hip_external_rotation_passive_l: new FormControl(''),
      hip_external_rotation_active_r: new FormControl(''),
      hip_external_rotation_active_l: new FormControl(''),
      hip_external_rotation_comment: new FormControl(null),
      hip_external_rotation_muscle_r: new FormControl(''),
      hip_external_rotation_muscle_l: new FormControl(''),
      knee_flexion_passive_r: new FormControl(''),
      knee_flexion_passive_l: new FormControl(''),
      knee_flexion_active_r: new FormControl(''),
      knee_flexion_active_l: new FormControl(''),
      knee_flexion_comment: new FormControl(null),
      knee_flexion_muscle_r: new FormControl(''),
      knee_flexion_muscle_l: new FormControl(''),
      knee_extension_passive_r: new FormControl(''),
      knee_extension_passive_l: new FormControl(''),
      knee_extension_active_r: new FormControl(''),
      knee_extension_active_l: new FormControl(''),
      knee_extension_comment: new FormControl(null),
      knee_extension_muscle_r: new FormControl(''),
      knee_extension_muscle_l: new FormControl(''),
      ankle_pf_passive_r: new FormControl(''),
      ankle_pf_passive_l: new FormControl(''),
      ankle_pf_active_r: new FormControl(''),
      ankle_pf_active_l: new FormControl(''),
      ankle_pf_comment: new FormControl(null),
      ankle_pf_muscle_r: new FormControl(''),
      ankle_pf_muscle_l: new FormControl(''),
      ankle_df_passive_r: new FormControl(''),
      ankle_df_passive_l: new FormControl(''),
      ankle_df_active_r: new FormControl(''),
      ankle_df_active_l: new FormControl(''),
      ankle_df_comment: new FormControl(null),
      ankle_df_muscle_r: new FormControl(''),
      ankle_df_muscle_l: new FormControl(''),
      knee_inversion_passive_r: new FormControl(''),
      knee_inversion_passive_l: new FormControl(''),
      knee_inversion_active_r: new FormControl(''),
      knee_inversion_active_l: new FormControl(''),
      knee_inversion_comment: new FormControl(null),
      knee_inversion_muscle_r: new FormControl(''),
      knee_inversion_muscle_l: new FormControl(''),
      knee_eversion_passive_r: new FormControl(''),
      knee_eversion_passive_l: new FormControl(''),
      knee_eversion_active_r: new FormControl(''),
      knee_eversion_active_l: new FormControl(''),
      knee_eversion_comment: new FormControl(null),
      knee_eversion_muscle_r: new FormControl(''),
      knee_eversion_muscle_l: new FormControl(''),
      extermeties_shoulder_muscle_tone_l: new FormControl(null),
      extermeties_shoulder_muscle_tone_r: new FormControl(null),
      extermeties_shoulder_muscle_tone_remark: new FormControl(null),
      extermeties_elbow_muscle_tone_l: new FormControl(null),
      extermeties_elbow_muscle_tone_r: new FormControl(null),
      extermeties_elbow_muscle_tone_remark: new FormControl(null),
      extermeties_wrist_muscle_tone_l: new FormControl(null),
      extermeties_wrist_muscle_tone_r: new FormControl(null),
      extermeties_wrist_muscle_tone_remark: new FormControl(null),
      extermeties_hip_muscle_tone_l: new FormControl(null),
      extermeties_hip_muscle_tone_r: new FormControl(null),
      extermeties_hip_muscle_tone_remark: new FormControl(null),
      extermeties_knee_muscle_tone_l: new FormControl(null),
      extermeties_knee_muscle_tone_r: new FormControl(null),
      extermeties_knee_muscle_tone_remark: new FormControl(null),
      extermeties_ankle_muscle_tone_l: new FormControl(null),
      extermeties_ankle_muscle_tone_r: new FormControl(null),
      extermeties_ankle_muscle_tone_remark: new FormControl(null),
      extermeties_trunk_muscle_tone_l: new FormControl(null),
      extermeties_trunk_muscle_tone_r: new FormControl(null),
      extermeties_trunk_muscle_tone_remark: new FormControl(null),
      three_months_neck: new FormControl(null),
      three_months_neck_remark: new FormControl(null),
      five_months_roll_over: new FormControl(null),
      five_months_roll_over_remark: new FormControl(null),
      six_months_tripod_fashion: new FormControl(null),
      six_months_tripod_fashion_remark: new FormControl(null),
      eight_months_sitting: new FormControl(null),
      eight_months_sitting_remark: new FormControl(null),
      nine_months_stand: new FormControl(null),
      nine_months_stand_remark: new FormControl(null),
      twelve_months_stand: new FormControl(null),
      twelve_months_stand_remark: new FormControl(null),
      fifteen_months_walk: new FormControl(null),
      fifteen_months_walk_remark: new FormControl(null),
      eighteen_months_run: new FormControl(null),
      eighteen_months_run_remark: new FormControl(null),
      two_years_walk_up: new FormControl(null),
      two_years_walk_up_remark: new FormControl(null),
      three_years_rides: new FormControl(''),
      three_years_rides_remark: new FormControl(null),
      four_years_upstairs: new FormControl(''),
      four_years_upstairs_remark: new FormControl(null),
      four_months_bidextrous: new FormControl(''),
      four_months_bidextrous_remark: new FormControl(null),
      six_months_transfer: new FormControl(''),
      six_months_transfer_remark: new FormControl(null),
      nine_months_immature: new FormControl(''),
      nine_months_immature_remark: new FormControl(null),
      twelve_months_mature: new FormControl(''),
      twelve_months_mature_remark: new FormControl(null),
      fifteen_months_tower: new FormControl(''),
      fifteen_months_tower_remark: new FormControl(null),
      eighteen_months_tower: new FormControl(''),
      eighteen_months_tower_remark: new FormControl(null),
      two_years_tower: new FormControl(''),
      two_years_tower_remark: new FormControl(null),
      three_years_tower: new FormControl(''),
      three_years_tower_remark: new FormControl(null),
      four_years_cross: new FormControl(''),
      four_years_cross_remark: new FormControl(null),
      five_years_triangle: new FormControl(''),
      five_years_triangle_remark: new FormControl(null),
      two_months_smile: new FormControl(''),
      two_months_smile_remark: new FormControl(null),
      three_months_reco_mother: new FormControl(''),
      three_months_reco_mother_remark: new FormControl(null),
      four_months_anxiety: new FormControl(''),
      four_months_anxiety_remark: new FormControl(null),
      nine_months_waves: new FormControl(''),
      nine_months_waves_remark: new FormControl(null),
      twelve_months_comes: new FormControl(''),
      twelve_months_comes_remark: new FormControl(null),
      eighteen_months_task: new FormControl(''),
      eighteen_months_task_remark: new FormControl(null),
      two_years_askfor: new FormControl(''),
      two_years_askfor_remark: new FormControl(null),
      three_years_name: new FormControl(''),
      three_years_name_remark: new FormControl(null),
      four_years_toilet: new FormControl(''),
      four_years_toilet_remark: new FormControl(null),
      five_years_dress: new FormControl(''),
      five_years_dress_remark: new FormControl(null),
      language_normal: new FormControl(null),
      language_delayed: new FormControl(null),
      language_remark: new FormControl(null),
      orthosis: new FormControl(''),
      orthosis_remark: new FormControl(null),
      splint: new FormControl(''),
      splint_remark: new FormControl(null),
      wheelchair: new FormControl(''),
      wheelchair_remark: new FormControl(null),
      cruthces: new FormControl(''),
      cruthces_remark: new FormControl(null),
      other: new FormControl(null),
      other_remark: new FormControl(null),
      problems_noted: new FormControl(null),
      short_term: new FormControl(''),
      short_term_session: new FormControl(null),
      long_term: new FormControl(),
      long_term_session: new FormControl(null),
      transfer_assisting_walker: new FormControl(''),
      // neck_rotation_comment: new FormControl(null),
      scope_session: new FormControl(null),
      recommended_duration: new FormControl(null),
      no_pt_sesssion_per_month: new FormControl(null),
      goals_acheived: new FormControl(null),
      exercise_checkbox: new FormControl(''),
      exercise_duration: new FormControl(null),
      exercise_details: new FormControl(null),
      tens_checkbox: new FormControl(''),
      tens_duration: new FormControl(null),
      tens_details: new FormControl(null),
      ultrasound_checkbox: new FormControl(''),
      ultrasound_duration: new FormControl(null),
      ultrasound_details: new FormControl(null),
      hot_packs_checkbox: new FormControl(''),
      hot_packs_duration: new FormControl(null),
      hot_packs_details: new FormControl(null),
      stimulation_checkbox: new FormControl(''),
      stimulation_duration: new FormControl(null),
      stimulation_details: new FormControl(null),
      vibrator_checkbox: new FormControl(''),
      vibrator_duration: new FormControl(null),
      vibrator_details: new FormControl(null),
      tapping_checkbox: new FormControl(''),
      tapping_duration: new FormControl(null),
      tapping_details: new FormControl(null),
      chest_therapy_checkbox: new FormControl(''),
      chest_therapy_duration: new FormControl(null),
      chest_therapy_details: new FormControl(null),
      session_technique_others_checkbox: new FormControl(''),
      session_technique_others_details: new FormControl(null),
      re_date: new FormControl(''),
      others_box: new FormControl(''),

    })
  }

  ngOnInit(): void {
    for (let i = 1; i <= 30; i++) {
      this.session_per_month.push({ session_per_month: i + " sessions" });
    }
    for (let i = 1; i <= 24; i++) {
      this.duration.push({ duration: i });
    }
    this.pt_service.ptGetScopeSession().then((res: any) => {
      console.log(res);
      this.sessionList = res?.data;
    })
    this.addMedication('add', 0);
    this.initialAssessementPedia.controls.exercise_duration.disable();
    this.initialAssessementPedia.controls.exercise_details.disable();
    this.initialAssessementPedia.controls.tens_duration.disable();
    this.initialAssessementPedia.controls.tens_details.disable();
    this.initialAssessementPedia.controls.stimulation_details.disable();
    this.initialAssessementPedia.controls.stimulation_duration.disable();
    this.initialAssessementPedia.controls.chest_therapy_details.disable();
    this.initialAssessementPedia.controls.chest_therapy_duration.disable();
    this.initialAssessementPedia.controls.hot_packs_duration.disable();
    this.initialAssessementPedia.controls.hot_packs_details.disable();
    this.initialAssessementPedia.controls.tapping_details.disable();
    this.initialAssessementPedia.controls.tapping_duration.disable();
    this.initialAssessementPedia.controls.vibrator_duration.disable();
    this.initialAssessementPedia.controls.vibrator_details.disable();
    this.initialAssessementPedia.controls.ultrasound_duration.disable();
    this.initialAssessementPedia.controls.ultrasound_details.disable();
    this.initialAssessementPedia.controls.session_technique_others_details.disable();
    this.initialAssessementPedia.controls.three_months_neck_remark.disable();
    this.initialAssessementPedia.controls.five_months_roll_over_remark.disable();
    this.initialAssessementPedia.controls.six_months_tripod_fashion_remark.disable();
    this.initialAssessementPedia.controls.eight_months_sitting_remark.disable();
    this.initialAssessementPedia.controls.nine_months_stand_remark.disable();
    this.initialAssessementPedia.controls.twelve_months_stand_remark.disable();
    this.initialAssessementPedia.controls.fifteen_months_walk_remark.disable();
    this.initialAssessementPedia.controls.eighteen_months_run_remark.disable();
    this.initialAssessementPedia.controls.two_years_walk_up_remark.disable();
    this.initialAssessementPedia.controls.three_years_rides_remark.disable();
    this.initialAssessementPedia.controls.four_years_upstairs_remark.disable();
    this.initialAssessementPedia.controls.four_months_bidextrous_remark.disable();
    this.initialAssessementPedia.controls.six_months_transfer_remark.disable();
    this.initialAssessementPedia.controls.nine_months_immature_remark.disable();
    this.initialAssessementPedia.controls.twelve_months_mature_remark.disable();
    this.initialAssessementPedia.controls.fifteen_months_tower_remark.disable();
    this.initialAssessementPedia.controls.eighteen_months_tower_remark.disable();
    this.initialAssessementPedia.controls.two_years_tower_remark.disable();
    this.initialAssessementPedia.controls.three_years_tower_remark.disable();
    this.initialAssessementPedia.controls.four_years_cross_remark.disable();
    this.initialAssessementPedia.controls.five_years_triangle_remark.disable();
    this.initialAssessementPedia.controls.two_months_smile_remark.disable();
    this.initialAssessementPedia.controls.three_months_reco_mother_remark.disable();
    this.initialAssessementPedia.controls.four_months_anxiety_remark.disable();
    this.initialAssessementPedia.controls.nine_months_waves_remark.disable();
    this.initialAssessementPedia.controls.twelve_months_comes_remark.disable();
    this.initialAssessementPedia.controls.eighteen_months_task_remark.disable();
    this.initialAssessementPedia.controls.two_years_askfor_remark.disable();
    this.initialAssessementPedia.controls.three_years_name_remark.disable();
    this.initialAssessementPedia.controls.four_years_toilet_remark.disable();
    this.initialAssessementPedia.controls.five_years_dress_remark.disable();
    this.initialAssessementPedia.controls.orthosis_remark.disable();
    this.initialAssessementPedia.controls.cruthces_remark.disable();
    this.initialAssessementPedia.controls.splint_remark.disable();
    this.initialAssessementPedia.controls.wheelchair_remark.disable();
    this.initialAssessementPedia.controls.other_remark.disable();
    this.initialAssessementPedia.controls.short_term_session.disable();
    this.initialAssessementPedia.controls.long_term_session.disable();
    this.initialAssessementPedia.controls.pin_remark.disable();
    this.initialAssessementPedia.controls.light_touch_remark.disable();
    this.initialAssessementPedia.controls.blunt_remark.disable();
    this.initialAssessementPedia.controls.pressure_remark.disable();
    this.initialAssessementPedia.controls.others.disable();
    

  }
  addMedication(string: any, index: any) {
    // console.log(index);
    let vaccinationArray = this.getMedicationFormArray();
    if (string === 'add') {
      let newVaccinationArray = new FormGroup({
        medication: new FormControl(''),

      });
      let vaccinationArray = this.getMedicationFormArray();
      vaccinationArray.insert(index + 1, newVaccinationArray);
    } else {
      vaccinationArray.removeAt(index);
    }
  }
  keyPress(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
    }
}
  /* -------------------------------------------------------------------------- */
  /*                    // save Initial Assessment Pedia Form                   */
  /* -------------------------------------------------------------------------- */
  savePediaForm(pediaForm) {
    window.scroll(0, 0)
  
    this.initialAssessementPedia.controls.oriented_people.setValue(this.peoplelist);
    this.initialAssessementPedia.controls.oriented_time.setValue(this.timelist);
    this.initialAssessementPedia.controls.oriented_place.setValue(this.placelist);
    this.initialAssessementPedia.controls.three_months_neck.setValue(this.necklist);
    this.initialAssessementPedia.controls.six_months_tripod_fashion.setValue(this.tripodlist);
    this.initialAssessementPedia.controls.eight_months_sitting.setValue(this.sittinglist);
    this.initialAssessementPedia.controls.nine_months_stand.setValue(this.supportlist);
    this.initialAssessementPedia.controls.twelve_months_stand.setValue(this.standlist);
    this.initialAssessementPedia.controls.eighteen_months_run.setValue(this.runlist);
    this.initialAssessementPedia.controls.fifteen_months_walk.setValue(this.walkalonelist);
    this.initialAssessementPedia.controls.two_years_walk_up.setValue(this.walklist);
    this.initialAssessementPedia.controls.three_years_rides.setValue(this.rideslist);
    this.initialAssessementPedia.controls.four_years_upstairs.setValue(this.upstairlist);
    this.initialAssessementPedia.controls.five_months_roll_over.setValue(this.rolllist);
    this.initialAssessementPedia.controls.four_months_bidextrous.setValue(this.bidextrouslist);
    this.initialAssessementPedia.controls.six_months_transfer.setValue(this.transferlist);
    this.initialAssessementPedia.controls.nine_months_immature.setValue(this.immaturelist);
    this.initialAssessementPedia.controls.twelve_months_mature.setValue(this.maturelist);
    this.initialAssessementPedia.controls.fifteen_months_tower.setValue(this.fifteenlist);
    this.initialAssessementPedia.controls.eighteen_months_tower.setValue(this.eighteenlist);
    this.initialAssessementPedia.controls.two_years_tower.setValue(this.twolist);
    this.initialAssessementPedia.controls.three_years_tower.setValue(this.towerlist);
    this.initialAssessementPedia.controls.four_years_cross.setValue(this.crosslist);
    this.initialAssessementPedia.controls.five_years_triangle.setValue(this.trianglelist);
    this.initialAssessementPedia.controls.two_months_smile.setValue(this.smilelist);
    this.initialAssessementPedia.controls.three_months_reco_mother.setValue(this.motherlist);
    this.initialAssessementPedia.controls.four_months_anxiety.setValue(this.anxietylist);
    this.initialAssessementPedia.controls.nine_months_waves.setValue(this.waveslist);
    this.initialAssessementPedia.controls.twelve_months_comes.setValue(this.comeslist);
    this.initialAssessementPedia.controls.eighteen_months_task.setValue(this.tasklist);
    this.initialAssessementPedia.controls.two_years_askfor.setValue(this.askforlist);
    this.initialAssessementPedia.controls.three_years_name.setValue(this.namelist);
    this.initialAssessementPedia.controls.four_years_toilet.setValue(this.toiletlist);
    this.initialAssessementPedia.controls.five_years_dress.setValue(this.dresslist);
    this.initialAssessementPedia.controls.orthosis.setValue(this.orthosislist);
    this.initialAssessementPedia.controls.cruthces.setValue(this.crutcheslist);
    this.initialAssessementPedia.controls.other.setValue(this.remarklist);
    this.initialAssessementPedia.controls.splint.setValue(this.splintlist);
    this.initialAssessementPedia.controls.wheelchair.setValue(this.wheelchairlist);
    // this.initialAssessementPedia.controls.others.setValue(this.otherscheck);


    if (pediaForm.valid) {
      let stringifyData = { ...this.initialAssessementPedia.value };
      delete stringifyData.date
      let formData = {
        pedia_assessment_json: JSON.stringify(stringifyData),
        date: moment(this.initialAssessementPedia.value.date).format('YYYY/MM/DD'),
      }
      this.pt_service.ptPediaAssessment(formData).then((res: any) => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: res?.message });
        pediaForm.reset();
        
        this.peoplelist=''
        this.placelist=''
        this.timelist=''
        this.necklist=''
        this.walkalonelist=''
        this.walklist=''
        this.supportlist=''
        this.sittinglist=''
        this.standlist=''
        this.rideslist=''
        this.upstairlist=''
        this.rolllist=''
        this.dresslist=''
        this.toiletlist=''
        this.namelist=''
        this.askforlist=''
        this.tasklist=''
        this.comeslist=''
        this.waveslist=''
        this.anxietylist=''
        this.motherlist=''
        this.smilelist=''
        this.trianglelist=''
        this.crosslist=''
        this.towerlist=''
        this.runlist=''
        this.twolist=''
        this.eighteenlist=''
        this.fifteenlist=''
        this.maturelist=''
        this.immaturelist=''
        this.transferlist=''
        this.fifteenlist=''
        this.orthosislist=''
        this.crutcheslist=''
        this.splintlist=''
        this.remarklist=''
        this.wheelchairlist=''
        

      })
      .catch((error) => {
        console.log(error)
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error?.message });
      })
    }
    else {
      this.messageService.add({
        severity: 'error', summary: 'Error', detail: 'Please Fill All the Details!',
      });
    }
  }
  getMedicationFormArray() {
    return this.initialAssessementPedia.get('assesment_json') as FormArray;
  }
  handleInput(event: any, controlName: string): void {
    console.log(event.target.value)

    if(controlName == 'people'){
      // this.initialAssessementAdult.controls.oriented_people.setValue(event.target.value);
      this.peoplelist = event.target.value;
      console.log(event.target.value)
      if(event.target.value=='Yes'){
        this.initialAssessementPedia.controls.oriented_people_remark.enable();
        }
      else{
        this.initialAssessementPedia.controls.oriented_people_remark.disable();
      }
      if(event.target.value=='Yes'){
        this.initialAssessementPedia.controls['oriented_people_remark'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementPedia.controls['oriented_people_remark'].clearValidators();
        this.initialAssessementPedia.controls['oriented_people_remark'].setValue(null);
      }
      this.initialAssessementPedia.controls['oriented_people_remark'].updateValueAndValidity();
    }
    else if(controlName=='time'){
      this.timelist = event.target.value;
      console.log(event.target.value)
      if(event.target.value=='Yes'){
        this.initialAssessementPedia.controls.oriented_time_remark.enable();
        }
      else{
        this.initialAssessementPedia.controls.oriented_time_remark.disable();
      }
      if(event.target.value=='Yes'){
        this.initialAssessementPedia.controls['oriented_time_remark'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementPedia.controls['oriented_time_remark'].clearValidators();
        this.initialAssessementPedia.controls['oriented_time_remark'].setValue(null);
      }
      this.initialAssessementPedia.controls['oriented_time_remark'].updateValueAndValidity();
    }
    else if(controlName=='place'){
      this.placelist = event.target.value;
      console.log(event.target.value)
      if(event.target.value=='Yes'){
        this.initialAssessementPedia.controls.oriented_place_remark.enable();
        }
      else{
        this.initialAssessementPedia.controls.oriented_place_remark.disable();
      }
      if(event.target.value=='Yes'){
        this.initialAssessementPedia.controls['oriented_place_remark'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementPedia.controls['oriented_place_remark'].clearValidators();
        this.initialAssessementPedia.controls['oriented_place_remark'].setValue(null);
      }
      this.initialAssessementPedia.controls['oriented_place_remark'].updateValueAndValidity();
    }
    else if(controlName=='neck'){
      this.necklist = event.target.value;
      console.log(event.target.value)
      if (event.target.value == 'Yes') {

        this.initialAssessementPedia.controls.three_months_neck_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.three_months_neck_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['three_months_neck_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['three_months_neck_remark'].clearValidators();
          this.initialAssessementPedia.controls['three_months_neck_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['three_months_neck_remark'].updateValueAndValidity();


    }
    else if(controlName=='roll'){
      this.rolllist = event.target.value;
      console.log(event.target.value)
      if (event.target.value == 'Yes') {

        this.initialAssessementPedia.controls.five_months_roll_over_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.five_months_roll_over_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['five_months_roll_over_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['five_months_roll_over_remark'].clearValidators();
          this.initialAssessementPedia.controls['five_months_roll_over_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['five_months_roll_over_remark'].updateValueAndValidity();

    }
    else if(controlName=='tripod'){
      this.tripodlist = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if (event.target.value == 'Yes') {

        this.initialAssessementPedia.controls.six_months_tripod_fashion_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.six_months_tripod_fashion_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['six_months_tripod_fashion_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['six_months_tripod_fashion_remark'].clearValidators();
          this.initialAssessementPedia.controls['six_months_tripod_fashion_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['six_months_tripod_fashion_remark'].updateValueAndValidity();
    }
    else if(controlName=='stand'){
      this.standlist = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if (event.target.value == 'Yes') {

        this.initialAssessementPedia.controls.twelve_months_stand_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.twelve_months_stand_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['twelve_months_stand_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['twelve_months_stand_remark'].clearValidators();
          this.initialAssessementPedia.controls['twelve_months_stand_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['twelve_months_stand_remark'].updateValueAndValidity();
    }
    else if(controlName=='sitting'){
      this.sittinglist = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if (event.target.value == 'Yes') {

        this.initialAssessementPedia.controls.eight_months_sitting_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.eight_months_sitting_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['eight_months_sitting_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['eight_months_sitting_remark'].clearValidators();
          this.initialAssessementPedia.controls['eight_months_sitting_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['eight_months_sitting_remark'].updateValueAndValidity();
    }
    else if(controlName=='support'){
      this.supportlist = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
       if (event.target.value == 'Yes') {

        this.initialAssessementPedia.controls.nine_months_stand_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.nine_months_stand_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['nine_months_stand_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['nine_months_stand_remark'].clearValidators();
          this.initialAssessementPedia.controls['nine_months_stand_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['nine_months_stand_remark'].updateValueAndValidity();
    }
    else if(controlName=='walk alone'){
      this.walkalonelist = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if (event.target.value == 'Yes') {

        this.initialAssessementPedia.controls.fifteen_months_walk_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.fifteen_months_walk_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['fifteen_months_walk_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['fifteen_months_walk_remark'].clearValidators();
          this.initialAssessementPedia.controls['fifteen_months_walk_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['fifteen_months_walk_remark'].updateValueAndValidity();
    }
    else if(controlName == 'run'){
      this.runlist = event.target.value;
      if (event.target.value == 'Yes') {

        this.initialAssessementPedia.controls.eighteen_months_run_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.eighteen_months_run_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['eighteen_months_run_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['eighteen_months_run_remark'].clearValidators();
          this.initialAssessementPedia.controls['eighteen_months_run_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['eighteen_months_run_remark'].updateValueAndValidity();
    }
    else if(controlName=='walk'){
      this.walklist = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.initialAssessementPedia.controls.two_years_walk_up_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.two_years_walk_up_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['two_years_walk_up_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['two_years_walk_up_remark'].clearValidators();
          this.initialAssessementPedia.controls['two_years_walk_up_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['two_years_walk_up_remark'].updateValueAndValidity();
    }
    else if(controlName=='rides'){
      this.rideslist = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.initialAssessementPedia.controls.three_years_rides_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.three_years_rides_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['three_years_rides_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['three_years_rides_remark'].clearValidators();
          this.initialAssessementPedia.controls['three_years_rides_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['three_years_rides_remark'].updateValueAndValidity();
    }
    else if(controlName=='upstair'){
      this.upstairlist = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.initialAssessementPedia.controls.four_years_upstairs_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.four_years_upstairs_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['four_years_upstairs_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['four_years_upstairs_remark'].clearValidators();
          this.initialAssessementPedia.controls['four_years_upstairs_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['four_years_upstairs_remark'].updateValueAndValidity();
    }
    else if(controlName=='bidextrous'){
      this.bidextrouslist = event.target.value;
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.initialAssessementPedia.controls.four_months_bidextrous_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.four_months_bidextrous_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['four_months_bidextrous_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['four_months_bidextrous_remark'].clearValidators();
          this.initialAssessementPedia.controls['four_months_bidextrous_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['four_months_bidextrous_remark'].updateValueAndValidity();
    }
    else if(controlName=='transfer'){
      this.transferlist = event.target.value;
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.initialAssessementPedia.controls.six_months_transfer_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.six_months_transfer_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['six_months_transfer_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['six_months_transfer_remark'].clearValidators();
          this.initialAssessementPedia.controls['six_months_transfer_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['six_months_transfer_remark'].updateValueAndValidity();
    }
    else if(controlName=='immature'){
      this.immaturelist = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.initialAssessementPedia.controls.nine_months_immature_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.nine_months_immature_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['nine_months_immature_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['nine_months_immature_remark'].clearValidators();
          this.initialAssessementPedia.controls['nine_months_immature_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['nine_months_immature_remark'].updateValueAndValidity();
    }
    else if(controlName=='mature'){
      this.maturelist = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.initialAssessementPedia.controls.twelve_months_mature_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.twelve_months_mature_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['twelve_months_mature_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['twelve_months_mature_remark'].clearValidators();
          this.initialAssessementPedia.controls['twelve_months_mature_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['twelve_months_mature_remark'].updateValueAndValidity();
    }
    else if(controlName=='fifteen'){
      this.fifteenlist = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.initialAssessementPedia.controls.fifteen_months_tower_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.fifteen_months_tower_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['fifteen_months_tower_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['fifteen_months_tower_remark'].clearValidators();
          this.initialAssessementPedia.controls['fifteen_months_tower_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['fifteen_months_tower_remark'].updateValueAndValidity();
    }
    else if(controlName=='eighteen'){
      this.eighteenlist = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.initialAssessementPedia.controls.eighteen_months_tower_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.eighteen_months_tower_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['eighteen_months_tower_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['eighteen_months_tower_remark'].clearValidators();
          this.initialAssessementPedia.controls['eighteen_months_tower_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['eighteen_months_tower_remark'].updateValueAndValidity();
    }
    else if(controlName=='two'){
      this.twolist = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.initialAssessementPedia.controls.two_years_tower_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.two_years_tower_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['two_years_tower_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['two_years_tower_remark'].clearValidators();
          this.initialAssessementPedia.controls['two_years_tower_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['two_years_tower_remark'].updateValueAndValidity();
    }
    else if(controlName=='tower'){
      this.towerlist = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.initialAssessementPedia.controls.three_years_tower_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.three_years_tower_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['three_years_tower_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['three_years_tower_remark'].clearValidators();
          this.initialAssessementPedia.controls['three_years_tower_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['three_years_tower_remark'].updateValueAndValidity();
    }
    else if(controlName=='cross'){
      this.crosslist = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.initialAssessementPedia.controls.four_years_cross_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.four_years_cross_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['four_years_cross_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['four_years_cross_remark'].clearValidators();
          this.initialAssessementPedia.controls['four_years_cross_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['four_years_cross_remark'].updateValueAndValidity();
    }
    else if(controlName=='triangle'){
      this.trianglelist = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.initialAssessementPedia.controls.five_years_triangle_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.five_years_triangle_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['five_years_triangle_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['five_years_triangle_remark'].clearValidators();
          this.initialAssessementPedia.controls['five_years_triangle_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['five_years_triangle_remark'].updateValueAndValidity();
    }
    else if(controlName=='smile'){
      this.smilelist = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.initialAssessementPedia.controls.two_months_smile_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.two_months_smile_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['two_months_smile_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['two_months_smile_remark'].clearValidators();
          this.initialAssessementPedia.controls['two_months_smile_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['two_months_smile_remark'].updateValueAndValidity();
    }
    else if(controlName=='mother'){
      this.motherlist = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.initialAssessementPedia.controls.three_months_reco_mother_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.three_months_reco_mother_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['three_months_reco_mother_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['three_months_reco_mother_remark'].clearValidators();
          this.initialAssessementPedia.controls['three_months_reco_mother_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['three_months_reco_mother_remark'].updateValueAndValidity();
    }
    else if(controlName=='anxiety'){
      this.anxietylist = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.initialAssessementPedia.controls.four_months_anxiety_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.four_months_anxiety_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['four_months_anxiety_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['four_months_anxiety_remark'].clearValidators();
          this.initialAssessementPedia.controls['four_months_anxiety_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['four_months_anxiety_remark'].updateValueAndValidity();
    }
    else if(controlName=='waves'){
      this.waveslist = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.initialAssessementPedia.controls.nine_months_waves_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.nine_months_waves_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['nine_months_waves_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['nine_months_waves_remark'].clearValidators();
          this.initialAssessementPedia.controls['nine_months_waves_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['nine_months_waves_remark'].updateValueAndValidity();
    }
    else if(controlName=='comes'){
      this.comeslist = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.initialAssessementPedia.controls.twelve_months_comes_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.twelve_months_comes_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['twelve_months_comes_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['twelve_months_comes_remark'].clearValidators();
          this.initialAssessementPedia.controls['twelve_months_comes_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['twelve_months_comes_remark'].updateValueAndValidity();
    }
    else if(controlName=='task'){
      this.tasklist = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.initialAssessementPedia.controls.eighteen_months_task_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.eighteen_months_task_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['eighteen_months_task_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['eighteen_months_task_remark'].clearValidators();
          this.initialAssessementPedia.controls['eighteen_months_task_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['eighteen_months_task_remark'].updateValueAndValidity();
    }
    else if(controlName=='askfor'){
      this.askforlist = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.initialAssessementPedia.controls.two_years_askfor_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.two_years_askfor_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['two_years_askfor_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['two_years_askfor_remark'].clearValidators();
          this.initialAssessementPedia.controls['two_years_askfor_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['two_years_askfor_remark'].updateValueAndValidity();
    }
    else if(controlName=='name'){
      this.namelist = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.initialAssessementPedia.controls.three_years_name_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.three_years_name_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['three_years_name_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['three_years_name_remark'].clearValidators();
          this.initialAssessementPedia.controls['three_years_name_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['three_years_name_remark'].updateValueAndValidity();
    }
    else if(controlName=='toilet'){
      this.toiletlist = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.initialAssessementPedia.controls.four_years_toilet_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.four_years_toilet_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['four_years_toilet_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['four_years_toilet_remark'].clearValidators();
          this.initialAssessementPedia.controls['four_years_toilet_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['four_years_toilet_remark'].updateValueAndValidity();
    }
    else if(controlName=='dress'){
      this.dresslist = event.target.value;
      // this.initialAssessementAdult.controls.oriented_place.setValue(event.target.value);
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.initialAssessementPedia.controls.five_years_dress_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.five_years_dress_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['five_years_dress_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['five_years_dress_remark'].clearValidators();
          this.initialAssessementPedia.controls['five_years_dress_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['five_years_dress_remark'].updateValueAndValidity();
    }
    else if(controlName=='cruthces'){
      this.crutcheslist = event.target.value;
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.initialAssessementPedia.controls.cruthces_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.cruthces_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['cruthces_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['cruthces_remark'].clearValidators();
          this.initialAssessementPedia.controls['cruthces_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['cruthces_remark'].updateValueAndValidity();
    }
    else if(controlName=='wheelchair'){
      this.wheelchairlist = event.target.value;
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.initialAssessementPedia.controls.wheelchair_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.wheelchair_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['wheelchair_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['wheelchair_remark'].clearValidators();
          this.initialAssessementPedia.controls['wheelchair_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['wheelchair_remark'].updateValueAndValidity();
    }
    else if(controlName=='splint'){
      this.splintlist = event.target.value;
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.initialAssessementPedia.controls.splint_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.splint_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['splint_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['splint_remark'].clearValidators();
          this.initialAssessementPedia.controls['splint_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['splint_remark'].updateValueAndValidity();
    }
    else if(controlName=='remark'){
      this.remarklist = event.target.value;
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.initialAssessementPedia.controls.other_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.other_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['other_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['other_remark'].clearValidators();
          this.initialAssessementPedia.controls['other_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['other_remark'].updateValueAndValidity();
    }
    else if(controlName=='orthosis'){
      this.orthosislist = event.target.value;
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.initialAssessementPedia.controls.orthosis_remark.enable();

      }
        else {
          this.initialAssessementPedia.controls.orthosis_remark.disable();

        }
        if(event.target.value == 'Yes'){
          this.initialAssessementPedia.controls['orthosis_remark'].setValidators([Validators.required]);
        }
        else {
          this.initialAssessementPedia.controls['orthosis_remark'].clearValidators();
          this.initialAssessementPedia.controls['orthosis_remark'].setValue(null);
        }
        this.initialAssessementPedia.controls['orthosis_remark'].updateValueAndValidity();
    }
  if(controlName == 'lightall'){
    switch(event.target.value){
      case 1:
        this.initialAssessementPedia.controls.lightallvalue.setValue(1);
        break;
      case 2:
        this.initialAssessementPedia.controls.lightallvalue.setValue(2);
        break;
      case 3:
        this.initialAssessementPedia.controls.lightallvalue.setValue(3);
        break;
      case 4:
        this.initialAssessementPedia.controls.lightallvalue.setValue(4);
        break;
    }
  }
  if(controlName == 'bluntall'){
    switch(event.target.value){
      case 1:
        this.initialAssessementPedia.controls.blunttouchall.setValue(1);
        break;
      case 2:
        this.initialAssessementPedia.controls.blunttouchall.setValue(2);
        break;
      case 3:
        this.initialAssessementPedia.controls.blunttouchall.setValue(3);
        break;
      case 4:
        this.initialAssessementPedia.controls.blunttouchall.setValue(4);
        break;
    }
  }
  if(controlName == 'pressall'){
    switch(event.target.value){
      case 1:
        this.initialAssessementPedia.controls.pressureall.setValue(1);
        break;
      case 2:
        this.initialAssessementPedia.controls.pressureall.setValue(2);
        break;
      case 3:
        this.initialAssessementPedia.controls.pressureall.setValue(3);
        break;
      case 4:
        this.initialAssessementPedia.controls.pressureall.setValue(4);
        break;
    }
  }
  if(controlName == 'pinprickall1'){
    switch(event.target.value){
      case 1:
        this.initialAssessementPedia.controls.pinprickall.setValue(1);
        break;
      case 2:
        this.initialAssessementPedia.controls.pinprickall.setValue(2);
        break;
      case 3:
        this.initialAssessementPedia.controls.pinprickall.setValue(3);
        break;
      case 4:
        this.initialAssessementPedia.controls.pinprickall.setValue(4);
        break;
    }
  }
  else if (controlName == 'exercise') {
    if (event.target.checked) {
      console.log('exercise');
      //  this.exercise = !this.exercise;
      this.initialAssessementPedia.controls.exercise_duration.enable();
      this.initialAssessementPedia.controls.exercise_details.enable();
    }
      else {
        this.initialAssessementPedia.controls.exercise_duration.disable();
        this.initialAssessementPedia.controls.exercise_details.disable();
      }
      if(event.target.checked){
        this.initialAssessementPedia.controls['exercise_duration'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementPedia.controls['exercise_duration'].clearValidators();
        this.initialAssessementPedia.controls['exercise_duration'].setValue(null);
      }
      this.initialAssessementPedia.controls['exercise_duration'].updateValueAndValidity();
      if(event.target.checked){
        this.initialAssessementPedia.controls['exercise_details'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementPedia.controls['exercise_details'].clearValidators();
        this.initialAssessementPedia.controls['exercise_details'].setValue(null);
      }
      this.initialAssessementPedia.controls['exercise_details'].updateValueAndValidity();

    }
    else if (controlName === 'box') {
      if (event.target.checked) {
        this.initialAssessementPedia.controls.others.enable();
        this.initialAssessementPedia.controls['others'].setValidators([Validators.required]);
      } else {
        this.initialAssessementPedia.controls.others.disable();
        this.initialAssessementPedia.controls['others'].clearValidators();
        this.initialAssessementPedia.controls['others'].setValue(null);
      }
    
      this.initialAssessementPedia.controls['others'].updateValueAndValidity();
    }
    

  else if (controlName == 'ten') {
    if (event.target.checked) {
      console.log('exercise');
      //  this.exercise = !this.exercise;
      this.initialAssessementPedia.controls.tens_duration.enable();
      this.initialAssessementPedia.controls.tens_details.enable();
    }
      else {
        this.initialAssessementPedia.controls.tens_duration.disable();
        this.initialAssessementPedia.controls.tens_details.disable();
      }
      if(event.target.checked){
        this.initialAssessementPedia.controls['tens_duration'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementPedia.controls['tens_details'].clearValidators();
        this.initialAssessementPedia.controls['tens_details'].setValue(null);
      }
      this.initialAssessementPedia.controls['tens_duration'].updateValueAndValidity();
      if(event.target.checked){
        this.initialAssessementPedia.controls['tens_details'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementPedia.controls['tens_duration'].clearValidators();
        this.initialAssessementPedia.controls['tens_duration'].setValue(null);
      }
      this.initialAssessementPedia.controls['tens_details'].updateValueAndValidity();

  } else if (controlName == 'ultrasound') {
    if (event.target.checked) {
      console.log('exercise');
      //  this.exercise = !this.exercise;
      this.initialAssessementPedia.controls.ultrasound_duration.enable();
      this.initialAssessementPedia.controls.ultrasound_details.enable();
    }
      else {
        this.initialAssessementPedia.controls.ultrasound_details.disable();
        this.initialAssessementPedia.controls.ultrasound_duration.disable();
      }
      if(event.target.checked){
        this.initialAssessementPedia.controls['ultrasound_duration'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementPedia.controls['ultrasound_duration'].clearValidators();
        this.initialAssessementPedia.controls['ultrasound_duration'].setValue(null);
      }
      this.initialAssessementPedia.controls['ultrasound_duration'].updateValueAndValidity();
      if(event.target.checked){
        this.initialAssessementPedia.controls['ultrasound_details'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementPedia.controls['ultrasound_details'].clearValidators();
        this.initialAssessementPedia.controls['ultrasound_details'].setValue(null);
      }
      this.initialAssessementPedia.controls['ultrasound_details'].updateValueAndValidity();

  } else if (controlName == 'packs') {
    // if (event.target.value == 'hotpacks') {
    //   console.log('hotpacks');
    //   this.hotpacks = !this.hotpacks;
    // }
    if (event.target.checked) {
      console.log('exercise');
      //  this.exercise = !this.exercise;
      this.initialAssessementPedia.controls.hot_packs_details.enable();
      this.initialAssessementPedia.controls.hot_packs_duration.enable();
    }
      else {
        this.initialAssessementPedia.controls.hot_packs_details.disable();
        this.initialAssessementPedia.controls.hot_packs_duration.disable();
      }
      if(event.target.checked){
        this.initialAssessementPedia.controls['hot_packs_duration'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementPedia.controls['hot_packs_duration'].clearValidators();
        this.initialAssessementPedia.controls['hot_packs_duration'].setValue(null);
      }
      this.initialAssessementPedia.controls['hot_packs_duration'].updateValueAndValidity();
      if(event.target.checked){
        this.initialAssessementPedia.controls['hot_packs_details'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementPedia.controls['hot_packs_details'].clearValidators();
        this.initialAssessementPedia.controls['hot_packs_details'].setValue(null);
      }
      this.initialAssessementPedia.controls['hot_packs_details'].updateValueAndValidity();
  } else if (controlName == 'stimulation') {
    // if (event.target.value == 'stimulation') {
    //   console.log('stimulation');
    //   this.stimulation = !this.stimulation;
    // }
    if (event.target.checked) {
      console.log('exercise');
      //  this.exercise = !this.exercise;
      this.initialAssessementPedia.controls.stimulation_details.enable();
      this.initialAssessementPedia.controls.stimulation_duration.enable();
    }
      else {
        this.initialAssessementPedia.controls.stimulation_duration.disable();
        this.initialAssessementPedia.controls.stimulation_details.disable();
      }
      if(event.target.checked){
        this.initialAssessementPedia.controls['stimulation_duration'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementPedia.controls['stimulation_duration'].clearValidators();
        this.initialAssessementPedia.controls['stimulation_duration'].setValue(null);
      }
      this.initialAssessementPedia.controls['stimulation_duration'].updateValueAndValidity();
      if(event.target.checked){
        this.initialAssessementPedia.controls['stimulation_details'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementPedia.controls['stimulation_details'].clearValidators();
        this.initialAssessementPedia.controls['stimulation_details'].setValue(null);
      }
      this.initialAssessementPedia.controls['stimulation_details'].updateValueAndValidity();
  } else if (controlName == 'vibrator') {
    // if (event.target.value == 'vibrator') {
    //   console.log('vibrator');
    //   this.vibrator = !this.vibrator;
    // }
    if (event.target.checked) {
      console.log('exercise');
      //  this.exercise = !this.exercise;
      this.initialAssessementPedia.controls.vibrator_duration.enable();
      this.initialAssessementPedia.controls.vibrator_details.enable();
    }
      else {
        this.initialAssessementPedia.controls.vibrator_details.disable();
        this.initialAssessementPedia.controls.vibrator_duration.disable();
      }
      if(event.target.checked){
        this.initialAssessementPedia.controls['vibrator_duration'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementPedia.controls['vibrator_duration'].clearValidators();
        this.initialAssessementPedia.controls['vibrator_duration'].setValue(null);
      }
      this.initialAssessementPedia.controls['vibrator_duration'].updateValueAndValidity();
      if(event.target.checked){
        this.initialAssessementPedia.controls['vibrator_details'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementPedia.controls['vibrator_details'].clearValidators();
        this.initialAssessementPedia.controls['vibrator_details'].setValue(null);
      }
      this.initialAssessementPedia.controls['vibrator_details'].updateValueAndValidity();
  } else if (controlName == 'tapping') {
    // if (event.target.value == 'tapping') {
    //   console.log('tapping');
    //   this.tapping = !this.tapping;
    // }
    if (event.target.checked) {
      console.log('exercise');
      //  this.exercise = !this.exercise;
      this.initialAssessementPedia.controls.tapping_duration.enable();
      this.initialAssessementPedia.controls.tapping_details.enable();
    }
      else {
        this.initialAssessementPedia.controls.tapping_duration.disable();
        this.initialAssessementPedia.controls.tapping_details.disable();
      }
      if(event.target.checked){
        this.initialAssessementPedia.controls['tapping_duration'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementPedia.controls['tapping_duration'].clearValidators();
        this.initialAssessementPedia.controls['tapping_duration'].setValue(null);

      }
      this.initialAssessementPedia.controls['tapping_duration'].updateValueAndValidity();
      if(event.target.checked){
        this.initialAssessementPedia.controls['tapping_details'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementPedia.controls['tapping_details'].clearValidators();
        this.initialAssessementPedia.controls['tapping_details'].setValue(null);
      }
      this.initialAssessementPedia.controls['tapping_details'].updateValueAndValidity();
  } else if (controlName == 'chest') {
    // if (event.target.value == 'therapy') {
    //   console.log('therapy');
    //   this.therapy = !this.therapy;
    // }
    if (event.target.checked) {
      console.log('exercise');
      //  this.exercise = !this.exercise;
      this.initialAssessementPedia.controls.chest_therapy_details.enable();
      this.initialAssessementPedia.controls.chest_therapy_duration.enable();
    }
      else {
        this.initialAssessementPedia.controls.chest_therapy_duration.disable();
        this.initialAssessementPedia.controls.chest_therapy_details.disable();
      }
      if(event.target.checked){
        this.initialAssessementPedia.controls['chest_therapy_duration'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementPedia.controls['chest_therapy_duration'].clearValidators();
        this.initialAssessementPedia.controls['chest_therapy_duration'].setValue(null);
      }
      this.initialAssessementPedia.controls['chest_therapy_duration'].updateValueAndValidity();
      if(event.target.checked){
        this.initialAssessementPedia.controls['chest_therapy_details'].setValidators([Validators.required]);
      }
      else {
        this.initialAssessementPedia.controls['chest_therapy_details'].clearValidators();
        this.initialAssessementPedia.controls['chest_therapy_details'].setValue(null);
      }
      this.initialAssessementPedia.controls['chest_therapy_details'].updateValueAndValidity();
  }
  else if (controlName=='other'){

    if(event.target.checked){
    this.initialAssessementPedia.controls.session_technique_others_details.enable();
    }
  else{
    this.initialAssessementPedia.controls.session_technique_others_details.disable();
  }
  if(event.target.checked){
    this.initialAssessementPedia.controls['session_technique_others_details'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementPedia.controls['session_technique_others_details'].clearValidators();
    this.initialAssessementPedia.controls['session_technique_others_details'].setValue(null);
  }
  this.initialAssessementPedia.controls['session_technique_others_details'].updateValueAndValidity();
}
if (controlName == 'secretion') {
  if (event.target.value == 'Yes') {
    console.log('called yes');
    this.initialAssessementPedia.controls.chest_secretion_remark.enable();
  } else {
    console.log('called no');
    this.initialAssessementPedia.controls.chest_secretion_remark.disable();
    this.initialAssessementPedia.controls.chest_secretion_remark.setValue(
      null
    );
  }
  if(event.target.checked){
    this.initialAssessementPedia.controls['chest_secretion_remark'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementPedia.controls['chest_secretion_remark'].clearValidators();
  }
  this.initialAssessementPedia.controls['chest_secretion_remark'].updateValueAndValidity();
}
if (controlName == 'cough') {
  if (event.target.value == 'Yes') {
    console.log('called yes');
    this.initialAssessementPedia.controls.cough_remark.enable();
  } else {
    console.log('called no');
    this.initialAssessementPedia.controls.cough_remark.disable();
    this.initialAssessementPedia.controls.cough_remark.setValue(
      null
    );
  }
  if(event.target.checked){
    this.initialAssessementPedia.controls['cough_remark'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementPedia.controls['cough_remark'].clearValidators();
  }
  this
  .initialAssessementPedia.controls['cough_remark'].updateValueAndValidity();
}
if (controlName == 'swell') {
  if (event.target.value == 'Yes') {
    console.log('called yes');
    this.initialAssessementPedia.controls.swelling_remark.enable();
  } else {
    console.log('called no');
    this.initialAssessementPedia.controls.swelling_remark.disable();
    this.initialAssessementPedia.controls.swelling_remark.setValue(
      null
    );
  }
  if(event.target.checked){
    this.initialAssessementPedia.controls['swelling_remark'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementPedia.controls['swelling_remark'].clearValidators();
  }
  this
  .initialAssessementPedia.controls['swelling_remark'].updateValueAndValidity();
}
if (controlName == 'scar') {
  if (event.target.value == 'Yes') {
    console.log('called yes');
    this.initialAssessementPedia.controls.scar_remark.enable();
  } else {
    console.log('called no');
    this.initialAssessementPedia.controls.scar_remark.disable();
    this.initialAssessementPedia.controls.scar_remark.setValue(
      null
    );
  }
  if(event.target.checked){
    this.initialAssessementPedia.controls['scar_remark'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementPedia.controls['scar_remark'].clearValidators();
  }
  this
  .initialAssessementPedia.controls['scar_remark'].updateValueAndValidity();
}
if (controlName == 'worm') {
  if (event.target.value == 'Yes') {
    console.log('called yes');
    this.initialAssessementPedia.controls.worm_remark.enable();
  } else {
    console.log('called no');
    this.initialAssessementPedia.controls.worm_remark.disable();
    this.initialAssessementPedia.controls.worm_remark.setValue(
      null
    );
  }
  if(event.target.checked){
    this.initialAssessementPedia.controls['worm_remark'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementPedia.controls['worm_remark'].clearValidators();
  }
  this
  .initialAssessementPedia.controls['worm_remark'].updateValueAndValidity();
}

if (controlName == 'infection') {
  if (event.target.value == 'Yes') {
    console.log('called yes');
    this.initialAssessementPedia.controls.infection_remark.enable();
  } else {
    console.log('called no');
    this.initialAssessementPedia.controls.infection_remark.disable();
    this.initialAssessementPedia.controls.infection_remark.setValue(
      null
    );
  }
  if(event.target.checked){
    this.initialAssessementPedia.controls['infection_remark'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementPedia.controls['infection_remark'].clearValidators();
  }
  this
  .initialAssessementPedia.controls['infection_remark'].updateValueAndValidity();
}
if (controlName == 'wound') {
  if (event.target.value == 'Yes') {
    console.log('called yes');
    this.initialAssessementPedia.controls.wound_remark.enable();
  } else {
    console.log('called no');
    this.initialAssessementPedia.controls.wound_remark.disable();
    this.initialAssessementPedia.controls.wound_remark.setValue(
      null
    );
  }
  if(event.target.checked){
    this.initialAssessementPedia.controls['wound_remark'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementPedia.controls['wound_remark'].clearValidators();
  }
  this
  .initialAssessementPedia.controls['wound_remark'].updateValueAndValidity();
}
if (controlName == 'pain') {
  if (event.target.value == 'Yes') {
    console.log('called yes');
    this.initialAssessementPedia.controls.pain_remark.enable();
  } else {
    console.log('called no');
    this.initialAssessementPedia.controls.pain_remark.disable();
    this.initialAssessementPedia.controls.pain_remark.setValue(
      null
    );
  }
  if(event.target.checked){
    this.initialAssessementPedia.controls['pain_remark'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementPedia.controls['pain_remark'].clearValidators();
  }
  this
  .initialAssessementPedia.controls['pain_remark'].updateValueAndValidity();
}
if (controlName == 'others') {
  if (event.target.value == 'Yes') {
    console.log('called yes');
    this.initialAssessementPedia.controls.others_remark.enable();
  } else {
    console.log('called no');
    this.initialAssessementPedia.controls.others_remark.disable();
    this.initialAssessementPedia.controls.others_remark.setValue(
      null
    );
  }
  if(event.target.checked){
    this.initialAssessementPedia.controls['others_remark'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementPedia.controls['others_remark'].clearValidators();
  }
  this
  .initialAssessementPedia.controls['others_remark'].updateValueAndValidity();
}
if (controlName == 'short') {
  // this.timelist = event.target.value;
  console.log(event.target.value)
  if (event.target.checked) {
    this.initialAssessementPedia.controls.short_term_session.enable();
  }
  else {
    this.initialAssessementPedia.controls.short_term_session.disable();
  }
  if (event.target.checked) {
    this.initialAssessementPedia.controls['short_term_session'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementPedia.controls['short_term_session'].clearValidators();
    this.initialAssessementPedia.controls['short_term_session'].setValue(null);
  }
  this.initialAssessementPedia.controls['short_term_session'].updateValueAndValidity();
}
if (controlName == 'long') {
  // this.timelist = event.target.value;
  console.log(event.target.value)
  if (event.target.checked) {
    this.initialAssessementPedia.controls.long_term_session.enable();
    // this.initialAssessementPedia.get(['transfer_assisting', 'long_term_session']).setValidators([Validators.required]);
  }
  else {
    this.initialAssessementPedia.controls.long_term_session.disable();

  }
  if (event.target.checked) {
    this.initialAssessementPedia.controls['long_term_session'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementPedia.controls['long_term_session'].clearValidators();
    this.initialAssessementPedia.controls['long_term_session'].setValue(null);
  }
  this.initialAssessementPedia.controls['long_term_session'].updateValueAndValidity();
}
if (controlName == 'lightall') {
  if (event.target.checked) {
    console.log('called yes');
    this.initialAssessementPedia.controls.light_touch_remark.enable();
  } else {
    console.log('called no');
    this.initialAssessementPedia.controls.light_touch_remark.disable();
    this.initialAssessementPedia.controls.light_touch_remark.setValue(
      null
    );
  }
  if(event.target.checked){
    this.initialAssessementPedia.controls['light_touch_remark'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementPedia.controls['light_touch_remark'].clearValidators();
  }
  this.initialAssessementPedia.controls['light_touch_remark'].updateValueAndValidity();
}
if (controlName == 'bluntall') {
  if (event.target.checked) {
    console.log('called yes');
    this.initialAssessementPedia.controls.blunt_remark.enable();
  } else {
    console.log('called no');
    this.initialAssessementPedia.controls.b.disable();
    this.initialAssessementPedia.controls.blunt_remark.setValue(
      null
    );
  }
  if(event.target.checked){
    this.initialAssessementPedia.controls['blunt_remark'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementPedia.controls['blunt_remark'].clearValidators();
  }
  this.initialAssessementPedia.controls['blunt_remark'].updateValueAndValidity();
}
if (controlName == 'lightall') {
  if (event.target.checked) {
    console.log('called yes');
    this.initialAssessementPedia.controls.pressure_remark.enable();
  } else {
    console.log('called no');
    this.initialAssessementPedia.controls.pressure_remark.disable();
    this.initialAssessementPedia.controls.pressure_remark.setValue(
      null
    );
  }
  if(event.target.checked){
    this.initialAssessementPedia.controls['pressure_remark'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementPedia.controls['pressure_remark'].clearValidators();
  }
  this.initialAssessementPedia.controls['pressure_remark'].updateValueAndValidity();
}
if (controlName == 'lightall') {
  if (event.target.checked) {
    console.log('called yes');
    this.initialAssessementPedia.controls.pin_remark.enable();
  } else {
    console.log('called no');
    this.initialAssessementPedia.controls.pin_remark.disable();
    this.initialAssessementPedia.controls.pin_remark.setValue(
      null
    );
  }
  if(event.target.checked){
    this.initialAssessementPedia.controls['pin_remark'].setValidators([Validators.required]);
  }
  else {
    this.initialAssessementPedia.controls['pin_remark'].clearValidators();
  }
  this.initialAssessementPedia.controls['pin_remark'].updateValueAndValidity();
}

}
}
