import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NannyTaskFormService } from 'src/app/services/nanny-task-form.service';
import { MessageService } from 'primeng/api';
import { CommonApiService } from 'src/app/services/common-api.service';
import * as moment from 'moment';

@Component({
  selector: 'app-nanny-task',
  templateUrl: './nanny-task.component.html',
  styleUrls: ['./nanny-task.component.scss'],
  providers: [MessageService],
})
export class NannyTaskComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Nurse Task',
      subTitle: 'Dashboard',
    },
  ];
  public escort: boolean = true;
  public educational: boolean = true;
  public machineWash: boolean = true;
  public handwash: boolean = true;
  public snack: boolean = true;
  // public sponge: boolean = true;
  public tub: boolean = true;
  public dinner: boolean = true;
  public lunch: boolean = true;
  public breakfast: boolean = true;
  public feedingFormula: boolean = true;
  public feedingBreast: boolean = true;
  showNeedsTextarea: boolean = false;
  selectedDate: Date = null; // Initialize with today's date
  month: any = '';
  days: any = '';
  years: any = '';
  selectedGender: any;
  babyGender: any;
  frequency: any = [];
  babyDetails: any = [];
  // genderArray: any = [
  //   { name: 'Male', value: 'male' },
  //   { name: 'Female', value: 'female' },
  // ];
  empArray: [number, string, boolean] = [2, 'yes', true];
  nannyTaskForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private nanny_tasks_service: NannyTaskFormService,
    private messageService: MessageService,
    private common_service: CommonApiService,
    private router:Router,
    private spinner:NgxSpinnerService
  ) {
    this.nannyTaskForm = this.fb.group({
      baby_details_id: new FormControl('', [Validators.required]),
      other_feeding_json: new FormGroup({
        solid_other_snack: new FormControl(''),
        solid_other_snack_time: new FormControl({ value: '', disabled: true }),
        soild_other_snack_food_components: new FormControl({
          value: '',
          disabled: true,
        }),
        regular_meal_type: new FormControl(''),
        regular_meal_time: new FormControl({ value: '', disabled: true }),
        regular_meal_food_components: new FormControl({
          value: '',
          disabled: true,
        }),
        lunch_meal_time: new FormControl({ value: '', disabled: true }),
        lunch_meal_food_components: new FormControl({
          value: '',
          disabled: true,
        }),
        dinner_meal_time: new FormControl({ value: '', disabled: true }),
        dinner_meal_food_components: new FormControl({
          value: '',
          disabled: true,
        }),
      }),
      diaper_change_frequency: new FormControl(''),
      feeding_json: new FormGroup({
        feeding_type_breast: new FormControl(''),
        feeding_type_formula: new FormControl(''),
        breast_frequency: new FormControl({ value: '', disabled: true }),
        formula_frequency: new FormControl({ value: '', disabled: true }),
        feeding_amount_per: new FormControl({ value: '', disabled: true }),
        type_of_formula: new FormControl({ value: '', disabled: true }),
      }),
      bathing_json: new FormGroup({
        bathing_type: new FormControl(''),
        bathing_type_tub_frequency: new FormControl({
          value: '',
          disabled: true,
        }),
        bathing_type_sponge_frequency: new FormControl({
          value: '',
          disabled: true,
        }),
      }),
      clothes_json: new FormGroup({
        changing_colthes_spiecal_instructions: new FormControl(''),
        washing_type: new FormControl(''),
        machine_washing_frequency: new FormControl(''),
        handwash_washing_frequency: new FormControl(''),
        changing_colthes_frequency: new FormControl(''),
        changing_colthes_time: new FormControl(''),
      }),
      sterilization_json: new FormGroup({
        sterilization_bottles: new FormControl(''),
        // sterilization_bottles_yes: new FormControl(''),
        sterilization_instructions: new FormControl(''),
        sterilization_toys: new FormControl(''),
        // sterilization_toys_yes: new FormControl(''),
      }),
      food_prep_json: new FormGroup({
        food_preparation_special_ins: new FormControl(''),
        food_preparation_mother: new FormControl(''),
        food_preparation_nanny: new FormControl(''),
      }),
      sleep_training_json: new FormGroup({
        sleep_training: new FormControl(''),
        sleep_training_time: new FormControl({ value: '', disabled: true }),
        sleep_training_special_ins: new FormControl({ value: '', disabled: true }),
      }),
      playing_json: new FormGroup({
        playing: new FormControl(''),
        playing_time: new FormControl({ value: '', disabled: true }),
        playing_special_ins: new FormControl({ value: '', disabled: true }),
      }),
      edu_games_toys_json: new FormGroup({
        education_games: new FormControl(''),
        education_games_ins: new FormControl({ value: '', disabled: true }),
      }),
      escort_to_school_json: new FormGroup({
        escort_school: new FormControl(''),
        escort_time: new FormControl({ value: '', disabled: true }),
        escort_special_ins: new FormControl({ value: '', disabled: true }),
      }),
      other_tasks_json: new FormGroup({
        other_tasks: new FormControl(''),
      }),
    });
  }

  ngOnInit(): void {
    this.spinner.show()
    this.common_service.getBabyDetails().subscribe((res: any) => {
      this.spinner.hide()
      console.log(res);
      this.babyDetails = res?.data;
    }, (error: any) => {
      this.spinner.hide()
      // Handle any errors that occurred during the API call
    
      
    });
    // for frequency dropdown list
    for (let i = 1; i <= 10; i++) {
      this.frequency.push({ frequency: i });
      console.log('frequency', this.frequency);
    }
  }

  handleInput(event: any, value: string) {
    if (value == 'sleep_training') {
      if (event.target.value == '1' && event.target.checked) {
        this.nannyTaskForm.controls.sleep_training_json['controls'].sleep_training_time.enable();
        this.nannyTaskForm.controls.sleep_training_json['controls'].sleep_training_special_ins.enable();
      }
      else {
        this.nannyTaskForm.controls.sleep_training_json['controls'].sleep_training_time.disable();
        this.nannyTaskForm.controls.sleep_training_json['controls'].sleep_training_special_ins.disable();

        this.nannyTaskForm.controls.sleep_training_json['controls'].sleep_training_time.setValue('');
        this.nannyTaskForm.controls.sleep_training_json['controls'].sleep_training_special_ins.setValue('');
      }
      if(event.target.checked){
        this.nannyTaskForm.get(['sleep_training_json','sleep_training_time']).setValidators([Validators.required]);

      }
      else {
        this.nannyTaskForm.get(['sleep_training_json','sleep_training_time']).clearValidators();

      }
      this.nannyTaskForm.get(['sleep_training_json','sleep_training_time']).updateValueAndValidity();
    }

    if (value == 'playing') {
      if (event.target.value == '1' && event.target.checked) {
        this.nannyTaskForm.controls.playing_json['controls'].playing_time.enable();
        this.nannyTaskForm.controls.playing_json['controls'].playing_special_ins.enable();
      }
      else {
        this.nannyTaskForm.controls.playing_json['controls'].playing_time.disable();
        this.nannyTaskForm.controls.playing_json['controls'].playing_special_ins.disable();

        this.nannyTaskForm.controls.playing_json['controls'].playing_time.setValue('');
        this.nannyTaskForm.controls.playing_json['controls'].playing_special_ins.setValue('');
      }
       if(event.target.checked){
        this.nannyTaskForm.get(['playing_json','playing_time']).setValidators([Validators.required]);

      }
      else {
        this.nannyTaskForm.get(['playing_json','playing_time']).clearValidators();

      }
      this.nannyTaskForm.get(['playing_json','playing_time']).updateValueAndValidity();
    }

    if (value == 'educational_game') {
      if (event.target.value == '1' && event.target.checked) {
        this.nannyTaskForm.controls.edu_games_toys_json['controls'].education_games_ins.enable();
      }
      else {
        this.nannyTaskForm.controls.edu_games_toys_json['controls'].education_games_ins.disable();
        this.nannyTaskForm.controls.edu_games_toys_json['controls'].education_games_ins.setValue('');
      }
    }

    if (value == 'escort') {
      if (event.target.value == '1' && event.target.checked) {
        this.nannyTaskForm.controls.escort_to_school_json['controls'].escort_time.enable();
        this.nannyTaskForm.controls.escort_to_school_json['controls'].escort_special_ins.enable();
      }
      else {
        this.nannyTaskForm.controls.escort_to_school_json['controls'].escort_time.disable();
        this.nannyTaskForm.controls.escort_to_school_json['controls'].escort_special_ins.disable();

        this.nannyTaskForm.controls.escort_to_school_json['controls'].escort_time.setValue('');
        this.nannyTaskForm.controls.escort_to_school_json['controls'].escort_special_ins.setValue('');
      }
      if(event.target.checked){
        this.nannyTaskForm.get(['escort_to_school_json','escort_time']).setValidators([Validators.required]);

      }
      else {
        this.nannyTaskForm.get(['escort_to_school_json','escort_time']).clearValidators();

      }
      this.nannyTaskForm.get(['escort_to_school_json','escort_time']).updateValueAndValidity();
    }


    if (value == 'bathing') {
      if (event.target.value == 'sponge' && event.target.checked) {
        console.log('sponge');
        // if (event.target.checked) {
        this.nannyTaskForm.controls.bathing_json[
          'controls'
        ].bathing_type_sponge_frequency.enable();
        // }
        // else {
        this.nannyTaskForm.controls.bathing_json[
          'controls'
        ].bathing_type_tub_frequency.disable();
        this.nannyTaskForm.controls.bathing_json[
          'controls'
        ].bathing_type_tub_frequency.setValue('');
        // }
        if(event.target.checked){
          this.nannyTaskForm.get(['bathing_json','bathing_type_tub_frequency']).setValidators([Validators.required]);

        }
        else {
          this.nannyTaskForm.get(['bathing_json','bathing_type_tub_frequency']).clearValidators();

        }
        this.nannyTaskForm.get(['bathing_json','bathing_type_tub_frequency']).updateValueAndValidity();

      } else if (event.target.value == 'tub' && event.target.checked) {
        console.log('tub');
        // if (event.target.checked) {
        this.nannyTaskForm.controls.bathing_json[
          'controls'
        ].bathing_type_tub_frequency.enable();
        // } else {
        this.nannyTaskForm.controls.bathing_json[
          'controls'
        ].bathing_type_sponge_frequency.disable();
        this.nannyTaskForm.controls.bathing_json[
          'controls'
        ].bathing_type_sponge_frequency.setValue('');
        // }
      }
      if(event.target.checked){
        this.nannyTaskForm.get(['bathing_json','bathing_type_sponge_frequency']).setValidators([Validators.required]);

      }
      else {
        this.nannyTaskForm.get(['bathing_json','bathing_type_sponge_frequency']).clearValidators();

      }
      this.nannyTaskForm.get(['bathing_json','bathing_type_sponge_frequency']).updateValueAndValidity();
    } else if (value == 'regularMeal') {
      if (event.target.value == 'breakfast') {
        if (event.target.checked) {
          this.nannyTaskForm.controls.other_feeding_json[
            'controls'
          ].regular_meal_time.enable();
          this.nannyTaskForm.controls.other_feeding_json[
            'controls'
          ].regular_meal_food_components.enable();
        } else {
          this.nannyTaskForm.controls.other_feeding_json[
            'controls'
          ].regular_meal_time.disable();
          this.nannyTaskForm.controls.other_feeding_json[
            'controls'
          ].regular_meal_food_components.disable();
          this.nannyTaskForm.controls.other_feeding_json[
            'controls'
          ].regular_meal_time.setValue('');
          this.nannyTaskForm.controls.other_feeding_json[
            'controls'
          ].regular_meal_food_components.setValue('');
        }
        if(event.target.checked){
          this.nannyTaskForm.get(['other_feeding_json','regular_meal_time']).setValidators([Validators.required]);
          this.nannyTaskForm.get(['other_feeding_json','regular_meal_food_components']).setValidators([Validators.required]);
        }
        else {
          this.nannyTaskForm.get(['other_feeding_json','regular_meal_time']).clearValidators();
          this.nannyTaskForm.get(['other_feeding_json','regular_meal_food_components']).clearValidators();
        }
        this.nannyTaskForm.get(['other_feeding_json','regular_meal_time']).updateValueAndValidity();
        this.nannyTaskForm.get(['other_feeding_json','regular_meal_food_components']).updateValueAndValidity();
      }
      if (event.target.value == 'lunch') {
        if (event.target.checked) {
          this.nannyTaskForm.controls.other_feeding_json[
            'controls'
          ].lunch_meal_time.enable();
          this.nannyTaskForm.controls.other_feeding_json[
            'controls'
          ].lunch_meal_food_components.enable();
        } else {
          this.nannyTaskForm.controls.other_feeding_json[
            'controls'
          ].lunch_meal_time.disable();
          this.nannyTaskForm.controls.other_feeding_json[
            'controls'
          ].lunch_meal_food_components.disable();
          this.nannyTaskForm.controls.other_feeding_json[
            'controls'
          ].lunch_meal_time.setValue('');
          this.nannyTaskForm.controls.other_feeding_json[
            'controls'
          ].lunch_meal_food_components.setValue('');
        }
        if(event.target.checked){
          this.nannyTaskForm.get(['other_feeding_json','lunch_meal_time']).setValidators([Validators.required]);
          this.nannyTaskForm.get(['other_feeding_json','lunch_meal_food_components']).setValidators([Validators.required]);
        }
        else {
          this.nannyTaskForm.get(['other_feeding_json','lunch_meal_time']).clearValidators();
          this.nannyTaskForm.get(['other_feeding_json','lunch_meal_food_components']).clearValidators();
        }
        this.nannyTaskForm.get(['other_feeding_json','lunch_meal_time']).updateValueAndValidity();
        this.nannyTaskForm.get(['other_feeding_json','lunch_meal_food_components']).updateValueAndValidity();
      }
      if (event.target.value == 'dinner') {
        if (event.target.checked) {
          this.nannyTaskForm.controls.other_feeding_json[
            'controls'
          ].dinner_meal_time.enable();
          this.nannyTaskForm.controls.other_feeding_json[
            'controls'
          ].dinner_meal_food_components.enable();
        } else {
          this.nannyTaskForm.controls.other_feeding_json[
            'controls'
          ].dinner_meal_time.disable();
          this.nannyTaskForm.controls.other_feeding_json[
            'controls'
          ].dinner_meal_food_components.disable();
          this.nannyTaskForm.controls.other_feeding_json[
            'controls'
          ].dinner_meal_time.setValue('');
          this.nannyTaskForm.controls.other_feeding_json[
            'controls'
          ].dinner_meal_food_components.setValue('');
        }
        if(event.target.checked){
          this.nannyTaskForm.get(['other_feeding_json','dinner_meal_time']).setValidators([Validators.required]);
          this.nannyTaskForm.get(['other_feeding_json','dinner_meal_food_components']).setValidators([Validators.required]);
        }
        else {
          this.nannyTaskForm.get(['other_feeding_json','dinner_meal_time']).clearValidators();
          this.nannyTaskForm.get(['other_feeding_json','dinner_meal_food_components']).clearValidators();
        }
        this.nannyTaskForm.get(['other_feeding_json','dinner_meal_time']).updateValueAndValidity();
        this.nannyTaskForm.get(['other_feeding_json','dinner_meal_food_components']).updateValueAndValidity();
      }
    } else if (value == 'washingFormula') {
      if (event.target.value == 'handwash' && event.target.checked) {
        // this.handwash = !this.handwash;
        // this.machineWash = true;
        // this.nannyTaskForm.controls.clothes_json[
        //   'controls'
        // ].machine_washing_frequency.setValue('');
        this.nannyTaskForm.controls.clothes_json[
          'controls'
        ].handwash_washing_frequency.enable();
        // } else {
        this.nannyTaskForm.controls.clothes_json[
          'controls'
        ].machine_washing_frequency.disable();
        this.nannyTaskForm.controls.clothes_json[
          'controls'
        ].machine_washing_frequency.setValue('');
        if(event.target.checked){
          this.nannyTaskForm.get(['clothes_json','machine_washing_frequency']).setValidators([Validators.required]);

        }
        else {
          this.nannyTaskForm.get(['clothes_json','machine_washing_frequency']).clearValidators();

        }
        this.nannyTaskForm.get(['clothes_json','machine_washing_frequency']).updateValueAndValidity();
      }

      else if (event.target.value == 'machineWash' && event.target.checked) {
        // this.machineWash = !this.machineWash;
        // this.handwash = true;
        // this.nannyTaskForm.controls.clothes_json[
        //   'controls'
        // ].handwash_washing_frequency.setValue('');
        // console.log(
        //   this.nannyTaskForm.controls.handwash_washing_frequency.value
        // );
        this.nannyTaskForm.controls.clothes_json[
          'controls'
        ].machine_washing_frequency.enable();
        // } else {
        this.nannyTaskForm.controls.clothes_json[
          'controls'
        ].handwash_washing_frequency.disable();
        this.nannyTaskForm.controls.clothes_json[
          'controls'
        ].handwash_washing_frequency.setValue('');
      }
      if(event.target.checked){
        this.nannyTaskForm.get(['clothes_json','handwash_washing_frequency']).setValidators([Validators.required]);

      }
      else {
        this.nannyTaskForm.get(['clothes_json','handwash_washing_frequency']).clearValidators();

      }
      this.nannyTaskForm.get(['clothes_json','handwash_washing_frequency']).updateValueAndValidity();
    } else if (value == 'snacks') {
      if (event.target.checked) {
        this.nannyTaskForm.controls.other_feeding_json[
          'controls'
        ].solid_other_snack_time.enable();
        this.nannyTaskForm.controls.other_feeding_json[
          'controls'
        ].soild_other_snack_food_components.enable();
      } else {
        this.nannyTaskForm.controls.other_feeding_json[
          'controls'
        ].solid_other_snack_time.disable();
        this.nannyTaskForm.controls.other_feeding_json[
          'controls'
        ].soild_other_snack_food_components.disable();
        this.nannyTaskForm.controls.other_feeding_json[
          'controls'
        ].solid_other_snack_time.setValue('');
        this.nannyTaskForm.controls.other_feeding_json[
          'controls'
        ].soild_other_snack_food_components.setValue('');
      }
      if(event.target.checked){
        this.nannyTaskForm.get(['other_feeding_json','solid_other_snack_time']).setValidators([Validators.required]);
        this.nannyTaskForm.get(['other_feeding_json','soild_other_snack_food_components']).setValidators([Validators.required]);
      }
      else {
        this.nannyTaskForm.get(['other_feeding_json','solid_other_snack_time']).clearValidators();
        this.nannyTaskForm.get(['other_feeding_json','soild_other_snack_food_components']).clearValidators();
      }
      this.nannyTaskForm.get(['other_feeding_json','solid_other_snack_time']).updateValueAndValidity();
      this.nannyTaskForm.get(['other_feeding_json','soild_other_snack_food_components']).updateValueAndValidity();
    } else if (value == 'breast-feeding') {
      console.log('breastFeeding');
      if (event.target.value == 'breastFeeding' && event.target.checked) {
        console.log('breastFeeding if');
        this.nannyTaskForm.controls.feeding_json[
          'controls'
        ].breast_frequency.enable();
      } else {
        console.log('breastFeeding else');
        this.nannyTaskForm.controls.feeding_json[
          'controls'
        ].breast_frequency.disable();
        this.nannyTaskForm.controls.feeding_json[
          'controls'
        ].breast_frequency.setValue('');
      }
      if(event.target.checked){
        this.nannyTaskForm.get(['feeding_json','breast_frequency']).setValidators([Validators.required]);

        console.log(event.target.checked);
      }else {
        this.nannyTaskForm.get(['feeding_json','breast_frequency']).clearValidators();

      }
      this.nannyTaskForm.get(['feeding_json','breast_frequency']).updateValueAndValidity();

    } else if (value == 'formula-feeding') {
      if (event.target.value == 'formulaFeeding' && event.target.checked) {
        console.log('breastFeeding if');
        this.nannyTaskForm.controls.feeding_json[
          'controls'
        ].formula_frequency.enable();
        this.nannyTaskForm.controls.feeding_json[
          'controls'
        ].feeding_amount_per.enable();
        this.nannyTaskForm.controls.feeding_json[
          'controls'
        ].type_of_formula.enable();
      } else {
        console.log('breastFeeding else');
        this.nannyTaskForm.controls.feeding_json[
          'controls'
        ].formula_frequency.disable();
        this.nannyTaskForm.controls.feeding_json[
          'controls'
        ].feeding_amount_per.setValue('');
        this.nannyTaskForm.controls.feeding_json[
          'controls'
        ].type_of_formula.disable();
        this.nannyTaskForm.controls.feeding_json[
          'controls'
        ].formula_frequency.setValue('');
        this.nannyTaskForm.controls.feeding_json[
          'controls'
        ].feeding_amount_per.disable();
        this.nannyTaskForm.controls.feeding_json[
          'controls'
        ].type_of_formula.setValue('');
      }
       if(event.target.checked){
      this.nannyTaskForm.get(['feeding_json','formula_frequency']).setValidators([Validators.required]);

      this.nannyTaskForm.get(['feeding_json','feeding_amount_per']).setValidators([Validators.required]);
      this.nannyTaskForm.get(['feeding_json','type_of_formula']).setValidators([Validators.required]);

      console.log(event.target.checked);
    }else {
      this.nannyTaskForm.get(['feeding_json','formula_frequency']).clearValidators();
      this.nannyTaskForm.get(['feeding_json','feeding_amount_per']).clearValidators();
      this.nannyTaskForm.get(['feeding_json','type_of_formula']).clearValidators();

    }
    this.nannyTaskForm.get(['feeding_json','formula_frequency']).updateValueAndValidity();
    this.nannyTaskForm.get(['feeding_json','feeding_amount_per']).updateValueAndValidity();
    this.nannyTaskForm.get(['feeding_json','type_of_formula']).updateValueAndValidity();
    }


  }
  /* -------------------------------------------------------------------------- */
  /*            //Soft Reloading            */
  /* -------------------------------------------------------------------------- */
  softReload() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  /* -------------------------------------------------------------------------- */
  /*                        // submit Form functionality                        */
  /* -------------------------------------------------------------------------- */
  saveNannyTasksForm(nannyTask: any) {
    this.spinner.show()
    window.scroll(0, 0);
    if (nannyTask.valid) {
      let data = JSON.parse(JSON.stringify(this.nannyTaskForm.value));
      console.log(this.nannyTaskForm.value);
      // let timeArray = [
      //   'solid_other_snack_time',
      //   'regular_meal_time',
      //   'lunch_meal_time',
      //   'dinner_meal_time',
      //   'changing_colthes_time',
      //   'sleep_training_time',
      //   'playing_time',
      //   'escort_time'
      // ];

      // for (let i = 0; i < timeArray.length; i++) {
      //   data[timeArray[i]] = data[timeArray[i]]
      //     ? moment(data[timeArray[i]]).format('HH:mm:ss')
      //     : '';
      // }
      // this.spinner.show();
      this.nanny_tasks_service.submitNannyTasksForm(data).subscribe(
        (response: any) => {
          this.spinner.hide()
          // Handle the API response here
          console.log('API Response:', response);
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: response?.message,
          });
          setTimeout(() => {
            this.softReload();
          }, 400);
          // this.spinner.hide();
        },
        (error: any) => {
          this.spinner.hide()

          // Handle any errors that occurred during the API call
        
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: error?.message,
          });
        }
      );
    } else {
      this.spinner.hide()

      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Fill All the Details!',
      });
    }
  }
  /* -------------------------------------------------------------------------- */
  /*               // get Baby Details on Baby name click function              */
  /* -------------------------------------------------------------------------- */
  getBabyDetails(event: any) {
    let data = this.babyDetails?.filter((res: any) => res?.id == event?.value);
    console.log(data[0].date_of_birth);
    let firstLetter = data[0]?.gender.toLowerCase();
    let capitalize =
      firstLetter.slice(0, 1).toUpperCase() +
      firstLetter.slice(1, firstLetter.length);
    this.babyGender = capitalize;
    this.selectedDate = data[0].date_of_birth
    // Manually parse the date string in DD/MM/YYYY format
    const dateParts = data[0].date_of_birth.split('/');
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Months are 0-indexed in JavaScript
    const year = parseInt(dateParts[2], 10);

    const selectedDate = moment([year, month, day]);
    const today = moment();
    console.log(selectedDate);

    // Calculate the difference in years, months, and days
    const years = today.diff(selectedDate, 'years');
    selectedDate.add(years, 'years');

    const months = today.diff(selectedDate, 'months');
    selectedDate.add(months, 'months');

    const days = today.diff(selectedDate, 'days');

    this.years = years;
    this.month = months;
    this.days = days;
  }
}
