import { Component, OnInit } from '@angular/core';
import { CommonApiService } from 'src/app/services/common-api.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-health-monitoring-summary',
  templateUrl: './health-monitoring-summary.component.html',
  styleUrls: ['./health-monitoring-summary.component.scss']
})
export class HealthMonitoringSummaryComponent implements OnInit {
  healthData:any;
  healthDatabase:any;
  tempratureDataSee:any;
  medicationRecommend:any;
  foodDetails:any
  food:any
  breadcrumb = [
    {
      title: 'Health Monitoring Summary',
      subTitle: 'Dashboard',
    }
  ]
  pulseDataSee: any;
  respDataSee: any;
  spo2DataSee: any;
  doctorValueRecommendation: any;
  constructor(
    private comman_services: CommonApiService,
    private router:Router,
     private spinner: NgxSpinnerService,    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.getHealthDataById();
  }
  getHealthDataById() {
    this.spinner.show()
    const healthId = localStorage.getItem('editedHealthId');
    this.comman_services.getByIdHealthMonitoring(healthId).then((res: any) => {
      this.spinner.hide()
     
      this.healthData = res.data;
      // this.healthDatabase.push(res)
      let temprature = this.healthData.temperature_json;
      console.log("Temprature", temprature);
      const tempratureData = this.healthData.temperature_json.temprature_extra_value?.[0].value;
      console.log("Temprature Data", tempratureData);
       // Assign the value for display in the template
       this.tempratureDataSee =JSON.parse(tempratureData);
      const pulseJsonData = this.healthData.vital_signs_json.pulse_vital.vital_extra_value?.[0].vital_value;
      console.log("Pulse Last Value", pulseJsonData);
      this.pulseDataSee= JSON.parse(pulseJsonData);
      const respJsonData = this.healthData.vital_signs_json.respiration_vital.respiration_extra_value?.[0].respiration_value;
      console.log("Respiration Last Value", respJsonData);
      this.respDataSee= JSON.parse(respJsonData);
      const spo2JsohnData = this.healthData.vital_signs_json.spo2_vital.spo2_extra_value?.[0].spo2_value;
      console.log("SPO2 Last Value", spo2JsohnData);
      this.spo2DataSee= JSON.parse(spo2JsohnData);
  
      this.doctorValueRecommendation = this.healthData.doc_recommendation_json;
      console.log("Doctor Recommendation", this.doctorValueRecommendation);
    
      this.medicationRecommend = this.healthData.medications_json;
      console.log("Medication Recommendation", this.medicationRecommend);
      this.foodDetails=this.healthData.nanny_task_details_json;
     this.food=this.foodDetails.regular_meal_type?this.foodDetails.regular_meal_type:this.foodDetails.
     feeding_breast==true?'Milk':''
         
    },
    (error: any) => {
      this.spinner.hide()
      // Handle any errors that occurred during the API call
    
      
    });
  }

  formatTime(timeString: string): string {
    // Ensure the timeString is in the format 'hh:mm:ss'
    const [hours, minutes] = timeString.split(':');
    const parsedHours = parseInt(hours, 10);
    const period = parsedHours >= 12 ? 'PM' : 'AM';
  
    // Convert hours to 12-hour format
    const formattedHours = parsedHours % 12 || 12;
  
    return `${formattedHours}:${minutes} ${period}`;
  }

  showMaximizableDialog() {
    let id = localStorage.getItem('editedHealthId');
    console.log("ID",id);
    localStorage.setItem('editedOrderId', id);
    this.router.navigateByUrl('/portal/health-monitoring');
  }
  getColorBasedOnTemperature(temperature: number): string {
    if (temperature > 30) {
      return '#ff9191'; // or any color you want for high temperature
    } else if (temperature > 20) {
      return '#efbb63'; // or any color you want for medium temperature
    } else {
      return '#70cb70'; // or any color you want for low temperature
    }}
    getColorBasedOnPulseDataSee(pulseColor: number): string {
      if (pulseColor > 30) {
        return '#ff9191'; // or any color you want for high temperature
      } else if (pulseColor > 20) {
        return '#efbb63'; // or any color you want for medium temperature
      } else {
        return '#70cb70'; // or any color you want for low temperature
      }}
      getColorBasedOnRespiration(respiration: number): string {
        if (respiration > 30) {
          return '#ff9191'; // or any color you want for high temperature
        } else if (respiration > 20) {
          return '#efbb63'; // or any color you want for medium temperature
        } else {
          return '#70cb70'; // or any color you want for low temperature
        }}
        getColorBasedOnSpo2(spo2: number): string {
          if (spo2 > 30) {
            return '#ff9191'; // or any color you want for high temperature
          } else if (spo2 > 20) {
            return '#efbb63'; // or any color you want for medium temperature
          } else {
            return '#70cb70'; // or any color you want for low temperature
          }}
}
