import { Component, OnInit } from '@angular/core';
import { MaikaListService } from 'src/app/services/maika-list.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import * as moment from 'moment';
@Component({
  selector: 'app-daily-task-summary',
  templateUrl: './daily-task-summary.component.html',
  styleUrls: ['./daily-task-summary.component.scss']
})
export class DailyTaskSummaryComponent implements OnInit {
  summaryData: any;
  milkFeeding: any;
  selectedDate: string;
  date: string;
  newData: any;
  bottle:any
  years: number = 0;
  months: number = 0;
  days: number = 0;

  constructor(private nanny_task_service: MaikaListService, private spinner: NgxSpinnerService, private router: Router,) { }
  FirstEntry: any;
  getToken: any;
  getRole: any;
  getname: any;
  error: boolean = false;
  babyAge: string = ''
  calendar1: null
  calendar12: null
  calendar11: null
  calendar2: null
  calendar3: null
  calendar4: null
  calendar5: null
  calendar6: null
  calendar7: null
  calendar8: null
  calendar9: null
  calendar10: null
  calendar13: null
  calendar14: null

  ngOnInit(): void {
    this.getAllsummaryData()
    this.getname = localStorage.getItem('name');
    this.getToken = localStorage.getItem('token');
    this.getRole = localStorage.getItem('role')
  }
  breadcrumb = [
    {
      title: localStorage.getItem('baby_name'),
      subTitle: 'Dashboard',
    },
  ];

  //   getDataByDate(event: any) {
  //     const payload = {
  //         babyId: localStorage.getItem('babyId'),
  //         date: `${event.getFullYear()}-${event.getMonth() + 1}-${event.getDate()}`
  //     };
  //     this.nanny_task_service.getNurseTaskByDate(payload).then((res: any) => {

  //     });
  // }

  GetBabyAge() {
    let date, month, year, data, dob, birth_date;

    const getEdit = localStorage.getItem('babyId');

    data = this.summaryData[0];
    dob = data.date_of_birth.split('/');
    birth_date= data.date_of_birth;

    // calculation the days , month ,year
     // Ensure the birth_date is in the correct format, e.g., 'DD/MM/YYYY'
     const birthDate = moment(birth_date, 'DD/MM/YYYY');
     const compareToday = moment();
     if (!birthDate.isValid()) {
      return;
    }
    // Calculate the difference in years, months, and days
    this.years = compareToday.diff(birthDate, 'years');
    birthDate.add(this.years, 'years');

    this.months = compareToday.diff(birthDate, 'months');
    birthDate.add(this.months, 'months');

    this.days = compareToday.diff(birthDate, 'days');

    // Print the difference to the console
        let today = new Date();




    let dateOfBirth = new Date(dob[2], dob[1] - 1, dob[0]);


    const timeDiffMilliseconds = today.getTime() - dateOfBirth.getTime();

    const daysDiff = Math.floor(timeDiffMilliseconds / (1000 * 3600 * 24));


    if (daysDiff <= 90) {
      this.babyAge = 'zero-to-three-months';
    }
    // up to 4 months  to 1 year
    else if (daysDiff <= 365 && daysDiff > 90) {
      this.babyAge = 'four-months-to-one-year-plan';
    }
    // 1 year to 3 year
    else if (daysDiff <= 1095 && daysDiff > 365) {
      this.babyAge = 'one-year-to-three-year-plan';
    }

    // up to 3 year 
    else if (daysDiff > 1095) {
      this.babyAge = 'three-years-above';
    }



  }


  getDataByDate(event: any) {
    this.spinner.show()

    this.newData = [];
    this.error = false
    this.date = `${event.getDate()}-${event.getMonth() + 1}-${event.getFullYear()}`
    const payload = {
      babyId: localStorage.getItem('babyId'),
      date: `${event.getFullYear()}-${event.getMonth() + 1}-${event.getDate()}`
    };
    this.nanny_task_service.getNurseTaskByDate(payload).then((res: any) => {
      this.spinner.hide()

      this.newData = res.data[0].nanny_task_details_json;
      console.log(this.newData,"data");
      
    }).catch((error: any) => {
      this.spinner.hide()
      this.error = true
    });
  }


  getAllsummaryData() {
    this.spinner.show()
    const id = localStorage.getItem('babyId');
    this.nanny_task_service.getDailyCarePlanById(id).then((res: any) => {
      this.spinner.hide()
      this.summaryData = res.data;
      this.FirstEntry = this.summaryData[0]?.nanny_task_details_json;
      this.bottle= this.FirstEntry?.bottle_sterilization
      console.log(this.bottle,"alldata");
      
      this.GetBabyAge();
    }).catch((error: any) => {
      this.spinner.hide()
      this.error = true
    });

  }

  formatDateTime(date: string | undefined): string {
    if (date) {
      const formattedDate = new Date(date);

      let hours = formattedDate.getHours();
      const minutes = ('0' + formattedDate.getMinutes()).slice(-2);
      const amOrPm = hours >= 12 ? 'PM' : 'AM';

      hours = hours % 12 || 12;

      const formattedHours = ('0' + hours).slice(-2);

      return `${formattedHours}:${minutes} ${amOrPm}`;
    }
    return '-';
  }
  logout() {
    localStorage.clear();
    // window.location.reload();
    this.router.navigate(['/']);
  }

  // calculateAndPrintDateDifference(birth_date: string) {
  //   // Ensure the birth_date is in the correct format, e.g., 'DD/MM/YYYY'
  //   const birthDate = moment(birth_date, 'DD/MM/YYYY');
  //   const today = moment();

  //   if (!birthDate.isValid()) {
  //     return;
  //   }

  //   // Calculate the difference in years, months, and days
  //   const years = today.diff(birthDate, 'years');
  //   birthDate.add(years, 'years');

  //   const months = today.diff(birthDate, 'months');
  //   birthDate.add(months, 'months');

  //   const days = today.diff(birthDate, 'days');

  //   // Print the difference to the console
  // }

  // exampleFunction() {
  //   const data = {
  //     date_of_birth: '01/01/2000' // Replace with your actual data
  //   };

  //   const birth_date = data.date_of_birth;

  //   this.calculateAndPrintDateDifference(birth_date);
  // }

}
