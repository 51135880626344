import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { TableService } from 'primeng/table';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import{ FormArray,
  FormBuilder,
  FormControl,
  FormControlDirective,
  FormGroup,
  Validators} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-roles-permission',
  templateUrl: './roles-permission.component.html',
  styleUrls: ['./roles-permission.component.scss'],
  providers: [MessageService, ConfirmationService, TableService],
})
export class RolesPermissionComponent implements OnInit {
  permissionData: any;
  permissionDataId: any;
  isEdit: boolean = false;
  selectedRowIndex: number | null = null;
  breadcrumb = [
    {
      title: 'Users Permission',
      subTitle: 'Dashboard'
    }
  ]
  filterval: string;
  extraActivities = this.fb.group({
    checkbox: new FormControl(null),
   
  });
  selectedIds: number[] = [];
  rolesData: { name: null, create: null, read: null, update: null, delete: null }[] = [];
  userRoleId: any;
  userId: any;
  checkedIds: number[] = [];

  constructor(private masterService: MasterService,private messageService: MessageService,
    private confirmationService: ConfirmationService,private fb:FormBuilder, private router: Router,
    private spinner: NgxSpinnerService,) { 
     
    }
   
  //function for get permission
  getAllUserPermissionId(){
    this.spinner.show()
    const idPermission=localStorage.getItem("permissionrole_id");
    this.masterService.getUserPremissionById(idPermission).then((res: any) => {
      this.spinner.hide()
     
      this.permissionDataId = res.data;
      if (this.permissionDataId) {
        this.selectedIds= this.permissionDataId.map(event => event.permission_id);
        // console.log(a)
        // this.selectedIds.push(a)
       }
    
      //  this.selectedIds=this.permissionDataId.permission_id
    
    }, (error: any) => {
      this.spinner.hide()
      // Handle any errors that occurred during the API call
       
      
    })
  }
  getAllUserPermission() {
    this.spinner.show()

    this.masterService.getUserPermissions().then((res: any) => {
      this.spinner.hide()
     
  
      // Assuming res.data is an array of actions
      this.permissionData = res.data;
  
      // Initialize an empty array to store rolesData
      this.rolesData = [];
  
      // Group actions by name
      const groupedByName: { [name: string]: any[] } = {};
      this.permissionData.forEach((action: any) => {
        if (!groupedByName[action.name]) {
          groupedByName[action.name] = [];
        }
        groupedByName[action.name].push(action);
      });
  
      // Map grouped actions to rolesData
      for (const name in groupedByName) {
        if (groupedByName.hasOwnProperty(name)) {
          const actions = groupedByName[name];
          const entry: any = { name };
  
          actions.forEach((action: any) => {
            entry[action.action.toLowerCase()] = action.id;
          });
  
          this.rolesData.push(entry);
        }
      }
  
      console.log(this.rolesData);
    },
    (error: any) => {
      this.spinner.hide()
      // Handle any errors that occurred during the API call
    
      
    });
  }
  
  

  
  
  
  ngOnInit(): void {
    this.getAllUserPermissionId();
    this.getAllUserPermission();
    this.userRoleId = localStorage.getItem('userRole_id');
    this.userId = localStorage.getItem('permissionrole_id');
  }
  savePermission() {
        this.spinner.show();
    console.log(this.selectedIds)
    const data = {
      user_id : this.userId,
      role_id : this.userRoleId,
      permission_ids : this.selectedIds
    }
    if(this.selectedIds){
      this.masterService
      .postUserPermissionFormData(data)
      .then((res: any) => {
        this.spinner.hide()
        console.log(res.message);
        this.messageService.add({
          severity: 'success',
          detail: res?.message,
        });
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(["portal/register"]);
        });       
      })
      .catch((error: HttpErrorResponse) => {
        this.spinner.hide()

        console.log(error);
      });
    } else{
      this.spinner.hide()

      this.messageService.add({
        severity: 'error',
        detail: 'Please Select Permission',
      });
  }
      
  }
  reset(dt1: any) {
    dt1.reset();
    this.filterval = '';
  }
 onCheckboxChange(action: any, actionType: string) {
 
 


  

  const checkboxId = action[actionType];
  const actionId = action[actionType.toLowerCase()];
  
    if (checkboxId && actionId) {
    if (this.selectedIds.includes(actionId)) {
      // Remove the ID from the array when unchecked
      this.selectedIds = this.selectedIds.filter(id => id !== actionId);
    } else {
      // Add the ID to the array when checked
      this.selectedIds.push(actionId);
    }
  }

  console.log("Selected IDs:", this.selectedIds);
}

isChecked(action: any, type: string): boolean {
  // Check if this.permissionDataId is defined before using some method
  return this.permissionDataId && this.permissionDataId.some(item => item.permission_id === action[type]);
}

  
  
}
