<p-toast></p-toast>
<div class="loginRegisterModal">
    <div class="modal1">
        <div class="modal1-dialog modal1-dialog-centered">
            <div class="modal1-content">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <button class="nav-link active" id="login-tab" data-bs-toggle="tab" data-bs-target="#login"
                            type="button" role="tab" aria-controls="login" aria-selected="true">Enter Password Here</button>
                    </li>
                    <!-- <li class="nav-item">
                        <button class="nav-link" id="register-tab" data-bs-toggle="tab" data-bs-target="#register"
                            type="button" role="tab" aria-controls="register" aria-selected="true">Register</button>
                    </li> -->
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="login" role="tabpanel">
                        <div class="vesax-login">
                            <form [formGroup]="forgetPassword" (ngSubmit)="matchPassword()" #login="ngForm">
                                <div class="form-group">
                                    <input type="text" placeholder="Please Enter New Password" formControlName="newPassword"
                                        class="form-control form-input" [class.is-invalid]="(login.submitted &&
                                    forgetPassword.get('newPassword')?.invalid)
                                    ||
                                    forgetPassword.get('newPassword')?.invalid
                                    &&
                                    forgetPassword.get('newPassword').touched">
                                    <div class="invalid-feedback">
                                        Please enter same Password
                                    </div>
                                </div>
                                <div class="form-group" >
                                    <input type="text" placeholder="Re-Enter the Password" formControlName="confirmPasword"
                                        class="form-control form-input" [class.is-invalid]="(login.submitted &&
                                    forgetPassword.get('confirmPasword')?.invalid)
                                    ||
                                    forgetPassword.get('confirmPasword')?.invalid
                                    &&
                                    forgetPassword.get('confirmPasword').touched">
                                    <div class="invalid-feedback">
                                        Please enter same Password
                                    </div>
                                </div>
                                
                                <button type="submit" class="login-form">Set Password</button>
                            </form>
                            
                        </div>
                    </div>
                    <!-- <div class="tab-pane fade" id="register" role="tabpanel">
                        <div class="vesax-register">
                             <form [formGroup]="registerForm" (ngSubmit)="saveRegisterForm()" #register="ngForm">
                                <div class="form-group">
                                    <input formControlName="name" type="text" placeholder="Username"
                                        class="form-control form-input">
                                </div>
                                <div class="form-group">
                                    <input formControlName="email" type="email" placeholder="Email"
                                        class="form-control form-input">
                                </div>
                                <div class="form-group">
                                    <input formControlName="password" type="password" placeholder="Password"
                                        class="form-control form-input">
                                </div>
                                <div class="form-group">
                                    <input formControlName="mobile" type="number" maxlength="10"
                                        placeholder="Contact No" class="form-control form-input">
                                </div>
                                <button type="submit" class="login-form">Register Now</button>
                            </form> -->
                    <!-- <span class="already-account">Already have an account? <a href="#">Login Now</a></span> -->
                </div>
            </div>
        </div>
    </div>
</div>
