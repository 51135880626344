import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonApiService } from 'src/app/services/common-api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-patient-view',
  templateUrl: './patient-view.component.html',
  styleUrls: ['./patient-view.component.scss']
})
export class PatientViewComponent implements OnInit {
  getToken: any;
  getRole: any;
  getname: any;
  carePlans: any;
  baby_id: any;

  breadcrumb = [
    {
      title: localStorage.getItem('baby_name'),
      subTitle: 'Dashboard',
    },
  ];
  constructor(private common_api_service: CommonApiService, private spinner: NgxSpinnerService,
    private route: Router,) { }

  ngOnInit(): void {
    this.spinner.show()
    this.common_api_service.getNannyCarePlans().toPromise().then((res: any) => {
      this.spinner.hide()
      this.carePlans = res?.data;

    },
      (error: any) => {
        this.spinner.hide()

        // Handle any errors that occurred during the API call

      })
    this.getname = localStorage.getItem('name');
    this.getToken = localStorage.getItem('token');
    this.getRole = localStorage.getItem('role')
    this.baby_id = localStorage.getItem('babyId');

    localStorage.setItem('editedOrderId', this.baby_id);
  }

  logout() {
    localStorage.clear();
    // window.location.reload();
    this.route.navigate(['/']);
  }

}
