<p-toast></p-toast>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

  <app-dashboard-navbar></app-dashboard-navbar>

  <!-- Breadcrumb Area -->
  <div *ngFor="let Content of breadcrumb;" class="breadcrumb-area">
    <h1>{{Content.title}}</h1>
    <ol class="breadcrumb">
      <li class="item"><a routerLink="/">Home</a></li>
      <li class="item">{{Content.subTitle}}</li>
      <li class="item">{{Content.title}}</li>
    </ol>
  </div>
  <!-- End Breadcrumb Area -->

  <div class="add-listings-box">
    <div class="row mt-4">
      <div class="col-lg-12 col-md-12">
        <p-table #dt1 [value]="data" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
            [tableStyle]="{ 'min-width': '50rem', width: '100%' }" styleClass="p-datatable-sm p-datatable-customers"
            [rowsPerPageOptions]="[10, 25, 50]" responsiveLayout="scroll" [loading]="" [paginator]="false"
            currentPageReportTemplate="{totalRecords} of records"
            [globalFilterFields]="['name', 'contact_no', 'age', 'date_of_birth']" editMode="row">
          <ng-template pTemplate="header">
            <tr>
              <th style="min-width: 62px">S. No.</th>
              <th>Date Of Birth</th>
              <th>Baby Name</th>
              <th>Contact No.</th>
              <th>Days</th>
              <th>Months</th>
              <th>Years</th>
            </tr>
          </ng-template>
          <!-- table to show data -->
          <ng-template pTemplate="body" let-list let-i="rowIndex">
            <tr>
              <td>
                {{ i + 1 }}
              </td>
              <td>{{ list?.date_of_birth ?? '-' }}</td>
              <td>{{ list?.name ?? '-' }}</td>
              <td>{{ list?.parent_mobile_number ?? '-' }}</td>
              <td>{{ list?.days ?? '-' }}</td>
              <td>{{ list?.months ?? '-' }}</td>
              <td>{{ list?.years ?? '-' }}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="5">No Data Found.</td>
            </tr>
          </ng-template>
        </p-table>
        <div>
        </div>
        <div class="add-listings-btn" style="text-align: right">
          <a [routerLink]="nurce_care_link"> <button class="btn m-2">Nurse Care Plan</button></a>
          <a [routerLink]="groth_monitoring_link"> <button class="btn m-2">Growth Monitoring</button></a>
        </div>
      </div>
      <!----------------------------------------------------------- view dialog --------------------------------------------------------->
      <!-- ***************************************************************************************************************** -->
    </div>
  </div>
  <app-copyrights></app-copyrights>

</div>