<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-bg" *ngFor="let Content of pageTitleContent;">
    <div class="container">
        <h2>{{Content.title}}</h2>
        <form>
            <div class="row m-0 align-items-center">
                <div class="col-lg-4 col-md-12 p-0">
                    <div class="form-group">
                        <label><i class="flaticon-search"></i></label>
                        <input type="text" class="form-control" placeholder="What are you looking for?">
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 p-0">
                    <div class="form-group">
                        <label><i class="flaticon-pin"></i></label>
                        <input type="text" class="form-control" placeholder="Location">
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 p-0">
                    <div class="form-group category-select">
                        <label><i class="flaticon-category"></i></label>
                        <!-- <ngx-select-dropdown (searchChange)="searchChange($event)" name="category-select" tabindex="0" [multiple]="false" [(ngModel)]="singleSelect" [config]="config" [options]="options"></ngx-select-dropdown> -->
                    </div>
                </div>

                <div class="col-lg-2 col-md-12 p-0">
                    <div class="submit-btn">
                        <button type="submit">Search Now</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Listings Area -->
<section class="listings-area ptb-100">
    <div class="container">
        <div class="listings-grid-sorting row align-items-center">
            <div class="col-lg-5 col-md-4 result-count">
                <p><span class="count">9</span> Results</p>
            </div>

            <div class="col-lg-7 col-md-8 ordering">
                <div class="d-flex justify-content-end">
                    <div class="select-box">
                        <label>Sort By:</label>
                        <!-- <ngx-select-dropdown (searchChange)="searchChange($event)" name="ordering-select" tabindex="0" [multiple]="false" [(ngModel)]="singleSelect" [config]="config" [options]="options2"></ngx-select-dropdown> -->
                    </div>

                    <div class="select-box">
                        <label>Distance:</label>
                        <!-- <ngx-select-dropdown (searchChange)="searchChange($event)" name="ordering-select" tabindex="0" [multiple]="false" [(ngModel)]="singleSelect" [config]="config" [options]="options3"></ngx-select-dropdown> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12" *ngFor="let Content of singleListingsItem | paginate: { itemsPerPage: 4, currentPage: verticalListings }">
                <div class="single-listings-item">
                    <div class="row m-0">
                        <div class="col-lg-4 col-md-4 p-0">
                            <div class="listings-image" style="background-image: url({{Content.mainImg}});">
                                <img [src]="Content.mainImg" alt="image">
                                <a routerLink="/{{Content.bookmarkLink}}" class="bookmark-save"><i class="flaticon-heart"></i></a>
                                <a routerLink="/{{Content.categoryLink}}" class="category"><i class="flaticon-cooking"></i></a>
                                <a routerLink="/{{Content.detailsLink}}" class="link-btn"></a>
                                <div class="author">
                                    <div class="d-flex align-items-center">
                                        <img [src]="Content.authorImg" alt="image">
                                        <span>{{Content.authorName}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-8 p-0">
                            <div class="listings-content">
                                <span class="status {{Content.extraClass}}"><i class="flaticon-save"></i> {{Content.openORclose}}</span>
                                <h3><a routerLink="/{{Content.detailsLink}}">{{Content.title}}</a></h3>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="rating">
                                        <i class='{{Star.icon}}' *ngFor="let Star of Content.rating;"></i>
                                        <span class="count">({{Content.ratingCount}})</span>
                                    </div>
                                    <div class="price">
                                        {{Content.price}}
                                    </div>
                                </div>
                                <ul class="listings-meta">
                                    <li><a routerLink="/{{Content.categoryLink}}"><i class="flaticon-furniture-and-household"></i> Restaurant</a></li>
                                    <li><i class="flaticon-pin"></i> {{Content.location}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area text-center">
                    <pagination-controls (pageChange)="verticalListings = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Listings Area -->

<app-footer-style-two></app-footer-style-two>