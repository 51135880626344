<p>feeding-assessment-list works!</p>
<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->
<!-- import { DialogModule } from 'primeng/dialog'; -->

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

  <app-dashboard-navbar></app-dashboard-navbar>

  <!-- Breadcrumb Area -->
  <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
    <h1>{{Content.title}}</h1>
    <ol class="breadcrumb">
      <li class="item"><a routerLink="/">Home</a></li>
      <li class="item">{{Content.subTitle}}</li>
      <li class="item">{{Content.title}}</li>
    </ol>
  </div>

  <!-- <div class="add-listings-box">
        <div class="row justify-content-between align-items-center">
            <div class="form-group col-4">
                <label class="p-0">Name <span class="required">*</span></label>
                <input type="text" name="name" class="form-control" placeholder="Name" (change)="getInput($event)" />
            </div>
            <div class="add-listings-btn mb-0 col-4 text-end">
                <button type="submit" (click)="addTableData()">Add</button>
            </div>
        </div>
    </div> -->
  <div class="add-listings-box">
    <div class="Master-view-box">
      <div class="theader d-flex justify-content-between">
        <div class="d-flex">
          <span class="p-input-icon-left ml-auto mr-2 w-100%">
            <i class="pi pi-search"></i>
            <input pInputText type="text" class="form-control "
                style="text-align: left; padding-left: 20%; height: 45px !important" scriptInjection
                placeholder="Search Keyword" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                [(ngModel)]="filterVal" />
          </span>
          <p-button type="button" class="mx-2" (click)="reset(dt1)" icon="bx bx-reset"
              tooltipPosition="bottom"></p-button>
        </div>
        <div class="add-listings-btn" style="text-align: right;">
          <a routerLink="/portal/four-to-one-year-growth-monitoring" class="nav-link">
            <button type="submit">Add</button>
          </a>
          <!-- <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="onPDFExport()"
          class="p-button-warning mr-2" pTooltip="PDF" tooltipPosition="bottom"></button> -->
        </div>

      </div>
    </div>

    <div class="row mt-4">
      <div class="col-lg-12 col-md-12">
        <p-table #dt1 [value]="allData" dataKey="name" [globalFilterFields]="['name', 'date_of_birth', 'age']"
            [rowsPerPageOptions]="[10, 25, 50]" [rows]="10" [showCurrentPageReport]="true" responsiveLayout="scroll"
            [paginator]="true" currentPageReportTemplate="Showing {first} to
					{last} of {totalRecords} entries">
          <ng-template pTemplate="header">
            <tr>
              <th></th>
              <th style="min-width: 62px">S. No.</th>
              <th>Name</th>
              <th>Date Of Birth</th>
              <th>Days</th>
              <th>Months</th>
              <th>years</th>
            </tr>

          </ng-template>
          <ng-template pTemplate="body" let-product let-expanded="expanded" let-i="rowIndex">
            <tr>
              <td>
                <button type="button" pButton pRipple [pRowToggler]="product"
                    class="p-button-text p-button-rounded p-button-plain"
                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                    (click)="getData(product.baby_id)"></button>
              </td>
              <td>{{i+1}}</td>
              <td>{{product.name}}</td>
              <td>{{product.date_of_birth}}</td>
              <td>{{product.days}}</td>
              <td>{{product.months}}</td>
              <td>{{product.years}}</td>

            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-product>
            <tr>
              <td colspan="7" class="inner-data">
                <div class="p-3 py-0">
                  <p-table [value]="product.development_milestones
                                    " dataKey="id">
                    <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="id" class="serial-width">Sr No.</th>
              <th pSortableColumn="customer">Action</th>
              <th pSortableColumn="date">Date</th>

            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-order let-expanded="expanded" let-i="rowIndex">
            <tr>
              <td>{{i+1}}</td>
              <td><button pButton pRipple class="p-button-rounded p-button-text p-button-info" pTooltip="View"
                    tooltipPosition="bottom" (click)="showMaximizableDialog(order.id)"><i
                      class="far fa-eye"></i></button>
                <button pButton pRipple class="p-button-rounded p-button-icon bx bx-pencil" pTooltip="Edit"
                    tooltipPosition="bottom" (click)="editOnCLick(order.id)">
                </button>
              </td>
              <td>{{ order?.created_at?(order?.created_at | date: 'dd-MM-yyyy' ): "-" }}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="6">There are no order for this product yet.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      </td>
      </tr>
      </ng-template>
      </p-table>

    </div>

    <!----------------------------------------------------------- view dialog --------------------------------------------------------->
    <!-- ***************************************************************************************************************** -->
    <p-dialog header="Baby Details" [(visible)]="displayMaximizable" [modal]="true" [style]="{ width: '90vw' }"
        [draggable]="false" [resizable]="false">
      <!-- Start -->
      <ng-container>
        <div class="row">
          <div class="col-lg-12">
            <div class="my-profile-box">
              <form>
                <!-- Baby detail -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Name :</label>
                      <input type="text" readonly class="form-control" value="{{babyData?.name?? '-' | titlecase }}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12
                                                col-md-12">
                    <div class="form-group">
                      <label class="label-height">Gender :</label>
                      <input type="text" readonly class="form-control" value="{{babyData?.gender ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Date of Birth :</label>
                      <input type="text" readonly class="form-control" value="{{babyData?.date_of_birth ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Days :</label>
                      <input type="text" readonly class="form-control" value="{{babyData?.days ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Months :</label>
                      <input type="text" readonly class="form-control" value="{{babyData?.months ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Years :</label>
                      <input type="text" readonly class="form-control" value="{{babyData?.years ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <h3>Height Weight</h3>
                <div class="row" *ngFor="let data of health">
                  <div class="col-xl-4 col-lg-12
                    col-md-12">
                    <div class="form-group">

                      <label>Time</label>
                      <input type="text" readonly class="form-control"
                          value="{{ data?.time | date:'dd-MM-yyyy'??'-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12
                    col-md-12">
                    <div class="form-group">


                      <label>Height</label>
                      <input type="text" readonly class="form-control" value="{{ data?.height ??'-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12
                    col-md-12">
                    <div class="form-group">
                      <label>Weight</label>
                      <input type="text" readonly class="form-control" value="{{ data?.weight ??'-'}}" />
                    </div>
                  </div>
                </div>
                <h3>Motor and Coordination</h3>
                <!-- Uses hands to support while sitting -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Uses hands to support while sitting</label>
                      <input type="text" readonly class="form-control"
                          value="{{motor_coordination?.use_hand ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{motor_coordination?.use_hand_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!-- Uses hands to support while sitting END -->

                <!-- Rolls from back to tummy and tummy to back -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Rolls from back to tummy and tummy to back</label>
                      <input type="text" readonly class="form-control"
                          value="{{motor_coordination?.roll_back ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{motor_coordination?.roll_back_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!-- Rolls from back to tummy and tummy to back END -->

                <!-- While standing with support, accepts entire weight with legs -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">While standing with support, accepts entire weight with legs</label>
                      <input type="text" readonly class="form-control"
                          value="{{motor_coordination?.standing_with_support ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{motor_coordination?.standing_with_support_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!-- While standing with support, accepts entire weight with legs END -->

                <!-- Reaches for nearby toys while on tummy -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Reaches for nearby toys while on tummy</label>
                      <input type="text" readonly class="form-control"
                          value="{{motor_coordination?.reaches_toys ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{motor_coordination?.reaches_toys_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!-- Reaches for nearby toys while on tummy END -->

                <!-- While lying on back, reaches both hands to play with feet -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">While lying on back, reaches both hands to play with feet</label>
                      <input type="text" readonly class="form-control"
                          value="{{motor_coordination?.lying_on_back ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{motor_coordination?.lying_on_back_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!-- While lying on back, reaches both hands to play with feet END -->

                <!-- Transfer toys from one hand to the other -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Transfer toys from one hand to the other</label>
                      <input type="text" readonly class="form-control"
                          value="{{motor_coordination?.transfer_toys ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{motor_coordination?.transfer_toys_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!-- Transfer toys from one hand to the other END -->

                <!-- Pulls to stand and cruise along furniture -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Pulls to stand and cruise along furniture</label>
                      <input type="text" readonly class="form-control"
                          value="{{motor_coordination?.pulls_to_stand ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{motor_coordination?.pulls_to_stand_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!-- Pulls to stand and cruise along furniture END -->

                <!-- Stands alone and takes several independent steps -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Stands alone and takes several independent steps</label>
                      <input type="text" readonly class="form-control"
                          value="{{motor_coordination?.stands_alone ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{motor_coordination?.stands_alone_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!-- Stands alone and takes several independent steps END -->


                <!-- Moves in and out of various positions to explore environment -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Moves in and out of various positions to explore environment</label>
                      <input type="text" readonly class="form-control"
                          value="{{motor_coordination?.move_in_out ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{motor_coordination?.move_in_out_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!-- Moves in and out of various positions to explore environment END -->

                <!-- Able to lift head forward when being pulled to ditting, from lying on back -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Able to lift head forward when being pulled to ditting, from lying on
                        back</label>
                      <input type="text" readonly class="form-control"
                          value="{{motor_coordination?.lift_head ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{motor_coordination?.lift_head_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!-- Able to lift head forward when being pulled to ditting, from lying on back END -->

                <!-- Able to play on tummy for short burst of time -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Able to play on tummy for short burst of time</label>
                      <input type="text" readonly class="form-control"
                          value="{{motor_coordination?.play_on_tummy ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{motor_coordination?.play_on_tummy_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!-- Able to play on tummy for short burst of time END -->

                <!-- Able to pick up head and prop on elbows during tummy time -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Able to pick up head and prop on elbows during tummy time</label>
                      <input type="text" readonly class="form-control"
                          value="{{motor_coordination?.pickup_head ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{motor_coordination?.pickup_head_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!-- Able to pick up head and prop on elbows during tummy time END -->

                <!-- Able to turn head to visually follow moving toys and people -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Able to turn head to visually follow moving toys and people</label>
                      <input type="text" readonly class="form-control"
                          value="{{motor_coordination?.turn_head ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{motor_coordination?.turn_head_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!-- Able to turn head to visually follow moving toys and people END -->

                <!-- Enjoys and seeks out various ways to move and play -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Enjoys and seeks out various ways to move and play</label>
                      <input type="text" readonly class="form-control"
                          value="{{motor_coordination?.enjoy_and_seeks ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{motor_coordination?.enjoy_and_seeks_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!-- Enjoys and seeks out various ways to move and play END -->

                <!--  SENSORY SECTION -->
                <h3> SENSORY SECTION</h3>
                <!--  Uses both hands to explore toys -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Uses both hands to explore toys</label>
                      <input type="text" readonly class="form-control" value="{{sensory?.explore_toys ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{sensory?.explore_toys_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--  Uses both hands to explore toys END -->

                <!--  Happy when not hungry or tired -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Happy when not hungry or tired</label>
                      <input type="text" readonly class="form-control" value="{{sensory?.happy_not_angry ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{sensory?.happy_not_angry_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--  Happy when not hungry or tired END -->

                <!--  Brings hands and objects to mouth -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Brings hands and objects to mouth</label>
                      <input type="text" readonly class="form-control" value="{{sensory?.brings_hand_object ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{sensory?.brings_hand_object_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--  Brings hands and objects to mouth END -->

                <!--  Calms with rocking, touching, and gentle sounds -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Calms with rocking, touching, and gentle sounds</label>
                      <input type="text" readonly class="form-control" value="{{sensory?.rocking_touching ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{sensory?.rocking_touching_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--  Calms with rocking, touching, and gentle sounds END -->

                <!--  Not upset by everyday sounds -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Not upset by everyday sounds</label>
                      <input type="text" readonly class="form-control" value="{{sensory?.not_upset ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{sensory?.not_upset_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--  Not upset by everyday sounds END -->

                <!--  Enjoys a variety of movement -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Enjoys a variety of movement</label>
                      <input type="text" readonly class="form-control" value="{{sensory?.enjoy_movement ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{sensory?.enjoy_movement_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--  Enjoys a variety of movement END -->

                <!--  Enjoys listening to songs -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Enjoys listening to songs</label>
                      <input type="text" readonly class="form-control" value="{{sensory?.listening_songs ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{sensory?.listening_songs_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--  Enjoys listening to songs END -->

                <!--  Explores toys with hands, fingers and mouth -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Explores toys with hands, fingers and mouth</label>
                      <input type="text" readonly class="form-control"
                          value="{{sensory?.explore_toys_with_hands ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{sensory?.explore_toys_with_hands_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--  Explores toys with hands, fingers and mouth END -->

                <!--  Crawls to or away from objects baby sees in the distance -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Crawls to or away from objects baby sees in the distance</label>
                      <input type="text" readonly class="form-control" value="{{sensory?.crawls ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" value="{{sensory?.crawls_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--  Crawls to or away from objects baby sees in the distance END -->

                <!--  Enjoys a variety of movements -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Enjoys a variety of movements</label>
                      <input type="text" readonly class="form-control" value="{{sensory?.enjoy_movements ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{sensory?.enjoy_movements_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--  Enjoys a variety of movements END -->
                <!--  SENSORY SECTION END-->

                <!--  COMMUNICATION SECTION -->
                <h3> COMMUNICATION SECTION</h3>
                <!--  Knows his/ her name when called -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Knows his/ her name when called</label>
                      <input type="text" readonly class="form-control" value="{{communication?.knows_name ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{communication?.knows_name_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--  Knows his/ her name when called END -->

                <!--  Reacts to sudden noises and sounds -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Reacts to sudden noises and sounds</label>
                      <input type="text" readonly class="form-control" value="{{communication?.reacts ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{communication?.reacts_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--  Reacts to sudden noises and sounds END -->

                <!--  Listens and responds when spoken to -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Listens and responds when spoken to</label>
                      <input type="text" readonly class="form-control"
                          value="{{communication?.listen_and_respond ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{communication?.listen_and_respond_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--  Listens and responds when spoken to END -->

                <!--  Notices toys that makes sounds -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Notices toys that makes sounds</label>
                      <input type="text" readonly class="form-control" value="{{communication?.notice_toys ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{communication?.notice_toys_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--  Notices toys that makes sounds END -->

                <!--  Meaningfully uses "mama" or "dada" -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Meaningfully uses "mama" or "dada"</label>
                      <input type="text" readonly class="form-control" value="{{communication?.meaning_uses ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{communication?.meaning_uses_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--  Meaningfully uses "mama" or "dada" END -->

                <!--  Responds to simple directions like "Come here" -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Responds to simple directions like "Come here"</label>
                      <input type="text" readonly class="form-control"
                          value="{{communication?.res_simple_direction ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{communication?.res_simple_direction_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--  Responds to simple directions like "Come here" END -->

                <!--  Say one or two words -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Say one or two words</label>
                      <input type="text" readonly class="form-control" value="{{communication?.say_words ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{communication?.say_words_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--  Say one or two words END -->

                <!--  Imitates speech sounds -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Imitates speech sounds</label>
                      <input type="text" readonly class="form-control"
                          value="{{communication?.imitates_sounds ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{communication?.imitates_sounds_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--  Imitates speech sounds END -->

                <!--  Babbling has sounds and rhythms of speech -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Babbling has sounds and rhythms of speech</label>
                      <input type="text" readonly class="form-control"
                          value="{{communication?.sounds_rhythms ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{communication?.sounds_rhythms_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--  Babbling has sounds and rhythms of speech END -->

                <!--  Pays attention to where you are looking and pointing  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Pays attention to where you are looking and pointing </label>
                      <input type="text" readonly class="form-control"
                          value="{{communication?.pays_attention ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{communication?.pays_attention_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--  Pays attention to where you are looking and pointing  END -->


                <!--   Responds to "No"  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Responds to "No" </label>
                      <input type="text" readonly class="form-control" value="{{communication?.respond ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{communication?.respond_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--   Responds to "No"  END -->

                <!--    Begins to use hand movements to communicate wants and needs e.g. reaches to be picked up  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Begins to use hand movements to communicate wants and needs e.g.
                        reaches to be picked up </label>
                      <input type="text" readonly class="form-control"
                          value="{{communication?.hand_movement ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{communication?.hand_movement_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--    Begins to use hand movements to communicate wants and needs e.g. reaches to be picked up  END -->

                <!-- FEEDING -->
                <h3>FEEDING</h3>
                <!-- Shows interest in food  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Shows interest in food </label>
                      <input type="text" readonly class="form-control" value="{{feeding?.show_interst ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{feeding?.show_interst_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--   Shows interest in food  END -->

                <!-- Opens mouth as spoon approaches  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Opens mouth as spoon approaches </label>
                      <input type="text" readonly class="form-control" value="{{feeding?.open_mouth ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{feeding?.open_mouth_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--   Opens mouth as spoon approaches  END -->

                <!-- Opens mouth as spoon approaches  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Opens mouth as spoon approaches </label>
                      <input type="text" readonly class="form-control" value="{{feeding?.open_mouth ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{feeding?.open_mouth_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--   Opens mouth as spoon approaches  END -->

                <!-- Moves pureed food from front of mouth to back  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Moves pureed food from front of mouth to back </label>
                      <input type="text" readonly class="form-control" value="{{feeding?.pureed_food ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{feeding?.pureed_food_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--   Moves pureed food from front of mouth to back  END -->

                <!-- Begins to eat cereals and pureed foods - smooth, pureed food (single ingredient only)  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Begins to eat cereals and pureed foods - smooth, pureed food (single
                        ingredient only) </label>
                      <input type="text" readonly class="form-control" value="{{feeding?.begins_to_eat ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{feeding?.begins_to_eat_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--   Begins to eat cereals and pureed foods - smooth, pureed food (single ingredient only)  END -->

                <!-- Finger feeds self  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Finger feeds self </label>
                      <input type="text" readonly class="form-control" value="{{feeding?.finger_feed ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{feeding?.finger_feed_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--   Finger feeds self  END -->

                <!-- Eating an increasing variety of food  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Eating an increasing variety of food </label>
                      <input type="text" readonly class="form-control" value="{{feeding?.increasing_food ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{feeding?.increasing_food_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--   Eating an increasing variety of food  END -->

                <!-- Begins to use an open cup  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Begins to use an open cup </label>
                      <input type="text" readonly class="form-control" value="{{feeding?.open_cup ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{feeding?.open_cup_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--   Begins to use an open cup  END -->

                <!-- Ready to try soft cooked vegetables, soft fruits, and finger food  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Ready to try soft cooked vegetables, soft fruits, and finger food
                      </label>
                      <input type="text" readonly class="form-control" value="{{feeding?.try_cooked_vegs ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{feeding?.try_cooked_vegs_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--   Ready to try soft cooked vegetables, soft fruits, and finger food  END -->

                <!-- Might be ready to start self-feeding with utensils  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Might be ready to start self-feeding with utensils </label>
                      <input type="text" readonly class="form-control" value="{{feeding?.try_cooked_vegs ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{feeding?.try_cooked_vegs_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--   Might be ready to start self-feeding with utensils  END -->

                <!-- Enjoys a greater variety of smells and tastes  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Enjoys a greater variety of smells and tastes </label>
                      <input type="text" readonly class="form-control" value="{{feeding?.enjoy_smell_taste ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{feeding?.enjoy_smell_taste_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--   Enjoys a greater variety of smells and tastes  END -->

                <!--FEEDING END  -->

                <!-- PLAY AND SOCIAL SKILLS SECTION -->
                <h3>Play and Social Skills</h3>
                <!-- Enjoys playful interactions with others, e.g. peek a boo  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Enjoys playful interactions with others, e.g. peek a boo </label>
                      <input type="text" readonly class="form-control" value="{{play?.playful_interaction ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{play?.playful_interaction_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--   Enjoys playful interactions with others, e.g. peek a boo  END -->

                <!-- Vocalizing in response to playful interactions  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Vocalizing in response to playful interactions </label>
                      <input type="text" readonly class="form-control" value="{{play?.vocalizing ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{play?.vocalizing_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!--   Vocalizing in response to playful interactions  END -->

                <!-- Turns head towards sounds  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Turns head towards sounds </label>
                      <input type="text" readonly class="form-control" value="{{play?.turns_head ?? '-'}}" readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" value="{{play?.turns_head_description ?? '-'}}"
                          readonly />
                    </div>
                  </div>
                </div>
                <!--   Turns head towards sounds  END -->

                <!-- Maintains eye contact with familiar people during playful interaction  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Maintains eye contact with familiar people during playful
                        interaction </label>
                      <input type="text" readonly class="form-control" value="{{play?.eye_contact ?? '-'}}" readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" value="{{play?.eye_contact_description ?? '-'}}"
                          readonly />
                    </div>
                  </div>
                </div>
                <!--   Maintains eye contact with familiar people during playful interaction  END -->

                <!-- Enjoys playing with toys of varied textures  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Enjoys playing with toys of varied textures </label>
                      <input type="text" readonly class="form-control" value="{{play?.enjoy_playing ?? '-'}}"
                          readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{play?.enjoy_playing_description ?? '-'}}" readonly />
                    </div>
                  </div>
                </div>
                <!--   Enjoys playing with toys of varied textures  END -->

                <!-- Enjoys musical toys  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Enjoys musical toys </label>
                      <input type="text" readonly class="form-control" value="{{play?.enjoy_toys ?? '-'}}" readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" value="{{play?.enjoy_toys_description ?? '-'}}"
                          readonly />
                    </div>
                  </div>
                </div>
                <!--   Enjoys musical toys  END -->

                <!-- Raises hands to be picked up  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Raises hands to be picked up </label>
                      <input type="text" readonly class="form-control" value="{{play?.raise_hands ?? '-'}}" readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" value="{{play?.raise_hands_description ?? '-'}}"
                          readonly />
                    </div>
                  </div>
                </div>
                <!--   Raises hands to be picked up  END -->

                <!-- Enjoys various types of movements, such as being gently swung  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Enjoys various types of movements, such as being gently swung
                      </label>
                      <input type="text" readonly class="form-control" value="{{play?.enjoy_movement ?? '-'}}"
                          readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{play?.enjoy_movement_description ?? '-'}}" readonly />
                    </div>
                  </div>
                </div>
                <!--   Enjoys various types of movements, such as being gently swung  END -->

                <!-- Maintains eye contact with people during playful interactions  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Maintains eye contact with people during playful interactions
                      </label>
                      <input type="text" readonly class="form-control" value="{{play?.eye_contact_interaction ?? '-'}}"
                          readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{play?.eye_contact_interaction_description ?? '-'}}" readonly />
                    </div>
                  </div>
                </div>
                <!--   Maintains eye contact with people during playful interactions  END -->

                <!-- Points to object of interest by 1 year of age  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Points to object of interest by 1 year of age </label>
                      <input type="text" readonly class="form-control" value="{{play?.point_to_object ?? '-'}}"
                          readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{play?.point_to_object_description ?? '-'}}" readonly />
                    </div>
                  </div>
                </div>
                <!--   Points to object of interest by 1 year of age  END -->

                <!-- Frequently explores the environment when placed on floor  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Frequently explores the environment when placed on floor </label>
                      <input type="text" readonly class="form-control" value="{{play?.explore_env ?? '-'}}" readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" value="{{play?.explore_env_description ?? '-'}}"
                          readonly />
                    </div>
                  </div>
                </div>
                <!--   Frequently explores the environment when placed on floor  END -->

                <!-- Enjoys moving to get desirable toy  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Enjoys moving to get desirable toy </label>
                      <input type="text" readonly class="form-control" value="{{play?.desirable_toy ?? '-'}}"
                          readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{play?.desirable_toy_description ?? '-'}}" readonly />
                    </div>
                  </div>
                </div>
                <!--   Enjoys moving to get desirable toy  END -->
                <!-- PLAY AND SOCIAL SKILLS SECTION END-->

                <!-- SELF-EXPRESSION SECTION -->
                <h3>SELF-EXPRESSION SECTION</h3>

                <!-- Comforted by cuddling  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Comforted by cuddling </label>
                      <input type="text" readonly class="form-control" value="{{self_exp?.cuddling ?? '-'}}" readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" value="{{self_exp?.cuddling_description ?? '-'}}"
                          readonly />
                    </div>
                  </div>
                </div>
                <!--   Comforted by cuddling  END -->

                <!-- Happy when not hungry or tired  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Happy when not hungry or tired </label>
                      <input type="text" readonly class="form-control" value="{{self_exp?.not_angry ?? '-'}}"
                          readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{self_exp?.not_angry_description ?? '-'}}" readonly />
                    </div>
                  </div>
                </div>
                <!--   Happy when not hungry or tired  END -->

                <!-- Enjoys varied playful movement experiences such as rocking, touching, and calm sounds  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Enjoys varied playful movement experiences such as rocking,
                        touching, and calm sounds </label>
                      <input type="text" readonly class="form-control" value="{{self_exp?.enjoy_movement ?? '-'}}"
                          readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{self_exp?.enjoy_movement_description2 ?? '-'}}" readonly />
                    </div>
                  </div>
                </div>
                <!--   Enjoys varied playful movement experiences such as rocking, touching, and calm sounds  END -->

                <!-- Calms with rocking, touching, and gentle sounds  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Calms with rocking, touching, and gentle sounds </label>
                      <input type="text" readonly class="form-control" value="{{self_exp?.calms ?? '-'}}" readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" value="{{self_exp?.calms_description ?? '-'}}"
                          readonly />
                    </div>
                  </div>
                </div>
                <!--   Calms with rocking, touching, and gentle sounds  END -->

                <!-- Has grown accustomed to everyday sounds and is usually not startled by it  -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Has grown accustomed to everyday sounds and is usually not startled
                        by it </label>
                      <input type="text" readonly class="form-control" value="{{self_exp?.grown_accustomed ?? '-'}}"
                          readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{self_exp?.grown_accustomed_description ?? '-'}}" readonly />
                    </div>
                  </div>
                </div>
                <!--   Has grown accustomed to everyday sounds and is usually not startled by it  END -->

                <!-- Enjoys a wide variety of touch, noisem and smell -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Enjoys a wide variety of touch, noisem and smell </label>
                      <input type="text" readonly class="form-control" value="{{self_exp?.grown_accustomed ?? '-'}}"
                          readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{self_exp?.grown_accustomed_description ?? '-'}}" readonly />
                    </div>
                  </div>
                </div>
                <!-- Enjoys a wide variety of touch, noisem and smell end -->
                <!-- Cries and notices when hurt -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Cries and notices when hurt</label>
                      <input type="text" readonly class="form-control" value="{{self_exp?.grown_accustomed ?? '-'}}"
                          readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                          value="{{self_exp?.grown_accustomed_description ?? '-'}}" readonly />
                    </div>
                  </div>
                </div>

                <!-- Cries and notices when hurt end -->

              </form>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template pTemplate="footer">
        <p-button (click)="displayMaximizable=
                            false" label="Ok" styleClass="p-button-text"></p-button>
      </ng-template>
    </p-dialog>
  </div>
</div>
<app-copyrights></app-copyrights>