<p-toast></p-toast>
<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item">{{Content.subTitle}}</li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <form [formGroup]="nannyTaskForm" (ngSubmit)="saveNannyTasksForm(nannyTask)" #nannyTask="ngForm">
        <!-- BABY DETAILS SECTION -->
        <div class="add-listings-box">
            <h3>Baby Details</h3>
            <div class="row">
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="p-0">Name<span class="required">*</span></label>
                        <p-dropdown [options]="babyDetails" (onChange)="getBabyDetails($event)" optionValue="id"
                            optionLabel="name" [filter]="true" filterBy="name" formControlName="baby_details_id"
                            placeholder="Select Baby's Name" [resetFilterOnHide]="true" [class.is-invalid]="(nannyTask.submitted &&
                        nannyTaskForm.get('baby_details_id')?.invalid)
                        ||
                        nannyTaskForm.get('baby_details_id')?.invalid
                        &&
                        nannyTaskForm.get('baby_details_id').touched">
                        </p-dropdown>
                        <div class="invalid-feedback">
                            Name is required
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Gender</label>
                        <div class="card flex justify-content-center">
                            <!-- <p-dropdown [options]="genderArray" optionLabel="name" optionValue="value" readonly
                                [(ngModel)]="babyGender" [ngModelOptions]="{standalone: true}"
                                placeholder="Select Baby's Gender" [resetFilterOnHide]="true" [readonly]="true">
                            </p-dropdown> -->
                            <input type="text" class="form-control" [(ngModel)]="babyGender"
                                [ngModelOptions]="{standalone: true}" placeholder="Select Baby's Gender" required
                                [readonly]="true">
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Date of birth</label>
                        <div class="card flex justify-content-center">
                            <!-- <p-calendar [(ngModel)]="selectedDate" [ngModelOptions]="{standalone: true}"
                                [showIcon]="true" [readonlyInput]="true" placeholder="MM/DD/YYYY"></p-calendar> -->
                            <input type="text" name="name" class="form-control" placeholder="DD/MM/YYYY"
                                [value]="selectedDate" readonly />

                            <!-- <input type="text" class="form-control" [(ngModel)]="selectedDate" [ngModelOptions]="{standalone: true}" placeholder="MM/DD/YYYY"
                                required [readonly]="true"> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Days</label>
                        <input type="text" name="name" class="form-control" placeholder="Days" [value]="days"
                            readonly />
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Months</label>
                        <input type="text" name="name" class="form-control" placeholder="Months" [value]="month"
                            readonly />
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Years</label>
                        <input type="text" name="name" class="form-control" placeholder="Years" [value]="years"
                            readonly />
                    </div>
                </div>
            </div>
        </div>

        <!-- Feeding -->
        <div class="add-listings-box" formGroupName="feeding_json">
            <h3 class="mb-0">Feeding</h3>
            <div>
                <!-- <div "> -->
                <div class="form-check checkbox-list p-0">
                    <div class="row py-3">
                        <label class="checkbox checkbox-outline col-lg-4 col-sm-6 col-12">
                            <input formControlName="feeding_type_breast" type="checkbox" value="breastFeeding"
                                name="feeding_type_breast" (change)="handleInput($event, 'breast-feeding')" />
                            <span></span>
                            Breast
                        </label>

                        <label class="checkbox checkbox-outline col-lg-4 col-sm-6 col-12 mt-sm-0 mt-3">
                            <input formControlName="feeding_type_formula" type="checkbox" value="formulaFeeding"
                                name="feeding_type_formula" (change)="handleInput($event, 'formula-feeding')" />
                            <span></span>
                            Formula
                        </label>

                    </div>
                </div>
                <!-- </div> -->
            </div>

            <!-- Breast feeding dropdown-->
            <div class=" row form-group">
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="p-0">Breast frequency:</label>
                        <p-dropdown [options]="frequency" formControlName="breast_frequency" optionLabel="frequency"
                            [filter]="true" filterBy="frequency" placeholder="Frequency" [resetFilterOnHide]="true" [class.is-invalid]="(nannyTask.submitted &&
                            nannyTaskForm.get(['feeding_json','breast_frequency'])?.invalid)
                            ||
                            nannyTaskForm.get(['feeding_json','breast_frequency'])?.invalid
                            &&
                            nannyTaskForm.get(['feeding_json','breast_frequency']).touched">
                        </p-dropdown>
                        <div class="invalid-feedback">
                            Breast frequency is required
                        </div>
                    </div>


                </div>

                <!-- Formula feeding dropdown -->
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="p-0">Formula frequency:</label>
                        <p-dropdown [options]="frequency" formControlName="formula_frequency" optionLabel="frequency"
                            [filter]="true" filterBy="frequency" placeholder="Frequency" [resetFilterOnHide]="true" [class.is-invalid]="(nannyTask.submitted &&
                            nannyTaskForm.get(['feeding_json','formula_frequency'])?.invalid)
                            ||
                            nannyTaskForm.get(['feeding_json','formula_frequency'])?.invalid
                            &&
                            nannyTaskForm.get(['feeding_json','formula_frequency']).touched">
                        </p-dropdown>
                        <div class="invalid-feedback">
                            Formula frequency is required
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-12 m-0">
                    <div class="form-group">
                        <label class="p-0">Amount (ml):</label>
                        <input type="number" formControlName="feeding_amount_per" name="name" class="form-control"
                            placeholder="Amount (ml)" [class.is-invalid]="(nannyTask.submitted &&
                            nannyTaskForm.get(['feeding_json','feeding_amount_per'])?.invalid)
                            ||
                            nannyTaskForm.get(['feeding_json','feeding_amount_per'])?.invalid
                            &&
                            nannyTaskForm.get(['feeding_json','feeding_amount_per']).touched"/>
                               <div class="invalid-feedback">
                                Amount is required
                            </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-12 m-0 offset-8">
                    <div class="form-group ml-0 col-12 p-0 mb-0">
                        <label class="p-0">Type of formula:</label>
                        <div class="col-12 p-0">
                            <input type="text" formControlName="type_of_formula" name="name" class="form-control"
                                placeholder="Formula" [class.is-invalid]="(nannyTask.submitted &&
                                nannyTaskForm.get(['feeding_json','type_of_formula'])?.invalid)
                                ||
                                nannyTaskForm.get(['feeding_json','type_of_formula'])?.invalid
                                &&
                                nannyTaskForm.get(['feeding_json','type_of_formula']).touched" />
                                   <div class="invalid-feedback">
                                    Type of formula is required
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Others Section -->
        <div class="add-listings-box" formGroupName="other_feeding_json">
            <h3>Others</h3>
            <div class="row">
                <div class="form-group my-2 col-sm-4 col-12">
                    <label class="subHeading p-0">Others:</label>
                    <div class="form-check p-0 checkbox-list">
                        <label class="checkbox checkbox-outline p-0">
                            <input formControlName="solid_other_snack" type="checkbox" value="snack" name="checkboxes"
                                (change)="handleInput($event, 'snacks')" />
                            <span></span>
                            Snack
                        </label>
                    </div>
                </div>
                <!-- snack component -->
                <!-- <div class=" row form-group"> -->
                <div class="col-sm-4 col-12">
                    <div class="form-group">
                        <label class="p-0">Time:</label>
                        <div class="card flex justify-content-center">
                            <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time"
                                formControlName="solid_other_snack_time"[class.is-invalid]="(nannyTask.submitted &&
                                nannyTaskForm.get(['other_feeding_json','solid_other_snack_time'])?.invalid)
                                ||
                                nannyTaskForm.get(['other_feeding_json','solid_other_snack_time'])?.invalid
                                &&
                                nannyTaskForm.get(['other_feeding_json','solid_other_snack_time']).touched" ></p-calendar>
                                  <div class="invalid-feedback">
                                    Time is required
                                </div>
                        </div>
                    </div>
                </div>


                <div class="col-sm-4 col-12 m-0">
                    <div class="form-group ml-0 col-12 p-0 mb-sm-0">
                        <label class="p-0">Food components:</label>
                        <div class="col-12 p-0">
                            <input formControlName="soild_other_snack_food_components" type="text" name="name"
                                class="form-control" placeholder="Food components" [class.is-invalid]="(nannyTask.submitted &&
                                nannyTaskForm.get(['other_feeding_json','soild_other_snack_food_components'])?.invalid)
                                ||
                                nannyTaskForm.get(['other_feeding_json','soild_other_snack_food_components'])?.invalid
                                &&
                                nannyTaskForm.get(['other_feeding_json','soild_other_snack_food_components']).touched"/>
                                <div class="invalid-feedback">
                                    Food components is required
                                </div>
                        </div>
                    </div>
                </div>
                <!-- Regular meal -->
                <label class="p-0 subHeading">Regular Meal:</label>
                <div class="form-group mb-0 col-sm-4 col-12 d-flex align-items-center">
                    <div class="form-check p-0 checkbox-list">
                        <label class="checkbox checkbox-outline p-0 mt-sm-auto mt-3">
                            <input formControlName="regular_meal_type" type="checkbox" value="breakfast"
                                name="checkboxes" (change)="handleInput($event, 'regularMeal')" />
                            <span></span>
                            Breakfast
                        </label>
                    </div>
                </div>
                <!-- breakfast -->
                <div class="col-sm-4 col-12">
                    <div class="form-group">
                        <label class="p-0">Time:</label>
                        <div class="card flex justify-content-center">
                            <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time"
                                formControlName="regular_meal_time" [disabled]="breakfast" [class.is-invalid]="(nannyTask.submitted &&
                                nannyTaskForm.get(['other_feeding_json','regular_meal_time'])?.invalid)
                                ||
                                nannyTaskForm.get(['other_feeding_json','regular_meal_time'])?.invalid
                                &&
                                nannyTaskForm.get(['other_feeding_json','regular_meal_time']).touched" ></p-calendar>
                                <div class="invalid-feedback">
                                    Time is required
                                </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 col-12 m-0">
                    <div class="form-group ml-0 col-12 p-0 mb-0">
                        <label class="p-0">Food components:</label>
                        <div class="col-12 p-0">
                            <input formControlName="regular_meal_food_components" type="text" name="name"
                                class="form-control" placeholder="Food components" [class.is-invalid]="(nannyTask.submitted &&
                                nannyTaskForm.get(['other_feeding_json','regular_meal_food_components'])?.invalid)
                                ||
                                nannyTaskForm.get(['other_feeding_json','regular_meal_food_components'])?.invalid
                                &&
                                nannyTaskForm.get(['other_feeding_json','regular_meal_food_components']).touched" />
                                <div class="invalid-feedback">
                                    Food components is required
                                </div>
                        </div>
                    </div>
                </div>
                <!-- </div> -->
            </div>


            <!-- Lunck checkbox -->
            <div class="row">
                <div class="form-group mb-0 col-sm-4 col-12 d-flex align-items-center">
                    <div class="form-check p-0 checkbox-list">
                        <label class="checkbox checkbox-outline p-0 mt-sm-auto mt-4">
                            <input formControlName="regular_meal_type" type="checkbox" value="lunch" name="checkboxes"
                                (change)="handleInput($event, 'regularMeal')" />
                            <span></span>
                            Lunch
                        </label>
                    </div>
                </div>


                <!-- lunch -->
                <!-- <div class=" row form-group mb-0"> -->
                <div class="col-sm-4 col-12">
                    <div class="form-group">
                        <label class="p-0">Time:</label>
                        <div class="card flex justify-content-center">
                            <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time"
                                formControlName="lunch_meal_time"[class.is-invalid]="(nannyTask.submitted &&
                                nannyTaskForm.get(['other_feeding_json','lunch_meal_time'])?.invalid)
                                ||
                                nannyTaskForm.get(['other_feeding_json','lunch_meal_time'])?.invalid
                                &&
                                nannyTaskForm.get(['other_feeding_json','lunch_meal_time']).touched" ></p-calendar>
                                <div class="invalid-feedback">
                                    Time is required
                                </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 col-12 m-0">
                    <div class="form-group ml-0 col-12 p-0 mb-0">
                        <label class="p-0">Food components:</label>
                        <div class="col-12 p-0">
                            <input formControlName="lunch_meal_food_components" type="text" name="name"
                                class="form-control" placeholder="Food components" [class.is-invalid]="(nannyTask.submitted &&
                                nannyTaskForm.get(['other_feeding_json','lunch_meal_food_components'])?.invalid)
                                ||
                                nannyTaskForm.get(['other_feeding_json','lunch_meal_food_components'])?.invalid
                                &&
                                nannyTaskForm.get(['other_feeding_json','lunch_meal_food_components']).touched"/>
                                <div class="invalid-feedback">
                                    Food components is required
                                </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Dinner checkbox -->
            <div class="row">
                <div class="form-group col-sm-4 col-12 d-flex align-items-center">
                    <div class="form-check p-0 checkbox-list">
                        <label class="checkbox checkbox-outline p-0 mt-sm-auto mt-4">
                            <input formControlName="regular_meal_type" type="checkbox" value="dinner" name="dinner"
                                (change)="handleInput($event, 'regularMeal')" />
                            <span></span>
                            Dinner
                        </label>
                    </div>
                </div>
                <!-- Dinner -->
                <div class="col-sm-4 col-12">
                    <div class="form-group">
                        <label class="p-0">Time:</label>
                        <div class="card flex justify-content-center">
                            <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time"
                                formControlName="dinner_meal_time" [class.is-invalid]="(nannyTask.submitted &&
                                nannyTaskForm.get(['other_feeding_json','dinner_meal_time'])?.invalid)
                                ||
                                nannyTaskForm.get(['other_feeding_json','dinner_meal_time'])?.invalid
                                &&
                                nannyTaskForm.get(['other_feeding_json','dinner_meal_time']).touched"></p-calendar>
                                <div class="invalid-feedback">
                                    Time is required
                                </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 col-12 m-0">
                    <div class="form-group ml-0 col-12 p-0 mb-sm-0">
                        <label class="p-0">Food components:</label>
                        <div class="col-12 p-0">
                            <input type="text" name="name" class="form-control"
                                formControlName="dinner_meal_food_components" placeholder="Food components" [class.is-invalid]="(nannyTask.submitted &&
                                nannyTaskForm.get(['other_feeding_json','dinner_meal_food_components'])?.invalid)
                                ||
                                nannyTaskForm.get(['other_feeding_json','dinner_meal_food_components'])?.invalid
                                &&
                                nannyTaskForm.get(['other_feeding_json','dinner_meal_food_components']).touched" />
                                <div class="invalid-feedback">
                                    Food components is required
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <!-- Diapering -->
        <div class="add-listings-box">
            <h3>Diaper Change
            </h3>
            <div class="row">
                <div class="col-lg-4 col-12">
                    <label class="subHeading py-3">Diaper Change</label>
                    <div class="form-group">
                        <label class="p-0">Frequency:</label>
                        <div class="card flex justify-content-center">
                            <p-dropdown [options]="frequency" formControlName="diaper_change_frequency"
                                optionLabel="frequency" [filter]="true" filterBy="frequency" placeholder="Frequency"
                                [resetFilterOnHide]="true">
                                <ng-template pTemplate="selectedItem">
                                    <div class="flex align-items-center gap-2"
                                        *ngIf="nannyTaskForm?.value?.diaper_change_frequency">
                                        <div>{{ nannyTaskForm?.value?.diaper_change_frequency?.frequency }}</div>
                                    </div>
                                </ng-template>
                                <ng-template let-frequency pTemplate="item">
                                    <div class="flex align-items-center gap-2">
                                        <div>{{ frequency?.frequency }}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Bathing -->
            <div formGroupName="bathing_json">
                <label class="subHeading py-3 px-0 mb-0">Bathing:</label>
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12 d-flex align-items-center">
                        <div class="form-group mb-2">
                            <div class="form-check p-0 checkbox-list">
                                <label class="checkbox checkbox-outline p-0">
                                    <p>
                                        <input formControlName="bathing_type" type="radio" value="Sponge" id="sponge"
                                            (change)="handleInput($event, 'bathing')" />
                                        <label for="sponge">Sponge</label>
                                    </p>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="p-0">Frequency:</label>
                            <div class="card flex justify-content-center">
                                <p-dropdown formControlName="bathing_type_sponge_frequency" [options]="frequency"
                                    optionLabel="frequency" [filter]="true" filterBy="frequency" placeholder="Frequency"
                                    [resetFilterOnHide]="true" [class.is-invalid]="(nannyTask.submitted &&
                                    nannyTaskForm.get(['bathing_json','bathing_type_sponge_frequency'])?.invalid)
                                    ||
                                    nannyTaskForm.get(['bathing_json','bathing_type_sponge_frequency'])?.invalid
                                    &&
                                    nannyTaskForm.get(['bathing_json','bathing_type_sponge_frequency']).touched" >
                                </p-dropdown>
                                <div class="invalid-feedback">
                                    Frequency is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Tub bathing -->
                    <div class="row m-0 p-0">
                        <div class="col-lg-4 col-sm-6 col-12 d-flex align-items-center">
                            <div class="form-group mb-2">
                                <div class="form-check p-0 checkbox-list">
                                    <label class="checkbox checkbox-outline p-0">
                                        <p>
                                            <input formControlName="bathing_type" type="radio" value="Tub" id="tub"
                                                (change)="handleInput($event, 'bathing')" />
                                            <label for="tub">Tub</label>
                                        </p>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="p-0">Frequency:</label>
                                <div class="card flex justify-content-center">
                                    <p-dropdown formControlName="bathing_type_tub_frequency" [options]="frequency"
                                        [disabled]="tub" optionLabel="frequency" [filter]="true" filterBy="frequency"
                                        placeholder="Frequency" [resetFilterOnHide]="true" [class.is-invalid]="(nannyTask.submitted &&
                                        nannyTaskForm.get(['bathing_json','bathing_type_tub_frequency'])?.invalid)
                                        ||
                                        nannyTaskForm.get(['bathing_json','bathing_type_tub_frequency'])?.invalid
                                        &&
                                        nannyTaskForm.get(['bathing_json','bathing_type_tub_frequency']).touched">
                                    </p-dropdown>
                                    <div class="invalid-feedback">
                                        Frequency is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- Changing clothes -->
            <div formGroupName="clothes_json">
                <div class="row">
                    <label class="subHeading py-3">Changing clothes:</label>
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="p-0">Frequency:</label>
                            <div class="card flex justify-content-center">
                                <p-dropdown formControlName="changing_colthes_frequency" [options]="frequency"
                                    optionLabel="frequency" [filter]="true" filterBy="frequency" placeholder="Frequency"
                                    [resetFilterOnHide]="true">
                                </p-dropdown>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="p-0">Time:</label>
                            <div class="card flex justify-content-center">
                                <p-calendar placeholder="Select Changing Clothes Time" [timeOnly]="true"
                                    [hourFormat]="12" inputId="time"
                                    formControlName="changing_colthes_time"></p-calendar>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="p-0">Special Instructions: </label>
                            <textarea formControlName="changing_colthes_spiecal_instructions" name="identify"
                                class="form-control" id="kt_autosize_1" rows="3" [disabled]="handwash"></textarea>
                        </div>
                    </div>
                </div>
                <!-- Washing clothes handwash-->
                <label class="subHeading py-3">Washing Clothes:</label>
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12 d-flex align-items-center">
                        <div class="form-group mb-2">
                            <div class="form-check p-0 checkbox-list">
                                <label class="checkbox checkbox-outline p-0">
                                    <p>
                                        <input formControlName="washing_type" type="radio" value="handwash"
                                            id="handwash" (change)="handleInput($event, 'washingFormula')" />
                                        <label for="handwash">Handwash</label>
                                    </p>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="p-0">Frequency:</label>
                            <div class="card flex justify-content-center">
                                <p-dropdown formControlName="handwash_washing_frequency" [options]="frequency"
                                    [disabled]="handwash" optionLabel="frequency" [filter]="true" filterBy="frequency"
                                    placeholder="Frequency" [resetFilterOnHide]="true" [class.is-invalid]="(nannyTask.submitted &&
                                    nannyTaskForm.get(['clothes_json','handwash_washing_frequency'])?.invalid)
                                    ||
                                    nannyTaskForm.get(['clothes_json','handwash_washing_frequency'])?.invalid
                                    &&
                                    nannyTaskForm.get(['clothes_json','handwash_washing_frequency']).touched">
                                </p-dropdown>
                                  <div class="invalid-feedback">
                                        Frequency is required
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">

                    <div class="col-lg-4 col-sm-6 col-12 d-flex align-items-center">
                        <div class="form-group mb-2">
                            <div class="form-check p-0 checkbox-list">
                                <label class="checkbox checkbox-outline p-0">
                                    <p>
                                        <input formControlName="washing_type" type="radio" value="Machine Wash"
                                            id="machineWash" (change)="handleInput($event, 'washingFormula')" />
                                        <label for="machineWash">Machine Wash</label>
                                    </p>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="p-0">Frequency:</label>
                            <div class="card flex justify-content-center">
                                <p-dropdown formControlName="machine_washing_frequency" [options]="frequency"
                                    [disabled]="machineWash" optionLabel="frequency" [filter]="true"
                                    filterBy="frequency" placeholder="Frequency" [resetFilterOnHide]="true" [class.is-invalid]="(nannyTask.submitted &&
                                    nannyTaskForm.get(['clothes_json','machine_washing_frequency'])?.invalid)
                                    ||
                                    nannyTaskForm.get(['clothes_json','machine_washing_frequency'])?.invalid
                                    &&
                                    nannyTaskForm.get(['clothes_json','machine_washing_frequency']).touched">
                                </p-dropdown>
                                <div class="invalid-feedback">
                                    Frequency is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- Sterilization -->
            <div formGroupName="sterilization_json">
                <div class="row">
                    <label class="subHeading py-3">Sterilization:</label>
                    <div class="col-lg-4 col-sm-3 col-12">
                        <div class="form-group">
                            <label class="p-0">Toys</label>
                            <p>
                                <input formControlName="sterilization_toys" type="radio" id="toys-no"
                                    name="sterilization_toys" value="no">
                                <label for="toys-no" class="mb-0 mx-sm-2 mx-0">No</label>
                            </p>
                            <p>
                                <input formControlName="sterilization_toys" type="radio" id="toys-yes"
                                    name="sterilization_toys" value="yes">
                                <label for="toys-yes" class="mb-0  mx-sm-2 mx-0">Yes</label>
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-3 col-12">
                        <div class="form-group mb-3">
                            <label class="p-0">Bottles</label>
                            <p>
                                <input formControlName="sterilization_bottles" type="radio" id="bottles-no" value="no"
                                    name="sterilization_bottles">
                                <label for="bottles-no" class="mb-0  mx-sm-2 mx-0">No</label>
                            </p>
                            <p>
                                <input formControlName="sterilization_bottles" type="radio" id="bottles-yes"
                                    name="sterilization_bottles" value="yes">
                                <label for="bottles-yes" class="mb-0  mx-sm-2 mx-0">Yes</label>
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-3">
                            <label class="p-0">Special Instructions:</label>
                            <textarea name="identify" class="form-control" id="kt_autosize_1" rows="3"
                                formControlName="sterilization_instructions"></textarea>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Food Preparation -->
            <label class="subHeading py-3">Food Preparation:</label>
            <div class="row" formGroupName="food_prep_json">
                <div class="col-lg-4 col-sm-3 col-12">
                    <div class="form-group mb-2">
                        <div class="form-check p-0 checkbox-list">
                            <label class="checkbox checkbox-outline p-0">
                                <input formControlName="food_preparation_mother" type="checkbox" value="sponge"
                                    (change)="handleInput($event, 'bathing')" />
                                <span></span>
                                Mother
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-3 col-12">
                    <div class="form-group mb-2">
                        <div class="form-check p-0 checkbox-list">
                            <label class="checkbox checkbox-outline p-0">
                                <input formControlName="food_preparation_nanny" type="checkbox" value="sponge"
                                    name="food_preparation_nanny" (change)="handleInput($event, 'bathing')" />
                                <span></span>
                                Nanny
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="p-0">Special Instructions:</label>
                        <textarea formControlName="food_preparation_special_ins" name="identify" class="form-control"
                            id="kt_autosize_1" rows="3"></textarea>
                    </div>
                </div>
            </div>
            <!-- Sleep trainiing -->
            <label class="subHeading py-3">Sleep Training:</label>
            <div class="row" formGroupName="sleep_training_json">
                <div class="col-sm-4 col-12">
                    <div class="form-group">
                        <p class="d-flex">
                            <input formControlName="sleep_training" type="radio" id="sleep-no" name="sleep_training"
                                (change)="handleInput($event, 'sleep_training')" value="0">
                            <label for="sleep-no" class="mb-0  mx-sm-2 mx-0">No</label>
                        </p>
                        <p class="d-flex">
                            <input formControlName="sleep_training" type="radio" id="sleep-yes" name="sleep_training"
                                (change)="handleInput($event, 'sleep_training')" value="1">
                            <label for="sleep-yes" class="mb-0  mx-sm-2 mx-0">Yes</label>
                        </p>
                    </div>
                </div>

                <div class="col-sm-4 col-12">
                    <div class="form-group">
                        <label class="p-0">Time:</label>
                        <div class="card flex justify-content-center">
                            <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time"
                                formControlName="sleep_training_time"  [class.is-invalid]="(nannyTask.submitted &&
                                nannyTaskForm.get(['sleep_training_json','sleep_training_time'])?.invalid)
                                ||
                                nannyTaskForm.get(['sleep_training_json','sleep_training_time'])?.invalid
                                &&
                                nannyTaskForm.get(['sleep_training_json','sleep_training_time']).touched"></p-calendar>
                                  <div class="invalid-feedback">
                                    Time is required
                                </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-4 col-12">
                    <div class="form-group">
                        <label class="p-0">Special Instructions:</label>
                        <textarea formControlName="sleep_training_special_ins" name="identify" class="form-control"
                            id="kt_autosize_1" rows="3"></textarea>
                    </div>
                </div>
            </div>

            <!-- Playing -->
            <label class="subHeading py-3">Playing:</label>
            <div class="row" formGroupName="playing_json">
                <div class="col-sm-4 col-12">
                    <div class="form-group">
                        <p class="d-flex">
                            <input type="radio" formControlName="playing" id="play-no" (change)="handleInput($event, 'playing')"
                                value="0">
                            <label for="play-no" class="mb-0  mx-sm-2 mx-0">No</label>
                        </p>
                        <p class="d-flex">
                            <input type="radio" formControlName="playing" id="play-yes" (change)="handleInput($event, 'playing')"
                                value="1">
                            <label for="play-yes" class="mb-0  mx-sm-2 mx-0">Yes</label>
                        </p>
                    </div>
                </div>

                <div class="col-sm-4 col-12">
                    <div class="form-group mb-3">
                        <label class="p-0">Time:</label>
                        <div class="card flex justify-content-center">
                            <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time"
                                formControlName="playing_time" [class.is-invalid]="(nannyTask.submitted &&
                                nannyTaskForm.get(['playing_json','playing_time'])?.invalid)
                                ||
                                nannyTaskForm.get(['playing_json','playing_time'])?.invalid
                                &&
                                nannyTaskForm.get(['playing_json','playing_time']).touched" ></p-calendar>
                                 <div class="invalid-feedback">
                                    Time is required
                                </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-4 col-12">
                    <div class="form-group">
                        <label class="p-0">Special Instructions:</label>
                        <textarea formControlName="playing_special_ins" name="identify" class="form-control"
                            id="kt_autosize_1" rows="3"></textarea>
                    </div>
                </div>
            </div>

            <!-- Education game/toys -->
            <label class="subHeading py-3">Educational Game/Toys:</label>
            <div class="row" formGroupName="edu_games_toys_json">
                <div class="col-sm-4 col-12 d-flex align-items-center">
                    <div class="form-group">
                        <p class="d-flex">
                            <input formControlName="education_games" type="radio" id="educational-no" value="0"
                                name="education_games" (change)="handleInput($event, 'educational_game')">
                            <label for="educational-no" class="mb-0  mx-sm-2 mx-0">No</label>
                        </p>
                        <p class="d-flex">
                            <input formControlName="education_games" type="radio" id="educational-yes" value="1"
                                name="education_games" (change)="handleInput($event, 'educational_game')">
                            <label for="educational-yes" class="mb-0  mx-sm-2 mx-0">Yes</label>
                        </p>
                    </div>
                </div>

                <div class="col-sm-4 col-12">
                    <div class="form-group">
                        <label class="p-0">Special Instructions:</label>
                        <textarea formControlName="education_games_ins" name="identify" class="form-control"
                            id="kt_autosize_1" rows="3"></textarea>
                    </div>
                </div>
            </div>


            <!-- Escort to School -->
            <label class="subHeading py-3">Escort to School:</label>
            <div formGroupName="escort_to_school_json">
                <div class="row">
                    <div class="col-sm-4 col-12">
                        <div class="form-group">
                            <p class="d-flex">
                                <input formControlName="escort_school" type="radio" id="escort-no"
                                (change)="handleInput($event, 'escort')" value="0">
                                <label for="escort-no" class="mb-0  mx-sm-2 mx-0">No</label>
                            </p>
                            <p class="d-flex">
                                <input formControlName="escort_school" type="radio" id="escort-yes"
                                (change)="handleInput($event, 'escort')" value="1">
                                <label for="escort-yes" class="mb-0  mx-sm-2 mx-0">Yes</label>
                            </p>
                        </div>
                    </div>

                    <div class="col-sm-4 col-12">
                        <div class="form-group">
                            <label class="p-0">Time:</label>
                            <div class="card flex justify-content-center">
                                <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time"
                                    formControlName="escort_time" [class.is-invalid]="(nannyTask.submitted &&
                                    nannyTaskForm.get(['escort_to_school_json','escort_time'])?.invalid)
                                    ||
                                    nannyTaskForm.get(['escort_to_school_json','escort_time'])?.invalid
                                    &&
                                    nannyTaskForm.get(['escort_to_school_json','escort_time']).touched" ></p-calendar>
                                      <div class="invalid-feedback">
                                        Time is required
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4 col-12">
                        <div class="form-group">
                            <label class="p-0">Special Instructions:</label>
                            <textarea formControlName="escort_special_ins" class="form-control" id="kt_autosize_1"
                                rows="3"></textarea>
                        </div>
                    </div>
                </div>
            </div>

            <!-- other task -->
            <div class="form-group">
                <div class="row" formGroupName="other_tasks_json">
                    <div class="col-12">
                        <label class="subHeading py-3 px-0 mb-0">Other tasks(as instructed):</label>
                        <textarea formControlName="other_tasks" name="identify" class="form-control" id="kt_autosize_1"
                            rows="7"></textarea>
                    </div>
                </div>
            </div>

        </div>
        <div class="add-listings-btn" style="text-align: right;">
            <button type="submit">Submit</button>
        </div>
    </form>
    <app-copyrights></app-copyrights>
</div>
