import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MasterService } from 'src/app/services/master.service';
import { MessageService, ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-permission-for-roles',
  templateUrl: './permission-for-roles.component.html',
  styleUrls: ['./permission-for-roles.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class PermissionForRolesComponent implements OnInit {
  selectedIds: string[] = []; // Change the type to string
  permissionData: any;
  permissionDataId: any;
  userRoleId: any;
  filterval: string;
  rolesData: {
    name: string;
    create: number | null;
    read: number | null;
    update: number | null;
    delete: number | null;
  }[] = [];

  constructor(
    private masterService: MasterService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private cdr: ChangeDetectorRef // Inject ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getAllData();
    this.getAllRolesPermsision();
    this.userRoleId = localStorage.getItem('userRole_id');
  }

  getAllData() {
    const id = localStorage.getItem('user_role_id');
    this.masterService.getPermissionAccordingToRoles(id).then(
      (res: any) => {
        console.log('Get All Permission Roles', res);
        this.permissionDataId = res.data;
        if (this.permissionDataId) {
          this.selectedIds = this.permissionDataId.map(
            (event) => event.permission_id.toString() // Convert to string
          );
          console.log('SELECTED IDS', this.selectedIds);
        }
      },
      (error: any) => {
        console.log('API Error in User Permission', error);
      }
    );
  }

  getAllRolesPermsision() {
    this.masterService.getUserPermissions().then((res: any) => {
      console.log('ALL PERMISSION RESPONSE', res);

      this.permissionData = res.data;
      this.rolesData = [];

      const groupedByName: { [name: string]: any[] } = {};
      this.permissionData.forEach((action: any) => {
        if (!groupedByName[action.name]) {
          groupedByName[action.name] = [];
        }
        groupedByName[action.name].push(action);
      });

      for (const name in groupedByName) {
        if (groupedByName.hasOwnProperty(name)) {
          const actions = groupedByName[name];
          const entry: any = { name };
          actions.forEach((action: any) => {
            entry[action.action.toLowerCase()] = action.id;
          });
          this.rolesData.push(entry);
        }
      }
      console.log('All ROLESDATA', this.rolesData);
    });
  }

  savePermission() {
    const data = {
      role_id: this.userRoleId,
      permission_ids: this.selectedIds,
    };
    if (this.selectedIds.length > 0) {
      this.masterService.postRolePermissionFormData(data).then((res: any) => {
        console.log(res.message);
        this.messageService.add({
          severity: 'success',
          detail: res?.message,
        });
      });
    }
  }

  reset(dt1: any) {
    dt1.reset();
    this.filterval = '';
  }

  onCheckboxChange(action: any, actionType: string) {
    const actionId = action[actionType.toLowerCase()];
  
    if (actionId) {
      const index = this.selectedIds.indexOf(actionId.toString());
      if (index !== -1) {
        this.selectedIds.splice(index, 1); // Remove the ID from the array
      } else {
        this.selectedIds.push(actionId.toString()); // Add the ID to the array
      }
    }
  
    console.log('Selected IDs:', this.selectedIds);
    this.cdr.detectChanges(); // Manually trigger change detection
  }

  isChecked(action: any, type: string) {
    return (
      this.permissionDataId &&
      this.permissionDataId.some(
        (item) => item.permission_id === action[type].toString()
      )
    );
  }
}
