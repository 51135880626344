import { Component, OnInit } from '@angular/core';
import {
  FormArray, FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CommonApiService } from 'src/app/services/common-api.service';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { UtilityTsService } from 'src/app/services/utility.ts.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-immunization',
  templateUrl: './immunization.component.html',
  styleUrls: ['./immunization.component.scss']
})
export class ImmunizationComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Immunization',
      subTitle: 'Dashboard'
    }
  ]
  immunization: FormGroup; 
  Date_of_birth: Date; 
  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private common_service: CommonApiService,
    private utility_service: UtilityTsService,
    private spinner: NgxSpinnerService,
  ) { 
    this.immunization = this.fb.group({
      // autocomplete fields 
      bcg_dose_date: new FormControl(null),
      HepatitiesB_1stdose_date: new FormControl(null),
      HepatitiesB_2nd_dose_date: new FormControl(null),
      pcv_1stdose_date: new FormControl(null),
      rotavirus_1stdose_date: new FormControl(null),
      HepatitiesB_3rd_dose_date: new FormControl(null),
      opv_1st_dose_date: new FormControl(null),
      pcv_2nd_dose_date: new FormControl(null),
      rotavirus_2nd_dose_date: new FormControl(null),
      HepatitiesB_4th_dose_date: new FormControl(null),
      rotateqx3_dose_date: new FormControl(null),
      opv_2nd_dose_date: new FormControl(null),
      pcv_3rd_dose_date: new FormControl(null),
      mmr1st_dose_date: new FormControl(null),
      chickenPox_1st_dose_date: new FormControl(null),
      pcv_4th_dose_date: new FormControl(null),
      tetravalent_dpt_hiv_dose_date: new FormControl(null),
      opv_4th_dose_date: new FormControl(null),
      mmr2nd_dose_date: new FormControl(null),
      dpt_dose_date: new FormControl(null),
      opv_3rd_dose_date: new FormControl(null),
      chickenPox_2nd_dose_date: new FormControl(null),
      influenza_dose_date: new FormControl(null),
      HepatitiesA_1st_dose_date: new FormControl(null),
      HepatitiesA_2nd_dose_date: new FormControl(null),
      meningococcal_dose_date: new FormControl(null),
      typhoid_dose_date: new FormControl(null),
      hpv_1st_dose_date: new FormControl(null),
      hpv_2nd_dose_date: new FormControl(null),
      hpv_3rd_dose_date: new FormControl(null),
      // autocomplete fields end 
      bcg_brand: new FormControl(null),
      bcg_dose_given_date: new FormControl(null),
      bcg_weight: new FormControl(null),
      bcg_staff: new FormControl(null),
      bcg_batchNumber: new FormControl(null),
      Hexavalent_1st_brand: new FormControl(null),
      HepatitiesB_2nd_brand: new FormControl(null),
      HepatitiesB_2nd_dose_given_date: new FormControl(null),
      Hexavalent_1st_weight: new FormControl(null),
      Hexavalent_1st_staff: new FormControl(null),
      Hexavalent_1st_batchNumber: new FormControl(null),
      HepatitiesB_1stdose_brand: new FormControl(null),
      HepatitiesB_1stdose_given_date: new FormControl(null),
      HepatitiesB_1stdose_weight: new FormControl(null),
      HepatitiesB_1stdose_staff: new FormControl(null),
      HepatitiesB_1stdose_batchNumber: new FormControl(null),
      pcv_1stdose_brand: new FormControl(null),
      pcv_1stdose_given_date: new FormControl(null),
      pcv_1stdose_weight: new FormControl(null),
      pcv_1stdose_staff: new FormControl(null),
      pcv_1stdose_batchNumber: new FormControl(null),
      rotavirus_1stdose_brand: new FormControl(null),
      rotavirus_1stdose_given_date: new FormControl(null),
      rotavirus_1stdose_weight: new FormControl(null),
      rotavirus_1stdose_staff: new FormControl(null),
      rotavirus_1stdose_batchNumber: new FormControl(null),
      HepatitiesB_3rd_dose_brand: new FormControl(null),
      HepatitiesB_3rd_dose_given_date: new FormControl(null),
      HepatitiesB_3rd_dose_weight: new FormControl(null),
      HepatitiesB_3rd_dose_staff: new FormControl(null),
      HepatitiesB_3rd_dose_batchNumber: new FormControl(null),
      opv_1st_dose_brand: new FormControl(null),
      opv_1st_dose_given_date: new FormControl(null),
      opv_1st_dose_weight: new FormControl(null),
      opv_1st_dose_staff: new FormControl(null),
      opv_1st_dose_batchNumber: new FormControl(null),
      pcv_2nd_dose_brand: new FormControl(null),
      pcv_2nd_dose_given_date: new FormControl(null),
      pcv_2nd_dose_weight: new FormControl(null),
      pcv_2nd_dose_staff: new FormControl(null),
      pcv_2nd_dose_batchNumber: new FormControl(null),
      rotavirus_2nd_dose_given_date: new FormControl(null),
      HepatitiesB_4th_dose_brand: new FormControl(null),
      HepatitiesB_4th_dose_given_date: new FormControl(null),
      HepatitiesB_4th_dose_weight: new FormControl(null),
      HepatitiesB_4th_dose_staff: new FormControl(null),
      HepatitiesB_4th_dose_batchNumber: new FormControl(null),
      rotateqx3_dose_brand: new FormControl(null),
      rotateqx3_dose_given_date: new FormControl(null),
      rotateqx3_dose_weight: new FormControl(null),
      rotateqx3_dose_staff: new FormControl(null),
      rotateqx3_dose_batchNumber: new FormControl(null),
      opv_2nd_dose_brand: new FormControl(null),
      opv_2nd_dose_given_date: new FormControl(null),
      opv_2nd_dose_weight: new FormControl(null),
      opv_2nd_dose_staff: new FormControl(null),
      opv_2nd_dose_batchNumber: new FormControl(null),
      pcv_3rd_dose_brand: new FormControl(null),
      pcv_3rd_dose_given_date: new FormControl(null),
      pcv_3rd_dose_weight: new FormControl(null),
      pcv_3rd_dose_staff: new FormControl(null),
      pcv_3rd_dose_batchNumber: new FormControl(null),
      mmr1st_dose_brand: new FormControl(null),
      mmr1st_dose_given_date: new FormControl(null),
      mmr1st_dose_weight: new FormControl(null),
      mmr1st_dose_staff: new FormControl(null),
      mmr1st_dose_batchNumber: new FormControl(null),
      chickenPox_1st_dose_brand: new FormControl(null),
      chickenPox_1st_dose_given_date: new FormControl(null),
      chickenPox_1st_dose_weight: new FormControl(null),
      chickenPox_1st_dose_staff: new FormControl(null),
      chickenPox_1st_dose_batchNumber: new FormControl(null),
      pcv_4th_dose_brand: new FormControl(null),
      pcv_4th_dose_given_date: new FormControl(null),
      pcv_4th_dose_weight: new FormControl(null),
      pcv_4th_dose_staff: new FormControl(null),
      pcv_4th_dose_batchNumber: new FormControl(null),
      tetravalent_dpt_hiv_dose_brand: new FormControl(null),
      tetravalent_dpt_hiv_dose_given_date: new FormControl(null),
      tetravalent_dpt_hiv_dose_weight: new FormControl(null),
      tetravalent_dpt_hiv_dose_staff: new FormControl(null),
      tetravalent_dpt_hiv_dose_batchNumber: new FormControl(null),
      opv_4th_dose_brand: new FormControl(null),
      opv_4th_dose_given_date: new FormControl(null),
      opv_4th_dose_weight: new FormControl(null),
      opv_4th_dose_staff: new FormControl(null),
      opv_4th_dose_batchNumber: new FormControl(null),
      mmr2nd_dose_brand: new FormControl(null),
      mmr2nd_dose_given_date: new FormControl(null),
      mmr2nd_dose_weight: new FormControl(null),
      mmr2nd_dose_staff: new FormControl(null),
      mmr2nd_dose_batchNumber: new FormControl(null),
      dpt_dose_brand: new FormControl(null),
      dpt_dose_given_date: new FormControl(null),
      dpt_dose_weight: new FormControl(null),
      dpt_dose_staff: new FormControl(null),
      dpt_dose_batchNumber: new FormControl(null),
      opv_3rd_dose_brand: new FormControl(null),
      opv_3rd_dose_given_date: new FormControl(null),
      opv_3rd_dose_weight: new FormControl(null),
      opv_3rd_dose_staff: new FormControl(null),
      opv_3rd_dose_batchNumber: new FormControl(null),
      chickenPox_2nd_dose_brand: new FormControl(null),
      chickenPox_2nd_dose_given_date: new FormControl(null),
      chickenPox_2nd_dose_weight: new FormControl(null),
      chickenPox_2nd_dose_staff: new FormControl(null),
      chickenPox_2nd_dose_batchNumber: new FormControl(null),
      influenza_dose_brand: new FormControl(null),
      influenza_dose_given_date: new FormControl(null),
      influenza_dose_weight: new FormControl(null),
      influenza_dose_staff: new FormControl(null),
      influenza_dose_batchNumber: new FormControl(null),
      HepatitiesA_1st_dose_brand: new FormControl(null),
      HepatitiesA_1st_dose_given_date: new FormControl(null),
      HepatitiesA_1st_dose_weight: new FormControl(null),
      HepatitiesA_1st_dose_staff: new FormControl(null),
      HepatitiesA_1st_dose_batchNumber: new FormControl(null),
      HepatitiesA_2nd_dose_brand: new FormControl(null),
      HepatitiesA_2nd_dose_given_date: new FormControl(null),
      HepatitiesA_2nd_dose_weight: new FormControl(null),
      HepatitiesA_2nd_dose_staff: new FormControl(null),
      HepatitiesA_2nd_dose_batchNumber: new FormControl(null),
      meningococcal_dose_brand: new FormControl(null),
      meningococcal_dose_given_date: new FormControl(null),
      meningococcal_dose_weight: new FormControl(null),
      meningococcal_dose_staff: new FormControl(null),
      meningococcal_dose_batchNumber: new FormControl(null),
      typhoid_dose_brand: new FormControl(null),
      typhoid_dose_given_date: new FormControl(null),
      typhoid_dose_weight: new FormControl(null),
      typhoid_dose_staff: new FormControl(null),
      typhoid_dose_batchNumber: new FormControl(null),
      hpv_1st_dose_brand: new FormControl(null),
      hpv_1st_dose_given_date: new FormControl(null),
      hpv_1st_dose_weight: new FormControl(null),
      hpv_1st_dose_staff: new FormControl(null),
      hpv_1st_dose_batchNumber: new FormControl(null),
      hpv_2nd_dose_brand: new FormControl(null),
      hpv_2nd_dose_given_date: new FormControl(null),
      hpv_2nd_dose_weight: new FormControl(null),
      hpv_2nd_dose_staff: new FormControl(null),
      hpv_2nd_dose_batchNumber: new FormControl(null),
      hpv_3rd_dose_brand: new FormControl(null),
      hpv_3rd_dose_given_date: new FormControl(null),
      hpv_3rd_dose_weight: new FormControl(null),
      hpv_3rd_dose_staff: new FormControl(null),
      hpv_3rd_dose_batchNumber: new FormControl(null),
    })
  }

  ngOnInit(): void {
    this.Date_of_birth = new Date();
    this.calculateDoseDates(this.Date_of_birth);
  }

  // calculate all Dose dates
  calculateDoseDates(Date_of_birth:Date){
    // 1st table 
    // birth 
    this.immunization.get('bcg_dose_date').setValue(this.addDate(Date_of_birth, 0, 0, 0));
    this.immunization.get('HepatitiesB_1stdose_date').setValue(this.addDate(Date_of_birth, 0, 0, 0));

    // 2 months
    this.immunization.get('HepatitiesB_2nd_dose_date').setValue(this.addDate(Date_of_birth, 0, 2, 0));
    this.immunization.get('pcv_1stdose_date').setValue(this.addDate(Date_of_birth, 0, 2, 0));
    this.immunization.get('rotavirus_1stdose_date').setValue(this.addDate(Date_of_birth, 0, 2, 0));

    // 4 months
    this.immunization.get('HepatitiesB_3rd_dose_date').setValue(this.addDate(Date_of_birth, 0, 4, 0));
    this.immunization.get('opv_1st_dose_date').setValue(this.addDate(Date_of_birth, 0, 4, 0));
    this.immunization.get('pcv_2nd_dose_date').setValue(this.addDate(Date_of_birth, 0, 4, 0));
    this.immunization.get('rotavirus_2nd_dose_date').setValue(this.addDate(Date_of_birth, 0, 4, 0));

    // 6 months
    this.immunization.get('HepatitiesB_4th_dose_date').setValue(this.addDate(Date_of_birth, 0, 6, 0));
    this.immunization.get('rotateqx3_dose_date').setValue(this.addDate(Date_of_birth, 0, 6, 0));
    this.immunization.get('opv_2nd_dose_date').setValue(this.addDate(Date_of_birth, 0, 6, 0));
    this.immunization.get('pcv_3rd_dose_date').setValue(this.addDate(Date_of_birth, 0, 6, 0));

    // 12 months
    this.immunization.get('mmr1st_dose_date').setValue(this.addDate(Date_of_birth, 0, 0, 1));
    this.immunization.get('chickenPox_1st_dose_date').setValue(this.addDate(Date_of_birth, 0, 0, 1));

    // 15 months 
    this.immunization.get('pcv_4th_dose_date').setValue(this.addDate(Date_of_birth, 0, 3, 1));

    // 18 months
    this.immunization.get('tetravalent_dpt_hiv_dose_date').setValue(this.addDate(Date_of_birth, 0, 6, 1));
    this.immunization.get('opv_4th_dose_date').setValue(this.addDate(Date_of_birth, 0, 6, 1));
    this.immunization.get('mmr2nd_dose_date').setValue(this.addDate(Date_of_birth, 0, 6, 1));

    // 5 years 
    this.immunization.get('dpt_dose_date').setValue(this.addDate(Date_of_birth, 0, 0, 5));
    this.immunization.get('opv_3rd_dose_date').setValue(this.addDate(Date_of_birth, 0, 0, 5));
    this.immunization.get('chickenPox_2nd_dose_date').setValue(this.addDate(Date_of_birth, 0, 0, 5));

    // 2nd table 
    // 6 months 
    this.immunization.get('influenza_dose_date').setValue(this.addDate(Date_of_birth, 0, 6, 0));

    // 1 year 
    this.immunization.get('HepatitiesA_1st_dose_date').setValue(this.addDate(Date_of_birth, 0, 0, 1));
    this.immunization.get('HepatitiesA_2nd_dose_date').setValue(this.addDate(Date_of_birth, 0, 0, 1));
    this.immunization.get('meningococcal_dose_date').setValue(this.addDate(Date_of_birth, 0, 0, 1));

    // 2 year 
    this.immunization.get('typhoid_dose_date').setValue(this.addDate(Date_of_birth, 0, 0, 2));

    // 11 years
    this.immunization.get('hpv_1st_dose_date').setValue(this.addDate(Date_of_birth, 0, 0, 11));
    this.immunization.get('hpv_2nd_dose_date').setValue(this.addDate(Date_of_birth, 0, 0, 11));
    this.immunization.get('hpv_3rd_dose_date').setValue(this.addDate(Date_of_birth, 0, 0, 11));




  }

  addDate(date: Date, days: number, months: number, years: number): Date {
  const resultDate = new Date(date);
  resultDate.setDate(resultDate.getDate() + days);
  resultDate.setMonth(resultDate.getMonth() + months);
  resultDate.setFullYear(resultDate.getFullYear() + years);
  return resultDate;
  }

}
