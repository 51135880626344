<p-toast></p-toast>

<div class="main-content d-flex flex-column">
    <app-dashboard-navbar></app-dashboard-navbar>
<div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
    <h1 style="text-transform: capitalize;">{{ Content.title }}</h1>
    <ol class="breadcrumb">
        <li class="item"><a routerLink="/">Home</a></li>
        <li class="item">
            {{ Content.subTitle }}
        </li>
        <li class="item" style="text-transform: capitalize;">{{ Content.title }}</li>
    </ol>
</div>

<p-accordion>
  <p-accordionTab header="Baby Details" class="accordianStyle">
    <div class="row">
        <div class="col-xl-4 col-lg-12 col-md-12">
            <div class="form-group viewSummary">
                <label>Name :</label>

                <div type="text" readonly>
                    {{ summaryData && summaryData &&  summaryData && summaryData[0]?.name ?? '-' | titlecase }}
                </div>

            </div>
        </div>
        <div class="col-xl-4 col-lg-12 col-md-12">
            <div class="form-group viewSummary">
                <label>Gender :</label>
             
                <div type="text" readonly>
                    {{ summaryData && summaryData[0]?.gender ?? '-' |
                    titlecase }}
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-12 col-md-12">
            <div class="form-group viewSummary">
                <label>Date of Birth :</label>
               
                <div type="text" readonly>
                    {{ summaryData && summaryData[0]?.date_of_birth ?? '-' |
                    titlecase }}
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-4 col-lg-12 col-md-12">
            <div class="form-group viewSummary">
                <label>Days :</label>
               

                <div type="text" readonly>
                    {{ summaryData && summaryData[0]?.days ?? '-' | titlecase
                    }}
                </div>

            </div>
        </div>
        <div class="col-xl-4 col-lg-12 col-md-12">
            <div class="form-group viewSummary">
                <label>Months :</label>
                <div type="text" readonly>
                    {{ summaryData && summaryData[0]?.months ?? '-' |
                    titlecase }}
                </div>

            </div>
        </div>
        <div class="col-xl-4 col-lg-12 col-md-12">
            <div class="form-group viewSummary">
                <label>Years :</label>
               
                <div type="text" readonly>
                    {{ summaryData && summaryData[0]?.years ?? '-'}}
                </div>

            </div>
        </div>
    </div>
</p-accordionTab>
<p-accordionTab header="Temprature Monitoring" class="accordianStyle">
  <div class="add-listings-btn mt-3">
      <p-accordion class="mt-3">
          <div>
              <label for="">Temprature Monitoring:</label>
              <table>
                <tr class="row">
                    <td class="col-sm-6 col-lg-4"><b>Temperature Frequency:</b> {{ summarytemperature?.temp_freq || '-' }}</td>
                    <td class="col-sm-6 col-lg-4"><b>Start Time:</b> {{ summarytemperature?.start_time_temperature | date:'HH:mm:ss a' || '-' }}</td>
                    <td class="col-sm-6 col-lg-4"><b>End Time:</b> {{ summarytemperature?.end_time_temperature | date:'HH:mm:ss a' || '-' }}</td>
                </tr>
            </table>
          </div>
          <div class="col">
              <div class="add-listings-box py-0 my-3"  >
                  <h3 class="mb-0 heading-calender dateTable">Temprature Monitoring <p-calendar placeholder="dd/mm/yyyy " [showTime]="false" dateFormat="dd/mm/yy"
                      (ngModelChange)="getDataByDate($event)" [(ngModel)]="calendar5"></p-calendar></h3>
                      <div class="dataBydate " *ngIf="calendar5 && !error">
                          <table class="table  font-size-13" >
                              <thead>
                                  <tr>
                                      <th>Temprature Frequency</th>
                                      <th>Start Time</th>
                                      <th>End Time</th>
                                      <th>Action</th>
                                      <th>Value</th>
                                      <th>Actual Time</th> 
                                      <th>Time</th>
                                      <th>Done</th>         
                                  </tr>
                              </thead>
                              <tbody>
                                <tr>
                                    <td>{{ temperature?.temp_freq || "-" }}</td>
                                    <td>{{ temperature?.start_time_temperature | date:'HH:mm:ss a' || "-" }}</td>
                                    <td>{{ temperature?.end_time_temperature | date:'HH:mm:ss a' || "-" }}</td>
                                    <ng-container *ngFor="let item of temperature?.temprature_extra_value">
                                        <td>{{ item.action || "-" }}</td>
                                        <td>{{ item.value || "-" }}</td>
                                        <td>{{ item.actual_temp_time | date:'HH:mm:ss a' || "-" }}</td>
                                        <td>{{ item.temp_time | date:'HH:mm:ss a' || "-" }}</td>
                                        <td>{{ item.temp_checkbox == 1 ? "Done" : "Not Done" }}</td>
                                    </ng-container>
                                </tr>
                            </tbody>
                            
                            
                          </table>
                        </div>
                          <div *ngIf=" calendar5 && error">
                            <p> No data available for the selected date.</p> 
                         </div>
              </div>
          </div>
      </p-accordion>
  </div>
</p-accordionTab>
    
<p-accordionTab header="Vital Signs" class="accordianStyle">
  <div class="add-listings-btn mt-3">
      <p-accordion class="mt-3">
          
        <div>
          <label for="">Pulse Monitoring:</label>
          <table>
            <tr class="row mb-3 mt-2" >
                <td class="col-sm-6 col-lg-6"><b>Pulse Frequency:</b>  {{ vitalSign?.pulse_vital?.freq_pulse || '-' }}</td>
                <td class="col-sm-6 col-lg-6"><b>Start Time:</b> {{ vitalSign?.pulse_vital?.start_time_pulse | date:'HH:mm:ss a' || '-' }}</td>
                <td class="col-sm-6 col-lg-6"><b>End Time:</b>  {{ vitalSign?.pulse_vital?.end_time_pulse | date:'HH:mm:ss a' || '-' }}</td>
            </tr>
        </table>
      </div>

      <div>
        <label for="">Respiration Monitoring:</label>
        <table>
          <tr class="row mb-3 mt-2">
              <td class="col-sm-6 col-lg-6"><b>Respiration Frequency:</b> {{ vitalSign?.respiration_vital?.freq_respiration || '-' }}</td>
              <td class="col-sm-6 col-lg-6"><b>Start Time:</b> {{ vitalSign?.respiration_vital?.start_time_respiration | date:'HH:mm:ss a' || '-' }}</td>
              <td class="col-sm-6 col-lg-6"><b>End Time:</b> {{ vitalSign?.respiration_vital?.end_time_respiration | date:'HH:mm:ss a' || '-' }}</td>
          </tr>
      </table>
    </div>

    <div>
      <label for="">spo2 Monitoring:</label>
      <table>
        <tr class="row mb-3 mt-2">
          <td class="col-sm-6 col-lg-6"><b>spo2 Frequency:</b> {{ vitalSign?.spo2_vital?.freq_spo2 || '-' }}</td>
          <td class="col-sm-6 col-lg-6"><b>Start Time:</b> {{ vitalSign?.spo2_vital?.start_time_spo2 | date:'HH:mm:ss a' || '-' }}</td>
          <td class="col-sm-6 col-lg-6"><b>End Time:</b> {{ vitalSign?.spo2_vital?.end_time_spo2 | date:'HH:mm:ss a' || '-' }}</td>
      </tr>
    </table>
  </div>

        <div class="col">
          <div class="add-listings-box py-0 my-3"  >
              <h3 class="mb-0 heading-calender dateTable">Vital Signs <p-calendar placeholder="dd/mm/yyyy " [showTime]="false" dateFormat="dd/mm/yy"
                  (ngModelChange)="getDataByDate($event)" [(ngModel)]="calendar6"></p-calendar></h3>

                  <div class="dataBydate " *ngIf="calendar6 && !error">
                      <table class="table  font-size-13" >
                          <thead>
                              <tr>
                                  <th>Pulse Frequency</th>
                                  <th>Start Time</th>
                                  <th>End Time</th>
                                  <th>Action</th>
                                  <th>Value</th>
                                  <th>Actual Time</th> 
                                  <th>Time</th>
                                  <th>Done</th>         
                              </tr>
                          </thead>
                          <tbody>
                            <tr>
                                <td>{{ newData?.vital_signs_json?.pulse_vital?.freq_pulse || "-" }}</td>
                                <td>{{newData?.vital_signs_json?.pulse_vital?.start_time_pulse| date:'HH:mm:ss a' || "-" }}</td>
                                <td>{{ newData?.vital_signs_json?.pulse_vital?.end_time_pulse | date:'HH:mm:ss a' || "-" }}</td>
                                <ng-container *ngFor="let item of newData?.vital_signs_json?.pulse_vital?.vital_extra_value">
                                    <td>{{ item.vital_action || "-" }}</td>
                                    <td>{{ item.vital_extra_value || "-" }}</td>
                                    <td>{{ item.actual_vital_time | date:'HH:mm:ss a' || "-" }}</td>
                                    <td>{{ item.vital_time | date:'HH:mm:ss a' || "-" }}</td>
                                    <td>{{ item.vital_checkbox == 1 ? "Done" : "Not Done" }}</td>
                                </ng-container>
                            </tr>
                        </tbody>
                      </table>
                    </div>


                    <div class="dataBydate " *ngIf="calendar6 && !error">
                    
                      <table class="table  font-size-13" >
                          <thead>
                              <tr>
                                  <th>Respiration Frequency</th>
                                  <th>Start Time</th>
                                  <th>End Time</th>
                                  <th>Action</th>
                                  <th>Value</th>
                                  <th>Actual Time</th> 
                                  <th>Time</th>
                                  <th>Done</th>         
                              </tr>
                          </thead>
                          <tbody>
                            <tr>
                                <td>{{ newData?.vital_signs_json?.respiration_vital?.freq_respiration || "-" }}</td>
                                <td>{{newData?.vital_signs_json?.respiration_vital?.start_time_respiration| date:'HH:mm:ss a' || "-" }}</td>
                                <td>{{ newData?.vital_signs_json?.respiration_vital?.end_time_respiration | date:'HH:mm:ss a' || "-" }}</td>
                                <ng-container *ngFor="let item of newData?.vital_signs_json?.respiration_vital?.respiration_extra_value">
                                    <td>{{ item.respiration_action || "-" }}</td>
                                    <td>{{ item.respiration_value|| "-" }}</td>
                                    <td>{{ item.actual_respiration_time | date:'HH:mm:ss a' || "-" }}</td>
                                    <td>{{ item.respiration_time | date:'HH:mm:ss a' || "-" }}</td>
                                    <td>{{ item.respiration_checkbox == 1 ? "Done" : "Not Done" }}</td>
                                </ng-container>
                            </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="dataBydate " *ngIf="calendar6 && !error">
                    
                      <table class="table  font-size-13" >
                          <thead>
                              <tr>
                                  <th>spo2 Frequency</th>
                                  <th>Start Time</th>
                                  <th>End Time</th>
                                  <th>Action</th>
                                  <th>Value</th>
                                  <th>Actual Time</th> 
                                  <th>Time</th>
                                  <th>Done</th>         
                              </tr>
                          </thead>
                          <tbody>
                            <tr>
                                <td>{{ newData?.vital_signs_json?.spo2_vital?.freq_spo2 || "-" }}</td>
                                <td>{{newData?.vital_signs_json?.spo2_vital?.start_time_spo2  | date:'HH:mm:ss a' || "-" }}</td>
                                <td>{{ newData?.vital_signs_json?.spo2_vital?.end_time_spo2| date:'HH:mm:ss a' || "-" }}</td>
                                <ng-container *ngFor="let item of newData?.vital_signs_json?.spo2_vital?.spo2_extra_value">
                                    <td>{{ item.spo2_action || "-" }}</td>
                                    <td>{{ item.spo2_value || "-" }}</td>
                                    <td>{{ item.actual_spo2_time | date:'HH:mm:ss a' || "-" }}</td>
                                    <td>{{ item.start_time_spo2| date:'HH:mm:ss a' || "-" }}</td>
                                    <td>{{ item.spo2_checkbox == 1 ? "Done" : "Not Done" }}</td>
                                </ng-container>
                            </tr>
                        </tbody>
                      </table>
                    </div>



                      <div *ngIf=" calendar6 && error">
                        <p> No data available for the selected date.</p> 
                     </div>
          </div>
      </div>

      </p-accordion>
  </div>
</p-accordionTab>
    <p-accordionTab header="Medications" class="accordianStyle">
        <div class="add-listings-btn mt-3">
            <p-accordion class="mt-3">
                <div >
                    <label for="">Medications:</label>
                       <div class="row">
                      <div>
                          <table>
                            <tr class="row" *ngFor="let item of getHealth?.medications_json; let i = index">
                              <td class="col-sm-6 col-lg-4"><b>{{i + 1}} Medicine Name:</b> {{ item?.medicians_name || '-' }}</td>
                              <td class="col-sm-6 col-lg-4"><b>Dosage:</b> {{ item?.medicians_dosage || '-' }}</td>
                              <td class="col-sm-6 col-lg-4"><b>Frequency:</b> {{ item?.medicians_freq || '-' }}</td>
                              <td class="col-sm-6 col-lg-4"><b>Date:</b> {{ item?.medicians_date | date:'dd-MM-yyyy' || '-' }}</td>
                              <td class="col-sm-6 col-lg-4"><b>Time:</b> {{ item?.medicians_time | date:'hh:mm:ss a' || '-' }}</td>
                              <td class="col-sm-6 col-lg-4"><b>Remarks:</b> {{ item?.medicians_remarks || '-' }}</td>
                          </tr>
                          
                          </table>
                      </div>
                       </div>
                </div>
                <div class="col">
                    <div class="add-listings-box py-0 my-3"  >
                        <h3 class="mb-0 heading-calender dateTable">Medications <p-calendar placeholder="dd/mm/yyyy " [showTime]="false" dateFormat="dd/mm/yy"
                            (ngModelChange)="getDataByDate($event)" [(ngModel)]="calendar3"></p-calendar></h3>
                            <div class="dataBydate"  *ngIf="calendar3 && !error">
                                <table class="table  font-size-13" >
                                    <thead>
                                        <tr>
                                            <th>Medicine Name</th>
                                            <th>Dosage</th>
                                            <th>Frequency</th>
                                            <th>Date</th>
                                            <th>Time </th>
                                            <th>Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of newData?.medications_json">
                                            <td>{{ item?.medicians_name || "-" }}</td>
                                            <td>{{ item?.medicians_dosage || "-" }}</td>
                                            <td>{{ item?.medicians_freq || "-" }}</td>
                                            <td>{{ item?.medicians_date  | date:'dd-MM-yyyy'|| "-" }}</td>
                                            <td>{{ item?.medicians_time| date:'HH:mm:ss a' || "-" }}</td>
                                            <td>{{ item?.medicians_remarks || "-" }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>

                                <div *ngIf=" calendar3 && error">
                                    <p> No data available for the selected date.</p> 
                                </div>
                    </div>
                </div>
            </p-accordion>
        </div>
    </p-accordionTab>




    <p-accordionTab header="Doctor Recommendations" class="accordianStyle">
        <div class="add-listings-btn mt-3">
            <p-accordion class="mt-3">
                <div>
                  <table>
                    <tr class="row" *ngFor="let item of getHealth?.doc_recommendation_json; let i = index">
                      <td class="col-sm-12 col-lg-12"><b>{{i + 1}} . Recommendation :</b> {{ item?.remarks_doc || '-' }}</td>
                     
                  </tr>
                  </table>
                </div>
                <div class="col">
                    <div class="add-listings-box py-0 my-3"  >
                        <h3 class="mb-0 heading-calender dateTable">Recommendation <p-calendar placeholder="dd/mm/yyyy " [showTime]="false" dateFormat="dd/mm/yy"
                            (ngModelChange)="getDataByDate($event)" [(ngModel)]="calendar2"></p-calendar></h3>
                            <div *ngIf="calendar2 && !error" >
                                <div *ngFor="let item of newData?.doc_recommendation_json
                                ; let i = index" class="mb-3">
                                    <input readonly type="text" name="remark{{i}}" class="form-control" readonly [value]="item.remarks_doc" placeholder="Remark" id="remark{{i}}" />
                                </div>
                            </div>
                            
                        <div *ngIf=" calendar2 && error">
                            <p> No data available for the selected date.</p> 
                         </div>
                    </div>
                </div>
            </p-accordion>
        </div>
    </p-accordionTab>


    <p-accordionTab header="Immunization" class="accordianStyle">
        <div class="add-listings-btn mt-3">
            <p-accordion class="mt-3">
                <!-- <div class="col-md-4 form-group">
                    <label for="">Temprature Monitoring:</label>
                    
                    <strong>ddkmkdmc</strong>
                </div> -->
                <div class="col">
                    <div class="add-listings-box py-0 my-3"  >
                        <h3 class="mb-0 heading-calender dateTable">Immunization Details <p-calendar placeholder="dd/mm/yyyy " [showTime]="false" dateFormat="dd/mm/yy"
                            (ngModelChange)="getDataByDate($event)" [(ngModel)]="calendar4"></p-calendar></h3>
                            <div class="dataBydate" *ngIf="calendar4 && !error">
                                    <div class="add-listings-box" >
                                      <label class="subHeading mb-3"></label>
                                      <div class="row">
                                        <div class="col-12 col-xxxl-6">
                                          <div class="form-group table-responsive">
                                            <table class="table table-bordered">
                                              <thead>
                                                <tr style="white-space: nowrap">
                                                  <th scope="col" class="text-center" rowspan="2">
                                                    <label class="subHeading px-0">Time</label>
                                                  </th>
                                                  <th scope="col" class="text-center" rowspan="2">
                                                    <label class="subHeading px-0">vaccine</label>
                                                  </th>
                                                  <th scope="col" class="text-center" rowspan="2">
                                                    <label class="subHeading px-0">Brand</label>
                                                  </th>
                                                  <th scope="col" class="text-center" colspan="2">
                                                    <label class="subHeading px-0">Date</label>
                                                  </th>
                                                  <th scope="col" class="text-center weight" rowspan="2">
                                                    <label class="subHeading px-0">Weight (K.g)</label>
                                                  </th>
                                                  <th scope="col" class="text-center" rowspan="2">
                                                    <label class="subHeading px-0">Name of HealthStaff</label>
                                                  </th>
                                                  <th scope="col" class="text-center" rowspan="2">
                                                    <label class="subHeading px-0">Batch No. of Vaccine</label>
                                                  </th>
                                                </tr>
                                                <tr>
                                                  <th class="text-center">Due on</th>
                                                  <th class="text-center">Given on</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                
                                                <tr>
                                                  <td scope="row" rowspan="2" class="text-verticle"><label class="px-0"><br> Birth</label></td>
                                                  <td class="blue">BCG</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.bcg_brand}}" class="form-control" placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                        [(ngModel)]="immunization?.bcg_dose_date" inputId="bcg_dose_date" [disabled]="true">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.bcg_dose_date | date:'dd-MM-yyyy'}}" class="form-control">
                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                        [(ngModel)]="immunization?.bc_dose_given_date" inputId="bcg_dose_given_date">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.bcg_dose_given_date| date:'dd-MM-yyyy'}}" class="form-control">

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.bcg_weight}}" class="form-control" placeholder="Weight" />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.bcg_staff}}" class="form-control"
                                                          placeholder="Name of health staff" />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.bcg_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                            
                                                <tr>
                                                  <td class="green">Hepatitis B(1<sup>st</sup>Dose)</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" 
                                                          value="{{immunization?.HepatitiesB_1stdose_brand}}" class="form-control" placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                        [(ngModel)]="immunization?.HeatitiesB_1stdose_date" inputId="HepatitiesB_1stdose_date"
                                                            [readonlyInput]="true" [disabled]="true">
                                                        </p-calendar> -->
                                                        <input readonly type="text" value="{{immunization?.HepatitiesB_1stdose_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>


                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                        [(ngModel)]="immunization?.HeatitiesB_1stdose_given_date"
                                                            inputId="HepatitiesB_1stdose_given_date">
                                                        </p-calendar> -->
                                                        <input readonly type="text" value="{{immunization?.HepatitiesB_1stdose_given_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.HepatitiesB_1stdose_weight}}" class="form-control"
                                                          placeholder="Weight" />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.HepatitiesB_1stdose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.HepatitiesB_1stdose_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <!-- 2 months  -->
                                                <tr>
                                                  <td scope="row" rowspan="3" class="text-verticle"><label class="px-0">2 Months</label></td>
                                                  <td class="blue">Hexavalent Vaccine <br> DTaP-IPV-Hib(1<sup>st</sup>Dose) <br> Hepatitis B
                                                    (2<sup>nd</sup>Dose)</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" 
                                                          value="{{immunization?.dtab_tpv_hib_1st_brand}}" class="form-control" placeholder="Brand." />
                                                    </div>
                                                    <div class="form-group">
                                                      <input readonly type="text" 
                                                          value="{{immunization?.HepatitiesB_2nd_brand}}" class="form-control" placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.dtab_tpv_hib_1st_dose_date" inputId="dtab_tpv_hib_1st_dose_date"
                                                            [readonlyInput]="true" [disabled]="true">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.dtab_tpv_hib_1st_dose_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.HepatitiesB_2nd_dose_date" inputId="HepatitiesB_2nd_dose_date"
                                                            [readonlyInput]="true" [disabled]="true">
                                                        </p-calendar> -->
                                                        <input readonly type="text" value="{{immunization?.HepatitiesB_2nd_dose_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                           [(ngModel)]="immunization?dtab_tpv_hib_1st_dose_given_date"
                                                            inputId="dtab_tpv_hib_1st_dose_given_date">
                                                        </p-calendar> -->
                                                        <input readonly type="text" value="{{immunization?.dtab_tpv_hib_1st_dose_given_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                           [(ngModel)]="immunization?HepatitiesB_2nd_dose_given_date"
                                                            inputId="HepatitiesB_2nd_dose_given_date">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.HepatitiesB_2nd_dose_given_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.dtab_tpv_hib_1st_weight}}" class="form-control"
                                                          placeholder="Weight" />
                                                    </div>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.Hexavalent_1st_weight}}" class="form-control"
                                                          placeholder="Weight" />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.dtab_tpv_hib_1st_staff}}" class="form-control"
                                                          placeholder="Name of health staff" />
                                                    </div>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.Hexavalent_1st_staff}}" class="form-control"
                                                          placeholder="Name of health staff" />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.dtab_tpv_hib_1st_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.Hexavalent_1st_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td class="green">PCV (1<sup>st</sup>Dose)</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text"  value="{{immunization?.pcv_1stdose_brand}}"
                                                          class="form-control" placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.pcv_1stdose_date" inputId="pcv_1stdose_date" [readonlyInput]="true"
                                                            [disabled]="true">
                                                        </p-calendar> -->


                                                        <input readonly type="text" value="{{immunization?.pcv_1stdose_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.pcv_1stdose_given_date" inputId="pcv_1stdose_given_date">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.pcv_1stdose_given_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.pcv_1stdose_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.pcv_1stdose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.pcv_1stdose_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td class="blue">Rotavirus</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text"
                                                          value="{{immunization?.rotavirus_1stdose_brand}}" class="form-control" placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                        [(ngModel)]="immunization?.roavirus_1stdose_date" inputId="rotavirus_1stdose_date"
                                                            [readonlyInput]="true" [disabled]="true">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.rotavirus_1stdose_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                        [(ngModel)]="immunization?.roavirus_1stdose_given_date" inputId="rotavirus_1stdose_given_date">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.rotavirus_1stdose_given_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.rotavirus_1stdose_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.rotavirus_1stdose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.rotavirus_1stdose_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <!-- 4 months  -->
                                                <tr>
                                                  <td scope="row" rowspan="4" class="text-verticle"><label class="px-0">4 Months</label></td>
                                                  <td class="green">Hexavalent Vaccine <br> DTaP-IPV-Hib(2<sup>nd</sup>Dose) <br> Hepatitis B
                                                    (3<sup>rd</sup>Dose)</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" 
                                                          value="{{immunization?.dtab_tpv_hib_2nd_brand}}" class="form-control" placeholder="Brand." />
                                                    </div>
                                                    <div class="form-group">
                                                      <input readonly type="text" 
                                                          value="{{immunization?.HepatitiesB_3rd_dose_brand}}" class="form-control" placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                        [(ngModel)]="immunization?.dtb_tpv_hib_2nd_dose_date" inputId="dtab_tpv_hib_2nd_dose_date"
                                                            [readonlyInput]="true" [disabled]="true">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.dtab_tpv_hib_2nd_dose_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                        [(ngModel)]="immunization?.HeatitiesB_3rd_dose_date" inputId="HepatitiesB_3rd_dose_date"
                                                            [readonlyInput]="true" [disabled]="true">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.HepatitiesB_3rd_dose_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.dtab_tpv_hib_2nd_dose_given_date"
                                                            inputId="dtab_tpv_hib_2nd_dose_given_date">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.dtab_tpv_hib_2nd_dose_given_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.HepatitiesB_3rd_dose_given_date"
                                                            inputId="HepatitiesB_3rd_dose_given_date">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.HepatitiesB_3rd_dose_given_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.dtab_tpv_hib_2nd_dose_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.HepatitiesB_3rd_dose_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.dtab_tpv_hib_2nd_dose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.HepatitiesB_3rd_dose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.dtab_tpv_hib_2nd_dose_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.HepatitiesB_3rd_dose_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td class="blue">OPV</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text"  value="{{immunization?.opv_1st_dose_brand}}"
                                                          class="form-control" placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                        [(ngModel)]="immunization?.op_1st_dose_date" inputId="opv_1st_dose_date" [readonlyInput]="true"
                                                            [disabled]="true">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.opv_1st_dose_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                        [(ngModel)]="immunization?.op_1st_dose_given_date" inputId="opv_1st_dose_given_date">
                                                        </p-calendar> -->
                                                        <input readonly type="text" value="{{immunization?.opv_1st_dose_given_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.opv_1st_dose_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.opv_1st_dose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.opv_1st_dose_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td class="green">PCV (2<sup>nd</sup>Dose)</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text"  value="{{immunization?.pcv_2nd_dose_brand}}"
                                                          class="form-control" placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                        [(ngModel)]="immunization?.pc_2nd_dose_date" inputId="pcv_2nd_dose_date" [readonlyInput]="true"
                                                            [disabled]="true">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.pcv_2nd_dose_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                        [(ngModel)]="immunization?.pc_2nd_dose_given_date" inputId="pcv_2nd_dose_given_date">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.pcv_2nd_dose_given_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.pcv_2nd_dose_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.pcv_2nd_dose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.pcv_2nd_dose_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td class="blue">Rotavirus</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text"
                                                          value="{{immunization?.rotavirus_2nddose_brand}}" class="form-control" placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                        [(ngModel)]="immunization?.roavirus_2nd_dose_date" inputId="rotavirus_2nd_dose_date"
                                                            [readonlyInput]="true" [disabled]="true">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.rotavirus_2nd_dose_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                        [(ngModel)]="immunization?.roavirus_2nd_dose_given_date" inputId="rotavirus_2nd_dose_given_date">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.rotavirus_2nd_dose_given_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.rotavirus_2nd_dose_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.rotavirus_2nd_dose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.rotavirus_2nd_dose_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                            
                                                <!-- 6 months  -->
                                                <tr>
                                                  <td scope="row" rowspan="4" class="text-verticle"><label class="px-0">6 Months</label></td>
                                                  <td class="green">Pentavalent Vaccine <br> DTaP-IPV-Hib(2<sup>nd</sup>Dose) <br> Hepatitis B
                                                    (4<sup>th</sup>Dose)</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" 
                                                          value="{{immunization?.dtab_tpv_hib_3rd_brand}}" class="form-control" placeholder="Brand." />
                                                    </div>
                                                    <div class="form-group">
                                                      <input readonly type="text" 
                                                          value="{{immunization?.HepatitiesB_4th_dose_brand}}" class="form-control" placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                        [(ngModel)]="immunization?.dtb_tpv_hib_3rd_dose_date" inputId="dtab_tpv_hib_3rd_dose_date"
                                                            [readonlyInput]="true" [disabled]="true">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.dtab_tpv_hib_3rd_dose_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                        [(ngModel)]="immunization?.HeatitiesB_4th_dose_date" inputId="HepatitiesB_4th_dose_date"
                                                            [readonlyInput]="true" [disabled]="true">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.HepatitiesB_4th_dose_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.dtab_tpv_hib_3rd_given_date" inputId="HepatitiesB_4th_dose_given_date">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.dtab_tpv_hib_3rd_given_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.HepatitiesB_4th_dose_given_date"
                                                            inputId="HepatitiesB_4th_dose_given_date">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.HepatitiesB_4th_dose_given_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.dtab_tpv_hib_3rd_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.HepatitiesB_4th_dose_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.dtab_tpv_hib_3rd_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.HepatitiesB_4th_dose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.dtab_tpv_hib_3rd_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.HepatitiesB_4th_dose_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td class="blue">Rotateq X3</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.rotateqx3_dose_brand}}" class="form-control"
                                                          placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.rotateqx3_dose_date" inputId="rotateqx3_dose_date"
                                                            [readonlyInput]="true" [disabled]="true">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.rotateqx3_dose_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.rotateqx3_dose_given_date" inputId="rotateqx3_dose_given_date">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.rotateqx3_dose_given_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.rotateqx3_dose_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.rotateqx3_dose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.rotateqx3_dose_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td class="green">OPV</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text"  value="{{immunization?.opv_2nd_dose_brand}}"
                                                          class="form-control" placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.opv_2nd_dose_date" inputId="opv_2nd_dose_date" [readonlyInput]="true"
                                                            [disabled]="true">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.opv_2nd_dose_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.opv_2nd_dose_given_date" inputId="opv_2nd_dose_given_date">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.opv_2nd_dose_given_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.opv_2nd_dose_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.opv_2nd_dose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.opv_2nd_dose_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td class="blue">PCV (3<sup>rd</sup>Dose)</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text"  value="{{immunization?.pcv_3rd_dose_brand}}"
                                                          class="form-control" placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.pcv_3rd_dose_date" inputId="pcv_3rd_dose_date" [readonlyInput]="true"
                                                            [disabled]="true">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.pcv_3rd_dose_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>


                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.pcv_3rd_dose_given_date" inputId="pcv_3rd_dose_given_date">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.pcv_3rd_dose_given_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.pcv_3rd_dose_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.pcv_3rd_dose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.pcv_3rd_dose_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                            
                                                <!-- 12 month  -->
                                                <tr>
                                                  <td scope="row" rowspan="2" class="text-verticle"><label class="px-0">12<br>Months</label></td>
                                                  <td class="green">MMR (1<sup>st</sup>Dose)</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text"  value="{{immunization?.mmr1st_dose_brand}}"
                                                          class="form-control" placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.mmr1st_dose_date" inputId="mmr1st_dose_date" [readonlyInput]="true"
                                                            [disabled]="true">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.mmr1st_dose_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.mmr1st_dose_given_date" inputId="mmr1st_dose_given_date">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.mmr1st_dose_given_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.mmr1st_dose_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.mmr1st_dose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.mmr1st_dose_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td class="blue">Chicken Pox (1<sup>st</sup>Dose)</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.chickenPox_1st_dose_brand}}" class="form-control"
                                                          placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.chickenPox_1st_dose_date" inputId="chickenPox_1st_dose_date"
                                                            [readonlyInput]="true" [disabled]="true">
                                                        </p-calendar> -->


                                                        <input readonly type="text" value="{{immunization?.chickenPox_1st_dose_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.chickenPox_1st_dose_given_date"
                                                            inputId="chickenPox_1st_dose_given_date">
                                                        </p-calendar> -->
                                                        <input readonly type="text" value="{{immunization?.chickenPox_1st_dose_given_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.chickenPox_1st_dose_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.chickenPox_1st_dose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.chickenPox_1st_dose_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                            
                                                <!-- 15 month  -->
                                                <tr>
                                                  <td scope="row" class="text-verticle"><label class="px-0">15<br>Months</label></td>
                                                  <td class="green">PCV (4<sup>th</sup>Dose)</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text"  value="{{immunization?.pcv_4th_dose_brand}}"
                                                          class="form-control" placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.pcv_4th_dose_date" inputId="pcv_4th_dose_date" [readonlyInput]="true"
                                                            [disabled]="true">
                                                        </p-calendar> -->
                                                        <input readonly type="text" value="{{immunization?.pcv_4th_dose_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                        
                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.pcv_4th_dose_given_date" inputId="pcv_4th_dose_given_date">
                                                        </p-calendar> -->
                                                        <input readonly type="text" value="{{immunization?.pcv_4th_dose_given_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.pcv_4th_dose_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.pcv_4th_dose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.pcv_4th_dose_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                            
                                                <!-- 18 month  -->
                                                <tr>
                                                  <td scope="row" rowspan="3" class="text-verticle"><label class="px-0">18<br>Months</label></td>
                                                  <td class="blue">Tetravalent Vaccine DPT-Hib</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.tetravalent_dpt_hiv_dose_brand}}" class="form-control"
                                                          placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.tetravalent_dpt_hiv_dose_date" inputId="tetravalent_dpt_hiv_dose_date"
                                                            [readonlyInput]="true" [disabled]="true">
                                                        </p-calendar> -->
                                                        <input readonly type="text" value="{{immunization?.tetravalent_dpt_hiv_dose_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.tetravalent_dpt_hiv_dose_given_date"
                                                            inputId="tetravalent_dpt_hiv_dose_given_date">
                                                        </p-calendar> -->
                                                        <input readonly type="text" value="{{immunization?.tetravalent_dpt_hiv_dose_given_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.tetravalent_dpt_hiv_dose_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.tetravalent_dpt_hiv_dose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.tetravalent_dpt_hiv_dose_batchNumber}}"
                                                          class="form-control" placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td class="blue">OPV</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text"  value="{{immunization?.opv_3rd_dose_brand}}"
                                                          class="form-control" placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.opv_3rd_dose_date" inputId="opv_3rd_dose_date" [readonlyInput]="true"
                                                            [disabled]="true">
                                                        </p-calendar> -->
                                                        <input readonly type="text" value="{{immunization?.opv_3rd_dose_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>


                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.opv_3rd_dose_given_date" inputId="opv_3rd_dose_given_date">
                                                        </p-calendar> -->
                                                        <input readonly type="text" value="{{immunization?.opv_3rd_dose_given_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.opv_3rd_dose_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.opv_3rd_dose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.opv_3rd_dose_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td class="blue">MMR</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text"  value="{{immunization?.mmr2nd_dose_brand}}"
                                                          class="form-control" placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.mmr2nd_dose_date" inputId="mmr2nd_dose_date" [readonlyInput]="true"
                                                            [disabled]="true">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.mmr2nd_dose_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.mmr2nd_dose_given_date" inputId="mmr2nd_dose_given_date">
                                                        </p-calendar> -->
                                                        <input readonly type="text" value="{{immunization?.mmr2nd_dose_given_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.mmr2nd_dose_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.mmr2nd_dose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.mmr2nd_dose_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                            
                                                <!-- 5-6 Years -->
                                                <tr>
                                                  <td scope="row" rowspan="3" class="text-verticle"><label class="px-0">5-6 Years</label></td>
                                                  <td class="green">DPT</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.dpt_dose_brand}}" class="form-control"
                                                          placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.dpt_dose_date" inputId="dpt_dose_date" [readonlyInput]="true"
                                                            [disabled]="true">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.dpt_dose_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.dpt_dose_given_date" inputId="dpt_dose_given_date">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.dpt_dose_given_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.dpt_dose_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.dpt_dose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.dpt_dose_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td class="green">OPV</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text"  value="{{immunization?.opv_4th_dose_brand}}"
                                                          class="form-control" placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="card flex justify-content-center">
                                                      <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                          [(ngModel)]="immunization?.opv_4th_dose_date" inputId="opv_4th_dose_date" [readonlyInput]="true"
                                                          [disabled]="true">
                                                      </p-calendar> -->

                                                      <input readonly type="text" value="{{immunization?.opv_4th_dose_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="card flex justify-content-center">
                                                      <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                          [(ngModel)]="immunization?.opv_4th_dose_given_date" inputId="opv_4th_dose_given_date">
                                                      </p-calendar> -->

                                                      <input readonly type="text" value="{{immunization?.opv_4th_dose_given_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.opv_4th_dose_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.opv_4th_dose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.opv_4th_dose_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td class="green">Chicken Pox-2</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.chickenPox_2nd_dose_brand}}" class="form-control"
                                                          placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.chickenPox_2nd_dose_date" inputId="chickenPox_2nd_dose_date"
                                                            [readonlyInput]="true" [disabled]="true">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.chickenPox_2nd_dose_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <div class="card flex justify-content-center">
                                                        <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                            [(ngModel)]="immunization?.chickenPox_2nd_dose_given_date"
                                                            inputId="chickenPox_2nd_dose_given_date">
                                                        </p-calendar> -->

                                                        <input readonly type="text" value="{{immunization?.chickenPox_2nd_dose_given_date| date:'dd-MM-yyyy'}}" class="form-control" readonly>

                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.chickenPox_2nd_dose_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.chickenPox_2nd_dose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.chickenPox_2nd_dose_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                            
                                        <!-- 2nd table  -->
                                        <div class="col-12 col-xxxl-6">
                                          <div class="form-group table-responsive">
                                            <table class="table table-bordered">
                                              <thead>
                                                <tr style="white-space: nowrap">
                                                  <th scope="col" class="text-center" rowspan="2">
                                                    <label class="subHeading px-0">Time</label>
                                                  </th>
                                                  <th scope="col" class="text-center" rowspan="2">
                                                    <label class="subHeading px-0">vaccine</label>
                                                  </th>
                                                  <th scope="col" class="text-center" rowspan="2">
                                                    <label class="subHeading px-0">Brand</label>
                                                  </th>
                                                  <th scope="col" class="text-center" colspan="2">
                                                    <label class="subHeading px-0">Date</label>
                                                  </th>
                                                  <th scope="col" class="text-center weight" rowspan="2">
                                                    <label class="subHeading px-0">Weight (K.g)</label>
                                                  </th>
                                                  <th scope="col" class="text-center" rowspan="2">
                                                    <label class="subHeading px-0">Name of HealthStaff</label>
                                                  </th>
                                                  <th scope="col" class="text-center" rowspan="2">
                                                    <label class="subHeading px-0">Batch No. of Vaccine</label>
                                                  </th>
                                                </tr>
                                                <tr>
                                                  <th class="text-center">Due on</th>
                                                  <th class="text-center">Given on</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td scope="row" class="text-verticle"><label class="px-0">> 6<br>Months</label></td>
                                                  <td class="blue">Influenza Vaccine</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.influenza_dose_brand}}" class="form-control"
                                                          placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="card flex justify-content-center">
                                                      <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                          [(ngModel)]="immunization?.influenza_dose_date" inputId="influenza_dose_date" [readonlyInput]="true"
                                                          [disabled]="true">
                                                      </p-calendar> -->

                                                      <input readonly  type="text" value="{{immunization?.influenza_dose_date | date:'dd-MM-yyyy'}}" class="form-control" />

                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="card flex justify-content-center">
                                                      <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                          [(ngModel)]="immunization?.influenza_dose_given_date" inputId="influenza_dose_given_date">
                                                      </p-calendar> -->

                                                      <input readonly  type="text" value="{{immunization?.influenza_dose_given_date | date:'dd-MM-yyyy'}}" class="form-control" />

                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.influenza_dose_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.influenza_dose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.influenza_dose_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td scope="row" rowspan="2" class="text-verticle"><label class="px-0">> 1<br>Years</label></td>
                                                  <td class="green">Hepatities A Vaccine (1<sup>st</sup>Dose)</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.HepatitiesA_1st_dose_brand}}" class="form-control"
                                                          placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="card flex justify-content-center">
                                                      <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                          [(ngModel)]="immunization?.HepatitiesA_1st_dose_date | date:'dd-MM-yyyy'" inputId="HepatitiesA_1st_dose_date | date:'dd-MM-yyyy'"
                                                          [readonlyInput]="true" [disabled]="true">
                                                      </p-calendar> -->

                                                      <input readonly type="text" value="{{immunization?.HepatitiesA_1st_dose_date | date:'dd-MM-yyyy'}}" class="form-control" />

                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="card flex justify-content-center">
                                                      <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                          [(ngModel)]="immunization.HepatitiesA_1st_dose_given_date | date:'dd-MM-yyyy'"
                                                          inputId="HepatitiesA_1st_dose_given_date | date:'dd-MM-yyyy'">
                                                      </p-calendar> -->

                                                      <input readonly  type="text" value="{{immunization.HepatitiesA_1st_dose_given_date | date:'dd-MM-yyyy'}}" class="form-control" />

                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization.HepatitiesA_1st_dose_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization.HepatitiesA_1st_dose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization.HepatitiesA_1st_dose_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                            
                                                <tr>
                                                  <td class="blue">(2<sup>nd</sup>Dose)</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization.HepatitiesA_2nd_dose_brand}}" class="form-control"
                                                          placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="card flex justify-content-center">
                                                      <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                          [(ngModel)]="immunization.HepatitiesA_2nd_dose_date | date:'dd-MM-yyyy'" inputId="HepatitiesA_2nd_dose_date | date:'dd-MM-yyyy'"
                                                          [readonlyInput]="true" [disabled]="true">
                                                      </p-calendar> -->

                                                      <input readonly type="text" value="{{immunization?.HepatitiesA_2nd_dose_date | date:'dd-MM-yyyy'}}" class="form-control" />

                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="card flex justify-content-center">
                                                      <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                          [(ngModel)]="immunization.HepatitiesA_2nd_dose_given_date | date:'dd-MM-yyyy'"
                                                          inputId="HepatitiesA_2nd_dose_given_date | date:'dd-MM-yyyy'">
                                                      </p-calendar> -->

                                                      <input readonly type="text" value="{{immunization.HepatitiesA_2nd_dose_given_date | date:'dd-MM-yyyy'}}" class="form-control" />

                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization.HepatitiesA_2nd_dose_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization.HepatitiesA_2nd_dose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization.HepatitiesA_2nd_dose_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td scope="row" class="text-verticle"><label class="px-0">> 1<br>Years</label></td>
                                                  <td class="green">Meningococcal Vaccine</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization.meningococcal_dose_brand}}" class="form-control"
                                                          placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="card flex justify-content-center">
                                                      <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                          [(ngModel)]="immunization.meningococcal_dose_date | date:'dd-MM-yyyy'" inputId="meningococcal_dose_date | date:'dd-MM-yyyy'"
                                                          [readonlyInput]="true" [disabled]="true">
                                                      </p-calendar> -->
                                                      <input readonly type="text"  value="{{immunization.meningococcal_dose_date | date:'dd-MM-yyyy' }}" class="form-control" />


                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="card flex justify-content-center">
                                                      <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                          [(ngModel)]="immunization.meningococcal_dose_given_date | date:'dd-MM-yyyy'" inputId="meningococcal_dose_given_date | date:'dd-MM-yyyy'">
                                                      </p-calendar> -->
                                                      <input readonly type="text" value="{{immunization.meningococcal_dose_given_date | date:'dd-MM-yyyy'}}" class="form-control" />

                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization.meningococcal_dose_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization.meningococcal_dose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization.meningococcal_dose_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                            
                                                <tr>
                                                  <td scope="row" class="text-verticle"><label class="px-0"> > 2<br>Years</label></td>
                                                  <td class="blue">Typhoid Vaccine</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization.typhoid_dose_brand}}" class="form-control"
                                                          placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="card flex justify-content-center">
                                                      <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                          [(ngModel)]="immunization.typhoid_dose_date" inputId="typhoid_dose_date" [readonlyInput]="true"
                                                          [disabled]="true">
                                                      </p-calendar> -->

                                                      <input readonly type="text" value="{{immunization.typhoid_dose_date | date:'dd-MM-yyyy'}}" class="form-control" />

                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="card flex justify-content-center">
                                                      <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                          [(ngModel)]="immunization.typhoid_dose_given_date" inputId="typhoid_dose_given_date">
                                                      </p-calendar> -->

                                                      <input readonly  type="text" value="{{immunization.typhoid_dose_given_date}}" class="form-control" />

                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization.typhoid_dose_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization.typhoid_dose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization.typhoid_dose_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                            
                                                <tr>
                                                  <td scope="row" rowspan="3" class="text-verticle"><label class="px-0"> > 11-12 <br> Years</label>
                                                  </td>
                                                  <td class="green">HPV <br>(1<sup>st</sup>Dose)</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text"  value="{{immunization?.hpv_1st_dose_brand}}"
                                                          class="form-control" placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="card flex justify-content-center">
                                                      <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                          [(ngModel)]="immunization?.hpv_1st_dose_date" inputId="hpv_1st_dose_date" [readonlyInput]="true"
                                                          [disabled]="true">
                                                      </p-calendar> -->

                                                      <input readonly type="text" value="{{immunization?.hpv_1st_dose_date | date:'dd-MM-yyyy'}}" class="form-control" />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="card flex justify-content-center">
                                                      <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                          [(ngModel)]="immunization?.hpv_1st_dose_given_date" inputId="hpv_1st_dose_given_date">
                                                      </p-calendar> -->
                                                      <input readonly type="text" value="{{immunization?.hpv_1st_dose_given_date | date:'dd-MM-yyyy'}}" class="form-control" />

                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.hpv_1st_dose_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.hpv_1st_dose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.hpv_1st_dose_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td class="blue">(2<sup>nd</sup>Dose)</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text"  value="{{immunization?.hpv_2nd_dose_brand}}"
                                                          class="form-control" placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="card flex justify-content-center">
                                                      <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                          [(ngModel)]="immunization?.hpv_2nd_dose_date" inputId="hpv_2nd_dose_date" [readonlyInput]="true"
                                                          [disabled]="true">
                                                      </p-calendar> -->
                                                      <input readonly type="text" value="{{immunization?.hpv_2nd_dose_date | date:'dd-MM-yyyy'}}" class="form-control" />

                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="card flex justify-content-center">
                                                      <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                          [(ngModel)]="immunization?.hpv_2nd_dose_given_date" inputId="hpv_2nd_dose_given_date">
                                                      </p-calendar> -->
                                                      <input readonly type="text"  value="{{immunization?.hpv_2nd_dose_given_date | date:'dd-MM-yyyy'}}" class="form-control" />

                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.hpv_2nd_dose_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.hpv_2nd_dose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.hpv_2nd_dose_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td class="green">(3<sup>rd</sup>Dose)</td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text"  value="{{immunization?.hpv_3rd_dose_brand}}"
                                                          class="form-control" placeholder="Brand." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="card flex justify-content-center">
                                                      <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                          [(ngModel)]="immunization?.hpv_3rd_dose_date" inputId="hpv_3rd_dose_date" [readonlyInput]="true"
                                                          [disabled]="true">
                                                      </p-calendar> -->
                                                      <input readonly type="text"  value="{{immunization?.hpv_3rd_dose_date | date:'dd-MM-yyyy'}}" class="form-control" />

                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="card flex justify-content-center">
                                                      <!-- <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                                          [(ngModel)]="immunization?.hpv_3rd_dose_given_date" inputId="hpv_3rd_dose_given_date">
                                                      </p-calendar> -->
                                                      <input readonly type="text" value="{{immunization?.hpv_3rd_dose_given_date | date:'dd-MM-yyyy'}}" class="form-control" />

                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.hpv_3rd_dose_weight}}" class="form-control"
                                                          placeholder="Weight." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="text" value="{{immunization?.hpv_3rd_dose_staff}}" class="form-control"
                                                          placeholder="Name of health staff." />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="form-group">
                                                      <input readonly type="number" value="{{immunization?.hpv_3rd_dose_batchNumber}}" class="form-control"
                                                          placeholder="Batch Number." />
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                          <div class="form-group table-responsive">
                                            <table class="table table-bordered">
                                              <tbody>
                                                <tr>
                                                  <td class="blue"><b>Typhoid -</b> It can be given every 3 years after the age of 2.</td>
                                                </tr>
                                                <tr>
                                                  <td class="green"><b>PCV -</b> If the primary doses are missed, take a single dose at the end of 2
                                                    years.</td>
                                                </tr>
                                                <tr>
                                                  <td class="blue"><b>Rotavirus -</b> Both the doses of rotavirus has to be finished before 6 months
                                                    of age.</td>
                                                </tr>
                                                <tr>
                                                  <td class="green"><b>FLU -</b> If flu vaccine is administered for the first time, a booster is
                                                    repeated after 1 month then repeat yearly.</td>
                                                </tr>
                                                <tr>
                                                  <td class="blue">
                                                    <b>Varicella -</b> Second dose of vaccine can be given 4 month to 1 year after 1st dose with
                                                    high risk group and given 5 yearly
                                                    after that. It is given as double dose between 9-24 month and as single dose after 24 months.
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td class="green">
                                                    <b>Meningococcal</b> - Aramen is given as double dose between 9-24 months and as a single dose
                                                    after 24 months. Mencevax is to be
                                                    repeated after 3 years after 1st dose in 2-6 years of age group with high risk and given 5
                                                    yearly after that.
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                <div *ngIf=" calendar4 && error">
                                  <p> No data available for the selected date.</p> 
                               </div>
                    </div>
                </div>
            </p-accordion>
        </div>
    </p-accordionTab>

    <p-accordionTab header="Remarks" class="accordianStyle">
        <div class="add-listings-btn mt-3">
            <p-accordion class="mt-3">
                <div class="col-md-4 form-group">
                    <label for=""> Remark:</label>
                    <strong>{{getHealth?.remarks}}</strong>
                </div>
                <div class="col">
                    <div class="add-listings-box py-0 my-3"  >
                        <h3 class="mb-0 heading-calender dateTable">Remark <p-calendar placeholder="dd/mm/yyyy " [showTime]="false" dateFormat="dd/mm/yy"
                            (ngModelChange)="getDataByDate($event)" [(ngModel)]="calendar1"></p-calendar></h3>
                        <div *ngIf="calendar1 && !error" class="mb-3">
                            <input readonly type="text" name="remark" class="form-control" readonly value="{{ newData?.remarks || '-' }}" placeholder="Remark" id="remark"/>
                        </div>
                        <div *ngIf=" calendar1 && error">
                           <p> No data available for the selected date.</p> 
                        </div>
                    </div>
                </div>
                

            </p-accordion>
        </div>
    </p-accordionTab>
</p-accordion>




<app-copyrights></app-copyrights>
</div>