
<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">


	<app-dashboard-navbar></app-dashboard-navbar>


	<!-- Breadcrumb Area -->
	<div *ngFor="let Content of breadcrumb;" class="breadcrumb-area">
		<h1>{{Content.title}}</h1>
		<ol class="breadcrumb">
			<li class="item">
				<a routerLink="/">Home</a>
			</li>
			<li class="item">
				{{Content.subTitle}}
			</li>
			<li class="item">{{Content.title}}</li>
		</ol>
	</div>


	<!-- <div class="add-listings-box">
        <div class="row justify-content-between align-items-center">
            <div class="form-group col-4">
                <label class="p-0">Name <span class="required">*</span></label>
                <input type="text" name="name" class="form-control" placeholder="Name" (change)="getInput($event)" />
            </div>
            <div class="add-listings-btn mb-0 col-4 text-end">
                <button type="submit" (click)="addTableData()">Add</button>
            </div>
        </div>
    </div> -->
	<div class="add-listings-box">
		<div class="Master-view-box">
			<div class="theader d-flex justify-content-between">
				<div class="d-flex w-100">
					<span class="p-input-icon-left ml-auto mr-2 w-100%">
						<i class="pi pi-search"></i>
						<input (input)="dt1.filterGlobal($event.target.value, 'contains')" pInputText type="text"
							class="form-control "style="text-align: left; padding-left: 20%; height: 45px !important" scriptInjection placeholder="Search Keyword"
							[(ngModel)]="filterVal">
					</span>
					<p-button type="button" class="mx-2" icon="bx bx-reset" tooltipPosition="bottom"
						(click)="reset(dt1)"></p-button>
				</div>
				<div class="add-listings-btn" style="text-align: right;">
					<a routerLink="/portal/feeding-assesment" class="nav-link">
						<button type="submit">Add</button>


					</a>
				</div>
			</div>
		</div>


		<div class="row mt-4">
			<div class="col-lg-12 col-md-12">
				<p-table #dt1 [value]="initialAssData" [rows]="10" [showCurrentPageReport]="true"
					[tableStyle]="{'min-width': '50rem', 'width' : '100%'}" [rowsPerPageOptions]="[10,25,50]"
					[loading]="loading" [paginator]="true"
					[globalFilterFields]="['name', 'date_of_birth', 'contact_no', 'age']" dataKey="id"
					styleClass="p-datatable-sm p-datatable-customers" responsiveLayout="scroll"
					currentPageReportTemplate="Showing {first} to
					{last} of {totalRecords} entries" editMode="row">
					<ng-template pTemplate="header">
						<tr>
							<th style="
							width: 50px;
						"></th>
							<th style="width: 53px;">S. No.</th>
							<!-- <th>Action</th> -->
							<th>Date Of Birth</th>
							<th>Name</th>
							<!-- <th>Contact No.</th> -->
							<th>Days</th>
							<th>Months</th>
							<th>Years</th>
						</tr>


					</ng-template>
					<!-- table to show data -->
					<ng-template pTemplate="body" let-list let-expanded="expanded" let-i="rowIndex">
						<tr>
							<td>
								<button [pRowToggler]="list" [icon]="
                    expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
                  " (click)="getData(list.id)" type="button" pButton pRipple
									class="p-button-text p-button-rounded p-button-plain"></button>
							</td>
							<td>{{ i + 1 }}</td>
							<!-- <td (click)="showMaximizableDialog(list.id)">
								<button pButton
									pRipple
									class="p-button-rounded p-button-text p-button-info"
									pTooltip="View"
									tooltipPosition="bottom">
									<i class="far fa-eye"></i>
								</button>
							</td> -->
							<!-- <td>{{ list?.baby_details_id ?? '-' }}</td> -->
							<td>
								{{ list?.date_of_birth ?? '-' }}
							</td>
							<td>
								{{ list?.name ?? '-' | titlecase }}
							</td>
							<!-- <td>{{ list?.contact_no ?? '-' }}</td> -->
							<td>
								{{ list?.days ?? '-' }}
							</td>
							<td>
								{{ list?.months ?? '-' }}
							</td>
							<td>
								{{ list?.years ?? '-' }}
							</td>
						</tr>
					</ng-template>
					<ng-template pTemplate="emptymessage">
						<tr>
							<td colspan="5">No Data Found.</td>
						</tr>
					</ng-template>





					<!-- ****************************************************** -->
					<ng-template pTemplate="rowexpansion" let-list>
						<tr>
							<td colspan="7" class="inner-data">
								<div class="p-3 py-0">
									<p-table [value]="list.feeding_assesments" dataKey="id">
										<ng-template pTemplate="header">
						<tr>
							<th pSortableColumn="id">S. No</th>
							<th pSortableColumn="customer">Action</th>
							<th pSortableColumn="date">Time</th>
							<!-- <th pSortableColumn="amount">Amount <p-sortIcon field="amount"></p-sortIcon></th>
                                              <th pSortableColumn="status">Status <p-sortIcon field="status"></p-sortIcon></th>
                                              <th style="width: 4rem"></th> -->
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
						<tr>
							<td>{{ rowIndex + 1 }}</td>
							<!-- <td>{{data.id}}</td> -->
							<td (click)="showMaximizableDialog(data.id)">
								<button pButton pRipple class="p-button-rounded p-button-text p-button-info"
									pTooltip="View" tooltipPosition="bottom">
									<i class="far fa-eye"></i>
								</button>
							</td>
							<td>
								{{ data?.created_at | date: 'dd-MM-yyyy' || "-" }}
							</td>
						</tr>
					</ng-template>
					<ng-template pTemplate="emptymessage">
						<tr>
							<td colspan="6">
								There are no order for this product yet.
							</td>
						</tr>
					</ng-template>
				</p-table>
			</div>
			</td>
			</tr>
			</ng-template>


			<ng-template pTemplate="emptymessage">
				<tr>
					<td colspan="5">No Data Found.</td>
				</tr>
			</ng-template>
			</p-table>
		</div>
	</div>
	<!-- --------------------------------------------------------- view dialog ------------------------------------------------------- -->
	<!-- ***************************************************************************************************************** -->
	<p-dialog [(visible)]="displayMaximizable" [modal]="true" [style]="{ width: '90vw' }" [draggable]="false"
		[resizable]="false" header="Baby Details">
		<!-- Start -->
		<ng-container *ngFor="let data of viewDetail">
			<div class="row">
				<div class="col-lg-12">
					<div class="my-profile-box">
						<form>
							<!-- Baby detail -->
							<div class="row">
								<div class="col-xl-4 col-lg-12 col-md-12">
									<div class="form-group">
										<label>Name :</label>
										<input type="text" readonly class="form-control"
											value="{{data?.name?? '-'| titlecase }}">
									</div>
								</div>
								<div class="col-xl-4 col-lg-12
                                                col-md-12">
									<div class="form-group">
										<label>Gender :</label>
										<input type="text" readonly class="form-control"
											value="{{data?.gender ?? '-'}}">
									</div>
								</div>
								<div class="col-xl-4 col-lg-12 col-md-12">
									<div class="form-group">
										<label>Date of Birth :</label>
										<input type="text" readonly class="form-control"
											value="{{data?.date_of_birth ?? '-'}}">
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-xl-4 col-lg-12 col-md-12">
									<div class="form-group">
										<label>Days :</label>
										<input type="text" readonly class="form-control" value="{{data?.days ?? '-'}}">
									</div>
								</div>
								<div class="col-xl-4 col-lg-12 col-md-12">
									<div class="form-group">
										<label>Months :</label>
										<input type="text" readonly class="form-control"
											value="{{data?.months ?? '-'}}">
									</div>
								</div>
								<div class="col-xl-4 col-lg-12 col-md-12">
									<div class="form-group">
										<label>Years :</label>
										<input type="text" readonly class="form-control" value="{{data?.years ?? '-'}}">
									</div>
								</div>
							</div>
							<h3>Assessment</h3>
							<ng-container *ngFor="let assessment of feeding_ass">
								<div class="row">
									<div class="col-xl-4 col-lg-12 col-md-12">
										<div class="form-group">
											<label class="label-height">
												Baby is not interested when offered to breastfeed,
												sleepy.
											</label>
											<input type="text" readonly class="form-control"
												value="{{assessment?.Baby_is_not_interested_when_offered_to_breastfeed_sleepy?.answer ?assessment?.Baby_is_not_interested_when_offered_to_breastfeed_sleepy?.answer: '-'}}">
										</div>
									</div>
									<div class="col-xl-4 col-lg-12 col-md-12">
										<div class="form-group">
											<label class="label-height">
												Baby is showing feeding cues but not attaching.
											</label>
											<input type="text" readonly class="form-control"
												value="{{assessment?.Baby_is_showing_feeding_clues_but_not_attaching?.answer ?assessment?.Baby_is_showing_feeding_clues_but_not_attaching?.answer: '-'}}">
										</div>
									</div>
									<div class="col-xl-4 col-lg-12 col-md-12">
										<div class="form-group">
											<label class="label-height">
												Attaches at the breast but quickly falls asleep.
											</label>
											<input type="text" readonly class="form-control"
												value="{{assessment?.Attaches_at_the_breast_but_quickly_falls_asleep?.answer ?assessment?.Attaches_at_the_breast_but_quickly_falls_asleep?.answer: '-'}}">
										</div>
									</div>


									<div class="col-xl-4 col-lg-12 col-md-12">
										<div class="form-group">
											<label class="label-height">
												Attaches for short bursts with long pause.
											</label>
											<input type="text" readonly class="form-control"
												value="{{assessment?.Attaches_for_short_bursts_with_long_pause?.answer ?assessment?.Attaches_for_short_bursts_with_long_pause?.answer: '-'}}">
										</div>
									</div>


									<div class="col-xl-4 col-lg-12 col-md-12">
										<div class="form-group">
											<label class="label-height">
												Attaches well with long rhythmical sucking and swallowing for
												a
												short feed.
											</label>
											<input type="text" readonly class="form-control"
												value="{{assessment?.Attaches_well_with_long_rhythmical_sucking_and_swallowing_for_a_short_feed?.answer ?assessment?.Attaches_well_with_long_rhythmical_sucking_and_swallowing_for_a_short_feed?.answer: '-'}}">
										</div>
									</div>


									<div class="col-xl-4 col-lg-12 col-md-12">
										<div class="form-group">
											<label class="label-height">
												Attaches well for a sustained period with long rhythmical
												sucking
												and swallowing.
											</label>
											<input type="text" readonly class="form-control"
												value="{{assessment?.Attaches_well_for_a_sustained_period_with_long_rhythmical_sucking_and_swallowing?.answer ?assessment?.Attaches_well_for_a_sustained_period_with_long_rhythmical_sucking_and_swallowing?.answer: '-'}}">
										</div>
									</div>


									<div class="col-xl-4 col-lg-12 col-md-12">
										<div class="form-group">
											<label class="label-height">
												Normal skin color and tone.
											</label>
											<input type="text" readonly class="form-control"
												value="{{assessment?.Normal_skin_color_and_tone?.answer ?assessment?.Normal_skin_color_and_tone?.answer:  '-'}}">
										</div>
									</div>


									<div class="col-xl-4 col-lg-12 col-md-12">
										<div class="form-group">
											<label class="label-height">
												Gaining weight appropriately.
											</label>
											<input type="text" readonly class="form-control"
												value="{{assessment?.Gaining_weight_appropriately?.answer ?assessment?.Gaining_weight_appropriately?.answer: '-'}}">
										</div>
									</div>
								</div>


								<h3>Other Concerns</h3>
								<div class="row">
									<div class="col-xl-4 col-lg-12 col-md-12">
										<div class="form-group">
											<input type="text" readonly class="form-control"
												value="{{assessment?.Other_concerns ?assessment?.Other_concerns : '-'}}">
										</div>
									</div>
								</div>
							</ng-container>
						</form>
					</div>
				</div>
			</div>
		</ng-container>
		<ng-template pTemplate="footer">
			<p-button (click)="displayMaximizable=
                            false"  label="Ok" styleClass="p-button-text"></p-button>
		</ng-template>
	</p-dialog>
</div>
</div>
<app-copyrights></app-copyrights>