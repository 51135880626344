<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">
  <p-toast></p-toast>

  <app-dashboard-navbar></app-dashboard-navbar>

  <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
    <h1>{{ Content.title }}</h1>
    <ol class="breadcrumb">
      <li class="item"><a routerLink="/">Home</a></li>
      <li class="item">
        <a routerLink="dashboard">{{ Content.subTitle }}</a>
      </li>
      <li class="item">{{ Content.title }}</li>
    </ol>
  </div>
  <form [formGroup]="initialAssessementAdult" (ngSubmit)="submitAssessment(submitAssessmentForm)"
    #submitAssessmentForm="ngForm">
    <!-- DATE PICKER -->
    <div class="add-listings-box pb-2">
      <h3>Basic Information</h3>
      <div class="row">
        <div class="col-sm-6 col-12">
          <div class="form-group">
            <label class="subHeading px-0">Date<span class="required">*</span></label>
            <div class="card flex justify-content-center">
              <p-calendar readonly placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" [showIcon]="true"
                [readonlyInput]="true" id="date" formControlName="date" [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('date')?.invalid) ||
                  (initialAssessementAdult.get('date')?.invalid &&
                    initialAssessementAdult.get('date').touched)
                " [minDate]="maxDate" [maxDate]="maxDate"></p-calendar>
              <div class="invalid-feedback">Date is required</div>
            </div>
          </div>
        </div>

        <!-- INITIAL ASSESSMENT -->
        <div class="col-sm-6 col-12">
          <div class="form-group">
            <label class="subHeading p-0">Initial Assessment:<span class="required">*</span></label>
            <textarea formControlName="initial_assessment" id="initial_assessment" placeholder="Initial assessment"
              class="form-control px-2" id="" rows="3" [disabled]="" [class.is-invalid]="
                (submitAssessmentForm.submitted &&
                  initialAssessementAdult.get('initial_assessment')?.invalid) ||
                (initialAssessementAdult.get('initial_assessment')?.invalid &&
                  initialAssessementAdult.get('initial_assessment').touched)
              "></textarea>
            <div class="invalid-feedback">Initial Assessment required</div>
          </div>
        </div>

        <!-- History of Present Illness -->
        <div class="col-sm-6 col-12">
          <div class="form-group">
            <label class="subHeading p-0">History of Present Illness:<span class="required">*</span></label>
            <textarea formControlName="history_present_illness" id="history_present_illness"
              placeholder="History of present illness" class="form-control px-2" id="" rows="3" [disabled]=""
              [class.is-invalid]="
                (submitAssessmentForm.submitted &&
                  initialAssessementAdult.get('history_present_illness')
                    ?.invalid) ||
                (initialAssessementAdult.get('history_present_illness')
                  ?.invalid &&
                  initialAssessementAdult.get('history_present_illness')
                    .touched)
              ">
            </textarea>
            <div class="invalid-feedback">History present illness required</div>
          </div>
        </div>

        <!--Past Medical history -->
        <div class="col-sm-6 col-12">
          <div class="form-group">
            <label class="subHeading p-0">Past Medical History:<span class="required">*</span></label>
            <textarea formControlName="past_medical_history" id="past_medical_history"
              placeholder="Past medical history" class="form-control px-2" id="" rows="3" [disabled]=""
              [class.is-invalid]="
                (submitAssessmentForm.submitted &&
                  initialAssessementAdult.get('past_medical_history')
                    ?.invalid) ||
                (initialAssessementAdult.get('past_medical_history')?.invalid &&
                  initialAssessementAdult.get('past_medical_history').touched)
              "></textarea>
            <div class="invalid-feedback">Past Medical History required</div>
          </div>
        </div>
      </div>

      <!-- Medications -->
      <div class="form-group">
        <label class="subHeading px-0">Medications: </label>
        <div formArrayName="assesment_json">
          <ng-container *ngFor="
              let control of getMedicationFormArray().controls;
              index as i;
              last as last
            ">
            <div class="row" [formGroupName]="i">
              <div class="col-md-11 col-sm-10 col-12">
                <div class="form-group">
                  <label class="px-0">Medications</label>
                  <div class="pl-0 checkbox-list">
                    <input type="text" class="form-control" placeholder="Medications" formControlName="medication"
                      id="medications" />
                  </div>
                </div>
              </div>

              <div class="col-md-1 col-sm-2 col-12 mt-2 d-flex justify-content-end align-items-center">
                <p-button type="button" icon="bx bx-plus" (click)="addMedication('add', i)" iconPos="right"
                  class="mr-2"></p-button>
                <div class="remove">
                  <p-button type="button" *ngIf="i != 0" class="ml-2" icon="bx bx-minus"
                    (click)="addMedication('delete', i)" iconPos="right"></p-button>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <!-- <div class="form-group">
				<label class="subHeading px-0">Medications:</label>
				<div formArrayName="assesment_json">
					<ng-container
						*ngFor="let control of getMedicationFormArray().controls; let i = index; last as last">
						<div [formGroupName]="i" class="row">
							<div class="col-sm-11 col-10">
								<input type="text" class="form-control px-2" placeholder="Medications"
									formControlName="medication">


							</div> -->
      <!-- <div class="d-flex justify-content-end col-sm-1 col-2 medication-plus">
                <p-button type="button" icon="bx bx-plus" iconPos="right" class="mr-2"></p-button>

              </div> -->
      <!-- <div class="d-flex justify-content-end col-sm-1 col-2 medication-plus">
								<div class="form-group mb-0">
									<div class="d-flex justify-content-end">
										<p-button (click)="addMedication('add', i)" type="button" icon="bx bx-plus"
											iconPos="right" class="mr-2 mr-0"></p-button>
										<div class="remove">
											<p-button *ngIf="i!= 0" (click)="addMedication('delete', i)" type="button"
												class="ml-2" icon="bx bx-minus" iconPos="right"></p-button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</div> -->

      <!-- Pain Assessment -->
      <div class="form-group">
        <label class="subHeading px-0">Pain Assessment: </label>
        <div class="row mt-3">
          <div class="col-sm-6 col-12">
            <label class="px-0">Tool:</label>
            <input type="text" class="form-control px-2" id="pain_assessment_tool" placeholder="Tool"
              formControlName="pain_assessment_tool" />
          </div>
          <div class="col-sm-6 col-12">
            <label class="px-0 mt-2 mt-sm-0">Score:</label>
            <input type="text" class="form-control px-2" placeholder="Score" formControlName="pain_assessment_score"
              id="pain_assessment_score" />
          </div>
        </div>
      </div>
    </div>

    <!-- Vital Signs -->
    <div class="add-listings-box pb-2">
      <h3>Vital Signs: Pre-session</h3>
      <div class="form-group">
        <div class="row mt-3">
          <!-- Blood Pressure -->
          <div class="col-sm-2 col-4">
            <label class="px-0">Blood Pressure:</label>
          </div>
          <div class="col-sm-10 col-8">
            <input type="text" pattern="[0-9]*" (keypress)="keyPress($event)" class="form-control px-2"
              placeholder="Blood pressure" formControlName="blood_pressure" id="blood_pressure" />
          </div>
        </div>

        <!-- Temperature -->
        <div class="row mt-3">
          <div class="col-sm-2 col-4">
            <label class="px-0">Temperature:</label>
          </div>
          <div class="col-sm-10 col-8">
            <input type="text" class="form-control px-2" pattern="[0-9]*" (keypress)="keyPress($event)"
              placeholder="Temperature" formControlName="temperature" id="temperature" />
          </div>
        </div>

        <!-- Heart rate -->
        <div class="row mt-3">
          <div class="col-sm-2 col-4">
            <label class="px-0">Heart Rate:</label>
          </div>
          <div class="col-sm-10 col-8">
            <input type="text" class="form-control px-2" pattern="[0-9]*" (keypress)="keyPress($event)"
              placeholder="Heart rate" formControlName="heart_rate" id="heart_rate" />
          </div>
        </div>

        <!-- SPO2 -->
        <div class="row mt-3">
          <div class="col-sm-2 col-4">
            <label class="px-0">SPO2:</label>
          </div>
          <div class="col-sm-10 col-8">
            <input type="text" class="form-control px-2" placeholder="SPO2" pattern="[0-9]*"
              (keypress)="keyPress($event)" formControlName="spo2" id="spo2" />
          </div>
        </div>

        <!-- RR-->
        <div class="row mt-3">
          <div class="col-sm-2 col-4">
            <label class="px-0">RR:</label>
          </div>
          <div class="col-sm-10 col-8">
            <input type="text" class="form-control px-2" placeholder="RR" pattern="[0-9]*" (keypress)="keyPress($event)"
              formControlName="rr" id="rr" />
          </div>
        </div>

        <!-- Attachment-->
        <!-- <div class="row mt-3"> -->
        <label class="subHeading my-3 px-0">Attachment:</label>
        <!-- <div class="col-2"> -->
        <div class="form-check checkbox-list px-0">
          <div class="row py-sm-3 py-0 p-0 m-0">
            <label class="checkbox checkbox-outline px-0 col-sm-3 col-6">
              <input type="checkbox" value="sterilizeAll" formControlName="attachment_PEG" id="attachment_PEG" />
              PEG
            </label>

            <label class="checkbox checkbox-outline mt-sm-0 mt-3 px-0 col-sm-3 col-6">
              <input type="checkbox" value="sterilizeOne" formControlName="attachment_trache" id="attachment_trache" />
              Trache
            </label>

            <label class="checkbox checkbox-outline mt-sm-0 mt-3 px-0 col-sm-3 col-6">
              <input type="checkbox" value="sterilizeOne" formControlName="attachment_NGT" id="attachment_NGT" />
              NGT
            </label>

            <label class="checkbox checkbox-outline mt-sm-0 mt-3 px-0 col-sm-3 col-6">
              <input type="checkbox" value="sterilizeOne" formControlName="attachment_FC1" id="attachment_FC1" />
              FC1
            </label>

            <label class="checkbox checkbox-outline mt-sm-0 mt-3 px-0 col-sm-3 col-6">
              <input type="checkbox" value="sterilizeOne" formControlName="attachment_colostomy"
                id="attachment_colostomy" />
              Colostomy
            </label>

            <label class="checkbox checkbox-outline mt-sm-0 mt-3 px-0 col-sm-3 col-6">
              <input type="checkbox" value="sterilizeOne" formControlName="attachment_FC2" id="attachment_FC2" />
              FC2
            </label>

            <label class="checkbox checkbox-outline mt-sm-0 mt-3 px-0 col-sm-3 col-6">
              <input type="checkbox" value="sterilizeOne" formControlName="attachment_ventilator"
                id="attachment_ventilator" />
              Ventilator
            </label>

            <label class="checkbox checkbox-outline mt-sm-0 mt-3 px-0 col-sm-3 col-6">
              <input type="checkbox" value="sterilizeOne" formControlName="attachment_canula" id="attachment_canula" />
              O2 Canula
            </label>

            <label class="checkbox checkbox-outline mt-sm-0 mt-3 px-0 col-sm-3 col-6">
              <input type="checkbox" value="sterilizeOne" formControlName="attachment_NA" id="attachment_NA" />
              NA
            </label>

            <label class="checkbox checkbox-outline mt-sm-0 mt-3 px-0 col-sm-3 col-6">
              <input type="checkbox" value="attachment_others" formControlName="attachment_others"
                id="attachment_others" (change)="handleInput($event, 'box')" />
              Others
            </label>
            <div class="col-sm-3 col-12 px-0">
              <input type="text" class="form-control" placeholder="Others" formControlName="others"
                id="attachment_others" [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('others')?.invalid) ||
                  (initialAssessementAdult.get('others')?.invalid &&
                    initialAssessementAdult.get('others').touched)
                " />
              <div class="invalid-feedback">Others remark is required</div>
            </div>
          </div>
        </div>

        <!-- Respiratory assessment-->
        <label class="subHeading my-3 px-0">Respiratory Assessment:</label>
        <div class="row mt-3 p-0">
          <!-- Cough -->
          <div class="col-lg-2 col-sm-6 col-12">
            <div class="form-group mb-2">
              <label class="mr-3 px-0">Cough:</label>
              <p class="d-flex">
                <input type="radio" id="assessment_yes" value="Yes" name="repiratory_assessment"
                  formControlName="repiratory_assessment" (change)="handleInput($event, 'cough')" />
                <label for="assessment_yes" class="mb-0 mx-2">Yes</label>
              </p>
              <p class="d-flex">
                <input type="radio" id="assessment_no" value="No" name="repiratory_assessment"
                  formControlName="repiratory_assessment" (change)="handleInput($event, 'cough')" />
                <label for="assessment_no" class="mb-0 mx-2">No</label>
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-12">
            <label class="mr-3 px-0">Remark</label>
            <input type="text" class="form-control px-2" placeholder="Remark" formControlName="cough_remark"
              id="cough_remark" [class.is-invalid]="
                (submitAssessmentForm.submitted &&
                  initialAssessementAdult.get('cough_remark')?.invalid) ||
                (initialAssessementAdult.get('cough_remark')?.invalid &&
                  initialAssessementAdult.get('cough_remark').touched)
              " />
            <div class="invalid-feedback">Cough remark is required</div>
          </div>

          <!-- Chest Secretions -->
          <div class="col-lg-2 col-sm-6 col-12">
            <div class="form-group mb-2">
              <label class="mr-3 px-0 mt-sm-0 mt-2">Chest Secretions:</label>
              <p class="d-flex">
                <input type="radio" id="chest_secretion_yes" value="Yes" name="chest_secretion"
                  formControlName="chest_secretion" (change)="handleInput($event, 'secretion')" />
                <label for="chest_secretion_yes" class="mb-0 mx-2">Yes</label>
              </p>
              <p class="d-flex">
                <input type="radio" id="chest_secretion_no" value="No" name="chest_secretion"
                  formControlName="chest_secretion" (change)="handleInput($event, 'secretion')" />
                <label for="chest_secretion_no" class="mb-0 mx-2">No</label>
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-12">
            <label class="mr-3 px-0">Remark</label>
            <input type="text" class="form-control px-2" placeholder="Remark" formControlName="chest_secretion_remark"
              id="chest_secretion_remark" [class.is-invalid]="
                (submitAssessmentForm.submitted &&
                  initialAssessementAdult.get('chest_secretion_remark')
                    ?.invalid) ||
                (initialAssessementAdult.get('chest_secretion_remark')
                  ?.invalid &&
                  initialAssessementAdult.get('chest_secretion_remark').touched)
              " />
            <div class="invalid-feedback">
              Chest secretion remark is required
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Congition -->
    <div class="add-listings-box">
      <h3>Cognition</h3>
      <div class="form-group table-responsive">
        <table class="table">
          <thead>
            <tr class="border-light" style="white-space: nowrap">
              <th scope="col">
                <label class="subHeading px-0">Neurosensory:</label>
              </th>
              <th scope="col"><label class="subHeading px-0">Intact</label></th>
              <th scope="col">
                <label class="subHeading px-0">Impaired</label>
              </th>
              <th scope="col"><label class="subHeading px-0">Absent</label></th>
              <th scope="col">
                <label class="subHeading px-0">Not tested</label>
              </th>
              <th scope="col"><label class="subHeading px-0">Remark</label></th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr class="border-light">
                            <td scope="row"> <label class=" px-0">Light Touch</label></td>
                            <td> <input class="m-3" type="checkbox" value="intact" id="intact_light"
                                    formControlName="intact_light" /></td>
                            <td> <input class="m-3" type="checkbox" value="intact" id="impaired_light"
                                    formControlName="impaired_light" /></td>
                            <td> <input class="m-3" type="checkbox" value="intact" id="absent_light"
                                    formControlName="absent_light" /></td>
                            <td> <input class="m-3" type="checkbox" value="intact" id="not_tested_light"
                                    formControlName="not_tested_light" /></td>
                            <td> <input class="m-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                                    formControlName="light_touch_remark" /></td>
                        </tr> -->
            <tr class="border-light">
              <td scope="row">
                <label class="px-0">Light Touch</label>
              </td>
              <td>
                <input type="radio" name="intact_light" value="1" id="intact_light" formControlName="intact_light"
                  class="p-radiobutton" (change)="handleInput($event, 'intactlight')" />
              </td>
              <td>
                <input type="radio" name="intact_light" value="2" id="impaired_light" formControlName="intact_light"
                  class="p-radiobutton" (change)="handleInput($event, 'intactlight')" />
              </td>
              <td>
                <input type="radio" name="intact_light" value="3" id="absent_light" formControlName="intact_light"
                  class="p-radiobutton" (change)="handleInput($event, 'intactlight')" />
              </td>
              <td>
                <input type="radio" name="intact_light" value="4" id="not_tested_light" formControlName="intact_light"
                  class="p-radiobutton" (change)="handleInput($event, 'intactlight')" />
              </td>
              <td>
                <input type="text" class="form-control px-2 remark" placeholder="Remark"
                  formControlName="light_touch_remark"  [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('light_touch_remark')
                      ?.invalid) ||
                  (initialAssessementAdult.get('light_touch_remark')
                    ?.invalid &&
                    initialAssessementAdult.get('light_touch_remark')
                      .touched)
                " />
                 <div class="invalid-feedback">
                 Light Touch Remark is required
                </div>
              </td>
            </tr>

            <tr class="border-light">
              <td scope="row"><label class="px-0">Blunt Touch/Deep</label></td>
              <td>
                <input type="radio" name="intact_blunt" value="1" id="intact_blunt" formControlName="intact_blunt"
                  class="p-radiobutton" (change)="handleInput($event, 'intactblunt')" />
              </td>
              <td>
                <input type="radio" name="intact_blunt" value="2" id="impaired_light" formControlName="intact_blunt"
                  class="p-radiobutton" (change)="handleInput($event, 'intactblunt')" />
              </td>
              <td>
                <input type="radio" name="intact_blunt" value="3" id="absent_light" formControlName="intact_blunt"
                  class="p-radiobutton" (change)="handleInput($event, 'intactblunt')" />
              </td>
              <td>
                <input type="radio" name="intact_blunt" value="4" id="not_tested_light" formControlName="intact_blunt"
                  class="p-radiobutton" (change)="handleInput($event, 'intactblunt')" />
              </td>
              <td>
                <input class="m-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                  formControlName="blunt_remark"   [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('blunt_remark')
                      ?.invalid) ||
                  (initialAssessementAdult.get('blunt_remark')
                    ?.invalid &&
                    initialAssessementAdult.get('blunt_remark')
                      .touched)
                "/>
                 <div class="invalid-feedback">
                  Blunt Touch Remark is required
                </div>
              </td>
              <!-- <td>
                              <input type="text" class="form-control px-2 remark" placeholder="Remark" formControlName="light_touch_remark" />
                            </td>
                            <td> <input class="m-3" type="checkbox" value="intact" id="intact_blunt"
                                    formControlName="intact_blunt" /></td>
                            <td> <input class="m-3" type="checkbox" value="intact" id="impaired_blunt"
                                    formControlName="impaired_blunt" /></td>
                            <td> <input class="m-3" type="checkbox" value="intact" id="absent_blunt"
                                    formControlName="absent_blunt" /></td>
                            <td> <input class="m-3" type="checkbox" value="intact" id="not_tested_blunt"
                                    formControlName="not_tested_blunt" /></td>
                            <td> <input class="m-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                                    formControlName="blunt_remark" /></td> -->
            </tr>
            <tr class="border-light">
              <td scope="row"><label class="px-0">Pressure</label></td>
              <td>
                <input type="radio" name="intact_pressure" value="1" id="intact_blunt" formControlName="intact_pressure"
                  class="p-radiobutton" (change)="handleInput($event, 'intactpressure')" />
              </td>
              <td>
                <input type="radio" name="intact_pressure" value="2" id="impaired_light"
                  formControlName="intact_pressure" class="p-radiobutton"
                  (change)="handleInput($event, 'intactpressure')" />
              </td>
              <td>
                <input type="radio" name="intact_pressure" value="3" id="absent_light" formControlName="intact_pressure"
                  class="p-radiobutton" (change)="handleInput($event, 'intactpressure')" />
              </td>
              <td>
                <input type="radio" name="intact_pressure" value="4" id="not_tested_light"
                  formControlName="intact_pressure" class="p-radiobutton"
                  (change)="handleInput($event, 'intactpressure')" />
              </td>
              <td>
                <input class="m-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                  formControlName="pressure_remark"  [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('pin_remark')
                      ?.invalid) ||
                  (initialAssessementAdult.get('pin_remark')
                    ?.invalid &&
                    initialAssessementAdult.get('pin_remark')
                      .touched)
                " />
                 <div class="invalid-feedback">
                  Pressure Remark is required
                </div>
              </td>
              <!-- <td> <input class="m-3" type="checkbox" value="intact" id="intact_pressure"
                                    formControlName="intact_pressure" /></td>
                            <td> <input class="m-3" type="checkbox" value="intact" id="impaired_pressure"
                                    formControlName="impaired_pressure" /></td>
                            <td> <input class="m-3" type="checkbox" value="intact" id="absent_pressure"
                                    formControlName="absent_pressure" /></td>
                            <td> <input class="m-3" type="checkbox" value="intact" id="not_tested_pressure"
                                    formControlName="not_tested_pressure" /></td>
                            <td> <input class="m-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                                    formControlName="pressure_remark" /></td> -->
            </tr>
            <tr class="border-light">
              <td scope="row"><label class="px-0">Pin Prick</label></td>
              <td>
                <input type="radio" name="intact_pin" value="1" id="intact_blunt" formControlName="intact_pin"
                  class="p-radiobutton" (change)="handleInput($event, 'intactpin')" />
              </td>
              <td>
                <input type="radio" name="intact_pin" value="2" id="impaired_light" formControlName="intact_pin"
                  class="p-radiobutton" (change)="handleInput($event, 'intactpin')" />
              </td>
              <td>
                <input type="radio" name="intact_pin" value="3" id="absent_light" formControlName="intact_pin"
                  class="p-radiobutton" (change)="handleInput($event, 'intactpin')" />
              </td>
              <td>
                <input type="radio" name="intact_pin" value="4" id="not_tested_light" formControlName="intact_pin"
                  class="p-radiobutton" (change)="handleInput($event, 'intactpin')" />
              </td>
              <td>
                <input class="m-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                  formControlName="pin_remark"  [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('pin_remark')
                      ?.invalid) ||
                  (initialAssessementAdult.get('pin_remark')
                    ?.invalid &&
                    initialAssessementAdult.get('pin_remark')
                      .touched)
                " />
                  <div class="invalid-feedback">
                    Pin Prick Remark is required
                  </div>
              </td>
              <!-- <td> <input class="m-3" type="checkbox" value="intact" id="intact_pin"
                                    formControlName="intact_pin" /></td>
                            <td> <input class="m-3" type="checkbox" value="intact" id="impaired_pin"
                                    formControlName="impaired_pin" /></td>
                            <td> <input class="m-3" type="checkbox" value="intact" id="absent_pin"
                                    formControlName="absent_pin" /></td>
                            <td> <input class="m-3" type="checkbox" value="intact" id="not_tested_pin"
                                    formControlName="not_tested_pin" /></td>
                            <td> <input class="m-3" type="text" class="form-control px-2 remark" placeholder="Remark"
                                    formControlName="pin_remark" /></td> -->
            </tr>
          </tbody>
        </table>
      </div>

      <!-- congnition -->
      <div class="form-group">
        <label class="subHeading px-0">Cognition:</label>
        <!-- Oriented to people -->
        <div class="row">
          <div class="col-sm-3 col-6">
            <label class="px-0">Oriented to people</label>
          </div>
          <div class="col-sm-2 col-3">
            <p class="d-flex align-items-center">
              <input type="radio" id="oriented_people_yes" value="Yes" name="oriented_people"
                formControlName="oriented_people" (change)="handleInput($event, 'people')"  />

              <label for="oriented_people_yes" class="mb-0 mx-2 px-0" style="padding-left: 22px !important">Yes</label>
            </p>
          </div>
          <div class="col-sm-2 col-3">
            <p class="d-flex align-items-center">
              <input type="radio" id="oriented_people_no" value="No" name="oriented_people"
                formControlName="oriented_people" (change)="handleInput($event, 'people')" />
              <label for="oriented_people_no" class="mb-0 mx-2 px-0" style="padding-left: 22px !important">No</label>
            </p>
          </div>
          <div class="col-sm-5 col-12">
            <div class="form-group">
              <input type="text" name="oriented_people_remark" formControlName="oriented_people_remark"
                id="oriented_people_remark" class="form-control" rows="3" placeholder="Remark" [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('oriented_people_remark')
                      ?.invalid) ||
                  (initialAssessementAdult.get('oriented_people_remark')
                    ?.invalid &&
                    initialAssessementAdult.get('oriented_people_remark')
                      .touched)
                " />

              <div class="invalid-feedback">
                Oriented people remark is required
              </div>
            </div>
          </div>
        </div>

        <!-- Oriented to time -->
        <div class="row">
          <div class="col-sm-3 col-6">
            <label class="px-0">Oriented to time</label>
          </div>
          <div class="col-sm-2 col-3">
            <p class="d-flex align-items-center">
              <input type="radio" name="oriented_time" value="Yes" id="oriented_time_yes" formControlName="oriented_time"
                (change)="handleInput($event, 'time')" />
              <label for="oriented_time_yes" class="mb-0 mx-2 px-0" style="padding-left: 22px !important">Yes</label>
            </p>
          </div>
          <div class="col-sm-2 col-3">
            <p class="d-flex align-items-center">
              <input type="radio" name="oriented_time" value="No" id="oriented_time_no"
                formControlName="oriented_time" (change)="handleInput($event, 'time')" />
              <label for="oriented_time_no" class="mb-0 mx-2 px-0" style="padding-left: 22px !important">No</label>
            </p>
          </div>
          <div class="col-sm-5 col-12">
            <div class="form-group">
              <input type="text" formControlName="oriented_time_remark" id="oriented_time_remark" class="form-control"
                rows="3" placeholder="Remark" [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('oriented_time_remark')
                      ?.invalid) ||
                  (initialAssessementAdult.get('oriented_time_remark')
                    ?.invalid &&
                    initialAssessementAdult.get('oriented_time_remark').touched)
                " />
              <div class="invalid-feedback">
                Oriented time remark is required
              </div>
            </div>
          </div>
        </div>

        <!-- Oriented to place -->
        <div class="row">
          <div class="col-sm-3 col-6">
            <label class="px-0">Oriented to place</label>
          </div>
          <div class="col-sm-2 col-3">
            <p class="d-flex align-items-center">
              <input type="radio" name="oriented_place" value="Yes" id="oriented_place_yes"
                formControlName="oriented_place" (change)="handleInput($event, 'place')" />
              <label for="oriented_place_yes" class="mb-0 mx-2 px-0" style="padding-left: 22px !important">Yes</label>
            </p>
          </div>
          <div class="col-sm-2 col-3">
            <p class="d-flex align-items-center">
              <input type="radio" name="oriented_place" value="No" id="oriented_place_no"
                formControlName="oriented_place" (change)="handleInput($event, 'place')" />
              <label for="oriented_place_no" class="mb-0 mx-2 px-0" style="padding-left: 22px !important">No</label>
            </p>
          </div>
          <div class="col-sm-5 col-12">
            <div class="form-group">
              <input type="text" formControlName="oriented_place_remark" id="oriented_place_remark" class="form-control"
                rows="3" placeholder="Remark" [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('oriented_place_remark')
                      ?.invalid) ||
                  (initialAssessementAdult.get('oriented_place_remark')
                    ?.invalid &&
                    initialAssessementAdult.get('oriented_place_remark')
                      .touched)
                " />
              <div class="invalid-feedback">
                Oriented place remark is required
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- PALPATION -->
    <div class="add-listings-box">
      <h3>Palpation</h3>
      <div class="form-group">
        <label class="subHeading px-0">Soft Tissues and Skin Assessment:</label>
        <!-- Swelling -->
        <div class="row">
          <div class="col-sm-3 col-6">
            <label class="px-0">Swelling</label>
          </div>
          <div class="col-sm-2 col-3">
            <p class="d-flex align-items-center">
              <input type="radio" name="swelling" value="Yes" id="swelling_yes" formControlName="swelling"
                (change)="handleInput($event, 'swelling')" />
              <label for="swelling_yes" class="mb-0 mx-2 px-0" style="padding-left: 22px !important">Yes</label>
            </p>
          </div>
          <div class="col-sm-2 col-3">
            <p class="d-flex align-items-center">
              <input type="radio" name="swelling" value="No" id="swelling_no" formControlName="swelling"
                (change)="handleInput($event, 'swelling')" />
              <label for="swelling_no" class="mb-0 mx-2 px-0" style="padding-left: 22px !important">No</label>
            </p>
          </div>
          <div class="col-sm-5 col-12">
            <div class="form-group">
              <input type="text" name="swelling_remark" formControlName="swelling_remark" id="swelling_remark"
                class="form-control" rows="3" placeholder="Remark" [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('swelling_remark')?.invalid) ||
                  (initialAssessementAdult.get('swelling_remark')?.invalid &&
                    initialAssessementAdult.get('swelling_remark').touched)
                " />
              <div class="invalid-feedback">Swelling remark is required</div>
            </div>
          </div>
        </div>

        <!-- Scar -->
        <div class="row">
          <div class="col-sm-3 col-6">
            <label class="px-0">Scar</label>
          </div>
          <div class="col-sm-2 col-3">
            <p class="d-flex align-items-center">
              <input type="radio" name="scar" value="Yes" id="scar_yes" formControlName="scar"
                (change)="handleInput($event, 'scar')" />
              <label for="scar_yes" class="mb-0 mx-2 px-0" style="padding-left: 22px !important">Yes</label>
            </p>
          </div>
          <div class="col-sm-2 col-3">
            <p class="d-flex align-items-center">
              <input type="radio" name="scar" value="No" id="scar_no" formControlName="scar"
                (change)="handleInput($event, 'scar')" />
              <label for="scar_no" class="mb-0 mx-2 px-0" style="padding-left: 22px !important">No</label>
            </p>
          </div>
          <div class="col-sm-5 col-12">
            <div class="form-group">
              <input type="text" formControlName="scar_remark" id="scar_remark" class="form-control" rows="3"
                placeholder="Remark" [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('scar_remark')?.invalid) ||
                  (initialAssessementAdult.get('scar_remark')?.invalid &&
                    initialAssessementAdult.get('scar_remark').touched)
                " />
              <div class="invalid-feedback">Scar remark is required</div>
            </div>
          </div>
        </div>

        <!-- Worm -->
        <div class="row">
          <div class="col-sm-3 col-6">
            <label class="px-0">Worm</label>
          </div>
          <div class="col-sm-2 col-3">
            <p class="d-flex align-items-center">
              <input type="radio" name="worm" value="Yes" id="worm_yes" formControlName="worm"
                (change)="handleInput($event, 'worm')" />
              <label for="worm_yes" class="mb-0 mx-2 px-0" style="padding-left: 22px !important">Yes</label>
            </p>
          </div>
          <div class="col-sm-2 col-3">
            <p class="d-flex align-items-center">
              <input type="radio" name="worm" value="No" id="worm_no" formControlName="worm"
                (change)="handleInput($event, 'worm')" />
              <label for="worm_no" class="mb-0 mx-2 px-0" style="padding-left: 22px !important">No</label>
            </p>
          </div>
          <div class="col-sm-5 col-12">
            <div class="form-group">
              <input type="text" formControlName="worm_remark" id="worm_remark" class="form-control" rows="3"
                placeholder="Remark" [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('worm_remark')?.invalid) ||
                  (initialAssessementAdult.get('worm_remark')?.invalid &&
                    initialAssessementAdult.get('worm_remark').touched)
                " />
              <div class="invalid-feedback">Worm remark is required</div>
            </div>
          </div>
        </div>

        <!-- Infection -->
        <div class="row">
          <div class="col-sm-3 col-6">
            <label class="px-0">Infection</label>
          </div>
          <div class="col-sm-2 col-3">
            <p class="d-flex align-items-center">
              <input type="radio" name="infection" value="Yes" id="infection_yes" formControlName="infection"
                (change)="handleInput($event, 'infection')" />
              <label for="infection_yes" class="mb-0 mx-2 px-0" style="padding-left: 22px !important">Yes</label>
            </p>
          </div>
          <div class="col-sm-2 col-3">
            <p class="d-flex align-items-center">
              <input type="radio" name="infection" value="No" id="infection_no" formControlName="infection"
                (change)="handleInput($event, 'infection')" />
              <label for="infection_no" class="mb-0 mx-2 px-0" style="padding-left: 22px !important">No</label>
            </p>
          </div>
          <div class="col-sm-5 col-12">
            <div class="form-group">
              <input type="text" name="infection_remark" formControlName="infection_remark" id="infection_remark"
                class="form-control" rows="3" placeholder="Remark" [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('infection_remark')?.invalid) ||
                  (initialAssessementAdult.get('infection_remark')?.invalid &&
                    initialAssessementAdult.get('infection_remark').touched)
                " />
              <div class="invalid-feedback">Infection remark is required</div>
            </div>
          </div>
        </div>
        <!-- Wound -->
        <div class="row">
          <div class="col-sm-3 col-6">
            <label class="px-0">Wound</label>
          </div>
          <div class="col-sm-2 col-3">
            <p class="d-flex align-items-center">
              <input type="radio" name="wound" value="Yes" id="wound_yes" formControlName="wound"
                (change)="handleInput($event, 'wound')" />
              <label for="wound_yes" class="mb-0 mx-2 px-0" style="padding-left: 22px !important">Yes</label>
            </p>
          </div>
          <div class="col-sm-2 col-3">
            <p class="d-flex align-items-center">
              <input type="radio" name="wound" value="No" id="wound_no" formControlName="wound"
                (change)="handleInput($event, 'wound')" />
              <label for="wound_no" class="mb-0 mx-2 px-0" style="padding-left: 22px !important">No</label>
            </p>
          </div>
          <div class="col-sm-5 col-12">
            <div class="form-group">
              <input type="text" name="wound_remark" formControlName="wound_remark" id="wound_remark"
                class="form-control" rows="3" placeholder="Remark" [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('wound_remark')?.invalid) ||
                  (initialAssessementAdult.get('wound_remark')?.invalid &&
                    initialAssessementAdult.get('wound_remark').touched)
                " />
              <div class="invalid-feedback">Wound remark is required</div>
            </div>
          </div>
        </div>

        <!-- Pain -->
        <div class="row">
          <div class="col-sm-3 col-6">
            <label class="px-0">Pain</label>
          </div>
          <div class="col-sm-2 col-3">
            <p class="d-flex align-items-center">
              <input type="radio" name="pain" value="Yes" id="pain_yes" formControlName="pain"
                (change)="handleInput($event, 'pain')" />
              <label for="pain_yes" class="mb-0 mx-2 px-0" style="padding-left: 22px !important">Yes</label>
            </p>
          </div>
          <div class="col-sm-2 col-3">
            <p class="d-flex align-items-center">
              <input type="radio" name="pain" value="No" id="pain_no" formControlName="pain"
                (change)="handleInput($event, 'pain')" />
              <label for="pain_no" class="mb-0 mx-2 px-0" style="padding-left: 22px !important">No</label>
            </p>
          </div>
          <div class="col-sm-5 col-12">
            <div class="form-group">
              <input type="text" formControlName="pain_remark" id="pain_remark" class="form-control" rows="3"
                placeholder="Remark" [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('pain_remark')?.invalid) ||
                  (initialAssessementAdult.get('pain_remark')?.invalid &&
                    initialAssessementAdult.get('pain_remark').touched)
                " />
              <div class="invalid-feedback">Pain remark is required</div>
            </div>
          </div>
        </div>

        <!-- Others -->
        <div class="row">
          <div class="col-sm-3 col-6">
            <label class="px-0">Others</label>
          </div>
          <div class="col-sm-2 col-3">
            <p class="d-flex align-items-center">
              <input type="radio" name="others_soft_tissue" value="Yes" id="others_yes"
                formControlName="others_soft_tissue" (change)="handleInput($event, 'others')" />
              <label for="others_yes" class="mb-0 mx-2 px-0" style="padding-left: 22px !important">Yes</label>
            </p>
          </div>
          <div class="col-sm-2 col-3">
            <p class="d-flex align-items-center">
              <input type="radio" name="others_soft_tissue" value="No" id="others_no"
                formControlName="others_soft_tissue" (change)="handleInput($event, 'others')" />
              <label for="others_no" class="mb-0 mx-2 px-0" style="padding-left: 22px !important">No</label>
            </p>
          </div>
          <div class="col-sm-5 col-12">
            <div class="form-group">
              <input type="text" formControlName="others_remark" id="others_remark" class="form-control" rows="3"
                placeholder="Remark" [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('others_remark')?.invalid) ||
                  (initialAssessementAdult.get('others_remark')?.invalid &&
                    initialAssessementAdult.get('others_remark').touched)
                " />
              <div class="invalid-feedback">Others remark is required</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- MOTOR COMPONENTS -->
    <div class="add-listings-box">
      <h3>Motor Components</h3>
      <label class="subHeading mb-3">Joint and Muscle:</label>
      <div class="form-group table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr style="white-space: nowrap">
              <th scope="col" class="text-center" rowspan="2">
                <label class="subHeading px-0">Joint</label>
              </th>
              <th scope="col" class="text-center" rowspan="2">
                <label class="subHeading px-0">Movement</label>
              </th>
              <th scope="col" class="text-center" rowspan="2">
                <label class="subHeading px-0">Norm ROM</label>
              </th>
              <th scope="col" class="text-center" colspan="2">
                <label class="subHeading px-0">Passive ROM</label>
              </th>
              <th scope="col" class="text-center" colspan="2">
                <label class="subHeading px-0">Active ROM</label>
              </th>
              <th scope="col" class="text-center" rowspan="2">
                <label class="subHeading px-0">Comments</label>
              </th>
              <th scope="col" class="text-center" colspan="2">
                <label class="subHeading px-0">Muscle Strength</label>
              </th>
            </tr>
            <tr>
              <th class="text-center">R</th>
              <th class="text-center">L</th>
              <th class="text-center">R</th>
              <th class="text-center">L</th>
              <th class="text-center">R</th>
              <th class="text-center">L</th>
            </tr>
          </thead>
          <tbody>
            <!-- NECK ROW -->
            <!-- Flexion -->
            <tr>
              <td scope="row" rowspan="4"><label class="px-0">Neck</label></td>
              <td>Flexion</td>
              <td>0-50</td>
              <td>
                <input type="checkbox" value="intact" id="neck_flexion_passive_r"
                  formControlName="neck_flexion_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="neck_flexion_passive_l"
                  formControlName="neck_flexion_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="neck_flexion_active_r"
                  formControlName="neck_flexion_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="neck_flexion_active_l"
                  formControlName="neck_flexion_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="nech_flexion_comment"
                  formControlName="nech_flexion_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="neck_flexion_muscle_r"
                  formControlName="neck_flexion_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="neck_flexion_muscle_l"
                  formControlName="neck_flexion_muscle_l" />
              </td>
            </tr>

            <!-- Extension -->
            <tr>
              <td>Extension</td>
              <td>0-60</td>
              <td>
                <input type="checkbox" value="intact" id="neck_extension_passive_r"
                  formControlName="neck_extension_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="neck_extension_passive_l"
                  formControlName="neck_extension_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="neck_extension_active_r"
                  formControlName="neck_extension_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="neck_extension_active_l"
                  formControlName="neck_extension_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="neck_extension_comment"
                  formControlName="neck_extension_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="neck_extension_muscle_r"
                  formControlName="neck_extension_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="neck_extension_muscle_l"
                  formControlName="neck_extension_muscle_l" />
              </td>
            </tr>

            <!-- Rotation -->
            <tr>
              <td>Rotation</td>
              <td>0-80</td>
              <td>
                <input type="checkbox" value="intact" id="neck_rotation_passive_r"
                  formControlName="neck_rotation_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="neck_rotation_passive_l"
                  formControlName="neck_rotation_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="neck_rotation_active_r"
                  formControlName="neck_rotation_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="neck_rotation_active_l"
                  formControlName="neck_rotation_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="neck_rotation_comment"
                  formControlName="neck_rotation_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="neck_rotation_muscle_r"
                  formControlName="neck_rotation_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="neck_rotation_muscle_l"
                  formControlName="neck_rotation_muscle_l" />
              </td>
            </tr>

            <!-- Side flex -->
            <tr>
              <td>Side flex</td>
              <td>0-45</td>
              <td>
                <input type="checkbox" value="intact" id="neck_sideFlex_passive_r"
                  formControlName="neck_sideFlex_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="neck_sideFlex_passive_l"
                  formControlName="neck_sideFlex_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="neck_sideFlex_active_r"
                  formControlName="neck_sideFlex_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="neck_sideFlex_active_l"
                  formControlName="neck_sideFlex_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="neck_sideFlex_comment"
                  formControlName="neck_sideFlex_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="neck_sideFlex_muscle_r"
                  formControlName="neck_sideFlex_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="neck_sideFlex_muscle_l"
                  formControlName="neck_sideFlex_muscle_l" />
              </td>
            </tr>

            <!-- SHOULDER ROW -->
            <!-- Flexion -->
            <tr>
              <td scope="row" rowspan="6">
                <label class="px-0">Shoulder</label>
              </td>
              <td>Flexion</td>
              <td>0-180</td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_flexion_passive_r"
                  formControlName="shoulder_flexion_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_flexion_passive_l"
                  formControlName="shoulder_flexion_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_flexion_active_r"
                  formControlName="shoulder_flexion_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_flexion_active_l"
                  formControlName="shoulder_flexion_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="" formControlName="shoulder_flexion_comment"
                  placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_flexion_muscle_r"
                  formControlName="shoulder_flexion_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_flexion_muscle_l"
                  formControlName="shoulder_flexion_muscle_l" />
              </td>
            </tr>

            <!-- Extension -->
            <tr>
              <td>Extension</td>
              <td>0-60</td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_extension_passive_r"
                  formControlName="shoulder_extension_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_extension_passive_l"
                  formControlName="shoulder_extension_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_extension_active_r"
                  formControlName="shoulder_extension_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_extension_active_l"
                  formControlName="shoulder_extension_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="shoulder_extension_comment"
                  formControlName="shoulder_extension_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_extension_muscle_r"
                  formControlName="shoulder_extension_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_extension_muscle_l"
                  formControlName="shoulder_extension_muscle_l" />
              </td>
            </tr>

            <!-- Abduction -->
            <tr>
              <td>Abduction</td>
              <td>0-180</td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_abduction_passive_r"
                  formControlName="shoulder_abduction_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_abduction_passive_l"
                  formControlName="shoulder_abduction_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_abduction_active_r"
                  formControlName="shoulder_abduction_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_abduction_active_l"
                  formControlName="shoulder_abduction_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="" formControlName="shoulder_abduction_comment"
                  placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_abduction_muscle_r"
                  formControlName="shoulder_abduction_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_abduction_muscle_l"
                  formControlName="shoulder_abduction_muscle_l" />
              </td>
            </tr>

            <!-- Adduction -->
            <tr>
              <td>Adduction</td>
              <td>0-90</td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_adduction_passive_r"
                  formControlName="shoulder_adduction_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_adduction_passive_l"
                  formControlName="shoulder_adduction_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_adduction_active_r"
                  formControlName="shoulder_adduction_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_adduction_active_l"
                  formControlName="shoulder_adduction_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="shoulder_adduction_comment"
                  formControlName="shoulder_adduction_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_adduction_muscle_r"
                  formControlName="shoulder_adduction_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_adduction_muscle_l"
                  formControlName="shoulder_adduction_muscle_l" />
              </td>
            </tr>

            <!-- Internal Rotation -->
            <tr>
              <td>Internal Rotation</td>
              <td>0-70</td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_internal_rotation_passive_r"
                  formControlName="shoulder_internal_rotation_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_internal_rotation_passive_l"
                  formControlName="shoulder_internal_rotation_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_internal_rotation_active_r"
                  formControlName="shoulder_internal_rotation_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_internal_rotation_active_l"
                  formControlName="shoulder_internal_rotation_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="shoulder_internal_rotation_comment"
                  formControlName="shoulder_internal_rotation_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_internal_rotation_muscle_r"
                  formControlName="shoulder_internal_rotation_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_internal_rotation_muscle_l"
                  formControlName="shoulder_internal_rotation_muscle_l" />
              </td>
            </tr>

            <!-- External Rotation -->
            <tr>
              <td>External Rotation</td>
              <td>0-90</td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_external_rotation_passive_r"
                  formControlName="shoulder_external_rotation_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_external_rotation_passive_l"
                  formControlName="shoulder_external_rotation_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_external_rotation_active_r"
                  formControlName="shoulder_external_rotation_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_external_rotation_active_l"
                  formControlName="shoulder_external_rotation_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="shoulder_external_rotation_comment"
                  formControlName="shoulder_external_rotation_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_external_rotation_muscle_r"
                  formControlName="shoulder_external_rotation_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="shoulder_external_rotation_muscle_l"
                  formControlName="shoulder_external_rotation_muscle_l" />
              </td>
            </tr>

            <!-- ELBOW ROW-->
            <!-- Flexion -->
            <tr>
              <td scope="row" rowspan="4"><label class="px-0">Elbow</label></td>
              <td>Flexion</td>
              <td>0-50</td>
              <td>
                <input type="checkbox" value="intact" id="elbow_flexion_passive_r"
                  formControlName="elbow_flexion_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="elbow_flexion_passive_l"
                  formControlName="elbow_flexion_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="elbow_flexion_active_r"
                  formControlName="elbow_flexion_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="elbow_flexion_active_l"
                  formControlName="elbow_flexion_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="elbow_flexion_comment"
                  formControlName="elbow_flexion_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="elbow_flexion_muscle_r"
                  formControlName="elbow_flexion_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="elbow_flexion_muscle_l"
                  formControlName="elbow_flexion_muscle_l" />
              </td>
            </tr>

            <!-- Extension -->
            <tr>
              <td>Extension</td>
              <td>0-60</td>
              <td>
                <input type="checkbox" value="intact" id="elbow_extension_passive_r"
                  formControlName="elbow_extension_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="elbow_extension_passive_l"
                  formControlName="elbow_extension_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="elbow_extension_active_r"
                  formControlName="elbow_extension_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="elbow_extension_active_l"
                  formControlName="elbow_extension_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="elbow_extension_comment"
                  formControlName="elbow_extension_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="elbow_extension_muscle_r"
                  formControlName="elbow_extension_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="elbow_extension_muscle_l"
                  formControlName="elbow_extension_muscle_l" />
              </td>
            </tr>

            <!-- Supination -->
            <tr>
              <td>Supination</td>
              <td>0-80</td>
              <td>
                <input type="checkbox" value="intact" id="elbow_supination_passive_r"
                  formControlName="elbow_supination_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="elbow_supination_passive_l"
                  formControlName="elbow_supination_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="elbow_supination_active_r"
                  formControlName="elbow_supination_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="elbow_supination_active_l"
                  formControlName="elbow_supination_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="elbow_supination_comment"
                  formControlName="elbow_supination_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="elbow_supination_muscle_r"
                  formControlName="elbow_supination_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="elbow_supination_muscle_l"
                  formControlName="elbow_supination_muscle_l" />
              </td>
            </tr>

            <!-- Pronation -->
            <tr>
              <td>Pronation</td>
              <td>0-80</td>
              <td>
                <input type="checkbox" value="intact" id="elbow_pronation_passive_r"
                  formControlName="elbow_pronation_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="elbow_pronation_passive_l"
                  formControlName="elbow_pronation_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="elbow_pronation_active_r"
                  formControlName="elbow_pronation_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="elbow_pronation_active_l"
                  formControlName="elbow_pronation_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="" formControlName="elbow_pronation_comment"
                  placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="elbow_pronation_muscle_r"
                  formControlName="elbow_pronation_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="elbow_pronation_muscle_l"
                  formControlName="elbow_pronation_muscle_l" />
              </td>
            </tr>

            <!--WRIST ROW -->
            <!-- Flexion -->
            <tr>
              <td scope="row" rowspan="4"><label class="px-0">Wrist</label></td>
              <td>Flexion</td>
              <td>0-50</td>
              <td>
                <input type="checkbox" value="intact" id="wrist_flexion_passive_r"
                  formControlName="wrist_flexion_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="wrist_flexion_passive_l"
                  formControlName="wrist_flexion_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="wrist_flexion_active_r"
                  formControlName="wrist_flexion_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="wrist_flexion_active_l"
                  formControlName="wrist_flexion_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="wrist_flexion_comment"
                  formControlName="wrist_flexion_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="wrist_flexion_muscle_r"
                  formControlName="wrist_flexion_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="wrist_flexion_muscle_l"
                  formControlName="wrist_flexion_muscle_l" />
              </td>
            </tr>

            <!-- Extension -->
            <tr>
              <td>Extension</td>
              <td>0-70</td>
              <td>
                <input type="checkbox" value="intact" id="wrist_extension_passive_r"
                  formControlName="wrist_extension_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="wrist_extension_passive_l"
                  formControlName="wrist_extension_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="wrist_extension_active_r"
                  formControlName="wrist_extension_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="wrist_extension_active_l"
                  formControlName="wrist_extension_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="" formControlName="wrist_extension_comment"
                  placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="wrist_extension_muscle_r"
                  formControlName="wrist_extension_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="wrist_extension_muscle_l"
                  formControlName="wrist_extension_muscle_l" />
              </td>
            </tr>

            <!-- Ulnar Deviation -->
            <tr>
              <td>Ulnar Deviation</td>
              <td>0-30</td>
              <td>
                <input type="checkbox" value="intact" id="wrist_ulnar_deviation_passive_r"
                  formControlName="wrist_ulnar_deviation_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="wrist_ulnar_deviation_passive_l"
                  formControlName="wrist_ulnar_deviation_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="wrist_ulnar_deviation_active_r"
                  formControlName="wrist_ulnar_deviation_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="wrist_ulnar_deviation_active_l"
                  formControlName="wrist_ulnar_deviation_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="wrist_ulnar_deviation_comment"
                  formControlName="wrist_ulnar_deviation_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="wrist_ulnar_deviation_muscle_r"
                  formControlName="wrist_ulnar_deviation_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="wrist_ulnar_deviation_muscle_l"
                  formControlName="wrist_ulnar_deviation_muscle_l" />
              </td>
            </tr>

            <!-- Radial Deviation -->
            <tr>
              <td>Radial Deviation</td>
              <td>0-20</td>
              <td>
                <input type="checkbox" value="intact" id="wrist_radial_deviation_passive_r"
                  formControlName="wrist_radial_deviation_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="wrist_radial_deviation_passive_l"
                  formControlName="wrist_radial_deviation_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="wrist_radial_deviation_active_r"
                  formControlName="wrist_radial_deviation_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="wrist_radial_deviation_active_l"
                  formControlName="wrist_radial_deviation_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="wrist_radial_deviation_comment"
                  formControlName="wrist_radial_deviation_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="wrist_radial_deviation_muscle_r"
                  formControlName="wrist_radial_deviation_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="wrist_radial_deviation_muscle_l"
                  formControlName="wrist_radial_deviation_muscle_l" />
              </td>
            </tr>

            <!-- DIGITS ROW -->
            <!-- Thumb MCP -->
            <tr>
              <td scope="row" rowspan="5">
                <label class="px-0">Digits</label>
              </td>
              <td>Thumb MCP Ext/flex</td>
              <td>10/30</td>
              <td>
                <input type="checkbox" value="intact" id="digits_thump_passive_r"
                  formControlName="digits_thump_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="digits_thump_passive_l"
                  formControlName="digits_thump_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="digits_thump_active_r"
                  formControlName="digits_thump_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="digits_thump_active_l"
                  formControlName="digits_thump_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="digits_thump_comment"
                  formControlName="digits_thump_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="digits_thump_muscle_r"
                  formControlName="digits_thump_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="digits_thump_muscle_l"
                  formControlName="digits_thump_muscle_l" />
              </td>
            </tr>

            <!-- Thump IP -->
            <tr>
              <td>Thumb IP Ext/Flex</td>
              <td>0/80</td>
              <td>
                <input type="checkbox" value="intact" id="digits_thump_ip_passive_r"
                  formControlName="digits_thump_ip_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="digits_thump_ip_passive_l"
                  formControlName="digits_thump_ip_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="digits_thump_ip_active_r"
                  formControlName="digits_thump_ip_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="digits_thump_ip_active_l"
                  formControlName="digits_thump_ip_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="digits_thump_ip_comment"
                  formControlName="digits_thump_ip_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="digits_thump_ip_muscle_r"
                  formControlName="digits_thump_ip_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="digits_thump_ip_muscle_l"
                  formControlName="digits_thump_ip_muscle_l" />
              </td>
            </tr>

            <!-- MCP Ext/Flex -->
            <tr>
              <td>MCP Ext/Flex</td>
              <td>0/90</td>
              <td>
                <input type="checkbox" value="intact" id="digits_mcp_passive_r"
                  formControlName="digits_mcp_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="digits_mcp_passive_l"
                  formControlName="digits_mcp_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="digits_mcp_active_r" formControlName="digits_mcp_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="digits_mcp_active_l" formControlName="digits_mcp_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="digits_mcp_comment"
                  formControlName="digits_mcp_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="digits_mcp_muscle_r" formControlName="digits_mcp_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="digits_mcp_muscle_l" formControlName="digits_mcp_muscle_l" />
              </td>
            </tr>

            <!-- PIP Ext/Flex -->
            <tr>
              <td>PIP Ext/Flex</td>
              <td>0/100</td>
              <td>
                <input type="checkbox" value="intact" id="digits_pip_passive_r"
                  formControlName="digits_pip_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="digits_pip_passive_l"
                  formControlName="digits_pip_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="digits_pip_active_r" formControlName="digits_pip_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="digits_pip_active_l" formControlName="digits_pip_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="digits_pip_comment"
                  formControlName="digits_pip_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="digits_pip_muscle_r" formControlName="digits_pip_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="digits_pip_muscle_l" formControlName="digits_pip_muscle_l" />
              </td>
            </tr>

            <!-- DIP Ext/Flex -->
            <tr>
              <td>DIP Ext/Flex</td>
              <td>0/90</td>
              <td>
                <input type="checkbox" value="intact" id="digits_dip_passive_r"
                  formControlName="digits_dip_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="digits_dip_passive_l"
                  formControlName="digits_dip_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="digits_dip_active_r" formControlName="digits_dip_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="digits_dip_active_l" formControlName="digits_dip_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="digits_dip_comment"
                  formControlName="digits_dip_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="digits_dip_muscle_r" formControlName="digits_dip_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="digits_dip_muscle_l" formControlName="digits_dip_muscle_l" />
              </td>
            </tr>

            <!-- HIP ROW -->
            <!-- Flexion -->
            <tr>
              <td scope="row" rowspan="6"><label class="px-0">Hip</label></td>
              <td>Flexion</td>
              <td>0-120</td>
              <td>
                <input type="checkbox" value="intact" id="hip_flexion_passive_r"
                  formControlName="hip_flexion_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_flexion_passive_l"
                  formControlName="hip_flexion_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_flexion_active_r"
                  formControlName="hip_flexion_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_flexion_active_l"
                  formControlName="hip_flexion_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="hip_flexion_comment"
                  formControlName="hip_flexion_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_flexion_muscle_r"
                  formControlName="hip_flexion_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_flexion_muscle_l"
                  formControlName="hip_flexion_muscle_l" />
              </td>
            </tr>

            <!-- Extension -->
            <tr>
              <td>Extension</td>
              <td>0-20</td>
              <td>
                <input type="checkbox" value="intact" id="hip_extension_passive_r"
                  formControlName="hip_extension_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_extension_passive_l"
                  formControlName="hip_extension_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_extension_active_r"
                  formControlName="hip_extension_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_extension_active_l"
                  formControlName="hip_extension_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="hip_extension_comment"
                  formControlName="hip_extension_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_extension_muscle_r"
                  formControlName="hip_extension_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_extension_muscle_l"
                  formControlName="hip_extension_muscle_l" />
              </td>
            </tr>

            <!-- Abduction -->
            <tr>
              <td>Abduction</td>
              <td>0-45</td>
              <td>
                <input type="checkbox" value="intact" id="hip_abduction_passive_r"
                  formControlName="hip_abduction_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_abduction_passive_l"
                  formControlName="hip_abduction_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_abduction_active_r"
                  formControlName="hip_abduction_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_abduction_active_l"
                  formControlName="hip_abduction_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="hip_abduction_comment"
                  formControlName="hip_abduction_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_abduction_muscle_r"
                  formControlName="hip_abduction_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_abduction_muscle_l"
                  formControlName="hip_abduction_muscle_l" />
              </td>
            </tr>

            <!--Adduction-->
            <tr>
              <td>Adduction</td>
              <td>0-20</td>
              <td>
                <input type="checkbox" value="intact" id="hip_adduction_passive_r"
                  formControlName="hip_adduction_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_adduction_passive_l"
                  formControlName="hip_adduction_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_adduction_active_r"
                  formControlName="hip_adduction_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_adduction_active_l"
                  formControlName="hip_adduction_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="hip_adduction_comment"
                  formControlName="hip_adduction_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_adduction_muscle_r"
                  formControlName="hip_adduction_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_adduction_muscle_l"
                  formControlName="hip_adduction_muscle_l" />
              </td>
            </tr>

            <!--Internal Rotation-->
            <tr>
              <td>Internal Rotation</td>
              <td>0-45</td>
              <td>
                <input type="checkbox" value="intact" id="hip_internal_rotation_passive_r"
                  formControlName="hip_internal_rotation_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_internal_rotation_passive_l"
                  formControlName="hip_internal_rotation_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_internal_rotation_active_r"
                  formControlName="hip_internal_rotation_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_internal_rotation_active_l"
                  formControlName="hip_internal_rotation_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="hip_internal_rotation_comment"
                  formControlName="hip_internal_rotation_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_internal_rotation_muscle_r"
                  formControlName="hip_internal_rotation_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_internal_rotation_muscle_l"
                  formControlName="hip_internal_rotation_muscle_l" />
              </td>
            </tr>

            <!--External Rotation-->
            <tr>
              <td>External Rotation</td>
              <td>0-45</td>
              <td>
                <input type="checkbox" value="intact" id="hip_external_rotation_passive_r"
                  formControlName="hip_external_rotation_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_external_rotation_passive_l"
                  formControlName="hip_external_rotation_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_external_rotation_active_r"
                  formControlName="hip_external_rotation_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_external_rotation_active_l"
                  formControlName="hip_external_rotation_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="hip_external_rotation_comment"
                  formControlName="hip_external_rotation_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_external_rotation_muscle_r"
                  formControlName="hip_external_rotation_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="hip_external_rotation_muscle_l"
                  formControlName="hip_external_rotation_muscle_l" />
              </td>
            </tr>

            <!-- KNEE ROW -->
            <!-- Flexion -->
            <tr>
              <td scope="row" rowspan="2"><label class="px-0">Knee</label></td>
              <td>Flexion</td>
              <td>0-135</td>
              <td>
                <input type="checkbox" value="intact" id="knee_flexion_passive_r"
                  formControlName="knee_flexion_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="knee_flexion_passive_l"
                  formControlName="knee_flexion_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="knee_flexion_active_r"
                  formControlName="knee_flexion_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="knee_flexion_active_l"
                  formControlName="knee_flexion_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="knee_flexion_comment"
                  formControlName="knee_flexion_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="knee_flexion_muscle_r"
                  formControlName="knee_flexion_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="knee_flexion_muscle_l"
                  formControlName="knee_flexion_muscle_l" />
              </td>
            </tr>

            <!-- Extension -->
            <tr>
              <td>Extension</td>
              <td>135-0</td>
              <td>
                <input type="checkbox" value="intact" id="knee_extension_passive_r"
                  formControlName="knee_extension_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="knee_extension_passive_l"
                  formControlName="knee_extension_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="knee_extension_active_r"
                  formControlName="knee_extension_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="knee_extension_active_l"
                  formControlName="knee_extension_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="knee_extension_comment"
                  formControlName="knee_extension_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="knee_extension_muscle_r"
                  formControlName="knee_extension_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="knee_extension_muscle_l"
                  formControlName="knee_extension_muscle_l" />
              </td>
            </tr>

            <!-- ANKLE ROW -->
            <!-- PF -->
            <tr>
              <td scope="row" rowspan="6"><label class="px-0">Ankle</label></td>
              <td>PF</td>
              <td>0-45</td>
              <td>
                <input type="checkbox" value="intact" id="ankle_pf_passive_r" formControlName="ankle_pf_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="ankle_pf_passive_l" formControlName="ankle_pf_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="ankle_pf_active_r" formControlName="ankle_pf_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="ankle_pf_active_l" formControlName="ankle_pf_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="ankle_pf_comment" formControlName="ankle_pf_comment"
                  placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="ankle_pf_muscle_r" formControlName="ankle_pf_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="ankle_pf_muscle_l" formControlName="ankle_pf_muscle_l" />
              </td>
            </tr>

            <!-- DF -->
            <tr>
              <td>DF</td>
              <td>0-20</td>
              <td>
                <input type="checkbox" value="intact" id="ankle_df_passive_r" formControlName="ankle_df_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="ankle_df_passive_l" formControlName="ankle_df_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="ankle_df_active_r" formControlName="ankle_df_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="ankle_df_active_l" formControlName="ankle_df_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="ankle_df_comment" formControlName="ankle_df_comment"
                  placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="ankle_df_muscle_r" formControlName="ankle_df_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="ankle_df_muscle_l" formControlName="ankle_df_muscle_l" />
              </td>
            </tr>

            <!-- Inversion -->
            <tr>
              <td>Inversion</td>
              <td>0-35</td>
              <td>
                <input type="checkbox" value="intact" id="knee_inversion_passive_r"
                  formControlName="knee_inversion_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="knee_inversion_passive_l"
                  formControlName="knee_inversion_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="knee_inversion_active_r"
                  formControlName="knee_inversion_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="knee_inversion_active_l"
                  formControlName="knee_inversion_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="knee_inversion_comment"
                  formControlName="knee_inversion_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="knee_inversion_muscle_r"
                  formControlName="knee_inversion_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="knee_inversion_muscle_l"
                  formControlName="knee_inversion_muscle_l" />
              </td>
            </tr>

            <!-- Eversion -->
            <tr>
              <td>Eversion</td>
              <td>0-15</td>
              <td>
                <input type="checkbox" value="intact" id="knee_eversion_passive_r"
                  formControlName="knee_eversion_passive_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="knee_eversion_passive_l"
                  formControlName="knee_eversion_passive_l" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="knee_eversion_active_r"
                  formControlName="knee_eversion_active_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="knee_eversion_active_l"
                  formControlName="knee_eversion_active_l" />
              </td>
              <td>
                <input type="text" class="form-control px-2" id="knee_eversion_comment"
                  formControlName="knee_eversion_comment" placeholder="Comment" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="knee_eversion_muscle_r"
                  formControlName="knee_eversion_muscle_r" />
              </td>
              <td>
                <input type="checkbox" value="intact" id="knee_eversion_muscle_l"
                  formControlName="knee_eversion_muscle_l" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row mt-3">
        <!-- Head Control -->
        <div class="col-sm-4 col-6">
          <div class="form-group">
            <label class="mr-3 px-0">Head Control:</label>
            <p class="d-flex">
              <input type="radio" id="head_control_yes" value="Yes" formControlName="head_control" />
              <label for="head_control_yes" class="mb-0 mx-2">Yes</label>
            </p>
            <p class="d-flex">
              <input type="radio" id="head_control_no" value="No" formControlName="head_control" />
              <label for="head_control_no" class="mb-0 mx-2">No</label>
            </p>
          </div>
        </div>

        <!-- Trunk Control -->
        <div class="col-sm-4 col-6">
          <div class="form-group mb-0">
            <label class="mr-3 px-0">Trunk Control:</label>
            <p class="d-flex">
              <input type="radio" id="trunk_control_yes" value="Yes" formControlName="trunk_control" />
              <label for="trunk_control_yes" class="mb-0 mx-2">Yes</label>
            </p>
            <p class="d-flex">
              <input type="radio" id="trunk_control_no" value="No" formControlName="trunk_control" />
              <label for="trunk_control_no" class="mb-0 mx-2">No</label>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- MOBILITY & BALANCE -->
    <div class="add-listings-box pb-2">
      <h3>Mobility And Balance</h3>
      <div class="row subHeading">
        <div class="col-sm-3 col-6">I - Independent</div>
        <div class="col-sm-3 col-6">A - Assistance</div>
        <div class="col-sm-3 col-6">D - Dependent</div>
        <div class="col-sm-3 col-6">N - Not Tested</div>
      </div>
      <div class="row add-listings-box pb-2"></div>
      <div class="form-group table-responsive mb-0">
        <table class="table">
          <thead>
            <tr class="border-light">
              <th scope="col">
                <label class="subHeading px-0">Activity</label>
              </th>
              <th scope="col"><label class="subHeading px-0">I</label></th>
              <th scope="col"><label class="subHeading px-0">A</label></th>
              <th scope="col"><label class="subHeading px-0">D</label></th>
              <th scope="col"><label class="subHeading px-0">N</label></th>
              <th scope="col"><label class="subHeading px-0">Remark</label></th>
            </tr>
          </thead>
          <tbody>
            <tr class="border-light">
              <td scope="row"><label class="px-0">Rolling</label></td>
              <td>
                <input type="radio" value="1" id="rolling_I" formControlName="rolling_I" name="rolling_I"
                  class="p-radiobutton" (change)="handleInput($event, 'independent')" />
              </td>
              <td>
                <input type="radio" value="2" id="rolling_A" formControlName="rolling_I" name="rolling_I"
                  class="p-radiobutton" (change)="handleInput($event, 'independent')" />
              </td>
              <td>
                <input type="radio" value="3" id="rolling_D" formControlName="rolling_I" name="rolling_I"
                  class="p-radiobutton" (change)="handleInput($event, 'independent')" />
              </td>
              <td>
                <input type="radio" value="4" id="rolling_N" formControlName="rolling_I" name="rolling_I"
                  class="p-radiobutton" (change)="handleInput($event, 'independent')" />
              </td>
              <td>
                <input type="text" class="form-control px-2 remark" id="rolling_remark" placeholder="Remark"
                  formControlName="rolling_remark"[class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('rolling_remark')?.invalid) ||
                  (initialAssessementAdult.get('rolling_remark')?.invalid &&
                    initialAssessementAdult.get('rolling_remark').touched)
                " />
                   <div class="invalid-feedback"> Remarks is required</div>
              </td>
            </tr>
            <tr class="border-light">
              <td scope="row"><label class="px-0">Sitting</label></td>
              <td>
                <input type="radio" value="1" id="sitting_I" formControlName="sitting_I" name="sitting_I"
                  class="p-radiobutton" (change)="handleInput($event, 'independentsitting')" />
              </td>
              <td>
                <input type="radio" value="2" id="sitting_A" formControlName="sitting_I" name="sitting_I"
                  class="p-radiobutton" (change)="handleInput($event, 'independentsitting')" />
              </td>
              <td>
                <input type="radio" value="3" id="sitting_D" formControlName="sitting_I" name="sitting_I"
                  class="p-radiobutton" (change)="handleInput($event, 'independentsitting')" />
              </td>
              <td>
                <input type="radio" value="4" id="sitting_N" formControlName="sitting_I" name="sitting_I"
                  class="p-radiobutton" (change)="handleInput($event, 'independentsitting')" />
              </td>
              <td>
                <input type="text" class="form-control px-2 remark" id="sitting_remark" placeholder="Remark"
                  formControlName="sitting_remark" [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('sitting_remark')?.invalid) ||
                  (initialAssessementAdult.get('sitting_remark')?.invalid &&
                    initialAssessementAdult.get('sitting_remark').touched)
                "  />
                  <div class="invalid-feedback"> Remarks is required</div>
              </td>
            </tr>
            <tr class="border-light">
              <td scope="row"><label class="px-0">Standing</label></td>
              <td>
                <input type="radio" value="1" id="standing_I" formControlName="standing_I" name="standing_I"
                  class="p-radiobutton" (change)="handleInput($event, 'independentstanding')" />
              </td>
              <td>
                <input type="radio" value="2" id="standing_A" formControlName="standing_I" name="standing_I"
                  class="p-radiobutton" (change)="handleInput($event, 'independentstanding')" />
              </td>
              <td>
                <input type="radio" value="3" id="standing_D" formControlName="standing_I" name="standing_I"
                  class="p-radiobutton" (change)="handleInput($event, 'independentstanding')" />
              </td>
              <td>
                <input type="radio" value="4" id="standing_N" formControlName="standing_I" name="standing_I"
                  class="p-radiobutton" (change)="handleInput($event, 'independentstanding')" />
              </td>
              <td>
                <input type="text" class="form-control px-2 remark" id="standing_remark" placeholder="Remark"
                  formControlName="standing_remark"  [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('standing_remark')?.invalid) ||
                  (initialAssessementAdult.get('standing_remark')?.invalid &&
                    initialAssessementAdult.get('standing_remark').touched)
                " />
                  <div class="invalid-feedback"> Remarks is required</div>
              </td>
            </tr>
            <tr class="border-light">
              <td scope="row"><label class="px-0">Gait</label></td>
              <td>
                <input type="radio" value="1" id="gait_I" formControlName="gait_I" name="gait_I" class="p-radiobutton"
                  (change)="handleInput($event, 'independentgait')" />
              </td>
              <td>
                <input type="radio" value="2" id="gait_A" formControlName="gait_I" name="gait_I" class="p-radiobutton"
                  (change)="handleInput($event, 'independentgait')" />
              </td>
              <td>
                <input type="radio" value="3" id="gait_D" formControlName="gait_I" name="gait_I" class="p-radiobutton"
                  (change)="handleInput($event, 'independentgait')" />
              </td>
              <td>
                <input type="radio" value="4" id="gait_N" formControlName="gait_I" name="gait_I" class="p-radiobutton"
                  (change)="handleInput($event, 'independentgait')" />
              </td>
              <td>
                <input type="text" class="form-control px-2 remark" id="gait_remark" placeholder="Remark"
                  formControlName="gait_remark"  [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('gait_remark')?.invalid) ||
                  (initialAssessementAdult.get('gait_remark')?.invalid &&
                    initialAssessementAdult.get('gait_remark').touched)
                " />
                  <div class="invalid-feedback"> Remarks is required</div>
              </td>
            </tr>
            <tr class="border-light">
              <td scope="row"><label class="px-0">Balance</label></td>
              <td>
                <input type="radio" value="1" id="balance_I" formControlName="balance_I" name="balance_I"
                  class="p-radiobutton" (change)="handleInput($event, 'independentbalance')" />
              </td>
              <td>
                <input type="radio" value="2" id="balance_A" formControlName="balance_I" name="balance_I"
                  class="p-radiobutton" (change)="handleInput($event, 'independentbalance')" />
              </td>
              <td>
                <input type="radio" value="3" id="balance_D" formControlName="balance_I" name="balance_I"
                  class="p-radiobutton" (change)="handleInput($event, 'independentbalance')" />
              </td>
              <td>
                <input type="radio" value="4" id="balance_N" formControlName="balance_I" name="balance_I"
                  class="p-radiobutton" (change)="handleInput($event, 'independentbalance')" />
              </td>
              <td>
                <input type="text" class="form-control px-2 remark" id="balance_remark" placeholder="Remark"
                  formControlName="balance_remark"  [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('balance_remark')?.invalid) ||
                  (initialAssessementAdult.get('balance_remark')?.invalid &&
                    initialAssessementAdult.get('balance_remark').touched)
                " />
                  <div class="invalid-feedback"> Remarks is required</div>
              </td>
            </tr>
            <tr class="border-light">
              <td scope="row"><label class="px-0">Static Sitting</label></td>
              <td>
                <input type="radio" value="1" id="static_sitting_I" formControlName="static_sitting_I"
                  name="static_sitting_I" class="p-radiobutton" (change)="handleInput($event, 'independentstatic')" />
              </td>
              <td>
                <input type="radio" value="2" id="static_sitting_A" formControlName="static_sitting_I"
                  name="static_sitting_I" class="p-radiobutton" (change)="handleInput($event, 'independentstatic')" />
              </td>
              <td>
                <input type="radio" value="3" id="static_sitting_D" formControlName="static_sitting_I"
                  name="static_sitting_I" class="p-radiobutton" (change)="handleInput($event, 'independentstatic')" />
              </td>
              <td>
                <input type="radio" value="4" id="static_sitting_N" formControlName="static_sitting_I"
                  name="static_sitting_I" class="p-radiobutton" (change)="handleInput($event, 'independentstatic')" />
              </td>
              <td>
                <input type="text" class="form-control px-2 remark" id="static_sitting_remark" placeholder="Remark"
                  formControlName="static_sitting_remark"  [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('static_sitting_remark')?.invalid) ||
                  (initialAssessementAdult.get('static_sitting_remark')?.invalid &&
                    initialAssessementAdult.get('static_sitting_remark').touched)
                " />
                  <div class="invalid-feedback"> Remarks is required</div>
              </td>
            </tr>
            <tr class="border-light">
              <td scope="row"><label class="px-0">Static Standing</label></td>
              <td>
                <input type="radio" value="1" id="static_standing_I" formControlName="static_standing_I"
                  name="static_standing_I" class="p-radiobutton"
                  (change)="handleInput($event, 'independentstaticstanding')" />
              </td>
              <td>
                <input type="radio" value="2" id="static_standing_A" formControlName="static_standing_I"
                  name="static_standing_I" class="p-radiobutton"
                  (change)="handleInput($event, 'independentstaticstanding')" />
              </td>
              <td>
                <input type="radio" value="3" id="static_standing_D" formControlName="static_standing_I"
                  name="static_standing_I" class="p-radiobutton"
                  (change)="handleInput($event, 'independentstaticstanding')" />
              </td>
              <td>
                <input type="radio" value="4" id="static_standing_N" formControlName="static_standing_I"
                  name="static_standing_I" class="p-radiobutton"
                  (change)="handleInput($event, 'independentstaticstanding')" />
              </td>
              <td>
                <input type="text" class="form-control px-2 remark" id="static_standing_remark" placeholder="Remark"
                  formControlName="static_standing_remark" [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('static_standing_remark')?.invalid) ||
                  (initialAssessementAdult.get('static_standing_remark')?.invalid &&
                    initialAssessementAdult.get('static_standing_remark').touched)
                "  />
                  <div class="invalid-feedback"> Remarks is required</div>
              </td>
            </tr>
            <tr class="border-light">
              <td scope="row"><label class="px-0">Dynamic Sitting</label></td>
              <td>
                <input type="radio" value="1" id="dynamic_sitting_I" formControlName="dynamic_sitting_I"
                  name="dynamic_sitting_I" class="p-radiobutton"
                  (change)="handleInput($event, 'independentdynamicsitting')" />
              </td>
              <td>
                <input type="radio" value="2" id="dynamic_sitting_A" formControlName="dynamic_sitting_I"
                  name="dynamic_sitting_I" class="p-radiobutton"
                  (change)="handleInput($event, 'independentdynamicsitting')" />
              </td>
              <td>
                <input type="radio" value="3" id="dynamic_sitting_D" formControlName="dynamic_sitting_I"
                  name="dynamic_sitting_I" class="p-radiobutton"
                  (change)="handleInput($event, 'independentdynamicsitting')" />
              </td>
              <td>
                <input type="radio" value="4" id="dynamic_sitting_N" formControlName="dynamic_sitting_I"
                  name="dynamic_sitting_I" class="p-radiobutton"
                  (change)="handleInput($event, 'independentdynamicsitting')" />
              </td>
              <td>
                <input type="text" class="form-control px-2 remark" id="dynamic_sitting_remark" placeholder="Remark"
                  formControlName="dynamic_sitting_remark"  [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('dynamic_sitting_remark')?.invalid) ||
                  (initialAssessementAdult.get('dynamic_sitting_remark')?.invalid &&
                    initialAssessementAdult.get('dynamic_sitting_remark').touched)
                " />
                  <div class="invalid-feedback"> Remarks is required</div>
              </td>
            </tr>
            <tr class="border-light">
              <td scope="row"><label class="px-0">Dynamic Standing</label></td>
              <td>
                <input type="radio" value="1" id="dynamic_standing_I" formControlName="dynamic_standing_I"
                  name="dynamic_standing_I" class="p-radiobutton"
                  (change)="handleInput($event, 'independentdynamicstanding')" />
              </td>
              <td>
                <input type="radio" value="2" id="dynamic_standing_A" formControlName="dynamic_standing_I"
                  name="dynamic_standing_I" class="p-radiobutton"
                  (change)="handleInput($event, 'independentdynamicstanding')" />
              </td>
              <td>
                <input type="radio" value="3" id="dynamic_standing_D" formControlName="dynamic_standing_I"
                  name="dynamic_standing_I" class="p-radiobutton"
                  (change)="handleInput($event, 'independentdynamicstanding')" />
              </td>
              <td>
                <input type="radio" value="4" id="dynamic_standing_D" formControlName="dynamic_standing_I"
                  name="dynamic_standing_I" class="p-radiobutton"
                  (change)="handleInput($event, 'independentdynamicstanding')" />
              </td>
              <td>
                <input type="text" class="form-control px-2 remark" id="dynamic_standing_remark" placeholder="Remark"
                  formControlName="dynamic_standing_remark"  [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('dynamic_standing_remark')?.invalid) ||
                  (initialAssessementAdult.get('dynamic_standing_remark')?.invalid &&
                    initialAssessementAdult.get('dynamic_standing_remark').touched)
                " />
                  <div class="invalid-feedback"> Remarks is required</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!--Tranfer and Assisting Devices  -->
    <div class="add-listings-box">
      <h3>Transfer And Assisting Devices:</h3>
      <div class="form-group">
        <div class="row mt-3">
          <!-- Head Control -->
          <label class="checkbox checkbox-outline col-12">
            <input type="radio" value="1" id="transfer_assisting_none" formControlName="transfer_assisting"
              name="transfer_assisting" (change)="handleInput($event, 'transferassisting')" />
            None
          </label>
          <label class="checkbox checkbox-outline col-lg-2 col-sm-3 col-5">
            <input type="radio" value="2" id="transfer_assisting_crutches" formControlName="transfer_assisting"
              name="transfer_assisting" (change)="handleInput($event, 'transferassisting')" [class.is-invalid]="(submitAssessmentForm.submitted &&
              initialAssessementAdult.get('transfer_assisting_pair')?.invalid)
              ||
              initialAssessementAdult.get('transfer_assisting_pair')?.invalid
              &&
              initialAssessementAdult.get('transfer_assisting_pair').touched"/>
            Crutches
            <div class="invalid-feedback"> Crutches is required</div>
          </label>
        
          <label class="checkbox checkbox-outline col-lg-2 col-sm-3 col">
            <input type="radio" value="One" id="transfer_assisting_none"
              formControlName="transfer_assisting_pair" />
            One
          </label>
          <label class="checkbox checkbox-outline col-lg-2 col-sm-3 col">
            <input type="radio" value="Pair" id="transfer_assisting_pair"
              formControlName="transfer_assisting_pair" />
            Pair
          </label>

          <label class="checkbox checkbox-outline col-12">
            <input type="radio" value="3" id="transfer_assisting_walker" formControlName="transfer_assisting"
              name="transfer_assisting" (change)="handleInput($event, 'transferassisting')" />
            Walker
          </label>
          <label class="checkbox checkbox-outline col-12">
            <input type="radio" value="4" id="transfer_assisting_wheel_chair" formControlName="transfer_assisting"
              name="transfer_assisting" (change)="handleInput($event, 'transferassisting')" />
            Wheel Chair
          </label>

          <label class="checkbox checkbox-outline col-lg-2 col-sm-3 col-4">
            <input type="radio" value="5" id="transfer_assisting_others" formControlName="transfer_assisting"
              name="transfer_assisting" (change)="handleInput($event, 'transferassisting')" />
            Others
          </label>
          <div class="col-lg-10 col-sm-9 col-8">
            <input type="text" class="form-control px-2" id="transfer_assisting_others_description" placeholder="Others"
              formControlName="transfer_assisting_others_description" [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('transfer_assisting_others_description')?.invalid) ||
                  (initialAssessementAdult.get('transfer_assisting_others_description')?.invalid &&
                    initialAssessementAdult.get('transfer_assisting_others_description').touched)
                " />
            <div class="invalid-feedback"> Details is required</div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-sm-6 col-12">
            <label class="subHeading p-0 my-3">Problems Noted: </label>
            <textarea formControlName="problems_noted" placeholder="Problem Note" class="form-control px-2"
              id="problems_noted" rows="3" [disabled]=""></textarea>
          </div>

          <div class="col-sm-6 col-12">
            <label class="subHeading p-0 my-3">Session Goals: </label>
            <div class="row">
              <div class="col-sm-5 col-12">
                <div class="form-group mb-0">
                  <label class="checkbox checkbox-outline px-0">
                    <input type="checkbox" value="sterilizeAll" id="short_term" formControlName="short_term"
                      (change)="handleInput($event, 'shortterm')" />
                    Short Term(1 Month)
                  </label>
                </div>
              </div>
              <div class="col-sm-7 col-12">
                <div class="form-group">
                  <input type="text" class="w-100 form-control" value="shorttermsession" id="short_term_session"
                    formControlName="short_term_session" placeholder="Short Term session" [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('short_term_session')?.invalid) ||
                  (initialAssessementAdult.get('short_term_session')?.invalid &&
                    initialAssessementAdult.get('short_term_session').touched)
                " />
                  <div class="invalid-feedback">Short Term details is required</div>
                </div>
              </div>

              <div class="col-sm-5 col-12">
                <div class="form-group mb-0">
                  <label class="checkbox checkbox-outline px-0">
                    <input type="checkbox" value="sterilizeAll" id="long_term" formControlName="long_term"
                      (change)="handleInput($event, 'longterm')" />
                    Long Term(3-6 Month)
                  </label>
                </div>
              </div>

              <div class="col-sm-7 col-12">
                <div class="form-group">
                  <input type="text" class="w-100 form-control" value="longtermsession"
                    formControlName="long_term_session" id="long_term_session" placeholder="Long Term session"
                    [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('long_term_session')?.invalid) ||
                  (initialAssessementAdult.get('long_term_session')?.invalid &&
                    initialAssessementAdult.get('long_term_session').touched)
                " />
                  <div class="invalid-feedback">Long Term details is required</div>
                </div>
              </div>
            </div>
          </div>

          <!-- scope of session -->
          <div class="col-sm-6 col-12">
            <div class="form-group">
              <label class="subHeading p-0">Scope of Session:</label>
              <p-dropdown optionValue="id" [options]="sessionList" class="data-padding" optionLabel="session_title"
                [filter]="true" filterBy="session_title" formControlName="scope_session" placeholder="Scope of session"
                id="scope_session" [resetFilterOnHide]="true">
              </p-dropdown>
            </div>
          </div>

          <!-- Recommended duration -->
          <div class="col-sm-6 col-12">
            <div class="form-group">
              <label class="subHeading p-0">Recommended Duration:</label>
              <p-dropdown optionValue="id" [options]="recomended_duration" optionLabel="name" [filter]="true"
                filterBy="name" formControlName="recommended_duration" id="recommended_duration"
                placeholder="Recommended duration" [resetFilterOnHide]="true">
              </p-dropdown>
            </div>
          </div>

          <!-- No. of PT Sessions per Month -->
          <div class="col-sm-6 col-12">
            <div class="form-group">
              <label class="subHeading p-0">No. of PT Sessions per Month:</label>
              <p-dropdown optionValue="session_per_month" [options]="session_per_month" optionLabel="session_per_month"
                [filter]="true" filterBy="session_per_month" id="no_pt_sesssion_per_month"
                formControlName="no_pt_sesssion_per_month" placeholder="No of PT session per month"
                [resetFilterOnHide]="true">
              </p-dropdown>
            </div>
          </div>

          <!-- Goals to be acheived -->
          <div class="col-sm-6 col-12">
            <div class="form-group">
              <label class="subHeading p-0">Goals to be Acheived: </label>
              <textarea formControlName="goals_acheived" placeholder="Goals to be acheived" class="form-control px-2"
                id="goals_acheived" rows="3"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Sessions Technique -->
    <div class="add-listings-box">
      <h3>Sessions Technique:</h3>
      <div class="form-group">
        <!-- Exercise (ROM) -->
        <div class="row">
          <div class="col-lg-2 col-sm-3 col-12">
            <div class="form-group mb-0">
              <label class="checkbox checkbox-outline px-0">
                <input type="checkbox" value="sterilizeAll" id="exercise_checkbox" formControlName="exercise_checkbox"
                  (change)="handleInput($event, 'exercise')" />
                Exercise(ROM)
              </label>
            </div>
          </div>
          <div class="col-lg-5 col-sm col-12">
            <p-dropdown optionValue="duration" [options]="duration" optionLabel="duration" [filter]="true"
              filterBy="duration" id="exercise_duration" formControlName="exercise_duration" placeholder="Duration"
              [resetFilterOnHide]="true" [class.is-invalid]="
                (submitAssessmentForm.submitted &&
                  initialAssessementAdult.get('exercise_duration')?.invalid) ||
                (initialAssessementAdult.get('exercise_duration')?.invalid &&
                  initialAssessementAdult.get('exercise_duration').touched)
              ">
            </p-dropdown>
            <div class="invalid-feedback">Exercise duration is required</div>
          </div>
          <div class="col-lg-5 col-sm col-12 mt-sm-0 mt-2">
            <div class="form-group">
              <input type="text" class="w-100 form-control" value="intact" id="exercise_details"
                formControlName="exercise_details" placeholder="Details" [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('exercise_details')?.invalid) ||
                  (initialAssessementAdult.get('exercise_details')?.invalid &&
                    initialAssessementAdult.get('exercise_details').touched)
                " />
              <div class="invalid-feedback">Exercise details is required</div>
            </div>
          </div>
        </div>

        <!-- TENS -->
        <div class="row">
          <div class="col-lg-2 col-sm-3 col-12">
            <div class="form-group mb-0">
              <label class="checkbox checkbox-outline px-0">
                <input type="checkbox" value="sterilizeAll" id="tens_checkbox" formControlName="tens_checkbox"
                  (change)="handleInput($event, 'ten')" />
                TENS
              </label>
            </div>
          </div>
          <div class="col-lg-5 col-sm col-12">
            <p-dropdown optionValue="duration" [options]="duration" optionLabel="duration" [filter]="true"
              filterBy="duration" formControlName="tens_duration" id="tens_duration" placeholder="Duration"
              [resetFilterOnHide]="true" [class.is-invalid]="
                (submitAssessmentForm.submitted &&
                  initialAssessementAdult.get('tens_duration')?.invalid) ||
                (initialAssessementAdult.get('tens_duration')?.invalid &&
                  initialAssessementAdult.get('tens_duration').touched)
              ">
            </p-dropdown>
            <div class="invalid-feedback">Tens duration is required</div>
          </div>
          <div class="col-lg-5 col-sm col-12 mt-sm-0 mt-2">
            <div class="form-group">
              <input type="text" class="w-100 form-control" value="intact" id="tens_details"
                formControlName="tens_details" placeholder="Details" [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('tens_details')?.invalid) ||
                  (initialAssessementAdult.get('tens_details')?.invalid &&
                    initialAssessementAdult.get('tens_details').touched)
                " />
              <div class="invalid-feedback">Tens details is required</div>
            </div>
          </div>
        </div>

        <!-- Altrasound -->
        <div class="row">
          <div class="col-lg-2 col-sm-3 col-12">
            <div class="form-group mb-0">
              <label class="checkbox checkbox-outline px-0">
                <input type="checkbox" value="sterilizeAll" id="ultrasound_checkbox"
                  formControlName="ultrasound_checkbox" (change)="handleInput($event, 'ultrasound')" />
                Ultrasound
              </label>
            </div>
          </div>
          <div class="col-lg-5 col-sm col-12">
            <p-dropdown optionValue="duration" [options]="duration" optionLabel="duration" [filter]="true"
              filterBy="duration" formControlName="ultrasound_duration" id="ultrasound_duration" placeholder="Duration"
              [resetFilterOnHide]="true" [class.is-invalid]="
                (submitAssessmentForm.submitted &&
                  initialAssessementAdult.get('ultrasound_duration')
                    ?.invalid) ||
                (initialAssessementAdult.get('ultrasound_duration')?.invalid &&
                  initialAssessementAdult.get('ultrasound_duration').touched)
              ">
            </p-dropdown>
            <div class="invalid-feedback">Ultrasound duration is required</div>
          </div>
          <div class="col-lg-5 col-sm col-12 mt-sm-0 mt-2">
            <div class="form-group">
              <input type="text" class="w-100 form-control" value="intact" formControlName="ultrasound_details"
                id="ultrasound_details" placeholder="Details" [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('ultrasound_details')
                      ?.invalid) ||
                  (initialAssessementAdult.get('ultrasound_details')?.invalid &&
                    initialAssessementAdult.get('ultrasound_details').touched)
                " />
              <div class="invalid-feedback">Ultrasound details is required</div>
            </div>
          </div>
        </div>

        <!-- Hot Packs -->
        <div class="row">
          <div class="col-lg-2 col-sm-3 col-12">
            <div class="form-group mb-0">
              <label class="checkbox checkbox-outline px-0">
                <input type="checkbox" value="sterilizeAll" id="hot_packs_checkbox" formControlName="hot_packs_checkbox"
                  (change)="handleInput($event, 'packs')" />
                Hot Packs
              </label>
            </div>
          </div>
          <div class="col-lg-5 col-sm col-12">
            <p-dropdown optionValue="duration" [options]="duration" optionLabel="duration" [filter]="true"
              filterBy="duration" formControlName="hot_packs_duration" id="hot_packs_duration" placeholder="Duration"
              [resetFilterOnHide]="true" [class.is-invalid]="
                (submitAssessmentForm.submitted &&
                  initialAssessementAdult.get('hot_packs_duration')?.invalid) ||
                (initialAssessementAdult.get('hot_packs_duration')?.invalid &&
                  initialAssessementAdult.get('hot_packs_duration').touched)
              ">
            </p-dropdown>
            <div class="invalid-feedback">Hot packs duration is required</div>
          </div>
          <div class="col-lg-5 col-sm col-12 mt-sm-0 mt-2">
            <div class="form-group">
              <input type="text" class="w-100 form-control" value="intact" id="hot_packs_details"
                formControlName="hot_packs_details" placeholder="Details" [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('hot_packs_details')
                      ?.invalid) ||
                  (initialAssessementAdult.get('hot_packs_details')?.invalid &&
                    initialAssessementAdult.get('hot_packs_details').touched)
                " />
              <div class="invalid-feedback">Hot packs details is required</div>
            </div>
          </div>
        </div>

        <!-- Stimulation -->
        <div class="row">
          <div class="col-lg-2 col-sm-3 col-12">
            <div class="form-group mb-0">
              <label class="checkbox checkbox-outline px-0">
                <input type="checkbox" value="sterilizeAll" id="stimulation_checkbox"
                  formControlName="stimulation_checkbox" (change)="handleInput($event, 'stimulation')" />
                Stimulation
              </label>
            </div>
          </div>
          <div class="col-lg-5 col-sm col-12">
            <p-dropdown optionValue="duration" [options]="duration" optionLabel="duration" [filter]="true"
              filterBy="duration" formControlName="stimulation_duration" id="stimulation_duration"
              placeholder="Duration" [resetFilterOnHide]="true" [class.is-invalid]="
                (submitAssessmentForm.submitted &&
                  initialAssessementAdult.get('stimulation_duration')
                    ?.invalid) ||
                (initialAssessementAdult.get('stimulation_duration')?.invalid &&
                  initialAssessementAdult.get('stimulation_duration').touched)
              ">
            </p-dropdown>
            <div class="invalid-feedback">
              Stimulation duration is required
            </div>
          </div>
          <div class="col-lg-5 col-sm col-12 mt-sm-0 mt-2">
            <div class="form-group">
              <input type="text" class="w-100 form-control" value="intact" formControlName="stimulation_details"
                id="stimulation_details" placeholder="Details" [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('stimulation_details')
                      ?.invalid) ||
                  (initialAssessementAdult.get('stimulation_details')
                    ?.invalid &&
                    initialAssessementAdult.get('stimulation_details').touched)
                " />
              <div class="invalid-feedback">
                Stimulation details is required
              </div>
            </div>
          </div>
        </div>

        <!-- Vibrator -->
        <div class="row">
          <div class="col-lg-2 col-sm-3 col-12">
            <div class="form-group mb-0">
              <label class="checkbox checkbox-outline px-0">
                <input type="checkbox" value="sterilizeAll" id="vibrator_checkbox" formControlName="vibrator_checkbox"
                  (change)="handleInput($event, 'vibrator')" />
                Vibrator
              </label>
            </div>
          </div>
          <div class="col-lg-5 col-sm col-12">
            <p-dropdown optionValue="duration" [options]="duration" optionLabel="duration" [filter]="true"
              filterBy="duration" formControlName="vibrator_duration" id="vibrator_duration" placeholder="Duration"
              [resetFilterOnHide]="true" [class.is-invalid]="
                (submitAssessmentForm.submitted &&
                  initialAssessementAdult.get('vibrator_duration')?.invalid) ||
                (initialAssessementAdult.get('vibrator_duration')?.invalid &&
                  initialAssessementAdult.get('vibrator_duration').touched)
              ">
            </p-dropdown>
            <div class="invalid-feedback">
              Vibrator duration is required
            </div>
          </div>
          <div class="col-lg-5 col-sm col-12 mt-sm-0 mt-2">
            <div class="form-group">
              <input type="text" class="w-100 form-control" value="intact" formControlName="vibrator_details"
                id="vibrator_details" placeholder="Details" [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('vibrator_details')?.invalid) ||
                  (initialAssessementAdult.get('vibrator_details')?.invalid &&
                    initialAssessementAdult.get('vibrator_details').touched)
                " />
              <div class="invalid-feedback">Vibrator details is required</div>
            </div>
          </div>
        </div>

        <!-- Tapping -->
        <div class="row">
          <div class="col-lg-2 col-sm-3 col-12">
            <div class="form-group mb-0">
              <label class="checkbox checkbox-outline px-0">
                <input type="checkbox" value="sterilizeAll" id="tapping_checkbox" formControlName="tapping_checkbox"
                  (change)="handleInput($event, 'tapping')" />
                Tapping
              </label>
            </div>
          </div>
          <div class="col-lg-5 col-sm col-12">
            <p-dropdown optionValue="duration" [options]="duration" optionLabel="duration" [filter]="true"
              filterBy="duration" id="tapping_duration" formControlName="tapping_duration" placeholder="Duration"
              [resetFilterOnHide]="true" [class.is-invalid]="
                (submitAssessmentForm.submitted &&
                  initialAssessementAdult.get('tapping_duration')?.invalid) ||
                (initialAssessementAdult.get('tapping_duration')?.invalid &&
                  initialAssessementAdult.get('tapping_duration').touched)
              ">
            </p-dropdown>
            <div class="invalid-feedback">Tapping duration is required</div>
          </div>
          <div class="col-lg-5 col-sm col-12 mt-sm-0 mt-2">
            <div class="form-group">
              <input type="text" class="w-100 form-control" value="intact" id="tapping_details"
                formControlName="tapping_details" placeholder="Details" [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('tapping_details')?.invalid) ||
                  (initialAssessementAdult.get('tapping_details')?.invalid &&
                    initialAssessementAdult.get('tapping_details').touched)
                " />
              <div class="invalid-feedback">Tapping details is required</div>
            </div>
          </div>
        </div>

        <!-- Chest Therapy -->
        <div class="row">
          <div class="col-lg-2 col-sm-3 col-12">
            <div class="form-group mb-0">
              <label class="checkbox checkbox-outline px-0">
                <input type="checkbox" value="sterilizeAll" id="chest_therapy_checkbox"
                  formControlName="chest_therapy_checkbox" (change)="handleInput($event, 'chest')" />
                Chest Therapy
              </label>
            </div>
          </div>
          <div class="col-lg-5 col-sm col-12">
            <p-dropdown optionValue="duration" [options]="duration" optionLabel="duration" [filter]="true"
              filterBy="duration" id="chest_therapy_duration" formControlName="chest_therapy_duration"
              placeholder="Duration" [resetFilterOnHide]="true" [class.is-invalid]="
                (submitAssessmentForm.submitted &&
                  initialAssessementAdult.get('chest_therapy_duration')
                    ?.invalid) ||
                (initialAssessementAdult.get('chest_therapy_duration')
                  ?.invalid &&
                  initialAssessementAdult.get('chest_therapy_duration').touched)
              ">
            </p-dropdown>
            <div class="invalid-feedback">
              Chest therapy duration is required
            </div>
          </div>
          <div class="col-lg-5 col-sm col-12 mt-sm-0 mt-2">
            <div class="form-group">
              <input type="text" class="w-100 form-control" id="chest_therapy_details" value="intact"
                formControlName="chest_therapy_details" placeholder="Details" [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get('chest_therapy_details')
                      ?.invalid) ||
                  (initialAssessementAdult.get('chest_therapy_details')
                    ?.invalid &&
                    initialAssessementAdult.get('chest_therapy_details')
                      .touched)
                " />
              <div class="invalid-feedback">
                Chest therapy details is required
              </div>
            </div>
          </div>
        </div>

        <!-- Others -->
        <div class="row">
          <div class="col-lg-2 col-sm-3 col-12">
            <div class="form-group mb-0">
              <label class="checkbox checkbox-outline px-0">
                <input type="checkbox" value="sterilizeAll" id="session_technique_others_checkbox"
                  formControlName="session_technique_others_checkbox" (change)="handleInput($event, 'other')" />
                Others
              </label>
            </div>
          </div>
          <div class="col-lg-10 col-sm-9 col-12">
            <div class="form-group mb-3">
              <input type="text" class="w-100 form-control" id="session_technique_others_details" value="intact"
                formControlName="session_technique_others_details" placeholder="Details" [class.is-invalid]="
                  (submitAssessmentForm.submitted &&
                    initialAssessementAdult.get(
                      'session_technique_others_details'
                    )?.invalid) ||
                  (initialAssessementAdult.get(
                    'session_technique_others_details'
                  )?.invalid &&
                    initialAssessementAdult.get(
                      'session_technique_others_details'
                    ).touched)
                " />
              <div class="invalid-feedback">
                Session technique others details is required
              </div>
            </div>
          </div>
        </div>

        <!-- Re assessment date -->
        <div class="row">
          <div class="col-sm-6 col-12">
            <div class="form-group">
              <div class="form-group">
                <label class="subHeading px-0">Re-assessment Date:</label>
                <div class="card flex justify-content-center">
                  <p-calendar readonly [showIcon]="true" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                    [readonlyInput]="true" id="re_date" formControlName="re_date" [minDate]="maxDate"></p-calendar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="add-listings-btn" style="text-align: right">
      <button type="submit">Submit</button>
    </div>
  </form>
</div>