<p-toast></p-toast>
<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <form [formGroup]="ptDailyNotes" (ngSubmit)="submitForm(fourMonthAbove)" #fourMonthAbove="ngForm">
        <!-- date -->
        <div class="add-listings-box">
            <h3>Basic Information</h3>
            <div class="row">
                <div class="col-lg-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Date<span class="required">*</span></label>
                        <div class="card flex justify-content-center">
                            <p-calendar id="date" formControlName="selectedDate" [maxDate]="currentDate" required
                                placeholder="DD/MM/YYYY" [showIcon]="true"  dateFormat="dd/mm/yy" [readonlyInput]="true" [class.is-invalid]="(fourMonthAbove.submitted &&
                                ptDailyNotes.get('selectedDate')?.invalid)
                                ||
                                ptDailyNotes.get('selectedDate')?.invalid
                                &&
                                ptDailyNotes.get('selectedDate').touched"></p-calendar>
                            <div class="invalid-feedback">Date of birth is required</div>
                        </div>
                    </div>
                </div>
            </div>


            <label class="subHeading mb-2">Pain tool </label>
            <div class="row">
                <!-- Pain tool -->
                <div class="col-lg-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Score<span class="required">*</span></label>
                        <div class="card flex justify-content-center">
                            <p-dropdown id="score" [options]="scoreList" formControlName="pain_tool_score"
                                optionLabel="name" optionValue="value" placeholder="Time" [class.is-invalid]="(fourMonthAbove.submitted &&
                                ptDailyNotes.get('pain_tool_score')?.invalid)
                                ||
                                ptDailyNotes.get('pain_tool_score')?.invalid
                                &&
                                ptDailyNotes.get('pain_tool_score').touched">
                            </p-dropdown>
                            <div class="invalid-feedback">Score is required</div>
                        </div>
                    </div>
                    <!-- </div> -->
                </div>
            
                <div class="form-check checkbox-list col-lg-2 col-sm-4 col-12">
                    <label class="subHeading mb-2">Pain Description</label>
                    <label class="radio radio-outline d-block mt-1">
                        <input type="radio" id="yes" value="Yes" formControlName="pain_discription"
                               (change)="handleInput($event, 'Discription')"/>
                        <span></span>
                        Yes
                    </label>
                    <label class="radio radio-outline d-block">
                        <input type="radio" id="na" value="No" formControlName="pain_discription"
                               (change)="handleInput($event, 'Discription')" />
                        No
                    </label>
                </div>
                
                <div class="col-lg-4 col-sm-8 col-12">
                    <div class="form-group ">
                        <label class="checkbox checkbox-outline px-0 mt-sm-0 mt-2">Specify</label>
                        <textarea formControlName="pain_tool_specify" placeholder="Specify Products"
                            class="form-control px-2" id="specify" rows="3"
                            [class.is-invalid]="(fourMonthAbove.submitted &&
                            ptDailyNotes.get('pain_tool_specify')?.invalid)
                            ||
                            ptDailyNotes.get('pain_tool_specify')?.invalid
                            &&
                            ptDailyNotes.get('pain_tool_specify').touched"></textarea>
                          <div class="invalid-feedback">Pain tool specify is required</div>
                    </div>
                </div>
                <!-- </div> -->
            </div>
            <!-- </div> -->
        </div>

        <!-- Vital Signs : Pre Session -->
        <div class="add-listings-box pb-2">
            <h3>Vital Signs: Pre-Session</h3>
            <div class="form-group">
                <div class="row mt-3">
                    <!-- Blood Pressure -->
                    <div class="col-sm-2 col-3">
                        <label class="px-0">Blood Pressure:</label>
                    </div>
                    <div class="col-sm-10 col-9">
                        <input type="text" id="blood-pressure-pre" class="form-control px-2"
                            placeholder="Blood pressure" formControlName="blood_pressure" (keypress)="keyPress($event)" />
                    </div>
                </div>

                <!-- Temperature -->
                <div class="row mt-3">
                    <div class="col-sm-2 col-3">
                        <label class="px-0">Temperature:</label>
                    </div>
                    <div class="col-sm-10 col-9">
                        <input type="text" id="temperature-pre" class="form-control px-2" placeholder="Temperature"
                            formControlName="temperature in Celsius" (keypress)="keyPress($event)" />
                    </div>
                </div>

                <!-- Heart rate -->
                <div class="row mt-3">
                    <div class="col-sm-2 col-3">
                        <label class="px-0">Heart Rate:</label>
                    </div>
                    <div class="col-sm-10 col-9">
                        <input type="text" id="heart-rate-pre" class="form-control px-2" placeholder="Heart rate"
                            formControlName="heart_rate"  (keypress)="keyPress($event)"/>
                    </div>
                </div>

                <!-- SPO2 -->
                <div class="row mt-3">
                    <div class="col-sm-2 col-3">
                        <label class="px-0">SPO2:</label>
                    </div>
                    <div class="col-sm-10 col-9">
                        <input type="text" id="sp02-pre" class="form-control px-2" placeholder="SPO2"
                            formControlName="spo2"(keypress)="keyPress($event)" />
                    </div>
                </div>

                <!-- RR-->
                <div class="row mt-3">
                    <div class="col-sm-2 col-3">
                        <label class="px-0">RR:</label>
                    </div>
                    <div class="col-sm-10 col-9">
                        <input type="text" id="rr-pre" class="form-control px-2" placeholder="RR"
                            formControlName="rr" (keypress)="keyPress($event)"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- </div> -->

        <!-- Vital Signs : Post Session -->

        <div class="add-listings-box pb-2">
            <h3>Vital Signs: Post-Session</h3>
            <div class="form-group">
                <div class="row mt-3">
                    <!-- Blood Pressure -->
                    <div class="col-sm-2 col-3">
                        <label class="px-0">Blood Pressure:</label>
                    </div>
                    <div class="col-sm-10 col-9">
                        <input type="text" id="blood-pressure-post" class="form-control px-2"
                            placeholder="Blood pressure" formControlName="post_blood_pressure"  (keypress)="keyPress($event)" />
                    </div>
                </div>

                <!-- Temperature -->
                <div class="row mt-3">
                    <div class="col-sm-2 col-3">
                        <label class="px-0">Temperature:</label>
                    </div>
                    <div class="col-sm-10 col-9">
                        <input type="text" id="temperature-post" class="form-control px-2" placeholder="Temperature in Celsius"
                            formControlName="post_temperature" (keypress)="keyPress($event)"  />
                    </div>
                </div>

                <!-- Heart rate -->
                <div class="row mt-3">
                    <div class="col-sm-2 col-3">
                        <label class="px-0">Heart Rate:</label>
                    </div>
                    <div class="col-sm-10 col-9">
                        <input type="text" id="heart-rate-post" class="form-control px-2" placeholder="Heart rate"
                            formControlName="post_heart_rate" (keypress)="keyPress($event)"  />
                    </div>
                </div>

                <!-- SPO2 -->
                <div class="row mt-3">
                    <div class="col-sm-2 col-3">
                        <label class="px-0">SPO2:</label>
                    </div>
                    <div class="col-sm-10 col-9">
                        <input type="text" id="sp02-post" class="form-control px-2" placeholder="SPO2"
                            formControlName="post_spo2"  (keypress)="keyPress($event)" />
                    </div>
                </div>

                <!-- RR-->
                <div class="row mt-3">
                    <div class="col-sm-2 col-3">
                        <label class="px-0">RR:</label>
                    </div>
                    <div class="col-sm-10 col-9">
                        <input type="text" id="rr-post" class="form-control px-2" placeholder="RR"
                            formControlName="post_rr"  (keypress)="keyPress($event)" />
                    </div>
                </div>
            </div>
        </div>

        <!-- ccomplished Modalities of the Session -->
        <div class="add-listings-box pb-2">
            <h3>Accomplished Modalities Of The Session</h3>
            <div class="form-group pb-2 py-3">
                <div class="row">
                    <div class="col-12">
                        <textarea formControlName="modalities_session" placeholder="Session Remark" class="form-control px-2"
                            id="accomplished-modalities" rows="3"></textarea>
                    </div>
                </div>
            </div>


            <!-- Exercise (ROM) -->
            <div class="row">
                <div class="col-lg-2 col-sm-3 col-12">
                    <div class="form-group">
                        <label class="checkbox checkbox-outline px-0">
                            <input type="checkbox" id="exercise-checkbox" value="sterilizeAll"
                                formControlName="exercise_checkbox"   (change)="handleInput($event, 'exercise')"  />
                            Exercise(ROM)
                        </label>
                    </div>
                </div>
                <div class="col-lg-5 col-sm col-12">
                    <div class="form-group">
                        <p-dropdown id="exercise-dropdown" optionValue="duration" [options]="duration" optionLabel="duration"
                            [filter]="true" filterBy="duration" formControlName="exercise_duration" placeholder="Duration"
                            [resetFilterOnHide]="true" [class.is-invalid]="(fourMonthAbove.submitted &&
                            ptDailyNotes.get('exercise_duration')?.invalid)
                            ||
                            ptDailyNotes.get('exercise_duration')?.invalid
                            &&
                            ptDailyNotes.get('exercise_duration').touched">
                        </p-dropdown>
                        <div class="invalid-feedback">Exercise duration is required</div>
                    </div>
                </div>
                <div class="col-lg-5 col-sm col-12">
                    <div class="form-group">
                        <input type="text" id="exercise-details" class="w-100 form-control" value="intact"
                            formControlName="exercise_details" placeholder="Details" [class.is-invalid]="(fourMonthAbove.submitted &&
                            ptDailyNotes.get('exercise_details')?.invalid)
                            ||
                            ptDailyNotes.get('exercise_details')?.invalid
                            &&
                            ptDailyNotes.get('exercise_details').touched" />
                         <div class="invalid-feedback">Exercise details is required</div>
                    </div>
                </div>

            </div>

            <!-- TENS -->
            <div class="row">
                <div class="col-lg-2 col-sm-3 col-12">
                    <div class="form-group">
                        <label class="checkbox checkbox-outline px-0">
                            <input type="checkbox" id="tens-checkbox" value="sterilizeAll"
                                formControlName="tens_checkbox" (change)="handleInput($event, 'ten')"/>
                            TENS
                        </label>
                    </div>
                </div>
                <div class="col-lg-5 col-sm col-12">
                    <div class="form-group">
                        <p-dropdown id="tens-dropdown" optionValue="duration" [options]="duration" optionLabel="duration"
                            [filter]="true" filterBy="duration" formControlName="tens_duration" placeholder="Duration"
                            [resetFilterOnHide]="true" [class.is-invalid]="(fourMonthAbove.submitted &&
                            ptDailyNotes.get('tens_duration')?.invalid)
                            ||
                            ptDailyNotes.get('tens_duration')?.invalid
                            &&
                            ptDailyNotes.get('tens_duration').touched">
                        </p-dropdown>
                        <div class="invalid-feedback">Tens duration is required</div>
                     
                    </div>
                </div>
                <div class="col-lg-5 col-sm col-12">
                    <div class="form-group">
                        <input id="tens-details" type="text" class="w-100 form-control" value="intact"
                            formControlName="tens_details" placeholder="Details"  [class.is-invalid]="(fourMonthAbove.submitted &&
                            ptDailyNotes.get('tens_details')?.invalid)
                            ||
                            ptDailyNotes.get('tens_details')?.invalid
                            &&
                            ptDailyNotes.get('tens_details').touched" />
                         <div class="invalid-feedback">Tens details is required</div>
                    </div>
                </div>

            </div>

            <!-- Altrasound -->
            <div class="row">
                <div class="col-lg-2 col-sm-3 col-12">
                    <div class="form-group">
                        <label class="checkbox checkbox-outline px-0">
                            <input type="checkbox" id="ultrasound-checkbox" value="sterilizeAll"
                                formControlName="ultrasound_checkbox" (change)="handleInput($event, 'ultrasound')"/>
                            Ultrasound
                        </label>
                    </div>
                </div>
                <div class="col-lg-5 col-sm col-12">
                    <div class="form-group">
                        <p-dropdown id="ultrasound-dropdown" optionValue="duration" [options]="duration"
                            optionLabel="duration" [filter]="true" filterBy="duration" formControlName="ultrasound_duration"
                            placeholder="Duration" [resetFilterOnHide]="true" [class.is-invalid]="(fourMonthAbove.submitted &&
                            ptDailyNotes.get('ultrasound_duration')?.invalid)
                            ||
                            ptDailyNotes.get('ultrasound_duration')?.invalid
                            &&
                            ptDailyNotes.get('ultrasound_duration').touched">
                        </p-dropdown>
                        <div class="invalid-feedback">Ultrasound duration is required</div>
                    </div>
                </div>
                <div class="col-lg-5 col-sm col-12">
                    <div class="form-group">
                        <input type="text" id="ultrasound-details" class="w-100 form-control" value="intact"
                            formControlName="ultrasound_details" placeholder="Details" [class.is-invalid]="(fourMonthAbove.submitted &&
                            ptDailyNotes.get('ultrasound_details')?.invalid)
                            ||
                            ptDailyNotes.get('ultrasound_details')?.invalid
                            &&
                            ptDailyNotes.get('ultrasound_details').touched" />
                         <div class="invalid-feedback">Ultrasound  details is required</div>
                    </div>
                </div>

            </div>

            <!-- Hot Packs -->
            <div class="row">
                <div class="col-lg-2 col-sm-3 col-12">
                    <div class="form-group">
                        <label class="checkbox checkbox-outline px-0">
                            <input type="checkbox" id="hot-packs-checkbox" value="sterilizeAll"
                                formControlName="hot_packs_checkbox" (change)="handleInput($event, 'packs')" />
                            Hot Packs
                        </label>
                    </div>
                </div>
                <div class="col-lg-5 col-sm col-12">
                    <div class="form-group">
                        <p-dropdown id="hot-packs-dropdown" optionValue="duration" [options]="duration" optionLabel="duration"
                            [filter]="true" filterBy="duration" formControlName="hot_packs_duration" placeholder="Duration"
                            [resetFilterOnHide]="true"  [class.is-invalid]="(fourMonthAbove.submitted &&
                            ptDailyNotes.get('hot_packs_duration')?.invalid)
                            ||
                            ptDailyNotes.get('hot_packs_duration')?.invalid
                            &&
                            ptDailyNotes.get('hot_packs_duration').touched">
                        </p-dropdown>
                        <div class="invalid-feedback">Hot packs duration is required</div>
                    </div>
                </div>
                <div class="col-lg-5 col-sm col-12">
                    <div class="form-group">
                        <input type="text" id="hot-packs-details" class="w-100 form-control" value="intact"
                            formControlName="hot_packs_details" placeholder="Details" [class.is-invalid]="(fourMonthAbove.submitted &&
                            ptDailyNotes.get('hot_packs_details')?.invalid)
                            ||
                            ptDailyNotes.get('hot_packs_details')?.invalid
                            &&
                            ptDailyNotes.get('hot_packs_details').touched" />
                         <div class="invalid-feedback">Hot packs details is required</div>
                    </div>
                </div>

            </div>

            <!-- Stimulation -->
            <div class="row">
                <div class="col-lg-2 col-sm-3 col-12">
                    <div class="form-group">
                        <label class="checkbox checkbox-outline px-0">
                            <input type="checkbox" id="stimulation-checkbox" value="sterilizeAll"
                                formControlName="stimulation_checkbox" (change)="handleInput($event, 'stimulation')" />
                            Stimulation
                        </label>
                    </div>
                </div>
                <div class="col-lg-5 col-sm col-12">
                    <div class="form-group">
                        <p-dropdown id="stimulation-dropdown" optionValue="duration" [options]="duration"
                            optionLabel="duration" [filter]="true" filterBy="duration"
                            formControlName="stimulation_duration" placeholder="Duration" [resetFilterOnHide]="true"  [class.is-invalid]="(fourMonthAbove.submitted &&
                            ptDailyNotes.get('stimulation_duration')?.invalid)
                            ||
                            ptDailyNotes.get('stimulation_duration')?.invalid
                            &&
                            ptDailyNotes.get('stimulation_duration').touched">
                        </p-dropdown>
                        <div class="invalid-feedback">Stimulation duration is required</div>
                    </div>
                </div>
                <div class="col-lg-5 col-sm col-12">
                    <div class="form-group">
                        <input type="text" id="stimulation-details" class="w-100 form-control" value="intact"
                            formControlName="stimulation_details" placeholder="Details"[class.is-invalid]="(fourMonthAbove.submitted &&
                            ptDailyNotes.get('stimulation_details')?.invalid)
                            ||
                            ptDailyNotes.get('stimulation_details')?.invalid
                            &&
                            ptDailyNotes.get('stimulation_details').touched" />
                         <div class="invalid-feedback">Stimulation details is required</div>
                    </div>
                </div>

            </div>

            <!-- Vibrator -->
            <div class="row">
                <div class="col-lg-2 col-sm-3 col-12">
                    <div class="form-group">
                        <label class="checkbox checkbox-outline px-0">
                            <input type="checkbox" id="vibrator-checkbox" value="sterilizeAll"
                                formControlName="vibrator_checkbox" (change)="handleInput($event, 'vibrator')"/>
                            Vibrator
                        </label>
                    </div>
                </div>
                <div class="col-lg-5 col-sm col-12">
                    <div class="form-group">
                        <p-dropdown id="vibrator-dropdown" optionValue="duration" [options]="duration" optionLabel="duration"
                            [filter]="true" filterBy="duration" formControlName="vibrator_duration" placeholder="Duration"
                            [resetFilterOnHide]="true"  [class.is-invalid]="(fourMonthAbove.submitted &&
                            ptDailyNotes.get('vibrator_duration')?.invalid)
                            ||
                            ptDailyNotes.get('vibrator_duration')?.invalid
                            &&
                            ptDailyNotes.get('vibrator_duration').touched" >
                        </p-dropdown>
                        <div class="invalid-feedback">Vibrator duration is required</div>
                    </div>
                </div>
                <div class="col-lg-5 col-sm col-12">
                    <div class="form-group">
                        <input type="text" id="vibrator-details" class="w-100 form-control" value="intact"
                            formControlName="vibrator_details" placeholder="Details" [class.is-invalid]="(fourMonthAbove.submitted &&
                            ptDailyNotes.get('vibrator_details')?.invalid)
                            ||
                            ptDailyNotes.get('vibrator_details')?.invalid
                            &&
                            ptDailyNotes.get('vibrator_details').touched" />
                         <div class="invalid-feedback">Vibrator details is required</div>
                    </div>
                </div>

            </div>

            <!-- Tapping -->
            <div class="row">
                <div class="col-lg-2 col-sm-3 col-12">
                    <div class="form-group">
                        <label class="checkbox checkbox-outline px-0">
                            <input type="checkbox" id="tapping-checkbox" value="sterilizeAll"
                                formControlName="tapping_checkbox" (change)="handleInput($event, 'tapping')"/>
                            Tapping
                        </label>
                    </div>
                </div>
                <div class="col-lg-5 col-sm col-12">
                    <div class="form-group">
                        <p-dropdown id="tapping-dropdown" optionValue="duration" [options]="duration" optionLabel="duration"
                            [filter]="true" filterBy="duration" formControlName="tapping_duration" placeholder="Duration"
                            [resetFilterOnHide]="true"  [class.is-invalid]="(fourMonthAbove.submitted &&
                            ptDailyNotes.get('tapping_duration')?.invalid)
                            ||
                            ptDailyNotes.get('tapping_duration')?.invalid
                            &&
                            ptDailyNotes.get('tapping_duration').touched" >
                        </p-dropdown>
                        <div class="invalid-feedback">Tapping duration is required</div>
                    </div>
                </div>
                <div class="col-lg-5 col-sm col-12">
                    <div class="form-group">
                        <input type="text" id="tapping-details" class="w-100 form-control" value="intact"
                            formControlName="tapping_details" placeholder="Details" [class.is-invalid]="(fourMonthAbove.submitted &&
                            ptDailyNotes.get('tapping_details')?.invalid)
                            ||
                            ptDailyNotes.get('tapping_details')?.invalid
                            &&
                            ptDailyNotes.get('tapping_details').touched" />
                         <div class="invalid-feedback">Tapping  details is required</div>
                    </div>
                </div>

            </div>

            <!-- Chest Therapy -->
            <div class="row">
                <div class="col-lg-2 col-sm-3 col-12">
                    <div class="form-group">
                        <label class="checkbox checkbox-outline px-0">
                            <input type="checkbox" id="chest-therapy-checkbox" value="sterilizeAll"
                                formControlName="chest_therapy_checkbox"(change)="handleInput($event, 'chest')" />
                            Chest Therapy
                        </label>
                    </div>
                </div>
                <div class="col-lg-5 col-sm col-12">
                    <div class="form-group">
                        <p-dropdown id="chest-therapy-dropdown" optionValue="duration" [options]="duration"
                            optionLabel="duration" [filter]="true" filterBy="duration"
                            formControlName="chest_therapy_duration" placeholder="Duration" [resetFilterOnHide]="true" [class.is-invalid]="(fourMonthAbove.submitted &&
                            ptDailyNotes.get('chest_therapy_duration')?.invalid)
                            ||
                            ptDailyNotes.get('chest_therapy_duration')?.invalid
                            &&
                            ptDailyNotes.get('chest_therapy_duration').touched">
                        </p-dropdown>
                        <div class="invalid-feedback">Chest therapy duration is required</div>
                    </div>
                </div>
                <div class="col-lg-5 col-sm col-12">
                    <div class="form-group">
                        <input type="text" id="chest-therapy-details" class="w-100 form-control" value="intact"
                            formControlName="chest_therapy_details" placeholder="Details" [class.is-invalid]="(fourMonthAbove.submitted &&
                            ptDailyNotes.get('chest_therapy_details')?.invalid)
                            ||
                            ptDailyNotes.get('chest_therapy_details')?.invalid
                            &&
                            ptDailyNotes.get('chest_therapy_details').touched" />
                         <div class="invalid-feedback">Chest therapy details is required</div>
                    </div>
                </div>

            </div>

            <!-- Others -->
            <div class="row">
                <div class="col-lg-2 col-sm-3 col-12">
                    <div class="form-group">
                        <label class="checkbox checkbox-outline px-0">
                            <input type="checkbox" id="others-checkbox" value="sterilizeAll"
                                formControlName="session_technique_others_checkbox" (change)="handleInput($event, 'other')" />
                            Others
                        </label>
                    </div>
                </div>
                <div class="col-lg-10 col-sm-9 col-12">
                    <div class="form-group">
                        <input type="text" id="others-details" class="w-100 form-control" value="intact"
                            formControlName="session_technique_others_details" placeholder="Details" [class.is-invalid]="(fourMonthAbove.submitted &&
                            ptDailyNotes.get('session_technique_others_details')?.invalid)
                            ||
                            ptDailyNotes.get('session_technique_others_details')?.invalid
                            &&
                            ptDailyNotes.get('session_technique_others_details').touched" />
                         <div class="invalid-feedback">Session technique others details is required</div>
                    </div>
                </div>

            </div>

            <!-- comments -->
            <div class="row">
                <div class="form-group ">
                    <label class="subHeading px-0">Comments:</label>
                    <textarea formControlName="comments" placeholder="Comments" class="form-control px-2"
                        id="session-comments" rows="3"></textarea>
                </div>
            </div>

            <!-- Therapied Parts Of Body -->

            <div class="row">
                <div class="form-group ">
                    <label class="subHeading px-0">Therapied Part of the Body:</label>
                    <textarea formControlName="therapied_part" placeholder="Comments" class="form-control px-2"
                        id="therapied-part-comments" rows="3"></textarea>

                </div>
            </div>
            <!-- Session goals Achieved -->
            <div class="row">
                    <div class="form-group mb-0 col-lg-3 col-12">
                        <label class="subHeading px-0">Session goals Achieved</label>
                        <div class="form-check px-0 checkbox-list">
                            <label class="radio radio-outline px-0">
                                <input type="radio" id="session-goal-yes" value="1" formControlName="session_goal"
                                       (change)="handleInput($event, 'goalAchieve')" />
                                <span></span>
                                Yes
                            </label>
                            <label class="radio radio-outline px-0">
                                <input type="radio" id="session-goal-no" value="0" formControlName="session_goal"
                                       (change)="handleInput($event, 'goalAchieve')" />
                                <span></span>
                                No
                            </label>
                        </div>
                    </div>
              
                
                <div class="col-lg-9 col-12">
                    <div class="form-group">
                        <label class="px-0">Reason </label>
                        <textarea formControlName="session_reason" placeholder="Comments" class="form-control px-2"
                            id="session-reason-comments" rows="3" class="form-control px-2" id="specify" rows="3"
                            [class.is-invalid]="(fourMonthAbove.submitted &&
                            ptDailyNotes.get('session_reason')?.invalid)
                            ||
                            ptDailyNotes.get('session_reason')?.invalid
                            &&
                            ptDailyNotes.get('session_reason').touched"></textarea>
                    <div class="invalid-feedback">Session reason  is required</div> 
                    </div>
                </div>
            </div>
        </div>
        <div class="add-listings-btn" style="text-align: right;">
            <button type="submit">Submit</button>
        </div>
    </form>
</div>