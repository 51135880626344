import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormControlDirective,
  FormGroup,
  Validators,
} from '@angular/forms';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { CommonApiService } from 'src/app/services/common-api.service';
import { UtilityTsService } from 'src/app/services/utility.ts.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MasterService } from 'src/app/services/master.service';
@Component({
  selector: 'app-four-months-to-one-yr',
  templateUrl: './four-months-to-one-yr.component.html',
  styleUrls: ['./four-months-to-one-yr.component.scss'],
  providers: [MessageService],
})
export class FourMonthsToOneYrComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Nurse CARE PLAN 4 MONTHS - 1 YEARS',
      subTitle: 'Dashboard',
    },
  ];
  getToken: any;
  getRole: any;
  getname: any;
  public flag: boolean = true;
  planId: any;
  showNeedsTextarea: boolean = false;
  formula: boolean = false;
  selectedDate: Date = null; // Initialize with today's date
  wipes: boolean = true;
  years: any = '';
  Breast: boolean = true;
  Formula: boolean = true;
  All: boolean = true;
  One: boolean = true;
  Snack: boolean = true;
  Yes: boolean = true;
  sleep_training: boolean = true;
  No: boolean = true;
  Breakfast: boolean = true;
  Lunch: boolean = true;
  Dinner: boolean = true;
  Shower: boolean = true;
  Sponge: boolean = true;
  showProducts: boolean = true;
  With_Nanny: boolean = true;
  With_Mother: boolean = true;
  Tub: boolean = true;
  month: any = '';
  days: any = '';
  selectedNumber: any;
  ageList: any = [];
  babyValues: any = [];
  frequency: any = [];
  babyDetails: any = [];
  babyGender: any;
  babyDOB: any;
  isFormula: any = false;
  isBathing: any = false;
  isBathingtub: any = false;
  isBathingshower: any = false;
  ismedicine: boolean = false;
  isdiaper: boolean = false;
  permissionData: any = [];
  permissions: any = [];
  isBreast: boolean = false;
  subscription: Subscription;
  isSettingValueProgrammatically = true;
  frequencyList: any = [
    { name: 1, value: 1 },
    { name: 2, value: 2 },
    { name: 3, value: 3 },
    { name: 4, value: 4 },
    { name: 5, value: 5 },
    { name: 6, value: 6 },
    { name: 7, value: 7 },
    { name: 8, value: 8 },
    { name: 9, value: 9 },
    { name: 10, value: 10 },
  ];

  fourMonthToTowYear = this.FB.group({
    baby_details_id: new FormControl(null),
    baby_plan_id: new FormControl(null),
    feeding_prepared_mother: new FormControl(null),
    feeding_prepared_other: new FormControl(null),
    feeding_prepared_nanny: new FormControl(null),
    feeding_type: new FormControl(null),
    feeding_breast: new FormControl(null),
    feeding_frequency_breast: new FormControl(null),
    breast_remark: new FormControl(null),
    feeding_breast_time: new FormControl(null),
    // feeding_time: new FormControl(null),
    feeding_frequency: new FormControl(null),
    feeding_time: new FormControl(null),
    formula_calculation: this.FB.array([]),
    bottle_sterilization: this.FB.array([]),
    bathing_time: this.FB.array([]),
    tub_bathing: this.FB.array([]),
    shower_bathing: this.FB.array([]),
    medicine_time: this.FB.array([]),
    diaper_changes: this.FB.array([]),
    formula_feeding_time: new FormControl(null),
    feeding_amount_per: new FormControl(null),
    formula_remark: new FormControl(null),
    type_of_formula: new FormControl(null),
    bottle_sterilization_all: new FormControl(null),
    bottle_sterilization_onetime: new FormControl(null),
    solid_other_snack: new FormControl(null),
    solid_other_snack_time: new FormControl(null),
    soild_other_snack_food_components: new FormControl(null),
    regular_meal_type: new FormControl(null),
    regular_meal_time: new FormControl(null),
    regular_meal_food_components: new FormControl(null),
    regular_meal_lunch: new FormControl(null),
    regular_meal_lunch_time: new FormControl(null),
    regular_meal_lunch_food_components: new FormControl(null),
    regular_meal_dinner: new FormControl(null),
    regular_meal_dinner_time: new FormControl(null),
    regular_meal_dinner_food_components: new FormControl(null),
    bottle_sterilization_all_time: new FormControl(null),
    bottle_sterilization_onetime_time: new FormControl(null),
    food_medicine_allergy: new FormControl(null),
    // food_medicine_allergy_no: new FormControl(null),
    food_medicine_allergy_specify_prod: new FormControl({
      value: null,
      disabled: true,
    }),
    // bathing_type: new FormControl(null),
    // bathing_frequency: new FormControl(null),
    // bathing_time: new FormControl(null),
    bathing_sponge: new FormControl(null),
    bathing_frequency_sponge: new FormControl(null),
    bathing_sponge_time: new FormControl(null),
    bathing_tub: new FormControl(null),
    bathing_tub_frequency: new FormControl(null),
    bathing_tub_time: new FormControl(null),
    bathing_shower: new FormControl(null),
    bathing_shower_frequency: new FormControl(null),
    bathing_shower_time: new FormControl(null),
    skin_care_lotion: new FormControl(null),
    skin_care_baby_oil: new FormControl(null),
    skin_care_others: new FormControl(null),
    skin_care_specify_produ: new FormControl(null),
    skin_care_Wipes: new FormControl(null),
    skin_care_special_instructions: new FormControl(null),
    changing_colthes_frequency: new FormControl(null),
    changing_colthes_spiecal_instructions: new FormControl(null),
    sleeping_mother: new FormControl(null),
    sleeping_mother_time: new FormControl(null),
    sleeping_nanny: new FormControl(null),
    sleeping_nanny_time: new FormControl(null),
    temperature_monitoring_frequency: new FormControl(null),
    vitamins_medications_json: this.FB.array([]),
    vaccinations_json: this.FB.array([]),
    sleep_training_json: this.FB.array([]),
    remarks: new FormControl(null),
    sleeping_time: this.FB.array([]),
    nanny_time: this.FB.array([]),
    diaper_change_frequency: new FormControl(null),
    diaper_change_times: this.FB.array([]),
    sleep_train: new FormControl(null),
    walk_time: new FormControl(null),
    nap_time: new FormControl(null),
    sleep_time: new FormControl(null),
    stroller: new FormControl(null),
    interrupt: new FormControl(null),
    need_dummy: new FormControl(null),
    pacifier: new FormControl(null),
    song_sleep: new FormControl(null),
    reading_sleep: new FormControl(null),
    answer: new FormControl(null),
    attach: new FormControl(null),
    Asleep: new FormControl(null),
    LongPause: new FormControl(null),
    rhythmical: new FormControl(null),
    swallowing: new FormControl(null),
    Normal_skin: new FormControl(null),
    weight: new FormControl(null),
    Other_concerns: new FormControl(null),
    solid_food: this.FB.array([]),
    lunch_food: this.FB.array([]),
    dinner_food: this.FB.array([]),
    skin_care: this.FB.array([]),
    breast_feeding: this.FB.array([]),
    changing_clothes: this.FB.array([]),
    diaper_label_end_time:new FormControl(null),
      diaper_label:new FormControl (null),
      diaper_type:new FormControl (null),
      diaper_calculation:this.FB.array([]),
      bottle_sterilization_one_at_a_time:new FormControl(null),
  });
  sleeptraaing: any[] = [];
  isedit: boolean;
  // genderArray: any = [
  //   { name: 'Male', value: 'male' },
  //   { name: 'Female', value: 'female' },
  // ];
  constructor(
    private router: Router,
    private commonService: CommonApiService,
    private FB: FormBuilder,
    private messageService: MessageService,
    private utility_service: UtilityTsService,
    private common_service: CommonApiService,
    private spinner: NgxSpinnerService,
    private masterService: MasterService
  ) {
    this.getAllUserPermission();
  }

  ngOnInit() {
    this.spinner.show()

    // this.getBabyDetails();
    this.common_service.getBabyDetails().subscribe((res: any) => {
      this.babyValues = res?.data;
      this.spinner.hide()

      // For frequency dropdown list.
      for (let i = 1; i <= 30; i++) {
        this.frequency.push({ frequency: i });
      }
      this.getform()
      // if (localStorage.getItem('create')) {
      //   this.isedit = false;
      //   const getEdit = localStorage.getItem('create');
      //   this.getRemainingDetails({ 'value': getEdit })
      //   this.fourMonthToTowYear.controls.baby_details_id.setValue(
      //     JSON.parse(getEdit)
      //   );
      // }
      this.getname = localStorage.getItem('name');
      this.getToken = localStorage.getItem('token');
      this.getRole = localStorage.getItem('role')
    });

    // this.getId();
    // if (localStorage.getItem('editedOrderId')) {
    //   this.getEditFormData();
    //   this.isedit = true;
    // }
    // else if(localStorage.getItem('create')){
    //   this.isSettingValueProgrammatically = false;
    //   const getEdit = localStorage.getItem('create');
    //   this.getRemainingDetails({'value':getEdit})

    // }
    // else {
    //   this.isSettingValueProgrammatically = false;
    //   this.isedit = false;
    //   this.addVitaminObject('add', 0);
    //   this.addVaccinationObject('add', 0);
    //   this.addRegularObject('add', 0);
    //   this.addLunchObject('add', 0);
    //   this.addDinnerObject('add', 0);
    //   this.addSkinObject('add', 0);
    // }
  }
  getform() {
    if (localStorage.getItem('create')) {
      const getEdit = localStorage.getItem('create');

      this.fourMonthToTowYear.controls.baby_details_id.setValue(
        JSON.parse(getEdit)
      );
      this.isSettingValueProgrammatically = false;
      this.getRemainingDetails({ 'value': getEdit })
      this.isedit = false;
      this.addVitaminObject('add', 0);
      this.addVaccinationObject('add', 0);
      this.addRegularObject('add', 0);
      this.addLunchObject('add', 0);
      this.addDinnerObject('add', 0);
      this.addSkinObject('add', 0);
      this.adddiaperObject('add', 0)
      this.addClothesObject('add', 0)
      this.addsleeptimeObject('add', 0)
      this.addnannyObject('add', 0)
      this.fourMonthToTowYear.controls.breast_remark.disable();
      // this.fourMonthToTowYear.controls.feeding_amount_per.disable();
      // this.fourMonthToTowYear.controls.type_of_formula.disable();
      // this.fourMonthToTowYear.controls.formula_remark.disable();
    }

    else if (localStorage.getItem('editedOrderId')) {
      this.getEditFormData();
      this.isedit = true;
    }
    // else if(localStorage.getItem('create')){
    //   const getEdit = localStorage.getItem('create');
    //   this.isedit = false;
    //   this.addVitaminObject('add', 0);
    //   this.addVaccinationObject('add', 0);
    //   this.addSkinObject('add',0)
    //   this.getBabyDetails({'value':getEdit});
    //   this.isSettingValueProgrammatically = false;
    // }
    else {
      this.isSettingValueProgrammatically = false;
      this.isedit = false;
      this.addVitaminObject('add', 0);
      this.addVaccinationObject('add', 0);
      this.addRegularObject('add', 0);
      this.addLunchObject('add', 0);
      this.addDinnerObject('add', 0);
      this.addSkinObject('add', 0);
      this.adddiaperObject('add', 0)
      this.addClothesObject('add', 0)
      this.addsleeptimeObject('add', 0)
      this.addnannyObject('add', 0)
    }
  }
  getDiaperChangeControlsfrequency(): FormArray {
    return this.fourMonthToTowYear.get('diaper_changes') as FormArray;
  }
  // clearDiaperControls() {
  //   (
  //     this.fourMonthToTowYear.get('diaper_changes') as FormArray
  //   ).clear();
  // }


  // getTimeLabel(index: number): string {
  //   let changetime = this.getDiaperChangeControlsfrequency();
  //   let time = new Date(changetime.at(index).get('diaper_label').value);
  //   return time.toLocaleTimeString('en-US', {
  //     hour: '2-digit',
  //     minute: '2-digit',
  //   });
  // }
  // DiaperChange(event: any) {
  //   if (!this.isSettingValueProgrammatically) {
  //     let start = new Date();
  //     let end = new Date();
  //     start.setHours(0, 0, 0, 0);
  //     end.setHours(23, 59, 59, 999);
  //     this.clearDiaperControls();
  //     let updated = start;

  //     while (updated < end) {
  //       if (updated < end) {

  //         let time = new Date();

  //         time.setHours(updated.getHours());
  //         time.setMinutes(updated.getMinutes());

  //         this.addDiaperChange(time);
  //       }
  //       updated.setHours(updated.getHours() + event);
  //     }
  //   }
  // }
  // addDiaperChange(time: any): void {
  //   const CalculationArray = this.getDiaperChangeControlsfrequency();

  //   const newCalculationGroup = this.FB.group({
  //     isDiaperChanged: new FormControl(null),
  //     diaper_label: new FormControl(time),
  //   });
  //   CalculationArray.push(newCalculationGroup);
  // }
  getDiaperChangeControls(): FormArray {
    return this.fourMonthToTowYear.get('diaper_change_times') as FormArray;
  }

  getbathingarray() {
    return this.fourMonthToTowYear.get('bathing_time') as FormArray;
  }
  clearbathingcalculation() {
    (
      this.fourMonthToTowYear.get('bathing_time') as FormArray
    ).clear();
  }
  clearmedicinecalculation(i) {
    ((this.fourMonthToTowYear.get('vitamins_medications_json') as FormArray).at(i).get('medicine_time') as FormArray).clear();
  }
  addmedicationcalculation(index: number): void {

    const viamincalc = (this.fourMonthToTowYear.get('vitamins_medications_json') as FormArray).at(index).get('medicine_time') as FormArray;
    const bathingcalculationgroup = this.FB.group({
      time: new FormControl(),
      vitamin_checkbox: new FormControl(null),
      vitamin_remark: new FormControl(null)
    });
    viamincalc.push(bathingcalculationgroup);
  }
  addbathingcalculation(): void {
    const bathingcalculation = this.getbathingarray();
    const bathingcalculationgroup = this.FB.group({
      bathing_sponge_time: new FormControl(),
      sponge_checkbox: new FormControl(null),
      spongeBathing_remark: new FormControl(null)
    });
    bathingcalculation.push(bathingcalculationgroup);
  }

  // DiaperChange(event: any) {
  //   if (!this.isSettingValueProgrammatically) {
  //     let start = new Date();
  //     let end = new Date();
  //     start.setHours(0, 0, 0, 0);
  //     end.setHours(23, 59, 59, 999);
  //     this.clearDiaperControls();
  //     let updated = start;

  //     while (updated < end) {
  //       if (updated < end) {
  //         // let time = updated.getHours()+':'+updated.getMinutes();

  //         let time = new Date();

  //         time.setHours(updated.getHours())
  //         time.setMinutes(updated.getMinutes())

  //         this.addDiaperChange(time);
  //       }
  //       updated.setHours(updated.getHours() + event);
  //     }
  //   }
  // }
  adddiaperObject(string: any, index: any) {
    let diaperArray = this.getDiaperChangeControls();
    if (string == 'add') {
      let newdiaperArray = new FormGroup({
        change: new FormControl(''),
        time: new FormControl(''),
        diaper_remark: new FormControl(''),
      });
      diaperArray.insert(index + 1, newdiaperArray);
    } else {
      diaperArray.removeAt(index);
    }
  }

  disableAllRegularFoodControls(checkboxValue: boolean, index: number): void {
    const regularFoodArray = this.getRegularFormArray();
    const control = regularFoodArray.at(index) as FormGroup;

    if (checkboxValue) {
      // Disable controls when checkbox is checked
      control.get('regular_meal_time').enable();
      control.get('regular_meal_food_components').enable();
      // Add validators if needed
      // control.get('regular_meal_time').setValidators([Validators.required]);
      // control.get('regular_meal_food_components').setValidators([Validators.required]);
    } else {
      // Disable controls when checkbox is unchecked
      control.get('regular_meal_time').disable();
      control.get('regular_meal_food_components').disable();
      // Clear validators and set values to null
      control.get('regular_meal_time').clearValidators();
      control.get('regular_meal_food_components').clearValidators();
      control.get('regular_meal_time').setValue(null);
      control.get('regular_meal_food_components').setValue(null);
    }

    // Update validity
    control.get('regular_meal_time').updateValueAndValidity();
    control.get('regular_meal_food_components').updateValueAndValidity();
  }
  disableAlllunchFoodControls(checkboxValue: boolean, index: number): void {
    const lunchFoodArray = this.getLunchFormArray();
    const control = lunchFoodArray.at(index) as FormGroup;

    if (checkboxValue) {
      // Disable controls when checkbox is checked
      control.get('regular_meal_lunch_time').enable();
      control.get('regular_meal_lunch_food_components').enable();
    } else {
      // Enable controls when checkbox is unchecked
      control.get('regular_meal_lunch_time').disable();
      control.get('regular_meal_lunch_food_components').disable();
      // Clear validators and set values to null
      control.get('regular_meal_lunch_time').clearValidators();
      control.get('regular_meal_lunch_food_components').clearValidators();
      control.get('regular_meal_lunch_time').setValue(null);
      control.get('regular_meal_lunch_food_components').setValue(null);
    }

    // Update validity
    control.get('regular_meal_lunch_time').updateValueAndValidity();
    control.get('regular_meal_lunch_food_components').updateValueAndValidity();
  }

  disableAlldinnerFoodControls(checkboxValue: boolean, index: number): void {
    const regularFoodArray = this.getDinnerFormArray();

    // for (let i = 0; i < regularFoodArray.length; i++) {
    const control = regularFoodArray.at(index) as FormGroup;

    if (checkboxValue) {
      // Disable controls and set values to null when checkbox is checked
      control.get('regular_meal_dinner_time').enable();
      control.get('regular_meal_dinner_food_components').enable();


    } else {
      // Enable controls when checkbox is unchecked
      control.get('regular_meal_dinner_time').disable();
      control.get('regular_meal_dinner_food_components').disable();
      control.get('regular_meal_dinner_time').clearValidators();
      control.get('regular_meal_dinner_food_components').clearValidators();
      control.get('regular_meal_dinner_time').setValue(null);
      control.get('regular_meal_dinner_food_components').setValue(null);
    }
    control.get('regular_meal_dinner_time').updateValueAndValidity();
    control.get('regular_meal_dinner_food_components').updateValueAndValidity();

  }
  getAllUserPermission() {
    let id = localStorage.getItem('user_id');
    this.masterService.getUserPremissionById(id).then((res: any) => {

      this.permissionData = res.data;

      for (let i = 0; i < this.permissionData.length; i++) {
        
        this.permissions.push(this.permissionData[i].permission_id);
      }
    })
  }

  checkPermission(permission: number) {
   
    if (this.permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
    // return this.permissions.includes(permission);
  }
  getId() {
    this.planId = this.utility_service.getData(this.breadcrumb[0].title);
  }
  getEditFormData() {
    this.spinner.show()

    const getEdit = localStorage.getItem('editedOrderId');
    this.common_service.getEditNursecare(getEdit).then((res: any) => {
      this.spinner.hide()

      const detailsInGrowthMonitoring = res.data[0];
      const nannytask = detailsInGrowthMonitoring.nanny_task_details_json;
      this.babyGender = detailsInGrowthMonitoring.gender;
      this.selectedDate = detailsInGrowthMonitoring.date_of_birth;

      this.days = detailsInGrowthMonitoring.days;
      this.month = detailsInGrowthMonitoring.months;
      this.years = detailsInGrowthMonitoring.years;
      const regular = res.data[0].nanny_task_details_json.solid_food;
      const lunch = res.data[0].nanny_task_details_json.lunch_food;
      const dinner = res.data[0].nanny_task_details_json.dinner_food;
      const VitaminsMedication = res.data[0].nanny_task_details_json.vitamins_medications_json;
      const vaccine = res.data[0].nanny_task_details_json.vaccinations_json;
      const skin = res.data[0].nanny_task_details_json.skin_care;
      this.fourMonthToTowYear.controls.baby_details_id.setValue(
        JSON.parse(detailsInGrowthMonitoring.baby_details_id)
      );
   
      this.fourMonthToTowYear.controls.feeding_prepared_mother.setValue(
        nannytask.feeding_prepared_mother
      );
      this.fourMonthToTowYear.controls.feeding_prepared_other.setValue(
        nannytask.feeding_prepared_other
      );
      this.fourMonthToTowYear.controls.feeding_prepared_nanny.setValue(
        nannytask.feeding_prepared_nanny
      );
      this.fourMonthToTowYear.controls.feeding_type.setValue(
        nannytask.feeding_type
      );
      this.fourMonthToTowYear.controls.feeding_breast.setValue(
        nannytask.feeding_breast
      );
      this.fourMonthToTowYear.controls.solid_other_snack.setValue(
        nannytask.solid_other_snack
      );
      this.fourMonthToTowYear.controls.regular_meal_dinner.setValue(
        nannytask.regular_meal_dinner
      );
      this.fourMonthToTowYear.controls.regular_meal_lunch.setValue(
        nannytask.regular_meal_lunch
      ); this.fourMonthToTowYear.controls.regular_meal_type.setValue(
        nannytask.regular_meal_type
      );
      this.fourMonthToTowYear.controls.bathing_sponge.setValue(
        nannytask.bathing_sponge
      );
      this.fourMonthToTowYear.controls.bathing_tub.setValue(
        nannytask.bathing_tub
      ); this.fourMonthToTowYear.controls.bathing_shower.setValue(
        nannytask.bathing_shower
      );
      this.fourMonthToTowYear.controls.sleeping_mother.setValue(
        nannytask.sleeping_mother
      ); this.fourMonthToTowYear.controls.sleeping_nanny.setValue(
        nannytask.sleeping_nanny
      );
      this.fourMonthToTowYear.controls.sleep_train.setValue(
        nannytask.sleep_train
      );
      this.fourMonthToTowYear.controls.answer.setValue(nannytask.answer);
      this.fourMonthToTowYear.controls.attach.setValue(nannytask.attach);
      this.fourMonthToTowYear.controls.Asleep.setValue(nannytask.Asleep);
      this.fourMonthToTowYear.controls.LongPause.setValue(nannytask.LongPause);
      this.fourMonthToTowYear.controls.rhythmical.setValue(
        nannytask.rhythmical
      );
      this.fourMonthToTowYear.controls.swallowing.setValue(
        nannytask.swallowing
      );
      this.fourMonthToTowYear.controls.Normal_skin.setValue(
        nannytask.Normal_skin
      );
      this.fourMonthToTowYear.controls.weight.setValue(nannytask.weight);
      this.fourMonthToTowYear.controls.Other_concerns.setValue(
        nannytask.Other_concerns
      );
      this.fourMonthToTowYear.controls.temperature_monitoring_frequency.setValue(nannytask.temperature_monitoring_frequency);
      this.fourMonthToTowYear.controls.stroller.setValue(nannytask.stroller);
      this.fourMonthToTowYear.controls.interrupt.setValue(nannytask.interrupt);
      this.fourMonthToTowYear.controls.need_dummy.setValue(nannytask.need_dummy);
      this.fourMonthToTowYear.controls.pacifier.setValue(nannytask.pacifier);
      this.fourMonthToTowYear.controls.song_sleep.setValue(nannytask.song_sleep);
      this.fourMonthToTowYear.controls.reading_sleep.setValue(nannytask.reading_sleep);
      this.fourMonthToTowYear.controls.sleep_time.setValue(this.utility_service.parseISOString(nannytask.sleep_time));
      this.fourMonthToTowYear.controls.nap_time.setValue(this.utility_service.parseISOString(nannytask.nap_time));
      this.fourMonthToTowYear.controls.walk_time.setValue(this.utility_service.parseISOString(nannytask.walk_time));
      this.fourMonthToTowYear.controls.soild_other_snack_food_components.setValue(nannytask.soild_other_snack_food_components);
      this.fourMonthToTowYear.controls.solid_other_snack_time.setValue(this.utility_service.parseISOString(nannytask.solid_other_snack_time));

      this.fourMonthToTowYear.controls.diaper_change_frequency.setValue(nannytask.diaper_change_frequency);
      this.fourMonthToTowYear.controls.bottle_sterilization_one_at_a_time.setValue(
        this.utility_service.parseISOString(
          nannytask.bottle_sterilization_one_at_a_time
        ));
      //diaper frequency set value

      const diapherchangefrequency =
        res.data[0].nanny_task_details_json.diaper_changes;
      // for (let i = 0; i < diapherchangefrequency.length; i++) {
      //   let CalculationArray = this.getDiaperChangeControlsfrequency();
      //   let diaper = diapherchangefrequency[i] || {};

      //   const newCalculationGroup = this.FB.group({
      //     isDiaperChanged: new FormControl(diaper.isDiaperChanged || ''),
      //     diaper_label: new FormControl(
      //       diaper.diaper_label
      //         ? new Date(diaper.diaper_label)
      //         : null
      //     ),
      //   });
      //   CalculationArray.push(newCalculationGroup);
      // }
      // set value of diaper changes
      const adddiaper=res.data[0].nanny_task_details_json.diaper_calculation;
      for (let i = 0; i < adddiaper.length; i++) {
        this.isdiaper = true;
        let bottleArray = this.getdiaperCalculationControls();
        let bottleFromApi = adddiaper[i] || {};

        let newbottleArray = new FormGroup({
          diaper_timeArray: new FormControl(
            bottleFromApi.diaper_timeArray
              ? new Date(bottleFromApi.diaper_timeArray)
              : null
          ),
          diaper_actual_time: new FormControl(
            bottleFromApi.diaper_actual_time
              ? new Date(bottleFromApi.diaper_actual_time)
              : null
          ),
          remark: new FormControl(bottleFromApi.remark || ''),
          amount: new FormControl(bottleFromApi.amount || ''),
          diaperRemark: new FormControl(bottleFromApi.formula_remark || ''),
          diaper_checkbox: new FormControl(bottleFromApi.feeding_checkbox || ''),
        });

        bottleArray.insert(i, newbottleArray);
      }
      this.fourMonthToTowYear.controls.diaper_label.setValue(
        this.utility_service.parseISOString(nannytask.diaper_label)
      );
      this.fourMonthToTowYear.controls.diaper_label_end_time.setValue(
        this.utility_service.parseISOString(nannytask.diaper_label_end_time)
      );
      this.fourMonthToTowYear.controls.diaper_type.setValue(
        nannytask.diaper_type
      );
      // for (let i = 0; i < diapherchange.length; i++) {

      //   let CalculationArray = this.getDiaperChangeControls();
      //   let diaper = diapherchange[i] || {};

      //   const newCalculationGroup = this.FB.group({
      //     change: new FormControl(diaper.change || ''),
      //     time: new FormControl(
      //       diaper.time
      //         ? new Date(diaper.time)
      //         : null
      //     ),
      //     diaper_remark: new FormControl(diaper.diaper_remark || ''),
      //   });
      //   CalculationArray.push(newCalculationGroup);
      // }
      this.fourMonthToTowYear.controls.food_medicine_allergy.setValue(nannytask.food_medicine_allergy);
      for (let i = 0; i < VitaminsMedication.length; i++) {
        let vitaminArray = this.getVitaminFormArray();
        let VitaminsMedicationFromApi = VitaminsMedication[i] || {};

        let newVitaminArray = new FormGroup({
          vitamin: new FormControl(VitaminsMedicationFromApi.vitamin || ''),
          medicine_time: new FormArray([]),
          frequency: new FormControl(VitaminsMedicationFromApi.frequency || ''),

        });

        vitaminArray.insert(i, newVitaminArray);

        this.ismedicine = true;
        for (let j = 0; j < VitaminsMedicationFromApi.medicine_time.length; j++) {

          const viamincalc = (this.fourMonthToTowYear.get('vitamins_medications_json') as FormArray).at(i).get('medicine_time') as FormArray;
          const bathingcalculationgroup = this.FB.group({
            time: new FormControl(new Date(VitaminsMedicationFromApi.medicine_time[j].time)),
            vitamin_checkbox: new FormControl(VitaminsMedicationFromApi.medicine_time[j].vitamin_checkbox),
            vitamin_remark: new FormControl(VitaminsMedicationFromApi.medicine_time[j].vitamin_remark)
          });
          viamincalc.push(bathingcalculationgroup);
        }
      }

      for (let i = 0; i < vaccine.length; i++) {
        let vaccinationArray = this.getVaccinationFormArray();
        let vaccineApi = vaccine[i] || {};

        let newVaccinationArray = new FormGroup({
          vaccination: new FormControl(vaccineApi.vaccination || ''),
          date_administration: new FormControl(
            vaccineApi.date_administration
              ? new Date(vaccineApi.date_administration)
              : null
          ),
        });

        vaccinationArray.insert(i, newVaccinationArray);
      }


      if (nannytask.feeding_breast == "1") {
        this.Breast = !this.Breast
        this.fourMonthToTowYear.controls['feeding_frequency_breast'].enable();
        this.fourMonthToTowYear.controls['breast_remark'].enable();
        this.fourMonthToTowYear.controls['feeding_breast_time'].enable();
        this.fourMonthToTowYear.controls['feeding_frequency_breast'].setValue(
          nannytask.feeding_frequency_breast
        );
        this.fourMonthToTowYear.controls['breast_remark'].setValue(
          nannytask.breast_remark
        );

      } else {
        this.fourMonthToTowYear.controls['feeding_frequency_breast'].disable();
        this.fourMonthToTowYear.controls['breast_remark'].disable();
        this.fourMonthToTowYear.controls['feeding_breast_time'].disable();
      }
      if (nannytask.feeding_type == "1") {
        this.Formula = !this.Formula
        this.fourMonthToTowYear.controls['feeding_frequency'].enable();
        this.fourMonthToTowYear.controls['feeding_time'].enable();
        this.fourMonthToTowYear.controls['formula_feeding_time'].enable();
        this.fourMonthToTowYear.controls['feeding_amount_per'].enable();
        this.fourMonthToTowYear.controls['formula_remark'].enable();
        this.fourMonthToTowYear.controls['type_of_formula'].enable();
        this.fourMonthToTowYear.controls.feeding_time.setValue(
          this.utility_service.parseISOString(nannytask.feeding_time)
        );
        this.fourMonthToTowYear.controls.formula_feeding_time.setValue(
          this.utility_service.parseISOString(nannytask.formula_feeding_time)
        );
        this.fourMonthToTowYear.controls.feeding_amount_per.setValue(
          nannytask.feeding_amount_per
        );
        this.fourMonthToTowYear.controls.formula_remark.setValue(
          nannytask.formula_remark
        );
        this.fourMonthToTowYear.controls.type_of_formula.setValue(
          nannytask.type_of_formula
        );
        this.fourMonthToTowYear.controls.feeding_frequency.setValue(
          nannytask.feeding_frequency
        );
        // formula calculation
        for (let i = 0; i < nannytask.formula_calculation.length; i++) {
          this.isFormula = true;
          let bottleArray = this.getFormulaCalculationControls();
          let bottleFromApi = nannytask.formula_calculation[i] || {};

          let newbottleArray = new FormGroup({
            formula_timeArray: new FormControl(bottleFromApi.formula_timeArray ? new Date(bottleFromApi.formula_timeArray)
              : null),
            nurse_feeding: new FormControl(bottleFromApi.nurse_feeding ? new Date(bottleFromApi.nurse_feeding)
              : null),
            remark: new FormControl(bottleFromApi.remark || ''),
            amount: new FormControl(bottleFromApi.amount || ''),
            formula_remark: new FormControl(bottleFromApi.formula_remark || ''),
            feeding_checkbox: new FormControl(bottleFromApi.feeding_checkbox || ''),
          });

          bottleArray.insert(i, newbottleArray);
        }
        // bottle sterilization
        for (let i = 0; i < nannytask.bottle_sterilization.length; i++) {
          this.isFormula = true;
          let bottlesterilization_timeArrayArray = this.getbottle_sterilizationControls();
          let bottleFromApi = nannytask.bottle_sterilization[i] || {};

          let newbottle_sterilizationArray = new FormGroup({
            sterilization_timeArray: new FormControl(bottleFromApi.sterilization_timeArray ? new Date(bottleFromApi.sterilization_timeArray)
              : null),
            done_sterilization: new FormControl(
              bottleFromApi.done_sterilization
            ),
            actual_sterilization_timeArray: new FormControl(
              bottleFromApi.actual_sterilization_timeArray ? new Date(bottleFromApi.actual_sterilization_timeArray) : null
            ),
            sterilization_remark: new FormControl(
              bottleFromApi.sterilization_remark || ''
            ),

          });

          bottlesterilization_timeArrayArray.insert(i, newbottle_sterilizationArray);
        }
      } else {
        this.fourMonthToTowYear.controls['feeding_frequency'].disable();
        this.fourMonthToTowYear.controls['feeding_time'].disable();
        this.fourMonthToTowYear.controls['formula_feeding_time'].disable();
      }
      //skin care add button on edit
      for (let i = 0; i < skin.length; i++) {
        let skinArray = this.getSkinFormArray();
        let skinFromApi = skin[i] || {};

        let newskinArray = new FormGroup({
          skin_care_lotion: new FormControl(skinFromApi.skin_care_lotion || ''),
          skin_care_baby_oil: new FormControl(skinFromApi.skin_care_baby_oil || ''),
          skin_care_others: new FormControl(skinFromApi.skin_care_others || ''),
          skin_care_specify_produ: new FormControl(skinFromApi.skin_care_specify_produ || ''),
        });

        skinArray.insert(i, newskinArray);
      }
      for (let i = 0; i < regular.length; i++) {
        let RegularArray = this.getRegularFormArray();
        let regularApi = regular[i] || {};

        let newRegularArray = new FormGroup({
          regular_meal_type: new FormControl(
            regularApi.regular_meal_type || ''
          ),
          regular_meal_time: new FormControl(
            regularApi.regular_meal_time
              ? new Date(regularApi.regular_meal_time)
              : null
          ),

          regular_meal_food_components: new FormControl(
            regularApi.regular_meal_food_components || ''
          ),
        });

        RegularArray.insert(i, newRegularArray);
      }
      //set value of lunch json in nanny task
      for (let i = 0; i < lunch.length; i++) {
        let LunchArray = this.getLunchFormArray();
        let lunchApi = lunch[i] || {};

        let newLunchArray = new FormGroup({
          regular_meal_lunch: new FormControl(
            lunchApi.regular_meal_lunch || ''
          ),
          regular_meal_lunch_time: new FormControl(
            lunchApi.regular_meal_lunch_time
              ? new Date(lunchApi.regular_meal_lunch_time)
              : null
          ),
          regular_meal_lunch_food_components: new FormControl(
            lunchApi.regular_meal_lunch_food_components || ''
          ),
        });

        LunchArray.insert(i, newLunchArray);
      }
      //set value of dinner json in nanny task
      for (let i = 0; i < dinner.length; i++) {
        let dinnerArray = this.getDinnerFormArray();
        let dinnerApi = dinner[i] || {};

        let newdinnerArray = new FormGroup({
          regular_meal_dinner: new FormControl(
            dinnerApi.regular_meal_dinner || ''
          ),
          regular_meal_dinner_time: new FormControl(
            dinnerApi.regular_meal_dinner_time
              ? new Date(dinnerApi.regular_meal_dinner_time)
              : null
          ),
          regular_meal_dinner_food_components: new FormControl(
            dinnerApi.regular_meal_dinner_food_components || ''
          ),
        });

        dinnerArray.insert(i, newdinnerArray);
      }
      //changing_colthes
      const changingclothes =
        res.data[0].nanny_task_details_json.changing_clothes;
      for (let i = 0; i < changingclothes.length; i++) {
        let clothesarray = this.getClothesFormArray();
        let clothes = changingclothes[i] || {};

        const newclothesGroup = this.FB.group({
          changing_colthes: new FormControl(clothes.changing_colthes || ''),
          Changing_time: new FormControl(
            clothes.Changing_time
              ? new Date(clothes.Changing_time)
              : null
          ),
          clothe_remark: new FormControl(clothes.clothe_remark || ''),
        });
        clothesarray.push(newclothesGroup);
      }
      const sleeptime = res.data[0].nanny_task_details_json.sleeping_time;
      for (let i = 0; i < sleeptime.length; i++) {
        this.isBathingtub = true;
        let CalculationArray = this.getSleepArray();
        let time = sleeptime[i] || {};

        const newCalculationGroup = this.FB.group({
          sleeping_mother_time: new FormControl(
            time.sleeping_mother_time
              ? new Date(time.sleeping_mother_time)
              : null
          ),
          sleeping_mother: new FormControl(time.sleeping_mother || ''),
        });
        CalculationArray.push(newCalculationGroup);
      }
      //nanny time
      const nannytime = res.data[0].nanny_task_details_json.nanny_time;
      for (let i = 0; i < nannytime.length; i++) {
        this.isBathingtub = true;
        let CalculationArray = this.getSleepnannyArray();
        let time = nannytime[i] || {};

        const newCalculationGroup = this.FB.group({
          sleeping_nanny_time: new FormControl(
            time.sleeping_nanny_time
              ? new Date(time.sleeping_nanny_time)
              : null
          ),
          sleeping_nanny: new FormControl(time.sleeping_nanny || ''),
        });
        CalculationArray.push(newCalculationGroup);
      }
      if (nannytask.bathing_shower == true) {
        // this.fourMonthToTowYear.controls['bathing_shower_time'].enable();
        this.fourMonthToTowYear.controls['bathing_shower_frequency'].enable();
        // this.fourMonthToTowYear.controls.bathing_shower_time.setValue(
        //   this.utility_service.parseISOString(nannytask.bathing_shower_time) 
        // );
        this.fourMonthToTowYear.controls.bathing_shower_frequency.setValue(
          nannytask.bathing_shower_frequency
        );
      } else {
        // this.fourMonthToTowYear.controls['bathing_shower_time'].disable();
        this.fourMonthToTowYear.controls['bathing_shower_frequency'].disable();
      }
      if (nannytask.bathing_tub == true) {
        // this.fourMonthToTowYear.controls['bathing_tub_time'].enable();
        this.fourMonthToTowYear.controls['bathing_tub_frequency'].enable();
        // this.fourMonthToTowYear.controls.bathing_tub_time.setValue(
        //   this.utility_service.parseISOString(nannytask.bathing_tub_time) 
        // );
        this.fourMonthToTowYear.controls.bathing_tub_frequency.setValue(
          nannytask.bathing_tub_frequency
        );
      } else {
        // this.fourMonthToTowYear.controls['bathing_tub_time'].disable();
        this.fourMonthToTowYear.controls['bathing_tub_frequency'].disable();
      }
      if (nannytask.bathing_sponge == true) {
        // this.fourMonthToTowYear.controls['bathing_sponge_time'].enable();
        this.fourMonthToTowYear.controls['bathing_frequency_sponge'].enable();
        // this.fourMonthToTowYear.controls.bathing_sponge_time.setValue(
        //   this.utility_service.parseISOString(nannytask.bathing_sponge_time) 
        // );
        this.fourMonthToTowYear.controls.bathing_frequency_sponge.setValue(
          nannytask.bathing_frequency_sponge
        );
      } else {
        // this.fourMonthToTowYear.controls['bathing_sponge_time'].disable();
        this.fourMonthToTowYear.controls['bathing_frequency_sponge'].disable();
      }

      if (nannytask.food_medicine_allergy == '1') {
        this.fourMonthToTowYear.controls[
          'food_medicine_allergy_specify_prod'
        ].enable();
        this.fourMonthToTowYear.controls.food_medicine_allergy_specify_prod.setValue(
          nannytask.food_medicine_allergy_specify_prod
        );
      } else {
        this.fourMonthToTowYear.controls[
          'food_medicine_allergy_specify_prod'
        ].disable();
      }
      this.fourMonthToTowYear.controls.skin_care_lotion.setValue(
        nannytask.skin_care_lotion
      );
      this.fourMonthToTowYear.controls.skin_care_baby_oil.setValue(
        nannytask.skin_care_baby_oil
      );
      this.fourMonthToTowYear.controls.skin_care_others.setValue(
        nannytask.skin_care_others
      );
      this.fourMonthToTowYear.controls.skin_care_specify_produ.setValue(
        nannytask.skin_care_specify_produ
      );
      this.fourMonthToTowYear.controls.skin_care_Wipes.setValue(
        nannytask.skin_care_Wipes
      );
      this.fourMonthToTowYear.controls.bottle_sterilization_all.setValue(
        nannytask.bottle_sterilization_all
      );
      this.fourMonthToTowYear.controls.bottle_sterilization_onetime.setValue(
        nannytask.bottle_sterilization_onetime
      );

      if (nannytask.bottle_sterilization_all == true) {
        this.All = !this.All;
        this.fourMonthToTowYear.controls.bottle_sterilization_all_time.enable();
        this.fourMonthToTowYear.controls.bottle_sterilization_all_time.setValue(
          this.utility_service.parseISOString(
            nannytask.bottle_sterilization_all_time
          )
        );
      }
      if (nannytask.bottle_sterilization_onetime == true) {
        this.fourMonthToTowYear.controls.bottle_sterilization_onetime_time.enable();
        this.fourMonthToTowYear.controls.bottle_sterilization_onetime_time.setValue(
          this.utility_service.parseISOString(
            nannytask.bottle_sterilization_onetime_time
          )
        );
        this.One = !this.One;
      } else {
        this.fourMonthToTowYear.controls.bottle_sterilization_onetime_time.disable();
      }
      if (nannytask.skin_care_Wipes == '1') {
        this.fourMonthToTowYear.controls['skin_care_special_instructions']
          .enable;
        this.fourMonthToTowYear.controls.skin_care_special_instructions.setValue(
          nannytask.skin_care_special_instructions
        );
      } else {
        this.fourMonthToTowYear.controls[
          'skin_care_special_instructions'
        ].disable();
      }
      this.fourMonthToTowYear.controls.changing_colthes_spiecal_instructions.setValue(
        nannytask.changing_colthes_spiecal_instructions
      );
      this.fourMonthToTowYear.controls.changing_colthes_frequency.setValue(
        nannytask.changing_colthes_frequency
      );
      this.fourMonthToTowYear.controls.remarks.setValue(nannytask.remarks);
      if (nannytask.sleeping_mother == "1") {
        this.fourMonthToTowYear.controls.sleeping_mother_time.enable();
        this.fourMonthToTowYear.controls.sleeping_mother_time.setValue(this.utility_service.parseISOString(nannytask.sleeping_mother_time))
      }
      else {
        this.fourMonthToTowYear.controls.sleeping_mother_time.disable();
      }
      if (nannytask.sleeping_nanny == "1") {
        this.fourMonthToTowYear.controls.sleeping_nanny_time.enable();
        this.fourMonthToTowYear.controls.sleeping_nanny_time.setValue(this.utility_service.parseISOString(nannytask.sleeping_nanny_time))
      }
      else {
        this.fourMonthToTowYear.controls.sleeping_nanny_time.disable();
      }
      if (nannytask.sleep_train == "Yes") {
        this.fourMonthToTowYear.controls.walk_time.enable();
        this.fourMonthToTowYear.controls.nap_time.enable();
        this.fourMonthToTowYear.controls.sleep_time.enable();
        this.fourMonthToTowYear.controls.walk_time.setValue(this.utility_service.parseISOString(nannytask.walk_time))
        this.fourMonthToTowYear.controls.nap_time.setValue(this.utility_service.parseISOString(nannytask.nap_time))
        this.fourMonthToTowYear.controls.sleep_time.setValue(this.utility_service.parseISOString(nannytask.sleep_time))
      }
      else {
        this.fourMonthToTowYear.controls.sleep_time.disable();
        this.fourMonthToTowYear.controls.nap_time.disable();
        this.fourMonthToTowYear.controls.walk_time.disable();
      }
      const spongetime = res.data[0].nanny_task_details_json.bathing_time;
      //bathing section
      for (let i = 0; i < spongetime.length; i++) {
        this.isBathing = true;
        this.Sponge = !this.Sponge;
        let CalculationArray = this.getbathingarray();
        let time = spongetime[i] || {};
        const newCalculationGroup = this.FB.group({
          bathing_sponge_time: new FormControl(new Date(time.bathing_sponge_time) || ''),
          sponge_checkbox: new FormControl(time.sponge_checkbox || ''),
          spongeBathing_remark: new FormControl(time.spongeBathing_remark || ''),
        });
        CalculationArray.push(newCalculationGroup);
      }
      const tubtime = res.data[0].nanny_task_details_json.tub_bathing;
      for (let i = 0; i < tubtime.length; i++) {
        this.isBathingtub = true;
        this.Tub = !this.Tub;
        let CalculationArray = this.getbathingtubarray();
        let time = tubtime[i] || {};

        const newCalculationGroup = this.FB.group({
          bathing_tub_time: new FormControl(new Date(time.bathing_tub_time) || ''),
          tub_checkbox: new FormControl(time.tub_checkbox || ''),
          tub_remark: new FormControl(time.tub_remark || ''),
        });
        CalculationArray.push(newCalculationGroup);
      }
      const showertime = res.data[0].nanny_task_details_json.shower_bathing;
      for (let i = 0; i < showertime.length; i++) {
        this.isBathingshower = true;
        this.Shower = !this.Shower;
        let CalculationArray = this.getshowertubarray();
        let time = showertime[i] || {};

        const newCalculationGroup = this.FB.group({
          bathing_shower_time: new FormControl(new Date(time.bathing_shower_time) || ''),
          shower_checkbox: new FormControl(time.shower_checkbox || ''),
          shower_remark: new FormControl(time.shower_remark || ''),
        });
        CalculationArray.push(newCalculationGroup);
      }
      //feeding multiple time fields
      const breastime = res.data[0].nanny_task_details_json.breast_feeding;
      for (let i = 0; i < breastime.length; i++) {
        this.isBreast = true;
        let CalculationArray = this.getbreastarray();
        let time = breastime[i] || {};
        const newCalculationGroup = this.FB.group({
          feeding_breast_time: new FormControl(
            time.feeding_breast_time
              ? new Date(time.feeding_breast_time)
              : null
          ),
          // feeding_breast_time: new FormControl(
          //   new Date(time.feeding_breast_time) || ''
          // ),
          feeding_breast_checkbox: new FormControl(time.feeding_breast_checkbox || ''),
          breast_feeding_remark: new FormControl(time.breast_feeding_remark || '')
        });
        CalculationArray.push(newCalculationGroup);

      }
      this.isSettingValueProgrammatically = false;

    });
  }
  //add button on regular meal
  addRegularObject(string: any, index: any) {
    let RegularArray = this.getRegularFormArray();
    if (string == 'add') {
      let newRegularArray = new FormGroup({
        regular_meal_type: new FormControl(''),
        regular_meal_time: new FormControl(''),
        regular_meal_food_components: new FormControl(''),
      });
      RegularArray.insert(index + 1, newRegularArray);
    } else {
      RegularArray.removeAt(index);
    }

  }
  //add button on lunch meal
  addLunchObject(string: any, index: any) {
    let LunchArray = this.getLunchFormArray();
    if (string == 'add') {
      let newLunchArray = new FormGroup({
        regular_meal_lunch: new FormControl(''),
        regular_meal_lunch_time: new FormControl(''),
        regular_meal_lunch_food_components: new FormControl(''),
      });
      LunchArray.insert(index + 1, newLunchArray);
    } else {
      LunchArray.removeAt(index);
    }
  }
  //add button on dinner meal
  addDinnerObject(string: any, index: any) {
    let DinnerArray = this.getDinnerFormArray();
    if (string == 'add') {
      let newDinnerArray = new FormGroup({
        regular_meal_dinner: new FormControl(''),
        regular_meal_dinner_time: new FormControl(''),
        regular_meal_dinner_food_components: new FormControl(''),
      });
      DinnerArray.insert(index + 1, newDinnerArray);
    } else {
      DinnerArray.removeAt(index);
    }
  }
  //add button on vitamin meal
  addVitaminObject(string: any, index: any) {
    let vitaminArray = this.getVitaminFormArray();
    if (string == 'add') {
      let newVitaminArray = new FormGroup({
        vitamin: new FormControl(''),
        medicine_time: new FormArray([]),
        frequency: new FormControl(''),

      });
      vitaminArray.insert(index + 1, newVitaminArray);
    } else {
      vitaminArray.removeAt(index);
    }
  }
  //add button on regular meal
  addSkinObject(string: any, index: any) {
    let skinArray = this.getSkinFormArray();
    if (string == 'add') {
      let newskinArray = new FormGroup({
        skin_care_lotion: new FormControl(''),
        skin_care_baby_oil: new FormControl(''),
        skin_care_others: new FormControl(''),
        skin_care_specify_produ: new FormControl(''),
      });
      skinArray.insert(index + 1, newskinArray);
    } else {
      skinArray.removeAt(index);
    }
  }
  getRegularFormArray() {
    return this.fourMonthToTowYear.get('solid_food') as FormArray;
  }
  getDinnerFormArray() {
    return this.fourMonthToTowYear.get('dinner_food') as FormArray;
  }
  getLunchFormArray() {
    return this.fourMonthToTowYear.get('lunch_food') as FormArray;
  }
  getSkinFormArray() {
    return this.fourMonthToTowYear.get('skin_care') as FormArray;
  }
  // addSleepTraining(string: any, index: any) {
  //   let sleepArray = this.getsleeptrainFormArray();
  //   if (string == 'add') {
  //     let newSleepTrainingArray = new FormGroup({
  //       vitamin: new FormControl(''),
  //       time: new FormControl(''),
  //       frequency: new FormControl(''),
  //     });
  //     sleepArray.insert(index + 1, newSleepTrainingArray);
  //   } else {
  //     sleepArray.removeAt(index);
  //   }
  // }
  /* -------------------------------------------------------------------------- */
  /*            //Soft Reloading            */
  /* -------------------------------------------------------------------------- */
  softReload() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['portal/patient-view']);
    });
  }
  addVaccinationObject(string: any, index: any) {
    let vaccinationArray = this.getVaccinationFormArray();
    if (string == 'add') {
      let newVaccinationArray = new FormGroup({
        vaccination: new FormControl(''),
        date_administration: new FormControl(''),
      });
      vaccinationArray.insert(index + 1, newVaccinationArray);
    } else {
      vaccinationArray.removeAt(index);
    }
  }

  // get baby details function
  getBabyDetails() {
    this.spinner.show()
    this.commonService.getBabyDetails().subscribe(
      (res: any) => {
        this.spinner.hide()
        this.babyValues = res?.data;
      },
      (error: HttpErrorResponse) => {
        this.spinner.hide()
      }
    );
  }
  getRemainingDetails(event: any) {
    let data = this.babyValues?.filter((res: any) => res?.id == event?.value);
    let firstLetter = data[0]?.gender.toLowerCase();
    let capitalize =
      firstLetter.slice(0, 1).toUpperCase() +
      firstLetter.slice(1, firstLetter.length);
    this.babyGender = capitalize;
    this.selectedDate = data[0].date_of_birth;
    // Manually parse the date string in DD/MM/YYYY format
    const dateParts = data[0].date_of_birth.split('/');
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Months are 0-indexed in JavaScript
    const year = parseInt(dateParts[2], 10);

    const selectedDate = moment([year, month, day]);
    const today = moment();

    // Calculate the difference in years, months, and days
    const years = today.diff(selectedDate, 'years');
    selectedDate.add(years, 'years');

    const months = today.diff(selectedDate, 'months');
    selectedDate.add(months, 'months');

    const days = today.diff(selectedDate, 'days');

    this.years = years;
    this.month = months;
    this.days = days;
    if (
      (this.years == 1 && this.month == 0 && this.days == 0) ||
      (this.years == 0 && this.days <= 31 && this.month >= 4)
    ) {
      this.flag = true;
    } else {
      this.flag = false;
      this.messageService.add({
        severity: 'info',
        summary: 'Info',
        detail:
          'Your child does not meet the eligibility criteria for this plan.',
      });
    }
  }

  handleInput(event: any, value: string) {
    if (value == 'Bathing') {
      // if (event.target.value == 'Sponge') {
      //   this.Sponge = !this.Sponge;
      //   if (this.Sponge == true) {
      //     this.fourMonthToTowYear.controls['bathing_frequency_sponge'].setValue(
      //       ''
      //     );
      //     this.fourMonthToTowYear.controls['bathing_sponge_time'].setValue('');
      //   }
      //   if(event.target.checked){
      //     this.fourMonthToTowYear.controls['bathing_frequency_sponge'].setValidators([Validators.required]);
      //     this.fourMonthToTowYear.controls['bathing_sponge_time'].setValidators([Validators.required]);
      //   }
      //   else {
      //     this.fourMonthToTowYear.controls['bathing_frequency_sponge'].clearValidators();
      //     this.fourMonthToTowYear.controls['bathing_sponge_time'].clearValidators();
      //   }
      //   this.fourMonthToTowYear.controls['bathing_frequency_sponge'].updateValueAndValidity();
      //   this.fourMonthToTowYear.controls['bathing_sponge_time'].updateValueAndValidity();
      if (event.target.value == 'Sponge') {

        this.Sponge = !this.Sponge;
        if (this.Sponge == true) {
          this.fourMonthToTowYear.controls[
            'bathing_frequency_sponge'
          ].setValue(null);
          this.fourMonthToTowYear.controls['bathing_sponge_time'].setValue(
            ''
          );
        }
        if (event.target.checked) {
          this.fourMonthToTowYear.controls[
            'bathing_frequency_sponge'
          ].setValidators([Validators.required]);
          // this.fourMonthToTowYear.controls[
          //   'bathing_sponge_time'
          // ].setValidators([Validators.required]);
        } else {
          this.fourMonthToTowYear.controls[
            'bathing_frequency_sponge'
          ].clearValidators();
          this.fourMonthToTowYear.controls[
            'bathing_sponge_time'
          ].clearValidators();
        }
        this.fourMonthToTowYear.controls[
          'bathing_frequency_sponge'
        ].updateValueAndValidity();
        this.fourMonthToTowYear.controls[
          'bathing_sponge_time'
        ].updateValueAndValidity();
      }
      else if (event.target.value == 'Tub') {
        this.Tub = !this.Tub;
        if (this.Tub == true) {
          this.fourMonthToTowYear.controls['bathing_tub_frequency'].setValue(
            ''
          );
          this.fourMonthToTowYear.controls['bathing_tub_time'].setValue('');
        }
        if (event.target.checked) {
          this.fourMonthToTowYear.controls[
            'bathing_tub_frequency'
          ].setValidators([Validators.required]);
          // this.fourMonthToTowYear.controls['bathing_tub_time'].setValidators([
          //   Validators.required,
          // ]);
        } else {
          this.fourMonthToTowYear.controls[
            'bathing_tub_frequency'
          ].clearValidators();
          this.fourMonthToTowYear.controls[
            'bathing_tub_time'
          ].clearValidators();
        }
        this.fourMonthToTowYear.controls[
          'bathing_tub_frequency'
        ].updateValueAndValidity();
        this.fourMonthToTowYear.controls[
          'bathing_tub_time'
        ].updateValueAndValidity();
      }
      if (event.target.value == 'Shower') {
        this.Shower = !this.Shower;
        if (this.Shower == true) {
          this.fourMonthToTowYear.controls['bathing_shower_frequency'].setValue(
            ''
          );
          this.fourMonthToTowYear.controls['bathing_shower_time'].setValue('');
        }
        if (event.target.checked) {
          this.fourMonthToTowYear.controls[
            'bathing_shower_frequency'
          ].setValidators([Validators.required]);
          // this.fourMonthToTowYear.controls['bathing_shower_time'].setValidators(
          //   [Validators.required]
          // );
        } else {
          this.fourMonthToTowYear.controls[
            'bathing_shower_frequency'
          ].clearValidators();
          this.fourMonthToTowYear.controls[
            'bathing_shower_time'
          ].clearValidators();
        }
        this.fourMonthToTowYear.controls[
          'bathing_shower_frequency'
        ].updateValueAndValidity();
        this.fourMonthToTowYear.controls[
          'bathing_shower_time'
        ].updateValueAndValidity();
      }
    } else if (value == 'milk') {
      if (event.target.value == 'Breast') {
        this.fourMonthToTowYear.controls.feeding_breast.setValue(
          event?.target?.checked ? 1 : 0
        );
        this.Breast = !this.Breast;
        if (this.Breast == true) {
          this.fourMonthToTowYear.controls['feeding_frequency_breast'].setValue(
            ''
          );
          this.fourMonthToTowYear.controls['breast_remark'].setValue(
            ''
          );
          this.fourMonthToTowYear.controls['feeding_breast_time'].setValue('');
          this.fourMonthToTowYear.controls[
            'breast_remark'
          ].disable();
        }
        else {
          this.fourMonthToTowYear.controls['breast_remark'].enable();
        }

        if (event.target.checked) {
          this.fourMonthToTowYear.controls[
            'feeding_frequency_breast'
          ].setValidators([Validators.required]);

        } else {
          this.fourMonthToTowYear.controls[
            'feeding_frequency_breast'
          ].clearValidators();
          this.fourMonthToTowYear.controls[
            'feeding_breast_time'
          ].clearValidators();
        }
        this.fourMonthToTowYear.controls[
          'feeding_frequency_breast'
        ].updateValueAndValidity();
        this.fourMonthToTowYear.controls[
          'feeding_breast_time'
        ].updateValueAndValidity();
        // if (event.target.value == 'Breast') {
        //   this.fourMonthToTowYear.controls.feeding_breast.setValue(event?.target?.checked ? 1 : 0);
        //   this.Breast = !this.Breast;
        //   if (this.Breast == true) {
        //     this.fourMonthToTowYear.controls['feeding_frequency_breast'].setValue(
        //       ''
        //     );
        //     this.fourMonthToTowYear.controls[
        //       'feeding_breast_time'
        //     ].setValue('');
        //   }
        //   if(event.target.checked){
        //     this.fourMonthToTowYear.controls['feeding_frequency_breast'].setValidators([Validators.required]);

        //     this.fourMonthToTowYear.controls['feeding_breast_time'].setValidators([Validators.required]);
        //   }else {
        //     this.fourMonthToTowYear.controls['feeding_frequency_breast'].clearValidators();
        //     this.fourMonthToTowYear.controls['feeding_breast_time'].clearValidators();
        //   }
        //   this.fourMonthToTowYear.controls['feeding_frequency_breast'].updateValueAndValidity();
        //   this.fourMonthToTowYear.controls['feeding_breast_time'].updateValueAndValidity();
      }
      else if (event.target.value == 'Formula') {
        
        this.fourMonthToTowYear.controls.feeding_type.setValue(
          event?.target?.checked ? 1 : 0
        );
        this.Formula = !this.Formula;
        if (this.Formula == true) {
          this.fourMonthToTowYear.controls['feeding_frequency'].setValue('');
          this.fourMonthToTowYear.controls['feeding_time'].setValue('');
          this.fourMonthToTowYear.controls['formula_feeding_time'].setValue('');

          this.fourMonthToTowYear.controls['type_of_formula'].setValue('');
          this.fourMonthToTowYear.controls['feeding_amount_per'].setValue('');
          this.fourMonthToTowYear.controls['formula_remark'].setValue('');
        }
        if (event.target.checked) {
          this.fourMonthToTowYear.controls['feeding_frequency'].setValidators([
            Validators.required,
          ]);

          this.fourMonthToTowYear.controls['feeding_time'].setValidators([
            Validators.required,
          ]);
          this.fourMonthToTowYear.controls['formula_feeding_time'].setValidators([
            Validators.required,
          ]);

          this.fourMonthToTowYear.controls['feeding_amount_per'].setValidators([
            Validators.required,
          ]);
          this.fourMonthToTowYear.controls['type_of_formula'].setValidators([
            Validators.required,
          ]);
        } else {
          this.fourMonthToTowYear.controls[
            'feeding_frequency'
          ].clearValidators();
          this.fourMonthToTowYear.controls['feeding_time'].clearValidators();
          this.fourMonthToTowYear.controls['formula_feeding_time'].clearValidators();
          this.fourMonthToTowYear.controls[
            'feeding_amount_per'
          ].clearValidators();
          this.fourMonthToTowYear.controls['type_of_formula'].clearValidators();
        }
        this.fourMonthToTowYear.controls[
          'feeding_frequency'
        ].updateValueAndValidity();
        this.fourMonthToTowYear.controls[
          'feeding_time'
        ].updateValueAndValidity();
        this.fourMonthToTowYear.controls[
          'formula_feeding_time'
        ].updateValueAndValidity();
        this.fourMonthToTowYear.controls[
          'feeding_amount_per'
        ].updateValueAndValidity();
        this.fourMonthToTowYear.controls[
          'type_of_formula'
        ].updateValueAndValidity();
      }
    } else if (value == 'feeding') {
      if (event.target.value == 'All') {
        this.All = !this.All;
        if (this.All == true) {
          this.fourMonthToTowYear.controls['bottle_sterilization_all'].setValue(
            ''
          );
        }
        if (event.target.checked) {
          this.fourMonthToTowYear.controls[
            'bottle_sterilization_all_time'
          ].setValidators([Validators.required]);
        } else {
          this.fourMonthToTowYear.controls[
            'bottle_sterilization_all_time'
          ].clearValidators();
        }
        this.fourMonthToTowYear.controls[
          'bottle_sterilization_all_time'
        ].updateValueAndValidity();
      }
      if (event.target.value == 'One') {
        this.One = !this.One;
        if (this.One == true) {
          this.fourMonthToTowYear.controls[
            'bottle_sterilization_onetime'
          ].setValue('');
        }

        this.fourMonthToTowYear.controls[
          'bottle_sterilization_onetime_time'
        ].updateValueAndValidity();
      }
    }
    else if (value == 'regularMeal') {
      if (event.target.value == 'Snack') {
        this.Snack = !this.Snack;
        if (this.Snack) {
          this.fourMonthToTowYear.controls[
            'soild_other_snack_food_components'
          ].setValue('');
          this.fourMonthToTowYear.controls['solid_other_snack_time'].setValue(
            ''
          );
        }
        if (event.target.checked) {
          this.fourMonthToTowYear.controls[
            'solid_other_snack_time'
          ].setValidators([Validators.required]);
          this.fourMonthToTowYear.controls[
            'soild_other_snack_food_components'
          ].setValidators([Validators.required]);
        } else {
          this.fourMonthToTowYear.controls[
            'solid_other_snack_time'
          ].clearValidators();
          this.fourMonthToTowYear.controls[
            'soild_other_snack_food_components'
          ].clearValidators();
        }
        this.fourMonthToTowYear.controls[
          'solid_other_snack_time'
        ].updateValueAndValidity();
        this.fourMonthToTowYear.controls[
          'soild_other_snack_food_components'
        ].updateValueAndValidity();
      }
    }
    // else if (value == 'break') {
    //     if (event.target.value == 'Breakfast') {
    //       this.Breakfast = !this.Breakfast;
    //       if (this.Breakfast) {
    //         this.fourMonthToTowYear.controls['regular_meal_time'].setValue('');
    //         this.fourMonthToTowYear.controls[
    //           'regular_meal_food_components'
    //         ].setValue('');
    //       }
    //       if (event.target.checked) {
    //         this.fourMonthToTowYear.controls['regular_meal_time'].setValidators(
    //           [Validators.required]
    //         );
    //         this.fourMonthToTowYear.controls[
    //           'regular_meal_food_components'
    //         ].setValidators([Validators.required]);
    //       } else {
    //         this.fourMonthToTowYear.controls[
    //           'regular_meal_time'
    //         ].clearValidators();
    //         this.fourMonthToTowYear.controls[
    //           'regular_meal_food_components'
    //         ].clearValidators();
    //       }
    //       this.fourMonthToTowYear.controls[
    //         'regular_meal_time'
    //       ].updateValueAndValidity();
    //       this.fourMonthToTowYear.controls[
    //         'regular_meal_food_components'
    //       ].updateValueAndValidity();
    //     }
    //   }
    //     else if (value == 'lunch') {
    //     if (event.target.value == 'Lunch') {
    //       this.Lunch = !this.Lunch;
    //       if (this.Lunch) {
    //         this.fourMonthToTowYear.controls[
    //           'regular_meal_lunch_time'
    //         ].setValue('');
    //         this.fourMonthToTowYear.controls[
    //           'regular_meal_lunch_food_components'
    //         ].setValue('');
    //       }
    //       if (event.target.checked) {
    //         this.fourMonthToTowYear.controls[
    //           'regular_meal_lunch_time'
    //         ].setValidators([Validators.required]);
    //         this.fourMonthToTowYear.controls[
    //           'regular_meal_lunch_food_components'
    //         ].setValidators([Validators.required]);
    //       } else {
    //         this.fourMonthToTowYear.controls[
    //           'regular_meal_lunch_time'
    //         ].clearValidators();
    //         this.fourMonthToTowYear.controls[
    //           'regular_meal_lunch_food_components'
    //         ].clearValidators();
    //       }
    //       this.fourMonthToTowYear.controls[
    //         'regular_meal_lunch_time'
    //       ].updateValueAndValidity();
    //       this.fourMonthToTowYear.controls[
    //         'regular_meal_lunch_food_components'
    //       ].updateValueAndValidity();
    //     }
    //   }
    //     else if (value == 'dinner') {
    //     if (event.target.value == 'Dinner') {
    //       this.Dinner = !this.Dinner;
    //       if (this.Dinner) {
    //         this.fourMonthToTowYear.controls[
    //           'regular_meal_dinner_time'
    //         ].setValue('');
    //         this.fourMonthToTowYear.controls[
    //           'regular_meal_dinner_food_components'
    //         ].setValue('');
    //       }
    //       if (event.target.checked) {
    //         this.fourMonthToTowYear.controls[
    //           'regular_meal_dinner_time'
    //         ].setValidators([Validators.required]);
    //         this.fourMonthToTowYear.controls[
    //           'regular_meal_dinner_food_components'
    //         ].setValidators([Validators.required]);
    //       } else {
    //         this.fourMonthToTowYear.controls[
    //           'regular_meal_dinner_time'
    //         ].clearValidators();
    //         this.fourMonthToTowYear.controls[
    //           'regular_meal_dinner_food_components'
    //         ].clearValidators();
    //       }
    //       this.fourMonthToTowYear.controls[
    //         'regular_meal_dinner_time'
    //       ].updateValueAndValidity();
    //       this.fourMonthToTowYear.controls[
    //         'regular_meal_dinner_food_components'
    //       ].updateValueAndValidity();
    //     }
    //   }


    // else if (value == 'Allergy') {
    //   if (event.target.value == '0') {
    //     this.Yes = true;
    //     if (this.Yes == true) {
    //       this.fourMonthToTowYear.controls[
    //         'food_medicine_allergy_specify_prod'
    //       ].setValue('');
    //     }
    //   }
    //   if (event.target.value == '1') {
    //     this.Yes = false;
    //   }
    // }
    else if (value == 'wipes') {
      if (event.target.value == '1') {
        this.wipes = false;
      } else if (event.target.value == '0') {
        this.wipes = true;
        this.fourMonthToTowYear.controls[
          'skin_care_special_instructions'
        ].setValue('');
      }
    }
    else if (value == 'Training') {
      if (event.target.value == 'Yes') {
        this.sleep_training = false;
        this.fourMonthToTowYear.controls['walk_time'].setValue('');
        this.fourMonthToTowYear.controls['nap_time'].setValue('');
        this.fourMonthToTowYear.controls['sleep_time'].setValue('');
      }
      // if (event.target.checked) {
      //   this.fourMonthToTowYear.controls['walk_time'].setValidators([
      //     Validators.required,
      //   ]);
      //   this.fourMonthToTowYear.controls['nap_time'].setValidators([
      //     Validators.required,
      //   ]);
      //   this.fourMonthToTowYear.controls['sleep_time'].setValidators([
      //     Validators.required,
      //   ]);
      // } 
      else {
        this.fourMonthToTowYear.controls['walk_time'].clearValidators();
        this.fourMonthToTowYear.controls['nap_time'].clearValidators();
        this.fourMonthToTowYear.controls['sleep_time'].clearValidators();
      }
      this.fourMonthToTowYear.controls['walk_time'].updateValueAndValidity();
      this.fourMonthToTowYear.controls['nap_time'].updateValueAndValidity();
      this.fourMonthToTowYear.controls['sleep_time'].updateValueAndValidity();
    }
    else if (value == 'sleeping') {
      if (event.target.value == 'With_Mother') {
        this.With_Mother = !this.With_Mother;
        if (this.With_Mother == true) {
          this.fourMonthToTowYear.controls['sleeping_mother_time'].setValue('');
        }
        if (event.target.checked) {
          this.fourMonthToTowYear.controls[
            'sleeping_mother_time'
          ].setValidators([Validators.required]);
        } else {
          this.fourMonthToTowYear.controls[
            'sleeping_mother_time'
          ].clearValidators();
        }
        this.fourMonthToTowYear.controls[
          'sleeping_mother_time'
        ].updateValueAndValidity();
      }
      if (event.target.value == 'With_Nanny') {
        this.With_Nanny = !this.With_Nanny;
        if (this.With_Nanny == true) {
          this.fourMonthToTowYear.controls['sleeping_nanny_time'].setValue('');
        }

        if (event.target.checked) {
          this.fourMonthToTowYear.controls['sleeping_nanny_time'].setValidators([
            Validators.required,
          ]);
        } else {
          this.fourMonthToTowYear.controls[
            'sleeping_nanny_time'
          ].clearValidators();
        }
        this.fourMonthToTowYear.controls[
          'sleeping_nanny_time'
        ].updateValueAndValidity();
      }
    }
    //  else if (value == 'others') {
    //   if (event.target.value == 'others') {
    //     this.showProducts = !this.showProducts;
    //   }
    // }
    else if (value == 'fnmAllergy') {
      if (event.target.value == '1') {
        this.fourMonthToTowYear.controls.food_medicine_allergy_specify_prod.enable();
      } else {
        this.fourMonthToTowYear.controls.food_medicine_allergy_specify_prod.disable();
        this.fourMonthToTowYear.controls.food_medicine_allergy_specify_prod.setValue(
          null
        );
      }
      if (event.target.checked) {
        this.fourMonthToTowYear.controls[
          'food_medicine_allergy_specify_prod'
        ].setValidators([Validators.required]);
      } else {
        this.fourMonthToTowYear.controls[
          'food_medicine_allergy_specify_prod'
        ].clearValidators();
      }
      this.fourMonthToTowYear.controls[
        'food_medicine_allergy_specify_prod'
      ].updateValueAndValidity();
    }
  }

  submitForm(fourMonthAbove) {
    let structuredObject = {
      baby_details_id: this.fourMonthToTowYear.get('baby_details_id').value,
      remarks:this.fourMonthToTowYear.get('remarks').value,
      common: [
        {
          Asleep: this.fourMonthToTowYear.get('Asleep').value,
          LongPause:this.fourMonthToTowYear.get('LongPause').value,
          rhythmical:this.fourMonthToTowYear.get('rhythmical').value,
          swallowing:this.fourMonthToTowYear.get('swallowing').value,
          Normal_skin:this.fourMonthToTowYear.get('Normal_skin').value,
          weight:this.fourMonthToTowYear.get('weight').value,
          Other_concerns:this.fourMonthToTowYear.get('Other_concerns').value,
        }
      ],
      bathing:[
        {
          bathing_frequency_sponge:this.fourMonthToTowYear.get('bathing_frequency_sponge').value,
          bathing_time:this.fourMonthToTowYear.get('bathing_time').value,
          bathing_sponge:this.fourMonthToTowYear.get('bathing_sponge').value,
          tub_bathing:this.fourMonthToTowYear.get('tub_bathing').value,
          bathing_tub_frequency:this.fourMonthToTowYear.get('bathing_tub_frequency').value,
          bathing_tub_time:this.fourMonthToTowYear.get('bathing_tub_time').value,
          bathing_shower:this.fourMonthToTowYear.get('bathing_shower').value,
          bathing_shower_frequency:this.fourMonthToTowYear.get('bathing_shower_frequency').value,
          shower_bathing:this.fourMonthToTowYear.get('shower_bathing').value,
        }
      ],
      bottle_sterilization:[
        {
          bottle_sterilization:this.fourMonthToTowYear.get('bottle_sterilization').value,
          bottle_sterilization_all:this.fourMonthToTowYear.get('bottle_sterilization_all').value,
          bottle_sterilization_all_time:this.fourMonthToTowYear.get('bottle_sterilization_all_time').value,
          bottle_sterilization_onetime:this.fourMonthToTowYear.get('bottle_sterilization_onetime').value,
          bottle_sterilization_onetime_time:this.fourMonthToTowYear.get('bottle_sterilization_onetime_time').value,
          bottle_sterilization_one_at_a_time:this.fourMonthToTowYear.get('bottle_sterilization_one_at_a_time').value,

        }
      ],
     
       clothes:[
        {
        changing_clothes:this.fourMonthToTowYear.get('changing_clothes').value,
        changing_colthes_spiecal_instructions:this.fourMonthToTowYear.get('changing_colthes_spiecal_instructions').value,
       }
      ],
      diaper:[{
        diaper_calculation:this.fourMonthToTowYear.get('diaper_calculation').value,
        diaper_change_frequency:this.fourMonthToTowYear.get('diaper_change_frequency').value,
        diaper_label:this.fourMonthToTowYear.get('diaper_label').value,
        diaper_label_end_time:this.fourMonthToTowYear.get('diaper_label_end_time').value,
        diaper_type:this.fourMonthToTowYear.get('diaper_type').value,
      }],
      
      breastfeeding:[
        {
         feeding_frequency_breast:this.fourMonthToTowYear.get('feeding_frequency_breast').value,
         breast_remark:this.fourMonthToTowYear.get('breast_remark').value,
         breast_feeding:this.fourMonthToTowYear.get('breast_feeding').value,
         feeding_breast:this.fourMonthToTowYear.get('feeding_breast').value,
        }
      ],
      formula_feeding:[
        {
         feeding_frequency:this.fourMonthToTowYear.get('feeding_frequency').value,
         formula_feeding_time:this.fourMonthToTowYear.get('formula_feeding_time').value,
          feeding_time:this.fourMonthToTowYear.get('feeding_time').value,
          feeding_amount_per:this.fourMonthToTowYear.get('feeding_amount_per').value,
          type_of_formula:this.fourMonthToTowYear.get('type_of_formula').value,
          formula_remark:this.fourMonthToTowYear.get('formula_remark').value,
             feeding_type: this.fourMonthToTowYear.get('feeding_type').value,
           formula_calculation:this.fourMonthToTowYear.get('formula_calculation').value,
        }
      ],
      prepared:[
        {
          feeding_prepared_mother:this.fourMonthToTowYear.get('feeding_prepared_mother').value,
          feeding_prepared_nanny:this.fourMonthToTowYear.get('feeding_prepared_nanny').value,
           feeding_prepared_other: this.fourMonthToTowYear.get('feeding_prepared_other').value,
        }
      ],
      sleeping:[{
        sleeping_time:this.fourMonthToTowYear.get('sleeping_time').value,
        nanny_time:this.fourMonthToTowYear.get('nanny_time').value,
      }],
      skin:[{
        skin_care:this.fourMonthToTowYear.get('skin_care').value,
      }],
      vitamin:[
        {
          vitamins_medications_json:this.fourMonthToTowYear.get('vitamins_medications_json').value,
        }
      ],
      wipes:[
        {
          skin_care_special_instructions:this.fourMonthToTowYear.get('skin_care_special_instructions').value,
          skin_care_Wipes:this.fourMonthToTowYear.get('skin_care_Wipes').value,
        }
      ],
      food:[{
        solid_food:this.fourMonthToTowYear.get('solid_food').value,
        lunch_food:this.fourMonthToTowYear.get('lunch_food').value,
        dinner_food:this.fourMonthToTowYear.get('dinner_food').value,
        solid_other_snack_time:this.fourMonthToTowYear.get('solid_other_snack_time').value,
        soild_other_snack_food_components:this.fourMonthToTowYear.get('soild_other_snack_food_components').value,
      }],
      allergy:[
        {
          food_medicine_allergy:this.fourMonthToTowYear.get('food_medicine_allergy').value,
          food_medicine_allergy_specify_prod:this.fourMonthToTowYear.get('food_medicine_allergy_specify_prod').value,
        }
      ],
      sleep_training:[
        {
          sleep_train:this.fourMonthToTowYear.get('sleep_train').value,
          walk_time:this.fourMonthToTowYear.get('walk_time').value,
          nap_time:this.fourMonthToTowYear.get('nap_time').value,
          sleep_time:this.fourMonthToTowYear.get('sleep_time').value,
          stroller:this.fourMonthToTowYear.get('stroller').value,
          interrupt:this.fourMonthToTowYear.get('interrupt').value,
          need_dummy:this.fourMonthToTowYear.get('need_dummy').value,
          pacifier:this.fourMonthToTowYear.get('pacifier').value,
          song_sleep:this.fourMonthToTowYear.get('song_sleep').value,
          reading_sleep:this.fourMonthToTowYear.get('reading_sleep').value,
        }
      ]

  };
    this.spinner.show()
    window.scroll(0, 0);
    if (fourMonthAbove.valid) {
      let data = JSON.parse(JSON.stringify(this.fourMonthToTowYear.value));

      data.vaccinations_json = JSON.stringify(data?.vaccinations_json);
      data.vitamins_medications_json = JSON.stringify(
        data?.vitamins_medications_json
      );
      data.sleep_training_json = JSON.stringify([
        data?.sleep_train,
        data?.walk_time,
        data?.sleep_time,
        data?.nap_time,
        data?.stroller,
        data?.interrupt,
        data?.need_dummy,
        data?.pacifier,
        data?.song_sleep,
        data?.reading_sleep,
      ]);
      data.baby_plan_id = 2;

      let timeArray = [
        'bottle_sterilization_onetime_time',
        'bottle_sterilization_all_time',
        'solid_other_snack_time',
        'regular_meal_time',
        'regular_meal_lunch_time',
        'regular_meal_dinner_time',
        'sleeping_mother_time',
        'sleeping_nanny_time',
        'feeding_breast_time',
        'feeding_time',
        'formula_feeding_time',
        'bathing_sponge_time',
        'bathing_tub_time',
        'bathing_shower_time',
        'walk_time',
        'nap_time',
        'sleep_time',
      ];
      // Filter out fields with no values
      let payload = {
        nanny_task_details_json: JSON.stringify(this.fourMonthToTowYear.value),
        baby_plan_id: 2,
        baby_details_id: this.fourMonthToTowYear.value.baby_details_id,
        structured_json: JSON.stringify(structuredObject),
      };
      // for (const key in data) {
      //   if (data[key] !== null && data[key] !== undefined && data[key] !== '') {
      //     payload[key] = data[key];
      //   }
      // }
      // for (let i = 0; i < timeArray.length; i++) {
      //   payload[timeArray[i]] = payload[timeArray[i]]
      //     ? moment(payload[timeArray[i]]).format('h:mm:ss a')
      //     : null;
      // }

     
      // delete data['sleep_train'];
      // delete data['walk_time'];
      // delete data['sleep_time'];
      // delete data['nap_time'];
      // delete data['stroller'];
      // delete data['interrupt'];
      // delete data['need_dummy'];
      // delete data['pacifier'];
      // delete data['song_sleep'];
      // delete data['reading_sleep'];
      if (this.isedit == true) {
        const id = localStorage.getItem('editedOrderId')

        // this.spinner.show();
        this.common_service.editNannyCarePlans(payload, id).then(
          (res: any) => {
            this.spinner.hide()

            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: res?.message,
            });
            setTimeout(() => {
              this.router.navigateByUrl('portal/first-screen')
            }, 1000);
            this.isedit = false;
            // this.spinner.hide();
          },
          (error: any) => {
            this.spinner.hide()

            // Handle any errors that occurred during the API call
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error?.message,
            });
            // this.spinner.hide();
          }
        );
      } else {
        // this.spinner.show();
        this.commonService
          .postBabyCarePlanDetail(payload)
          .then((res: any) => {
            this.spinner.hide()

            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: res?.message,
            });
            setTimeout(() => {
              this.softReload();
            }, 400);
            // this.spinner.hide();
            // fourMonthAbove.reset();
          })
          .catch((error: HttpErrorResponse) => {
            this.spinner.hide()

            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error?.message,
            });
          });
      }
    } else {
      this.spinner.hide()

      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Fill All the Details!',
      });
    }
    // this.fourMonthToTowYear.reset();
  }

  isset(variable) {
    if (variable != 'undefined' && variable != null && variable != '') {
      return true;
    }
    else {
      return false;
    }
  }

  getFormulaCalculationControls(): FormArray {
    return this.fourMonthToTowYear.get('formula_calculation') as FormArray;
  }

  // get bottle_sterilization array 
  getbottle_sterilizationControls(): FormArray {
    return this.fourMonthToTowYear.get('bottle_sterilization') as FormArray;
  }

  // clear feeding array 
  clearFormulaCalculation() {
    (this.fourMonthToTowYear.get('formula_calculation') as FormArray).clear();
  }

  // clear Bottle Sterilization array 
  clearbottle_sterilizationControls() {
    (this.fourMonthToTowYear.get('bottle_sterilization') as FormArray).clear();
  }

  // add new object in feeding array 
  addFeedingCalculation(time: any): void {
    const feedingCalculationArray = this.getFormulaCalculationControls();

    const newFeedingCalculationGroup = this.FB.group({
      formula_timeArray: new FormControl(time),
      nurse_feeding: new FormControl(),
      remark: new FormControl(null),
      amount: new FormControl(null),
      formula_remark: new FormControl(null),
      feeding_checkbox: new FormControl(null)
    });
    feedingCalculationArray.push(newFeedingCalculationGroup);
  }

  // add new object in Bottle Sterilization array 
  addbottle_sterilization(time: any): void {
    const bottle_sterilizationArray = this.getbottle_sterilizationControls();

    const newbottle_sterilizationGroup = this.FB.group({
      done_sterilization: new FormControl(null),
      sterilization_timeArray: new FormControl(time),
      actual_sterilization_timeArray: new FormControl(null),
      sterilization_remark: new FormControl(null),

    });
    bottle_sterilizationArray.push(newbottle_sterilizationGroup);
  }

  // auto generate feeding time and remark fields 
  createfields() {
    if (!this.isSettingValueProgrammatically) {

      let start = new Date(this.fourMonthToTowYear.controls.feeding_time.value);
      let end = new Date(this.fourMonthToTowYear.controls.formula_feeding_time.value);
      let frequency = this.fourMonthToTowYear.controls.feeding_frequency.value;
      let setbottle_sterilization = new Date(this.fourMonthToTowYear.controls.feeding_time.value);


      if (this.isset(this.fourMonthToTowYear.controls.feeding_time.value)
        && this.isset(this.fourMonthToTowYear.controls.formula_feeding_time.value) &&
        this.isset(frequency)) {

        let array = this.getFormulaCalculationControls();
        let updated = start;
        this.isFormula = true;
        this.clearFormulaCalculation();
        this.clearbottle_sterilizationControls();

        if (start > end) {
          end.setDate(end.getDate() + 1);
        }

        while (updated < end) {
          if (updated < end) {
            // let time = updated.getHours()+':'+updated.getMinutes();

            let time = new Date();

            time.setHours(updated.getHours())
            time.setMinutes(updated.getMinutes())

            let sterilization_time = new Date();

            sterilization_time.setHours(updated.getHours() - 2)
            sterilization_time.setMinutes(updated.getMinutes())

            this.addFeedingCalculation(time);
            this.addbottle_sterilization(sterilization_time);
          }
          updated.setHours(updated.getHours() + frequency);
        }
      }
    }
  }
  getmedicinearray(index) {
    return (this.fourMonthToTowYear.get('vitamins_medications_json') as FormArray).at(index).get('medicine_time') as FormArray;
  }
  getVitaminFormArray() {
    return this.fourMonthToTowYear.get(
      'vitamins_medications_json'
    ) as FormArray;
  }
  getVaccinationFormArray() {
    return this.fourMonthToTowYear.get('vaccinations_json') as FormArray;
  }
  get getsleeptrainFormArray() {
    // return this.fourMonthToTowYear.get('sleep_training_json') as FormArray;
    return this.fourMonthToTowYear.get('sleep_training_json') as FormArray;
  }
  addfield() {

    if (!this.isSettingValueProgrammatically) {
      let frequency = this.fourMonthToTowYear.controls.bathing_frequency_sponge.value;


      if (this.isset(frequency)) {

        let array = this.getbathingarray();
        this.isBathing = true;
        this.clearbathingcalculation();

        for (let i = 0; i < frequency; i++) {

          // Create a new Date object representing the current time


          this.addbathingcalculation();
        }
      }
    }
  }
  getbathingtubarray() {
    return this.fourMonthToTowYear.get('tub_bathing') as FormArray;
  }
  clearbathingtubcalculation() {
    (
      this.fourMonthToTowYear.get('tub_bathing') as FormArray
    ).clear();
  }
  addbathingtubcalculation(): void {
    const bathingcalculationtub = this.getbathingtubarray();
    const bathingcalculationgroup1 = this.FB.group({
      bathing_tub_time: new FormControl(),
      tub_checkbox: new FormControl(null),
      tub_remark: new FormControl(null)
    });
    bathingcalculationtub.push(bathingcalculationgroup1);
  }
  addtubfield() {

    if (!this.isSettingValueProgrammatically) {
      let frequency = this.fourMonthToTowYear.controls.bathing_tub_frequency.value;


      if (this.isset(frequency)) {

        let array = this.getbathingtubarray();
        this.isBathingtub = true;
        this.clearbathingtubcalculation();

        for (let i = 0; i < frequency; i++) {
          this.addbathingtubcalculation();
        }
      }
    }
  }
  getshowertubarray() {
    return this.fourMonthToTowYear.get('shower_bathing') as FormArray;
  }
  clearshowercalculation() {
    (
      this.fourMonthToTowYear.get('shower_bathing') as FormArray
    ).clear();
  }
  addshowercalculation(): void {
    const bathingcalculationtub = this.getshowertubarray();
    const bathingcalculationgroup1 = this.FB.group({
      bathing_shower_time: new FormControl(),
      shower_checkbox: new FormControl(null),
      shower_remark: new FormControl(null)
    });
    bathingcalculationtub.push(bathingcalculationgroup1);
  }
  addshowerfield() {

    if (!this.isSettingValueProgrammatically) {
      let frequency = this.fourMonthToTowYear.controls.bathing_shower_frequency.value;


      if (this.isset(frequency)) {
        let array = this.getshowertubarray();
        this.isBathingshower = true;
        this.clearshowercalculation();

        for (let i = 0; i < frequency; i++) {
          this.addshowercalculation();
        }
      }
    }
  }
  getFrequency(index: number) {
    let vitaminFormArray = this.getVitaminFormArray();
    if (vitaminFormArray && vitaminFormArray.controls.length > index) {
      let vitaminscontrols = vitaminFormArray.controls[index] as FormGroup;
      let frequency = vitaminscontrols.get('frequency').value;

      return frequency;
    }
    return null; // or any default value if needed
  }
  addmedicinefield(index: number) {

    if (!this.isSettingValueProgrammatically) {
      let frequency = this.getFrequency(index); // Replace 0 with the desired index

      if (this.isset(frequency)) {

        // let array = this.getmedicinearray();
        this.ismedicine = true;
        this.clearmedicinecalculation(index);

        for (let i = 0; i < frequency; i++) {
          this.addmedicationcalculation(index);
        }
      }
    }
  }
  getbreastarray() {
    return this.fourMonthToTowYear.get('breast_feeding') as FormArray;
  }
  clearbreastcalculation() {
    (this.fourMonthToTowYear.get('breast_feeding') as FormArray).clear();
  }
  addbreastcalculation(): void {
    const breastcalculation = this.getbreastarray();
    const breastcalculationgroup = this.FB.group({
      feeding_breast_time: new FormControl(),
      feeding_breast_checkbox: new FormControl(null),
      breast_feeding_remark: new FormControl()
    });

    breastcalculation.push(breastcalculationgroup);
  }
  addbreastfield() {

    if (!this.isSettingValueProgrammatically) {
      let frequency =
        this.fourMonthToTowYear.controls.feeding_frequency_breast.value;


      if (this.isset(frequency)) {
    

        let array = this.getbreastarray();
        this.isBreast = true;
        this.clearbreastcalculation();

        for (let i = 0; i < frequency; i++) {

          this.addbreastcalculation();
        }
      }
    }
  }
  getClothesFormArray() {
    return this.fourMonthToTowYear.get('changing_clothes') as FormArray;
  }
  addClothesObject(string: any, index: any) {
    let ClothesArray = this.getClothesFormArray();
    if (string == 'add') {
      let newclothesArray = new FormGroup({
        changing_colthes: new FormControl(''),
        Changing_time: new FormControl(''),
        clothe_remark: new FormControl('')
      });
      ClothesArray.insert(index + 1, newclothesArray);
    } else {
      ClothesArray.removeAt(index);
    }
  }
  addsleeptimeObject(string: any, index: any) {
    let vitaminArray = this.getSleepArray();
    if (string == 'add') {
      let newVitaminArray = new FormGroup({
        sleeping_mother: new FormControl(''),
        sleeping_mother_time: new FormControl(''),
      });

      // let formatedTime =  moment(newTime).format('HH:mm:ss');
      vitaminArray.insert(index + 1, newVitaminArray);
    } else {
      vitaminArray.removeAt(index);
    }
  }
  addnannyObject(string: any, index: any) {
    let vitaminArray = this.getSleepnannyArray();
    if (string == 'add') {
      let newVitaminArray = new FormGroup({
        sleeping_nanny: new FormControl(''),
        sleeping_nanny_time: new FormControl(''),
      });

      // let formatedTime =  moment(newTime).format('HH:mm:ss');
      vitaminArray.insert(index + 1, newVitaminArray);
    } else {
      vitaminArray.removeAt(index);
    }
  }
  getSleepArray(): FormArray {
    return this.fourMonthToTowYear.get('sleeping_time') as FormArray;
  }
  getSleepnannyArray(): FormArray {
    return this.fourMonthToTowYear.get('nanny_time') as FormArray;
  }
  logout() {
    localStorage.clear();
    // window.location.reload();
    this.router.navigate(['/']);
  }
  cleardiaperCalculation() {
    (this.fourMonthToTowYear.get('diaper_calculation') as FormArray).clear();
  }
  getdiaperCalculationControls(): FormArray {
    return this.fourMonthToTowYear.get('diaper_calculation') as FormArray;
  }
  adddiaperCalculation(time: any): void {
    const feedingCalculationArray = this.getdiaperCalculationControls();

    const newFeedingCalculationGroup = this.FB.group({
      diaper_timeArray: new FormControl(time),
      diaper_actual_time: new FormControl(),
      remark: new FormControl(null),
      amount: new FormControl(null),
      diaperRemark: new FormControl(null),
      diaper_checkbox: new FormControl(null)
    
    });
    feedingCalculationArray.push(newFeedingCalculationGroup);
  }
  creatediaperfields() {
    if (!this.isSettingValueProgrammatically) {

      let start = new Date(this.fourMonthToTowYear.controls.diaper_label.value);
      let end = new Date(this.fourMonthToTowYear.controls.diaper_label_end_time.value);
      let frequency = this.fourMonthToTowYear.controls.diaper_change_frequency.value;
      // let setbottle_sterilization = new Date(this.fourMonthToTowYear.controls.diaper_label.value);



      if (this.isset(this.fourMonthToTowYear.controls.diaper_label.value)
        && this.isset(this.fourMonthToTowYear.controls.diaper_change_frequency.value) &&
        this.isset(frequency)) {
     
        let array = this.getdiaperCalculationControls();
        let updated = start;
        this.isdiaper = true;
        this.cleardiaperCalculation();
        // this.clearbottle_sterilizationControls();

        if (start > end) {
          end.setDate(end.getDate() + 1);
        }

        while (updated < end) {
          if (updated < end) {
            // let time = updated.getHours()+':'+updated.getMinutes();

            let time = new Date();

            time.setHours(updated.getHours())
            time.setMinutes(updated.getMinutes())

            // let sterilization_time = new Date();

            // sterilization_time.setHours(updated.getHours() - 2)
            // sterilization_time.setMinutes(updated.getMinutes())

            this.adddiaperCalculation(time);
            // this.addbottle_sterilization(sterilization_time);
          }
          updated.setHours(updated.getHours() + frequency);
        }
      }
    }
  }
}
