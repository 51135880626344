<div class="main-content d-flex flex-column">
    <p-toast></p-toast>
  
    <app-dashboard-navbar></app-dashboard-navbar>
  
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
      <h1>{{ Content.title }}</h1>
      <ol class="breadcrumb">
        <li class="item"><a routerLink="/">Home</a></li>
        <li class="item">
          <a routerLink="dashboard">{{ Content.subTitle }}</a>
        </li>
        <li class="item">{{ Content.title }}</li>
      </ol>
    </div>
    <form 
      #submitImmunizationForm="ngForm">
        <div class="add-listings-box" [formGroup]="immunization">
                    <!-- <h3>heading here</h3> -->
                    <label class="subHeading mb-3"></label>
            <div class="row">
                <div class="col-12 col-xxxl-6">
                    <div class="form-group table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr style="white-space: nowrap">
                                    <th scope="col" class="text-center" rowspan="2">
                                        <label class="subHeading px-0">Time</label>
                                    </th>
                                    <th scope="col" class="text-center" rowspan="2">
                                        <label class="subHeading px-0">vaccine</label>
                                    </th>
                                    <th scope="col" class="text-center" rowspan="2">
                                        <label class="subHeading px-0">Brand</label>
                                    </th>
                                    <th scope="col" class="text-center" colspan="2">
                                        <label class="subHeading px-0">Date</label>
                                    </th>
                                    <th scope="col" class="text-center weight" rowspan="2">
                                        <label class="subHeading px-0">Weight (K.g)</label>
                                    </th>
                                    <th scope="col" class="text-center" rowspan="2">
                                        <label class="subHeading px-0">Name of HealthStaff</label>
                                    </th>
                                    <th scope="col" class="text-center" rowspan="2">
                                        <label class="subHeading px-0">Batch No. of Vaccine</label>
                                    </th>
                                </tr>
                                <tr>
                                    <th class="text-center">Due on</th>
                                    <th class="text-center">Given on</th>
                                </tr>
                            </thead>
                            <tbody>
                            <!-- NECK ROW -->
                            <!-- Flexion -->
                            <tr>
                                <td scope="row" rowspan="2" class="text-verticle"><label class="px-0"><br> Birth</label></td>
                                <td class="blue">BCG</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="bcg_brand" class="form-control" placeholder="BCG Brand"/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy"  [readonlyInput]="true" [showIcon]="true"  placeholder="BCG Dose" formControlName="bcg_dose_date"
                                             inputId="bcg_dose_date"
                                             [disabled]="true">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="dd/mm/yy" formControlName="bcg_dose_given_date"
                                             inputId="bcg_dose_given_date">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="bcg_weight" class="form-control" placeholder="Weight"/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="bcg_staff" class="form-control" placeholder="Name of health staff"/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="bcg_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="green">Hepatities B(1<sup>st</sup>Dose)</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="HepatitiesB_1stdose_brand" class="form-control" placeholder="Hepatities Brand"/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="Hepatities B 1st Dose" formControlName="HepatitiesB_1stdose_date"
                                             inputId="HepatitiesB_1stdose_date" [readonlyInput]="true" [disabled]="true">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="dd/mm/yy" formControlName="HepatitiesB_1stdose_given_date"
                                             inputId="HepatitiesB_1stdose_given_date">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="HepatitiesB_1stdose_weight" class="form-control" placeholder="Weight"/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="HepatitiesB_1stdose_staff" class="form-control" placeholder="Name of health staff."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="HepatitiesB_1stdose_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>
                            <!-- 2 months  -->
                            <tr>
                                <td scope="row" rowspan="3" class="text-verticle"><label class="px-0">2 Months</label></td>
                                <td class="blue">Hexavalent Vaccine <br> DTaP-IPV-Hib(1<sup>st</sup>Dose) <br> Hepatitis B (2<sup>nd</sup>Dose)</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="Hexavalent_1st_brand" class="form-control" placeholder="Hexavalent Brand"/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="Hexavalent Vaccine
                                            DTaP-IPV-Hib(1stDose) Hepatitis B (2ndDose)"
                                            formControlName="HepatitiesB_2nd_dose_date"
                                            inputId="HepatitiesB_2nd_dose_date" [readonlyInput]="true" [disabled]="true">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="dd/mm/yy"
                                            formControlName="HepatitiesB_2nd_dose_given_date"
                                            inputId="HepatitiesB_2nd_dose_given_date">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="Hexavalent_1st_weight" class="form-control" placeholder="Weight"/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="Hexavalent_1st_staff" class="form-control" placeholder="Name of health staff"/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="Hexavalent_1st_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="green">PCV (1<sup>st</sup>Dose)</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="pcv_1stdose_brand" class="form-control" placeholder="PCV Brand."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="dd/mm/yy" formControlName="pcv_1stdose_date"
                                             inputId="pcv_1stdose_date" [readonlyInput]="true" [disabled]="true">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="dd/mm/yy" formControlName="pcv_1stdose_given_date"
                                             inputId="pcv_1stdose_given_date">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="pcv_1stdose_weight" class="form-control" placeholder="Weight."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="pcv_1stdose_staff" class="form-control" placeholder="Name of health staff."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="pcv_1stdose_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="blue">Rotavirus</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="rotavirus_1stdose_brand" class="form-control" placeholder="Rotavirus Brand."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="dd/mm/yy" formControlName="rotavirus_1stdose_date"
                                             inputId="rotavirus_1stdose_date" [readonlyInput]="true" [disabled]="true">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="dd/mm/yy" formControlName="rotavirus_1stdose_given_date"
                                             inputId="rotavirus_1stdose_given_date">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="rotavirus_1stdose_weight" class="form-control" placeholder="Weight."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="rotavirus_1stdose_staff" class="form-control" placeholder="Name of health staff."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="rotavirus_1stdose_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>
                            <!-- 4 months  -->
                            <tr>
                                <td scope="row" rowspan="4" class="text-verticle"><label class="px-0">4 Months</label></td>
                                <td class="green">Hexavalent Vaccine <br> DTaP-IPV-Hib(2<sup>nd</sup>Dose) <br> Hepatitis B (3<sup>rd</sup>Dose)</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="HepatitiesB_3rd_dose_brand" class="form-control" placeholder="Hexavalent Vaccine Brand."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="Hexavalent Vaccine
                                            DTaP-IPV-Hib(2nd Dose) Hepatitis B (3rd Dose)"
                                            formControlName="HepatitiesB_3rd_dose_date"
                                            inputId="HepatitiesB_3rd_dose_date" [readonlyInput]="true" [disabled]="true">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="Hexavalent Vaccine
                                            DTaP-IPV-Hib(2nd Dose) Hepatitis B (3rd Dose)"
                                            formControlName="HepatitiesB_3rd_dose_given_date"
                                            inputId="HepatitiesB_3rd_dose_given_date" >
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="HepatitiesB_3rd_dose_weight" class="form-control" placeholder="Weight."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="HepatitiesB_3rd_dose_staff" class="form-control" placeholder="Name of health staff."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="HepatitiesB_3rd_dose_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="blue">OPV</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="opv_1st_dose_brand" class="form-control" placeholder="PCV Brand."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="OPV (1st Dose)"
                                            formControlName="opv_1st_dose_date"
                                            inputId="opv_1st_dose_date" [readonlyInput]="true" [disabled]="true">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="OPV (1st Dose)"
                                            formControlName="opv_1st_dose_given_date"
                                            inputId="opv_1st_dose_given_date">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="opv_1st_dose_weight" class="form-control" placeholder="Weight."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="opv_1st_dose_staff" class="form-control" placeholder="Name of health staff."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="opv_1st_dose_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="green">PCV (2<sup>nd</sup>Dose)</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="pcv_2nd_dose_brand" class="form-control" placeholder="Brand."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="PCV (2nd Dose)"
                                            formControlName="pcv_2nd_dose_date"
                                            inputId="pcv_2nd_dose_date"  [readonlyInput]="true" [disabled]="true">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="PCV (2nd Dose)"
                                            formControlName="pcv_2nd_dose_given_date"
                                            inputId="pcv_2nd_dose_given_date">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="pcv_2nd_dose_weight" class="form-control" placeholder="Weight."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="pcv_2nd_dose_staff" class="form-control" placeholder="Name of health staff."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="pcv_2nd_dose_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="blue">Rotavirus</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="pcv_1stdose_brand" class="form-control" placeholder="Brand."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="Rotavirus 2nd Dose" formControlName="rotavirus_2nd_dose_date"
                                             inputId="rotavirus_2nd_dose_date" [readonlyInput]="true" [disabled]="true">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="Rotavirus 2nd Dose" formControlName="rotavirus_2nd_dose_given_date"
                                             inputId="rotavirus_2nd_dose_given_date">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="pcv_1stdose_weight" class="form-control" placeholder="Weight."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="pcv_1stdose_staff" class="form-control" placeholder="Name of health staff."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="pcv_1stdose_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>

                            <!-- 6 months  -->
                            <tr>
                                <td scope="row" rowspan="4" class="text-verticle"><label class="px-0">6 Months</label></td>
                                <td class="green">Pentavalent Vaccine <br> DTaP-IPV-Hib(2<sup>nd</sup>Dose) <br> Hepatitis B (4<sup>th</sup>Dose)</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="HepatitiesB_4th_dose_brand" class="form-control" placeholder="Brand."/>
                                    </div>
                                </td> 
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="Hexavalent Vaccine
                                            DTaP-IPV-Hib(2nd Dose) Hepatitis B (4th Dose)"
                                            formControlName="HepatitiesB_4th_dose_date"
                                            inputId="HepatitiesB_4th_dose_date" [readonlyInput]="true" [disabled]="true">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="Hexavalent Vaccine
                                            DTaP-IPV-Hib(2nd Dose) Hepatitis B (4th Dose)"
                                            formControlName="HepatitiesB_4th_dose_given_date"
                                            inputId="HepatitiesB_4th_dose_given_date">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="HepatitiesB_4th_dose_weight" class="form-control" placeholder="Weight."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="HepatitiesB_4th_dose_staff" class="form-control" placeholder="Name of health staff."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="HepatitiesB_4th_dose_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="blue">Rotateq X3</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="rotateqx3_dose_brand" class="form-control" placeholder="Brand."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="Rotateq X3" formControlName="rotateqx3_dose_date"
                                             inputId="rotateqx3_dose_date" [readonlyInput]="true" [disabled]="true">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="Rotateq X3" formControlName="rotateqx3_dose_given_date"
                                             inputId="rotateqx3_dose_given_date">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="rotateqx3_dose_weight" class="form-control" placeholder="Weight."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="rotateqx3_dose_staff" class="form-control" placeholder="Name of health staff."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="rotateqx3_dose_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="green">OPV</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="opv_2nd_dose_brand" class="form-control" placeholder="Brand."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="OPV (2nd Dose)"
                                            formControlName="opv_2nd_dose_date"
                                            inputId="opv_2nd_dose_date" [readonlyInput]="true" [disabled]="true">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="OPV (2nd Dose)"
                                            formControlName="opv_2nd_dose_given_date"
                                            inputId="opv_2nd_dose_given_date">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                    <input type="number" formControlName="opv_2nd_dose_weight" class="form-control" placeholder="Weight."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="opv_2nd_dose_staff" class="form-control" placeholder="Name of health staff."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="opv_2nd_dose_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="blue">PCV (3<sup>rd</sup>Dose)</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="pcv_3rd_dose_brand" class="form-control" placeholder="Brand."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="PCV (3rd Dose)"
                                            formControlName="pcv_3rd_dose_date"
                                            inputId="pcv_3rd_dose_date" [readonlyInput]="true" [disabled]="true">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="PCV (3rd Dose)"
                                            formControlName="pcv_3rd_dose_given_date"
                                            inputId="pcv_3rd_dose_given_date">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="pcv_3rd_dose_weight" class="form-control" placeholder="Weight."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="pcv_3rd_dose_staff" class="form-control" placeholder="Name of health staff."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="pcv_3rd_dose_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>

                            <!-- 12 month  -->
                            <tr>
                                <td scope="row" rowspan="2" class="text-verticle"><label class="px-0">12<br>Months</label></td>
                                <td class="green">MMR (1<sup>st</sup>Dose)</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="mmr1st_dose_brand" class="form-control" placeholder="Brand."/>
                                    </div>
                                </td> 
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="MMR (1st Dose)"
                                            formControlName="mmr1st_dose_date"
                                            inputId="mmr1st_dose_date" [readonlyInput]="true" [disabled]="true">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="MMR (1st Dose)"
                                            formControlName="mmr1st_dose_given_date"
                                            inputId="mmr1st_dose_given_date">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="mmr1st_dose_weight" class="form-control" placeholder="Weight."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="mmr1st_dose_staff" class="form-control" placeholder="Name of health staff."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="mmr1st_dose_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="blue">Chicken Pox (1<sup>st</sup>Dose)</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="chickenPox_1st_dose_brand" class="form-control" placeholder="Brand."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="Chicken Pox (1st Dose)"
                                            formControlName="chickenPox_1st_dose_date"
                                            inputId="chickenPox_1st_dose_date" [readonlyInput]="true" [disabled]="true">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="Chicken Pox (1st Dose)"
                                            formControlName="chickenPox_1st_dose_given_date"
                                            inputId="chickenPox_1st_dose_given_date">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="chickenPox_1st_dose_weight" class="form-control" placeholder="Weight."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="chickenPox_1st_dose_staff" class="form-control" placeholder="Name of health staff."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="chickenPox_1st_dose_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>

                            <!-- 15 month  -->
                            <tr>
                                <td scope="row" class="text-verticle"><label class="px-0">15<br>Months</label></td>
                                <td class="green">PCV (4<sup>th</sup>Dose)</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="pcv_4th_dose_brand" class="form-control" placeholder="Brand."/>
                                    </div>
                                </td> 
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="PCV (4th Dose)"
                                            formControlName="pcv_4th_dose_date"
                                            inputId="pcv_4th_dose_date" [readonlyInput]="true" [disabled]="true">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="PCV (4th Dose)"
                                            formControlName="pcv_4th_dose_given_date"
                                            inputId="pcv_4th_dose_given_date">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="pcv_4th_dose_weight" class="form-control" placeholder="Weight."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="pcv_4th_dose_staff" class="form-control" placeholder="Name of health staff."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="pcv_4th_dose_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>

                            <!-- 18 month  -->
                            <tr>
                                <td scope="row" rowspan="3" class="text-verticle"><label class="px-0">18<br>Months</label></td>
                                <td class="blue">Tetravalent Vaccine DPT-Hib</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="tetravalent_dpt_hiv_dose_brand" class="form-control" placeholder="Brand."/>
                                    </div>
                                </td> 
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="Tetravalent Vaccine DPT-Hib Dose"
                                            formControlName="tetravalent_dpt_hiv_dose_date"
                                            inputId="tetravalent_dpt_hiv_dose_date" [readonlyInput]="true" [disabled]="true">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="Tetravalent Vaccine DPT-Hib Dose"
                                            formControlName="tetravalent_dpt_hiv_dose_given_date"
                                            inputId="tetravalent_dpt_hiv_dose_given_date">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="tetravalent_dpt_hiv_dose_weight" class="form-control" placeholder="Weight."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="tetravalent_dpt_hiv_dose_staff" class="form-control" placeholder="Name of health staff."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="tetravalent_dpt_hiv_dose_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="green">OPV</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="opv_4th_dose_brand" class="form-control" placeholder="Brand."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="card flex justify-content-center">
                                        <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="OPV (4th Dose)"
                                        formControlName="opv_4th_dose_date"
                                        inputId="opv_4th_dose_date" [readonlyInput]="true" [disabled]="true">
                                        </p-calendar>
                                    </div>
                                </td>
                                <td>
                                    <div class="card flex justify-content-center">
                                        <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="OPV (4th Dose)"
                                        formControlName="opv_4th_dose_given_date"
                                        inputId="opv_4th_dose_given_date">
                                        </p-calendar>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="opv_4th_dose_weight" class="form-control" placeholder="Weight."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="opv_4th_dose_staff" class="form-control" placeholder="Name of health staff."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="opv_4th_dose_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="blue">MMR</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="mmr2nd_dose_brand" class="form-control" placeholder="Brand."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="MMR (2nd Dose)"
                                            formControlName="mmr2nd_dose_date"
                                            inputId="mmr2nd_dose_date" [readonlyInput]="true" [disabled]="true">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="MMR (2nd Dose)"
                                            formControlName="mmr2nd_dose_given_date"
                                            inputId="mmr2nd_dose_given_date">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="mmr2nd_dose_weight" class="form-control" placeholder="Weight."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="mmr2nd_dose_staff" class="form-control" placeholder="Name of health staff."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="mmr2nd_dose_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>

                            <!-- 5-6 Years -->
                            <tr>
                                <td scope="row" rowspan="3" class="text-verticle"><label class="px-0">5-6 Years</label></td>
                                <td class="green">DPT</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="dpt_dose_brand" class="form-control" placeholder="Brand."/>
                                    </div>
                                </td> 
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="DPT Dose"
                                            formControlName="dpt_dose_date"
                                            inputId="dpt_dose_date" [readonlyInput]="true" [disabled]="true">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="DPT Dose"
                                            formControlName="dpt_dose_given_date"
                                            inputId="dpt_dose_given_date">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="dpt_dose_weight" class="form-control" placeholder="Weight."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="dpt_dose_staff" class="form-control" placeholder="Name of health staff."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="dpt_dose_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="blue">OPV</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="opv_3rd_dose_brand" class="form-control" placeholder="Brand."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="OPV (3rd Dose)"
                                            formControlName="opv_3rd_dose_date"
                                            inputId="opv_3rd_dose_date" [readonlyInput]="true" [disabled]="true">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="OPV (3rd Dose)"
                                            formControlName="opv_3rd_dose_given_date"
                                            inputId="opv_3rd_dose_given_date">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="opv_3rd_dose_weight" class="form-control" placeholder="Weight."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="opv_3rd_dose_staff" class="form-control" placeholder="Name of health staff."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="opv_3rd_dose_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="green">Chicken Pox-2</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="chickenPox_2nd_dose_brand" class="form-control" placeholder="Brand."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="Chicken Pox (2nd Dose)"
                                            formControlName="chickenPox_2nd_dose_date"
                                            inputId="chickenPox_2nd_dose_date" [readonlyInput]="true" [disabled]="true">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <div class="card flex justify-content-center">
                                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="Chicken Pox (2nd Dose)"
                                            formControlName="chickenPox_2nd_dose_given_date"
                                            inputId="chickenPox_2nd_dose_given_date">
                                            </p-calendar>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="chickenPox_2nd_dose_weight" class="form-control" placeholder="Weight."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="chickenPox_2nd_dose_staff" class="form-control" placeholder="Name of health staff."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="chickenPox_2nd_dose_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </div>

                <!-- 2nd table  -->
                <div class="col-12 col-xxxl-6">
                    <div class="form-group table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr style="white-space: nowrap">
                                    <th scope="col" class="text-center" rowspan="2">
                                        <label class="subHeading px-0">Time</label>
                                    </th>
                                    <th scope="col" class="text-center" rowspan="2">
                                        <label class="subHeading px-0">vaccine</label>
                                    </th>
                                    <th scope="col" class="text-center" rowspan="2">
                                        <label class="subHeading px-0">Brand</label>
                                    </th>
                                    <th scope="col" class="text-center" colspan="2">
                                        <label class="subHeading px-0">Date</label>
                                    </th>
                                    <th scope="col" class="text-center weight" rowspan="2">
                                        <label class="subHeading px-0">Weight (K.g)</label>
                                    </th>
                                    <th scope="col" class="text-center" rowspan="2">
                                        <label class="subHeading px-0">Name of HealthStaff</label>
                                    </th>
                                    <th scope="col" class="text-center" rowspan="2">
                                        <label class="subHeading px-0">Batch No. of Vaccine</label>
                                    </th>
                                </tr>
                                <tr>
                                    <th class="text-center">Due on</th>
                                    <th class="text-center">Given on</th>
                                </tr>
                            </thead>
                            <tbody>
                            <!-- 6 months  -->
                            <tr>
                                <td scope="row" class="text-verticle"><label class="px-0">> 6<br>Months</label></td>
                                <td class="blue">Influenza Vaccine</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="influenza_dose_brand" class="form-control" placeholder="Brand."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="card flex justify-content-center">
                                        <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="Influenza Dose"
                                        formControlName="influenza_dose_date"
                                        inputId="influenza_dose_date" [readonlyInput]="true" [disabled]="true">
                                        </p-calendar>
                                    </div>
                                </td>
                                <td>
                                    <div class="card flex justify-content-center">
                                        <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="Influenza Dose"
                                        formControlName="influenza_dose_given_date"
                                        inputId="influenza_dose_given_date">
                                        </p-calendar>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="influenza_dose_weight" class="form-control" placeholder="Weight."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="influenza_dose_staff" class="form-control" placeholder="Name of health staff."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="influenza_dose_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>
                            <!-- > 1 years  -->
                            <tr>
                                <td scope="row" rowspan="2" class="text-verticle"><label class="px-0">> 1<br>Years</label></td>
                                <td class="green">Hepatities A Vaccine (1<sup>st</sup>Dose)</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="HepatitiesA_1st_dose_brand" class="form-control" placeholder="Brand."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="card flex justify-content-center">
                                        <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="Hepatities A "
                                        formControlName="HepatitiesA_1st_dose_date"
                                        inputId="HepatitiesA_1st_dose_date" [readonlyInput]="true" [disabled]="true">
                                        </p-calendar>
                                    </div>
                                </td>
                                <td>
                                    <div class="card flex justify-content-center">
                                        <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="Hepatities A "
                                        formControlName="HepatitiesA_1st_dose_given_date"
                                        inputId="HepatitiesA_1st_dose_given_date">
                                        </p-calendar>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="HepatitiesA_1st_dose_weight" class="form-control" placeholder="Weight."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="HepatitiesA_1st_dose_staff" class="form-control" placeholder="Name of health staff."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="HepatitiesA_1st_dose_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="blue">(2<sup>nd</sup>Dose)</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="HepatitiesA_2nd_dose_brand" class="form-control" placeholder="Brand."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="card flex justify-content-center">
                                        <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="Hepatities A "
                                        formControlName="HepatitiesA_2nd_dose_date"
                                        inputId="HepatitiesA_2nd_dose_date" [readonlyInput]="true" [disabled]="true">
                                        </p-calendar>
                                    </div>
                                </td>
                                <td>
                                    <div class="card flex justify-content-center">
                                        <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="Hepatities A "
                                        formControlName="HepatitiesA_2nd_dose_given_date"
                                        inputId="HepatitiesA_2nd_dose_given_date">
                                        </p-calendar>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="HepatitiesA_2nd_dose_weight" class="form-control" placeholder="Weight."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="HepatitiesA_2nd_dose_staff" class="form-control" placeholder="Name of health staff."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="HepatitiesA_2nd_dose_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>
                            <!-- > 1 years   -->
                            <tr>
                                <td scope="row" class="text-verticle"><label class="px-0">> 1<br>Years</label></td>
                                <td class="green">Meningococcal Vaccine</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="meningococcal_dose_brand" class="form-control" placeholder="Brand."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="card flex justify-content-center">
                                        <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="Meningococcal"
                                        formControlName="meningococcal_dose_date"
                                        inputId="meningococcal_dose_date" [readonlyInput]="true" [disabled]="true">
                                        </p-calendar>
                                    </div>
                                </td>
                                <td>
                                    <div class="card flex justify-content-center">
                                        <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="Meningococcal"
                                        formControlName="meningococcal_dose_given_date"
                                        inputId="meningococcal_dose_given_date">
                                        </p-calendar>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="meningococcal_dose_weight" class="form-control" placeholder="Weight."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="meningococcal_dose_staff" class="form-control" placeholder="Name of health staff."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="meningococcal_dose_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>

                            <!-- > 2 years  -->
                            <tr>
                                <td scope="row" class="text-verticle"><label class="px-0"> > 2<br>Years</label></td>
                                <td class="blue">Typhoid Vaccine</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="typhoid_dose_brand" class="form-control" placeholder="Brand."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="card flex justify-content-center">
                                        <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="Typhoid Vaccine"
                                        formControlName="typhoid_dose_date"
                                        inputId="typhoid_dose_date" [readonlyInput]="true" [disabled]="true">
                                        </p-calendar>
                                    </div>
                                </td>
                                <td>
                                    <div class="card flex justify-content-center">
                                        <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="Typhoid Vaccine"
                                        formControlName="typhoid_dose_given_date"
                                        inputId="typhoid_dose_given_date">
                                        </p-calendar>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="typhoid_dose_weight" class="form-control" placeholder="Weight."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="typhoid_dose_staff" class="form-control" placeholder="Name of health staff."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="typhoid_dose_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>

                            <!-- 11 -12 Years  -->
                            <tr>
                                <td scope="row" rowspan="3" class="text-verticle"><label class="px-0"> > 11-12 <br> Years</label></td>
                                <td class="green">HPV <br>(1<sup>st</sup>Dose)</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="hpv_1st_dose_brand" class="form-control" placeholder="Brand."/>
                                    </div>
                                </td> 
                                <td>
                                    <div class="card flex justify-content-center">
                                        <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="HPV 1st Dose"
                                        formControlName="hpv_1st_dose_date"
                                        inputId="hpv_1st_dose_date" [readonlyInput]="true" [disabled]="true">
                                        </p-calendar>
                                    </div>
                                </td>
                                <td>
                                    <div class="card flex justify-content-center">
                                        <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="HPV 1st Dose"
                                        formControlName="hpv_1st_dose_given_date"
                                        inputId="hpv_1st_dose_given_date">
                                        </p-calendar>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="hpv_1st_dose_weight" class="form-control" placeholder="Weight."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="hpv_1st_dose_staff" class="form-control" placeholder="Name of health staff."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="hpv_1st_dose_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="blue">(2<sup>nd</sup>Dose)</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="hpv_2nd_dose_brand" class="form-control" placeholder="Brand."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="card flex justify-content-center">
                                        <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="HPV 2nd Dose"
                                        formControlName="hpv_2nd_dose_date"
                                        inputId="hpv_2nd_dose_date" [readonlyInput]="true" [disabled]="true">
                                        </p-calendar>
                                    </div>
                                </td>
                                <td>
                                    <div class="card flex justify-content-center">
                                        <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="HPV 2nd Dose"
                                        formControlName="hpv_2nd_dose_given_date"
                                        inputId="hpv_2nd_dose_given_date">
                                        </p-calendar>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="hpv_2nd_dose_weight" class="form-control" placeholder="Weight."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="hpv_2nd_dose_staff" class="form-control" placeholder="Name of health staff."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="hpv_2nd_dose_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="green">(3<sup>rd</sup>Dose)</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="hpv_3rd_dose_brand" class="form-control" placeholder="Brand."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="card flex justify-content-center">
                                        <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="HPV 3rd Dose"
                                        formControlName="hpv_3rd_dose_date"
                                        inputId="hpv_3rd_dose_date" [readonlyInput]="true" [disabled]="true">
                                        </p-calendar>
                                    </div>
                                </td>
                                <td>
                                    <div class="card flex justify-content-center">
                                        <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"  placeholder="HPV 3rd Dose"
                                        formControlName="hpv_3rd_dose_given_date"
                                        inputId="hpv_3rd_dose_given_date">
                                        </p-calendar>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="hpv_3rd_dose_weight" class="form-control" placeholder="Weight."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" formControlName="hpv_3rd_dose_staff" class="form-control" placeholder="Name of health staff."/>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="number" formControlName="hpv_3rd_dose_batchNumber" class="form-control" placeholder="Batch Number."/>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    <!-- Notes  -->
                    <div class="form-group table-responsive">
                        <table class="table table-bordered">
                            <tbody>
                                <tr>
                                    <td class="blue"><b>Typhoid -</b> It can be given every 3 years after the age of 2.</td>
                                </tr>
                                <tr>
                                    <td class="green"><b>PCV -</b> If the primary doses are missed, take a single dose at the end of 2 years.</td>
                                </tr>
                                <tr>
                                    <td class="blue"><b>Rotavirus -</b> Both the doses of rotavirus has to be finished before 6 months of age.</td>
                                </tr>
                                <tr>
                                    <td class="green"><b>FLU -</b> If flu vaccine is administered for the first time, a booster is repeated after 1 month then repeat yearly.</td>
                                </tr>
                                <tr>
                                    <td class="blue">
                                        <b>Varicella -</b> Second dose of vaccine can be given 4 month to 1 year after 1st dose with high risk group and given 5 yearly 
                                        after that. It is given as double dose between 9-24 month and as single dose after 24 months.
                                    </td>
                                </tr>
                                <tr>
                                    <td class="green">
                                        <b>Meningococcal</b> - Aramen is given as double dose between 9-24 months and as a single dose after 24 months. Mencevax is to be 
                                        repeated after 3 years after 1st dose in 2-6 years of age group with high risk and given 5 yearly after that.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>