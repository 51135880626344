<div class="main-content d-flex flex-column">
  <app-dashboard-navbar></app-dashboard-navbar>
  <!-- Title area -->
  <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
    <h1>{{ Content.title }}</h1>
    <ol class="breadcrumb">
      <li class="item"><a routerLink="/">Home</a></li>
      <li class="item">{{ Content.subTitle }}</li>
      <li class="item">{{ Content.title }}</li>
    </ol>
  </div>
  <ng-container *ngIf="healthData" let product>
    <div class="add-listings-box">
      <div class="row mt-4" style="display: flex;">
        <div class="col-xl-2 col-lg-12 col-md-12 custom-form-group"
            [ngStyle]="{ 'background-color': getColorBasedOnTemperature(tempratureDataSee) }" style="
        display: flex;
        justify-content: center;
        margin: 2px ;
        align-items: center;
    ">
          <div class="form-group">
            <label>Temp:</label>
            <label>{{ tempratureDataSee}}</label>
          </div>
        </div>
        <div class="col-xl-2 col-lg-12 col-md-12 custom-form-group"
            [ngStyle]="{ 'background-color': getColorBasedOnPulseDataSee(pulseColor) }" style="
        display: flex;
        justify-content: center;
        margin: 2px; align-items: center">
          <div class="form-group">
            <label>Pulse:</label>
            <label>{{pulseDataSee}}</label>
          </div>
        </div>
        <div class="col-xl-2 col-lg-12 col-md-12 custom-form-group"
            [ngStyle]="{ 'background-color': getColorBasedOnRespiration(respiration) }" style="
        display: flex;
        margin: 2px ;
        justify-content: center; align-items: center">
          <div class="form-group">
            <label>Respiration:</label>
            <label>{{ respDataSee }}</label>
          </div>
        </div>
        <div class="col-xl-2 col-lg-12 col-md-12 custom-form-group"
            [ngStyle]="{ 'background-color': getColorBasedOnSpo2(spo2) }" style="
        display: flex;
        margin: 2px ;
        justify-content: center; align-items: center;">
          <div class="form-group">
            <label>Spo2:</label>
            <label>{{ spo2DataSee }}</label>
          </div>
        </div>
        <div class="col-xl-2 col-lg-12 col-md-12 custom-form-group" style="background-color: #f3f370; margin: 2px;">
          <div class="form-group">
            <table style="text-align: left;">
              <tr>
                <td><label>Allergy:</label></td>
                <td><label>{{ foodDetails.food_medicine_allergy_specify_prod }}</label></td>
              </tr>
              <tr>
                <td><label>Food:</label></td>
                <td><label>{{food}}</label></td>
              </tr>
              <!-- <tr>
                <td><label>Exercise:</label></td>
                <td><label>{{ spo2DataSee }}</label></td>
              </tr> -->
              <tr>
                <td><label>Other:</label></td>
                <td><label>{{ foodDetails.Other_concerns}}</label></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="row recom" style="margin-top: 20px;">
        <div class="col-md-6">
          <div>
            <div class="form-group">
              <div>
                <label>Doctor Recommendation:</label>
                <ul class="list-arrow">
                  <li *ngFor="let recommendation of doctorValueRecommendation">
                    {{ recommendation.remarks_doc }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="col-lg-12 col-md-12">
            <div class="form-group">
              <label>Medication:</label>
              <ul class="list-arrow" *ngFor="let recommendation of medicationRecommend">
                <li>
                  <label>Medication Name:<span>{{ recommendation.medicians_name}}</span></label>

                </li>
                <li>
                  <label>Medication Frequency:<span>{{ recommendation.medicians_freq}}</span></label>

                </li>
                <li>
                  <label>Medication Date:<span>{{ recommendation.medicians_date | date:'dd-MM-yyyy'}}</span></label>

                </li>
                <li>
                  <label>Medication Time:<span>{{ formatTime(recommendation.medicians_time) }}</span></label>

                </li>
                <li>
                  <label>Medication Remark:<span>{{ recommendation.medicians_remarks}}</span></label>

                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div style="text-align: left; margin-top: 2px; padding-top:10px; padding-bottom:10px; float:left">
        <table class="table-stamp">
          <tr>
            <td><b>Created By:</b> {{healthData.created_by_user_name }}</td>
            <td><b>Time:</b>{{ healthData.created_at?.substring(0, 16).replace('T', ' ') }}</td>
            <td><b>Updated By:</b> {{healthData.updated_by_user_name}}</td>
            <td><b>Time:</b> {{ healthData.updated_at?.substring(0, 16).replace('T', ' ') }}</td>
          </tr>
        </table>
      </div>
      <div style="text-align: right; margin-top: 2px; float:right">
        <button pButton pRipple class="p-button-rounded p-button-text view-button p-button-info" pTooltip="View"
            tooltipPosition="bottom" (click)="showMaximizableDialog()" style="position: relative;"><i
              class="far fa-eye"></i></button>
      </div>
    </div>
  </ng-container>
</div>