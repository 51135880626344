
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.scss'],
  providers: [MessageService]
})
export class LoginRegisterComponent implements OnInit {
  visible: boolean = false;
  showPassword: boolean = false;
  loginVisiable: boolean = false;
  showForgetPasswordLink: boolean = true;
  verifyOTP: boolean = false;
  sentOTP: boolean = true;
  loginForm: FormGroup;
  registerForm: FormGroup;
  forgotPasswordForm: FormGroup;
  constructor(private fb: FormBuilder, private spinner: NgxSpinnerService, private auth_service: AuthenticationService, private messageService: MessageService, private route: Router) {
    this.loginForm = this.fb.group({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    })
    this.registerForm = this.fb.group({
      name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      mobile: new FormControl('', Validators.required),
      role_id: new FormControl(2)
    })
    this.forgotPasswordForm = this.fb.group({
      email: new FormControl('', Validators.required), // Add validators as needed
      otp: new FormControl('', Validators.required)
    });
  }
  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword; // Toggle the value of showPassword
  }
  ngOnInit(): void {
    this.loginVisiable = !this.loginVisiable
  }
  showDialog() {
  }
  /* -------------------------------------------------------------------------- */
  /*                         // save login form function                        */
  /* -------------------------------------------------------------------------- */
  saveLoginForm() {
    this.spinner.show()

    if (this.loginForm.valid) {
      this.auth_service.loginApi(this.loginForm.value).then((response: any) => {
        const personId = response.userId
        this.route.navigate(['/portal/first-screen']);
        console.log('API Response:', response);
        this.messageService.add({ severity: 'success', summary: 'Success', detail: response?.message });
        if (response.code == 200) {
          this.spinner.hide()
          localStorage.setItem('token', response?.token);
          localStorage.setItem('role', response?.roleName);
          localStorage.setItem('user_id', response?.userId);;
          localStorage.setItem('access_token', JSON.stringify(response));
          localStorage.setItem('name', response.name)
        }
      },
        (error: any) => {
          this.spinner.hide()
        
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter valid username and password' });
        }
      );
    } else {
      this.spinner.hide()
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter username and password' });
    }
  }
  forgotPassword() {

    // this.route.navigateByUrl('/forget-password');
    // this.loginVisiable = false;
    // this.visible = !this.visible;
    this.visible = !this.visible;
    this.loginVisiable = !this.visible;
    this.showForgetPasswordLink = !this.showForgetPasswordLink; // Toggle forget password link text
    console.log("Yes Forgot Password is clicked")
  }
  sendotp(email: any) {
    const sendemail = email;
    let obj = {
      email: email
    }
    console.log("Send OTP Email", obj);
    this.auth_service.sendEmailForOtp(obj).then((response: any) => {
      console.log('GET OTP API Response:', response);
      this.verifyOTP = true;
      this.sentOTP = false;
    })
  }
  verify(email: any, otp: any) {
    let obj = {
      email: email,
      otp: otp
    }
    this.auth_service.verifyOtp(obj).then((response: any) => {
      console.log('Verify OTP API Response:', response);
      this.messageService.add({ severity: 'success', summary: 'Success', detail: response?.message });
      if (response.code == 200) {
        localStorage.setItem('emailOTP', email);
        this.route.navigateByUrl('/forget-password');
      }
      else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please check otp or email' });
      }
    })
  }

  // mark as untouched
  markFormGroupTouched(formGroup: FormGroup) {
    // Mark all form controls in the provided form group as touched
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
