<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->
<!-- import { DialogModule } from 'primeng/dialog'; -->

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">
  <app-dashboard-navbar></app-dashboard-navbar>

  <!-- Breadcrumb Area -->
  <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
    <h1>{{ Content.title }}</h1>
    <ol class="breadcrumb">
      <li class="item"><a routerLink="/">Home</a></li>
      <li class="item">{{ Content.subTitle }}</li>
      <li class="item">{{ Content.title }}</li>
    </ol>
  </div>


  <div class="add-listings-box">
    <div class="Master-view-box">
      <div class="theader d-flex justify-content-between">
        <div class="d-flex w-100">
          <span class="p-input-icon-left ml-auto mr-2 w-100%">
            <i class="pi pi-search"></i>
            <input pInputText type="text" class="form-control"
              style="text-align: left; padding-left: 20%; height: 45px !important" scriptInjection
              placeholder="Search Keyword" (input)="dt1.filterGlobal($event.target.value, 'contains')"
              [(ngModel)]="filterVal" />
          </span>
          <button pButton type="button" class="mx-2" icon="bx bx-reset" (click)="reset(dt1)" tooltipPosition="bottom"
            style="height: 45px;"></button>
        </div>
        <div class="add-listings-btn" style="text-align: right">
          <a routerLink="/portal/initial-assessment-adult" class="nav-link">
            <button type="submit">Add</button>
          </a>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-lg-12 col-md-12">
        <p-table #dt1 [value]="ptAssListData" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
          [tableStyle]="{ 'min-width': '50rem', width: '100%' }" styleClass="p-datatable-sm p-datatable-customers"
          [rowsPerPageOptions]="[10, 25, 50]" responsiveLayout="scroll" [loading]="loading" [paginator]="true"
          currentPageReportTemplate="Showing {first} to
            {last} of {totalRecords} entries"
          [globalFilterFields]="['date', 'initial_assessment', 'history_present_illness']" editMode="row">
          <ng-template pTemplate="header">
            <tr>
              <th style="min-width: 62px">S. No.</th>
              <th>Action</th>
              <!-- <th>Date Of Birth</th> -->
              <!-- <th>ID</th> -->
              <th>Date</th>
              <th>Initial Assessment</th>
              <th>History of Present Illness</th>
            </tr>
          </ng-template>
          <!-- table to show data -->
          <ng-template pTemplate="body" let-list let-i="rowIndex">
            <tr>
              <td>
                {{ i + 1 }}
              </td>
              <td>
                <button pButton pRipple class="p-button-rounded p-button-text p-button-info" pTooltip="View"
                  tooltipPosition="bottom">
                  <i (click)="showMaximizableDialog(list)" class="far fa-eye"></i>
                </button>
              </td>
              <!-- <td>{{ list?.baby_details_id ?? '-' }}</td> -->
              <td>{{ list?.date | date:'dd-MM-yyyy' ?? "-" }}</td>
              <td>{{ list?.initial_assessment
                ?? "-" | titlecase}}</td>
              <td>{{ list?.history_present_illness?? "-" | titlecase}}</td>
              <!-- <td>{{ list?.years ?? "-" }}</td> -->
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="5">No Data Found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <!-- ****************************************************view dialog********************************************************* -->
      <!-- <p-dialogheader="Baby Details" [(visible)]="displayMaximizable" [modal]="true" [style]="{ width: '90vw' }"
       [draggable]="false" [resizable]="false"></p-dialog> -->
      <!----------------------------------------------------------- view dialog --------------------------------------------------------->
      <!-- ***************************************************************************************************************** -->
      <p-dialog header="Adult Details" [(visible)]="displayMaximizable" [modal]="true" [style]="{ width: '90vw' }"
        [draggable]="false" [resizable]="false">
        <!-- Start -->
        <ng-container *ngFor="let data of viewDetail">
          <div class="row">
            <div class="col-lg-12">
              <div class="my-profile-box">
                <form>
                  <!-- Basic detail -->
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Date :</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.date | date:'dd-MM-yyyy' ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Initial Assessment </label>
                        <input type="text" readonly class="form-control" value="{{ data?.initial_assessment
                            ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> History of Present Illness:</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.history_present_illness ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Past Medical History:</label>
                        <input type="text" readonly class="form-control" value="{{ data?.past_medical_history
                             ?? '-' }}" />
                      </div>
                    </div>
                    <div *ngFor="let item of data?.assesment_json" class="row">
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label>Medications:</label>
                          <!-- <input type="text" readonly class="form-control" value="{{ data?.assesment_json?.medication
                            ?? '-' }}" /> -->
                          <!-- <ng-container *ngFor="let item of data?.assesment_json"> -->
                          <!-- <input type="text" readonly class="form-control" value="{{item?.medication
                                  ?? '-' }}" /> -->
                          <input type="text" readonly class="form-control" *ngIf="
													item?.medication !== null &&
													item?.medication!== '' &&
													item?.medication!==
														undefined;
													else elseBlock
												  " value="{{
                            item?.medication
												  }}" />
                          <ng-template #elseBlock>
                            <input type="text" readonly class="form-control" value="-" />
                          </ng-template>
                          <!-- </ng-container> -->
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Tool:</label>
                        <input type="text" readonly class="form-control" value="{{ data?.pain_assessment_tool
                            ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Score :</label>
                        <input type="text" readonly class="form-control" value="{{ data?.pain_assessment_score
                            ?? '-' }}" />
                      </div>
                    </div>
                  </div>




                  <!-- intial assissment -->
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Vital Signs: Pre-session</label>
                      <!-- <input type="text" readonly class="form-control" value="{{ data?.mode_delivery ?? '-' }}" /> -->
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Blood Pressure :</label>
                      <input type="text" readonly class="form-control" value="{{ data?.blood_pressure
                          ?? '-' }}" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Temperature:</label>
                        <input type="text" readonly class="form-control" value="{{ data?.temperature ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Heart Rate :</label>
                        <input type="text" readonly class="form-control" value="{{ data?.heart_rate ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>SPO2 :</label>
                        <input type="text" readonly class="form-control" value="{{ data?.spo2 ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>RR:</label>
                        <input type="text" readonly class="form-control" value="{{ data?.rr ?? '-' }}" />
                      </div>
                    </div>
                  </div>


                  <!-- Mother detail -->
                  <h3 class="fw-bold">Attachment:</h3>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label class="px-0">PEG:</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.attachment_PEG == 1? 'Yes': '-'?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label class="px-0">Trache:</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.attachment_trache == 1? 'Yes': '-'?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label class="px-0"> NGT:</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.attachment_NGT== 1? 'Yes': '-' ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label class="px-0">FC1:</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.attachment_FC1 == 1? 'Yes': '-'?? '-' }}" />
                      </div>
                    </div>




                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Colostomy :</label>
                        <input type="text" readonly class="form-control" value="{{ data?.attachment_colostomy== 1? 'Yes': '-'
                            ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>FC2:</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.attachment_FC2 == 1? 'Yes': '-'?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Ventilator :</label>
                        <!-- <input type="text" readonly class="form-control" value="{{
                              data?.mother_para == 'null' ? '-' : data?.mother_para
                            }}" /> -->
                        <input type="text" readonly class="form-control"
                          value="{{ data?.attachment_ventilator == 1? 'Yes': '-'?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-122">
                      <div class="form-group">
                        <label class="px-0">O2 Canula:</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.attachment_canula == 1? 'Yes': '-'?? '-' }}" />
                      </div>
                    </div>
                  </div>

                  <!-- Attachment-->
                  <h3 class="fw-bold">Respiratory Assessment:</h3>

                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Cough :</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.repiratory_assessment ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Remark :</label>
                        <input type="text" readonly class="form-control" value="{{ data?.cough_remark ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Chest Secretions:</label>
                        <input type="text" readonly class="form-control" value="{{ data?.chest_secretion ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Remark:</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.chest_secretion_remark ?? '-' }}" />
                      </div>
                    </div>
                  </div>
                  <h3 class="fw-bold">Cognition:</h3>

                  <div class="form-group table-responsive">
                    <table class="table">
                      <thead>
                        <tr class="border-light" style="white-space: nowrap;">
                          <th scope="col"><label class="subHeading px-0">Light Touch:</label></th>
                          <th scope="col"><label class="subHeading px-0">Blunt Touch/Deep</label></th>
                          <th scope="col"><label class="subHeading px-0">Pressure</label></th>
                          <th scope="col"><label class="subHeading px-0">Pin Prick</label></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="border-light">

                          <td> <input type="text" readonly class="form-control"
                              value="{{ data?.intact_light == 1 ? 'Intact' : data?.intact_light == 2 ? 'Impaired' : data?.intact_light == 3 ? 'Absent' : data?.intact_light == 4 ? 'Not tested ' : '-' | titlecase}}" />
                          </td>
                          <td> <input type="text" readonly class="form-control"
                              value="{{ data?.intact_blunt == 1 ? 'Intact' : data?.intact_blunt == 2 ? 'Impaired' : data?.intact_blunt == 3 ? 'Absent' : data?.intact_blunt == 4 ? 'Not tested ' : '-'  | titlecase}}" />
                          </td>
                          <td> <input type="text" readonly class="form-control"
                              value="{{ data?.intact_pressure == 1 ? 'Intact' : data?.intact_pressure == 2 ? 'Impaired' : data?.intact_pressure == 3 ? 'Absent' : data?.intact_pressure == 4 ? 'Not tested ' : '-' | titlecase }}" />
                          </td>
                          <td> <input type="text" readonly class="form-control"
                              value="{{ data?.intact_pin == 1 ? 'Intact' : data?.intact_pin == 2 ? 'Impaired' : data?.intact_pin == 3 ? 'Absent' : data?.intact_pin == 4 ? 'Not tested ' : '-'  | titlecase}}" />
                          </td>
                        </tr>
                        <tr class="border-light">
                          <td>
                            <input type="text" readonly class="form-control"
                              value="{{ data?.light_touch_remark ?? '-' }}" />
                          </td>
                          <td>
                            <input type="text" readonly class="form-control" value="{{ data?.blunt_remark ?? '-' }}" />
                          </td>
                          <td>
                            <input type="text" readonly class="form-control"
                              value="{{ data?.pressure_remark ?? '-' }}" />
                          </td>
                          <td>
                            <input type="text" readonly class="form-control" value="{{ data?.pin_remark ?? '-' }}" />
                          </td>
                        </tr>


                        <!-- <td> <input type="text" readonly class="form-control"
                              value="{{ data?.impaired_light == 2? 'Yes': '-'?? '-' }}" /></td>
                          <td> <input type="text" readonly class="form-control"
                              value="{{ data?.absent_light == 3? 'Yes': '-'?? '-' }}" /></td>
                          <td><input type="text" readonly class="form-control"
                              value="{{ data?.not_tested_light== 4? 'Yes': '-' ?? '-' }}" /></td>
                          <td> <input type="text" readonly class="form-control"
                              value="{{ data?.light_touch_remark ?? '-' }}" />
                            </td> -->
                        <!-- <tr class="border-light">
                          <td scope="row"> <label class=" px-0">Blunt Touch/Deep</label></td>
                          <td> <input type="text" readonly class="form-control"
                              value="{{ data?.intact_blunt == 1? 'Yes': '-'?? '-' }}" /></td>
                          <td><input type="text" readonly class="form-control"
                              value="{{ data?.impaired_blunt == 1? 'Yes': '-'?? '-' }}" /></td>
                          <td> <input type="text" readonly class="form-control"
                              value="{{ data?.absent_blunt == 1? 'Yes': '-'?? '-' }}" /></td>
                          <td> <input type="text" readonly class="form-control"
                              value="{{ data?.not_tested_blunt == 1? 'Yes': '-'?? '-' }}" /></td>
                          <td><input type="text" readonly class="form-control"
                              value="{{ data?.blunt_remark ?? '-' }}" /></td>
                        </tr>
                        <tr class="border-light">
                          <td scope="row"> <label class="px-0 ">Pressure</label></td>
                          <td><input type="text" readonly class="form-control"
                              value="{{ data?.intact_pressure== 1? 'Yes': '-' ?? '-' }}" /></td>
                          <td> <input type="text" readonly class="form-control"
                              value="{{ data?.impaired_pressure== 1? 'Yes': '-' ?? '-' }}" /></td>
                          <td> <input type="text" readonly class="form-control"
                              value="{{ data?.absent_pressure == 1? 'Yes': '-'?? '-' }}" /></td>
                          <td> <input type="text" readonly class="form-control"
                              value="{{ data?.not_tested_pressure == 1? 'Yes': '-'?? '-' }}" /></td>
                          <td> <input type="text" readonly class="form-control"
                              value="{{ data?.pressure_remark ?? '-' }}" /></td>
                        </tr>
                        <tr class="border-light">
                          <td scope="row"> <label class="px-0 ">Pin Prick</label></td>
                          <td> <input type="text" readonly class="form-control"
                              value="{{ data?.intact_pin == 1? 'Yes': '-'?? '-' }}" /></td>
                          <td><input type="text" readonly class="form-control"
                              value="{{ data?.impaired_pin == 1? 'Yes': '-'?? '-' }}" /></td>
                          <td> <input type="text" readonly class="form-control"
                              value="{{ data?.absent_pin== 1? 'Yes': '-' ?? '-' }}" /></td>
                          <td><input type="text" readonly class="form-control"
                              value="{{ data?.not_tested_pin == 1? 'Yes': '-'?? '-' }}" /></td>
                          <td> <input type="text" readonly class="form-control" value="{{ data?.pin_remark ?? '-' }}" />
                          </td>
                        </tr> -->
                      </tbody>
                    </table>
                  </div>
                  <div class="form-group">
                    <h3 class="fw-bold">Cognition:</h3>

                    <!-- Oriented to people -->
                    <div class="row">
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <label class="px-0">Oriented to People :</label>
                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <label>Oriented People </label>
                        <input type="text" readonly class="form-control" value="{{data?.oriented_people ?? '-' }}" />

                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <!-- <div class="form-group"> -->
                          <label>Oriented Remark </label>
                          <input type="text" readonly class="form-control"
                            value="{{ data?.oriented_people_remark ?? '-' }}" />
                        <!-- </div> -->
                      </div>
                    </div>

                    <!-- Oriented to time -->
                    <div class="row">
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <label class="px-0">Oriented to Time :</label>
                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <label>Oriented People </label>
                        <input type="text" readonly class="form-control" value="{{data?.oriented_time ?? '-' }}" />

                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">

                        <label>Oriented Time Remark </label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.oriented_time_remark ?? '-' }}" />

                      </div>
                    </div>
                     <!-- oriented to place -->
                    <div class="row">
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <label class="px-0">Oriented to Place :</label>
                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <label>Oriented Place</label>
                        <input type="text" readonly class="form-control" value="{{data?.oriented_place ?? '-' }}" />

                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <label>Oriented Place Remark</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.oriented_place_remark ?? '-' }}" />

                      </div>


                    </div>
                    <div class="add-listings-box">
                      <h3>Palpation</h3>
                      <div class="form-group">
                        <label class="subHeading px-0">Soft Tissues and Skin Assessment:</label>
                        <!-- Swelling -->
                        <div class="row">
                          <div class="col-xl-4 col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>Swelling:</label>
                              <input type="text" readonly class="form-control" value="{{data?.swelling ?? '-' }}" />
                            </div>
                          </div>
                          <div class="col-xl-4 col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>Swelling Remark: </label>
                              <input type="text" readonly class="form-control" value="{{ data?.swelling_remark
                                  ?? '-' }}" />
                            </div>
                          </div>
                          <div class="col-xl-4 col-lg-12 col-md-12">
                            <div class="form-group">
                              <label> Scar:</label>
                              <input type="text" readonly class="form-control" value="{{data?.scar ?? '-' }}" />
                            </div>
                          </div>
                          <div class="col-xl-4 col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>Scar Remark</label>
                              <input type="text" readonly class="form-control" value="{{ data?.scar_remark
                                   ?? '-' }}" />
                            </div>
                          </div>
                          <div class="col-xl-4 col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>Worm:</label>
                              <input type="text" readonly class="form-control" value="{{data?.worm ?? '-' }}" />
                            </div>
                          </div>
                          <div class="col-xl-4 col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>Worm Remark</label>
                              <input type="text" readonly class="form-control" value="{{ data?.worm_remark
                                  ?? '-' }}" />
                            </div>
                          </div>
                          <div class="col-xl-4 col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>Infection:</label>
                              <input type="text" readonly class="form-control" value="{{data?.infection ?? '-' }}" />
                            </div>
                          </div>
                          <div class="col-xl-4 col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>Infection Remark</label>
                              <input type="text" readonly class="form-control" value="{{ data?.infection_remark
                                  ?? '-' }}" />
                            </div>
                          </div>
                          <div class="col-xl-4 col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>Wound:</label>
                              <input type="text" readonly class="form-control" value="{{data?.wound ?? '-' }}" />
                            </div>
                          </div>
                          <div class="col-xl-4 col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>Wound Remark</label>
                              <input type="text" readonly class="form-control" value="{{ data?.wound_remark
                                  ?? '-' }}" />
                            </div>
                          </div>
                          <div class="col-xl-4 col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>Pain:</label>
                              <input type="text" readonly class="form-control" value="{{data?.pain?? '-' }}" />
                            </div>
                          </div>
                          <div class="col-xl-4 col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>Pain Remark</label>
                              <input type="text" readonly class="form-control" value="{{ data?.pain_remark
                                  ?? '-' }}" />
                            </div>
                          </div>
                          <div class="col-xl-4 col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>Other</label>
                              <input type="text" readonly class="form-control" value="{{data?.others ?? '-' }}" />
                            </div>
                          </div>
                          <div class="col-xl-4 col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>Other Remark</label>
                              <input type="text" readonly class="form-control" value="{{ data?.others_remark
                                  ?? '-' }}" />
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <!-- </div> -->
                  <div class="add-listings-box">
                    <h3>Motor Components</h3>
                    <label class="subHeading mb-3">Joint and Muscle:</label>
                    <div class="form-group table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr style="white-space: nowrap;">
                            <th scope="col" class="text-center" rowspan="2"><label class="subHeading px-0">Joint</label>
                            </th>
                            <th scope="col" class="text-center" rowspan="2"><label
                                class="subHeading px-0">Movement</label>
                            </th>
                            <th scope="col" class="text-center" rowspan="2"><label class="subHeading px-0">Norm
                                ROM</label>
                            </th>
                            <th scope="col" class="text-center" colspan="2"><label class="subHeading px-0">Passive
                                ROM</label></th>
                            <th scope="col" class="text-center" colspan="2"><label class="subHeading px-0">Active
                                ROM</label>
                            </th>
                            <th scope="col" class="text-center" rowspan="2"><label
                                class="subHeading px-0">Comments</label>
                            </th>
                            <th scope="col" class="text-center" colspan="2"><label class="subHeading px-0">Muscle
                                Strength</label></th>
                          </tr>
                          <tr>
                            <th class="text-center">R</th>
                            <th class="text-center">L</th>
                            <th class="text-center">R</th>
                            <th class="text-center">L</th>
                            <th class="text-center">R</th>
                            <th class="text-center">L</th>
                          </tr>
                        </thead>
                        <tbody>
                          <!-- NECK ROW -->
                          <!-- Flexion -->
                          <tr>
                            <td scope="row" rowspan="4"> <label class="px-0 ">Neck</label></td>
                            <td>Flexion</td>
                            <td>0-50</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.neck_flexion_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.neck_flexion_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.neck_flexion_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.neck_flexion_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.nech_flexion_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.neck_flexion_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.neck_flexion_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!-- Extension -->
                          <tr>
                            <td>Extension</td>
                            <td>0-60</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.neck_extension_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.neck_extension_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.neck_extension_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.neck_extension_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.neck_extension_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.neck_extension_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.neck_extension_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!-- Rotation -->
                          <tr>
                            <td>Rotation</td>
                            <td>0-80</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.neck_rotation_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.neck_rotation_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.neck_rotation_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.neck_rotation_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.neck_rotation_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.neck_rotation_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.neck_rotation_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!-- Side flex -->
                          <tr>
                            <td>Side flex</td>
                            <td>0-45</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.neck_sideFlex_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.neck_sideFlex_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.neck_sideFlex_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.neck_sideFlex_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.neck_sideFlex_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.neck_sideFlex_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.neck_sideFlex_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                          </tr>

                          <!-- SHOULDER ROW -->
                          <!-- Flexion -->
                          <tr>
                            <td scope="row" rowspan="6"> <label class="px-0 ">Shoulder</label></td>
                            <td>Flexion</td>
                            <td>0-180</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_flexion_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_flexion_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_flexion_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_flexion_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.shoulder_flexion_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_flexion_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_flexion_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!-- Extension -->
                          <tr>
                            <td>Extension</td>
                            <td>0-60</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_extension_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_extension_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_extension_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_extension_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.shoulder_extension_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_extension_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_extension_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!-- Abduction -->
                          <tr>
                            <td>Abduction</td>
                            <td>0-180</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_abduction_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_abduction_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_abduction_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_abduction_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.shoulder_abduction_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_abduction_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_abduction_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!-- Adduction -->
                          <tr>
                            <td>Adduction</td>
                            <td>0-90</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_adduction_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_adduction_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_adduction_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_adduction_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.shoulder_adduction_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_adduction_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_adduction_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!-- Internal Rotation -->
                          <tr>
                            <td>Internal Rotation</td>
                            <td>0-70</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_internal_rotation_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_internal_rotation_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_internal_rotation_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_internal_rotation_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.shoulder_internal_rotation_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_internal_rotation_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_internal_rotation_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!-- External Rotation -->
                          <tr>
                            <td>External Rotation</td>
                            <td>0-90</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_external_rotation_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_external_rotation_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_external_rotation_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_external_rotation_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.shoulder_external_rotation_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_external_rotation_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.shoulder_external_rotation_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!-- ELBOW ROW-->
                          <!-- Flexion -->
                          <tr>
                            <td scope="row" rowspan="4"> <label class="px-0 ">Elbow</label></td>
                            <td>Flexion</td>
                            <td>0-50</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.elbow_flexion_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.elbow_flexion_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.elbow_flexion_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.elbow_flexion_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.elbow_flexion_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.elbow_flexion_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.elbow_flexion_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                          </tr>

                          <!-- Extension -->
                          <tr>
                            <td>Extension</td>
                            <td>0-60</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.elbow_extension_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.elbow_extension_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.elbow_extension_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.elbow_extension_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.elbow_extension_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.elbow_extension_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.elbow_extension_muscle_l== 1? 'Yes': '-' ?? '-' }}" />

                          </tr>

                          <!-- Supination -->
                          <tr>
                            <td>Supination</td>
                            <td>0-80</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.elbow_supination_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.elbow_supination_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.elbow_supination_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.elbow_supination_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.elbow_supination_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.elbow_supination_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.elbow_supination_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!-- Pronation -->
                          <tr>
                            <td>Pronation</td>
                            <td>0-80</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.elbow_pronation_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.elbow_pronation_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.elbow_pronation_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.elbow_pronation_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.elbow_pronation_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.elbow_pronation_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.elbow_pronation_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!--WRIST ROW -->
                          <!-- Flexion -->
                          <tr>
                            <td scope="row" rowspan="4"> <label class="px-0 ">Wrist</label></td>
                            <td>Flexion</td>
                            <td>0-50</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.wrist_flexion_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.wrist_flexion_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.wrist_flexion_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.wrist_flexion_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.wrist_flexion_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.wrist_flexion_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.wrist_flexion_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!-- Extension -->
                          <tr>
                            <td>Extension</td>
                            <td>0-70</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.wrist_extension_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.wrist_extension_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.wrist_extension_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.wrist_extension_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.wrist_extension_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.wrist_extension_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.wrist_extension_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!-- Ulnar Deviation -->
                          <tr>
                            <td>Ulnar Deviation</td>
                            <td>0-30</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.wrist_ulnar_deviation_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.wrist_ulnar_deviation_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.wrist_ulnar_deviation_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.wrist_ulnar_deviation_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.wrist_ulnar_deviation_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.wrist_ulnar_deviation_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.wrist_ulnar_deviation_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!-- Radial Deviation -->
                          <tr>
                            <td> Radial Deviation</td>
                            <td>0-20</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.wrist_radial_deviation_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.wrist_radial_deviation_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.wrist_radial_deviation_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.wrist_radial_deviation_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.wrist_radial_deviation_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.wrist_radial_deviation_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.wrist_radial_deviation_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!-- DIGITS ROW -->
                          <!-- Thumb MCP -->
                          <tr>
                            <td scope="row" rowspan="5"> <label class="px-0 ">Digits</label></td>
                            <td>Thumb MCP Ext/flex</td>
                            <td>10/30</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_thump_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_thump_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_thump_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_thump_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.digits_thump_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_thump_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_thump_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!-- Thump IP -->
                          <tr>
                            <td>Thump IP Ext/Flex</td>
                            <td>0/80</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_thump_ip_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_thump_ip_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_thump_ip_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_thump_ip_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.digits_thump_ip_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_thump_ip_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_thump_ip_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!-- MCP Ext/Flex -->
                          <tr>
                            <td>MCP Ext/Flex</td>
                            <td>0/90</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_mcp_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_mcp_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_mcp_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_mcp_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.digits_mcp_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_mcp_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_mcp_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!-- PIP Ext/Flex -->
                          <tr>
                            <td>PIP Ext/Flex</td>
                            <td>0/100</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_pip_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_pip_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_pip_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_pip_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.digits_pip_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_pip_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_pip_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!-- DIP Ext/Flex -->
                          <tr>
                            <td>DIP Ext/Flex</td>
                            <td>0/90</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_dip_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_dip_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_dip_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_dip_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.digits_dip_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_dip_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.digits_dip_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!-- HIP ROW -->
                          <!-- Flexion -->
                          <tr>
                            <td scope="row" rowspan="6"> <label class="px-0 ">Hip</label></td>
                            <td>Flexion</td>
                            <td>0-120</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_flexion_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_flexion_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_flexion_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_flexion_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.hip_flexion_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_flexion_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_flexion_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!-- Extension -->
                          <tr>
                            <td>Extension</td>
                            <td>0-20</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_extension_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_extension_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_extension_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_extension_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.hip_extension_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_extension_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_extension_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!-- Abduction -->
                          <tr>
                            <td>Abduction</td>
                            <td>0-45</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_abduction_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_abduction_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_abduction_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_abduction_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.hip_abduction_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_abduction_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_abduction_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!--Adduction-->
                          <tr>
                            <td>Adduction</td>
                            <td>0-20</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_adduction_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_adduction_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_adduction_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_adduction_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.hip_adduction_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_adduction_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_adduction_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!--Internal Rotation-->
                          <tr>
                            <td>Internal Rotation</td>
                            <td>0-45</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_internal_rotation_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_internal_rotation_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_internal_rotation_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_internal_rotation_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.hip_internal_rotation_comment ?? '-' }}" />
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_internal_rotation_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_internal_rotation_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!--External Rotation-->
                          <tr>
                            <td>External Rotation</td>
                            <td>0-45</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_external_rotation_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_external_rotation_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_external_rotation_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_external_rotation_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.hip_external_rotation_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_external_rotation_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.hip_external_rotation_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!-- KNEE ROW -->
                          <!-- Flexion -->
                          <tr>
                            <td scope="row" rowspan="2"> <label class=" px-0">Knee</label></td>
                            <td>Flexion</td>
                            <td>0-135</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.knee_flexion_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.knee_flexion_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.knee_flexion_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.knee_flexion_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.knee_flexion_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.knee_flexion_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.knee_flexion_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!-- Extension -->
                          <tr>
                            <td>Extension</td>
                            <td>135-0</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.knee_extension_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.knee_extension_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.knee_extension_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.knee_extension_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.knee_extension_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.knee_extension_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.knee_extension_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                          </tr>

                          <!-- ANKLE ROW -->
                          <!-- PF -->
                          <tr>
                            <td scope="row" rowspan="6"> <label class=" px-0">Ankle</label></td>
                            <td>PF</td>
                            <td>0-45</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.ankle_pf_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.ankle_pf_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.ankle_pf_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.ankle_pf_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.ankle_pf_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.ankle_pf_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.ankle_pf_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!-- DF -->
                          <tr>
                            <td>DF</td>
                            <td>0-20</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.ankle_df_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.ankle_df_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.ankle_df_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.ankle_df_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.ankle_df_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.ankle_df_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.ankle_df_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                          </tr>

                          <!-- Inversion -->
                          <tr>
                            <td>Inversion</td>
                            <td>0-35</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.knee_inversion_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.knee_inversion_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.knee_inversion_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.knee_inversion_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.knee_inversion_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.knee_inversion_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.knee_inversion_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>

                          </tr>

                          <!-- Eversion -->
                          <tr>
                            <td>Eversion</td>
                            <td>0-15</td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.knee_eversion_passive_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.knee_eversion_passive_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.knee_eversion_active_r== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.knee_eversion_active_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.knee_eversion_comment ?? '-' }}" />
                            </td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.knee_eversion_muscle_r== 1? 'Yes': '-' ?? '-' }}" />
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{data?.knee_eversion_muscle_l== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="row">
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label>Head Control:
                          </label>
                          <input type="text" readonly class="form-control"
                            value="{{data?.head_control? 'Yes':  '-' }}" />
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label>Trunk Control:</label>
                          <input type="text" readonly class="form-control"
                            value="{{data?.trunk_control? 'Yes':  '-' }}" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="add-listings-box pb-2">
                    <h3>Mobility And Balance</h3>
                    <div class="form-group table-responsive mb-0">
                      <table class="table">
                        <thead>
                          <tr class="border-light">
                            <th scope="col"></th>
                            <th scope="col"><label class="subHeading px-0">Activity</label></th>
                            <th scope="col"><label class="subHeading px-0">Remark</label></th>
                            <!-- <th scope="col"><label class="subHeading px-0">D</label></th>
                            <th scope="col"><label class="subHeading px-0">N</label></th>
                            <th scope="col"><label class="subHeading px-0">Remark</label></th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="border-light">
                            <td scope="row"> <label class=" px-0">Rolling</label></td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{ data?.rolling_I == 1 ? 'Independent' : data?.rolling_I == 2 ? 'Assistance' : data?.rolling_I == 3 ? 'Dependent' : data?.rolling_I == 4 ? 'Not Tested' : '-' }}" />
                            </td>
                            <td><input type="text" readonly class="form-control"
                                value="{{data?.rolling_remark?? '-' }}" /></td>
                            <!-- <td> <input type="text" readonly class="form-control"
                                value="{{data?.rolling_A== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{data?.rolling_D== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td> <input type="text" readonly class="form-control"
                              value="{{data?.rolling_N== 1? 'Yes': '-' ?? '-' }}" />
                            </td> -->
                          </tr>
                          <tr class="border-light">
                            <td scope="row"> <label class=" px-0">Sitting</label></td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{ data?.sitting_I == 1 ? 'Independent' : data?.sitting_I == 2 ? 'Assistance' : data?.sitting_I == 3 ? 'Dependent' : data?.sitting_I == 4 ? 'Not Tested' : '-' }}" />
                            </td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{data?.sitting_remark ?? '-' }}" /></td>
                            <!-- <td> <input type="text" readonly class="form-control"
                                value="{{data?.sitting_A== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td><input type="text" readonly class="form-control"
                                value="{{data?.sitting_D== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td><input type="text" readonly class="form-control"
                                value="{{data?.sitting_N== 1? 'Yes': '-' ?? '-' }}" />
                            </td> -->
                          </tr>
                          <tr class="border-light">
                            <td scope="row"> <label class=" px-0">Standing</label></td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.standing_I == 1 ? 'Independent' : data?.standing_I == 2 ? 'Assistance' : data?.standing_I == 3 ? 'Dependent' : data?.standing_I == 4 ? 'Not Tested' : '-' }}" />

                            </td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{data?.standing_remark?? '-' }}" /></td>
                            <!-- <td> <input type="text" readonly class="form-control"
                                value="{{data?.standing_A== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{data?.standing_D== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{data?.standing_N== 1? 'Yes': '-' ?? '-' }}" />
                            </td> -->
                          </tr>
                          <tr class="border-light">
                            <td scope="row"> <label class=" px-0">Gait</label></td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{ data?.gait_I == 1 ? 'Independent' : data?.gait_I == 2 ? 'Assistance' : data?.gait_I == 3 ? 'Dependent' : data?.gait_I == 4 ? 'Not Tested' : '-' }}" />
                            </td>
                            <td><input type="text" readonly class="form-control" value="{{data?.gait_remark??  '-'}}" />
                            </td>
                            <!-- <td> <input type="text" readonly class="form-control"
                                value="{{data?.gait_A== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{data?.gait_D== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td><input type="text" readonly class="form-control"
                                value="{{data?.gait_N== 1? 'Yes': '-' ?? '-' }}" />
                            </td> -->
                          </tr>
                          <tr class="border-light">
                            <td scope="row"> <label class=" px-0">Balance</label></td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{ data?.balance_I == 1 ? 'Independent' : data?.balance_I == 2 ? 'Assistance' : data?.balance_I == 3 ? 'Dependent' : data?.balance_I == 4 ? 'Not Tested' : '-' }}" />
                            </td>
                            <td><input type="text" readonly class="form-control" value="{{ data?.balance_remark
                                      ?? '-' }}" /></td>
                            <!-- <td><input type="text" readonly class="form-control"
                                value="{{data?.balance_A== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{data?.balance_D== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{data?.balance_N== 1? 'Yes': '-' ?? '-' }}" />
                            </td> -->
                          </tr>
                          <tr class="border-light">
                            <td scope="row"> <label class=" px-0">Static Sitting</label></td>
                            <td>
                              <input type="text" readonly class="form-control"
                                value="{{ data?.static_sitting_I == 1 ? 'Independent' : data?.static_sitting_I == 2 ? 'Assistance' : data?.static_sitting_I == 3 ? 'Dependent' : data?.static_sitting_I == 4 ? 'Not Tested' : '-' }}" />
                            </td>
                            <td> <input type="text" readonly class="form-control" value="{{ data?.static_sitting_remark
                                      ?? '-' }}" /></td>
                            <!-- <td> <input type="text" readonly class="form-control"
                                value="{{data?.static_sitting_A== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{data?.static_sitting_D== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td><input type="text" readonly class="form-control"
                                value="{{data?.static_sitting_N== 1? 'Yes': '-' ?? '-' }}" />
                            </td> -->
                          </tr>
                          <tr class="border-light">
                            <td scope="row"> <label class="px-0 ">Static Standing</label></td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{ data?.static_standing_I == 1 ? 'Independent' : data?.static_standing_I == 2 ? 'Assistance' : data?.static_standing_I == 3 ? 'Dependent' : data?.static_standing_I == 4 ? 'Not Tested' : '-' }}" />
                            </td>
                            <td> <input type="text" readonly class="form-control" value="{{ data?.static_standing_remark
                                      ?? '-' }}" /></td>
                            <!-- <td> <input type="text" readonly class="form-control"
                                value="{{data?.static_standing_A== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{data?.static_standing_D== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{data?.static_standing_N== 1? 'Yes': '-' ?? '-' }}" />
                            </td> -->
                          </tr>
                          <tr class="border-light">
                            <td scope="row"> <label class=" px-0">Dynamic Sitting</label></td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{ data?.dynamic_sitting_I == 1 ? 'Independent' : data?.dynamic_sitting_I == 2 ? 'Assistance' : data?.dynamic_sitting_I == 3 ? 'Dependent' : data?.dynamic_sitting_I == 4 ? 'Not Tested' : '-' }}" />
                            </td>
                            <td> <input type="text" readonly class="form-control" value="{{ data?.dynamic_sitting_remark
                                       ?? '-' }}" /> </td>
                            <!-- <td><input type="text" readonly class="form-control"
                                value="{{data?.dynamic_sitting_A== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td><input type="text" readonly class="form-control"
                                value="{{data?.dynamic_sitting_D== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{data?.dynamic_sitting_N== 1? 'Yes': '-' ?? '-' }}" />
                            </td> -->
                          </tr>
                          <tr class="border-light">
                            <td scope="row"> <label class="px-0 ">Dynamic Standing</label></td>
                            <td><input type="text" readonly class="form-control"
                                value="{{ data?.dynamic_standing_I == 1 ? 'Independent' : data?.dynamic_standing_I == 2 ? 'Assistance' : data?.dynamic_standing_I == 3 ? 'Dependent' : data?.dynamic_standing_I == 4 ? 'Not Tested' : '-' }}" />
                            </td>
                            <td> <input type="text" readonly class="form-control" value="{{ data?.dynamic_standing_remark
                                      ?? '-' }}" /></td>
                            <!-- <td> <input type="text" readonly class="form-control"
                                value="{{data?.dynamic_standing_A== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td><input type="text" readonly class="form-control"
                                value="{{data?.dynamic_standing_D== 1? 'Yes': '-' ?? '-' }}" />
                            </td>
                            <td> <input type="text" readonly class="form-control"
                                value="{{data?.dynamic_standing_N== 1? 'Yes': '-' ?? '-' }}" />
                            </td> -->
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <!-- <div class="row subHeading">
                      <div class="col-sm-3 col-6">I - Independent</div>
                      <div class="col-sm-3 col-6">A - Assistance</div>
                      <div class="col-sm-3 col-6">D - Dependent</div>
                      <div class="col-sm-3 col-6">N - Not Tested</div>
                    </div> -->
                  </div>

                  <div class="row">
                    <h3>Transfer And Assisting Devices:</h3>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>None :</label>
                        <input type="text" readonly class="form-control"
                          value="{{data?.transfer_assisting == 1? 'Yes': '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Crutches </label>
                        <input type="text" readonly class="form-control"
                          value="{{data?.transfer_assisting== 2? 'Yes':  '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> One/Pair</label>
                        <input type="text" readonly class="form-control"
                          value="{{data?.transfer_assisting_pair?? '-' }}" />
                      </div>
                    </div>
                    <!-- <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Pair:</label>
                        <input type="text" readonly class="form-control"
                          value="{{data?.transfer_assisting_pair? 'Yes': '-' }}" />
                      </div>
                    </div> -->
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Walker:</label>
                        <input type="text" readonly class="form-control"
                          value="{{data?.transfer_assisting== 3? 'Yes':  '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Wheel Chair</label>
                        <input type="text" readonly class="form-control"
                          value="{{data?.transfer_assisting== 4? 'Yes': '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Other:</label>
                        <input type="text" readonly class="form-control"
                          value="{{data?.transfer_assisting== 5? 'Yes':  '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Other remark :</label>
                        <input type="text" readonly class="form-control"
                          value="{{data?.transfer_assisting_others_description ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Problem Noted :</label>
                        <input type="text" readonly class="form-control" value="{{data?.problems_noted ?? '-' }}" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-3 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label> Short Term(1 Month) :</label>
                          <input type="text" readonly class="form-control"
                            value="{{data?.short_term== 1? 'Yes': '-' ?? '-' }}" />
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label> Short Term Session :</label>
                          <input type="text" readonly class="form-control"
                            value="{{data?.short_term_session ?? '-' }}" />
                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label> Long Term(3-6 Month) :</label>
                          <input type="text" readonly class="form-control"
                            value="{{data?.long_term== 1? 'Yes': '-' ?? '-' }}" />

                        </div>
                      </div>
                      <div class="col-xl-3 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label>Long Term Session :</label>
                          <input type="text" readonly class="form-control"
                            value="{{data?.long_term_session ?? '-' }}" />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-12">
                      <div class="form-group">
                        <label class="subHeading p-0">Scope of Session:</label>
                        <input type="text" readonly class="form-control" value="{{data?.scope_session ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-sm-6 col-12">
                      <div class="form-group">
                        <label class="subHeading p-0">Recommended Duration:</label>
                        <input type="text" readonly class="form-control"
                          value="{{data?.recommended_duration?? '-' }}" />
                      </div>
                    </div>

                    <!-- No. of PT Sessions per Month -->
                    <div class="col-sm-6 col-12">
                      <div class="form-group">
                        <label class="subHeading p-0">No. of PT Sessions per Month:</label>
                        <input type="text" readonly class="form-control"
                          value="{{data?.no_pt_sesssion_per_month ?? '-' }}" />
                      </div>
                    </div>

                    <!-- Goals to be acheived -->
                    <div class="col-sm-6 col-12">
                      <div class="form-group">
                        <label class="subHeading p-0">Goals to be Acheived: </label>
                        <input type="text" readonly class="form-control" value="{{data?.goals_acheived?? '-' }}" />
                      </div>
                    </div>
                  </div>


                  <h3 class="fw-bold">Sessions Technique:</h3>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Exercise(ROM) :</label>
                        <input type="text" readonly class="form-control"
                          value="{{data?.exercise_checkbox== 1? 'Yes': '-' ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Duration:</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.exercise_duration  ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Details :</label>

                        <input type="text" readonly class="form-control" value="{{ data?.exercise_details
                              ?? '-' }}" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> TENS :</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.tens_checkbox == 1? 'Yes': '-'?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Duration:</label>
                        <input type="text" readonly class="form-control" value="{{ data?.tens_duration ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Details :</label>
                        <!-- <input type="text" readonly class="form-control" value="{{
                              data?.mother_para == 'null' ? '-' : data?.mother_para
                            }}" /> -->
                        <input type="text" readonly class="form-control" value="{{ data?.tens_details ?? '-' }}" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Ultrasound:</label>
                        <input type="text" readonly class="form-control" value="{{ data?.ultrasound_checkbox == 1? 'Yes': '-'
     ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Duration:</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.ultrasound_duration ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Details :</label>
                        <!-- <input type="text" readonly class="form-control" value="{{
                                data?.mother_para == 'null' ? '-' : data?.mother_para
                              }}" /> -->
                        <input type="text" readonly class="form-control"
                          value="{{ data?.ultrasound_details ?? '-' }}" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Hot Packs :</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.hot_packs_checkbox== 1? 'Yes': '-'?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Duration:</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.hot_packs_duration ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Details :</label>
                        <!-- <input type="text" readonly class="form-control" value="{{
                                data?.mother_para == 'null' ? '-' : data?.mother_para
                              }}" /> -->
                        <input type="text" readonly class="form-control" value="{{ data?.hot_packs_details ?? '-' }}" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Stimulation :</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.stimulation_checkbox == 1? 'Yes': '-'?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Duration:</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.stimulation_duration ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Details :</label>
                        <!-- <input type="text" readonly class="form-control" value="{{
                                data?.mother_para == 'null' ? '-' : data?.mother_para
                              }}" /> -->
                        <input type="text" readonly class="form-control"
                          value="{{ data?.stimulation_details ?? '-' }}" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Vibrator :</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.vibrator_checkbox == 1? 'Yes': '-'?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Duration:</label>
                        <input type="text" readonly class="form-control" value="{{ data?.vibrator_duration ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Details :</label>
                        <!-- <input type="text" readonly class="form-control" value="{{
                                data?.mother_para == 'null' ? '-' : data?.mother_para
                              }}" /> -->
                        <input type="text" readonly class="form-control" value="{{ data?.vibrator_details ?? '-' }}" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Tapping :</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.tapping_checkbox == 1? 'Yes': '-'?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Duration:</label>
                        <input type="text" readonly class="form-control" value="{{ data?.tapping_duration ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Details :</label>
                        <!-- <input type="text" readonly class="form-control" value="{{
                                data?.mother_para == 'null' ? '-' : data?.mother_para
                              }}" /> -->
                        <input type="text" readonly class="form-control" value="{{ data?.tapping_details ?? '-' }}" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Chest Therapy :</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.chest_therapy_checkbox == 1? 'Yes': '-'?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Duration:</label>
                        <input type="text" readonly class="form-control"
                          value="{{ data?.chest_therapy_duration ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Details :</label>
                        <!-- <input type="text" readonly class="form-control" value="{{
                                data?.mother_para == 'null' ? '-' : data?.mother_para
                              }}" /> -->
                        <input type="text" readonly class="form-control"
                          value="{{ data?.chest_therapy_details ?? '-' }}" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label>Others:</label>
                          <input type="text" readonly class="form-control"
                            value="{{ data?.session_technique_others_checkbox == 1? 'Yes': '-'?? '-' }}" />
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-12 col-md-12">
                        <div class="form-group">
                          <label>Others details:</label>
                          <input type="text" readonly class="form-control"
                            value="{{ data?.session_technique_others_details ?? '-' }}" />
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Re-assessment Date::</label>
                        <input type="text" readonly class="form-control" *ngIf="data?.re_date; else dash"
                          value="{{ data?.re_date | date:'dd-MM-yyyy' }}" />

                        <ng-template #dash>
                          <input type="text" readonly class="form-control" value="-" />
                        </ng-template>

                      </div>
                    </div>
                  </div>



                </form>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template pTemplate="footer">
          <p-button (click)="displayMaximizable = false" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template>
      </p-dialog>
    </div>
  </div>
  <app-copyrights></app-copyrights>
</div>