import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CommonApiService } from 'src/app/services/common-api.service';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { GrowthMonitorService } from 'src/app/services/growth-monitor.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-zero-to-three-monitor',
  templateUrl: './zero-to-three-monitor.component.html',
  styleUrls: ['./zero-to-three-monitor.component.scss'],
  providers: [MessageService],
})
export class ZeroToThreeMonitorComponent implements OnInit, OnDestroy {
  @ViewChild('video') videoElement: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  stream: MediaStream;
  ImageName = "Captured-Image"
  openCameraforUpload: boolean = false
  IndexforImage = 0
  capturedImg: File
  imagePreview: boolean = false
  weightConverted = false;
  heightConverted = false;
  convertedHeight: string = '';
  stringifiedData: any;
  public flag: boolean = true;
  breadcrumb = [
    {
      title: 'Zero To Three Month Growth Monitoring',
      subtitle: 'Dashboard',
    },
  ];
  genderArray: any = [
    { name: 'Male', value: 'male' },
    { name: 'Female', value: 'female' },
  ];
  heightUnits = [
    { label: 'Centimeters', value: 'cm' },
    { label: 'Inches', value: 'inches' }
  ];
  weight_unit: any = [
    { name: 'Kg', value: '1' },
    { name: 'Pounds', value: '2' },
  ]
  form: FormGroup;
  today = new Date();
  babyDetails: any = [];
  selectedDate: any = ''; // Initialize with today's date;
  babyGender: any;
  years: any = '';
  month: any = '';
  days: any = '';
  babyImgArray: any = []
  babyAge: any;
  name: any;
  zeroToThreeMonitor: FormGroup;
  isedit: boolean = false;
  ImageUrl: string = environment.imgUrl;

  descriptionControlNames: string[] = [
    'head_description',
    'holdhead_description',
    'clenches_description',
    'gripping_finger_description',
    'pushes_up_description',
    'head_up_description',
    'fists_move_description',
    'bring_hands_description',
    'move_legs_description',
    'enjoys_movements_description',
    'calm_description',
    'lying_description',
    'moving_toy_description',
    'quiets_description',
    'turns_head_description',
    'interest_description',
    'eye_contact_description',
    'cries_description',
    'smiles_description',
    'latches_description',
    'tounge_description',
    'drinks_description',
    'sucks_description',
    'enjoys_playful_description',
    'coos_reponse_description',
    'responds_sounds_description',
    'able_to_calm_description',
    'enjoys_baths_description',
    'tolerate_diaper_change_description',
    'cuddling_description',
    'upset_description',
    'happy_description',
    'playful_movment_description',
    'demonstrate_description'
  ];
  getToken: any;
  getRole: any;
  getname: any;
  growthData: any;
  selectedFile: any;
  babyImgJSon: any;
  capturedByCamera: any = [];
  imgSrcForPreview: any;
  constructor(private common_service: CommonApiService, private fb: FormBuilder, private messageService: MessageService, private growth_service: GrowthMonitorService, private router: Router, private spinner: NgxSpinnerService) {
    this.zeroToThreeMonitor = this.fb.group({
      growth_id: new FormControl(null),
      baby_details_id: new FormControl('', Validators.required),
      head: new FormControl(null),
      head_description: new FormControl(null),
      hold_head: new FormControl(null),
      holdhead_description: new FormControl(null),
      clenches: new FormControl(null),
      clenches_description: new FormControl(null),
      gripping_finger: new FormControl(null),
      gripping_finger_description: new FormControl(null),
      pushes_up: new FormControl(null),
      pushes_up_description: new FormControl(null),
      head_up: new FormControl(null),
      head_up_description: new FormControl(null),
      fists_move: new FormControl(null),
      fists_move_description: new FormControl(null),
      bring_hands: new FormControl(null),
      bring_hands_description: new FormControl(null),
      move_legs: new FormControl(null),
      move_legs1: new FormControl(null),
      enjoys_movements: new FormControl(null),
      enjoys_movements_description: new FormControl(null),
      move_legs_description: new FormControl(null),
      move_legs_description1: new FormControl(null),
      calm: new FormControl(null),
      calm_description: new FormControl(null),
      lying: new FormControl(null),
      lying_description: new FormControl(null),
      moving_toy: new FormControl(null),
      moving_toy_description: new FormControl(null),
      quiets: new FormControl(null),
      quiets_description: new FormControl(null),
      turns_head: new FormControl(null),
      turns_head_description: new FormControl(null),
      interest: new FormControl(null),
      interest_description: new FormControl(null),
      eye_contact: new FormControl(null),
      eye_contact_description: new FormControl(null),
      cries: new FormControl(null),
      cries_description: new FormControl(null),
      smiles: new FormControl(null),
      smiles_description: new FormControl(null),
      latches: new FormControl(null),
      latches_description: new FormControl(null),
      tounge: new FormControl(null),
      tounge_description: new FormControl(null),
      drinks: new FormControl(null),
      drinks_description: new FormControl(null),
      sucks: new FormControl(null),
      sucks_description: new FormControl(null),
      enjoys_playful: new FormControl(null),
      enjoys_playful_description: new FormControl(null),
      coos_reponse: new FormControl(null),
      coos_reponse_description: new FormControl(null),
      responds_sounds: new FormControl(null),
      responds_sounds_description: new FormControl(null),
      able_to_calm: new FormControl(null),
      able_to_calm_description: new FormControl(null),
      enjoys_bath: new FormControl(null),
      enjoys_baths_description: new FormControl(null),
      tolerate_diaper_change: new FormControl(null),
      tolerate_diaper_change_description: new FormControl(null),
      cuddling: new FormControl(null),
      cuddling_description: new FormControl(null),
      upset: new FormControl(null),
      upset_description: new FormControl(null),
      happy: new FormControl(null),
      happy_description: new FormControl(null),
      playful_movment: new FormControl(null),
      playful_movment_description: new FormControl(null),
      demonstrate: new FormControl(null),
      demonstrate_description: new FormControl(null),
      health_json: this.fb.array([]),
      baby_images: this.fb.array([])
    });
    // this.form.get('head_description').disable();
  }

  ngOnInit(): void {
    this.spinner.show()
    this.common_service.getBabyDetails().subscribe((res: any) => {
      this.spinner.hide()

      this.babyDetails = res?.data;
      this.setBasicDetails();
    }, (error: any) => {
      this.spinner.hide()

      // Handle any errors that occurred during the API call
      

    });
    this.getname = localStorage.getItem('name');
    this.getToken = localStorage.getItem('token');
    this.getRole = localStorage.getItem('role')
    this.zeroToThreeMonitor.get('height')?.valueChanges.subscribe(value => {
    });
  }
  ngOnDestroy(): void {
    this.shutDownCamera();
    // Perform cleanup actions here, such as closing the camera
    // Close the camera code here...
  }
  setBasicDetails() {
    if (localStorage.getItem('editedOrderId')) {
      this.getEditFormData();
      this.isedit = true;
    }
    else if (localStorage.getItem('babyId')) {
      const getEdit = localStorage.getItem('babyId');
      this.getBabyDetails({ 'value': getEdit })
      this.zeroToThreeMonitor.controls.baby_details_id.setValue(
        JSON.parse(getEdit)
      );
      this.addVitaminObject('add', 0);
      this.addBabyImg('add', 0)
      this.isedit = false;
      this.zeroToThreeMonitor.controls.head_description.disable();
      this.zeroToThreeMonitor.controls.holdhead_description.disable();
      this.zeroToThreeMonitor.controls.clenches_description.disable();
      this.zeroToThreeMonitor.controls.gripping_finger_description.disable();
      this.zeroToThreeMonitor.controls.pushes_up_description.disable();
      this.zeroToThreeMonitor.controls.head_up_description.disable();
      this.zeroToThreeMonitor.controls.fists_move_description.disable();
      this.zeroToThreeMonitor.controls.bring_hands_description.disable();
      this.zeroToThreeMonitor.controls.move_legs_description.disable();
      this.zeroToThreeMonitor.controls.move_legs_description1.disable();
      this.zeroToThreeMonitor.controls.moving_toy_description.disable();
      this.zeroToThreeMonitor.controls.lying_description.disable();
      this.zeroToThreeMonitor.controls.calm_description.disable();
      this.zeroToThreeMonitor.controls.enjoys_movements_description.disable();
      this.zeroToThreeMonitor.controls.quiets_description.disable();
      this.zeroToThreeMonitor.controls.turns_head_description.disable();
      this.zeroToThreeMonitor.controls.interest_description.disable();
      this.zeroToThreeMonitor.controls.eye_contact_description.disable();
      this.zeroToThreeMonitor.controls.cries_description.disable();
      this.zeroToThreeMonitor.controls.smiles_description.disable();
      this.zeroToThreeMonitor.controls.latches_description.disable();
      this.zeroToThreeMonitor.controls.tounge_description.disable();
      this.zeroToThreeMonitor.controls.drinks_description.disable();
      this.zeroToThreeMonitor.controls.sucks_description.disable();
      this.zeroToThreeMonitor.controls.enjoys_playful_description.disable();
      this.zeroToThreeMonitor.controls.coos_reponse_description.disable();

      this.zeroToThreeMonitor.controls.responds_sounds_description.disable();
      this.zeroToThreeMonitor.controls.able_to_calm_description.disable();
      this.zeroToThreeMonitor.controls.enjoys_baths_description.disable();
      this.zeroToThreeMonitor.controls.tolerate_diaper_change_description.disable();
      this.zeroToThreeMonitor.controls.upset_description.disable();
      this.zeroToThreeMonitor.controls.happy_description.disable();
      this.zeroToThreeMonitor.controls.playful_movment_description.disable();
      this.zeroToThreeMonitor.controls.demonstrate_description.disable();

    }
  }
  keyPress(event: KeyboardEvent) {
    const pattern = /^\d+(\.\d{0,2})?$/;

    const inputValue = (event.target as HTMLInputElement).value;
    const proposedValue = inputValue + String.fromCharCode(event.charCode);

    if (!pattern.test(proposedValue)) {
      // Invalid input, prevent input
      event.preventDefault();
    }
  }
  /* -------------------------------------------------------------------------- */
  /*            //Soft Reloading            */
  /* -------------------------------------------------------------------------- */
  softReload() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['portal/patient-view']);
    });
  }

  /* -------------------------------------------------------------------------- */
  /*                // get baby details and show dob accordingly                */
  /* -------------------------------------------------------------------------- */
  getBabyDetails(event: any) {
    // let data = this.babyDetails?.filter((res: any) => res?.id == event?.value);
    // let firstLetter = data[0]?.gender.toLowerCase();
    // let capitalize = (firstLetter.slice(0, 1).toUpperCase()) + (firstLetter.slice(1, firstLetter.length));
    // this.babyGender = capitalize;
    // this.selectedDate = data[0].date_of_birth;
    // if (data[0]?.date_of_birth) {
    //   const today = new Date();
    //   const selectedDate = new Date(data[0]?.date_of_birth);
    //   const time = today.getTime() - selectedDate.getTime();
    //   const date = time / (1000 * 3600 * 24);

    //   var diff = Math.floor(today.getTime() - selectedDate.getTime());
    //   var day = 1000 * 60 * 60 * 24;
    //   this.days = Math.floor(diff / day);
    //   this.month = Math.floor(this.days / 31);
    //   this.years = Math.floor(this.month / 12);
    //   let total_months = this.years * 12;
    //   let total_days = this.month * 31;
    //   this.month = this.month - total_months;
    //   this.days = this.days - total_days;
    //  
    // } else {
    //   this.month = '';
    //   this.years = '';
    //   this.years = '';
    // }
    let data = this.babyDetails?.filter((res: any) => res?.id == event?.value);
    let firstLetter = data[0]?.gender.toLowerCase();
    let capitalize =
      firstLetter.slice(0, 1).toUpperCase() +
      firstLetter.slice(1, firstLetter.length);
    this.babyGender = capitalize;
    this.selectedDate = data[0].date_of_birth;
    // Manually parse the date string in DD/MM/YYYY format
    const dateParts = data[0].date_of_birth.split('/');
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Months are 0-indexed in JavaScript
    const year = parseInt(dateParts[2], 10);

    const selectedDate = moment([year, month, day]);
    const today = moment();

    // Calculate the difference in years, months, and days
    const years = today.diff(selectedDate, 'years');
    selectedDate.add(years, 'years');

    const months = today.diff(selectedDate, 'months');
    selectedDate.add(months, 'months');

    const days = today.diff(selectedDate, 'days');

    this.years = years;
    this.month = months;
    this.days = days;
    if (
      (this.years == 0 && this.month < 4) || // Less than 3 months old
      (this.years == 0 && this.days < 119) || // Less than 119 days old
      (this.years == 0 && this.month == 3 && this.days == 0) // Exactly 3 months old
    ) {
      this.flag = true;
    }
    else {
      this.flag = false;
      this.messageService.add({
        severity: 'info',
        summary: 'Info',
        detail:
          'Your child does not meet the eligibility criteria for this plan.',
      });
    }
    // if (this.years != null) {
    //   if (this.years == 1 || this.years == 2) {
    //     this.babyAge = '1 - 2 Years';
    //   } else if (this.years == 3 || this.years == 4) {
    //     this.babyAge = '3 - 4 Years';
    //   } else if (this.years == 4 || this.years == 5) {
    //     this.babyAge = '4 - 5 Years';
    //   } else if (this.years == 5 || this.years == 6) {
    //     this.babyAge = '5 - 6 Years';
    //   } else if (this.years < 1) {
    //     if (this.month > 0 && this.month <= 3) {
    //       this.babyAge = '0 - 3 Months';
    //     } else if (this.month > 3 && this.month <= 11) {
    //       this.babyAge = '4 Months - 1 Year';
    //     } else {
    //       this.babyAge = 'not found';
    //     }
    //   }
    // } else {
    //   this.years = 0;
    //   this.month = 0;
    //   this.days = 0;
    //   this.babyAge = 'not found';
    // }
  }
  /* -------------------------------------------------------------------------- */
  /*                           // get edit form data function                          */
  /* -------------------------------------------------------------------------- */
  getEditFormData() {
    this.spinner.show()
    const getEdit = localStorage.getItem('editedOrderId')
    this.common_service.getEditGrowthMonitoring(getEdit).then((res: any) => {
      this.spinner.hide()

      const detailsInGrowthMonitoring = res.data[0];
      const developmentMilestones = res.data[0].development_milestones;
      const physical_assessment_details_json = developmentMilestones[0].physical_assessment_details_json

      const milestoneData = JSON.parse(developmentMilestones[0].development_milestone);
      this.growthData = milestoneData

      this.babyGender = detailsInGrowthMonitoring.gender;
      this.selectedDate = detailsInGrowthMonitoring.date_of_birth;
      this.days = detailsInGrowthMonitoring.days;
      this.month = detailsInGrowthMonitoring.months;
      this.years = detailsInGrowthMonitoring.years;
      const VitaminsMedication = physical_assessment_details_json;

      var check = true
      for (let i = 0; i < VitaminsMedication.length; i++) {
        if ((VitaminsMedication[i].height != null) || (VitaminsMedication[i].time != null) || (VitaminsMedication[i].weight != null)) {
          check = false
          let vitaminArray = this.gethealthFormArray();
          let VitaminsMedicationFromApi = VitaminsMedication[i] || {};

          let newVitaminArray = new FormGroup({
            height: new FormControl(VitaminsMedicationFromApi.height || null),
            time: new FormControl(
              VitaminsMedicationFromApi.time
                ? new Date(VitaminsMedicationFromApi.time)
                : null
            ),
            weight: new FormControl(VitaminsMedicationFromApi.weight || null),

          });

          vitaminArray.insert(i, newVitaminArray);
        }

      }







      this.babyImgJSon = developmentMilestones[0].growth_images_json;
      this.addBabyImg('add', 0);


      // for (let i = 0; i < babyImg.length; i++) {
      //   let babyImgArray = this.getImageArray();
      //   let babyImgFromApi = babyImg[i] || {};

      //   let newbabyImg = new FormGroup({
      //     // upload_photo: new FormControl(babyImgFromApi.upload_photo ?babyImgFromApi.upload_photo : null || ''),      
      //   });
      //   babyImgArray.insert(i, newbabyImg);
      // }
      // debugger
      this.zeroToThreeMonitor.controls.baby_details_id.setValue(detailsInGrowthMonitoring.baby_id)

      this.zeroToThreeMonitor.controls.head.setValue(milestoneData.head);
      this.zeroToThreeMonitor.get('head_description').setValue(milestoneData.head_description);
      // this.zeroToThreeMonitor.controls.head_description.setValue(milestoneData.head_description);
      this.zeroToThreeMonitor.controls.hold_head.setValue(milestoneData.hold_head);
      this.zeroToThreeMonitor.get('holdhead_description').setValue(milestoneData.holdhead_description);
      // this.zeroToThreeMonitor.controls.holdhead_description.setValue(milestoneData.holdhead_description);
      this.zeroToThreeMonitor.controls.clenches.setValue(milestoneData.clenches);
      this.zeroToThreeMonitor.get('clenches_description').setValue(milestoneData.clenches_description);
      // this.zeroToThreeMonitor.controls.clenches_description.setValue(milestoneData.clenches_description);
      this.zeroToThreeMonitor.controls.gripping_finger.setValue(milestoneData.gripping_finger);
      this.zeroToThreeMonitor.controls.gripping_finger_description.setValue(milestoneData.gripping_finger_description);
      this.zeroToThreeMonitor.controls.pushes_up.setValue(milestoneData.pushes_up);
      this.zeroToThreeMonitor.controls.pushes_up_description.setValue(milestoneData.pushes_up_description);
      this.zeroToThreeMonitor.controls.head_up.setValue(milestoneData.head_up);
      this.zeroToThreeMonitor.controls.head_up_description.setValue(milestoneData.head_up_description);
      this.zeroToThreeMonitor.controls.fists_move.setValue(milestoneData.fists_move);
      this.zeroToThreeMonitor.controls.fists_move_description.setValue(milestoneData.fists_move_description);
      this.zeroToThreeMonitor.controls.bring_hands.setValue(milestoneData.bring_hands);
      this.zeroToThreeMonitor.controls.bring_hands_description.setValue(milestoneData.bring_hands_description);
      this.zeroToThreeMonitor.controls.move_legs.setValue(milestoneData.move_legs);
      this.zeroToThreeMonitor.controls.move_legs1.setValue(milestoneData.move_legs1);
      this.zeroToThreeMonitor.controls.enjoys_movements.setValue(milestoneData.enjoys_movements);
      this.zeroToThreeMonitor.controls.enjoys_movements_description.setValue(milestoneData.bring_hands_description);
      this.zeroToThreeMonitor.controls.move_legs_description.setValue(milestoneData.move_legs_description);
      this.zeroToThreeMonitor.controls.move_legs_description1.setValue(milestoneData.move_legs_description1);
      this.zeroToThreeMonitor.controls.calm.setValue(milestoneData.calm);
      this.zeroToThreeMonitor.controls.calm_description.setValue(milestoneData.calm_description);
      this.zeroToThreeMonitor.controls.lying.setValue(milestoneData.lying);
      this.zeroToThreeMonitor.controls.lying_description.setValue(milestoneData.lying_description);
      this.zeroToThreeMonitor.controls.moving_toy.setValue(milestoneData.moving_toy);
      this.zeroToThreeMonitor.controls.moving_toy_description.setValue(milestoneData.moving_toy_description);
      this.zeroToThreeMonitor.controls.quiets.setValue(milestoneData.quiets);
      this.zeroToThreeMonitor.controls.quiets_description.setValue(milestoneData.quiets_description);
      this.zeroToThreeMonitor.controls.turns_head.setValue(milestoneData.turns_head);
      this.zeroToThreeMonitor.controls.turns_head_description.setValue(milestoneData.turns_head_description);
      this.zeroToThreeMonitor.controls.interest.setValue(milestoneData.interest);
      this.zeroToThreeMonitor.controls.interest_description.setValue(milestoneData.interest_description);
      this.zeroToThreeMonitor.controls.eye_contact.setValue(milestoneData.eye_contact);
      this.zeroToThreeMonitor.controls.eye_contact_description.setValue(milestoneData.eye_contact_description);
      this.zeroToThreeMonitor.controls.cries.setValue(milestoneData.cries);
      this.zeroToThreeMonitor.controls.cries_description.setValue(milestoneData.cries_description);
      this.zeroToThreeMonitor.controls.smiles.setValue(milestoneData.smiles);
      this.zeroToThreeMonitor.controls.smiles_description.setValue(milestoneData.smiles_description);
      this.zeroToThreeMonitor.controls.latches.setValue(milestoneData.latches);
      this.zeroToThreeMonitor.controls.latches_description.setValue(milestoneData.latches_description);
      this.zeroToThreeMonitor.controls.tounge.setValue(milestoneData.tounge);
      this.zeroToThreeMonitor.controls.tounge_description.setValue(milestoneData.tounge_description);
      this.zeroToThreeMonitor.controls.drinks.setValue(milestoneData.drinks);
      this.zeroToThreeMonitor.controls.drinks_description.setValue(milestoneData.drinks_description);
      this.zeroToThreeMonitor.controls.sucks.setValue(milestoneData.sucks);
      this.zeroToThreeMonitor.controls.sucks_description.setValue(milestoneData.sucks_description);
      this.zeroToThreeMonitor.controls.enjoys_playful.setValue(milestoneData.enjoys_playful);
      this.zeroToThreeMonitor.controls.enjoys_playful_description.setValue(milestoneData.enjoys_playful_description);
      this.zeroToThreeMonitor.controls.coos_reponse.setValue(milestoneData.coos_reponse);
      this.zeroToThreeMonitor.controls.coos_reponse_description.setValue(milestoneData.coos_reponse_description);
      this.zeroToThreeMonitor.controls.responds_sounds.setValue(milestoneData.responds_sounds);
      this.zeroToThreeMonitor.controls.responds_sounds_description.setValue(milestoneData.responds_sounds_description);
      this.zeroToThreeMonitor.controls.able_to_calm.setValue(milestoneData.able_to_calm);
      this.zeroToThreeMonitor.controls.able_to_calm_description.setValue(milestoneData.able_to_calm_description);
      this.zeroToThreeMonitor.controls.enjoys_bath.setValue(milestoneData.enjoys_bath);
      this.zeroToThreeMonitor.controls.enjoys_baths_description.setValue(milestoneData.enjoys_baths_description);
      this.zeroToThreeMonitor.controls.tolerate_diaper_change.setValue(milestoneData.tolerate_diaper_change);
      this.zeroToThreeMonitor.controls.tolerate_diaper_change_description.setValue(milestoneData.tolerate_diaper_change_description);
      this.zeroToThreeMonitor.controls.cuddling.setValue(milestoneData.cuddling);
      this.zeroToThreeMonitor.controls.cuddling_description.setValue(milestoneData.cuddling_description);
      this.zeroToThreeMonitor.controls.upset.setValue(milestoneData.upset);
      this.zeroToThreeMonitor.controls.upset_description.setValue(milestoneData.upset_description);
      this.zeroToThreeMonitor.controls.happy.setValue(milestoneData.happy);
      this.zeroToThreeMonitor.controls.happy_description.setValue(milestoneData.happy_description);
      this.zeroToThreeMonitor.controls.playful_movment.setValue(milestoneData.playful_movment);
      this.zeroToThreeMonitor.controls.playful_movment_description.setValue(milestoneData.playful_movment_description);
      this.zeroToThreeMonitor.controls.demonstrate.setValue(milestoneData.demonstrate);
      this.zeroToThreeMonitor.controls.demonstrate_description.setValue(milestoneData.demonstrate_description);
      this.disableForm()

      if (check == true) {
        this.addVitaminObject('add', 0)
      }


    }, (error: any) => {
      this.spinner.hide()

      // Handle any errors that occurred during the API call
      

    })
  }
  /* -------------------------------------------------------------------------- */
  /*                           // submit form function                          */
  /* -------------------------------------------------------------------------- */
  submitGrowthMonitoringForm(form: FormGroupDirective) {

    this.zeroToThreeMonitor.enable()
    //   const previousData = this.growthData?this.growthData:null
    //   let data = JSON.parse(JSON.stringify(form.value))
    //   const newData = data
    //   const mergedObject = { ...newData };
    //  if (previousData) { 

    //     for (const [key, value] of Object.entries(previousData)) {
    //       if ((value !== null && key == 'health_json') && typeof value === 'object') {
    //       if (Object.keys(value).length > 0) {

    //         let a = newData[key];
    //         if (mergedObject.hasOwnProperty(key)) {
    //           mergedObject[key] = [];
    //          }
    //          let b =previousData[key]
    //          if (previousData.hasOwnProperty(key)) {
    //           mergedObject[key] = [];
    //          }
    //          if(b){
    //           if (previousData.hasOwnProperty(key)) {
    //             b.forEach(element => {
    //               if(element != undefined){
    //                 mergedObject[key].push(element);
    //               }
    //           });
    //         }
    //          }
    //         if(a){
    //           if (mergedObject.hasOwnProperty(key)) {
    //             a.forEach(element => {
    //               if(element != undefined){
    //                 mergedObject[key].push(element);
    //               }          });

    //         }
    //         }


    //      }
    //       }

    //         if (value !== null && typeof value === 'object') {
    //             // Check if the value is an object

    //             if (!mergedObject.hasOwnProperty(key)) {
    //                 mergedObject[key] = {};
    //             }
    //             for (const [nestedKey, nestedValue] of Object.entries(value)) {

    //               if (
    //                     !mergedObject[key].hasOwnProperty(nestedKey) &&
    //                     nestedValue !== null &&
    //                     nestedValue !== undefined &&
    //                     typeof nestedValue === 'string' &&
    //                     nestedValue.trim() !== ''
    //                 ) {
    //                     mergedObject[key][nestedKey] = nestedValue;
    //                 }


    //             }
    //         } else {
    //             if (
    //                 !mergedObject.hasOwnProperty(key) &&
    //                 value !== null &&
    //                 value !== undefined &&
    //                 typeof value === 'string' &&
    //                 value.trim() !== ''
    //             ) {
    //                 mergedObject[key] = value;
    //             }
    //         }
    //     }
    // }



    window.scrollTo(0, 0);
    if (this.zeroToThreeMonitor.valid) {
      let jsonData = JSON.stringify(this.zeroToThreeMonitor.value);
      // debugger
      // let dataObject = {
      //   growth_id: 1,
      //   baby_details_id: this.zeroToThreeMonitor.value.baby_details_id,
      //   growth_monitoring_period_id: 1,
      //   development_milestones: jsonData,
      //   physical_assessment_details_json: null,
      // };

      let formData = new FormData;
      formData.append('growth_id', '1')
      formData.append('baby_details_id', this.zeroToThreeMonitor.value.baby_details_id)
      formData.append('growth_monitoring_period_id', '1')
      formData.append('development_milestones', jsonData)
      let images = JSON.stringify(this.zeroToThreeMonitor.value.health_json);

      formData.append('physical_assessment_details_json', images)

      this.babyImgArray?.forEach((file) => {
        formData.append('media', file);
      });
      if (this.isedit == true) {
        const id = localStorage.getItem('editedOrderId')
        this.spinner.show();
        this.growth_service.editGrowthMonitorForm(formData, id).then(
          (res: any) => {
            this.spinner.hide()
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: res?.message,
            });

            setTimeout(() => {
              this.router.navigateByUrl('/portal/first-screen')
            }, 1000);
            this.isedit = false;
            // this.spinner.hide();
          },
          (error: any) => {
            this.spinner.hide()

            // Handle any errors that occurred during the API call
            
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error?.message,
            });
          }
        );
      } else {
        this.spinner.show();

        this.growth_service.postGrowthMonitorForm(formData).then(
          (res: any) => {
            this.spinner.hide()

            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: res?.message,
            });
            setTimeout(() => {
              this.router.navigateByUrl('/portal/first-screen')
            }, 400);
            // this.spinner.hide();
          },
          (error: any) => {
            this.spinner.hide()

            // Handle any errors that occurred during the API call
            
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error?.message,
            });
          }
        );
      }

    } else {
      this.spinner.hide()

      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Fill All the Details!',
      });
    }

    this.zeroToThreeMonitor.reset();
  }
  /************************************************************************** */
  // yes no togglemark
  /************************************************************************** */

  handleInput(event: any, value: string) {

    if (value == 'headside') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.head_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.head_description.disable();
        this.zeroToThreeMonitor.controls.head_description.setValue(null);
      }
    } else if (value == 'headhold') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.holdhead_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.holdhead_description.disable();
        this.zeroToThreeMonitor.controls.holdhead_description.setValue(null);
      }
    } else if (value == 'clenches') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.clenches_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.clenches_description.disable();
        this.zeroToThreeMonitor.controls.clenches_description.setValue(null);
      }
    } else if (value == 'gripping') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.gripping_finger_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.gripping_finger_description.disable();
        this.zeroToThreeMonitor.controls.gripping_finger_description.setValue(
          null
        );
      }
    } else if (value == 'pushes') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.pushes_up_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.pushes_up_description.disable();
        this.zeroToThreeMonitor.controls.pushes_up_description.setValue(null);
      }
    } else if (value == 'lifts') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.head_up_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.head_up_description.disable();
        this.zeroToThreeMonitor.controls.head_up_description.setValue(null);
      }
    } else if (value == 'lifts') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.head_up_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.head_up_description.disable();
        this.zeroToThreeMonitor.controls.head_up_description.setValue(null);
      }
    } else if (value == 'fists') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.fists_move_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.fists_move_description.disable();
        this.zeroToThreeMonitor.controls.fists_move_description.setValue(null);
      }
    } else if (value == 'bring_hands') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.bring_hands_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.bring_hands_description.disable();
        this.zeroToThreeMonitor.controls.bring_hands_description.setValue(null);
      }
    } else if (value == 'move_legs') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.move_legs_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.move_legs_description.disable();
        this.zeroToThreeMonitor.controls.move_legs_description.setValue(null);
      }
    } else if (value == 'move_legs1') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.move_legs_description1.enable();
      } else {
        this.zeroToThreeMonitor.controls.move_legs_description1.disable();
        this.zeroToThreeMonitor.controls.move_legs_description1.setValue(null);
      }
    } else if (value == 'moving_toy') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.moving_toy_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.moving_toy_description.disable();
        this.zeroToThreeMonitor.controls.moving_toy_description.setValue(null);
      }
    } else if (value == 'lying') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.lying_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.lying_description.disable();
        this.zeroToThreeMonitor.controls.lying_description.setValue(null);
      }
    } else if (value == 'calm') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.calm_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.calm_description.disable();
        this.zeroToThreeMonitor.controls.calm_description.setValue(null);
      }
    } else if (value == 'enjoys') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.enjoys_movements_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.enjoys_movements_description.disable();
        this.zeroToThreeMonitor.controls.enjoys_movements_description.setValue(
          null
        );
      }
    } else if (value == 'quiets') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.quiets_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.quiets_description.disable();
        this.zeroToThreeMonitor.controls.quiets_description.setValue(null);
      }
    } else if (value == 'turns_head') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.turns_head_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.turns_head_description.disable();
        this.zeroToThreeMonitor.controls.turns_head_description.setValue(null);
      }
    } else if (value == 'interest') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.interest_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.interest_description.disable();
        this.zeroToThreeMonitor.controls.interest_description.setValue(null);
      }
    } else if (value == 'eye_contact_description') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.eye_contact_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.eye_contact_description.disable();
        this.zeroToThreeMonitor.controls.eye_contact_description_description.setValue(
          null
        );
      }
    } else if (value == 'cries') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.cries_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.cries_description.disable();
        this.zeroToThreeMonitor.controls.cries_description.setValue(null);
      }
    } else if (value == 'smiles') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.smiles_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.smiles_description.disable();
        this.zeroToThreeMonitor.controls.smiles_description.setValue(null);
      }
    } else if (value == 'latches') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.latches_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.latches_description.disable();
        this.zeroToThreeMonitor.controls.latches_description.setValue(null);
      }
    } else if (value == 'tounge') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.tounge_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.tounge_description.disable();
        this.zeroToThreeMonitor.controls.tounge_description.setValue(null);
      }
    } else if (value == 'drinks') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.drinks_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.drinks_description.disable();
        this.zeroToThreeMonitor.controls.drinks_description.setValue(null);
      }
    } else if (value == 'sucks') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.sucks_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.sucks_description.disable();
        this.zeroToThreeMonitor.controls.sucks_description.setValue(null);
      }
    } else if (value == 'enjoys-playful') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.enjoys_playful_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.enjoys_playful_description.disable();
        this.zeroToThreeMonitor.controls.enjoys_playful_description.setValue(
          null
        );
      }
    } else if (value == 'coos') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.coos_reponse_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.coos_reponse_description.disable();
        this.zeroToThreeMonitor.controls.coos_reponse_description.setValue(
          null
        );
      }
    } else if (value == 'responds') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.responds_sounds_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.responds_sounds_description.disable();
        this.zeroToThreeMonitor.controls.responds_sounds_description.setValue(
          null
        );
      }
    } else if (value == 'able_to') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.able_to_calm_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.able_to_calm_description.disable();
        this.zeroToThreeMonitor.controls.able_to_calm_description.setValue(
          null
        );
      }
    } else if (value == 'enjoys_baths') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.enjoys_baths_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.enjoys_baths_description.disable();
        this.zeroToThreeMonitor.controls.enjoys_baths_description.setValue(
          null
        );
      }
    } else if (value == 'tolerate_diaper') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.tolerate_diaper_change_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.tolerate_diaper_change_description.disable();
        this.zeroToThreeMonitor.controls.tolerate_diaper_change_description.setValue(
          null
        );
      }
    }
    else if (value == 'cuddling') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.cuddling_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.cuddling_description.disable();
        this.zeroToThreeMonitor.controls.cuddling_description.setValue(
          null
        );
      }
    } else if (value == 'upset') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.upset_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.upset_description.disable();
        this.zeroToThreeMonitor.controls.upset_description.setValue(null);
      }
    } else if (value == 'happy') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.happy_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.happy_description.disable();
        this.zeroToThreeMonitor.controls.happy_description.setValue(null);
      }
    } else if (value == 'playful') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.playful_movment_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.playful_movment_description.disable();
        this.zeroToThreeMonitor.controls.playful_movment_description.setValue(
          null
        );
      }
    } else if (value == 'demonstrate') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.zeroToThreeMonitor.controls.demonstrate_description.enable();
      } else {
        this.zeroToThreeMonitor.controls.demonstrate_description.disable();
        this.zeroToThreeMonitor.controls.demonstrate_description.setValue(null);
      }
    }

  }
  gethealthFormArray() {
    return this.zeroToThreeMonitor.get(
      'health_json'
    ) as FormArray;
  }
  addVitaminObject(string: any, index: any) {
    let vitaminArray = this.gethealthFormArray();
    if (string == 'add') {
      let newVitaminArray = new FormGroup({
        height: new FormControl(null),
        time: new FormControl(null),
        weight: new FormControl(null),
        heightUnit: new FormControl(null),
        weight_unit: new FormControl(null)
      });

      // let formatedTime =  moment(newTime).format('HH:mm:ss');
      vitaminArray.insert(index + 1, newVitaminArray);
    } else {
      vitaminArray.removeAt(index);
    }
  }


  getImageArray() {
    return this.zeroToThreeMonitor.get(
      'baby_images'
    ) as FormArray;
  }

  addBabyImg(string: any, index: any) {
    let imgArray = this.getImageArray();
    if (string == 'add') {
      let newbabyImg = new FormGroup({
        // upload_photo: new FormControl(''),
      });
      // let formatedTime =  moment(newTime).format('HH:mm:ss');
      imgArray.insert(index + 1, newbabyImg);
    } else {
      imgArray.removeAt(index);
    }
  }

  disableForm() {
    const formData = this.zeroToThreeMonitor.value;
    for (let key in formData) {
      if (formData[key] !== null && formData[key] !== undefined) {
        if (typeof formData[key] === 'object') {
          for (let nestedKey in formData[key]) {
            if (formData[key][nestedKey] !== null && formData[key][nestedKey] !== undefined) {
              const control = this.zeroToThreeMonitor.get(`${key}.${nestedKey}`);
              if (control) {
                control.disable();
              }
            }
          }
        } else {
          const control = this.zeroToThreeMonitor.get(key);
          if (control) {
            control.disable();
          }
        }
      }
    }
  }


  startCamera() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          this.videoElement.nativeElement.srcObject = stream;
        })
        .catch(error => {
        });
    }
  }

  openCamera() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          this.stream = stream;
          this.videoElement.nativeElement.srcObject = stream;
          this.openCameraforUpload = true
        })
        .catch(error => {
        });
    }
  }
  shutDownCamera() {
    this.openCameraforUpload = false;
    const video = this.videoElement.nativeElement;
    if (video.srcObject) {
      const stream = video.srcObject as MediaStream;
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop()); // Stop tracks to close the camera
      video.srcObject = null; // Reset the srcObject to release the camera resource
      this.openCameraforUpload = false
    }
  }
  capture() {
    ++this.IndexforImage
    const context = this.canvas.nativeElement.getContext('2d');
    const canvasWidth = this.canvas.nativeElement.width;
    const canvasHeight = this.canvas.nativeElement.height;
    context.drawImage(this.videoElement.nativeElement, 0, 0, canvasWidth, canvasHeight);
    const imageData = this.canvas.nativeElement.toDataURL('image/png');
    this.capturedByCamera.push(imageData)
    const byteString = atob(imageData.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: 'image/png' });
    this.capturedImg = new File([blob], `captured-image${this.IndexforImage}.png`, { type: 'image/png' });
    this.uploadPhoto(null, this.capturedImg)
    this.stopCamera();
  }

  stopCamera() {
    const video = this.videoElement.nativeElement;
    if (video.srcObject) {
      const stream = video.srcObject as MediaStream;
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop()); // Stop tracks to close the camera
      video.srcObject = null; // Reset the srcObject to release the camera resource
      this.openCameraforUpload = false
    }
  }

  uploadPhoto(event: any, captureImg?: any) {

    if (captureImg) {
      this.selectedFile = captureImg;
    }
    else {
      this.selectedFile = event.target.files[0];
    }
    if (event &&
      event.target.files[0].type == 'image/jpeg' ||
      'image/jpg' ||
      'image/png' ||
      'application/pdf'
    ) {
      if (event) {
        this.babyImgArray.push(event.target.files[0]);
      }
      else {
        this.babyImgArray.push(captureImg);

      }
    }

  }

  previewImage(imgSrc: any) {
    this.imagePreview = true
    this.imgSrcForPreview = imgSrc
  }
  deletecapture(imgSrc: any) {
    const byteString = atob(imgSrc.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: 'image/png' });

    const deleteIMG = new File([blob], `captured-image${this.IndexforImage}.png`, { type: 'image/png' });
    this.babyImgArray.forEach((file, index) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const arrayBuffer = e.target.result;

        if (this.arrayBuffersEqual(arrayBuffer, ab)) {
          this.babyImgArray.splice(index, 1); // Remove the file from babyImgArray
        }
      };
      reader.readAsArrayBuffer(file);
    });


    this.capturedByCamera = this.capturedByCamera.filter(item => item !== imgSrc);
    this.imagePreview = false;
  }

  arrayBuffersEqual(buf1: ArrayBuffer, buf2: ArrayBuffer): boolean {
    if (buf1.byteLength !== buf2.byteLength) return false;
    const dv1 = new DataView(buf1);
    const dv2 = new DataView(buf2);
    for (let i = 0; i < buf1.byteLength; i++) {
      if (dv1.getUint8(i) !== dv2.getUint8(i)) return false;
    }
    return true;
  }



  closePreview() {
    this.imagePreview = false
  }
  logout() {
    localStorage.clear();
    // window.location.reload();
    this.router.navigate(['/']);
  }





  // convertHeightToCm(group: FormGroup): void {

  //   const heightValue = group.get('height')?.value;

  //   if (heightValue) {
  //     const heightInInches = parseFloat(heightValue);
  //     if (!isNaN(heightInInches)) {
  //       const heightInCm = heightInInches * 2.54;
  //       group.patchValue({ height: heightInCm.toFixed(2) });
  //     }
  //   }
  // }


  // convertHeightToCm(index: number): void {

  //   const group = this.gethealthFormArray().at(index) as FormGroup;

  //   const heightValue = group.get('height')?.value;

  //   if (heightValue) {
  //     const heightInInches = parseFloat(heightValue);
  //     if (!isNaN(heightInInches)) {
  //       const heightInCm = heightInInches * 2.54;
  //       group.patchValue({ height: heightInCm.toFixed(2) });
  //     }
  //   }

  // convertKgToPounds(group: FormGroup): void {
  //   const weightValue = group.get('weight')?.value;
  //   if (weightValue) {
  //     const weightInKg = parseFloat(weightValue);
  //     if (!isNaN(weightInKg)) {
  //       const weightInPounds = weightInKg * 2.20462;
  //       group.patchValue({ weight: weightInPounds.toFixed(2) });
  //     }
  //   }
  // }
  // convertKgToPounds() {
  //   if (this.weightConverted) return; 

  //   // Get the FormArray from the form group
  //   const healthFormArray = this.zeroToThreeMonitor.get('health_json') as FormArray;
  
  //   // Loop through each form group in the FormArray
  //   healthFormArray.controls.forEach((control) => {
  //     // Get the current weight value
  //     const weightInKg = control.get('weight').value;
  
  //     // Convert weight to pounds if it's a valid number
  //     if (weightInKg && !isNaN(weightInKg)) {
  //       const weightInPounds = (parseFloat(weightInKg) * 2.20462).toFixed(2);
  //       control.get('weight').setValue(weightInPounds);
  //     }
  //   });
  // this.weightConverted = true; //

  // }
  
  // convertHeightToCm(): void {
  //   if (this.heightConverted) return;
  //   const healthFormArray = this.zeroToThreeMonitor.get('health_json') as FormArray;
  
  //   // Loop through each form group in the FormArray
  //   healthFormArray.controls.forEach((control) => {
  //     // Get the current weight value
  //     const heightIncm = control.get('height').value;

  //   if (heightIncm && !isNaN(heightIncm)) {
  //     const heightInInches = parseFloat(heightIncm);
  //     if (!isNaN(heightInInches)) {
  //       const heightInCm = heightInInches * 2.54;
  //       control.patchValue({ height: heightInCm.toFixed(2) });
  //     }
  //   }
  // });
  // this.heightConverted=true;
  // }
}
