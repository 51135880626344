<div class="main-content d-flex flex-column">
    <app-dashboard-navbar></app-dashboard-navbar>
  
    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
      <h1>{{ Content.title }}</h1>
      <ol class="breadcrumb">
        <li class="item"><a routerLink="/">Home</a></li>
        <li class="item">{{ Content.subTitle }}</li>
        <li class="item">{{ Content.title }}</li>
      </ol>
    </div>
    <div class="add-listings-box">
        <div class="Master-view-box">
          <div class="theader d-flex justify-content-between">
            <div class="d-flex w-100">
              <span class="p-input-icon-left ml-auto mr-2 w-100%">
                <i class="pi pi-search"></i>
                <input pInputText type="text" class="form-control" style="text-align: left; padding-left: 20%; height: 45px !important" scriptInjection placeholder="Search Keyword"
                  (input)="dt1.filterGlobal($event.target.value, 'contains')"  [(ngModel)]="filterVal" />
              </span>
              <button type="button" class="mx-2" icon="bx bx-reset" (click)="reset(dt1)" tooltipPosition="bottom" pButton style="height: 45px;"></button>
            </div>
            <div class="add-listings-btn" style="text-align: right">
              <a routerLink="/portal/daily-notes" class="nav-link">
                <button type="submit">Add</button>
              </a>
            </div>
          </div>
        </div>
        <div class="row mt-4">
            <div class="col-lg-12 col-md-12">
             
              <p-table #dt1 [value]="dailynotesdata" [rows]="10"  dataKey="id" [showCurrentPageReport]="true"
                [tableStyle]="{ 'min-width': '50rem', width: '100%' }" styleClass="p-datatable-sm p-datatable-customers"
                [rowsPerPageOptions]="[10, 25, 50]" responsiveLayout="scroll" [loading]="loading" [paginator]="true"
                currentPageReportTemplate="Showing {first} to
                {last} of {totalRecords} entries"
                [globalFilterFields]="['date', 'pain_tool_score', 'pain_discription']" editMode="row">
                <ng-template pTemplate="header">
                  <tr>
                    <th style="min-width: 62px">S. No.</th>
                    <th>Action</th>
                    <!-- <th>Date Of Birth</th> -->
                    <!-- <th>ID</th> -->
                    <th>Date</th>
                    <th>Score</th>
                    <th>Pain Description</th>
                  </tr>
                </ng-template>
                <!-- table to show data -->
                <ng-template pTemplate="body" let-list let-i="rowIndex">
                  <tr>
                    <td>
                      {{ i + 1 }}
                    </td>
                    <td>
                      <button pButton pRipple class="p-button-rounded p-button-text p-button-info" pTooltip="View"
                        tooltipPosition="bottom">
                        <i (click)="showMaximizableDialog(list)" class="far fa-eye"></i>
                      </button>
                    </td>
                    <!-- <td>{{ list?.baby_details_id ?? '-' }}</td> -->
                    <td>{{ list?.selectedDate  | date:'dd-MM-yyyy' ?? "-" }}</td>
                    <td>{{ list?.pain_tool_score ?? "-" }}</td>
                    <td>{{ list?.pain_discription ?? "-"}}
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="5">No Data Found.</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <!-- ****************************************************view dialog********************************************************* -->
     <!-- <p-dialogheader="Baby Details" [(visible)]="displayMaximizable" [modal]="true" [style]="{ width: '90vw' }"
     [draggable]="false" [resizable]="false"></p-dialog> -->
<!----------------------------------------------------------- view dialog --------------------------------------------------------->
      <!-- ***************************************************************************************************************** -->
      <p-dialog header="Daily Notes Details" [(visible)]="displayMaximizable" [modal]="true" [style]="{ width: '90vw' }"
      [draggable]="false" [resizable]="false">
      <!-- Start -->
      <ng-container  *ngFor="let data of viewDetail">
        <div class="row">
          <div class="col-lg-12">
            <div class="my-profile-box">
              <form>
                <!-- Basic detail -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Date :</label>
                      <input type="text" readonly class="form-control" value="{{ data?.selectedDate | date:'dd-MM-yyyy' ?? '-' }}" />

                      <!-- <input type="text" readonly class="form-control" value="{{ viewDetailJson?.selectedDate ?? '-' }}" /> -->
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Score:</label>
                      <input type="text" readonly class="form-control" value="{{ data?.pain_tool_score ?? '-' }}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Pain Description :</label>
                     
                    <!-- <input type="text" readonly class="form-control" *ngIf=" data?.pain_discription !== null &&  data?.pain_discription !== '' &&  data?.pain_discription !== undefined; else elseBlock"
                    value="{{ data?.pain_discription ??}}" />
                    <ng-template #elseBlock>
                      <input type="text" readonly class="form-control" value="-" />
                  </ng-template> -->
                      <input type="text" readonly class="form-control" value="{{ data?.pain_discription?? '-' }}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Specify:</label>
                      <input type="text" readonly class="form-control" value="{{ data?.pain_tool_specify ?? '-' }}" />
                    </div>
                  </div>
                 
                </div>
                
                <!-- intial assissment -->
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label>Vital Signs: Pre-Session</label>
                    <!-- <input type="text" readonly class="form-control" value="{{ data?.mode_delivery ?? '-' }}" /> -->
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label> Blood Pressure:
                    </label>
                    <input type="text" readonly class="form-control" value="{{ data?.blood_pressure
 ?? '-' }}" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Heart Rate:</label>
                      <input type="text" readonly class="form-control"
                        value="{{ data?.heart_rate ?? '-' }}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>SPO2:</label>
                      <input type="text" readonly class="form-control" value="{{ data?.spo2 ?? '-' }}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>RR:</label>
                      <input type="text" readonly class="form-control" value="{{ data?.rr ?? '-' }}" />
                    </div>
                  </div>
                </div>


                <!-- Mother detail -->
                <h3 class="fw-bold">Accomplished Modalities Of The Session</h3>
               
                 

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>  Exercise(ROM) :</label>
                      <input type="text" readonly class="form-control" value="{{data?.exercise_checkbox== 1? 'Yes': '-' ?? '-' }}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Duration:</label>
                      <input type="text" readonly class="form-control" value="{{ data?.exercise_duration  ?? '-' }}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label> Details :</label>
                      <!-- <input type="text" readonly class="form-control" value="{{
                          data?.mother_para == 'null' ? '-' : data?.mother_para
                        }}" /> -->
                        <input type="text" readonly class="form-control" value="{{ data?.exercise_details
                          ?? '-' }}" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label> TENS :</label>
                      <input type="text" readonly class="form-control" value="{{ data?.tens_checkbox == 1? 'Yes': '-'?? '-' }}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Duration:</label>
                      <input type="text" readonly class="form-control" value="{{ data?.tens_duration ?? '-' }}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label> Details :</label>
                      
                        <input type="text" readonly class="form-control" value="{{ data?.tens_details ?? '-' }}" />
                    </div>
                  </div>
                </div>
                <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>  Ultrasound:</label>
                        <input type="text" readonly class="form-control" value="{{ data?.ultrasound_checkbox == 1? 'Yes': '-'
 ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Duration:</label>
                        <input type="text" readonly class="form-control" value="{{ data?.ultrasound_duration ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Details :</label>
                       
                          <input type="text" readonly class="form-control" value="{{ data?.ultrasound_details ?? '-' }}" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>  Hot Packs :</label>
                        <input type="text" readonly class="form-control" value="{{ data?.hot_packs_checkbox== 1? 'Yes': '-'?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Duration:</label>
                        <input type="text" readonly class="form-control" value="{{ data?.hot_packs_duration ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Details :</label>
                     
                          <input type="text" readonly class="form-control" value="{{ data?.hot_packs_details ?? '-' }}" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Stimulation :</label>
                        <input type="text" readonly class="form-control" value="{{ data?.stimulation_checkbox == 1? 'Yes': '-'?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Duration:</label>
                        <input type="text" readonly class="form-control" value="{{ data?.stimulation_duration ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Details :</label>
                       
                          <input type="text" readonly class="form-control" value="{{ data?.stimulation_details ?? '-' }}" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>  Vibrator :</label>
                        <input type="text" readonly class="form-control" value="{{ data?.vibrator_checkbox == 1? 'Yes': '-'?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Duration:</label>
                        <input type="text" readonly class="form-control" value="{{ data?.vibrator_duration ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Details :</label>
                       
                          <input type="text" readonly class="form-control" value="{{ data?.vibrator_details ?? '-' }}" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Tapping :</label>
                        <input type="text" readonly class="form-control" value="{{ data?.tapping_checkbox == 1? 'Yes': '-'?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Duration:</label>
                        <input type="text" readonly class="form-control" value="{{ data?.tapping_duration ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Details :</label>
                        
                          <input type="text" readonly class="form-control" value="{{ data?.tapping_details ?? '-' }}" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>  Chest Therapy :</label>
                        <input type="text" readonly class="form-control" value="{{ data?.chest_therapy_checkbox == 1? 'Yes': '-'?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Duration:</label>
                        <input type="text" readonly class="form-control" value="{{ data?.chest_therapy_duration ?? '-' }}" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label> Details :</label>
                      
                          <input type="text" readonly class="form-control" value="{{ data?.chest_therapy_details ?? '-' }}" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>  Others :</label>
                        <input type="text" readonly class="form-control" value="{{ data?.session_technique_others_checkbox == 1? 'Yes': '-'?? '-' }}" />
                      </div>
    
                     
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-12">
                      <div class="form-group">
                        <label>Details:</label>
                        <input type="text" readonly class="form-control" value="{{ data?.session_technique_others_details ?? '-' }}" />
                      </div>
                    </div>
                  </div>
                 
                   <div class="col-sm-6 col-12">
                    <div class="form-group">
                      <label class="px-0">Comments:</label>
                      <input type="text" readonly class="form-control" value="{{ data?.comments ?? '-' }}" />
                    </div>
                  </div>
                 
                  <div class="col-sm-6 col-12">
                    <div class="form-group">
                      <label class="px-0">Therapied Part of the Body:</label>
                      <input type="text" readonly class="form-control" value="{{ data?.therapied_part ?? '-' }}" />
                    </div>
                  </div>

                <!-- Attachment-->
                <h3 class="fw-bold">Attachment</h3>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Session goals Achieved  :</label>
                      <input type="text" readonly class="form-control" *ngIf=" data?.session_goal !== null &&  data?.session_goal !== '' &&  data?.session_goal !== undefined; else elseBlock"
                      value="{{ data?.session_goal == '1'? 'Yes': 'No'}}" />
                      <ng-template #elseBlock>
                        <input type="text" readonly class="form-control" value="-" />
                    </ng-template>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Reason :</label>
                      <input type="text" readonly class="form-control" value="{{ data?.session_reason ?? '-' }}" />
                    </div>
                  </div>




         
                </div>
              </form>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template pTemplate="footer">
        <p-button (click)="displayMaximizable = false" label="Ok"
          styleClass="p-button-text"></p-button>
      </ng-template>
    </p-dialog>
  </div>
</div>
<app-copyrights></app-copyrights>
</div>
