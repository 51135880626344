import { Component, OnInit } from '@angular/core';
import { MaikaListService } from 'src/app/services/maika-list.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-nanny-daily-monitor',
  templateUrl: './nanny-daily-monitor.component.html',
  styleUrls: ['./nanny-daily-monitor.component.scss'],
})
export class NannyDailyMonitorComponent implements OnInit {
  parsedData: any = [];
  datalength: any;
  breadcrumb = [
    {
      title: 'Nurse Daily Monitoring',
      subTitle: 'Dashboard',
    },
  ];
  protein: any = {};
  dairy_food: any = {};
  vegetables: any = {};
  fruits: any = {};
  cerals_grains: any = {};
  breastfeeding:any
  feedingData: any = [];
  feeding_json: any;
  feeding_formula_json: any;
  solidFoodFeeding: any = [];
  sleep_training: any = {};
  diapering: any = [];
  vitamins_and_medications: any = [];
  bathing_frequency: any;
  bathing: any = [];
  feeding_breast: any = [];
  feeding_formula: any = [];
  solid_food_feeding: any[] = [];
  sleeping: any = [];
  bed_time_routine: any = {};
  displayMaximizable: boolean = false;
  viewDetail: any = [];
  NannyDailyMonitoringList: any;
  loading: any;
  babyData: any = {};
  data: any = [];
  filteredPDFData: any[];
  pdfData: any[];
  exportColumns: any = [];
  cols: any;
  constructor(private daily_monitor: MaikaListService, private router:Router, private spinner: NgxSpinnerService) {}
  getAllData() {
    this.spinner.show();
    this.daily_monitor.GetNannyDailyMonitoringList().subscribe((res: any) => {
            this.spinner.hide();

      this.NannyDailyMonitoringList = res.data;
      this.datalength = res?.data?.length;
      console.log(this.NannyDailyMonitoringList);
    }, (error: any) => {
      this.spinner.hide()
      // Handle any errors that occurred during the API call
    
      
    });
  }

  ngOnInit(): void {
    this.getAllData();
    console.log('nanny daily monitoring list');
    localStorage.removeItem('editedOrderId');
    this.cols = [
      { field: 'sr_no', header: 'Sr No' },
      { field: 'name', header: 'Name' },
      { field: 'date_of_birth', header: 'Date of Birth' },
      { field: 'days', header: 'Age' },
      { field: 'months', header: 'Months' },
      { field: 'years', header: 'Years' },
    ];
    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
  }
  getData(id: any) {
    this.NannyDailyMonitoringList?.map((res: any) => {
      if (res.id === id) {
        this.babyData = res;

        console.log('babyyyy:', this.babyData);
        // console.log('babyyyy-created:', this.babyData.daily_monitoring.created_at);
      }
    });
  }
  daily_monitoring: any;
  details: any = [];
  showMaximizableDialog(id: any) {
    console.log(id);
    this.data = [];

    // let viewData = this.nannyTaskData?.filter((task: any) => task.id == id);
    this.NannyDailyMonitoringList?.map((res: any) => {
      // console.log('show....', res, id);
      let viewData = res?.daily_monitoring?.filter(
        (task: any) => task.id == id
      );
      console.log(viewData)
      // console.log('view details...', viewData);
      if (viewData.length > 0 && viewData != null && Array.isArray(viewData)) {
        this.data = viewData;
        console.log('view details...', this.data);
        viewData?.map((data: any) => {
          this.daily_monitoring = data;
          console.log(this.daily_monitoring)
          // this.viewDetail.push(data);
          // console.log(JSON.parse(data?.feeding));
          // Tetching feeding data
          // if(data?.breast_feeding.length > 0){
          //   this.breastfeeding=JSON.parse(data?.breast_feeding)
          // }
          console.log('BreastFeeding Data',this.breastfeeding);
          if (data?.feeding) {
            this.feedingData = JSON.parse(data?.feeding);
            console.log('check data',this.feedingData);
            if (this.feedingData.length > 0) {
              this.feedingData = this.feedingData;
            }
            console.log(this.feedingData);
            // console.log(this.feedingData.length > 0);
            // console.log(typeof this.feedingData);
            if (Array.isArray(this.feedingData)) {
              this.feedingData?.map((res: any) => {
                if (res?.feeding_breast) {
                  this.feeding_breast.push(JSON.parse(res?.feeding_breast));
                  console.log('Feeding Breast', this.feeding_breast);
                }
                if (res?.feeding_formula) {
                  this.feeding_formula.push(JSON.parse(res?.feeding_formula));
                  // console.log('feeding formula', this.feeding_formula);
                }
                // Feeding brust data fetching
                this.feeding_breast.map((feed: any) => {
                  this.feeding_json = feed;
                  console.log('feed', this.feeding_json);
                });
                // Feeding formula data fetching
                this.feeding_formula?.map((feed: any) => {
                  this.feeding_formula_json = feed;
                  console.log('formula', this.feeding_formula_json);
                });
              });
            }
          }

          // Fetching Solid food
          if (data?.solid_food_feeding_json) {
            this.solidFoodFeeding =
              JSON.parse(data.solid_food_feeding_json)
            ;
            console.log('solidFoodFeeding', this.solidFoodFeeding);
            //Fetchnig Cerals grains
            this.cerals_grains = JSON.parse(
              this.solidFoodFeeding[0]?.cerals_grains
            );
            console.log('this.cerals_grains', this.cerals_grains);
            //Fetchnig fruits

            this.fruits = JSON.parse(this.solidFoodFeeding[1]?.fruits);
            console.log('fruits', this.fruits);
            //Fetchnig Vegetables
            this.vegetables = JSON.parse(this.solidFoodFeeding[2]?.vegetables);
            console.log('vegetables', this.vegetables);

            //Fetching Dairy food
            this.dairy_food = JSON.parse(this.solidFoodFeeding[3]?.dairy_food);
            console.log('dairy_food', this.dairy_food);

            // Fetching protein
            this.protein = JSON.parse(this.solidFoodFeeding[4]?.protein);
            console.log('protein', this.protein);


          }

          //  Fetchnig sleeping
          if (data?.sleeping.length > 0 && data?.sleeping != null) {
            console.log(JSON.parse(data?.sleeping));
            this.sleeping = (JSON.parse(data?.sleeping));
          }

          //  Fetchnig sleep training
          if (
            data?.sleep_training_json.length > 0 &&
            data?.sleep_training_json.length != null
          ) {
            this.sleep_training =
              JSON.parse(data?.sleep_training_json)
            ;
            console.log('sleep training', this.sleep_training);
          }

          //  Fetchnig Bed time
          if (
            data?.bed_time_routine_json.length > 0 &&
            data?.bed_time_routine_json != null
          ) {
            this.bed_time_routine = JSON.parse(
              (data?.bed_time_routine_json)
            );
            // console.log(this.bed_time_routine)
          }

          //  Fetchnig bathing
          if (data?.bathing_json.length > 0 && data?.bathing_json != null) {
            this.bathing = (JSON.parse(data?.bathing_json));
            console.log(this.bathing.bathing_type);

            if (this.bathing.bathing_type == 'tub') {
              this.bathing_frequency = this.bathing.machine_washing_frequency;
              console.log(this.bathing_frequency);
            } else {
              this.bathing_frequency =
                this.bathing.bathing_type_sponge_frequency;
              console.log(this.bathing_frequency);

            }
          }

          //  Fetchnig diapering
          if (data?.diapering.length > 0 && data?.diapering != null) {
            this.diapering = (JSON.parse(data?.diapering));
            this.diapering.map((res: any) => {});
            console.log(this.diapering);
          }
          //  Fetchnig vitamins and medications
          if (
            data?.vitamins_and_medications.length > 0 &&
            data?.vitamins_and_medications != null
          ) {
            this.vitamins_and_medications = JSON.parse(
              (data?.vitamins_and_medications)
            );
            console.log(this.vitamins_and_medications);
          }
        });
      }
    });
    this.displayMaximizable = true;
  }
  
  editOnCLick(id:any){
    localStorage.setItem('editedOrderId', id);
    console.log("id",id);
    this.router.navigateByUrl('/portal/nanny-daily-monitoring');
  }
  
  
  onPDFExport() {
    this.filteredPDFData = [];
    this.pdfData = [];
    this.filteredPDFData = this.NannyDailyMonitoringList;
  
    for (let i = 0; i < this.filteredPDFData.length; i++) {
      this.pdfData.push({
        sr_no: i + 1,
        name: this.filteredPDFData[i]?.name ?? '-',
        date_of_birth: this.filteredPDFData[i]?.date_of_birth ?? '-',
        days: this.filteredPDFData[i]?.days ?? '-',
        months: this.filteredPDFData[i]?.months ?? '-',
        years: this.filteredPDFData[i]?.years ?? '-',
      });
    }
  
    this.daily_monitor.exportPdf(
      this.pdfData,
      this.exportColumns,
      'nurse-daily-monitor.pdf'
    );
  }
  
}
