import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormArray,
  FormGroupDirective,
} from '@angular/forms';
import { InitialAssessmentFormService } from 'src/app/services/initial-assessment-form.service';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { AbstractControl } from '@angular/forms';
import { __values } from 'tslib';
import { MasterService } from 'src/app/services/master.service';
@Component({
  selector: 'app-initial-assessment-form',
  templateUrl: './initial-assessment-form.component.html',
  styleUrls: ['./initial-assessment-form.component.scss'],
  providers: [MessageService],
  // template: `<google-map width: 100% [center]="initialCoordinates" [options]="mapConfigurations"></google-map>`
})
export class InitialAssessmentFormComponent implements OnInit {
  // initialCoordinates = {
  //   lat: "46.533408",
  //   lng: "8.352592"
  // };
  // mapConfigurations={
  // disableDefaultUI: true,
  // fullscreenControl: true,
  // zoomControl: true
  // }

  breadcrumb = [
    {
      title: 'Baby Child Registration',
      subTitle: 'Dashboard',
    },
  ];
  genderArray: any = [
    { name: 'Male', value: 'Male' },
    { name: 'Female', value: 'Female' },
  ];
  viewDetail: any = [];
  initialAssData: any = [];
  getToken: any;
  getRole: any;
  getname: any;
  maxDate = new Date();
  relative: boolean = false;
  villaNo: boolean = false;
  workingHoursDropdown: boolean = true;
  showHealthTextarea: boolean = true;
  showRelativeInput: boolean = true;
  showFlatInput: boolean = true;
  showVillaInput: boolean = true;
  siblingNeeds: boolean = true;
  selectedGender: any = null;
  requireChanges: boolean = true;
  showShift: boolean = false;
  showReasonInput: boolean = false;
  showSiblingNeedsInput: boolean = false;
  displayMaximizable: boolean = false;
  showAdditionalFields: boolean = false;
  totaldays: any;
  start: any;
  end: any;
  date: any;
  editID: any;
  // selectedDate: Date = new Date(); // Initialize with today's date
  month: any;
  years: any;
  // data:any;
  days: any = 0;
  ageList: any = [];
  noOfMaidsList: any = [];
  workingHours: any = [];
  fatherarray: any = [];
  motherarray: any = [];
  nursearray: any = [];
  selectedFatherAge: any;
  selectedMotherAge: any;
  selectedReligion: any;
  selectedMaidsNumber: any;
  selectedHours: any;
  nurseData: any;
  permissionData: any = [];
  permissions: any = [];
  religionArray: any = [
    { name: 'Islam', value: 'Islam' },
    { name: 'Christianity', value: 'Christianity' },
    { name: 'Hinduism', value: 'Hinduism' },
    { name: 'Sikhism', value: 'Sikhism' },
    { name: 'Jainism', value: 'Jainism' },
    { name: 'Buddhism', value: 'Buddhism' },
    { name: 'Judaism', value: 'Judaism' },
    { name: 'Atheism', value: 'Atheism' },
  ];
  serviceArray: any = [
    { name: '2', value: '2' },
    { name: '4', value: '4' },
    { name: '6', value: '6' },
    { name: '8', value: '8' },
    { name: '10', value: '10' },
    { name: '12', value: '12' },
    { name: '24', value: '24' },
  ];

  serviceArrayForContract: any[] = [
    { name: '1', value: '1' },
    { name: '2', value: '2' },
    { name: '3', value: '3' },
    { name: '4', value: '4' },
    { name: '5', value: '5' },
    { name: '6', value: '6' },
    { name: '7', value: '7' },
    { name: '8', value: '8' },
    { name: '9', value: '9' },
    { name: '10', value: '10' },
    { name: '11', value: '11' },
    { name: '12', value: '12' },
    { name: '13', value: '13' },
    { name: '14', value: '14' },
    { name: '15', value: '15' },
    { name: '16', value: '16' },
    { name: '17', value: '17' },
    { name: '18', value: '18' },
    { name: '19', value: '19' },
    { name: '20', value: '20' },
    { name: '21', value: '21' },
    { name: '22', value: '22' },
    { name: '23', value: '23' },
    { name: '24', value: '24' }
  ];


  reportArray: any = [
    { name: 'Father', value: 'Father' },
    { name: 'Mother', value: 'Mother' },
    { name: 'Other', value: 'Other' },

  ];
  initialAssessmentForm: FormGroup;
  selectedFile: File | null = null;
  localUrl: any;
  binaryData: string | ArrayBuffer;
  isedit: boolean = false;
  isMotherSelected3: boolean = false;
  isNurseSelected3: boolean = false;
  isFatherSelected3: boolean = false;
  isMotherSelected2: boolean = false;
  isNurseSelected2: boolean = false;
  isFatherSelected2: boolean = false;
  isMotherSelected1: boolean = false;
  isNurseSelected1: boolean = false;
  isFatherSelected1: boolean = false;
  createdBy: any;
  createdAt: any;
  updatedBy: any;
  updatedAt: any;
  isNurse: boolean = false;
  username: string | null = null;
  totalHours: boolean = false;
  private intervalId: any;
  constructor(
    private fb: FormBuilder,
    private initial_form_service: InitialAssessmentFormService,
    private messageService: MessageService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private masterservice: MasterService,
    ) 
      {
    this.getAllUserPermission()
    this.initialAssessmentForm = this.fb.group({
      // service_hours: new FormControl(null),
      shift_mode: new FormControl(null),
      father_para: new FormControl(null),
      report_name: new FormControl(''),
      name: new FormControl(null, [Validators.required]),
      gender: new FormControl(null, [Validators.required]),
      date_of_birth: new FormControl(null, [Validators.required]),
      mode_delivery: new FormControl(null, [Validators.required]),
      upload_photo: new FormControl(null),
      health_issues: new FormControl(null, [Validators.required]),
      delivery_complications: new FormControl(null, [Validators.required]),
      reason: new FormControl({ value: null, disabled: true }),
      age: new FormControl(null),
      health_identify: new FormControl({ value: null, disabled: true }),
      any_speical_needs: new FormControl(null, [Validators.required]),
      mother_name: new FormControl(null, [Validators.required]),
      mother_age: new FormControl(null, [Validators.required]),
      mother_dob: new FormControl(null, [Validators.required]),
      mother_working: new FormControl(null, [Validators.required]),
      // needs: new FormControl(null, [Validators.required]),
      // siblings_specify: new FormControl(null, [Validators.required]),
      father_name: new FormControl(null, [Validators.required]),
      father_dateofbirth: new FormControl(null, [Validators.required]),
      father_age: new FormControl(null, [Validators.required]),
      father_work_hours: new FormControl(null, [Validators.required]),
      family_religion: new FormControl(null, [Validators.required]),
      // house_no: new FormControl({ value: null, disabled: true }),
      house_no: new FormControl(''),
      house_address: new FormControl(''),
      nurses: this.fb.array([]),

      house_location: new FormControl('', [
        Validators.required,
        Validators.pattern(
          '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
        ),
      ]),
      speical_identify: new FormControl({ value: null, disabled: true }),
      mother_work_hours: new FormControl(null, [Validators.required]),
      mother_para: new FormControl(null),
      days: new FormControl(null),
      months: new FormControl(null),
      years: new FormControl(null),
      sibling_details_json: this.fb.array([]),
      relatives_name_json: this.fb.array([]),
      relatives_house: new FormControl(null),
      // type_villa: new FormControl(null, [Validators.required]),
      type_of_house: new FormControl(''),
      mother_mental_supp: new FormControl(''),
      mental_supp_specify: new FormControl({ value: null, disabled: true }, [
        Validators.required,
      ]),
      appointment_vaccine_responsibility: new FormControl(null),
      medication_admin_responsibility: new FormControl(null),
      baby_supplies_responsibility: new FormControl(null),
      environment_changes: new FormControl(null),
      contact_no: new FormControl(''),
      mobile: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required]),
      mother_mobile: new FormControl(null, [Validators.required]),
      mother_email: new FormControl(null, [Validators.required]),
      report_issue: new FormControl(null, [Validators.required]),
      environment_identify_require: new FormControl({
        value: null,
        disabled: true,
      }),
      no_of_maids: new FormControl(null, [Validators.required]),
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      total_days: new FormControl(null),
      amount: new FormControl(null),
      type_of_shift: new FormControl(null),
      contract_json: this.fb.array([]),
      // associated_nurse_id: new FormControl(null),
      createdby: new FormControl(null),
      updateddby: new FormControl(null),
      createdat: new FormControl(null),
      updateddat: new FormControl(null),
    });
  }


  onKeyPress(event: KeyboardEvent): void {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // Invalid character, prevent input
      event.preventDefault();
    }
  }
  save(event: any) {
    
    window.open(event.target.value, '_blank');
  }
  keyPress(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  ngOnInit(): void {
    // this.calculateAgeAndYears();
    this.getNurseDropDown();
    this.getAgeDropdown();
    this.getNoOfMaidsDropdown();
    this.getWorkingHoursDropdown();
    this.onAddRow('add');
    this.addRelativeNamesRow('add');
    this.addcontractObject('add', 0);
    // not show contract when nurse login
    this.getToken = localStorage.getItem('token');
    this.getRole = localStorage.getItem('role');

    this.getname = localStorage.getItem('name');

    const userRole = localStorage.getItem('role');
    if (userRole && userRole === 'Nurse') {
      this.isNurse = true;

    }
    //get initial assesment id
    if (localStorage.getItem('babyId')) {
      this.getEditFormData();
      this.isedit = true;
      this.initialAssessmentForm.disable();

      //   const relativesNameFormArray = this.initialAssessmentForm.get(
      //     'relatives_name_json'
      //   ) as FormArray;

      //   for (let i = 0; i < relativesNameFormArray.length; i++) {
      //     const relativeFormGroup = relativesNameFormArray.at(i) as FormGroup;
      //     relativeFormGroup.get('name').disable();
      //   }
      const relativesArray = this.initialAssessmentForm.get(
        'relatives_name_json'
      ) as FormArray;

      // Disable the entire FormArray
      relativesArray.disable();

    } else {
      this.addNurseObject('add', 0)
      this.isedit = false;
    }
    this.initialAssessmentForm.get('report_issue').valueChanges.subscribe((value) => {
      // Set showAdditionalFields based on the dropdown value
      this.showAdditionalFields = value === 'Other';
    });
    this.intervalId = setInterval(() => {
      const dob = this.initialAssessmentForm.controls['date_of_birth'].value;
      this.calculateAgeAndYears(dob);
    }, 1000); // Update every second
  }
  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
  onDateSelect(event: any) {
    const dob = this.initialAssessmentForm.controls['date_of_birth'].value;
    this.calculateAgeAndYears(dob);
  }
  contarct() {
    if (this.isedit) {
      // Assuming 'contract_json' is a FormArray
      const contractFormArray = this.initialAssessmentForm.get(
        'contract_json'
      ) as FormArray;

      // Enable or disable controls based on some condition
      if (this.isedit) {
        // If 'isedit' is true, enable the controls
        contractFormArray.controls.forEach((control: AbstractControl) => {
          control.enable();
        });
      } else {
        // If 'isedit' is false, disable the controls
        contractFormArray.controls.forEach((control: AbstractControl) => {
          control.disable();
        });
      }
    }
  }
  onclick() {
    if (this.isedit) {
      this.initialAssessmentForm.get('name').enable();
      this.initialAssessmentForm.get('gender').enable();
      //  this.initialAssessmentForm.get('date_of_birth').enable();
      this.initialAssessmentForm.get('mode_delivery').enable();
      this.initialAssessmentForm.get('delivery_complications').enable();
      // this.initialAssessmentForm.get('health_identify').enable();
      this.initialAssessmentForm.get('health_issues').enable();
      // this.initialAssessmentForm.get('reason').enable();
      this.initialAssessmentForm.get('any_speical_needs').enable();
      // this.initialAssessmentForm.get('speical_identify').enable();
    }
  }
  mother() {
    if (this.isedit) {
 
      // this.initialAssessmentForm.get('mother_work_hours').enable();
      this.initialAssessmentForm.get('mother_working').enable();
      this.initialAssessmentForm.get('mother_name').enable();
      this.initialAssessmentForm.get('mother_dob').enable();
      this.initialAssessmentForm.get('mother_mobile').enable();
      this.initialAssessmentForm.get('mother_email').enable();
    }
  }
  father() {
    if (this.isedit) {
 
      this.initialAssessmentForm.get('father_work_hours').enable();
      this.initialAssessmentForm.get('father_name').enable();
      this.initialAssessmentForm.get('father_dateofbirth').enable();
      this.initialAssessmentForm.get('mobile').enable();
      this.initialAssessmentForm.get('email').enable();
    }
  }
  sibling() {
    if (this.isedit) {
      // Assuming 'sibling_details_json' is a FormArray
      const siblingArray = this.initialAssessmentForm.get(
        'sibling_details_json'
      ) as FormArray;

      for (let i = 0; i < siblingArray.length; i++) {
        const siblingGroup = siblingArray.at(i) as FormGroup;
        siblingGroup.get('name').enable();
        siblingGroup.get('dob').enable();
        siblingGroup.get('age').enable();
        siblingGroup.get('needs').enable();
        siblingGroup.get('special_needs_identify').enable();
      }
    }
  }
  basic() {
    this.initialAssessmentForm.get('nurses').enable();
  }
  location() {
    if (this.isedit) {
 
      this.initialAssessmentForm.get('house_location').enable();
      this.initialAssessmentForm.get('house_no').enable();
      this.initialAssessmentForm.get('house_address').enable();
      this.initialAssessmentForm.get('type_of_house').enable();
    }
  }
  social() {
    if (this.isedit) {
 
      // this.initialAssessmentForm.get('environment_identify_require').enable();
      this.initialAssessmentForm.get('environment_changes').enable();
      this.initialAssessmentForm.get('baby_supplies_responsibility').enable();
      this.initialAssessmentForm
        .get('medication_admin_responsibility')
        .enable();
      this.initialAssessmentForm
        .get('appointment_vaccine_responsibility')
        .enable();
      // this.initialAssessmentForm.get('mental_supp_specify').enable();
      this.initialAssessmentForm.get('upload_photo').enable();
      this.initialAssessmentForm.get('contact_no').enable();
      this.initialAssessmentForm.get('report_name').enable();
      this.initialAssessmentForm.get('report_issue').enable();
      this.initialAssessmentForm.get('family_religion').enable();
      this.initialAssessmentForm.get('no_of_maids').enable();
      this.initialAssessmentForm.get('contact_no').enable();
      this.initialAssessmentForm.get('report_name').enable();
      this.initialAssessmentForm.get('family_religion').enable();
      this.initialAssessmentForm.get('relatives_house').enable();

      const relativesNameFormArray = this.initialAssessmentForm.get(
        'relatives_name_json'
      ) as FormArray;

      for (let i = 0; i < relativesNameFormArray.length; i++) {
        const relativeFormGroup = relativesNameFormArray.at(i) as FormGroup;
        relativeFormGroup.get('name').enable();
      }
    }
  }

  getEditFormData() {
    this.spinner.show();
    const getEdit = localStorage.getItem('babyId');
    this.initial_form_service
      .getInitialAssessmentForm(getEdit)
      .then((res: any) => {
        this.spinner.hide()
        const detailsInGrowthMonitoring = res.data[0];
        this.createdBy = detailsInGrowthMonitoring.created_by_user_name
        this.createdAt = detailsInGrowthMonitoring.created_at
        this.updatedBy = detailsInGrowthMonitoring.updated_by_user_name
        this.updatedAt = detailsInGrowthMonitoring.updated_at


        // this.ge = detailsInGrowthMonitoring.gender;
        // this.selectedDate = detailsInGrowthMonitoring.date_of_birth;
        this.days = detailsInGrowthMonitoring.days;
        this.month = detailsInGrowthMonitoring.months;
        this.years = detailsInGrowthMonitoring.years;

        // if (detailsInGrowthMonitoring && detailsInGrowthMonitoring.sibling_details_json) {
        //   const siblingDetailsJson = JSON.parse(detailsInGrowthMonitoring.sibling_details_json);

        //   // Now you can use siblingDetailsJson as an array
        // }


        const contract = detailsInGrowthMonitoring.contract_json;


        const a = detailsInGrowthMonitoring.appointment_vaccine_responsibility
        const valuesArray1 = a.split(',').map(value => value.trim());

        valuesArray1.forEach(value => {

          if (value === 'father') {
            this.fatherarray.push(value);

            this.isFatherSelected1 = true
          }
          if (value === 'nurse') {
            this.fatherarray.push(value);

            this.isNurseSelected1 = true
          }

          if (value === 'mother') {
            this.fatherarray.push(value);

            this.isMotherSelected1 = true
          }
        });

        const b = detailsInGrowthMonitoring.medication_admin_responsibility
        const valuesArray2 = b.split(',').map(value => value.trim());

        valuesArray2.forEach(value => {

          if (value === 'father') {
            this.motherarray.push(value);

            this.isFatherSelected2 = true
          }
          if (value === 'nurse') {
            this.motherarray.push(value);

            this.isNurseSelected2 = true
          }
          if (value == 'mother') {
            this.motherarray.push(value);

            this.isMotherSelected2 = true
          }
        });


        const c = detailsInGrowthMonitoring.baby_supplies_responsibility
        const valuesArray3 = c.split(',').map(value => value.trim());

        valuesArray3.forEach(value => {

          if (value === 'father') {
            this.nursearray.push(value);

            this.isFatherSelected3 = true
          }
          if (value === 'nurse') {
            this.nursearray.push(value);

            this.isNurseSelected3 = true
          }
          if (value === 'mother') {
            this.nursearray.push(value);

            this.isMotherSelected3 = true
          }
        });


        // this.initialAssessmentForm.controls.contract_json.setValue(
        //   detailsInGrowthMonitoring.contract_json
        // );

        // this.initialAssessmentForm.controls.service_hours.setValue(
        //   detailsInGrowthMonitoring.service_hours
        // );
        this.initialAssessmentForm.controls.createdby.setValue(
          detailsInGrowthMonitoring.created_by_user_id
        );

        this.initialAssessmentForm.controls.updateddby.setValue(
          detailsInGrowthMonitoring.updated_by_user_id
        );
        this.initialAssessmentForm.controls.createdat.setValue(
          detailsInGrowthMonitoring.created_at
        );
        this.initialAssessmentForm.controls.updateddat.setValue(
          detailsInGrowthMonitoring.updated_at
        );
        // this.initialAssessmentForm.controls.associated_nurse_id.setValue(
        //   JSON.parse(detailsInGrowthMonitoring.associated_nurse_id)
        // );

        this.initialAssessmentForm.controls.name.setValue(
          detailsInGrowthMonitoring.name
        );
        this.initialAssessmentForm.controls.gender.setValue(
          detailsInGrowthMonitoring.gender
        );
        this.initialAssessmentForm.controls.date_of_birth.setValue(
          detailsInGrowthMonitoring.date_of_birth
        );
        this.initialAssessmentForm.controls.days.setValue(
          detailsInGrowthMonitoring.days
        );
        this.initialAssessmentForm.controls.months.setValue(
          detailsInGrowthMonitoring.months
        );
        this.initialAssessmentForm.controls.years.setValue(
          detailsInGrowthMonitoring.years
        );
        this.initialAssessmentForm.controls.mode_delivery.setValue(
          detailsInGrowthMonitoring.mode_delivery
        );
        this.initialAssessmentForm.controls.mobile.setValue(
          detailsInGrowthMonitoring.parent_mobile_number
        );

        this.initialAssessmentForm.controls.email.setValue(
          detailsInGrowthMonitoring.parent_email
        );
        this.initialAssessmentForm.controls.mother_mobile.setValue(
          detailsInGrowthMonitoring.mother_mobile
        );
        this.initialAssessmentForm.controls.mother_email.setValue(
          detailsInGrowthMonitoring.mother_email
        );
        this.initialAssessmentForm.controls.delivery_complications.setValue(
          detailsInGrowthMonitoring.delivery_complications
        );
        this.initialAssessmentForm.controls.reason.setValue(
          detailsInGrowthMonitoring.reason
        );
        this.initialAssessmentForm.controls.health_issues.setValue(
          detailsInGrowthMonitoring.health_issues
        );
        this.initialAssessmentForm.controls.health_identify.setValue(
          detailsInGrowthMonitoring.health_identify
        );
        this.initialAssessmentForm.controls.any_speical_needs.setValue(
          detailsInGrowthMonitoring.any_speical_needs
        );

        this.initialAssessmentForm.controls.speical_identify.setValue(
          detailsInGrowthMonitoring.speical_identify
        );
        this.initialAssessmentForm.controls.mother_name.setValue(
          detailsInGrowthMonitoring.mother_name
        );
        this.initialAssessmentForm.controls.mother_dob.setValue(
          detailsInGrowthMonitoring.mother_dob
        );
        this.initialAssessmentForm.controls.mother_age.setValue(
          detailsInGrowthMonitoring.mother_age
        );
        this.initialAssessmentForm.controls.mode_delivery.setValue(
          detailsInGrowthMonitoring.mode_delivery
        );
        this.initialAssessmentForm.controls.mother_working.setValue(
          detailsInGrowthMonitoring.mother_working
        );
        this.initialAssessmentForm.controls.mother_work_hours.setValue(
          JSON.parse(detailsInGrowthMonitoring.mother_work_hours)
        );

        this.initialAssessmentForm.controls.mother_para.setValue(
          detailsInGrowthMonitoring.mother_para
        );
        this.initialAssessmentForm.controls.father_name.setValue(
          detailsInGrowthMonitoring.father_name
        );
        this.initialAssessmentForm.controls.father_dateofbirth.setValue(
          detailsInGrowthMonitoring.father_dateofbirth
        );
        this.initialAssessmentForm.controls.father_age.setValue(
          detailsInGrowthMonitoring.father_age
        );
        this.initialAssessmentForm.controls.father_work_hours.setValue(
          JSON.parse(detailsInGrowthMonitoring.father_work_hours)
        );

        this.initialAssessmentForm.controls.type_of_house.setValue(
          detailsInGrowthMonitoring.type_of_house
        );

        this.initialAssessmentForm.controls.house_no.setValue(
          detailsInGrowthMonitoring.house_no
        );
        this.initialAssessmentForm.controls.house_address.setValue(
          detailsInGrowthMonitoring.house_address
        );
        this.initialAssessmentForm.controls.house_location.setValue(
          detailsInGrowthMonitoring.house_location
        );
        this.initialAssessmentForm.controls.relatives_house.setValue(
          detailsInGrowthMonitoring.relatives_house
        );
        this.initialAssessmentForm.controls.no_of_maids.setValue(
          detailsInGrowthMonitoring.no_of_maids
        );
        this.initialAssessmentForm.controls.family_religion.setValue(
          detailsInGrowthMonitoring.family_religion
        );
        this.initialAssessmentForm.controls.report_name.setValue(
          detailsInGrowthMonitoring.report_name
        );

        this.initialAssessmentForm.controls.contact_no.setValue(
          detailsInGrowthMonitoring.contact_no
        );
        this.initialAssessmentForm.controls.report_issue.setValue(
          detailsInGrowthMonitoring.report_issue
        );
        this.initialAssessmentForm.controls.mother_mental_supp.setValue(
          detailsInGrowthMonitoring.mother_mental_supp
        );
        this.initialAssessmentForm.controls.upload_photo.setValue(
          detailsInGrowthMonitoring.upload_photo
        );
        this.initialAssessmentForm.controls.mental_supp_specify.setValue(
          detailsInGrowthMonitoring.mental_supp_specify
        );
        // this.initialAssessmentForm.controls.appointment_vaccine_responsibility.setValue(
        //   detailsInGrowthMonitoring.appointment_vaccine_responsibility
        // );
        // this.initialAssessmentForm.controls.medication_admin_responsibility.setValue(
        //   detailsInGrowthMonitoring.medication_admin_responsibility
        // );
        // this.initialAssessmentForm.controls.baby_supplies_responsibility.setValue(
        //   detailsInGrowthMonitoring.baby_supplies_responsibility
        // );
        this.initialAssessmentForm.controls.environment_changes.setValue(
          detailsInGrowthMonitoring.environment_changes
        );
        this.initialAssessmentForm.controls.environment_identify_require.setValue(
          detailsInGrowthMonitoring.environment_identify_require
        );
        this.getContractFormArray().clear();
        for (let i = 0; i < contract.length; i++) {
          let vitaminArray = this.getContractFormArray();
          let VitaminsMedicationFromApi = contract[i] || {};

          let newVitaminArray = new FormGroup({
            start_date: new FormControl(
              VitaminsMedicationFromApi.start_date
                ? new Date(VitaminsMedicationFromApi.start_date)
                : null
            ),
            end_date: new FormControl(
              VitaminsMedicationFromApi.end_date
                ? new Date(VitaminsMedicationFromApi.end_date)
                : null
            ),
            total_days: new FormControl(
              VitaminsMedicationFromApi.total_days || ''
            ),
            amount: new FormControl(VitaminsMedicationFromApi.amount || ''),
            type_of_shift: new FormControl(
              VitaminsMedicationFromApi.type_of_shift || ''
            ),
            //   date_administration: new FormControl(
            //     vaccineApi.date_administration
            //       ? new Date(vaccineApi.date_administration)
            //       : null
            //   ),
            // });
          });

          vitaminArray.insert(i, newVitaminArray);
        }

        const nurse = detailsInGrowthMonitoring.nurses;

        for (let i = 0; i < nurse.length; i++) {
          let nurseArray = this.getNurses();
          let nurseData = nurse[i] || {};

          let newNurseArray = new FormGroup({
            associated_nurse_id: new FormControl(nurseData.associated_nurse_id || ''),
            service_hours: new FormControl(String(nurseData.service_hours) || ''), // Convert to string
          });
          nurseArray.insert(i, newNurseArray);
        }


        //    const intermediateObject = JSON.parse(detailsInGrowthMonitoring.relatives_name_json);
        //  const relative = JSON.parse(intermediateObject);

        //     if (relative) {
        //       this.relative = true;
        //       this.getrelativearray().clear();
        //       for (let i = 0; i < relative.length; i++) {
        //         let bottleArray = this.getrelativearray();
        //         bottleArray.push(
        //           this.fb.group({
        //             name: new FormControl(relative[i].name),
        //           })
        //         );
        //       }
        //     }


        // const relative = JSON.parse(detailsInGrowthMonitoring.relatives_name_json);

        // if (Array.isArray(relative)) {
        //   this.getrelativearray().clear();

        //   for (let i = 0; i < relative.length; i++) {
        //     let bottleArray = this.getrelativearray();
        //     bottleArray.push(
        //       this.fb.group({
        //         name: new FormControl(relative[i].name),
        //       })
        //     );
        //   }
        // } else {

        const intermediateObjects = JSON.parse(detailsInGrowthMonitoring.relatives_name_json);
        const relative = JSON.parse(JSON.stringify(intermediateObjects));

        if (Array.isArray(relative)) {
          this.relative = true;
          this.getrelativearray().clear();
          for (let i = 0; i < relative.length; i++) {
            let bottleArray = this.getrelativearray();
            bottleArray.push(
              this.fb.group({
                name: new FormControl(relative[i].name),
              })
            );
          }
        } else {
          const relatives = JSON.parse(JSON.parse(
            detailsInGrowthMonitoring.relatives_name_json
          ));


          if (relatives) {
            this.relative = true;
            this.getrelativearray().clear();
            for (let i = 0; i < relatives.length; i++) {
              let bottleArrays = this.getrelativearray();
              bottleArrays.push(
                this.fb.group({
                  name: new FormControl(relatives[i].name),
                })
              );
            }
          }

        }










        const sibling = JSON.parse(detailsInGrowthMonitoring.sibling_details_json);

        // Ensure sibling is an array
        if (Array.isArray(sibling)) {
          this.getsiblingarray().clear();


          // Iterate over the sibling array and create form controls for each sibling
          for (let i = 0; i < sibling.length; i++) {
            let sibarray = this.getsiblingarray();
            sibarray.push(
              this.fb.group({
                name: new FormControl(sibling[i].name),
                dob: new FormControl(sibling[i].dob ? new Date(sibling[i].dob) : null),
                age: new FormControl(sibling[i].age),
                needs: new FormControl(sibling[i].needs),
                special_needs_identify: new FormControl(sibling[i].special_needs_identify),
              })
            );
          }
        } else {


          const intermediateObject = JSON.parse(detailsInGrowthMonitoring.sibling_details_json);

          const sibling = JSON.parse(intermediateObject);

          this.getsiblingarray().clear();
          for (let i = 0; i < sibling.length; i++) {
            let sibarray = this.getsiblingarray();
            sibarray.push(
              this.fb.group({
                name: new FormControl(sibling[i].name),
                // dob: new FormControl(sibling[i].dob),
                dob: new FormControl(
                  sibling[i].dob ? new Date(sibling[i].dob) : null
                ),
                age: new FormControl(sibling[i].age),
                needs: new FormControl(sibling[i].needs),
                special_needs_identify: new FormControl(
                  sibling[i].special_needs_identify
                ),
              })
            );
          }
        }

        // Assuming 'i' is the index of the form group in the form array

        // for (let i = 0; i < siblingDetailsJson.length; i++) {
        //   let vitaminArray = this. getFormArray('siblingarray');
        //   let siblingDetailsJsonFromApi = siblingDetailsJson[i] || {};

        //   let newVitaminArray = new FormGroup({
        //     name: new FormControl(VitaminsMedicationFromApi.name || ''),
        //     age: new FormControl(VitaminsMedicationFromApi.age || ''),
        //     dob: new FormControl(VitaminsMedicationFromApi.frequency || ''),
        //     needs: new FormControl(VitaminsMedicationFromApi.needs || ''),
        //     special_needs_identify: new FormControl(VitaminsMedicationFromApi.special_needs_identify || ''),
        //   });

        //   vitaminArray.insert(i, newVitaminArray);
        // }

        this.initialAssessmentForm.disable();

      },
        (error: any) => {
          this.spinner.hide()

          // Handle any errors that occurred during the API call
          

          // this.spinner.hide();
        }
      );
  }

  getDaysDifference(startDate, endDate) {
    // Convert both dates to milliseconds
    const startMillis = startDate.getTime();
    const endMillis = endDate.getTime();

    // Calculate the difference in milliseconds
    const differenceMillis = endMillis - startMillis;

    // Convert the difference to days
    const daysDifference = Math.floor(differenceMillis / (24 * 60 * 60 * 1000));

    return daysDifference;
  }

  getcontract(index) {
    let group = this.initialAssessmentForm.get('contract_json') as FormArray;
    return group.controls[index] as FormGroup;
  }

  calculateDays(index: number) {
    let contract = this.getcontract(index);
    let start = contract.get('start_date').value;
    let end = contract.get('end_date').value;

    // Call getDaysDifference with start and end dates
    const totaldays = this.getDaysDifference(start, end);

    contract.get('total_days').setValue(totaldays);
  }

  /* -------------------------------------------------------------------------- */
  /*                        // get form array                                   */
  /* -------------------------------------------------------------------------- */
  getrelativearray() {
    return this.initialAssessmentForm.get('relatives_name_json') as FormArray;
  }
  getsiblingarray() {
    return this.initialAssessmentForm.get('sibling_details_json') as FormArray;
  }
  /* -------------------------------------------------------------------------- */
  /*                       //add siblings array function                        */
  /* -------------------------------------------------------------------------- */
  onAddRow(string: any, indexat?: any) {
    let siblingArray = this.getsiblingarray();
    if (string == 'add') {
      // if (siblingArray.valid) {
      siblingArray.push(
        this.fb.group({
          name: new FormControl(null),
          dob: new FormControl(null),
          age: new FormControl(null),
          needs: new FormControl(null),
          special_needs_identify: new FormControl(null),
        })
      );
      // }
    } else {
      siblingArray.removeAt(indexat);
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                       //add relatives array function                        */
  /* -------------------------------------------------------------------------- */
  addRelativeNamesRow(string: any, indexat?: any) {
    let relativeNameArray = this.getrelativearray();
    if (string == 'add') {
      if (relativeNameArray.valid) {
        relativeNameArray.push(
          this.fb.group({
            name: new FormControl(null),
          })
        );
      }
      // else this.docErrorState = true;
    } else {
      relativeNameArray.removeAt(indexat);
    }
  }
  // getDaysDifference(startDate, endDate) {
  //   // Convert both dates to milliseconds
  //   const startMillis = startDate.getTime();
  //   const endMillis = endDate.getTime();

  //   // Calculate the difference in milliseconds
  //   const differenceMillis = endMillis - startMillis;

  //   // Convert the difference to days
  //   const daysDifference = Math.floor(differenceMillis / (24 * 60 * 60 * 1000));

  //   return daysDifference;
  // }

  // calculatedays(){
  //  const start = this.initialAssessmentForm.get('start_time').value;
  //  const end=this.initialAssessmentForm.get('end_time').value;
  //   const totaldays = getDaysDifference()
  //   this.initialAssessmentForm.controls.total_days.setValue(totaldays);

  // }

  /* -------------------------------------------------------------------------- */
  /*                               // add contract fields                              */
  /* -------------------------------------------------------------------------- */
  addcontractObject(string: any, index: any) {
    let contractarray = this.getContractFormArray();
    if (string == 'add') {
      let newcontractarray = new FormGroup({
        start_date: new FormControl(''),
        end_date: new FormControl(''),
        type_of_shift: new FormControl(''),
        amount: new FormControl(''),
        total_days: new FormControl(''),
      });
      contractarray.insert(index + 1, newcontractarray);
    } else {
      contractarray.removeAt(index);
    }
  }

  getContractFormArray() {
    return this.initialAssessmentForm.get('contract_json') as FormArray;
  }
  /* -------------------------------------------------------------------------- */
  /*                               // Age dropdown                              */
  /* -------------------------------------------------------------------------- */
  getAgeDropdown() {
    for (let i = 20; i <= 50; i++) {
      this.ageList.push({ age: i });
    }
  }
  /* -------------------------------------------------------------------------- */
  /*                               // Age dropdown                              */
  /* -------------------------------------------------------------------------- */
  getNoOfMaidsDropdown() {
    // this.noOfMaidsList = [

    // { label: '0', value: 0 },
    //   { label: '1', value: 1 },
    //   { label: '2', value: 2 },
    //   { label: '3', value: 3 },
    //   { label: '4', value: 4 },
    //   { label: '5', value: 5 },
    //   { label: '6', value: 6 },
    //   { label: '7', value: 7 },
    //   { label: '8', value: 8 },
    //   { label: '9', value: 9 },
    //   { label: '10', value: 10 },]
    // this.noOfMaidsList.push({ number: 0, label:"0" });
    for (let i = 0; i <= 10; i++) {
      this.noOfMaidsList.push({ number: String(i) });
      // this.noOfMaidsList.push({ number: i, label: i == 0 ? '0' : i.toString() });
    }
  }
  /* -------------------------------------------------------------------------- */
  /*                               // Working hours                              */
  /* -------------------------------------------------------------------------- */
  getWorkingHoursDropdown() {
    for (let i = 1; i <= 24; i++) {
      this.workingHours.push({ hours: i });
    }
  }
  /* -------------------------------------------------------------------------- */
  /*            //Soft Reloading            */
  /* -------------------------------------------------------------------------- */
  softReload() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  /* -------------------------------------------------------------------------- */
  /*            // get radio button values and show inputs accordingly           */
  /* -------------------------------------------------------------------------- */
  showInputOnSelection(event: any, value: string) {

    
    if (value == 'servicehours') {
      if (event.target.value == '12hrs') {
        this.showShift = true;
        this.initialAssessmentForm.controls['shift_mode'].setValidators([
          Validators.required,
        ]);
      } else {
        this.showShift = false;
        this.initialAssessmentForm.controls['shift_mode'].clearValidators();
        this.initialAssessmentForm.controls[
          'shift_mode'
        ].updateValueAndValidity();
      }
    } else if (value == 'delivery') {
      if (event.target.value == 'Yes') {
        // this.showReasonInput = true;
        this.initialAssessmentForm.controls.reason.enable();
        this.initialAssessmentForm.controls['reason'].setValidators([
          Validators.required,
        ]);
        this.initialAssessmentForm.controls['reason'].updateValueAndValidity();
      } else {
        // this.showReasonInput = false;
        this.initialAssessmentForm.controls.reason.disable();
        this.initialAssessmentForm.controls['reason'].clearValidators();
        this.initialAssessmentForm.controls['reason'].updateValueAndValidity();
        this.initialAssessmentForm.controls.reason.setValue(null);
      }
    } else if (value == 'health') {
      // ;
      if (event.target.value == 'Yes') {
        this.initialAssessmentForm.controls.health_identify.enable();
        this.initialAssessmentForm.controls['health_identify'].setValidators([
          Validators.required,
        ]);
        this.initialAssessmentForm.controls[
          'health_identify'
        ].updateValueAndValidity();
      } else {
        this.initialAssessmentForm.controls.health_identify.disable();
        this.initialAssessmentForm.controls[
          'health_identify'
        ].clearValidators();
        this.initialAssessmentForm.controls[
          'health_identify'
        ].updateValueAndValidity();
        this.initialAssessmentForm.controls.health_identify.setValue(null);
      }
    } else if (value == 'need') {
      if (event.target.value == 'Yes') {
        this.initialAssessmentForm.controls.speical_identify.enable();
        this.initialAssessmentForm.controls['speical_identify'].setValidators([
          Validators.required,
        ]);
        this.initialAssessmentForm.controls[
          'speical_identify'
        ].updateValueAndValidity();
      } else {
        this.initialAssessmentForm.controls.speical_identify.disable();
        this.initialAssessmentForm.controls[
          'speical_identify'
        ].clearValidators();
        this.initialAssessmentForm.controls[
          'speical_identify'
        ].updateValueAndValidity();
        this.initialAssessmentForm.controls.speical_identify.setValue(null);
      }
    } else if (value == 'siblingneed') {
      if (event.target.value == 'Yes') {
        this.showSiblingNeedsInput = true;
      } else {
        this.showSiblingNeedsInput = false;
      }
    } else if (value == 'working_mother') {
      if (event.target.value == 'Yes') {
        this.initialAssessmentForm.controls.mother_work_hours.enable();
        this.initialAssessmentForm.controls['mother_work_hours'].setValidators([
          Validators.required,
        ]);
        this.initialAssessmentForm.controls[
          'mother_work_hours'
        ].updateValueAndValidity();
      } else {
        this.initialAssessmentForm.controls.mother_work_hours.disable();
        this.initialAssessmentForm.controls[
          'mother_work_hours'
        ].clearValidators();
        this.initialAssessmentForm.controls[
          'mother_work_hours'
        ].updateValueAndValidity();
      }
    } else if (value == 'houseType') {
      if (event.target.value == 'villa' && event.target.checked) {
        this.initialAssessmentForm.controls.house_no.enable();
        this.villaNo = true;
        this.initialAssessmentForm.controls.house_no.setValue(null);
      } else if (event.target.value == 'flat' && event.target.checked) {
        this.initialAssessmentForm.controls.house_no.enable();
        this.villaNo = false;
        this.initialAssessmentForm.controls.house_no.setValue(null);
      } else {
        this.initialAssessmentForm.controls.house_no.disable();
      }
    } else if (value == 'mentalSupport') {
      if (event.target.value == 'Yes') {
        this.initialAssessmentForm.controls.mental_supp_specify.enable();
      } else {
        this.initialAssessmentForm.controls.mental_supp_specify.disable();
        this.initialAssessmentForm.controls.mental_supp_specify.setValue(null);
      }
    } else if (value == 'environment_changes') {
      if (event.target.value == 'Yes') {
        this.initialAssessmentForm.controls.environment_identify_require.enable();
      } else {
        this.initialAssessmentForm.controls.environment_identify_require.disable();
        this.initialAssessmentForm.controls.environment_identify_require.setValue(
          null
        );
      }
    } else if (value == 'relatives') {
      if (event.target.value == 'Yes') {
        this.relative = true;
      } else {
        this.relative = false;
      }
    } else if (value == 'father') {
      if (event.target.checked) {
        // If checkbox is checked, add the value to the array only if it's not already present
        if (!this.fatherarray.includes(event.target.value)) {
          this.fatherarray.push(event.target.value);
        }
        else {
          this.fatherarray.push(event.target.value);

        }
      } else {
        // If checkbox is unchecked, remove the value from the array
        const index = this.fatherarray.indexOf(event.target.value);
        if (index !== -1) {
          this.fatherarray.splice(index, 1);
        }
      }

    } else if (value == 'mother') {
      if (event.target.checked) {
        if (!this.motherarray.includes(event.target.value)) {
          this.motherarray.push(event.target.value);
        }
      } else {
        const index = this.motherarray.indexOf(event.target.value);
        if (index !== -1) {
          this.motherarray.splice(index, 1);
        }
      }

    } else if (value == 'nurse') {
      if (event.target.checked) {
        if (!this.nursearray.includes(event.target.value)) {
          this.nursearray.push(event.target.value);
        }
      } else {
        const index = this.nursearray.indexOf(event.target.value);
        if (index !== -1) {
          this.nursearray.splice(index, 1);
        }
      }

    }


  }
  /* -------------------------------------------------------------------------- */
  /*                        // on date of birth selection                       */
  /* -------------------------------------------------------------------------- */
  calculateAgeAndYears(value: string, i?: any) {
    // _______________________baby's age calcuation________________________
    if (value) {
      const selectedDate = moment(value, 'DD/MM/YYYY');
      const today = moment();

      // Calculate the difference in years, months, and days
      const years = today.diff(selectedDate, 'years');
      selectedDate.add(years, 'years');

      const months = today.diff(selectedDate, 'months');
      selectedDate.add(months, 'months');

      const days = today.diff(selectedDate, 'days');

      // Set the values in the form
      this.initialAssessmentForm.controls['years'].setValue(years);
      this.initialAssessmentForm.controls['months'].setValue(months);
      this.initialAssessmentForm.controls['days'].setValue(days);
    } else {
      // Handle case when the birthdate is not provided
      this.initialAssessmentForm.controls['years'].setValue(null);
      this.initialAssessmentForm.controls['months'].setValue(null);
      this.initialAssessmentForm.controls['days'].setValue(null);
    }
    // _______________________mother's age calcuation________________________
    if (value == 'mother') {
      if (this.initialAssessmentForm.controls['mother_dob'].value) {
        const today = new Date();
        const selectedDate = new Date(
          this.initialAssessmentForm.controls['mother_dob'].value
        );

        // Calculate the difference in years
        const yearsDiff = today.getFullYear() - selectedDate.getFullYear();

        // Check if the birthdate hasn't occurred this year yet
        if (
          today.getMonth() < selectedDate.getMonth() ||
          (today.getMonth() === selectedDate.getMonth() &&
            today.getDate() < selectedDate.getDate())
        ) {
          this.initialAssessmentForm.controls['mother_age'].setValue(
            yearsDiff - 1
          );
        } else {
          this.initialAssessmentForm.controls['mother_age'].setValue(yearsDiff);
        }
      } else {
        this.initialAssessmentForm.controls['mother_age'].setValue(null);
      }
    }
    // _______________________father's age calcuation________________________
    else if (value == 'father') {
      if (this.initialAssessmentForm.controls['father_dateofbirth'].value) {
        const today = new Date();
        const selectedDate = new Date(
          this.initialAssessmentForm.controls['father_dateofbirth'].value
        );

        // Calculate the difference in years
        const yearsDiff = today.getFullYear() - selectedDate.getFullYear();

        // Check if the birthdate hasn't occurred this year yet
        if (
          today.getMonth() < selectedDate.getMonth() ||
          (today.getMonth() === selectedDate.getMonth() &&
            today.getDate() < selectedDate.getDate())
        ) {
          this.initialAssessmentForm.controls['father_age'].setValue(
            yearsDiff - 1
          );
        } else {
          this.initialAssessmentForm.controls['father_age'].setValue(yearsDiff);
        }
      } else {
        this.initialAssessmentForm.controls['father_age'].setValue(null);
      }
    }
    // _______________________sibling's age calcuation________________________
    else if (value == 'sibling') {
      // 
      
      if (
        this.initialAssessmentForm['controls'].sibling_details_json['controls'][
          i
        ]['controls'].dob.value
      ) {
        const today = new Date();
        const selectedDate = new Date(
          this.initialAssessmentForm['controls'].sibling_details_json[
            'controls'
          ][i]['controls'].dob.value
        );

        // Calculate the difference in years
        const yearsDiff = today.getFullYear() - selectedDate.getFullYear();

        // Check if the birthdate hasn't occurred this year yet
        if (
          today.getMonth() < selectedDate.getMonth() ||
          (today.getMonth() === selectedDate.getMonth() &&
            today.getDate() < selectedDate.getDate())
        ) {
          this.initialAssessmentForm.controls.sibling_details_json['controls'][
            i
          ]['controls'].age.setValue(yearsDiff - 1);
        } else {
          this.initialAssessmentForm.controls.sibling_details_json['controls'][
            i
          ]['controls'].age.setValue(yearsDiff);
        }
      } else {
        this.initialAssessmentForm.controls.sibling_details_json['controls'][i][
          'controls'
        ].age.setValue(null);
      }
    
    }
  }
  /* -------------------------------------------------------------------------- */
  /*                        // convert image to binary                      */
  /* -------------------------------------------------------------------------- */
  fileChange(event, item) { }
  // convenience getter for easy access to form fields
  get f() {
    return this.initialAssessmentForm.controls;
  }

  /* -------------------------------------------------------------------------- */
  /*                        // save assessment form                      */
  /* -------------------------------------------------------------------------- */
  saveAssessmentForm(assessmentForm: any) {

    this.initialAssessmentForm.get('nurses').enable()
    window.scroll(0, 0);
    Object.keys(this.initialAssessmentForm.controls).forEach((res) => {
    });
    if (assessmentForm.valid) {
      this.initialAssessmentForm.controls['date_of_birth'].setValue(
        !['Invalid date', null].includes(
          this.initialAssessmentForm.controls['date_of_birth'].value
        )
          ? moment(this.initialAssessmentForm.value.date_of_birth).format(
            'DD/MM/YYYY'
          )
          : null
      );
      this.initialAssessmentForm.controls['mother_dob'].setValue(
        !['Invalid date', null].includes(
          this.initialAssessmentForm.controls['mother_dob'].value
        )
          ? moment(this.initialAssessmentForm.value.mother_dob).format(
            'DD/MM/YYYY'
          )
          : null
      );
      this.initialAssessmentForm.controls['father_dateofbirth'].setValue(
        !['Invalid date', null].includes(
          this.initialAssessmentForm.controls['father_dateofbirth'].value
        )
          ? moment(this.initialAssessmentForm.value.father_dateofbirth).format(
            'DD/MM/YYYY'
          )
          : null
      );

      this.initialAssessmentForm.controls[
        'appointment_vaccine_responsibility'
      ].setValue(this.fatherarray.join(', '));

      this.initialAssessmentForm.controls[
        'medication_admin_responsibility'
      ].setValue(this.motherarray.join(', '));

      this.initialAssessmentForm.controls[
        'baby_supplies_responsibility'
      ].setValue(this.nursearray.join(', '));


      // Get the form data from the initialAssessmentForm
      const formData = new FormData();
      const formValue = this.initialAssessmentForm.value;
      // Loop through each control in the form and append it to the FormData
      Object.keys(formValue).forEach((key) => {
        if (Array.isArray(formValue[key])) {
          formData.append(`${key}`, JSON.stringify(formValue[key]));
        } else if (key != 'upload_photo') {
          formData.append(key, formValue[key]);
        }
        if (key == 'upload_photo') {
          formData.append('upload_photo', this.selectedFile);
        }
      });

      // if (assessmentForm.valid) {
      //edit form submission
      if (this.isedit == true) {
        const dataObject = {
          // service_hours: null,
          shift_mode: null,
          report_name: '',
          name: null,
          gender: null,
          date_of_birth: null,
          mode_delivery: null,
          upload_photo: null,
          health_issues: null,
          delivery_complications: null,
          age: null,
          any_special_needs: null,
          mother_name: null,
          mother_age: null,
          mother_dob: null,
          mother_working: null,
          father_name: null,
          father_para: null,
          father_dateofbirth: null,
          father_age: null,
          father_work_hours: null,
          family_religion: null,
          house_no: '',
          house_location: null,
          mother_work_hours: null,
          mother_para: null,
          days: null,
          months: null,
          years: null,
          sibling_details_json: {},
          relatives_name_json: {},
          nurses: [],
          relatives_house: null,
          type_of_house: null,
          house_address: '',
          mother_mental_supp: null,
          appointment_vaccine_responsibility: null,
          medication_admin_responsibility: null,
          baby_supplies_responsibility: null,
          environment_changes: null,
          contact_no: '',
          report_issue: null,
          no_of_maids: null,
          environment_identify_require: 'null',
          mental_supp_specify: 'null',
          care_plan_id: 1,
          is_contract: 1,
          contract_json: {},
          // associated_nurse_id: null,
          mobile: null,
          email: null,
          mother_mobile: null,
          mother_email: null,
          any_speical_needs: null,
          speical_identify: null,
          health_identify: null,
          reason: null,
        };
        let formValue = this.initialAssessmentForm.value
        formValue.nurses.forEach((item) => {
          dataObject.nurses.push(item);
        })
        JSON.stringify(formValue.nurses);
        Object.keys(this.initialAssessmentForm.controls).forEach(
          (controlName) => {
            const control = this.initialAssessmentForm.get(controlName);
            if (dataObject.hasOwnProperty(controlName)) {
              dataObject[controlName] = control.value;
            }
          }
        );
        // Special handling for arrays (e.g., sibling_details_json and relatives_name_json)
        // assuming you have nested FormArrays in the FormGroup
        if (
          this.initialAssessmentForm.get('sibling_details_json') instanceof
          FormArray
        ) {
          const siblingDetailsArray = this.initialAssessmentForm.get(
            'sibling_details_json'
          ) as FormArray;
          // Iterate through the controls of the sibling_details_json FormArray
          for (let i = 0; i < siblingDetailsArray.length; i++) {
            const siblingDetailsControl = siblingDetailsArray.at(i);
            // Update the corresponding property in dataObject
            dataObject.sibling_details_json[i] = {
              name: siblingDetailsControl.get('name').value,
              dob: siblingDetailsControl.get('dob').value,
              age: siblingDetailsControl.get('age').value,
              needs: siblingDetailsControl.get('needs').value,
            };
          }
        }
        if (
          this.initialAssessmentForm.get('relatives_name_json') instanceof
          FormArray
        ) {
          const siblingDetailsArray = this.initialAssessmentForm.get(
            'relatives_name_json'
          ) as FormArray;
          // Iterate through the controls of the relatives_name_json FormArray
          for (let i = 0; i < siblingDetailsArray.length; i++) {
            const relatives_name_jsonControl = siblingDetailsArray.at(i);
            // Update the corresponding property in dataObject
            dataObject.relatives_name_json[i] = {
              name: relatives_name_jsonControl.get('name').value,
            };
          }
        }
        if (
          this.initialAssessmentForm.get('contract_json') instanceof
          FormArray
        ) {
          const contractarray = this.initialAssessmentForm.get(
            'contract_json'
          ) as FormArray;
          // Iterate through the controls of the contract_json FormArray
          for (let i = 0; i < contractarray.length; i++) {
            const contract_jsoncontrols = contractarray.at(i);
            // Update the corresponding property in dataObject
            dataObject.contract_json[i] = {
              start_date: contract_jsoncontrols.get('start_date').value,
              end_date: contract_jsoncontrols.get('end_date').value,
              amount: contract_jsoncontrols.get('amount').value,
              total_days: contract_jsoncontrols.get('total_days').value,
              type_of_shift: contract_jsoncontrols.get('type_of_shift').value,
             

            };
          }
        }





        // 

        const id = localStorage.getItem('babyId');
        // this.spinner.show();

        this.initial_form_service
          .editInitialAssessmentForm(id, dataObject)
          .then(
            (res: any) => {
              this.spinner.hide()
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: res?.message,
              });
              setTimeout(() => {
                this.router.navigateByUrl('/portal/first-screen');
              }, 1000);
              this.isedit = false;
              // this.spinner.hide();
            },
            (error: any) => {
              this.spinner.hide()

              // Handle any errors that occurred during the API call
              
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: error?.message,
              });
            }
          );
      } else {

        this.initial_form_service
          .submitInitialAssessmentForm(formData)
          .subscribe(
            (response: any) => {
              this.spinner.hide()

              // Handle the API response here
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: response?.message,
                // router link
              });

              this.fatherarray = [];
              this.motherarray = [];
              this.nursearray = [];
              setTimeout(() => {
                this.softReload();
              }, 400);
              // this.initialAssessmentForm.setValue(null)
              // this.initialAssessmentForm.setValue = null
              // setTimeout(() => {
              //   Object.keys(this.initialAssessmentForm.controls).forEach(key => {
              //     this.initialAssessmentForm.controls[key].setErrors(null)
              //   })
              // }, 1000);
              // Object.keys(this.initialAssessmentForm.controls).forEach(key => {
              //   this.initialAssessmentForm.controls.values
              // })

              

              // Reset form, mark as untouched, and clear errors after 2 seconds
              setTimeout(() => {
                // this.resetFormAndErrors();
                // Clear any error messages

             
              }, 200);
              // this.spinner.hide();
              this.router.navigate(['/portal/first-screen']);
            },
            (error: any) => {

              this.spinner.hide()

              // Handle any errors that occurred during the API call
              // this.spinner.hide();
              
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: error?.message,
              });
            }
          );
      }
    } else {
      this.spinner.hide()

      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Fill All the Details!',
      });
      this.markAllControlsAsTouched();
    }
  }

  markAllControlsAsTouched() {
    Object.values(this.initialAssessmentForm.controls).forEach((control) => {
      control.markAsTouched();
    });
  }
  resetFormAndErrors() {
    // Reset the form
    this.initialAssessmentForm.reset();

    // Mark all controls as untouched
    this.initialAssessmentForm.markAsUntouched();
  }
  clearErrors() {
    Object.keys(this.initialAssessmentForm.controls).forEach((key) => {
      const control = this.initialAssessmentForm.get(key);
      control.setErrors(null);
    });

    this.messageService.clear();
  }
  setSpecialNeed(status: any, index: any) {
    let siblingValues = this.getsiblingarray();
    siblingValues.controls[index]['controls']['needs'].setValue(
      status.target.value
    );
    if (siblingValues.controls[index]['controls']['needs'].value == 'Yes') {
      siblingValues.controls[index]['controls'].special_needs_identify.enable();
    } else {
      siblingValues.controls[index][
        'controls'
      ].special_needs_identify.disable();
    }
    // this.getFormArray('siblingarray').controls['siblings_special_needs'].setValue(status);
  }

  // uploadPhoto(event: any) {
  //   const selectedFile: File = event.target.files[0];
  //   if (selectedFile) {
  //     // Do something with the selected file, such as storing it in a variable or uploading it.
  //     // Example: this.selectedFile = selectedFile;
  //   }
  // }
  uploadPhoto(event: any) {
    this.selectedFile = event.target.files[0];
    if (
      event.target.files[0].type == 'image/jpeg' ||
      'image/jpg' ||
      'image/png' ||
      'application/pdf'
    ) {
      return event.target.files[0];
    }
  }
  getNurseDropDown() {
    this.initial_form_service.getNurseDropDown().then((res: any) => {
      this.nurseData = res?.data;
    });
  }
  details: any = [];

  // getcontract(index) {
  //   let group = this.initialAssessmentForm.get('contract_json') as FormArray;
  //   return group.controls[index] as FormGroup;
  // }
  service(event: any) {

    let contractform = this.getContractFormArray().controls;
    let nurseArray = this.getNurses();
    // const newServiceHours = parseInt(event.value);

    let totalServiceHours = 0;
    nurseArray.value.forEach((nurse: any) => {
      totalServiceHours += parseInt(nurse.service_hours);
    });

    for (let i = 0; i < contractform.length; i++) {
      let contract = this.getcontract(i);
      contract.get('type_of_shift').setValue(String(totalServiceHours));
    }




    // let start = contract.get('start_date').value;
    // let end = contract.get('end_date').value;

    // // Call getDaysDifference with start and end dates
    // const totaldays = this.getDaysDifference(start, end);

    // contract.get('total_days').setValue(totaldays);









    // this.initialAssessmentForm.get('service_hours').setValue(event);
  }
  getAllUserPermission() {
    let id = localStorage.getItem('user_id');
    this.masterservice.getUserPremissionById(id).then((res: any) => {

      this.permissionData = res.data;

      for (let i = 0; i < this.permissionData.length; i++) {
        
        this.permissions.push(this.permissionData[i].permission_id);
      }
    })
  }

  checkPermission(permission: number) {
    //
    if (this.permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
    // return this.permissions.includes(permission);
  }
  onDropdownChange(event: any) {
    if (event.value === 'Other') {
      this.initialAssessmentForm.get('report_name').setValidators([Validators.required]);
      this.initialAssessmentForm.get('contact_no').setValidators([Validators.required]);

      // Update validation status
      this.initialAssessmentForm.get('report_name').updateValueAndValidity();
      this.initialAssessmentForm.get('contact_no').updateValueAndValidity();
    }
    else {
      this.initialAssessmentForm.get('contact_no').clearValidators();
      this.initialAssessmentForm.get('report_name').clearValidators();

      // Update validation status
      this.initialAssessmentForm.get('report_name').updateValueAndValidity();
      this.initialAssessmentForm.get('contact_no').updateValueAndValidity();
    }
  }


  addNurseObject(action: string, index: number) {
    let nurseArray = this.getNurses();
    // Assuming you have a boolean variable totalHours to track if total hours exceed 24
    this.totalHours = false;

    if (action === 'add') {
      // Calculate total service hours
      let totalServiceHours = 0;
      nurseArray.value.forEach((nurse: any) => {
        totalServiceHours += parseInt(nurse.service_hours);
      });

      // Assuming each service hour is a number
      // const newServiceHours = parseInt(nurseArray.at(index).get('service_hours').value);

      // Check if adding the new nurse will exceed 24 hours

      if (totalServiceHours < 24) {
        let newNurseArray = new FormGroup({
          associated_nurse_id: new FormControl('', Validators.required),
          service_hours: new FormControl('', Validators.required),
        });
        nurseArray.insert(index + 1, newNurseArray);
        this.updateServiceHours(totalServiceHours)
      } else {
        this.totalHours = true;
      }
    } else {
      this.totalHours = false;
      nurseArray.removeAt(index);
    }
  }

  updateServiceHours(totalhrs: any) {
    if (totalhrs) {
      let remainingHrs = 24 - totalhrs;

      // Iterate through the serviceArray and set the 'disabled' property for options exceeding remainingHrs
      this.serviceArray.forEach(item => {
        item.disabled = parseInt(item.value) > remainingHrs;
      });

    }
  }

  logout() {
    localStorage.clear();
    // window.location.reload();
    this.router.navigate(['/']);
  }


  getNurses(): FormArray {
    return this.initialAssessmentForm.get('nurses') as FormArray;
  }
}
