import { Component, OnInit } from '@angular/core';
import { MaikaListService } from 'src/app/services/maika-list.service';
import { MasterService } from 'src/app/services/master.service';
import { Router } from '@angular/router';
import { MessageService, ConfirmationService } from 'primeng/api';
import { InitialAssessmentFormService } from 'src/app/services/initial-assessment-form.service';
import { NgxSpinnerService } from 'ngx-spinner';
// import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-daily-care-plan',
  templateUrl: './daily-care-plan.component.html',
  styleUrls: ['./daily-care-plan.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DailyCarePlanComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Daily Care Plan',
      subTitle: 'Dashboard',
    },
  ];
  getToken: any;
  getRole: any;
  getname: any;
  permissionData: any = [];
  permissions: any = [];
  datalength: any;
  loading: any;
  edit: any;
  filterVal: string;
  dailyCare: any = [];
  vitamins: any = [];
  bottle: any = []
  viewDetail: any = {};
  latestTaskId: any;
  vaccination: any = [];
  stellrization: any = []
  skin: any = [];
  sleepTraing: any[];
  diaper: any = [];
  diaper_Frequency = []
  dinner: any = [];
  update: any;
  regular: any = [];
  sleepingmother: any = [];
  lunch: any = [];
  sleep: any = [];
  training: any = [];
  activity: any = [];
  temp: any;
  escort: any = [];
  playing: any = [];
  bathing: any = []
  tub: any = [];
  shower: any = []
  clothes: any = []
  medicine: any = [];
  breast_feeding: any = [];
  sleeping_nurse: any = []
  dataonzeroposition: any;
  changing_colthes_frequency: any;
  bathing_tub_frequency: any;
  temperature_monitoring_frequency: any;
  bathing_shower_frequency: any;
  school: any = [];
  displayMaximizable: boolean = false;
  displayMaximizable1: boolean = false;
  displayMaximizable2: boolean = false;
  displayMaximizable3: boolean = false;
  carePlans: string[] = [];
  constructor(
    private daily_care_plan: MaikaListService,
    private masterService: MasterService,
    private router: Router,
    private initial_form_service: InitialAssessmentFormService,
    private spinner: NgxSpinnerService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,


  ) {
    this.getAllUserPermission()
  }
  getAllDailyCarePlan() {
    this.spinner.show()
    const babyId = localStorage.getItem('babyId');
    this.daily_care_plan.getDailyCarePlanById(babyId).then((res: any) => {
      this.spinner.hide()
      this.dailyCare = res.data;
      this.datalength = res?.data?.length;
    }, (error: any) => {
      this.spinner.hide()
      // Handle any errors that occurred during the API call

    })
  }
  ngOnInit(): void {
    localStorage.removeItem('create');
    localStorage.removeItem('editedOrderId')
    this.getAllDailyCarePlan();
    // if(localStorage.getItem('showDTS')){
    //   this.showMaximizableDialog(this.latestTaskId)
    // }
    this.getname = localStorage.getItem('name');
    this.getToken = localStorage.getItem('token');
    this.getRole = localStorage.getItem('role')
  }

  genratenurseLink() {
    let nurce_care_link, data;

    const getEdit = localStorage.getItem('babyId');
    this.initial_form_service
      .getInitialAssessmentForm(getEdit)
      .then((res: any) => {
        data = res.data[0];

        let dob = data.date_of_birth.split("/");

        let birthDate = new Date(dob[2], dob[1] - 1, dob[0]);
        let today = new Date();
        const timeDiffMilliseconds = today.getTime() - birthDate.getTime();

        const daysDiff = Math.floor(timeDiffMilliseconds / (1000 * 3600 * 24));

        if (daysDiff <= 119) {
          nurce_care_link = '/portal/zero-to-three-months';
        }
        else if (daysDiff <= 365 && daysDiff > 119) {
          nurce_care_link = '/portal/four-months-to-one-year-plan';
        }
        else if (daysDiff <= 1095 && daysDiff > 365) {
          nurce_care_link = '/portal/one-year-to-three-year-plan';
        }
        else if (daysDiff > 1095) {
          nurce_care_link = nurce_care_link = '/portal/three-years-above';
        }

        localStorage.setItem('create', getEdit);

        this.router.navigateByUrl(nurce_care_link);
      })

  }


  showMaximizableDialog(id: any) {
    let date, month, year;
    this.viewDetail = {};

    this.dailyCare?.forEach((res: any) => {
      if (res.id == id) {
        this.viewDetail = res;

        // date = viewDetail.days;
        // month = res.months;
        // year = res.years;
        // 
        // 
        // this is for zero to 3 month
        // 
        // 
        if (this.viewDetail?.care_plan_id == 1) {

          this.dataonzeroposition = this.viewDetail.nanny_task_details_json;
          const formula_frequency =
            this.dataonzeroposition?.bathing_frequency_sponge?.frequency;
          const tub_frequency =
            this.dataonzeroposition?.bathing_tub_frequency?.frequency;
          const breast_frequency =
            this.dataonzeroposition?.feeding_frequency?.frequency;
          const feeding_frequency_breast =
            this.dataonzeroposition?.feeding_frequency_breast?.frequency;
          const changings_frequency =
            this.dataonzeroposition?.changing_colthes_frequency?.frequency;
          const temperature_monitoring_frequency =
            this.dataonzeroposition?.temperature_monitoring_frequency?.frequency;
          // this.breast_frequency = JSON.parse(res?.feeding_frequency_breast)?.frequency;
          // this.formula_frequency = JSON.parse(res?.feeding_frequency)?.frequency;
     
          // this.vaccination = this.dataonzeroposition?.vaccinations_json;
          this.vitamins = this.dataonzeroposition?.vitamins_medications_json;

          this.edit = id;
          this.bottle = this.dataonzeroposition?.formula_calculation;
          // this.medicine = this.dataonzeroposition?.vitamins_medications_json[0]?.medicine_time;
          //get data from skin care array
          this.skin = this.dataonzeroposition?.skin_care;
          this.stellrization = this.dataonzeroposition?.bottle_sterilization;
          this.diaper = this.dataonzeroposition?.diaper_calculation;
          this.bathing = this.dataonzeroposition?.bathing_time;
          this.tub = this.dataonzeroposition?.tub_bathing;
          this.breast_feeding = this.dataonzeroposition?.breast_feeding
          this.clothes = this.dataonzeroposition?.changing_clothes
          this.clothes = this.dataonzeroposition?.changing_clothes
          this.sleepingmother = this.dataonzeroposition?.sleeping_time;

          this.sleeping_nurse = this.dataonzeroposition?.nanny_time
          this.diaper_Frequency = this.dataonzeroposition?.diaper_changes
          // this.temp = JSON.parse(res?.temperature_monitoring_frequency)?.frequency;
          // this.tub_frequency = JSON.parse(res?.bathing_tub_frequency)?.frequency;
          // this.sponge_frequency = JSON.parse(res?.bathing_frequency_sponge)?.frequency;
          // this.changings_frequency = JSON.parse(res?.changing_colthes_frequency)?.frequency;
          // this.feeding_frequency_breast = JSON.parse(res?.feeding_frequency_breast)?.frequency;
          this.displayMaximizable = true;
        }
        // 
        // 
        // 
        // four to one year form 
        // 
        // 
        else if (this.viewDetail?.care_plan_id == 2) {
          this.displayMaximizable1 = true;
          this.dataonzeroposition = this.viewDetail.nanny_task_details_json;
          this.vaccination = this.dataonzeroposition?.vaccinations_json;
          this.vitamins = this.dataonzeroposition?.vitamins_medications_json;
          this.sleepTraing = this.dataonzeroposition?.sleep_training_json;
          //get data from form array
          this.skin = this.dataonzeroposition?.skin_care;
          this.lunch = this.dataonzeroposition?.lunch_food;
          this.dinner = this.dataonzeroposition?.dinner_food;
          this.regular = this.dataonzeroposition?.solid_food;
          this.edit = id;
          this.update = this.viewDetail.created_at;
          this.bottle = this.dataonzeroposition?.formula_calculation;
          this.stellrization = this.dataonzeroposition?.bottle_sterilization;
          this.diaper = this.dataonzeroposition?.diaper_calculation;
          this.bathing = this.dataonzeroposition?.bathing_time;
          this.tub = this.dataonzeroposition?.tub_bathing;
          this.shower = this.dataonzeroposition?.shower_bathing;
          this.breast_feeding = this.dataonzeroposition?.breast_feeding
          this.clothes = this.dataonzeroposition?.changing_clothes
          this.sleepingmother = this.dataonzeroposition?.sleeping_time;

          this.sleeping_nurse = this.dataonzeroposition?.nanny_time
          this.diaper_Frequency = this.dataonzeroposition?.diaper_changes
       
        }
        // 
        // 
        // one to three year
        // 
        // 
        if (this.viewDetail?.care_plan_id == 3) {
          this.dataonzeroposition = this.viewDetail.nanny_task_details_json;
          console.log(this.dataonzeroposition,"bottle");
          
          this.vaccination = this.dataonzeroposition?.vaccinations_json;
          this.vitamins = this.dataonzeroposition?.vitamins_medications_json;
          this.regular = this.dataonzeroposition?.solid_food;
          //get data from formarray
          this.lunch = this.dataonzeroposition?.lunch_food;
          this.dinner = this.dataonzeroposition?.dinner_food;
          this.playing = this.dataonzeroposition?.outside_child;
          this.escort = this.dataonzeroposition?.escort_child;
          this.activity = this.dataonzeroposition?.activities_name;
          this.sleep = this.dataonzeroposition?.sleeping_training;
          this.training = this.dataonzeroposition?.potty_training;
          this.skin = this.dataonzeroposition?.skin_care;
          this.bottle = this.dataonzeroposition?.formula_calculation;
          this.stellrization = this.dataonzeroposition?.bottle_sterilization;
          this.diaper = this.dataonzeroposition?.diaper_calculation;
          this.bathing = this.dataonzeroposition?.bathing_time;
          this.shower = this.dataonzeroposition?.shower_bathing;

          this.tub = this.dataonzeroposition?.tub_bathing;
          this.breast_feeding = this.dataonzeroposition?.breast_feeding
          this.edit = id;
          this.clothes = this.dataonzeroposition?.changing_clothes
          this.sleepingmother = this.dataonzeroposition?.sleeping_time;

          this.sleeping_nurse = this.dataonzeroposition?.nanny_time
          this.diaper_Frequency = this.dataonzeroposition?.diaper_changes
   
          this.displayMaximizable2 = true;
        }
        // 
        // 
        // three year and above
        // 
        // 
        if (this.viewDetail?.care_plan_id == 4) {
          this.displayMaximizable3 = true;
          this.dataonzeroposition = this.viewDetail.nanny_task_details_json;
          this.vaccination = this.dataonzeroposition?.vaccinations_json;
          this.vitamins = this.dataonzeroposition?.vitamins_medications_json;
          this.changing_colthes_frequency = this.dataonzeroposition?.temperature_monitoring_frequency;
          this.bathing_shower_frequency = this.dataonzeroposition?.bathing_tub_frequency;
          this.bathing_tub_frequency = this.dataonzeroposition?.bathing_shower_frequency;
          this.temperature_monitoring_frequency = this.dataonzeroposition?.changing_colthes_frequency;
          //get data from formarray
          this.lunch = this.dataonzeroposition?.lunch_food;
          this.dinner = this.dataonzeroposition?.dinner_food;
          this.regular = this.dataonzeroposition?.regular_food;
          this.playing = this.dataonzeroposition?.outside_child;
          this.escort = this.dataonzeroposition?.escort_child;
          this.activity = this.dataonzeroposition?.activities_name;
          this.sleep = this.dataonzeroposition?.sleeping_training;
          this.training = this.dataonzeroposition?.child_training;
          this.diaper = this.dataonzeroposition?.diaper_change_times;
          this.bathing = this.dataonzeroposition?.bathing_time;
          this.tub = this.dataonzeroposition?.tub_bathing;
          this.shower = this.dataonzeroposition?.shower_bathing;
          this.skin = this.dataonzeroposition?.skin_care;
          this.school = this.dataonzeroposition?.school_name;
          this.edit = id;
          this.clothes = this.dataonzeroposition?.changing_clothes
          this.sleepingmother = this.dataonzeroposition?.sleeping_time;

          this.sleeping_nurse = this.dataonzeroposition?.nanny_time
          
        
        }
        else {
        }
      }


    })

  }

  EditEntry(id: any) {

  }
  EditOnClick(id: any) {
    localStorage.setItem('editedOrderId', id);

    this.dailyCare?.forEach((res: any) => {
      if (res.id == id) {
        this.viewDetail = res;
      }
    });


    if (this.viewDetail) {
      if (this.viewDetail.care_plan_id == 1) {
        this.router.navigateByUrl('/portal/zero-to-three-months');
      } else if (this.viewDetail.care_plan_id == 2) {
        this.router.navigateByUrl('/portal/four-months-to-one-year-plan');
      } else if (this.viewDetail.care_plan_id == 3) {
        this.router.navigateByUrl('/portal/one-year-to-three-year-plan');
      } else if (this.viewDetail.care_plan_id == 4) {
        this.router.navigateByUrl('/portal/three-years-above');
      }
    }
  }
  formatDateTime(date: string | undefined): string {
    if (date) {
      const formattedDate = new Date(date);
      const hours = formattedDate.getHours();
      const minutes = ('0' + formattedDate.getMinutes()).slice(-2);
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const displayHours = hours % 12 || 12; // Convert 0 to 12

      return `${displayHours}:${minutes} ${ampm}`;
    }
    return '-';
  }

  getAllUserPermission() {
    let id = localStorage.getItem('user_id');
    this.masterService.getUserPremissionById(id).then((res: any) => {

      this.permissionData = res.data;

      for (let i = 0; i < this.permissionData.length; i++) {
        
        this.permissions.push(this.permissionData[i].permission_id);
      }
    })
  }
  checkPermission(permission: number) {
    //
    if (this.permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
    // return this.permissions.includes(permission);
  }
  /* -------------------------------------------------------------------------- */
  /*            //Soft Reloading            */
  /* -------------------------------------------------------------------------- */
  softReload() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['portal/patient-view']);
    });
  }
  reset(dt) {
    dt.reset();
    this.filterVal = '';
  }
  // deleteplan(id: any) {

  //   // Ask for confirmation before proceeding
  //   if (confirm("Are you sure you want to delete this plan?")) {

  //     this.spinner.show();

  //     // Directly call the deletecareplan method without confirmation
  //     // this.confirmationService.confirm({
  //     //   message: 'Do you want to delete this record?',
  //     //   header: 'Delete Confirmation',
  //     //   icon: 'pi pi-info-circle',
  //     //   accept: () => {
  //     this.daily_care_plan
  //       .deletecareplan(id)
  //       .subscribe(
  //         (data: any) => {
  //           this.spinner.hide();
  //           this.messageService.add({
  //             severity: 'success',
  //             detail: data?.message,
  //           });
  //         },
  //         (error: any) => {
  //           this.spinner.hide();
  //         }
  //       );
  //     // }
  //   }
  //   this.softReload();
  // }
  deleteplan(id: any) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: "p-button-danger p-button-text",
      rejectButtonStyleClass: "p-button-text p-button-text",
      acceptIcon: "none",
      rejectIcon: "none",
      accept: () => {
        this.daily_care_plan.deletecareplan(id).subscribe(
          () => {
            this.messageService.add({ severity: 'success', summary: 'Success' });
          },
          error => {
          }
        );
        this.softReload();
      },
      reject: () => {
        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
      }
    });
  }
  // deleteplan(id: any) {
  //   this.confirmationService.confirm({
  //     message: 'Do you want to delete this record?',
  //     header: 'Delete Confirmation',
  //     icon: 'pi pi-info-circle',
  //   });
  //   accept: () => {
  //     this.spinner.show();

  //     // Directly call the deletecareplan method without confirmation
  //     this.daily_care_plan
  //       .deletecareplan(id)
  //       .subscribe(
  //         (data: any) => {
  //           this.spinner.hide();
  //           this.messageService.add({
  //             severity: 'success',
  //             detail: data?.message,
  //           });
  //         },
  //         (error: any) => {
  //           this.spinner.hide();
  //         }
  //       );
  //   }

  //   this.softReload();
  // }
  logout() {
    localStorage.clear();
    // window.location.reload();
    this.router.navigate(['/']);
  }
}
