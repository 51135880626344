<div class="main-content d-flex flex-column">
    <p-toast></p-toast>
    <p-confirmDialog [style]="{width:'40vw'}" [baseZIndex]="10000"></p-confirmDialog>
    <app-dashboard-navbar></app-dashboard-navbar>

    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item">{{Content.subTitle}}</li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <div class="add-listings-box">
        <form (ngSubmit)="formSubmit(momentsForm)" #momentsForm="ngForm" >
            <div class="row justify-content-between align-items-center">
                <div class="form-group col-md-4 col-sm-6 col-8">
                    <label class="p-0 mb-0">Name<span class="required">*</span></label>
                    <input type="text" [(ngModel)]="momentData.tags" #tags="ngModel"
                    name="tags" class="form-control" placeholder="Name" required>
                    <div *ngIf="momentsForm.submitted || tags.touched" class="Is-invalid text-danger" style="font-weight: 500; font-size: 13.5px;">
                        <div *ngIf="tags.errors?.['required']">
                            Name is Required.
                        </div>
                    </div>
                </div>
                <div class="add-listings-btn mb-0 col-4 text-end">
                    <button type="submit">Add</button>
                </div>
            </div>
        </form>
    </div>
    <div class="add-listings-box">
        <div class="Master-view-box">
            <div class="theader">
                <div class="d-flex">
                    <span class="p-input-icon-left ml-auto mr-2 w-100%">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" class="form-control text-center" scriptInjection
                            placeholder="Search Keyword" [(ngModel)]="filterval" (input)="dt1.filterGlobal($event.target.value, 'contains')" />
                    </span>
                    <p-button type="button" class="mx-2" icon="bx bx-reset" (click)="reset(dt1)"  tooltipPosition="bottom"></p-button>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-lg-12 col-md-12">
                <p-table #dt1 [value]="moment" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
                    [tableStyle]="{'min-width': '50rem', 'width' : '100%'}"
                    styleClass="p-datatable-sm p-datatable-customers" [rowsPerPageOptions]="[10,25,50]"
                    responsiveLayout="scroll" [loading]="loading" [paginator]="true"
                    currentPageReportTemplate="{totalRecords} of records" [globalFilterFields]="['tags']"
                    editMode="row">
                    <ng-template pTemplate="header" style="min-height: 500px;">
                        <tr>
                            <th>S. No.</th>

                            <th pSortableColumn="name">Name</th>
                            <th>Action</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-moment let-i="rowIndex" let-editing="editing">
                        <tr [pEditableRow]="moment">
                            <td >
                                {{i + 1}}
                            </td>
                            <td style="min-width: 130px;">
                                <p-cellEditor editingRowKeys="id">
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" scriptInjection [(ngModel)]="moment.tags"
                                            required>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{moment?.tags}}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td 
                            class="d-flex justify-content-center align-items-center">
                            <button *ngIf="!editing" pButton pRipple icon="bx bx-pencil"
                            class="p-button-rounded p-button-text p-button-success mr-2"
                            (click)="editMemoriesMaster(moment)" pInitEditableRow></button>
                            <button *ngIf="!editing" pButton pRipple icon="bx bx-trash"
                            class="p-button-rounded p-button-text p-button-danger"
                            (click)="deleteMaster(moment)"></button>
                            <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow
                            icon="pi pi-check" (click)="onRowEditSave(moment)"
                            class="p-button-rounded p-button-success p-button-text mr-2"></button>
                            <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow
                            icon="pi pi-times" (click)="onRowEditCancel(moment, i)"
                            class="p-button-rounded p-button-danger p-button-text"></button>
                            </td>
                        </tr> 
                    </ng-template>    
                </p-table> 
            </div>
        </div>
    </div>
    <app-copyrights></app-copyrights>
</div>
