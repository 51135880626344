<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->
<p-toast></p-toast>
<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">
	<app-dashboard-navbar></app-dashboard-navbar>

	<div class="dropdown profile-nav-item d-flex flex-column align-items-end">
		<a class="dropdown-toggle admin-btn" style="text-decoration: none;" role="button" data-bs-toggle="dropdown"
			aria-haspopup="true" aria-expanded="false">
			<div class="menu-profile">
				<img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
				<span class="name">{{getname}}</span>
			</div>
		</a>
		<div class="dropdown-menu d-flex flex-column align-items-center">
			<div class="dropdown-header align">
				<div class="figure mb-3">
					<img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
				</div>
				<div class="info text-center">
					<span class="name" style="white-space: break-spaces;">{{getname}}</span>
					<p class="mb-3 email">{{getRole}}</p>
				</div>
			</div>
			<!-- <div class="dropdown-body">
				<ul class="profile-nav p-0 pt-3">
					<li class="nav-item"><a routerLink="/dashboard-my-profile" class="nav-link"><i class='bx bx-user'></i> <span>Profile Dashboard</span></a></li>
				</ul>
			</div> -->
			<div class="dropdown-footer">
				<ul class="profile-nav">
					<li class="nav-item"><a class="nav-link" (click)="logout()"><i class='bx bx-log-out'></i> <span
								class="logout-text">Logout</span></a></li>
				</ul>
			</div>

		</div>
	</div>

	<!-- Breadcrumb Area -->
	<div *ngFor="let Content of breadcrumb" class="breadcrumb-area">
		<h1>{{ Content.title }}</h1>
		<ol class="breadcrumb">
			<li class="item">
				<a routerLink="/">Home</a>
			</li>
			<li class="item">
				{{ Content.subtitle }}
			</li>
			<li class="item">{{ Content.title }}</li>
		</ol>
	</div>
	<!-- End Breadcrumb Area -->
	<form #formSubmitted="ngForm" [formGroup]="zeroToThreeMonitor"
		(ngSubmit)="submitGrowthMonitoringForm(formSubmitted) " (keydown.enter)="$event.preventDefault()">
		<!-- BABY DETAILS SECTION -->
		<div class="add-listings-box">
			<h3>Baby Details</h3>
			<div class="row">
				<div class="col-lg-4 col-sm-6 col-12">
					<div class="form-group">
						<label class="p-0">
							Name
							<span class="required">*</span>
						</label>
						<p-dropdown [options]="babyDetails" [filter]="true" [resetFilterOnHide]="true" [readonly]="true"
							[class.is-invalid]="(formSubmitted.submitted &&
                            zeroToThreeMonitor.get('baby_details_id')?.invalid)
                            ||
                            zeroToThreeMonitor.get('baby_details_id')?.invalid
                            &&
                            zeroToThreeMonitor.get('baby_details_id').touched" (onChange)="getBabyDetails($event)"
							optionValue="id" optionLabel="name" filterBy="name" formControlName="baby_details_id"
							placeholder="Select Baby's Name">
						</p-dropdown>
						<div class="invalid-feedback">Name is required</div>
					</div>
				</div>
				<div class="col-lg-4 col-sm-6 col-12">
					<div class="form-group">
						<label class="px-0">Gender</label>
						<div class="card flex justify-content-center">
							<input [(ngModel)]="babyGender" [ngModelOptions]="{standalone: true}" [readonly]="true"
								type="text" class="form-control" placeholder="Select Baby's Gender" required>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-sm-6 col-12">
					<div class="form-group">
						<label class="px-0">Date of birth</label>
						<div class="card flex justify-content-center">
							<!-- <p-calendar readonly [(ngModel)]="selectedDate" [ngModelOptions]="{standalone: true}"
                                [showIcon]="true" [readonlyInput]="true" placeholder="DD/MM/YYYY"></p-calendar> -->
							<input [value]="selectedDate" type="text" name="name" class="form-control"
								placeholder="DD/MM/YYYY" readonly>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-sm-6 col-12">
					<div class="form-group">
						<label class="px-0">Days</label>
						<input [value]="days" type="text" name="days" class="form-control" placeholder="Days" readonly>
					</div>
				</div>
				<div class="col-lg-4 col-sm-6 col-12">
					<div class="form-group">
						<label class="px-0">Months</label>
						<input [value]="month" type="text" name="months" class="form-control" placeholder="Months"
							readonly>
					</div>
				</div>
				<div class="col-lg-4 col-sm-6 col-12">
					<div class="form-group">
						<label class="px-0">Years</label>
						<input [value]="years" type="text" name="years" class="form-control" placeholder="Years"
							readonly>
					</div>
				</div>
			</div>
		</div>
		<ng-container *ngIf="flag">
			<!-- Form section -->
			<!-- *MOTOR AND COORDINATION SECTION -->
			<div class="add-listings-box py-3">
				<div class="row">
					<label class="subHeading mb-3">Height Weight</label>
					
					
					<div formArrayName="health_json">
						<!-- <div class="row">
						  <div class="col-lg-4 col-sm-6 col-12" *ngIf="isedit">
							<div class="form-group">
							  <div class="secondary-btn">
								<button type="button" [disabled]="heightConverted" (click)="convertHeightToCm()">Convert Height to CM</button>
							  </div>
							</div>
						  </div>
						  <div class="col-lg-4 col-sm-6 col-12" *ngIf="isedit">
							<div class="form-group">
							  <div class="secondary-btn">
								<button type="button"[disabled]="weightConverted" (click)="convertKgToPounds()">Convert Weight to Pounds</button>
							  </div>
							</div>
						  </div>
						</div> -->
					  
						<ng-container *ngFor="let control of gethealthFormArray().controls; index as i; last as last">
						  <div class="row" [formGroupName]="i">
							<div class="col-lg-4 col-sm-6 col-12">
							  <div class="form-group">
								<label class="px-0">Date</label>
								<div class="card flex justify-content-center">
								  <p-calendar formControlName="time" [showIcon]="true" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" [minDate]="today" [maxDate]="today"></p-calendar>
								</div>
							  </div>
							</div>
					  
							<div class="col-lg-4 col-sm-6 col-12">
							  <div class="form-group">
								<label class="px-0">Height</label>
								<div class="pl-0 checkbox-list">
								  <input type="text" formControlName="height" class="form-control" maxlength="5" placeholder="Enter Height" />
								</div>
							  </div>
							</div>
					  
							<div class="col-lg-4 col-sm-6 col-12">
							  <div class="form-group">
								<label class="px-0">Weight</label>
								<div class="pl-0 checkbox-list">
								  <input type="text" formControlName="weight" class="form-control" maxlength="5" placeholder="Enter Weight" (keypress)="keyPress($event)" />
								</div>
							  </div>
							</div>
					  
							<div class="col-12 d-flex mb-3 justify-content-end">
							  <p-button type="button" icon="bx bx-plus" (click)="addVitaminObject('add', i)" iconPos="right" class="mr-2 secondary-btn"></p-button>
							  <div class="remove">
								<p-button type="button" *ngIf="i != 0" class="ml-2 secondary-btn" icon="bx bx-minus" (click)="addVitaminObject('delete', i)" iconPos="right"></p-button>
							  </div>
							</div>
						  </div>
						</ng-container>
					  </div>
					  
				</div>
			</div>

			<!-- <div class="add-listings-box py-3">
				<div class="row">
					<label class="subHeading mb-3">Baby Images of Growth Monitoring </label>
					<div formArrayName="baby_images">
						<ng-container *ngFor="let control of getImageArray().controls; index as i; last as last">
							<div class="row" [formGroupName]="i">
								
								<div class="col-lg-4 col-sm-6 col-12">
									<div class="form-group">
										<label class="px-0">Upload Photo </label>
										<input (change)="uploadPhoto($event)" type="file" accept=".png, .jpg, .jpeg" class="inputfile" placeholder="Name" />
									</div>
								</div>
							</div>
							<div class="col-12 d-flex mb-3 justify-content-end">
								<p-button type="button" icon="bx bx-plus" (click)="addBabyImg('add', i)"
									iconPos="right" class="mr-2"></p-button>
								<div class="remove">
									<p-button type="button" *ngIf="i!= 0" class="ml-2" icon="bx bx-minus"
										(click)="addBabyImg('delete', i)" iconPos="right"></p-button>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</div> -->


			<div class="add-listings-box py-3">
				<div class="row">
					<label class="subHeading mb-3">Baby Images of Growth Monitoring </label>
					<div *ngIf="babyImgJSon">
						<div *ngFor="let control of babyImgJSon">
							<a href="{{ImageUrl + control?.name}}" target="_blank">View</a>
						</div>
					</div>


					<div formArrayName="baby_images">
						<label class="fw-500 mb-1">Upload Photo </label>

						<ng-container *ngFor="let control of getImageArray().controls; index as i; last as last">

							<div class="row" [formGroupName]="i">
								<div class="col-lg-4 col-sm-6 col-12">
									<div class="form-group mb-2">
										<input (change)="uploadPhoto($event)" type="file" accept=".png, .jpg, .jpeg"
											class="form-control" aria-label="Upload"
											aria-describedby="inputGroupFileAddon">


									</div>
								</div>
								<div class="col-lg-4 col-sm-6 col-12 d-flex mb-2 align-items-end">
									<p-button type="button" icon="bx bx-plus" (click)="addBabyImg('add', i)"
										iconPos="right" class="mr-2 secondary-btn"></p-button>
									<div class="remove">
										<p-button type="button" *ngIf="i!= 0" class="ml-2 secondary-btn"
											icon="bx bx-minus" (click)="addBabyImg('delete', i)"
											iconPos="right"></p-button>
									</div>
								</div>

							</div>

						</ng-container>

					</div>

					<div class="d-flex align-items-start">
						<button class="p-button" type="button" (click)="openCamera()">
							<i class="fas fa-camera"></i>
						</button>
						<div class=" imgPreview" *ngFor="let img of capturedByCamera">

							<!-- <a (click)="previewImage(img)">Click to preview</a> -->
							<a (click)="deletecapture(img)">✖</a>
							<img [src]="img" alt="">

						</div>


					</div>
				</div>
			</div>

			<div class="add-listings-box py-3">
				<h3 class="text-center">0-3 MONTHS OLD</h3>
				<div class="d-flex col-lg-6 col-md-12 justify-content-between">
					<label class="subHeading mb-3">
						Motor and Coordination
					</label>
					<label class="subHeading2 mb-3">Remarks</label>
				</div>
				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								Turns head side to side
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="head-yes" name="head" value="Yes" formControlName="head"
										(change)="handleInput($event, 'headside')">
									<label for="head-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="head-no" name="head" value="No" formControlName="head"
										(change)="handleInput($event, 'headside')">
									<label for="head-no">No</label>
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="head_description">
						</div>
					</div>
				</div>



				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								Holds up head during tummy time
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="holdhead-yes" formControlName="hold_head" value="Yes"
										(change)="handleInput($event, 'headhold')">
									<label for="holdhead-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="holdhead-no" formControlName="hold_head" value="No"
										(change)="handleInput($event, 'headhold')">
									<label for="holdhead-no">No</label>
								</p>

							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="holdhead_description">
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">Clenches fists</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="clenches-yes" formControlName="clenches" value="Yes"
										(change)="handleInput($event, 'clenches')">
									<label for="clenches-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="clenches-no" formControlName="clenches" value="No"
										(change)="handleInput($event, 'clenches')">
									<label for="clenches-no">No</label>
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="clenches_description">
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">Gripping finger</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="gripping-finger-yes" formControlName="gripping_finger"
										value="Yes" (change)="handleInput($event, 'gripping')">
									<label for="gripping-finger-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="gripping-finger-no" formControlName="gripping_finger"
										value="No" (change)="handleInput($event, 'gripping')">
									<label for="gripping-finger-no">No</label>
								</p>

							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="gripping_finger_description">
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">Pushes up on arms</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="pushes-up-yes" formControlName="pushes_up" value="Yes"
										(change)="handleInput($event, 'pushes')">
									<label for="pushes-up-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="pushes-up-no" formControlName="pushes_up" value="No"
										(change)="handleInput($event, 'pushes')">
									<label for="pushes-up-no">No</label>
								</p>

							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="pushes_up_description">
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								Lifts and holds head up
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="head-up-yes" formControlName="head_up" value="Yes"
										(change)="handleInput($event, 'lifts')">
									<label for="head-up-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="head-up-no" formControlName="head_up" value="No"
										(change)="handleInput($event, 'lifts')">
									<label for="head-up-no">No</label>
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="head_up_description">
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								Moves fists from closed to open
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="fists-move-yes" formControlName="fists_move" value="Yes"
										(change)="handleInput($event, 'fists')">
									<label for="fists-move-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="fists-move-no" formControlName="fists_move" value="No"
										(change)="handleInput($event, 'fists')">
									<label for="fists-move-no">No</label>
								</p>

							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="fists_move_description">
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								Bring hands to mouth
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="bring-hands-yes" formControlName="bring_hands" value="Yes"
										(change)="handleInput($event, 'bring_hands')">
									<label for="bring-hands-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="bring-hands-no" formControlName="bring_hands" value="No"
										(change)="handleInput($event, 'bring_hands')">
									<label for="bring-hands-no">No</label>
								</p>

							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="bring_hands_description">
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								Moves legs and arms off of the surface when excited
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="move-legs-yes" formControlName="move_legs" value="Yes"
										(change)="handleInput($event, 'move_legs')">
									<label for="move-legs-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="move-legs-no" formControlName="move_legs" value="No"
										(change)="handleInput($event, 'move_legs')">
									<label for="move-legs-no">No</label>
								</p>

							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="move_legs_description">
						</div>
					</div>
				</div>
			</div>
			<!-- *SENSORY SECTION -->
			<div class="add-listings-box py-3">
				<div class="d-flex col-lg-6 col-md-12 justify-content-between">
					<label class="subHeading mb-3">Sensory</label>
					<label class="subHeading2 mb-3">Remarks</label>
				</div>
				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								Moves legs and arms off of the surface when excited
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="move-legs-surface-yes" formControlName="move_legs1"
										value="Yes" (change)="handleInput($event, 'move_legs1')">
									<label for="move-legs-surface-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="move-legs-surface-no" formControlName="move_legs1"
										value="No" (change)="handleInput($event, 'move_legs1')">
									<label for="move-legs-surface-no">No</label>
								</p>

							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="move_legs_description1">
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								When lying on back, visually tracks a moving toy from side to side
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="moving-toy-yes" formControlName="moving_toy" value="Yes"
										(change)="handleInput($event, 'moving_toy')">
									<label for="moving-toy-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="moving-toy-no" formControlName="moving_toy" value="No"
										(change)="handleInput($event, 'moving_toy')">
									<label for="moving-toy-no">No</label>
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="moving_toy_description">
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								When lying on back, keeps head centered to watch faces or toys
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="lying-yes" formControlName="lying" value="Yes"
										(change)="handleInput($event, 'lying')">
									<label for="lying-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="lying-no" formControlName="lying" value="No"
										(change)="handleInput($event, 'lying')">
									<label for="lying-no">No</label>
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="lying_description">
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								Able to calm with rocking, touching, and gentle sounds
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="calm-yes" formControlName="calm" value="Yes"
										(change)="handleInput($event, 'calm')">
									<label for="calm-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="calm-no" formControlName="calm" value="No"
										(change)="handleInput($event, 'calm')">
									<label for="calm-no">No</label>
								</p>

							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="calm_description">
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								Enjoys a variety of movements
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="enjoys-yes" formControlName="enjoys_movements" value="Yes"
										(change)="handleInput($event, 'enjoys')">
									<label for="enjoys-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="enjoys-no" formControlName="enjoys_movements" value="No"
										(change)="handleInput($event, 'enjoys')">
									<label for="enjoys-no">No</label>
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="enjoys_movements_description">
						</div>
					</div>
				</div>
			</div>
			<!-- *COMMUNICATION SECTION -->
			<div class="add-listings-box py-3">
				<div class="d-flex col-lg-6 col-md-12 justify-content-between">
					<label class="subHeading mb-3">Communication</label>
					<label class="subHeading2 mb-3">Remarks</label>
				</div>
				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								Quiets or smiles in response to sound or voice
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="quiets-yes" formControlName="quiets" value="Yes"
										(change)="handleInput($event, 'quiets')">
									<label for="quiets-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="quiets-no" formControlName="quiets" value="No"
										(change)="handleInput($event, 'quiets')">
									<label for="quiets-no">No</label>
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="quiets_description">
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								Turns head towards sound or voice
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="turns-head-yes" formControlName="turns_head" value="Yes"
										(change)="handleInput($event, 'turns_head')">
									<label for="turns-head-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="turns-head-no" formControlName="turns_head" value="No"
										(change)="handleInput($event, 'turns_head')">
									<label for="turns-head-no">No</label>
								</p>

							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="turns_head_description">
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								Shows interest in faces
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="interest-yes" formControlName="interest" value="Yes"
										(change)="handleInput($event, 'interest')">
									<label for="interest-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="interest-no" formControlName="interest" value="No"
										(change)="handleInput($event, 'interest')">
									<label for="interest-no">No</label>
								</p>

							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="interest_description">
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">Makes eye contact</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="eye-contact-yes" formControlName="eye_contact" value="Yes"
										(change)="handleInput($event, 'eye_contact_description')">
									<label for="eye-contact-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="eye-contact-no" formControlName="eye_contact" value="No"
										(change)="handleInput($event, 'eye_contact_description')">
									<label for="eye-contact-no">No</label>
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="eye_contact_description">
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								Cries differently for different needs
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="cries-yes" formControlName="cries" value="Yes"
										(change)="handleInput($event, 'cries')">
									<label for="cries-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="cries-no" formControlName="cries" value="No">
									<label for="cries-no" (change)="handleInput($event, 'cries')">No</label>
								</p>

							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="cries_description">
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">Coos and smiles</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="smiles-yes" formControlName="smiles" value="Yes"
										(change)="handleInput($event, 'smiles')">
									<label for="smiles-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="smiles-no" formControlName="smiles" value="No"
										(change)="handleInput($event, 'smiles')">
									<label for="smiles-no">No</label>
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="smiles_description">
						</div>
					</div>
				</div>
			</div>
			<!-- *FEEDING SECTION -->
			<div class="add-listings-box py-3">
				<div class="d-flex col-lg-6 col-md-12 justify-content-between">
					<label class="subHeading mb-3">Feeding</label>
					<label class="subHeading2 mb-3">Remarks</label>
				</div>
				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								Latches onto while nursing or bottle feeding
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="latches-yes" formControlName="latches" value="Yes"
										(change)="handleInput($event, 'latches')">
									<label for="latches-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="latches-no" formControlName="latches" value="No"
										(change)="handleInput($event, 'latches')">
									<label for="latches-no">No</label>
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="latches_description">
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								Tongue moves forward and back to suck
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="tounge-move-yes" formControlName="tounge" value="Yes"
										(change)="handleInput($event, 'tounge')">
									<label for="tounge-move-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="tounge-move-no" formControlName="tounge" value="No"
										(change)="handleInput($event, 'tounge')">
									<label for="tounge-move-no">No</label>
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="tounge_description">
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								Drinks 2-6 oz. of liquid per feeding, 6 time per day
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="drinks-yes" formControlName="drinks" value="Yes"
										(change)="handleInput($event, 'drinks')">
									<label for="drinks-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="drinks-no" formControlName="drinks" value="No"
										(change)="handleInput($event, 'drinks')">
									<label for="drinks-no">No</label>
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="drinks_description">
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								Sucks and swallows well during feeding
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="sucks-yes" formControlName="sucks" value="Yes"
										(change)="handleInput($event, 'sucks')">
									<label for="sucks-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="sucks-no" formControlName="sucks" value="No"
										(change)="handleInput($event, 'sucks')">
									<label for="sucks-no">No</label>
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="sucks_description">
						</div>
					</div>
				</div>
			</div>
			<!-- *PLAY AND SOCIAL SKILLS SECTION -->
			<div class="add-listings-box py-3">
				<div class="d-flex col-lg-6 col-md-12 justify-content-between">
					<label class="subHeading mb-3">
						Play and Social Skills
					</label>
					<label class="subHeading2 mb-3">Remarks</label>
				</div>
				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								Enjoys playful face-to-face interaction with people
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="enjoys-playful-yes" formControlName="enjoys_playful"
										value="Yes" (change)="handleInput($event, 'enjoys-playful')">
									<label for="enjoys-playful-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="enjoys-playful-no" formControlName="enjoys_playful"
										value="No" (change)="handleInput($event, 'enjoys-playful')">
									<label for="enjoys-playful-no">No</label>
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="enjoys_playful_description">
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								Coos in response to playful interaction
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="coos-response-yes" formControlName="coos_reponse"
										value="Yes" (change)="handleInput($event, 'coos')">
									<label for="coos-response-yes">Yes</label>
								</p>
								<p class="me-3">
									<input type="radio" id="coos-response-no" formControlName="coos_reponse" value="No"
										(change)="handleInput($event, 'coos')">
									<label for="coos-response-no">No</label>
								</p>

							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="coos_reponse_description">
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								Notices and responds to sounds
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="responds-sounds-yes" formControlName="responds_sounds"
										value="Yes" (change)="handleInput($event, 'responds')">
									<label for="responds-sounds-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="responds-sounds-no" formControlName="responds_sounds"
										value="No" (change)="handleInput($event, 'responds')">
									<label for="responds-sounds-no">No</label>
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="responds_sounds_description">
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								Able to calm during car rides when not tired or hungry
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="able-to-calm-yes" formControlName="able_to_calm" value="Yes"
										(change)="handleInput($event, 'able_to')">
									<label for="able-to-calm-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="able-to-calm-no" formControlName="able_to_calm" value="No"
										(change)="handleInput($event, 'able_to')">
									<label for="able-to-calm-no">No</label>
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="able_to_calm_description">
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">Enjoys bath time</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="enjoys-bath-yes" formControlName="enjoys_bath" value="Yes"
										(change)="handleInput($event, 'enjoys_baths')">
									<label for="enjoys-bath-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="enjoys-bath-no" formControlName="enjoys_bath" value="No"
										(change)="handleInput($event, 'enjoys_baths')">
									<label for="enjoys-bath-no">No</label>
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="enjoys_baths_description">
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								Able to tolerate diaper changing without crying
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="tolerate-diaper-change-yes"
										formControlName="tolerate_diaper_change" value="Yes"
										(change)="handleInput($event, 'tolerate_diaper')">
									<label for="tolerate-diaper-change-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="tolerate-diaper-change-no"
										formControlName="tolerate_diaper_change" value="No"
										(change)="handleInput($event, 'tolerate_diaper')">
									<label for="tolerate-diaper-change-no">No</label>
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="tolerate_diaper_change_description">
						</div>
					</div>
				</div>
			</div>
			<!-- *SELF-EXPRESSION SECTION -->
			<div class="add-listings-box py-3">
				<div class="d-flex col-lg-6 col-md-12 justify-content-between">
					<label class="subHeading mb-3">Self-Experssion</label>
					<label class="subHeading2 mb-3">Remarks</label>
				</div>
				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								Comforted by cuddling
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="cuddling-yes" formControlName="cuddling" value="Yes"
										(change)="handleInput($event, 'cuddling')">
									<label for="cuddling-yes">Yes</label>
								</p>
								<p class="me-3">
									<input type="radio" id="cuddling-no" formControlName="cuddling" value="No"
										(change)="handleInput($event, 'cuddling')">
									<label for="cuddling-no">No</label>
								</p>

							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="cuddling_description">
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								Not upset with everyday sounds
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="upset-yes" formControlName="upset" value="Yes"
										(change)="handleInput($event, 'upset')">
									<label for="upset-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="upset-no" formControlName="upset" value="No"
										(change)="handleInput($event, 'upset')">
									<label for="upset-no">No</label>
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="upset_description">
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								Happy when not hungry or tired
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="happy-yes" formControlName="happy" value="Yes"
										(change)="handleInput($event, 'happy')">
									<label for="happy-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="happy-no" formControlName="happy" value="No"
										(change)="handleInput($event, 'happy')">
									<label for="happy-no">No</label>
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="happy_description">
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								Enjoys varied playful movement experiences such as rocking, touching, and
								calm
								sounds
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="playful-movment-yes" formControlName="playful_movment"
										value="Yes" (change)="handleInput($event, 'playful')">
									<label for="playful-movment-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="playful-movment-no" formControlName="playful_movment"
										value="No" (change)="handleInput($event, 'playful')">
									<label for="playful-movment-no">No</label>
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="playful_movment_description">
						</div>
					</div>
				</div>


				<div class="row">
					<div class="col-lg-4 col-sm-6 col-12">
						<div class="form-group mb-2">
							<label class="p-0">
								Does not demonstrate frequent or long bouts of being upset
							</label>
							<div class="d-flex">
								<p class="me-3">
									<input type="radio" id="demonstrate-yes" formControlName="demonstrate" value="Yes"
										(change)="handleInput($event, 'demonstrate')">
									<label for="demonstrate-yes">Yes</label>
								</p>
								<p class="d-flex">
									<input type="radio" id="demonstrate-no" formControlName="demonstrate" value="No"
										(change)="handleInput($event, 'demonstrate')">
									<label for="demonstrate-no">No</label>
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-sm-6 col-12 mt-auto">
						<div class="form-group mb-0">
							<input class="form-control" type="text" placeholder="Remark"
								formControlName="demonstrate_description">
						</div>
					</div>
				</div>
			</div>
		</ng-container>
		<div class="add-listings-btn" style="text-align: right">
			<button [disabled]="!flag" type="submit">
				Submit
			</button>
		</div>
	</form>


	<p-dialog id="capturewin" header="Capture Photo" [(visible)]="openCameraforUpload" [modal]="true"
		[draggable]="false" [closable]="false" [resizable]="false">
		<div>
			<button type="button" class="close-button" (click)="shutDownCamera()" aria-label="Close"></button>
			<video #video autoplay="true" playsinline="true"></video>
			<canvas #canvas style="display:none;"></canvas>
		</div>
		<div>
			<a class="captureButton" (click)="capture()"><i class="fas fa-camera"></i>
			</a>
		</div>
	</p-dialog>



	<app-copyrights></app-copyrights>
</div>