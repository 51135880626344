<div class="main-content d-flex flex-column">
    <app-dashboard-navbar></app-dashboard-navbar>

    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
        <h1>{{ Content.title }}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item">{{ Content.subTitle }}
            </li>
            <li class="item">{{ Content.title }}</li>
        </ol>
    </div>
    <div class="add-listings-box">
        <div class="Master-view-box">

            <div class="theader d-flex justify-content-between flex-wrap">
                <div class="d-flex mb-2">
                    <span class="p-input-icon-left ml-auto mr-2 w-sm-50 w-100%">
                        <i class="pi pi-search"> </i>
                        <input pInputText type="text" class="form-control "   style="text-align: left; padding-left: 20%; height: 45px !important" scriptInjection
                            placeholder="Search Keyword" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                            [(ngModel)]="filterVal" />
                    </span>
                    <p-button type="button" class="mx-2" (click)="reset(dt1)" icon="bx bx-reset"
                        tooltipPosition="bottom"></p-button>
                </div>
                <div class="add-listings-btn d-flex mb-0" style="text-align: right;">
                    <!-- <a routerLink="/portal/health-monitoring" class="nav-link"  *ngIf="checkPermission(37)"> -->
                    <a (click)="getbabyhealthdata()" class="nav-link" *ngIf="checkPermission(37)">
                        <button type="submit">Add New</button>
                    </a>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-lg-12 col-md-12">
                    <p-table #dt1 [value]="getHealth" dataKey="id"
                        [tableStyle]="{'min-width': '50rem', 'width' : '100%'}"
                        [globalFilterFields]="['baby_name', 'baby_details_id', 'nurse_name','created_at','created_at']"
                        [rowsPerPageOptions]="[10, 25, 50]" [rows]="10" [showCurrentPageReport]="true"
                        responsiveLayout="scroll" [paginator]="true"
                        currentPageReportTemplate="{totalRecords} of records">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="min-width: 62px">S. No.</th>
                                <th>Baby Name</th>
                                <th>Baby ID</th>
                                <th>Nurse Name</th>
                                <th>Create Date</th>
                                <th>Create Time</th>
                                <th>Summary</th>
                                <th class="text-center">Action</th>
                                <th>Add Task</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-i="rowIndex" let-product>
                            <tr>
                                <td>{{ i + 1 }}</td>
                                <td>{{ product.baby_name }}</td>
                                <td>{{ product.baby_details_id }}</td>
                                <td>{{ product.nurse_name }}</td>
                                <td>{{ product.create_time | date: 'dd-MM-yyyy' }}</td>
                                <td>{{ product.created_at | date: 'hh:mm' }}</td>
                                <td (click)="goToSummary(product.id)"> <a href="" (click)="goToSummary(product.id) ;  $event.preventDefault();">View</a></td>

                                <td class="text-center">
                                    <button pButton pRipple
                                        class="p-button-rounded p-button-text p-button-info view-button" pTooltip="View"
                                        tooltipPosition="bottom" (click)="showMaximizableDialog(product.id)">
                                        <i class="far fa-eye"></i>
                                    </button>
                                    <button *ngIf="checkPermission(37)" pButton pRipple
                                        class="p-button-rounded p-button-text p-button-info view-button" pTooltip="Edit"
                                        tooltipPosition="bottom" (click)="editOnClick(product.id)">
                                        <i class="far fa-pencil"></i>
                                    </button>
                                </td>
                                <td *ngIf="i == 0 " class="text-center add-listings-btn3">
                                    <button (click)="addEditOnClick(product.id)">Here</button>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>