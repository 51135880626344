<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->
<!-- import { DialogModule } from 'primeng/dialog'; -->

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">
  <!-- <app-dashboard-navbar></app-dashboard-navbar> -->
  <p-confirmDialog></p-confirmDialog>
  <!-- Breadcrumb Area -->
  <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
    <h1>{{ Content.title }}</h1>
    <ol class="breadcrumb">
      <li class="item"><a routerLink="/">Home</a></li>
      <li class="item">{{ Content.subTitle }}</li>
      <li class="item">{{ Content.title }}</li>
    </ol>
  </div>

  <!-- <div class="add-listings-box">
        <div class="row justify-content-between align-items-center">
            <div class="form-group col-4">
                <label class="p-0">Name <span class="required">*</span></label>
                <input type="text" name="name" class="form-control" placeholder="Name" (change)="getInput($event)" />
            </div>
            <div class="add-listings-btn mb-0 col-4 text-end">
                <button type="submit" (click)="addTableData()">Add</button>
            </div>
        </div>
    </div> -->
  <div class="add-listings-box">
    <div class="Master-view-box">
      <div class="theader d-flex  justify-content-between">
        <div class="d-flex  flex-wrap w-100">
          <span class="p-input-icon-left ml-auto mr-2 col-12 col-md-auto mb-2 md-mb-0">
            <i class="pi pi-search"></i>
            <input pInputText type="text" class="form-control "
                style="text-align: left; padding-left: 20%; height: 45px !important" scriptInjection
                placeholder="Search Keyword" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                [(ngModel)]="filterVal" />

                 
          </span>

          <p-dropdown #dropdown [ngModel]="dropdownValue"   [options]="options"
          placeholder="Select" [showClear]="false"
         [resetFilterOnHide]="true"  (onChange)="applyFilter($event.value)">
         <ng-template let-option pTemplate="item">
             <span>{{option.label}}</span>
         </ng-template>
         </p-dropdown>

          <p-button type="button" class="mx-2" icon="bx bx-reset" (click)="reset(dt1)"
              tooltipPosition="bottom"></p-button>
        </div>
        <div class="add-listings-btn mb-0 ms-2" style="text-align: right" *ngIf="checkPermission(5)">
          <a routerLink="/portal/initial-assessment-form" class="nav-link">
            <button type="submit">Add</button>
          </a>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-lg-12 col-md-12">
        <p-table #dt1 [value]="initialAssData" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
            [tableStyle]="{ 'min-width': '50rem', width: '100%' }"
            [ngClass]="{'p-datatable-sm p-datatable-customers': true, 'table_sticky_4col': headNurse || superAdmin ,'table_sticky_3col': admin }"
            [rowsPerPageOptions]="[10, 25, 50]" responsiveLayout="scroll" scrollable="true" scrollHeight="600px" [loading]="loading" [paginator]="true"
            currentPageReportTemplate="Showing {first} to
					{last} of {totalRecords} entries" [globalFilterFields]="['name', 'contact_no', 'age', 'date_of_birth']"
            editMode="row">
          <ng-template pTemplate="header">
            <tr>
              <th style="min-width: 62px">Created Date</th>
              <th>Patient Name</th>
              <th>Contact No.</th>
              <th>Date Of Birth</th>
              <th>Age</th>
              <th>Type Of Shift</th>
              <th>Contract Period</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Status</th>
              <th width="90" class="text-center">Nurse Care Plan</th>
              <th width="90" class="text-center" *ngIf="!nurseLogin">Daily Task Summary</th>
              <th width="90" class="text-center" *ngIf="nurseLogin">DTS</th>

              <th width="90" class="text-center" *ngIf="checkPermission(13)">Growth Monitoring</th>
              <th width="90" class="text-center">Moments Capture</th>
              <th width="90" class="text-center">Action</th>
              <th width="90" class="text-center" *ngIf="checkPermission(8)">Remove</th>
            </tr>
          </ng-template>
          <!-- table to show data -->
          <ng-template pTemplate="body" let-list let-i="rowIndex">
            <tr>
              <!-- <td>
                {{ i + 1 }}
              </td> -->
              <td>{{ list?.created_at ?? '-'| date : 'dd/MM/yyyy' }}</td>
              <td>{{ list?.name ?? "-" | titlecase }}</td>
              <td>{{ list?.parent_mobile_number ?? "-" }}</td>
              <td>{{ list?.date_of_birth }}</td>
              <td>
                <span *ngIf="list?.date_of_birth">{{ calculateAge(list.date_of_birth) }}</span>
              </td>
              <td>{{ list?.service_hours ?? "-" }}hr</td>
              <ng-container *ngIf="list?.contract_json[0]">
                <td
                    *ngIf="list?.contract_json[0]?.total_days !== undefined && list?.contract_json[0]?.total_days !== ''">
                  {{ list?.contract_json[0]?.total_days }}
                </td>
              </ng-container>
              <td *ngIf="list?.contract_json[0]?.total_days === undefined || list?.contract_json[0]?.total_days === ''">
                -
              </td>
              <td *ngIf="list?.contract_json[0]?.start_date !== undefined && list?.contract_json[0]?.start_date !== ''">
                {{ list?.contract_json[0]?.start_date| date:'dd/MM/yyyy' }}
              </td>
              <td *ngIf="list?.contract_json[0]?.start_date === undefined || list?.contract_json[0]?.start_date === ''">
                -
              </td>
              <td *ngIf="list?.contract_json[0]?.end_date !== undefined && list?.contract_json[0]?.end_date !== ''">
                {{ list?.contract_json[0]?.end_date | date:'dd/MM/yyyy' }}
              </td>
              
              <td *ngIf="list?.contract_json[0]?.end_date === undefined || list?.contract_json[0]?.end_date === ''">
                -
              </td>
              <td [ngClass]="{'active-style': list?.is_active === 1, 'inactive-style': list?.is_active === 0}">
                {{ list?.is_active === 1 ? 'Active' : list?.is_active === 0 ? 'Inactive' : '-' }}
              </td>



              <td class="text-center">

                <!-- <button *ngIf="!headNurse" class="p-button-rounded p-button-icon "
                  (click)="getDailyCarePlan(list.baby_id)">View</button> -->

                <button (click)="getDailyCarePlan(list.baby_id)" *ngIf="!headNurse" class="btn-link:hover btn-link "
                    pTooltip="Edit" tooltipPosition="bottom">View
                </button>

                <button *ngIf="headNurse" (click)="genratenurseLink(list.baby_id, list.id)"
                    class="btn-link:hover btn-link" pTooltip="Edit" tooltipPosition="bottom">Create NCP
                </button>

              </td>

              <td class="text-center">
                <button (click)=" getDailyTaskSummary(list?.name,list?.baby_id)" class="btn-link:hover btn-link"
                    pTooltip="Edit" tooltipPosition="bottom">View
                </button>
              </td>
              <td class="text-center" *ngIf="checkPermission(13)">
                <a [routerLink]="'/portal/growth-monitoring-summary'"><button
                      (click)="growthMonitoringView(list?.baby_id , list?.name , list?.date_of_birth)"
                      class="p-button-rounded p-button-icon "><i class="far fa-chart-line"></i></button></a>
              </td>

              <!-- <td class="text-center" *ngIf="checkPermission(13)&& nurseLogin ">
                <button class="p-button-rounded p-button-icon "
                  (click)="genrateGrothLinkForNurse(list.baby_id,list.id)"><i class="far fa-chart-line"></i></button>
              </td> -->
              <td class="text-center">
                <button (click)=" memoriesMoments(list?.name,list?.baby_id)" class="btn-link:hover btn-link"
                    pTooltip="Edit" tooltipPosition="bottom">View
                </button>
              </td>
              <td class="text-center">
                <!-- <button  pButton pRipple class="p-button-rounded p-button-text p-button-info"
                  pTooltip="View" tooltipPosition="bottom">
                  <i (click)="showMaximizableDialog(list.id)" class="far fa-eye"></i>
                </button> -->
                <!-- edit button on initial assesment listing -->
                <!-- <button  pButton pRipple class="p-button-rounded p-button-icon "
                  pTooltip="Edit" tooltipPosition="bottom" (click)="editOnCLick(list.initial_assessment_id
                )"><i class="far fa-pencil"></i>
                </button> -->
                <button class="btn-link:hover btn-link" pTooltip="Edit" tooltipPosition="bottom"
                    (click)="view(list.baby_id , list?.name , list?.date_of_birth)">View
                </button>
              </td>
              <td class="text-center" *ngIf="checkPermission(8)">
                <button pButton pRipple class="p-button-rounded p-button-text p-button-info view-button" pTooltip="Edit"
                    tooltipPosition="bottom" (click)="deleteplan(list.id)"><i class="far fa-trash"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="5">No Data Found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <!----------------------------------------------------------- view dialog --------------------------------------------------------->
      <!-- ***************************************************************************************************************** -->
      <p-dialog header="Baby Details" [(visible)]="displayMaximizable" [modal]="true" [style]="{ width: '90vw' }"
          [draggable]="false" [resizable]="false">
        <!-- Start -->
        <ng-container *ngFor="let data of viewDetail">
          <div class="row">
            <div class="col-lg-12">
              <div class="my-profile-box">
                <form>
                  <!-- Baby detail -->
                  <p-accordion>
                    <p-accordionTab header="Baby Details">
                      <div class="row">
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Name :</label>
                            <input type="text" readonly class="form-control"
                                value="{{ data?.name  ?? '-' | titlecase  }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Gender :</label>
                            <input type="text" readonly class="form-control" value="{{ data?.gender ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Date of Birth :</label>
                            <input type="text" readonly class="form-control" value="{{ data?.date_of_birth ?? '-' }}" />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Days :</label>
                            <input type="text" readonly class="form-control" value="{{ data?.days ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Months :</label>
                            <input type="text" readonly class="form-control" value="{{ data?.months ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Years :</label>
                            <input type="text" readonly class="form-control" value="{{ data?.years ?? '-' }}" />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Mode of Delivery :</label>
                            <input type="text" readonly class="form-control" value="{{ data?.mode_delivery ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Delivery Complications :</label>
                            <input type="text" readonly class="form-control"
                                value="{{ data?.delivery_complications ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Health Issues :</label>
                            <input type="text" readonly class="form-control" value="{{ data?.health_issues ?? '-' }}" />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Any Special Needs :</label>
                            <input type="text" readonly class="form-control"
                                value="{{ data?.any_speical_needs ?? '-' }}" />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xl-4 col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>Any Special Needs :</label>
                              <input type="text" readonly class="form-control"
                                  value="{{ data?.associated_nurse_id ?? '-' }}" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </p-accordionTab>

                    <!-- Mother detail -->
                    <p-accordionTab header="Mother Details">
                      <h3 class="fw-bold">Mother Detail</h3>
                      <div class="row">
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Name :</label>
                            <input type="text" readonly class="form-control" value="{{ data?.mother_name ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Age :</label>
                            <input type="text" readonly class="form-control" value="{{ data?.mother_age ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Date of Birth :</label>
                            <input type="text" readonly class="form-control" value="{{ data?.mother_dob ?? '-' }}" />
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Working Mother? :</label>
                            <input type="text" readonly class="form-control"
                                value="{{ data?.mother_working ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Working Hour :</label>
                            <input type="text" readonly class="form-control"
                                value="{{ data?.mother_work_hours ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Mother Para :</label>
                            <input type="text" readonly class="form-control" value="{{
                            data?.mother_para == 'null' ? '-' : data?.mother_para
                          }}" />
                          </div>
                        </div>
                      </div>
                    </p-accordionTab>

                    <!-- Father Detail -->
                    <p-accordionTab header="Father Details">
                      <h3 class="fw-bold">Father Detail</h3>
                      <div class="row">
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Name :</label>
                            <input type="text" readonly class="form-control" value="{{ data?.father_name ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Age :</label>
                            <input type="text" readonly class="form-control" value="{{ data?.father_age ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Date of Birth :</label>
                            <input type="text" readonly class="form-control"
                                value="{{ data?.father_dateofbirth ?? '-' }}" />
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Working Hour :</label>
                            <input type="text" readonly class="form-control"
                                value="{{ data?.father_work_hours ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Contact Number:</label>
                            <input type="text" readonly class="form-control"
                                value="{{ data?.parent_mobile_number ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Email Address:</label>
                            <input type="text" readonly class="form-control" value="{{ data?.parent_email ?? '-' }}" />
                          </div>
                        </div>
                      </div>
                    </p-accordionTab>

                    <!-- Sibling Detail -->
                    <p-accordionTab header="Sibling Details">
                      <h3 class="fw-bold">Sibling Detail</h3>
                      <div class="row" *ngFor="let sib of sibling">
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Name :</label>
                            <input type="text" readonly class="form-control" value="{{ sib?.name ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Age :</label>
                            <input type="text" readonly class="form-control" value="{{ sib?.age ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Date of Birth :</label>
                            <input type="text" readonly class="form-control" value="{{ (sib?.dob ? (sib?.dob | date : 'dd/MM/yyyy') : '-') }}
                          " />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Special Needs :</label>
                            <input type="text" readonly class="form-control" value="{{ sib?.needs ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Specify :</label>
                            <input type="text" readonly class="form-control"
                                value="{{ sib?.special_needs_identify ?? '-' }}" />
                          </div>
                        </div>
                      </div>
                    </p-accordionTab>

                    <!-- Social Environment Details -->
                    <p-accordionTab header="Social Environment Details">
                      <h3 class="fw-bold">Social Environment Details</h3>
                      <div class="row">
                        <div class="col-xl-4 col-lg-12col-md-12">
                          <div class="form-group">
                            <label>Relatives in the House :</label>
                            <input type="text" readonly class="form-control"
                                value="{{ data?.relatives_house ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12" *ngFor="let relative of relatives">
                          <div class="form-group">
                            <label>Relative's Name :</label>
                            <input type="text" readonly class="form-control" value="{{ relative?.name?? '-' }}" />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xl-4 col-lg-12col-md-12">
                          <div class="form-group">
                            <label>Number of Housemaids :</label>
                            <input type="text" readonly class="form-control" value="{{ data?.no_of_maids ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Family Religion :</label>
                            <input type="text" readonly class="form-control"
                                value="{{ data?.family_religion ?? '-' }}" />
                          </div>
                        </div>

                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Type of House :</label>

                            <input type="text" readonly class="form-control"
                                value="{{ data?.type_of_house == 'null' ? '-' : data?.type_of_house }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>House no :</label>
                            <input type="text" readonly class="form-control"
                                value="{{ data?.house_no == 'null' ? '-' : data?.house_no }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>House Location :</label>
                            <a style="font-weight: 600; color: blue;"
                                href="{{ data?.house_location == 'null'? '-' : data?.house_location }}"
                                target="_blank">{{
                              data?.house_location == 'null'? '-' : data?.house_location }}</a>
                          </div>
                        </div>

                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Report Name :</label>
                            <input type="text" readonly class="form-control" value="{{ data?.report_name ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Contact Number :</label>
                            <input type="text" readonly class="form-control" value="{{ data?.contact_no ?? '-' }}" />
                          </div>
                        </div>

                        <!-- <div class="col-xl-4 col-lg-12 col-md-12">
                                            <div class="form-group">
                                                <label>Upload photo :</label>
                                                <input type="text" readonly class="form-control"
                                                    value="{{data?.upload_photo ?? '-'}}" />
                                            </div>
                                        </div> -->

                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Uploaded photo:</label>
                            <img *ngIf="data?.upload_photo" [src]="getImageUrl(data.upload_photo)" alt="Uploaded Photo"
                                class="img-fluid" style="width: 150px" accept="image/png, image/gif, image/jpeg" />
                            <span *ngIf="!data?.upload_photo">No photo uploaded</span>
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Need Mental Support for Mother? :</label>
                            <input type="text" readonly class="form-control"
                                value="{{ data?.mother_mental_supp ?? '-' }}" />
                          </div>
                        </div>

                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Specify :</label>
                            <input type="text" readonly class="form-control"
                                value="{{ data?.mental_supp_specify ?? '-' }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <!-- <div class="form-group">
                                                <label>Appoinment and Vaccines responsibility :</label>
                                                <input type="text" readonly class="form-control"
                                                    value="{{data?.appointment_vaccine_responsibility ?? '-'}}" />
                                            </div> -->
                          <ng-container>
                            <div class="form-group">
                              <label for="">Appoinment and Vaccines responsibility :</label>
                              <input type="text" readonly class="form-control" *ngIf="
                              data?.appointment_vaccine_responsibility !==
                                null &&
                                data?.appointment_vaccine_responsibility !==
                                  '' &&
                                data?.appointment_vaccine_responsibility !==
                                  undefined;
                              else elseBlock
                            " value="{{data?.appointment_vaccine_responsibility}}" />
                              <ng-template #elseBlock>
                                <input type="text" readonly class="form-control" value="-" />
                              </ng-template>
                            </div>
                          </ng-container>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <!-- <div class="form-group">
                                                <label>Medication and Administration :</label>
                                                <input type="text" readonly class="form-control"
                                                    value="{{data?.medication_admin_responsibility ?? '-'}}" />
                                            </div> -->
                          <ng-container>
                            <div class="form-group">
                              <label for="">Medication and Administration :</label>
                              <input type="text" readonly class="form-control" *ngIf="
                              data?.medication_admin_responsibility !== null &&
                                data?.medication_admin_responsibility !== '' &&
                                data?.medication_admin_responsibility !==
                                  undefined;
                              else elseBlock
                            " value="{{
                              data?.medication_admin_responsibility
                            }}" />
                              <ng-template #elseBlock>
                                <input type="text" readonly class="form-control" value="-" />
                              </ng-template>
                            </div>
                          </ng-container>
                        </div>

                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <ng-container>
                            <div class="form-group">
                              <label for="">Baby's supplies responsibility :</label>
                              <input type="text" readonly class="form-control" *ngIf="
                              data?.baby_supplies_responsibility !== null &&
                              data?.baby_supplies_responsibility !== '' &&
                              data?.baby_supplies_responsibility !==
                                  undefined;
                              else elseBlock
                            " value="{{
                                data?.baby_supplies_responsibility
                            }}" />
                              <ng-template #elseBlock>
                                <input type="text" readonly class="form-control" value="-" />
                              </ng-template>
                            </div>
                          </ng-container>
                          <!-- <div class="form-group">
                                                <label>Baby's supplies responsibility :</label>
                                                <input type="text" readonly class="form-control"
                                                    value="{{data?.baby_supplies_responsibility ?data?.baby_supplies_responsibility: '-'}}" />
                                            </div> -->


                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Requires changes in the environment? :</label>
                            <input type="text" readonly class="form-control"
                                value="{{ data?.environment_changes == 'null' ? '-' : data?.environment_changes }}" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Identify requirements :</label>
                            <input type="text" readonly class="form-control" value="{{
                            data?.environment_identify_require ?data?.environment_identify_require : '-'
                          }}" />
                          </div>
                        </div>
                      </div>
                    </p-accordionTab>
                    <p-accordionTab header="Contract Details">
                      <h3 class="fw-bold">Contract Details</h3>
                      <div class="row">
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Start Date:</label>

                            <input type="text" readonly class="form-control"
                                [value]="(contract[0]?.start_date | date:'dd-MM-yyyy') || '-'" />


                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xl-4 col-lg-12col-md-12">
                          <div class="form-group">
                            <label>End Date:</label>
                            <input type="text" readonly class="form-control"
                                [value]="(contract[0]?.end_date | date:'dd-MM-yyyy')  || '-'" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label> Total no Of Days:</label>
                            <input type="text" readonly class="form-control" [value]="contract[0]?.total_days || '-'" />
                          </div>
                        </div>

                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Amount:</label>

                            <input type="text" readonly class="form-control" [value]="contract[0]?.amount || '-'" />
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-12 col-md-12">
                          <div class="form-group">
                            <label>Type Of Shift:</label>
                            <input type="text" readonly class="form-control"
                                [value]="contract[0]?.type_of_shift || '-'" />
                          </div>
                        </div>
                      </div>
                    </p-accordionTab>
                  </p-accordion>
                </form>
              </div>
            </div>
          </div>

        </ng-container>
        <ng-template pTemplate="footer">

          <p-button (click)="displayMaximizable = false" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template>
      </p-dialog>
    </div>
  </div>
  <app-copyrights></app-copyrights>
</div>