<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->
<p-toast></p-toast>
<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item">{{Content.subTitle}}</li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <form [formGroup]="EnvironmentAndChiledSaftyAssessment" (ngSubmit)="submitEnviorment(submitForm)"
        #submitForm="ngForm">
        <!-- BABY DETAILS SECTION -->
        <div class="add-listings-box">
            <h3>Baby Details</h3>
            <div class="row">
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Name<span class="required">*</span></label>
                        <!-- <input type="text" id="baby_details_id" class="form-control" formControlName="baby_details_id" placeholder="Name" /> -->
                        <p-dropdown [options]="babyDetails" (onChange)="getBabyDetails($event)" optionValue="id"
                            optionLabel="name" [filter]="true" filterBy="name" formControlName="baby_details_id"
                            placeholder="Select Baby's Name" [resetFilterOnHide]="true" [class.is-invalid]="(submitForm.submitted &&
                            EnvironmentAndChiledSaftyAssessment.get('baby_details_id')?.invalid)
                            ||
                            EnvironmentAndChiledSaftyAssessment.get('baby_details_id')?.invalid
                            &&
                            EnvironmentAndChiledSaftyAssessment.get('baby_details_id').touched">
                        </p-dropdown>
                        <div class="invalid-feedback">Name is required</div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Gender</label>
                        <div class="card flex justify-content-center">
                            <!-- <p-dropdown [options]="genderArray" optionLabel="name" optionValue="value" [filter]="true"
                                filterBy="name" [(ngModel)]="babyGender" readonly [ngModelOptions]="{standalone: true}"
                                placeholder="Select Baby's Gender" [resetFilterOnHide]="true">
                            </p-dropdown> -->
                            <input type="text" class="form-control" [(ngModel)]="babyGender" [ngModelOptions]="{standalone: true}" placeholder="Select Baby's Gender"
                            required [readonly]="true">
                            <div class="invalid-feedback">
                                Gender is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Date of birth</label>
                        <div class="card flex justify-content-center">
                            <!-- <p-calendar [(ngModel)]="selectedDate" [ngModelOptions]="{standalone: true}"
                                [readonlyInput]="true" [showIcon]="true" (onSelect)="calculateAgeAndYears()"
                                placeholder="DD/MM/YYYY"></p-calendar> -->
                                <input type="text" name="name" class="form-control" placeholder="DD/MM/YYYY" [value]="selectedDate" readonly />
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Days</label>
                        <input type="text" name="name" class="form-control" placeholder="Days" [value]="days"
                            readonly />
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Months</label>
                        <input type="text" name="name" class="form-control" placeholder="Months" [value]="month"
                            readonly />
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-126">
                    <div class="form-group">
                        <label class="px-0">Years</label>
                        <input type="text" name="name" class="form-control" placeholder="Years" [value]="years"
                            readonly />
                    </div>
                </div>
            </div>
        </div>


        <!--CHILD'S ROOM-->

        <div class="add-listings-box">
            <h3>Child's Room </h3>
            <div class="row mt-3">

                <!-- Requires Changes -->

                <!-- no -->
                <div class="row m-0 p-0" formGroupName="child_room_change">
                    <div class="subHeading mb-3">Requires Changes?</div>
                    <div class="form-group col-4 px-0">
                        <div class="radio-inline">
                            <p class="d-flex">
                                <input type="radio" id="require-no" (change)="handleInput($event, 'requirechanges')" value="0"
                                    formControlName="answer">
                                <label for="require-no" class="mb-0 mx-2">No</label>
                            </p>
                            <p class="d-flex">
                                <input type="radio" id="require-yes" (change)="handleInput($event, 'requirechanges')" value="1"
                                    formControlName="answer">
                                <label for="require-yes" class="mb-0 mx-2">Yes</label>
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="px-0">Identify Requirements</label>
                            <div class="card flex justify-content-center">
                                <div class="col-12 p-0">
                                    <input type="text" name="name" class="form-control"
                                        placeholder="Identify Requirements" formControlName="identify_requirements"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Baby Stroller Available  -->

                <!-- <div class="row"> -->
                    <!-- no -->
                    <div class="row">
                        <div class="form-group col-4 px-0">
                            <div class="subHeading mb-3">Baby Stroller Available?</div>
                            <div class="radio-inline">
                                <p class="d-flex">
                                    <input type="radio" value="0" formControlName="stroller_available" id="stroller-no">
                                    <label for="stroller-no" class="mb-0 mx-2">No</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" value="1" formControlName="stroller_available" id="stroller-yes">
                                    <label for="stroller-yes" class="mb-0 mx-2">Yes</label>
                                </p>
                            </div>
                        </div>
                    </div>
                <!-- </div> -->

                <!-- washroom fall risk  -->

                <div class="subHeading mb-3">Washroom(Fall Risk)</div>
                <div class="row m-0 p-0" formGroupName="washroom_fall_risk">
                    <div class="form-group col-4 px-0">
                        <div class="radio-inline">
                            <p class="d-flex">
                                <input type="radio" id="washroom-no" value="0" formControlName="answer"
                                (change)="handleInput($event, 'washroom')">
                                <label for="washroom-no" class="mb-0 mx-2">No</label>
                            </p>
                            <p class="d-flex">
                                <input type="radio" id="washroom-yes" value="1" formControlName="answer"
                                (change)="handleInput($event, 'washroom')">
                                <label for="washroom-yes" class="mb-0 mx-2">Yes</label>
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group">
                            <label class="px-0">Identify Risks</label>
                            <div class="card flex justify-content-center">
                                <div class="col-12 p-0">
                                    <input type="text" formControlName="washroom_identify_risks" class="form-control"
                                        placeholder="Identify Risks"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-4 px-0">
                    <div class="subHeading mb-3">Outdoor Playground</div>
                    <div class="radio-inline">
                        <p class="d-flex">
                            <input type="radio" id="outdoor-no" formControlName="outdoor_playground" value="0">
                            <label for="outdoor-no" class="mb-0 mx-2">No</label>
                        </p>
                        <p class="d-flex">
                            <input type="radio" id="outdoor-yes" formControlName="outdoor_playground" value="1">
                            <label for="outdoor-yes" class="mb-0 mx-2">Yes</label>
                        </p>
                    </div>
                </div>
            </div>

            <!-- Any Loose Part Or Sharp Edges-->


            <!-- yes -->
            <div class="subHeading mb-3">Any Loose Part Or Sharp Edges?</div>
            <div class="row" formGroupName="any_loose_parts_sharp_edges">
                <div class="form-group col-4 px-0">
                    <div class="radio-inline">
                        <p class="d-flex">
                            <input type="radio" value="0" id="loosepart-no" formControlName="answer"
                            (change)="handleInput($event, 'loose_part')">
                            <label for="loosepart-no" class="mb-0 mx-2">No</label>
                        </p>
                        <p class="d-flex">
                            <input type="radio" value="1" id="loosepart-yes" formControlName="answer"
                            (change)="handleInput($event, 'loose_part')">
                            <label for="loosepart-yes" class="mb-0 mx-2">Yes</label>
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Identify Parts</label>
                        <div class="card flex justify-content-center">
                            <div class="col-12 p-0">
                                <input type="text" formControlName="identify_parts" class="form-control"
                                    placeholder="Identify Parts" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- points  -->

            <!-- <div class="add-listings-box"> -->


            <div class="row">
                <!-- play -->
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="p-0 subHeading">Play</label>
                        <p>
                            <input type="radio" id="Play-no" formControlName="play" value="0" />
                            <label for="Play-no">No</label>
                        </p>
                        <p class="d-flex">
                            <input type="radio" id="Play-yes" formControlName="play" value="1">
                            <label for="Play-yes">Yes</label>
                        </p>
                    </div>
                </div>

                <!-- Tricylce -->
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="p-0 subHeading">Tricycle</label>
                        <p>
                            <input type="radio" id="tricycle-no" formControlName="tricycle" value="0" />
                            <label for="tricycle-no">No</label>
                        </p>
                        <p class="d-flex">
                            <input type="radio" id="tricycle-yes" formControlName="tricycle" value="1">
                            <label for="tricycle-yes">Yes</label>
                        </p>
                    </div>
                </div>

                <!-- Scooter -->
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="p-0 subHeading">Scooter</label>
                        <p>
                            <input type="radio" id="scooter-no" formControlName="scooter" value="0" />
                            <label for="scooter-no">No</label>
                        </p>
                        <p class="d-flex">
                            <input type="radio" id="scooter-yes" formControlName="scooter" value="1">
                            <label for="scooter-yes">Yes</label>
                        </p>
                    </div>
                </div>

                <!-- Slides -->
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="p-0 subHeading">Slides</label>
                        <p>
                            <input type="radio" id="slides-no" formControlName="slides" value="0" />
                            <label for="slides-no">No</label>
                        </p>
                        <p class="d-flex">
                            <input type="radio" id="slides-yes" formControlName="slides" value="1">
                            <label for="slides-yes">Yes</label>
                        </p>
                    </div>
                </div>

                <!-- Swimming pool -->
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="p-0 subHeading">Swimming Pool</label>
                        <p>
                            <input type="radio" id="swimming-no" formControlName="swimming_pool" value="0" />
                            <label for="swimming-no">No</label>
                        </p>
                        <p class="d-flex">
                            <input type="radio" id="swimming-yes" formControlName="swimming_pool" value="1">
                            <label for="swimming-yes">Yes</label>
                        </p>
                    </div>
                </div>
            </div>

            <div class="row" formGroupName="toyes">
                <!-- Toys -->
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="p-0 subHeading">Toys</label>
                        <p>
                            <input type="radio" id="toys-no" formControlName="toyes" value="0" (change)="handleInput($event, 'toys')" />
                            <label for="toys-no">No</label>
                        </p>
                        <p class="d-flex">
                            <input type="radio" id="toys-yes" formControlName="toyes" value="1" (change)="handleInput($event, 'toys')">
                            <label for="toys-yes">Yes</label>
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Kind:</label>
                        <div class="card flex justify-content-center">
                            <div class="col-12 p-0">
                                <input type="text" formControlName="kind" class="form-control" placeholder="Kind"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" formGroupName="pets">
                <!-- Pets -->
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="p-0 subHeading">Pets</label>
                        <p>
                            <input type="radio" id="pets-no" formControlName="pets" value="0" (change)="handleInput($event, 'pets')" />
                            <label for="pets-no">No</label>
                        </p>
                        <p class="d-flex">
                            <input type="radio" id="pets-yes" formControlName="pets" value="1" (change)="handleInput($event, 'pets')" />
                            <label for="pets-yes">Yes</label>
                        </p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Kind:</label>
                        <div class="card flex justify-content-center">
                            <div class="col-12 p-0">
                                <input type="text" formControlName="kind" class="form-control" placeholder="Kind"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <!-- Child seat available -->
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group mb-0">
                        <label class="p-0 subHeading">Child seat available in the car?</label>
                        <p>
                            <input type="radio" id="carseat-no" formControlName="child_seat_available_car" value="0" />
                            <label for="carseat-no">No</label>
                        </p>
                        <p class="d-flex">
                            <input type="radio" id="carseat-yes" formControlName="child_seat_available_car" value="1">
                            <label for="carseat-yes">Yes</label>
                        </p>
                    </div>
                </div>
            </div>


            <!-- OTHER CONCERN  -->

            <div class="form-group pb-4">
                <div class="row">
                    <div class="col-12">
                        <label class="subHeading py-3 px-0">Other Concerns:</label>
                        <textarea formControlName="other_conserns" class="form-control" id="kt_autosize_1"
                            rows="7"></textarea>
                    </div>
                </div>
            </div>

        </div>
        <div class="add-listings-btn" style="text-align: right;">
            <button type="submit">Submit</button>
        </div>
    </form>
    <app-copyrights></app-copyrights>
</div>
