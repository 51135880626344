import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MaikaListService } from 'src/app/services/maika-list.service';
import { CommonApiService } from 'src/app/services/common-api.service';
@Component({
  selector: 'app-health-monitoring-daily-summary',
  templateUrl: './health-monitoring-daily-summary.component.html',
  styleUrls: ['./health-monitoring-daily-summary.component.scss']
})
export class HealthMonitoringDailySummaryComponent implements OnInit {
  newData: any = [] ;
  error: boolean = false;
  date: string;
  immunization: any = [];
  getHealth: any;
  summaryData: any;
  FirstEntry: any;
  temperature: any;
  vital: any;
  summarytemperature: any;
  vitalSign: any;

  constructor(private nanny_task_service: MaikaListService, private spinner: NgxSpinnerService, private common_service: CommonApiService
  ) { }


  calendar1;
  calendar2;
  calendar3;
  calendar4;
  calendar5;
  calendar6;
   ngOnInit(): void {
    this. getAllsummaryData()
    this. getHealthMonitoring() 
  }


  breadcrumb = [
    {
      title: localStorage.getItem('baby_name'),
      subTitle: 'Dashboard',
    },
  ];


  getAllsummaryData() {
    this.spinner.show()
    const id = localStorage.getItem('babyId');
    this.nanny_task_service.getDailyCarePlanById(id).then((res: any) => {
      this.spinner.hide()
      this.summaryData = res.data;
      this.FirstEntry = this.summaryData[0]?.nanny_task_details_json;
      // this.GetBabyAge();
    }).catch((error: any) => {
      this.spinner.hide()
      this.error = true
    });
  }
  getHealthMonitoring() {
    this.spinner.show()
   const id = localStorage.getItem('FormId');
   this.common_service.getByIdHealthMonitoring(id).then(
     (res: any) => {
       this.spinner.hide()
       this.getHealth = res.data;
       this.summarytemperature=this.getHealth?.temperature_json;
       this.vitalSign=this.getHealth?.vital_signs_json;
     },
     (error: any) => {
       this.spinner.hide()

     }
   );
 }
  getDataByDate(event: any) {
    this.spinner.show()
    this.immunization=[]
    this.newData = [];
    this.error = false
    this.date = `${event.getDate()}-${event.getMonth() + 1}-${event.getFullYear()}`
    let month = event.getMonth() + 1;
    if (month < 10) {
        month = '0' + month;
    }
    
    const payload = {
        id: localStorage.getItem('FormId'),
        date: `${event.getFullYear()}-${month}-${event.getDate()}`
    };
    
    this.spinner.hide()
    
    this.nanny_task_service.getHealthDataByDate(payload).then((res: any) => {
      this.spinner.hide()

      this.newData = res.data;
      this.immunization = this.newData?.immunization_json;
      ;
      this.temperature=this.newData?.temperature_json;
      this.vital=this.newData?.vital_signs_json;   
    }).catch((error: any) => {
      this.spinner.hide()
      this.error = true
    });
  }
}
