import { Component, OnInit } from '@angular/core';
import { MaikaListService } from 'src/app/services/maika-list.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MasterService } from 'src/app/services/master.service';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-three-year-to-above-list',
  templateUrl: './three-year-to-above-list.component.html',
  styleUrls: ['./three-year-to-above-list.component.scss']
})
export class ThreeYearToAboveListComponent implements OnInit {

  breadcrumb = [
    {
      title: 'Three Years to Above',
      subTitle: 'Dashboard'
    }
  ]
  bathing_tub_frequency :any;
  changing_colthes_frequency :any;
  bathing_shower_frequency :any;
  feeding_frequency :any;
  temperature_monitoring_frequency :any;
  temp: any;
  vaccination: any =[];
  vitamins: any = [];
  oneYearToThreeYear: any = []
  regular:any=[];
  sleep:any=[];
  skin:any=[];
  lunch: any = [];
  dinner:any=[];
  tub: any = [];
  bathing: any = [];
  playing:any=[];
  escort:any=[];
  activity:any=[];
  training:any=[];
  school:any=[];
  loading: any;
  diaper:any=[];
  tooltipText: string = 'One Year To Three Year List';
  datalength: any;
  viewDetail: any = {};
  filterVal: string;
  dataonzeroposition:any;
  displayMaximizable: boolean = false;
  filteredPDFData: any[];
  pdfData: any[];
  exportColumns: any = [];
  cols: any;
  edit:any;
  permissionData: any = []; 
  permissions: any = [];
  constructor(private two_year_and_above_service: MaikaListService,
              private spinner:NgxSpinnerService,
              private router:Router,
              private masterService: MasterService,
              private messageService: MessageService,
              ){ }

  // fn to get the data from backend
  getAllData() {
   this.spinner.show()
    this.two_year_and_above_service.getThreeYearToAboveList().subscribe((res: any) => {
      this.spinner.hide()
     
      this.oneYearToThreeYear = res.data;
      this.datalength = res?.data?.length;
      console.log("two years and above data", res.data);

    },(error: any) => {
      this.spinner.hide()
      // Handle any errors that occurred during the API call
    
      
    })
  }

  ngOnInit(): void {
    this.getAllData();
    localStorage.removeItem('editedOrderId');
    localStorage.removeItem('create');
    this.cols = [
      { field: 'sr_no', header: 'Sr No' },
      { field: 'name', header: 'Name' },
      { field: 'date_of_birth', header: 'Date of Birth' },
      { field: 'days', header: 'Age' },
      { field: 'months', header: 'Months' },
    ];
    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    console.log("new api call");
    this.getAllUserPermission();
  }
  getAllUserPermission() {
    let id = localStorage.getItem('user_id');
    this.masterService.getUserPremissionById(id).then((res: any) => {
     

      this.permissionData = res.data;

      for(let i =0; i < this.permissionData.length; i++){
        
        this.permissions.push(this.permissionData[i].permission_id);
      }
      
    })
  }

  checkPermission(permission:number){
    
    if(this.permissions.includes(permission)){
      return true;
    }else{
      return false;
    }
    // return this.permissions.includes(permission);
  }
  formatDateTime(date: string | undefined): string {
    if (date) {
      const formattedDate = new Date(date);
      // const year = formattedDate.getFullYear();
      // const month = ('0' + (formattedDate.getMonth() + 1)).slice(-2);
      // const day = ('0' + formattedDate.getDate()).slice(-2);
      const hours = ('0' + formattedDate.getHours()).slice(-2);
      const minutes = ('0' + formattedDate.getMinutes()).slice(-2);
      const seconds = ('0' + formattedDate.getSeconds()).slice(-2);

      return `${hours}:${minutes}:${seconds}`;
      // return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }
    return '-';
  }
  // open view dialog
  details: any = [];
  showMaximizableDialog(id: any) {

    console.log("click id", id);
    this.viewDetail = {};
    this.oneYearToThreeYear.map((res: any) => {
      console.log("showwww", id, "......", res.id)
      if (res.id == id) {
        console.log("view detail:", res);
        // this.viewDetail = res;
        this.viewDetail = res;
        console.log("single record:", this.viewDetail);
        this.dataonzeroposition=this.viewDetail.nanny_task_details_json;
        console.log("Data on zero position",this.dataonzeroposition);
        // this.vaccination = this.dataonzeroposition?.vaccinations_json;
        // console.log(this.vaccination);
        this.vitamins = this.dataonzeroposition?.vitamins_medications_json;
        
        //  this.changing_colthes_frequency=this.dataonzeroposition?.temperature_monitoring_frequency;
        //  console.log("dfhd",this.changing_colthes_frequency);
         this.bathing_shower_frequency=this.dataonzeroposition?.bathing_tub_frequency;
         console.log("dfhd",this.bathing_shower_frequency);
         this.bathing_tub_frequency=this.dataonzeroposition?.bathing_shower_frequency;
         console.log("dfhd",this.bathing_tub_frequency);
         this.temperature_monitoring_frequency=this.dataonzeroposition?.changing_colthes_frequency;
         console.log("dfhd",this.temperature_monitoring_frequency);
         //get data from formarray
         this.lunch=this.dataonzeroposition?.lunch_food;
         this.dinner=this.dataonzeroposition?.dinner_food;
         this.regular=this.dataonzeroposition?.regular_food;
         this.playing=this.dataonzeroposition?.outside_child;
         this.escort=this.dataonzeroposition?.escort_child;
         this.activity=this.dataonzeroposition?.activities_name;
         this.sleep=this.dataonzeroposition?.sleeping_training;
         this.training=this.dataonzeroposition?.child_training;
         console.log("as",this.training);
         this.diaper=this.dataonzeroposition?.diaper_change_times;
        this.skin=this.dataonzeroposition?.skin_care;
        this.school=this.dataonzeroposition?.school_name;
        this.bathing = this.dataonzeroposition?.bathing_time;
        this.tub = this.dataonzeroposition?.tub_bathing;
        this.edit=id;
        // this.vaccination = JSON.parse(JSON.parse(res?.vaccinations_json));
        // console.log("vitamin", this.vaccination)
        // this.vitamins = JSON.parse(JSON.parse(res?.vitamins_medications_json));
        // console.log("vitamin",this.vitamins)
        // this.temp = JSON.parse(res?.temperature_monitoring_frequency)?.frequency;
        console.log("vaccination", this.vaccination, "vitamins", this.vitamins, "temperature monitoring frequency", this.temp);
        // this.relatives.push(JSON.parse(res?.relatives_name_json));
        // this.sibling.push(JSON.parse(res?.sibling_details_json));
        console.log("single record:", this.viewDetail.escort_child_outside, this.viewDetail.escort_child_outside_special_Instructions);
        // console.log( "single re", this.viewDetail.changing_colthes_frequency)
        // this.temperature_monitoring_frequency= JSON.parse(res?.temperature_monitoring_frequency)?.frequency;
        // this.changing_colthes_frequency= JSON.parse(this.dataonzeroposition?.changing_colthes_frequency)?.frequency;
        // console.log("fre",this.changing_colthes_frequency)
        // this.feeding_frequency = JSON.parse(this.dataonzeroposition?.feeding_frequency)?.frequency;
        // this.bathing_shower_frequency  = JSON.parse(this.dataonzeroposition?.bathing_shower_frequency)?.frequency;
        // this.bathing_tub_frequency  = JSON.parse(this.dataonzeroposition?.bathing_tub_frequency)?.frequency;
        // console.log(this.bathing_tub_frequency)
      }
      else {
        
      }
      console.log(id);
    });
    this.displayMaximizable = true;
  }
  editOnCLick(id:any){
    localStorage.setItem('editedOrderId', id);
    console.log("id",id);
    this.router.navigateByUrl('/portal/three-years-above');
  }
  reset(dt) {
    dt.reset();
    this.filterVal = '';
  }
  onPDFExport() {
    this.filteredPDFData = [];
    this.pdfData = [];
    this.filteredPDFData = this.oneYearToThreeYear;
  
    for (let i = 0; i < this.filteredPDFData.length; i++) {
      this.pdfData.push({
        sr_no: i + 1,
        name: this.filteredPDFData[i]?.name ?? '-',
        date_of_birth: this.filteredPDFData[i]?.date_of_birth ?? '-',
        days: this.filteredPDFData[i]?.days ?? '-',
        months: this.filteredPDFData[i]?.months ?? '-',
      });
    }
  
    this.two_year_and_above_service.exportPdf(
      this.pdfData,
      this.exportColumns,
      'zeo-to-three-month.pdf'
    );
  }
}
