import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  apiBaseUrl: string = environment.api_base_url;

  constructor(private http: HttpClient) { }

  /* -------------------------------------------------------------------------- */
  /*               // get roles dropdown values from master                     */
  /* -------------------------------------------------------------------------- */
  getRoles() {
    return this.http.get(this.apiBaseUrl + 'roles').toPromise();
  }


  /* -------------------------------------------------------------------------- */
  /*               // get urine dropdown values from master                     */
  /* -------------------------------------------------------------------------- */
  getUrineValues() {
    return this.http.get(this.apiBaseUrl + 'urine-description').toPromise();
  }
  /* -------------------------------------------------------------------------- */
  /*               // post user master data                    */
  /* -------------------------------------------------------------------------- */
  postUserFormData(data: any) {
    return this.http.post(this.apiBaseUrl + 'roles', data).toPromise();
  }
  /* -------------------------------------------------------------------------- */
  /*               // post urine master data                    */
  /* -------------------------------------------------------------------------- */
  postUrineFormData(data: any) {
    return this.http.post(this.apiBaseUrl + 'create-urine', data).toPromise();
  }
  /* -------------------------------------------------------------------------- */
  /*               // Update user master data                    */
  /* -------------------------------------------------------------------------- */
  editUserFormData(data?: any, id?: any) {
    return this.http.put(this.apiBaseUrl + `roles/${id}`, data);
  }
  /* -------------------------------------------------------------------------- */
  /*               // Update urine master data                    */
  /* -------------------------------------------------------------------------- */
  editUrineFormData(data?: any, id?: any) {
    return this.http.put(this.apiBaseUrl + `update-urine-description/${id}`, data);
  }

  /* -------------------------------------------------------------------------- */
  /*               // Delete urine master data                    */
  /* -------------------------------------------------------------------------- */
  deleteUrineFormData(id?: any, deleted?: any) {
    return this.http.put(this.apiBaseUrl + `delete-urine-description/${id}`, deleted);
  }

  /* -------------------------------------------------------------------------- */
  /*               // get stool dropdown values from master                    */
  /* -------------------------------------------------------------------------- */
  getStoolValues() {
    return this.http.get(this.apiBaseUrl + 'get-stool-description').toPromise();
  }
  /* -------------------------------------------------------------------------- */
  /*               // post stool master data                    */
  /* -------------------------------------------------------------------------- */
  postStoolFormData(data: any) {
    return this.http.post(this.apiBaseUrl + 'create-stool-description', data).toPromise();
  }
 /* -------------------------------------------------------------------------- */
  /*               // post moment data                    */
  /* -------------------------------------------------------------------------- */
  postMomentData(data: any){
    return this.http.post(this.apiBaseUrl + 'hashtag-moments', data).toPromise();
  }
  /* -------------------------------------------------------------------------- */
  /*               // edit moment data                    */
  /* -------------------------------------------------------------------------- */
  editMomentData(data?: any, id?: any){
    return this.http.put(this.apiBaseUrl + `hashtag-moments/${id}`, data);
  }
  /* -------------------------------------------------------------------------- */
  /*               // get moment data                    */
  /* -------------------------------------------------------------------------- */
  getMomentData(){
    return this.http.get(this.apiBaseUrl + 'hashtag-moments').toPromise();
  }
    /* -------------------------------------------------------------------------- */
  /*               // delet moment data                    */
  /* -------------------------------------------------------------------------- */
  deletMomentData(id?: any, deleted?: any){
    return this.http.delete(this.apiBaseUrl + `hashtag-moments/${id}`, deleted);
  }
  /* -------------------------------------------------------------------------- */
  /*               // Update stool master data                    */
  /* -------------------------------------------------------------------------- */
  editStoolFormData(data?: any, id?: any) {
    return this.http.put(this.apiBaseUrl + `update-stool-description/${id}`, data);
  }

  /* -------------------------------------------------------------------------- */
  /*               // Delete stool master data                    */
  /* -------------------------------------------------------------------------- */
  deleteStoolFormData(id?: any, deleted?: any) {
    return this.http.put(this.apiBaseUrl + `delete-stool-description/${id}`, deleted);
  }
  /* -------------------------------------------------------------------------- */
  /*               // save edit forms data                     */
  /* -------------------------------------------------------------------------- */
  editSaveForm(data?: any, id?: any) {
    return this.http.put(this.apiBaseUrl + `users/` + `${id}`, data).toPromise();
  }
   /* -------------------------------------------------------------------------- */
  /*               // user permission                     */
  /* -------------------------------------------------------------------------- */
  getUserPermissions(){
    return this.http.get(this.apiBaseUrl + 'permissions').toPromise();
  }
   /* -------------------------------------------------------------------------- */
  /*               // user permission get id                    */
  /* -------------------------------------------------------------------------- */
  getUserPremissionById(id: any) {
    return this.http.get(this.apiBaseUrl + 'user-has-permissions/user/' + `${id}`).toPromise();
  }
  /* -------------------------------------------------------------------------- */
  /*               // post user permission data                */
  /* -------------------------------------------------------------------------- */
  postUserPermissionFormData(data: any) {
    return this.http.post(this.apiBaseUrl + 'user-has-permissions', data).toPromise();
  }
   /* -------------------------------------------------------------------------- */
  /*               // get all permission data                  */
  /* -------------------------------------------------------------------------- */
  getPermissionAccordingToRoles(id:any) {
    return this.http.get(this.apiBaseUrl + 'role-has-permissions/'+`${id}`).toPromise();
  }
  /* -------------------------------------------------------------------------- */
  /*               // post all permission data                  */
  /* -------------------------------------------------------------------------- */
  postRolePermissionFormData(data: any) {
    return this.http.post(this.apiBaseUrl + 'role-has-permissions', data).toPromise();
  }
}
