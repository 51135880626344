import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CommonApiService } from 'src/app/services/common-api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { MasterService } from 'src/app/services/master.service';
import { MaikaListService } from 'src/app/services/maika-list.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-health-monitoring-listing',
  templateUrl: './health-monitoring-listing.component.html',
  styleUrls: ['./health-monitoring-listing.component.scss']
})
export class HealthMonitoringListingComponent implements OnInit {
  getHealth: any;
  showHealthData: any;
  setHealth: any = [];
  breadcrumb = [
    {
      title: 'Health Monitoring Listing',
      subTitle: 'Dashboard',
    },
  ];
  filterVal: string;
  permissionData: any = [];
  permissions: any = [];
  datalength: any;
  constructor(private commanServices: CommonApiService,
    private messageService: MessageService,
    private router: Router,
    private masterService: MasterService,
    private daily_care_plan: MaikaListService,
    private spinner: NgxSpinnerService

  ) { 
    this.getAllUserPermission();
  }

  ngOnInit(): void {
    this.getHealthMonitoring();
    localStorage.removeItem('editedOrderId');
    this.getAllUserPermission();
  }
  getHealthMonitoring() {
     this.spinner.show()
    const id = localStorage.getItem('babyId');
    this.commanServices.getHealthMonitoringByBabyId(id).then(
      (res: any) => {
        this.spinner.hide()
        console.log(res);
        this.getHealth = res.data;
        this.setHealth.push(this.getHealth);
        // console.log("HEALTH", this.setHealth);
        console.log("HEALTH", this.setHealth);
      },
      (error: HttpErrorResponse) => {
        this.spinner.hide()

        console.log(error);
      }
    );
  }


  getbabyhealthdata() {
    let babydata: any;
    const babyId = localStorage.getItem('babyId');
    localStorage.setItem('create', babyId);
    this.router.navigateByUrl('/portal/health-monitoring');

  }

  editOnClick(id: any) {
    localStorage.setItem('editedOrderId', id);
    console.log('id', id);
    this.router.navigateByUrl('portal/health-monitoring');
  }
  addEditOnClick(id: any) {
    localStorage.setItem('editedOrderId', id);
    console.log('id', id);
    this.router.navigateByUrl('portal/health-monitoring-addtask');
  }
  getHealthData(id: any) {
    this.getHealth?.map((res: any) => {
      this.showHealthData = res
      if (res?.id == id) {
        console.log('show', res.id, 'id', id);
      }
    })
  }
  showMaximizableDialog(id: any) {
    console.log("ID", id);
    localStorage.setItem('editedHealthId', id);
    this.router.navigateByUrl('/portal/health-monitoring-summary');
  }
  // 
  // permission 
  getAllUserPermission() {
    let id = localStorage.getItem('user_id');
    this.masterService.getUserPremissionById(id).then((res: any) => {
      console.log("Response of Health Monitoring", res);

      this.permissionData = res.data;

      for (let i = 0; i < this.permissionData.length; i++) {
        
        this.permissions.push(this.permissionData[i].permission_id);
      }
      console.log("all permissions", this.permissions);
    })
  }

  checkPermission(permission: number) {
   
    return this.permissions.includes(permission);
  }
  // 
  /* -------------------------------------------------------------------------- */
  /*                        // reset table data function                        */
  /* -------------------------------------------------------------------------- */

  reset(dt) {
    dt.reset();
    this.filterVal = '';
  }


  goToSummary(formId:any){
    localStorage.setItem("FormId" , formId)
    this.router.navigateByUrl('/portal/health-monitoring-daily-summary');

  }
}
