import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardSidemenuComponent } from 'src/app/components/common/dashboard-sidemenu/dashboard-sidemenu.component';
import { MessageService } from 'primeng/api';

const sharedDeclarations = [
  // DashboardSidemenuComponent
]

const exportsDeclarations = [
  CommonModule
]

@NgModule({
  declarations: [...sharedDeclarations],
  imports: [
    CommonModule
  ],
  providers: [MessageService],
  exports: [...sharedDeclarations, ...exportsDeclarations]
})
export class SharedModule { }
