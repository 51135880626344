import { Component, ElementRef, OnInit, ViewChild,OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CommonApiService } from 'src/app/services/common-api.service';
import { GrowthMonitorService } from 'src/app/services/growth-monitor.service';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-four-to-one-yr-monitor',
  templateUrl: './four-to-one-yr-monitor.component.html',
  styleUrls: ['./four-to-one-yr-monitor.component.scss'],
})
export class FourToOneYrMonitorComponent implements OnInit,OnDestroy {
  @ViewChild('video') videoElement: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  stream: MediaStream;
  capturedImg: File
  ImageName = "Captured-Image"
  openCameraforUpload: boolean = false
  IndexforImage = 0
  public flag: boolean = true;
  breadcrumb = [
    {
      title: 'Four Months to One Years Growth Monitoring',
      subtitle: 'Dashboard',
    },
  ];
  getToken: any;
  getRole: any;
  getname: any;
  selectedFile: File | null = null;
  babyDetails: any = [];
  babyImgArray: any = []
  babyGender: any;
  years: any = '';
  month: any = '';
  days: any = '';
  babyAge: any;
  today = new Date();
  name: any;
  weightConverted = false;
  heightConverted = false;
  selectedDate: any = null; // Initialize with today's date;
  isedit: boolean = false;
  fourTooneYearMonitor: FormGroup;
  growthData: any;
  babyImgJSon: any;
  ImageUrl: string = environment.imgUrl;
  capturedByCamera: any = []
  imagePreview: boolean = false
  imgSrcForPreview: any;
  constructor(
    private fb: FormBuilder,
    private common_service: CommonApiService,
    private growth_service: GrowthMonitorService,
    private messageService: MessageService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    this.fourTooneYearMonitor = this.fb.group({
      baby_details_id: new FormControl(null, Validators.required),
      growth_id: new FormControl(null),
      health_json: this.fb.array([]),
      baby_images: this.fb.array([]),
      motor_coordination: new FormGroup({
        use_hand: new FormControl(null),
        use_hand_description: new FormControl(null),
        roll_back: new FormControl(null),
        roll_back_description: new FormControl(null),
        standing_with_support: new FormControl(null),
        standing_with_support_description: new FormControl(null),
        reaches_toys: new FormControl(null),
        reaches_toys_description: new FormControl(null),
        lying_on_back: new FormControl(null),
        lying_on_back_description: new FormControl(null),
        transfer_toys: new FormControl(null),
        transfer_toys_description: new FormControl(null),
        pulls_to_stand: new FormControl(null),
        pulls_to_stand_description: new FormControl(null),
        stands_alone: new FormControl(null),
        stands_alone_description: new FormControl(null),
        move_in_out: new FormControl(null),
        move_in_out_description: new FormControl(null),
        lift_head: new FormControl(null),
        lift_head_description: new FormControl(null),
        play_on_tummy: new FormControl(null),
        play_on_tummy_description: new FormControl(null),
        pickup_head: new FormControl(null),
        pickup_head_description: new FormControl(null),
        turn_head: new FormControl(null),
        turn_head_description: new FormControl(null),
        enjoy_and_seeks: new FormControl(null),
        enjoy_and_seeks_description: new FormControl(null),
      }),
      sensory: new FormGroup({
        explore_toys: new FormControl(null),
        explore_toys_description: new FormControl(null),
        happy_not_angry: new FormControl(null),
        happy_not_angry_description: new FormControl(null),
        brings_hand_object: new FormControl(null),
        brings_hand_object_description: new FormControl(null),
        rocking_touching: new FormControl(null),
        rocking_touching_description: new FormControl(null),
        not_upset: new FormControl(null),
        not_upset_description: new FormControl(null),
        enjoy_movement: new FormControl(null),
        enjoy_movement_description: new FormControl(null),
        listening_songs: new FormControl(null),
        listening_songs_description: new FormControl(null),
        explore_toys_with_hands: new FormControl(null),
        explore_toys_with_hands_description: new FormControl(null),
        crawls: new FormControl(null),
        crawls_description: new FormControl(null),
        enjoy_movements: new FormControl(null),
        enjoy_movements_description: new FormControl(null),
      }),
      communication: new FormGroup({
        knows_name: new FormControl(null),
        knows_name_description: new FormControl(null),
        reacts: new FormControl(null),
        reacts_description: new FormControl(null),
        listen_and_respond: new FormControl(null),
        listen_and_respond_description: new FormControl(null),
        notice_toys: new FormControl(null),
        notice_toys_description: new FormControl(null),
        meaning_uses: new FormControl(null),
        meaning_uses_description: new FormControl(null),
        res_simple_direction: new FormControl(null),
        res_simple_direction_description: new FormControl(null),
        say_words: new FormControl(null),
        say_words_description: new FormControl(null),
        imitates_sounds: new FormControl(null),
        imitates_sounds_description: new FormControl(null),
        sounds_rhythms: new FormControl(null),
        sounds_rhythms_description: new FormControl(null),
        pays_attention: new FormControl(null),
        pays_attention_description: new FormControl(null),
        respond: new FormControl(null),
        respond_description: new FormControl(null),
        hand_movement: new FormControl(null),
        hand_movement_description: new FormControl(null),
      }),
      feeding: new FormGroup({
        show_interst: new FormControl(null),
        show_interst_description: new FormControl(null),
        open_mouth: new FormControl(null),
        open_mouth_description: new FormControl(null),
        pureed_food: new FormControl(null),
        pureed_food_description: new FormControl(null),
        begins_to_eat: new FormControl(null),
        begins_to_eat_description: new FormControl(null),
        finger_feed: new FormControl(null),
        finger_feed_description: new FormControl(null),
        increasing_food: new FormControl(null),
        increasing_food_description: new FormControl(null),
        open_cup: new FormControl(null),
        open_cup_description: new FormControl(null),
        try_cooked_vegs: new FormControl(null),
        try_cooked_vegs_description: new FormControl(null),
        self_feeding: new FormControl(null),
        self_feeding_description: new FormControl(null),
        enjoy_smell_taste: new FormControl(null),
        enjoy_smell_taste_description: new FormControl(null),
      }),
      play_and_social_skills: new FormGroup({
        playful_interaction: new FormControl(null),
        playful_interaction_description: new FormControl(null),
        vocalizing: new FormControl(null),
        vocalizing_description: new FormControl(null),
        turns_head: new FormControl(null),
        turns_head_description: new FormControl(null),
        eye_contact: new FormControl(null),
        eye_contact_description: new FormControl(null),
        enjoy_playing: new FormControl(null),
        enjoy_playing_description: new FormControl(null),
        enjoy_toys: new FormControl(null),
        enjoy_toys_description: new FormControl(null),
        raise_hands: new FormControl(null),
        raise_hands_description: new FormControl(null),
        enjoy_movement: new FormControl(null),
        enjoy_movement_descriptionn1: new FormControl(null),
        eye_contact_interaction: new FormControl(null),
        eye_contact_interaction_description: new FormControl(null),
        point_to_object: new FormControl(null),
        point_to_object_description: new FormControl(null),
        explore_env: new FormControl(null),
        explore_env_description: new FormControl(null),
        desirable_toy: new FormControl(null),
        desirable_toy_description: new FormControl(null),
      }),
      self_expression: new FormGroup({
        cuddling: new FormControl(null),
        cuddling_description: new FormControl(null),
        not_angry: new FormControl(null),
        not_angry_description: new FormControl(null),
        enjoy_movement: new FormControl(null),
        enjoy_movement_description2: new FormControl(null),
        grown_accustomed: new FormControl(null),
        grown_accustomed_description: new FormControl(null),
        enjoy_touch: new FormControl(null),
        enjoy_touch_description: new FormControl(null),
        cries: new FormControl(null),
        cries_description: new FormControl(null),
        calms: new FormControl(null),
        calms_description: new FormControl(null),
      }),
    });
  }

  logout() {
    localStorage.clear();
    // window.location.reload();
    this.router.navigate(['/']);
  }
  ngOnInit(): void {
    this.spinner.show()
    this.common_service.getBabyDetails().subscribe((res: any) => {
      this.spinner.hide()
      this.babyDetails = res?.data;
      this.setBasicDetails();

    }, (error: any) => {
      this.spinner.hide()

      // Handle any errors that occurred during the API call

    });
    this.getname = localStorage.getItem('name');
    this.getToken = localStorage.getItem('token');
    this.getRole = localStorage.getItem('role')
  }
  ngOnDestroy(): void {
    this.shutDownCamera();
    // Perform cleanup actions here, such as closing the camera
    // Close the camera code here...
  }
  setBasicDetails() {
    if (localStorage.getItem('editedOrderId')) {
      this.getEditFormData();
      this.isedit = true;
    }
    else if (localStorage.getItem('babyId')) {
      const getEdit = localStorage.getItem('babyId');
      this.getBabyDetails({ 'value': getEdit })
      this.fourTooneYearMonitor.controls.baby_details_id.setValue(
        JSON.parse(getEdit)
      );
      this.addVitaminObject('add', 0);
      this.addBabyImg('add', 0);
      this.isedit = false;
      this.fourTooneYearMonitor.get(['motor_coordination', 'use_hand_description']).disable();
      this.fourTooneYearMonitor.get(['motor_coordination', 'roll_back_description']).disable();

      this.fourTooneYearMonitor.get(['motor_coordination', 'standing_with_support_description']).disable();
      this.fourTooneYearMonitor.get(['motor_coordination', 'reaches_toys_description']).disable();
      this.fourTooneYearMonitor.get(['motor_coordination', 'lying_on_back_description']).disable();
      this.fourTooneYearMonitor.get(['motor_coordination', 'transfer_toys_description']).disable();
      this.fourTooneYearMonitor.get(['motor_coordination', 'pulls_to_stand_description']).disable();
      this.fourTooneYearMonitor.get(['motor_coordination', 'stands_alone_description']).disable();
      this.fourTooneYearMonitor.get(['motor_coordination', 'move_in_out_description']).disable();
      this.fourTooneYearMonitor.get(['motor_coordination', 'lift_head_description']).disable();
      this.fourTooneYearMonitor.get(['motor_coordination', 'play_on_tummy_description']).disable();
      this.fourTooneYearMonitor.get(['motor_coordination', 'turn_head_description']).disable();
      this.fourTooneYearMonitor.get(['motor_coordination', 'pickup_head_description']).disable();
      this.fourTooneYearMonitor.get(['motor_coordination', 'enjoy_and_seeks_description']).disable();
      this.fourTooneYearMonitor.get(['sensory', 'explore_toys_description']).disable();
      this.fourTooneYearMonitor.get(['sensory', 'happy_not_angry_description']).disable();
      this.fourTooneYearMonitor.get(['sensory', 'brings_hand_object_description']).disable();
      this.fourTooneYearMonitor.get(['sensory', 'rocking_touching_description']).disable();
      this.fourTooneYearMonitor.get(['sensory', 'not_upset_description']).disable();
      this.fourTooneYearMonitor.get(['sensory', 'enjoy_movement_description']).disable();
      this.fourTooneYearMonitor.get(['sensory', 'listening_songs_description']).disable();
      this.fourTooneYearMonitor.get(['sensory', 'explore_toys_with_hands_description']).disable();
      this.fourTooneYearMonitor.get(['sensory', 'crawls_description']).disable();
      this.fourTooneYearMonitor.get(['sensory', 'enjoy_movements_description']).disable();
      this.fourTooneYearMonitor.get(['communication', 'knows_name_description']).disable();
      this.fourTooneYearMonitor.get(['communication', 'reacts_description']).disable();
      this.fourTooneYearMonitor.get(['communication', 'listen_and_respond_description']).disable();
      this.fourTooneYearMonitor.get(['communication', 'notice_toys_description']).disable();
      this.fourTooneYearMonitor.get(['communication', 'meaning_uses_description']).disable();
      this.fourTooneYearMonitor.get(['communication', 'res_simple_direction_description']).disable();
      this.fourTooneYearMonitor.get(['communication', 'say_words_description']).disable();
      this.fourTooneYearMonitor.get(['communication', 'imitates_sounds_description']).disable();
      this.fourTooneYearMonitor.get(['communication', 'sounds_rhythms_description']).disable();
      this.fourTooneYearMonitor.get(['communication', 'pays_attention_description']).disable();
      this.fourTooneYearMonitor.get(['communication', 'respond_description']).disable();
      this.fourTooneYearMonitor.get(['communication', 'hand_movement_description']).disable();
      this.fourTooneYearMonitor.get(['feeding', 'show_interst_description']).disable();
      this.fourTooneYearMonitor.get(['feeding', 'open_mouth_description']).disable();
      this.fourTooneYearMonitor.get(['feeding', 'pureed_food_description']).disable();
      this.fourTooneYearMonitor.get(['feeding', 'begins_to_eat_description']).disable();
      this.fourTooneYearMonitor.get(['feeding', 'finger_feed_description']).disable();
      this.fourTooneYearMonitor.get(['feeding', 'increasing_food_description']).disable();
      this.fourTooneYearMonitor.get(['feeding', 'open_cup_description']).disable();
      this.fourTooneYearMonitor.get(['feeding', 'try_cooked_vegs_description']).disable();
      this.fourTooneYearMonitor.get(['feeding', 'self_feeding_description']).disable();
      this.fourTooneYearMonitor.get(['feeding', 'enjoy_smell_taste_description']).disable();
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'playful_interaction_description']).disable();
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'vocalizing_description']).disable();
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'turns_head_description']).disable();
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'eye_contact_description']).disable();
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'enjoy_playing_description']).disable();
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'enjoy_toys_description']).disable();
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'raise_hands_description']).disable();
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'enjoy_movement_descriptionn1']).disable();
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'eye_contact_interaction_description']).disable();
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'point_to_object_description']).disable();
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'explore_env_description']).disable();
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'desirable_toy_description']).disable();
      this.fourTooneYearMonitor.get(['self_expression', 'cuddling_description']).disable();
      this.fourTooneYearMonitor.get(['self_expression', 'not_angry_description']).disable();
      this.fourTooneYearMonitor.get(['self_expression', 'enjoy_movement_description2']).disable();
      this.fourTooneYearMonitor.get(['self_expression', 'calms_description']).disable();
      this.fourTooneYearMonitor.get(['self_expression', 'grown_accustomed_description']).disable();
      this.fourTooneYearMonitor.get(['self_expression', 'enjoy_touch_description']).disable();
      this.fourTooneYearMonitor.get(['self_expression', 'cries_description']).disable();
    }
  }


  keyPress(event: KeyboardEvent) {
    const pattern = /^\d+(\.\d{0,2})?$/;

    const inputValue = (event.target as HTMLInputElement).value;
    const proposedValue = inputValue + String.fromCharCode(event.charCode);

    if (!pattern.test(proposedValue)) {
      // Invalid input, prevent input
      event.preventDefault();
    }
  }

  getEditFormData() {
    this.spinner.show()
    const getEdit = localStorage.getItem('editedOrderId')
    this.common_service.getEditGrowthMonitoring(getEdit).then((res: any) => {
      this.spinner.hide()

      const detailsInGrowthMonitoring = res.data[0];
      const developmentMilestones = res.data[0].development_milestones;
      const imagedata = developmentMilestones[0].growth_images_json;

      const milestoneData = JSON.parse(developmentMilestones[0].development_milestone);
      this.growthData = milestoneData

      this.babyGender = detailsInGrowthMonitoring.gender;
      this.selectedDate = detailsInGrowthMonitoring.date_of_birth;
      this.days = detailsInGrowthMonitoring.days;
      this.month = detailsInGrowthMonitoring.months;
      this.years = detailsInGrowthMonitoring.years;
      this.fourTooneYearMonitor.controls.baby_details_id.setValue(detailsInGrowthMonitoring.baby_id)
      const babyImg = milestoneData.baby_images;
      this.babyImgJSon = developmentMilestones[0].growth_images_json;
      this.addBabyImg('add', 0);
      const physical_assessment_details_json = developmentMilestones[0].physical_assessment_details_json


      const VitaminsMedication =
        physical_assessment_details_json;
      this.clearbathingcalculation();
      var check =true
      for (let i = 0; i < VitaminsMedication.length; i++) {
        if((VitaminsMedication[i].height != null) || (VitaminsMedication[i].time!=null) || (VitaminsMedication[i].weight != null)){
          check = false
          let vitaminArray = this.gethealthFormArray();
          let VitaminsMedicationFromApi = VitaminsMedication[i] || {};
  
          let newVitaminArray = new FormGroup({
            height: new FormControl(VitaminsMedicationFromApi.height || null),
            time: new FormControl(
              VitaminsMedicationFromApi.time
                ? new Date(VitaminsMedicationFromApi.time)
                : null
            ),
            weight: new FormControl(VitaminsMedicationFromApi.weight || null),
          });
  
          vitaminArray.insert(i, newVitaminArray);
        }
       
      }
      
      this.fourTooneYearMonitor.get(['motor_coordination', 'use_hand']).setValue(milestoneData.motor_coordination.use_hand);
      this.fourTooneYearMonitor.get(['motor_coordination', 'use_hand_description']).setValue(milestoneData.motor_coordination.use_hand_description);
      this.fourTooneYearMonitor.get(['motor_coordination', 'roll_back']).setValue(milestoneData.motor_coordination.roll_back);
      this.fourTooneYearMonitor.get(['motor_coordination', 'roll_back_description']).setValue(milestoneData.motor_coordination.roll_back_description);
      this.fourTooneYearMonitor.get(['motor_coordination', 'standing_with_support']).setValue(milestoneData.motor_coordination.standing_with_support);
      this.fourTooneYearMonitor.get(['motor_coordination', 'standing_with_support_description']).setValue(milestoneData.motor_coordination.standing_with_support_description);
      this.fourTooneYearMonitor.get(['motor_coordination', 'reaches_toys']).setValue(milestoneData.motor_coordination.reaches_toys);
      this.fourTooneYearMonitor.get(['motor_coordination', 'reaches_toys_description']).setValue(milestoneData.motor_coordination.reaches_toys_description);
      this.fourTooneYearMonitor.get(['motor_coordination', 'lying_on_back']).setValue(milestoneData.motor_coordination.lying_on_back);
      this.fourTooneYearMonitor.get(['motor_coordination', 'lying_on_back_description']).setValue(milestoneData.motor_coordination.lying_on_back_description);
      this.fourTooneYearMonitor.get(['motor_coordination', 'transfer_toys']).setValue(milestoneData.motor_coordination.transfer_toys);
      this.fourTooneYearMonitor.get(['motor_coordination', 'transfer_toys_description']).setValue(milestoneData.motor_coordination.transfer_toys_description);
      this.fourTooneYearMonitor.get(['motor_coordination', 'pulls_to_stand']).setValue(milestoneData.motor_coordination.pulls_to_stand);
      this.fourTooneYearMonitor.get(['motor_coordination', 'pulls_to_stand_description']).setValue(milestoneData.motor_coordination.pulls_to_stand_description);
      this.fourTooneYearMonitor.get(['motor_coordination', 'stands_alone']).setValue(milestoneData.motor_coordination.stands_alone);
      this.fourTooneYearMonitor.get(['motor_coordination', 'stands_alone_description']).setValue(milestoneData.motor_coordination.stands_alone_description);
      this.fourTooneYearMonitor.get(['motor_coordination', 'move_in_out']).setValue(milestoneData.motor_coordination.move_in_out);
      this.fourTooneYearMonitor.get(['motor_coordination', 'move_in_out_description']).setValue(milestoneData.motor_coordination.move_in_out_description);
      this.fourTooneYearMonitor.get(['motor_coordination', 'lift_head']).setValue(milestoneData.motor_coordination.lift_head);
      this.fourTooneYearMonitor.get(['motor_coordination', 'lift_head_description']).setValue(milestoneData.motor_coordination.lift_head_description);
      this.fourTooneYearMonitor.get(['motor_coordination', 'play_on_tummy']).setValue(milestoneData.motor_coordination.play_on_tummy);
      this.fourTooneYearMonitor.get(['motor_coordination', 'play_on_tummy_description']).setValue(milestoneData.motor_coordination.play_on_tummy_description);
      this.fourTooneYearMonitor.get(['motor_coordination', 'pickup_head']).setValue(milestoneData.motor_coordination.pickup_head);
      this.fourTooneYearMonitor.get(['motor_coordination', 'pickup_head_description']).setValue(milestoneData.motor_coordination.pickup_head_description);
      this.fourTooneYearMonitor.get(['motor_coordination', 'turn_head']).setValue(milestoneData.motor_coordination.turn_head);
      this.fourTooneYearMonitor.get(['motor_coordination', 'turn_head_description']).setValue(milestoneData.motor_coordination.turn_head_description);
      this.fourTooneYearMonitor.get(['motor_coordination', 'enjoy_and_seeks']).setValue(milestoneData.motor_coordination.enjoy_and_seeks);
      this.fourTooneYearMonitor.get(['motor_coordination', 'enjoy_and_seeks_description']).setValue(milestoneData.motor_coordination.enjoy_and_seeks_description);
      this.fourTooneYearMonitor.get(['sensory', 'explore_toys']).setValue(milestoneData.sensory.explore_toys);
      this.fourTooneYearMonitor.get(['sensory', 'explore_toys_description']).setValue(milestoneData.motor_coordination.explore_toys_description);
      this.fourTooneYearMonitor.get(['sensory', 'happy_not_angry']).setValue(milestoneData.sensory.happy_not_angry);
      this.fourTooneYearMonitor.get(['sensory', 'happy_not_angry_description']).setValue(milestoneData.sensory.happy_not_angry_description);
      this.fourTooneYearMonitor.get(['sensory', 'brings_hand_object']).setValue(milestoneData.sensory.brings_hand_object);
      this.fourTooneYearMonitor.get(['sensory', 'brings_hand_object_description']).setValue(milestoneData.sensory.brings_hand_object_description);
      this.fourTooneYearMonitor.get(['sensory', 'rocking_touching']).setValue(milestoneData.sensory.rocking_touching);
      this.fourTooneYearMonitor.get(['sensory', 'rocking_touching_description']).setValue(milestoneData.sensory.rocking_touching_description);
      this.fourTooneYearMonitor.get(['sensory', 'not_upset']).setValue(milestoneData.sensory.not_upset);
      this.fourTooneYearMonitor.get(['sensory', 'not_upset_description']).setValue(milestoneData.sensory.not_upset_description);
      this.fourTooneYearMonitor.get(['sensory', 'enjoy_movement']).setValue(milestoneData.sensory.enjoy_movement);
      this.fourTooneYearMonitor.get(['sensory', 'enjoy_movement_description']).setValue(milestoneData.sensory.enjoy_movement_description);
      this.fourTooneYearMonitor.get(['sensory', 'listening_songs']).setValue(milestoneData.sensory.listening_songs);
      this.fourTooneYearMonitor.get(['sensory', 'listening_songs_description']).setValue(milestoneData.sensory.listening_songs_description);
      this.fourTooneYearMonitor.get(['sensory', 'explore_toys_with_hands']).setValue(milestoneData.sensory.explore_toys_with_hands);
      this.fourTooneYearMonitor.get(['sensory', 'explore_toys_with_hands_description']).setValue(milestoneData.sensory.explore_toys_with_hands_description);
      this.fourTooneYearMonitor.get(['sensory', 'crawls']).setValue(milestoneData.sensory.crawls);
      this.fourTooneYearMonitor.get(['sensory', 'crawls_description']).setValue(milestoneData.sensory.crawls);
      this.fourTooneYearMonitor.get(['sensory', 'enjoy_movements']).setValue(milestoneData.sensory.enjoy_movements);
      this.fourTooneYearMonitor.get(['sensory', 'enjoy_movements_description']).setValue(milestoneData.sensory.enjoy_movements_description);
      this.fourTooneYearMonitor.get(['communication', 'knows_name']).setValue(milestoneData.communication.knows_name);
      this.fourTooneYearMonitor.get(['communication', 'knows_name_description']).setValue(milestoneData.communication.knows_name_description);
      this.fourTooneYearMonitor.get(['communication', 'reacts']).setValue(milestoneData.communication.reacts);
      this.fourTooneYearMonitor.get(['communication', 'reacts_description']).setValue(milestoneData.communication.reacts_description);
      this.fourTooneYearMonitor.get(['communication', 'listen_and_respond']).setValue(milestoneData.communication.listen_and_respond);
      this.fourTooneYearMonitor.get(['communication', 'listen_and_respond_description']).setValue(milestoneData.communication.listen_and_respond_description);
      this.fourTooneYearMonitor.get(['communication', 'notice_toys']).setValue(milestoneData.communication.notice_toys);
      this.fourTooneYearMonitor.get(['communication', 'notice_toys_description']).setValue(milestoneData.communication.notice_toys_description);
      this.fourTooneYearMonitor.get(['communication', 'meaning_uses']).setValue(milestoneData.communication.meaning_uses);
      this.fourTooneYearMonitor.get(['communication', 'meaning_uses_description']).setValue(milestoneData.communication.meaning_uses_description);
      this.fourTooneYearMonitor.get(['communication', 'res_simple_direction']).setValue(milestoneData.communication.res_simple_direction);
      this.fourTooneYearMonitor.get(['communication', 'res_simple_direction_description']).setValue(milestoneData.communication.res_simple_direction_description);
      this.fourTooneYearMonitor.get(['communication', 'say_words']).setValue(milestoneData.communication.say_words);
      this.fourTooneYearMonitor.get(['communication', 'say_words_description']).setValue(milestoneData.communication.say_words_description);
      this.fourTooneYearMonitor.get(['communication', 'imitates_sounds']).setValue(milestoneData.communication.imitates_sounds);
      this.fourTooneYearMonitor.get(['communication', 'imitates_sounds_description']).setValue(milestoneData.communication.imitates_sounds_description);
      this.fourTooneYearMonitor.get(['communication', 'sounds_rhythms']).setValue(milestoneData.communication.sounds_rhythms);
      this.fourTooneYearMonitor.get(['communication', 'sounds_rhythms_description']).setValue(milestoneData.communication.sounds_rhythms_description);
      this.fourTooneYearMonitor.get(['communication', 'pays_attention']).setValue(milestoneData.communication.pays_attention);
      this.fourTooneYearMonitor.get(['communication', 'pays_attention_description']).setValue(milestoneData.communication.pays_attention_description);
      this.fourTooneYearMonitor.get(['communication', 'respond']).setValue(milestoneData.communication.respond);
      this.fourTooneYearMonitor.get(['communication', 'respond_description']).setValue(milestoneData.communication.respond_description);
      this.fourTooneYearMonitor.get(['communication', 'hand_movement']).setValue(milestoneData.communication.hand_movement);
      this.fourTooneYearMonitor.get(['communication', 'hand_movement_description']).setValue(milestoneData.communication.hand_movement_description);
      this.fourTooneYearMonitor.get(['feeding', 'show_interst']).setValue(milestoneData.feeding.show_interst);
      this.fourTooneYearMonitor.get(['feeding', 'show_interst_description']).setValue(milestoneData.feeding.show_interst_description);
      this.fourTooneYearMonitor.get(['feeding', 'open_mouth']).setValue(milestoneData.feeding.open_mouth);
      this.fourTooneYearMonitor.get(['feeding', 'open_mouth_description']).setValue(milestoneData.feeding.open_mouth_description);
      this.fourTooneYearMonitor.get(['feeding', 'pureed_food']).setValue(milestoneData.feeding.pureed_food);
      this.fourTooneYearMonitor.get(['feeding', 'pureed_food_description']).setValue(milestoneData.feeding.pureed_food_description);
      this.fourTooneYearMonitor.get(['feeding', 'begins_to_eat']).setValue(milestoneData.feeding.begins_to_eat);
      this.fourTooneYearMonitor.get(['feeding', 'begins_to_eat_description']).setValue(milestoneData.feeding.begins_to_eat_description);
      this.fourTooneYearMonitor.get(['feeding', 'finger_feed']).setValue(milestoneData.feeding.finger_feed);
      this.fourTooneYearMonitor.get(['feeding', 'finger_feed_description']).setValue(milestoneData.feeding.finger_feed_description);
      this.fourTooneYearMonitor.get(['feeding', 'increasing_food']).setValue(milestoneData.feeding.increasing_food);
      this.fourTooneYearMonitor.get(['feeding', 'increasing_food_description']).setValue(milestoneData.feeding.increasing_food_description);
      this.fourTooneYearMonitor.get(['feeding', 'open_cup']).setValue(milestoneData.feeding.open_cup)
      this.fourTooneYearMonitor.get(['feeding', 'open_cup_description']).setValue(milestoneData.feeding.open_cup_description);
      this.fourTooneYearMonitor.get(['feeding', 'try_cooked_vegs']).setValue(milestoneData.feeding.try_cooked_vegs);
      this.fourTooneYearMonitor.get(['feeding', 'try_cooked_vegs_description']).setValue(milestoneData.feeding.try_cooked_vegs_description);
      this.fourTooneYearMonitor.get(['feeding', 'self_feeding']).setValue(milestoneData.feeding.self_feeding);
      this.fourTooneYearMonitor.get(['feeding', 'self_feeding_description']).setValue(milestoneData.feeding.self_feeding_description);
      this.fourTooneYearMonitor.get(['feeding', 'enjoy_smell_taste']).setValue(milestoneData.feeding.enjoy_smell_taste);
      this.fourTooneYearMonitor.get(['feeding', 'enjoy_smell_taste_description']).setValue(milestoneData.feeding.enjoy_smell_taste_description);
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'playful_interaction']).setValue(milestoneData.play_and_social_skills.playful_interaction);
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'playful_interaction_description']).setValue(milestoneData.play_and_social_skills.playful_interaction_description);
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'vocalizing']).setValue(milestoneData.play_and_social_skills.vocalizing);
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'vocalizing_description']).setValue(milestoneData.play_and_social_skills.vocalizing_description);
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'turns_head']).setValue(milestoneData.play_and_social_skills.turns_head);
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'turns_head_description']).setValue(milestoneData.play_and_social_skills.turns_head_description);
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'eye_contact']).setValue(milestoneData.play_and_social_skills.eye_contact);
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'eye_contact_description']).setValue(milestoneData.play_and_social_skills.eye_contact_description);
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'enjoy_playing']).setValue(milestoneData.play_and_social_skills.enjoy_playing);
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'enjoy_playing_description']).setValue(milestoneData.play_and_social_skills.enjoy_playing_description);
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'enjoy_toys']).setValue(milestoneData.play_and_social_skills.enjoy_toys);
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'enjoy_toys_description']).setValue(milestoneData.play_and_social_skills.enjoy_toys_description);
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'raise_hands']).setValue(milestoneData.play_and_social_skills.raise_hands);
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'raise_hands_description']).setValue(milestoneData.play_and_social_skills.raise_hands_description);
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'enjoy_movement']).setValue(milestoneData.play_and_social_skills.enjoy_movement);
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'enjoy_movement_descriptionn1']).setValue(milestoneData.play_and_social_skills.enjoy_movement_descriptionn1);
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'eye_contact_interaction']).setValue(milestoneData.play_and_social_skills.eye_contact_interaction);
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'eye_contact_interaction_description']).setValue(milestoneData.play_and_social_skills.eye_contact_interaction_description);
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'point_to_object']).setValue(milestoneData.play_and_social_skills.point_to_object);
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'point_to_object_description']).setValue(milestoneData.play_and_social_skills.point_to_object_description);
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'explore_env']).setValue(milestoneData.play_and_social_skills.explore_env);
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'explore_env_description']).setValue(milestoneData.play_and_social_skills.explore_env_description);
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'desirable_toy']).setValue(milestoneData.play_and_social_skills.desirable_toy);
      this.fourTooneYearMonitor.get(['play_and_social_skills', 'desirable_toy_description']).setValue(milestoneData.play_and_social_skills.desirable_toy_description);
      this.fourTooneYearMonitor.get(['self_expression', 'cuddling']).setValue(milestoneData.self_expression.cuddling);
      this.fourTooneYearMonitor.get(['self_expression', 'cuddling_description']).setValue(milestoneData.self_expression.cuddling_description);
      this.fourTooneYearMonitor.get(['self_expression', 'not_angry']).setValue(milestoneData.self_expression.not_angry);
      this.fourTooneYearMonitor.get(['self_expression', 'not_angry_description']).setValue(milestoneData.self_expression.not_angry_description);
      this.fourTooneYearMonitor.get(['self_expression', 'enjoy_movement']).setValue(milestoneData.self_expression.enjoy_movement);
      this.fourTooneYearMonitor.get(['self_expression', 'enjoy_movement_description2']).setValue(milestoneData.self_expression.enjoy_movement_description2);
      this.fourTooneYearMonitor.get(['self_expression', 'calms']).setValue(milestoneData.self_expression.calms);
      this.fourTooneYearMonitor.get(['self_expression', 'calms_description']).setValue(milestoneData.self_expression.calms_description);
      this.fourTooneYearMonitor.get(['self_expression', 'grown_accustomed']).setValue(milestoneData.self_expression.grown_accustomed);
      this.fourTooneYearMonitor.get(['self_expression', 'grown_accustomed_description']).setValue(milestoneData.self_expression.grown_accustomed_description);
      this.fourTooneYearMonitor.get(['self_expression', 'enjoy_touch']).setValue(milestoneData.self_expression.enjoy_touch);
      this.fourTooneYearMonitor.get(['self_expression', 'enjoy_touch_description']).setValue(milestoneData.self_expression.enjoy_touch_description);
      this.fourTooneYearMonitor.get(['self_expression', 'cries']).setValue(milestoneData.self_expression.cries);
      this.fourTooneYearMonitor.get(['self_expression', 'cries_description']).setValue(milestoneData.self_expression.cries_description);
      this.disableForm()
      if(check == true){
        this.addVitaminObject('add' , 0)
 }
    }, (error: any) => {
      this.spinner.hide()

      // Handle any errors that occurred during the API call

    })
  }
  /*---------------------------------------------------------------------------*/
  /*                       Reloading of page                                    */
  /*----------------------------------------------------------------------------*/
  softReload() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['portal/patient-view']);
    });
  }
  getBabyDetails(event: any) {
    let data = this.babyDetails?.filter((res: any) => res?.id == event?.value);
    let firstLetter = data[0]?.gender.toLowerCase();
    let capitalize =
      firstLetter.slice(0, 1).toUpperCase() +
      firstLetter.slice(1, firstLetter.length);
    this.babyGender = capitalize;
    this.selectedDate = data[0].date_of_birth;
    // Manually parse the date string in DD/MM/YYYY format
    const dateParts = data[0].date_of_birth.split('/');
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Months are 0-indexed in JavaScript
    const year = parseInt(dateParts[2], 10);

    const selectedDate = moment([year, month, day]);
    const today = moment();

    // Calculate the difference in years, months, and days
    const years = today.diff(selectedDate, 'years');
    selectedDate.add(years, 'years');

    const months = today.diff(selectedDate, 'months');
    selectedDate.add(months, 'months');

    const days = today.diff(selectedDate, 'days');

    this.years = years;
    this.month = months;
    this.days = days;
    if ((this.years == 1 && this.month == 0 && this.days == 0) || (this.years == 0 && this.days <= 31 && this.month >= 4)) {
      this.flag = true;
    } else {
      this.flag = false;
      this.messageService.add({
        severity: 'info',
        summary: 'Info',
        detail:
          'Your child does not meet the eligibility criteria for this plan.',
      });
    }
    // if (this.years != null) {
    //   if (this.years == 1 || this.years == 2) {
    //     this.babyAge = '1 - 2 Years';
    //   } else if (this.years == 3 || this.years == 4) {
    //     this.babyAge = '3 - 4 Years';
    //   } else if (this.years == 4 || this.years == 5) {
    //     this.babyAge = '4 - 5 Years';
    //   } else if (this.years == 5 || this.years == 6) {
    //     this.babyAge = '5 - 6 Years';
    //   } else if (this.years < 1) {
    //     if (this.month > 0 && this.month <= 3) {
    //       this.babyAge = '0 - 3 Months';
    //     } else if (this.month > 3 && this.month <= 11) {
    //       this.babyAge = '4 Months - 1 Year';
    //     } else {
    //       this.babyAge = 'not found';
    //     }
    //   }
    // } else {
    //   this.years = 0;
    //   this.month = 0;
    //   this.days = 0;
    //   this.babyAge = 'not found';
    // }
  }
  growthMonitoringForm(formSubmitted: any) {
    this.fourTooneYearMonitor.enable()
    window.scrollTo(0, 0);

    // const previousData = this.growthData ? this.growthData : null
    // let data = JSON.parse(JSON.stringify(formSubmitted.value))
    // const newData = data
    // const mergedObject = { ...newData };
    // if (previousData) {

    //   for (const [key, value] of Object.entries(previousData)) {
    //     // if ((value !== null && key == 'health_json') && typeof value === 'object') 
    //     if ((value !== null && (key == 'health_json' || key == 'baby_images')) && typeof value === 'object') {
    //       if (Object.keys(value).length > 0) {

    //         let a = newData.health_json;
    //         if (mergedObject.hasOwnProperty(key)) {
    //           mergedObject[key] = [];
    //         }
    //         let b = previousData.health_json
    //         if (previousData.hasOwnProperty(key)) {
    //           mergedObject[key] = [];
    //         }
    //         if (b) {
    //           if (previousData.hasOwnProperty(key)) {
    //             b.forEach(element => {
    //               if (element != undefined) {
    //                 mergedObject[key].push(element);
    //               }
    //             });
    //           }
    //         }
    //         if (a) {
    //           if (mergedObject.hasOwnProperty(key)) {
    //             a.forEach(element => {
    //               if (element != undefined) {
    //                 mergedObject[key].push(element);
    //               }
    //             });

    //           }
    //         }


    //       }
    //     }

    //     if (value !== null && typeof value === 'object') {
    //       // Check if the value is an object

    //       if (!mergedObject.hasOwnProperty(key)) {
    //         mergedObject[key] = {};
    //       }
    //       for (const [nestedKey, nestedValue] of Object.entries(value)) {

    //         if (
    //           !mergedObject[key].hasOwnProperty(nestedKey) &&
    //           nestedValue !== null &&
    //           nestedValue !== undefined &&
    //           typeof nestedValue === 'string' &&
    //           nestedValue.trim() !== ''
    //         ) {
    //           mergedObject[key][nestedKey] = nestedValue;
    //         }


    //       }
    //     } else {
    //       if (
    //         !mergedObject.hasOwnProperty(key) &&
    //         value !== null &&
    //         value !== undefined &&
    //         typeof value === 'string' &&
    //         value.trim() !== ''
    //       ) {
    //         mergedObject[key] = value;
    //       }
    //     }
    //   }
    // }


    if (formSubmitted.valid) {
      let jsonData = JSON.stringify(this.fourTooneYearMonitor.value);
      // let dataObject = {
      //   growth_id: 2,
      //   baby_details_id: this.fourTooneYearMonitor.value.baby_details_id,
      //   growth_monitoring_period_id: 2,
      //   development_milestones: jsonData,
      //   physical_assessment_details_json: null,
      // };
      let formData = new FormData;
      formData.append('growth_id', '2')
      formData.append('baby_details_id', this.fourTooneYearMonitor.value.baby_details_id)
      formData.append('growth_monitoring_period_id', '2')
      formData.append('development_milestones', jsonData)
      let images = JSON.stringify(this.fourTooneYearMonitor.value.health_json);
      formData.append('physical_assessment_details_json', images)
      this.babyImgArray.forEach((file) => {
        formData.append('media', file);
      });

      if (this.isedit == true) {
        const id = localStorage.getItem('editedOrderId')
        this.spinner.show();
        this.growth_service.editGrowthMonitorForm(formData, id).then(
          (res: any) => {
            this.spinner.hide()
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: res?.message,
            });
            setTimeout(() => {
              this.router.navigateByUrl('/portal/first-screen')
            }, 400);
            // this.spinner.hide();
          },
          (error: any) => {
            this.spinner.hide()
            // Handle any errors that occurred during the API call
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error?.message,
            });
          }
        )
      }
      else {
        this.spinner.show();
        this.growth_service.postGrowthMonitorForm(formData).then(
          (res: any) => {
            this.spinner.hide()

            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: res?.message,
            });
            setTimeout(() => {
              this.router.navigateByUrl('/portal/first-screen')
            }, 400);
            // this.spinner.hide();
          },
          (error: any) => {
            this.spinner.hide()

            // Handle any errors that occurred during the API call
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error?.message,
            });
          }
        );
      }
    } else {
      this.spinner.hide()

      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Fill All the Details!',
      });
    }
    this.fourTooneYearMonitor.reset();
  }
  handleInput(event: any, value: string) {

    if (value == 'use_hand') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['motor_coordination', 'use_hand_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['motor_coordination', 'use_hand_description']).disable();
        this.fourTooneYearMonitor.get(['motor_coordination', 'use_hand_description']).setValue(null);

        this.fourTooneYearMonitor.controls.use_hand_description.setValue(null);
      }
    } else if (value == 'roll_back') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['motor_coordination', 'roll_back_description']).enable();

      } else {
        this.fourTooneYearMonitor.get(['motor_coordination', 'roll_back_description']).disable();
        this.fourTooneYearMonitor.get(['motor_coordination', 'roll_back_description']).setValue(null);

      }
    } else if (value == 'standing_with') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['motor_coordination', 'standing_with_support_description']).enable();

      } else {
        this.fourTooneYearMonitor.get(['motor_coordination', 'standing_with_support_description']).disable();
        this.fourTooneYearMonitor.get(['motor_coordination', 'standing_with_support_description']).setValue(
          null
        );
      }
    } else if (value == 'reaches_toys') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['motor_coordination', 'reaches_toys_description']).enable();

      } else {
        this.fourTooneYearMonitor.get(['motor_coordination', 'reaches_toys_description']).disable();
        this.fourTooneYearMonitor.get(['motor_coordination', 'reaches_toys_description']).setValue(
          null
        );
      }
    } else if (value == 'lying_on') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['motor_coordination', 'lying_on_back_description']).enable()
      } else {
        this.fourTooneYearMonitor.get(['motor_coordination', 'lying_on_back_description']).disable();
        this.fourTooneYearMonitor.get(['motor_coordination', 'lying_on_back_description']).setValue(
          null
        );
      }
    } else if (value == 'transfer_toys') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['motor_coordination', 'transfer_toys_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['motor_coordination', 'transfer_toys_description']).disable();
        this.fourTooneYearMonitor.get(['motor_coordination', 'transfer_toys_description']).setValue(
          null
        );
      }
    }
    else if (value == 'pulls_to') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['motor_coordination', 'pulls_to_stand_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['motor_coordination', 'pulls_to_stand_description']).disable();
        this.fourTooneYearMonitor.get(['motor_coordination', 'pulls_to_stand_description']).setValue(
          null
        );
      }
    }
    else if (value == 'stands_alone') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['motor_coordination', 'stands_alone_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['motor_coordination', 'stands_alone_description']).disable();
        this.fourTooneYearMonitor.get(['motor_coordination', 'stands_alone_description']).setValue(
          null
        );
      }
    }
    else if (value == 'move_in') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['motor_coordination', 'move_in_out_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['motor_coordination', 'move_in_out_description']).disable();
        this.fourTooneYearMonitor.get(['motor_coordination', 'move_in_out_description']).setValue(
          null
        );
      }
    }
    else if (value == 'lift_head') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['motor_coordination', 'lift_head_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['motor_coordination', 'lift_head_description']).disable();
        this.fourTooneYearMonitor.get(['motor_coordination', 'lift_head_description']).setValue(
          null
        );
      }
    }
    else if (value == 'play_on_tummy') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['motor_coordination', 'play_on_tummy_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['motor_coordination', 'play_on_tummy_description']).disable();
        this.fourTooneYearMonitor.get(['motor_coordination', 'play_on_tummy_description']).setValue(
          null
        );
      }
    }
    else if (value == 'pickup_head') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['motor_coordination', 'pickup_head_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['motor_coordination', 'pickup_head_description']).disable();
        this.fourTooneYearMonitor.get(['motor_coordination', 'pickup_head_description']).setValue(
          null
        );
      }
    }
    else if (value == 'turn_head') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['motor_coordination', 'turn_head_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['motor_coordination', 'turn_head_description']).disable();
        this.fourTooneYearMonitor.get(['motor_coordination', 'turn_head_description']).setValue(
          null
        );
      }
    }
    else if (value == 'enjoy_and') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['motor_coordination', 'enjoy_and_seeks_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['motor_coordination', 'enjoy_and_seeks_description']).disable();
        this.fourTooneYearMonitor.get(['motor_coordination', 'enjoy_and_seeks_description']).setValue(
          null
        );
      }
    }
    // sensory
    else if (value == 'explore_toys') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['sensory', 'explore_toys_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['sensory', 'explore_toys_description']).disable();
        this.fourTooneYearMonitor.get(['sensory', 'explore_toys_description']).setValue(
          null
        );
      }
    }
    // else if (value == 'explore_toys') {
    //   if (event.target.value == 'Yes' || event.target.value == 'No') {
    //     this.fourTooneYearMonitor.get(['sensory','explore_toys_description']).enable();
    //   } else {
    //     this.fourTooneYearMonitor.controls.explore_toys_description.disable();
    //     this.fourTooneYearMonitor.controls.explore_toys_description.setValue(
    //       null
    //     );
    //   }
    // }
    else if (value == 'happy_not_angry') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['sensory', 'happy_not_angry_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['sensory', 'happy_not_angry_description']).disable();
        this.fourTooneYearMonitor.get(['sensory', 'happy_not_angry_description']).setValue(
          null
        );
      }
    }
    else if (value == 'brings_hand') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['sensory', 'brings_hand_object_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['sensory', 'brings_hand_object_description']).disable();
        this.fourTooneYearMonitor.get(['sensory', 'brings_hand_object_description']).setValue(
          null
        );
      }
    }
    else if (value == 'rocking_touching') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['sensory', 'rocking_touching_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['sensory', 'rocking_touching_description']).disable();
        this.fourTooneYearMonitor.get(['sensory', 'rocking_touching_description']).setValue(
          null
        );
      }
    }
    else if (value == 'not_upset') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['sensory', 'not_upset_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['sensory', 'not_upset_description']).disable();
        this.fourTooneYearMonitor.get(['sensory', 'not_upset_description']).setValue(
          null
        );
      }
    }
    else if (value == 'enjoy_movement') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['sensory', 'enjoy_movement_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['sensory', 'enjoy_movement_description']).disable();
        this.fourTooneYearMonitor.get(['sensory', 'enjoy_movement_description']).setValue(
          null
        );
      }
    }
    // else if (value == 'enjoy_movement') {
    //   if (event.target.value == 'Yes' || event.target.value == 'No') {
    //     this.fourTooneYearMonitor.get(['sensory','enjoy_movement_description']).enable();
    //   } else {
    //     this.fourTooneYearMonitor.controls.enjoy_movement_description.disable();
    //     this.fourTooneYearMonitor.controls.enjoy_movement_description.setValue(
    //       null
    //     );
    //   }
    // }
    else if (value == 'listening_songs') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['sensory', 'listening_songs_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['sensory', 'listening_songs_description']).disable();
        this.fourTooneYearMonitor.get(['sensory', 'listening_songs_description']).setValue(
          null
        );
      }
    }
    else if (value == 'explore_toys1') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['sensory', 'explore_toys_with_hands_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['sensory', 'explore_toys_with_hands_description']).disable();
        this.fourTooneYearMonitor.get(['sensory', 'explore_toys_with_hands_description']).setValue(
          null
        );
      }
    }
    else if (value == 'crawls') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['sensory', 'crawls_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['sensory', 'crawls_description']).disable();
        this.fourTooneYearMonitor.get(['sensory', 'crawls_description']).setValue(
          null
        );
      }
    }
    else if (value == 'enjoy_movements') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['sensory', 'enjoy_movements_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['sensory', 'enjoy_movements_description']).disable();
        this.fourTooneYearMonitor.get(['sensory', 'enjoy_movements_description']).setValue(
          null
        );
      }
    }

    else if (value == 'knows_name') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['communication', 'knows_name_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['communication', 'knows_name_description']).disable();
        this.fourTooneYearMonitor.get(['communication', 'knows_name_description']).setValue(
          null
        );
      }
    }
    else if (value == 'reacts') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['communication', 'reacts_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['communication', 'reacts_description']).disable();
        this.fourTooneYearMonitor.get(['communication', 'reacts_description']).setValue(
          null
        );
      }
    }
    else if (value == 'listen_and_respond') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['communication', 'listen_and_respond_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['communication', 'listen_and_respond_description']).disable();
        this.fourTooneYearMonitor.get(['communication', 'listen_and_respond_description']).setValue(
          null
        );
      }
    }
    else if (value == 'notice_toys') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['communication', 'notice_toys_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['communication', 'notice_toys_description']).disable();
        this.fourTooneYearMonitor.get(['communication', 'notice_toys_description']).setValue(
          null
        );
      }
    }
    else if (value == 'meaning_uses') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['communication', 'meaning_uses_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['communication', 'meaning_uses_description']).disable();
        this.fourTooneYearMonitor.get(['communication', 'meaning_uses_description']).setValue(
          null
        );
      }
    }
    else if (value == 'res_simple') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['communication', 'res_simple_direction_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['communication', 'res_simple_direction_description']).disable();
        this.fourTooneYearMonitor.get(['communication', 'res_simple_direction_description']).setValue(
          null
        );
      }
    }
    else if (value == 'say_words') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['communication', 'say_words_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['communication', 'say_words_description']).disable();
        this.fourTooneYearMonitor.get(['communication', 'say_words_description']).setValue(
          null
        );
      }
    }
    else if (value == 'imitates_sounds') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['communication', 'imitates_sounds_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['communication', 'imitates_sounds_description']).disable();
        this.fourTooneYearMonitor.get(['communication', 'imitates_sounds_description']).setValue(
          null
        );
      }
    }
    else if (value == 'sounds_rhythms') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['communication', 'sounds_rhythms_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['communication', 'sounds_rhythms_description']).disable();
        this.fourTooneYearMonitor.get(['communication', 'sounds_rhythms_description']).setValue(
          null
        );
      }
    }
    else if (value == 'pays_attention') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['communication', 'pays_attention_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['communication', 'pays_attention_description']).disable();
        this.fourTooneYearMonitor.get(['communication', 'pays_attention_description']).setValue(
          null
        );
      }
    }
    else if (value == 'respond') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['communication', 'respond_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['communication', 'respond_description']).disable();
        this.fourTooneYearMonitor.get(['communication', 'respond_description']).setValue(
          null
        );
      }
    }
    else if (value == 'hand_movement') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['communication', 'hand_movement_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['communication', 'hand_movement_description']).disable();
        this.fourTooneYearMonitor.get(['communication', 'hand_movement_description']).setValue(
          null
        );
      }
    }
    else if (value == 'show_interst') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['feeding', 'show_interst_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['feeding', 'show_interst_description']).disable();
        this.fourTooneYearMonitor.get(['feeding', 'show_interst_description']).setValue(
          null
        );
      }
    }
    else if (value == 'open_mouth') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['feeding', 'open_mouth_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['feeding', 'open_mouth_description']).disable();
        this.fourTooneYearMonitor.get(['feeding', 'open_mouth_description']).setValue(
          null
        );
      }
    }
    else if (value == 'pureed_food') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['feeding', 'pureed_food_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['feeding', 'pureed_food_description']).disable();
        this.fourTooneYearMonitor.get(['feeding', 'pureed_food_description']).setValue(
          null
        );
      }
    }
    else if (value == 'begins_to_eat') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['feeding', 'begins_to_eat_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['feeding', 'begins_to_eat_description']).disable();
        this.fourTooneYearMonitor.get(['feeding', 'begins_to_eat_description']).setValue(
          null
        );
      }
    }
    // else if (value == 'begins_to_eat') {
    //   if (event.target.value == 'Yes' || event.target.value == 'No') {
    //     this.fourTooneYearMonitor.get(['communication','begins_to_eat_description']).enable();
    //   } else {
    //     this.fourTooneYearMonitor.controls.begins_to_eat_description.disable();
    //     this.fourTooneYearMonitor.controls.begins_to_eat_description.setValue(
    //       null
    //     );
    //   }
    // }
    else if (value == 'finger_feed') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['feeding', 'finger_feed_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['feeding', 'finger_feed_description']).disable();
        this.fourTooneYearMonitor.get(['feeding', 'finger_feed_description']).setValue(
          null
        );
      }
    }
    else if (value == 'increasing_food') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['feeding', 'increasing_food_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['feeding', 'increasing_food_description']).disable();
        this.fourTooneYearMonitor.get(['feeding', 'increasing_food_description']).setValue(
          null
        );
      }
    }
    else if (value == 'open_cup') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['feeding', 'open_cup_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['feeding', 'open_cup_description']).disable();
        this.fourTooneYearMonitor.get(['feeding', 'open_cup_description']).setValue(
          null
        );
      }
    }
    else if (value == 'try_cooked') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['feeding', 'try_cooked_vegs_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['feeding', 'try_cooked_vegs_description']).disable();
        this.fourTooneYearMonitor.get(['feeding', 'try_cooked_vegs_description']).setValue(
          null
        );
      }
    }
    else if (value == 'self_feeding') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['feeding', 'self_feeding_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['feeding', 'self_feeding_description']).disable();
        this.fourTooneYearMonitor.get(['feeding', 'self_feeding_description']).setValue(
          null
        );
      }
    }
    else if (value == 'enjoy_smell') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['feeding', 'enjoy_smell_taste_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['feeding', 'enjoy_smell_taste_description']).disable();
        this.fourTooneYearMonitor.get(['feeding', 'enjoy_smell_taste_description']).setValue(
          null
        );
      }
    }
    //play feeding
    else if (value == 'playful_interaction') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'playful_interaction_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'playful_interaction_description']).disable();
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'playful_interaction_description']).setValue(
          null
        );
      }
    }
    else if (value == 'vocalizing') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'vocalizing_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'vocalizing_description']).disable();
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'vocalizing_description']).setValue(
          null
        );
      }
    }
    else if (value == 'turns_head') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'turns_head_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'turns_head_description']).disable();
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'turns_head_description']).setValue(
          null
        );
      }
    }
    else if (value == 'eye_contact_familiar') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'eye_contact_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'eye_contact_description']).disable();
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'eye_contact_description']).setValue(
          null
        );
      }
    }
    else if (value == 'enjoy_playing') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'enjoy_playing_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'enjoy_playing_description']).disable();
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'enjoy_playing_description']).setValue(
          null
        );
      }
    }
    else if (value == 'enjoy_toys') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'enjoy_toys_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'enjoy_toys_description']).disable();
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'enjoy_toys_description']).setValue(
          null
        );
      }
    }
    else if (value == 'raise_hands') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'raise_hands_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'raise_hands_description']).disable();
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'raise_hands_description']).setValue(
          null
        );
      }
    }
    else if (value == 'enjoy_movement_gently') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'enjoy_movement_descriptionn1']).enable();
      } else {
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'enjoy_movement_descriptionn1']).disable();
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'enjoy_movement_descriptionn1']).setValue(
          null
        );
      }
    }
    else if (value == 'eye_contact') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'eye_contact_interaction_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'eye_contact_interaction_description']).disable();
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'eye_contact_interaction_description']).setValue(
          null
        );
      }
    }
    else if (value == 'point_to_object') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'point_to_object_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'point_to_object_description']).disable();
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'point_to_object_description']).setValue(
          null
        );
      }
    }
    else if (value == 'explore_env') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'explore_env_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'explore_env_description']).disable();
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'explore_env_description']).setValue(
          null
        );
      }
    }
    else if (value == 'desirable_toy') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'desirable_toy_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'desirable_toy_description']).disable();
        this.fourTooneYearMonitor.get(['play_and_social_skills', 'desirable_toy_description']).setValue(
          null
        );
      }
    }
    else if (value == 'cuddling') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['self_expression', 'cuddling_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['self_expression', 'cuddling_description']).disable();
        this.fourTooneYearMonitor.get(['self_expression', 'cuddling_description']).setValue(
          null
        );
      }
    }
    else if (value == 'not_angry') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['self_expression', 'not_angry_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['self_expression', 'not_angry_description']).disable();
        this.fourTooneYearMonitor.get(['self_expression', 'not_angry_description']).setValue(
          null
        );
      }
    }
    else if (value == 'enjoy_movement2') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['self_expression', 'enjoy_movement_description2']).enable();
      } else {
        this.fourTooneYearMonitor.get(['self_expression', 'enjoy_movement_description2']).disable();
        this.fourTooneYearMonitor.get(['self_expression', 'enjoy_movement_description2']).setValue(
          null
        );
      }
    }
    else if (value == 'calms') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['self_expression', 'calms_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['self_expression', 'calms_description']).disable();
        this.fourTooneYearMonitor.get(['self_expression', 'calms_description']).setValue(
          null
        );
      }
    }
    else if (value == 'grown_accustomed') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['self_expression', 'grown_accustomed_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['self_expression', 'grown_accustomed_description']).disable();
        this.fourTooneYearMonitor.get(['self_expression', 'grown_accustomed_description']).setValue(
          null
        );
      }
    }
    else if (value == 'enjoy_touch') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['self_expression', 'enjoy_touch_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['self_expression', 'enjoy_touch_description']).disable();
        this.fourTooneYearMonitor.get(['self_expression', 'enjoy_touch_description']).setValue(
          null
        );
      }
    }
    else if (value == 'cries') {
      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.fourTooneYearMonitor.get(['self_expression', 'cries_description']).enable();
      } else {
        this.fourTooneYearMonitor.get(['self_expression', 'cries_description']).disable();
        this.fourTooneYearMonitor.get(['self_expression', 'cries_description']).setValue(
          null
        );
      }
    }
  }
  gethealthFormArray() {
    return this.fourTooneYearMonitor.get(
      'health_json'
    ) as FormArray;
  }
  clearbathingcalculation() {
    (this.fourTooneYearMonitor.get('health_json') as FormArray).clear();
  }
  addVitaminObject(string: any, index: any) {
    let vitaminArray = this.gethealthFormArray();
    if (string == 'add') {
      let newVitaminArray = new FormGroup({
        height: new FormControl(null),
        time: new FormControl(null),
        weight: new FormControl(null),
      });

      // let formatedTime =  moment(newTime).format('HH:mm:ss');
      vitaminArray.insert(index + 1, newVitaminArray);
    } else {
      vitaminArray.removeAt(index);
    }
  }

  disableForm() {
    const formData = this.fourTooneYearMonitor.value;
    for (let key in formData) {
      if (formData[key] !== null && formData[key] !== undefined) {
        if (typeof formData[key] === 'object') {
          for (let nestedKey in formData[key]) {
            if (formData[key][nestedKey] !== null && formData[key][nestedKey] !== undefined) {
              const control = this.fourTooneYearMonitor.get(`${key}.${nestedKey}`);
              if (control) {
                control.disable();
              }
            }
          }
        } else {
          const control = this.fourTooneYearMonitor.get(key);
          if (control) {
            control.disable();
          }
        }
      }
    }
  }
  getImageArray() {
    return this.fourTooneYearMonitor.get(
      'baby_images'
    ) as FormArray;
  }
  addBabyImg(string: any, index: any) {
    let imgArray = this.getImageArray();
    if (string == 'add') {
      let newbabyImg = new FormGroup({

      });

      // let formatedTime =  moment(newTime).format('HH:mm:ss');
      imgArray.insert(index + 1, newbabyImg);
    } else {
      imgArray.removeAt(index);
    }
  }
  startCamera() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          this.videoElement.nativeElement.srcObject = stream;
        })
        .catch(error => {
        });
    }
  }

  openCamera() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          this.stream = stream;
          this.videoElement.nativeElement.srcObject = stream;
          this.openCameraforUpload = true
        })
        .catch(error => {
        });
    }
  }
  shutDownCamera(){
    this.openCameraforUpload=false;
    const video = this.videoElement.nativeElement;
  if (video.srcObject) {
    const stream = video.srcObject as MediaStream;
    const tracks = stream.getTracks();
    tracks.forEach(track => track.stop()); // Stop tracks to close the camera
    video.srcObject = null; // Reset the srcObject to release the camera resource
    this.openCameraforUpload = false
  }
  }
  capture() {
    ++this.IndexforImage
    const context = this.canvas.nativeElement.getContext('2d');
    const canvasWidth = this.canvas.nativeElement.width;
    const canvasHeight = this.canvas.nativeElement.height;
    context.drawImage(this.videoElement.nativeElement, 0, 0, canvasWidth, canvasHeight);
    const imageData = this.canvas.nativeElement.toDataURL('image/png');
    this.capturedByCamera.push(imageData)
    const byteString = atob(imageData.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: 'image/png' });
    this.capturedImg = new File([blob], `captured-image${this.IndexforImage}.png`, { type: 'image/png' });
    this.uploadPhoto(null, this.capturedImg)
    this.stopCamera();
  }

  stopCamera() {
    const video = this.videoElement.nativeElement;
    if (video.srcObject) {
      const stream = video.srcObject as MediaStream;
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop()); // Stop tracks to close the camera
      video.srcObject = null; // Reset the srcObject to release the camera resource
      this.openCameraforUpload = false
    }
  }

  uploadPhoto(event: any, captureImg?: any) {

    if (captureImg) {
      this.selectedFile = captureImg;
    }
    else {
      this.selectedFile = event.target.files[0];
    }
    if (event &&
      event.target.files[0].type == 'image/jpeg' ||
      'image/jpg' ||
      'image/png' ||
      'application/pdf'
    ) {
      if (event) {
        this.babyImgArray.push(event.target.files[0]);
      }
      else {
        this.babyImgArray.push(captureImg);

      }
    }

  }

  previewImage(imgSrc: any) {
    this.imagePreview = true
    this.imgSrcForPreview = imgSrc
  }

  deletecapture(imgSrc: any) {
    const byteString = atob(imgSrc.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: 'image/png' });

    const deleteIMG = new File([blob], `captured-image${this.IndexforImage}.png`, { type: 'image/png' });
    this.babyImgArray.forEach((file, index) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const arrayBuffer = e.target.result;

        if (this.arrayBuffersEqual(arrayBuffer, ab)) {
          this.babyImgArray.splice(index, 1); // Remove the file from babyImgArray
        }
      };
      reader.readAsArrayBuffer(file);
    });


    this.capturedByCamera = this.capturedByCamera.filter(item => item !== imgSrc);
    this.imagePreview = false;
  }

  arrayBuffersEqual(buf1: ArrayBuffer, buf2: ArrayBuffer): boolean {
    if (buf1.byteLength !== buf2.byteLength) return false;
    const dv1 = new DataView(buf1);
    const dv2 = new DataView(buf2);
    for (let i = 0; i < buf1.byteLength; i++) {
      if (dv1.getUint8(i) !== dv2.getUint8(i)) return false;
    }
    return true;
  }


  closePreview() {
    this.imagePreview = false
  }
  // convertKgToPounds() {
  //   if (this.weightConverted) return; 

  //   // Get the FormArray from the form group
  //   const healthFormArray = this.fourTooneYearMonitor.get('health_json') as FormArray;
  
  //   // Loop through each form group in the FormArray
  //   healthFormArray.controls.forEach((control) => {
  //     // Get the current weight value
  //     const weightInKg = control.get('weight').value;
  
  //     // Convert weight to pounds if it's a valid number
  //     if (weightInKg && !isNaN(weightInKg)) {
  //       const weightInPounds = (parseFloat(weightInKg) * 2.20462).toFixed(2);
  //       control.get('weight').setValue(weightInPounds);
  //     }
  //   });
  // this.weightConverted = true; //

  // }
  
  // convertHeightToCm(): void {
  //   if (this.heightConverted) return;
  //   const healthFormArray = this.fourTooneYearMonitor.get('health_json') as FormArray;
  
  //   // Loop through each form group in the FormArray
  //   healthFormArray.controls.forEach((control) => {
  //     // Get the current weight value
  //     const heightIncm = control.get('height').value;

  //   if (heightIncm && !isNaN(heightIncm)) {
  //     const heightInInches = parseFloat(heightIncm);
  //     if (!isNaN(heightInInches)) {
  //       const heightInCm = heightInInches * 2.54;
  //       control.patchValue({ height: heightInCm.toFixed(2) });
  //     }
  //   }
  // });
  // this.heightConverted=true;
  // }
}
