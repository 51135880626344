import { Component, OnInit } from '@angular/core';
import { MaikaListService } from 'src/app/services/maika-list.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MasterService } from 'src/app/services/master.service';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-one-year-to-three-year-list',
  templateUrl: './one-year-to-three-year-list.component.html',
  styleUrls: ['./one-year-to-three-year-list.component.scss']
})
export class OneYearToThreeYearListComponent implements OnInit {

  breadcrumb = [
    {
      title: 'One Year to Three Years',
      subTitle: 'Dashboard'
    }
  ]
  oneYearToThreeYear: any = []
  // feeding_frequency_breast: any;
  tub_frequency: any;
  sponge_frequency: any;
  changings_frequency: any;
  temp: any;
  vaccination: any =[];
  vitamins: any = [];
  regular:any=[];
  sleep:any=[];
  skin:any=[];
  lunch: any = [];
  dinner:any=[];
  playing:any=[];
  escort:any=[];
  activity:any=[];
  training:any=[];
  tub: any = [];
  bathing: any = [];
  formula_frequency: any;
  // breast_frequency: any;
  filterVal: string;
  loading: any;
  tooltipText: string = 'One Year To Three Year List';
  datalength: any;
  dataonzeroposition:any;
  viewDetail: any = {};
  displayMaximizable: boolean = false;
  filteredPDFData: any[];
  pdfData: any[];
  exportColumns: any = [];
  bottle:any=[];
  stellrization:any=[];
  cols: any;
  diaper:any=[]
  edit:any;
  permissionData: any = []; 
  permissions: any = [];
  constructor(private two_year_and_above_service: MaikaListService,
              private spinner: NgxSpinnerService,
              private router:Router,
              private masterService: MasterService , 
              private messageService: MessageService,
              ) { }

  // fn to get the data from backend
  getAllData() {
    this.spinner.show()
    this.two_year_and_above_service.getOneYearToThreeList().subscribe((res: any) => {
      this.spinner.hide()
     
      this.oneYearToThreeYear = res.data;
      this.datalength = res?.data?.length;
      console.log("two years and above data", res.data);
     
    },
    (error: any) => {
      this.spinner.hide()
      // Handle any errors that occurred during the API call
    
      
    })
  }

  ngOnInit(): void {
    localStorage.removeItem('editedOrderId');
    localStorage.removeItem('create');
    this.getAllData();
    this.cols = [
      { field: 'sr_no', header: 'Sr No' },
      { field: 'name', header: 'Name' },
      { field: 'date_of_birth', header: 'Date of Birth' },
      { field: 'days', header: 'Age' },
      { field: 'months', header: 'Months' },
    ];
    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    console.log("new api call");
    this.getAllUserPermission();
  }
  getAllUserPermission() {
    let id = localStorage.getItem('user_id');
    this.masterService.getUserPremissionById(id).then((res: any) => {
     

      this.permissionData = res.data;

      for(let i =0; i < this.permissionData.length; i++){
        
        this.permissions.push(this.permissionData[i].permission_id);
      }
      
    })
  }

  checkPermission(permission:number){
    
    return this.permissions.includes(permission);
  }
  formatDateTime(date: string | undefined): string {
    if (date) {
      const formattedDate = new Date(date);
      // const year = formattedDate.getFullYear();
      // const month = ('0' + (formattedDate.getMonth() + 1)).slice(-2);
      // const day = ('0' + formattedDate.getDate()).slice(-2);
      const hours = ('0' + formattedDate.getHours()).slice(-2);
      const minutes = ('0' + formattedDate.getMinutes()).slice(-2);
      const seconds = ('0' + formattedDate.getSeconds()).slice(-2);

      return `${hours}:${minutes}:${seconds}`;
      // return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }
    return '-';
  }
  // open view dialog
  showMaximizableDialog(id: any) {
    this.spinner.show()
    console.log("click id", id);
    this.viewDetail = {};
    this.oneYearToThreeYear.map((res: any) => {

      console.log("showwww", id, "......", res.id)
      if (res.id == id) {
        this.spinner.hide()
        console.log("view detail:", res);
        this.viewDetail = res;
        console.log("single record:", this.viewDetail);
        this.dataonzeroposition=this.viewDetail.nanny_task_details_json;
        console.log("Data on zero position",this.dataonzeroposition);
        // this.vaccination = this.dataonzeroposition?.vaccinations_json;
        // console.log(this.vaccination);
        this.vitamins = this.dataonzeroposition?.vitamins_medications_json;
        
        this.regular=this.dataonzeroposition?.solid_food;
        console.log(this.regular)
        //get data from formarray
        this.lunch=this.dataonzeroposition?.lunch_food;
        this.dinner=this.dataonzeroposition?.dinner_food;
        this.playing=this.dataonzeroposition?.outside_child;
        this.escort=this.dataonzeroposition?.escort_child;
        this.activity=this.dataonzeroposition?.activities_name;
        this.sleep=this.dataonzeroposition?.sleeping_training;
        this.training=this.dataonzeroposition?.potty_training;
       this.skin=this.dataonzeroposition?.skin_care;
       this.bottle=this.dataonzeroposition?.formula_calculation;
       this.stellrization=this.dataonzeroposition?.bottle_sterilization;
       this.diaper=this.dataonzeroposition?.diaper_change_times;
       console.log("we",this.bottle);
       this.bathing = this.dataonzeroposition?.shower_bathing;
       this.tub = this.dataonzeroposition?.tub_bathing;
       this.edit =id;
        // this.breast_frequency = JSON.parse(res?.feeding_frequency_breast)?.frequency;
        // this.formula_frequency = JSON.parse(res?.feeding_frequency)?.frequency;
        // console.log(res?.feeding_frequency,"formula frequency");
        // console.log(res?.feeding_frequency_breast,"breast_frequency frequency");
        // this.vaccination = JSON.parse(JSON.parse(res?.vaccinations_json));
        // this.vitamins = JSON.parse(JSON.parse(res?.vitamins_medications_json));
        // this.temp = JSON.parse(res?.temperature_monitoring_frequency)?.frequency;
        // console.log( this.temp);
        // this.tub_frequency = JSON.parse(res?.bathing_tub_frequency)?.frequency;
        // this.sponge_frequency = JSON.parse(res?.bathing_frequency_sponge)?.frequency;
        // this.changings_frequency = JSON.parse(res?.changing_colthes_frequency)?.frequency;
        // this.feeding_frequency_breast = JSON.parse(res?.feeding_frequency_breast)?.frequency;
        // this.relatives.push(JSON.parse(res?.relatives_name_json));
        // this.sibling.push(JSON.parse(res?.sibling_details_json));
        console.log("single record:", this.viewDetail);
      }
      else {
        this.spinner.hide()
        
      }
      console.log(id);
    });
    this.displayMaximizable = true;
  }

  editOnCLick(id:any){
    localStorage.setItem('editedOrderId', id);
    console.log("id",id);
    this.router.navigateByUrl('/portal/one-year-to-three-year-plan');
  }
  reset(dt) {
    dt.reset();
    this.filterVal = '';
  }
  onPDFExport() {
    this.filteredPDFData = [];
    this.pdfData = [];
    this.filteredPDFData = this.oneYearToThreeYear;
  
    for (let i = 0; i < this.filteredPDFData.length; i++) {
      this.pdfData.push({
        sr_no: i + 1,
        name: this.filteredPDFData[i]?.name ?? '-',
        date_of_birth: this.filteredPDFData[i]?.date_of_birth ?? '-',
        days: this.filteredPDFData[i]?.days ?? '-',
        months: this.filteredPDFData[i]?.months ?? '-',
      });
    }
  
    this.two_year_and_above_service.exportPdf(
      this.pdfData,
      this.exportColumns,
      'zeo-to-three-month.pdf'
    );
  }
}
