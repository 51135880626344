<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">
  <app-dashboard-navbar></app-dashboard-navbar>

  <!-- Breadcrumb Area -->
  <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
    <h1>{{ Content.title }}</h1>
    <ol class="breadcrumb">
      <li class="item"><a routerLink="/">Home</a></li>
      <li class="item">{{ Content.subTitle }}
      </li>
      <li class="item">{{ Content.title }}</li>
    </ol>
  </div>
  <div class="add-listings-box">
    <div class="Master-view-box">
      <div class="theader d-flex justify-content-between flex-wrap">
        <div class="d-flex mb-2">
          <span class="p-input-icon-left ml-auto mr-2 w-sm-50 w-100%">
            <i class="pi pi-search"></i>
            <input pInputText type="text" class="form-control "
                style="text-align: left; padding-left: 20%; height: 45px !important" scriptInjection
                placeholder="Search Keyword" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                [(ngModel)]="filterVal" />
          </span>
          <p-button type="button" class="mx-2" (click)="reset(dt1)" icon="bx bx-reset"
              tooltipPosition="bottom"></p-button>
        </div>
        <div class="add-listings-btn d-flex mb-0" style="text-align: right;">
          <a class="nav-link">
            <button type="submit" *ngIf="checkPermission(13)" (click)="addGrowthMonitoring()">Add</button>
          </a>
          <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="onPDFExport()"
              class="p-button-warning ms-2" pTooltip="PDF" tooltipPosition="bottom"></button>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-lg-12 col-md-12">
        <p-table #dt1 [value]="allData" dataKey="name" [globalFilterFields]="['name', 'date_of_birth', 'age']"
            [rowsPerPageOptions]="[10, 25, 50]" [rows]="10" [showCurrentPageReport]="true" responsiveLayout="scroll"
            [paginator]="true" currentPageReportTemplate="Showing {first} to
                      {last} of {totalRecords} entries">
          <ng-template pTemplate="header">
            <tr>
              <th></th>
              <th style="min-width: 62px">S. No.</th>
              <th>Date Added</th>
              <th>Name</th>
              <th>Category</th>
              <th>Date Of Birth</th>
              <th>Days</th>
              <th>Months</th>
              <!-- <th>Age</th> -->
            </tr>

          </ng-template>
          <ng-template pTemplate="body" let-product let-expanded="expanded" let-i="rowIndex">
            <tr>
              <td>
                <button type="button" pButton pRipple [pRowToggler]="product"
                    class="p-button-text p-button-rounded p-button-plain"
                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                    (click)="getData(product.baby_id)"></button>
              </td>
              <td>{{i+1}}</td>
              <td>{{ product.development_milestones[0]?.created_at? (product.development_milestones[0]?.created_at | date: 'd-MMM-yyyy') : '-' }}</td>
              <td>{{ 'Growth Monitoring Plan ' + (i + 1) }}</td>
              <td>
                {{ product?.growth_monitoring_period_id == 1 ? '0-3 months' : product?.growth_monitoring_period_id == 2 ? '4 months - 1 year' : product?.growth_monitoring_period_id == 3 ? '1-3 years' : product?.growth_monitoring_period_id == 4 ? '3 years and above' : '-' }}
              </td>
              <td>{{product.date_of_birth}}</td>
              <td>{{product.days}}</td>
              <td>{{product.months}}</td>
              <!-- <td>{{product.years}}</td> -->
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-product>
            <tr>
              <td colspan="7" class="inner-data">
                <div class="p-3 py-0">
                  <p-table [value]="product.development_milestones
                              " dataKey="id">
                    <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="id" class="serial-width">Sr No.</th>
              <th pSortableColumn="customer">Action</th>
              <th pSortableColumn="date">Date</th>

            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-order let-expanded="expanded" let-i="rowIndex">
            <tr>
              <td>{{i+1}}</td>
              <td>
                <!-- <button pButton pRipple class="p-button-rounded p-button-text p-button-info" pTooltip="View"
                    tooltipPosition="bottom" (click)="showMaximizableDialog(order.id)"><i class="far fa-eye"></i></button> -->

                <button pButton pRipple class="p-button-rounded p-button-text p-button-info" pTooltip="View"
                    tooltipPosition="bottom" (click)="showData(order.id)"><i class="far fa-eye"></i></button>

                <button pButton pRipple class="p-button-rounded p-button-icon" pTooltip="Edit" tooltipPosition="bottom"
                    (click)="editOnCLick(order.id)"><i class=" bx bx-pencil"></i>
                </button>
              </td>

              <td>
                {{ order?.created_at?(order?.created_at | date:'dd-MM-yyyy' ): "-" }}
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="6">There are no order for this product yet.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      </td>
      </tr>
      </ng-template>
      </p-table>

    </div>
    <!----------------------------------------------------------- view dialog --------------------------------------------------------->
    <!-- ***************************************************************************************************************** -->

    <!-- 0 -3 months view dialog  -->
    <p-dialog header="Baby Details" [(visible)]="displayMaximizable" [modal]="true" [style]="{ width: '90vw' }"
        [draggable]="false" [resizable]="false">
      <!-- Start -->
      <ng-container>
        <div class="row viewSummary">
          <div class="col-lg-12">
            <div class="my-profile-box">
              <!-- Baby detail -->
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label>Name :</label>
                    <div type="text" readonly> {{ babyData?.name ?? '-' | titlecase  }} </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label>Gender :</label>
                    <div type="text" readonly>{{ babyData?.gender ?? '-' }}</div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label>Date of Birth :</label>
                    <div type="text" readonly>{{ babyData?.date_of_birth ?? '-' }}</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label>Days :</label>
                    <div type="text" readonly>{{ babyData?.days ?? '-' }}</div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label>Months :</label>
                    <div type="text" readonly>{{ babyData?.months ?? '-' }}</div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label>Years :</label>
                    <div type="text" readonly>{{ babyData?.years ?? '-' }}</div>
                  </div>
                </div>
              </div>

              <div class="row">
                <h3>Baby Images of Growth Monitoring</h3>
                <div>
                  <ng-container>
                    <div class="row">
                      <div class="col-lg-4 col-sm-6 col-12" *ngFor="let control of babyImages; index as i;">
                        <div class="card mb-3" style="width: 200px;">
                          <img class="card-img-top" src="{{ImageUrl + control?.name}}" alt="Description of the image">
                          <div class="card-body">
                            <!-- Assuming you have a property called 'date' in your data -->
                            <p class="card-text">Date: {{control?.date| date: 'dd-MM-yyyy'?? '-'}}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>

              <div class="row">
                <h3>Height Weight</h3>
                <div>
                  <ng-container *ngFor="let control of health_json; index as i;">
                    <div class="row">
                      <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group">
                          <label class="px-0">Date</label>
                          <div type="text" readonly>{{ control?.time | date: 'dd-MM-yyyy' ?? '-' }}</div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group">
                          <label class="px-0">Height</label>
                          <div type="text" readonly>{{ control?.height ?? '-' }}</div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group">
                          <label class="px-0">Weight</label>
                          <div type="text" readonly>{{ control?.weight ?? '-' }}</div>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <div class="card" *ngIf="health_json" [style.width]="isExpanded ? '600px' : '300px'"
                      (click)="toggleSize()">
                    <div #chartContainer style="overflow-y: auto;">
                      <p-chart type="line" [data]="data" [options]="options" class="baby-height"></p-chart>
                    </div>
                  </div>

                </div>
              </div>
              <!-- motor and coordination -->
              <h3>Motor And Coordination</h3>

              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group ">
                    <label for="">Turns head side to side</label>
                    <div type="text" readonly> {{detail?.head ?? '-'}}</div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group ">
                    <label for="">Remark</label>
                    <div type="text" readonly>
                      {{ detail?.head_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group ">
                    <label for="">Holds up head during tummy time</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.hold_head ?? '-' }}
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group ">
                    <label for="">Remark</label>
                    <div type="text" readonly class="">
                      {{ detail?.holdhead_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="">Clenches fists</label>
                    <div type="text" readonly class="">
                      {{ detail?.clenches ?? '-' }}
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.clenches_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Gripping finger</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.gripping_finger ?? '-' }}
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.gripping_finger_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Pushes up on arms</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.pushes_up ?? '-' }}
                    </div>
                  </div>
                </div>

                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.pushes_up_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Lifts and holds head up</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.head_up ?? '-' }}
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.head_up_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Moves fists from closed to open</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.fists_move ?? '-' }}
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.fists_move_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Bring hands to mouth</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.bring_hands ?? '-' }}
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.bring_hands_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Moves legs and arms off of the surface when
                      excited</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.move_legs ?? '-' }}
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class="">
                      {{ detail?.move_legs_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- motor and coordination end -->

              <!-- Sesory -->
              <h3>Sensory</h3>
              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Moves legs and arms off of the surface when
                      excited</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.move_legs ?? '-' }}
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.move_legs_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">When lying on back, visually tracks a moving toy from
                      side to side</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.moving_toy ?? '-' }}
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.moving_toy_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">When lying on back, keeps head centered to watch faces
                      or toys</label>
                    <div type="text" readonly class=" ">{{ detail?.lying ?? '-' }}</div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.lying_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Able to calm with rocking, touching, and gentle
                      sounds</label>
                    <div type="text" readonly class="">{{ detail?.calm ?? '-' }}</div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.calm_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Enjoys a variety of movements</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.enjoys_movements ?? '-' }}
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.enjoys_movements_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- sensory end -->

              <!-- communication-->
              <h3>Communication</h3>
              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Quiets or smiles in response to sound or voice</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.quiets ?? '-' }}
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.quiets_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Turns head towards sound or voice</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.turns_head ?? '-' }}
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.turns_head_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Shows interest in faces</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.interest ?? '-' }}
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.interest_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Makes eye contact</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.eye_contact ?? '-' }}
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.eye_contact_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Cries differently for different needs</label>
                    <div type="text" readonly class=" ">{{ detail?.cries ?? '-' }} </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.cries_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Coos and smiles</label>
                    <div type="text" readonly class="">{{ detail?.smiles ?? '-' }}</div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class="">
                      {{ detail?.smiles_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- communication End-->

              <!--feeding -->
              <h3>Feeding</h3>
              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Latches onto while nursing or bottle feeding</label>
                    <div type="text" readonly class="">{{ detail?.latches ?? '-' }}</div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.latches_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Tongue  moves forward and back to suck</label>
                    <div type="text" readonly class=" ">{{ detail?.tounge ?? '-' }}</div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class="">
                      {{ detail?.tounge_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Drinks 2-6 oz. of liquid per feeding, 6 time per
                      day</label>
                    <div type="text" readonly class="">{{ detail?.drinks ?? '-' }}</div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class="">
                      {{ detail?.drinks_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Sucks and swallows well during feeding</label>
                    <div type="text" readonly class=" ">{{ detail?.sucks ?? '-' }}</div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class="">
                      {{ detail?.sucks_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- feeding end -->

              <!-- play and social skills -->
              <h3>Play And Social Skill</h3>
              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Enjoys playful face-to-face interaction with
                      people</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.enjoys_playful ?? '-' }}
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class="">
                      {{ detail?.enjoys_playful_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Coos in response to playful interaction</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.coos_reponse ?? '-' }}
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class="">
                      {{ detail?.coos_reponse_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Notices and responds to sounds</label>
                    <div type="text" readonly class="">
                      {{ detail?.responds_sounds ?? '-' }}
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.responds_sounds_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Able to calm during car rides when not tired or
                      hungry</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.able_to_calm ?? '-' }}
                    </div>
                  </div>
                </div>

                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class="">
                      {{ detail?.able_to_calm_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Enjoys bath time</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.enjoys_bath ?? '-' }}
                    </div>
                  </div>
                </div>

                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.enjoys_baths_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Able to tolerate diaper changing without crying</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.tolerate_diaper_change ?? '-' }}
                    </div>
                  </div>
                </div>

                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.tolerate_diaper_change_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- play and social skill end -->

              <!-- Self Expression -->
              <h3>Self-Expression</h3>
              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Comforted by cuddling</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.cuddling ?? '-' }}
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class="">
                      {{ detail?.cuddling_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Not upset with everyday sounds</label>
                    <div type="text" readonly class=" ">{{ detail?.upset ?? '-' }}</div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class="">
                      {{ detail?.upset_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Happy when not hungry or tired</label>
                    <div type="text" readonly class=" ">{{ detail?.happy ?? '-' }}</div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.happy_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Enjoys varied playful movement experiences such as
                      rocking, touching, and calm sounds</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.playful_movment ?? '-' }}
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class="">
                      {{ detail?.playful_movment_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Does not demonstrate frequent or long bouts of being
                      upset</label>
                    <div type="text" readonly class=" ">
                      {{ detail?.demonstrate ?? '-' }}
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                    <label for="" class="">Remark</label>
                    <div type="text" readonly class="">
                      {{ detail?.demonstrate_description ?? '-' }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- self expression end -->
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template pTemplate="footer" let-list let-i="rowIndex">
        <div class="row ">
            <div class="col-md">
              <table class="table-stamp w-100 text-start">
                <tr class="row">
                    <td class="col-sm-6 col-lg-3 "><b>Created By:</b> {{ createdby}}</td>
                    <td class="col-sm-6 col-lg-3 "><b>Created
                            Date&Time:</b>{{ createdat.substring(0, 16).replace('T', ' ') }}
                    </td>
                    <td class="col-sm-6 col-lg-3 "><b>Updated By:</b> {{ updatedby}}</td>
                    <td class="col-sm-6 col-lg-3 "><b>Updated
                            Date&Time:</b> {{ updatedat.substring(0, 16).replace('T', ' ') }}
                    </td>
                </tr>
            </table>
            </div>
            <div class="col-md-auto"> <p-button (click)="displayMaximizable=
              false" label="Ok" styleClass="p-button-text"></p-button> </div>
        </div>


    </ng-template>
      <!-- <ng-template pTemplate="footer">
        <p-button (click)="displayMaximizable=
                    false" label="Ok" styleClass="p-button-text"></p-button>
      </ng-template> -->
    </p-dialog>

    <!-- 4 months to 1 year view dialog -->
    <p-dialog header="Baby Details" [(visible)]="displayfour_months_to_one_year" [modal]="true"
        [style]="{ width: '90vw' }" [draggable]="false" [resizable]="false">
      <!-- Start -->
      <ng-container>
        <div class="row viewSummary">
          <div class="col-lg-12">
            <div class="my-profile-box">
              <form>
                <!-- Baby detail -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Name :</label>
                      <div type="text" readonly> {{ babyData?.name ?? '-' | titlecase  }} </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Gender :</label>
                      <div type="text" readonly>{{ babyData?.gender ?? '-' }}</div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Date of Birth :</label>
                      <div type="text" readonly>{{ babyData?.date_of_birth ?? '-' }}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Days :</label>
                      <div type="text" readonly>{{ babyData?.days ?? '-' }}</div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Months :</label>
                      <div type="text" readonly>{{ babyData?.months ?? '-' }}</div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Years :</label>
                      <div type="text" readonly>{{ babyData?.years ?? '-' }}</div>
                    </div>
                  </div>
                </div>



                <div class="row">
                  <h3>Baby Images of Growth Monitoring</h3>
                  <div>
                    <ng-container>
                      <div class="row">
                        <div class="col-lg-4 col-sm-6 col-12" *ngFor="let control of babyImages; index as i;">
                          <div class="card mb-3" style="width: 200px;">
                            <img class="card-img-top" src="{{ImageUrl + control?.name}}" alt="Description of the image">
                            <div class="card-body">
                              <!-- Assuming you have a property called 'date' in your data -->
                              <p class="card-text">Date: {{control?.date| date: 'dd-MM-yyyy'?? '-'}}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>


                <div class="row">
                  <h3>Height Weight</h3>
                  <div>
                    <ng-container *ngFor="let control of health_json; index as i;">
                      <div class="row">
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="px-0">Date</label>

                            <div type="text" readonly>
                              {{control?.time |  date: 'dd-MM-yyyy'?? '-'}}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="px-0">Height</label>

                            <div type="text" readonly>
                              {{control?.height ?? '-'}}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="px-0">Weight</label>

                            <div type="text" readonly>
                              {{control?.weight ?? '-'}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>

                    <div class="card" *ngIf="health_json">
                      <p-chart type="line" [data]="data" [options]="options"></p-chart>
                    </div>
                  </div>
                </div>


                <h3>Motor and Coordination</h3>

                <!-- Uses hands to support while sitting -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Uses hands to support while sitting</label>
                      <div type="text" readonly>
                        {{motor_coordination?.use_hand ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{motor_coordination?.use_hand_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Uses hands to support while sitting END -->

                <!-- Rolls from back to tummy and tummy to back -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Rolls from back to tummy and tummy to back</label>
                      <div type="text" readonly>
                        {{motor_coordination?.roll_back ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{motor_coordination?.use_hand_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Rolls from back to tummy and tummy to back END -->

                <!-- While standing with support, accepts entire weight with legs -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">While standing with support, accepts entire weight with legs</label>
                      <div type="text" readonly>
                        {{motor_coordination?.standing_with_support ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{motor_coordination?.standing_with_support_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- While standing with support, accepts entire weight with legs END -->

                <!-- Reaches for nearby toys while on tummy -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Reaches for nearby toys while on tummy</label>
                      <div type="text" readonly>
                        {{motor_coordination?.reaches_toys ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{motor_coordination?.reaches_toys_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Reaches for nearby toys while on tummy END -->

                <!-- While lying on back, reaches both hands to play with feet -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">While lying on back, reaches both hands to play with feet</label>
                      <div type="text" readonly>
                        {{motor_coordination?.lying_on_back ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{motor_coordination?.lying_on_back_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- While lying on back, reaches both hands to play with feet END -->

                <!-- Transfer toys from one hand to the other -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Transfer toys from one hand to the other</label>
                      <div type="text" readonly>
                        {{motor_coordination?.transfer_toys ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{motor_coordination?.transfer_toys_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Transfer toys from one hand to the other END -->

                <!-- Pulls to stand and cruise along furniture -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Pulls to stand and cruise along furniture</label>
                      <div type="text" readonly>
                        {{motor_coordination?.pulls_to_stand ?? '-'}}
                      </div>

                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{motor_coordination?.pulls_to_stand_description ?? '-'}}
                      </div>

                    </div>
                  </div>
                </div>
                <!-- Pulls to stand and cruise along furniture END -->

                <!-- Stands alone and takes several independent steps -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Stands alone and takes several independent steps</label>
                      <div type="text" readonly>
                        {{motor_coordination?.stands_alone ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{motor_coordination?.stands_alone_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Stands alone and takes several independent steps END -->


                <!-- Moves in and out of various positions to explore environment -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Moves in and out of various positions to explore environment</label>
                      <div type="text" readonly>
                        {{motor_coordination?.move_in_out ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{motor_coordination?.move_in_out_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Moves in and out of various positions to explore environment END -->

                <!-- Able to lift head forward when being pulled to ditting, from lying on back -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to lift head forward when being pulled to ditting, from lying on
                        back</label>
                      <div type="text" readonly>
                        {{motor_coordination?.lift_head ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{motor_coordination?.lift_head_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Able to lift head forward when being pulled to ditting, from lying on back END -->

                <!-- Able to play on tummy for short burst of time -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to play on tummy for short burst of time</label>
                      <div type="text" readonly>
                        {{motor_coordination?.play_on_tummy ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{motor_coordination?.play_on_tummy_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Able to play on tummy for short burst of time END -->

                <!-- Able to pick up head and prop on elbows during tummy time -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to pick up head and prop on elbows during tummy time</label>
                      <div type="text" readonly>
                        {{motor_coordination?.pickup_head ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{motor_coordination?.pickup_head_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Able to pick up head and prop on elbows during tummy time END -->

                <!-- Able to turn head to visually follow moving toys and people -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to turn head to visually follow moving toys and people</label>
                      <div type="text" readonly>
                        {{motor_coordination?.turn_head ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{motor_coordination?.turn_head_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Able to turn head to visually follow moving toys and people END -->

                <!-- Enjoys and seeks out various ways to move and play -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Enjoys and seeks out various ways to move and play</label>
                      <div type="text" readonly>
                        {{motor_coordination?.enjoy_and_seeks ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{motor_coordination?.enjoy_and_seeks_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Enjoys and seeks out various ways to move and play END -->

                <!--  SENSORY SECTION -->
                <h3> Sensory Section</h3>
                <!--  Uses both hands to explore toys -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Uses both hands to explore toys</label>
                      <div type="text" readonly>{{sensory?.explore_toys ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{sensory?.explore_toys_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--  Uses both hands to explore toys END -->

                <!--  Happy when not hungry or tired -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Happy when not hungry or tired</label>
                      <div type="text" readonly>{{sensory?.happy_not_angry ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{sensory?.happy_not_angry_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--  Happy when not hungry or tired END -->

                <!--  Brings hands and objects to mouth -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Brings hands and objects to mouth</label>
                      <div type="text" readonly>
                        {{sensory?.brings_hand_object ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{sensory?.brings_hand_object_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--  Brings hands and objects to mouth END -->

                <!--  Calms with rocking, touching, and gentle sounds -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Calms with rocking, touching, and gentle sounds</label>
                      <div type="text" readonly>{{sensory?.rocking_touching ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{sensory?.rocking_touching_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--  Calms with rocking, touching, and gentle sounds END -->

                <!--  Not upset by everyday sounds -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Not upset by everyday sounds</label>
                      <div type="text" readonly>{{sensory?.not_upset ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{sensory?.not_upset_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--  Not upset by everyday sounds END -->

                <!--  Enjoys a variety of movement -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Enjoys a variety of movement</label>
                      <div type="text" readonly>{{sensory?.enjoy_movement ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{sensory?.enjoy_movement_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--  Enjoys a variety of movement END -->

                <!--  Enjoys listening to songs -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Enjoys listening to songs</label>
                      <div type="text" readonly> {{sensory?.listening_songs ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{sensory?.listening_songs_description ?? '-'}}</div>
                    </div>
                  </div>
                </div>
                <!--  Enjoys listening to songs END -->

                <!--  Explores toys with hands, fingers and mouth -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Explores toys with hands, fingers and mouth</label>
                      <div type="text" readonly>
                        {{sensory?.explore_toys_with_hands ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{sensory?.explore_toys_with_hands_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--  Explores toys with hands, fingers and mouth END -->

                <!--  Crawls to or away from objects baby sees in the distance -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Crawls to or away from objects baby sees in the distance</label>
                      <div type="text" readonly>{{sensory?.crawls ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{sensory?.crawls_description ?? '-'}}</div>
                    </div>
                  </div>
                </div>
                <!--  Crawls to or away from objects baby sees in the distance END -->

                <!--  Enjoys a variety of movements -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Enjoys a variety of movements</label>
                      <div type="text" readonly>{{sensory?.enjoy_movements ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{sensory?.enjoy_movements_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--  Enjoys a variety of movements END -->
                <!--  SENSORY SECTION END-->

                <!--  COMMUNICATION SECTION -->
                <h3> Communication Section</h3>
                <!--  Knows his/ her name when called -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Knows his/ her name when called</label>
                      <div type="text" readonly>{{communication?.knows_name ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{communication?.knows_name_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--  Knows his/ her name when called END -->

                <!--  Reacts to sudden noises and sounds -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Reacts to sudden noises and sounds</label>
                      <div type="text" readonly>{{communication?.reacts ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{communication?.reacts_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--  Reacts to sudden noises and sounds END -->

                <!--  Listens and responds when spoken to -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Listens and responds when spoken to</label>
                      <div type="text" readonly>
                        {{communication?.listen_and_respond ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{communication?.listen_and_respond_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--  Listens and responds when spoken to END -->

                <!--  Notices toys that makes sounds -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Notices toys that makes sounds</label>
                      <div type="text" readonly>{{communication?.notice_toys ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{communication?.notice_toys_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--  Notices toys that makes sounds END -->

                <!--  Meaningfully uses "mama" or "dada" -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Meaningfully uses "mama" or "dada"</label>
                      <div type="text" readonly>{{communication?.meaning_uses ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{communication?.meaning_uses_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--  Meaningfully uses "mama" or "dada" END -->

                <!--  Responds to simple directions like "Come here" -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Responds to simple directions like "Come here"</label>
                      <div type="text" readonly>
                        {{communication?.res_simple_direction ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{communication?.res_simple_direction_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--  Responds to simple directions like "Come here" END -->

                <!--  Say one or two words -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Say one or two words</label>
                      <div type="text" readonly>{{communication?.say_words ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{communication?.say_words_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--  Say one or two words END -->

                <!--  Imitates speech sounds -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Imitates speech sounds</label>
                      <div type="text" readonly>
                        {{communication?.imitates_sounds ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{communication?.imitates_sounds_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--  Imitates speech sounds END -->

                <!--  Babbling has sounds and rhythms of speech -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Babbling has sounds and rhythms of speech</label>
                      <div type="text" readonly>
                        {{communication?.sounds_rhythms ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{communication?.sounds_rhythms_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--  Babbling has sounds and rhythms of speech END -->

                <!--  Pays attention to where you are looking and pointing  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Pays attention to where you are looking and pointing </label>
                      <div type="text" readonly>
                        {{communication?.pays_attention ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{communication?.pays_attention_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--  Pays attention to where you are looking and pointing  END -->


                <!--   Responds to "No"  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Responds to "No" </label>
                      <div type="text" readonly>{{communication?.respond ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{communication?.respond_description ?? '-'}}</div>
                    </div>
                  </div>
                </div>
                <!--   Responds to "No"  END -->

                <!--    Begins to use hand movements to communicate wants and needs e.g. reaches to be picked up  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Begins to use hand movements to communicate wants and needs e.g.
                        reaches to be picked up </label>
                      <div type="text" readonly>
                        {{communication?.hand_movement ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{communication?.hand_movement_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--    Begins to use hand movements to communicate wants and needs e.g. reaches to be picked up  END -->

                <!-- FEEDING -->
                <h3>FEEDING</h3>
                <!-- Shows interest in food  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Shows interest in food </label>
                      <div type="text" readonly>{{feeding?.show_interst ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{feeding?.show_interst_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--   Shows interest in food  END -->

                <!-- Opens mouth as spoon approaches  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Opens mouth as spoon approaches </label>
                      <div type="text" readonly>{{feeding?.open_mouth ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{feeding?.open_mouth_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--   Opens mouth as spoon approaches  END -->

                <!-- Opens mouth as spoon approaches  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Opens mouth as spoon approaches </label>
                      <div type="text" readonly>{{feeding?.open_mouth ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly> {{feeding?.open_mouth_description ?? '-'}}</div>
                    </div>
                  </div>
                </div>
                <!--   Opens mouth as spoon approaches  END -->

                <!-- Moves pureed food from front of mouth to back  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Moves pureed food from front of mouth to back </label>
                      <div type="text" readonly>{{feeding?.pureed_food ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{feeding?.pureed_food_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--   Moves pureed food from front of mouth to back  END -->

                <!-- Begins to eat cereals and pureed foods - smooth, pureed food (single ingredient only)  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Begins to eat cereals and pureed foods - smooth, pureed food (single
                        ingredient only) </label>
                      <div type="text" readonly>{{feeding?.begins_to_eat ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{feeding?.begins_to_eat_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--   Begins to eat cereals and pureed foods - smooth, pureed food (single ingredient only)  END -->

                <!-- Finger feeds self  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Finger feeds self </label>
                      <div type="text" readonly>{{feeding?.finger_feed ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{feeding?.finger_feed_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--   Finger feeds self  END -->

                <!-- Eating an increasing variety of food  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Eating an increasing variety of food </label>
                      <div type="text" readonly> {{feeding?.increasing_food ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{feeding?.increasing_food_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--   Eating an increasing variety of food  END -->

                <!-- Begins to use an open cup  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Begins to use an open cup </label>
                      <div type="text" readonly>{{feeding?.open_cup ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{feeding?.open_cup_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--   Begins to use an open cup  END -->

                <!-- Ready to try soft cooked vegetables, soft fruits, and finger food  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Ready to try soft cooked vegetables, soft fruits, and finger food
                      </label>
                      <div type="text" readonly>{{feeding?.try_cooked_vegs ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{feeding?.try_cooked_vegs_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--   Ready to try soft cooked vegetables, soft fruits, and finger food  END -->

                <!-- Might be ready to start self-feeding with utensils  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Might be ready to start self-feeding with utensils </label>
                      <div type="text" readonly>{{feeding?.try_cooked_vegs ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{feeding?.try_cooked_vegs_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--   Might be ready to start self-feeding with utensils  END -->

                <!-- Enjoys a greater variety of smells and tastes  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Enjoys a greater variety of smells and tastes </label>
                      <div type="text" readonly>{{feeding?.enjoy_smell_taste ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{feeding?.enjoy_smell_taste_description ?? '-'}}</div>
                    </div>
                  </div>
                </div>
                <!--   Enjoys a greater variety of smells and tastes  END -->

                <!--FEEDING END  -->

                <!-- PLAY AND SOCIAL SKILLS SECTION -->
                <h3>Play and Social Skills</h3>
                <!-- Enjoys playful interactions with others, e.g. peek a boo  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Enjoys playful interactions with others, e.g. peek a boo </label>
                      <div type="text" readonly>{{play?.playful_interaction ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{play?.playful_interaction_description ?? '-'}}</div>
                    </div>
                  </div>
                </div>
                <!--   Enjoys playful interactions with others, e.g. peek a boo  END -->

                <!-- Vocalizing in response to playful interactions  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Vocalizing in response to playful interactions </label>
                      <div type="text" readonly>{{play?.vocalizing ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly> {{play?.vocalizing_description ?? '-'}}</div>
                    </div>
                  </div>
                </div>
                <!--   Vocalizing in response to playful interactions  END -->

                <!-- Turns head towards sounds  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Turns head towards sounds </label>
                      <div type="text" readonly>{{play?.turns_head ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{play?.turns_head_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--   Turns head towards sounds  END -->

                <!-- Maintains eye contact with familiar people during playful interaction  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Maintains eye contact with familiar people during playful
                        interaction </label>
                      <div type="text" readonly>{{play?.eye_contact ?? '-'}}</div>

                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{play?.eye_contact_description ?? '-'}}
                      </div>
                    </div>
                  </div>

                </div>
                <!--   Maintains eye contact with familiar people during playful interaction  END -->

                <!-- Enjoys playing with toys of varied textures  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Enjoys playing with toys of varied textures </label>
                      <div type="text" readonly>
                        {{play?.enjoy_playing ?? '-'}}
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{play?.enjoy_playing_description ?? '-'}}</div>

                    </div>
                  </div>
                </div>

                <!--   Enjoys playing with toys of varied textures  END -->

                <!-- Enjoys musical toys  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Enjoys musical toys </label>
                      <div type="text" readonly>{{play?.enjoy_toys ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{play?.enjoy_toys_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--   Enjoys musical toys  END -->

                <!-- Raises hands to be picked up  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Raises hands to be picked up </label>
                      <div type="text" readonly>{{play?.raise_hands ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{play?.raise_hands_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--   Raises hands to be picked up  END -->

                <!-- Enjoys various types of movements, such as being gently swung  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Enjoys various types of movements, such as being gently swung
                      </label>
                      <div type="text" readonly> {{play?.enjoy_movement ?? '-'}}</div>

                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{play?.enjoy_movement_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--   Enjoys various types of movements, such as being gently swung  END -->

                <!-- Maintains eye contact with people during playful interactions  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Maintains eye contact with people during playful interactions
                      </label>
                      <div type="text" readonly>{{play?.eye_contact_interaction ?? '-'}}

                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{play?.eye_contact_interaction_description ?? '-'}}</div>
                    </div>
                  </div>
                </div>
                <!--   Maintains eye contact with people during playful interactions  END -->

                <!-- Points to object of interest by 1 year of age  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Points to object of interest by 1 year of age </label>
                      <div type="text" readonly>{{play?.point_to_object ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{play?.point_to_object_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--   Points to object of interest by 1 year of age  END -->

                <!-- Frequently explores the environment when placed on floor  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Frequently explores the environment when placed on floor </label>
                      <div type="text" readonly>{{play?.explore_env ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{play?.explore_env_description ?? '-'}}</div>
                    </div>
                  </div>
                </div>
                <!--   Frequently explores the environment when placed on floor  END -->

                <!-- Enjoys moving to get desirable toy  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Enjoys moving to get desirable toy </label>
                      <div type="text" readonly> {{play?.desirable_toy ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{play?.desirable_toy_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--   Enjoys moving to get desirable toy  END -->
                <!-- PLAY AND SOCIAL SKILLS SECTION END-->

                <!-- SELF-EXPRESSION SECTION -->
                <h3>SELF-EXPRESSION SECTION</h3>

                <!-- Comforted by cuddling  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Comforted by cuddling </label>
                      <div type="text" readonly>{{self_exp?.cuddling ?? '-'}} </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{self_exp?.cuddling_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--   Comforted by cuddling  END -->

                <!-- Happy when not hungry or tired  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Happy when not hungry or tired </label>
                      <div type="text" readonly>{{self_exp?.not_angry ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{self_exp?.not_angry_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--   Happy when not hungry or tired  END -->

                <!-- Enjoys varied playful movement experiences such as rocking, touching, and calm sounds  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Enjoys varied playful movement experiences such as rocking,
                        touching, and calm sounds </label>
                      <div type="text" readonly>{{self_exp?.enjoy_movement ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{self_exp?.enjoy_movement_description2 ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--   Enjoys varied playful movement experiences such as rocking, touching, and calm sounds  END -->

                <!-- Calms with rocking, touching, and gentle sounds  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Calms with rocking, touching, and gentle sounds </label>
                      <div type="text" readonly>{{self_exp?.calms ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div> {{self_exp?.calms_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--   Calms with rocking, touching, and gentle sounds  END -->

                <!-- Has grown accustomed to everyday sounds and is usually not startled by it  -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Has grown accustomed to everyday sounds and is usually not startled
                        by it </label>
                      <div type="text" readonly>{{self_exp?.grown_accustomed ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{self_exp?.grown_accustomed_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--   Has grown accustomed to everyday sounds and is usually not startled by it  END -->

                <!-- Enjoys a wide variety of touch, noisem and smell -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Enjoys a wide variety of touch, noisem and smell </label>
                      <div type="text" readonly>{{self_exp?.grown_accustomed ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{self_exp?.grown_accustomed_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Enjoys a wide variety of touch, noisem and smell end -->
                <!-- Cries and notices when hurt -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Cries and notices when hurt</label>
                      <div type="text" readonly>{{self_exp?.grown_accustomed ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{self_exp?.grown_accustomed_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Cries and notices when hurt end -->

              </form>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template pTemplate="footer" let-list let-i="rowIndex">
        <div class="row ">
            <div class="col-md">
                <table class="table-stamp w-100 text-start">
                    <tr class="row">
                        <td class="col-sm-6 col-lg-3 "><b>Created By:</b> {{ createdby}}</td>
                        <td class="col-sm-6 col-lg-3 "><b>Created
                                Date&Time:</b>{{ createdat.substring(0, 16).replace('T', ' ') }}
                        </td>
                        <td class="col-sm-6 col-lg-3 "><b>Updated By:</b> {{ updatedby}}</td>
                        <td class="col-sm-6 col-lg-3 "><b>Updated
                                Date&Time:</b> {{ updatedat.substring(0, 16).replace('T', ' ') }}
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col-md-auto">  <p-button (click)="displayfour_months_to_one_year=
              false" label="Ok" styleClass="p-button-text"></p-button></div>
        </div>


    </ng-template>
      <!-- <ng-template pTemplate="footer">
       
      </ng-template> -->
    </p-dialog>

    <!-- 1 year to 3 year view dialoge  -->
    <p-dialog header="Baby Details" [(visible)]="displayOneToThreeYear" [modal]="true" [style]="{ width: '90vw' }"
        [draggable]="false" [resizable]="false">
      <!-- Start -->
      <ng-container>
        <div class="row viewSummary">
          <div class="col-lg-12">
            <div class="my-profile-box">
              <form>
                <!-- Baby detail -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Name :</label>
                      <div type="text" readonly>{{babyData?.name?? '-' | titlecase }}</div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12
                                                col-md-12">
                    <div class="form-group">
                      <label>Gender :</label>
                      <div type="text" readonly>{{babyData?.gender ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Date of Birth :</label>
                      <div type="text" readonly>{{babyData?.date_of_birth ?? '-'}}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Days :</label>
                      <div type="text" readonly>{{babyData?.days ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Months :</label>
                      <div type="text" readonly>{{babyData?.months ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Years :</label>
                      <div type="text" readonly>{{babyData?.years ?? '-'}}</div>
                    </div>
                  </div>
                </div>


                <div class="row">
                  <h3>Baby Images of Growth Monitoring</h3>
                  <div>
                    <ng-container>
                      <div class="row">
                        <div class="col-lg-4 col-sm-6 col-12" *ngFor="let control of babyImages; index as i;">
                          <div class="card mb-3" style="width: 200px;">
                            <img class="card-img-top" src="{{ImageUrl + control?.name}}" alt="Description of the image">
                            <div class="card-body">
                              <!-- Assuming you have a property called 'date' in your data -->
                              <p class="card-text">Date: {{control?.date| date: 'dd-MM-yyyy'?? '-'}}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="row">
                  <h3>Height Weight</h3>
                  <div>
                    <ng-container *ngFor="let control of health_json; index as i;">
                      <div class="row">
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="px-0">Date</label>

                            <div type="text" readonly>
                              {{control?.time |  date: 'dd-MM-yyyy'?? '-'}}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="px-0">Height</label>

                            <div type="text" readonly>
                              {{control?.height ?? '-'}}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="px-0">Weight</label>

                            <div type="text" readonly>
                              {{control?.weight ?? '-'}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>

                    <div class="card" *ngIf="health_json">
                      <p-chart type="line" [data]="data" [options]="options"></p-chart>
                    </div>
                  </div>
                </div>


                <!-- motor and coordination -->
                <h3>Motor and Coordination</h3>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Walks independently and seldom falls</label>
                      <div type="text" readonly>{{detail?.walk ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.walk_description?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>



                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Squats to pick up the toy</label>
                      <div type="text" readonly>{{detail?.squats_toy ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.squats_toy_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Starts to jump with both feet leaving the ground</label>
                      <div type="text" readonly>{{detail?.jump ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.jump_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">When walking, able to pull toys behind them</label>
                      <div type="text" readonly>{{detail?.pull_toys ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.pull_toys_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Runs</label>
                      <div type="text" readonly>{{detail?.runs ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.runs_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Stands on tip toes</label>
                      <div type="text" readonly>
                        {{detail?.stand_on_toes ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.stand_on_toes_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Stands on low furniture</label>
                      <div type="text" readonly>
                        {{detail?.stands_on_furniture ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.stands_on_furniture_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Kicks large balls</label>
                      <div type="text" readonly>
                        {{detail?.kicks_balls ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{detail?.kicks_balls_description ?? '-'}}</div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Goes up and down stairs with support</label>
                      <div type="text" readonly>{{detail?.stairs ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.stairs_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Consistently uses 2-3 word phrases</label>
                      <div type="text" readonly>
                        {{detail?.word_phrases ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.word_phrases_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Follows 2-step unrelated directions, e.g. “Give me the ball and go
                        get your
                        coat”</label>
                      <div type="text" readonly>
                        {{detail?.unrelated_directions ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.unrelated_directions_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Simple understanding of concepts including colour, space, and time
                      </label>
                      <div type="text" readonly>
                        {{detail?.understanding ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.understanding_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Frequently moving in and out of various positions (e.g. crawling,
                        climbing, cruising, and walking) to explore and get desirable
                        objects</label>
                      <div type="text" readonly>
                        {{detail?.frequent_move ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.frequent_move_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Has adequate endurance and strength to play with peers</label>
                      <div type="text" readonly>{{detail?.strength ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.strength_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Walks with heel toe pattern and not primarily on toes</label>
                      <div type="text" readonly>
                        {{detail?.walk_with_heel ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.walk_with_heel_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Leans on furniture, walls, or people and sits slumped over when
                        tired</label>
                      <div type="text" readonly>{{detail?.leans ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.leans_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>


                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Coordinates both hands to play, such as swinging a bat or opening a
                        container</label>
                      <div type="text" readonly>
                        {{detail?.coordinates ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.coordinates_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- motor and coordination end -->

                <!--  SENSORY SECTION -->
                <h3> SENSORY SECTION</h3>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Helps with getting dressed/undressed</label>
                      <div type="text" readonly>{{detail?.dressed ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.dressed_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Has a regular sleep schedule</label>
                      <div type="text" readonly>
                        {{detail?.regular_sleep ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.regular_sleep_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Eats an increasing variety of foods</label>
                      <div type="text" readonly>{{detail?.foods ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.foods_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Flips switches on and off</label>
                      <div type="text" readonly>{{detail?.flips ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{detail?.flips_description ?? '-'}}</div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Uses crayons, pens, or markers to make marks on paper</label>
                      <div type="text" readonly>{{detail?.crayons ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.crayons_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Enjoys a variety of movement</label>
                      <div type="text" readonly>{{detail?.sorts ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.sorts_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Stacks 5 or more small blocks or toys on top of each other</label>
                      <div type="text" readonly>{{detail?.stacks ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.stacks_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Takes toys apart and puts them back together</label>
                      <div type="text" readonly>{{detail?.takes_toys ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.takes_toys_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <!--  SENSORY SECTION END-->

                <!--  COMMUNICATION SECTION -->
                <h3> Communication Section</h3>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Combines sounds and gestures</label>
                      <div type="text" readonly>{{detail?.combines ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.combines_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Imitates simple words and actions</label>
                      <div type="text" readonly>{{detail?.imitates ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.imitates_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Follows simple directions</label>
                      <div type="text" readonly>{{detail?.follows ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.follows_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Identifies 1-2 body parts when named</label>
                      <div type="text" readonly>{{detail?.identifies ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.identifies_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Produces speech-like babbling</label>
                      <div type="text" readonly>{{detail?.babbling ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.babbling_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>


                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Points at familiar objects and people in pictures</label>
                      <div type="text" readonly>{{detail?.familiar ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.familiar_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Understands basic nouns and pronouns</label>
                      <div type="text" readonly>{{detail?.basic ?? '-'}} </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.basic_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Understands “mine” and “yours”</label>
                      <div type="text">
                        {{detail?.understands ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.understands_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Asks “what” and “where” and “why” questions</label>
                      <div type="text" readonly>{{detail?.questions ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.questions_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Uses plurals, e.g. “dogs” </label>
                      <div type="text" readonly>{{detail?.plurals ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.plurals_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Understands “in” and “on” </label>
                      <div type="text" readonly>{{detail?.in_on ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.in_on_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Responds to yes/no questions with head shake/nod
                      </label>
                      <div type="text" readonly>{{detail?.head_shake ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.head_shake_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Uses at least 50 words
                      </label>
                      <div type="text" readonly> {{detail?.use_words ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.use_words_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Names objects and pictures
                      </label>
                      <div type="text" readonly>{{detail?.names ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.names_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Understands action words
                      </label>
                      <div type="text" readonly>{{detail?.action ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.action_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Uses gestures and words in pretend play
                      </label>
                      <div type="text" readonly>{{detail?.gestures ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.gestures_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Follows 2-step related direction, e.g. “Pick up your coat and bring
                        it to me”
                      </label>
                      <div type="text" readonly> {{detail?.direction ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.direction_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Enjoys listening to stories
                      </label>
                      <div type="text" readonly>{{detail?.stories ?? '-'}} </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.stories_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Consistently uses 2-3 word phrases
                      </label>
                      <div type="text" readonly>{{detail?.phrases ?? '-'}} </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.phrases_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Simple understanding of concepts including colour, space, and time
                      </label>
                      <div type="text" readonly>{{detail?.concepts ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.concepts_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>



                <!-- FEEDING -->
                <h3>Feeding</h3>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Increases variety of coarsely chopped table foods </label>
                      <div type="text" readonly>
                        {{detail?.table_foods ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.table_foods_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Holds and drink from a cup </label>
                      <div type="text" readonly>{{detail?.drink ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.drink_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- feeding end -->

                <!-- PLAY AND SOCIAL SKILLS SECTION -->
                <!-- Enjoys playful interactions with others, e.g. peek a boo  -->
                <h3>Play and Social Skills</h3>
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Interested in interacting with people (plays patty cake)</label>
                      <div type="text" readonly>
                        {{detail?.interacting ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.interacting_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Raises hands to be picked up </label>
                      <div type="text" readonly>{{detail?.picked_up ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.picked_up_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Maintains eye contact with people during playful
                        interactions</label>
                      <div type="text" readonly>{{detail?.eye_contacts ?? '-'}}</div>

                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.eye_contacts_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Interested in imitating others </label>
                      <div type="text" readonly>{{detail?.imitating_others ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.imitating_others_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Turns head in response to name being called </label>
                      <div type="text" readonly>{{detail?.turns_head ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{detail?.turns_head_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Points to objects of interest by 1 year old </label>
                      <div type="text" readonly>{{detail?.points_objects ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.points_objects_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Able to locate objects you are pointing to </label>
                      <div type="text" readonly>{{detail?.locate_objects ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.locate_objects_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Claps and together in response to social play</label>
                      <div type="text" readonly>{{detail?.claps ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{detail?.claps_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Enjoys playing with a variety of toys and textures </label>
                      <div type="text" readonly>{{detail?.enjoy_playing ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.enjoy_playing_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>


                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Eagerly explores the environment when placed on the floor </label>
                      <div type="text" readonly>{{detail?.explores ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{detail?.explores_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Enjoys exploring and playing at the playground </label>
                      <div type="text" readonly>{{detail?.playground ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{detail?.playground_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Enjoys swinging on playground swings </label>
                      <div type="text" readonly>{{detail?.swinging ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{detail?.swinging_description ?? '-'}} </div>
                    </div>
                  </div>
                </div>


                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Able to participate in small groups with other children </label>
                      <div type="text" readonly>{{detail?.participate ?? '-'}}</div>

                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.participate_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Plays with toys without mouthing them </label>
                      <div type="text" readonly>{{detail?.mouthing ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{detail?.mouthing_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Enjoys sitting to look at or listen to a book</label>
                      <div type="text" readonly>{{detail?.listen_book ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.listen_book_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Enjoys swinging, being thrown in air, and roughhousing </label>
                      <div type="text" readonly>{{detail?.roughhousing ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.roughhousing_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Able to participate in messy activities that result in dirty hands
                      </label>
                      <div type="text" readonly>{{detail?.messy_activity ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.messy_activity_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>


                <!-- PLAY AND SOCIAL SKILLS SECTION END-->



                <!-- SELF-EXPRESSION SECTION -->
                <h3>Self-Expresssion Section</h3>


                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Comforted by cuddling and a parent's touch </label>
                      <div type="text" readonly>{{detail?.cuddling ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly> {{detail?.cuddling_description ?? '-'}}</div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Able to calm with experiences such as rocking, touch, and soothing
                        sounds </label>
                      <div type="text" readonly>{{detail?.calm ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{detail?.calm_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Grown accustomed to everyday sounds and is usually not startled by
                        them </label>
                      <div type="text" readonly>{{detail?.accustomed ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{detail?.accustomed_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Has an established and reliable sleeping schedule</label>
                      <div type="text" readonly>{{detail?.sleeping ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{detail?.sleeping_description ?? '-'}}</div>

                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Able to enjoy a wide variety of touch, noise, and smells </label>
                      <div type="text" readonly>{{detail?.touch ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{detail?.touch_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class=""> Cries and notices when hurt </label>
                      <div type="text" readonly>{{detail?.cries ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{detail?.cries_description ?? '-'}}</div>

                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to self soothe when upset</label>
                      <div type="text" readonly>{{detail?.soothe ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{detail?.soothe_description ?? '-'}} </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to transition to new environment or activity</label>
                      <div type="text" readonly>{{detail?.transition ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{detail?.transition_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Happy when not hungry or tired</label>
                      <div type="text" readonly>{{detail?.happy ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{detail?.happy_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Does not require an excessive routine to calm</label>
                      <div type="text" readonly>{{detail?.excessive ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{detail?.excessive_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to be away from parents when with supportive and familiar
                        people</label>
                      <div type="text" readonly>{{detail?.supportive ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{detail?.supportive_description ?? '-'}} </div>
                    </div>

                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Enjoys playing near peers</label>
                      <div type="text" readonly>{{detail?.peers ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{detail?.peers_description ?? '-'}}</div>

                    </div>
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>
      </ng-container>
      <ng-template pTemplate="footer" let-list let-i="rowIndex">
        <div class="row ">
            <div class="col-md">
              <table class="table-stamp w-100 text-start">
                <tr class="row">
                    <td class="col-sm-6 col-lg-3 "><b>Created By:</b> {{ createdby}}</td>
                    <td class="col-sm-6 col-lg-3 "><b>Created
                            Date&Time:</b>{{ createdat.substring(0, 16).replace('T', ' ') }}
                    </td>
                    <td class="col-sm-6 col-lg-3 "><b>Updated By:</b> {{ updatedby}}</td>
                    <td class="col-sm-6 col-lg-3 "><b>Updated
                            Date&Time:</b> {{ updatedat.substring(0, 16).replace('T', ' ') }}
                    </td>
                </tr>
            </table>
            </div>
            <div class="col-md-auto">   <p-button (click)="displayOneToThreeYear=
              false" label="Ok" styleClass="p-button-text"></p-button></div>
        </div>


    </ng-template>
      
    </p-dialog>

    <!-- 3 Years Old and Above -->
    <p-dialog header="Baby Details" [(visible)]="displayThree_year_above" [modal]="true" [style]="{ width: '90vw' }"
        [draggable]="false" [resizable]="false">
      <!-- Start -->
      <ng-container>
        <div class="row viewSummary">
          <div class="col-lg-12">
            <div class="my-profile-box">
              <form>
                <!-- Baby detail -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Name :</label>
                      <div type="text" readonly> {{ babyData?.name ?? '-' | titlecase  }} </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Gender :</label>
                      <div type="text" readonly>{{ babyData?.gender ?? '-' }}</div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Date of Birth :</label>
                      <div type="text" readonly>{{ babyData?.date_of_birth ?? '-' }}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Days :</label>
                      <div type="text" readonly>{{ babyData?.days ?? '-' }}</div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Months :</label>
                      <div type="text" readonly>{{ babyData?.months ?? '-' }}</div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Years :</label>
                      <div type="text" readonly>{{ babyData?.years ?? '-' }}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <h3>Baby Images of Growth Monitoring</h3>
                  <div>
                    <ng-container>
                      <div class="row">
                        <div class="col-lg-4 col-sm-6 col-12" *ngFor="let control of babyImages; index as i;">
                          <div class="card mb-3" style="width: 200px;">
                            <img class="card-img-top" src="{{ImageUrl + control?.name}}" alt="Description of the image">
                            <div class="card-body">
                              <!-- Assuming you have a property called 'date' in your data -->
                              <p class="card-text">Date: {{control?.date| date: 'dd-MM-yyyy'?? '-'}}
                              </p>

                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="row">
                  <h3>Height Weight</h3>
                  <div>
                    <ng-container *ngFor="let control of health_json; index as i;">
                      <div class="row">
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="px-0">Date</label>

                            <div type="text" readonly>
                              {{control?.time |  date: 'dd-MM-yyyy'?? '-'}}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="px-0">Height</label>

                            <div type="text" readonly>
                              {{control?.height ?? '-'}}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                          <div class="form-group">
                            <label class="px-0">Weight</label>

                            <div type="text" readonly>
                              {{control?.weight ?? '-'}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>

                    <div class="card" *ngIf="health_json">
                      <p-chart type="line" [data]="data" [options]="options"></p-chart>
                    </div>
                  </div>
                </div>


                <h3>Coordination & Daily Activities</h3>
                <!-- Enjoys and seeks out various ways to -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label for="">Enjoys and seeks out various ways to
                        move and play</label>
                      <div type="text" readonly>{{detail?.enjoyandplay ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail?.enjoyandplay_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--                             move and play END -->

                <!-- Has adequate endurance and strength
                            to play with peers -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Has adequate endurance and strength
                        to play with peers</label>
                      <div type="text" readonly>{{detail.enduranceandstrength ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.enduranceandstrength_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Has adequate endurance and strength
                            to play with peers END -->

                <!-- Coordinates movements needed to play
                            and explore -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Coordinates movements needed to play
                        and explore</label>
                      <div type="text" readonly>{{detail.coordinateandexplore ?? '-'}} </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.coordinateandexplore_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Coordinates movements needed to play
                            and explore END -->

                <!-- Usually walks with heel toe pattern
                            and not primarily on toes -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Usually walks with heel toe pattern
                        and not primarily on toes</label>
                      <div type="text" readonly>{{detail.healtoewalk ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.healtoewalk_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--                             Usually walks with heel toe pattern
                            and not primarily on toes END -->

                <!-- Can maintain balance to catch ball or
                            when gently bumped by peers -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Can maintain balance to catch ball or
                        when gently bumped by peers</label>
                      <div type="text" readonly>{{detail.balancetocatch ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.balancetocatch_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--                             Can maintain balance to catch ball or
                            when gently bumped by peers END -->

                <!-- Able to walk and maintain balance
                            over uneven surfaces -->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to walk and maintain balance
                        over uneven surfaces</label>
                      <div type="text" readonly>
                        {{detail.balanceonunevensurfaces ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.balanceonunevensurfaces_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Able to walk and maintain balance
                            over uneven surfaces END -->

                <!--Walks through new room without
                            bumping into objects or people-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Walks through new room without
                        bumping into objects or people</label>
                      <div type="text" readonly>
                        {{detail.walkinnewroomnobumping ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.walkinnewroomnobumping_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Walks through new room without
                            bumping into objects or people END -->

                <!--Only leans on furniture, walls or
                            people and sits slumped over when tired-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Only leans on furniture, walls or
                        people and sits slumped over when tired</label>
                      <div type="text" readonly>
                        {{detail.leansoverandsitsslumped ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.leansoverandsitsslumped_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Only leans on furniture, walls or
                            people and sits slumped over when tired END -->

                <!--Able to throw and attempt to catch
                            ball without losing balance-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to throw and attempt to catch
                        ball without losing balance</label>
                      <div type="text" readonly>
                        {{detail.throwandcatchwithbalance ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.throwandcatchwithbalance_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Able to throw and attempt to catch
                            ball without losing balance END -->

                <!--Coordinates both hands to play, such
                            as swinging a bat or opening a container-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Coordinates both hands to play, such
                        as swinging a bat or opening a container</label>
                      <div type="text" readonly>{{detail.bothhandstoplay ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.bothhandstoplay_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Coordinates both hands to play, such
                            as swinging a bat or opening a container END -->

                <!--Coordinates hand and finger movement
                            needed to participate in table top games and
                            activities-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Coordinates hand and finger movement
                        needed to participate in table top games and
                        activities</label>
                      <div type="text" readonly>
                        {{detail.partsingamesandactivities ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.partsingamesandactivities_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Coordinates hand and finger movement
                            needed to participate in table top games and
                            activities END -->

                <!--Able to color and begin to imitate
                            shapes-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to color and begin to imitate
                        shapes</label>
                      <div type="text" readonly>{{detail.colorandshapes ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.colorandshapes_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Able to color and begin to imitate
                            shapes END -->

                <!--Uses appropriate force when playing
                            with peers or pets or when holding objects-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Uses appropriate force when playing
                        with peers or pets or when holding objects</label>
                      <div type="text" readonly>{{detail.usesforcewhenplaying ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.usesforcewhenplaying_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Uses appropriate force when playing
                            with peers or pets or when holding objects END -->

                <!--Able to maintain good sitting posture
                            needed to sit in chair-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to maintain good sitting posture
                        needed to sit in chair</label>
                      <div type="text" readonly>{{detail.goodsittingposture ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.goodsittingposture_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Able to maintain good sitting posture
                            needed to sit in chair END -->

                <!--Able to use utensils to pick up
                            pieces of food-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to use utensils to pick up
                        pieces of food</label>
                      <div type="text" readonly>{{detail.useutensilsforfood ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.useutensilsforfood_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Able to use utensils to pick up
                            pieces of food END -->

                <!--Has an established sleep schedule-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Has an established sleep schedule</label>
                      <div type="text" readonly> {{detail.establishedsleep ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.establishedsleep_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Has an established sleep schedule END -->

                <!--Able to self-calm to fall asleep-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to self-calm to fall asleep</label>
                      <div type="text" readonly>{{detail.selfcalmfallasleep ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.selfcalmfallasleep_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Able to self-calm to fall asleep END -->

                <!--Able to self-calm to fall asleep-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to self-calm to fall asleep</label>
                      <div type="text" readonly> {{detail.selfcalmfallasleep ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.selfcalmfallasleep_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Able to self-calm to fall asleep END -->

                <!--Able to tolerate and wear textures of
                            new and varied articles of clothes-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to tolerate and wear textures of
                        new and varied articles of clothes</label>
                      <div type="text" readonly>{{detail.wearnewclothes ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.wearnewclothes_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Able to tolerate and wear textures of
                            new and varied articles of clothes END -->

                <!--Able to take appropriate bites of
                            food, does not always stuff mouth-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to take appropriate bites of
                        food, does not always stuff mouth</label>
                      <div type="text" readonly>
                        {{detail.appropriatebitesoffood ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.appropriatebitesoffood_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Able to take appropriate bites of
                            food, does not always stuff mouth END -->

                <!--Able to tolerate haircuts and nail
                            cutting without crying-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to tolerate haircuts and nail
                        cutting without crying</label>
                      <div type="text" readonly>
                        {{detail.toleratehairandnailcut ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.toleratehairandnailcut_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Able to tolerate haircuts and nail
                            cutting without crying END -->

                <!--Able to adapt to changes in routine-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to adapt to changes in routine</label>
                      <div type="text" readonly>{{detail.adaptnewroutine ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.adaptnewroutine_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Able to adapt to changes in routine END -->

                <!--Takes bath or shower, although
                            preference may be present-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Takes bath or shower, although
                        preference may be present</label>
                      <div type="text" readonly>{{detail.takesbathandshower ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.takesbathandshower_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Takes bath or shower, although
                            preference may be present END -->

                <!--Eats a diet rich in various foods,
                            temperatures, and textures-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Eats a diet rich in various foods,
                        temperatures, and textures</label>
                      <div type="text" readonly>{{detail.eatsvarietyofdiet ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.eatsvarietyofdiet_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Eats a diet rich in various foods,
                            temperatures, and textures END -->

                <!--Drinks from a cup and straw without
                            dribbling-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Drinks from a cup and straw without
                        dribbling</label>
                      <div type="text" readonly>{{detail.drinkswithoutdribble ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.drinkswithoutdribble_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Drinks from a cup and straw without
                            dribbling END -->

                <!--Need for crashing, bumping and moving
                            fast does not interfere with participation in
                            activities and family life-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Need for crashing, bumping and moving
                        fast does not interfere with participation in
                        activities and family life</label>
                      <div type="text" readonly>{{detail.notactiveinfamily ?? '-'}} </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.notactiveinfamily_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Need for crashing, bumping and moving
                            fast does not interfere with participation in
                            activities and family life END -->

                <!--Able to complete everyday tasks that
                            have multiple steps, such as dressing-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to complete everyday tasks that
                        have multiple steps, such as dressing</label>
                      <div type="text" readonly>
                        {{detail.completemultiplesteptasks ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.completemultiplesteptasks_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Able to complete everyday tasks that
                            have multiple steps, such as dressing END -->

                <!--Frequently wakes up rested and ready
                            for a new day-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Frequently wakes up rested and ready
                        for a new day</label>
                      <div type="text" readonly>{{detail.wakesrestedandready ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.wakesrestedandready_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Frequently wakes up rested and ready
                            for a new day END -->

                <!-- Sensory -->
                <h3>Sensory</h3>
                <!--Can count 1-10-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Can count 1-10</label>
                      <div type="text" readonly>{{detail.cancount ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{detail.cancount_description ?? '-'}}</div>
                    </div>
                  </div>
                </div>
                <!--Can count 1-10 END -->

                <!--Knows shapes-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Knows shapes</label>
                      <div type="text" readonly>{{detail.knowshapes ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.knowshapes_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Knows shapes END -->

                <!--Develops friendships-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Develops friendships</label>
                      <div type="text" readonly>{{detail.developsfriendships ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.developsfriendships_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Develops friendships END -->

                <!--Expresses emotions-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Expresses emotions</label>
                      <div type="text" readonly>{{detail.expressesemotions ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.expressesemotions_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Expresses emotions END -->

                <!--Able to follow rules-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to follow rules</label>
                      <div type="text" readonly>{{detail.followrules ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.followrules_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Able to follow rules END -->

                <!--Able to sit and pay attention (e.g.
                            mat time)-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to sit and pay attention (e.g.
                        mat time)</label>
                      <div type="text" readonly>{{detail.payattention ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.payattention_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Able to sit and pay attention (e.g.
                            mat time) END -->

                <!--20/20 vision-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">20/20 vision</label>
                      <div type="text" readonly>{{detail.vision ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{detail.vision_description ?? '-'}}</div>
                    </div>
                  </div>
                </div>
                <!--20/20 vision END -->

                <!--Able to write numbers and letters-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to write numbers and letters</label>
                      <div type="text" readonly>
                        {{detail.writenumbersandletters ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.writenumbersandletters_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Able to write numbers and letters END -->

                <!--Begins to write simple sentences-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Begins to write simple sentences</label>
                      <div type="text" readonly>{{detail.writesentences ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.writesentences_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Begins to write simple sentences END -->

                <!--Begins to recognise others
                            perspectives-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Begins to recognise others
                        perspectives</label>
                      <div type="text" readonly>
                        {{detail.recogniseothersperspective ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.recogniseothersperspective_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Begins to recognise others
                            perspectives END -->

                <!--Knows left and right-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Knows left and right</label>
                      <div type="text" readonly>{{detail.leftright ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.leftright_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Knows left and right END -->

                <!--Begins to reason and argue; uses
                            words like why and because-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Begins to reason and argue; uses
                        words like why and because</label>
                      <div type="text" readonly>{{detail.reasonandargue ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.reasonandargue_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Begins to reason and argue; uses
                            words like why and because END -->

                <!--Categorizes objects: “These are toys;
                            These are books”-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Categorizes objects: “These are toys;
                        These are books”</label>
                      <div type="text" readonly>{{detail.categoriseobjects ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.categoriseobjects_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Categorizes objects: “These are toys;
                            These are books” END -->

                <!--Understand concepts like yesterday,
                            today, and tomorrow-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Understand concepts like yesterday,
                        today, and tomorrow</label>
                      <div type="text" readonly>
                        {{detail.yesterdaytodaytomorrow ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.yesterdaytodaytomorrow_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Understand concepts like yesterday,
                            today, and tomorrow END -->

                <!--Can copy complex shapes, such as a
                            diamond-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Can copy complex shapes, such as a
                        diamond</label>
                      <div type="text" readonly>{{detail.copycomplexshapes ?? '-'}} </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.copycomplexshapes_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Can copy complex shapes, such as a
                            diamond END -->

                <!--Sounding out simple words like
                            “hang”, “neat”, “jump” and “sank”-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Sounding out simple words like
                        “hang”, “neat”, “jump” and “sank”</label>
                      <div type="text" readonly>{{detail.sayssimplewords ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.sayssimplewords_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Sounding out simple words like
                            “hang”, “neat”, “jump” and “sank” END -->

                <!--Can sit at a desk , follow teacher
                            instructions, and independently do simple in-class
                            assignments-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Can sit at a desk , follow teacher
                        instructions, and independently do simple in-class
                        assignments</label>
                      <div type="text" readonly>{{detail.canattendclass ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.canattendclass_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Can sit at a desk , follow teacher
                            instructions, and independently do simple in-class
                            assignments END -->

                <!--Forms numbers and letters correctly-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Forms numbers and letters correctly</label>
                      <div type="text" readonly>
                        {{detail.formsnumbersandletters ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.formsnumbersandletters_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Forms numbers and letters correctly END -->

                <!--Recognises others perspective-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Recognises others perspective</label>
                      <div type="text" readonly>
                        {{detail.recogniseothersperspective ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.recogniseothersperspective_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Recognises others perspective END -->

                <!--Develops a longer attention span-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Develops a longer attention span</label>
                      <div type="text" readonly>{{detail.longattention ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.longattention_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Develops a longer attention span END -->

                <!--Willing to take on more
                            responsibility (i.e. chores)-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Willing to take on more
                        responsibility (i.e. chores)</label>
                      <div type="text" readonly>
                        {{detail.onemoreresponsibility ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.onemoreresponsibility_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Willing to take on more
                            responsibility (i.e. chores) END -->

                <!--Understands fractions and the concept
                            of space-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Understands fractions and the concept
                        of space</label>
                      <div type="text" readonly>{{detail.fractionandspace ?? '-'}} </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.fractionandspace_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Understands fractions and the concept
                            of space END -->

                <!--Understands money-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Understands money</label>
                      <div type="text" readonly>{{detail.understandmoney ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.understandmoney_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Understands money END -->

                <!--Can tell time-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Can tell time</label>
                      <div type="text" readonly>{{detail.telltime ?? '-'}} </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{detail.telltime_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Can tell time END -->

                <!--Can name months and days of the week
                            in order-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Can name months and days of the week
                        in order</label>
                      <div type="text" readonly>{{detail.monthsdaysinorder ?? '-'}} </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.monthsdaysinorder_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Can name months and days of the week
                            in order END -->

                <!--Enjoys reading a book on their own-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Enjoys reading a book on their own</label>
                      <div type="text" readonly>{{detail.enjoyreadingbook ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.enjoyreadingbook_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Enjoys reading a book on their own END -->
                <h3>Communication</h3>
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                      <div class="form-group">
                          <label class="">Understand more complex language structures; for example, 'why.... Because'</label>
                          <div type="text" readonly>
                              {{detail.understandscomplexlanguage
                          ?? '-'}}
                          </div>
                      </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                      <div class="form-group">
                          <label class="">Remark</label>
                          <div type="text" readonly>
                              {{detail.understandscomplexlanguage_description ?? '-'}}
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                        <label class="">Ask lots of 'what', 'where' and 'why' questions to find out new information</label>
                        <div type="text" readonly>
                            {{detail.findnewinfo
                        ?? '-'}}
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                        <label class="">Remark</label>
                        <div type="text" readonly>
                            {{detail.findnewinfo_description ?? '-'}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                      <label class="">Taking part in longer and more complicated make-believe play sequences with peers; for example, buying a ticket, going on the bus, getting off at the right stop and then getting back on enjoy simple jokes – even though their jokes may not make sense!</label>
                      <div type="text" readonly>
                          {{detail.takingpart
                      ?? '-'}}
                      </div>
                  </div>
              </div>
              <div class="col-xl-6 col-lg-12 col-md-12">
                  <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                          {{detail.takingpart_description ?? '-'}}
                      </div>
                  </div>
              </div>
          </div>
          <div class="row">
            <div class="col-xl-6 col-lg-12 col-md-12">
                <div class="form-group">
                    <label class="">Recognise their own written name                                                                        </label>
                    <div type="text" readonly> {{detail.recogniseownname ??
                    '-'}}</div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12">
                <div class="form-group">
                    <label class="">Remark</label>
                    <div type="text" readonly>
                        {{detail.recogniseownname_description ?? '-'}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
          <div class="col-xl-6 col-lg-12 col-md-12">
              <div class="form-group">
                  <label class="">Know some letter names</label>
                  <div type="text" readonly>
                      {{detail.knowletternames
                  ?? '-'}}
                  </div>
              </div>
          </div>
          <div class="col-xl-6 col-lg-12 col-md-12">
              <div class="form-group">
                  <label class="">Remark</label>
                  <div type="text" readonly>
                      {{detail.knowletternames_description ?? '-'}}
                  </div>
              </div>
          </div>
      </div>
      <div class="row">
        <div class="col-xl-6 col-lg-12 col-md-12">
            <div class="form-group">
                <label class="">Recognise some printed words in the
                    environment; for example, a stop sign</label>
                <div type="text" readonly>{{detail.recognisewordsoutside ??
                '-'}}</div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12">
            <div class="form-group">
                <label class="">Remark</label>
                <div type="text" readonly>
                    {{detail.recognisewordsoutside_description ?? '-'}}
                </div>
            </div>
        </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-lg-12 col-md-12">
          <div class="form-group">
              <label class="">Attempting to write their name</label>
              <div type="text" readonly>{{detail.attemptwritingtherename ??
              '-'}}</div>
          </div>
      </div>
      <div class="col-xl-6 col-lg-12 col-md-12">
          <div class="form-group">
              <label class="">Remark</label>
              <div type="text" readonly>
                  {{detail.attemptwritingtherename_description ?? '-'}}
              </div>

          </div>
      </div>
  </div>
  <div class="row">
      <div class="col-xl-6 col-lg-12 col-md-12">
          <div class="form-group">
              <label class="">Starting to use talking to make friends and to solve problems</label>
              <div type="text" readonly>{{detail.talkingforsolutions ??
              '-'}}</div>
          </div>
      </div>
      <div class="col-xl-6 col-lg-12 col-md-12">
          <div class="form-group">
              <label class="">Remark</label>
              <div type="text" readonly>
                  {{detail.talkingforsolutions_description ?? '-'}}
              </div>

          </div>
      </div>
  </div>
  <div class="row">
      <div class="col-xl-6 col-lg-12 col-md-12">
          <div class="form-group">
              <label class="">Able to talk about what they have done and what they might do; for example, they can tell nana about their trip to the park, then talk about what they want for dinner</label>
              <div type="text" readonly>{{detail.talkaboutdidanddos ??
              '-'}}</div>
          </div>
      </div>
      <div class="col-xl-6 col-lg-12 col-md-12">
          <div class="form-group">
              <label class="">Remark</label>
              <div type="text" readonly>
                  {{detail.talkaboutdidanddos_description ?? '-'}}
              </div>

          </div>
      </div>
  </div>
  <div class="row">
      <div class="col-xl-6 col-lg-12 col-md-12">
          <div class="form-group">
              <label class="">Understood by unfamiliar adults almost all of the time</label>
              <div type="text" readonly>{{detail.unfamiliarpeopleunderstands ??
              '-'}}</div>
          </div>
      </div>
      <div class="col-xl-6 col-lg-12 col-md-12">
          <div class="form-group">
              <label class="">Remark</label>
              <div type="text" readonly>
                  {{detail.unfamiliarpeopleunderstands_description ?? '-'}}
              </div>

          </div>
      </div>
  </div>
                <h3>Feeding</h3>

                <!--Eats the same foods as the rest of
                            the family-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Eats the same foods as the rest of
                        the family</label>
                      <div type="text" readonly>{{detail.eatssameasfamily ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.eatssameasfamily_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Eats the same foods as the rest of
                            the family END -->

                <!--Feeds well with utensils-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Feeds well with utensils</label>
                      <div type="text" readonly>{{detail.feedswellutensils ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.feedswellutensils_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Feeds well with utensils END -->

                <!--Has definite food likes and dislikes
                            & refuse certain foods-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Has definite food likes and dislikes
                        & refuse certain foods</label>
                      <div type="text" readonly>{{detail.likedislikesfood ?? '-'}} </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.likedislikesfood_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Has definite food likes and dislikes
                            & refuse certain foods END -->

                <!--Possibly start to become a “picky
                            eater” (see post next week!)-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Possibly start to become a “picky
                        eater” (see post next week!)</label>
                      <div type="text" readonly> {{detail.pickyeater ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{detail.pickyeater_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Possibly start to become a “picky
                            eater” (see post next week!) END -->

                <!--Wipes own mouth and hands with a
                            napkin or cloth-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Wipes own mouth and hands with a
                        napkin or cloth</label>
                      <div type="text" readonly>{{detail.wipeshandsnmouth ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.wipeshandsnmouth_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Wipes own mouth and hands with a
                            napkin or cloth END -->

                <!--Starts to serve themselves at the
                            table with some spills-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Starts to serve themselves at the
                        table with some spills</label>
                      <div type="text" readonly>{{detail.servesself ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{detail.servesself_description ?? '-'}}</div>
                    </div>
                  </div>
                </div>
                <!--Starts to serve themselves at the
                            table with some spills END -->

                <!--Pours liquids into cup from a small
                            container-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Pours liquids into cup from a small
                        container</label>
                      <div type="text" readonly>{{detail.poursliquid ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly> {{detail.poursliquid_description ?? '-'}}</div>

                    </div>
                  </div>
                </div>
                <!--Pours liquids into cup from a small
                            container END -->

                <h3>Play and Social Skills</h3>

                <!--Interested in, aware of, and able to
                            maintain eye contact with others-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Interested in, aware of, and able to
                        maintain eye contact with others</label>
                      <div type="text" readonly>{{detail.eyecontact ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{detail.eyecontact_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Interested in, aware of, and able to
                            maintain eye contact with others END -->

                <!--Enjoys playing in small groups with
                            children of the same age-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Enjoys playing in small groups with
                        children of the same age</label>
                      <div type="text" readonly>{{detail.playingsamllgroup ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.playingsamllgroup_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Enjoys playing in small groups with
                            children of the same age END -->

                <!--Able to initiate and play with
                            another child of the same age-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to initiate and play with
                        another child of the same age</label>
                      <div type="text" readonly>{{detail.initiateplay ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.initiateplay_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Able to initiate and play with
                            another child of the same age END -->

                <!--Turns head in response to name being
                            called-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Turns head in response to name being
                        called</label>
                      <div type="text" readonly>{{detail.turnsinresponse ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.turnsinresponse_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Turns head in response to name being
                            called END -->

                <!--Interested in exploring varied
                            environments, such as new playground or friend’s
                            house-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Interested in exploring varied
                        environments, such as new playground or friend’s
                        house</label>
                      <div type="text" readonly>{{detail.exploringnew ?? '-'}}"</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.exploringnew_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Interested in exploring varied
                            environments, such as new playground or friend’s
                            house END -->

                <!--Able to play in new social situations-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to play in new social situations</label>
                      <div type="text" readonly>{{detail.newsocialplay ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.newsocialplay_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Able to play in new social situations END -->

                <!--Enjoys playing with variety of toys
                            intended for children of the same age-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Enjoys playing with variety of toys
                        intended for children of the same age</label>
                      <div type="text" readonly>{{detail.playvarietytoys ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.playvarietytoys_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Enjoys playing with variety of toys
                            intended for children of the same age END -->

                <!--Aware of risky and unsafe
                            environments and behavior-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Aware of risky and unsafe
                        environments and behavior</label>
                      <div type="text" readonly>{{detail.aware ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{detail.aware_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Aware of risky and unsafe
                            environments and behavior END -->

                <!--Participates in crafts/activities
                            that involve wet textures, such as glue-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Participates in crafts/activities
                        that involve wet textures, such as glue</label>
                      <div type="text" readonly>{{detail.craftswithglue ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.craftswithglue_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!--Participates in crafts/activities
                            that involve wet textures, such as glue END -->

                <!--Enjoys rough but safe play with
                            peers, siblings, or parents-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Enjoys rough but safe play with
                        peers, siblings, or parents</label>
                      <div type="text" readonly>{{detail.roughsafeplay ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.roughsafeplay_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Enjoys rough but safe play with
                            peers, siblings, or parents END -->

                <!--Enjoys swinging on playground swings-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Enjoys swinging on playground swings</label>
                      <div type="text" readonly>{{detail.swinging ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>{{detail.swinging_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Enjoys swinging on playground swings END -->

                <!--Enjoys playing with new toys-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Enjoys playing with new toys</label>
                      <div type="text" readonly>{{detail.newtoysplay ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.newtoysplay_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Enjoys playing with new toys END -->

                <!--Able to locate objects you are
                            pointing to-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to locate objects you are
                        pointing to</label>
                      <div type="text" readonly>{{detail.locatepointing ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.locatepointing_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Able to locate objects you are
                            pointing to END -->

                <!--Enjoys sitting to look at or listen
                            to a book-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Enjoys sitting to look at or listen
                        to a book</label>
                      <div type="text" readonly>{{detail.enjoysitting ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.enjoysitting_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Enjoys sitting to look at or listen
                            to a book END -->

                <!--Able to play with one toy or theme
                            for 15 minute periods of time-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to play with one toy or theme
                        for 15 minute periods of time</label>
                      <div type="text" readonly>{{detail.playtoyforawhile ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.playtoyforawhile_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Able to play with one toy or theme
                            for 15 minute periods of time END -->

                <!--Able to play with one toy or theme
                            for 15 minute periods of time-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to play with one toy or theme
                        for 15 minute periods of time</label>
                      <div type="text" readonly>{{detail.playtoyforawhile ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.playtoyforawhile_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Able to play with one toy or theme
                            for 15 minute periods of time END -->

                <h3>Self-Expression</h3>
                <!--Happy when not hungry or tired-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Happy when not hungry or tired</label>
                      <div type="text" readonly>{{detail.happywhennothungry ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.happywhennothungry_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Happy when not hungry or tired END -->

                <!--Accustomed to everyday sounds and is
                            usually not startled by them-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Accustomed to everyday sounds and is
                        usually not startled by them</label>
                      <div type="text" readonly>{{detail.accustomedsounds ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.accustomedsounds_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Accustomed to everyday sounds and is
                            usually not startled by them END -->

                <!--Established and reliable sleeping
                            schedule-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Established and reliable sleeping
                        schedule</label>
                      <div type="text" readonly>{{detail.reliablesleeping ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.reliablesleeping_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Established and reliable sleeping
                            schedule END -->

                <!--Able to enjoy a wide variety of
                            touch, noises, and smells-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to enjoy a wide variety of
                        touch, noises, and smells</label>
                      <div type="text" readonly>{{detail.widevariety ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.widevariety_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Able to enjoy a wide variety of
                            touch, noises, and smells END -->

                <!--Cries and notices when hurt-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Cries and notices when hurt</label>
                      <div type="text" readonly>{{detail.crieswhenhurt ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.crieswhenhurt_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Cries and notices when hurt END -->


                <!--Able to calm self down after
                            upsetting event-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to calm self down after
                        upsetting event</label>
                      <div type="text" readonly>{{detail.selfcalmafterupset ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.selfcalmafterupset_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Able to calm self down after
                            upsetting event END -->

                <!--Able to transition to new environment
                            or activity-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to transition to new environment
                        or activity</label>
                      <div type="text" readonly>{{detail.transitiontonewenv ?? '-'}}</div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.transitiontonewenv_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Able to transition to new environment
                            or activity END -->

                <!--Able to pay attention and is not
                            distracted by sounds not noticed by others-->
                <div class="row">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to pay attention and is not
                        distracted by sounds not noticed by others</label>
                      <div type="text" readonly>
                        {{detail.nodistractionbysound ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.nodistractionbysound_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Able to pay attention and is not
                            distracted by sounds not noticed by others END -->

                <!--Able to cope with an unexpected
                            change-->
                <div class="row ">
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Able to cope with an unexpected
                        change</label>
                      <div type="text" readonly>
                        {{detail.copeunexpectedchange ?? '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="">Remark</label>
                      <div type="text" readonly>
                        {{detail.copeunexpectedchange_description ?? '-'}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Able to cope with an unexpected
                            change END -->

              </form>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template pTemplate="footer" let-list let-i="rowIndex">
        <div class="row ">
            <div class="col-md">
              <table class="table-stamp w-100 text-start">
                <tr class="row">
                    <td class="col-sm-6 col-lg-3 "><b>Created By:</b> {{ createdby}}</td>
                    <td class="col-sm-6 col-lg-3 "><b>Created
                            Date&Time:</b>{{ createdat.substring(0, 16).replace('T', ' ') }}
                    </td>
                    <td class="col-sm-6 col-lg-3 "><b>Updated By:</b> {{ updatedby}}</td>
                    <td class="col-sm-6 col-lg-3 "><b>Updated
                            Date&Time:</b> {{ updatedat.substring(0, 16).replace('T', ' ') }}
                    </td>
                </tr>
            </table>
            </div>
            <div class="col-md-auto"> <p-button (click)="displayThree_year_above=
              false" label="Ok" styleClass="p-button-text"></p-button></div>
        </div>
    </ng-template>
    </p-dialog>
  </div>
</div>
<app-copyrights></app-copyrights>