import { Component, OnInit } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { NanyCarePlansService } from 'src/app/services/nany-care-plans.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { TableService } from 'primeng/table';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-nanny-plans',
  templateUrl: './nanny-plans.component.html',
  styleUrls: ['./nanny-plans.component.scss'],
  providers: [MessageService, ConfirmationService, TableService],
})
export class NannyPlansComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Nurse Care Plans',
      subTitle: 'Dashboard',
    },
  ];
  carePlanData: any = {};
  editPlan: any = [];
  newName: any;
  nannyData: any;
  loading: any;
  member: any;
  filterval: any;
  tooltipText: string = 'Nanny Care Plan';
  nannyPlans: FormGroup;
  constructor(
    private nanny_service: NanyCarePlansService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService
  ) { }

  getAllData() {
    this.spinner.show()
    this.nanny_service.getNannyCarePlans().subscribe((res: any) => {
      this.spinner.hide()

      console.log('response', res);
      this.nannyData = res.data;
      console.log('nannyData', res.data);
    }, (error: any) => {
      this.spinner.hide()
      // Handle any errors that occurred during the API call
    
      
    });
  }

  ngOnInit(): void {
    this.getAllData();
  }

  // getInput($event: any) {
  //   this.newName = $event.target.value;
  //   console.log('getInput', this.newName);
  // }

  // addTableData() {
  //   this.nanny_service
  //     .createNannyCarePlans({ heading_name: this.newName })
  //     .then((res: any) => {
  //       this.messageService.add({
  //         severity: 'success',
  //         detail: res?.message,
  //       })
  //       this.newName = '';
  //       console.log('Response from server:', res); // Add this line to log the response
  //       // form.resetForm();
  //       this.getAllData();
  //     })
  //     .catch((error) => {
  //       console.error('Error:', error); // Log the error
  //     });
  // }

  formSubmit(carePlan: NgForm) {
    this.spinner.show()
    console.log(carePlan.valid, carePlan.value);
    if (carePlan.valid) {
      this.nanny_service.createNannyCarePlans( carePlan.value ).then((res: any) => {
        this.spinner.hide()
        this.messageService.add({
          severity: 'success',
          detail: res?.message,
        });
        carePlan.resetForm();
        this.carePlanData = {}
        this.getAllData();
      }).catch((error: HttpErrorResponse) => {
        this.spinner.hide()

        console.log(error);
      })
    }
  }


  /********************************************************************
                Edit Care Plan
********************************************************************/

  editBabyPlanMaster(event: any) {
    // this.editPlan[event.id] = { ...event };
    // console.log('Edit', this.editPlan);
  }

  // onRowEditSave(plan: any) {
  //   console.log('onRowEditSave', plan);
  //   this.loading = true;
  //   if (plan.heading_name != '' && plan.heading_name != null) {
  //     this.nanny_service
  //       .editNannyCarePlans(plan, plan.id)
  //       .subscribe((data: any) => {
  //         this.messageService.add({
  //           severity: 'success',
  //           detail: data?.message,
  //         });
  //         this.loading = false;
  //         this.getAllData();
  //       });
  //   } else {
  //     this.messageService.add({
  //       severity: 'error',
  //       detail: 'Please Fill All the Details!',
  //     });
  //     this.loading = false;
  //   }
  // }

  /********************************************************************
                Delete Care Plan
********************************************************************/

  deleteMaster(plan: any) {
    // this.confirmationService.confirm({
    //   message: 'Do you want to delete this record?',
    //   header: 'Delete Confirmation',
    //   icon: 'pi pi-info-circle',
    //   accept: () => {
    //     this.nanny_service
    //       .deleteNannyCarePlans(plan.id, { is_deleted: 1 })
    //       .subscribe((data: any) => {
    //         this.getAllData();
    //         this.messageService.add({
    //           severity: 'success',
    //           detail: data?.message,
    //         });
    //       });
    //   },
    // });
  }

  reset(dt1:any) {
    dt1.reset();
    this.filterval = '';
  }
}
