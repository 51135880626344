<p-toast></p-toast>
<div class="main-content d-flex flex-column">
    <app-dashboard-navbar></app-dashboard-navbar>
    <div class="breadcrumb-area">
        <div>
            <h1>{{viewDetails[0]?.name | titlecase}}</h1>
        </div>
        <div *ngFor="let Content of breadcrumb">
            <!-- <h1>{{ Content.title }}</h1> -->
            <ol class="breadcrumb">
                <li class="item">
                    <a routerLink="/">Home</a>
                </li>
                <li class="item">
                    {{ Content.subTitle }}
                </li>
                <li class="item">{{ Content.title }}</li>
            </ol>
        </div>
    </div>
    <div class="d-flex justify-content-end mb-2 pe-4 summary-btns">
        <div class=" secondary-btn" style="text-align: right;">
            <a [routerLink]="'/portal/environment-and-child-safety-list'"> <button type="submit">Completed Extra
                    Activites</button> </a>
        </div>
        <div class="ms-2 secondary-btn" style="text-align: right;">
            <a [routerLink]="'/portal/nanny-task-list'"> <button type="submit">Completed Task</button> </a>
        </div>
        <div class=" ms-2 secondary-btn" style="text-align: right;">
            <a [routerLink]="'/portal/health-monitoring-list'"> <button type="submit">Health Monitoring</button> </a>
        </div>
        <!-- <div class=" ms-2 secondary-btn" style="text-align: right;">
            <a [routerLink]="'/portal/growth-monitoring-list'"> <button type="submit">Growth Monitoring</button></a>
        </div> -->
        <div class=" ms-2 secondary-btn" style="text-align: right;">
            <a [routerLink]="'/portal/growth-monitoring-summary'"> <button type="submit">Growth Monitoring</button></a>
        </div>
        <div class=" ms-2 secondary-btn" style="text-align: right;" (click)="fillExtraActivites()">
            <a> <button type="submit">Extra
                    Activites</button></a>
        </div>
        <div class="add-listings-btn  ms-2 " style="text-align: right;">
            <button type="submit" (click)="editOnCLick()" class="px-3 fw-normal">Add Task</button>
        </div>

    </div>
    <ng-container>
        <div class="row">
            <div class="col-lg-12">
                <div class="add-listings-box mb-2">
                    <form>
                        <p-accordion>
                            <p-accordionTab header="Patient Data" class="accordianStyle">
                                <div class="add-listings-btn mt-3">
                                    <p-accordion class="mt-3">
                                        <app-initial-assessment-form></app-initial-assessment-form>
                                    </p-accordion>
                                </div>
                            </p-accordionTab>
                            <p-accordionTab header="Feeding Assesment" class="accordianStyle">
                                <div class=" form-groupviewSummary">
                                    <h3 class="mb-0">Assesment Information Summary</h3>
                                    <div class="row">
                                        <!-- <label class="subHeading my-2">Assesment</label> -->
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group viewSummary">
                                                <label for="">Baby is not interested when offered to breastfeed,
                                                    sleepy:</label>
                                                <!-- <input type="text" readonly class="form-control" value="{{ insideViewData?.answer?? '-'
                            }}" /> -->
                                                <div type="text">{{insideViewData?.answer ?? '-'}}</div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group viewSummary">
                                                <label for="">Baby is showing feeding cues but not
                                                    attaching:</label>
                                                <!-- <input type="text" readonly class="form-control" value="{{ insideViewData?.attach??'-'
                            }}" /> -->
                                                <div type="text">{{insideViewData?.attach ?? '-'}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">

                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group viewSummary">
                                                <label for="">Attaches at the breast but quickly falls
                                                    asleep:</label>

                                                <div type="text">{{insideViewData?.Asleep ?? '-'}}</div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group viewSummary">
                                                <label for="">Attaches for short bursts with long pause:</label>

                                                <div type="text">{{insideViewData?.LongPause ?? '-'}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">

                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group viewSummary">
                                                <label for="">Attaches well with long rhythmical sucking and
                                                    swallowing
                                                    for a short feed:</label>

                                                <div type="text">{{insideViewData?.rhythmical ?? '-'}}</div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group viewSummary">
                                                <label for="">Attaches well for a sustained period with long
                                                    rhythmical
                                                    sucking and swallowing:</label>

                                                <div type="text">{{insideViewData?.swallowing ?? '-'}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">

                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group viewSummary">
                                                <label for="">Normal skin color and tone:</label>

                                                <div type="text">{{insideViewData?.Normal_skin ?? '-'}}</div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group viewSummary">
                                                <label for="">Gaining weight appropriately:</label>

                                                <div type="text">{{insideViewData?.weight ?? '-'}}</div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group viewSummary">
                                                <label for="">Other Concerns:</label>

                                                <div type="text">{{insideViewData?.Other_concerns ?? '-'}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </p-accordionTab>
                            <p-accordionTab header="Daily Cares" class="accordianStyle">

                                <div class="row">
                                    <div class="col-lg-12
                                        col-md-12">
                                        <div class="form-group viewSummary">
                                            <label for="">Prepared By:</label>

                                            <div type="text">
                                                {{
                                                    (insideViewData?.feeding_prepared_mother == 1 ? 'Mother' : '') +
                                                    (insideViewData?.feeding_prepared_mother == 1 &&
                                                    insideViewData?.feeding_prepared_nanny == 1 ? ' and ' : '') +
                                                    (insideViewData?.feeding_prepared_nanny == 1 ? 'Nurse' : '') +
                                                    (insideViewData?.feeding_prepared_other == 1 &&
                                                    (insideViewData?.feeding_prepared_mother == 1 ||
                                                    insideViewData?.feeding_prepared_nanny == 1) ? ', ' : '') +
                                                    (insideViewData?.feeding_prepared_other == 1 ? 'Other' : '')
                                                    }}
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div class="">
                                    <div class="col">
                                        <div class="add-listings-box py-0 my-3">
                                            <h3 class="mb-0 heading-calender dateTable">Milk Feeding of the day
                                                (Select date for data)
                                                <p-calendar placeholder="dd/mm/yyyy " [showTime]="false"
                                                    dateFormat="dd/mm/yy"
                                                    (onSelect)="getDataByDate($event,'feeding')"></p-calendar>
                                            </h3>

                                            <div class="add-listings-box py-0 my-3"
                                                *ngIf="dataOnString == 'feeding' && !error;">
                                                <h3 class="mb-0 heading-calender dateTable">Breast Feeding
                                                    (Subcategory)

                                                </h3>
                                                <ng-container *ngIf="dataOnString == 'feeding' && !error; ">
                                                    <div class="dataBydate">

                                                    <table class="table font-size-13">
                                                        <thead>
                                                            <tr>
                                                                <th>Reference Time</th>
                                                                <th>Actual Time</th>
                                                                <th>Is it Done?</th>
                                                                <th>Remark</th>
                                                                <th>Assign Nurse</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let data of newData?.breast_feeding">
                                                                <td>{{ formatDateTime(data?.feeding_breast_time) }}
                                                                </td>
                                                                <td>{{ formatDateTime(data?.feeding_breast_actual_time) }}
                                                                </td>
                                                                <td>{{data?.feeding_breast_checkbox == '1'? ' Yes' : 'No'}}
                                                                </td>
                                                                <td>{{data?.breast_feeding_remark }}</td>
                                                                <td>{{data?.nurses }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    </div>
                                                </ng-container>
                                                <ng-container #elseBlock>
                                                    <div *ngIf="dataOnString === 'feeding'">
                                                        No data available for the selected date.
                                                    </div>
                                                </ng-container>
                                            </div>

                                            <div class="add-listings-box py-0 my-3"
                                                *ngIf="dataOnString == 'feeding' && !error;">
                                                <h3 class="mb-0 heading-calender dateTable">Formula Feeding
                                                    (Subcategory)

                                                </h3>
                                                <ng-container *ngIf="!error; else elseBlock">
                                                    <div class="dataBydate">

                                                    <table class="table  font-size-13">
                                                        <thead>
                                                            <tr>
                                                                <th>Reference Time</th>
                                                                <th>Actual Time</th>
                                                                <th>Amount</th>
                                                                <th>Is it Done?</th>
                                                                <th>Remarks</th>
                                                                <th>Assign Nurse</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let data of newData?.formula_calculation">
                                                                <td>{{ formatDateTime(data?.formula_timeArray) }}
                                                                </td>
                                                                <td>{{ formatDateTime(data?.nurse_feeding) }}</td>
                                                                <td>{{data?.amount? data?.amount : ' '}}</td>
                                                                <td>{{ data?.feeding_checkbox == '1' ? 'Yes' : 'No' }}
                                                                </td>
                                                                <td>{{ data?.remark? data?.remark : data?.formula_remark }}
                                                                </td>
                                                                <td>{{data?.nurses}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    </div>
                                                </ng-container>
                                                <ng-template #elseBlock>
                                                    <div *ngIf="dataOnString === 'feeding'">
                                                        No data available for the selected date.
                                                    </div>
                                                </ng-template>
                                            </div>


                                            <!-- bottle sterilization start -->

                                            <div class="col" *ngIf="dataOnString == 'feeding' && !error;">
                                                <!-- based on date  -->
                                                <div class="add-listings-box py-0 my-3">
                                                    <h3 class="mb-0 heading-calender dateTable">Bottle Sterilization
                                                        (Subcategory)
                                                    </h3>
                                                    <ng-container *ngIf="!error; else elseBlock">
                                                        <div class="dataBydate">

                                                        <table class="table  font-size-13
                    ">
                                                            <thead>
                                                                <tr>
                                                                    <th>Reference Time</th>
                                                                    <th>Actual Time</th>
                                                                    <th>Is it Done?</th>
                                                                    <th>Remarks</th>
                                                                    <th>Assign Nurse</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let data of newData?.bottle_sterilization">
                                                                    <td>{{ formatDateTime(data?.sterilization_timeArray) }}
                                                                    </td>
                                                                    <td>{{ formatDateTime(data?.actual_sterilization_timeArray)}}
                                                                    </td>
                                                                    <td>{{ data?.done_sterilization == '1'? 'Yes' : 'No' }}
                                                                    </td>
                                                                    <td>{{ data?.sterilization_remark }}</td>
                                                                    <td>{{data?.nurses}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        </div>
                                                    </ng-container>
                                                    <ng-template #elseBlock>
                                                        <div *ngIf="dataOnString === 'feeding'">
                                                            No data available for the selected date.
                                                        </div>
                                                    </ng-template>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 class="mb-0">Feeding Type</h3>
                                    <div class="col">
                                        <!-- based on date  -->


                                        <!-- bottle sterilization end  -->
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="add-listings-box py-0 my-3 peach-bg ">
                                                <h3 class="mb-0">Breast Feeding Time and Amount</h3>
                                                <div class="form-group viewSummary ">

                                                    <div class="col-md-4">
                                                        Breast Feeding is:
                                                        <strong>{{insideViewData?.feeding_breast == 1 ? 'Selected' : 'Not Selected'}}
                                                        </strong>
                                                    </div>
                                                    <div class="col-md-4">, Frequency is:
                                                        <strong>{{insideViewData?.feeding_frequency_breast ?? ' Not filled'}}</strong>
                                                    </div>
                                                    <div class="col-md-4">, remark is:
                                                        <strong>{{insideViewData?.breast_remark ?? ' Not filled'}}</strong>
                                                    </div>
                                                    <!-- <div class="col-md-4">Time of feeding is: <strong>{{ formatDateTime(insideViewData?.feeding_breast_time) ?? 'Not provided' }}</strong></div> -->
                                                </div>

                                                <div class="row">
                                                    <div class="col">
                                                        <label for=""> Breast Feeding Time is</label>
                                                        <div class="col-md-3 form-group"
                                                            *ngFor="let breast of insideViewData?.breast_feeding let index = index">
                                                            <div class="input-group2 ">
                                                                <span class="input-group-text "> {{ index+1 }}. </span>
                                                                <div class="formbox">
                                                                    {{ formatDateTime(breast?.feeding_breast_time)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col">
                                            <div *ngFor="let breast of insideViewData?.breast_feeding">
                                            <div class = "form-group viewSummary">
                                                <div class="col-md-4">
                                                    Breast Feeding Time is:  <strong>{{ formatDateTime(breast?.feeding_breast_time) ?? 'Not provided' }}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                                    <!-- <div class="col-md-4">Breast Feeding Remark: <strong>{{breast?.breast_feeding_remark ?? ' Not filled'}}</strong></div> -->

                                                    <!-- <div class="col-xl-4 col-lg-12
                    col-md-12">
                                            <div class="form-group">
                                                <label for="">Frequency</label>
                                                <input type="text" readonly class="form-control" value="{{ insideViewData?.feeding_frequency_breast??'-'
                        }}" />
                                            </div>
                                        </div> -->

                                                    <!-- <div class="col-xl-4 col-lg-12
                    col-md-12">
                                            <div class="form-group">
                                                <label for="">Time</label>
                                                <input type="text" readonly class="form-control"
                                                    [value]="formatDateTime(insideViewData?.feeding_breast_time)" />


                                            </div>
                                        </div> -->
                                                </div>
                                                <div class="col">

                                                    <!-- <h3 class="mb-0">Breast Feeding Time and Amount</h3> -->
                                                    <div class="dataBydate">

                                                    <table class="table font-size-13">
                                                        <thead>
                                                            <tr>
                                                                <th class="fw-500">Status</th>
                                                                <th class="fw-500">Reference Time</th>
                                                                <th class="fw-500">Actual Time</th>
                                                                <th class="fw-500">Remarks</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let data of insideViewData?.breast_feeding">
                                                                <td>{{ data?.feeding_breast_checkbox == true ? 'Done': 'Not Done' }}
                                                                </td>
                                                                <td>{{ formatDateTime(data?.feeding_breast_time) }}</td>
                                                                <td>{{ formatDateTime(data?.feeding_breast_actual_time) }}
                                                                </td>
                                                                <td>{{ data?.breast_feeding_remark ?? '-' }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="add-listings-box py-0 my-3 peach-bg">
                                                <h3 class="mb-0">Formula Feeding Time and Amount</h3>
                                                <div class="viewSummary">
                                                    <!-- <label for="">Formula Feeding is </label> -->
                                                    <!-- <input type="text" readonly class="form-control" value="{{ insideViewData?.feeding_type== 1? 'Yes': '-' ?? '-'
                            }}" /> -->

                                                    <div class="col-md-4 form-group">
                                                        Formula Feeding is:&nbsp;<strong>
                                                            {{insideViewData?.feeding_type== 1?'Selected' : 'Not Selected'}}
                                                        </strong>
                                                    </div>
                                                    <div class="col-md-4 form-group"
                                                        *ngIf="insideViewData?.feeding_frequency?.frequency">
                                                        , Frequency is:&nbsp;
                                                        <strong>{{ insideViewData.feeding_frequency.frequency }}</strong>
                                                    </div>

                                                    <div class="col-md-4 form-group"
                                                        *ngIf="!insideViewData?.feeding_frequency?.frequency && insideViewData?.feeding_frequency">
                                                        , Frequency is:&nbsp;
                                                        <strong>{{insideViewData?.feeding_frequency?? 'Not filled'}}</strong>
                                                    </div>

                                                    <!-- <div class="col-md-4 form-group">
                                                        , Frequency is:&nbsp;
                                                        <strong>{{insideViewData?.feeding_frequency?? 'Not filled'}}</strong>
                                                    </div> -->
                                                    <div class="col-md-4 form-group">
                                                        , Start Time of feeding is:&nbsp;
                                                        <strong>{{ formatDateTime(insideViewData?.feeding_time) ?? 'Not provided' }}</strong>
                                                    </div>
                                                    <!-- <div class="col-md-4 form-group">
                                                        , End Time of feeding:&nbsp;
                                                        <strong>{{ formatDateTime(insideViewData?.feeding_breast_time_end)?? 'Not provided' }}</strong>
                                                    </div> -->
                                                    <div class="col-md-4 form-group"
                                                        *ngIf="insideViewData?.feeding_breast_time_end">
                                                        , End Time of feeding:&nbsp;
                                                        <strong>{{ formatDateTime(insideViewData.feeding_breast_time_end) }}</strong>
                                                    </div>

                                                    <div class="col-md-4 form-group"
                                                        *ngIf="!insideViewData?.feeding_breast_time_end && insideViewData?.formula_feeding_time">
                                                        , End Time of feeding:&nbsp;
                                                        <strong>{{ formatDateTime(insideViewData.formula_feeding_time) }}</strong>
                                                    </div>

                                                    <div class="col-md-4 form-group">
                                                        , Amount of Feeding:&nbsp;
                                                        <strong>{{ insideViewData?.feeding_amount_per ?? '-'}}</strong>
                                                    </div>
                                                    <div class="col-md-4 form-group">
                                                        , Type of Formula of Feeding:&nbsp;
                                                        <strong>{{ insideViewData?.type_of_formula ?? '-'}}</strong>
                                                    </div>
                                                    <div class="col-md-4 form-group">
                                                        , Remark of Formula of Feeding:&nbsp;
                                                        <strong>{{ insideViewData?.formula_remark ?? '-'}}</strong>
                                                    </div>
                                                </div>
                                                <!-- <div class="add-listings-box py-0 my-3">
                                                <h3 class="mb-0">Formula Feeding Time and Amount </h3>
                                                <div class="row" *ngFor="let data of insideViewData?.formula_calculation">
                                                    <div class="col-xl-4 col-lg-12
                                                        col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Time</label>
                                                            <input type="text" readonly class="form-control"
                                                                [value]="formatDateTime(data?.formula_timeArray)" />
    
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-12
                                                        col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Actual Given Time</label>
                                                            <input type="text" readonly class="form-control"
                                                                [value]="formatDateTime(data?.nurse_feeding)" />
    
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-12
                                                        col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Amount</label>
                                                            <input type="text" readonly class="form-control"
                                                                value="{{ data?.amount?? '-'}}" />
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-12
                                                            col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Name of Formula</label>
                                                            <input type="text" readonly class="form-control"
                                                                value="{{ data?.remark?? '-'}}" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                                <div class="col">
                                                    <div class="dataBydate">


                                                    <table class="table font-size-13">
                                                        <thead>
                                                            <tr>
                                                                <th class="fw-500">Reference Time</th>
                                                                <th class="fw-500">Nurse Feeding Time</th>
                                                                <th class="fw-500">Done or Not</th>
                                                                <th class="fw-500">Amount</th>
                                                                <th class="fw-500">Same Formula</th>
                                                                <th class="fw-500">Name of Formula</th>
                                                                <th class="fw-500">Remark</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="let data of insideViewData?.formula_calculation">
                                                                <td>{{ formatDateTime(data?.formula_timeArray) }}</td>
                                                                <td>{{ formatDateTime(data?.nurse_feeding) }}</td>
                                                                <td>{{ data?.feeding_checkbox == true ? 'Done': 'Not Done' }}
                                                                </td>
                                                                <td>{{ data?.amount ?? '-' }}</td>
                                                                <td>{{ data?.is_same_formula == true ? 'Yes': 'No' }}
                                                                </td>
                                                                <td>{{ data?.remark ?? '-' }}</td>

                                                                <td>{{ data?.formula_remark ?? '-' }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- 
                                        <div class="col-xl-4 col-lg-12
                col-md-12">
                                            <div class="form-group">
                                                <label for="">Frequency</label>
                                                <input type="text" readonly class="form-control" value="{{ insideViewData?.feeding_frequency?.frequency?? '-'
                        }}" />
                                            </div>
                                        </div> -->

                                        <!-- <div class="col-xl-4 col-lg-12
                        col-md-12">
                                            <div class="form-group">
                                                <label for="">Start Time</label>
                                                <input type="text" readonly class="form-control"
                                                    [value]="formatDateTime(insideViewData?.feeding_time)" />
                                            </div>
                                        </div> -->

                                        <!-- <div class="col-xl-4 col-lg-12
                        col-md-12">
                                            <div class="form-group">
                                                <label for="">End Time</label>
                                                <input type="text" readonly class="form-control"
                                                    [value]="formatDateTime(insideViewData?.feeding_breast_time_end)" />
                                            </div>
                                        </div> -->
                                        <!-- <div class="col-xl-3 col-lg-12
                    col-md-12">
                                            <div class="form-group">
                                                <label for="">Amount</label>
                                                <input type="text" readonly class="form-control" value="{{ insideViewData?.feeding_amount_per ?? '-'
                            }}" />
                                            </div>
                                        </div> -->

                                        <!-- <div class="col-xl-3 col-lg-12
                    col-md-12">
                                            <div class="form-group">
                                                <label for="">Type of Formula</label>
                                                <input type="text" readonly class="form-control" value="{{ insideViewData?.type_of_formula ?? '-'
                            }}" />
                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="add-listings-box py-0 my-3 peach-bg">
                                                <h3 class="mb-0"> Bottle Sterilization</h3>
                                                <div class="viewSummary">
                                                    <!-- <label for="">Formula Feeding is </label> -->
                                                    <!-- <input type="text" readonly class="form-control" value="{{ insideViewData?.feeding_type== 1? 'Yes': '-' ?? '-'
                            }}" /> -->
                                                    <div class="col-md-8 form-group">
                                                        <label for="">All:</label> <strong>{{
                                    insideViewData?.bottle_sterilization_all== 1? 'Yes':
                                                                '-' ?? '-'}}</strong>
                                                    </div>
                                                    <div class="col-md-8 form-group">
                                                        <label for="">, Bottle Sterilization All at a Time:</label>
                                                        <strong>{{
                                    formatDateTime(insideViewData?.bottle_sterilization_all_time)}}</strong>
                                                    </div>




                                                </div>
                                                <div class="dataBydate">

                                                    <table class="table font-size-13">
                                                        <thead>
                                                            <tr>
                                                                <th class="fw-500">Reference Time</th>
                                                                <th class="fw-500">Actual Time</th>
                                                                <th class="fw-500">Done Or Not</th>
                                                                <th class="fw-500">Remark</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                               >
                                                                <td>{{ formatDateTime(insideViewData?.bottle_sterilization_all_time) }}
                                                                </td>
                                                                <td>{{ formatDateTime(insideViewData?.actual_sterilization_all_time) }}
                                                                </td>
                                                                <td>{{ insideViewData?.done_sterilization_onetime == true ? 'Done': 'Not Done' }}
                                                                </td>
                                                                <td>{{ insideViewData?.onetime_Remark ?? '-' }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    </div>
                                                <div class="col">
                                                    <div class="viewSummary">
                                                        <div class="col-md-8 form-group">
                                                            <label for="">, One At A time:</label> <strong>{{
                                       insideViewData?.bottle_sterilization_onetime== 1? 'Yes':
                                                                    '-' ?? '-'}}</strong>
                                                        </div>
                                                        <div class="col-md-8 form-group">

                                                            <label for="">Bottle Sterilization One at a Time:</label>
                                                        </div>
                                                    </div>
                                                    <div class="dataBydate">

                                                    <table class="table font-size-13">
                                                        <thead>
                                                            <tr>
                                                                <th class="fw-500">Reference Time</th>
                                                                <th class="fw-500">Actual Time</th>
                                                                <th class="fw-500">Done Or Not</th>
                                                                <th class="fw-500">Remark</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="let data of insideViewData?.bottle_sterilization">
                                                                <td>{{ formatDateTime(data?.sterilization_timeArray) }}
                                                                </td>
                                                                <td>{{ formatDateTime(data?.actual_sterilization_timeArray) }}
                                                                </td>
                                                                <td>{{ data?.done_sterilization == true ? 'Done': 'Not Done' }}
                                                                </td>
                                                                <td>{{ data?.remark ?? '-' }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </p-accordionTab>
                            <!-- DAILY CARE END-->
                            <p-accordionTab header="Bathing" class="accordianStyle">
                                <div class="col">
                                    <div class="add-listings-box py-0 my-3">
                                        <h3 class="mb-0 heading-calender">Previous Bathing data (Select a date to
                                            retrieve previous data.)
                                            <p-calendar placeholder="dd/mm/yyyy " [showTime]="false"
                                                (onSelect)="getDataByDate($event , 'bath')"></p-calendar>
                                        </h3>
                                        <ng-container *ngIf="dataOnString == 'bath' && !error; ">
                                            <div class="dataBydate">

                                            <table class="table  font-size-13
                    ">
                                                <thead>
                                                    <tr>
                                                        <th>Type</th>
                                                        <th>Reference Time</th>
                                                        <th>Actual Type</th>
                                                        <th>Is it Done?</th>
                                                        <th>Remarks</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let data of newData?.shower_bathing">
                                                        <td>Shower</td>
                                                        <td>{{ formatDateTime(data?.bathing_shower_time) }}</td>
                                                        <td>{{ formatDateTime(data?.bathing_shower_actual_time) }}</td>
                                                        <td>{{ data?.shower_checkbox == '1' ? 'Yes' : 'No' }}</td>
                                                        <td>{{ data?.shower_remark}}</td>
                                                    </tr>
                                                    <tr *ngFor="let data of newData?.tub_bathing">
                                                        <td>Tub</td>
                                                        <td>{{ formatDateTime(data?.bathing_tub_time) }}</td>
                                                        <td>{{ formatDateTime(data?.bathing_tub_actual_time) }}</td>
                                                        <td>{{ data?.tub_checkbox == '1' ? 'Yes' : 'No' }}</td>
                                                        <td>{{ data?.tubBathing_remark}}</td>
                                                    </tr>
                                                    <tr *ngFor="let data of newData?.bathing_time">
                                                        <td>Sponge</td>
                                                        <td>{{ formatDateTime(data?.bathing_sponge_time) }}</td>
                                                        <td>{{ formatDateTime(data?.bathing_sponge_actual_time) }}</td>
                                                        <td>{{ data?.sponge_checkbox == '1' ? 'Yes' : 'No' }}</td>
                                                        <td>{{ data?.spongeBathing_remark}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="error">
                                            <div *ngIf="dataOnString === 'bath'">
                                                No data available for the selected date.
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="row ">
                                    <div class="col">
                                        <div class="add-listings-box py-0 my-3 peach-bg">
                                            <h3 class="mb-0">Tub Bath</h3>
                                            <div class="col">
                                                <div class="viewSummary " id="bathing">
                                                    <div class="col-md-8 form-group">
                                                        <label for="">Tub:</label> <strong>{{
                                   insideViewData?.bathing_tub== 1? 'Yes':
                                                                '-' ?? '-'}}</strong>
                                                    </div>
                                                    <div class="col-md-8 form-group">

                                                        <label for="">Time of Tub Bath:</label>
                                                    </div>
                                                </div>
                                                <div class="dataBydate">

                                                <table class="table font-size-13">
                                                    <thead>
                                                        <tr>
                                                            <th class="fw-500">Reference Time</th>
                                                            <th class="fw-500">Actual Time</th>
                                                            <th class="fw-500">Done Or Not</th>
                                                            <th class="fw-500">Remark</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let data of insideViewData?.tub_bathing">
                                                            <td>{{ formatDateTime(data?.bathing_tub_time) }}
                                                            </td>
                                                            <td>{{ formatDateTime(data?.bathing_tub_actual_time) }}
                                                            </td>
                                                            <td>{{ data?.tub_checkbox == true ? 'Done': 'Not Done' }}
                                                            </td>
                                                            <td>{{ data?.tubBathing_remark ?? '-' }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="add-listings-box py-0 my-3 peach-bg">
                                            <h3 class="mb-0">Sponge</h3>
                                            <div class="col">
                                                <div class="viewSummary" id="bathing" >
                                                    <div class="col-md-8 form-group" >
                                                        <label for="">Sponge:</label> <strong>{{
                                   insideViewData?.bathing_sponge== 1? 'Yes':
                                                                '-' ?? '-'}}</strong>
                                                    </div>
                                                    <div class="col-md-8 form-group">

                                                        <label for="">Time of Sponge Bath:</label>
                                                    </div>
                                                </div>

                                                <div class="dataBydate">

                                                <table class="table font-size-13">
                                                    <thead>
                                                        <tr>
                                                            <th class="fw-500">Reference Time</th>
                                                            <th class="fw-500">Actual Time</th>
                                                            <th class="fw-500">Done Or Not</th>
                                                            <th class="fw-500">Remark</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let data of insideViewData?.bathing_time">
                                                            <td>{{ formatDateTime(data?.bathing_sponge_time) }}
                                                            </td>
                                                            <td>{{ formatDateTime(data?.bathing_sponge_actual_time) }}
                                                            </td>
                                                            <td>{{ data?.sponge_checkbox == true ? 'Done': 'Not Done' }}
                                                            </td>
                                                            <td>{{ data?.spongeBathing_remark ?? '-' }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="add-listings-box py-0 my-3 peach-bg">
                                            <h3 class="mb-0">Shower</h3>
                                            <div class="col">

                                                <div class="dataBydate">

                                                <table class="table font-size-13">
                                                    <thead>
                                                        <tr>
                                                            <th class="fw-500">Reference Time</th>
                                                            <th class="fw-500">Actual Time</th>
                                                            <th class="fw-500">Done Or Not</th>
                                                            <th class="fw-500">Remark</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let data of insideViewData?.shower_bathing">
                                                            <td>{{ formatDateTime(data?.bathing_shower_time) }}
                                                            </td>
                                                            <td>{{ formatDateTime(data?.bathing_shower_actual_time) }}
                                                            </td>
                                                            <td>{{ data?.shower_checkbox == true ? 'Done': 'Not Done' }}
                                                            </td>
                                                            <td>{{ data?.shower_remark ?? '-' }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>


                            </p-accordionTab>

                            <p-accordionTab header="Food/Medicine Allergy"
                                [class.allergyhighlight]="insideViewData?.food_medicine_allergy == '1'">

                                <div class="row peach-bg">
                                    <div class=" col-lg-12-md-12 my-3">

                                        <ng-container>
                                            <div class="form-group viewSummary">
                                                <label for="">Is their Food/Medicine Allergy to Baby:</label>
                                                <div type="text"
                                                    *ngIf="insideViewData?.food_medicine_allergy !== null && insideViewData?.food_medicine_allergy !== '' && insideViewData?.food_medicine_allergy !== undefined; else elseBlock">
                                                    {{ insideViewData?.food_medicine_allergy == '1'? 'Yes': 'No'}}
                                                </div>
                                                <ng-template #elseBlock>
                                                    <div>-</div>
                                                </ng-template>
                                            </div>
                                        </ng-container>
                                    </div>




                                    <div class="form-group viewSummary">
                                        <label for="">Prevent it Food/Medicine Allergy :</label>
                                        <div type="text">
                                            {{ insideViewData?.food_medicine_allergy_specify_prod ?? '-'}}
                                        </div>
                                    </div>


                                </div>

                            </p-accordionTab>

                            <!-- BATHING END -->

                            <!-- diaper change -->
                            <p-accordionTab header="Diaper Frequency" class="accordianStyle">

                                <div class="col">
                                    <div class="add-listings-box py-0 ">
                                        <h3 class="mb-0 heading-calender">Previous Diaper Change Times (Select a date to
                                            retrieve previous data.)
                                            <p-calendar placeholder="dd/mm/yyyy " [showTime]="false"
                                                (onSelect)="getDataByDate($event , 'diaper')"></p-calendar>
                                        </h3>

                                        <div class="add-listings-box py-0 my-3"
                                            *ngIf="dataOnString == 'diaper' && !error;">

                                            <ng-container *ngIf="dataOnString == 'diaper' && !error; ">
                                                <h3 class="mb-0 heading-calender dateTable"> Additional Diaper change in
                                                    a Day

                                                </h3>
                                                <div class="dataBydate">

                                                <table class="table  font-size-13">
                                                    <thead>
                                                        <tr
                                                            *ngIf="newData?.diaper_change_times && newData?.diaper_change_times.length > 0">
                                                            <th>Time</th>
                                                            <th>Is it Done?</th>
                                                            <th>Remark</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let data of newData?.diaper_change_times; let index = index">
                                                            <td>{{ formatDateTime(data?.time) }}</td>
                                                            <td>{{ data?.change == '1' ? 'Yes' : 'No' }}</td>
                                                            <td>{{ data?.diaper_remark }}</td>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="error">
                                                <div *ngIf="dataOnString === 'diaper'">
                                                    No data available for the selected date.
                                                </div>
                                            </ng-container>
                                        </div>

                                        <div class="add-listings-box py-0 my-3"
                                            *ngIf="dataOnString == 'diaper' && !error;">

                                            <ng-container>
                                                <h3 class="mb-0 heading-calender dateTable"> Diaper change in a Day

                                                </h3>
                                                <div class="dataBydate">

                                                <table class="table  font-size-13">
                                                    <thead>
                                                        <tr
                                                            *ngIf="newData?.diaper_calculation && newData?.diaper_calculation.length > 0">
                                                            <th>Time</th>
                                                            <th>Actual Time</th>
                                                            <th>Is it Done?</th>
                                                            <th>Remark</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let data of newData?.diaper_calculation; let index = index">
                                                            <td>{{ formatDateTime(data?.diaper_timeArray) }}</td>
                                                            <td>{{ formatDateTime(data?.diaper_actual_time) ?? '-' }}
                                                            </td>
                                                            <td>{{ data?.diaper_checkbox == '1' ? 'Yes' : 'No' }}</td>
                                                            <td>{{ data?.diaperRemark }}</td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                            </ng-container>
                                        </div>




                                    </div>
                                </div>
                                <br>
                                <div>
                                    <div class=" col-lg-12
         col-md-12">
                                        <div class="form-group viewSummary">
                                            <label for="">Diaper Frequency(in every hours): </label>
                                            <div>
                                                <strong>{{ insideViewData?.diaper_change_frequency?? '-' }}</strong>
                                            </div>
                                        </div>

                                        <div class="add-listings-box py-0 my-3 ">
                                            <h3 class="mb-0 heading-calender dateTable ">Diaper Change:</h3>
                                            <div class="dataBydate">

                                            <table class="table  font-size-13 ">
                                                <thead>
                                                    <tr>
                                                        <th>Diaper Change Reference Time</th>
                                                        <th>Diaper Change Actual Time</th>
                                                        <th>Done or Not</th>
                                                        <th>Diaper Change Remarks</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let change of insideViewData?.diaper_calculation">
                                                        <td>{{ formatDateTime(change?.diaper_timeArray) ?? '-' }}</td>
                                                        <td>{{ formatDateTime(change?.diaper_actual_time) ?? '-' }}</td>
                                                        <td>{{ change?.isDiaperChanged ? 'Yes' : 'No' }}</td>
                                                        <td>{{ change?.diaperRemark || '-' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>



                                        </div>




                                        <div class="add-listings-box py-0 my-3 ">
                                            <h3 class="mb-0 heading-calender dateTable ">Addtional Diaper Change: </h3>
                                            <ng-container>

                                                <div class="dataBydate">


                                                <table class="table  font-size-13 ">
                                                    <thead>
                                                        <tr>
                                                            <th>Diaper Change Time</th>
                                                            <th>Done or Not</th>
                                                            <th>Diaper Change Remarks</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let change of insideViewData?.diaper_change_times">
                                                            <td>{{ formatDateTime(change?.time) ?? '-'}}</td>
                                                            <td>{{ change?.change == true ? 'Done': 'Not Done' }}
                                                            </td>
                                                            <td>{{ change?.diaper_remark|| '-'}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                </div>



                                            </ng-container>
                                        </div>
                                    </div>



                                </div>

                            </p-accordionTab>
                            <!-- Skin Cares -->
                            <p-accordionTab header="Skin Care" class="accordianStyle">
                                <div class="col">
                                    <div class="add-listings-box py-0 my-3">
                                        <h3 class="mb-0 heading-calender">Previous Skin care of the day data (Select a
                                            date to retrieve previous data.)
                                            <p-calendar placeholder="dd/mm/yyyy " [showTime]="false"
                                                (onSelect)="getDataByDate($event , 'skinCare')"></p-calendar>
                                        </h3>
                                        <ng-container *ngIf="dataOnString == 'skinCare' && !error;">
                                            <div class="add-listings-box py-0 my-3">
                                                <h3 class="mb-0 heading-calender">Skin care of the day </h3>
                                                <div class="dataBydate">

                                                <table class="table  font-size-13
" *ngIf="newData?.skin_care">
                                                    <thead>
                                                        <tr>
                                                            <th>Type</th>
                                                            <th>Is it Done?</th>
                                                            <th>Product</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let data of newData?.skin_care">
                                                            <td>{{ data?.skin_care_baby_oil == '1' ? 'Oil' : '' }}
                                                                {{ data?.skin_care_lotion == '1' ? 'Lotion' : '' }}
                                                                {{ data?.skin_care_others == '1' ? ',other' : '' }}
                                                            </td>
                                                            <td>{{ data?.skin_care_baby_oil == '1' ? 'Yes' : '' }}
                                                                {{ data?.skin_care_lotion == '1' ? 'Yes' : '' }}
                                                                {{ data?.skin_care_others == '1' ? ',Yes' : '' }}
                                                            </td>
                                                            <td>{{ data?.skin_care_specify_produ }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                            </div>


                                        </ng-container>
                                        <ng-container *ngIf="error">
                                            <div *ngIf="dataOnString === 'skinCare'">
                                                No data available for the selected date.
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class=" ">
                                    <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                    <h3>Skin Cares</h3>
                                    <div class="row" *ngFor="let care of insideViewData?.skin_care">


                                        <!-- Lotion -->
                                        <div class=" col-lg-4
                  ">
                                            <div class="form-group viewSummary">
                                                <label for="">Skin care product for baby:</label>
                                                <div type="text"> {{
                                                (care?.skin_care_lotion == 1 ? 'Lotion' : '') +
                                                (care?.skin_care_lotion == 1 &&
                                                care?.skin_care_baby_oil == 1 ? ' and ' : '') +
                                                (care?.skin_care_baby_oil == 1 ? 'Baby Oil' : '') +
                                                (care?.skin_care_others == 1 &&
                                                (care?.skin_care_lotion == 1 ||
                                                care?.skin_care_baby_oil == 1) ? ', ' : '') +
                                                (care?.skin_care_others == 1 ? 'Others' : '')
                                                }}</div>

                                            </div>
                                        </div>



                                        <div class="col-lg-4
                     ">
                                            <div class="form-group viewSummary">

                                                <label for="">Specify Product use for baby:</label>
                                                <div type="text">
                                                    {{ care?.skin_care_specify_produ ?care?. skin_care_specify_produ:'Not filled'}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </p-accordionTab>
                            <!-- Skin Cares End-->
                            <!-- Solid food Feeding -->
                            <p-accordionTab header="Solid Food Feeding" class="accordianStyle">
                                <div class="col">
                                    <!-- based on date  -->
                                    <div class="add-listings-box py-0 ">
                                        <h3 class="mb-0 heading-calender dateTable">Previous Solid Food data (Select a
                                            date to
                                            retrieve previous data.)
                                            <p-calendar placeholder="dd/mm/yyyy " [showTime]="false"
                                                (onSelect)="getDataByDate($event , 'solidFood')"></p-calendar>
                                        </h3>
                                        <ng-container *ngIf="dataOnString == 'solidFood' &&!error;">
                                            <div class="dataBydate">

                                            <table class="table  font-size-13
                    ">
                                                <thead>
                                                    <tr>
                                                        <th>Type</th>
                                                        <th>Reference Time</th>
                                                        <th> Actual Time</th>

                                                        <th>Is it Done?</th>
                                                        <th>Remarks</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngIf="newData?.solid_other_snack == '1'">
                                                        <td>Snacks</td>
                                                        <td>{{ formatDateTime(newData?.solid_other_snack_time) }}</td>
                                                        <td>{{ formatDateTime(newData?.solid_other_snack_actual_time) }}
                                                        </td>
                                                        <td>{{ newData?.solid_other_snack == '1' ? 'Yes' : 'No' }}</td>
                                                        <td>{{ newData?.soild_other_snack_food_components }}</td>
                                                    </tr>
                                                    <tr
                                                        *ngFor="let breakfast of newData?.solid_food ? newData?.solid_food :newData?.regular_food  ">
                                                        <td>Breakfast</td>
                                                        <td>{{ formatDateTime(breakfast?.regular_meal_time) }}</td>
                                                        <td>{{ formatDateTime(breakfast?.regular_meal_actual_time) }}
                                                        </td>
                                                        <td>{{ breakfast.regular_meal_type == '1' ? 'Yes' : 'No' }}</td>
                                                        <td>{{ breakfast?.regular_meal_food_components }}</td>
                                                    </tr>
                                                    <tr *ngFor="let lunch of newData?.lunch_food">
                                                        <td>Lunch</td>
                                                        <td>{{ formatDateTime(lunch?.regular_meal_lunch_time) }}</td>
                                                        <td> {{formatDateTime(lunch?.regular_meal_lunch_actual_time)}}
                                                        </td>
                                                        <td>{{ lunch.regular_meal_lunch == '1' ? 'Yes' : 'No' }}</td>
                                                        <td>{{ lunch?.regular_meal_lunch_food_components }}</td>
                                                    </tr>
                                                    <tr *ngFor="let dinner of newData?.dinner_food">
                                                        <td>Dinner</td>
                                                        <td>{{ formatDateTime(dinner?.regular_meal_dinner_time) }}</td>
                                                        <td>{{ formatDateTime(dinner?.regular_meal_dinner_actual_time ) }}
                                                        </td>
                                                        <td>{{ dinner.regular_meal_dinner == '1' ? 'Yes' : 'No' }}</td>
                                                        <td>{{ dinner?.regular_meal_dinner_food_components }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="error">
                                            <div *ngIf="dataOnString === 'solidFood'">
                                                No data available for the selected date.
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <br>
                                <div class="">
                                    <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->


                                    <!-- Regular Meal -->
                                    <div class="row" *ngFor="let food of insideViewData?.solid_food ">
                                        <!-- Breakfast -->
                                        <div class="col-md-4">
                                            <div class="form-group viewSummary">
                                                <label for>Breakfast</label>
                                                <!-- <input type="text" readonly class="form-control" value="{{ food?.regular_meal_type == 1? 'Yes': '-'?? '-'
                    }}" /> -->

                                                <div type="text" readonly>{{ food?.regular_meal_type == 1?
                        'Yes': '-'?? '-'
                        }}</div>
                                            </div>
                                        </div>


                                        <div class="col-md-4">
                                            <div class="form-group viewSummary">
                                                <label for>Food Component</label>
                                                <!-- <input type="text" readonly class="form-control" value="{{ food?.regular_meal_food_components ?  food?.regular_meal_food_components:  '-'
                    }}" /> -->

                                                <div type="text" readonly>{{
                        food?.regular_meal_food_components ?
                        food?.regular_meal_food_components: '-'
                        }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngFor="let food of insideViewData?.solid_food  let index = index">
                                        <div class="col-md-4">
                                            <div class="form-group viewSummary">
                                                <label for>Reference Time</label>
                                                <!-- <input type="text" readonly class="form-control" value="{{ food?.regular_meal_time ? (food?.regular_meal_time | date: 'h:mm:ss' ): '-'
                                    }}" /> -->
                                                <div class="input-group2 ">
                                                    <span class="input-group-text "> {{ index+1 }}. </span>
                                                    <div class="formbox">
                                                        {{ formatDateTime(food?.regular_meal_time)}}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div class="col add-listings-box py-0 my-3 ">
                                        <h3 class="mb-0 heading-calender dateTable ">Breakfast</h3>
                                        <div class="dataBydate">

                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>
                                                    <th class="fw-500">Done or Not</th>
                                                    <th class="fw-500">Reference Time</th>
                                                    <th class="fw-500">Actual Time</th>
                                                    <th class="fw-500">Food Component</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let food of insideViewData?.solid_food; let index = index">
                                                    <td>{{ food?.regular_meal_type == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ formatDateTime(food?.regular_meal_time) }}</td>
                                                    <td>{{ formatDateTime(food?.regular_meal_actual_time) }}</td>
                                                    <td>{{ food?.regular_meal_food_components ? food?.regular_meal_food_components : '-' }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>

                                    <!-- Lunch -->
                                    <div class="row" *ngFor="let food of insideViewData?.lunch_food">
                                        <div class="col-md-4">
                                            <div class="form-group viewSummary">
                                                <label for>Lunch</label>
                                                <!-- <input type="text" readonly class="form-control" value="{{ food?.regular_meal_lunch== 1? 'Yes': '-' ?? '-'
                    }}" /> -->

                                                <div type="text" readonly>{{ food?.regular_meal_lunch== 1?
                        'Yes': '-' ?? '-'
                        }}</div>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group viewSummary">
                                                <label for>Food Component</label>
                                                <!-- <input type="text" readonly class="form-control" value="{{  food?.regular_meal_lunch_food_components ?  food?.regular_meal_lunch_food_components:'-'
                    }}" /> -->
                                                <div type="text" readonly>{{
                        food?.regular_meal_lunch_food_components ?
                        food?.regular_meal_lunch_food_components:'-'
                        }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngFor="let food of insideViewData?.lunch_food; let index = index">
                                        <div class="col-md-4">
                                            <div class="form-group viewSummary">
                                                <label for>Reference Time</label>
                                                <div class="input-group2">
                                                    <span class="input-group-text">{{ index + 1 }}.</span>
                                                    <div class="formbox">
                                                        {{ formatDateTime(food?.regular_meal_lunch_time)}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="col add-listings-box py-0 my-3 ">
                                        <h3 class="mb-0 heading-calender dateTable ">Lunch</h3>
                                        <div class="dataBydate">

                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>
                                                    <th class="fw-500">Done or Not</th>
                                                    <th class="fw-500">Reference Time</th>
                                                    <th class="fw-500">Actual Time</th>
                                                    <th class="fw-500">Food Component</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let food of insideViewData?.lunch_food; let index = index">
                                                    <td>{{ food?.regular_meal_lunch == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ formatDateTime(food?.regular_meal_lunch_time) }}</td>
                                                    <td>{{ formatDateTime(food?.regular_meal_lunch_actual_time) }}</td>
                                                    <td>{{ food?.regular_meal_lunch_food_components ? food?.regular_meal_lunch_food_components : '-' }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>

                                    <!-- Snack -->
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group viewSummary">
                                                <label for>Snack</label>
                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.solid_other_snack== 1? 'Yes': '-' ?? '-'
                    }}" /> -->

                                                <div type="text" readonly>{{
                        insideViewData?.solid_other_snack== 1? 'Yes': '-' ??
                        '-'
                        }}</div>

                                            </div>
                                        </div>



                                        <div class="col-md-4">
                                            <div class="form-group viewSummary">
                                                <label for>Food Component</label>
                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.soild_other_snack_food_components ?? '-'
                    }}" /> -->

                                                <div type="text" readonly>{{
                        insideViewData?.soild_other_snack_food_components ??
                        '-'
                        }}</div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group viewSummary">
                                                <label for>Reference Time</label>
                                                <!-- <input type="text" readonly class="form-control"
                                                                    [value]="formatDateTime(dataonzeroposition?.solid_other_snack_time)" /> -->

                                                <div type="text" readonly>{{
                                        formatDateTime(insideViewData?.solid_other_snack_time)
                                        }}</div>

                                            </div>
                                        </div>

                                    </div>
                                    <div class="col add-listings-box py-0 my-3 ">
                                        <h3 class="mb-0 heading-calender dateTable ">Snacks</h3>
                                        <div class="dataBydate">

                                        <table class="table font-size-13">

                                            <thead>

                                                <tr>
                                                    <th class="fw-500">Done Or Not</th>
                                                    <th class="fw-500">Reference Time</th>
                                                    <th class="fw-500">Actual Time</th>
                                                    <th class="fw-500">Food Component</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{{ insideViewData?.solid_other_snack == 1 ? 'Yes' : '-' }}
                                                    </td>
                                                    <td>{{ formatDateTime(insideViewData?.solid_other_snack_time) }}
                                                    </td>
                                                    <td>{{ formatDateTime(insideViewData?.solid_other_snack_actual_time) }}
                                                    </td>
                                                    <td>{{ insideViewData?.soild_other_snack_food_components??'-'}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                    <!-- Dinner -->
                                    <div class="row" *ngFor="let food of insideViewData?.dinner_food">
                                        <div class="col-md-4">
                                            <div class="form-group viewSummary">
                                                <label for>Dinner</label>

                                                <div type="text" readonly>{{ food?.regular_meal_dinner== 1?
                        'Yes': '-' ?? '-'
                        }}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group viewSummary">
                                                <label for>Food Component</label>

                                                <div type="text" readonly>{{
                        food?.regular_meal_dinner_food_components ?
                        food?.regular_meal_dinner_food_components: '-'
                        }}</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row"
                                        *ngFor="let food of insideViewData?.dinner_food; let index = index">
                                        <div class="col-md-4">
                                            <div class="form-group viewSummary">
                                                <label for>Reference Time</label>
                                                <div class="input-group2">
                                                    <span class="input-group-text">{{ index + 1 }}.</span>
                                                    <div class="formbox">
                                                        {{ formatDateTime(food?.regular_meal_dinner_time) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="col add-listings-box py-0 my-3 ">
                                        <h3 class="mb-0 heading-calender dateTable ">Dinner</h3>
                                        <div class="dataBydate">

                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>
                                                    <th class="fw-500">Done or Not</th>
                                                    <th class="fw-500">Reference Time</th>
                                                    <th class="fw-500">Actual Time</th>
                                                    <th class="fw-500">Food Component</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let food of insideViewData?.dinner_food; let index = index">
                                                    <td>{{ food?.regular_meal_dinner == 1 ? 'Yes' : '-' }}</td>
                                                    <td>{{ formatDateTime(food?.regular_meal_dinner_time) }}</td>
                                                    <td>{{ formatDateTime(food?.regular_meal_dinner_actual_time) }}</td>
                                                    <td>{{ food?.regular_meal_dinner_food_components ? food?.regular_meal_dinner_food_components : '-' }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </div>



                            </p-accordionTab>

                            <!-- Solid food feeding End -->
                            <!-- Wipes -->
                            <p-accordionTab header="Wipes" class="accordianStyle">


                                <div class="row ">
                                    <div class="col-xl-4 col-lg-12
                   col-md-12 my-3">

                                        <ng-container>
                                            <div class="form-group viewSummary">
                                                <label for="">Wipes:</label>
                                                <div type="text"
                                                    *ngIf="insideViewData?.skin_care_Wipes !== null && insideViewData?.skin_care_Wipes !== ''  && insideViewData?.skin_care_Wipes !== undefined; else elseBlock">
                                                    {{ insideViewData?.skin_care_Wipes == '1'? 'Yes': 'No'}}
                                                </div>

                                                <ng-template #elseBlock>
                                                    <!-- <input type="text" readonly class="form-control" value="-" /> -->
                                                    <div>-</div>
                                                </ng-template>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div class="col-xl-4 col-lg-12
                      col-md-12  my-3">
                                        <div class="form-group viewSummary">
                                            <label>Special Instruction for wipes:</label>
                                            <div type="text"> {{ insideViewData?.skin_care_special_instructions ?? '-'}}
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </p-accordionTab>
                            <!-- Wipes End-->
                            <!-- Potty Training -->
                            <p-accordionTab header="Potty Training" class="accordianStyle">

                                <div class="col">
                                    <div class="add-listings-box py-0 ">
                                        <h3 class="mb-0 heading-calender">Previous Training data (Select a date to
                                            retrieve previous data.)
                                            <p-calendar placeholder="dd/mm/yyyy " [showTime]="false"
                                                (onSelect)="getDataByDate($event , 'pottyTrain')"></p-calendar>
                                        </h3>
                                        <ng-container *ngIf=" dataOnString == 'pottyTrain' && !error;">
                                            <div class="dataBydate">

                                            <table class="table  font-size-13
                    ">
                                                <thead>
                                                    <tr>
                                                        <th>Training</th>
                                                        <th>Reference Time</th>
                                                        <th>Actual Time</th>
                                                        <th>Remark</th>
                                                        <th>Is it Done?</th>
                                                        <!-- <th>Remarks</th> -->
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let data of newData?.potty_training ? newData?.potty_training :newData?.child_training">
                                                        <td>{{ data?.training_child }}</td>
                                                        <td>{{formatDateTime(data?.time_training_child)}}</td>
                                                        <td>{{formatDateTime(data?.time_training_actual_child)}}</td>
                                                        <td>{{ data?.training_child_remark}}</td>
                                                        <td>{{data?.done_training_child == '1' ? 'Yes': 'No'}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="error">
                                            <div *ngIf="dataOnString === 'pottyTrain'">
                                                No data available for the selected date.
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <br>
                                <div class="add-listings-box py-0 ">
                                    <div class="col">

                                        <!-- <h3 class="mb-0">Potty Training Information</h3> -->
                                        <div class="dataBydate">

                                        <table class="table font-size-13 my-3">
                                            <thead>
                                                <tr>
                                                    <th class="fw-500">Potty Training Required</th>
                                                    <th class="fw-500">Training Type</th>
                                                    <th class="fw-500">Done or Not?</th>
                                                    <th class="fw-500">Potty Training Time</th>
                                                    <th class="fw-500">Potty Training Remark</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of insideViewData?.potty_training">
                                                    <td>{{data?.potty_training}}</td>
                                                    <td>{{data?.training_child??'-'}}</td>
                                                    <td>{{ data?.training_child_remark ?? '-' }}</td>
                                                    <td>{{ formatDateTime(data?.time_training_child) }}</td>
                                                    <td>{{ data?.done_training_child ?? '-' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </div>


                            </p-accordionTab>
                            <!-- Potty Training End -->
                            <!-- Changing Clothes -->
                            <p-accordionTab header="Changing Clothes" class="accordianStyle">
                                <div class="col">
                                    <div class="add-listings-box py-0 my-3">
                                        <h3 class="mb-0 heading-calender">Previous Changing clothes of the day (Select a
                                            date to
                                            retrieve previous data.)
                                            <p-calendar placeholder="dd/mm/yyyy " [showTime]="false"
                                                (onSelect)="getDataByDate($event, 'clothes') "></p-calendar>
                                        </h3>
                                        <ng-container *ngIf="dataOnString === 'clothes' &&!error;">

                                            <div class="add-listings-box py-0 my-3">
                                                <div class="dataBydate">

                                                <table class="table  font-size-13
                    ">
                                                    <thead>
                                                        <tr>
                                                            <th>Reference Time</th>
                                                            <th>Actual Time</th>
                                                            <th>Is it Done?</th>
                                                            <th>Remark</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let data of newData?.changing_clothes">
                                                            <td>{{ formatDateTime(data?.Changing_time) }}</td>
                                                            <td>{{ formatDateTime(data?.Changing_actual_time) }}</td>

                                                            <td>{{ data?.changing_colthes == '1' ? 'Yes' : 'No' }}</td>
                                                            <td>{{ data?.clothe_remark
                                                                }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="error">
                                            <div *ngIf="dataOnString === 'clothes'">
                                                No data available for the selected date.
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="">
                                    <h3>Changing Clothes</h3>


                                    <div class="col-xl-4 col-lg-12
                                        col-md-12">
                                        <div class="form-group viewSummary">

                                            <label for="">Special Instruction for changing clothes: </label>
                                            <div><strong>
                                                    {{ insideViewData?.changing_colthes_spiecal_instructions ?? '-'}}</strong>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="add-listings-box py-0 my-3 ">
                                        <ng-container>


                                            <div class="dataBydate">

                                            <table class="table  font-size-13 ">
                                                <thead>
                                                    <tr>
                                                        <th>Changing Time</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let change of insideViewData?.changing_clothes">
                                                        <td>
                                                            {{ formatDateTime(change?.Changing_time) ?? '-' }}
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>



                                        </ng-container>
                                    </div>

                                </div>

                            </p-accordionTab>
                            <!-- Changing Clothes End-->

                            <!-- Sleeping -->
                            <p-accordionTab header="Sleeping" class="accordianStyle">


                                <div class="col">
                                    <!-- based on date  -->
                                    <div class="add-listings-box py-0 my-3">
                                        <h3 class="mb-0 heading-calender dateTable">Previous Sleeping data(Select a date
                                            to
                                            retrieve previous data.)
                                            <p-calendar placeholder="dd/mm/yyyy " [showTime]="false"
                                                (onSelect)="getDataByDate($event , 'sleep')">
                                            </p-calendar>
                                        </h3>
                                        <ng-container *ngIf=" dataOnString == 'sleep' && !error;">
                                            <div class="dataBydate">

                                            <table class="table  font-size-13">
                                                <thead>
                                                    <tr>
                                                        <th>With</th>
                                                        <th>Reference Time</th>
                                                        <th>Actual Time</th>
                                                        <th>Is it Done?</th>
                                                        <!-- <th>Remarks</th> -->
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let data of newData?.sleeping_time">
                                                        <td>Mother</td>
                                                        <td>{{ formatDateTime(data?.sleeping_mother_time) }}</td>
                                                        <td>{{ formatDateTime(data?.sleeping_mother_actual_time) }}</td>
                                                        <td>{{ data?.sleeping_mother == '1' ? 'Yes' : 'No' }}</td>
                                                    </tr>
                                                    <tr *ngFor="let data of newData?.nanny_time">
                                                        <td>Nurse</td>
                                                        <td>{{ formatDateTime(data?.sleeping_nanny_time) }}</td>
                                                        <td>{{ formatDateTime(data?.sleeping_nanny_actual_time) }}</td>
                                                        <td>{{ data?.sleeping_nanny == '1' ? 'Yes' : 'No' }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="error">
                                            <div *ngIf="dataOnString === 'sleep'">
                                                No data available for the selected date.
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>




                                <div class="add-listings-box py-0 my-3 ">
                                    <ng-container>

                                        <h3 class="mb-0 heading-calender dateTable">Sleeping With Mother:</h3>
                                        <div class="dataBydate">

                                        <table class="table font-size-13 ">
                                            <thead>
                                                <tr>
                                                    <th class="fw-500">Sleeping Time</th>
                                                    <th class="fw-500">Actual Sleeping Time</th>
                                                    <th class="fw-500">Done or Not</th>
                                                    <th class="fw-500">Remark</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let change of insideViewData?.sleeping_time">
                                                    <td>{{ formatDateTime(change?.sleeping_mother_time) ?? '-' }}
                                                    </td>
                                                    <td>{{ formatDateTime(change?.sleeping_mother_actual_time) ?? '-' }}
                                                    </td>
                                                    <td>{{ change?.sleeping_mother == true ? 'Done ' : 'Not Done' }}
                                                    </td>
                                                    <td>{{ change?.sleeping_mother_remark ?? '-' }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>



                                    </ng-container>
                                </div>




                                <div class="add-listings-box py-0 my-3 ">
                                    <ng-container>


                                        <h3 class="mb-0 heading-calender dateTable">Sleeping With Nurse:</h3>
                                        <div class="dataBydate">

                                        <table class="table font-size-13 ">
                                            <thead>
                                                <tr>
                                                    <th class="fw-500">Sleeping Time</th>
                                                    <th class="fw-500">Actual Sleeping Time</th>
                                                    <th class="fw-500">Done or Not</th>
                                                    <th class="fw-500">Remark</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let change of insideViewData?.nanny_time">
                                                    <td>{{ formatDateTime(change?.sleeping_nanny_time) ?? '-' }}
                                                    </td>
                                                    <td>{{ formatDateTime(change?.sleeping_nanny_actual_time) ?? '-' }}
                                                    </td>
                                                    <td>{{ change?.sleeping_nanny == true ? 'Done ' : 'Not Done' }}
                                                    </td>
                                                    <td>{{ change?.sleeping_nanny_remark ?? '-' }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>



                                    </ng-container>
                                </div>




                            </p-accordionTab>
                            <!-- Sleeping End-->



                            <!-- Activites -->

                            <p-accordionTab header="Activites" class="accordianStyle">
                                <div class="col">
                                    <div class="add-listings-box py-0 my-3">
                                        <h3 class="mb-0 heading-calender dateTable">Previous Activities of the day
                                            (Select a date to
                                            retrieve previous data.)
                                            <p-calendar placeholder="dd/mm/yyyy " [showTime]="false"
                                                (onSelect)="getDataByDate($event , 'activities')"></p-calendar>
                                        </h3>
                                        <ng-container *ngIf="dataOnString == 'activities' && !error;">
                                            <div class="add-listings-box py-0 my-3">
                                                <h3 class="mb-0 heading-calender">Outside Activities </h3>
                                                <div class="dataBydate">

                                                <table class="table  font-size-13
            " *ngIf="newData?.outside_child">
                                                    <thead>
                                                        <tr>
                                                            <th>Reference Time</th>
                                                            <th>Actual Time</th>

                                                            <th>Special instruction</th>
                                                            <th>Is it Done ? </th>
                                                            <th>Remark</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let data of newData?.outside_child">
                                                            <td>{{formatDateTime(data?.play_child_outside_time)}}</td>
                                                            <td>{{formatDateTime(data?.play_child_outside_actual_time)}}
                                                            </td>

                                                            <td>{{ data?.play_child_outside_special_Instructions }}</td>
                                                            <td>{{ data.play_child_outside_done== 1 ? 'Yes' : 'No' }}
                                                            </td>
                                                            <td>{{ data?.play_child_outside_remark}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                            </div>
                                            <div class="add-listings-box py-0 my-3">
                                                <h3 class="mb-0 heading-calender">Escort Activities </h3>
                                                <div class="dataBydate">

                                                <table class="table  font-size-13
            " *ngIf="newData?.escort_child">
                                                    <thead>
                                                        <tr>
                                                            <th>Reference Time</th>
                                                            <th>Actual Time</th>
                                                            <th>Special instruction</th>
                                                            <th>Is it Done ? </th>
                                                            <th>Remark</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let data of newData?.escort_child">
                                                            <td>{{formatDateTime(data?.escort_child_outside_time)}}</td>
                                                            <td>{{formatDateTime(data?.escort_child_outside_actual_time)}}
                                                            </td>
                                                            <td>{{ data?.escort_child_outside_special_Instructions }}
                                                            </td>
                                                            <td>{{ data.escort_child_outside_done== 1 ? 'Yes' : 'No' }}
                                                            </td>
                                                            <td>{{ data?.escort_child_outside_remark}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                            </div>
                                            <div class="add-listings-box py-0 my-3">
                                                <h3 class="mb-0 heading-calender"> Other Activities </h3>
                                                <div class="dataBydate">

                                                <table class="table  font-size-13" *ngIf="newData?.activities_name">
                                                    <thead>
                                                        <tr>
                                                            <th>Reference Time</th>
                                                            <th>Actual Time</th>
                                                            <th>Special instruction</th>
                                                            <th>Is it Done ? </th>
                                                            <th>Remark</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let data of newData?.activities_name">
                                                            <td>{{formatDateTime(data?.other_child_outside_time)}}</td>
                                                            <td>{{formatDateTime(data?.other_child_outside_actual_time)}}
                                                            </td>
                                                            <td>{{ data?.activities_child_outside_special_Instructions }}
                                                            </td>
                                                            <td>{{ data.other_child_outside_done == 1 ? 'Yes' : 'No' }}
                                                            </td>
                                                            <td>{{ data?.other_child_outside_remark}}</td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                            </div>
                                            <div class="add-listings-box py-0 my-3">
                                                <h3 class="mb-0 heading-calender">School Activities </h3>
                                                <div class="dataBydate">

                                                <table class="table  font-size-13
            " *ngIf="newData?.school_name">
                                                    <thead>
                                                        <tr>
                                                            <th>Reference Time</th>
                                                            <th>Actual Time</th>
                                                            <th>Special instruction</th>
                                                            <th>Is it Done ? </th>
                                                            <th>Remark</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let data of newData?.school_name">
                                                            <td>{{formatDateTime(data?.school_child_outside_time)}}</td>
                                                            <td>{{formatDateTime(data?.school_child_outside_actual_time)}}
                                                            </td>
                                                            <td>{{ data?.escort_child_school_special_instructions }}
                                                            </td>
                                                            <td>{{ data.school_child_outside_done== 1 ? 'Yes' : 'No' }}
                                                            </td>
                                                            <td>{{ data?.school_child_outside_remark}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="error">
                                            <div *ngIf="dataOnString === 'activities'">
                                                No data available for the selected date.
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div>
                                    <div class="col  add-listings-box py-0 my-3 ">
                                        <h3 class="mb-0 heading-calender dateTable">Playing with child outside</h3>
                                        <div class="dataBydate">

                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>
                                                    <th class="fw-500">Special Instruction</th>
                                                    <th class="fw-500">Reference Time</th>
                                                    <th class="fw-500">Actual Time </th>
                                                    <th class="fw-500">Done or Not?</th>
                                                    <th class="fw-500">Remark</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of insideViewData?.outside_child">
                                                    <td>{{data?.play_child_outside_special_Instructions|| '-'}}</td>
                                                    <td>{{ formatDateTime(data?.play_child_outside_time) }}</td>
                                                    <td>{{ formatDateTime(data?.play_child_outside_actual_time) }}
                                                    </td>
                                                    <td>{{data?.play_child_outside_done == true ? 'Done': 'Not Done'}}
                                                    </td>
                                                    <td>{{ data?.play_child_outside_remark ||'-' }}</td>

                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </div>



                                <!-- Playing with child outside? End-->

                                <!-- Escort child outside? -->

                                <!-- <h3>Escort child outside</h3> -->
                                <div class="row peach-bg">
                                    <div class="col add-listings-box py-0 my-3 ">

                                        <h3 class="mb-0 heading-calender dateTable">Escort child outside</h3>
                                        <div class="dataBydate">

                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>
                                                    <th class="fw-500">Special Instruction</th>
                                                    <th class="fw-500">Reference Time</th>
                                                    <th class="fw-500">Actual Time</th>
                                                    <th class="fw-500">Done or Not?</th>
                                                    <th class="fw-500">Remark</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of insideViewData?.escort_child">
                                                    <td>{{data?.escort_child_outside_special_Instructions || '-'}}</td>
                                                    <td>{{ formatDateTime(data?.escort_child_outside_time) }}
                                                    </td>
                                                    <td>{{ formatDateTime(data?.escort_child_outside_actual_time) }}
                                                    </td>
                                                    <td>{{data?.escort_child_outside_done == true ? 'Done': 'Not Done'}}
                                                    </td>
                                                    <td>{{ data?.escort_child_outside_remark ||'-' }}</td>

                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </div>
                                <!-- Escort child outside? End-->
                                <!-- activity name -->
                                <div class="row peach-bg">
                                    <div class="col add-listings-box py-0 my-3 ">

                                        <h3 class="mb-0 heading-calender dateTable">Extra Activities</h3>
                                        <div class="dataBydate">

                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>
                                                    <th class="fw-500">Activity Name</th>
                                                    <th class="fw-500">Reference Time</th>
                                                    <th class="fw-500">Actual Time</th>
                                                    <th class="fw-500">Done or Not?</th>
                                                    <th class="fw-500">Remark</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of insideViewData?.activities_name">
                                                    <td>{{data?.activities_child_outside_special_Instructions ||'-'}}
                                                    </td>
                                                    <td>{{ formatDateTime(data?.other_child_outside_time) }}</td>
                                                    <td>{{ formatDateTime(data?.other_child_outside_actual_time ) }}
                                                    </td>
                                                    <td>{{data?.other_child_outside_done == true ? 'Done': 'Not Done'}}
                                                    </td>
                                                    <td>{{ data?.other_child_outside_remark || '-' }}</td>

                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </div>
                                <!-- school child -->
                                <div class="row peach-bg">
                                    <div class="col add-listings-box py-0 my-3 ">

                                        <h3 class="mb-0 heading-calender dateTable">Schooling</h3>
                                        <div class="dataBydate">

                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>
                                                    <th class="fw-500">Activity Name</th>
                                                    <th class="fw-500">Reference Time</th>
                                                    <th class="fw-500">Actual Time</th>
                                                    <th class="fw-500">Done or Not?</th>
                                                    <th class="fw-500">Remark</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of insideViewData?.school_name">
                                                    <td>{{data?.escort_child_school_special_instructions || '-'}}
                                                    </td>
                                                    <td>{{ formatDateTime(data?.school_child_outside_time ) }}
                                                    </td>
                                                    <td>{{ formatDateTime(data?.school_child_outside_actual_time) }}
                                                    </td>
                                                    <td>{{data?.school_child_outside_done == true ? 'Done': 'Not Done'}}
                                                    </td>
                                                    <td>{{ data?.school_child_outside_remark ||'-' }}</td>

                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </div>
                                <!-- <h3>Enter activities name here</h3> -->



                            </p-accordionTab>
                            <!--Activites End  -->
                            <!-- Sleeping Start -->
                            <p-accordionTab header="Sleeping Training" class="accordianStyle">
                                <div class="col">
                                    <div class="add-listings-box py-0 my-3">
                                        <h3 class="mb-0 heading-calender">Previous Sleeping Training Data (Select a date
                                            to
                                            retrieve previous data.)
                                            <p-calendar placeholder="dd/mm/yyyy " [showTime]="false"
                                                (onSelect)="getDataByDate($event , 'sleepTrain')">
                                            </p-calendar>
                                        </h3>
                                        <ng-container *ngIf="dataOnString == 'sleepTrain' && !error;">
                                            <div class="dataBydate">

                                            <table class="table  font-size-13
                    ">
                                                <thead>
                                                    <tr>
                                                        <th>Time</th>
                                                        <th>Is it Done?</th>
                                                        <th>Remarks</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let data of newData?.sleeping_training">
                                                        <td>{{ formatDateTime(data?.sleeping_time) }}</td>
                                                        <td>{{ data?.sleeping_remark ?? '-'}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="error">
                                            <div *ngIf="dataOnString === 'sleepTrain'">
                                                No data available for the selected date.
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div>
                                    <div class="col mb-0 my-3">

                                        <!-- <h3 class="">Slepp</h3> -->
                                        <div class="dataBydate">

                                        <table class="table font-size-13">
                                            <thead>
                                                <tr>
                                                    <th class="fw-500">Sleeping Training of Baby</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of insideViewData?.sleeping_training">
                                                    <td>{{formatDateTime(data?.sleeping_time ?? '-')}}</td>


                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                        <div class="row peach-bg">
                                            <div class="my-3">
                                                <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                <!-- <h3>Sleep Training</h3> -->
                                                <div class="row">
                                                    <!-- <label class="subHeading my-2">Assesment</label> -->
                                                    <div class="col-xl-4 col-lg-12
                col-md-12">
                                                        <div class="form-group viewSummary">
                                                            <label for="">Sleep Training
                                                            </label>
                                                            <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.sleep_train?? '-'
                        }}" /> -->

                                                            <div type="text" readonly>
                                                                {{insideViewData?.sleep_train?? '-'
                                                            }}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-xl-4 col-lg-12
                col-md-12">
                                                        <div class="form-group viewSummary">
                                                            <label for="">Wake up time (morning)</label>
                                                            <!-- <input type="text" readonly class="form-control" [value]="formatDateTime( insideViewData?.walk_time)
                    " /> -->

                                                            <div type="text" readonly>{{formatDateTime(
                                                            insideViewData?.walk_time)
                                                            }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">

                                                    <div class="col-xl-4 col-lg-12
                col-md-12">
                                                        <div class="form-group viewSummary">
                                                            <label for="">Nap time</label>
                                                            <!-- <input type="text" readonly class="form-control" [value]="formatDateTime (insideViewData?.nap_time)
                        " /> -->

                                                            <div type="text" readonly>{{formatDateTime
                                                            (insideViewData?.nap_time)
                                                            }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-12
            col-md-12">
                                                        <div class="form-group viewSummary">
                                                            <label for="">Sleep time (evening)</label>
                                                            <!-- <input type="text" readonly class="form-control" [value]="formatDateTime (insideViewData?.sleep_time)
                    " /> -->

                                                            <div type="text" readonly>{{formatDateTime
                                                            (insideViewData?.sleep_time)
                                                            }}</div>
                                                        </div>
                                                    </div>

                                                    <div class="col-xl-4 col-lg-12
                col-md-12">
                                                        <div class="form-group viewSummary">
                                                            <label for="">Stroller</label>
                                                            <!-- <input type="text" readonly class="form-control" value="{{ insideViewData?.stroller??'-'
                        }}" /> -->

                                                            <div type="text" readonly>{{
                                                            insideViewData?.stroller??'-'
                                                            }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">

                                                    <div class="col-xl-4 col-lg-12
                col-md-12">
                                                        <div class="form-group viewSummary">
                                                            <label for="">Can the baby get himself back to sleep
                                                                when
                                                                interrupted
                                                                during short naps?</label>
                                                            <!-- <input type="text" readonly class="form-control" value="{{ insideViewData?.interrupt?? '-'
                        }}" /> -->

                                                            <div type="text" readonly>{{ insideViewData?.interrupt??
                                                            '-'
                                                            }}</div>
                                                        </div>
                                                    </div>

                                                    <div class="col-xl-4 col-lg-12
                col-md-12">
                                                        <div class="form-group viewSummary">
                                                            <label for="">Needs dummy to help soothe the baby during
                                                                sleep</label>
                                                            <!-- <input type="text" readonly class="form-control" value="{{ insideViewData?.need_dummy??'-'
                        }}" /> -->
                                                            <div type="text" readonly>
                                                                {{insideViewData?.need_dummy??'-' }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">

                                                    <div class="col-xl-4 col-lg-12
                col-md-12">
                                                        <div class="form-group viewSummary">
                                                            <label for="">Pacifier</label>
                                                            <!-- <input type="text" readonly class="form-control" value="{{ insideViewData?.pacifier?? '-'
                        }}" /> -->

                                                            <div type="text" readonly>
                                                                {{insideViewData?.pacifier??
                                                            '-'
                                                            }}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-xl-4 col-lg-12
                col-md-12">
                                                        <div class="form-group viewSummary">
                                                            <label for="">Songs before sleep
                                                            </label>
                                                            <!-- <input type="text" readonly class="form-control" value="{{ insideViewData?.song_sleep??'-'
                        }}" /> -->
                                                            <div type="text" readonly>{{
                                                            insideViewData?.song_sleep??'-'
                                                            }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-12
                col-md-12">
                                                        <div class="form-group viewSummary">
                                                            <label for="">Reading Sleep</label>
                                                            <!-- <input type="text" readonly class="form-control" value="{{ insideViewData?.reading_sleep??'-'
                        }}" /> -->
                                                            <div type="text" readonly>{{
                                                            insideViewData?.reading_sleep??'-'
                                                            }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </p-accordionTab>


                            <!-- Sleeping End -->
                            <!-- Vitamins/Medications -->
                            <p-accordionTab header="Vitamins" class="accordianStyle">
                                <div class="col">
                                    <div class="add-listings-box py-0 my-3">
                                        <h3 class="heading-calender dateTable">Previous Vitamin Medications Data (Select
                                            a date to
                                            retrieve previous data.)
                                            <p-calendar placeholder="Select date for data" [showTime]="false"
                                                (onSelect)="getDataByDate($event , 'vitamin')">
                                            </p-calendar>
                                        </h3>
                                        <ng-container *ngIf="dataOnString === 'vitamin' && !error;">
                                            <div class="dataBydate">

                                            <table class="table font-size-13">
                                                <thead>
                                                    <tr>
                                                        <th>Medications</th>
                                                        <th>Frequency</th>
                                                        <th>Reference Time</th>
                                                        <th>Actual Time</th>
                                                        <th>Is it Done?</th>
                                                        <th>Remark</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let vitamin of newData?.vitamins_medications_json">
                                                        <td>{{ vitamin?.vitamin ? vitamin?.vitamin : '-' }}</td>
                                                        <td>{{ vitamin?.frequency ? vitamin?.frequency : '-' }}</td>
                                                        <td>
                                                            <ng-container *ngFor="let time of vitamin.medicine_time">
                                                                {{ time?.time ? formatDateTime(time?.time) : '-' }}
                                                                <br>
                                                            </ng-container>
                                                        </td>
                                                        <td>
                                                            <ng-container *ngFor="let time of vitamin.medicine_time">
                                                                {{ time?.time ? formatDateTime(time?.actual_time) : '-' }}
                                                                <br>
                                                            </ng-container>
                                                        </td>
                                                        <td>
                                                            <ng-container *ngFor="let time of vitamin.medicine_time">
                                                                {{ time?.vitamin_checkbox ? 'Yes' : 'No' }}
                                                                <br>
                                                            </ng-container>
                                                        </td>
                                                        <td>
                                                            <ng-container *ngFor="let time of vitamin.medicine_time">
                                                                {{ time?.vitamin_remark ? time?.vitamin_remark : ' ' }}
                                                                <br>
                                                            </ng-container>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="error">
                                            <div *ngIf="dataOnString === 'vitamin'">
                                                No data available for the selected date.
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <ng-container>
                                    <div>
                                        <div class="col-xl-12 col-lg-12 col-md-12 my-3">
                                            <div *ngFor="let data of insideViewData?.vitamins_medications_json">
                                                <div class="form-group viewSummary">
                                                    <div class="d-flex align-items-center">
                                                        <label for="" class="ml-3 mr-2 ">Medications:</label>
                                                        <div type="text" class="flex-grow-1"
                                                            *ngIf="data?.vitamin !== null && data?.vitamin !== '' && data?.vitamin !== undefined; else elseBlock">
                                                            {{ data?.vitamin ?? '-'}}
                                                        </div>
                                                        <ng-template #elseBlock>
                                                            <div>-</div>

                                                        </ng-template>
                                                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                        <label for="" class="ml-3 mr-2">Frequency:</label>
                                                        <div type="text" class="flex-grow-1"
                                                            *ngIf="data?.frequency !== null && data?.frequency !== '' && data?.frequency !== undefined; else elseBlock">
                                                            {{ data?.frequency ?? '-'}}
                                                        </div>
                                                        <ng-template #elseBlock>
                                                            <div>-</div>
                                                        </ng-template>
                                                    </div>
                                                    <!-- <table class="table font-size-13">
                                                        <thead>
                                                            <tr>
                                                                <th class="fw-500">Reference Time</th>
                                                                <th class="fw-500">Actual Time</th>
                                                                <th class="fw-500">Done or Not</th>
                                                                <th class="fw-500">Remarks</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let data1 of data?.medicine_time">
                                                                <td>{{ formatDateTime(data1?.time ?? '-') }}</td>
                                                                <td>{{ formatDateTime(data1?.actual_time ?? '-') }}</td>
                                                                <td>{{data1?.vitamin_checkbox == true ? 'Done': 'Not Done'}}
                                                                </td>
                                                                <td>{{data1?.vitamin_remark ?? '-'}}</td>


                                                            </tr>
                                                        </tbody>
                                                    </table> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>





                            </p-accordionTab>

                            <!-- Remark -->
                            <p-accordionTab header="Remark" class="accordianStyle">
                                <div class="col">
                                    <div class="add-listings-box py-0 my-3">
                                        <h3 class="mb-0 heading-calender dateTable">Previous Remark (Select a date to
                                            retrieve previous data.)
                                            <p-calendar placeholder="dd/mm/yyyy" [showTime]="false"
                                                (onSelect)="getDataByDate($event, 'remark')"></p-calendar>
                                        </h3>
                                        <ng-container *ngIf="dataOnString === 'remark' && !error;">
                                            <div>
                                                <input type="text" name="remark" class="form-control" readonly
                                                    [value]="newData?.remarks" placeholder="Remark" id="remark" />
                                            </div>
                                        </ng-container>

                                        <div class="add-listings-box py-0 my-3"
                                            *ngIf="dataOnString === 'remark' && !error; ">
                                            <h3 class="mb-0 heading-calender dateTable">Addition Remark from Nurse Side
                                            </h3>
                                            <ng-container *ngIf="dataOnString === 'remark' && !error; ">
                                                <div class="dataBydate">

                                                <table class="table font-size-13">
                                                    <thead>
                                                        <tr>
                                                            <th>Remarks</th>
                                                            <th>Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let data of newData?.nurse_remarks">
                                                            <td><textarea name="">{{ data?.nurse_remark}}</textarea>
                                                            </td>
                                                            <td>{{ data?.nurse_remark_time }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                            </ng-container>


                                        </div>

                                        <ng-container *ngIf="error">
                                            <div *ngIf="dataOnString === 'remark'">
                                                No data available for the selected date.
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div>
                                    <div class="col-xl-4 col-lg-12 col-md-12 my-3">
                                        <div class="form-group">
                                            <div type="text">{{ insideViewData?.remarks ?? '-'}}</div>

                                        </div>
                                    </div>
                                </div>





                            </p-accordionTab>
                        </p-accordion>
                    </form>
                </div>
            </div>
        </div>
    </ng-container>

</div>