import { Component, OnInit } from '@angular/core';
import { MaikaListService } from 'src/app/services/maika-list.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';




@Component({
  selector: 'app-initial-assessment-list',
  templateUrl: './initial-assessment-list.component.html',
  styleUrls: ['./initial-assessment-list.component.scss']
})
export class InitialAssessmentListComponent implements OnInit {
  day:number;
  month:number;
  year:number;
  nurce_care_link: string = '';
  groth_monitoring_link: string = '';
  loading: any;
  date: any = new Date('2000-01-04T10:04:04.000Z');
  tempdate: any;
  breadcrumb = [
    {
      title: 'Initial Assessment ',
      subTitle: 'Dashboard'
    }
  ]
  constructor(private initial_ass_service: MaikaListService, private spinner:NgxSpinnerService ,     private messageService: MessageService,

    ) { }
  initialAssData: any = []
  data: any = [];
  ngOnInit(): void {
    console.log(this.date);
    this.getAllData();
    localStorage.removeItem('showDTS')
  }

  // call api and fetch all data
  getAllData() {
    this.spinner.show()
    this.initial_ass_service.getInitialAssList().subscribe((res: any) => {
      this.spinner.hide();
      this.initialAssData = res.data;
      this.findLastEntry();
    },(error: any) => {
      this.spinner.hide()
      // Handle any errors that occurred during the API call
    
      
    }
  );
  }

  // find last data 
  findLastEntry() {
    this.initialAssData.map((res: any) => {
      this.tempdate = new Date(res.created_at);

      if ( this.tempdate.getTime() > this.date.getTime()) {
        this.date = new Date(res.created_at);
        console.log(res.created_at);
        this.data.push(res);
      }
    });
    this.genrateLink();  
  }


  // generate link for plans according to baby's age 
  genrateLink(){
    // 0 to 3 months 
    this.day = this.data[0].days;
    this.month = this.data[0].months;
    this.year = this.data[0].years;
    if(this.month < 2  && this.year == 0){
      this.nurce_care_link = '/portal/care-plans/zero-to-three-month-list';
      this.groth_monitoring_link = '/portal/growth-monitor-zero-three-months-list';
    }
    else if(this.month == 3  && this.year == 0 && this.day < 1 ){
      this.nurce_care_link = '/portal/care-plans/zero-to-three-month-list';
      this.groth_monitoring_link = '/portal/growth-monitor-zero-three-months-list';
    }

    // up to 3 months  to 1 year
    else if(this.month >= 3 && this.month < 12 && this.year == 0){
      this.nurce_care_link = '/portal/care-plans/four-month-to-one-year-list';
      this.groth_monitoring_link = '/portal/growth-monitor-four-month-one-year-list';
    }
    else if(this.year == 1 && this.month < 1 && this.day < 1){
      this.nurce_care_link = '/portal/care-plans/four-month-to-one-year-list';
      this.groth_monitoring_link = '/portal/growth-monitor-four-month-to-one-year-list';
    }

    // 1 year to 3 year
    else if(this.year <= 2 && this.year > 0){
      this.nurce_care_link = '/portal/care-plans/one-year-to-three-year-list';
      this.groth_monitoring_link = '/portal/growth-monitor-one-to-three-year-list'; 
    }
    else if(this.year == 3 && this.month < 1 && this.day < 1){
      this.nurce_care_link = '/portal/care-plans/one-year-to-three-year-list';
      this.groth_monitoring_link = '/portal/growth-monitor-one-to-three-year-list'; 
    } 

    // up to 3 year 
    else if(this.year >= 3){
      this.nurce_care_link = '/portal/care-plans/three-year-to-above-list';
      this.groth_monitoring_link = 'http://localhost:4200/portal/growth-monitor-three-year-and-above-list';
    } 

  }

}
