<p-toast></p-toast>
<div class="main-content d-flex flex-column">
  <app-dashboard-navbar></app-dashboard-navbar>
  <!-- title of the page -->
  <div class="breadcrumb-area" *ngFor="let content of breadcrumb">
    <h1>{{ content.title }}</h1>
    <ol class="breadcrumb">
      <li class="item"><a routerLink="/">Home</a></li>
      <li class="item">{{ content.subTitle }}</li>
      <li class="item">{{ content.title }}</li>
    </ol>
  </div>
  <form [formGroup]="heathMonitoringForm" (ngSubmit)="submitFormHealthMonitoring(healthMonitoring)"
      #healthMonitoring="ngForm">
    <p-accordion class="mt-3">
      <p-accordionTab header="Basic Details">
        <div class="add-listings-box pb-0">
          <h3>Basic Details</h3>
          <div class="row">
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="form-group">
                <label class="px-0">Name<span class="required">*</span></label>
                <div class="card flex justify-content-center">
                  <p-dropdown [options]="babyDetails" (onChange)="getBabyDetails($event)" [disabled]="isedit"
                      [readonly]="true" optionValue="id" placeholder="Baby Name" optionLabel="name" [filter]="true"
                      filterBy="name" formControlName="baby_details_id" placeholder="Select Baby's Name"
                      [resetFilterOnHide]="true" [class.is-invalid]="
                    (healthMonitoring.submitted &&
                    heathMonitoringForm.get('baby_details_id')?.invalid) ||
                    (heathMonitoringForm.get('baby_details_id')?.invalid &&
                    heathMonitoringForm.get('baby_details_id').touched)
                  "></p-dropdown>
                  <div class="invalid-feedback">Name is required</div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="form-group">
                <label class="px-0">Nurse Assigned<span class="required">*</span></label>
                <div class="card flex justify-content-center">
                  <!-- <p-dropdown [options]="nurseData" formControlName="nurse_id" optionLabel="first_name" [filter]="true"
                      filterBy="name" [disabled]="isedit" [showClear]="false" optionValue="id"
                      placeholder="Select Nurse" [resetFilterOnHide]="true"></p-dropdown> -->
                  <input type="text" readonly class="form-control" value="{{nurseName? nurseName : ''}}" />
                  <div class="invalid-feedback">Nurse Assigned is required</div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="form-group">
                <label class="px-0">Nurse Form Filling Time <span class="required">*</span></label>
                <div class="card flex justify-content-center">
                  <p-calendar placeholder="Time" [disabled]="isedit" formControlName="create_time" [timeOnly]="true"
                      [hourFormat]="12" inputId="time" [class.is-invalid]="
                    (healthMonitoring.submitted &&
                    heathMonitoringForm.get('create_time')?.invalid) ||
                    (heathMonitoringForm.get('create_time')?.invalid &&
                    heathMonitoringForm.get('create_time').touched)
                  "></p-calendar>
                  <div class="invalid-feedback">TIme is required</div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="form-group mb-0">
                <div class=" ">
                  <div class="form-check checkbox-list p-0">
                    <div class="py-0">
                      <div class="form-group">
                        <label class="px-0">Is baby is sick<span class="required">*</span></label>
                        <div class="d-flex">
                          <p class="d-flex">
                            <input type="radio" id="sick_yes" value="1" name="sick_or_not" formControlName="sick_or_not"
                                (change)="handleInput($event, 'sick')" disabled="true" />

                            <label for="sick_yes" class="mb-0 me-2">Yes</label>
                          </p>
                          <p class="d-flex">
                            <input type="radio" checked="true" id="sick_no" value="0" name="sick_or_not" disabled="true"
                                formControlName="sick_or_not" (change)="handleInput($event, 'notsick')" />
                            <label for="sick_no" class="mb-0 mx-2">No</label>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </p-accordionTab>

      <p-accordionTab header="Temprature Monitoring">
        <div class="add-listings-box" formGroupName="temperature_json">
          <h3>Temprature Monitoring</h3>
          <div class="row">
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="form-group">
                <label class="px-0">
                  Temprature Frequency
                  <span class="required">*</span>
                </label>
                <div class="form-check checkbox-list p-0">
                  <div>{{healthData?.temperature_json?.temp_freq}}</div>
                  <!-- <p-dropdown [options]="frequencyList" optionValue="value" [(ngModel)]="frequencyValue"
                      formControlName="temp_freq" optionLabel="value" [filter]="true" filterBy="value"
                      placeholder="Frequency" [resetFilterOnHide]="true">
                    </p-dropdown> -->
                  <!-- <ng-template pTemplate="selectedItem">
              <div
                class="flex align-items-center gap-2"
                *ngIf="frequencyValue"
              >
                <div>{{ frequencyValue.frequencyList }}</div>s
              </div>
            </ng-template>
            <ng-template let-frequency pTemplate="item">
              <div class="flex align-items-center gap-2">
                <div>{{ frequencyList.frequencyList }}</div>
              </div>
            </ng-template> -->
                  <!-- <div class="invalid-feedback">Frequency is required</div> -->
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="form-group">
                <label class="px-0">Start Time <span class="required">*</span></label>
                <div class="card flex justify-content-center">
                  <div>{{formatDateTime(healthData?.temperature_json?.start_time_temperature)}}</div>
                  <!-- <p-calendar placeholder="Time" formControlName="start_time_temperature" [timeOnly]="true"
                      [hourFormat]="12" inputId="time"></p-calendar>
                  <div class="invalid-feedback">TIme is required</div> -->
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="form-group">
                <label class="px-0">End Time <span class="required">*</span></label>
                <div class="card flex justify-content-center">
                  <div>{{formatDateTime(healthData?.temperature_json?.end_time_temperature)}}</div>
                  <!-- <p-calendar placeholder="Time" formControlName="end_time_temperature" [timeOnly]="true"
                      [hourFormat]="12" inputId="time"></p-calendar>
                  <div class="invalid-feedback">TIme is required</div> -->
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-lg-4 col-sm-6 col-12">
            <div class="add-listings-btn">
              <button [disabled]="!babysick" (click)="editAutoMaticTimeFill($event)">Proceed</button>
            </div>
          </div> -->
          <div class="row">
            <div class="col-12 m-2" *ngIf="isFeeding && babysick">
              <div formArrayName="temprature_extra_value">
                <div *ngFor="let control of getTempratureExtraValue().controls; let i = index" [formGroupName]="i">
                  <div class="row">
                    <div class="form-check checkbox-listb d-flex align-items-center col-md-2 col-lg-2 form-group">
                      <div class="form-group">
                        <label class="checkbox checkbox-outline px-0 mt-3">
                          <input type="checkbox" value="temp" name="checkboxes" formControlName="temp_checkbox" />
                          <span></span>
                          Done?
                        </label>
                      </div>
                    </div>
                    <div class="justify-content-center col-md-2 form-group">
                      <div class="form-group">
                        <label class="px-0">Time</label>
                        <p-calendar placeholder="Time" formControlName="temp_time" [timeOnly]="true" [hourFormat]="12"
                            [disabled]="true" inputId="time"></p-calendar>
                      </div>
                    </div>
                    <div class="justify-content-center col-md-2 form-group">
                      <div class="form-group">
                        <label class="px-0">Actual Time</label>
                        <p-calendar placeholder="Time" formControlName="actual_temp_time" [timeOnly]="true"
                            [hourFormat]="12" inputId="time"
                            [disabled]="!control.get('temp_checkbox').value"></p-calendar>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label class="px-0">Value</label>
                        <input type="text" pattern="[0-9]*" (keypress)="keyPress($event)" name="number"
                            class="form-control" placeholder="value" formControlName="value"
                            [disabled]="!control.get('temp_checkbox').value" />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label class="px-0">Action</label>
                        <input type="text" name="name" class="form-control" placeholder="action"
                            formControlName="action" [disabled]="!control.get('temp_checkbox').value" />
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </p-accordionTab>
      <!-- their is add value increase and enable when i click on processed button -->
      <p-accordionTab header="Vital Signs">
        <div class="add-listings-box pb-0">
          <h3>Vital Signs</h3>
          <div class="row">

            <!-- Pulse check -->
            <div formGroupName="vital_signs_json">
              <div class="row">
                <div class="col-lg-4 col-sm-6 col-12" formGroupName="pulse_vital">
                  <div class="form-group">
                    <label class="px-0">
                      Pulse Frequency
                      <span class="required">*</span>
                    </label>
                    <div class="form-check checkbox-list p-0">
                      <div>{{healthData?.vital_signs_json?.pulse_vital?.freq_pulse}}</div>
                      <!-- <p-dropdown [options]="frequencyList" [(ngModel)]="frequencyValue1" optionValue="value"
                          formControlName="freq_pulse" optionLabel="name" optionLabel="value" [filter]="true"
                          filterBy="value" placeholder="Frequency" [resetFilterOnHide]="true">
                        </p-dropdown> -->
                      <!-- <ng-template pTemplate="selectedItem">
                  <div
                    class="flex align-items-center gap-2"
                    *ngIf="frequencyValue"
                  >
                    <div>{{ frequencyValue.frequencyList }}</div>
                  </div>
                </ng-template>
                <ng-template let-frequency pTemplate="item">
                  <div class="flex align-items-center gap-2">
                    <div>{{ frequencyList.frequencyList }}</div>
                  </div>
                </ng-template> -->
                      <div class="invalid-feedback">Frequency is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12" formGroupName="pulse_vital">
                  <div class="form-group">
                    <label class="px-0">Start Time <span class="required">*</span></label>
                    <div class="card flex justify-content-center">
                      <!-- <p-calendar placeholder="Time" formControlName="start_time_pulse" [timeOnly]="true"
                          [hourFormat]="12" inputId="time"></p-calendar>
                      <div class="invalid-feedback">TIme is required</div> -->
                      <div>{{formatDateTime(healthData?.vital_signs_json?.pulse_vital?.start_time_pulse)}}</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12" formGroupName="pulse_vital">
                  <div class="form-group">
                    <label class="px-0">End Time <span class="required">*</span></label>
                    <div class="card flex justify-content-center">
                      <!-- <p-calendar placeholder="Time" formControlName="end_time_pulse" [timeOnly]="true"
                          [hourFormat]="12" inputId="time"></p-calendar>
                      <div class="invalid-feedback">TIme is required</div> -->
                      <div>{{formatDateTime(healthData?.vital_signs_json?.pulse_vital?.end_time_pulse)}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="col-lg-4 col-sm-6 col-12">
                <div class="add-listings-btn">
                  <button (click)="editAutoMaticVitalSignFill($event)">Proceed</button>
                </div>
              </div> -->
              <div class="row" formGroupName="pulse_vital">
                <div class="col-12 m-2" *ngIf="isFeedingVital && babysick">
                  <div formArrayName="vital_extra_value">
                    <div *ngFor="let control of getVitalSign().controls; let i = index" [formGroupName]="i">
                      <div class="row">
                        <div class="form-check checkbox-listb d-flex align-items-center col-md-2 col-lg-2 form-group">
                          <div class="form-group">
                            <label class="checkbox checkbox-outline px-0 mt-3">
                              <input type="checkbox" value="temp" name="checkboxes" formControlName="vital_checkbox" />
                              <span></span>
                              Done?
                            </label>
                          </div>
                        </div>
                        <div class="justify-content-center col-md-2 form-group">
                          <div class="form-group">
                            <label class="px-0">Time</label>
                            <p-calendar placeholder="Time" formControlName="vital_time" [timeOnly]="true"
                                [hourFormat]="12" disabled="true" inputId="time"></p-calendar>
                          </div>
                        </div>
                        <div class="justify-content-center col-md-2 form-group">
                          <div class="form-group">
                            <label class="px-0">Actual Time</label>
                            <p-calendar placeholder="Time" formControlName="actual_vital_time" [timeOnly]="true"
                                [hourFormat]="12" inputId="time"
                                [disabled]="!control.get('vital_checkbox').value"></p-calendar>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="px-0">Value</label>
                            <input type="text" pattern="[0-9]*" (keypress)="keyPress($event)" name="name"
                                class="form-control" placeholder="value" formControlName="vital_value"
                                [disabled]="!control.get('vital_checkbox').value" />
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="px-0">Action</label>
                            <input type="text" name="name" class="form-control" placeholder="action"
                                formControlName="vital_action" [disabled]="!control.get('vital_checkbox').value" />
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- SPO2 Section
                        -->

          <div class="row" formGroupName="vital_signs_json">
            <div class="col-lg-4 col-sm-6 col-12" formGroupName="spo2_vital">
              <div class="form-group">
                <label class="px-0">
                  SpO2 frequency(in hr)
                  <span class="required">*</span>
                </label>
                <div class="form-check checkbox-list p-0">
                  <!-- <p-dropdown [options]="frequencyList" [(ngModel)]="frequencyValue3" optionValue="value"
                  formControlName="freq_spo2" optionLabel="name" optionLabel="value" [filter]="true"
                  filterBy="value" placeholder="Frequency" [resetFilterOnHide]="true">
                </p-dropdown> -->
                  <div>{{healthData?.vital_signs_json?.spo2_vital?.freq_spo2}}</div>
                  <!-- <ng-template pTemplate="selectedItem">
                  <div
                    class="flex align-items-center gap-2"
                    *ngIf="frequencyValue"
                  >
                    <div>{{ frequencyValue.frequencyList }}</div>
                  </div>
                </ng-template>
                <ng-template let-frequency pTemplate="item">
                  <div class="flex align-items-center gap-2">
                    <div>{{ frequencyList.frequencyList }}</div>
                  </div>
                </ng-template> -->
                  <div class="invalid-feedback">Frequency is required</div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12" formGroupName="spo2_vital">
              <div class="form-group">
                <label class="px-0">Start Time <span class="required">*</span></label>
                <div class="card flex justify-content-center">
                  <div>{{formatDateTime(healthData?.vital_signs_json?.spo2_vital?.start_time_spo2)}}</div>
                  <!-- <p-calendar placeholder="Time" formControlName="start_time_spo2" [timeOnly]="true" [hourFormat]="12"
                      inputId="time"></p-calendar>
                  <div class="invalid-feedback">TIme is required</div> -->
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12" formGroupName="spo2_vital">
              <div class="form-group">
                <label class="px-0">End Time <span class="required">*</span></label>
                <div class="card flex justify-content-center">
                  <div>{{formatDateTime(healthData?.vital_signs_json?.spo2_vital?.end_time_spo2)}}</div>
                  <!-- <p-calendar placeholder="Time" formControlName="end_time_spo2" [timeOnly]="true" [hourFormat]="12"
                      inputId="time"></p-calendar>
                  <div class="invalid-feedback">TIme is required</div> -->
                </div>
              </div>
            </div>
            <!-- <div class="col-lg-4 col-sm-6 col-12">
              <div class="form-group mb-0">
                <label class="px-0"> <span class="required"></span></label>
                <div class="add-listings-btn">
                  <button (click)="editSpO2($event)">Proceed</button>
                </div>
              </div>
            </div> -->
            <div class="row" formGroupName="spo2_vital">
              <div class="col-12 m-2" *ngIf="isFeedingSpO2 && babysick">
                <div formArrayName="spo2_extra_value">
                  <div *ngFor="let control of getSpO2ExtraValue().controls; let i = index" [formGroupName]="i">
                    <div class="row">
                      <div class="form-check checkbox-listb d-flex align-items-center col-md-2 col-lg-2 form-group">
                        <div class="form-group">
                          <label class="checkbox checkbox-outline px-0 mt-3">
                            <input type="checkbox" value="temp" name="checkboxes" formControlName="spo2_checkbox" />
                            <span></span>
                            Done?
                          </label>
                        </div>
                      </div>
                      <div class="justify-content-center col-md-2 form-group">
                        <div class="form-group">
                          <label class="px-0">Time</label>
                          <p-calendar placeholder="Time" formControlName="spo2_time" [timeOnly]="true" [hourFormat]="12"
                              [disabled]="true" inputId="time"></p-calendar>
                        </div>
                      </div>
                      <div class="justify-content-center col-md-2 form-group">
                        <div class="form-group">
                          <label class="px-0">Actual Time</label>
                          <p-calendar placeholder="Time" formControlName="actual_spo2_time" [timeOnly]="true"
                              [hourFormat]="12" inputId="time"
                              [disabled]="!control.get('spo2_checkbox').value"></p-calendar>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label class="px-0">Value</label>
                          <input type="text" pattern="[0-9]*" (keypress)="keyPress($event)" name="name"
                              class="form-control" placeholder="value" formControlName="spo2_value"
                              [disabled]="!control.get('spo2_checkbox').value" />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label class="px-0">Action</label>
                          <input type="text" name="name" class="form-control" placeholder="action"
                              formControlName="spo2_action" [disabled]="!control.get('spo2_checkbox').value" />
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <!-- Respiration Section -->
          <div class="row" formGroupName="vital_signs_json">
            <div class="col-lg-4 col-sm-6 col-12" formGroupName="respiration_vital">
              <div class="form-group mb-0">
                <div class=" ">
                  <div class="form-check checkbox-list p-0">
                    <div class="py-0">
                      <div class="form-group">
                        <label class="px-0">Is the baby/child asthmatic?
                          <span class="required">*</span></label>
                        <div class="d-flex">
                          <p class="d-flex">
                            <input type="radio" id="asthmatic_yes" value="1" formControlName="yes_no_respiration"
                                name="yes_no_respiration" (change)="handleInput($event, 'asthmatic')" />
                            <label for="asthmatic_yes" class="mb-0 me-2">Yes</label>
                          </p>
                          <p class="d-flex">
                            <input type="radio" id="asthmatic_no" value="0" checked="true"
                                formControlName="yes_no_respiration" name="yes_no_respiration"
                                (change)="handleInput($event, 'unasthmatic')" />
                            <label for="asthmatic_no" class="mb-0 mx-2">No</label>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row" formGroupName="vital_signs_json">
            <div class="col-lg-4 col-sm-6 col-12" formGroupName="respiration_vital">
              <div class="form-group">
                <label class="px-0">
                  Respiration Frequency
                  <span class="required">*</span>
                </label>
                <div class="form-check checkbox-list p-0">
                  <!-- <p-dropdown [options]="frequencyList" [(ngModel)]="frequencyValue2" optionValue="value"
                      formControlName="freq_respiration" optionLabel="value" [filter]="true" filterBy="value"
                      placeholder="Frequency" [resetFilterOnHide]="true">
                  </p-dropdown>
                  <div class="invalid-feedback">Frequency is required</div> -->
                  <div>{{healthData?.vital_signs_json?.respiration_vital?.freq_respiration}}</div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12" formGroupName="respiration_vital">
              <div class="form-group">
                <label class="px-0" style="margin-bottom: 2%">Start Time <span class="required">*</span></label>
                <div class="card flex justify-content-center">
                  <!-- <p-calendar placeholder="Time" formControlName="start_time_respiration" [timeOnly]="true"
                      [hourFormat]="12" inputId="time"></p-calendar>
                  <div class="invalid-feedback">TIme is required</div> -->
                  <div>{{formatDateTime(healthData?.vital_signs_json?.respiration_vital?.start_time_respiration)}}</div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12" formGroupName="respiration_vital">
              <div class="form-group">
                <label class="px-0" style="margin-bottom: 2%">End Time <span class="required">*</span></label>
                <div class="card flex justify-content-center">
                  <!-- <p-calendar placeholder="Time" formControlName="end_time_respiration" [timeOnly]="true"
                      [hourFormat]="12" inputId="time"></p-calendar>
                  <div class="invalid-feedback">TIme is required</div> -->
                  <div>{{formatDateTime(healthData?.vital_signs_json?.respiration_vital?.end_time_respiration)}}</div>
                </div>
              </div>
            </div>
            <!-- <div class="col-lg-4 col-sm-6 col-12">
              <div class="add-listings-btn">
                <button (click)="editRespiration($event)">Proceed</button>
              </div>
            </div> -->
            <div class="row" formGroupName="respiration_vital">
              <div class="col-12 m-2" *ngIf="isFeedingRespiration && babysick">
                <div formArrayName="respiration_extra_value">
                  <div *ngFor="let control of getRespirationExtraValue().controls; let i = index" [formGroupName]="i">
                    <div class="row">
                      <div class="form-check checkbox-listb d-flex align-items-center col-md-2 col-lg-2 form-group">
                        <div class="form-group">
                          <label class="checkbox checkbox-outline px-0 mt-3">
                            <input type="checkbox" value="temp" name="checkboxes"
                                formControlName="respiration_checkbox" />
                            <span></span>
                            Done?
                          </label>
                        </div>
                      </div>
                      <div class="justify-content-center col-md-2 form-group">
                        <div class="form-group">
                          <label class="px-0">Time</label>
                          <p-calendar placeholder="Time" formControlName="respiration_time" [timeOnly]="true"
                              [hourFormat]="12" [disabled]="true" inputId="time"></p-calendar>
                        </div>
                      </div>
                      <div class="justify-content-center col-md-2 form-group">
                        <div class="form-group">
                          <label class="px-0">Actual Time</label>
                          <p-calendar placeholder="Time" formControlName="actual_respiration_time" [timeOnly]="true"
                              inputId="time" [hourFormat]="12"
                              [disabled]="!control.get('respiration_checkbox').value"></p-calendar>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label class="px-0">Value</label>
                          <input type="text" pattern="[0-9]*" (keypress)="keyPress($event)" name="name"
                              class="form-control" placeholder="value" formControlName="respiration_value"
                              [disabled]="!control.get('respiration_checkbox').value" />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label class="px-0">Action</label>
                          <input type="text" name="name" class="form-control" placeholder="action"
                              formControlName="respiration_action"
                              [disabled]="!control.get('respiration_checkbox').value" />
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </p-accordionTab>




      <!--  -->
      <!-- Doctor Recommendation -->
      <!--  -->
      <p-accordionTab header="Doctor Recommendations">
        <div class="add-listings-box pb-0">
          <h3>Doctor Recommendations</h3>
          <div formArrayName="doc_recommendation_json">
            <ng-container *ngFor="
              let group of heathMonitoringForm.get('doc_recommendation_json')['controls'];
              index as i;
              last as last
            ">
              <div class="row align-items-end" [formGroupName]="i">
                <!-- Use align-items-end to align button slightly higher -->
                <div class="col pe-0">
                  <div class="form-group">
                    <label class="px-0">Enter Doctor recommendation here</label>

                    <div class="pl-0 checkbox-list">
                      <input type="text" formControlName="remarks_doc" class="form-control"
                          placeholder="Enter Remark" />
                    </div>
                  </div>
                </div>
                <div class="col-auto d-flex">
                  <div class="add-listings-btn mb-2">
                    <!-- Add mt-1 to button div to move it slightly higher -->

                    <button type="button" class="plus-btn" (click)="addDocRecommendation('add', i)">
                      +
                    </button>
                  </div>
                  <div class="add-listings-btn  ms-2" *ngIf="i > 0">
                    <div class="remove">
                      <button class="plus-btn" (click)="addDocRecommendation('delete', i)">
                        -
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </ng-container>
          </div>
        </div>
      </p-accordionTab>
      <!--  -->
      <!-- this is medication section -->
      <!--  -->
      <p-accordionTab header="Medication">
        <div class="add-listings-box pb-0" formArrayName="medications_json">
          <h3>Medication</h3>
          <ng-container *ngFor="
        let group of heathMonitoringForm.get('medications_json')['controls'];
        index as i;
        last as last
      ">
            <div class="row" [formGroupName]="i">
              <div class="col-lg-4 col-sm-6 col-12">
                <label class="mb-2">Medicine Name</label>
                <div class="form-group">
                  <!-- <label class="px-0">Remarks</label> -->
                  <div class="pl-0 checkbox-list">
                    <input type="text" formControlName="medicians_name" class="form-control"
                        placeholder="Enter remarks" />
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-6 col-12">
                <label class=" mb-2">Dosage</label>
                <div class="form-group">
                  <!-- <label class="px-0">Remarks</label> -->
                  <div class="pl-0 checkbox-list">
                    <input type="text" formControlName="medicians_dosage" class="form-control"
                        placeholder="Enter remarks" />
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-6 col-12">
                <label class=" mb-2">Frequency</label>
                <div class="form-group">
                  <!-- <label class="px-0">Remarks</label> -->
                  <div class="form-check checkbox-list p-0">
                    <p-dropdown [options]="frequencyList" optionValue="value" formControlName="medicians_freq"
                        optionLabel="value" [filter]="true" filterBy="value" placeholder="Frequency"
                        [resetFilterOnHide]="true">
                    </p-dropdown>
                    <div class="invalid-feedback">Frequency is required</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-6 col-12">
                <label class="mb-2">Date</label>
                <div class="form-group">
                  <!-- <label class="px-0">Remarks</label> -->
                  <div class="form-check checkbox-list p-0">
                    <p-calendar placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" formControlName="medicians_date"
                        [minDate]="maxDate" [showIcon]="true"></p-calendar>
                    <div class="invalid-feedback">TIme is required</div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-sm-6 col-12">
                <div class="form-group">
                  <label class="px-0 mb-2">Time <span class="required">*</span></label>
                  <div class="card flex justify-content-center">
                    <p-calendar placeholder="Time" formControlName="medicians_time" [timeOnly]="true" [hourFormat]="12"
                        inputId="time"></p-calendar>
                    <div class="invalid-feedback">TIme is required</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-6 col-12">
                <label class="mb-2">Remarks</label>
                <div class="form-group">
                  <!-- <label class="px-0">Remarks</label> -->
                  <div class="pl-0 checkbox-list">
                    <input type="text" formControlName="medicians_remarks" class="form-control"
                        placeholder="Enter remarks" />
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex">
                <div class="add-listings-btn ">
                  <!-- Add mt-1 to button div to move it slightly higher -->

                  <button type="button" class="plus-btn" (click)="addMedication('add', i)">
                    +
                  </button>
                </div>
                <div class="add-listings-btn  ms-2">
                  <div class="remove" *ngIf="i > 0">
                    <button class="plus-btn" (click)="addMedication('delete', i)">
                      -
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </p-accordionTab>

      <!-- immunization form Start -->
      <p-accordionTab header="Immunization">
        <div class="add-listings-box" formGroupName="immunization">
          <!-- <h3>heading here</h3> -->
          <label class="subHeading mb-3"></label>
          <div class="row">
            <div class="col-12 col-xxxl-6">
              <div class="form-group table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr style="white-space: nowrap">
                      <th scope="col" class="text-center" rowspan="2">
                        <label class="subHeading px-0">Time</label>
                      </th>
                      <th scope="col" class="text-center" rowspan="2">
                        <label class="subHeading px-0">vaccine</label>
                      </th>
                      <th scope="col" class="text-center" rowspan="2">
                        <label class="subHeading px-0">Brand</label>
                      </th>
                      <th scope="col" class="text-center" colspan="2">
                        <label class="subHeading px-0">Date</label>
                      </th>
                      <th scope="col" class="text-center weight" rowspan="2">
                        <label class="subHeading px-0">Weight (K.g)</label>
                      </th>
                      <th scope="col" class="text-center" rowspan="2">
                        <label class="subHeading px-0">Name of HealthStaff</label>
                      </th>
                      <th scope="col" class="text-center" rowspan="2">
                        <label class="subHeading px-0">Batch No. of Vaccine</label>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-center">Due on</th>
                      <th class="text-center">Given on</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- NECK ROW -->
                    <!-- Flexion -->
                    <tr>
                      <td scope="row" rowspan="2" class="text-verticle"><label class="px-0"><br> Birth</label></td>
                      <td class="blue">BCG</td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="bcg_brand" class="form-control" placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="bcg_dose_date" inputId="bcg_dose_date" [disabled]="true">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="bcg_dose_given_date" inputId="bcg_dose_given_date">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="bcg_weight" class="form-control" placeholder="Weight" />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="bcg_staff" class="form-control"
                              placeholder="Name of health staff" />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="bcg_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td class="green">Hepatitis B(1<sup>st</sup>Dose)</td>
                      <td>
                        <div class="form-group">
                          <input type="text" (input)="changeHapatitisBrand($event)"
                              formControlName="HepatitiesB_1stdose_brand" class="form-control" placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="HepatitiesB_1stdose_date" inputId="HepatitiesB_1stdose_date"
                                [readonlyInput]="true" [disabled]="true">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="HepatitiesB_1stdose_given_date"
                                inputId="HepatitiesB_1stdose_given_date">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="HepatitiesB_1stdose_weight" class="form-control"
                              placeholder="Weight" />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="HepatitiesB_1stdose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="HepatitiesB_1stdose_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>
                    <!-- 2 months  -->
                    <tr>
                      <td scope="row" rowspan="3" class="text-verticle"><label class="px-0">2 Months</label></td>
                      <td class="blue">Hexavalent Vaccine <br> DTaP-IPV-Hib(1<sup>st</sup>Dose) <br> Hepatitis B
                        (2<sup>nd</sup>Dose)</td>
                      <td>
                        <div class="form-group">
                          <input type="text" (input)="changeDtapIpvHibBrand($event)"
                              formControlName="dtab_tpv_hib_1st_brand" class="form-control" placeholder="Brand." />
                        </div>
                        <div class="form-group">
                          <input type="text" (input)="changeHapatitisBrand($event)"
                              formControlName="HepatitiesB_2nd_brand" class="form-control" placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="dtab_tpv_hib_1st_dose_date" inputId="dtab_tpv_hib_1st_dose_date"
                                [readonlyInput]="true" [disabled]="true">
                            </p-calendar>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="HepatitiesB_2nd_dose_date" inputId="HepatitiesB_2nd_dose_date"
                                [readonlyInput]="true" [disabled]="true">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="dtab_tpv_hib_1st_dose_given_date"
                                inputId="dtab_tpv_hib_1st_dose_given_date">
                            </p-calendar>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="HepatitiesB_2nd_dose_given_date"
                                inputId="HepatitiesB_2nd_dose_given_date">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="dtab_tpv_hib_1st_weight" class="form-control"
                              placeholder="Weight" />
                        </div>
                        <div class="form-group">
                          <input type="number" formControlName="Hexavalent_1st_weight" class="form-control"
                              placeholder="Weight" />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="dtab_tpv_hib_1st_staff" class="form-control"
                              placeholder="Name of health staff" />
                        </div>
                        <div class="form-group">
                          <input type="text" formControlName="Hexavalent_1st_staff" class="form-control"
                              placeholder="Name of health staff" />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="dtab_tpv_hib_1st_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                        <div class="form-group">
                          <input type="number" formControlName="Hexavalent_1st_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="green">PCV (1<sup>st</sup>Dose)</td>
                      <td>
                        <div class="form-group">
                          <input type="text" (input)="changePCVBrand($event)" formControlName="pcv_1stdose_brand"
                              class="form-control" placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="pcv_1stdose_date" inputId="pcv_1stdose_date" [readonlyInput]="true"
                                [disabled]="true">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="pcv_1stdose_given_date" inputId="pcv_1stdose_given_date">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="pcv_1stdose_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="pcv_1stdose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="pcv_1stdose_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="blue">Rotavirus</td>
                      <td>
                        <div class="form-group">
                          <input type="text" (input)="changeRotavirusBrand($event)"
                              formControlName="rotavirus_1stdose_brand" class="form-control" placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="rotavirus_1stdose_date" inputId="rotavirus_1stdose_date"
                                [readonlyInput]="true" [disabled]="true">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="rotavirus_1stdose_given_date" inputId="rotavirus_1stdose_given_date">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="rotavirus_1stdose_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="rotavirus_1stdose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="rotavirus_1stdose_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>
                    <!-- 4 months  -->
                    <tr>
                      <td scope="row" rowspan="4" class="text-verticle"><label class="px-0">4 Months</label></td>
                      <td class="green">Hexavalent Vaccine <br> DTaP-IPV-Hib(2<sup>nd</sup>Dose) <br> Hepatitis B
                        (3<sup>rd</sup>Dose)</td>
                      <td>
                        <div class="form-group">
                          <input type="text" (input)="changeDtapIpvHibBrand($event)"
                              formControlName="dtab_tpv_hib_2nd_brand" class="form-control" placeholder="Brand." />
                        </div>
                        <div class="form-group">
                          <input type="text" (input)="changeHapatitisBrand($event)"
                              formControlName="HepatitiesB_3rd_dose_brand" class="form-control" placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="dtab_tpv_hib_2nd_dose_date" inputId="dtab_tpv_hib_2nd_dose_date"
                                [readonlyInput]="true" [disabled]="true">
                            </p-calendar>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="HepatitiesB_3rd_dose_date" inputId="HepatitiesB_3rd_dose_date"
                                [readonlyInput]="true" [disabled]="true">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="dtab_tpv_hib_2nd_dose_given_date"
                                inputId="dtab_tpv_hib_2nd_dose_given_date">
                            </p-calendar>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="HepatitiesB_3rd_dose_given_date"
                                inputId="HepatitiesB_3rd_dose_given_date">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="dtab_tpv_hib_2nd_dose_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                        <div class="form-group">
                          <input type="number" formControlName="HepatitiesB_3rd_dose_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="dtab_tpv_hib_2nd_dose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                        <div class="form-group">
                          <input type="text" formControlName="HepatitiesB_3rd_dose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="dtab_tpv_hib_2nd_dose_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                        <div class="form-group">
                          <input type="number" formControlName="HepatitiesB_3rd_dose_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="blue">OPV</td>
                      <td>
                        <div class="form-group">
                          <input type="text" (input)="changeOPVBrand($event)" formControlName="opv_1st_dose_brand"
                              class="form-control" placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="opv_1st_dose_date" inputId="opv_1st_dose_date" [readonlyInput]="true"
                                [disabled]="true">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="opv_1st_dose_given_date" inputId="opv_1st_dose_given_date">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="opv_1st_dose_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="opv_1st_dose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="opv_1st_dose_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="green">PCV (2<sup>nd</sup>Dose)</td>
                      <td>
                        <div class="form-group">
                          <input type="text" (input)="changePCVBrand($event)" formControlName="pcv_2nd_dose_brand"
                              class="form-control" placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="pcv_2nd_dose_date" inputId="pcv_2nd_dose_date" [readonlyInput]="true"
                                [disabled]="true">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="pcv_2nd_dose_given_date" inputId="pcv_2nd_dose_given_date">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="pcv_2nd_dose_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="pcv_2nd_dose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="pcv_2nd_dose_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="blue">Rotavirus</td>
                      <td>
                        <div class="form-group">
                          <input type="text" (input)="changeRotavirusBrand($event)"
                              formControlName="rotavirus_2nddose_brand" class="form-control" placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="rotavirus_2nd_dose_date" inputId="rotavirus_2nd_dose_date"
                                [readonlyInput]="true" [disabled]="true">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="rotavirus_2nd_dose_given_date" inputId="rotavirus_2nd_dose_given_date">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="rotavirus_2nd_dose_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="rotavirus_2nd_dose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="rotavirus_2nd_dose_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>

                    <!-- 6 months  -->
                    <tr>
                      <td scope="row" rowspan="4" class="text-verticle"><label class="px-0">6 Months</label></td>
                      <td class="green">Pentavalent Vaccine <br> DTaP-IPV-Hib(2<sup>nd</sup>Dose) <br> Hepatitis B
                        (4<sup>th</sup>Dose)</td>
                      <td>
                        <div class="form-group">
                          <input type="text" (input)="changeDtapIpvHibBrand($event)"
                              formControlName="dtab_tpv_hib_3rd_brand" class="form-control" placeholder="Brand." />
                        </div>
                        <div class="form-group">
                          <input type="text" (input)="changeHapatitisBrand($event)"
                              formControlName="HepatitiesB_4th_dose_brand" class="form-control" placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="dtab_tpv_hib_3rd_dose_date" inputId="dtab_tpv_hib_3rd_dose_date"
                                [readonlyInput]="true" [disabled]="true">
                            </p-calendar>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="HepatitiesB_4th_dose_date" inputId="HepatitiesB_4th_dose_date"
                                [readonlyInput]="true" [disabled]="true">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="dtab_tpv_hib_3rd_given_date" inputId="HepatitiesB_4th_dose_given_date">
                            </p-calendar>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="HepatitiesB_4th_dose_given_date"
                                inputId="HepatitiesB_4th_dose_given_date">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="dtab_tpv_hib_3rd_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                        <div class="form-group">
                          <input type="number" formControlName="HepatitiesB_4th_dose_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="dtab_tpv_hib_3rd_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                        <div class="form-group">
                          <input type="text" formControlName="HepatitiesB_4th_dose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="dtab_tpv_hib_3rd_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                        <div class="form-group">
                          <input type="number" formControlName="HepatitiesB_4th_dose_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="blue">Rotateq X3</td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="rotateqx3_dose_brand" class="form-control"
                              placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="rotateqx3_dose_date" inputId="rotateqx3_dose_date"
                                [readonlyInput]="true" [disabled]="true">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="rotateqx3_dose_given_date" inputId="rotateqx3_dose_given_date">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="rotateqx3_dose_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="rotateqx3_dose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="rotateqx3_dose_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="green">OPV</td>
                      <td>
                        <div class="form-group">
                          <input type="text" (input)="changeOPVBrand($event)" formControlName="opv_2nd_dose_brand"
                              class="form-control" placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="opv_2nd_dose_date" inputId="opv_2nd_dose_date" [readonlyInput]="true"
                                [disabled]="true">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="opv_2nd_dose_given_date" inputId="opv_2nd_dose_given_date">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="opv_2nd_dose_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="opv_2nd_dose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="opv_2nd_dose_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="blue">PCV (3<sup>rd</sup>Dose)</td>
                      <td>
                        <div class="form-group">
                          <input type="text" (input)="changePCVBrand($event)" formControlName="pcv_3rd_dose_brand"
                              class="form-control" placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="pcv_3rd_dose_date" inputId="pcv_3rd_dose_date" [readonlyInput]="true"
                                [disabled]="true">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="pcv_3rd_dose_given_date" inputId="pcv_3rd_dose_given_date">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="pcv_3rd_dose_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="pcv_3rd_dose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="pcv_3rd_dose_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>

                    <!-- 12 month  -->
                    <tr>
                      <td scope="row" rowspan="2" class="text-verticle"><label class="px-0">12<br>Months</label></td>
                      <td class="green">MMR (1<sup>st</sup>Dose)</td>
                      <td>
                        <div class="form-group">
                          <input type="text" (input)="changeMMRBrand($event)" formControlName="mmr1st_dose_brand"
                              class="form-control" placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="mmr1st_dose_date" inputId="mmr1st_dose_date" [readonlyInput]="true"
                                [disabled]="true">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="mmr1st_dose_given_date" inputId="mmr1st_dose_given_date">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="mmr1st_dose_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="mmr1st_dose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="mmr1st_dose_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="blue">Chicken Pox (1<sup>st</sup>Dose)</td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="chickenPox_1st_dose_brand" class="form-control"
                              placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="chickenPox_1st_dose_date" inputId="chickenPox_1st_dose_date"
                                [readonlyInput]="true" [disabled]="true">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="chickenPox_1st_dose_given_date"
                                inputId="chickenPox_1st_dose_given_date">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="chickenPox_1st_dose_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="chickenPox_1st_dose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="chickenPox_1st_dose_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>

                    <!-- 15 month  -->
                    <tr>
                      <td scope="row" class="text-verticle"><label class="px-0">15<br>Months</label></td>
                      <td class="green">PCV (4<sup>th</sup>Dose)</td>
                      <td>
                        <div class="form-group">
                          <input type="text" (input)="changePCVBrand($event)" formControlName="pcv_4th_dose_brand"
                              class="form-control" placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="pcv_4th_dose_date" inputId="pcv_4th_dose_date" [readonlyInput]="true"
                                [disabled]="true">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="pcv_4th_dose_given_date" inputId="pcv_4th_dose_given_date">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="pcv_4th_dose_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="pcv_4th_dose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="pcv_4th_dose_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>

                    <!-- 18 month  -->
                    <tr>
                      <td scope="row" rowspan="3" class="text-verticle"><label class="px-0">18<br>Months</label></td>
                      <td class="blue">Tetravalent Vaccine DPT-Hib</td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="tetravalent_dpt_hiv_dose_brand" class="form-control"
                              placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="tetravalent_dpt_hiv_dose_date" inputId="tetravalent_dpt_hiv_dose_date"
                                [readonlyInput]="true" [disabled]="true">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="tetravalent_dpt_hiv_dose_given_date"
                                inputId="tetravalent_dpt_hiv_dose_given_date">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="tetravalent_dpt_hiv_dose_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="tetravalent_dpt_hiv_dose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="tetravalent_dpt_hiv_dose_batchNumber"
                              class="form-control" placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="blue">OPV</td>
                      <td>
                        <div class="form-group">
                          <input type="text" (input)="changeOPVBrand($event)" formControlName="opv_3rd_dose_brand"
                              class="form-control" placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="opv_3rd_dose_date" inputId="opv_3rd_dose_date" [readonlyInput]="true"
                                [disabled]="true">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="opv_3rd_dose_given_date" inputId="opv_3rd_dose_given_date">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="opv_3rd_dose_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="opv_3rd_dose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="opv_3rd_dose_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="blue">MMR</td>
                      <td>
                        <div class="form-group">
                          <input type="text" (input)="changeMMRBrand($event)" formControlName="mmr2nd_dose_brand"
                              class="form-control" placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="mmr2nd_dose_date" inputId="mmr2nd_dose_date" [readonlyInput]="true"
                                [disabled]="true">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="mmr2nd_dose_given_date" inputId="mmr2nd_dose_given_date">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="mmr2nd_dose_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="mmr2nd_dose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="mmr2nd_dose_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>

                    <!-- 5-6 Years -->
                    <tr>
                      <td scope="row" rowspan="3" class="text-verticle"><label class="px-0">5-6 Years</label></td>
                      <td class="green">DPT</td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="dpt_dose_brand" class="form-control"
                              placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="dpt_dose_date" inputId="dpt_dose_date" [readonlyInput]="true"
                                [disabled]="true">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="dpt_dose_given_date" inputId="dpt_dose_given_date">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="dpt_dose_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="dpt_dose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="dpt_dose_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="green">OPV</td>
                      <td>
                        <div class="form-group">
                          <input type="text" (input)="changeOPVBrand($event)" formControlName="opv_4th_dose_brand"
                              class="form-control" placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="card flex justify-content-center">
                          <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                              formControlName="opv_4th_dose_date" inputId="opv_4th_dose_date" [readonlyInput]="true"
                              [disabled]="true">
                          </p-calendar>
                        </div>
                      </td>
                      <td>
                        <div class="card flex justify-content-center">
                          <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                              formControlName="opv_4th_dose_given_date" inputId="opv_4th_dose_given_date">
                          </p-calendar>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="opv_4th_dose_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="opv_4th_dose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="opv_4th_dose_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="green">Chicken Pox-2</td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="chickenPox_2nd_dose_brand" class="form-control"
                              placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="chickenPox_2nd_dose_date" inputId="chickenPox_2nd_dose_date"
                                [readonlyInput]="true" [disabled]="true">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <div class="card flex justify-content-center">
                            <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                                formControlName="chickenPox_2nd_dose_given_date"
                                inputId="chickenPox_2nd_dose_given_date">
                            </p-calendar>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="chickenPox_2nd_dose_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="chickenPox_2nd_dose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="chickenPox_2nd_dose_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- 2nd table  -->
            <div class="col-12 col-xxxl-6">
              <div class="form-group table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr style="white-space: nowrap">
                      <th scope="col" class="text-center" rowspan="2">
                        <label class="subHeading px-0">Time</label>
                      </th>
                      <th scope="col" class="text-center" rowspan="2">
                        <label class="subHeading px-0">vaccine</label>
                      </th>
                      <th scope="col" class="text-center" rowspan="2">
                        <label class="subHeading px-0">Brand</label>
                      </th>
                      <th scope="col" class="text-center" colspan="2">
                        <label class="subHeading px-0">Date</label>
                      </th>
                      <th scope="col" class="text-center weight" rowspan="2">
                        <label class="subHeading px-0">Weight (K.g)</label>
                      </th>
                      <th scope="col" class="text-center" rowspan="2">
                        <label class="subHeading px-0">Name of HealthStaff</label>
                      </th>
                      <th scope="col" class="text-center" rowspan="2">
                        <label class="subHeading px-0">Batch No. of Vaccine</label>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-center">Due on</th>
                      <th class="text-center">Given on</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- 6 months  -->
                    <tr>
                      <td scope="row" class="text-verticle"><label class="px-0">> 6<br>Months</label></td>
                      <td class="blue">Influenza Vaccine</td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="influenza_dose_brand" class="form-control"
                              placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="card flex justify-content-center">
                          <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                              formControlName="influenza_dose_date" inputId="influenza_dose_date" [readonlyInput]="true"
                              [disabled]="true">
                          </p-calendar>
                        </div>
                      </td>
                      <td>
                        <div class="card flex justify-content-center">
                          <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                              formControlName="influenza_dose_given_date" inputId="influenza_dose_given_date">
                          </p-calendar>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="influenza_dose_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="influenza_dose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="influenza_dose_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>
                    <!-- > 1 years  -->
                    <tr>
                      <td scope="row" rowspan="2" class="text-verticle"><label class="px-0">> 1<br>Years</label></td>
                      <td class="green">Hepatities A Vaccine (1<sup>st</sup>Dose)</td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="HepatitiesA_1st_dose_brand" class="form-control"
                              placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="card flex justify-content-center">
                          <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                              formControlName="HepatitiesA_1st_dose_date" inputId="HepatitiesA_1st_dose_date"
                              [readonlyInput]="true" [disabled]="true">
                          </p-calendar>
                        </div>
                      </td>
                      <td>
                        <div class="card flex justify-content-center">
                          <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                              formControlName="HepatitiesA_1st_dose_given_date"
                              inputId="HepatitiesA_1st_dose_given_date">
                          </p-calendar>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="HepatitiesA_1st_dose_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="HepatitiesA_1st_dose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="HepatitiesA_1st_dose_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td class="blue">(2<sup>nd</sup>Dose)</td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="HepatitiesA_2nd_dose_brand" class="form-control"
                              placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="card flex justify-content-center">
                          <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                              formControlName="HepatitiesA_2nd_dose_date" inputId="HepatitiesA_2nd_dose_date"
                              [readonlyInput]="true" [disabled]="true">
                          </p-calendar>
                        </div>
                      </td>
                      <td>
                        <div class="card flex justify-content-center">
                          <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                              formControlName="HepatitiesA_2nd_dose_given_date"
                              inputId="HepatitiesA_2nd_dose_given_date">
                          </p-calendar>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="HepatitiesA_2nd_dose_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="HepatitiesA_2nd_dose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="HepatitiesA_2nd_dose_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>
                    <!-- > 1 years   -->
                    <tr>
                      <td scope="row" class="text-verticle"><label class="px-0">> 1<br>Years</label></td>
                      <td class="green">Meningococcal Vaccine</td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="meningococcal_dose_brand" class="form-control"
                              placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="card flex justify-content-center">
                          <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                              formControlName="meningococcal_dose_date" inputId="meningococcal_dose_date"
                              [readonlyInput]="true" [disabled]="true">
                          </p-calendar>
                        </div>
                      </td>
                      <td>
                        <div class="card flex justify-content-center">
                          <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                              formControlName="meningococcal_dose_given_date" inputId="meningococcal_dose_given_date">
                          </p-calendar>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="meningococcal_dose_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="meningococcal_dose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="meningococcal_dose_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>

                    <!-- > 2 years  -->
                    <tr>
                      <td scope="row" class="text-verticle"><label class="px-0"> > 2<br>Years</label></td>
                      <td class="blue">Typhoid Vaccine</td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="typhoid_dose_brand" class="form-control"
                              placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="card flex justify-content-center">
                          <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                              formControlName="typhoid_dose_date" inputId="typhoid_dose_date" [readonlyInput]="true"
                              [disabled]="true">
                          </p-calendar>
                        </div>
                      </td>
                      <td>
                        <div class="card flex justify-content-center">
                          <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                              formControlName="typhoid_dose_given_date" inputId="typhoid_dose_given_date">
                          </p-calendar>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="typhoid_dose_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="typhoid_dose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="typhoid_dose_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>

                    <!-- 11 -12 Years  -->
                    <tr>
                      <td scope="row" rowspan="3" class="text-verticle"><label class="px-0"> > 11-12 <br> Years</label>
                      </td>
                      <td class="green">HPV <br>(1<sup>st</sup>Dose)</td>
                      <td>
                        <div class="form-group">
                          <input type="text" (input)="changeHPVBrand($event)" formControlName="hpv_1st_dose_brand"
                              class="form-control" placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="card flex justify-content-center">
                          <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                              formControlName="hpv_1st_dose_date" inputId="hpv_1st_dose_date" [readonlyInput]="true"
                              [disabled]="true">
                          </p-calendar>
                        </div>
                      </td>
                      <td>
                        <div class="card flex justify-content-center">
                          <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                              formControlName="hpv_1st_dose_given_date" inputId="hpv_1st_dose_given_date">
                          </p-calendar>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="hpv_1st_dose_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="hpv_1st_dose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="hpv_1st_dose_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="blue">(2<sup>nd</sup>Dose)</td>
                      <td>
                        <div class="form-group">
                          <input type="text" (input)="changeHPVBrand($event)" formControlName="hpv_2nd_dose_brand"
                              class="form-control" placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="card flex justify-content-center">
                          <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                              formControlName="hpv_2nd_dose_date" inputId="hpv_2nd_dose_date" [readonlyInput]="true"
                              [disabled]="true">
                          </p-calendar>
                        </div>
                      </td>
                      <td>
                        <div class="card flex justify-content-center">
                          <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                              formControlName="hpv_2nd_dose_given_date" inputId="hpv_2nd_dose_given_date">
                          </p-calendar>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="hpv_2nd_dose_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="hpv_2nd_dose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="hpv_2nd_dose_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="green">(3<sup>rd</sup>Dose)</td>
                      <td>
                        <div class="form-group">
                          <input type="text" (input)="changeHPVBrand($event)" formControlName="hpv_3rd_dose_brand"
                              class="form-control" placeholder="Brand." />
                        </div>
                      </td>
                      <td>
                        <div class="card flex justify-content-center">
                          <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                              formControlName="hpv_3rd_dose_date" inputId="hpv_3rd_dose_date" [readonlyInput]="true"
                              [disabled]="true">
                          </p-calendar>
                        </div>
                      </td>
                      <td>
                        <div class="card flex justify-content-center">
                          <p-calendar dateFormat="dd/mm/yy" [showIcon]="true" placeholder="dd/mm/yy"
                              formControlName="hpv_3rd_dose_given_date" inputId="hpv_3rd_dose_given_date">
                          </p-calendar>
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="hpv_3rd_dose_weight" class="form-control"
                              placeholder="Weight." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="text" formControlName="hpv_3rd_dose_staff" class="form-control"
                              placeholder="Name of health staff." />
                        </div>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" formControlName="hpv_3rd_dose_batchNumber" class="form-control"
                              placeholder="Batch Number." />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- Notes  -->
              <div class="form-group table-responsive">
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <td class="blue"><b>Typhoid -</b> It can be given every 3 years after the age of 2.</td>
                    </tr>
                    <tr>
                      <td class="green"><b>PCV -</b> If the primary doses are missed, take a single dose at the end of 2
                        years.</td>
                    </tr>
                    <tr>
                      <td class="blue"><b>Rotavirus -</b> Both the doses of rotavirus has to be finished before 6 months
                        of age.</td>
                    </tr>
                    <tr>
                      <td class="green"><b>FLU -</b> If flu vaccine is administered for the first time, a booster is
                        repeated after 1 month then repeat yearly.</td>
                    </tr>
                    <tr>
                      <td class="blue">
                        <b>Varicella -</b> Second dose of vaccine can be given 4 month to 1 year after 1st dose with
                        high risk group and given 5 yearly
                        after that. It is given as double dose between 9-24 month and as single dose after 24 months.
                      </td>
                    </tr>
                    <tr>
                      <td class="green">
                        <b>Meningococcal</b> - Aramen is given as double dose between 9-24 months and as a single dose
                        after 24 months. Mencevax is to be
                        repeated after 3 years after 1st dose in 2-6 years of age group with high risk and given 5
                        yearly after that.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </p-accordionTab>
      <!-- immunization form End -->
      <p-accordionTab header="Remarks">
        <div class="add-listings-box pb-0">
          <h3>Remarks</h3>
          <ng-container>
            <div class="row align-items-end">
              <!-- Use align-items-end to align button slightly higher -->
              <div class="col-12">
                <div class="form-group">
                  <div class="pl-0 checkbox-list">
                    <input type="text" formControlName="remarks" class="form-control" placeholder="Remarks" />
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </p-accordionTab>
    </p-accordion>
    <div class="add-listings-btn" style="text-align: right">
      <button type="submit" *ngIf="checkPermission(37)">Submit</button>
    </div>
  </form>
</div>