import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonApiService } from 'src/app/services/common-api.service';
import * as moment from 'moment';
import { FeedingAssesmentFormService } from 'src/app/services/feeding-assesment-form.service';
import { MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-feeding-assesment-form',
  templateUrl: './feeding-assesment-form.component.html',
  styleUrls: ['./feeding-assesment-form.component.scss'],
  providers: [MessageService]
})
export class FeedingAssesmentFormComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Breast Feeding Assessment',
      subtitle: 'Dashboard'
    }
  ]
  public interestedSleepyYes: boolean = true;
  public interestedSleepyNo: boolean = true;
  public cluesButNoAttachmentYes: boolean = true;
  public cluesButNoAttachmentNo: boolean = true;
  public attachesButFallsAsleepYes: boolean = true;
  public attachesButFallsAsleepNo: boolean = true;
  public attachShortLongPauseYes: boolean = true;
  public attachShortLongPauseNo: boolean = true;
  public longRhythmShortFeedYes: boolean = true;
  public longRhythmShortFeedNo: boolean = true;
  public longRhythmSustainedPeriodYes: boolean = true;
  public longRhythmSustainedPeriodNo: boolean = true;
  public normalSkinAndToneYes: boolean = true;
  public normalSkinAndToneNo: boolean = true;
  public appropriateWeightGainYes: boolean = true;
  public appropriateWeightGainNo: boolean = true;
  babyDetails: any = [];
  babyGender: any;
  date: any;
  currentDate: Date = new Date();;
  selectedDate: Date = null; // Initialize with today's date
  month: any = '';
  years: any = '';
  days: any = '';
  selectedGender: any;
  selectedNumber: any;
  formData: any;
  formObject: any;
  ageList: any = [];
  // genderArray: any = [
  //   { name: 'Male', value: 'male' },
  //   { name: 'Female', value: 'female' },
  // ];
  feedingAssesmentForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private common_service: CommonApiService,
    private feeding_service: FeedingAssesmentFormService,
    private messageService: MessageService,
    private router:Router,
    private spinner:NgxSpinnerService
  ) {
    this.feedingAssesmentForm = this.fb.group({
      baby_details_id: new FormControl('', [Validators.required]),
      Baby_is_not_interested_when_offered_to_breastfeed_sleepy: new FormGroup({
        question: new FormControl('Baby is not interested when offered to breastfeed, sleepy.'),
        answer: new FormControl(''),
      }),

      Baby_is_showing_feeding_clues_but_not_attaching: new FormGroup({
        question: new FormControl('Baby is showing feeding clues but not attaching.'),
        answer: new FormControl(''),
      }),
      Attaches_at_the_breast_but_quickly_falls_asleep: new FormGroup({
        question: new FormControl('Attaches at the breast but quickly falls asleep.'),
        answer: new FormControl(''),
      }),
      Attaches_for_short_bursts_with_long_pause: new FormGroup({
        question: new FormControl('Attaches for short bursts with long pause.'),
        answer: new FormControl(''),
      }),
      Attaches_well_with_long_rhythmical_sucking_and_swallowing_for_a_short_feed: new FormGroup({
        question: new FormControl('Attaches well with long rhythmical sucking and swallowing for a short feed.'),
        answer: new FormControl(''),
      }),
      Attaches_well_for_a_sustained_period_with_long_rhythmical_sucking_and_swallowing: new FormGroup({
        question: new FormControl('Attaches well for a sustained period with long rhythmical sucking and swallowing.'),
        answer: new FormControl(''),
      }),
      Normal_skin_color_and_tone: new FormGroup({
        question: new FormControl('Normal skin color and tone.'),
        answer: new FormControl(''),
      }),
      Gaining_weight_appropriately: new FormGroup({
        question: new FormControl('Gaining weight appropriately.'),
        answer: new FormControl(''),
      }),
      Other_concerns: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.spinner.show();
    this.common_service.getBabyDetails().subscribe((res: any) => {
      this.spinner.hide()
      console.log(res);
      this.babyDetails = res?.data;
    });
  }
/* -------------------------------------------------------------------------- */
  /*            //Soft Reloading            */
  /* -------------------------------------------------------------------------- */
  softReload() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });}
  /* -------------------------------------------------------------------------- */
  /*               // get Baby Details on Baby name click function              */
  /* -------------------------------------------------------------------------- */
  getBabyDetails(event: any) {
    let data = this.babyDetails?.filter((res: any) => res?.id == event?.value);
    console.log(data[0].date_of_birth);
    let firstLetter = data[0]?.gender.toLowerCase();
    let capitalize =
      firstLetter.slice(0, 1).toUpperCase() +
      firstLetter.slice(1, firstLetter.length);
    this.babyGender = capitalize;
    this.selectedDate = data[0].date_of_birth
    // Manually parse the date string in DD/MM/YYYY format
    const dateParts = data[0].date_of_birth.split('/');
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Months are 0-indexed in JavaScript
    const year = parseInt(dateParts[2], 10);

    const selectedDate = moment([year, month, day]);
    const today = moment();
    console.log(selectedDate);

    // Calculate the difference in years, months, and days
    const years = today.diff(selectedDate, 'years');
    selectedDate.add(years, 'years');

    const months = today.diff(selectedDate, 'months');
    selectedDate.add(months, 'months');

    const days = today.diff(selectedDate, 'days');

    this.years = years;
    this.month = months;
    this.days = days;
  }

  submitFeedingAssessment(formSubmitted) {
    this.spinner.show()
    window.scrollTo(0, 0);
    if (formSubmitted.valid) {

      this.formData = this.feedingAssesmentForm.value;
      console.log(this.formData, "?????");

      this.formObject = {
        baby_details_id: this.feedingAssesmentForm.value.baby_details_id,
        assessment_json: this.formData,
      };
      delete this.formData['baby_details_id']
      console.log('submit', this.formData, this.feedingAssesmentForm);
      // this.spinner.show();
      this.feeding_service
        .createFeedingAssessmentForm(this.formObject)
        .then((res: any) => {
          this.spinner.hide()

          console.log('submit res', res);
          this.messageService.add({ severity: 'success', summary: 'Success', detail: res?.message });
          setTimeout(() => {
            this.softReload();
          }, 400);
          // this.spinner.hide();
        },
          (error: any) => {
            this.spinner.hide()
            // Handle any errors that occurred during the API call
          
            this.messageService.add({ severity: 'error', summary: 'Error', detail: error?.message });

          }
        );
    }
    else {
      this.spinner.hide()
      this.messageService.add({
        severity: 'error', summary: 'Error', detail: 'Please Fill All the Details!',
      });
    }
  }

  calculateAgeAndYears() {
    //   if (this.selectedDate) {
    //     const today = new Date();
    //     const selectedDate = new Date(this.selectedDate);
    //     const time = today.getTime() - selectedDate.getTime();
    //     console.log(today, selectedDate, time);
    //     const date = time / (1000 * 3600 * 24);
    //     console.log(date / 30);

    //     var diff = Math.floor(today.getTime() - selectedDate.getTime());
    //     var day = 1000 * 60 * 60 * 24;
    //     this.days = Math.floor(diff / day);
    //     this.month = Math.floor(this.days / 31);
    //     this.years = Math.floor(this.month / 12);
    //   } else {
    //     this.month = '';
    //     this.years = '';
    //     this.years = '';
    //     console.log(this.month, this.years, this.days);
    //   }
  }

  handleInput(event: any, value: string) {
    if (value == 'interestedSleepy') {
      if (event.target.value == 'Yes') {
        this.interestedSleepyYes = !this.interestedSleepyYes;
      } else if (event.target.value == 'No') {
        this.interestedSleepyNo = !this.interestedSleepyNo;
      }
    } else if (value == 'cluesButNoAttachment') {
      if (event.target.value == 'Yes') {
        this.cluesButNoAttachmentYes = !this.cluesButNoAttachmentYes;
      } else if (event.target.value == 'No') {
        this.cluesButNoAttachmentNo = !this.cluesButNoAttachmentNo;
      }
    } else if (value == 'attachesButFallsAsleep') {
      if (event.target.value == 'Yes') {
        this.attachesButFallsAsleepYes = !this.attachesButFallsAsleepYes;
      } else if (event.target.value == 'No') {
        this.attachesButFallsAsleepNo = !this.attachesButFallsAsleepNo;
      }
    } else if (value == 'attachShortLongPause') {
      if (event.target.value == 'Yes') {
        this.attachShortLongPauseYes = !this.attachShortLongPauseYes;
      } else if (event.target.value == 'No') {
        this.attachShortLongPauseNo = !this.attachShortLongPauseNo;
      }
    } else if (value == 'longRhythmShortFeed') {
      if (event.target.value == 'Yes') {
        this.longRhythmShortFeedYes = !this.longRhythmShortFeedYes;
      } else if (event.target.value == 'No') {
        this.longRhythmShortFeedNo = !this.longRhythmShortFeedNo;
      }
    } else if (value == 'longRhythmSustainedPeriod') {
      if (event.target.value == 'Yes') {
        this.longRhythmSustainedPeriodYes = !this.longRhythmSustainedPeriodYes;
      } else if (event.target.value == 'No') {
        this.longRhythmSustainedPeriodNo = !this.longRhythmSustainedPeriodNo;
      }
    } else if (value == 'normalSkinAndTone') {
      if (event.target.value == 'Yes') {
        this.normalSkinAndToneYes = !this.normalSkinAndToneYes;
      } else if (event.target.value == 'No') {
        this.normalSkinAndToneNo = !this.normalSkinAndToneNo;
      }
    } else if (value == 'appropriateWeightGain') {
      if (event.target.value == 'Yes') {
        this.appropriateWeightGainYes = !this.appropriateWeightGainYes;
      } else if (event.target.value == 'No') {
        this.appropriateWeightGainNo = !this.appropriateWeightGainNo;
      }
    }
  }
}
