import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { async } from 'rxjs/internal/scheduler/async';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UtilityTsService {
  title: any;
  api: any = [];
  apiBaseUrl: string = environment.api_base_url;
  constructor(private http: HttpClient) {}
  // Get Nanny plan
  getNannyPlan() {
    return this.http.get(this.apiBaseUrl + 'get-all-nannyplan');
  }
  async getData(plan: string) {
    try {
      const res: any = await this.getNannyPlan().toPromise();
      console.log(res.data, 'dataaaaa');
      this.title = res?.data?.filter((item: any) => item.heading_name === plan);
      console.log('gettttt', this.title);

      return this.title[0]?.id;
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle the error as needed
    }
  }
  public parseISOString(s: string): Date | null {
    if (!s) return null;
    return new Date(s);
  }
}
