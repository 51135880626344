<p>feeding-assessment-list works!</p>
<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->
<!-- import { DialogModule } from 'primeng/dialog'; -->

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item">{{Content.subTitle}}</li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>

    <!-- <div class="add-listings-box">
        <div class="row justify-content-between align-items-center">
            <div class="form-group col-4">
                <label class="p-0">Name <span class="required">*</span></label>
                <input type="text" name="name" class="form-control" placeholder="Name" (change)="getInput($event)" />
            </div>
            <div class="add-listings-btn mb-0 col-4 text-end">
                <button type="submit" (click)="addTableData()">Add</button>
            </div>
        </div>
    </div> -->
    <div class="add-listings-box">
        <div class="Master-view-box">
            <div class="theader d-flex justify-content-between">
                <div class="d-flex w-100">
                    <span class="p-input-icon-left ml-auto mr-2 w-100%">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" class="form-control "
                            style="text-align: left; padding-left: 20%; height: 45px !important" scriptInjection
                            placeholder="Search Keyword" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                            [(ngModel)]="filterVal" />
                    </span>
                    <p-button type="button" class="mx-2" icon="bx bx-reset" tooltipPosition="bottom"
                        (click)="reset(dt1)"></p-button>
                </div>
                <div class="add-listings-btn" style="text-align: right;">
                    <a routerLink="/portal/three-year-and-above-year-growth-monitoring" class="nav-link">
                        <button type="submit">Add</button>

                    </a>
                </div>
                <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="onPDFExport()"
                    class="p-button-warning ms-2" pTooltip="PDF" tooltipPosition="bottom"></button>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-lg-12 col-md-12">
                <p-table #dt1 [value]="allData" dataKey="name" [globalFilterFields]="['name', 'date_of_birth', 'age']"
                    [rowsPerPageOptions]="[10, 25, 50]" [rows]="10" [showCurrentPageReport]="true"
                    responsiveLayout="scroll" [paginator]="true" currentPageReportTemplate="Showing {first} to
                {last} of {totalRecords} entries">
                    <ng-template pTemplate="header">
                        <tr>
                            <th></th>
                            <th style="min-width: 62px">S. No.</th>
                            <th>Name</th>
                            <th>Date Of Birth</th>
                            <th>Days</th>
                            <th>Months</th>
                            <th>Years</th>
                        </tr>

                    </ng-template>
                    <ng-template pTemplate="body" let-product let-expanded="expanded" let-i="rowIndex">
                        <tr>
                            <td>
                                <button type="button" pButton pRipple [pRowToggler]="product"
                                    class="p-button-text p-button-rounded p-button-plain"
                                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                                    (click)="getData(product.baby_id)"></button>
                            </td>
                            <td>{{i+1}}</td>
                            <td>{{product.name | titlecase }}</td>
                            <td>{{product.date_of_birth}}</td>
                            <td>{{product.days}}</td>
                            <td>{{product.months}}</td>
                            <td>{{product.years}}</td>

                        </tr>
                    </ng-template>
                    <ng-template pTemplate="rowexpansion" let-product>
                        <tr>
                            <td colspan="7" class="inner-data">
                                <div class="p-3 py-0">
                                    <p-table [value]="product.development_milestones
                                    " dataKey="id">
                                        <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="id" class="serial-width">Sr No.</th>
                            <th pSortableColumn="customer">Action</th>
                            <th pSortableColumn="date">Date</th>

                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-order let-expanded="expanded" let-i="rowIndex">
                        <tr>
                            <td>{{i+1}}</td>
                            <td><button pButton pRipple class="p-button-rounded p-button-text p-button-info"
                                    pTooltip="View" tooltipPosition="bottom"
                                    (click)="showMaximizableDialog(order.id)"><i class="far fa-eye"></i></button>
                                <button pButton pRipple class="p-button-rounded p-button-icon " pTooltip="Edit"
                                    tooltipPosition="bottom" (click)="editOnCLick(order.id)"><i
                                        class="bx bx-pencil"></i>
                                </button>
                            </td>
                            <td>{{ order?.created_at?(order?.created_at | date: 'dd-MM-yyyy' ): "-" }}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="6">There are no order for this product yet.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            </td>
            </tr>
            </ng-template>
            </p-table>

        </div>

        <!----------------------------------------------------------- view dialog --------------------------------------------------------->
        <!-- ***************************************************************************************************************** -->
        <p-dialog header="Baby Details" [(visible)]="displayMaximizable" [modal]="true" [style]="{ width: '90vw' }"
            [draggable]="false" [resizable]="false">
            <!-- Start -->
            <ng-container>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="my-profile-box">
                            <form>
                                <!-- Baby detail -->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Name :</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{babyData?.name?? '-' | titlecase }}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12
                                                col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Gender :</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{babyData?.gender ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Date of Birth :</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{babyData?.date_of_birth ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Days :</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{babyData?.days ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Months :</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{babyData?.months ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Years :</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{babyData?.years ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <h3>Height Weight</h3>
                                <div class="row" *ngFor="let data of health">
                                    <div class="col-xl-4 col-lg-12
                                    col-md-12">
                                        <div class="form-group">

                                            <label>Time</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{ data?.time | date:'dd-MM-yyyy'??'-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12
                                    col-md-12">
                                        <div class="form-group">


                                            <label>Height</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{ data?.height ??'-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12
                                    col-md-12">
                                        <div class="form-group">
                                            <label>Weight</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{ data?.weight ??'-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <h3>Coordination & Daily Activities</h3>
                                <!-- Enjoys and seeks out various ways to -->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Enjoys and seeks out various ways to
                                                move and play</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail?.enjoyandplay ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail?.enjoyandplay_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!--                             move and play END -->

                                <!-- Has adequate endurance and strength
                            to play with peers -->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Has adequate endurance and strength
                                                to play with peers</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.enduranceandstrength ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.enduranceandstrength_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!-- Has adequate endurance and strength
                            to play with peers END -->

                                <!-- Coordinates movements needed to play
                            and explore -->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Coordinates movements needed to play
                                                and explore</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.coordinateandexplore ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.coordinateandexplore_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!-- Coordinates movements needed to play
                            and explore END -->

                                <!-- Usually walks with heel toe pattern
                            and not primarily on toes -->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Usually walks with heel toe pattern
                                                and not primarily on toes</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.healtoewalk ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.healtoewalk_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!--                             Usually walks with heel toe pattern
                            and not primarily on toes END -->

                                <!-- Can maintain balance to catch ball or
                            when gently bumped by peers -->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Can maintain balance to catch ball or
                                                when gently bumped by peers</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.balancetocatch ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.balancetocatch_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!--                             Can maintain balance to catch ball or
                            when gently bumped by peers END -->

                                <!-- Able to walk and maintain balance
                            over uneven surfaces -->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Able to walk and maintain balance
                                                over uneven surfaces</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.balanceonunevensurfaces ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.balanceonunevensurfaces_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!-- Able to walk and maintain balance
                            over uneven surfaces END -->

                                <!--Walks through new room without
                            bumping into objects or people-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Walks through new room without
                                                bumping into objects or people</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.walkinnewroomnobumping ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.walkinnewroomnobumping_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!-- Walks through new room without
                            bumping into objects or people END -->

                                <!--Only leans on furniture, walls or
                            people and sits slumped over when tired-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Only leans on furniture, walls or
                                                people and sits slumped over when tired</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.leansoverandsitsslumped ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.leansoverandsitsslumped_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!-- Only leans on furniture, walls or
                            people and sits slumped over when tired END -->

                                <!--Able to throw and attempt to catch
                            ball without losing balance-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Able to throw and attempt to catch
                                                ball without losing balance</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.throwandcatchwithbalance ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.throwandcatchwithbalance_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!-- Able to throw and attempt to catch
                            ball without losing balance END -->

                                <!--Coordinates both hands to play, such
                            as swinging a bat or opening a container-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Coordinates both hands to play, such
                                                as swinging a bat or opening a container</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.bothhandstoplay ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.bothhandstoplay_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!-- Coordinates both hands to play, such
                            as swinging a bat or opening a container END -->

                                <!--Coordinates hand and finger movement
                            needed to participate in table top games and
                            activities-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Coordinates hand and finger movement
                                                needed to participate in table top games and
                                                activities</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.partsingamesandactivities ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.partsingamesandactivities_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!-- Coordinates hand and finger movement
                            needed to participate in table top games and
                            activities END -->

                                <!--Able to color and begin to imitate
                            shapes-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Able to color and begin to imitate
                                                shapes</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.colorandshapes ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.colorandshapes_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!-- Able to color and begin to imitate
                            shapes END -->

                                <!--Uses appropriate force when playing
                            with peers or pets or when holding objects-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Uses appropriate force when playing
                                                with peers or pets or when holding objects</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.usesforcewhenplaying ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.usesforcewhenplaying_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!-- Uses appropriate force when playing
                            with peers or pets or when holding objects END -->

                                <!--Able to maintain good sitting posture
                            needed to sit in chair-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Able to maintain good sitting posture
                                                needed to sit in chair</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.goodsittingposture ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.goodsittingposture_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!-- Able to maintain good sitting posture
                            needed to sit in chair END -->

                                <!--Able to use utensils to pick up
                            pieces of food-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Able to use utensils to pick up
                                                pieces of food</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.useutensilsforfood ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.useutensilsforfood_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!--Able to use utensils to pick up
                            pieces of food END -->

                                <!--Has an established sleep schedule-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Has an established sleep schedule</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.establishedsleep ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.establishedsleep_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!-- Has an established sleep schedule END -->

                                <!--Able to self-calm to fall asleep-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Able to self-calm to fall asleep</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.selfcalmfallasleep ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.selfcalmfallasleep_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!-- Able to self-calm to fall asleep END -->

                                <!--Able to self-calm to fall asleep-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Able to self-calm to fall asleep</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.selfcalmfallasleep ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.selfcalmfallasleep_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!-- Able to self-calm to fall asleep END -->

                                <!--Able to tolerate and wear textures of
                            new and varied articles of clothes-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Able to tolerate and wear textures of
                                                new and varied articles of clothes</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.wearnewclothes ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.wearnewclothes_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!-- Able to tolerate and wear textures of
                            new and varied articles of clothes END -->

                                <!--Able to take appropriate bites of
                            food, does not always stuff mouth-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Able to take appropriate bites of
                                                food, does not always stuff mouth</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.appropriatebitesoffood ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.appropriatebitesoffood_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!-- Able to take appropriate bites of
                            food, does not always stuff mouth END -->

                                <!--Able to tolerate haircuts and nail
                            cutting without crying-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Able to tolerate haircuts and nail
                                                cutting without crying</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.toleratehairandnailcut ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.toleratehairandnailcut_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!-- Able to tolerate haircuts and nail
                            cutting without crying END -->

                                <!--Able to adapt to changes in routine-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Able to adapt to changes in routine</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.adaptnewroutine ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.adaptnewroutine_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!-- Able to adapt to changes in routine END -->

                                <!--Takes bath or shower, although
                            preference may be present-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Takes bath or shower, although
                                                preference may be present</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.takesbathandshower ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.takesbathandshower_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!-- Takes bath or shower, although
                            preference may be present END -->

                                <!--Eats a diet rich in various foods,
                            temperatures, and textures-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Eats a diet rich in various foods,
                                                temperatures, and textures</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.eatsvarietyofdiet ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.eatsvarietyofdiet_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!-- Eats a diet rich in various foods,
                            temperatures, and textures END -->

                                <!--Drinks from a cup and straw without
                            dribbling-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Drinks from a cup and straw without
                                                dribbling</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.drinkswithoutdribble ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.drinkswithoutdribble_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!-- Drinks from a cup and straw without
                            dribbling END -->

                                <!--Need for crashing, bumping and moving
                            fast does not interfere with participation in
                            activities and family life-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Need for crashing, bumping and moving
                                                fast does not interfere with participation in
                                                activities and family life</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.notactiveinfamily ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.notactiveinfamily_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!--Need for crashing, bumping and moving
                            fast does not interfere with participation in
                            activities and family life END -->

                                <!--Able to complete everyday tasks that
                            have multiple steps, such as dressing-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Able to complete everyday tasks that
                                                have multiple steps, such as dressing</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.completemultiplesteptasks ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.completemultiplesteptasks_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!--Able to complete everyday tasks that
                            have multiple steps, such as dressing END -->

                                <!--Frequently wakes up rested and ready
                            for a new day-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Frequently wakes up rested and ready
                                                for a new day</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.wakesrestedandready ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.wakesrestedandready_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!--Frequently wakes up rested and ready
                            for a new day END -->

                                <!-- Sensory -->
                                <h3>Sensory</h3>
                                <!--Can count 1-10-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Can count 1-10</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.cancount ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.cancount_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!--Can count 1-10 END -->

                                <!--Knows shapes-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Knows shapes</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.knowshapes ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.knowshapes_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!--Knows shapes END -->

                                <!--Develops friendships-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Develops friendships</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.developsfriendships ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.developsfriendships_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!--Develops friendships END -->

                                <!--Expresses emotions-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Expresses emotions</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.expressesemotions ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.expressesemotions_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!--Expresses emotions END -->

                                <!--Able to follow rules-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Able to follow rules</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.followrules ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.followrules_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!--Able to follow rules END -->

                                <!--Able to sit and pay attention (e.g.
                            mat time)-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Able to sit and pay attention (e.g.
                                                mat time)</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.payattention ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.payattention_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!--Able to sit and pay attention (e.g.
                            mat time) END -->

                                <!--20/20 vision-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">20/20 vision</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.vision ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.vision_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!--20/20 vision END -->

                                <!--Able to write numbers and letters-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Able to write numbers and letters</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.writenumbersandletters ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.writenumbersandletters_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!--Able to write numbers and letters END -->

                                <!--Begins to write simple sentences-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Begins to write simple sentences</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.writesentences ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.writesentences_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!--Begins to write simple sentences END -->

                                <!--Begins to recognise others
                            perspectives-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Begins to recognise others
                                                perspectives</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.recogniseothersperspective ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.recogniseothersperspective_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!--Begins to recognise others
                            perspectives END -->

                                <!--Knows left and right-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Knows left and right</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.leftright ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.leftright_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!--Knows left and right END -->

                                <!--Begins to reason and argue; uses
                            words like why and because-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Begins to reason and argue; uses
                                                words like why and because</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.reasonandargue ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.reasonandargue_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!--Begins to reason and argue; uses
                            words like why and because END -->

                                <!--Categorizes objects: “These are toys;
                            These are books”-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Categorizes objects: “These are toys;
                                                These are books”</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.categoriseobjects ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.categoriseobjects_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!--Categorizes objects: “These are toys;
                            These are books” END -->

                                <!--Understand concepts like yesterday,
                            today, and tomorrow-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Understand concepts like yesterday,
                                                today, and tomorrow</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.yesterdaytodaytomorrow ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.yesterdaytodaytomorrow_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!--Understand concepts like yesterday,
                            today, and tomorrow END -->

                                <!--Can copy complex shapes, such as a
                            diamond-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Can copy complex shapes, such as a
                                                diamond</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.copycomplexshapes ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.copycomplexshapes_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!--Can copy complex shapes, such as a
                            diamond END -->

                                <!--Sounding out simple words like
                            “hang”, “neat”, “jump” and “sank”-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Sounding out simple words like
                                                “hang”, “neat”, “jump” and “sank”</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.sayssimplewords ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.sayssimplewords_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!--Sounding out simple words like
                            “hang”, “neat”, “jump” and “sank” END -->

                                <!--Can sit at a desk , follow teacher
                            instructions, and independently do simple in-class
                            assignments-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Can sit at a desk , follow teacher
                                                instructions, and independently do simple in-class
                                                assignments</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.canattendclass ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.canattendclass_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!--Can sit at a desk , follow teacher
                            instructions, and independently do simple in-class
                            assignments END -->

                                <!--Forms numbers and letters correctly-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Forms numbers and letters correctly</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.formsnumbersandletters ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.formsnumbersandletters_description ?? '-'}}" />
                                        </div>
                                    </div>
                                </div>
                                <!--Forms numbers and letters correctly END -->

                                <!--Recognises others perspective-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Recognises others perspective</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.recogniseothersperspective ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.recogniseothersperspective_description ?? '-'}}"
                                                readonly />
                                        </div>
                                    </div>
                                </div>
                                <!--Recognises others perspective END -->

                                <!--Develops a longer attention span-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Develops a longer attention span</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.longattention ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.longattention_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!--Develops a longer attention span END -->

                                <!--Willing to take on more
                            responsibility (i.e. chores)-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Willing to take on more
                                                responsibility (i.e. chores)</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.onemoreresponsibility ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.onemoreresponsibility_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!--Willing to take on more
                            responsibility (i.e. chores) END -->

                                <!--Understands fractions and the concept
                            of space-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Understands fractions and the concept
                                                of space</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.fractionandspace ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.fractionandspace_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!--Understands fractions and the concept
                            of space END -->

                                <!--Understands money-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Understands money</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.understandmoney ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.understandmoney_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!--Understands money END -->

                                <!--Can tell time-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Can tell time</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.telltime ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.telltime_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!--Can tell time END -->

                                <!--Can name months and days of the week
                            in order-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Can name months and days of the week
                                                in order</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.monthsdaysinorder ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.monthsdaysinorder_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!--Can name months and days of the week
                            in order END -->

                                <!--Enjoys reading a book on their own-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Enjoys reading a book on their own</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.enjoyreadingbook ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.enjoyreadingbook_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!--Enjoys reading a book on their own END -->

                                <h3>Communication</h3>

                                <!--Eats the same foods as the rest of
                            the family-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Eats the same foods as the rest of
                                                the family</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.eatssameasfamily ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.eatssameasfamily_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!--Eats the same foods as the rest of
                            the family END -->

                                <!--Feeds well with utensils-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Feeds well with utensils</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.feedswellutensils ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.feedswellutensils_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!--Feeds well with utensils END -->

                                <!--Has definite food likes and dislikes
                            & refuse certain foods-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Has definite food likes and dislikes
                                                & refuse certain foods</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.likedislikesfood ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.likedislikesfood_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!--Has definite food likes and dislikes
                            & refuse certain foods END -->

                                <!--Possibly start to become a “picky
                            eater” (see post next week!)-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Possibly start to become a “picky
                                                eater” (see post next week!)</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.pickyeater ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.pickyeater_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!--Possibly start to become a “picky
                            eater” (see post next week!) END -->

                                <!--Wipes own mouth and hands with a
                            napkin or cloth-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Wipes own mouth and hands with a
                                                napkin or cloth</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.wipeshandsnmouth ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.wipeshandsnmouth_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!--Wipes own mouth and hands with a
                            napkin or cloth END -->

                                <!--Starts to serve themselves at the
                            table with some spills-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Starts to serve themselves at the
                                                table with some spills</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.servesself ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.servesself_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!--Starts to serve themselves at the
                            table with some spills END -->

                                <!--Pours liquids into cup from a small
                            container-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Pours liquids into cup from a small
                                                container</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.poursliquid ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.poursliquid_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!--Pours liquids into cup from a small
                            container END -->

                                <h3>Play and Social Skills</h3>

                                <!--Interested in, aware of, and able to
                            maintain eye contact with others-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Interested in, aware of, and able to
                                                maintain eye contact with others</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.eyecontact ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.eyecontact_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!-- Interested in, aware of, and able to
                            maintain eye contact with others END -->

                                <!--Enjoys playing in small groups with
                            children of the same age-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Enjoys playing in small groups with
                                                children of the same age</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.playingsamllgroup ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.playingsamllgroup_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!-- Enjoys playing in small groups with
                            children of the same age END -->

                                <!--Able to initiate and play with
                            another child of the same age-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Able to initiate and play with
                                                another child of the same age</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.initiateplay ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.initiateplay_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!-- Able to initiate and play with
                            another child of the same age END -->

                                <!--Turns head in response to name being
                            called-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Turns head in response to name being
                                                called</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.turnsinresponse ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.turnsinresponse_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!--Turns head in response to name being
                            called END -->

                                <!--Interested in exploring varied
                            environments, such as new playground or friend’s
                            house-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Interested in exploring varied
                                                environments, such as new playground or friend’s
                                                house</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.exploringnew ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.exploringnew_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!--Interested in exploring varied
                            environments, such as new playground or friend’s
                            house END -->

                                <!--Able to play in new social situations-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Able to play in new social situations</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.newsocialplay ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.newsocialplay_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!--Able to play in new social situations END -->

                                <!--Enjoys playing with variety of toys
                            intended for children of the same age-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Enjoys playing with variety of toys
                                                intended for children of the same age</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.playvarietytoys ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.playvarietytoys_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!--Enjoys playing with variety of toys
                            intended for children of the same age END -->

                                <!--Aware of risky and unsafe
                            environments and behavior-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Aware of risky and unsafe
                                                environments and behavior</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.aware ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.aware_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!--Aware of risky and unsafe
                            environments and behavior END -->

                                <!--Participates in crafts/activities
                            that involve wet textures, such as glue-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Participates in crafts/activities
                                                that involve wet textures, such as glue</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.craftswithglue ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.craftswithglue_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!--Participates in crafts/activities
                            that involve wet textures, such as glue END -->

                                <!--Enjoys rough but safe play with
                            peers, siblings, or parents-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Enjoys rough but safe play with
                                                peers, siblings, or parents</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.roughsafeplay ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.roughsafeplay_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!-- Enjoys rough but safe play with
                            peers, siblings, or parents END -->

                                <!--Enjoys swinging on playground swings-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Enjoys swinging on playground swings</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.swinging ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.swinging_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!-- Enjoys swinging on playground swings END -->

                                <!--Enjoys playing with new toys-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Enjoys playing with new toys</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.newtoysplay ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.newtoysplay_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!-- Enjoys playing with new toys END -->

                                <!--Able to locate objects you are
                            pointing to-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Able to locate objects you are
                                                pointing to</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.locatepointing ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.locatepointing_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!-- Able to locate objects you are
                            pointing to END -->

                                <!--Enjoys sitting to look at or listen
                            to a book-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Enjoys sitting to look at or listen
                                                to a book</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.enjoysitting ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.enjoysitting_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!-- Enjoys sitting to look at or listen
                            to a book END -->

                                <!--Able to play with one toy or theme
                            for 15 minute periods of time-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Able to play with one toy or theme
                                                for 15 minute periods of time</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.playtoyforawhile ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.playtoyforawhile_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!-- Able to play with one toy or theme
                            for 15 minute periods of time END -->

                                <!--Able to play with one toy or theme
                            for 15 minute periods of time-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Able to play with one toy or theme
                                                for 15 minute periods of time</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.playtoyforawhile ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.playtoyforawhile_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!-- Able to play with one toy or theme
                            for 15 minute periods of time END -->

                                <h3>Self-Expression</h3>
                                <!--Happy when not hungry or tired-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Happy when not hungry or tired</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.happywhennothungry ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.happywhennothungry_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!-- Happy when not hungry or tired END -->

                                <!--Accustomed to everyday sounds and is
                            usually not startled by them-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Accustomed to everyday sounds and is
                                                usually not startled by them</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.accustomedsounds ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.accustomedsounds_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!-- Accustomed to everyday sounds and is
                            usually not startled by them END -->

                                <!--Established and reliable sleeping
                            schedule-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Established and reliable sleeping
                                                schedule</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.reliablesleeping ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.reliablesleeping_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!-- Established and reliable sleeping
                            schedule END -->

                                <!--Able to enjoy a wide variety of
                            touch, noises, and smells-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Able to enjoy a wide variety of
                                                touch, noises, and smells</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.widevariety ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.widevariety_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!-- Able to enjoy a wide variety of
                            touch, noises, and smells END -->

                                <!--Cries and notices when hurt-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Cries and notices when hurt</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.crieswhenhurt ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.crieswhenhurt_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!-- Cries and notices when hurt END -->


                                <!--Able to calm self down after
                            upsetting event-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Able to calm self down after
                                                upsetting event</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.selfcalmafterupset ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.selfcalmafterupset_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!-- Able to calm self down after
                            upsetting event END -->

                                <!--Able to transition to new environment
                            or activity-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Able to transition to new environment
                                                or activity</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.transitiontonewenv ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.transitiontonewenv_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!-- Able to transition to new environment
                            or activity END -->

                                <!--Able to pay attention and is not
                            distracted by sounds not noticed by others-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Able to pay attention and is not
                                                distracted by sounds not noticed by others</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.nodistractionbysound ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.nodistractionbysound_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!-- Able to pay attention and is not
                            distracted by sounds not noticed by others END -->

                                <!--Able to cope with an unexpected
                            change-->
                                <div class="row">
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Able to cope with an unexpected
                                                change</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.copeunexpectedchange ?? '-'}}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label class="label-height">Remark</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{detail.copeunexpectedchange_description ?? '-'}}" readonly />
                                        </div>
                                    </div>
                                </div>
                                <!-- Able to cope with an unexpected
                            change END -->



                            </form>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template pTemplate="footer">
                <p-button (click)="displayMaximizable=
                            false" label="Ok" styleClass="p-button-text"></p-button>
            </ng-template>
        </p-dialog>
    </div>
</div>
<app-copyrights></app-copyrights>