import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormArray,
  FormControlName,
  FormGroup,
  Validators,
  NonNullableFormBuilder,
} from '@angular/forms';
import * as moment from 'moment';
import { PtServiceService } from 'src/app/services/pt-service.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-pt-assessment',
  templateUrl: './pt-assessment.component.html',
  styleUrls: ['./pt-assessment.component.scss'],
  providers: [MessageService]
})
export class PtAssessmentComponent implements OnInit {
  selectedDate = new Date();
  session_per_month: any = [];
  sessionList: any;
  motionlist: any;
  placelist: any;
  timelist: any;
  peoplelist: any;
  seizurelist: any;
  secretionlist: any;
  recomended_duration: any = [
    { name: '1 month', id: 1 },
    { name: '2 months', id: 2 },
    { name: '3 months', id: 3 },
    { name: '4 months', id: 4 },
    { name: '5 months', id: 4 },
    { name: '6 months', id: 5 },
  ];
  duration: any = [];
  ptassessment: FormGroup;
  constructor(private FB: FormBuilder, private pt_service: PtServiceService, private messageService: MessageService) { }

  ptAssessment = this.FB.group({
    assesment_json: this.FB.array([]),
    patient_name: new FormControl('', Validators.required),
    patient_code: new FormControl('', Validators.required),
    patient_age: new FormControl('', Validators.required),
    dob: new FormControl('', Validators.required),
    gender: new FormControl('', Validators.required),
    physical_activity: this.FB.group({
      bedbound: new FormControl(null),
      // chairbound: new FormControl(''),
      // ambulatory: new FormControl(''),
    }),
    medical_diagnosis: new FormControl(null),
    chief_complain: new FormControl(null),
    mental_status_remarks: new FormControl(null),
    weight: new FormControl(null),
    weight_remarks: new FormControl(null),
    height: new FormControl(null),
    attachment: this.FB.group({
      ngt: new FormControl(''),
      colostomy: new FormControl(''),
      pegtube: new FormControl(''),
      fc: new FormControl(''),
      tracheostomy: new FormControl(''),
      mechanical_ventilator: new FormControl(''),
      na: new FormControl(''),
      others: new FormControl(''),
      others_remarks: new FormControl({
        value: null,
        disabled: true,
      }),
    }),
    attachment_remarks: new FormControl(null),
    vital_signs: this.FB.group({
      pre_temp: new FormControl(null),
      pre_pulse: new FormControl(null),
      pre_resp: new FormControl(null),
      pre_bp: new FormControl(null),
      pre_sp: new FormControl(null),
      post_temp: new FormControl(null),
      post_pulse: new FormControl(null),
      post_resp: new FormControl(null),
      post_bp: new FormControl(null),
      post_sp: new FormControl(null),
    }),
    pain_assessment: this.FB.group({
      pain_yes: new FormControl(null),
      pain_yes_remark: new FormControl(null),
      // pain_no: new FormControl(null),
      pain_no_remark: new FormControl(null),
      // pain_na: new FormControl(null),
    }),
    onset: new FormControl(null),
    location: new FormControl(null),
    character: new FormControl(null),
    aggravating_factor: new FormControl(null),
    relieving_factor: new FormControl(null),
    timing: new FormControl(null),
    severity: new FormControl(null),
    mental_status: this.FB.group({
      alert: new FormControl(null),
      oriented: new FormControl(''),
      confused: new FormControl(''),
      restless: new FormControl(''),
      stupurous: new FormControl(''),
      unresponsive: new FormControl(''),
      lethargic: new FormControl(''),
    }),
    seizure_comment: new FormControl(null),
    seizure_yes: new FormControl(null),
    // seizure_no: new FormControl(''),
    sensory_information: this.FB.group({
      paralysis: new FormControl(null),
      numbness: new FormControl(''),
      tingling: new FormControl(''),
      dizziness: new FormControl(''),
      unable_assess: new FormControl(''),
    }),
    cognition: this.FB.group({
      light_intact: new FormControl(''),
      light_impaired: new FormControl(''),
      light_absent: new FormControl(''),
      light_not_tested: new FormControl(''),
      light_remarks: new FormControl(null),
      blunt_intact: new FormControl(''),
      blunt_impaired: new FormControl(''),
      blunt_absent: new FormControl(''),
      blunt_not_tested: new FormControl(''),
      blunt_remarks: new FormControl(null),
      pin_intact: new FormControl(''),
      pin_impaired: new FormControl(''),
      pin_absent: new FormControl(''),
      pin_not_tested: new FormControl(''),
      pin_remarks: new FormControl(null),
      cognition_oriented: this.FB.group({
        // people_no: new FormControl(''),
        people_yes: new FormControl(null),
        people_remark: new FormControl({
          value: null,
          disabled: true,
        }),
        // time_no: new FormControl(''),
        time_yes: new FormControl(null),
        time_remark: new FormControl({
          value: null,
          disabled: true,
        }),
        // place_no: new FormControl(''),
        place_yes: new FormControl(null),
        place_remark: new FormControl({
          value: null,
          disabled: true,
        }),
      }),
    }),
    visual_impairment: this.FB.group({
      visual_none: new FormControl(''),
      visual_glasses: new FormControl(''),
      visual_contact_lens: new FormControl(''),
      visual_contact_left: new FormControl(''),
      visual_contact_right: new FormControl(''),
      visual_blind: new FormControl(''),
      visual_blind_left: new FormControl(''),
      visual_blind_right: new FormControl(''),
    }),
    hearing_impairment: this.FB.group({
      hearing_none: new FormControl(''),
      hearing_impaired: new FormControl(''),
      hearing_impaired_left: new FormControl(''),
      hearing_impaired_right: new FormControl(''),
      hearing_aid: new FormControl(''),
      hearing_aid_left: new FormControl(''),
      hearing_aid_right: new FormControl(''),
      hearing_deaf: new FormControl(''),
      hearing_deaf_left: new FormControl(''),
      hearing_deaf_right: new FormControl(''),
      hearing_unable: new FormControl(''),
      hearing_unable_left: new FormControl(''),
      hearing_unable_right: new FormControl(''),
    }),
    speech_impairment: this.FB.group({
      speech_none: new FormControl(''),
      speech_slurred: new FormControl(''),
      speech_stuttered: new FormControl(''),
      speech_mute: new FormControl(''),
      speech_unable: new FormControl(''),
      speech_remarks: new FormControl(null),
      skin_temperature: new FormControl(null),
      skin_temp_comment: new FormControl(''),
    }),
    repository_assessment: this.FB.group({
      repository_none: new FormControl(''),
      repository_dyspnea: new FormControl(''),
      repository_Orthopnea: new FormControl(''),
      repository_nonproductive: new FormControl(''),
      repository_productive: new FormControl(''),
      repository_cough: new FormControl(''),
      speech_remarks: new FormControl(''),
      breath_sound_clear: new FormControl(''),
      breath_sound_left: new FormControl(''),
      breath_sound_crackles: new FormControl(''),
      breath_sound_crackles_left: new FormControl(''),
      breath_sound_wheezing: new FormControl(''),
      breath_sound_wheezing_left: new FormControl(''),
      breath_sound_diminish: new FormControl(''),
      breath_sound_diminish_left: new FormControl(''),
      breath_sound_rhonchi: new FormControl(''),
      breath_sound_rhonchi_left: new FormControl(''),
      breath_sound_stridor: new FormControl(''),
      breath_sound_stridor_left: new FormControl(''),
      breath_sound_absent: new FormControl(''),
      breath_sound_absent_left: new FormControl(''),
      breath_sound_comments: new FormControl(null),
      breathing_pattern_Labored: new FormControl(''),
      breathing_pattern_Rapid: new FormControl(''),
      breathing_pattern_Normal: new FormControl(null),
      breathing_pattern_Shallow: new FormControl(''),
      breathing_pattern_comments: new FormControl(''),
      lmp_oxygen: new FormControl(''),
      spo2_therapy: new FormControl(''),
      oxygen_therapy: new FormControl(null),
      oxygen_therapy_comments: new FormControl(null),
      secretion: new FormControl(null),
      Thick_secretion: new FormControl(''),
      Loose_secretion: new FormControl(''),
      Retention_secretion: new FormControl(''),
    }),
    pulse: new FormControl(null),
    blood_pressure: new FormControl(null),
    peripheral_pulse: new FormControl(null),
    edematious_text: new FormControl(null),
    edematious: new FormControl(null),
    scale: new FormControl(null),
    capillary_refill: new FormControl(null),
    gastro_circulatory: new FormControl(null),
    gastro: new FormControl(null),
    medication: new FormControl(null),
    bowel_sound: new FormControl(null),
    bowel_sound_comment: new FormControl(null),
    // bowel_sound_hypoactive: new FormControl(''),
    enternal_feeling: new FormControl(null),
    enternal_feeling_comment: new FormControl(null),
    nausea: new FormControl(null),
    dysphagia: new FormControl(''),
    difficulty_chewing: new FormControl(''),
    constipation: new FormControl(''),
    diarrhea: new FormControl(''),
    pressure_ulcer: new FormControl(null),
    pressure_ulcer_length: new FormControl(null),
    pressure_ulcer_width: new FormControl(null),
    pressure_ulcer_depth: new FormControl(null),
    swelling: new FormControl(null),
    scar: new FormControl(null),
    medications: new FormControl(null),
    mood_calm: new FormControl(''),
    head_control: new FormControl(null),
    trunk_control: new FormControl(null),
    psychological_information: this.FB.group({
      mood_calm: new FormControl(''),
      mood_sleep_dis: new FormControl(''),
      mood_abuse: new FormControl(''),
      mood_anxious: new FormControl(''),
      mood_sad: new FormControl(''),
      mood_trearful: new FormControl(''),
      mood_ubcooperative: new FormControl(''),
      mood_widrawal: new FormControl(''),
      mood_sucidal: new FormControl(''),
      mood_access: new FormControl(''),
      mood_others: new FormControl(''),
      mood_other_comment: new FormControl(null),
    }),
    musculoskeletal_assessment: this.FB.group({
      musculoskeletal_problems: new FormControl(''),
      motion_extreme_yes: new FormControl(null),
      motion_extreme_no: new FormControl(''),
      upper_extreme: new FormControl(''),
      upper_extreme_right: new FormControl(''),
      upper_extreme_left: new FormControl(''),
      lower_extreme: new FormControl(''),
      lower_extreme_right: new FormControl(''),
      lower_extreme_left: new FormControl(''),
    }),
    mobility_balance: this.FB.group({
      rolling_I: new FormControl(''),
      rolling_A: new FormControl(''),
      rolling_D: new FormControl(''),
      rolling_N: new FormControl(''),
      rolling_remark: new FormControl(null),
      sitting_I: new FormControl(''),
      sitting_A: new FormControl(''),
      sitting_D: new FormControl(''),
      sitting_N: new FormControl(''),
      sitting_remark: new FormControl(null),
      standing_I: new FormControl(''),
      standing_A: new FormControl(''),
      standing_D: new FormControl(''),
      standing_N: new FormControl(''),
      standing_remark: new FormControl(null),
      gait_I: new FormControl(''),
      gait_A: new FormControl(''),
      gait_D: new FormControl(''),
      gait_N: new FormControl(''),
      gait_remark: new FormControl(null),
      balance_I: new FormControl(''),
      balance_A: new FormControl(''),
      balance_D: new FormControl(''),
      balance_N: new FormControl(''),
      balance_remark: new FormControl(null),
      static_sitting_I: new FormControl(''),
      static_sitting_A: new FormControl(''),
      static_sitting_D: new FormControl(''),
      static_sitting_N: new FormControl(''),
      static_sitting_remark: new FormControl(null),
      static_standing_I: new FormControl(''),
      static_standing_A: new FormControl(''),
      static_standing_D: new FormControl(''),
      static_standing_N: new FormControl(''),
      static_standing_remark: new FormControl(null),
      dynamic_sitting_I: new FormControl(''),
      dynamic_sitting_A: new FormControl(''),
      dynamic_sitting_D: new FormControl(''),
      dynamic_sitting_N: new FormControl(''),
      dynamic_sitting_remark: new FormControl(null),
      dynamic_standing_I: new FormControl(''),
      dynamic_standing_A: new FormControl(''),
      dynamic_standing_D: new FormControl(''),
      dynamic_standing_N: new FormControl(''),
      dynamic_standing_remark: new FormControl(null),
    }),
    transfer_assisting: this.FB.group({
      transfer_assisting_none: new FormControl(''),
      transfer_assisting_crutches: new FormControl(''),
      transfer_assisting_one: new FormControl(''),
      transfer_assisting_pair: new FormControl(''),
      transfer_assisting_wheel_chair: new FormControl(''),
      transfer_assisting_others: new FormControl(''),
      transfer_assisting_others_description: new FormControl(null),
      transfer_assisting_walker: new FormControl(''),
      problems_noted: new FormControl(null),
      short_term: new FormControl(''),
      short_term_session: new FormControl(null),
      long_term: new FormControl(''),
      long_term_session: new FormControl(null),
      scope_session: new FormControl(null),
      recommended_duration: new FormControl(null),
      no_pt_sesssion_per_month: new FormControl(null),
      goals_acheived: new FormControl(null),
    }),
    session_technique: this.FB.group({
      exercise_checkbox: new FormControl(''),
      exercise_duration: new FormControl(''),
      exercise_details: new FormControl(''),
      tens_checkbox: new FormControl(''),
      tens_duration: new FormControl(''),
      tens_details: new FormControl(''),
      ultrasound_checkbox: new FormControl(''),
      ultrasound_duration: new FormControl(''),
      ultrasound_details: new FormControl(''),
      hot_packs_checkbox: new FormControl(''),
      hot_packs_duration: new FormControl(''),
      hot_packs_details: new FormControl(''),
      stimulation_checkbox: new FormControl(''),
      stimulation_duration: new FormControl(''),
      stimulation_details: new FormControl(''),
      vibrator_checkbox: new FormControl(''),
      vibrator_duration: new FormControl(''),
      vibrator_details: new FormControl(''),
      tapping_checkbox: new FormControl(''),
      tapping_duration: new FormControl(''),
      tapping_details: new FormControl(''),
      chest_therapy_checkbox: new FormControl(''),
      chest_therapy_duration: new FormControl(''),
      chest_therapy_details: new FormControl(''),
      session_technique_others_checkbox: new FormControl(''),
      session_technique_others_details: new FormControl(''),
      heat_and_Cold_therapy_checkbox: new FormControl(''),
      heat_and_Cold_therapy_duration: new FormControl(''),
      heat_and_Cold_therapy_details: new FormControl(''),
      electrotherapy_checkbox: new FormControl(''),
      electrotherapy_duration: new FormControl(''),
      electrotherapy_details: new FormControl(''),
      excercises_checkbox: new FormControl(''),
      excercises_duration: new FormControl(''),
      excercises_details: new FormControl(''),
      manual_techniques_checkbox: new FormControl(''),
      manual_techniques_duration: new FormControl(''),
      manual_techniques_details: new FormControl(''),
      breathing_techniques_checkbox: new FormControl(''),
      breathing_techniques_duration: new FormControl(''),
      breathing_techniques_details: new FormControl(''),
      body_mechanics_checkbox: new FormControl(''),
      body_mechanics_duration: new FormControl(''),
      body_mechanics_details: new FormControl(''),
      re_date: new FormControl(''),
    }),
    joint_muscles: this.FB.group({
      neck_flexion_passive_r: new FormControl(''),
      neck_flexion_passive_l: new FormControl(''),
      neck_flexion_active_r: new FormControl(''),
      neck_flexion_active_l: new FormControl(''),
      nech_flexion_comment: new FormControl(null),
      neck_flexion_muscle_r: new FormControl(''),
      neck_flexion_muscle_l: new FormControl(''),
      neck_extension_passive_r: new FormControl(''),
      neck_extension_passive_l: new FormControl(''),
      neck_extension_active_r: new FormControl(''),
      neck_extension_active_l: new FormControl(''),
      neck_extension_comment: new FormControl(null),
      neck_extension_muscle_r: new FormControl(''),
      neck_extension_muscle_l: new FormControl(''),
      neck_rotation_passive_r: new FormControl(''),
      neck_rotation_passive_l: new FormControl(''),
      neck_rotation_active_r: new FormControl(''),
      neck_rotation_active_l: new FormControl(''),
      neck_rotation_comment: new FormControl(null),
      neck_rotation_muscle_r: new FormControl(''),
      neck_rotation_muscle_l: new FormControl(''),
      neck_sideFlex_passive_r: new FormControl(''),
      neck_sideFlex_passive_l: new FormControl(''),
      neck_sideFlex_active_r: new FormControl(''),
      neck_sideFlex_active_l: new FormControl(''),
      neck_sideFlex_comment: new FormControl(null),
      neck_sideFlex_muscle_r: new FormControl(''),
      neck_sideFlex_muscle_l: new FormControl(''),
      shoulder_flexion_passive_r: new FormControl(''),
      shoulder_flexion_passive_l: new FormControl(''),
      shoulder_flexion_active_r: new FormControl(''),
      shoulder_flexion_active_l: new FormControl(''),
      shoulder_flexion_comment: new FormControl(null),
      shoulder_flexion_muscle_r: new FormControl(''),
      shoulder_flexion_muscle_l: new FormControl(''),
      shoulder_extension_passive_r: new FormControl(''),
      shoulder_extension_passive_l: new FormControl(''),
      shoulder_extension_active_r: new FormControl(''),
      shoulder_extension_active_l: new FormControl(''),
      shoulder_extension_comment: new FormControl(null),
      shoulder_extension_muscle_r: new FormControl(''),
      shoulder_extension_muscle_l: new FormControl(''),
      shoulder_abduction_passive_r: new FormControl(''),
      shoulder_abduction_passive_l: new FormControl(''),
      shoulder_abduction_active_r: new FormControl(''),
      shoulder_abduction_active_l: new FormControl(''),
      shoulder_abduction_comment: new FormControl(null),
      shoulder_abduction_muscle_r: new FormControl(''),
      shoulder_abduction_muscle_l: new FormControl(''),
      shoulder_adduction_passive_r: new FormControl(''),
      shoulder_adduction_passive_l: new FormControl(''),
      shoulder_adduction_active_r: new FormControl(''),
      shoulder_adduction_active_l: new FormControl(''),
      shoulder_adduction_comment: new FormControl(null),
      shoulder_adduction_muscle_r: new FormControl(''),
      shoulder_adduction_muscle_l: new FormControl(''),
      shoulder_internal_rotation_passive_r: new FormControl(''),
      shoulder_internal_rotation_passive_l: new FormControl(''),
      shoulder_internal_rotation_active_r: new FormControl(''),
      shoulder_internal_rotation_active_l: new FormControl(''),
      shoulder_internal_rotation_comment: new FormControl(null),
      shoulder_internal_rotation_muscle_r: new FormControl(''),
      shoulder_internal_rotation_muscle_l: new FormControl(''),
      shoulder_external_rotation_passive_r: new FormControl(''),
      shoulder_external_rotation_passive_l: new FormControl(''),
      shoulder_external_rotation_active_r: new FormControl(''),
      shoulder_external_rotation_active_l: new FormControl(''),
      shoulder_external_rotation_comment: new FormControl(null),
      shoulder_external_rotation_muscle_r: new FormControl(''),
      shoulder_external_rotation_muscle_l: new FormControl(''),
      elbow_flexion_passive_r: new FormControl(''),
      elbow_flexion_passive_l: new FormControl(''),
      elbow_flexion_active_r: new FormControl(''),
      elbow_flexion_active_l: new FormControl(''),
      elbow_flexion_comment: new FormControl(null),
      elbow_flexion_muscle_r: new FormControl(''),
      elbow_flexion_muscle_l: new FormControl(''),
      elbow_extension_passive_r: new FormControl(''),
      elbow_extension_passive_l: new FormControl(''),
      elbow_extension_active_r: new FormControl(''),
      elbow_extension_active_l: new FormControl(''),
      elbow_extension_comment: new FormControl(null),
      elbow_extension_muscle_r: new FormControl(''),
      elbow_extension_muscle_l: new FormControl(''),
      elbow_supination_passive_r: new FormControl(''),
      elbow_supination_passive_l: new FormControl(''),
      elbow_supination_active_r: new FormControl(''),
      elbow_supination_active_l: new FormControl(''),
      elbow_supination_comment: new FormControl(null),
      elbow_supination_muscle_r: new FormControl(''),
      elbow_supination_muscle_l: new FormControl(''),
      elbow_pronation_passive_r: new FormControl(''),
      elbow_pronation_passive_l: new FormControl(''),
      elbow_pronation_active_r: new FormControl(''),
      elbow_pronation_active_l: new FormControl(''),
      elbow_pronation_comment: new FormControl(null),
      elbow_pronation_muscle_r: new FormControl(''),
      elbow_pronation_muscle_l: new FormControl(''),
      wrist_flexion_passive_r: new FormControl(''),
      wrist_flexion_passive_l: new FormControl(''),
      wrist_flexion_active_r: new FormControl(''),
      wrist_flexion_active_l: new FormControl(''),
      wrist_flexion_comment: new FormControl(null),
      wrist_flexion_muscle_r: new FormControl(''),
      wrist_flexion_muscle_l: new FormControl(''),
      wrist_extension_passive_r: new FormControl(''),
      wrist_extension_passive_l: new FormControl(''),
      wrist_extension_active_r: new FormControl(''),
      wrist_extension_active_l: new FormControl(''),
      wrist_extension_comment: new FormControl(null),
      wrist_extension_muscle_r: new FormControl(''),
      wrist_extension_muscle_l: new FormControl(''),
      wrist_ulnar_deviation_passive_r: new FormControl(''),
      wrist_ulnar_deviation_passive_l: new FormControl(''),
      wrist_ulnar_deviation_active_r: new FormControl(''),
      wrist_ulnar_deviation_active_l: new FormControl(''),
      wrist_ulnar_deviation_comment: new FormControl(null),
      wrist_ulnar_deviation_muscle_r: new FormControl(''),
      wrist_ulnar_deviation_muscle_l: new FormControl(''),
      wrist_radial_deviation_passive_r: new FormControl(''),
      wrist_radial_deviation_passive_l: new FormControl(''),
      wrist_radial_deviation_active_r: new FormControl(''),
      wrist_radial_deviation_active_l: new FormControl(''),
      wrist_radial_deviation_comment: new FormControl(null),
      wrist_radial_deviation_muscle_r: new FormControl(''),
      wrist_radial_deviation_muscle_l: new FormControl(''),
      digits_thump_passive_r: new FormControl(''),
      digits_thump_passive_l: new FormControl(''),
      digits_thump_active_r: new FormControl(''),
      digits_thump_active_l: new FormControl(''),
      digits_thump_comment: new FormControl(null),
      digits_thump_muscle_r: new FormControl(''),
      digits_thump_muscle_l: new FormControl(''),
      digits_thump_ip_passive_r: new FormControl(''),
      digits_thump_ip_passive_l: new FormControl(''),
      digits_thump_ip_active_r: new FormControl(''),
      digits_thump_ip_active_l: new FormControl(''),
      digits_thump_ip_comment: new FormControl(null),
      digits_thump_ip_muscle_r: new FormControl(''),
      digits_thump_ip_muscle_l: new FormControl(''),
      digits_mcp_passive_r: new FormControl(''),
      digits_mcp_passive_l: new FormControl(''),
      digits_mcp_active_r: new FormControl(''),
      digits_mcp_active_l: new FormControl(''),
      digits_mcp_comment: new FormControl(null),
      digits_mcp_muscle_r: new FormControl(''),
      digits_mcp_muscle_l: new FormControl(''),
      digits_pip_passive_r: new FormControl(''),
      digits_pip_passive_l: new FormControl(''),
      digits_pip_active_r: new FormControl(''),
      digits_pip_active_l: new FormControl(''),
      digits_pip_comment: new FormControl(null),
      digits_pip_muscle_r: new FormControl(''),
      digits_pip_muscle_l: new FormControl(''),
      digits_dip_passive_r: new FormControl(''),
      digits_dip_passive_l: new FormControl(''),
      digits_dip_active_r: new FormControl(''),
      digits_dip_active_l: new FormControl(''),
      digits_dip_comment: new FormControl(null),
      digits_dip_muscle_r: new FormControl(''),
      digits_dip_muscle_l: new FormControl(''),
      hip_flexion_passive_r: new FormControl(''),
      hip_flexion_passive_l: new FormControl(''),
      hip_flexion_active_r: new FormControl(''),
      hip_flexion_active_l: new FormControl(''),
      hip_flexion_comment: new FormControl(null),
      hip_flexion_muscle_r: new FormControl(''),
      hip_flexion_muscle_l: new FormControl(''),
      hip_extension_passive_r: new FormControl(''),
      hip_extension_passive_l: new FormControl(''),
      hip_extension_active_r: new FormControl(''),
      hip_extension_active_l: new FormControl(''),
      hip_extension_comment: new FormControl(null),
      hip_extension_muscle_r: new FormControl(''),
      hip_extension_muscle_l: new FormControl(''),
      hip_abduction_passive_r: new FormControl(''),
      hip_abduction_passive_l: new FormControl(''),
      hip_abduction_active_r: new FormControl(''),
      hip_abduction_active_l: new FormControl(''),
      hip_abduction_comment: new FormControl(null),
      hip_abduction_muscle_r: new FormControl(''),
      hip_abduction_muscle_l: new FormControl(''),
      hip_adduction_passive_r: new FormControl(''),
      hip_adduction_passive_l: new FormControl(''),
      hip_adduction_active_r: new FormControl(''),
      hip_adduction_active_l: new FormControl(''),
      hip_adduction_comment: new FormControl(null),
      hip_adduction_muscle_r: new FormControl(''),
      hip_adduction_muscle_l: new FormControl(''),
      hip_internal_rotation_passive_r: new FormControl(''),
      hip_internal_rotation_passive_l: new FormControl(''),
      hip_internal_rotation_active_r: new FormControl(''),
      hip_internal_rotation_active_l: new FormControl(''),
      hip_internal_rotation_comment: new FormControl(null),
      hip_internal_rotation_muscle_r: new FormControl(''),
      hip_internal_rotation_muscle_l: new FormControl(''),
      hip_external_rotation_passive_r: new FormControl(''),
      hip_external_rotation_passive_l: new FormControl(''),
      hip_external_rotation_active_r: new FormControl(''),
      hip_external_rotation_active_l: new FormControl(''),
      hip_external_rotation_comment: new FormControl(null),
      hip_external_rotation_muscle_r: new FormControl(''),
      hip_external_rotation_muscle_l: new FormControl(''),
      knee_flexion_passive_r: new FormControl(''),
      knee_flexion_passive_l: new FormControl(''),
      knee_flexion_active_r: new FormControl(''),
      knee_flexion_active_l: new FormControl(''),
      knee_flexion_comment: new FormControl(null),
      knee_flexion_muscle_r: new FormControl(''),
      knee_flexion_muscle_l: new FormControl(''),
      knee_extension_passive_r: new FormControl(''),
      knee_extension_passive_l: new FormControl(''),
      knee_extension_active_r: new FormControl(''),
      knee_extension_active_l: new FormControl(''),
      knee_extension_comment: new FormControl(null),
      knee_extension_muscle_r: new FormControl(''),
      knee_extension_muscle_l: new FormControl(''),
      ankle_pf_passive_r: new FormControl(''),
      ankle_pf_passive_l: new FormControl(''),
      ankle_pf_active_r: new FormControl(''),
      ankle_pf_active_l: new FormControl(''),
      ankle_pf_comment: new FormControl(null),
      ankle_pf_muscle_r: new FormControl(''),
      ankle_pf_muscle_l: new FormControl(''),
      ankle_df_passive_r: new FormControl(''),
      ankle_df_passive_l: new FormControl(''),
      ankle_df_active_r: new FormControl(''),
      ankle_df_active_l: new FormControl(''),
      ankle_df_comment: new FormControl(null),
      ankle_df_muscle_r: new FormControl(''),
      ankle_df_muscle_l: new FormControl(''),
      knee_inversion_passive_r: new FormControl(''),
      knee_inversion_passive_l: new FormControl(''),
      knee_inversion_active_r: new FormControl(''),
      knee_inversion_active_l: new FormControl(''),
      knee_inversion_comment: new FormControl(null),
      knee_inversion_muscle_r: new FormControl(''),
      knee_inversion_muscle_l: new FormControl(''),
      knee_eversion_passive_r: new FormControl(''),
      knee_eversion_passive_l: new FormControl(''),
      knee_eversion_active_r: new FormControl(''),
      knee_eversion_active_l: new FormControl(''),
      knee_eversion_comment: new FormControl(null),
      knee_eversion_muscle_r: new FormControl(''),
      knee_eversion_muscle_l: new FormControl(''),
      head_control: new FormControl(''),
      trunk_control: new FormControl(''),
    })
  });
  onKeyPress(event: KeyboardEvent): void {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // Invalid character, prevent input
      event.preventDefault();
    }
  }
  keyPress(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  breadcrumb = [
    {
      title: 'Physiotherapy Assessment',
      subTitle: 'Dashboard',
    },
  ];

  genderArray: any = [
    { name: 'Male', value: 'male' },
    { name: 'Female', value: 'female' },
  ];

  ngOnInit(): void {
    for (let i = 1; i <= 24; i++) {
      this.duration.push({ duration: i });
    }
    for (let i = 1; i <= 30; i++) {
      this.session_per_month.push({ session_per_month: i + " sessions" });
    }
    this.pt_service.ptGetScopeSession().then((res: any) => {
      console.log(res);
      this.sessionList = res?.data;
      console.log("sessionList:", res?.data);
    })
    this.addMedication('add', 0);
    this.ptAssessment.get(['session_technique', 'exercise_duration']).disable();
    this.ptAssessment.get(['session_technique', 'exercise_details']).disable();
    this.ptAssessment.get(['session_technique', 'tens_duration']).disable();
    this.ptAssessment.get(['session_technique', 'tens_details']).disable();
    this.ptAssessment.get(['session_technique', 'ultrasound_duration']).disable();
    this.ptAssessment.get(['session_technique', 'ultrasound_details']).disable();
    this.ptAssessment.get(['session_technique', 'hot_packs_duration']).disable();
    this.ptAssessment.get(['session_technique', 'hot_packs_details']).disable();
    this.ptAssessment.get(['session_technique', 'stimulation_duration']).disable();
    this.ptAssessment.get(['session_technique', 'stimulation_details']).disable();
    this.ptAssessment.get(['session_technique', 'vibrator_duration']).disable();
    this.ptAssessment.get(['session_technique', 'vibrator_details']).disable();
    this.ptAssessment.get(['session_technique', 'tapping_duration']).disable();
    this.ptAssessment.get(['session_technique', 'tapping_details']).disable();
    this.ptAssessment.get(['session_technique', 'chest_therapy_duration']).disable();
    this.ptAssessment.get(['session_technique', 'chest_therapy_details']).disable();
    this.ptAssessment.get(['session_technique', 'heat_and_Cold_therapy_duration']).disable();
    this.ptAssessment.get(['session_technique', 'heat_and_Cold_therapy_details']).disable();
    this.ptAssessment.get(['session_technique', 'electrotherapy_duration']).disable();
    this.ptAssessment.get(['session_technique', 'electrotherapy_details']).disable();
    this.ptAssessment.get(['session_technique', 'excercises_duration']).disable();
    this.ptAssessment.get(['session_technique', 'excercises_details']).disable();
    this.ptAssessment.get(['session_technique', 'manual_techniques_duration']).disable();
    this.ptAssessment.get(['session_technique', 'manual_techniques_details']).disable();
    this.ptAssessment.get(['session_technique', 'breathing_techniques_duration']).disable();
    this.ptAssessment.get(['session_technique', 'breathing_techniques_details']).disable();
    this.ptAssessment.get(['session_technique', 'body_mechanics_duration']).disable();
    this.ptAssessment.get(['session_technique', 'body_mechanics_details']).disable();
    this.ptAssessment.get(['session_technique', 'session_technique_others_details']).disable();
    this.ptAssessment.controls.seizure_comment.disable();
    this.ptAssessment.controls.enternal_feeling_comment.disable();
    this.ptAssessment.controls.edematious_text.disable();
    this.ptAssessment.get(['cognition', 'pin_remarks']).disable();
    this.ptAssessment.get(['cognition', 'light_remarks']).disable();
    this.ptAssessment.get(['cognition', 'blunt_remarks']).disable();
    this.ptAssessment.get(['repository_assessment', 'breathing_pattern_comments']).disable();
    this.ptAssessment.get(['pain_assessment', 'pain_yes_remark']).disable();
    this.ptAssessment.get(['pain_assessment', 'pain_no_remark']).disable();
    this.ptAssessment.get(['transfer_assisting', 'transfer_assisting_pair']).disable();
    this.ptAssessment.get(['visual_impairment', 'visual_blind_left']).disable();
    this.ptAssessment.get(['visual_impairment', 'visual_blind_right']).disable();
    this.ptAssessment.get(['visual_impairment', 'visual_contact_right']).disable();
    this.ptAssessment.get(['visual_impairment', 'visual_contact_left']).disable();
    this.ptAssessment.get(['transfer_assisting', 'short_term_session']).disable();
    this.ptAssessment.get(['transfer_assisting', 'long_term_session']).disable();
    this.ptAssessment.get(['repository_assessment', 'oxygen_therapy_comments']).disable();
    this.ptAssessment.get(['repository_assessment', 'Thick_secretion']).disable();
    this.ptAssessment.get(['repository_assessment', 'Loose_secretion']).disable();
    this.ptAssessment.get(['repository_assessment', 'Retention_secretion']).disable();
    this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_left']).disable();
    this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_right']).disable();
    this.ptAssessment.get(['hearing_impairment', 'hearing_aid_left']).disable();
    this.ptAssessment.get(['hearing_impairment', 'hearing_aid_right']).disable();
    this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_left']).disable();
    this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_right']).disable();
    this.ptAssessment.get(['hearing_impairment', 'hearing_unable_left']).disable();
    this.ptAssessment.get(['hearing_impairment', 'hearing_unable_right']).disable();
    this.ptAssessment.get(['mobility_balance', 'rolling_remark']).disable();
    this.ptAssessment.get(['mobility_balance', 'sitting_remark']).disable();
    this.ptAssessment.get(['mobility_balance', 'standing_remark']).disable();
    this.ptAssessment.get(['mobility_balance', 'gait_remark']).disable();
    this.ptAssessment.get(['mobility_balance', 'balance_remark']).disable();
    this.ptAssessment.get(['transfer_assisting', 'transfer_assisting_others_description']).disable();
    this.ptAssessment.get(['mobility_balance', 'static_sitting_remark']).disable();
    this.ptAssessment.get(['mobility_balance', 'static_standing_remark']).disable();
    this.ptAssessment.get(['mobility_balance', 'dynamic_sitting_remark']).disable();
    this.ptAssessment.get(['mobility_balance', 'dynamic_standing_remark']).disable();
    this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme']).disable();
    this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme']).disable();
    this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_right']).disable();
    this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_left']).disable();
    this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme_right']).disable();
    this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme_left']).disable();

  }

  addMedication(string: any, index: any) {
    // console.log(index);
    let vaccinationArray = this.getMedicationFormArray();
    if (string === 'add') {
      let newVaccinationArray = new FormGroup({
        medication: new FormControl(''),

      });
      let vaccinationArray = this.getMedicationFormArray();
      vaccinationArray.insert(index + 1, newVaccinationArray);
    } else {
      vaccinationArray.removeAt(index);
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                      // PT Assessment form submission                      */
  /* -------------------------------------------------------------------------- */
  saveAssessmentForm(initialForm: any) {
    window.scroll(0, 0);

    if (initialForm.valid) {
      let stringifyData = JSON.parse(JSON.stringify(this.ptAssessment.value))
      let formData = {

        name: this.ptAssessment.value.patient_name,
        code: this.ptAssessment.value.patient_code,
        age: this.ptAssessment.value.patient_age ? this.ptAssessment.value.patient_age : null,
        dob: moment(this.ptAssessment.value.dob).format('YYYY/MM/DD'),
        gender: this.ptAssessment.value.gender,
        assesment_json: stringifyData
      }
      stringifyData.assesment_json = JSON.stringify(stringifyData.assesment_json);
      console.log('stringify assessment json', this.getMedicationFormArray());

      delete stringifyData['name']
      delete stringifyData['code']
      delete stringifyData['age']
      delete stringifyData['dob']
      delete stringifyData['gender']
      console.log(formData)

      this.pt_service.ptInitialAssessment(formData).then((res: any) => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: res?.message });
        initialForm.reset();

      }).catch((error) => {
        console.log(error)
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error?.message });
      })
    } else {
      this.messageService.add({
        severity: 'error', summary: 'Error', detail: 'Please Fill All the Details!',
      });
    }
  }
  getMedicationFormArray() {
    return this.ptAssessment.get('assesment_json') as FormArray;
  }
  handleInput(event: any, controlName: string): void {
    if (controlName == 'box') {

      if (event.target.value == 'Yes') {
        this.ptAssessment.get(['attachment', 'others_remarks']).enable();
      }
      else {
        this.ptAssessment.get(['attachment', 'others_remarks']).disable();
      }
      if (event.target.checked) {
        this.ptAssessment.get(['attachment', 'others_remarks']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['attachment', 'others_remarks']).clearValidators();
        this.ptAssessment.get(['attachment', 'others_remarks']).setValue(null);
      }
      this.ptAssessment.get(['attachment', 'others_remarks']).updateValueAndValidity();
    }
    if (controlName == 'people') {

      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.ptAssessment.get(['cognition', 'cognition_oriented', 'people_remark']).enable();
      }
      else {
        this.ptAssessment.get(['cognition', 'cognition_oriented', 'people_remark']).disable();
      }
      if (event.target.checked) {
        this.ptAssessment.get(['cognition', 'cognition_oriented', 'people_remark']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['cognition', 'cognition_oriented', 'people_remark']).clearValidators();
        this.ptAssessment.get(['cognition', 'cognition_oriented', 'people_remark']).setValue(null);
      }
      this.ptAssessment.get(['cognition', 'cognition_oriented', 'people_remark']).updateValueAndValidity();
    }
    if (controlName == 'place') {

      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.ptAssessment.get(['cognition', 'cognition_oriented', 'place_remark']).enable();
      }
      else {
        this.ptAssessment.get(['cognition', 'cognition_oriented', 'place_remark']).disable();
      }
      if (event.target.checked) {
        this.ptAssessment.get(['cognition', 'cognition_oriented', 'place_remark']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['cognition', 'cognition_oriented', 'place_remark']).clearValidators();
        this.ptAssessment.get(['cognition', 'cognition_oriented', 'place_remark']).setValue(null);
      }
      this.ptAssessment.get(['cognition', 'cognition_oriented', 'place_remark']).updateValueAndValidity();
    }
    if (controlName == 'time') {
      // this.timelist = event.target.value;
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.ptAssessment.get(['cognition', 'cognition_oriented', 'time_remark']).enable();
      }
      else {
        this.ptAssessment.get(['cognition', 'cognition_oriented', 'time_remark']).disable();
      }
      if (event.target.checked) {
        this.ptAssessment.get(['cognition', 'cognition_oriented', 'time_remark']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['cognition', 'cognition_oriented', 'time_remark']).clearValidators();
        this.ptAssessment.get(['cognition', 'cognition_oriented', 'time_remark']).setValue(null);
      }
      this.ptAssessment.get(['cognition', 'cognition_oriented', 'time_remark']).updateValueAndValidity();
    }
    if (controlName == 'oxygen') {
      // this.timelist = event.target.value;
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.ptAssessment.get(['repository_assessment', 'oxygen_therapy_comments']).enable();
      }
      else {
        this.ptAssessment.get(['repository_assessment', 'oxygen_therapy_comments']).disable();
      }
      if (event.target.checked) {
        this.ptAssessment.get(['repository_assessment', 'oxygen_therapy_comments']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['repository_assessment', 'oxygen_therapy_comments']).clearValidators();
        this.ptAssessment.get(['repository_assessment', 'oxygen_therapy_comments']).setValue(null);
      }
      this.ptAssessment.get(['repository_assessment', 'oxygen_therapy_comments']).updateValueAndValidity();
    }
    if (controlName == 'seizure') {
      // }
      if (event.target.value == '1') {
        console.log('called yes');
        this.ptAssessment.controls.seizure_comment.enable();
      } else {
        console.log('called no');
        this.ptAssessment.controls.seizure_comment.disable();
        this.ptAssessment.controls.seizure_comment.setValue(
          null
        );
      }
      if (event.target.checked) {
        this.ptAssessment.controls['seizure_comment'].setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.controls['seizure_comment'].clearValidators();
      }
      this.ptAssessment.controls['seizure_comment'].updateValueAndValidity();
    }
    if (controlName == 'enternal') {
      // }
      if (event.target.value == 'Yes') {
        console.log('called yes');
        this.ptAssessment.controls.enternal_feeling_comment.enable();
      } else {
        console.log('called no');
        this.ptAssessment.controls.enternal_feeling_comment.disable();
        this.ptAssessment.controls.enternal_feeling_comment.setValue(
          null
        );
      }
      if (event.target.checked) {
        this.ptAssessment.controls['enternal_feeling_comment'].setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.controls['enternal_feeling_comment'].clearValidators();
      }
      this.ptAssessment.controls['enternal_feeling_comment'].updateValueAndValidity();
    }
    if (controlName == 'edematious') {
      // }
      if (event.target.value == 'Yes') {
        console.log('called yes');
        this.ptAssessment.controls.edematious_text.enable();
      } else {
        console.log('called no');
        this.ptAssessment.controls.edematious_text.disable();
        this.ptAssessment.controls.edematious_text.setValue(
          null
        );
      }
      if (event.target.checked) {
        this.ptAssessment.controls['edematious_text'].setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.controls['edematious_text'].clearValidators();
      }
      this.ptAssessment.controls['edematious_text'].updateValueAndValidity();
    }
    if (controlName == 'short') {
      // this.timelist = event.target.value;
      console.log(event.target.value)
      if (event.target.checked) {
        this.ptAssessment.get(['transfer_assisting', 'short_term_session']).enable();
      }
      else {
        this.ptAssessment.get(['transfer_assisting', 'short_term_session']).disable();
      }
      if (event.target.checked) {
        this.ptAssessment.get(['transfer_assisting', 'short_term_session']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['transfer_assisting', 'short_term_session']).clearValidators();
        this.ptAssessment.get(['transfer_assisting', 'short_term_session']).setValue(null);
      }
      this.ptAssessment.get(['transfer_assisting', 'short_term_session']).updateValueAndValidity();
    }
    if (controlName == 'long') {
      // this.timelist = event.target.value;
      console.log(event.target.value)
      if (event.target.checked) {
        this.ptAssessment.get(['transfer_assisting', 'long_term_session']).enable();
        // this.ptAssessment.get(['transfer_assisting', 'long_term_session']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['transfer_assisting', 'long_term_session']).disable();

      }
      if (event.target.checked) {
        this.ptAssessment.get(['transfer_assisting', 'long_term_session']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['transfer_assisting', 'long_term_session']).clearValidators();
        this.ptAssessment.get(['transfer_assisting', 'long_term_session']).setValue(null);
      }
      this.ptAssessment.get(['transfer_assisting', 'long_term_session']).updateValueAndValidity();
    }

    if (controlName == 'lenses') {
      this.ptAssessment.get(['visual_impairment', 'visual_blind_left']).disable();
      this.ptAssessment.get(['visual_impairment', 'visual_blind_right']).disable();
      this.ptAssessment.get(['visual_impairment', 'visual_blind_left']).setValue(null);
      this.ptAssessment.get(['visual_impairment', 'visual_blind_right']).setValue(null);

      if (event.target.checked) {
        // If the checkbox is checked, enable both checkboxes and set validators for both.
        this.ptAssessment.get(['visual_impairment', 'visual_contact_right']).enable();
        this.ptAssessment.get(['visual_impairment', 'visual_contact_left']).enable();
        this.ptAssessment.get(['visual_impairment', 'visual_contact_left']).setValidators([Validators.required]);
        this.ptAssessment.get(['visual_impairment', 'visual_contact_right']).setValidators([Validators.required]);
      } else {
        // If the checkbox is unchecked, clear validators for both checkboxes.
        this.ptAssessment.get(['visual_impairment', 'visual_contact_right']).clearValidators();
        this.ptAssessment.get(['visual_impairment', 'visual_contact_left']).clearValidators();
      }

      // Update the validity of both checkboxes.
      this.ptAssessment.get(['visual_impairment', 'visual_contact_right']).updateValueAndValidity();
      this.ptAssessment.get(['visual_impairment', 'visual_contact_left']).updateValueAndValidity();

      // If the event target value is 'visual_contact_left', set validators accordingly.
      if (event.target.value == 'visual_contact_left') {
        this.ptAssessment.get(['visual_impairment', 'visual_contact_left']).setValidators([Validators.required]);
        this.ptAssessment.get(['visual_impairment', 'visual_contact_right']).clearValidators();
      }
      // If the event target value is 'visual_contact_right', set validators accordingly.
      else if (event.target.value == 'visual_contact_right') {
        this.ptAssessment.get(['visual_impairment', 'visual_contact_right']).setValidators([Validators.required]);
        this.ptAssessment.get(['visual_impairment', 'visual_contact_left']).clearValidators();
      }

      // Update the validity of the selected checkbox.
      this.ptAssessment.get(['visual_impairment', 'visual_contact_right']).updateValueAndValidity();
      this.ptAssessment.get(['visual_impairment', 'visual_contact_left']).updateValueAndValidity();

    }

    if (controlName == 'blind') {
      this.ptAssessment.get(['visual_impairment', 'visual_contact_right']).disable();
      this.ptAssessment.get(['visual_impairment', 'visual_contact_left']).disable();
      this.ptAssessment.get(['visual_impairment', 'visual_contact_right']).setValue(null);
      this.ptAssessment.get(['visual_impairment', 'visual_contact_left']).setValue(null);
      if (event.target.checked) {
        this.ptAssessment.get(['visual_impairment', 'visual_blind_left']).enable();
        this.ptAssessment.get(['visual_impairment', 'visual_blind_right']).enable();
        this.ptAssessment.get(['visual_impairment', 'visual_blind_left']).setValidators([Validators.required]);
        this.ptAssessment.get(['visual_impairment', 'visual_blind_right']).setValidators([Validators.required]);
      }
      else {

        this.ptAssessment.get(['visual_impairment', 'visual_blind_left']).clearValidators();
        this.ptAssessment.get(['visual_impairment', 'visual_blind_right']).clearValidators();

      }
      this.ptAssessment.get(['visual_impairment', 'visual_blind_right']).updateValueAndValidity();
      this.ptAssessment.get(['visual_impairment', 'visual_blind_left']).updateValueAndValidity();

      if (event.target.value == 'visual_blind_left') {
        this.ptAssessment.get(['visual_impairment', 'visual_blind_left']).setValidators([Validators.required]);
        this.ptAssessment.get(['visual_impairment', 'visual_blind_right']).clearValidators();
      }
      else if (event.target.value == 'visual_blind_right') {
        this.ptAssessment.get(['visual_impairment', 'visual_blind_right']).setValidators([Validators.required]);
        this.ptAssessment.get(['visual_impairment', 'visual_blind_left']).clearValidators();
      }
      this.ptAssessment.get(['visual_impairment', 'visual_blind_right']).updateValueAndValidity();
      this.ptAssessment.get(['visual_impairment', 'visual_blind_left']).updateValueAndValidity();
    }

    if (controlName == 'impaired') {
      this.ptAssessment.get(['hearing_impairment', 'hearing_aid_right']).disable();
      this.ptAssessment.get(['hearing_impairment', 'hearing_aid_left']).disable();
      this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_right']).disable();
      this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_left']).disable();
      this.ptAssessment.get(['hearing_impairment', 'hearing_unable_right']).disable();
      this.ptAssessment.get(['hearing_impairment', 'hearing_unable_left']).disable();
      this.ptAssessment.get(['hearing_impairment', 'hearing_aid_left']).setValue(null);
      this.ptAssessment.get(['hearing_impairment', 'hearing_aid_right']).setValue(null);
      this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_right']).setValue(null);
      this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_left']).setValue(null);
      this.ptAssessment.get(['hearing_impairment', 'hearing_unable_right']).setValue(null);
      this.ptAssessment.get(['hearing_impairment', 'hearing_unable_left']).setValue(null);

      if (event.target.checked) {
        this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_left']).enable();
        this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_right']).enable();
        this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_left']).setValidators([Validators.required]);
        this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_right']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_left']).clearValidators();
        this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_right']).clearValidators();
      }
      this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_right']).updateValueAndValidity();
      this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_left']).updateValueAndValidity();
      if (event.target.value == 'hearing_impaired_left') {
        this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_left']).setValidators([Validators.required]);
        this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_right']).clearValidators();
      }
      else if (event.target.value == 'hearing_impaired_right') {
        this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_right']).setValidators([Validators.required]);
        this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_left']).clearValidators();
      }
      this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_right']).updateValueAndValidity();
      this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_left']).updateValueAndValidity();
    }
    if (controlName == 'aid') {
      this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_right']).disable();
      this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_left']).disable();
      this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_right']).disable();
      this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_left']).disable();
      this.ptAssessment.get(['hearing_impairment', 'hearing_unable_right']).disable();
      this.ptAssessment.get(['hearing_impairment', 'hearing_unable_left']).disable();
      this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_left']).setValue(null);
      this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_right']).setValue(null);
      this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_right']).setValue(null);
      this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_left']).setValue(null);
      this.ptAssessment.get(['hearing_impairment', 'hearing_unable_right']).setValue(null);
      this.ptAssessment.get(['hearing_impairment', 'hearing_unable_left']).setValue(null);
      if (event.target.checked) {
        this.ptAssessment.get(['hearing_impairment', 'hearing_aid_left']).enable();
        this.ptAssessment.get(['hearing_impairment', 'hearing_aid_right']).enable();
        this.ptAssessment.get(['hearing_impairment', 'hearing_aid_left']).setValidators([Validators.required]);
        this.ptAssessment.get(['hearing_impairment', 'hearing_aid_right']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['hearing_impairment', 'hearing_aid_left']).clearValidators();
        this.ptAssessment.get(['hearing_impairment', 'hearing_aid_right']).clearValidators();
      }
      this.ptAssessment.get(['hearing_impairment', 'hearing_aid_right']).updateValueAndValidity();
      this.ptAssessment.get(['hearing_impairment', 'hearing_aid_left']).updateValueAndValidity();
      if (event.target.value == 'hearing_aid_left') {
        this.ptAssessment.get(['hearing_impairment', 'hearing_aid_left']).setValidators([Validators.required]);
        this.ptAssessment.get(['hearing_impairment', 'hearing_aid_right']).clearValidators();
      }
      else if (event.target.value == 'hearing_aid_right') {
        this.ptAssessment.get(['hearing_impairment', 'hearing_aid_right']).setValidators([Validators.required]);
        this.ptAssessment.get(['hearing_impairment', 'hearing_aid_left']).clearValidators();
      }
      this.ptAssessment.get(['hearing_impairment', 'hearing_aid_right']).updateValueAndValidity();
      this.ptAssessment.get(['hearing_impairment', 'hearing_aid_left']).updateValueAndValidity();
    }
    if (controlName == 'deaf') {
      this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_right']).disable();
      this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_left']).disable();
      this.ptAssessment.get(['hearing_impairment', 'hearing_aid_right']).disable();
      this.ptAssessment.get(['hearing_impairment', 'hearing_aid_left']).disable();
      this.ptAssessment.get(['hearing_impairment', 'hearing_unable_right']).disable();
      this.ptAssessment.get(['hearing_impairment', 'hearing_unable_left']).disable();
      this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_left']).setValue(null);
      this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_right']).setValue(null);
      this.ptAssessment.get(['hearing_impairment', 'hearing_aid_right']).setValue(null);
      this.ptAssessment.get(['hearing_impairment', 'hearing_aid_left']).setValue(null);
      this.ptAssessment.get(['hearing_impairment', 'hearing_unable_right']).setValue(null);
      this.ptAssessment.get(['hearing_impairment', 'hearing_unable_left']).setValue(null);
      if (event.target.checked) {
        this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_left']).enable();
        this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_right']).enable();
        this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_left']).setValidators([Validators.required]);
        this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_right']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_left']).clearValidators();
        this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_right']).clearValidators();
      }
      this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_right']).updateValueAndValidity();
      this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_left']).updateValueAndValidity();
      if (event.target.value == 'hearing_deaf_left') {
        this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_left']).setValidators([Validators.required]);
        this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_right']).clearValidators();
      }
      else if (event.target.value == 'hearing_deaf_right') {
        this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_right']).setValidators([Validators.required]);
        this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_left']).clearValidators();
      }
      this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_left']).updateValueAndValidity();
      this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_right']).updateValueAndValidity();
    }
    if (controlName == 'unable') {
      this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_right']).disable();
      this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_left']).disable();
      this.ptAssessment.get(['hearing_impairment', 'hearing_aid_right']).disable();
      this.ptAssessment.get(['hearing_impairment', 'hearing_aid_left']).disable();
      this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_right']).disable();
      this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_left']).disable();
      this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_left']).setValue(null);
      this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_right']).setValue(null);
      this.ptAssessment.get(['hearing_impairment', 'hearing_aid_right']).setValue(null);
      this.ptAssessment.get(['hearing_impairment', 'hearing_aid_left']).setValue(null);
      this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_right']).setValue(null);
      this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_left']).setValue(null);
      if (event.target.checked) {
        this.ptAssessment.get(['hearing_impairment', 'hearing_unable_left']).enable();
        this.ptAssessment.get(['hearing_impairment', 'hearing_unable_right']).enable();
        this.ptAssessment.get(['hearing_impairment', 'hearing_unable_left']).setValidators([Validators.required]);
        this.ptAssessment.get(['hearing_impairment', 'hearing_unable_right']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['hearing_impairment', 'hearing_unable_left']).clearValidators();
        this.ptAssessment.get(['hearing_impairment', 'hearing_unable_right']).clearValidators();
      }
      this.ptAssessment.get(['hearing_impairment', 'hearing_unable_right']).updateValueAndValidity();
      this.ptAssessment.get(['hearing_impairment', 'hearing_unable_left']).updateValueAndValidity();
      if (event.target.value == 'hearing_unable_left') {
        this.ptAssessment.get(['hearing_impairment', 'hearing_unable_left']).setValidators([Validators.required]);
        this.ptAssessment.get(['hearing_impairment', 'hearing_unable_right']).clearValidators();
      }
      else if (event.target.value == 'hearing_unable_right') {
        this.ptAssessment.get(['hearing_impairment', 'hearing_unable_right']).setValidators([Validators.required]);
        this.ptAssessment.get(['hearing_impairment', 'hearing_unable_left']).clearValidators();
      }
      this.ptAssessment.get(['hearing_impairment', 'hearing_unable_right']).updateValueAndValidity();
      this.ptAssessment.get(['hearing_impairment', 'hearing_unable_left']).updateValueAndValidity();
    }
    else if (controlName == 'exercise') {
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.ptAssessment.get(['session_technique', 'exercise_duration']).enable();
        this.ptAssessment.get(['session_technique', 'exercise_details']).enable();
      }
      else {
        this.ptAssessment.get(['session_technique', 'exercise_duration']).disable();
        this.ptAssessment.get(['session_technique', 'exercise_details']).disable();
      }
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'exercise_duration']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'exercise_duration']).clearValidators();
        this.ptAssessment.get(['session_technique', 'exercise_duration']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'exercise_duration']).updateValueAndValidity();
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'exercise_details']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'exercise_details']).clearValidators();
        this.ptAssessment.get(['session_technique', 'exercise_details']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'exercise_details']).updateValueAndValidity();

    }
    else if (controlName == 'ten') {
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.ptAssessment.get(['session_technique', 'tens_duration']).enable();
        this.ptAssessment.get(['session_technique', 'tens_details']).enable();
      }
      else {
        this.ptAssessment.get(['session_technique', 'tens_duration']).disable();
        this.ptAssessment.get(['session_technique', 'tens_details']).disable();
      }
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'tens_duration']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'tens_duration']).clearValidators();
        this.ptAssessment.get(['session_technique', 'tens_duration']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'tens_duration']).updateValueAndValidity();
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'tens_details']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'tens_details']).clearValidators();
        this.ptAssessment.get(['session_technique', 'tens_details']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'tens_details']).updateValueAndValidity();
    }
    else if (controlName == 'sound') {
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.ptAssessment.get(['session_technique', 'ultrasound_duration']).enable();
        this.ptAssessment.get(['session_technique', 'ultrasound_details']).enable();
      }
      else {
        this.ptAssessment.get(['session_technique', 'ultrasound_duration']).disable();
        this.ptAssessment.get(['session_technique', 'ultrasound_details']).disable();
      }
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'ultrasound_duration']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'ultrasound_duration']).clearValidators();
        this.ptAssessment.get(['session_technique', 'ultrasound_duration']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'ultrasound_duration']).updateValueAndValidity();
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'ultrasound_details']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'ultrasound_details']).clearValidators();
        this.ptAssessment.get(['session_technique', 'ultrasound_details']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'ultrasound_details']).updateValueAndValidity();
    }
    else if (controlName == 'pack') {
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.ptAssessment.get(['session_technique', 'hot_packs_duration']).enable();
        this.ptAssessment.get(['session_technique', 'hot_packs_details']).enable();
      }
      else {
        this.ptAssessment.get(['session_technique', 'hot_packs_duration']).disable();
        this.ptAssessment.get(['session_technique', 'hot_packs_details']).disable();
      }
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'hot_packs_duration']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'hot_packs_duration']).clearValidators();
        this.ptAssessment.get(['session_technique', 'hot_packs_duration']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'hot_packs_duration']).updateValueAndValidity();
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'hot_packs_details']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'ultrasound_details']).clearValidators();
        this.ptAssessment.get(['session_technique', 'hot_packs_details']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'hot_packs_details']).updateValueAndValidity();
    }
    else if (controlName == 'stimulation') {
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.ptAssessment.get(['session_technique', 'stimulation_duration']).enable();
        this.ptAssessment.get(['session_technique', 'stimulation_details']).enable();
      }
      else {
        this.ptAssessment.get(['session_technique', 'stimulation_duration']).disable();
        this.ptAssessment.get(['session_technique', 'stimulation_details']).disable();
      }
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'stimulation_duration']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'stimulation_duration']).clearValidators();
        this.ptAssessment.get(['session_technique', 'stimulation_duration']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'stimulation_duration']).updateValueAndValidity();
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'stimulation_details']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'ultrasound_details']).clearValidators();
        this.ptAssessment.get(['session_technique', 'stimulation_details']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'stimulation_details']).updateValueAndValidity();
    }
    else if (controlName == 'vibrator') {
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.ptAssessment.get(['session_technique', 'vibrator_duration']).enable();
        this.ptAssessment.get(['session_technique', 'vibrator_details']).enable();
      }
      else {
        this.ptAssessment.get(['session_technique', 'vibrator_duration']).disable();
        this.ptAssessment.get(['session_technique', 'vibrator_details']).disable();
      }
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'vibrator_duration']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'vibrator_duration']).clearValidators();
        this.ptAssessment.get(['session_technique', 'vibrator_duration']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'vibrator_duration']).updateValueAndValidity();
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'vibrator_details']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'vibrator_details']).clearValidators();
        this.ptAssessment.get(['session_technique', 'vibrator_details']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'vibrator_details']).updateValueAndValidity();
    }
    else if (controlName == 'tapping') {
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.ptAssessment.get(['session_technique', 'tapping_duration']).enable();
        this.ptAssessment.get(['session_technique', 'tapping_details']).enable();
      }
      else {
        this.ptAssessment.get(['session_technique', 'tapping_duration']).disable();
        this.ptAssessment.get(['session_technique', 'tapping_details']).disable();
      }
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'tapping_duration']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'tapping_duration']).clearValidators();
        this.ptAssessment.get(['session_technique', 'tapping_duration']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'tapping_duration']).updateValueAndValidity();
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'tapping_details']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'tapping_details']).clearValidators();
        this.ptAssessment.get(['session_technique', 'tapping_details']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'tapping_details']).updateValueAndValidity();
    }
    else if (controlName == 'chest') {
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.ptAssessment.get(['session_technique', 'chest_therapy_duration']).enable();
        this.ptAssessment.get(['session_technique', 'chest_therapy_details']).enable();
      }
      else {
        this.ptAssessment.get(['session_technique', 'chest_therapy_duration']).disable();
        this.ptAssessment.get(['session_technique', 'chest_therapy_details']).disable();
      }
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'chest_therapy_duration']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'chest_therapy_duration']).clearValidators();
        this.ptAssessment.get(['session_technique', 'chest_therapy_duration']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'chest_therapy_duration']).updateValueAndValidity();
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'chest_therapy_details']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'chest_therapy_details']).clearValidators();
        this.ptAssessment.get(['session_technique', 'chest_therapy_details']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'chest_therapy_details']).updateValueAndValidity();
    }
    else if (controlName == 'cold') {
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.ptAssessment.get(['session_technique', 'heat_and_Cold_therapy_duration']).enable();
        this.ptAssessment.get(['session_technique', 'heat_and_Cold_therapy_details']).enable();
      }
      else {
        this.ptAssessment.get(['session_technique', 'heat_and_Cold_therapy_duration']).disable();
        this.ptAssessment.get(['session_technique', 'heat_and_Cold_therapy_details']).disable();
      }
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'heat_and_Cold_therapy_duration']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'heat_and_Cold_therapy_duration']).clearValidators();
        this.ptAssessment.get(['session_technique', 'heat_and_Cold_therapy_duration']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'heat_and_Cold_therapy_duration']).updateValueAndValidity();
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'heat_and_Cold_therapy_details']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'heat_and_Cold_therapy_details']).clearValidators();
        this.ptAssessment.get(['session_technique', 'heat_and_Cold_therapy_details']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'heat_and_Cold_therapy_details']).updateValueAndValidity();
    }
    else if (controlName == 'electro') {
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.ptAssessment.get(['session_technique', 'electrotherapy_duration']).enable();
        this.ptAssessment.get(['session_technique', 'electrotherapy_details']).enable();
      }
      else {
        this.ptAssessment.get(['session_technique', 'electrotherapy_duration']).disable();
        this.ptAssessment.get(['session_technique', 'electrotherapy_details']).disable();
      }
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'electrotherapy_duration']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'electrotherapy_duration']).clearValidators();
        this.ptAssessment.get(['session_technique', 'electrotherapy_duration']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'electrotherapy_duration']).updateValueAndValidity();
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'electrotherapy_details']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'electrotherapy_details']).clearValidators();
        this.ptAssessment.get(['session_technique', 'electrotherapy_details']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'electrotherapy_details']).updateValueAndValidity();
    }
    else if (controlName == 'cises') {
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.ptAssessment.get(['session_technique', 'excercises_duration']).enable();
        this.ptAssessment.get(['session_technique', 'excercises_details']).enable();
      }
      else {
        this.ptAssessment.get(['session_technique', 'excercises_duration']).disable();
        this.ptAssessment.get(['session_technique', 'excercises_details']).disable();
      }
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'excercises_duration']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'excercises_duration']).clearValidators();
        this.ptAssessment.get(['session_technique', 'excercises_duration']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'excercises_duration']).updateValueAndValidity();
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'excercises_details']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'excercises_details']).clearValidators();
        this.ptAssessment.get(['session_technique', 'excercises_details']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'excercises_details']).updateValueAndValidity();
    }
    else if (controlName == 'manual') {
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.ptAssessment.get(['session_technique', 'manual_techniques_duration']).enable();
        this.ptAssessment.get(['session_technique', 'manual_techniques_details']).enable();
      }
      else {
        this.ptAssessment.get(['session_technique', 'manual_techniques_duration']).disable();
        this.ptAssessment.get(['session_technique', 'manual_techniques_details']).disable();
      }
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'manual_techniques_duration']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'manual_techniques_duration']).clearValidators();
        this.ptAssessment.get(['session_technique', 'manual_techniques_duration']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'manual_techniques_duration']).updateValueAndValidity();
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'manual_techniques_details']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'manual_techniques_details']).clearValidators();
        this.ptAssessment.get(['session_technique', 'manual_techniques_details']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'manual_techniques_details']).updateValueAndValidity();
    }
    else if (controlName == 'relax') {
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.ptAssessment.get(['session_technique', 'breathing_techniques_duration']).enable();
        this.ptAssessment.get(['session_technique', 'breathing_techniques_details']).enable();
      }
      else {
        this.ptAssessment.get(['session_technique', 'breathing_techniques_duration']).disable();
        this.ptAssessment.get(['session_technique', 'breathing_techniques_details']).disable();
      }
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'breathing_techniques_duration']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'breathing_techniques_duration']).clearValidators();
        this.ptAssessment.get(['session_technique', 'breathing_techniques_duration']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'breathing_techniques_duration']).updateValueAndValidity();
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'breathing_techniques_details']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'breathing_techniques_details']).clearValidators();
        this.ptAssessment.get(['session_technique', 'breathing_techniques_details']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'breathing_techniques_details']).updateValueAndValidity();
    }
    else if (controlName == 'body') {
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.ptAssessment.get(['session_technique', 'body_mechanics_duration']).enable();
        this.ptAssessment.get(['session_technique', 'body_mechanics_details']).enable();
      }
      else {
        this.ptAssessment.get(['session_technique', 'body_mechanics_duration']).disable();
        this.ptAssessment.get(['session_technique', 'body_mechanics_details']).disable();
      }
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'body_mechanics_duration']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'body_mechanics_duration']).clearValidators();
        this.ptAssessment.get(['session_technique', 'body_mechanics_duration']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'body_mechanics_duration']).updateValueAndValidity();
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'body_mechanics_details']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'body_mechanics_details']).clearValidators();
        this.ptAssessment.get(['session_technique', 'body_mechanics_details']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'body_mechanics_details']).updateValueAndValidity();
    }
    else if (controlName == 'other') {

      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'session_technique_others_details']).enable();
      }
      else {
        this.ptAssessment.get(['session_technique', 'session_technique_others_details']).disable();
      }
      if (event.target.checked) {
        this.ptAssessment.get(['session_technique', 'session_technique_others_details']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['session_technique', 'session_technique_others_details']).clearValidators();
        this.ptAssessment.get(['session_technique', 'session_technique_others_details']).setValue(null);
      }
      this.ptAssessment.get(['session_technique', 'session_technique_others_details']).updateValueAndValidity();

    }
    if (controlName == 'pain') {
      console.log(event.target.value)
      if (event.target.value == 'Yes') {
        this.ptAssessment.get(['pain_assessment', 'pain_yes_remark']).enable();
        this.ptAssessment.get(['pain_assessment', 'pain_no_remark']).enable();
      }
      else {
        this.ptAssessment.get(['pain_assessment', 'pain_yes_remark']).disable();
        this.ptAssessment.get(['pain_assessment', 'pain_no_remark']).disable();
      }
      if (event.target.checked) {
        this.ptAssessment.get(['pain_assessment', 'pain_yes_remark']).setValidators([Validators.required]);
        this.ptAssessment.get(['pain_assessment', 'pain_no_remark']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['pain_assessment', 'pain_yes_remark']).clearValidators();
        this.ptAssessment.get(['pain_assessment', 'pain_no_remark']).setValue(null);
        this.ptAssessment.get(['pain_assessment', 'pain_yes_remark']).clearValidators();
        this.ptAssessment.get(['pain_assessment', 'pain_no_remark']).setValue(null);
      }
      this.ptAssessment.get(['pain_assessment', 'pain_yes_remark']).updateValueAndValidity();
      this.ptAssessment.get(['pain_assessment', 'pain_no_remark']).updateValueAndValidity();
    }
    if (controlName === 'breath') {
      if (event.target.checked) {
        this.ptAssessment.get(['repository_assessment', 'breathing_pattern_comments']).enable();
        this.ptAssessment.get(['repository_assessment', 'breathing_pattern_comments']).setValidators([Validators.required]);
      } else {
        this.ptAssessment.get(['repository_assessment', 'breathing_pattern_comments']).disable();
        this.ptAssessment.get(['repository_assessment', 'breathing_pattern_comments']).clearValidators();
        this.ptAssessment.get(['repository_assessment', 'breathing_pattern_comments']).setValue(null);
      }
      this.ptAssessment.get(['repository_assessment', 'breathing_pattern_comments']).updateValueAndValidity();
    }
    if (controlName === 'box') {
      if (event.target.checked) {
        this.ptAssessment.get(['attachment', 'others_remarks']).enable();
        this.ptAssessment.get(['attachment', 'others_remarks']).setValidators([Validators.required]);
      } else {
        this.ptAssessment.get(['attachment', 'others_remarks']).disable();
        this.ptAssessment.get(['attachment', 'others_remarks']).clearValidators();
        this.ptAssessment.get(['attachment', 'others_remarks']).setValue(null);
      }
      this.ptAssessment.get(['attachment', 'others_remarks']).updateValueAndValidity();
    }
    if (controlName === 'speech') {
      if (event.target.checked) {
        this.ptAssessment.get(['visual_impairment', 'visual_contact_left']).disable();
        this.ptAssessment.get(['visual_impairment', 'visual_contact_right']).disable();
        this.ptAssessment.get(['visual_impairment', 'visual_blind_left']).disable();
        this.ptAssessment.get(['visual_impairment', 'visual_blind_right']).disable();
        this.ptAssessment.get(['visual_impairment', 'visual_contact_right']).setValue(null);
        this.ptAssessment.get(['visual_impairment', 'visual_contact_left']).setValue(null);

      } else {
        this.ptAssessment.get(['visual_impairment', 'visual_contact_left']).enable();
        this.ptAssessment.get(['visual_impairment', 'visual_contact_right']).enable();
        this.ptAssessment.get(['visual_impairment', 'visual_blind_left']).enable();
        this.ptAssessment.get(['visual_impairment', 'visual_blind_right']).enable();
      }

    }

    if (controlName === 'hearing') {
      if (event.target.checked) {
        this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_left']).disable();
        this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_right']).disable();
        this.ptAssessment.get(['hearing_impairment', 'hearing_aid_left']).disable();
        this.ptAssessment.get(['hearing_impairment', 'hearing_aid_right']).disable();
        this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_left']).disable();
        this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_right']).disable();
        this.ptAssessment.get(['hearing_impairment', 'hearing_unable_left']).disable();
        this.ptAssessment.get(['hearing_impairment', 'hearing_unable_right']).disable();
        this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_left']).setValue(null);
        this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_right']).setValue(null);
        this.ptAssessment.get(['hearing_impairment', 'hearing_aid_left']).setValue(null);
        this.ptAssessment.get(['hearing_impairment', 'hearing_aid_right']).setValue(null);
        this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_left']).setValue(null);
        this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_right']).setValue(null);
        this.ptAssessment.get(['hearing_impairment', 'hearing_unable_left']).setValue(null);
        this.ptAssessment.get(['hearing_impairment', 'hearing_unable_right']).setValue(null);

      } else {
        this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_left']).enable();
        this.ptAssessment.get(['hearing_impairment', 'hearing_impaired_right']).enable();
        this.ptAssessment.get(['hearing_impairment', 'hearing_aid_left']).enable();
        this.ptAssessment.get(['hearing_impairment', 'hearing_aid_right']).enable();
        this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_left']).enable();
        this.ptAssessment.get(['hearing_impairment', 'hearing_deaf_right']).enable();
        this.ptAssessment.get(['hearing_impairment', 'hearing_unable_left']).enable();
        this.ptAssessment.get(['hearing_impairment', 'hearing_unable_right']).enable();
      }

    }

    if (controlName === 'repo') {
      if (event.target.checked) {
        this.ptAssessment.get(['repository_assessment', 'repository_cough']).disable();
        this.ptAssessment.get(['repository_assessment', 'repository_cough']).setValue(null);
      } else {
        this.ptAssessment.get(['repository_assessment', 'repository_cough']).enable();
      }

    }
    if (controlName == 'secretion') {
      if (event.target.value == 'Yes') {
        this.ptAssessment.get(['repository_assessment', 'Thick_secretion']).enable();
        this.ptAssessment.get(['repository_assessment', 'Loose_secretion']).enable();
        this.ptAssessment.get(['repository_assessment', 'Retention_secretion']).enable();
      }
      else {
        this.ptAssessment.get(['repository_assessment', 'Thick_secretion']).disable();
        this.ptAssessment.get(['repository_assessment', 'Loose_secretion']).disable();
        this.ptAssessment.get(['repository_assessment', 'Retention_secretion']).disable();
        this.ptAssessment.get(['repository_assessment', 'Thick_secretion']).setValue(null);
        this.ptAssessment.get(['repository_assessment', 'Loose_secretion']).setValue(null);
        this.ptAssessment.get(['repository_assessment', 'Retention_secretion']).setValue(null);
      }
    }
    if (controlName === 'light') {
      if (event.target.checked) {
        this.ptAssessment.get(['cognition', 'light_remarks']).enable();
        this.ptAssessment.get(['cognition', 'light_remarks']).setValidators([Validators.required]);

      } else {
        this.ptAssessment.get(['cognition', 'light_remarks']).disable();
        this.ptAssessment.get(['cognition', 'light_remarks']).clearValidators();
        this.ptAssessment.get(['cognition', 'light_remarks']).setValue(null);
      }
      this.ptAssessment.get(['cognition', 'light_remarks']).updateValueAndValidity();
    }
    if (controlName === 'blunt') {
      if (event.target.checked) {
        this.ptAssessment.get(['cognition', 'blunt_remarks']).enable();
        this.ptAssessment.get(['cognition', 'blunt_remarks']).setValidators([Validators.required]);

      } else {
        this.ptAssessment.get(['cognition', 'blunt_remarks']).disable();
        this.ptAssessment.get(['cognition', 'blunt_remarks']).clearValidators();
        this.ptAssessment.get(['cognition', 'blunt_remarks']).setValue(null);
      }
      this.ptAssessment.get(['cognition', 'blunt_remarks']).updateValueAndValidity();
    }
    if (controlName === 'pin') {
      if (event.target.checked) {
        this.ptAssessment.get(['cognition', 'pin_remarks']).enable();
        this.ptAssessment.get(['cognition', 'pin_remarks']).setValidators([Validators.required]);

      } else {
        this.ptAssessment.get(['cognition', 'pin_remarks']).disable();
        this.ptAssessment.get(['cognition', 'pin_remarks']).clearValidators();
        this.ptAssessment.get(['cognition', 'pin_remarks']).setValue(null);
      }
      this.ptAssessment.get(['cognition', 'pin_remarks']).updateValueAndValidity();
    }
    if (controlName == 'gastro') {
      console.log(event.target.value)
      if (event.target.checked) {
        this.ptAssessment.controls.pulse.disable();
        this.ptAssessment.controls.blood_pressure.disable();
        this.ptAssessment.controls.edematious.disable();
        this.ptAssessment.controls.edematious_text.disable();
        this.ptAssessment.controls.peripheral_pulse.disable();
        this.ptAssessment.controls.capillary_refill.disable();
        this.ptAssessment.controls.scale.disable();
        this.ptAssessment.get(['speech_impairment', 'skin_temperature']).disable();
      }
      else {
        this.ptAssessment.controls.pulse.enable();
        this.ptAssessment.controls.blood_pressure.enable();
        this.ptAssessment.controls.edematious.enable();
        this.ptAssessment.controls.edematious_text.enable();
        this.ptAssessment.controls.peripheral_pulse.enable();
        this.ptAssessment.controls.capillary_refill.enable();
        this.ptAssessment.controls.scale.enable();
        this.ptAssessment.get(['speech_impairment', 'skin_temperature']).enable();
      }
      if (event.target.checked) {
        this.ptAssessment.controls.pulse.setValidators([Validators.required]);
        this.ptAssessment.controls.blood_pressure.setValidators([Validators.required]);
        this.ptAssessment.controls.edematious.setValidators([Validators.required]);
        this.ptAssessment.controls.scale.setValidators([Validators.required]);
        this.ptAssessment.controls.edematious_text.setValidators([Validators.required]);
        this.ptAssessment.controls.peripheral_pulse.setValidators([Validators.required]);
        this.ptAssessment.controls.capillary_refill.setValidators([Validators.required]);
        this.ptAssessment.get(['speech_impairment', 'skin_temperature']).setValidators([Validators.required]);
        this.ptAssessment.controls.pulse.setValue(null);
        this.ptAssessment.controls.blood_pressure.setValue(null);
        this.ptAssessment.controls.edematious.setValue(null);
        this.ptAssessment.controls.edematious_text.setValue(null);
        this.ptAssessment.controls.peripheral_pulse.setValue(null);
        this.ptAssessment.controls.capillary_refill.setValue(null);
        this.ptAssessment.controls.scale.setValue(null);
        this.ptAssessment.get(['speech_impairment', 'skin_temperature']).setValue(null);

        // this.ptAssessment.get(['pain_assessment', 'pain_yes_remark']).setValidators([Validators.required]);
        // this.ptAssessment.get(['pain_assessment', 'pain_no_remark']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.controls.pulse.clearValidators();
        this.ptAssessment.controls.blood_pressure.clearValidators();
        this.ptAssessment.controls.edematious.clearValidators();
        this.ptAssessment.controls.edematious_text.clearValidators();
        this.ptAssessment.controls.peripheral_pulse.clearValidators();
        this.ptAssessment.controls.capillary_refill.clearValidators();
        this.ptAssessment.controls.scale.clearValidators();
        this.ptAssessment.get(['speech_impairment', 'skin_temperature']).clearValidators();
        this.ptAssessment.controls.pulse.setValue(null);
        this.ptAssessment.controls.blood_pressure.setValue(null);
        this.ptAssessment.controls.edematious.setValue(null);
        this.ptAssessment.controls.edematious_text.setValue(null);
        this.ptAssessment.controls.peripheral_pulse.setValue(null);
        this.ptAssessment.controls.capillary_refill.setValue(null);
        this.ptAssessment.get(['speech_impairment', 'skin_temperature']).setValue(null);

      }
      this.ptAssessment.controls.pulse.updateValueAndValidity();
      this.ptAssessment.controls.blood_pressure.updateValueAndValidity();
      this.ptAssessment.controls.edematious.updateValueAndValidity();
      this.ptAssessment.controls.edematious_text.updateValueAndValidity();
      this.ptAssessment.controls.peripheral_pulse.updateValueAndValidity();
      this.ptAssessment.controls.capillary_refill.updateValueAndValidity();
      this.ptAssessment.controls.scale.updateValueAndValidity();
      this.ptAssessment.get(['speech_impairment', 'skin_temperature']).updateValueAndValidity();
      // this.ptAssessment.get(['pain_assessment', 'pain_yes_remark']).updateValueAndValidity();
      // this.ptAssessment.get(['pain_assessment', 'pain_no_remark']).updateValueAndValidity();
    }
    if (controlName == 'bowel') {
      console.log(event.target.value)
      if (event.target.checked) {
        this.ptAssessment.controls.bowel_sound.disable();
        this.ptAssessment.controls.enternal_feeling.disable();
        this.ptAssessment.controls.nausea.disable();
        this.ptAssessment.controls.bowel_sound.setValue(null);
        this.ptAssessment.controls.enternal_feeling.setValue(null);
        this.ptAssessment.controls.nausea.setValue(null);

      }
      else {
        this.ptAssessment.controls.bowel_sound.enable();
        this.ptAssessment.controls.enternal_feeling.enable();
        this.ptAssessment.controls.nausea.enable();

      }
    }
    if (controlName == 'light') {
      switch (event.target.value) {
        case 1:
          this.ptAssessment.get(['cognition', 'light_intact']).setValue(1);

          break;
        case 2:

          this.ptAssessment.get(['cognition', 'light_intact']).setValue(2);
          break;
        case 3:
          this.ptAssessment.get(['cognition', 'light_intact']).setValue(3);

          break;
        case 4:
          this.ptAssessment.get(['cognition', 'light_intact']).setValue(4);

          break;
      }
    }
    if (controlName == 'blunt') {
      switch (event.target.value) {
        case 1:
          this.ptAssessment.get(['cognition', 'blunt_intact']).setValue(1);

          break;
        case 2:

          this.ptAssessment.get(['cognition', 'blunt_intact']).setValue(2);
          break;
        case 3:
          this.ptAssessment.get(['cognition', 'blunt_intact']).setValue(3);

          break;
        case 4:
          this.ptAssessment.get(['cognition', 'blunt_intact']).setValue(4);

          break;
      }
    }
    if (controlName == 'pin') {
      switch (event.target.value) {
        case 1:
          this.ptAssessment.get(['cognition', 'pin_intact']).setValue(1);

          break;
        case 2:

          this.ptAssessment.get(['cognition', 'pin_intact']).setValue(2);
          break;
        case 3:
          this.ptAssessment.get(['cognition', 'pin_intact']).setValue(3);

          break;
        case 4:
          this.ptAssessment.get(['cognition', 'pin_intact']).setValue(4);

          break;
      }
    }

    if (controlName == 'rolling') {
      switch (event.target.value) {
        case 1:
          this.ptAssessment.get(['mobility_balance', 'rolling_I']).setValue(1);

          break;
        case 2:

          this.ptAssessment.get(['mobility_balance', 'rolling_I']).setValue(2);
          break;
        case 3:
          this.ptAssessment.get(['mobility_balance', 'rolling_I']).setValue(3);

          break;
        case 4:
          this.ptAssessment.get(['mobility_balance', 'rolling_I']).setValue(4);

          break;
      }
    }
    if (controlName == 'sitting') {
      switch (event.target.value) {
        case 1:
          this.ptAssessment.get(['mobility_balance', 'sitting_I']).setValue(1);

          break;
        case 2:

          this.ptAssessment.get(['mobility_balance', 'sitting_I']).setValue(2);
          break;
        case 3:
          this.ptAssessment.get(['mobility_balance', 'sitting_I']).setValue(3);

          break;
        case 4:
          this.ptAssessment.get(['mobility_balance', 'sitting_I']).setValue(4);

          break;
      }
    }
    if (controlName == 'standing') {
      switch (event.target.value) {
        case 1:
          this.ptAssessment.get(['mobility_balance', 'standing_I']).setValue(1);

          break;
        case 2:

          this.ptAssessment.get(['mobility_balance', 'standing_I']).setValue(2);
          break;
        case 3:
          this.ptAssessment.get(['mobility_balance', 'standing_I']).setValue(3);

          break;
        case 4:
          this.ptAssessment.get(['mobility_balance', 'standing_I']).setValue(4);

          break;
      }
    }
    if (controlName == 'gait') {
      switch (event.target.value) {
        case 1:
          this.ptAssessment.get(['mobility_balance', 'gait_I']).setValue(1);

          break;
        case 2:

          this.ptAssessment.get(['mobility_balance', 'gait_I']).setValue(2);
          break;
        case 3:
          this.ptAssessment.get(['mobility_balance', 'gait_I']).setValue(3);

          break;
        case 4:
          this.ptAssessment.get(['mobility_balance', 'gait_I']).setValue(4);
          break;
      }
    }
    if (controlName == 'balance') {
      switch (event.target.value) {
        case 1:
          this.ptAssessment.get(['mobility_balance', 'balance_I']).setValue(1);

          break;
        case 2:

          this.ptAssessment.get(['mobility_balance', 'balance_I']).setValue(2);
          break;
        case 3:
          this.ptAssessment.get(['mobility_balance', 'balance_I']).setValue(3);

          break;
        case 4:
          this.ptAssessment.get(['mobility_balance', 'balance_I']).setValue(4);

          break;
      }
    }
    if (controlName == 'static') {
      switch (event.target.value) {
        case 1:
          this.ptAssessment.get(['mobility_balance', 'static_sitting_I']).setValue(1);

          break;
        case 2:

          this.ptAssessment.get(['mobility_balance', 'static_sitting_I']).setValue(2);
          break;
        case 3:
          this.ptAssessment.get(['mobility_balance', 'static_sitting_I']).setValue(3);

          break;
        case 4:
          this.ptAssessment.get(['mobility_balance', 'static_sitting_I']).setValue(4);

          break;
      }
    }
    if (controlName == 'standing') {
      switch (event.target.value) {
        case 1:
          this.ptAssessment.get(['mobility_balance', 'static_standing_I']).setValue(1);

          break;
        case 2:

          this.ptAssessment.get(['mobility_balance', 'static_standing_I']).setValue(2);
          break;
        case 3:
          this.ptAssessment.get(['mobility_balance', 'static_standing_I']).setValue(3);

          break;
        case 4:
          this.ptAssessment.get(['mobility_balance', 'static_standing_I']).setValue(4);

          break;
      }
    }
    if (controlName == 'dynamic') {
      switch (event.target.value) {
        case 1:
          this.ptAssessment.get(['mobility_balance', 'dynamic_sitting_I']).setValue(1);

          break;
        case 2:

          this.ptAssessment.get(['mobility_balance', 'dynamic_sitting_I']).setValue(2);
          break;
        case 3:
          this.ptAssessment.get(['mobility_balance', 'dynamic_sitting_I']).setValue(3);

          break;
        case 4:
          this.ptAssessment.get(['mobility_balance', 'dynamic_sitting_I']).setValue(4);

          break;
      }
    }
    if (controlName == 'dynamic standing') {
      switch (event.target.value) {
        case 1:
          this.ptAssessment.get(['mobility_balance', 'dynamic_standing_I']).setValue(1);

          break;
        case 2:

          this.ptAssessment.get(['mobility_balance', 'dynamic_standing_I']).setValue(2);
          break;
        case 3:
          this.ptAssessment.get(['mobility_balance', 'dynamic_standing_I']).setValue(3);

          break;
        case 4:
          this.ptAssessment.get(['mobility_balance', 'dynamic_standing_I']).setValue(4);

          break;
      }
    }
    if (controlName === 'rolling') {
      if (event.target.checked) {
        this.ptAssessment.get(['mobility_balance', 'rolling_remark']).enable();
        this.ptAssessment.get(['mobility_balance', 'rolling_remark']).setValidators([Validators.required]);

      } else {
        this.ptAssessment.get(['mobility_balance', 'rolling_remark']).disable();
        this.ptAssessment.get(['mobility_balance', 'rolling_remark']).clearValidators();
        this.ptAssessment.get(['mobility_balance', 'rolling_remark']).setValue(null);
      }
      this.ptAssessment.get(['mobility_balance', 'rolling_remark']).updateValueAndValidity();
    }
    if (controlName === 'sitting') {
      if (event.target.checked) {
        this.ptAssessment.get(['mobility_balance', 'sitting_remark']).enable();
        this.ptAssessment.get(['mobility_balance', 'sitting_remark']).setValidators([Validators.required]);

      } else {
        this.ptAssessment.get(['mobility_balance', 'sitting_remark']).disable();
        this.ptAssessment.get(['mobility_balance', 'sitting_remark']).clearValidators();
        this.ptAssessment.get(['mobility_balance', 'sitting_remark']).setValue(null);
      }
      this.ptAssessment.get(['mobility_balance', 'sitting_remark']).updateValueAndValidity();
    }
    if (controlName === 'standing') {
      if (event.target.checked) {
        this.ptAssessment.get(['mobility_balance', 'standing_remark']).enable();
        this.ptAssessment.get(['mobility_balance', 'standing_remark']).setValidators([Validators.required]);

      } else {
        this.ptAssessment.get(['mobility_balance', 'standing_remark']).disable();
        this.ptAssessment.get(['mobility_balance', 'standing_remark']).clearValidators();
        this.ptAssessment.get(['mobility_balance', 'standing_remark']).setValue(null);
      }
      this.ptAssessment.get(['mobility_balance', 'standing_remark']).updateValueAndValidity();
    }
    if (controlName === 'gait') {
      if (event.target.checked) {
        this.ptAssessment.get(['mobility_balance', 'gait_remark']).enable();
        this.ptAssessment.get(['mobility_balance', 'gait_remark']).setValidators([Validators.required]);

      } else {
        this.ptAssessment.get(['mobility_balance', 'gait_remark']).disable();
        this.ptAssessment.get(['mobility_balance', 'gait_remark']).clearValidators();
        this.ptAssessment.get(['mobility_balance', 'gait_remark']).setValue(null);
      }
      this.ptAssessment.get(['mobility_balance', 'gait_remark']).updateValueAndValidity();
    }
    if (controlName === 'balance') {
      if (event.target.checked) {
        this.ptAssessment.get(['mobility_balance', 'balance_remark']).enable();
        this.ptAssessment.get(['mobility_balance', 'balance_remark']).setValidators([Validators.required]);

      } else {
        this.ptAssessment.get(['mobility_balance', 'balance_remark']).disable();
        this.ptAssessment.get(['mobility_balance', 'balance_remark']).clearValidators();
        this.ptAssessment.get(['mobility_balance', 'balance_remark']).setValue(null);
      }
      this.ptAssessment.get(['mobility_balance', 'balance_remark']).updateValueAndValidity();
    }
    if (controlName === 'static') {
      if (event.target.checked) {
        this.ptAssessment.get(['mobility_balance', 'static_sitting_remark']).enable();
        this.ptAssessment.get(['mobility_balance', 'static_sitting_remark']).setValidators([Validators.required]);

      } else {
        this.ptAssessment.get(['mobility_balance', 'static_sitting_remark']).disable();
        this.ptAssessment.get(['mobility_balance', 'static_sitting_remark']).clearValidators();
        this.ptAssessment.get(['mobility_balance', 'static_sitting_remark']).setValue(null);
      }
      this.ptAssessment.get(['mobility_balance', 'static_sitting_remark']).updateValueAndValidity();
    }
    if (controlName === 'dynamic') {
      if (event.target.checked) {
        this.ptAssessment.get(['mobility_balance', 'dynamic_sitting_remark']).enable();
        this.ptAssessment.get(['mobility_balance', 'dynamic_sitting_remark']).setValidators([Validators.required]);

      } else {
        this.ptAssessment.get(['mobility_balance', 'dynamic_sitting_remark']).disable();
        this.ptAssessment.get(['mobility_balance', 'dynamic_sitting_remark']).clearValidators();
        this.ptAssessment.get(['mobility_balance', 'dynamic_sitting_remark']).setValue(null);
      }
      this.ptAssessment.get(['mobility_balance', 'dynamic_sitting_remark']).updateValueAndValidity();
    }
    if (controlName === 'standingstatic') {
      if (event.target.checked) {
        this.ptAssessment.get(['mobility_balance', 'static_standing_remark']).enable();
        this.ptAssessment.get(['mobility_balance', 'static_standing_remark']).setValidators([Validators.required]);

      } else {
        this.ptAssessment.get(['mobility_balance', 'static_standing_remark']).disable();
        this.ptAssessment.get(['mobility_balance', 'static_standing_remark']).clearValidators();
        this.ptAssessment.get(['mobility_balance', 'static_standing_remark']).setValue(null);
      }
      this.ptAssessment.get(['mobility_balance', 'static_standing_remark']).updateValueAndValidity();
    }
    if (controlName === 'dynamic standing') {
      if (event.target.checked) {
        this.ptAssessment.get(['mobility_balance', 'dynamic_standing_remark']).enable();
        this.ptAssessment.get(['mobility_balance', 'dynamic_standing_remark']).setValidators([Validators.required]);

      } else {
        this.ptAssessment.get(['mobility_balance', 'dynamic_standing_remark']).disable();
        this.ptAssessment.get(['mobility_balance', 'dynamic_standing_remark']).clearValidators();
        this.ptAssessment.get(['mobility_balance', 'dynamic_standing_remark']).setValue(null);
      }
      this.ptAssessment.get(['mobility_balance', 'dynamic_standing_remark']).updateValueAndValidity();
    }
    if (controlName === 'transferassisting') {

      switch (event.target.value) {
        case 1:
          this.ptAssessment.get(['transfer_assisting', 'transfer_assisting_none']).setValue(1);
          break;
        case 2:
          this.ptAssessment.get(['transfer_assisting', 'transfer_assisting_none']).setValue(1);
          break;
        case 3:
          this.ptAssessment.get(['transfer_assisting', 'transfer_assisting_none']).setValue(1);
          break;
        case 4:
          this.ptAssessment.get(['transfer_assisting', 'transfer_assisting_none']).setValue(1);
          break;
        case 5:
          this.ptAssessment.get(['transfer_assisting', 'transfer_assisting_none']).setValue(1);
          break;
      }
    }
    if (controlName == 'transferassisting') {
      // }
      if (event.target.value == '5') {
        console.log('called yes');
        this.ptAssessment.get(['transfer_assisting', 'transfer_assisting_others_description']).enable();
      } else {
        console.log('called no');
        this.ptAssessment.get(['transfer_assisting', 'transfer_assisting_others_description']).disable();
        this.ptAssessment.get(['transfer_assisting', 'transfer_assisting_others_description']).setValue(
          null
        );
      }
      if (event.target.checked) {
        this.ptAssessment.get(['transfer_assisting', 'transfer_assisting_others_description']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['transfer_assisting', 'transfer_assisting_others_description']).clearValidators();
      }
      this.ptAssessment.get(['transfer_assisting', 'transfer_assisting_others_description']).updateValueAndValidity();
    }
    if (controlName === 'transferassisting') {
      this.ptAssessment.get(['transfer_assisting', 'transfer_assisting_pair']).disable();
      this.ptAssessment.get(['transfer_assisting', 'transfer_assisting_pair']).setValue(null);
      if (event.target.value === '2') {
        this.ptAssessment.get(['transfer_assisting', 'transfer_assisting_pair']).enable();
        this.ptAssessment.get(['transfer_assisting', 'transfer_assisting_pair']).setValidators([Validators.required]);
      }
      else {
        this.ptAssessment.get(['transfer_assisting', 'transfer_assisting_pair']).disable();
        this.ptAssessment.get(['transfer_assisting', 'transfer_assisting_pair']).setValue(null);
      }
      this.ptAssessment.get(['transfer_assisting', 'transfer_assisting_pair']).updateValueAndValidity();
    }
    if (controlName === 'musco') {
      if (event.target.checked) {
        this.ptAssessment.get(['musculoskeletal_assessment', 'motion_extreme_yes']).disable();
        this.ptAssessment.get(['musculoskeletal_assessment', 'motion_extreme_yes']).setValue(null);
        this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme']).disable();
        this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme']).disable();
        this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_right']).disable();
        this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_left']).disable();
        this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme_right']).disable();
        this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme_left']).disable();
        this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme']).setValue(null);
        this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme']).setValue(null);
        this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_right']).setValue(null);
        this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_left']).setValue(null);
        this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme_right']).setValue(null);
        this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme_left']).setValue(null);
      }
      else {
        this.ptAssessment.get(['musculoskeletal_assessment', 'motion_extreme_yes']).enable();
      }
    }
    if (controlName == 'motion') {
      if (event.target.value == 'Yes') {
        this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme']).enable();
        this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme']).enable();
      }
      else {
        this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme']).disable();
        this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme']).disable();
        this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_right']).disable();
        this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_left']).disable();
        this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme']).setValue(null);
        this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme']).setValue(null);
        this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_right']).setValue(null);
        this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_left']).setValue(null);
        this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme_right']).disable();
        this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme_left']).disable();
        this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme_right']).setValue(null);
        this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme_left']).setValue(null);
      }
    }
   
    if (controlName == 'left') {
      if (event.target.checked) {
        // If the checkbox is checked, enable both checkboxes and set validators for both.
        this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme_right']).enable();
        this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme_left']).enable();
        this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme_left']).setValidators([Validators.required]);
        this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme_right']).setValidators([Validators.required]);
      } else {
        // If the checkbox is unchecked, disable both checkboxes and clear validators for both.
        this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme_right']).disable();
        this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme_left']).disable();
        this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme_right']).clearValidators();
        this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme_left']).clearValidators();
        this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme_right']).setValue(null);
        this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme_left']).setValue(null);
      }
    
      // Update the validity of both checkboxes.
      this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme_right']).updateValueAndValidity();
      this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme_left']).updateValueAndValidity();
    
      // If the event target value is 'lower_extreme_left', set validators accordingly.
      if (event.target.value == 'lower_extreme_left') {
        this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme_left']).setValidators([Validators.required]);
        this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme_right']).clearValidators();
      }
      // If the event target value is 'lower_extreme_right', set validators accordingly.
      else if (event.target.value == 'lower_extreme_right') {
        this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme_right']).setValidators([Validators.required]);
        this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme_left']).clearValidators();
      }
    
      // Update the validity of the selected checkbox.
      this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme_right']).updateValueAndValidity();
      this.ptAssessment.get(['musculoskeletal_assessment', 'lower_extreme_left']).updateValueAndValidity();
    }
    if (controlName == 'right') {
      if (event.target.checked) {
        // If the checkbox is checked, enable both checkboxes and set validators for both.
        this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_right']).enable();
        this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_left']).enable();
        this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_left']).setValidators([Validators.required]);
        this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_right']).setValidators([Validators.required]);
      } else {
        // If the checkbox is unchecked, disable both checkboxes and clear validators for both.
        this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_right']).disable();
        this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_left']).disable();
        this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_right']).clearValidators();
        this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_left']).clearValidators();
        this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_right']).setValue(null);
        this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_left']).setValue(null);
      }
    
      // Update the validity of both checkboxes.
      this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_right']).updateValueAndValidity();
      this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_left']).updateValueAndValidity();
    
      // If the event target value is 'upper_extreme_left', set validators accordingly.
      if (event.target.value == 'upper_extreme_left') {
        this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_left']).setValidators([Validators.required]);
        this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_right']).clearValidators();
      }
      // If the event target value is 'upper_extreme_right', set validators accordingly.
      else if (event.target.value == 'upper_extreme_right') {
        this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_right']).setValidators([Validators.required]);
        this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_left']).clearValidators();
      }
    
      // Update the validity of the selected checkbox.
      this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_right']).updateValueAndValidity();
      this.ptAssessment.get(['musculoskeletal_assessment', 'upper_extreme_left']).updateValueAndValidity();
    }
    
  }
}
