import { Component, OnInit } from '@angular/core';
import { MaikaListService } from 'src/app/services/maika-list.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MasterService } from 'src/app/services/master.service';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-environment-and-child-safety-ass-list',
  templateUrl: './environment-and-child-safety-ass-list.component.html',
  styleUrls: ['./environment-and-child-safety-ass-list.component.scss'],
})
export class EnvironmentAndChildSafetyAssListComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Extra Activities',
      subTitle: 'Dashboard',
    },
  ];
  detail: any = [];
  arrordionOpenStates: boolean[] = [];
  allData: any = [];
  babyData: any = {};
  loose_parts: any = {};
  pets: any = {};
  toys: any = {};
  washroom_fall: any = {};
  require: any = {};
  viewDetail: any = {};
  displayMaximizable: boolean = false;
  accessories_toys_remarks: any
  childSafetyData: any = [];
  childSafetyDataById: any = [];
  filterVal: string;
  loading: any;
  tooltipText: string = 'Environment and child safety';
  parsedData: any = [];
  datalength: any;
  children: any;
  accessories: any;
  remark: any;
  outDoor: any;
  otherValue: any;
  amenitiesValue: any;
  permissionData: any = [];
  permissions: any = [];
  constructor(private child_safety_service: MaikaListService, private spinner: NgxSpinnerService, private router: Router, private masterService: MasterService, private messageService: MessageService,
  ) {
    this.getAllUserPermission()
  }

  // fn to get the data from backend
  getAllData() {
    this.spinner.show()
    const id = localStorage.getItem('babyId');
    this.child_safety_service.getExtraActivityListById(id).then((res: any) => {
      this.spinner.hide()
      console.log('response', res);
      this.childSafetyData = res.data;
      // this.childSafetyDataById.push(this.childSafetyData)
      this.allData = res.data;
      this.datalength = res?.data?.length;
      console.log('envirnment safety data', res.data[0]);
      // Initialize accordion openstate with false value
      this.arrordionOpenStates = new Array(this.allData.length).fill(false);
      this.childSafetyData?.map((ele: any) => {
        // parsing data
        // this.parsedData.push(JSON.parse(ele?.child_safety_json))
        // console.log(this.parsedData)
      });

    }, (error: any) => {
      this.spinner.hide()
      // Handle any errors that occurred during the API call
    

    });
  }

  ngOnInit(): void {
    this.getAllData();
    localStorage.removeItem('extraactivity');
    // this.getAllUserPermission();
  }

  getData(id: any) {
    // ;
    this.allData?.forEach((res: any) => {
      console.log(this.allData);
      if (res.id == id) {
        console.log('show.....', res.id, 'id', id);
        this.babyData = res;
        console.log('babyyyyy:', this.babyData);
        setTimeout(() => {
          this.removeCSs();
        }, 1500);
      }
    })

  }

  editOnCLick(id: any) {
    // const editExtraActivity = localStorage.getItem('babyId');
    // localStorage.setItem('extraactivitys', editExtraActivity);
    // console.log("editExtraActivity", editExtraActivity);
    localStorage.setItem('extraactivity', id);
    console.log("id", id);
    this.router.navigateByUrl('/portal/extra-activities');
  }
  // open view dialog
  // details: any = [];
  showMaximizableDialog(id: any) {
    this.displayMaximizable = true;
    this.viewDetail = [];

    this.babyData?.environment_child_safety?.forEach((res: any) => {
      console.log("ID's", id, "RES ID", res.id);
      if (res.id == id) {
        console.log('view detail:', res);
        this.viewDetail = res;
        // this.require = JSON.parse(res?.child_room_json);
        // console.log('REQUIRE' +this.require);

        this.require = res?.child_safety_json;
        console.log("Requires", this.require)

        // console.log('REQUIRE' + this.require);
        this.children = this.require?.childroom;
        console.log("children", this.children);
        this.outDoor = this.require?.OutdoorActivitys;
        console.log("OUTDOOR", this.outDoor);
        this.otherValue = this.require?.others
        console.log("OTHER VALUES", this.otherValue);
        // console.log('WASHROOM' + this.washroom_fall);
        this.accessories = this.require?.accessories;
        console.log("accessories", this.accessories);
        this.accessories_toys_remarks = this.require?.accessories?.toys_remarks?.remark;
        console.log("accessories toys remarks", this.accessories_toys_remarks);
        this.amenitiesValue = this.require?.amenities;
        console.log("Amenties", this.amenitiesValue)
        this.remark = this.require?.remarks;
        console.log("Remarks", this.remark);
        this.loose_parts = res?.loose_parts_sharp_edges;
        this.pets = res?.pets;

        // this.relatives.push(JSON.parse(res?.relatives_name_json));
        // this.sibling.push(JSON.parse(res?.sibling_details_json));
        // console.log('single record:', this.viewDetail);
      } else {
        ;
      }
      // console.log(id);
    });
    // this.displayMaximizable = true;
  }
  // details: any = [];
  // showMaximizableDialog(id: any) {
  //   // ;
  //   this.viewDetail = [];
  //   // this.detail = [];
  //   this.babyData?.environment_child_safety?.map((res: any) => {
  //     if (res.id == id) {
  //       this.viewDetail= JSON.parse(res?.environment_child_safety);
  //       console.log(this.viewDetail)
  //     }
  //   });

  //   this.displayMaximizable = true;
  // }

  // 
  // Permission setup
  getAllUserPermission() {
    let id = localStorage.getItem('user_id');
    this.masterService.getUserPremissionById(id).then((res: any) => {
     

      this.permissionData = res.data;

      for (let i = 0; i < this.permissionData.length; i++) {
        
        this.permissions.push(this.permissionData[i].permission_id);
      }
      console.log("all permissions", this.permissions);
    })
  }

  checkPermission(permission: number) {
   
    if (this.permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
    // return this.permissions.includes(permission);
  }
  // 
  reset(dt) {
    dt.reset();
    this.filterVal = '';
  }
  getbabyhealthdata() {
    let babydata: any;
    const babyId = localStorage.getItem('babyId');


    localStorage.setItem('create', babyId);
    this.router.navigateByUrl('/portal/extra-activities');

  }
  softReload() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['portal/patient-view']);
    });
  }
  removeCSs(){
    var i , getId;
     for(i=0; i < 10000 ; i++ )
      {
        var id = `pr_id_${i}`
        getId=document.getElementById(id);

        if(getId){
          const tdElement = getId.querySelector('.p-datatable-tbody');
          const tr = tdElement?.querySelector('tr');
          const td= tr?.querySelector('td')
         if(td){
          getId.removeAttribute('id');     
          }
         
        }
      }
  }
}
