import { Component, OnInit,HostListener  } from '@angular/core';
import { PtServiceService } from 'src/app/services/pt-service.service';
@Component({
  selector: 'app-pt-assessment-adults-listing',
  templateUrl: './pt-assessment-adults-listing.component.html',
  styleUrls: ['./pt-assessment-adults-listing.component.scss']
})

export class PtAssessmentAdultsListingComponent implements OnInit {
  breadcrumb = [
    {
      title: 'PT Initial Assessment Adult',
      subTitle: 'Dashboard'
    }
  ]
  data: any;
  displayMaximizable: boolean = false;
  viewDetail: any = [];
  ptAssListData: any = [];
  adultdata:any=[];
  loading: any;
  viewDetailJson:any;
  filterVal: string;
  medication:any=[];
  tooltipText: string = 'Initial  Adult Assessment';

  constructor(private adultassessment :PtServiceService) { }

  ngOnInit(): void {
    this. getAllData()
  }
  details: any = [];
  showMaximizableDialog(data: any) {
    this.details = [];
    this.viewDetail = [];
    this.viewDetail.push(data)
   this.displayMaximizable = true;
   console.log(this.viewDetailJson);
 }


  getAllData() {

    this.adultassessment.ptadultassessment().subscribe((res: any) => {
      this.ptAssListData = res?.data;
      this.ptAssListData?.map((ele: any,key:any) => {
        if(ele?.adult_assessment_json) {
          if(typeof JSON.parse(ele?.adult_assessment_json) == "object") {
            this.ptAssListData[key] = {...JSON.parse(ele?.adult_assessment_json),...ele};
          };
          if(typeof JSON.parse(ele?.adult_assessment_json) == "string") {
            this.ptAssListData[key] = {...JSON.parse(JSON.parse(ele?.adult_assessment_json)),...ele};
          }
        }

      });
    })


  }
  reset(dt) {
    dt.reset();
    this.filterVal = '';
  }
}
