import { Component, OnInit } from '@angular/core';
import { MaikaListService } from 'src/app/services/maika-list.service';
import { DialogModule } from 'primeng/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-feeding-assessment-list',
  templateUrl: './feeding-assessment-list.component.html',
  styleUrls: ['./feeding-assessment-list.component.scss'],
})
export class FeedingAssessmentListComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Feeding Assessment',
      subTitle: 'Dashboard',
    },
  ];
  i:any=0;
  feedingData: any = {};
  displayMaximizable: boolean = false;
  feeding_ass: any = [];
  viewDetail: any = [];
  initialAssData: any = [];
  loading: any;
  filterVal:string;
  tooltipText: string = 'Initial Assessment';
  constructor(private initial_ass_service: MaikaListService,private spinner:NgxSpinnerService,    private messageService: MessageService,    ) { }
  // fn to get the data from backend
  getAllData() {
   this.spinner.show()
    this.initial_ass_service.getFeedingAss().subscribe((res: any) => {
      this.spinner.hide()
      console.log('response', res);
      this.initialAssData = res.data;
      console.log('initial ass data', res.data);
      // this.initialAssData.map((ele: any) => {
      //   console.log('element', ele);
      //   //parsing the data
      //   // JSON.parse(ele?.initial_ass_json)
      //   // console.log(".......",JSON.parse((ele?.initial_ass_json)))
      // });
     
    },(error: any) => {
      this.spinner.hide()
      // Handle any errors that occurred during the API call
    
     
    });
  }

  ngOnInit(): void {
    this.getAllData();
  }
  getData(id: any) {
    this.initialAssData?.map((res: any) => {
      if (res.id === id) {
        this.feedingData = res;
        console.log("feedingDataaa:", this.feedingData);
      }
    })
  }
  // open view dialog
  details: any = [];
  showMaximizableDialog(id: any) {
    // debugger;
    this.viewDetail = [];
    this.feeding_ass = [];
    this.initialAssData.map((res: any) => {
      console.log(res.id, id)
      // if (res.id == id) {
        res.feeding_assesments?.map((feed: any) => {
          if (feed.id == id) {
            this.feeding_ass.push(JSON.parse(feed?.feeding_json));
            this.viewDetail.push(res);
          }
        });
        console.log('view detail', res);
        // console.log('single record:', this.feeding_ass[0]);
      // } else {
      //   ;
      // }
      console.log(id);
    });
    this.displayMaximizable = true;
  }
  reset(dt) {
    dt.reset();
    this.filterVal = '';
  }
}
