import { Component, ElementRef, OnInit, ViewChild,OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CommonApiService } from 'src/app/services/common-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { GrowthMonitorService } from 'src/app/services/growth-monitor.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-one-to-three-yr-monitor',
  templateUrl: './one-to-three-yr-monitor.component.html',
  providers: [MessageService],
  styleUrls: ['./one-to-three-yr-monitor.component.scss']
})
export class OneToThreeYrMonitorComponent implements OnInit,OnDestroy {
  @ViewChild('video') videoElement: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  stream: MediaStream;
  ImageName = "Captured-Image"
  openCameraforUpload: boolean = false
  IndexforImage = 0
  capturedImg: File

  public flag: boolean = true;
  breadcrumb = [
    {
      title: 'One To Three Year Growth Monitoring',
      subtitle: 'Dashboard'
    }
  ]
  genderArray: any = [
    { name: 'Male', value: 'male' },
    { name: 'Female', value: 'female' },
  ];
  getToken: any;
  getRole: any;
  getname: any;
  today = new Date();
  babyDetails: any = [];
  selectedDate: Date = null; // Initialize with today's date;
  babyGender: any;
  years: any = '';
  month: any = '';
  days: any = '';
  babyAge: any;
  name: any = '';
  isedit: boolean = false;
  weightConverted=false;
  heightConverted=false;
  oneToThreeYearMonitor: FormGroup;
  babyImgJSon: any
  growthData: any;
  babyImgArray: any = []
  ImageUrl: string = environment.imgUrl;
  selectedFile: File | null = null;
  capturedByCamera: any = [];
  imagePreview: boolean = false;
  imgSrcForPreview: any;
  constructor(private common_service: CommonApiService, private fb: FormBuilder, private messageService: MessageService, private growth_service: GrowthMonitorService, private router: Router, private spinner: NgxSpinnerService) {
    this.oneToThreeYearMonitor = this.fb.group({
      baby_details_id: new FormControl('', Validators.required),
      walk: new FormControl(null),
      walk_description: new FormControl(null),
      squats_toy: new FormControl(null),
      squats_toy_description: new FormControl(null),
      jump: new FormControl(null),
      jump_description: new FormControl(null),
      pull_toys: new FormControl(null),
      pull_toys_description: new FormControl(null),
      runs: new FormControl(null),
      runs_description: new FormControl(null),
      stand_on_toes: new FormControl(null),
      stand_on_toes_description: new FormControl(null),
      stands_on_furniture: new FormControl(null),
      stands_on_furniture_description: new FormControl(null),
      kicks_balls: new FormControl(null),
      kicks_balls_description: new FormControl(null),
      stairs: new FormControl(null),
      stairs_description: new FormControl(null),
      word_phrases: new FormControl(null),
      word_phrases_description: new FormControl(null),
      unrelated_directions: new FormControl(null),
      unrelated_directions_description: new FormControl(null),
      understanding: new FormControl(null),
      understanding_description: new FormControl(null),
      frequent_move: new FormControl(null),
      frequent_move_description: new FormControl(null),
      strength: new FormControl(null),
      strength_description: new FormControl(null),
      walk_with_heel: new FormControl(null),
      walk_with_heel_description: new FormControl(null),
      leans: new FormControl(null),
      leans_description: new FormControl(null),
      coordinates: new FormControl(null),
      coordinates_description: new FormControl(null),
      dressed: new FormControl(null),
      dressed_description: new FormControl(null),
      regular_sleep: new FormControl(null),
      regular_sleep_description: new FormControl(null),
      foods: new FormControl(null),
      foods_description: new FormControl(null),
      flips: new FormControl(null),
      flips_description: new FormControl(null),
      crayons: new FormControl(null),
      crayons_description: new FormControl(null),
      sorts: new FormControl(null),
      sorts_description: new FormControl(null),
      stacks: new FormControl(null),
      stacks_description: new FormControl(null),
      takes_toys: new FormControl(null),
      takes_toys_description: new FormControl(null),
      combines: new FormControl(null),
      combines_description: new FormControl(null),
      imitates: new FormControl(null),
      imitates_description: new FormControl(null),
      follows: new FormControl(null),
      follows_description: new FormControl(null),
      identifies: new FormControl(null),
      identifies_description: new FormControl(null),
      babbling: new FormControl(null),
      babbling_description: new FormControl(null),
      familiar: new FormControl(null),
      familiar_description: new FormControl(null),
      basic: new FormControl(null),
      basic_description: new FormControl(null),
      understands: new FormControl(null),
      understands_description: new FormControl(null),
      questions: new FormControl(null),
      questions_description: new FormControl(null),
      plurals: new FormControl(null),
      plurals_description: new FormControl(null),
      in_on: new FormControl(null),
      in_on_description: new FormControl(null),
      head_shake: new FormControl(null),
      head_shake_description: new FormControl(null),
      use_words: new FormControl(null),
      use_words_description: new FormControl(null),
      names: new FormControl(null),
      names_description: new FormControl(null),
      action: new FormControl(null),
      action_description: new FormControl(null),
      gestures: new FormControl(null),
      gestures_description: new FormControl(null),
      direction: new FormControl(null),
      direction_description: new FormControl(null),
      stories: new FormControl(null),
      stories_description: new FormControl(null),
      phrases: new FormControl(null),
      phrases_description: new FormControl(null),
      concepts: new FormControl(null),
      concepts_description: new FormControl(null),
      drink: new FormControl(null),
      drink_description: new FormControl(null),
      table_foods: new FormControl(null),
      table_foods_description: new FormControl(null),
      interacting: new FormControl(null),
      interacting_description: new FormControl(null),
      picked_up: new FormControl(null),
      picked_up_description: new FormControl(null),
      eye_contacts: new FormControl(null),
      eye_contacts_description: new FormControl(null),
      imitating_others: new FormControl(null),
      imitating_others_description: new FormControl(null),
      turns_head: new FormControl(null),
      turns_head_description: new FormControl(null),
      points_objects: new FormControl(null),
      points_objects_description: new FormControl(null),
      locate_objects: new FormControl(null),
      locate_objects_description: new FormControl(null),
      claps: new FormControl(null),
      claps_description: new FormControl(null),
      enjoy_playing: new FormControl(null),
      enjoy_playing_description: new FormControl(null),
      explores: new FormControl(null),
      explores_description: new FormControl(null),
      swung: new FormControl(null),
      swung_description: new FormControl(null),
      playground: new FormControl(null),
      playground_description: new FormControl(null),
      swinging: new FormControl(null),
      swinging_description: new FormControl(null),
      participate: new FormControl(null),
      participate_description: new FormControl(null),
      mouthing: new FormControl(null),
      mouthing_description: new FormControl(null),
      listen_book: new FormControl(null),
      listen_book_description: new FormControl(null),
      roughhousing: new FormControl(null),
      roughhousing_description: new FormControl(null),
      messy_activity: new FormControl(null),
      messy_activity_description: new FormControl(null),
      cuddling: new FormControl(null),
      cuddling_description: new FormControl(null),
      calm: new FormControl(null),
      calm_description: new FormControl(null),
      accustomed: new FormControl(null),
      accustomed_description: new FormControl(null),
      sleeping: new FormControl(null),
      sleeping_description: new FormControl(null),
      touch: new FormControl(null),
      touch_description: new FormControl(null),
      cries: new FormControl(null),
      cries_description: new FormControl(null),
      soothe: new FormControl(null),
      soothe_description: new FormControl(null),
      transition: new FormControl(null),
      transition_description: new FormControl(null),
      happy: new FormControl(null),
      happy_description: new FormControl(null),
      excessive: new FormControl(null),
      excessive_description: new FormControl(null),
      supportive: new FormControl(null),
      supportive_description: new FormControl(null),
      peers: new FormControl(null),
      peers_description: new FormControl(null),
      health_json: this.fb.array([]),
      baby_images: this.fb.array([])
    })
  }
  ngOnDestroy(): void {
    this.shutDownCamera();
    // Perform cleanup actions here, such as closing the camera
    // Close the camera code here...
  }
  ngOnInit(): void {
    this.spinner.show()

    this.common_service.getBabyDetails().subscribe((res: any) => {
      this.spinner.hide()
      this.babyDetails = res?.data;
      this.setBasicDetails();

    }, (error: any) => {
      this.spinner.hide()

      // Handle any errors that occurred during the API call

    });
    this.getname = localStorage.getItem('name');
    this.getToken = localStorage.getItem('token');
    this.getRole = localStorage.getItem('role')
  }

  logout() {
    localStorage.clear();
    // window.location.reload();
    this.router.navigate(['/']);
  }
  setBasicDetails() {
    if (localStorage.getItem('editedOrderId')) {
      this.getEditFormData();
      this.isedit = true;
    }
    else if (localStorage.getItem('babyId')) {
      const getEdit = localStorage.getItem('babyId');
      this.getBabyDetails({ 'value': getEdit })
      this.oneToThreeYearMonitor.controls.baby_details_id.setValue(
        JSON.parse(getEdit)
      );
      this.addVitaminObject('add', 0);
      this.addBabyImg('add', 0);
      this.isedit = false;
      this.oneToThreeYearMonitor.controls.walk_description.disable();
      this.oneToThreeYearMonitor.controls.squats_toy_description.disable();
      this.oneToThreeYearMonitor.controls.jump_description.disable();
      this.oneToThreeYearMonitor.controls.pull_toys_description.disable();
      this.oneToThreeYearMonitor.controls.runs_description.disable();
      this.oneToThreeYearMonitor.controls.stand_on_toes_description.disable();
      this.oneToThreeYearMonitor.controls.stands_on_furniture_description.disable();
      this.oneToThreeYearMonitor.controls.kicks_balls_description.disable();
      this.oneToThreeYearMonitor.controls.stairs_description.disable();
      this.oneToThreeYearMonitor.controls.word_phrases_description.disable();
      this.oneToThreeYearMonitor.controls.unrelated_directions_description.disable();
      this.oneToThreeYearMonitor.controls.understanding_description.disable();
      this.oneToThreeYearMonitor.controls.frequent_move_description.disable();
      this.oneToThreeYearMonitor.controls.strength_description.disable();
      this.oneToThreeYearMonitor.controls.walk_with_heel_description.disable();
      this.oneToThreeYearMonitor.controls.leans_description.disable();
      this.oneToThreeYearMonitor.controls.coordinates_description.disable();
      this.oneToThreeYearMonitor.controls.word_phrases_description.disable();
      this.oneToThreeYearMonitor.controls.unrelated_directions_description.disable();
      this.oneToThreeYearMonitor.controls.understanding_description.disable();
      this.oneToThreeYearMonitor.controls.frequent_move_description.disable();
      this.oneToThreeYearMonitor.controls.strength_description.disable();
      this.oneToThreeYearMonitor.controls.walk_with_heel_description.disable();
      this.oneToThreeYearMonitor.controls.leans_description.disable();
      this.oneToThreeYearMonitor.controls.coordinates_description.disable();
      this.oneToThreeYearMonitor.controls.dressed_description.disable();
      this.oneToThreeYearMonitor.controls.regular_sleep_description.disable();
      this.oneToThreeYearMonitor.controls.foods_description.disable();
      this.oneToThreeYearMonitor.controls.flips_description.disable();
      this.oneToThreeYearMonitor.controls.crayons_description.disable();
      this.oneToThreeYearMonitor.controls.sorts_description.disable();
      this.oneToThreeYearMonitor.controls.stacks_description.disable();
      this.oneToThreeYearMonitor.controls.takes_toys_description.disable();
      this.oneToThreeYearMonitor.controls.combines_description.disable();
      this.oneToThreeYearMonitor.controls.imitates_description.disable();
      this.oneToThreeYearMonitor.controls.follows_description.disable();
      this.oneToThreeYearMonitor.controls.identifies_description.disable();
      this.oneToThreeYearMonitor.controls.babbling_description.disable();
      this.oneToThreeYearMonitor.controls.familiar_description.disable();
      this.oneToThreeYearMonitor.controls.basic_description.disable();
      this.oneToThreeYearMonitor.controls.understands_description.disable();
      this.oneToThreeYearMonitor.controls.questions_description.disable();
      this.oneToThreeYearMonitor.controls.plurals_description.disable();
      this.oneToThreeYearMonitor.controls.in_on_description.disable();
      this.oneToThreeYearMonitor.controls.head_shake_description.disable();
      this.oneToThreeYearMonitor.controls.use_words_description.disable();
      this.oneToThreeYearMonitor.controls.names_description.disable();
      this.oneToThreeYearMonitor.controls.action_description.disable();
      this.oneToThreeYearMonitor.controls.gestures_description.disable();
      this.oneToThreeYearMonitor.controls.direction_description.disable();
      this.oneToThreeYearMonitor.controls.stories_description.disable();
      this.oneToThreeYearMonitor.controls.phrases_description.disable();
      this.oneToThreeYearMonitor.controls.concepts_description.disable();
      this.oneToThreeYearMonitor.controls.table_foods_description.disable();
      this.oneToThreeYearMonitor.controls.drink_description.disable();
      this.oneToThreeYearMonitor.controls.interacting_description.disable();
      this.oneToThreeYearMonitor.controls.picked_up_description.disable();
      this.oneToThreeYearMonitor.controls.eye_contacts_description.disable();
      this.oneToThreeYearMonitor.controls.imitating_others_description.disable();
      this.oneToThreeYearMonitor.controls.turns_head_description.disable();
      this.oneToThreeYearMonitor.controls.points_objects_description.disable();
      this.oneToThreeYearMonitor.controls.locate_objects_description.disable();
      this.oneToThreeYearMonitor.controls.claps_description.disable();
      this.oneToThreeYearMonitor.controls.enjoy_playing_description.disable();
      this.oneToThreeYearMonitor.controls.explores_description.disable();
      this.oneToThreeYearMonitor.controls.playground_description.disable();
      this.oneToThreeYearMonitor.controls.swinging_description.disable();
      this.oneToThreeYearMonitor.controls.participate_description.disable();
      this.oneToThreeYearMonitor.controls.mouthing_description.disable();
      this.oneToThreeYearMonitor.controls.listen_book_description.disable();
      this.oneToThreeYearMonitor.controls.roughhousing_description.disable();
      this.oneToThreeYearMonitor.controls.messy_activity_description.disable();
      this.oneToThreeYearMonitor.controls.cuddling_description.disable();
      this.oneToThreeYearMonitor.controls.calm_description.disable();
      this.oneToThreeYearMonitor.controls.accustomed_description.disable();
      this.oneToThreeYearMonitor.controls.sleeping_description.disable();
      this.oneToThreeYearMonitor.controls.touch_description.disable();
      this.oneToThreeYearMonitor.controls.cries_description.disable();
      this.oneToThreeYearMonitor.controls.soothe_description.disable();
      this.oneToThreeYearMonitor.controls.transition_description.disable();
      this.oneToThreeYearMonitor.controls.happy_description.disable();
      this.oneToThreeYearMonitor.controls.excessive_description.disable();
      this.oneToThreeYearMonitor.controls.supportive_description.disable();
      this.oneToThreeYearMonitor.controls.peers_description.disable();
    }



  }
  keyPress(event: KeyboardEvent) {
    const pattern = /^\d+(\.\d{0,2})?$/;

    const inputValue = (event.target as HTMLInputElement).value;
    const proposedValue = inputValue + String.fromCharCode(event.charCode);

    if (!pattern.test(proposedValue)) {
      // Invalid input, prevent input
      event.preventDefault();
    }
  }
  getEditFormData() {
    this.spinner.show()
    const getEdit = localStorage.getItem('editedOrderId')
    this.common_service.getEditGrowthMonitoring(getEdit).then((res: any) => {
      this.spinner.hide()

      const detailsInGrowthMonitoring = res.data[0];
      const developmentMilestones = res.data[0].development_milestones;
      const milestoneData = JSON.parse(developmentMilestones[0].development_milestone);
      this.growthData = milestoneData

      const physical_assessment_details_json = developmentMilestones[0].physical_assessment_details_json

      this.oneToThreeYearMonitor.controls.baby_details_id.setValue(detailsInGrowthMonitoring.baby_id)
      this.babyGender = detailsInGrowthMonitoring.gender;
      this.selectedDate = detailsInGrowthMonitoring.date_of_birth;
      this.days = detailsInGrowthMonitoring.days;
      this.month = detailsInGrowthMonitoring.months;
      this.years = detailsInGrowthMonitoring.years;
      const VitaminsMedication = physical_assessment_details_json;


      const babyImg = milestoneData.baby_images;
      this.babyImgJSon = developmentMilestones[0].growth_images_json;



      this.addBabyImg('add', 0);

      var check =true
      for (let i = 0; i < VitaminsMedication.length; i++) {
        if((VitaminsMedication[i].height != null) || (VitaminsMedication[i].time!=null) || (VitaminsMedication[i].weight != null)){
          check = false
          let vitaminArray = this.gethealthFormArray();
          let VitaminsMedicationFromApi = VitaminsMedication[i] || {};
  
          let newVitaminArray = new FormGroup({
            height: new FormControl(VitaminsMedicationFromApi.height || null),
            time: new FormControl(
              VitaminsMedicationFromApi.time
                ? new Date(VitaminsMedicationFromApi.time)
                : null
            ),
            weight: new FormControl(VitaminsMedicationFromApi.weight || null),
          });
  
          vitaminArray.insert(i, newVitaminArray);
        }
       
      }
      this.oneToThreeYearMonitor.controls.walk.setValue(milestoneData.walk);
      this.oneToThreeYearMonitor.controls.walk_description.setValue(milestoneData.walk_description);
      this.oneToThreeYearMonitor.controls.squats_toy.setValue(milestoneData.squats_toy);
      this.oneToThreeYearMonitor.controls.squats_toy_description.setValue(milestoneData.squats_toy_description);
      this.oneToThreeYearMonitor.controls.jump.setValue(milestoneData.jump);
      this.oneToThreeYearMonitor.controls.jump_description.setValue(milestoneData.jump_description);
      this.oneToThreeYearMonitor.controls.pull_toys.setValue(milestoneData.pull_toys);
      this.oneToThreeYearMonitor.controls.pull_toys_description.setValue(milestoneData.pull_toys_description);
      this.oneToThreeYearMonitor.controls.runs.setValue(milestoneData.runs);
      this.oneToThreeYearMonitor.controls.runs_description.setValue(milestoneData.runs_description);
      this.oneToThreeYearMonitor.controls.stand_on_toes.setValue(milestoneData.stand_on_toes);
      this.oneToThreeYearMonitor.controls.stand_on_toes_description.setValue(milestoneData.stand_on_toes_description);
      this.oneToThreeYearMonitor.controls.stands_on_furniture.setValue(milestoneData.stands_on_furniture);
      this.oneToThreeYearMonitor.controls.stands_on_furniture_description.setValue(milestoneData.stands_on_furniture_description);
      this.oneToThreeYearMonitor.controls.kicks_balls.setValue(milestoneData.kicks_balls);
      this.oneToThreeYearMonitor.controls.kicks_balls_description.setValue(milestoneData.kicks_balls_description);
      this.oneToThreeYearMonitor.controls.stairs.setValue(milestoneData.stairs);
      this.oneToThreeYearMonitor.controls.stairs_description.setValue(milestoneData.stairs_description);
      this.oneToThreeYearMonitor.controls.word_phrases.setValue(milestoneData.word_phrases);
      this.oneToThreeYearMonitor.controls.word_phrases_description.setValue(milestoneData.word_phrases_description);
      this.oneToThreeYearMonitor.controls.unrelated_directions.setValue(milestoneData.unrelated_directions);
      this.oneToThreeYearMonitor.controls.unrelated_directions_description.setValue(milestoneData.unrelated_directions_description);
      this.oneToThreeYearMonitor.controls.understanding.setValue(milestoneData.understanding);
      this.oneToThreeYearMonitor.controls.understanding_description.setValue(milestoneData.understanding_description);
      this.oneToThreeYearMonitor.controls.frequent_move.setValue(milestoneData.frequent_move);
      this.oneToThreeYearMonitor.controls.frequent_move_description.setValue(milestoneData.frequent_move_description);
      this.oneToThreeYearMonitor.controls.strength.setValue(milestoneData.strength);
      this.oneToThreeYearMonitor.controls.strength_description.setValue(milestoneData.strength_description);
      this.oneToThreeYearMonitor.controls.walk_with_heel.setValue(milestoneData.walk_with_heel);
      this.oneToThreeYearMonitor.controls.walk_with_heel_description.setValue(milestoneData.walk_with_heel_description);
      this.oneToThreeYearMonitor.controls.leans.setValue(milestoneData.leans);
      this.oneToThreeYearMonitor.controls.leans_description.setValue(milestoneData.leans_description);
      this.oneToThreeYearMonitor.controls.coordinates.setValue(milestoneData.coordinates);
      this.oneToThreeYearMonitor.controls.coordinates_description.setValue(milestoneData.coordinates_description);

      this.oneToThreeYearMonitor.controls.dressed.setValue(milestoneData.dressed);
      this.oneToThreeYearMonitor.controls.dressed_description.setValue(milestoneData.dressed_description);
      this.oneToThreeYearMonitor.controls.regular_sleep.setValue(milestoneData.regular_sleep);
      this.oneToThreeYearMonitor.controls.regular_sleep_description.setValue(milestoneData.regular_sleep_description);
      this.oneToThreeYearMonitor.controls.foods.setValue(milestoneData.foods);
      this.oneToThreeYearMonitor.controls.foods_description.setValue(milestoneData.foods_description);
      this.oneToThreeYearMonitor.controls.flips.setValue(milestoneData.flips);
      this.oneToThreeYearMonitor.controls.flips_description.setValue(milestoneData.flips_description);
      this.oneToThreeYearMonitor.controls.crayons.setValue(milestoneData.crayons);
      this.oneToThreeYearMonitor.controls.crayons_description.setValue(milestoneData.crayons_description);
      this.oneToThreeYearMonitor.controls.sorts.setValue(milestoneData.sorts);
      this.oneToThreeYearMonitor.controls.sorts_description.setValue(milestoneData.sorts_description);
      this.oneToThreeYearMonitor.controls.stacks.setValue(milestoneData.stacks);
      this.oneToThreeYearMonitor.controls.stacks_description.setValue(milestoneData.stacks_description);
      this.oneToThreeYearMonitor.controls.takes_toys.setValue(milestoneData.takes_toys);
      this.oneToThreeYearMonitor.controls.takes_toys_description.setValue(milestoneData.takes_toys_description);
      this.oneToThreeYearMonitor.controls.combines.setValue(milestoneData.combines);
      this.oneToThreeYearMonitor.controls.combines_description.setValue(milestoneData.combines_description);
      this.oneToThreeYearMonitor.controls.imitates.setValue(milestoneData.imitates);
      this.oneToThreeYearMonitor.controls.imitates_description.setValue(milestoneData.imitates_description);
      this.oneToThreeYearMonitor.controls.follows.setValue(milestoneData.follows);
      this.oneToThreeYearMonitor.controls.follows_description.setValue(milestoneData.follows_description);
      this.oneToThreeYearMonitor.controls.identifies.setValue(milestoneData.identifies);
      this.oneToThreeYearMonitor.controls.identifies_description.setValue(milestoneData.identifies_description);
      this.oneToThreeYearMonitor.controls.babbling.setValue(milestoneData.babbling);
      this.oneToThreeYearMonitor.controls.babbling_description.setValue(milestoneData.babbling_description);
      this.oneToThreeYearMonitor.controls.familiar.setValue(milestoneData.familiar);
      this.oneToThreeYearMonitor.controls.familiar_description.setValue(milestoneData.familiar_description);
      this.oneToThreeYearMonitor.controls.basic.setValue(milestoneData.basic);
      this.oneToThreeYearMonitor.controls.basic_description.setValue(milestoneData.basic_description);
      this.oneToThreeYearMonitor.controls.understands.setValue(milestoneData.understands);
      this.oneToThreeYearMonitor.controls.understands_description.setValue(milestoneData.understands_description);
      this.oneToThreeYearMonitor.controls.questions.setValue(milestoneData.questions);
      this.oneToThreeYearMonitor.controls.questions_description.setValue(milestoneData.questions_description);
      this.oneToThreeYearMonitor.controls.plurals.setValue(milestoneData.plurals);
      this.oneToThreeYearMonitor.controls.plurals_description.setValue(milestoneData.plurals_description);
      this.oneToThreeYearMonitor.controls.in_on.setValue(milestoneData.in_on);
      this.oneToThreeYearMonitor.controls.in_on_description.setValue(milestoneData.in_on_description);
      this.oneToThreeYearMonitor.controls.head_shake.setValue(milestoneData.head_shake);
      this.oneToThreeYearMonitor.controls.head_shake_description.setValue(milestoneData.head_shake_description);
      this.oneToThreeYearMonitor.controls.use_words.setValue(milestoneData.use_words);
      this.oneToThreeYearMonitor.controls.use_words_description.setValue(milestoneData.use_words_description);
      this.oneToThreeYearMonitor.controls.names_description.setValue(milestoneData.names_description);
      this.oneToThreeYearMonitor.controls.names.setValue(milestoneData.names);
      this.oneToThreeYearMonitor.controls.action.setValue(milestoneData.action);
      this.oneToThreeYearMonitor.controls.action_description.setValue(milestoneData.action_description);
      this.oneToThreeYearMonitor.controls.gestures.setValue(milestoneData.gestures);
      this.oneToThreeYearMonitor.controls.gestures_description.setValue(milestoneData.gestures_description);
      this.oneToThreeYearMonitor.controls.direction.setValue(milestoneData.direction);
      this.oneToThreeYearMonitor.controls.direction_description.setValue(milestoneData.direction_description);
      this.oneToThreeYearMonitor.controls.stories.setValue(milestoneData.stories);
      this.oneToThreeYearMonitor.controls.stories_description.setValue(milestoneData.stories_description);
      this.oneToThreeYearMonitor.controls.phrases.setValue(milestoneData.phrases);
      this.oneToThreeYearMonitor.controls.phrases_description.setValue(milestoneData.phrases_description);
      this.oneToThreeYearMonitor.controls.concepts.setValue(milestoneData.concepts);
      this.oneToThreeYearMonitor.controls.concepts_description.setValue(milestoneData.concepts_description);
      this.oneToThreeYearMonitor.controls.table_foods.setValue(milestoneData.table_foods);
      this.oneToThreeYearMonitor.controls.table_foods_description.setValue(milestoneData.table_foods_description);
      this.oneToThreeYearMonitor.controls.drink.setValue(milestoneData.drink);
      this.oneToThreeYearMonitor.controls.drink_description.setValue(milestoneData.drink_description);
      this.oneToThreeYearMonitor.controls.interacting.setValue(milestoneData.interacting);
      this.oneToThreeYearMonitor.controls.interacting_description.setValue(milestoneData.interacting_description);
      this.oneToThreeYearMonitor.controls.picked_up.setValue(milestoneData.picked_up);
      this.oneToThreeYearMonitor.controls.picked_up_description.setValue(milestoneData.picked_up_description);
      this.oneToThreeYearMonitor.controls.eye_contacts.setValue(milestoneData.eye_contacts);
      this.oneToThreeYearMonitor.controls.eye_contacts_description.setValue(milestoneData.eye_contacts_description);
      this.oneToThreeYearMonitor.controls.imitating_others.setValue(milestoneData.imitating_others);
      this.oneToThreeYearMonitor.controls.imitating_others_description.setValue(milestoneData.imitating_others_description);
      this.oneToThreeYearMonitor.controls.turns_head.setValue(milestoneData.turns_head);
      this.oneToThreeYearMonitor.controls.turns_head_description.setValue(milestoneData.turns_head_description);
      this.oneToThreeYearMonitor.controls.points_objects.setValue(milestoneData.points_objects);
      this.oneToThreeYearMonitor.controls.points_objects_description.setValue(milestoneData.points_objects_description);
      this.oneToThreeYearMonitor.controls.locate_objects.setValue(milestoneData.locate_objects);
      this.oneToThreeYearMonitor.controls.locate_objects_description.setValue(milestoneData.locate_objects_description);
      this.oneToThreeYearMonitor.controls.claps.setValue(milestoneData.claps);
      this.oneToThreeYearMonitor.controls.claps_description.setValue(milestoneData.claps_description);
      this.oneToThreeYearMonitor.controls.enjoy_playing.setValue(milestoneData.enjoy_playing);
      this.oneToThreeYearMonitor.controls.enjoy_playing_description.setValue(milestoneData.enjoy_playing_description);
      this.oneToThreeYearMonitor.controls.explores.setValue(milestoneData.explores);
      this.oneToThreeYearMonitor.controls.explores_description.setValue(milestoneData.explores_description);
      this.oneToThreeYearMonitor.controls.swung.setValue(milestoneData.swung);
      this.oneToThreeYearMonitor.controls.swung_description.setValue(milestoneData.swung_description);
      this.oneToThreeYearMonitor.controls.playground.setValue(milestoneData.playground);
      this.oneToThreeYearMonitor.controls.playground_description.setValue(milestoneData.playground_description);
      this.oneToThreeYearMonitor.controls.swinging.setValue(milestoneData.swinging);
      this.oneToThreeYearMonitor.controls.swinging_description.setValue(milestoneData.swinging_description);
      this.oneToThreeYearMonitor.controls.participate.setValue(milestoneData.participate);
      this.oneToThreeYearMonitor.controls.participate_description.setValue(milestoneData.participate_description);
      this.oneToThreeYearMonitor.controls.mouthing.setValue(milestoneData.mouthing);
      this.oneToThreeYearMonitor.controls.mouthing_description.setValue(milestoneData.mouthing_description);
      this.oneToThreeYearMonitor.controls.listen_book.setValue(milestoneData.listen_book);
      this.oneToThreeYearMonitor.controls.listen_book_description.setValue(milestoneData.listen_book_description);
      this.oneToThreeYearMonitor.controls.roughhousing.setValue(milestoneData.roughhousing);
      this.oneToThreeYearMonitor.controls.roughhousing_description.setValue(milestoneData.roughhousing_description);
      this.oneToThreeYearMonitor.controls.messy_activity.setValue(milestoneData.messy_activity);
      this.oneToThreeYearMonitor.controls.messy_activity_description.setValue(milestoneData.messy_activity_description);
      this.oneToThreeYearMonitor.controls.cuddling.setValue(milestoneData.cuddling);
      this.oneToThreeYearMonitor.controls.cuddling_description.setValue(milestoneData.cuddling_description);
      this.oneToThreeYearMonitor.controls.calm.setValue(milestoneData.calm);
      this.oneToThreeYearMonitor.controls.calm_description.setValue(milestoneData.calm_description);
      this.oneToThreeYearMonitor.controls.accustomed.setValue(milestoneData.accustomed);
      this.oneToThreeYearMonitor.controls.accustomed_description.setValue(milestoneData.accustomed_description);
      this.oneToThreeYearMonitor.controls.sleeping.setValue(milestoneData.sleeping);
      this.oneToThreeYearMonitor.controls.sleeping_description.setValue(milestoneData.sleeping_description);
      this.oneToThreeYearMonitor.controls.touch.setValue(milestoneData.touch);
      this.oneToThreeYearMonitor.controls.touch_description.setValue(milestoneData.touch_description);
      this.oneToThreeYearMonitor.controls.cries_description.setValue(milestoneData.cries_description);
      this.oneToThreeYearMonitor.controls.cries.setValue(milestoneData.cries);
      this.oneToThreeYearMonitor.controls.soothe.setValue(milestoneData.soothe);
      this.oneToThreeYearMonitor.controls.soothe_description.setValue(milestoneData.soothe_description);
      this.oneToThreeYearMonitor.controls.transition.setValue(milestoneData.transition);
      this.oneToThreeYearMonitor.controls.transition_description.setValue(milestoneData.transition_description);
      this.oneToThreeYearMonitor.controls.happy.setValue(milestoneData.happy);
      this.oneToThreeYearMonitor.controls.happy_description.setValue(milestoneData.happy_description);
      this.oneToThreeYearMonitor.controls.excessive.setValue(milestoneData.excessive);
      this.oneToThreeYearMonitor.controls.excessive_description.setValue(milestoneData.excessive_description);
      this.oneToThreeYearMonitor.controls.supportive.setValue(milestoneData.supportive);
      this.oneToThreeYearMonitor.controls.supportive_description.setValue(milestoneData.supportive_description);
      this.oneToThreeYearMonitor.controls.peers.setValue(milestoneData.peers);
      this.oneToThreeYearMonitor.controls.peers_description.setValue(milestoneData.peers_description);
      this.disableForm();
      if(check == true){
        this.addVitaminObject('add' , 0)
 }
    }, (error: any) => {
      this.spinner.hide()

      // Handle any errors that occurred during the API call

    })
  }


  /*---------------------------------------------------------------------------*/
  /*                       Reloading of page                                    */
  /*----------------------------------------------------------------------------*/
  softReload() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['portal/patient-view']);
    });
  }
  /* ------------------------------------------------------------------------- */
  /*                // get baby details and show dob accordingly                */
  /* -------------------------------------------------------------------------- */
  getBabyDetails(event: any) {
    let data = this.babyDetails?.filter((res: any) => res?.id == event?.value);
    let firstLetter = data[0]?.gender.toLowerCase();
    let capitalize =
      firstLetter.slice(0, 1).toUpperCase() +
      firstLetter.slice(1, firstLetter.length);
    this.babyGender = capitalize;
    this.selectedDate = data[0].date_of_birth
    // Manually parse the date string in DD/MM/YYYY format
    const dateParts = data[0].date_of_birth.split('/');
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Months are 0-indexed in JavaScript
    const year = parseInt(dateParts[2], 10);

    const selectedDate = moment([year, month, day]);
    const today = moment();

    // Calculate the difference in years, months, and days
    const years = today.diff(selectedDate, 'years');
    selectedDate.add(years, 'years');

    const months = today.diff(selectedDate, 'months');
    selectedDate.add(months, 'months');

    const days = today.diff(selectedDate, 'days');

    this.years = years;
    this.month = months;
    this.days = days;
    if ((this.years >= 1 && this.years < 3) || (this.years === 3 && this.days <= 0)) {
      this.flag = true;
    } else {
      this.flag = false;
      this.messageService.add({
        severity: 'info',
        summary: 'Info',
        detail: 'Your child does not meet the eligibility criteria for this plan.',
      });
    }
    // if (this.years != null) {
    //   if (this.years == 1 || this.years == 2) {
    //     this.babyAge = '1 - 2 Years';
    //   } else if (this.years == 3 || this.years == 4) {
    //     this.babyAge = '3 - 4 Years';
    //   } else if (this.years == 4 || this.years == 5) {
    //     this.babyAge = '4 - 5 Years';
    //   } else if (this.years == 5 || this.years == 6) {
    //     this.babyAge = '5 - 6 Years';
    //   } else if (this.years < 1) {
    //     if (this.month > 0 && this.month <= 3) {
    //       this.babyAge = '0 - 3 Months';
    //     } else if (this.month > 3 && this.month <= 11) {
    //       this.babyAge = '4 Months - 1 Year';
    //     } else {
    //       this.babyAge = 'not found';
    //     }
    //   }
    // } else {
    //   this.years = 0;
    //   this.month = 0;
    //   this.days = 0;
    //   this.babyAge = 'not found';
    // }
  }
  /* -------------------------------------------------------------------------- */
  /*                           // submit form function                          */
  /* -------------------------------------------------------------------------- */
  submitGrowthMonitoringForm(form) {
    this.oneToThreeYearMonitor.enable()


    window.scrollTo(0, 0);
    if (this.oneToThreeYearMonitor.valid) {
      let jsonData = JSON.stringify(this.oneToThreeYearMonitor.value);
      // let dataObject = {
      //   baby_details_id: this.oneToThreeYearMonitor.value.baby_details_id,
      //   growth_monitoring_period_id: 3,
      //   development_milestones: jsonData,
      //   physical_assessment_details_json: null,
      // };
      let formData = new FormData;
      // formData.append('growth_id', '3')
      formData.append('baby_details_id', this.oneToThreeYearMonitor.value.baby_details_id)
      formData.append('growth_monitoring_period_id', '3')
      formData.append('development_milestones', jsonData)
      let images = JSON.stringify(this.oneToThreeYearMonitor.value.health_json);
      formData.append('physical_assessment_details_json', images)
      this.babyImgArray.forEach((file) => {
        formData.append('media', file);
      });
      if (this.isedit == true) {

        const id = localStorage.getItem('editedOrderId')
        this.spinner.show();
        this.growth_service.editGrowthMonitorForm(formData, id).then(
          (res: any) => {
            this.spinner.hide()

            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: res?.message,
            });
            // this.zeroToThreeMonitor.reset();
            // form.reset();
            // this.days = '';
            // this.month = '';
            // this.years = '';
            // this.babyGender = '';
            // this.selectedDate='';
            // Object.keys(this.zeroToThreeMonitor.controls).forEach(key => {
            //   this.zeroToThreeMonitor.controls[key].setErrors(null)
            // })
            setTimeout(() => {
              this.router.navigateByUrl('/portal/first-screen')
            }, 400);
            this.isedit = false;
            // this.spinner.hide();
          },
          (error: any) => {
            this.spinner.hide()
            // Handle any errors that occurred during the API call
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error?.message,
            });
          }
        );
      } else {
        this.spinner.show();
        this.growth_service
          .postGrowthMonitorForm(formData)
          .then((res: any) => {
            this.spinner.hide()

            this.messageService.add({ severity: 'success', summary: 'Success', detail: res?.message });
            setTimeout(() => {
              this.router.navigateByUrl('/portal/first-screen')
            }, 400);
            // this.spinner.hide()
          },
            (error: any) => {
              this.spinner.hide()

              // Handle any errors that occurred during the API call
              this.messageService.add({ severity: 'error', summary: 'Error', detail: error?.message });
            }
          );
      }
    } else {
      this.spinner.hide()

      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Fill All the Details!',
      });
    }
    this.oneToThreeYearMonitor.reset();
  }
  handleInput(event: any, value: string) {
    // 
    if (value == 'Walks') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.walk_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.walk_description.disable();
        this.oneToThreeYearMonitor.controls.walk_description.setValue(null);
      }
    } else if (value == 'squats_toy') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.squats_toy_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.squats_toy_description.disable();
        this.oneToThreeYearMonitor.controls.squats_toy_description.setValue(null);
      }
    }
    else if (value == 'jump') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.jump_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.jump_description.disable();
        this.oneToThreeYearMonitor.controls.jump_description.setValue(null);
      }
    } else if (value == 'pull_toys') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.pull_toys_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.pull_toys_description.disable();
        this.oneToThreeYearMonitor.controls.pull_toys_description.setValue(null);
      }
    } else if (value == 'runs') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.runs_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.runs_description.disable();
        this.oneToThreeYearMonitor.controls.runs_description.setValue(null);
      }
    }
    else if (value == 'stand_on_toes') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.stand_on_toes_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.stand_on_toes_description.disable();
        this.oneToThreeYearMonitor.controls.stand_on_toes_description.setValue(null);
      }
    }
    else if (value == 'stands_on_furniture') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.stands_on_furniture_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.stands_on_furniture_description.disable();
        this.oneToThreeYearMonitor.controls.stands_on_furniture_description.setValue(null);
      }
    }
    else if (value == 'kicks_balls') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.kicks_balls_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.kicks_balls_description.disable();
        this.oneToThreeYearMonitor.controls.kicks_balls_description.setValue(null);
      }
    }
    else if (value == 'stairs') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.stairs_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.stairs_description.disable();
        this.oneToThreeYearMonitor.controls.stairs_description.setValue(null);
      }
    }
    else if (value == 'word_phrases') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.word_phrases_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.word_phrases_description.disable();
        this.oneToThreeYearMonitor.controls.word_phrases_description.setValue(null);
      }
    }
    else if (value == 'unrelated_directions') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.unrelated_directions_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.unrelated_directions_description.disable();
        this.oneToThreeYearMonitor.controls.unrelated_directions_description.setValue(null);
      }
    }
    else if (value == 'understanding') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.understanding_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.understanding_description.disable();
        this.oneToThreeYearMonitor.controls.understanding_description.setValue(null);
      }
    }
    else if (value == 'frequent_move') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.frequent_move_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.frequent_move_description.disable();
        this.oneToThreeYearMonitor.controls.frequent_move_description.setValue(null);
      }
    }
    else if (value == 'strength') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.strength_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.strength_description.disable();
        this.oneToThreeYearMonitor.controls.strength_description.setValue(null);
      }
    }
    else if (value == 'walk_with_heel') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.walk_with_heel_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.walk_with_heel_description.disable();
        this.oneToThreeYearMonitor.controls.walk_with_heel_description.setValue(null);
      }
    }
    else if (value == 'leans') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.leans_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.leans_description.disable();
        this.oneToThreeYearMonitor.controls.leans_description.setValue(null);
      }
    }
    else if (value == 'coordinates') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.coordinates_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.coordinates_description.disable();
        this.oneToThreeYearMonitor.controls.coordinates_description.setValue(null);
      }
    }
    else if (value == 'dressed') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.dressed_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.dressed_description.disable();
        this.oneToThreeYearMonitor.controls.dressed_description.setValue(null);
      }
    }
    else if (value == 'regular_sleep') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.regular_sleep_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.regular_sleep_description.disable();
        this.oneToThreeYearMonitor.controls.regular_sleep_description.setValue(null);
      }
    } else if (value == 'foods') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.foods_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.foods_description.disable();
        this.oneToThreeYearMonitor.controls.foods_description.setValue(null);
      }
    } else if (value == 'flips') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.flips_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.flips_description.disable();
        this.oneToThreeYearMonitor.controls.flips_description.setValue(null);
      }
    } else if (value == 'crayons') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.crayons_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.crayons_description.disable();
        this.oneToThreeYearMonitor.controls.crayons_description.setValue(null);
      }
    } else if (value == 'sorts') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.sorts_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.sorts_description.disable();
        this.oneToThreeYearMonitor.controls.sorts_description.setValue(null);
      }
    } else if (value == 'stacks') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.stacks_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.stacks_description.disable();
        this.oneToThreeYearMonitor.controls.stacks_description.setValue(null);
      }
    } else if (value == 'takes_toys') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.takes_toys_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.takes_toys_description.disable();
        this.oneToThreeYearMonitor.controls.takes_toys_description.setValue(null);
      }
    }
    else if (value == 'combines') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.combines_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.combines_description.disable();
        this.oneToThreeYearMonitor.controls.combines_description.setValue(null);
      }
    }
    else if (value == 'imitates') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.imitates_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.imitates_description.disable();
        this.oneToThreeYearMonitor.controls.imitates_description.setValue(null);
      }
    } else if (value == 'follows') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.follows_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.follows_description.disable();
        this.oneToThreeYearMonitor.controls.follows_description.setValue(null);
      }
    } else if (value == 'identifies') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.identifies_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.identifies_description.disable();
        this.oneToThreeYearMonitor.controls.identifies_description.setValue(null);
      }
    } else if (value == 'babbling') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.babbling_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.babbling_description.disable();
        this.oneToThreeYearMonitor.controls.babbling_description.setValue(null);
      }
    } else if (value == 'familiar') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.familiar_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.familiar_description.disable();
        this.oneToThreeYearMonitor.controls.familiar_description.setValue(null);
      }
    } else if (value == 'basic') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.basic_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.basic_description.disable();
        this.oneToThreeYearMonitor.controls.basic_description.setValue(null);
      }
    } else if (value == 'understands') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.understands_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.understands_description.disable();
        this.oneToThreeYearMonitor.controls.understands_description.setValue(null);
      }
    } else if (value == 'questions') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.questions_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.questions_description.disable();
        this.oneToThreeYearMonitor.controls.questions_description.setValue(null);
      }
    } else if (value == 'plurals') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.plurals_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.plurals_description.disable();
        this.oneToThreeYearMonitor.controls.plurals_description.setValue(null);
      }
    } else if (value == 'in_on') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.in_on_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.in_on_description.disable();
        this.oneToThreeYearMonitor.controls.in_on_description.setValue(null);
      }
    } else if (value == 'head_shake') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.head_shake_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.head_shake_description.disable();
        this.oneToThreeYearMonitor.controls.head_shake_description.setValue(null);
      }
    } else if (value == 'use_words') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.use_words_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.use_words_description.disable();
        this.oneToThreeYearMonitor.controls.use_words_description.setValue(null);
      }
    } else if (value == 'names') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.names_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.names_description.disable();
        this.oneToThreeYearMonitor.controls.names_description.setValue(null);
      }
    } else if (value == 'action') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.action_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.action_description.disable();
        this.oneToThreeYearMonitor.controls.action_description.setValue(null);
      }
    } else if (value == 'gestures') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.gestures_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.gestures_description.disable();
        this.oneToThreeYearMonitor.controls.gestures_description.setValue(null);
      }
    } else if (value == 'direction') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.direction_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.direction_description.disable();
        this.oneToThreeYearMonitor.controls.direction_description.setValue(null);
      }
    } else if (value == 'stories') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.stories_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.stories_description.disable();
        this.oneToThreeYearMonitor.controls.stories_description.setValue(null);
      }
    } else if (value == 'phrases') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.phrases_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.phrases_description.disable();
        this.oneToThreeYearMonitor.controls.phrases_description.setValue(null);
      }
    }
    else if (value == 'concepts') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.concepts_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.concepts_description.disable();
        this.oneToThreeYearMonitor.controls.concepts_description.setValue(null);
      }
    }
    else if (value == 'table_foods') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.table_foods_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.table_foods_description.disable();
        this.oneToThreeYearMonitor.controls.table_foods_description.setValue(null);
      }
    }
    else if (value == 'drink') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.drink_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.drink_description.disable();
        this.oneToThreeYearMonitor.controls.drink_description.setValue(null);
      }
    }
    else if (value == 'interacting') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.interacting_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.interacting_description.disable();
        this.oneToThreeYearMonitor.controls.interacting_description.setValue(null);
      }
    }
    else if (value == 'picked_up') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.picked_up_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.picked_up_description.disable();
        this.oneToThreeYearMonitor.controls.picked_up_description.setValue(null);
      }
    }
    else if (value == 'eye_contacts') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.eye_contacts_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.eye_contacts_description.disable();
        this.oneToThreeYearMonitor.controls.eye_contacts_description.setValue(null);
      }
    } else if (value == 'imitating_others') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.imitating_others_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.imitating_others_description.disable();
        this.oneToThreeYearMonitor.controls.imitating_others_description.setValue(null);
      }
    } else if (value == 'turns_head') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.turns_head_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.turns_head_description.disable();
        this.oneToThreeYearMonitor.controls.turns_head_description.setValue(null);
      }
    } else if (value == 'points_objects') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.points_objects_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.points_objects_description.disable();
        this.oneToThreeYearMonitor.controls.points_objects_description.setValue(null);
      }
    } else if (value == 'locate_objects') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.locate_objects_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.locate_objects_description.disable();
        this.oneToThreeYearMonitor.controls.locate_objects_description.setValue(null);
      }
    } else if (value == 'claps') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.claps_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.claps_description.disable();
        this.oneToThreeYearMonitor.controls.claps_description.setValue(null);
      }
    } else if (value == 'enjoy_playing') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.enjoy_playing_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.enjoy_playing_description.disable();
        this.oneToThreeYearMonitor.controls.enjoy_playing_description.setValue(null);
      }
    } else if (value == 'explores') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.explores_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.explores_description.disable();
        this.oneToThreeYearMonitor.controls.explores_description.setValue(null);
      }
    }

    else if (value == 'playground') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.playground_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.playground_description.disable();
        this.oneToThreeYearMonitor.controls.playground_description.setValue(null);
      }
    } else if (value == 'swinging') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.swinging_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.swinging_description.disable();
        this.oneToThreeYearMonitor.controls.swinging_description.setValue(null);
      }
    }
    else if (value == 'participate') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.participate_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.participate_description.disable();
        this.oneToThreeYearMonitor.controls.participate_description.setValue(null);
      }
    } else if (value == 'mouthing') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.mouthing_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.mouthing_description.disable();
        this.oneToThreeYearMonitor.controls.mouthing_description.setValue(null);
      }
    } else if (value == 'listen_book') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.listen_book_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.listen_book_description.disable();
        this.oneToThreeYearMonitor.controls.listen_book_description.setValue(null);
      }
    } else if (value == 'roughhousing') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.roughhousing_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.roughhousing_description.disable();
        this.oneToThreeYearMonitor.controls.roughhousing_description.setValue(null);
      }
    } else if (value == 'messy_activity') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.messy_activity_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.messy_activity_description.disable();
        this.oneToThreeYearMonitor.controls.messy_activity_description.setValue(null);
      }
    }
    else if (value == 'cuddling') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.cuddling_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.cuddling_description.disable();
        this.oneToThreeYearMonitor.controls.cuddling_description.setValue(null);
      }
    } else if (value == 'calm') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.calm_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.calm_description.disable();
        this.oneToThreeYearMonitor.controls.calm_description.setValue(null);
      }
    } else if (value == 'accustomed') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.accustomed_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.accustomed_description.disable();
        this.oneToThreeYearMonitor.controls.accustomed_description.setValue(null);
      }
    } else if (value == 'sleeping') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.sleeping_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.sleeping_description.disable();
        this.oneToThreeYearMonitor.controls.sleeping_description.setValue(null);
      }
    } else if (value == 'touch') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.touch_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.touch_description.disable();
        this.oneToThreeYearMonitor.controls.touch_description.setValue(null);
      }
    } else if (value == 'cries') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.cries_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.cries_description.disable();
        this.oneToThreeYearMonitor.controls.cries_description.setValue(null);
      }
    } else if (value == 'soothe') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.soothe_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.soothe_description.disable();
        this.oneToThreeYearMonitor.controls.soothe_description.setValue(null);
      }
    } else if (value == 'transition') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.transition_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.transition_description.disable();
        this.oneToThreeYearMonitor.controls.transition_description.setValue(null);
      }
    } else if (value == 'happy') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.happy_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.happy_description.disable();
        this.oneToThreeYearMonitor.controls.happy_description.setValue(null);
      }
    } else if (value == 'excessive') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.excessive_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.excessive_description.disable();
        this.oneToThreeYearMonitor.controls.excessive_description.setValue(null);
      }
    } else if (value == 'supportive') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.supportive_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.supportive_description.disable();
        this.oneToThreeYearMonitor.controls.supportive_description.setValue(null);
      }
    } else if (value == 'peers') {

      if (event.target.value == 'Yes' || event.target.value == 'No') {
        this.oneToThreeYearMonitor.controls.peers_description.enable();
      } else {
        this.oneToThreeYearMonitor.controls.peers_description.disable();
        this.oneToThreeYearMonitor.controls.peers_description.setValue(null);
      }
    }
  }
  gethealthFormArray() {
    return this.oneToThreeYearMonitor.get(
      'health_json'
    ) as FormArray;
  }
  addVitaminObject(string: any, index: any) {
    let vitaminArray = this.gethealthFormArray();
    if (string == 'add') {
      let newVitaminArray = new FormGroup({
        height: new FormControl(null),
        time: new FormControl(null),
        weight: new FormControl(null),
      });

      // let formatedTime =  moment(newTime).format('HH:mm:ss');
      vitaminArray.insert(index + 1, newVitaminArray);
    } else {
      vitaminArray.removeAt(index);
    }
  }
  getImageArray() {
    return this.oneToThreeYearMonitor.get(
      'baby_images'
    ) as FormArray;
  }
  addBabyImg(string: any, index: any) {
    let imgArray = this.getImageArray();
    if (string == 'add') {
      let newbabyImg = new FormGroup({
        // upload_photo: new FormControl(''),
      });
      // let formatedTime =  moment(newTime).format('HH:mm:ss');
      imgArray.insert(index + 1, newbabyImg);
    } else {
      imgArray.removeAt(index);
    }
  }
  // uploadPhoto(event: any) {
  //   // 
  //   this.selectedFile = event.target.files[0];
  //   if (
  //     event.target.files[0].type == 'image/jpeg' ||
  //     'image/jpg' ||
  //     'image/png' ||
  //     'application/pdf'
  //   ) {
  //     this.babyImgArray.push(event.target.files[0]);
  //     // return event.target.files[0];
  //   }
  // }
  startCamera() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          this.videoElement.nativeElement.srcObject = stream;
        })
        .catch(error => {
        });
    }
  }

  openCamera() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          this.stream = stream;
          this.videoElement.nativeElement.srcObject = stream;
          this.openCameraforUpload = true
        })
        .catch(error => {
        });
    }
  }
  shutDownCamera(){
    this.openCameraforUpload=false;
    const video = this.videoElement.nativeElement;
  if (video.srcObject) {
    const stream = video.srcObject as MediaStream;
    const tracks = stream.getTracks();
    tracks.forEach(track => track.stop()); // Stop tracks to close the camera
    video.srcObject = null; // Reset the srcObject to release the camera resource
    this.openCameraforUpload = false
  }
  }
  capture() {
    ++this.IndexforImage
    const context = this.canvas.nativeElement.getContext('2d');
    const canvasWidth = this.canvas.nativeElement.width;
    const canvasHeight = this.canvas.nativeElement.height;
    context.drawImage(this.videoElement.nativeElement, 0, 0, canvasWidth, canvasHeight);
    const imageData = this.canvas.nativeElement.toDataURL('image/png');
    this.capturedByCamera.push(imageData)
    const byteString = atob(imageData.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: 'image/png' });
    this.capturedImg = new File([blob], `captured-image${this.IndexforImage}.png`, { type: 'image/png' });
    this.uploadPhoto(null, this.capturedImg)
    this.stopCamera();
  }

  stopCamera() {
    const video = this.videoElement.nativeElement;
    if (video.srcObject) {
      const stream = video.srcObject as MediaStream;
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop()); // Stop tracks to close the camera
      video.srcObject = null; // Reset the srcObject to release the camera resource
      this.openCameraforUpload = false
    }
  }

  uploadPhoto(event: any, captureImg?: any) {

    if (captureImg) {
      this.selectedFile = captureImg;
    }
    else {
      this.selectedFile = event.target.files[0];
    }
    if (event &&
      event.target.files[0].type == 'image/jpeg' ||
      'image/jpg' ||
      'image/png' ||
      'application/pdf'
    ) {
      if (event) {
        this.babyImgArray.push(event.target.files[0]);
      }
      else {
        this.babyImgArray.push(captureImg);

      }
    }

  }

  previewImage(imgSrc: any) {
    this.imagePreview = true
    this.imgSrcForPreview = imgSrc
  }

  deletecapture(imgSrc: any) {
    const byteString = atob(imgSrc.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: 'image/png' });

    const deleteIMG = new File([blob], `captured-image${this.IndexforImage}.png`, { type: 'image/png' });
    this.babyImgArray.forEach((file, index) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const arrayBuffer = e.target.result;

        if (this.arrayBuffersEqual(arrayBuffer, ab)) {
          this.babyImgArray.splice(index, 1); // Remove the file from babyImgArray
        }
      };
      reader.readAsArrayBuffer(file);
    });


    this.capturedByCamera = this.capturedByCamera.filter(item => item !== imgSrc);
    this.imagePreview = false;
  }

  arrayBuffersEqual(buf1: ArrayBuffer, buf2: ArrayBuffer): boolean {
    if (buf1.byteLength !== buf2.byteLength) return false;
    const dv1 = new DataView(buf1);
    const dv2 = new DataView(buf2);
    for (let i = 0; i < buf1.byteLength; i++) {
      if (dv1.getUint8(i) !== dv2.getUint8(i)) return false;
    }
    return true;
  }


  closePreview() {
    this.imagePreview = false
  }
  disableForm() {
    const formData = this.oneToThreeYearMonitor.value;
    for (let key in formData) {
      if (formData[key] !== null && formData[key] !== undefined) {
        if (typeof formData[key] === 'object') {
          for (let nestedKey in formData[key]) {
            if (formData[key][nestedKey] !== null && formData[key][nestedKey] !== undefined) {
              const control = this.oneToThreeYearMonitor.get(`${key}.${nestedKey}`);
              if (control) {
                control.disable();
              }
            }
          }
        } else {
          const control = this.oneToThreeYearMonitor.get(key);
          if (control) {
            control.disable();
          }
        }
      }
    }
  }
  // convertKgToPounds() {
  //   if (this.weightConverted) return; 

  //   // Get the FormArray from the form group
  //   const healthFormArray = this.oneToThreeYearMonitor.get('health_json') as FormArray;
  
  //   // Loop through each form group in the FormArray
  //   healthFormArray.controls.forEach((control) => {
  //     // Get the current weight value
  //     const weightInKg = control.get('weight').value;
  
  //     // Convert weight to pounds if it's a valid number
  //     if (weightInKg && !isNaN(weightInKg)) {
  //       const weightInPounds = (parseFloat(weightInKg) * 2.20462).toFixed(2);
  //       control.get('weight').setValue(weightInPounds);
  //     }
  //   });
  // this.weightConverted = true; //

  // }
  
  // convertHeightToCm(): void {
  //   if (this.heightConverted) return;
  //   const healthFormArray = this.oneToThreeYearMonitor.get('health_json') as FormArray;
  
  //   // Loop through each form group in the FormArray
  //   healthFormArray.controls.forEach((control) => {
  //     // Get the current weight value
  //     const heightIncm = control.get('height').value;

  //   if (heightIncm && !isNaN(heightIncm)) {
  //     const heightInInches = parseFloat(heightIncm);
  //     if (!isNaN(heightInInches)) {
  //       const heightInCm = heightInInches * 2.54;
  //       control.patchValue({ height: heightInCm.toFixed(2) });
  //     }
  //   }
  // });
  // this.heightConverted=true;
  // }
}
