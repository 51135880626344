<p-toast></p-toast>
<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>
    <div class="dropdown profile-nav-item d-flex flex-column align-items-end">
        <a class="dropdown-toggle admin-btn" style="text-decoration: none;" role="button" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <div class="menu-profile">
                <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
                <span class="name">{{getname}}</span>
            </div>
        </a>
        <div class="dropdown-menu d-flex flex-column align-items-center">
            <div class="dropdown-header align">
                <div class="figure mb-3">
                    <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
                </div>
                <div class="info text-center">
                    <span class="name" style="white-space: break-spaces;">{{getname}}</span>
                    <p class="mb-3 email">{{getRole}}</p>
                </div>
            </div>
            <!-- <div class="dropdown-body">
                <ul class="profile-nav p-0 pt-3">
                    <li class="nav-item"><a routerLink="/dashboard-my-profile" class="nav-link"><i class='bx bx-user'></i> <span>Profile Dashboard</span></a></li>
                </ul>
            </div> -->
            <div class="dropdown-footer">
                <ul class="profile-nav">
                    <li class="nav-item"><a class="nav-link" (click)="logout()"><i class='bx bx-log-out'></i> <span
                                class="logout-text">Logout</span></a></li>
                </ul>
            </div>

        </div>
    </div>
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item">{{Content.subTitle}}</li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <form [formGroup]="oneToThreeYear" (ngSubmit)="submitForm(savedData)" #savedData="ngForm">
        <!-- BABY DETAILS SECTION -->
        <div class="add-listings-box">
            <h3>Baby Details</h3>
            <div class="row">
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Name <span class="required">*</span></label>
                        <div class="card flex justify-content-center">
                            <p-dropdown [options]="babyValues" (onChange)="getRemainingDetails($event)" readonly="true"
                                optionLabel="name" [filter]="true" filterBy="name" optionValue="id"
                                formControlName="baby_details_id" placeholder="Select Baby's Name"
                                [resetFilterOnHide]="true" required [class.is-invalid]="(savedData.submitted &&
                                oneToThreeYear.get('baby_details_id')?.invalid)
                                ||
                                oneToThreeYear.get('baby_details_id')?.invalid
                                &&
                                oneToThreeYear.get('baby_details_id').touched">
                            </p-dropdown>
                            <div class="invalid-feedback">Name is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Gender</label>
                        <div class="card flex justify-content-center">
                            <!-- <p-dropdown [options]="genderArray" optionLabel="name" optionValue="value"
                                [(ngModel)]="babyGender" [ngModelOptions]="{standalone: true}"
                                placeholder="Select Baby's Gender" [resetFilterOnHide]="true" [readonly]="true">
                            </p-dropdown> -->
                            <input type="text" class="form-control" [(ngModel)]="babyGender"
                                [ngModelOptions]="{standalone: true}" placeholder="Select Baby's Gender" required
                                [readonly]="true">
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Date of birth</label>
                        <div class="card flex justify-content-center">
                            <!-- <p-calendar [(ngModel)]="selectedDate" [ngModelOptions]="{standalone: true}"
                                [showIcon]="true" [readonlyInput]="true" placeholder="DD/MM/YYYY"></p-calendar> -->
                            <input type="text" name="name" class="form-control" placeholder="DD/MM/YYYY"
                                [value]="selectedDate" readonly />
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Days</label>
                        <input type="text" name="name" class="form-control" placeholder="Days" [value]="days"
                            readonly />
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Months</label>
                        <input type="text" name="name" class="form-control" placeholder="Months" [value]="month"
                            readonly />
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Years</label>
                        <input type="text" name="name" class="form-control" placeholder="Years" [value]="years"
                            readonly />
                    </div>
                </div>
            </div>
        </div>


        <!--DAILY CARE -->
        <!-- accordion start -->
        <ng-container *ngIf="flag">
            <p-accordion>
                <p-accordionTab header="Milk Feeding">
                    <div class="add-listings-box">
                        <h3>Daily Cares</h3>
                        <div class="subHeading mb-3">Feeding: <span class="fw-normal">Prepared By</span></div>
                        <div class=" ">
                            <div class="form-check checkbox-list p-0">
                                <div class="form-group ">
                                    <div class=" ">
                                        <div class="row">
                                            <div class="col-auto">

                                                <label for="mother" class="checkbox checkbox-outline px-0"><input
                                                        type="checkbox" id="mother"
                                                        formControlName="feeding_prepared_mother"
                                                        value="mother"><span>Mother</span></label>
                                            </div>
                                            <div class="col-auto">

                                                <label for="nanny" class="checkbox checkbox-outline px-0"> <input
                                                        type="checkbox" formControlName="feeding_prepared_nanny"
                                                        id="nanny" value="nanny"><span>Nurse</span></label>
                                            </div>
                                            <div class="col-auto">

                                                <label for="other" class="checkbox checkbox-outline px-0"> <input
                                                        type="checkbox" id="other"
                                                        formControlName="feeding_prepared_other"
                                                        value="other"><span>Other</span></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- MILK-->

                    <div class="add-listings-box">
                        <h3 class="mb-0">Milk</h3>
                        <div class=" ">
                            <!-- <div "> -->
                            <div class="form-check checkbox-list p-0">
                                <div class="row">
                                    <div class="col-md col-lg-2">
                                        <label class="checkbox checkbox-outline mt-md-2">
                                            <input type="checkbox" value="Breast" formControlName="feeding_breast"
                                                (change)="handleInput($event, 'milk')" />
                                            <span></span>
                                            Breast
                                        </label>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label class="px-0">Breast frequency</label>
                                            <div class="card flex justify-content-center">
                                                <p-dropdown id="breast_frequency" [options]="frequencyList"
                                                    formControlName="feeding_frequency_breast" optionLabel="name"
                                                    optionValue="value" placeholder="Select Frequency" [filter]="true"
                                                    filterBy="value" (onChange)="addbreastfield()" [disabled]="Breast"
                                                    [class.is-invalid]="(savedData.submitted &&
                                                    oneToThreeYear.get('feeding_frequency_breast')?.invalid)
                                                    ||
                                                    oneToThreeYear.get('feeding_frequency_breast')?.invalid
                                                    &&
                                                    oneToThreeYear.get('feeding_frequency_breast').touched">
                                                </p-dropdown>
                                                <div class="invalid-feedback">Breast Frequency is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label class="px-0">Remark</label>
                                            <input type="text" name="name" class="form-control" placeholder="Remark"
                                                formControlName="breast_remark" [readonly]="Breast" />
                                        </div>
                                    </div>
                                    <div *ngIf="isBreast" class="add-listings-box pt-3 mb-3">
                                        <div class="row" formArrayName="breast_feeding">
                                            <div *ngFor="let control of getbreastarray().controls; let i = index"
                                                [formGroupName]="i" class="col-6 col-md-4 col-lg-4">
                                                <!-- <div class="row"> -->
                                                <!-- <div class="col-md-2">
                                                        <div class="form-group">
                                                            <div class="col-md col-lg-2 mt-3">
                                                                <label class="checkbox checkbox-outline px-0  mt-md-2">
                                                                    <input type="checkbox" value="breastFeeding"
                                                                        formControlName="feeding_breast_checkbox" />
                                                                    Done?
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                <!-- <div class="col-md-4"> -->
                                                <div class="form-group">
                                                    <label class="p-0">Time: </label>
                                                    <div class="card flex justify-content-center">

                                                        <p-calendar placeholder="Start Time"
                                                            formControlName="feeding_breast_time" [timeOnly]="true"
                                                            [hourFormat]="12" inputId="time" [disabled]="feedingBreast"
                                                            [class.is-invalid]="(savedData.submitted &&
                                                                    oneToThreeYear.get('feeding_breast_time')?.invalid)
                                                                    ||
                                                                    oneToThreeYear.get('feeding_breast_time')?.invalid
                                                                    &&
                                                                    oneToThreeYear.get('feeding_breast_time').touched">
                                                        </p-calendar>
                                                        <div class="invalid-feedback">Start Time is required
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- </div> -->
                                                <!-- <div class="col-md-5">
                                                        <div class="form-group">
                                                            <div class="card flex justify-content-center">
                                                                <label class="px-0">Remark</label>
                                                                <input type="text" name="remark" class="form-control"
                                                                    placeholder="Remark" id="remark"
                                                                    formControlName="breast_feeding_remark" />
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                <!-- </div> -->

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">

                                    <div class="col-lg-2 col-md-4">
                                        <label class="checkbox checkbox-outline col-12 mb-3">
                                            <input type="checkbox" value="Formula" formControlName="feeding_type"
                                                (change)="handleInput($event, 'milk')" />
                                            <span></span>
                                            Formula
                                        </label>
                                    </div>
                                    <div class="col-lg-4 col-md-4">
                                        <div class="form-group">
                                            <label class="px-0">Formula frequency (every hr​) </label>
                                            <div class="card flex justify-content-center">
                                                <p-dropdown id="formula_frequency" [options]="frequencyList"
                                                    optionLabel="name" optionValue="value"
                                                    (ngModelChange)="createfields()" [filter]="true" filterBy="value"
                                                    formControlName="feeding_frequency" placeholder="Select Frequency"
                                                    [disabled]="Formula" [class.is-invalid]="(savedData.submitted &&
                                                        oneToThreeYear.get('feeding_frequency')?.invalid)
                                                        ||
                                                        oneToThreeYear.get('feeding_frequency')?.invalid
                                                        &&
                                                        oneToThreeYear.get('feeding_frequency').touched">
                                                </p-dropdown>
                                                <div class="invalid-feedback">Formula frequency is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4">
                                        <div class="form-group">
                                            <label class="px-0">Start Time</label>
                                            <div class="card flex justify-content-center">
                                                <p-calendar placeholder="Time" formControlName="feeding_time"
                                                    [disabled]="Formula" [timeOnly]="true" [hourFormat]="12"
                                                    (ngModelChange)="createfields()" inputId="time"
                                                    id="formula_frequency" [class.is-invalid]="(savedData.submitted &&
                                                        oneToThreeYear.get('feeding_time')?.invalid)
                                                        ||
                                                        oneToThreeYear.get('feeding_time')?.invalid
                                                        &&
                                                        oneToThreeYear.get('feeding_time').touched"></p-calendar>
                                                <div class="invalid-feedback">Start Time is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4">
                                        <div class="form-group">
                                            <label class="px-0">End Time</label>
                                            <div class="card flex justify-content-center">
                                                <p-calendar placeholder="Time"
                                                    formControlName="feeding_formula_time_end" [disabled]="Formula"
                                                    [timeOnly]="true" [hourFormat]="12" (ngModelChange)="createfields()"
                                                    inputId="time" id="formula_frequency"
                                                    [class.is-invalid]="(savedData.submitted &&
                                                        oneToThreeYear.get('feeding_formula_time_end')?.invalid)
                                                        ||
                                                        oneToThreeYear.get('feeding_formula_time_end')?.invalid
                                                        &&
                                                        oneToThreeYear.get('feeding_formula_time_end').touched"></p-calendar>
                                                <div class="invalid-feedback">End Time is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-2"></div>
                                    <div class="col-lg-4 col-md-4">
                                        <div class="form-group">
                                            <label class="px-0">Amount (ml)</label>
                                            <div class="card flex justify-content-center">
                                                <input type="number" formControlName="feeding_amount_per"
                                                    class="form-control" placeholder="Enter Amount (ML)"
                                                    [readonly]="Formula" [class.is-invalid]="(savedData.submitted &&
                                                        oneToThreeYear.get('feeding_amount_per')?.invalid)
                                                        ||
                                                        oneToThreeYear.get('feeding_amount_per')?.invalid
                                                        &&
                                                        oneToThreeYear.get('feeding_amount_per').touched" />
                                                <div class="invalid-feedback">Amount is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3">
                                        <div class="form-group ml-0 col-12 p-0 mb-0">
                                            <label class="col-form-label col-lg-12 col-sm-12 p-0">Type of
                                                formula</label>
                                            <div class="col-12 p-0">
                                                <input type="text" formControlName="type_of_formula"
                                                    class="form-control" placeholder="Formula" [readonly]="Formula"
                                                    [class.is-invalid]="(savedData.submitted &&
                                                        oneToThreeYear.get('type_of_formula')?.invalid)
                                                        ||
                                                        oneToThreeYear.get('type_of_formula')?.invalid
                                                        &&
                                                        oneToThreeYear.get('type_of_formula').touched" />
                                                <div class="invalid-feedback">Formula is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-2">
                                        <div class="form-group">
                                            <label class="px-0">Remark</label>
                                            <input type="text" name="formula_remark" class="form-control"
                                                placeholder="Remark" formControlName="formula_remark"
                                                [readonly]="Formula" />
                                        </div>
                                    </div>

                                </div>
                                <div class="col-12">
                                    <!-- formula feeding feeding time  -->
                                    <div *ngIf="!Formula">
                                        <div class="row justify-content-end mx-0">
                                            <div class="add-listings-box pt-3 mb-3 col-lg-10">
                                                <div formArrayName="formula_calculation" class="row">
                                                    <div *ngFor="let control of getFormulaCalculationControls().controls; let i = index"
                                                        [formGroupName]="i" class="col-md-4 col-lg-3">
                                                        <!-- <div class="row"> -->
                                                        <!-- <div class="col-md-4 col-lg-3">
                                                                <div class="form-group">
                                                                    <label
                                                                        class="checkbox checkbox-outline px-0  mt-md-2">
                                                                        <input type="checkbox" value="formulaFeeding"
                                                                            name="feeding_checkbox"
                                                                            formControlName="feeding_checkbox" />
                                                                        Done?
                                                                    </label>
                                                                </div>
                                                            </div> -->
                                                        <!-- <div class="col-md-4 col-lg-3"> -->
                                                        <div class="form-group">
                                                            <label class="px-0">Time </label>
                                                            <p-calendar placeholder="Time"
                                                                formControlName="formula_timeArray" [timeOnly]="true"
                                                                inputId="time"></p-calendar>
                                                        </div>
                                                        <!-- </div> -->
                                                        <!-- <div class="col-md-4 col-lg-3">
                                                                <div class="form-group">
                                                                    <label class="px-0">Time Feeding By
                                                                        Nurse</label>
                                                                    <p-calendar placeholder="Actual Time"
                                                                        formControlName="nurse_feeding"
                                                                        [timeOnly]="true" inputId="time"></p-calendar>
                                                                </div>
                                                            </div> -->
                                                        <!-- <div class="col-md-4 col-lg-3">
                                                                <div class="form-group">
                                                                    <label class="px-0">Amount (ml)</label>
                                                                    <input type="number" name="amount"
                                                                        class="form-control" placeholder="Amount"
                                                                        formControlName="amount" />
                                                                </div>
                                                            </div> -->
                                                        <!-- <div class="col-md-4 col-lg-3">
                                                                <div class="form-group">
                                                                    <label class="px-0">Name of Formula</label>
                                                                    <input type="text" name="remark"
                                                                        class="form-control" placeholder="Remark"
                                                                        formControlName="remark" />
                                                                </div>
                                                            </div> -->
                                                        <!-- <div class="col-md-4 col-lg-9">
                                                                <div class="form-group">
                                                                    <label class="px-0">Remark</label>
                                                                    <input type="text" name="remark"
                                                                        class="form-control" placeholder="Remark"
                                                                        id="remark" formControlName="formula_remark" />
                                                                </div>
                                                            </div> -->
                                                        <!-- </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <!-- Feeding -->
                        <div class="row">
                            <label class="subHeading mb-3">Bottle Sterilization</label>
                            <div class="form-group col-lg-2 col-md-4 col-12 d-flex">
                                <div class="form-check pl-0 checkbox-list px-0">
                                    <label class="checkbox checkbox-outline px-0">
                                        <!-- <div class="form-check pl-0 checkbox-list"> -->
                                        <!-- <label class="checkbox checkbox-outline col-4"> -->
                                        <input type="checkbox" value="All" formControlName="all_time"
                                            (change)="handleInput($event, 'feeding')" />
                                        <span></span>
                                        All
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label class="px-0">Time </label>
                                    <div class="card flex justify-content-center">
                                        <p-calendar placeholder="Time" formControlName="bottle_sterilization_all"
                                            [disabled]="All" [timeOnly]="true" [hourFormat]="12" inputId="time"
                                            [class.is-invalid]="(savedData.submitted &&
                                    oneToThreeYear.get('bottle_sterilization_all')?.invalid)
                                    ||
                                    oneToThreeYear.get('bottle_sterilization_all')?.invalid
                                    &&
                                    oneToThreeYear.get('bottle_sterilization_all').touched"></p-calendar>
                                        <div class="invalid-feedback">Time is required</div>
                                    </div>
                                </div>
                                <!-- </div> -->
                            </div>
                        </div>
                        <!-- one at a time -->

                        <div class="row">
                            <div class="form-group col-lg-2 col-md-4 col-12 d-flex">
                                <div class="form-check pl-0 checkbox-list px-0">
                                    <label class="checkbox checkbox-outline px-0">
                                        <input type="checkbox" value="One" formControlName="onetime"
                                            (change)="handleInput($event, 'feeding')" />
                                        <span></span>
                                        One At A time
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label class="px-0">Reference Time </label>
                                    <div class="card flex justify-content-center">
                                        <p-calendar placeholder="Time" formControlName="bottle_sterilization_one_at_a_time"
                                            [disabled]="One" [timeOnly]="true" [hourFormat]="12" inputId="time"
                                            [class.is-invalid]="(savedData.submitted &&
                                    oneToThreeYear.get('bottle_sterilization_one_at_a_time')?.invalid)
                                    ||
                                    oneToThreeYear.get('bottle_sterilization_one_at_a_time')?.invalid
                                    &&
                                    oneToThreeYear.get('bottle_sterilization_one_at_a_time').touched"></p-calendar>
                                        <div class="invalid-feedback">Time is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div *ngIf="!Formula && !One">
                                    <div class="row">
                                        <div class="add-listings-box pt-3 mb-3 col-lg-10">
                                            <div formArrayName="bottle_sterilization" class="row">
                                                <div *ngFor="let control of getbottle_sterilizationControls().controls; let i = index"
                                                    [formGroupName]="i" class="col-lg-4">
                                                    <!-- <div class="row"> -->
                                                    <!-- <div class="col-lg-2 col-md-2 mt-3">
                                                            <label class="checkbox checkbox-outline px-0  mt-md-2">
                                                                <input type="checkbox" value="true" name="checkboxes"
                                                                    formControlName="done_sterilization" />
                                                                <span></span>
                                                                Done?
                                                            </label>
                                                        </div> -->
                                                    <!-- <div class="col-lg-3 col-sm-6 col-12"> -->
                                                    <div class="form-group">
                                                        <label class="px-0">Time</label>
                                                        <p-calendar placeholder="Time"
                                                            formControlName="sterilization_timeArray" [timeOnly]="true"
                                                            inputId="time" [hourFormat]="12"
                                                            [disabled]="true"></p-calendar>
                                                    </div>
                                                    <!-- </div> -->
                                                    <!-- <div class="col-lg-3 col-sm-6 col-12">
                                                            <div class="form-group">
                                                                <label class="px-0">Actual Time</label>
                                                                <p-calendar placeholder="Time"
                                                                    formControlName="actual_sterilization_timeArray"
                                                                    [timeOnly]="true" inputId="time"
                                                                    [hourFormat]="12"></p-calendar>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4 col-sm-6 col-12">
                                                            <div class="form-group">
                                                                <label class="px-0">Remark</label>
                                                                <input type="text" name="name" class="form-control"
                                                                    placeholder="Remark"
                                                                    [formControl]="control.get('sterilization_remark')" />
                                                            </div>
                                                        </div> -->
                                                    <!-- </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </p-accordionTab>


                <!--SOLID-->
                <p-accordionTab header="Solid Food Feeding">
                    <div class="add-listings-box">
                        <h3>Solid</h3>
                        <!--Regular Meal-->
                        <div formArrayName="solid_food">
                            <ng-container
                                *ngFor="let control of getRegularFormArray().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">
                                    <label class="subHeading mb-3 mt-sm-0 mt-3">Regular meal</label>
                                    <div class="form-group mb-2 col-6 col-md-3 col-lg-2 d-flex align-items-center">
                                        <div class="form-check pl-0 checkbox-list px-0 d-flex">
                                            <label class="checkbox checkbox-outline px-0 m-0">
                                                <!-- <input type="checkbox" value="Breakfast"
                                                    (change)="disableAllRegularFoodControls($event.target.checked,i)"
                                                    formControlName="regular_meal_type" /> -->
                                                <span></span>
                                                Breakfast
                                            </label>
                                        </div>
                                    </div>
                                    <!-- breakfast -->
                                    <div class="col-6 col-md-3 col">
                                        <div class="form-group">
                                            <label class="px-0">Time</label>
                                            <div class="card flex justify-content-center">
                                                <p-calendar placeholder="Time" formControlName="regular_meal_time"
                                                    [timeOnly]="true" [hourFormat]="12" inputId="time"
                                                    [class.is-invalid]="(savedData.submitted &&
                                                    oneToThreeYear.get('regular_meal_time')?.invalid)
                                                    ||
                                                    oneToThreeYear.get('regular_meal_time')?.invalid
                                                    &&
                                                    oneToThreeYear.get('regular_meal_time').touched"></p-calendar>
                                                <div class="invalid-feedback">Time is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col col-md-3 col-lg col m-0">
                                        <div class="form-group ml-0 col-12 p-0 mb-0">
                                            <label class="px-0">Food components</label>
                                            <div class="col-12 p-0">
                                                <input type="text" formControlName="regular_meal_food_components"
                                                    class="form-control" placeholder="Food components"
                                                    [class.is-invalid]="(savedData.submitted &&
                                                    oneToThreeYear.get('regular_meal_food_components')?.invalid)
                                                    ||
                                                    oneToThreeYear.get('regular_meal_food_components')?.invalid
                                                    &&
                                                    oneToThreeYear.get('regular_meal_food_components').touched" />
                                                <div class="invalid-feedback">Food components is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-auto d-flex mb-3 align-items-end">
                                        <p-button type="button" icon="bx bx-plus" (click)="addRegularObject('add', i)"
                                            iconPos="right" class="secondary-btn mr-2"></p-button>
                                        <div class="remove">
                                            <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                icon="bx bx-minus" (click)="addRegularObject('delete', i)"
                                                iconPos="right"></p-button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <!-- Lunck checkbox -->
                        <div formArrayName="lunch_food">
                            <ng-container
                                *ngFor="let control of getLunchFormArray().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">
                                    <div class="form-group mb-2 col-6 col-md-3 col-lg-2 d-flex align-items-center">
                                        <div class="form-check pl-0 checkbox-list px-0 d-flex">
                                            <label class="checkbox checkbox-outline px-0 m-0">
                                                <!-- <input type="checkbox" value="Lunch"
                                                    (change)="disableAlllunchFoodControls($event.target.checked,i)"
                                                    formControlName="regular_meal_lunch" /> -->
                                                <span></span>
                                                Lunch
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-3 col">
                                        <div class="form-group">
                                            <label class="px-0">Time</label>
                                            <div class="card flex justify-content-center">
                                                <p-calendar placeholder="Time" [timeOnly]="true" [hourFormat]="12"
                                                    formControlName="regular_meal_lunch_time" inputId="time"
                                                    [class.is-invalid]="(savedData.submitted &&
                                    oneToThreeYear.get('regular_meal_lunch_time')?.invalid)
                                    ||
                                    oneToThreeYear.get('regular_meal_lunch_time')?.invalid
                                    &&
                                    oneToThreeYear.get('regular_meal_lunch_time').touched"></p-calendar>
                                                <div class="invalid-feedback">Time is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col col-md-3 col-lg col m-0">
                                        <div class="form-group ml-0 col-12 p-0 mb-0">
                                            <label class="px-0">Food components</label>
                                            <div class="col-12 p-0">
                                                <input type="text" formControlName="regular_meal_lunch_food_components"
                                                    class="form-control" placeholder="Food components"
                                                    [class.is-invalid]="(savedData.submitted &&
                                    oneToThreeYear.get('regular_meal_lunch_food_components')?.invalid)
                                    ||
                                    oneToThreeYear.get('regular_meal_lunch_food_components')?.invalid
                                    &&
                                    oneToThreeYear.get('regular_meal_lunch_food_components').touched" />
                                                <div class="invalid-feedback">Food components is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-auto d-flex mb-3 align-items-end">
                                        <p-button type="button" icon="bx bx-plus" (click)="addLunchObject('add', i)"
                                            iconPos="right" class="secondary-btn mr-2"></p-button>
                                        <div class="remove">
                                            <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                icon="bx bx-minus" (click)="addLunchObject('delete', i)"
                                                iconPos="right"></p-button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <!-- Dinner -->
                        <div formArrayName="dinner_food">
                            <ng-container
                                *ngFor="let control of getDinnerFormArray().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">
                                    <div class="form-group mb-2 col-6 col-md-3 col-lg-2 d-flex align-items-center">
                                        <div class="form-check pl-0 checkbox-list px-0 d-flex">
                                            <label class="checkbox checkbox-outline px-0 m-0">
                                                <!-- <input type="checkbox" value="Dinner"
                                                    (change)="disableAlldinnerFoodControls($event.target.checked,i)"
                                                    formControlName="regular_meal_dinner" /> -->
                                                <span></span>
                                                Dinner
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-3 col">
                                        <div class="form-group">
                                            <label class="px-0">Time</label>
                                            <div class="card flex justify-content-center">
                                                <p-calendar placeholder="Time" [timeOnly]="true" [hourFormat]="12"
                                                    formControlName="regular_meal_dinner_time" inputId="time"
                                                    [class.is-invalid]="(savedData.submitted &&
                                    oneToThreeYear.get('regular_meal_dinner_time')?.invalid)
                                    ||
                                    oneToThreeYear.get('regular_meal_dinner_time')?.invalid
                                    &&
                                    oneToThreeYear.get('regular_meal_dinner_time').touched"></p-calendar>
                                                <div class="invalid-feedback">Time is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col col-md-3 col-lg col m-0">
                                        <div class="form-group ml-0 col-12 p-0 mb-0">
                                            <label class="px-0">Food components</label>
                                            <div class="col-12 p-0">
                                                <input type="text" formControlName="regular_meal_dinner_food_components"
                                                    class="form-control" placeholder="Food components"
                                                    [class.is-invalid]="(savedData.submitted &&
                                    oneToThreeYear.get('regular_meal_dinner_food_components')?.invalid)
                                    ||
                                    oneToThreeYear.get('regular_meal_dinner_food_components')?.invalid
                                    &&
                                    oneToThreeYear.get('regular_meal_dinner_food_components').touched" />
                                                <div class="invalid-feedback">Food components is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-auto d-flex mb-3 align-items-end">
                                        <p-button type="button" icon="bx bx-plus" (click)="addDinnerObject('add', i)"
                                            iconPos="right" class="secondary-btn mr-2"></p-button>
                                        <div class="remove">
                                            <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                icon="bx bx-minus" (click)="addDinnerObject('delete', i)"
                                                iconPos="right"></p-button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                        <div class="row">
                            <label class="subHeading mb-3">Others</label>
                            <div class="form-group mb-2 col-6 col-md-3 col-lg-2 d-flex align-items-center">
                                <div class="form-check pl-0 checkbox-list px-0 d-flex align-items-center">
                                    <label class="checkbox checkbox-outline px-0 m-0">
                                        <!-- <input type="checkbox" value="Snack" formControlName="solid_other_snack"
                                            (change)="handleInput($event, 'regularMeal')" /> -->
                                        <span></span>
                                        Snack
                                    </label>
                                </div>
                            </div>
                            <div class="col-6 col-md-3 col">
                                <div class="form-group">
                                    <label class="px-0">Time</label>
                                    <div class="card flex justify-content-center">
                                        <p-calendar placeholder="Time" [timeOnly]="true" [hourFormat]="12"
                                            formControlName="solid_other_snack_time" inputId="time" [class.is-invalid]="(savedData.submitted &&
                                    oneToThreeYear.get('solid_other_snack_time')?.invalid)
                                    ||
                                    oneToThreeYear.get('solid_other_snack_time')?.invalid
                                    &&
                                    oneToThreeYear.get('solid_other_snack_time').touched"></p-calendar>
                                        <div class="invalid-feedback">Time is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="form-group ml-0 col-12 p-0 mb-0">
                                    <label class="px-0">Food components</label>
                                    <div class="col-12 p-0">
                                        <input type="text" formControlName="soild_other_snack_food_components"
                                            [disabled]="Snack" class="form-control" placeholder="Food components"
                                            [class.is-invalid]="(savedData.submitted &&
                                    oneToThreeYear.get('soild_other_snack_food_components')?.invalid)
                                    ||
                                    oneToThreeYear.get('soild_other_snack_food_components')?.invalid
                                    &&
                                    oneToThreeYear.get('soild_other_snack_food_components').touched" />
                                        <div class="invalid-feedback">Food components is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </p-accordionTab>
                <!-- </div> -->


                <!--Food/Medicine Allergy-->
                <p-accordionTab header="Allergy">
                    <div class="add-listings-box">
                        <div class="row">
                            <!-- noAllergy -->
                            <label class="subHeading mb-3 mt-sm-0 mt-3">Food/Medicine Allergy</label>
                            <div class="form-group col-md-3 col-12">
                                <div class="form-group m-0">
                                    <div class="d-flex">
                                        <p class="me-2">
                                            <input type="radio" id="allergy_yes" value="1"
                                                formControlName="food_medicine_allergy"
                                                (change)="handleInput($event, 'fnmAllergy')">
                                            <label for="allergy_yes" class="mb-0 mx-2">Yes</label>
                                        </p>
                                        <p class="d-flex">
                                            <input type="radio" id="allergy_no" value="0"
                                                formControlName="food_medicine_allergy"
                                                (change)="handleInput($event, 'fnmAllergy')">
                                            <label for="allergy_no" class="mb-0 mx-2">No</label>
                                        </p>
                                    </div>
                                </div>

                                <!-- <p class="d-flex"> -->
                                <!-- <input type="radio" id="allergy_yes" value="1" (change)="handleInput($event, 'fnmAllergy')"
                          formControlName="food_medicine_allergy" />
                        <label for="allergy_yes" class="mb-0 mx-2">Yes</label> -->
                                <!-- <label class="checkbox checkbox-outline px-0">
                            <input type="checkbox" value="1" formControlName="food_medicine_allergy_yes" />
                            <span></span>
                            Yes
                        </label>
                    </p>
                    <p class="d-flex">
                        <label class="checkbox checkbox-outline px-0">
                            <input type="checkbox" value="0" formControlName="food_medicine_allergy_no" />
                            <span></span>
                            No
                        </label>
                    </p> -->
                            </div>
                            <!-- Allergy -->
                            <div class="col">
                                <div class="form-group">
                                    <!-- <label class="px-0">Specify Products</label> -->
                                    <textarea formControlName="food_medicine_allergy_specify_prod" class="form-control"
                                        placeholder="Specify Products" rows="3" [class.is-invalid]="(savedData.submitted &&
                                        oneToThreeYear.get('food_medicine_allergy_specify_prod')?.invalid)
                                        ||
                                        oneToThreeYear.get('food_medicine_allergy_specify_prod')?.invalid
                                        &&
                                        oneToThreeYear.get('food_medicine_allergy_specify_prod').touched"></textarea>
                                    <div class="invalid-feedback">Specify product is required</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </p-accordionTab>
                <!-- </div> -->
                <p-accordionTab header="Diaper Change">
          
                    <div class="add-listings-box pb-0">
                      <div class="row">
                        <div class="col-lg-2 col-md-4">
                          <label class="checkbox checkbox-outline px-0  mt-md-2">
                            <input type="checkbox" value="formulaFeeding" name="checkboxes" formControlName="diaper_type" />
                            <span></span>
                            Diaper Formula
                          </label>
                        </div>
          
                        <!-- Formula feeding dropdown -->
                        <div class="col-lg-4 col-md-4">
                          <div class="form-group">
                            <label class="p-0">Diaper frequency (every hr​): </label>
                            <div class="card flex justify-content-center">
                              <p-dropdown [options]="frequencyList" formControlName="diaper_change_frequency"
                                (ngModelChange)="creatediaperfields()" optionLabel="name" optionValue="value" [filter]="true"
                                filterBy="value" placeholder="Frequency" [resetFilterOnHide]="true" [class.is-invalid]="(savedData.submitted &&
                                  oneToThreeYear.get('diaper_change_frequency')?.invalid)
                                  ||
                                  oneToThreeYear.get('diaper_change_frequency')?.invalid
                                  &&
                                  oneToThreeYear.get('diaper_change_frequency').touched">
          
                              </p-dropdown>
                              <div class="invalid-feedback"> Frequency is required</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-4">
                          <div class="form-group">
                            <label class="p-0">Start Time:</label>
                            <div class="card flex justify-content-center">
                              <p-calendar placeholder="Time" formControlName="diaper_label" [timeOnly]="true" [hourFormat]="12"
                                inputId="time" (ngModelChange)="creatediaperfields()" [class.is-invalid]="(savedData.submitted &&
                                  oneToThreeYear.get('diaper_label')?.invalid)
                                  ||
                                  oneToThreeYear.get('diaper_label')?.invalid
                                  &&
                                  oneToThreeYear.get('diaper_label').touched"></p-calendar>
                              <div class="invalid-feedback"> Time is required</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-4">
                          <div class="form-group">
                            <label class="p-0">End Time: </label>
                            <div class="card flex justify-content-center">
                              <p-calendar placeholder="Time" formControlName="diaper_label_end_time" [timeOnly]="true"
                                [hourFormat]="12" (ngModelChange)="creatediaperfields()" inputId="time" [class.is-invalid]="(savedData.submitted &&
                                    oneToThreeYear.get('diaper_label_end_time')?.invalid)
                                    ||
                                    oneToThreeYear.get('diaper_label_end_time')?.invalid
                                    &&
                                    oneToThreeYear.get('diaper_label_end_time').touched">
                              </p-calendar>
                              <div class="invalid-feedback">End Time is required</div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-2"></div>
                      <div class="col-lg-4 col-md-2">
                        <div class="form-group">
                          <label class="p-0">Amount (ml): </label>
                          <div class="card flex justify-content-center">
                            <input type="text" name="name" class="form-control" placeholder="Amount(ml)"
                                formControlName="feeding_amount_per" [class.is-invalid]="(savedData.submitted &&
                              oneToThreeYear.get('feeding_amount_per')?.invalid) 
                              ||
                              oneToThreeYear.get('feeding_amount_per')?.invalid
                              &&
                              oneToThreeYear.get('feeding_amount_per').touched" />
                            <div class="invalid-feedback">Amount is required</div>
                          </div>
                        </div>
                      </div>
          
                      <div class="col-lg-3 col-md-2">
                        <div class="form-group ml-0 col-12 p-0 mb-0">
                          <label class="p-0">Type of formula: </label>
                          <div class="col-12 p-0">
                            <input type="text" name="name" class="form-control" placeholder="Formula"
                                formControlName="type_of_formula" [class.is-invalid]="(savedData.submitted &&
                                  oneToThreeYear.get('type_of_formula')?.invalid)
                                  ||
                                  oneToThreeYear.get('type_of_formula')?.invalid
                                  &&
                                  oneToThreeYear.get('type_of_formula').touched" />
                            <div class="invalid-feedback">Type of formula is required</div>
                          </div>
                        </div>
                      </div> -->
                        <!-- <div class="col-lg-3 col-md-2">
                        <div class="form-group">
                          <label class="px-0">Remark</label>
                          <input type="text" name="formula_remark" class="form-control" placeholder="Remark"
                              formControlName="formula_remark" />
                        </div>
                      </div> -->
                      </div>
                    </div>
                    <div *ngIf="isdiaper">
                      <div class="row justify-content-end mx-0">
                        <div class="add-listings-box pt-3 mb-3 col-lg-12">
                          <div formArrayName="diaper_calculation" class="row">
                            <div *ngFor="let control of getdiaperCalculationControls().controls; let i = index"
                              [formGroupName]="i" class="col-lg-4">
                              <!-- <div class="row"> -->
                              <!-- <div class="col-md col-lg-2 mt-3">
                                  <label class="checkbox checkbox-outline px-0  mt-md-2">
                                    <input type="checkbox" value="formulaFeeding" name="checkboxes"
                                        formControlName="feeding_checkbox" />
                                    <span></span>
                                    Done?
                                  </label>
                                </div> -->
                              <!-- <div class="col-lg-3 col-sm-6 col-12"> -->
                              <div class="form-group">
                                <label class="px-0">Time</label>
                                <p-calendar placeholder="Time" formControlName="diaper_timeArray" [hourFormat]="12"
                                  [timeOnly]="true" inputId="time" disabled="true"></p-calendar>
                              </div>
                              <!-- </div> -->
                              <!-- <div class="col-lg-3 col-sm-6 col-12">
                                  <div class="form-group">
                                    <label class="px-0">Time Feeding By Nurse</label>
                                    <p-calendar placeholder="Actual Time" formControlName="nurse_feeding" [hourFormat]="12"
                                        [timeOnly]="true" inputId="time"></p-calendar>
                                  </div>
                                </div> -->
                              <!-- <div class="col-lg-3 col-sm-6 col-12">
                                  <div class="form-group">
                                    <label class="px-0">Amount (ml)</label>
                                    <input type="text" name="amount" class="form-control" placeholder="Amount"
                                        formControlName="amount" />
                                  </div>
                                </div> -->
                              <!-- <div class="col-lg-2"></div>
                                <div class="col-lg-3 col-sm-12 col-12">
                                  <div class="form-group">
                                    <label class="px-0">Name of formula</label>
                                    <input type="text" name="remark" class="form-control" placeholder="Name of formula"
                                        formControlName="remark" />
                                  </div>
                                </div> -->
                              <!-- <div class="col-lg-3 col-sm-12 col-12">
                                  <div class="form-group">
                                    <label class="px-0">Remark</label>
                                    <input type="text" name="remark" class="form-control" placeholder="Remark" id="remark"
                                        formControlName="formula_remark" />
                                  </div>
                                </div> -->
                              <!-- </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
          
                    <!-- <div class="add-listings-box p-0 border-0">
                      <div class="row">
                        <div class="col-sm-4 col-12">
                          <div class="form-group">
                            <label class="px-0">Frequency </label>
                            <div class="form-check checkbox-list p-0">
                              <p-dropdown [options]="frequencyList" formControlName="diaper_change_frequency" optionLabel="name"
                                  optionValue="value" [filter]="true" filterBy="value" placeholder="Frequency"
                                  (ngModelChange)="DiaperChange($event)" [resetFilterOnHide]="true" [class.is-invalid]="(savedData.submitted &&
                                oneToThreeYear.get('diaper_change_frequency')?.invalid)
                                ||
                                oneToThreeYear.get('diaper_change_frequency')?.invalid
                                &&
                                oneToThreeYear.get('diaper_change_frequency').touched">
                              </p-dropdown>
                              <div class="invalid-feedback">Frequency is required</div>
                            </div>
                          </div>
                        </div>
                       
                        <div class="col-sm-8">
                          <label for="" class="d-none d-md-block">&nbsp;</label>
                          <div class="row" formArrayName="diaper_changes">
                            <div *ngFor="let control of getDiaperChangeControlsfrequency().controls; let i = index"
                                [formGroupName]="i" class="col-6 col-md-4 col-lg-3">
                              <div class="form-group">
          
                                <div class="form-check px-0 checkbox-list">
                                  <label class="checkbox checkbox-outline px-0">
                                  
                                    <span></span>
                                    {{ getTimeLabel(i) }}
                                  </label>
                                </div>
          
                              </div>
                            </div>
                          </div>
          
                        </div>
                    
                      </div>
                    </div> -->
          
                  </p-accordionTab>

                <!-- bathing-->

                <!-- <div class="add-listings-box"> -->
                <p-accordionTab header="Bathing">
                    <div class="add-listings-box">
                        <div class="row">
                            <label class="subHeading mb-3">Bathing </label>
                            <!--Tub Bath -->
                            <div class="form-group col-sm-4 col-12 d-flex align-items-end">
                                <div class="form-check pl-0 checkbox-list px-0">
                                    <label class="checkbox checkbox-outline px-0">
                                        <input type="checkbox" value="Tub" formControlName="bathing_tub"
                                            (change)="handleInput($event, 'Bathing')" />
                                        <span></span>
                                        Tub
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-4 col-12">
                                <div class="form-group">
                                    <label class="px-0">Frequency</label>
                                    <div class="card flex justify-content-center">
                                        <p-dropdown id="tub_frequency" [options]="frequencyList"
                                            formControlName="bathing_tub_frequency" optionLabel="name" [filter]="true"
                                            filterBy="value" [disabled]="tubBath" optionValue="value"
                                            placeholder="Select Frequency" (onChange)="addtubfield()"
                                            [class.is-invalid]="(savedData.submitted &&
                                            oneToThreeYear.get('bathing_tub_frequency')?.invalid)
                                            ||
                                            oneToThreeYear.get('bathing_tub_frequency')?.invalid
                                            &&
                                            oneToThreeYear.get('bathing_tub_frequency').touched">
                                        </p-dropdown>
                                        <div class="invalid-feedback">Bathing tub frequency is required</div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isBathingtub" class="add-listings-box pt-3 mb-3 col-lg-12">
                                <div class="row" formArrayName="tub_bathing">
                                    <div *ngFor="let control of getbathingtubarray().controls; let i = index"
                                        [formGroupName]="i" class="col-lg-4 col-sm-6 col-4">
                                        <!-- <div class="col-md col-lg-2">
                                            <div class="form-group">
                                                <label class="checkbox checkbox-outline px-0  mt-md-2 ">
                                                    <input type="checkbox" value="breastFeeding" name="checkboxes"
                                                        formControlName="tub_checkbox" />
                                                    Time
                                                </label>
                                            </div>
                                        </div> -->

                                        <!-- <div class="col-lg-4 col-sm-6 col-12"> -->
                                        <div class="form-group">
                                            <label class="px-0">Time</label>
                                            <p-calendar placeholder="Select Time" [timeOnly]="true" [disabled]="tubBath"
                                                formControlName="bathing_tub_time" [hourFormat]="12" inputId="time"
                                                [class.is-invalid]="(savedData.submitted &&
                                                oneToThreeYear.get('bathing_tub_time')?.invalid)
                                                ||
                                                oneToThreeYear.get('bathing_tub_time')?.invalid
                                                &&
                                                oneToThreeYear.get('bathing_tub_time').touched"></p-calendar>
                                            <div class="invalid-feedback">Time is required</div>
                                        </div>
                                        <!-- </div> -->

                                        <!-- <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Remark</label>
                                                <input type="text" name="name" class="form-control" placeholder="Remark"
                                                    [formControl]="control.get('tubBathing_remark')" />
                                            </div>
                                        </div> -->

                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Shower -->
                        <div class="row">
                            <div class="form-group col-sm-4 col-12 d-flex align-items-end">
                                <div class="form-check pl-0 checkbox-list px-0">
                                    <label class="checkbox checkbox-outline px-0">
                                        <input type="checkbox" value="Shower" formControlName="bathing_shower"
                                            (change)="handleInput($event, 'Bathing')" />
                                        <span></span>
                                        Shower
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-4 col-12">
                                <div class="form-group">
                                    <label class="px-0">Frequency</label>
                                    <div class="card flex justify-content-center">
                                        <p-dropdown id="tub_frequency" [options]="frequencyList"
                                            (onChange)="addshowerfield()" [disabled]="spongeBath"
                                            formControlName="bathing_shower_frequency" optionLabel="name"
                                            optionValue="value" placeholder="Select Frequency" [filter]="true"
                                            filterBy="value" [class.is-invalid]="(savedData.submitted &&
                                            oneToThreeYear.get('bathing_shower_frequency')?.invalid)
                                            ||
                                            oneToThreeYear.get('bathing_shower_frequency')?.invalid
                                            &&
                                            oneToThreeYear.get('bathing_shower_frequency').touched">
                                        </p-dropdown>
                                        <div class="invalid-feedback">Bathing shower frequency is required</div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isBathingshower" class="add-listings-box pt-3 mb-3 col-lg-12">
                                <div class="row" formArrayName="shower_bathing">
                                    <div *ngFor="let control of getshowertubarray().controls; let i = index"
                                        [formGroupName]="i" class="col-lg-4 col-sm-6 col-12">
                                        <!-- <div class="col-md col-lg-2">
                                            <div class="form-group">
                                                <label class="checkbox checkbox-outline px-0  mt-md-2">
                                                    <input type="checkbox" value="breastFeeding" name="checkboxes"
                                                        formControlName="shower_checkbox" />
                                                    Time
                                                </label>
                                            </div>
                                        </div> -->
                                        <!-- <div class="col-lg-4 col-sm-6 col-12"> -->
                                        <div class="form-group">
                                            <label class="px-0">Time</label>
                                            <p-calendar placeholder="Select Time" [timeOnly]="true"
                                                [disabled]="spongeBath" formControlName="bathing_shower_time"
                                                [hourFormat]="12" inputId="time" [class.is-invalid]="(savedData.submitted &&
                                                    oneToThreeYear.get('bathing_shower_time')?.invalid)
                                                    ||
                                                    oneToThreeYear.get('bathing_shower_time')?.invalid
                                                    &&
                                                    oneToThreeYear.get('bathing_shower_time').touched"></p-calendar>
                                            <div class="invalid-feedback">Bathing time is required</div>
                                        </div>
                                        <!-- </div> -->
                                        <!-- <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Remark</label>
                                                <input type="text" name="name" class="form-control" placeholder="Remark"
                                                    [formControl]="control.get('shower_remark')" />
                                            </div>
                                        </div> -->
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </p-accordionTab>
                <!-- </div> -->

                <!-- potty training-->
                <p-accordionTab header="Potty Training">
                    <div class="add-listings-box">
                        <div class="form-group">
                            <div class="col-lg-12">
                                <label class="subHeading px-0">Potty Training:</label>
                                <div formArrayName="potty_training">
                                    <ng-container
                                        *ngFor="let control of getTrainingFormArray().controls; index as i; last as last">
                                        <div class="row" [formGroupName]="i">
                                            <div class="col-md-3 col-lg-2">
                                                <div class="radio-inline">
                                                    <div class="d-flex">
                                                        <p class="me-2">
                                                            <input type="radio" id="training-yes" id="training-yes{{i}}"
                                                                formControlName="potty_training" value="Yes"
                                                                (change)="handleInput($event, 'potty_training')">
                                                            <label for="training-yes{{i}}"
                                                                class="mb-0 mx-sm-2 mx-0">Yes</label>
                                                        </p>
                                                        <p class="d-flex">
                                                            <input type="radio" id="training-no{{i}}"
                                                                formControlName="potty_training" value="No"
                                                                (change)="handleInput($event, 'potty_training')">
                                                            <label for="training-no{{i}}"
                                                                class="mb-0 mx-sm-2 mx-0">No</label>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="form-group">
                                                    <!-- <label class="p-0">Activity Name:</label> -->
                                                    <textarea name="training_child" class="form-control"
                                                        id="kt_autosize_1" rows="2" [readonly]="potty_training"
                                                        formControlName="training_child"
                                                        placeholder="Activity Name"></textarea>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <!-- <div class="col-lg-2 col-md-2 mt-3">
                                                    <label class="checkbox checkbox-outline px-0  mt-md-2">
                                                        <input type="checkbox" value="true" name="checkboxes"
                                                            formControlName="done_training_child" />
                                                        <span></span>
                                                        Done?
                                                    </label>
                                                </div> -->
                                                <div class="col-lg-3 col-sm-6 col-12">
                                                    <div class="form-group">
                                                        <label class="px-0">Time</label>
                                                        <p-calendar placeholder="Time"
                                                            formControlName="time_training_child" [timeOnly]="true"
                                                            [disabled]="potty_training" inputId="time"
                                                            [hourFormat]="12"></p-calendar>
                                                    </div>
                                                </div>

                                                <!-- <div class="col-lg-4 col-sm-6 col-12">
                                                    <div class="form-group">
                                                        <label class="px-0">Remark</label>
                                                        <input type="text" name="name" class="form-control"
                                                            placeholder="Remark"
                                                            [formControl]="control.get('training_child_remark')" />
                                                    </div>
                                                </div> -->
                                            </div>
                                            <div class="col-auto d-flex mb-3 justify-content-end">
                                                <p-button type="button" icon="bx bx-plus"
                                                    (click)="addTrainingObject('add', i)" iconPos="right"
                                                    class="secondary-btn mr-2"></p-button>
                                                <div class="remove">
                                                    <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                        icon="bx bx-minus" (click)="addTrainingObject('delete', i)"
                                                        iconPos="right"></p-button>
                                                </div>
                                            </div>

                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </p-accordionTab>
                <!-- Skin Care -->
                <!-- <div class="add-listings-box"> -->
                <!-- <div class="form-check checkbox-list p-0">
                <div class="form-check checkbox-list p-0"> -->
                <p-accordionTab header="Skin Care">
                    <div class="add-listings-box">
                        <!-- formarray creation -->
                        <label class="subHeading mb-2">Skin Cares</label>
                        <div formArrayName="skin_care">
                            <ng-container *ngFor="let control of getSkinFormArray().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">

                                    <div class="col-lg-4">
                                        <div class="form-group">

                                            <div class="form-check checkbox-list p-0">
                                                <label for="Lotion" class="checkbox checkbox-outline px-0"> <input
                                                        type="checkbox" id="Lotion" value="Lotion"
                                                        formControlName="skin_care_lotion"
                                                        (change)="handleInput($event, 'others')"><span>Lotion</span></label>



                                                <label for="Baby_Oil"
                                                    class="checkbox checkbox-outline mt-sm-0 mt-3 px-0"><input
                                                        type="checkbox" id="Baby_Oil" value="Baby Oil"
                                                        formControlName="skin_care_baby_oil"
                                                        (change)="handleInput($event, 'others')"><span>Baby
                                                        Oil</span></label>


                                                <label for="other"
                                                    class="checkbox checkbox-outline mt-sm-0 mt-3 px-0"><input
                                                        type="checkbox" formControlName="skin_care_others"
                                                        (change)="handleInput($event, 'others')"
                                                        value="others"><span>Others</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg">
                                        <!-- <label class="px-0">Specify Products</label> -->
                                        <div class="card flex justify-content-center">
                                            <textarea formControlName="skin_care_specify_produ" class="form-control"
                                                placeholder="Specify Products" rows="2"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto d-flex mb-3 justify-content-end">
                                        <p-button type="button" icon="bx bx-plus" (click)="addSkinObject('add', i)"
                                            iconPos="right" class="secondary-btn mr-2"></p-button>
                                        <div class="remove">
                                            <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                icon="bx bx-minus" (click)="addSkinObject('delete', i)"
                                                iconPos="right"></p-button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <!-- wipes-->

                        <div class="row">
                            <label class="subHeading mb-2">Wipes</label>
                            <div class="col-lg-4 col-sm-6 col-12 d-flex align-items-center">
                                <div class="form-group">
                                    <div class="d-flex">
                                        <p class="me-3">
                                            <input type="radio" id="wipes_yes" value="1"
                                                formControlName="skin_care_Wipes"
                                                (change)="handleInput($event, 'wipes')">
                                            <label for="wipes_yes" class="mb-0 mx-2">Yes</label>
                                        </p>
                                        <p class="d-flex">
                                            <input type="radio" id="wipes_no" value="0"
                                                formControlName="skin_care_Wipes"
                                                (change)="handleInput($event, 'wipes')">
                                            <label for="wipes_no" class="mb-0 mx-2">No</label>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-12">
                                <div class="form-group mb-0">
                                    <label class="px-0">Special Instructions</label>
                                    <div class="form-group pb-3 mb-0">
                                        <textarea class="form-control" rows="3" placeholder="Special Instructions"
                                            formControlName="skin_care_special_instructions"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- </div> -->

                        <!-- Changing clothes -->
                        <!-- <div class="add-listings-box"> -->
                        <div class="row">
                            <label class="subHeading mb-2">Changing of clothes</label>
                            <div class="col">
                                <div class="form-group mb-0">
                                    <label class="px-0">Special Instructions</label>
                                    <div class="form-group pb-3 mb-0">
                                        <textarea class="form-control"
                                            formControlName="changing_colthes_spiecal_instructions" rows="3"
                                            placeholder="Special Instructions"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" formArrayName="changing_clothes">
                            <ng-container
                                *ngFor="let control of getClothesFormArray().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">

                                    <!-- <div class="col-lg-4 col-sm-6 col-12">
                                        <div class="form-group">
                                            <div class="form-check checkbox-list p-0">
                                                <label class="checkbox checkbox-outline mt-sm-0 mt-3 px-0">
                                                    <input type="checkbox" value="withMother" name="checkboxes"
                                                        formControlName="changing_colthes" />
                                                    Changing Clothes
                                                </label>
                                            </div>
                                        </div>
                                    </div> -->

                                    <div class="col-lg-4 col-sm-6 col-12">
                                        <div class="form-group">
                                            <label class="px-0">Changing Time </label>
                                            <div class="form-check checkbox-list p-0">
                                                <p-calendar placeholder="Select Time" [timeOnly]="true"
                                                    [hourFormat]="12" inputId="time" formControlName="Changing_time"
                                                    [class.is-invalid]="(savedData.submitted &&
                                                   oneToThreeYear.get('Changing_time')?.invalid) ||
                                                  oneToThreeYear.get('Changing_time')?.invalid &&
                                                  oneToThreeYear.get('Changing_time').touched">
                                                </p-calendar>
                                                <div class="invalid-feedback">Time is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-lg-4 col-sm-6 col-12">
                                        <div class="form-group">
                                            <label class="px-0">Remark</label>
                                            <input type="text" name="name" class="form-control" placeholder="Remark"
                                                [formControl]="control.get('clothe_remark')" />
                                        </div>
                                    </div> -->
                                    <div class="col-auto d-flex mb-3 align-items-end">
                                        <p-button type="button" icon="bx bx-plus" (click)="addClothesObject('add', i)"
                                            iconPos="right" class=" secondary-btn mr-2"></p-button>
                                        <div class="remove">
                                            <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                icon="bx bx-minus" (click)="addClothesObject('delete', i)"
                                                iconPos="right">
                                            </p-button>
                                        </div>
                                    </div>

                                </div>
                            </ng-container>
                        </div>
                    </div>
                </p-accordionTab>
                <!-- </div> -->
                <!-- sleep training -->
                <p-accordionTab header="Sleep Training">
                    <div class="add-listings-box">
                        <div class="row">
                            <div class="col-lg-4 col-sm-6 col-12">
                                <div class="form-group mb-2">
                                    <label class="p-0">
                                        Sleep Training
                                    </label>
                                    <div class="d-flex">
                                        <p class="me-3">
                                            <input type="radio" id="head-yes" name="head" value="Yes"
                                                formControlName="sleeping_training_checkbox"
                                                (change)="handleInput($event, 'sleep_Training')">
                                            <label for="head-yes">Yes</label>
                                        </p>
                                        <p class="d-flex">
                                            <input type="radio" id="head-no" name="head" value="No"
                                                formControlName="sleeping_training_checkbox"
                                                (change)="handleInput($event, 'sleep_Training')">
                                            <label for="head-no">No</label>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div formArrayName="sleeping_training">
                            <ng-container
                                *ngFor="let control of getsleepTrainingFormArray().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">
                                    <div class="col-lg-4 col-sm-6 col-12">
                                        <div class="form-group">
                                            <label class="px-0">Time</label>
                                            <div class="card flex justify-content-center" [ngClass]="{
                                                'is-invalid': (savedData.submitted && control.get('sleeping_time')?.invalid) ||
                                                             control.get('sleeping_time')?.invalid && control.get('sleeping_time').touched
                                            }">
                                                <p-calendar placeholder="Time" [disabled]="sleeptraining"
                                                    formControlName="sleeping_time" [timeOnly]="true" [hourFormat]="12"
                                                    inputId="time"></p-calendar>
                                            </div>
                                            <div *ngIf="(savedData.submitted || control.get('sleeping_time').touched) && control.get('sleeping_time')?.invalid"
                                                class="invalid-feedback">
                                                Time is Required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-6 col-12">
                                        <div class="form-group">
                                            <label class="px-0">Remark</label>
                                            <input type="text" name="remark" class="form-control" placeholder="Remark"
                                                [readonly]="sleeptraining" formControlName="sleeping_remark" [ngClass]="{
                                                'is-invalid': (savedData.submitted && control.get('sleeping_remark')?.invalid) ||
                                                             control.get('sleeping_remark')?.invalid && control.get('sleeping_remark').touched
                                            }" />
                                            <div *ngIf="(savedData.submitted || control.get('sleeping_remark').touched) && control.get('sleeping_remark')?.invalid"
                                                class="invalid-feedback">
                                                Remark is Required
                                            </div>
                                        </div>
                                    </div>



                                    <div class="col-lg-4 d-flex mb-3 align-items-end">
                                        <p-button type="button" icon="bx bx-plus"
                                            (click)="addSleepTrainingObject('add', i)" iconPos="right"
                                            class="secondary-btn mr-2"></p-button>
                                        <div class="remove">
                                            <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                icon="bx bx-minus" (click)="addSleepTrainingObject('delete', i)"
                                                iconPos="right"></p-button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                </p-accordionTab>

                <!--Sleeping-->
                <!-- <div class="add-listings-box"> -->
                <p-accordionTab header="Sleeping">
                    <div class="add-listings-box">
                        <div class="row">
                            <label class="subHeading mb-3">Sleeping Independently:</label>
                            <div class="radio-inline mb-3">
                                <div class="d-flex">
                                    <p class="me-2">
                                        <input type="radio" id="sleeping-yes" formControlName="sleeping_independently"
                                            value="1">
                                        <label for="sleeping-yes" class="mb-0 mx-sm-2 mx-0">Yes</label>
                                    </p>
                                    <p class="d-flex">
                                        <input type="radio" id="sleeping-no" formControlName="sleeping_independently"
                                            value="0">
                                        <label for="sleeping-no" class="mb-0 mx-sm-2 mx-0">No</label>
                                    </p>
                                </div>
                            </div>
                            <div class="row" formArrayName="sleeping_time">
                                <ng-container
                                    *ngFor="let control of getSleepArray().controls; index as i; last as last">
                                    <div class="row" [formGroupName]="i">
                                        <div class="col-lg-4 col-sm-6 col-12 d-flex align-items-center">

                                            <div class="form-check pl-0 checkbox-list px-0">
                                                <label class="checkbox checkbox-outline px-0">
                                                    <!-- <input type="checkbox" value="With_Mother"
                                                        formControlName="sleeping_mother"
                                                        (change)="handleInput($event, 'sleeping')" /> -->
                                                    <span></span>
                                                    With Mother
                                                </label>
                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Time</label>
                                                <div class="card flex justify-content-center">
                                                    <p-calendar placeholder="Time"
                                                        formControlName="sleeping_mother_time" [timeOnly]="true"
                                                        [hourFormat]="12" inputId="time"
                                                        [class.is-invalid]="(savedData.submitted &&
                                                        oneToThreeYear.get('sleeping_mother_time')?.invalid)
                                                        ||
                                                        oneToThreeYear.get('sleeping_mother_time')?.invalid
                                                        &&
                                                        oneToThreeYear.get('sleeping_mother_time').touched"></p-calendar>

                                                    <div class="invalid-feedback">Time is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto d-flex mb-3 align-items-end">
                                            <p-button type="button" icon="bx bx-plus"
                                                (click)="addsleeptimeObject('add', i)" iconPos="right"
                                                class=" secondary-btn mr-2"></p-button>
                                            <div class="remove">
                                                <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                    icon="bx bx-minus" (click)="addsleeptimeObject('delete', i)"
                                                    iconPos="right">
                                                </p-button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>

                        <!--With Nanny -->
                        <div class="row">
                            <div class="row" formArrayName="nanny_time">
                                <ng-container
                                    *ngFor="let control of getSleepnannyArray().controls; index as i; last as last">
                                    <div class="row" [formGroupName]="i">
                                        <div class="col-lg-4 col-sm-6 col-12 d-flex align-items-center">
                                            <div class="form-check pl-0 checkbox-list px-0">
                                                <label class="checkbox checkbox-outline px-0">
                                                    <!-- <input type="checkbox" value="With_Nanny"
                                                        formControlName="sleeping_nanny"
                                                        (change)="handleInput($event, 'sleeping')" /> -->
                                                    <span></span>
                                                    With Nurse
                                                </label>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Time</label>
                                                <div class="card flex justify-content-center">
                                                    <p-calendar placeholder="Time" [timeOnly]="true" [hourFormat]="12"
                                                        formControlName="sleeping_nanny_time" inputId="time"
                                                        [class.is-invalid]="(savedData.submitted &&
                                                        oneToThreeYear.get('sleeping_nanny_time')?.invalid)
                                                        ||
                                                        oneToThreeYear.get('sleeping_nanny_time')?.invalid
                                                        &&
                                                        oneToThreeYear.get('sleeping_nanny_time').touched"></p-calendar>

                                                    <div class="invalid-feedback">Time is required</div>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-auto d-flex mb-3 align-items-end">
                                            <p-button type="button" icon="bx bx-plus" (click)="addnannyObject('add', i)"
                                                iconPos="right" class=" secondary-btn mr-2"></p-button>
                                            <div class="remove">
                                                <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                    icon="bx bx-minus" (click)="addnannyObject('delete', i)"
                                                    iconPos="right">
                                                </p-button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </p-accordionTab>
                <!--Temprature Monitoring-->
                <!-- <div class="add-listings-box"> -->
                <p-accordionTab header="Vitamins">
                    <div class="add-listings-box">
                        <!-- <div class="row">
                            <label class="subHeading mb-3">Temperature Monitoring( In hours)</label>
                            <div class="col-md">
                              <div class="form-group">
                                <div class="form-check checkbox-list p-0">
                                  <p-dropdown [options]="frequencyList" formControlName="temperature_monitoring_frequency"
                                    optionLabel="frequency" [filter]="true" filterBy="value" placeholder="Frequency"
                                    [resetFilterOnHide]="true" optionLabel="name" optionValue="value"> -->
                        <!-- <ng-template pTemplate="selectedItem">
                                      <div class="flex align-items-center gap-2" *ngIf="selectedTempMonitoringFrequency">
                                        <div>{{ selectedTempMonitoringFrequency.frequency }}</div>
                                      </div>
                                    </ng-template>
                                    <ng-template let-frequency pTemplate="item">
                                      <div class="flex align-items-center gap-2">
                                        <div>{{ frequency.frequency }}</div>
                                      </div>
                                    </ng-template> -->
                        <!-- </p-dropdown>
                                </div>
                              </div>
                            </div>
                          </div> -->
                        <!-- Temperature Section End-->

                        <!-- Vitamins & Medications Start-->
                        <!-- Kindly ADD multiple selection of this Section-->
                        <div class="row">
                            <label class="subHeading mb-3">Vitamins/Medications</label>
                            <div formArrayName="vitamins_medications_json">
                                <ng-container
                                    *ngFor="let control of getVitaminFormArray().controls; index as i; last as last">
                                    <div class="row" [formGroupName]="i">
                                        <div class="col-md">
                                            <div class="form-group">
                                                <label class="px-0">Medications</label>
                                                <div class="pl-0 checkbox-list">
                                                    <input type="text" formControlName="vitamin" class="form-control"
                                                        placeholder="Vitamins/ Medications" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md">
                                            <div class="form-group">
                                                <label class="px-0">Frequency</label>
                                                <div class="card flex justify-content-center">
                                                    <p-dropdown id="vitamin_frequency" [options]="frequencyList"
                                                        filterBy="value" [resetFilterOnHide]="true" [filter]="true"
                                                        formControlName="frequency" optionLabel="name"
                                                        optionValue="value" (onChange)="addmedicinefield(i)"
                                                        placeholder="Select Frequency">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="ismedicine" class="add-listings-box pt-3 mb-3 col-lg-12">
                                            <div class="row" formArrayName="medicine_time">
                                                <div *ngFor="let control of getmedicinearray(i).controls; index as j"
                                                    class="col-md-4">
                                                    <div class="" [formGroupName]="j">
                                                        <div class="row">
                                                            <!-- <div class="col-md-4">
                                                                <label
                                                                    class="checkbox checkbox-outline col-lg-5 col-sm-6 col-12 mt-sm-4 mt-3 px-0">
                                                                    <input type="checkbox" value="withvitamin"
                                                                        name="checkboxes"
                                                                        formControlName="vitamin_checkbox" />
                                                                    Done?
                                                                </label>
                                                            </div> -->
                                                            <!-- <div class="col-md-4"> -->
                                                            <div class="form-group">
                                                                <label class="px-0">Time</label>
                                                                <p-calendar placeholder="Time" formControlName="time"
                                                                    [timeOnly]="true" [hourFormat]="12"
                                                                    inputId="time"></p-calendar>
                                                            </div>
                                                            <!-- </div> -->
                                                            <!-- <div class="col-md col-sm-6 col-12">
                                                                <div class="form-group">
                                                                    <label class="px-0">Remark</label>
                                                                    <input type="text" name="name" class="form-control"
                                                                        placeholder="Remark"
                                                                        [formControl]="control.get('vitamin_remark')" />
                                                                </div>
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-auto d-flex mb-3  align-items-end">
                                            <p-button type="button" icon="bx bx-plus"
                                                (click)="addVitaminObject('add', i)" iconPos="right"
                                                class="secondary-btn"></p-button>
                                            <div class="remove">
                                                <p-button type="button" *ngIf="i!= 0" class="ms-2 secondary-btn"
                                                    icon="bx bx-minus" (click)="addVitaminObject('delete', i)"
                                                    iconPos="right"></p-button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>

                        <!-- <div class="row">
                            <label class="subHeading mb-3">Vaccinations</label>
                            <div formArrayName="vaccinations_json">
                              <ng-container *ngFor="let control of getVaccinationFormArray().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">
                                  <div class="col-md-4">
                                    <div class="form-group">
                                      <label class="px-0">Vaccinations</label>
                                      <div class="pl-0 checkbox-list">
                                        <input type="text" formControlName="vaccination" class="form-control"
                                          placeholder="Vaccinations" />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="form-group mb-0">
                                      <label class="px-0">Date of Administration</label>
                                      <div class="card flex justify-content-center">
                                        <p-calendar placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                                          formControlName="date_administration" [showIcon]="true"></p-calendar>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-4 d-flex justify-content-end align-items-end mt-sm-0 mt-3">
                                    <div class="form-group mb-0">
                                      <div class="d-flex justify-content-end">
                                        <p-button type="button" icon="bx bx-plus" (click)="addVaccinationObject('add', i)"
                                          iconPos="right" class="secondary-btn  "></p-button>
                                        <div class="remove">
                                          <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2" icon="bx bx-minus"
                                            (click)="addVaccinationObject('delete', i)" iconPos="right"></p-button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </div> -->
                    </div>
                </p-accordionTab>
                <!-- Playing with child outside -->
                <p-accordionTab header="Activities">
                    <div class="add-listings-box">
                        <label class="subHeading py-3">Playing with child outside?:</label>
                        <div formArrayName="outside_child">
                            <ng-container *ngFor="let control of getPlayFormArray().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <div class="d-flex">
                                                <p class="me-2">
                                                    <input type="radio" id="play-yes_{{i}}"
                                                        (change)="handleInput($event, 'playing')"
                                                        formControlName="play_child_outside" value="1">
                                                    <label for="play-yes_{{i}}" class="mb-0 mx-sm-2 mx-0">Yes</label>
                                                </p>
                                                <p class="d-flex">
                                                    <input type="radio" id="play-no_{{i}}"
                                                        (change)="handleInput($event, 'playing')"
                                                        formControlName="play_child_outside" value="0">
                                                    <label for="play-no_{{i}}" class="mb-0 mx-sm-2 mx-0">No</label>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg">
                                        <div class="form-group">
                                            <!-- <label class="p-0">Special Instructions:</label> -->
                                            <textarea class="form-control" id="kt_autosize_1" rows="2"
                                                [readonly]="playWith"
                                                formControlName="play_child_outside_special_Instructions"
                                                placeholder="Special Instructions"></textarea>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <!-- <div class="col-lg-3 col-sm-6 col-12">
                                            <label class="checkbox checkbox-outline px-0  mt-md-2">
                                                <input type="checkbox" value="true" name="checkboxes"
                                                    formControlName="play_child_outside_done" />
                                                <span></span>
                                                Done?
                                            </label>
                                        </div> -->
                                        <div class="col-lg-3 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Time</label>
                                                <p-calendar placeholder="Time" formControlName="play_child_outside_time"
                                                    [disabled]="playWith" [timeOnly]="true" inputId="time"
                                                    [hourFormat]="12"></p-calendar>
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-3 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Remark</label>
                                                <input type="text" name="name" class="form-control" placeholder="Remark"
                                                    [formControl]="control.get('play_child_outside_remark')" />
                                            </div>
                                        </div> -->
                                        <div class="col-lg-auto d-flex align-items-center">
                                            <p-button type="button" icon="bx bx-plus" (click)="addPlayObject('add', i)"
                                                iconPos="right" class="secondary-btn mr-2"></p-button>
                                            <div class="remove">
                                                <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                    icon="bx bx-minus" (click)="addPlayObject('delete', i)"
                                                    iconPos="right"></p-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </ng-container>
                        </div>
                        <!-- Escort child outside -->
                        <label class="subHeading py-3">Escort child outside?</label>
                        <div formArrayName="escort_child">
                            <ng-container
                                *ngFor="let control of getescortFormArray().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">
                                    <div class="col-lg-4 ">
                                        <div class="form-group">
                                            <div class="d-flex">
                                                <p class="me-2">
                                                    <input type="radio" id="escort-yes{{i}}"
                                                        (change)="handleInput($event, 'escort')"
                                                        formControlName="escort_child_outside" value="1">
                                                    <label for="escort-yes{{i}}" class="mb-0 mx-sm-2 mx-0">Yes</label>
                                                </p>
                                                <p class="d-flex">
                                                    <input type="radio" id="escort-no{{i}}"
                                                        (change)="handleInput($event, 'escort')"
                                                        formControlName="escort_child_outside" value="0">
                                                    <label for="escort-no{{i}}" class="mb-0 mx-sm-2 mx-0">No</label>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg">
                                        <div class="form-group">
                                            <!-- <label class="p-0">Special Instructions:</label> -->
                                            <textarea name="escort_child_outside_special_Instructions"
                                                class="form-control" id="kt_autosize_1" rows="2" [readonly]="escort"
                                                formControlName="escort_child_outside_special_Instructions"
                                                placeholder="Special Instructions"></textarea>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <!-- <div class="col-lg-3 col-sm-6 col-12">
                                            <label class="checkbox checkbox-outline px-0  mt-md-2">
                                                <input type="checkbox" value="true" name="checkboxes"
                                                    formControlName="escort_child_outside_done" />
                                                <span></span>
                                                Done?
                                            </label>
                                        </div> -->
                                        <div class="col-lg-3 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Time</label>
                                                <p-calendar placeholder="Time"
                                                    formControlName="escort_child_outside_time" [timeOnly]="true"
                                                    [disabled]="escort" inputId="time" [hourFormat]="12"></p-calendar>
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-3 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Remark</label>
                                                <input type="text" name="name" class="form-control" placeholder="Remark"
                                                    [formControl]="control.get('escort_child_outside_remark')" />
                                            </div>
                                        </div> -->


                                        <div class="col-lg-auto d-flex align-items-center">
                                            <p-button type="button" icon="bx bx-plus"
                                                (click)="addEscortObject('add', i)" iconPos="right"
                                                class=" secondary-btn mr-2"></p-button>
                                            <div class="remove">
                                                <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                    icon="bx bx-minus" (click)="addEscortObject('delete', i)"
                                                    iconPos="right"></p-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <label class="subHeading py-3">Enter activities name here</label>
                        <div formArrayName="activities_name">
                            <ng-container
                                *ngFor="let control of getActiveFormArray().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <div class="d-flex">
                                                <p class="me-2">
                                                    <input type="radio" id="active-yes{{i}}"
                                                        formControlName="other_activity" value="1"
                                                        (change)="handleInput($event, 'activities')">
                                                    <label for="active-yes{{i}}" class="mb-0 mx-sm-2 mx-0">Yes</label>
                                                </p>
                                                <p class="d-flex">
                                                    <input type="radio" id="active-no{{i}}"
                                                        formControlName="other_activity" value="0"
                                                        (change)="handleInput($event, 'activities')">
                                                    <label for="active-no{{i}}" class="mb-0 mx-sm-2 mx-0">No</label>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg">
                                        <div class="form-group">
                                            <!-- <label class="p-0">Activity Name:</label> -->
                                            <textarea name="activities_child_outside_special_Instructions"
                                                class="form-control" id="kt_autosize_1" rows="2"
                                                [readonly]="activities_child"
                                                formControlName="activities_child_outside_special_Instructions"
                                                placeholder="Activity Name"></textarea>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <!-- <div class="col-lg-3 col-sm-6 col-12">
                                            <label class="checkbox checkbox-outline px-0  mt-md-2">
                                                <input type="checkbox" value="true" name="checkboxes"
                                                    formControlName="other_child_outside_done" />
                                                <span></span>
                                                Done?
                                            </label>
                                        </div> -->
                                        <div class="col-lg-3 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Time</label>
                                                <p-calendar placeholder="Time"
                                                    formControlName="other_child_outside_time" [timeOnly]="true"
                                                    [disabled]="activities_child" inputId="time"
                                                    [hourFormat]="12"></p-calendar>
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-3 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Remark</label>
                                                <input type="text" name="name" class="form-control" placeholder="Remark"
                                                    [formControl]="control.get('other_child_outside_remark')" />
                                            </div>
                                        </div> -->
                                        <div class="col-lg-auto d-flex align-items-center">
                                            <p-button type="button" icon="bx bx-plus"
                                                (click)="addActiveObject('add', i)" iconPos="right"
                                                class="secondary-btn mr-2"></p-button>
                                            <div class="remove">
                                                <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                    icon="bx bx-minus" (click)="addActiveObject('delete', i)"
                                                    iconPos="right"></p-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                </p-accordionTab>
                <!-- </div>


             <div class="add-listings-box"> -->
                <p-accordionTab header="Remarks">
                    <div class="add-listings-box">
                        <label class="subHeading mb-3">Remarks</label>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <div class="pl-0 checkbox-list">
                                        <textarea class="form-control" id="remarks" rows="5"
                                            formControlName="remarks"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </p-accordionTab>

            </p-accordion>
        </ng-container>
        <div class="add-listings-btn text-end">
            <button type="submit" [disabled]="!flag" *ngIf="checkPermission(9)">Submit</button>
        </div>
    </form>
    <app-copyrights></app-copyrights>
</div>