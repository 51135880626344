import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormArray,
  FormGroup,
  FormControlDirective,
  Validators,
} from '@angular/forms';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { PtServiceService } from 'src/app/services/pt-service.service';
@Component({
  selector: 'app-daily-notes',
  templateUrl: './daily-notes.component.html',
  styleUrls: ['./daily-notes.component.scss'],
  providers: [MessageService]
})
export class DailyNotesComponent implements OnInit {
  breadcrumb = [
    {
      title: 'PT Daily Notes',
      subTitle: 'Dashboard',
    },
  ];
  duration =[];
  currentDate = new Date();
  selectedDate: Date; // Initialize with today's date
  Score: boolean = true;
  pain_tool_specify: boolean = true;
  exercise: boolean = true;
  noGoal: boolean = true;
  goalAchieve: boolean = true;
  discription:boolean =true;
  Exercise: boolean = true;
  stimulation: boolean = true;
  tens: boolean = true;
  therapy: boolean = true;
  tapping: boolean = true;
  vibrator: boolean = true;
  ultrasound: boolean = true;
  hotpacks: boolean = true;
  scoreList: any = [
    { name: 1, value: 1 },
    { name: 2, value: 2 },
    { name: 3, value: 3 },
    { name: 4, value: 4 },
    { name: 5, value: 5 },
    { name: 6, value: 6 },
    { name: 7, value: 7 },
    { name: 8, value: 8 },
    { name: 9, value: 9 },
    { name: 10, value: 10 },
  ];
  durationList: any = [
    { name: 1, value: 1 },
    { name: 2, value: 2 },
    { name: 3, value: 3 },
    { name: 4, value: 4 },
    { name: 5, value: 5 },
    { name: 6, value: 6 },
    { name: 7, value: 7 },
    { name: 8, value: 8 },
    { name: 9, value: 9 },
    { name: 10, value: 10 },
  ];

  ptDailyNotes = this.FB.group({
    pain_tool_score: new FormControl(null,[Validators.required]),
    selectedDate: new FormControl('', Validators.required),
    pain_discription: new FormControl(null),
    // yes_pain_discription: new FormControl(''),
    pain_tool_specify: new FormControl({
      value: null,
      disabled: true,
    }),
    blood_pressure: new FormControl(null),
    temperature: new FormControl(null),
    heart_rate: new FormControl(null),
    spo2: new FormControl(null),
    rr: new FormControl(null),
    post_blood_pressure: new FormControl(null),
    post_temperature: new FormControl(null),
    post_heart_rate: new FormControl(null),
    post_spo2: new FormControl(null),
    post_rr: new FormControl(null),
    modalities_session: new FormControl(null),
    Exercise: new FormControl(null),
    Exercise_Duretion: new FormControl(null),
    exercise_details: new FormControl(null),
    exercise_checkbox: new FormControl(''),
    tens_checkbox: new FormControl(''),
    tens_duration: new FormControl(null),
    tens_details: new FormControl(null),
    ultrasound_checkbox: new FormControl(''),
    ultrasound_duration: new FormControl(null),
    ultrasound_details: new FormControl(null),
    hot_packs_checkbox: new FormControl(''),
    hot_packs_duration: new FormControl(null),
    hot_packs_details: new FormControl(null),
    stimulation_checkbox: new FormControl(''),
    stimulation_duration: new FormControl(null),
    stimulation_details: new FormControl(null),
    vibrator_checkbox: new FormControl(''),
    vibrator_duration: new FormControl(null),
    vibrator_details: new FormControl(null),
    tapping_checkbox: new FormControl(''),
    tapping_duration: new FormControl(null),
    tapping_details: new FormControl(null),
    chest_therapy_checkbox: new FormControl(''),
    chest_therapy_duration: new FormControl(null),
    chest_therapy_details: new FormControl(null),
    session_technique_others_checkbox: new FormControl(''),
    session_technique_others_details: new FormControl(null),
    session_goal: new FormControl(''),
    exercise_duration: new FormControl(null),
    comments: new FormControl(null),
    therapied_part: new FormControl(null),
    session_reason: new FormControl({
      value: null,
      disabled: true,
    }),
   
  });
  onKeyPress(event: KeyboardEvent): void {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // Invalid character, prevent input
      event.preventDefault();
    }
  }
  keyPress(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  constructor(
    private FB: FormBuilder,
    private daily_service: PtServiceService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    for(let i=1; i<=24; i++){
      this.duration.push({duration: i});
    }
    console.log("duration: " + this.duration);
    const ultrasoundDurationValue = this.ptDailyNotes.get('ultrasound_duration').value;
  console.log('ultrasound_duration value:', ultrasoundDurationValue);
  this.ptDailyNotes.controls.exercise_duration.disable();
  this.ptDailyNotes.controls.exercise_details.disable();
  this.ptDailyNotes.controls.tens_duration.disable();
  this.ptDailyNotes.controls.tens_details.disable();
  this.ptDailyNotes.controls.stimulation_details.disable();
  this.ptDailyNotes.controls.stimulation_duration.disable();
  this.ptDailyNotes.controls.chest_therapy_details.disable();
  this.ptDailyNotes.controls.chest_therapy_duration.disable();
  this.ptDailyNotes.controls.hot_packs_duration.disable();
  this.ptDailyNotes.controls.hot_packs_details.disable();
  this.ptDailyNotes.controls.tapping_details.disable();
  this.ptDailyNotes.controls.tapping_duration.disable();
  this.ptDailyNotes.controls.vibrator_duration.disable();
  this.ptDailyNotes.controls.vibrator_details.disable();
  this.ptDailyNotes.controls.ultrasound_duration.disable();
  this.ptDailyNotes.controls.ultrasound_details.disable();
  this.ptDailyNotes.controls.session_technique_others_details.disable();
  }

  /*----------------------------------------------------------------------------------------------
                              handleinput 
  ------------------------------------------------------------------------------------------------*/

  handleInput(event: any, value: string) {
    if (value == 'goalAchieve') {
    // }
    if (event.target.value == '1') {
      console.log('called yes');
      this.ptDailyNotes.controls.session_reason.enable();
    } else {
      console.log('called no');
      this.ptDailyNotes.controls.session_reason.disable();
      this.ptDailyNotes.controls.session_reason.setValue(
        null
      );
    }
    if(event.target.checked){
      this.ptDailyNotes.controls['session_reason'].setValidators([Validators.required]);
    }
    else {
      this.ptDailyNotes.controls['session_reason'].clearValidators();
    }
    this.ptDailyNotes.controls['session_reason'].updateValueAndValidity();
  }
    if (value == 'Discription') {
      if (event.target.value == 'Yes') {
        console.log('called yes');
        this.ptDailyNotes.controls.pain_tool_specify.enable();
      } else {
        console.log('called no');
        this.ptDailyNotes.controls.pain_tool_specify.disable();
        this.ptDailyNotes.controls.pain_tool_specify.setValue(
          null
        );
      }
      if(event.target.checked){
        this.ptDailyNotes.controls['pain_tool_specify'].setValidators([Validators.required]);
      }
      else {
        this.ptDailyNotes.controls['pain_tool_specify'].clearValidators();
      }
      this.ptDailyNotes.controls['pain_tool_specify'].updateValueAndValidity();
    }
   else if (value == 'exercise') {
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.ptDailyNotes.controls.exercise_duration.enable();
        this.ptDailyNotes.controls.exercise_details.enable();
      }
        else {
          this.ptDailyNotes.controls.exercise_duration.disable();
          this.ptDailyNotes.controls.exercise_details.disable();
        }
        if(event.target.checked){
          this.ptDailyNotes.controls['exercise_duration'].setValidators([Validators.required]);
        }
        else {
          this.ptDailyNotes.controls['exercise_duration'].clearValidators();
          this.ptDailyNotes.controls['exercise_duration'].setValue(null);
        }
        this.ptDailyNotes.controls['exercise_duration'].updateValueAndValidity();
        if(event.target.checked){
          this.ptDailyNotes.controls['exercise_details'].setValidators([Validators.required]);
        }
        else {
          this.ptDailyNotes.controls['exercise_details'].clearValidators();
          this.ptDailyNotes.controls['exercise_details'].setValue(null);
        }
        this.ptDailyNotes.controls['exercise_details'].updateValueAndValidity();

      }
    

  
    else if (value == 'ten') {
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.ptDailyNotes.controls.tens_duration.enable();
        this.ptDailyNotes.controls.tens_details.enable();
      }
        else {
          this.ptDailyNotes.controls.tens_duration.disable();
          this.ptDailyNotes.controls.tens_details.disable();
        }
        if(event.target.checked){
          this.ptDailyNotes.controls['tens_duration'].setValidators([Validators.required]);
        }
        else {
          this.ptDailyNotes.controls['tens_details'].clearValidators();
          this.ptDailyNotes.controls['tens_details'].setValue(null);
        }
        this.ptDailyNotes.controls['tens_duration'].updateValueAndValidity();
        if(event.target.checked){
          this.ptDailyNotes.controls['tens_details'].setValidators([Validators.required]);
        }
        else {
          this.ptDailyNotes.controls['tens_duration'].clearValidators();
          this.ptDailyNotes.controls['tens_duration'].setValue(null);
        }
        this.ptDailyNotes.controls['tens_details'].updateValueAndValidity();

    } else if (value == 'ultrasound') {
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.ptDailyNotes.controls.ultrasound_duration.enable();
        this.ptDailyNotes.controls.ultrasound_details.enable();
      }
        else {
          this.ptDailyNotes.controls.ultrasound_details.disable();
          this.ptDailyNotes.controls.ultrasound_duration.disable();
        }
        if(event.target.checked){
          this.ptDailyNotes.controls['ultrasound_duration'].setValidators([Validators.required]);
        }
        else {
          this.ptDailyNotes.controls['ultrasound_duration'].clearValidators();
          this.ptDailyNotes.controls['ultrasound_duration'].setValue(null);
        }
        this.ptDailyNotes.controls['ultrasound_duration'].updateValueAndValidity();
        if(event.target.checked){
          this.ptDailyNotes.controls['ultrasound_details'].setValidators([Validators.required]);
        }
        else {
          this.ptDailyNotes.controls['ultrasound_details'].clearValidators();
          this.ptDailyNotes.controls['ultrasound_details'].setValue(null);
        }
        this.ptDailyNotes.controls['ultrasound_details'].updateValueAndValidity();

    } else if (value == 'packs') {
      // if (event.target.value == 'hotpacks') {
      //   console.log('hotpacks');
      //   this.hotpacks = !this.hotpacks;
      // }
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.ptDailyNotes.controls.hot_packs_details.enable();
        this.ptDailyNotes.controls.hot_packs_duration.enable();
      }
        else {
          this.ptDailyNotes.controls.hot_packs_details.disable();
          this.ptDailyNotes.controls.hot_packs_duration.disable();
        }
        if(event.target.checked){
          this.ptDailyNotes.controls['hot_packs_duration'].setValidators([Validators.required]);
        }
        else {
          this.ptDailyNotes.controls['hot_packs_duration'].clearValidators();
          this.ptDailyNotes.controls['hot_packs_duration'].setValue(null);
        }
        this.ptDailyNotes.controls['hot_packs_duration'].updateValueAndValidity();
        if(event.target.checked){
          this.ptDailyNotes.controls['hot_packs_details'].setValidators([Validators.required]);
        }
        else {
          this.ptDailyNotes.controls['hot_packs_details'].clearValidators();
          this.ptDailyNotes.controls['hot_packs_details'].setValue(null);
        }
        this.ptDailyNotes.controls['hot_packs_details'].updateValueAndValidity();
    } else if (value == 'stimulation') {
      // if (event.target.value == 'stimulation') {
      //   console.log('stimulation');
      //   this.stimulation = !this.stimulation;
      // }
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.ptDailyNotes.controls.stimulation_details.enable();
        this.ptDailyNotes.controls.stimulation_duration.enable();
      }
        else {
          this.ptDailyNotes.controls.stimulation_duration.disable();
          this.ptDailyNotes.controls.stimulation_details.disable();
        }
        if(event.target.checked){
          this.ptDailyNotes.controls['stimulation_duration'].setValidators([Validators.required]);
        }
        else {
          this.ptDailyNotes.controls['stimulation_duration'].clearValidators();
          this.ptDailyNotes.controls['stimulation_duration'].setValue(null);
        }
        this.ptDailyNotes.controls['stimulation_duration'].updateValueAndValidity();
        if(event.target.checked){
          this.ptDailyNotes.controls['stimulation_details'].setValidators([Validators.required]);
        }
        else {
          this.ptDailyNotes.controls['stimulation_details'].clearValidators();
          this.ptDailyNotes.controls['stimulation_details'].setValue(null);
        }
        this.ptDailyNotes.controls['stimulation_details'].updateValueAndValidity();
    } else if (value == 'vibrator') {
      // if (event.target.value == 'vibrator') {
      //   console.log('vibrator');
      //   this.vibrator = !this.vibrator;
      // }
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.ptDailyNotes.controls.vibrator_duration.enable();
        this.ptDailyNotes.controls.vibrator_details.enable();
      }
        else {
          this.ptDailyNotes.controls.vibrator_details.disable();
          this.ptDailyNotes.controls.vibrator_duration.disable();
        }
        if(event.target.checked){
          this.ptDailyNotes.controls['vibrator_duration'].setValidators([Validators.required]);
        }
        else {
          this.ptDailyNotes.controls['vibrator_duration'].clearValidators();
          this.ptDailyNotes.controls['vibrator_duration'].setValue(null);
        }
        this.ptDailyNotes.controls['vibrator_duration'].updateValueAndValidity();
        if(event.target.checked){
          this.ptDailyNotes.controls['vibrator_details'].setValidators([Validators.required]);
        }
        else {
          this.ptDailyNotes.controls['vibrator_details'].clearValidators();
          this.ptDailyNotes.controls['vibrator_details'].setValue(null);
        }
        this.ptDailyNotes.controls['vibrator_details'].updateValueAndValidity();
    } else if (value == 'tapping') {
      // if (event.target.value == 'tapping') {
      //   console.log('tapping');
      //   this.tapping = !this.tapping;
      // }
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.ptDailyNotes.controls.tapping_duration.enable();
        this.ptDailyNotes.controls.tapping_details.enable();
      }
        else {
          this.ptDailyNotes.controls.tapping_duration.disable();
          this.ptDailyNotes.controls.tapping_details.disable();
        }
        if(event.target.checked){
          this.ptDailyNotes.controls['tapping_duration'].setValidators([Validators.required]);
        }
        else {
          this.ptDailyNotes.controls['tapping_duration'].clearValidators();
          this.ptDailyNotes.controls['tapping_duration'].setValue(null);

        }
        this.ptDailyNotes.controls['tapping_duration'].updateValueAndValidity();
        if(event.target.checked){
          this.ptDailyNotes.controls['tapping_details'].setValidators([Validators.required]);
        }
        else {
          this.ptDailyNotes.controls['tapping_details'].clearValidators();
          this.ptDailyNotes.controls['tapping_details'].setValue(null);
        }
        this.ptDailyNotes.controls['tapping_details'].updateValueAndValidity();
    } else if (value == 'chest') {
      // if (event.target.value == 'therapy') {
      //   console.log('therapy');
      //   this.therapy = !this.therapy;
      // }
      if (event.target.checked) {
        console.log('exercise');
        //  this.exercise = !this.exercise;
        this.ptDailyNotes.controls.chest_therapy_details.enable();
        this.ptDailyNotes.controls.chest_therapy_duration.enable();
      }
        else {
          this.ptDailyNotes.controls.chest_therapy_duration.disable();
          this.ptDailyNotes.controls.chest_therapy_details.disable();
        }
        if(event.target.checked){
          this.ptDailyNotes.controls['chest_therapy_duration'].setValidators([Validators.required]);
        }
        else {
          this.ptDailyNotes.controls['chest_therapy_duration'].clearValidators();
          this.ptDailyNotes.controls['chest_therapy_duration'].setValue(null);
        }
        this.ptDailyNotes.controls['chest_therapy_duration'].updateValueAndValidity();
        if(event.target.checked){
          this.ptDailyNotes.controls['chest_therapy_details'].setValidators([Validators.required]);
        }
        else {
          this.ptDailyNotes.controls['chest_therapy_details'].clearValidators();
          this.ptDailyNotes.controls['chest_therapy_details'].setValue(null);
        }
        this.ptDailyNotes.controls['chest_therapy_details'].updateValueAndValidity();
    }
    else if (value=='other'){

      if(event.target.checked){
      this.ptDailyNotes.controls.session_technique_others_details.enable();
      }
    else{
      this.ptDailyNotes.controls.session_technique_others_details.disable();
    }
    if(event.target.checked){
      this.ptDailyNotes.controls['session_technique_others_details'].setValidators([Validators.required]);
    }
    else {
      this.ptDailyNotes.controls['session_technique_others_details'].clearValidators();
      this.ptDailyNotes.controls['session_technique_others_details'].setValue(null);
    }
    this.ptDailyNotes.controls['session_technique_others_details'].updateValueAndValidity();
  }
}

  /*----------------------------------------------------------------------------------------------
                                submitform
    ------------------------------------------------------------------------------------------------*/

  submitForm(fourMonthAbove) {
    console.log(this.ptDailyNotes.controls.exercise_duration.value)
    
    window.scroll(0, 0);
    if(fourMonthAbove.valid){
      let date = moment(this.ptDailyNotes.value.selectedDate).format('YYYY/MM/DD')
      let value = JSON.stringify(this.ptDailyNotes.value);
      let dataObject = {
        date: date,
        daily_notes_json: value,
      };
      console.log(this.ptDailyNotes.value)
      delete value['date'];
      this.daily_service.dailyNotesData(dataObject).then((res: any) => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: res?.message });
      });
      this.ptDailyNotes.reset();
    }
    else {
      this.messageService.add({
        severity: 'error', summary: 'Error', detail: 'Please Fill All the Details!',
      });
    }
  }
}
