import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonApiService } from 'src/app/services/common-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';


@Component({
  selector: 'app-first-screen',
  templateUrl: './first-screen.component.html',
  styleUrls: ['./first-screen.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class FirstScreenComponent implements OnInit {
  getToken: any;
  getRole: any;
  getname: any;
  carePlans: any;
  breadcrumb = [
    {
      title: 'Patient View',
      subTitle: 'Dashboard',
    },
  ];

  constructor(private common_api_service: CommonApiService, private spinner: NgxSpinnerService, private route: Router
  ) { }

  ngOnInit(): void {
    // this.spinner.show()
    this.common_api_service.getNannyCarePlans().toPromise().then((res: any) => {
      // this.spinner.hide()

      this.carePlans = res?.data;
    })
    this.getToken = localStorage.getItem('token');
    this.getRole = localStorage.getItem('role');

    this.getname = localStorage.getItem('name');

  }
  logout(){
    localStorage.clear();
    // window.location.reload();
    this.route.navigate(['/']);
  }
}
