import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import {CommonApiService} from '../services/common-api.service';
import { environment } from 'src/environments/environment';
import { ConfirmationService, MessageService } from 'primeng/api';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  constructor(private router: Router,
    private commanServices: CommonApiService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,) {}
  tokenData: any;
  // intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
  //   debugger
  //   return next.handle(request).pipe(
  //     catchError((error: HttpErrorResponse) => {
  //       this.tokenData = localStorage.getItem('token');
  //         var token: any = JSON.parse(localStorage.getItem('access_token'));
  //         const isLoggedIn = token ? true : false;
  //         const isApiUrl =
  //           request.url.startsWith(environment.api_base_url) ||
  //           request.url.startsWith('assets');
  //         if (isLoggedIn && isApiUrl) {
  //           // debugger;
  //           // if (
  //           //   this.activatedRoute.snapshot['_routerState'].url !=
  //           //     '/portal/first-screen' &&
  //           //   this.activatedRoute.snapshot['_routerState'].url.includes('/portal') &&
  //           //   (!this.activatedRoute.snapshot['_routerState'].url.includes('/member-data-update'))
  //           // ) {
  //           //   // this.spinner.show();
  //           // }
  //           request = request.clone({
  //             setHeaders: { Authorization: `Bearer ${token.token}` },
  //           });
  //         }
  //           else if (error.status === 401) {
  //         // Handle unauthorized access or token expiration
  //         // For example, navigate to login page
          
  //         // this.router.navigate(['/login']);
  //         if (
  //           !request.url.includes('login') &&
  //           !request.url.includes('register') &&
  //           error.error.message == 'You could not be authorized'
  //         ) {
  //           if (
  //             // this.activatedRoute.snapshot['_routerState'].url ==
  //             //   '/user-panel/dashboard' &&
  //             error.error.message == 'You could not be authorized'
  //           ) {
  //             this.commanServices.authModalState.next(true);
  //             environment.authModalState = true;
  //           }
  //         }
  //         else if (
  //           !request.url.includes('login') &&
  //           !request.url.includes('register') &&
  //           error.error.message != 'You could not be authorized'
  //         ) {
  //           let message: string;
  //           if (error.error.error) {
  //             message = error.error.error;
  //           }
  //           this.messageService.add({
  //             severity: 'error',
  //             detail: message,
  //           });
  //         }
  //       }
  //       // Pass the error to the caller of the request
  //       return throwError(error);
  //     })
  //   )
  // }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.includes('login') || request.url.includes('register')) {
      // if (this.activeRequest == 0) {
      //   this.spinner.show();
      // }
      // return next.handle(request);
    }

    var token: any = JSON.parse(localStorage.getItem('access_token'));
    const isLoggedIn = token ? true : false;
    const isApiUrl =
      request.url.startsWith(environment.api_base_url) ||
      request.url.startsWith('assets');
    if (isLoggedIn && isApiUrl) {
      // debugger;
      // if (
      //   this.activatedRoute.snapshot['_routerState'].url !=
      //     '/portal/first-screen' &&
      //   this.activatedRoute.snapshot['_routerState'].url.includes('/portal')
      // ) {
      //   this.spinner.show();
      // }
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${token.token}` },
      });
    }
    // this.activeRequest++;

    return next.handle(request).pipe(
      finalize(() => {
        // this.stopLoader(request);
      }),
      catchError((err) => {
        // debugger;
        if (err.status === 401) {
          this.tokenData = JSON.parse(localStorage.getItem('access_token'));
          // debugger;
          if (
            !request.url.includes('login') &&
            !request.url.includes('register') &&
            err.error.message == 'You could not be authorized.'
          ) {
            // Unauthenticated.
            // if (
            //   // this.activatedRoute.snapshot['_routerState'].url !=
            //   //   '/user-panel/dashboard' &&
            //   err.error.message == 'Unauthenticated.'
            // ) {
            //   return this.handle401Error(request, next);
            // } else

            if (
              // this.activatedRoute.snapshot['_routerState'].url ==
              //   '/user-panel/dashboard' &&
              err.error.message == 'You could not be authorized.'
            ) {
              this.commanServices.authModalState.next(true);
              environment.authModalState = true;
            }
            // if()
          } else if (
            !request.url.includes('login') &&
            !request.url.includes('register') &&
            err.error.message == 'jwt expired'
          ) {
            let message: string;
            if (err.error.error) {
              message = err.error.error;
            }
            this.messageService.add({
              severity: 'error',
              detail: message,
            });
            this.commanServices.authModalState.next(true);
            environment.authModalState = true;
          } else if (
            request.url.includes('login') ||
            request.url.includes('register')
          ) {
            let message: string;
           
            // this.spinner.hide();
            console.log(err, err.error.error);
            if (err.error.error) {
              message = err.error.error;
            } else if (err.error.message) {
              message = err.error.message;
            }

            this.messageService.add({
              severity: 'error',
              detail: message,
            });
          }
          // this.spinner.hide();
          return next.handle(request);
        }
        if (err.status === 400) {
          console.log(err);

          let message: any;
          if (err.error.error) {
            message = err.error.error;
          } else if (err.error.message) {
            if (err.error.message.invoice) {
              message = err.error.message.invoice[0];
            } else if (err.error.message.other_document) {
              message = err.error.message.other_document;
            } else if (err.error.message.interest_area) {
              message = err.error.message.interest_area[0];
            } else if (err.error.message.sequence) {
              message = err.error.message.sequence[0];
            } else if (err.error.message.slug) {
              message = err.error.message.slug[0];
            } else {
              message = err.error.message;
              console.log(message);
            }
          } else if (err.error.password) {
            message = err.error.password;
          } else if (err.error.email) {
            message = err.error.email;
          } else if (err.error.name) {
            message = err.error.name;
          } else if (err.error.profile2) {
            message = err.error.profile2;
          } else if (err.error.profile1) {
            message = err.error.profile1;
          } else if (err.error.logo) {
            message = err.error.logo;
          } else if (err?.error?.name1[0]) {
            message = err?.error?.name1[0];
          } else if (err?.error?.filter_name[0]) {
            message = err?.error?.filter_name[0];
          } else if (err.error.form_name[0]) {
            message = err.error.form_name[0];
          }
          // this.spinner.hide();
          this.messageService.add({
            severity: 'error',
            detail: message,
          });
        }
        if (err.status === 500) {
          if (err.error) {
            // err.error.message != 'Token could not be parsed from the request.'
            this.messageService.add({
              severity: 'error',
              detail: err.error.message || err.statusText,
            });
          }
        }
        if (err.status === 403) {
          if (err.error) {
            this.messageService.add({
              severity: 'error',
              detail: err.error.message || err.statusText,
            });
          }
        }
        if (err.status === 404) {
          if (err.error) {
            this.messageService.add({
              severity: 'error',
              detail: err.error.message || err.statusText,
            });
          }
        }
        if (err.status === 422) {
          if (err.error) {
            this.messageService.add({
              severity: 'error',
              detail: err.error.message || err.statusText,
            });
          }
        }
        const error = err?.error?.message || err?.statusText;
        // this.spinner.hide();
        return throwError(error);
      })
    );
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler){
    if(!this.isRefreshing){
      this.isRefreshing = true;
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.tokenData}`,
        },
      });
    //   return this.apiservice.refreshToken().pipe(
    //     switchMap((res) => {
    //       this.isRefreshing = false;
    //       this.newToken = res?.token;
    //       // this.tokenData = this.commonFunction.getLocalStorage();
    //       this.tokenData.access_token = this.newToken;
    //       localStorage.setItem('access_token', JSON.stringify(this.tokenData));
    //       this.isRefreshing = false;
    //       this.spinner.hide();
    //       request = request.clone({
    //         setHeaders: {
    //           Authorization: `Bearer ${this.tokenData.access_token}`,
    //         },
    //       });
    //       for (var i = 0; i < this.failedRequest.length; i++) {
    //         var newrequest = this.failedRequest[i].clone({
    //           setHeaders: {
    //             Authorization: `Bearer ${this.tokenData.access_token}`,
    //           },
    //         });
    //         next.handle(newrequest);
    //       }
    //       return next.handle(request);
    //     }),
    //     catchError((error) => {
    //       this.isRefreshing = false;

    //       return throwError(() => error);
    //     })
    //   );
    // }else {
    //   this.failedRequest.push(request);
    //   //return next.handle(request);
    // }
  }
  return next.handle(request)
}
}
