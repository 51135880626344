import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CommonApiService } from 'src/app/services/common-api.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { UtilityTsService } from 'src/app/services/utility.ts.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MaikaListService } from 'src/app/services/maika-list.service';
import { MasterService } from 'src/app/services/master.service';
import { empty } from 'rxjs';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-edit-zero-to-three-months',
  templateUrl: './edit-zero-to-three-months.component.html',
  styleUrls: ['./edit-zero-to-three-months.component.scss'],
  providers: [MessageService],
})
export class EditZeroToThreeMonthsComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Edit NURSE CARE PLAN 0-3 MONTHS',
      subtitle: 'Dashboard',
    },
  ];
  public flag: boolean = true;
  public withNanny: boolean = true;
  public withMother: boolean = true;
  public feedingFormula: boolean = true;
  public feedingBreast: boolean = true;
  public sterilizingAll: boolean = true;
  public sterilizingOne: boolean = true;
  public allergicTo: boolean = true;
  public spongeBath: boolean = true;
  public tubBath: boolean = true;
  public hideWipes: boolean = true;
  date: any;
  getToken: any;
  getRole: any;
  getname: any;
  selectedDate: Date = null; // Initialize with today's date
  month: any = '';
  years: any = '';
  days: any = '';
  babyGender: any;
  name: any;
  selectedGender: any;
  selectedNumber: any;
  ageList: any = [];
  babyDetails: any = [];
  frequency: any = [];
  tub_frequency: any = [];
  selectedDiaperFrequency: any;
  selectedVitaminsAndMedsFrequency: any;
  selectedTempMonitoringFrequency: any;
  selectedClothesFrequency: any;
  selectedBreastFeeding: any;
  selectedFormulaFeeding: any;
  selectedBathFrequency: any;
  selectedTubFrequency: any;
  selectedVitaminFrequency: any;
  isFormula: boolean = false;
  isBathing: boolean = false;
  isBathingtub: boolean = false;
  ismedicine: boolean = false;
  isBreast: boolean = false;
  isdiaper:boolean=false;
  isSettingValueProgrammatically = true;
  permissions: any = [];
  permissionData: any = [];
  planId: any;
  frequencyList: any = [
    { name: 1, value: 1 },
    { name: 2, value: 2 },
    { name: 3, value: 3 },
    { name: 4, value: 4 },
    { name: 5, value: 5 },
    { name: 6, value: 6 },
    { name: 7, value: 7 },
    { name: 8, value: 8 },
    { name: 9, value: 9 },
    { name: 10, value: 10 },
  ];
  FormulaValue: any = [
    { name: 'Yes', value: 'Yes' },
    { name: 'No', value: 'No' },
  ];

  zeroToThreeMonthsForm: FormGroup;
  isedit: boolean = false;
  control: any;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private messageService: MessageService,
    private common_service: CommonApiService,
    private utility_service: UtilityTsService,
    private spinner: NgxSpinnerService,
    private masterService: MasterService,
    private confirmationService: ConfirmationService,
  ) {
    this.getAllUserPermission();
    this.zeroToThreeMonthsForm = this.fb.group({
      baby_details_id: new FormControl(null, [Validators.required]),
      feeding_prepared_mother: new FormControl(null),
      feeding_prepared_other: new FormControl(null),
      feeding_prepared_nanny: new FormControl(null),
      feeding_type: new FormControl(null),
      feeding_breast: new FormControl(null),
      feeding_frequency_breast: new FormControl(null),
      feeding_breast_time: new FormControl(null),
      feeding_breast_time_end: new FormControl(null),
      formula_calculation: this.fb.array([]),
      bottle_sterilization: this.fb.array([]),
      feeding_time: new FormControl(null),
      sleeping_time: this.fb.array([]),
      nanny_time: this.fb.array([]),
      feeding_amount_per: new FormControl(null),
      feeding_frequency: new FormControl(null),
      breast_feeding_time: new FormControl(null),
      // feeding_frequency: new FormControl(null),
      // feeding_time: new FormControl({value: null, disabled: true}),
      // feeding_amount_per: new FormControl({value: null, disabled: true}),
      type_of_formula: new FormControl(null),
      bottle_sterilization_all: new FormControl(null),
      bottle_sterilization_onetime: new FormControl(null),
      solid_other_snack: new FormControl(null),
      // solid_other_snack_time: new FormControl(null),
      soild_other_snack_food_components: new FormControl(null),
      regular_meal_type: new FormControl(null),
      regular_meal_time: new FormControl(null),
      regular_meal_food_components: new FormControl(null),
      bottle_sterilization_all_time: new FormControl(null),
      bottle_sterilization_onetime_time: new FormControl(null),
      food_medicine_allergy: new FormControl(null),
      // food_medicine_allergy: new FormControl(null),
      food_medicine_allergy_specify_prod: new FormControl({
        value: null,
        disabled: true,
      }),
      bathing_sponge: new FormControl(null),
      bathing_frequency_sponge: new FormControl(null),
      diaper_change_frequency: new FormControl(null),
      diaper_change_times: this.fb.array([]),
      diaper_changes: this.fb.array([]),
      bathing_time: this.fb.array([]),
      tub_bathing: this.fb.array([]),
      medicine_time: this.fb.array([]),
      changing_clothes: this.fb.array([]),
      bathing_sponge_time: new FormControl(null),
      bathing_tub: new FormControl(null),
      bathing_tub_frequency: new FormControl(null),
      bathing_tub_time: new FormControl(null),
      skin_care_lotion: new FormControl(null),
      skin_care_baby_oil: new FormControl(null),
      skin_care_others: new FormControl(null),
      skin_care_specify_produ: new FormControl(null),
      skin_care_Wipes: new FormControl(null),
      skin_care_special_instructions: new FormControl({
        value: null,
        disabled: true,
      }),

      changing_colthes_spiecal_instructions: new FormControl(null),
      sleeping_mother: new FormControl(null),
      sleeping_mother_time: new FormControl(null),
      sleeping_nanny: new FormControl(null),
      sleeping_nanny_time: new FormControl(null),
      temperature_monitoring_frequency: new FormControl(null),
      vitamins_medications_json: this.fb.array([]),
      vaccinations_json: this.fb.array([]),
      potty_training: new FormControl(null),
      play_child_outside: new FormControl(null),
      play_child_outside_special_Instructions: new FormControl(null),
      escort_child_outside: new FormControl(null),
      escort_child_outside_special_Instructions: new FormControl(null),
      remarks: new FormControl(null),
      answer: new FormControl(null),
      attach: new FormControl(null),
      Asleep: new FormControl(null),
      LongPause: new FormControl(null),
      rhythmical: new FormControl(null),
      swallowing: new FormControl(null),
      Normal_skin: new FormControl(null),
      weight: new FormControl(null),
      Other_concerns: new FormControl(null),
      skin_care: this.fb.array([]),
      nurse_remarks: this.fb.array([]),
      breast_feeding: this.fb.array([]),
      diaper_calculation:this.fb.array([]),
      diaper_label_end_time:new FormControl(null),
      diaper_label:new FormControl (null),
      done_sterilization_onetime:new FormControl(null),
      actual_sterilization_all_time:new FormControl(null),
      onetime_Remark:new FormControl(null)
    });
  }
  
// final submit button
// nannyTaskOnFinalSubmit() {
//   let id = localStorage.getItem('babyId');
//   this.spinner.show();
//   this.common_service.nannyfinaltasksubmit(id)
//     .then((res: any) => {
//       this.spinner.hide();
//       this.messageService.add({
//         severity: 'success',
//         summary: 'Success',
//         detail: res?.message,
//       });
//     })
//     .catch((error: any) => {
//       this.spinner.hide();
      
//       this.messageService.add({
//         severity: 'error',
//         summary: "Error",
//         detail: error,
//       });
//     });
// }
nannyTaskOnFinalSubmit() {
  this.zeroToThreeMonthsForm.enable();
  // Show confirmation dialog
  let structuredObject = {
    baby_details_id: this.zeroToThreeMonthsForm.get('baby_details_id').value,
    nurse_remarks:this.zeroToThreeMonthsForm.get('nurse_remarks').value,
    remarks:this.zeroToThreeMonthsForm.get('remarks').value,
    allergy:[
      {
        food_medicine_allergy:this.zeroToThreeMonthsForm.get('food_medicine_allergy').value,
        food_medicine_allergy_specify_prod:this.zeroToThreeMonthsForm.get('food_medicine_allergy_specify_prod').value,
      }
    ],
    common: [
      {
        Asleep: this.zeroToThreeMonthsForm.get('Asleep').value,
        LongPause:this.zeroToThreeMonthsForm.get('LongPause').value,
        rhythmical:this.zeroToThreeMonthsForm.get('rhythmical').value,
        swallowing:this.zeroToThreeMonthsForm.get('swallowing').value,
        Normal_skin:this.zeroToThreeMonthsForm.get('Normal_skin').value,
        weight:this.zeroToThreeMonthsForm.get('weight').value,
        Other_concerns:this.zeroToThreeMonthsForm.get('Other_concerns').value,
      }
    ],
    bathing:[
      {
        bathing_frequency_sponge:this.zeroToThreeMonthsForm.get('bathing_frequency_sponge').value,
        bathing_time:this.zeroToThreeMonthsForm.get('bathing_time').value,
        bathing_sponge:this.zeroToThreeMonthsForm.get('bathing_sponge').value,
        bathing_tub:this.zeroToThreeMonthsForm.get('bathing_tub').value,
        bathing_tub_frequency:this.zeroToThreeMonthsForm.get('bathing_tub_frequency').value,
        tub_bathing:this.zeroToThreeMonthsForm.get('tub_bathing').value
      }
    ],
    bottle_sterilization:[
      {
        bottle_sterilization:this.zeroToThreeMonthsForm.get('bottle_sterilization').value,
        bottle_sterilization_all:this.zeroToThreeMonthsForm.get('bottle_sterilization_all').value,
        bottle_sterilization_all_time:this.zeroToThreeMonthsForm.get('bottle_sterilization_all_time').value,
        bottle_sterilization_onetime:this.zeroToThreeMonthsForm.get('bottle_sterilization_onetime').value,
        bottle_sterilization_onetime_time:this.zeroToThreeMonthsForm.get('bottle_sterilization_onetime_time').value,
      }
    ],
   
     clothes:[
      {
      changing_clothes:this.zeroToThreeMonthsForm.get('changing_clothes').value,
      changing_colthes_spiecal_instructions:this.zeroToThreeMonthsForm.get('changing_colthes_spiecal_instructions').value,
     }
    ],
    diaper:[{
      diaper_calculation:this.zeroToThreeMonthsForm.get('diaper_calculation').value,
      diaper_change_frequency:this.zeroToThreeMonthsForm.get('diaper_change_frequency').value,
      diaper_label:this.zeroToThreeMonthsForm.get('diaper_label').value,
      diaper_label_end_time:this.zeroToThreeMonthsForm.get('diaper_label_end_time').value,
      // diaper_type:this.zeroToThreeMonthsForm.get('diaper_type').value,
      diaper_change_times:this.zeroToThreeMonthsForm.get('diaper_change_times').value,
    }],
    
    breastfeeding:[
      {
       feeding_frequency_breast:this.zeroToThreeMonthsForm.get('feeding_frequency_breast').value,
       breast_feeding:this.zeroToThreeMonthsForm.get('breast_feeding').value,
      }
    ],
    formula_feeding:[
      {
        formula_calculation:this.zeroToThreeMonthsForm.get('formula_calculation').value,
       feeding_frequency:this.zeroToThreeMonthsForm.get('feeding_frequency').value,
       feeding_breast_time_end:this.zeroToThreeMonthsForm.get('feeding_breast_time_end').value,
        feeding_time:this.zeroToThreeMonthsForm.get('feeding_time').value,
        feeding_amount_per:this.zeroToThreeMonthsForm.get('feeding_amount_per').value,
        type_of_formula:this.zeroToThreeMonthsForm.get('type_of_formula').value,
        // formula_remark:this.zeroToThreeMonthsForm.get('formula_remark').value,
           feeding_type: this.zeroToThreeMonthsForm.get('feeding_type').value,
      }
    ],
    prepared:[
      {
        feeding_prepared_mother:this.zeroToThreeMonthsForm.get('feeding_prepared_mother').value,
        feeding_prepared_nanny:this.zeroToThreeMonthsForm.get('feeding_prepared_nanny').value,
         feeding_prepared_other: this.zeroToThreeMonthsForm.get('feeding_prepared_other').value,
      }
    ],
    sleeping:[{
      sleeping_time:this.zeroToThreeMonthsForm.get('sleeping_time').value,
      nanny_time:this.zeroToThreeMonthsForm.get('nanny_time').value,
    }],
    skin:[{
      skin_care:this.zeroToThreeMonthsForm.get('skin_care').value,
    }],
    vitamin:[
      {
        vitamins_medications_json:this.zeroToThreeMonthsForm.get('vitamins_medications_json').value,
      }
    ],
    wipes:[
      {
        skin_care_special_instructions:this.zeroToThreeMonthsForm.get('skin_care_special_instructions').value,
        skin_care_Wipes:this.zeroToThreeMonthsForm.get('skin_care_Wipes').value,
      }
    ]
};
  this.confirmationService.confirm({
    target: event.target as EventTarget,
    message: 'Do you want to submit this task?',
    header: 'Submit Confirmation',
    icon: 'pi pi-info-circle',
    acceptButtonStyleClass: "p-button-success p-button-text",
    rejectButtonStyleClass: "p-button-text p-button-text",
    acceptIcon: "none",
    rejectIcon: "none",
    accept: () => {
      // If user confirms, proceed with submission
       let nurseid=  localStorage.getItem('nurseTaskEditID') 
       
      let id = localStorage.getItem('babyId');
      let payload = {
        nanny_task_details_json: JSON.stringify(this.zeroToThreeMonthsForm.value),
         baby_id:localStorage.getItem('babyId'),
        structured_json: JSON.stringify(structuredObject),
        task_id :localStorage.getItem('nurseTaskEditID'),
        baby_plan_id: 1,
      };
  
      
      this.spinner.show();
      this.common_service.nannyfinaltasksubmit(id,payload)
        .then((res: any) => {
          this.spinner.hide();
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: res?.message,
          });
          setTimeout(() => {
            this.router.navigateByUrl('/portal/first-screen')
          }, 2000);
        })
        .catch((error: any) => {
          this.spinner.hide();
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: error,
          });
        });
    },
    reject: () => {
      // If user rejects, show rejection message
      this.messageService.add({
        severity: 'info',
        summary: 'Rejected',
        detail: 'Submission cancelled',
      });
    }
  });
}


  ngOnInit(): void {
    this.spinner.show()
    this.common_service.getBabyDetails().subscribe((res: any) => {
      this.spinner.hide()
      this.babyDetails = res?.data;
      // For frequency dropdown list.
      for (let i = 1; i <= 10; i++) {
        this.frequency.push({ frequency: i });
      }
    }, (error: any) => {
      this.spinner.hide()

      // Handle any errors that occurred during the API call

    });
    if (localStorage.getItem('nurseTaskEditID') != "undefined" && localStorage.getItem('nurseTaskEditID') != null) {
      this.getEditFormData();
      this.isedit = true;
    }
    else {
      this.getCreateFormData();
      // this.isSettingValueProgrammatically = false;
      this.isedit = false;
      // this.addVitaminObject('add', 0);
      // this.addVaccinationObject('add', 0);
      // this.addSkinObject('add', 0);
    }
    this.getname = localStorage.getItem('name');
    this.getToken = localStorage.getItem('token');
    this.getRole = localStorage.getItem('role')
    // this.baby_id = localStorage.getItem('babyId');
  }

  logout() {
    localStorage.clear();
    // window.location.reload();
    this.router.navigate(['/']);
  }

  getCreateFormData() {
    this.spinner.show()
    const getEdit = localStorage.getItem('babyId');
    this.common_service.getEditNursetaskByBaby(getEdit).then((res: any) => {
      this.spinner.hide()

      const detailsInGrowthMonitoring = res.data[0];
      const nannytask = detailsInGrowthMonitoring.nanny_task_details_json;
   
      this.babyGender = detailsInGrowthMonitoring.gender;
      this.selectedDate = detailsInGrowthMonitoring.date_of_birth;
      this.days = detailsInGrowthMonitoring.days;
      this.month = detailsInGrowthMonitoring.months;
      this.years = detailsInGrowthMonitoring.years;
      const skin = res.data[0].nanny_task_details_json.skin_care;
      const nurseRemarks = res.data[0].nanny_task_details_json.nurse_remarks;
      const bottle = res.data[0].nanny_task_details_json.formula_calculation;
      
      const bottle_sterilization =
        res.data[0].nanny_task_details_json.bottle_sterilization;
     
      const VitaminsMedication =
        res.data[0].nanny_task_details_json.vitamins_medications_json;
      const vaccine = res.data[0].nanny_task_details_json.vaccinations_json;
      // const diapherchange = res.data[0].nanny_task_details_json.diaper_change_times;
      this.zeroToThreeMonthsForm.controls.baby_details_id.setValue(
        JSON.parse(detailsInGrowthMonitoring.baby_details_id)
      );

      this.zeroToThreeMonthsForm.controls.feeding_prepared_mother.setValue(
        nannytask.feeding_prepared_mother
      );
      this.zeroToThreeMonthsForm.controls.feeding_prepared_other.setValue(
        nannytask.feeding_prepared_other
      );
      this.zeroToThreeMonthsForm.controls.feeding_prepared_nanny.setValue(
        nannytask.feeding_prepared_nanny
      );
      this.zeroToThreeMonthsForm.controls.feeding_type.setValue(
        nannytask.feeding_type
      );
      this.zeroToThreeMonthsForm.controls.feeding_breast.setValue(
        nannytask.feeding_breast
      );
      this.zeroToThreeMonthsForm.controls.answer.setValue(nannytask.answer);
      this.zeroToThreeMonthsForm.controls.attach.setValue(nannytask.attach);
      this.zeroToThreeMonthsForm.controls.Asleep.setValue(nannytask.Asleep);
      this.zeroToThreeMonthsForm.controls.LongPause.setValue(
        nannytask.LongPause
      );
      this.zeroToThreeMonthsForm.controls.rhythmical.setValue(
        nannytask.rhythmical
      );
      this.zeroToThreeMonthsForm.controls.swallowing.setValue(
        nannytask.swallowing
      );
      this.zeroToThreeMonthsForm.controls.Normal_skin.setValue(
        nannytask.Normal_skin
      );
      this.zeroToThreeMonthsForm.controls.weight.setValue(nannytask.weight);
      this.zeroToThreeMonthsForm.controls.Other_concerns.setValue(
        nannytask.Other_concerns
      );
      // this.zeroToThreeMonthsForm.controls.changing_colthes_frequency.setValue(
      //   nannytask.changing_colthes_frequency
      // );
      this.zeroToThreeMonthsForm.controls.temperature_monitoring_frequency.setValue(
        nannytask.temperature_monitoring_frequency
      );
      this.zeroToThreeMonthsForm.controls.bathing_tub.setValue(
        nannytask.bathing_tub
      );
      this.zeroToThreeMonthsForm.controls.bathing_sponge.setValue(
        nannytask.bathing_sponge
      );
      this.zeroToThreeMonthsForm.controls.diaper_change_frequency.setValue(
        nannytask.diaper_change_frequency
      );
      this.zeroToThreeMonthsForm.controls.diaper_label.setValue(
        this.utility_service.parseISOString(nannytask.diaper_label)
      );
      this.zeroToThreeMonthsForm.controls.diaper_label_end_time.setValue(
        this.utility_service.parseISOString(nannytask.diaper_label_end_time)
      );
      // set value for sleeping 
      const sleeptime = res.data[0].nanny_task_details_json.sleeping_time;
      for (let i = 0; i < sleeptime?.length; i++) {
        this.isBathingtub = true;
        let CalculationArray = this.getSleepArray();
        let time = sleeptime[i] || {};

        const newCalculationGroup = this.fb.group({
          sleeping_mother_time: new FormControl(
            time.sleeping_mother_time
              ? new Date(time.sleeping_mother_time)
              : null
          ),
          sleeping_mother_actual_time: new FormControl(
            time.sleeping_mother_actual_time
              ? new Date(time.sleeping_mother_actual_time)
              : null
          ),
          sleeping_mother_remark: new FormControl(time.sleeping_mother_remark || ''),
          sleeping_mother: new FormControl(time.sleeping_mother || ''),
        });
        CalculationArray.push(newCalculationGroup);
      }
      //nanny time
      const nannytime = res.data[0].nanny_task_details_json.nanny_time;
      for (let i = 0; i < nannytime?.length; i++) {
        this.isBathingtub = true;
        let CalculationArray = this.getSleepnannyArray();
        let time = nannytime[i] || {};

        const newCalculationGroup = this.fb.group({
          sleeping_nanny_time: new FormControl(
            time.sleeping_nanny_time
              ? new Date(time.sleeping_nanny_time)
              : null
          ),
          sleeping_nanny_actual_time: new FormControl(
            time.sleeping_nanny_actual_time
              ? new Date(time.sleeping_nanny_actual_time)
              : null
          ),
          sleeping_nanny: new FormControl(time.sleeping_nanny || ''),
          sleeping_nanny_remark: new FormControl(time.sleeping_nanny_remark || ''),
        });
        CalculationArray.push(newCalculationGroup);
      }



      // set value of diaper changes
      const diapherchange =
        res.data[0].nanny_task_details_json.diaper_change_times;
      for (let i = 0; i < diapherchange?.length; i++) {
        let CalculationArray = this.getDiaperChangeControls();
        let diaper = diapherchange[i] || {};

        const newCalculationGroup = this.fb.group({
          change: new FormControl(diaper.change || ''),
          time: new FormControl(
            diaper.time
              ? new Date(diaper.time)
              : null
          ),
          diaper_remark: new FormControl(diaper.diaper_remark || ''),
        });
        CalculationArray.push(newCalculationGroup);
      }
      //diaper frequency changes
      // const diapherchangefrequency =
      //   res.data[0].nanny_task_details_json.diaper_changes;
      // for (let i = 0; i < diapherchangefrequency?.length; i++) {
      //   let CalculationArray = this.getDiaperChangeControlsfrequency();
      //   let diaper = diapherchangefrequency[i] || {};

      //   const newCalculationGroup = this.fb.group({
      //     isDiaperChanged: new FormControl(diaper.isDiaperChanged || ''),
      //     diaper_label: new FormControl(
      //       diaper.diaper_label
      //         ? new Date(diaper.diaper_label)
      //         : null
      //     ),
      //     diaper_actual_time: new FormControl(
      //       diaper.diaper_actual_time
      //         ? new Date(diaper.diaper_actual_time)
      //         : null
      //     ),
      //     diaperRemark: new FormControl(diaper.diaperRemark)

      //   });
      //   CalculationArray.push(newCalculationGroup);
      // }
      //changing clothes  timing
      const changingclothes =
        res.data[0].nanny_task_details_json.changing_clothes;
      for (let i = 0; i < changingclothes?.length; i++) {
        let clothesarray = this.getClothesFormArray();
        let clothes = changingclothes[i] || {};

        const newclothesGroup = this.fb.group({
          changing_colthes: new FormControl(clothes.changing_colthes || ''),
          Changing_time: new FormControl(
            clothes.Changing_time
              ? new Date(clothes.Changing_time)
              : null
          ),
          Changing_actual_time: new FormControl(
            clothes.Changing_actual_time ? new Date(clothes.Changing_actual_time) : null
          ),
          clothe_remark: new FormControl(clothes.clothe_remark || ''),
        });
        clothesarray.push(newclothesGroup);
      }

      //skin care add button on edit
      for (let i = 0; i < skin?.length; i++) {
        let skinArray = this.getSkinFormArray();
        let skinFromApi = skin[i] || {};

        let newskinArray = new FormGroup({
          skin_care_lotion: new FormControl(skinFromApi.skin_care_lotion || ''),
          skin_care_baby_oil: new FormControl(
            skinFromApi.skin_care_baby_oil || ''
          ),
          skin_care_others: new FormControl(skinFromApi.skin_care_others || ''),
          skin_care_specify_produ: new FormControl(
            skinFromApi.skin_care_specify_produ || ''
          ),
        });

        skinArray.insert(i, newskinArray);
      }

      // set nurse Remarks
      // if(nurseRemarks){
      //   for (let i = 0; i < nurseRemarks?.length; i++) {
      //     this.isBathingtub = true;
      //     let CalculationArray = this.getNurseRemarks();
      //     let time = nurseRemarks[i] || {};

      //     const newCalculationGroup = this.fb.group({
      //       nurse_remark: new FormControl(time.nurse_remark? time.nurse_remark : null),
      //       nurse_remark_time :new FormControl(time.nurse_remark_time?time.nurse_remark_time :null)

      //     });
      //     CalculationArray.push(newCalculationGroup);
      //   }
      // }
      // else{
      // if(nurseRemarks?.length < 1 || nurseRemarks == undefined){
      this.addNurseRemark('add', 0);
      // }
      // }
      const adddiaper = res.data[0].nanny_task_details_json.diaper_calculation;
      for (let i = 0; i < adddiaper?.length; i++) {
        this.isdiaper = true;
        let bottleArray = this.getdiaperCalculationControls();
        let bottleFromApi = adddiaper[i] || {};

        let newbottleArray = new FormGroup({
          diaper_timeArray: new FormControl(bottleFromApi.diaper_timeArray ? new Date(bottleFromApi.diaper_timeArray)
              : null),
          diaper_actual_time: new FormControl(
            bottleFromApi.diaper_actual_time
              ? new Date(bottleFromApi.diaper_actual_time)
              : null
          ),
          remark: new FormControl(bottleFromApi.remark || ''),
          amount: new FormControl(bottleFromApi.amount || ''),
          diaperRemark: new FormControl(bottleFromApi.diaperRemark || ''),
          diaper_checkbox: new FormControl(bottleFromApi.diaper_checkbox || ''),
        });

        bottleArray.insert(i, newbottleArray);
      }
      // formula calculation
      for (let i = 0; i < bottle?.length; i++) {
        this.isFormula = true;
        let bottleArray = this.getFormulaCalculationControls();
        let bottleFromApi = bottle[i] || {};

        let newbottleArray = new FormGroup({
          formula_timeArray: new FormControl(
            bottleFromApi.formula_timeArray
              ? new Date(bottleFromApi.formula_timeArray)
              : null
          ),
          nurse_feeding: new FormControl(
            bottleFromApi.nurse_feeding
              ? new Date(bottleFromApi.nurse_feeding)
              : null
          ),
          is_same_formula: new FormControl(bottleFromApi.is_same_formula || 'Yes'),
          remark: new FormControl(bottleFromApi.remark || ''),
          amount: new FormControl(bottleFromApi.amount || ''),
          formula_remark: new FormControl(bottleFromApi.formula_remark || ''),
          feeding_checkbox: new FormControl(bottleFromApi.feeding_checkbox || ''),
        });

        bottleArray.insert(i, newbottleArray);
      }
      // bottle sterilization
      for (let i = 0; i < bottle_sterilization?.length; i++) {
        this.isFormula = true;
        let bottlesterilization_timeArrayArray =
          this.getbottle_sterilizationControls();
        let bottleFromApi = bottle_sterilization[i] || {};

        let newbottle_sterilizationArray = new FormGroup({
          done_sterilization: new FormControl(
            bottleFromApi.done_sterilization || ''
          ),
          sterilization_timeArray: new FormControl(
            bottleFromApi.sterilization_timeArray
              ? new Date(bottleFromApi.sterilization_timeArray)
              : null
          ),
          actual_sterilization_timeArray: new FormControl(
            bottleFromApi.actual_sterilization_timeArray
              ? new Date(bottleFromApi.actual_sterilization_timeArray)
              : null
          ),
          sterilization_remark: new FormControl(
            bottleFromApi.sterilization_remark || ''
          ),
        });

        bottlesterilization_timeArrayArray.insert(
          i,
          newbottle_sterilizationArray
        );
      }

      for (let i = 0; i < VitaminsMedication?.length; i++) {
        let vitaminArray = this.getVitaminFormArray();
        let VitaminsMedicationFromApi = VitaminsMedication[i] || {};


        let newVitaminArray = new FormGroup({
          vitamin: new FormControl(VitaminsMedicationFromApi.vitamin || ''),
          medicine_time: new FormArray([]),
          frequency: new FormControl(VitaminsMedicationFromApi.frequency || ''),
        });
        vitaminArray.insert(i, newVitaminArray);

        this.ismedicine = true;

        for (let j = 0; j < VitaminsMedicationFromApi.medicine_time?.length; j++) {

          const viamincalc = (this.zeroToThreeMonthsForm.get('vitamins_medications_json') as FormArray).at(i).get('medicine_time') as FormArray;
          const bathingcalculationgroup = this.fb.group({
            time: new FormControl(new Date(VitaminsMedicationFromApi.medicine_time[j].time)),
            vitamin_checkbox: new FormControl(VitaminsMedicationFromApi.medicine_time[j].vitamin_checkbox),
            vitamin_remark: new FormControl(VitaminsMedicationFromApi.medicine_time[j].vitamin_remark),
            actual_time: new FormControl(VitaminsMedicationFromApi.medicine_time[j].actual_time ? new Date(VitaminsMedicationFromApi.medicine_time[j].actual_time) : ''),
          });

          viamincalc.push(bathingcalculationgroup);

          // let remarkArray = this.getremark(index);
          // let newremarkArray = this.fb.group({
          //   toys_remark: new FormControl(detailschildsafety.accessories[index]['toys_remarks'][i].toys_remark),
          // });
          // remarkArray.insert(i, newremarkArray); // Use just 'i' instead of 'index + 1'
        }

      }

      for (let i = 0; i < vaccine?.length; i++) {
        let vaccinationArray = this.getVaccinationFormArray();
        let vaccineApi = vaccine[i] || {};

        let newVaccinationArray = new FormGroup({
          vaccination: new FormControl(vaccineApi.vaccination || ''),
          date_administration: new FormControl(
            vaccineApi.date_administration
              ? new Date(vaccineApi.date_administration)
              : null
          ),
        });

        vaccinationArray.insert(i, newVaccinationArray);
      }

      if (nannytask.feeding_breast == true) {
        this.feedingBreast = !this.feedingBreast;
        this.zeroToThreeMonthsForm.controls[
          'feeding_frequency_breast'
        ].enable();
        this.zeroToThreeMonthsForm.controls['feeding_breast_time'].enable();
        this.zeroToThreeMonthsForm.controls[
          'feeding_frequency_breast'
        ].setValue(nannytask.feeding_frequency_breast);

        this.zeroToThreeMonthsForm.controls.feeding_breast_time.setValue(
          this.utility_service.parseISOString(nannytask.feeding_breast_time)
        );
      } else {
        this.zeroToThreeMonthsForm.controls[
          'feeding_frequency_breast'
        ].disable();
        this.zeroToThreeMonthsForm.controls['feeding_breast_time'].disable();
      }
      if (nannytask.feeding_type == true) {
        this.feedingFormula = !this.feedingFormula;
        this.zeroToThreeMonthsForm.controls['feeding_frequency'].enable();
        this.zeroToThreeMonthsForm.controls['feeding_time'].enable();
        this.zeroToThreeMonthsForm.controls['feeding_breast_time_end'].enable();
        // this.feedingFormula = true;
        this.zeroToThreeMonthsForm.controls['type_of_formula'].enable;
        this.zeroToThreeMonthsForm.controls.feeding_time.setValue(
          this.utility_service.parseISOString(nannytask.feeding_time)
        );
        this.zeroToThreeMonthsForm.controls.feeding_breast_time_end.setValue(
          this.utility_service.parseISOString(nannytask.feeding_breast_time_end)
        );

        this.zeroToThreeMonthsForm.controls.feeding_frequency.setValue(
          nannytask.feeding_frequency
        );
        // this.zeroToThreeMonthsForm.controls.type_of_formula.setValue(nannytask.type_of_formula);
      } else {
        this.zeroToThreeMonthsForm.controls['feeding_frequency'].disable();
        this.zeroToThreeMonthsForm.controls['feeding_time'].disable();
        this.zeroToThreeMonthsForm.controls['type_of_formula'].disable();
        this.zeroToThreeMonthsForm.controls[
          'feeding_breast_time_end'
        ].disable();
      }

      // this.zeroToThreeMonthsForm.controls.feeding_time.setValue(nannytask.feeding_time);
      this.zeroToThreeMonthsForm.controls.feeding_amount_per.setValue(
        nannytask.feeding_amount_per
      );
      this.zeroToThreeMonthsForm.controls.type_of_formula.setValue(
        nannytask.type_of_formula
      );
      this.zeroToThreeMonthsForm.controls.food_medicine_allergy.setValue(
        nannytask.food_medicine_allergy
      );

      if (nannytask.food_medicine_allergy == '1') {
        this.zeroToThreeMonthsForm.controls[
          'food_medicine_allergy_specify_prod'
        ].enable();
        this.zeroToThreeMonthsForm.controls.food_medicine_allergy_specify_prod.setValue(
          nannytask.food_medicine_allergy_specify_prod
        );
      } else {
        this.zeroToThreeMonthsForm.controls[
          'food_medicine_allergy_specify_prod'
        ].disable();
      }

      this.zeroToThreeMonthsForm.controls.skin_care_Wipes.setValue(
        nannytask.skin_care_Wipes
      );
      this.zeroToThreeMonthsForm.controls.bottle_sterilization_all.setValue(
        nannytask.bottle_sterilization_all
      );

      if (nannytask.bottle_sterilization_all == true) {
        this.sterilizingAll = !this.sterilizingAll;
        this.zeroToThreeMonthsForm.controls.bottle_sterilization_all_time.disable();
        this.zeroToThreeMonthsForm.controls.bottle_sterilization_all_time.setValue(
          this.utility_service.parseISOString(
            nannytask.bottle_sterilization_all_time
          )
        );
        this.zeroToThreeMonthsForm.controls.actual_sterilization_all_time.setValue(
          this.utility_service.parseISOString(
            nannytask.actual_sterilization_all_time
          )
        );
        this.zeroToThreeMonthsForm.controls.onetime_Remark.setValue(nannytask.onetime_Remark);
        this.zeroToThreeMonthsForm.controls.done_sterilization_onetime.setValue(nannytask.done_sterilization_onetime);
      }

      if (nannytask.bottle_sterilization_onetime) {
        this.zeroToThreeMonthsForm.controls.bottle_sterilization_onetime.setValue(
          nannytask.bottle_sterilization_onetime
        );
        this.sterilizingOne = false;
      }
      if (nannytask.skin_care_Wipes == '1') {
        this.zeroToThreeMonthsForm.controls['skin_care_special_instructions']
          .enable;
        this.zeroToThreeMonthsForm.controls.skin_care_special_instructions.setValue(
          nannytask.skin_care_special_instructions
        );
      } else {
        this.zeroToThreeMonthsForm.controls[
          'skin_care_special_instructions'
        ].disable();
      }
      this.zeroToThreeMonthsForm.controls.changing_colthes_spiecal_instructions.setValue(
        nannytask.changing_colthes_spiecal_instructions
      );
      this.zeroToThreeMonthsForm.controls.remarks.setValue(nannytask.remarks);
      if (nannytask.sleeping_mother == true) {
        this.withMother = !this.withMother;
        this.zeroToThreeMonthsForm.controls.sleeping_mother_time.enable();
        this.zeroToThreeMonthsForm.controls.sleeping_mother_remark.enable();
        this.zeroToThreeMonthsForm.controls.sleeping_mother.setValue(
          nannytask.sleeping_mother
        );
        this.zeroToThreeMonthsForm.controls.sleeping_mother_time.setValue(
          this.utility_service.parseISOString(nannytask.sleeping_mother_time)
        );
        this.zeroToThreeMonthsForm.controls.sleeping_mother_actual_time.setValue(
          this.utility_service.parseISOString(nannytask.sleeping_mother_actual_time)
        );
        this.zeroToThreeMonthsForm.controls.sleeping_mother_remark.setValue(
          this.utility_service.parseISOString(nannytask.sleeping_mother_remark)
        );
      } else {
        this.zeroToThreeMonthsForm.controls.sleeping_mother_time.disable();
      }
      if (nannytask.sleeping_nanny == true) {
        this.withNanny = !this.withNanny;
        this.zeroToThreeMonthsForm.controls.sleeping_nanny_time.enable();
        this.zeroToThreeMonthsForm.controls.sleeping_nanny_remark.enable();
        this.zeroToThreeMonthsForm.controls.sleeping_nanny.setValue(
          nannytask.sleeping_nanny
        );
        this.zeroToThreeMonthsForm.controls.sleeping_nanny_time.setValue(
          this.utility_service.parseISOString(nannytask.sleeping_nanny_time)
        );
        this.zeroToThreeMonthsForm.controls.sleeping_nanny_actual_time.setValue(
          this.utility_service.parseISOString(nannytask.sleeping_nanny_actual_time)
        );
        this.zeroToThreeMonthsForm.controls.sleeping_nanny_remark.setValue(
          this.utility_service.parseISOString(nannytask.sleeping_nanny_remark)
        );
      } else {
        this.zeroToThreeMonthsForm.controls.sleeping_nanny_time.disable();
      }

      if (nannytask.bathing_tub == true) {
        // this.tubBath = !this.tubBath;
        this.zeroToThreeMonthsForm.controls['bathing_tub_time'].enable();
        this.zeroToThreeMonthsForm.controls['bathing_tub_frequency'].enable();
        // this.zeroToThreeMonthsForm.controls.bathing_tub_time.setValue(
        //   this.utility_service.parseISOString(nannytask.bathing_tub_time)
        // );
        this.zeroToThreeMonthsForm.controls.bathing_tub_frequency.setValue(
          nannytask.bathing_tub_frequency
        );
      }

      if (nannytask.bathing_sponge == true) {
        // this.spongeBath = !this.spongeBath;
        // this.zeroToThreeMonthsForm.controls['bathing_sponge_time'].enable();
        this.zeroToThreeMonthsForm.controls[
          'bathing_frequency_sponge'
        ].enable();
        // this.zeroToThreeMonthsForm.controls.bathing_sponge_time.setValue(
        //   this.utility_service.parseISOString(nannytask.bathing_sponge_time)
        // );
        this.zeroToThreeMonthsForm.controls.bathing_frequency_sponge.setValue(
          nannytask.bathing_frequency_sponge
        );
      }


      // bathing section start
      const spongetime = res.data[0].nanny_task_details_json.bathing_time;

      for (let i = 0; i < spongetime?.length; i++) {
        this.isBathing = true;
        let CalculationArray = this.getbathingarray();
        let time = spongetime[i] || {};
        const newCalculationGroup = this.fb.group({
          bathing_sponge_time: new FormControl(
            new Date(time.bathing_sponge_time) || ''
          ),
          sponge_checkbox: new FormControl(time.sponge_checkbox || ''),
          spongeBathing_remark: new FormControl(time.spongeBathing_remark || ''),
          bathing_sponge_actual_time: new FormControl(
            time.bathing_sponge_actual_time ? new Date(time.bathing_sponge_actual_time) : ''
          )
        });
        CalculationArray.push(newCalculationGroup);
      }
      const tubtime = res.data[0].nanny_task_details_json.tub_bathing;
      for (let i = 0; i < tubtime?.length; i++) {
        this.isBathingtub = true;
        let CalculationArray = this.getbathingtubarray();
        let time = tubtime[i] || {};

        const newCalculationGroup = this.fb.group({
          bathing_tub_time: new FormControl(
            new Date(time.bathing_tub_time) || ''
          ),
          bathing_tub_actual_time: new FormControl(
            time.bathing_tub_actual_time ? new Date(time.bathing_tub_actual_time) : ''
          ),
          tub_checkbox: new FormControl(time.tub_checkbox || ''),
          tubBathing_remark: new FormControl(time.tubBathing_remark || '')
        });
        CalculationArray.push(newCalculationGroup);
      }
      //feeding multiple time fields
      const breastime = res.data[0].nanny_task_details_json.breast_feeding;
      for (let i = 0; i < breastime?.length; i++) {
        this.isBreast = true;
        let CalculationArray = this.getbreastarray();
        let time = breastime[i] || {};

        const newCalculationGroup = this.fb.group({
          feeding_breast_time: new FormControl(
            time.feeding_breast_time
              ? new Date(time.feeding_breast_time)
              : null
          ),
          feeding_breast_checkbox: new FormControl(time.feeding_breast_checkbox || ''),
          feeding_breast_actual_time: new FormControl(
            time.feeding_breast_actual_time ? new Date(time.feeding_breast_actual_time) : null
          ),
          breast_feeding_remark: new FormControl(time.breast_feeding_remark || '')
        });
        CalculationArray.push(newCalculationGroup);
      }
      this.disableForm();
      this.isSettingValueProgrammatically = false;
    },
      (error: any) => {
        this.spinner.hide()

        // Handle any errors that occurred during the API call
        //.error('API Error:', error);

      });

  }


  getEditFormData() {
    this.spinner.show()
    const getEdit = localStorage.getItem('nurseTaskEditID');
    this.common_service.getEditNurseDailyTask(getEdit).then((res: any) => {
      this.spinner.hide()

      const detailsInGrowthMonitoring = res.data[0];
      const nannytask = detailsInGrowthMonitoring.nanny_task_details_json;
      
      this.babyGender = detailsInGrowthMonitoring.gender;
      this.selectedDate = detailsInGrowthMonitoring.date_of_birth;
      this.days = detailsInGrowthMonitoring.days;
      this.month = detailsInGrowthMonitoring.months;
      this.years = detailsInGrowthMonitoring.years;
      const skin = res.data[0].nanny_task_details_json.skin_care;
      const nurseRemarks = res.data[0].nanny_task_details_json.nurse_remarks;
     
      const bottle = res.data[0].nanny_task_details_json.formula_calculation;
      
      const bottle_sterilization =
        res.data[0].nanny_task_details_json.bottle_sterilization;
      const VitaminsMedication =
        res.data[0].nanny_task_details_json.vitamins_medications_json;
      const vaccine = res.data[0].nanny_task_details_json.vaccinations_json;
      // const diapherchange = res.data[0].nanny_task_details_json.diaper_change_times;
      this.zeroToThreeMonthsForm.controls.baby_details_id.setValue(
        JSON.parse(detailsInGrowthMonitoring.baby_details_id)
      );
      this.zeroToThreeMonthsForm.controls.diaper_label.setValue(
        this.utility_service.parseISOString(nannytask.diaper_label)
      );
      this.zeroToThreeMonthsForm.controls.diaper_label_end_time.setValue(
        this.utility_service.parseISOString(nannytask.diaper_label_end_time)
      );
      this.zeroToThreeMonthsForm.controls.feeding_prepared_mother.setValue(
        nannytask.feeding_prepared_mother
      );
      this.zeroToThreeMonthsForm.controls.feeding_prepared_other.setValue(
        nannytask.feeding_prepared_other
      );
      this.zeroToThreeMonthsForm.controls.feeding_prepared_nanny.setValue(
        nannytask.feeding_prepared_nanny
      );
      this.zeroToThreeMonthsForm.controls.feeding_type.setValue(
        nannytask.feeding_type
      );
      this.zeroToThreeMonthsForm.controls.feeding_breast.setValue(
        nannytask.feeding_breast
      );
      this.zeroToThreeMonthsForm.controls.answer.setValue(nannytask.answer);
      this.zeroToThreeMonthsForm.controls.attach.setValue(nannytask.attach);
      this.zeroToThreeMonthsForm.controls.Asleep.setValue(nannytask.Asleep);
      this.zeroToThreeMonthsForm.controls.LongPause.setValue(
        nannytask.LongPause
      );
      this.zeroToThreeMonthsForm.controls.rhythmical.setValue(
        nannytask.rhythmical
      );
      this.zeroToThreeMonthsForm.controls.swallowing.setValue(
        nannytask.swallowing
      );
      this.zeroToThreeMonthsForm.controls.Normal_skin.setValue(
        nannytask.Normal_skin
      );
      this.zeroToThreeMonthsForm.controls.weight.setValue(nannytask.weight);
      this.zeroToThreeMonthsForm.controls.Other_concerns.setValue(
        nannytask.Other_concerns
      );
      // this.zeroToThreeMonthsForm.controls.changing_colthes_frequency.setValue(
      //   nannytask.changing_colthes_frequency
      // );
      this.zeroToThreeMonthsForm.controls.temperature_monitoring_frequency.setValue(
        nannytask.temperature_monitoring_frequency
      );
      this.zeroToThreeMonthsForm.controls.bathing_tub.setValue(
        nannytask.bathing_tub
      );
      this.zeroToThreeMonthsForm.controls.bathing_sponge.setValue(
        nannytask.bathing_sponge
      );
      this.zeroToThreeMonthsForm.controls.diaper_change_frequency.setValue(
        nannytask.diaper_change_frequency
      );

      // set value for sleeping 
      const sleeptime = res.data[0].nanny_task_details_json.sleeping_time;
      for (let i = 0; i < sleeptime?.length; i++) {
        this.isBathingtub = true;
        let CalculationArray = this.getSleepArray();
        let time = sleeptime[i] || {};

        const newCalculationGroup = this.fb.group({
        
          sleeping_mother_time: new FormControl(
            time.sleeping_mother_time
              ? new Date(time.sleeping_mother_time)
              : null
          ),
          sleeping_mother_actual_time: new FormControl(
            time.sleeping_mother_actual_time
              ? new Date(time.sleeping_mother_actual_time)
              : null
          ),
          sleeping_mother: new FormControl(time.sleeping_mother || ''),
          sleeping_mother_remark: new FormControl(time.sleeping_mother_remark || ''),
        });
        CalculationArray.push(newCalculationGroup);
      }
      //nanny time
      const nannytime = res.data[0].nanny_task_details_json.nanny_time;
      for (let i = 0; i < nannytime?.length; i++) {
        this.isBathingtub = true;
        let CalculationArray = this.getSleepnannyArray();
        let time = nannytime[i] || {};

        const newCalculationGroup = this.fb.group({
          sleeping_nanny_time: new FormControl(
            time.sleeping_nanny_time
              ? new Date(time.sleeping_nanny_time)
              : null
          ),
          sleeping_nanny_actual_time: new FormControl(
            time.sleeping_nanny_actual_time ? new Date(time.sleeping_nanny_actual_time) : null
          ),
          sleeping_nanny: new FormControl(time.sleeping_nanny || ''),
          sleeping_nanny_remark: new FormControl(time.sleeping_nanny_remark || ''),

        });
        CalculationArray.push(newCalculationGroup);
      }
      //frequency diaper
      // const diapherchangefrequency =
      //   res.data[0].nanny_task_details_json.diaper_changes;
      // for (let i = 0; i < diapherchangefrequency?.length; i++) {
      //   let CalculationArray = this.getDiaperChangeControlsfrequency();
      //   let diaper = diapherchangefrequency[i] || {};

      //   const newCalculationGroup = this.fb.group({
      //     isDiaperChanged: new FormControl(diaper.isDiaperChanged || ''),
      //     diaper_label: new FormControl(
      //       diaper.diaper_label
      //         ? new Date(diaper.diaper_label)
      //         : null
      //     ),
      //     diaper_actual_time: new FormControl(
      //       diaper.diaper_actual_time
      //         ? new Date(diaper.diaper_actual_time)
      //         : null
      //     ),
      //     diaperRemark: new FormControl(diaper.diaperRemark)

      //   });
      //   CalculationArray.push(newCalculationGroup);
      // }

      // set value of diaper changes
      const diapherchange =
        res.data[0].nanny_task_details_json.diaper_change_times;
      for (let i = 0; i < diapherchange?.length; i++) {
        let CalculationArray = this.getDiaperChangeControls();
        let diaper = diapherchange[i] || {};

        const newCalculationGroup = this.fb.group({
          change: new FormControl(diaper.change || ''),
          time: new FormControl(
            diaper.time
              ? new Date(diaper.time)
              : null
          ),
          diaper_remark: new FormControl(diaper.diaper_remark || ''),
        });
        CalculationArray.push(newCalculationGroup);
      }
      //changing clothes  timing
      const changingclothes =
        res.data[0].nanny_task_details_json.changing_clothes;
      for (let i = 0; i < changingclothes?.length; i++) {
        let clothesarray = this.getClothesFormArray();
        let clothes = changingclothes[i] || {};

        const newclothesGroup = this.fb.group({
          changing_colthes: new FormControl(clothes.changing_colthes || ''),
          Changing_time: new FormControl(
            clothes.Changing_time
              ? new Date(clothes.Changing_time)
              : null
          ),
          Changing_actual_time: new FormControl(
            clothes.Changing_actual_time ? new Date(clothes.Changing_actual_time) : null
          ),

          clothe_remark: new FormControl(clothes.clothe_remark || ''),
        });
        clothesarray.push(newclothesGroup);
      }

      //skin care add button on edit
      for (let i = 0; i < skin?.length; i++) {
        let skinArray = this.getSkinFormArray();
        let skinFromApi = skin[i] || {};

        let newskinArray = new FormGroup({
          skin_care_lotion: new FormControl(skinFromApi.skin_care_lotion || ''),
          skin_care_baby_oil: new FormControl(
            skinFromApi.skin_care_baby_oil || ''
          ),
          skin_care_others: new FormControl(skinFromApi.skin_care_others || ''),
          skin_care_specify_produ: new FormControl(
            skinFromApi.skin_care_specify_produ || ''
          ),
        });

        skinArray.insert(i, newskinArray);
      }
      // set nurse Remarks
      if (nurseRemarks != undefined) {
        for (let i = 0; i < nurseRemarks?.length; i++) {
          this.isBathingtub = true;
          let CalculationArray = this.getNurseRemarks();
          let time = nurseRemarks[i] || {};

          const newCalculationGroup = this.fb.group({
            nurse_remark: new FormControl(time.nurse_remark ? time.nurse_remark : null),
            nurse_remark_time: new FormControl(time.nurse_remark_time ? time.nurse_remark_time : null)

          });
          CalculationArray.push(newCalculationGroup);
        }
      }
      else {
        this.addNurseRemark('add', 0);
      }

      const adddiaper = res.data[0].nanny_task_details_json.diaper_calculation;
      for (let i = 0; i < adddiaper?.length; i++) {
        this.isdiaper = true;
        let bottleArray = this.getdiaperCalculationControls();
        let bottleFromApi = adddiaper[i] || {};

        let newbottleArray = new FormGroup({
          diaper_timeArray: new FormControl(bottleFromApi.diaper_timeArray ? new Date(bottleFromApi.diaper_timeArray)
              : null),
          diaper_actual_time: new FormControl(
            bottleFromApi.diaper_actual_time
              ? new Date(bottleFromApi.diaper_actual_time)
              : null
          ),
          
          remark: new FormControl(bottleFromApi.remark || ''),
          amount: new FormControl(bottleFromApi.amount || ''),
          diaperRemark: new FormControl(bottleFromApi.diaperRemark || ''),
          diaper_checkbox: new FormControl(bottleFromApi.diaper_checkbox || ''),
        });

        bottleArray.insert(i, newbottleArray);
      }

      // formula calculation
      for (let i = 0; i < bottle?.length; i++) {
        this.isFormula = true;
        let bottleArray = this.getFormulaCalculationControls();
        let bottleFromApi = bottle[i] || {};

        let newbottleArray = new FormGroup({
          formula_timeArray: new FormControl(
            bottleFromApi.formula_timeArray
              ? new Date(bottleFromApi.formula_timeArray)
              : null
          ),
          nurse_feeding: new FormControl(
            bottleFromApi.nurse_feeding
              ? new Date(bottleFromApi.nurse_feeding)
              : null
          ),
          is_same_formula: new FormControl(bottleFromApi.is_same_formula || 'Yes'),
          remark: new FormControl(bottleFromApi.remark || ''),
          amount: new FormControl(bottleFromApi.amount || ''),
          formula_remark: new FormControl(bottleFromApi.formula_remark || ''),
          feeding_checkbox: new FormControl(bottleFromApi.feeding_checkbox || ''),
        });

        bottleArray.insert(i, newbottleArray);
      }
      // bottle sterilization
      for (let i = 0; i < bottle_sterilization?.length; i++) {
        this.isFormula = true;
        let bottlesterilization_timeArrayArray =
          this.getbottle_sterilizationControls();
        let bottleFromApi = bottle_sterilization[i] || {};

        let newbottle_sterilizationArray = new FormGroup({
          done_sterilization: new FormControl(
            bottleFromApi.done_sterilization || ''
          ),
          sterilization_timeArray: new FormControl(
            bottleFromApi.sterilization_timeArray
              ? new Date(bottleFromApi.sterilization_timeArray)
              : null
          ),
          actual_sterilization_timeArray: new FormControl(
            bottleFromApi.actual_sterilization_timeArray
              ? new Date(bottleFromApi.actual_sterilization_timeArray)
              : null
          ),
          sterilization_remark: new FormControl(
            bottleFromApi.sterilization_remark || ''
          ),
        });

        bottlesterilization_timeArrayArray.insert(
          i,
          newbottle_sterilizationArray
        );
      }

      for (let i = 0; i < VitaminsMedication?.length; i++) {
        let vitaminArray = this.getVitaminFormArray();
        let VitaminsMedicationFromApi = VitaminsMedication[i] || {};


        let newVitaminArray = new FormGroup({
          vitamin: new FormControl(VitaminsMedicationFromApi.vitamin || ''),
          medicine_time: new FormArray([]),
          frequency: new FormControl(VitaminsMedicationFromApi.frequency || ''),
        });
        vitaminArray.insert(i, newVitaminArray);

        this.ismedicine = true;

        for (let j = 0; j < VitaminsMedicationFromApi?.medicine_time?.length; j++) {

          const viamincalc = (this.zeroToThreeMonthsForm.get('vitamins_medications_json') as FormArray).at(i).get('medicine_time') as FormArray;
          const bathingcalculationgroup = this.fb.group({
            time: new FormControl(new Date(VitaminsMedicationFromApi.medicine_time[j].time)),
            vitamin_checkbox: new FormControl(VitaminsMedicationFromApi.medicine_time[j].vitamin_checkbox),
            vitamin_remark: new FormControl(VitaminsMedicationFromApi.medicine_time[j].vitamin_remark),
            actual_time: new FormControl(VitaminsMedicationFromApi.medicine_time[j].actual_time ? new Date(VitaminsMedicationFromApi.medicine_time[j].actual_time) : ''),
          });

          viamincalc.push(bathingcalculationgroup);

          // let remarkArray = this.getremark(index);
          // let newremarkArray = this.fb.group({
          //   toys_remark: new FormControl(detailschildsafety.accessories[index]['toys_remarks'][i].toys_remark),
          // });
          // remarkArray.insert(i, newremarkArray); // Use just 'i' instead of 'index + 1'
        }

      }

      for (let i = 0; i < vaccine?.length; i++) {
        let vaccinationArray = this.getVaccinationFormArray();
        let vaccineApi = vaccine[i] || {};

        let newVaccinationArray = new FormGroup({
          vaccination: new FormControl(vaccineApi.vaccination || ''),
          date_administration: new FormControl(
            vaccineApi.date_administration
              ? new Date(vaccineApi.date_administration)
              : null
          ),
        });

        vaccinationArray.insert(i, newVaccinationArray);
      }

      if (nannytask.feeding_breast == true) {
        this.feedingBreast = !this.feedingBreast;
        this.zeroToThreeMonthsForm.controls[
          'feeding_frequency_breast'
        ].enable();
        this.zeroToThreeMonthsForm.controls['feeding_breast_time'].enable();
        this.zeroToThreeMonthsForm.controls[
          'feeding_frequency_breast'
        ].setValue(nannytask.feeding_frequency_breast);

        this.zeroToThreeMonthsForm.controls.feeding_breast_time.setValue(
          this.utility_service.parseISOString(nannytask.feeding_breast_time)
        );
      } else {
        this.zeroToThreeMonthsForm.controls[
          'feeding_frequency_breast'
        ].disable();
        this.zeroToThreeMonthsForm.controls['feeding_breast_time'].disable();
      }
      if (nannytask.feeding_type == true) {
        this.feedingFormula = !this.feedingFormula;
        this.zeroToThreeMonthsForm.controls['feeding_frequency'].enable();
        this.zeroToThreeMonthsForm.controls['feeding_time'].enable();
        this.zeroToThreeMonthsForm.controls['feeding_breast_time_end'].enable();
        // this.feedingFormula = true;
        this.zeroToThreeMonthsForm.controls['type_of_formula'].enable;
        this.zeroToThreeMonthsForm.controls.feeding_time.setValue(
          this.utility_service.parseISOString(nannytask.feeding_time)
        );
        this.zeroToThreeMonthsForm.controls.feeding_breast_time_end.setValue(
          this.utility_service.parseISOString(nannytask.feeding_breast_time_end)
        );

        this.zeroToThreeMonthsForm.controls.feeding_frequency.setValue(
          nannytask.feeding_frequency
        );
        // this.zeroToThreeMonthsForm.controls.type_of_formula.setValue(nannytask.type_of_formula);
      } else {
        this.zeroToThreeMonthsForm.controls['feeding_frequency'].disable();
        this.zeroToThreeMonthsForm.controls['feeding_time'].disable();
        this.zeroToThreeMonthsForm.controls['type_of_formula'].disable();
        this.zeroToThreeMonthsForm.controls[
          'feeding_breast_time_end'
        ].disable();
      }

      // this.zeroToThreeMonthsForm.controls.feeding_time.setValue(nannytask.feeding_time);
      this.zeroToThreeMonthsForm.controls.feeding_amount_per.setValue(
        nannytask.feeding_amount_per
      );
      this.zeroToThreeMonthsForm.controls.type_of_formula.setValue(
        nannytask.type_of_formula
      );
      this.zeroToThreeMonthsForm.controls.food_medicine_allergy.setValue(
        nannytask.food_medicine_allergy
      );

      if (nannytask.food_medicine_allergy == '1') {
        this.zeroToThreeMonthsForm.controls[
          'food_medicine_allergy_specify_prod'
        ].enable();
        this.zeroToThreeMonthsForm.controls.food_medicine_allergy_specify_prod.setValue(
          nannytask.food_medicine_allergy_specify_prod
        );
      } else {
        this.zeroToThreeMonthsForm.controls[
          'food_medicine_allergy_specify_prod'
        ].disable();
      }

      this.zeroToThreeMonthsForm.controls.skin_care_Wipes.setValue(
        nannytask.skin_care_Wipes
      );
      this.zeroToThreeMonthsForm.controls.bottle_sterilization_all.setValue(
        nannytask.bottle_sterilization_all
      );
      if (nannytask.bottle_sterilization_all == true) {
        this.sterilizingAll = !this.sterilizingAll;
        this.zeroToThreeMonthsForm.controls.bottle_sterilization_all_time.disable();
        this.zeroToThreeMonthsForm.controls.bottle_sterilization_all_time.setValue(
          this.utility_service.parseISOString(
            nannytask.bottle_sterilization_all_time
          )
        );
        this.zeroToThreeMonthsForm.controls.actual_sterilization_all_time.setValue(
          this.utility_service.parseISOString(
            nannytask.actual_sterilization_all_time
          )
        );
        this.zeroToThreeMonthsForm.controls.onetime_Remark.setValue(nannytask.onetime_Remark);
        this.zeroToThreeMonthsForm.controls.done_sterilization_onetime.setValue(nannytask.done_sterilization_onetime);
      }


      if (nannytask.bottle_sterilization_onetime) {
        this.zeroToThreeMonthsForm.controls.bottle_sterilization_onetime.setValue(
          nannytask.bottle_sterilization_onetime
        );
        this.sterilizingOne = false;
      }
      if (nannytask.skin_care_Wipes == '1') {
        this.zeroToThreeMonthsForm.controls['skin_care_special_instructions']
          .enable;
        this.zeroToThreeMonthsForm.controls.skin_care_special_instructions.setValue(
          nannytask.skin_care_special_instructions
        );
      } else {
        this.zeroToThreeMonthsForm.controls[
          'skin_care_special_instructions'
        ].disable();
      }
      this.zeroToThreeMonthsForm.controls.changing_colthes_spiecal_instructions.setValue(
        nannytask.changing_colthes_spiecal_instructions
      );
      this.zeroToThreeMonthsForm.controls.remarks.setValue(nannytask.remarks);
      if (nannytask.sleeping_mother == true) {
        this.withMother = !this.withMother;
        this.zeroToThreeMonthsForm.controls.sleeping_mother_time.enable();
        this.zeroToThreeMonthsForm.controls.sleeping_mother_remark.enable();
        this.zeroToThreeMonthsForm.controls.sleeping_mother.setValue(
          nannytask.sleeping_mother
        );
        this.zeroToThreeMonthsForm.controls.sleeping_mother_time.setValue(
          this.utility_service.parseISOString(nannytask.sleeping_mother_time)
        );
        this.zeroToThreeMonthsForm.controls.sleeping_mother_actual_time.setValue(
          this.utility_service.parseISOString(nannytask.sleeping_mother_actual_time)
        );
        this.zeroToThreeMonthsForm.controls.sleeping_mother_remark.setValue(
          this.utility_service.parseISOString(nannytask.sleeping_mother_remark)
        );
      } else {
        // this.zeroToThreeMonthsForm.controls.sleeping_mother_time.disable();
        // this.zeroToThreeMonthsForm.controls.sleeping_mother_remark.disable();
      }
      if (nannytask.sleeping_nanny == true) {
        this.withNanny = !this.withNanny;
        this.zeroToThreeMonthsForm.controls.sleeping_nanny_time.enable();
        this.zeroToThreeMonthsForm.controls.sleeping_nanny_remark.enable();

        this.zeroToThreeMonthsForm.controls.sleeping_nanny.setValue(
          nannytask.sleeping_nanny
        );
        this.zeroToThreeMonthsForm.controls.sleeping_nanny_time.setValue(
          this.utility_service.parseISOString(nannytask.sleeping_nanny_time)
        );
        this.zeroToThreeMonthsForm.controls.sleeping_nanny_actual_time.setValue(
          this.utility_service.parseISOString(nannytask.sleeping_nanny_actual_time)
        );
      } else {
        this.zeroToThreeMonthsForm.controls.sleeping_nanny_time.disable();
      }

      if (nannytask.bathing_tub == true) {
        // this.tubBath = !this.tubBath;
        this.zeroToThreeMonthsForm.controls['bathing_tub_time'].enable();
        this.zeroToThreeMonthsForm.controls['bathing_tub_frequency'].enable();
        // this.zeroToThreeMonthsForm.controls.bathing_tub_time.setValue(
        //   this.utility_service.parseISOString(nannytask.bathing_tub_time)
        // );
        this.zeroToThreeMonthsForm.controls.bathing_tub_frequency.setValue(
          nannytask.bathing_tub_frequency
        );
      }

      if (nannytask.bathing_sponge == true) {
        // this.spongeBath = !this.spongeBath;
        // this.zeroToThreeMonthsForm.controls['bathing_sponge_time'].enable();
        this.zeroToThreeMonthsForm.controls[
          'bathing_frequency_sponge'
        ].enable();
        //   this.utility_service.parseISOString(nannytask.bathing_sponge_time)
        // );
        this.zeroToThreeMonthsForm.controls.bathing_frequency_sponge.setValue(
          nannytask.bathing_frequency_sponge
        );
      }


      // bathing section start
      const spongetime = res.data[0].nanny_task_details_json.bathing_time;

      for (let i = 0; i < spongetime?.length; i++) {
        this.isBathing = true;
        let CalculationArray = this.getbathingarray();
        let time = spongetime[i] || {};
        const newCalculationGroup = this.fb.group({
          bathing_sponge_time: new FormControl(
            new Date(time.bathing_sponge_time) || ''
          ),
          sponge_checkbox: new FormControl(time.sponge_checkbox || ''),
          spongeBathing_remark: new FormControl(time.spongeBathing_remark || ''),
          bathing_sponge_actual_time: new FormControl(
            time.bathing_sponge_actual_time ? new Date(time.bathing_sponge_actual_time) : ''
          )
        });
        CalculationArray.push(newCalculationGroup);
      }
      const tubtime = res.data[0].nanny_task_details_json.tub_bathing;
      for (let i = 0; i < tubtime?.length; i++) {
        this.isBathingtub = true;
        let CalculationArray = this.getbathingtubarray();
        let time = tubtime[i] || {};

        const newCalculationGroup = this.fb.group({
          bathing_tub_time: new FormControl(
            new Date(time.bathing_tub_time) || ''
          ),
          bathing_tub_actual_time: new FormControl(
            time.bathing_tub_actual_time ? new Date(time.bathing_tub_actual_time) : ''
          ),
          tub_checkbox: new FormControl(time.tub_checkbox || ''),
          tubBathing_remark: new FormControl(time.tubBathing_remark || '')
        });
        CalculationArray.push(newCalculationGroup);
      }
      //feeding multiple time fields
      const breastime = res.data[0].nanny_task_details_json.breast_feeding;
      for (let i = 0; i < breastime?.length; i++) {
        this.isBreast = true;
        let CalculationArray = this.getbreastarray();
        let time = breastime[i] || {};

        const newCalculationGroup = this.fb.group({
          feeding_breast_time: new FormControl(
            new Date(time.feeding_breast_time) || ''
          ),
          feeding_breast_checkbox: new FormControl(time.feeding_breast_checkbox || ''),
          feeding_breast_actual_time: new FormControl(
            time.feeding_breast_actual_time ? new Date(time.feeding_breast_actual_time) : null
          ),
          breast_feeding_remark: new FormControl(time.breast_feeding_remark || '')
        });
        CalculationArray.push(newCalculationGroup);
      }
      this.disableForm();
      this.isSettingValueProgrammatically = false;
    }, (error: any) => {
      this.spinner.hide()

      // Handle any errors that occurred during the API call
      //.error('API Error:', error);

    });

  }

  getSleepArray(): FormArray {
    return this.zeroToThreeMonthsForm.get('sleeping_time') as FormArray;
  }
  getSleepnannyArray(): FormArray {
    return this.zeroToThreeMonthsForm.get('nanny_time') as FormArray;
  }

  addsleeptimeObject(string: any, index: any) {
    let vitaminArray = this.getSleepArray();
    if (string == 'add') {
      let newVitaminArray = new FormGroup({
        sleeping_mother: new FormControl(''),
        sleeping_mother_time: new FormControl(''),
        sleeping_mother_actual_time: new FormControl(''),
        sleeping_mother_remark: new FormControl(''),
      });

      // let formatedTime =  moment(newTime).format('HH:mm:ss');
      vitaminArray.insert(index + 1, newVitaminArray);
    } else {
      vitaminArray.removeAt(index);
    }
  }
  addnannyObject(string: any, index: any) {
    let vitaminArray = this.getSleepnannyArray();
    if (string == 'add') {
      let newVitaminArray = new FormGroup({
        sleeping_nanny: new FormControl(''),
        sleeping_nanny_time: new FormControl(''),
        sleeping_nanny_actual_time: new FormControl(''),
        sleeping_nanny_remark: new FormControl(''),
      });

      // let formatedTime =  moment(newTime).format('HH:mm:ss');
      vitaminArray.insert(index + 1, newVitaminArray);
    } else {
      vitaminArray.removeAt(index);
    }
  }

  getDiaperChangeControls(): FormArray {
    return this.zeroToThreeMonthsForm.get('diaper_change_times') as FormArray;
  }
  // clearDiaperControls() {
  //   (
  //     this.zeroToThreeMonthsForm.get('diaper_change_times') as FormArray
  //   ).clear();
  // }

  // getTimeLabel(index: number): string {
  //   let changetime = this.getDiaperChangeControls();
  //   let time = new Date(changetime.at(index).get('time').value);
  //   return time.toLocaleTimeString('en-US', {
  //     hour: '2-digit',
  //     minute: '2-digit',
  //   });
  // }

  getAllUserPermission() {
    let id = localStorage.getItem('user_id');
    this.masterService.getUserPremissionById(id).then((res: any) => {

      this.permissionData = res.data;

      for (let i = 0; i < this.permissionData?.length; i++) {
        this.permissions.push(this.permissionData[i].permission_id);
      }
    })
  }

  checkPermission(permission: number) {
    if (this.permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
    // return this.permissions.includes(permission);
  }

  // fuction for check if variable isnot set
  isset(variable) {
    if (variable != 'undefined' && variable != null && variable != '') {
      return true;
    } else {
      return false;
    }
  }

  // get feeding array
  getFormulaCalculationControls(): FormArray {
    return this.zeroToThreeMonthsForm.get('formula_calculation') as FormArray;
  }

  // get bottle_sterilization array
  getbottle_sterilizationControls(): FormArray {
    return this.zeroToThreeMonthsForm.get('bottle_sterilization') as FormArray;
  }

  // clear feeding array
  clearFormulaCalculation() {
    (
      this.zeroToThreeMonthsForm.get('formula_calculation') as FormArray
    ).clear();
  }

  // clear Bottle Sterilization array
  clearbottle_sterilizationControls() {
    (
      this.zeroToThreeMonthsForm.get('bottle_sterilization') as FormArray
    ).clear();
  }

  // add new object in feeding array
  addFeedingCalculation(time: any): void {
    const feedingCalculationArray = this.getFormulaCalculationControls();

    const newFeedingCalculationGroup = this.fb.group({
      formula_timeArray: new FormControl(time),
      nurse_feeding: new FormControl(),
      is_same_formula: new FormControl(null),
      remark: new FormControl(null),
      amount: new FormControl(null),
      formula_remark: new FormControl(null),
      feeding_checkbox: new FormControl(null)
    });
    feedingCalculationArray.push(newFeedingCalculationGroup);
  }
  clearbathingcalculation() {
    (this.zeroToThreeMonthsForm.get('bathing_time') as FormArray).clear();
  }
  clearbathingtubcalculation() {
    (this.zeroToThreeMonthsForm.get('tub_bathing') as FormArray).clear();
  }
  clearmedicinecalculation(i) {
    ((this.zeroToThreeMonthsForm.get('vitamins_medications_json') as FormArray).at(i).get('medicine_time') as FormArray).clear();
  }
  addbathingcalculation(): void {
    const bathingcalculation = this.getbathingarray();
    const bathingcalculationgroup = this.fb.group({
      bathing_sponge_time: new FormControl(),
      sponge_checkbox: new FormControl(null),
      spongeBathing_remark: new FormControl(null),
      bathing_sponge_actual_time: new FormControl(),
    });

    bathingcalculation.push(bathingcalculationgroup);
  }
  addmedicationcalculation(index: number): void {

    const viamincalc = (this.zeroToThreeMonthsForm.get('vitamins_medications_json') as FormArray).at(index).get('medicine_time') as FormArray;
    const bathingcalculationgroup = this.fb.group({
      time: new FormControl(),
      vitamin_checkbox: new FormControl(null),
      vitamin_remark: new FormControl(null),
      actual_time: new FormControl(),
    });
    viamincalc.push(bathingcalculationgroup);
  }
  addbathingtubcalculation(): void {
    const bathingcalculationtub = this.getbathingtubarray();
    const bathingcalculationgroup1 = this.fb.group({
      bathing_tub_time: new FormControl(),
      bathing_tub_actual_time: new FormControl(),
      tub_checkbox: new FormControl(),
      tubBathing_remark: new FormControl()
    });
    bathingcalculationtub.push(bathingcalculationgroup1);
  }
  // add new object in Bottle Sterilization array
  addbottle_sterilization(time: any): void {
    const bottle_sterilizationArray = this.getbottle_sterilizationControls();

    const newbottle_sterilizationGroup = this.fb.group({
      done_sterilization: new FormControl(null),
      sterilization_timeArray: new FormControl(time),
      actual_sterilization_timeArray: new FormControl(null),
      sterilization_remark: new FormControl(null),
    });
    bottle_sterilizationArray.push(newbottle_sterilizationGroup);
  }

  setbottle_sterilizationfunction(time) {
    time.setHours(time.getHours() - 2);
    this.zeroToThreeMonthsForm
      .get('bottle_sterilization_onetime_time')
      .setValue(new Date(time));
  }

  // auto generate feeding time and remark fields
  createfields() {
    if (!this.isSettingValueProgrammatically) {
      let start = new Date(
        this.zeroToThreeMonthsForm.controls.feeding_time.value
      );
      let end = new Date(
        this.zeroToThreeMonthsForm.controls.feeding_breast_time_end.value
      );
      let frequency =
        this.zeroToThreeMonthsForm.controls.feeding_frequency.value.frequency;
      let setbottle_sterilization = new Date(
        this.zeroToThreeMonthsForm.controls.feeding_time.value
      );

      if (
        this.isset(this.zeroToThreeMonthsForm.controls.feeding_time.value) &&
        this.isset(
          this.zeroToThreeMonthsForm.controls.feeding_breast_time_end.value
        ) &&
        this.isset(frequency)
      ) {

        this.setbottle_sterilizationfunction(setbottle_sterilization);
        let array = this.getFormulaCalculationControls();
        this.isFormula = true;
        let updated = start;
        this.clearFormulaCalculation();
        this.clearbottle_sterilizationControls();
        if (start > end) {
          end.setDate(end.getDate() + 1);
        }
        while (updated < end) {
          if (updated < end) {
            // let time = updated.getHours()+':'+updated.getMinutes();

            let time = new Date();

            time.setHours(updated.getHours());
            time.setMinutes(updated.getMinutes());

            let sterilization_time = new Date();

            sterilization_time.setHours(updated.getHours() - 2);
            sterilization_time.setMinutes(updated.getMinutes());

            this.addFeedingCalculation(time);
            this.addbottle_sterilization(sterilization_time);
          }
          updated.setHours(updated.getHours() + frequency);
        }
      }
    }
  }

  getId() {
    this.planId = this.utility_service.getData(this.breadcrumb[0].title);
  }


  /* -------------------------------------------------------------------------- */
  /*               // get Baby Details on Baby name click function              */
  /* -------------------------------------------------------------------------- */
  // getBabyDetails(event: any) {
  //   let data = this.babyDetails?.filter((res: any) => res?.id == event?.value);
  //   let firstLetter = data[0]?.gender.toLowerCase();
  //   let capitalize =
  //     firstLetter.slice(0, 1).toUpperCase() +
  //     firstLetter.slice(1, firstLetter?.length);
  //   this.babyGender = capitalize;
  //   // this.selectedDate = moment(
  //   //   data[0]?.date_of_birth,
  //   //   moment.defaultFormat
  //   // ).toDate();
  //   let formatedDate: any = data[0].date_of_birth
  //   this.selectedDate = formatedDate;
  //   const selectedDate = moment(
  //     data[0]?.date_of_birth
  //   );
  //   const today = moment();
  //   // Calculate the difference in years, months, and days
  //   const years = today.diff(selectedDate, 'years');
  //   selectedDate.add(years, 'years');

  //   const months = today.diff(selectedDate, 'months');
  //   selectedDate.add(months, 'months');

  //   const days = today.diff(selectedDate, 'days');

  //   this.years = years;
  //   this.month = months;
  //   this.days = days;
  // }


  vitamin() {
    // this.zeroToThreeMonthsForm.controls['time'].setValidators([Validators.required]);

    let control = this.getVitaminFormArray().controls;
  }

  getBabyDetails(event: any) {
    let data = this.babyDetails?.filter((res: any) => res?.id == event?.value);
    let firstLetter = data[0]?.gender.toLowerCase();
    let capitalize =
      firstLetter.slice(0, 1).toUpperCase() +
      firstLetter.slice(1, firstLetter?.length);
    this.babyGender = capitalize;
    this.selectedDate = data[0].date_of_birth;
    // Manually parse the date string in DD/MM/YYYY format
    const dateParts = data[0].date_of_birth.split('/');
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Months are 0-indexed in JavaScript
    const year = parseInt(dateParts[2], 10);

    const selectedDate = moment([year, month, day]);
    const today = moment();

    // Calculate the difference in years, months, and days
    const years = today.diff(selectedDate, 'years');
    selectedDate.add(years, 'years');

    const months = today.diff(selectedDate, 'months');
    selectedDate.add(months, 'months');

    const days = today.diff(selectedDate, 'days');

    this.years = years;
    this.month = months;
    this.days = days;
  
    if (
      (this.years == 0 && this.month < 3) ||
      (this.days == 0 && this.month == 3 && this.years == 0)
    ) {
      this.flag = true;
    } else {
      this.flag = false;
      this.messageService.add({
        severity: 'info',
        summary: 'Info',
        detail:
          'Your child does not meet the eligibility criteria for this plan.',
      });
    }
  }

  handleInput(event: any, value: string) {
    if (value == 'feeding') {
      if (event.target.value == 'breastFeeding') {
        this.feedingBreast = !this.feedingBreast;
        if (this.feedingBreast == true) {
          this.zeroToThreeMonthsForm.controls[
            'feeding_frequency_breast'
          ].setValue('');
          this.zeroToThreeMonthsForm.controls['feeding_breast_time'].setValue(
            ''
          );

          // this.zeroToThreeMonthsForm.controls['feeding_frequency_breast'].setValidators([Validators.required]);
          // this.zeroToThreeMonthsForm.controls['feeding_frequency_breast'].updateValueAndValidity();
        }
        // else{
        //   this.zeroToThreeMonthsForm.controls['feeding_frequency_breast'].clearValidators();
        // }
        if (event.target.checked) {
          this.zeroToThreeMonthsForm.controls[
            'feeding_frequency_breast'
          ].setValidators([Validators.required]);

          // this.zeroToThreeMonthsForm.controls[
          //   'feeding_breast_time'
          // ].setValidators([Validators.required]);
        } else {
          this.zeroToThreeMonthsForm.controls[
            'feeding_frequency_breast'
          ].clearValidators();
          this.zeroToThreeMonthsForm.controls[
            'feeding_breast_time'
          ].clearValidators();
        }
        this.zeroToThreeMonthsForm.controls[
          'feeding_frequency_breast'
        ].updateValueAndValidity();
        this.zeroToThreeMonthsForm.controls[
          'feeding_breast_time'
        ].updateValueAndValidity();
      } else if (event.target.value == 'formulaFeeding') {
        this.feedingFormula = !this.feedingFormula;
        if (this.feedingFormula == true) {
          this.zeroToThreeMonthsForm.controls['feeding_frequency'].setValue('');
          this.zeroToThreeMonthsForm.controls['feeding_time'].setValue('');
          this.zeroToThreeMonthsForm.controls[
            'feeding_breast_time_end'
          ].setValue('');
          // this.zeroToThreeMonthsForm.controls['feeding_time'].disable();
          this.zeroToThreeMonthsForm.controls['feeding_amount_per'].disable();
          this.zeroToThreeMonthsForm.controls['type_of_formula'].disable();
          this.zeroToThreeMonthsForm.controls['feeding_amount_per'].setValue(
            ''
          );
          this.zeroToThreeMonthsForm.controls['type_of_formula'].setValue('');
        } else {
          // this.zeroToThreeMonthsForm.controls['feeding_time'].enable();
          this.zeroToThreeMonthsForm.controls['feeding_amount_per'].enable();
          this.zeroToThreeMonthsForm.controls['type_of_formula'].enable();
          this.zeroToThreeMonthsForm.controls[
            'feeding_breast_time_end'
          ].enable();
        }
        if (event.target.checked) {
          this.zeroToThreeMonthsForm.controls[
            'feeding_frequency'
          ].setValidators([Validators.required]);
          this.zeroToThreeMonthsForm.controls[
            'feeding_breast_time_end'
          ].setValidators([Validators.required]);
          this.zeroToThreeMonthsForm.controls['feeding_time'].setValidators([
            Validators.required,
          ]);
          this.zeroToThreeMonthsForm.controls[
            'feeding_amount_per'
          ].setValidators([Validators.required]);
          this.zeroToThreeMonthsForm.controls['type_of_formula'].setValidators([
            Validators.required,
          ]);
        } else {
          this.zeroToThreeMonthsForm.controls[
            'feeding_frequency'
          ].clearValidators();
          this.zeroToThreeMonthsForm.controls['feeding_time'].clearValidators();
          this.zeroToThreeMonthsForm.controls[
            'feeding_amount_per'
          ].clearValidators();
          this.zeroToThreeMonthsForm.controls[
            'type_of_formula'
          ].clearValidators();
          this.zeroToThreeMonthsForm.controls[
            'feeding_breast_time_end'
          ].clearValidators();
        }
        this.zeroToThreeMonthsForm.controls[
          'feeding_frequency'
        ].updateValueAndValidity();
        this.zeroToThreeMonthsForm.controls[
          'feeding_time'
        ].updateValueAndValidity();
        this.zeroToThreeMonthsForm.controls[
          'feeding_amount_per'
        ].updateValueAndValidity();
        this.zeroToThreeMonthsForm.controls[
          'type_of_formula'
        ].updateValueAndValidity();
        this.zeroToThreeMonthsForm.controls[
          'feeding_breast_time_end'
        ].updateValueAndValidity();
      }
    } else if (value == 'sterilization') {
      if (event.target.value == 'all') {
        this.sterilizingAll = !this.sterilizingAll;
        if (this.sterilizingAll == true) {
          this.zeroToThreeMonthsForm.controls[
            'bottle_sterilization_all_time'
          ].setValue(null);
        }
        if (event.target.checked) {
          this.zeroToThreeMonthsForm.controls[
            'bottle_sterilization_all_time'
          ].setValidators([Validators.required]);
        } else {
          this.zeroToThreeMonthsForm.controls[
            'bottle_sterilization_all_time'
          ].clearValidators();
        }
        this.zeroToThreeMonthsForm.controls[
          'bottle_sterilization_all_time'
        ].updateValueAndValidity();
      } else if (event.target.value == 'one') {
        this.sterilizingOne = !this.sterilizingOne;
        if (this.sterilizingOne == true) {
          this.zeroToThreeMonthsForm.controls[
            'bottle_sterilization_onetime_time'
          ].setValue(null);
        }
        if (event.target.checked) {
          // this.zeroToThreeMonthsForm.controls['bottle_sterilization_onetime_time'].setValidators([Validators.required]);
        } else {
          this.zeroToThreeMonthsForm.controls[
            'bottle_sterilization_onetime_time'
          ].clearValidators();
        }
        this.zeroToThreeMonthsForm.controls[
          'bottle_sterilization_onetime_time'
        ].updateValueAndValidity();
      }
    } else if (value == 'fnmAllergy') {
      if (event.target.value == '1') {
        this.zeroToThreeMonthsForm.controls.food_medicine_allergy_specify_prod.enable();
      } else {
        this.zeroToThreeMonthsForm.controls.food_medicine_allergy_specify_prod.disable();
        this.zeroToThreeMonthsForm.controls.food_medicine_allergy_specify_prod.setValue(
          null
        );
      }
      if (event.target.checked) {
        this.zeroToThreeMonthsForm.controls[
          'food_medicine_allergy_specify_prod'
        ].setValidators([Validators.required]);
      } else {
        this.zeroToThreeMonthsForm.controls[
          'food_medicine_allergy_specify_prod'
        ].clearValidators();
      }
      this.zeroToThreeMonthsForm.controls[
        'food_medicine_allergy_specify_prod'
      ].updateValueAndValidity();
    } else if (value == 'wipes') {
      if (event.target.value == '1') {
        this.zeroToThreeMonthsForm.controls[
          'skin_care_special_instructions'
        ].enable();
        this.hideWipes = false;
      } else if (event.target.value == '0') {
        this.hideWipes = true;
        this.zeroToThreeMonthsForm.controls[
          'skin_care_special_instructions'
        ].setValue(null);
        this.zeroToThreeMonthsForm.controls[
          'skin_care_special_instructions'
        ].disable();
      }
      if (event.target.checked) {
        this.zeroToThreeMonthsForm.controls[
          'skin_care_special_instructions'
        ].setValidators([Validators.required]);
      } else {
        this.zeroToThreeMonthsForm.controls[
          'skin_care_special_instructions'
        ].clearValidators();
      }
      this.zeroToThreeMonthsForm.controls[
        'skin_care_special_instructions'
      ].updateValueAndValidity();
    } else if (value == 'bathing') {
      if (event.target.value == 'Sponge') {
        // this.spongeBath = !this.spongeBath;
        if (this.spongeBath == true) {
          this.zeroToThreeMonthsForm.controls[
            'bathing_frequency_sponge'
          ].setValue(null);
          this.zeroToThreeMonthsForm.controls['bathing_sponge_time'].setValue(
            ''
          );
        }
        if (event.target.checked) {
          this.zeroToThreeMonthsForm.controls[
            'bathing_frequency_sponge'
          ].setValidators([Validators.required]);
          // this.zeroToThreeMonthsForm.controls[
          //   'bathing_sponge_time'
          // ].setValidators([Validators.required]);
        } else {
          this.zeroToThreeMonthsForm.controls[
            'bathing_frequency_sponge'
          ].clearValidators();
          this.zeroToThreeMonthsForm.controls[
            'bathing_sponge_time'
          ].clearValidators();
        }
        this.zeroToThreeMonthsForm.controls[
          'bathing_frequency_sponge'
        ].updateValueAndValidity();
        this.zeroToThreeMonthsForm.controls[
          'bathing_sponge_time'
        ].updateValueAndValidity();
      } else if (event.target.value == 'tubBath') {
        // this.tubBath = !this.tubBath;
        if (this.tubBath == true) {
          this.zeroToThreeMonthsForm.controls['bathing_tub_frequency'].setValue(
            null
          );
          this.zeroToThreeMonthsForm.controls['bathing_tub_time'].setValue(
            null
          );
          this.zeroToThreeMonthsForm.controls['bathing_tub_actual_time'].setValue(
            null
          );
        }
        if (event.target.checked) {
          this.zeroToThreeMonthsForm.controls[
            'bathing_tub_frequency'
          ].setValidators([Validators.required]);
          // this.zeroToThreeMonthsForm.controls['bathing_tub_time'].setValidators(
          //   [Validators.required]
          // );
        } else {
          this.zeroToThreeMonthsForm.controls[
            'bathing_tub_frequency'
          ].clearValidators();
          this.zeroToThreeMonthsForm.controls[
            'bathing_tub_time'
          ].clearValidators();
        }
        this.zeroToThreeMonthsForm.controls[
          'bathing_tub_frequency'
        ].updateValueAndValidity();
        this.zeroToThreeMonthsForm.controls[
          'bathing_tub_time'
        ].updateValueAndValidity();
      }
    }
    // else if (value == 'sleepingWith') {
    //   if (event.target.value == 'withMother') {
    //     this.withMother = !this.withMother;
    //     if (this.withMother == true) {
    //       this.zeroToThreeMonthsForm.controls['sleeping_mother_time'].setValue(
    //         null
    //       );
    //     }
    //     if (event.target.checked) {
    //       this.zeroToThreeMonthsForm.controls[
    //         'sleeping_mother_time'
    //       ].setValidators([Validators.required]);
    //     } else {
    //       this.zeroToThreeMonthsForm.controls[
    //         'sleeping_mother_time'
    //       ].clearValidators();
    //     }
    //     this.zeroToThreeMonthsForm.controls[
    //       'sleeping_mother_time'
    //     ].updateValueAndValidity();
    //   }
    //   if (event.target.value == 'withNanny') {
    //     this.withNanny = !this.withNanny;
    //     if (this.withNanny == true) {
    //       this.zeroToThreeMonthsForm.controls['sleeping_nanny_time'].setValue(
    //         null
    //       );
    //     }
    //     if (event.target.checked) {
    //       this.zeroToThreeMonthsForm.controls[
    //         'sleeping_nanny_time'
    //       ].setValidators([Validators.required]);
    //     } else {
    //       this.zeroToThreeMonthsForm.controls[
    //         'sleeping_nanny_time'
    //       ].clearValidators();
    //     }
    //     this.zeroToThreeMonthsForm.controls[
    //       'sleeping_nanny_time'
    //     ].updateValueAndValidity();
    //   }
    // }
  }

  /* -------------------------------------------------------------------------- */
  /*            //Soft Reloading            */
  /* -------------------------------------------------------------------------- */
  softReload() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['portal/patient-view']);
    });
  }
  /* -------------------------------------------------------------------------- */
  /*                        // submit Form functionality                        */
  /* -------------------------------------------------------------------------- */
  saveBabyPlanForm(formSubmitted: any) {
    this.zeroToThreeMonthsForm.enable();

    let structuredObject = {
      baby_details_id: this.zeroToThreeMonthsForm.get('baby_details_id').value,
      nurse_remarks:this.zeroToThreeMonthsForm.get('nurse_remarks').value,
      remarks:this.zeroToThreeMonthsForm.get('remarks').value,
      allergy:[
        {
          food_medicine_allergy:this.zeroToThreeMonthsForm.get('food_medicine_allergy').value,
          food_medicine_allergy_specify_prod:this.zeroToThreeMonthsForm.get('food_medicine_allergy_specify_prod').value,
        }
      ],
      common: [
        {
          Asleep: this.zeroToThreeMonthsForm.get('Asleep').value,
          LongPause:this.zeroToThreeMonthsForm.get('LongPause').value,
          rhythmical:this.zeroToThreeMonthsForm.get('rhythmical').value,
          swallowing:this.zeroToThreeMonthsForm.get('swallowing').value,
          Normal_skin:this.zeroToThreeMonthsForm.get('Normal_skin').value,
          weight:this.zeroToThreeMonthsForm.get('weight').value,
          Other_concerns:this.zeroToThreeMonthsForm.get('Other_concerns').value,
        }
      ],
      bathing:[
        {
          bathing_frequency_sponge:this.zeroToThreeMonthsForm.get('bathing_frequency_sponge').value,
          bathing_time:this.zeroToThreeMonthsForm.get('bathing_time').value,
          bathing_sponge:this.zeroToThreeMonthsForm.get('bathing_sponge').value,
          bathing_tub:this.zeroToThreeMonthsForm.get('bathing_tub').value,
          bathing_tub_frequency:this.zeroToThreeMonthsForm.get('bathing_tub_frequency').value,
          tub_bathing:this.zeroToThreeMonthsForm.get('tub_bathing').value
        }
      ],
      bottle_sterilization:[
        {
          bottle_sterilization:this.zeroToThreeMonthsForm.get('bottle_sterilization').value,
          bottle_sterilization_all:this.zeroToThreeMonthsForm.get('bottle_sterilization_all').value,
          bottle_sterilization_all_time:this.zeroToThreeMonthsForm.get('bottle_sterilization_all_time').value,
          bottle_sterilization_onetime:this.zeroToThreeMonthsForm.get('bottle_sterilization_onetime').value,
          bottle_sterilization_onetime_time:this.zeroToThreeMonthsForm.get('bottle_sterilization_onetime_time').value,
          done_sterilization_onetime:this.zeroToThreeMonthsForm.get('done_sterilization_onetime').value,
          actual_sterilization_all_time:this.zeroToThreeMonthsForm.get('actual_sterilization_all_time').value,
          onetime_Remark:this.zeroToThreeMonthsForm.get('onetime_Remark').value,
        }
      ],
     
       clothes:[
        {
        changing_clothes:this.zeroToThreeMonthsForm.get('changing_clothes').value,
        changing_colthes_spiecal_instructions:this.zeroToThreeMonthsForm.get('changing_colthes_spiecal_instructions').value,
       }
      ],
      diaper:[{
        diaper_calculation:this.zeroToThreeMonthsForm.get('diaper_calculation').value,
        diaper_change_frequency:this.zeroToThreeMonthsForm.get('diaper_change_frequency').value,
        diaper_label:this.zeroToThreeMonthsForm.get('diaper_label').value,
        diaper_label_end_time:this.zeroToThreeMonthsForm.get('diaper_label_end_time').value,
        // diaper_type:this.zeroToThreeMonthsForm.get('diaper_type').value,
        diaper_change_times:this.zeroToThreeMonthsForm.get('diaper_change_times').value,
      }],
      
      breastfeeding:[
        {
         feeding_frequency_breast:this.zeroToThreeMonthsForm.get('feeding_frequency_breast').value,
         breast_feeding:this.zeroToThreeMonthsForm.get('breast_feeding').value,
        }
      ],
      formula_feeding:[
        {
          formula_calculation:this.zeroToThreeMonthsForm.get('formula_calculation').value,
         feeding_frequency:this.zeroToThreeMonthsForm.get('feeding_frequency').value,
         feeding_breast_time_end:this.zeroToThreeMonthsForm.get('feeding_breast_time_end').value,
          feeding_time:this.zeroToThreeMonthsForm.get('feeding_time').value,
          feeding_amount_per:this.zeroToThreeMonthsForm.get('feeding_amount_per').value,
          type_of_formula:this.zeroToThreeMonthsForm.get('type_of_formula').value,
          // formula_remark:this.zeroToThreeMonthsForm.get('formula_remark').value,
             feeding_type: this.zeroToThreeMonthsForm.get('feeding_type').value,
        }
      ],
      prepared:[
        {
          feeding_prepared_mother:this.zeroToThreeMonthsForm.get('feeding_prepared_mother').value,
          feeding_prepared_nanny:this.zeroToThreeMonthsForm.get('feeding_prepared_nanny').value,
           feeding_prepared_other: this.zeroToThreeMonthsForm.get('feeding_prepared_other').value,
        }
      ],
      sleeping:[{
        sleeping_time:this.zeroToThreeMonthsForm.get('sleeping_time').value,
        nanny_time:this.zeroToThreeMonthsForm.get('nanny_time').value,
      }],
      skin:[{
        skin_care:this.zeroToThreeMonthsForm.get('skin_care').value,
      }],
      vitamin:[
        {
          vitamins_medications_json:this.zeroToThreeMonthsForm.get('vitamins_medications_json').value,
        }
      ],
      wipes:[
        {
          skin_care_special_instructions:this.zeroToThreeMonthsForm.get('skin_care_special_instructions').value,
          skin_care_Wipes:this.zeroToThreeMonthsForm.get('skin_care_Wipes').value,
        }
      ]
  };
    // return 0;
    this.spinner.show()
    Object.keys(this.zeroToThreeMonthsForm.controls).forEach((key) => { });
    window.scrollTo(0, 0);
    if (formSubmitted.valid) {
      // const formData = new FormData();
      // const formValue = this.zeroToThreeMonthsForm.value;
      let data = JSON.parse(JSON.stringify(this.zeroToThreeMonthsForm.value));
      data.vitamins_medications_json = JSON.stringify(
        data.vitamins_medications_json
      );
      data.vaccinations_json = JSON.stringify(data.vaccinations_json);

      let newTime = this.zeroToThreeMonthsForm.controls.vitamins_medications_json['value'];
      for (let i = 0; i < newTime?.length; i++) {
        data[newTime[i]] = data[newTime[i]]
          ? moment(data[newTime[i]]).format('hh:mm:ss')
          : null;
      }

      let timeArray = [
        'breast_feeding_time',
        'feeding_time',
        'sponge_bathing_time',
        'bathing_tub_time',
        'sleeping_mother_time',
        'sleeping_nanny_time',
        'bottle_sterilization_all_time',
        'bottle_sterilization_onetime_time',
        'time',
        'feeding_breast_time',
        'feeding_breast_time_end',
        'bathing_sponge_time',
      ];

      // Filter out fields with no values
      let payload = {
        nanny_task_details_json: JSON.stringify(this.zeroToThreeMonthsForm.value),
        baby_plan_id: 1,
        baby_details_id: this.zeroToThreeMonthsForm.value.baby_details_id,
        structured_json: JSON.stringify(structuredObject),
      };


      // this.spinner.show();
      let id = localStorage.getItem('nurseTaskEditID');
      if (this.isedit == true) {
        this.common_service.putEditNurseDailytask(payload, id).then(
          (response: any) => {
            this.spinner.hide()

            this.isSettingValueProgrammatically = true;
            // Handle the API response here
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: response?.message,
            });
            setTimeout(() => {
              this.router.navigateByUrl('/portal/first-screen')
            }, 2000);
            // this.spinner.hide();
            formSubmitted.reset();

          },
          (error: any) => {
            this.spinner.hide()

            // Handle any errors that occurred during the API call
            //.error('API Error:', error);
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error,
            });
            
          }
        );
      }
      else {
        this.common_service.postBabyDailyTaskDetail(payload).then(
          (response: any) => {
            this.spinner.hide()

            // Handle the API response here
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: response?.message,
            });
            setTimeout(() => {
              this.router.navigateByUrl('/portal/first-screen')
            }, 400);
            // this.spinner.hide();
            // formSubmitted.reset();
            this.years = '';
            this.month = '';
            this.days = '';
            this.babyGender = '';
            this.selectedDate = null;
          },
          (error: any) => {
            this.spinner.hide()

            // Handle any errors that occurred during the API call
            //.error('API Error:', error);
            this.messageService.add({
              severity: 'error',
              summary: error,
              detail: error,
            });
          }
        );
      }
    } else {
      this.spinner.hide()

      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Fill All the Details!',
      });
    }
  }

  addVitaminObject(string: any, index: any) {
    let vitaminArray = this.getVitaminFormArray();
    if (string == 'add') {
      let newVitaminArray = new FormGroup({
        vitamin: new FormControl(''),
        medicine_time: new FormArray([]),
        frequency: new FormControl(''),
      });

      // let formatedTime =  moment(newTime).format('HH:mm:ss');
      vitaminArray.insert(index + 1, newVitaminArray);
    } else {
      vitaminArray.removeAt(index);
    }
  }
  addVaccinationObject(string: any, index: any) {
    let vaccinationArray = this.getVaccinationFormArray();
    if (string == 'add') {
      let newVaccinationArray = new FormGroup({
        vaccination: new FormControl(''),
        date_administration: new FormControl(''),
      });
      vaccinationArray.insert(index + 1, newVaccinationArray);
    } else {
      vaccinationArray.removeAt(index);
    }
  }

  getVitaminFormArray() {
    return this.zeroToThreeMonthsForm.get(
      'vitamins_medications_json'
    ) as FormArray;
  }
  getVaccinationFormArray() {
    return this.zeroToThreeMonthsForm.get('vaccinations_json') as FormArray;
  }
  //add button function skin care
  addSkinObject(string: any, index: any) {
    let skinArray = this.getSkinFormArray();
    if (string == 'add') {
      let newskinArray = new FormGroup({
        skin_care_lotion: new FormControl(''),
        skin_care_baby_oil: new FormControl(''),
        skin_care_others: new FormControl(''),
        skin_care_specify_produ: new FormControl(''),
      });
      skinArray.insert(index + 1, newskinArray);
    } else {
      skinArray.removeAt(index);
    }
  }

  addNurseRemark(string: any, index: any) {
    let remarkArray = this.getNurseRemarks();
    const currentDate = new Date();

    let hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const seconds = currentDate.getSeconds();

    const amOrPm = hours >= 12 ? 'PM' : 'AM';

    if (hours > 12) {
      hours -= 12;
    }

    if (string == 'add') {
      let newremarkArray = new FormGroup({
        nurse_remark: new FormControl(''),
        nurse_remark_time: new FormControl(`${hours}:${minutes}:${seconds} ${amOrPm}`)
      });
      remarkArray.insert(index + 1, newremarkArray);
    } else {
      remarkArray.removeAt(index);
    }
  }
  addClothesObject(string: any, index: any) {
    let ClothesArray = this.getClothesFormArray();
    if (string == 'add') {
      let newclothesArray = new FormGroup({
        changing_colthes: new FormControl(''),
        Changing_time: new FormControl(''),
        clothe_remark: new FormControl(''),
        Changing_actual_time: new FormControl(''),
      });
      ClothesArray.insert(index + 1, newclothesArray);
    } else {
      ClothesArray.removeAt(index);
    }
  }
  adddiaperObject(string: any, index: any) {
    let diaperArray = this.getDiaperChangeControls();
    if (string == 'add') {
      let newdiaperArray = new FormGroup({
        change: new FormControl(''),
        time: new FormControl(''),
        diaper_remark: new FormControl(''),
      });
      diaperArray.insert(index + 1, newdiaperArray);
    } else {
      diaperArray.removeAt(index);
    }
  }
  getSkinFormArray() {
    return this.zeroToThreeMonthsForm.get('skin_care') as FormArray;
  }
  getNurseRemarks() {
    return this.zeroToThreeMonthsForm.get('nurse_remarks') as FormArray;
  }
  getbathingarray() {
    return this.zeroToThreeMonthsForm.get('bathing_time') as FormArray;
  }
  getClothesFormArray() {
    return this.zeroToThreeMonthsForm.get('changing_clothes') as FormArray;
  }
  getmedicinearray(index) {
    return (this.zeroToThreeMonthsForm.get('vitamins_medications_json') as FormArray).at(index).get('medicine_time') as FormArray;
  }
  addfield() {

    if (!this.isSettingValueProgrammatically) {
      let frequency =
        this.zeroToThreeMonthsForm.controls.bathing_frequency_sponge.value;


      if (this.isset(frequency)) {
     

        let array = this.getbathingarray();
        this.isBathing = true;
        this.clearbathingcalculation();

        for (let i = 0; i < frequency; i++) {

          // Create a new Date object representing the current time
          // let time = new Date();

          // // Assuming frequency is the number of hours you want to add
          // time.setHours(time.getHours() + frequency);

          // let sterilization_time = new Date();

          // // Assuming frequency is the number of hours you want to subtract
          // sterilization_time.setHours(time.getHours() - 2);

          this.addbathingcalculation();
        }
      }
    }
  }
  getbathingtubarray() {
    return this.zeroToThreeMonthsForm.get('tub_bathing') as FormArray;
  }
  addtubfield() {

    if (!this.isSettingValueProgrammatically) {
      let frequency =
        this.zeroToThreeMonthsForm.controls.bathing_tub_frequency.value;


      if (this.isset(frequency)) {
      

        let array = this.getbathingtubarray();
        this.isBathingtub = true;
        this.clearbathingtubcalculation();

        for (let i = 0; i < frequency; i++) {
          this.addbathingtubcalculation();
        }
      }
    }
  }
  getFrequency(index: number) {
    let vitaminFormArray = this.getVitaminFormArray();
    if (vitaminFormArray && vitaminFormArray.controls?.length > index) {
      let vitaminscontrols = vitaminFormArray.controls[index] as FormGroup;
      let frequency = vitaminscontrols.get('frequency').value;

      return frequency;
    }
    return null; // or any default value if needed
  }

  addmedicinefield(index: number) {
  

    if (!this.isSettingValueProgrammatically) {
      let frequency = this.getFrequency(index); // Replace 0 with the desired index

      if (this.isset(frequency)) {
        

        // let array = this.getmedicinearray();
        this.ismedicine = true;
        this.clearmedicinecalculation(index);

        for (let i = 0; i < frequency; i++) {
        
          this.addmedicationcalculation(index);
        }
      }
    }
  }
  getbreastarray() {
    return this.zeroToThreeMonthsForm.get('breast_feeding') as FormArray;
  }
  clearbreastcalculation() {
    (this.zeroToThreeMonthsForm.get('breast_feeding') as FormArray).clear();
  }
  addbreastcalculation(): void {
    const breastcalculation = this.getbreastarray();
    const breastcalculationgroup = this.fb.group({
      feeding_breast_time: new FormControl(),
      feeding_breast_checkbox: new FormControl(),
      feeding_breast_actual_time: new FormControl(),
      breast_feeding_remark: new FormControl()
    });
    breastcalculation.push(breastcalculationgroup);
  }
  addbreastfield() {
  
    if (!this.isSettingValueProgrammatically) {
      let frequency =
        this.zeroToThreeMonthsForm.controls.feeding_frequency_breast.value;


      if (this.isset(frequency)) {
      

        let array = this.getbreastarray();
        this.isBreast = true;
        this.clearbreastcalculation();

        for (let i = 0; i < frequency; i++) {

          this.addbreastcalculation();
        }
      }
    }
  }

  getDiaperChangeControlsfrequency(): FormArray {
    return this.zeroToThreeMonthsForm.get('diaper_changes') as FormArray;
  }
  clearDiaperControls() {
    (
      this.zeroToThreeMonthsForm.get('diaper_changes') as FormArray
    ).clear();
  }


  getTimeLabel(index: number): string {
    let changetime = this.getDiaperChangeControlsfrequency();
    let time = new Date(changetime.at(index).get('diaper_label').value);
    return time.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  CheckFormula(event: any, controls) {
    if (event == 'Yes') {
      controls.get('remark').disable();
    }
    else {
      controls.get('remark').enable();
    }
  }

  DiaperChange(event: any) {
    if (!this.isSettingValueProgrammatically) {
      let start = new Date();
      let end = new Date();
      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 59, 999);
      this.clearDiaperControls();
      let updated = start;

      while (updated < end) {
        if (updated < end) {
          // let time = updated.getHours()+':'+updated.getMinutes();

          let time = new Date();

          time.setHours(updated.getHours());
          time.setMinutes(updated.getMinutes());

          this.addDiaperChange(time);
        }
        updated.setHours(updated.getHours() + event);
      }
    }
  }
  addDiaperChange(time: any): void {
    const CalculationArray = this.getDiaperChangeControlsfrequency();

    const newCalculationGroup = this.fb.group({
      isDiaperChanged: new FormControl(null),
      diaper_label: new FormControl(time),
      diaper_actual_time: new FormControl(time),
      diaperRemark: new FormControl('')

    });
    CalculationArray.push(newCalculationGroup);
  }

  addValidator(index: number, event: any, value: string) {

    this.control = this.getDiaperChangeControls().at(index).get('time');
    if (value === 'addDiaper' && event.target.checked) {
      this.control.setValidators([Validators.required]);
    }
    else {
      this.control.clearValidators();
    }
    this.control.updateValueAndValidity();
  }

  disableForm() {
    const formData = this.zeroToThreeMonthsForm.value;
    for (let key in formData) {
      if ((formData[key] !== null && formData[key] !== ' ') && formData[key] !== undefined) {
        if (Array.isArray(formData[key])) {
          let isenabled = true;
          for (let nestedKey in formData[key]) {
            // if (nestedKey !== '0' && formData[key][nestedKey] !== null && formData[key][nestedKey] !== undefined) {
            const control = this.zeroToThreeMonthsForm.get(`${key}.${nestedKey}`);
            // if(isenabled == false && (formData[key][nestedKey] == null || formData[key][nestedKey] == "")) {
            if (key == "vitamins_medications_json") {

              for (let innerKey in formData[key][nestedKey]) {
                if (Array.isArray(formData[key][nestedKey][innerKey])) {
                  const innercontrol = this.zeroToThreeMonthsForm.get(`${key}.${nestedKey}.${innerKey}`);
                  let isenabled1 = true;
                  for (let innergroup in formData[key][nestedKey][innerKey]) {
                    const innercontrol1 = this.zeroToThreeMonthsForm.get(`${key}.${nestedKey}.${innerKey}.${innergroup}`);
                    if (innercontrol1.get('vitamin_checkbox').value != true && isenabled1 && innercontrol1.get('vitamin_remark').value == null) {
                      if (innercontrol1) {
                        innercontrol1.enable();
                        isenabled1 = false;
                      }
                    }
                    else {
                      if (innercontrol1) {
                        innercontrol1.disable();
                      }
                    }
                  }

                }
              }

            }
            // else if(key=="nurse_remarks"){
            //   if(control.get('nurse_remark').value != null){
                
            //     control.get('nurse_remark').disable();
            //   }
            // }
            else if(key == "nurse_remarks") {
              const nurseRemarkControl = control.get('nurse_remark');
              const nurseRemarkValue = nurseRemarkControl.value;
          
          
              if (nurseRemarkValue !== null && nurseRemarkValue !== '') {
                  nurseRemarkControl.disable();
              } else {
                  nurseRemarkControl.enable();
              }
          }
          
            else if (this.checkcontrol(control, ["feeding_breast_checkbox","done_sterilization_onetime", "feeding_checkbox", "sleeping_mother", "done_sterilization", "isDiaperChanged", "change", "sponge_checkbox", "tub_checkbox", "changing_colthes", "vitamin_checkbox", "sleeping_nanny",'diaper_checkbox'], ['breast_feeding_remark','breast_feeding_remark', 'formula_remark', 'sleeping_mother_remark','sterilization_remark','diaper_remark', 'diaper_remark','spongeBathing_remark','tubBathing_remark','clothe_remark','vitamin_remark','sleeping_nanny_remark','diaperRemark']) && isenabled) {
              if (control) {
                control.enable();
                isenabled = false;
              }
            }
            else {
              if (control) {
                control.disable();
              }
            }
          }
        } else {
          const control = this.zeroToThreeMonthsForm.get(key);
          if (control) {
            // control.disable();
          }
        }
      }
    }
  }

  checkcontrol(control, name,remarks) {
    let a
    // let value = name.forEach((key) => {
    // let filteredKeys = control.value.filter((item) => {
    //     return item === key;
    // });
    // a.push(filteredKeys); 
    // return control.get(key)?.value != true && control.get(key)?.value != undefined;
    // });

    // return value;


    for (let i = 0; i < name?.length; i++) {
      if (control.get(name[i])?.value != true && control.get(name[i])?.value != undefined) {
        
for (let j = 0; j < remarks?.length; j++) {
  if (control.get(remarks[j])?.value != '' && control.get(remarks[j])?.value != undefined) {
    return false;
  }
}
        return true;
      }
    }
    return false;


    // return control.get(a)?.value != true && control.get(a)?.value != undefined;
  }
  formatDateTime(date: string | undefined): string {
    if (date) {
      const formattedDate = new Date(date);
      // const year = formattedDate.getFullYear();
      // const month = ('0' + (formattedDate.getMonth() + 1)).slice(-2);
      // const day = ('0' + formattedDate.getDate()).slice(-2);
      const hours = ('0' + formattedDate.getHours()).slice(-2);
      const minutes = ('0' + formattedDate.getMinutes()).slice(-2);
      const seconds = ('0' + formattedDate.getSeconds()).slice(-2);

      return `${hours}:${minutes}:${seconds}`;
      // return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }
    return '-';
  }
  cleardiaperCalculation() {
    (this.zeroToThreeMonthsForm.get('diaper_calculation') as FormArray).clear();
  }
  getdiaperCalculationControls(): FormArray {
    return this.zeroToThreeMonthsForm.get('diaper_calculation') as FormArray;
  }
  adddiaperCalculation(time: any): void {
    const feedingCalculationArray = this.getdiaperCalculationControls();

    const newFeedingCalculationGroup = this.fb.group({
      diaper_timeArray: new FormControl(time),
      diaper_actual_time: new FormControl(time),
      remark: new FormControl(null),
      amount: new FormControl(null),
      diaperRemark: new FormControl(null),
      diaper_checkbox: new FormControl(null)
    
    });
    feedingCalculationArray.push(newFeedingCalculationGroup);
  }
  creatediaperfields() {
    if (!this.isSettingValueProgrammatically) {

      let start = new Date(this.zeroToThreeMonthsForm.controls.diaper_label.value);
      let end = new Date(this.zeroToThreeMonthsForm.controls.diaper_label_end_time.value);
      let frequency = this.zeroToThreeMonthsForm.controls.diaper_change_frequency.value;
      // let setbottle_sterilization = new Date(this.zeroToThreeMonthsForm.controls.diaper_label.value);


      if (this.isset(this.zeroToThreeMonthsForm.controls.diaper_label.value)
        && this.isset(this.zeroToThreeMonthsForm.controls.diaper_change_frequency.value) &&
        this.isset(frequency)) {
        
        let array = this.getdiaperCalculationControls();
        let updated = start;
        this.isdiaper = true;
        this.cleardiaperCalculation();
        // this.clearbottle_sterilizationControls();

        if (start > end) {
          end.setDate(end.getDate() + 1);
        }

        while (updated < end) {
          if (updated < end) {
            // let time = updated.getHours()+':'+updated.getMinutes();

            let time = new Date();

            time.setHours(updated.getHours())
            time.setMinutes(updated.getMinutes())

            // let sterilization_time = new Date();

            // sterilization_time.setHours(updated.getHours() - 2)
            // sterilization_time.setMinutes(updated.getMinutes())

            this.adddiaperCalculation(time);
            // this.addbottle_sterilization(sterilization_time);
          }
          updated.setHours(updated.getHours() + frequency);
        }
      }
    }
  }
}
