<p-toast></p-toast>
<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>
    <div class="dropdown profile-nav-item d-flex flex-column align-items-end">
        <a class="dropdown-toggle admin-btn" style="text-decoration: none;" role="button" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <div class="menu-profile">
                <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
                <span class="name">{{getname}}</span>
            </div>
        </a>
        <div class="dropdown-menu d-flex flex-column align-items-center">
            <div class="dropdown-header align">
                <div class="figure mb-3">
                    <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
                </div>
                <div class="info text-center">
                    <span class="name" style="white-space: break-spaces;">{{getname}}</span>
                    <p class="mb-3 email">{{getRole}}</p>
                </div>
            </div>
            <!-- <div class="dropdown-body">
                <ul class="profile-nav p-0 pt-3">
                    <li class="nav-item"><a routerLink="/dashboard-my-profile" class="nav-link"><i class='bx bx-user'></i> <span>Profile Dashboard</span></a></li>
                </ul>
            </div> -->
            <div class="dropdown-footer">
                <ul class="profile-nav">
                    <li class="nav-item"><a class="nav-link" (click)="logout()"><i class='bx bx-log-out'></i> <span
                                class="logout-text">Logout</span></a></li>
                </ul>
            </div>

        </div>
    </div>
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item">{{Content.subTitle}}</li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <form [formGroup]="fourMonthToTowYear" (ngSubmit)="submitForm(fourMonthAbove)" #fourMonthAbove="ngForm">
        <!-- BABY DETAILS SECTION -->
        <div class="add-listings-box">
            <h3>Baby Details</h3>
            <div class="row">
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Name <span class="required">*</span></label>
                        <div class="card flex justify-content-center">
                            <p-dropdown [options]="babyValues" (onChange)="getRemainingDetails($event)" readonly="true"
                                optionLabel="name" [filter]="true" filterBy="name" optionValue="id"
                                formControlName="baby_details_id" placeholder="Select Baby's Name"
                                [resetFilterOnHide]="true" required [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('baby_details_id')?.invalid)
                                ||
                                fourMonthToTowYear.get('baby_details_id')?.invalid
                                &&
                                fourMonthToTowYear.get('baby_details_id').touched">
                            </p-dropdown>
                            <div class="invalid-feedback">Name is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Gender</label>
                        <div class="card flex justify-content-center">
                            <!-- <p-dropdown [options]="genderArray" optionLabel="name" optionValue="value"
                                [(ngModel)]="babyGender" [ngModelOptions]="{standalone: true}"
                                placeholder="Select Baby's Gender" [resetFilterOnHide]="true" [readonly]="true">
                            </p-dropdown> -->
                            <input type="text" class="form-control" [(ngModel)]="babyGender"
                                [ngModelOptions]="{standalone: true}" placeholder="Select Baby's Gender" required
                                [readonly]="true">
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Date of birth</label>
                        <div class="card flex justify-content-center">
                            <!-- <p-calendar [(ngModel)]="selectedDate" [ngModelOptions]="{standalone: true}"
                                [showIcon]="true" [readonlyInput]="true" placeholder="DD/MM/YYYY"></p-calendar> -->
                            <input type="text" name="name" class="form-control" placeholder="DD/MM/YYYY"
                                [value]="selectedDate" readonly />

                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Days</label>
                        <input type="text" name="name" class="form-control" placeholder="Days" [value]="days"
                            readonly />
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Months</label>
                        <input type="text" name="name" class="form-control" placeholder="Months" [value]="month"
                            readonly />
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Years</label>
                        <input type="text" name="name" class="form-control" placeholder="Years" [value]="years"
                            readonly />
                    </div>
                </div>
            </div>
        </div>

        <ng-container *ngIf="flag">
            <p-accordion>
                <p-accordionTab header="Feeding Assesment">
                    <div class="add-listings-box">
                        <h3>Assesment:</h3>
                        <!-- Ques 1 START-->
                        <div class="row">
                            <div class="col-sm-6 col-12">
                                <div class="form-group">
                                    <label class="px-0">Baby is not interested when offered to breastfeed,
                                        sleepy.</label>
                                    <p class="d-flex">
                                        <input type="radio" id="interested-yes" value="Yes" formControlName="answer"
                                            (change)="handleInput($event, 'interestedSleepy')">
                                        <label for="interested-yes">Yes</label>
                                    </p>
                                    <p>
                                        <input type="radio" id="interested-no" value="No" formControlName="answer"
                                            (change)="handleInput($event, 'interestedSleepy')">
                                        <label for="interested-no">No</label>
                                    </p>
                                </div>

                            </div>
                            <!-- Ques 1 END-->
                            <!-- Ques 2 START-->

                            <div class="col-sm-6 col-12">
                                <div class="form-group">
                                    <label class="px-0">Baby is showing feeding cues but not attaching.</label>
                                    <p class="d-flex">
                                        <input type="radio" id="feeding-clues-yes" value="Yes" formControlName="attach"
                                            (change)="handleInput($event, 'cluesButNoAttachment')">
                                        <label for="feeding-clues-yes">Yes</label>
                                    </p>
                                    <p>
                                        <input type="radio" id="feeding-clues-no" value="No" formControlName="attach"
                                            (change)="handleInput($event, 'cluesButNoAttachment')">
                                        <label for="feeding-clues-no">No</label>
                                    </p>

                                </div>

                            </div>
                            <!-- Ques 2 END-->
                            <!-- Ques 3 START-->

                            <div class="col-sm-6 col-12">
                                <div class="form-group">
                                    <label class="px-0">Attaches at the breast but quickly falls asleep.</label>
                                    <p class="d-flex">
                                        <input type="radio" id="quickly-fall-yes" value="Yes" formControlName="Asleep"
                                            (change)="handleInput($event, 'attachesButFallsAsleep')">
                                        <label for="quickly-fall-yes">Yes</label>
                                    </p>
                                    <p>
                                        <input type="radio" id="quickly-fall-no" value="No" formControlName="Asleep"
                                            (change)="handleInput($event, 'attachesButFallsAsleep')">
                                        <label for="quickly-fall-no">No</label>
                                    </p>

                                </div>
                            </div>

                            <!-- Ques 3 END-->
                            <!-- Ques 4 START-->

                            <div class="col-sm-6 col-12">
                                <div class="form-group">
                                    <label class="px-0">Attaches for short bursts with long pause.</label>
                                    <p class="d-flex">
                                        <input type="radio" id="short-burst-yes" value="Yes" formControlName="LongPause"
                                            (change)="handleInput($event, 'attachShortLongPause')">
                                        <label for="short-burst-yes">Yes</label>
                                    </p>
                                    <p>
                                        <input type="radio" id="short-burst-no" value="No" formControlName="LongPause"
                                            (change)="handleInput($event, 'attachShortLongPause')">
                                        <label for="short-burst-no">No</label>
                                    </p>

                                </div>

                            </div>
                            <!-- Ques 4 END-->
                            <!-- Ques 5 START-->

                            <div class="col-sm-6 col-12">
                                <div class="form-group">
                                    <label class="px-0">Attaches well with long rhythmical sucking and swallowing for
                                        a short feed.</label>
                                    <p class="d-flex">
                                        <input type="radio" id="rhythmical-yes" value="Yes" formControlName="rhythmical"
                                            (change)="handleInput($event, 'longRhythmShortFeed')">
                                        <label for="rhythmical-yes">Yes</label>
                                    </p>
                                    <p>
                                        <input type="radio" id="rhythmical-no" value="No" formControlName="rhythmical"
                                            (change)="handleInput($event, 'longRhythmShortFeed')">
                                        <label for="rhythmical-no">No</label>
                                    </p>

                                </div>


                            </div>
                            <!-- Ques 5 END-->
                            <!-- Ques 6 START-->

                            <div class="col-sm-6 col-12">
                                <div class="form-group">
                                    <label class="px-0">Attaches well for a sustained period with long rhythmical
                                        sucking and swallowing.</label>
                                    <p class="d-flex">
                                        <input type="radio" id="sustain-period-yes" value="Yes"
                                            formControlName="swallowing"
                                            (change)="handleInput($event, 'longRhythmSustainedPeriod')">
                                        <label for="sustain-period-yes">Yes</label>
                                    </p>
                                    <p>
                                        <input type="radio" id="sustain-period-no" value="No"
                                            formControlName="swallowing"
                                            (change)="handleInput($event, 'longRhythmSustainedPeriod')">
                                        <label for="sustain-period-no">No</label>
                                    </p>

                                </div>

                            </div>
                            <!-- Ques 6 END-->
                            <!-- Ques 7 START-->

                            <div class="col-sm-6 col-12">
                                <div class="form-group">
                                    <label class="px-0">Normal skin color and tone.</label>
                                    <p class="d-flex">
                                        <input type="radio" id="skin-color-yes" value="Yes"
                                            formControlName="Normal_skin"
                                            (change)="handleInput($event, 'normalSkinAndTone')">
                                        <label for="skin-color-yes">Yes</label>
                                    </p>
                                    <p>
                                        <input type="radio" id="skin-color-no" value="No" formControlName="Normal_skin"
                                            (change)="handleInput($event, 'normalSkinAndTone')">
                                        <label for="skin-color-no">No</label>
                                    </p>

                                </div>



                            </div>
                            <!-- Ques 7 END-->
                            <!-- Ques 8 START-->

                            <div class="col-sm-6 col-12">
                                <div class="form-group">
                                    <label class="px-0">Gaining weight appropriately.</label>
                                    <p class="d-flex">
                                        <input type="radio" id="weight-yes" value="Yes" formControlName="weight"
                                            (change)="handleInput($event, 'appropriateWeightGain')">
                                        <label for="weight-yes">Yes</label>
                                    </p>
                                    <p>
                                        <input type="radio" id="weight-no" value="No" formControlName="weight"
                                            (change)="handleInput($event, 'appropriateWeightGain')">
                                        <label for="weight-no">No</label>
                                    </p>

                                </div>
                            </div>
                        </div>
                        <!-- Ques 8 END-->
                        <!-- Other Concerns START-->
                        <div class="row">
                            <label class="subHeading mb-3">Other Concerns.</label>
                            <div class="col-12">
                                <div class="form-group d-flex justify-content-between">
                                    <textarea name="identify" class="form-control" id="kt_autosize_1" rows="5"
                                        formControlName="Other_concerns"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </p-accordionTab>
                <!--DAILY CARE -->
                <!-- accordion start -->
                <!-- <ng-container *ngIf="flag">
            <p-accordion> -->
                <p-accordionTab header="Milk Feeding">
                    <div class="add-listings-box">
                        <h3>Daily Cares</h3>
                        <div class="subHeading mb-3">Feeding: <span class="fw-normal">Prepared By</span></div>
                        <div class=" ">
                            <div class="form-check checkbox-list p-0 mb-3">
                              <div class="row">
                                <div class="col-auto">
                                  <label class="checkbox checkbox-outline px-0" for="mother">
                                    <input type="checkbox" id="mother" formControlName="feeding_prepared_mother" value="mother">
                                    <span></span>
                                    Mother
                                  </label>
                                </div>
                                <div class="col-auto">
                                  <label class="checkbox checkbox-outline px-0" for="nanny">
                                    <input type="checkbox" formControlName="feeding_prepared_nanny" id="nanny" value="nanny">
                                    <span></span>
                                    Nurse
                                  </label>
                                  <!-- <input type="checkbox" formControlName="feeding_prepared_nanny" id="nanny"
                                    value="nanny">
                                  <label for="nanny" class="mb-0 mx-2">Nurse</label> -->
                                </div>
                                <div class="col-auto">
              
                                  <label class="checkbox checkbox-outline px-0" for="nanny" for="other">
                                    <input type="checkbox" id="other" formControlName="feeding_prepared_other" value="other">
                                    <span></span>
                                    Other
                                  </label>
                                  <!-- <input type="checkbox" id="other" formControlName="feeding_prepared_other"
                                    value="other">
                                  <label for="other" class="mb-0 mx-2">Other</label> -->
                                </div>
                              </div>
              
              
                              <!-- <div class="form-group">
                                <div class=" ">
                                  <div class="form-check checkbox-list p-0">
                                    <div class="form-check checkbox-list p-0">
                                      <div class="row">
                                        <div class="form-group col-6">
                                          <div class=" mb-3 d-flex justify-content-between">
                                           
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div> -->
              
                            </div>
                          </div>
                    </div>




                    <!-- MILK-->

                    <div class="add-listings-box pb-3">

                        <h3 class="mb-0">Milk</h3>
                        <div class=" ">
                            <!-- <div "> -->
                            <div class="form-check checkbox-list p-0">
                                <div class="row">

                                    <div class="col-md-3 col-lg-2  mt-md-2">
                                        <label class="checkbox checkbox-outline mb-3">
                                            <input type="checkbox" value="Breast" formControlName="feeding_breast"
                                                (change)="handleInput($event, 'milk')" />
                                            <span></span>
                                            Breast
                                        </label>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label class="px-0">Breast frequency</label>
                                            <div class="card flex justify-content-center">
                                                <p-dropdown id="breast_frequency" [options]="frequency"
                                                    (onChange)="addbreastfield()"
                                                    formControlName="feeding_frequency_breast" optionLabel="frequency"
                                                    optionValue="frequency" [filter]="true" filterBy="frequency"
                                                    placeholder="Select Frequency" [disabled]="Breast"
                                                    [class.is-invalid]="(fourMonthAbove.submitted &&
                                            fourMonthToTowYear.get('feeding_frequency_breast')?.invalid)
                                            ||
                                            fourMonthToTowYear.get('feeding_frequency_breast')?.invalid
                                            &&
                                            fourMonthToTowYear.get('feeding_frequency_breast').touched">
                                                </p-dropdown>
                                                <div class="invalid-feedback">Breast frequency is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label class="px-0">Remark</label>
                                            <input type="text" name="breast_remark" class="form-control"
                                                placeholder="Remark" formControlName="breast_remark" />
                                        </div>
                                    </div>
                                    <div *ngIf="isBreast" class="add-listings-box pt-3 mb-3">
                                        <div class="row" formArrayName="breast_feeding">
                                            <div *ngFor="let control of getbreastarray().controls; let i = index"
                                                [formGroupName]="i" class="col-6 col-md-4 col-lg-4">
                                                <!-- <div class="row"> -->
                                                <!-- <div class="col-md-2">
                                                            <div class="form-group">
                                                                <div class="col-md col-lg-2 mt-3">
                                                                    <label
                                                                        class="checkbox checkbox-outline px-0  mt-md-2">
                                                                        <input type="checkbox" value="breastFeeding"
                                                                            name="checkboxes"
                                                                            formControlName="feeding_breast_checkbox" />
                                                                        Done?
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                <!-- <div class="col-md-5"> -->
                                                <div class="form-group">
                                                    <label class="p-0">Time: </label>
                                                    <div class="card flex justify-content-center">
                                                        <p-calendar placeholder="Start Time"
                                                            formControlName="feeding_breast_time" [timeOnly]="true"
                                                            [hourFormat]="12" inputId="time" [disabled]="feedingBreast"
                                                            [class.is-invalid]="(fourMonthAbove.submitted &&
                                                            fourMonthToTowYear.get('feeding_breast_time')?.invalid)
                                                            ||
                                                              fourMonthToTowYear.get('feeding_breast_time')?.invalid
                                                            &&
                                                              fourMonthToTowYear.get('feeding_breast_time').touched">
                                                        </p-calendar>
                                                        <div class="invalid-feedback">Start Time is required
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- </div> -->
                                                <!-- <div class="col-md-5">
                                                            <div class="form-group">
                                                                <div class="card flex justify-content-center">
                                                                    <label class="px-0">Remark</label>
                                                                    <input type="text" name="remark"
                                                                        class="form-control" placeholder="Remark"
                                                                        id="remark"
                                                                        formControlName="breast_feeding_remark" />
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                <!-- </div> -->

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-2 col-md-4">
                                        <label class="checkbox checkbox-outline  mb-3 mt-md-2">
                                            <input type="checkbox" value="Formula" formControlName="feeding_type"
                                                (change)="handleInput($event, 'milk')" />
                                            <span></span>
                                            Formula
                                        </label>
                                    </div>
                                    <div class="col-lg-4 col-md-4">
                                        <div class="form-group">
                                            <label class="px-0">Formula frequency (every hr​)</label>
                                            <div class="card flex justify-content-center">
                                                <p-dropdown id="formula_frequency" [options]="frequencyList"
                                                    optionLabel="name" optionValue="value"
                                                    (ngModelChange)="createfields()" [filter]="true" filterBy="value"
                                                    formControlName="feeding_frequency" placeholder="Select Frequency"
                                                    [disabled]="Formula" [class.is-invalid]="(fourMonthAbove.submitted &&
                                                        fourMonthToTowYear.get('feeding_frequency')?.invalid)
                                                        ||
                                                        fourMonthToTowYear.get('feeding_frequency')?.invalid
                                                        &&
                                                        fourMonthToTowYear.get('feeding_frequency').touched">
                                                </p-dropdown>
                                                <div class="invalid-feedback">Formula frequency is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4">
                                        <div class="form-group">
                                            <label class="px-0">Start Time</label>
                                            <div class="card flex justify-content-center">
                                                <p-calendar placeholder="Time" formControlName="feeding_time"
                                                    (ngModelChange)="createfields()" [disabled]="Formula"
                                                    [timeOnly]="true" [hourFormat]="12" inputId="time"
                                                    id="formula_frequency" [class.is-invalid]="(fourMonthAbove.submitted &&
                                                        fourMonthToTowYear.get('feeding_time')?.invalid)
                                                        ||
                                                        fourMonthToTowYear.get('feeding_time')?.invalid
                                                        &&
                                                        fourMonthToTowYear.get('feeding_time').touched"></p-calendar>
                                                <div class="invalid-feedback">Time is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4">
                                        <div class="form-group">
                                            <label class="px-0">End Time</label>
                                            <div class="card flex justify-content-center">
                                                <p-calendar placeholder="Time" formControlName="formula_feeding_time"
                                                    (ngModelChange)="createfields()" [disabled]="Formula"
                                                    [timeOnly]="true" [hourFormat]="12" inputId="time"
                                                    id="formula_frequency"
                                                    [class.is-invalid]="(fourMonthAbove.submitted &&
                                                        fourMonthToTowYear.get('formula_feeding_time')?.invalid)
                                                        ||
                                                        fourMonthToTowYear.get('formula_feeding_time')?.invalid
                                                        &&
                                                        fourMonthToTowYear.get('formula_feeding_time').touched"></p-calendar>
                                                <div class="invalid-feedback">Time is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2"></div>

                                    <div class="col-lg-4 col-md-4">
                                        <div class="form-group">
                                            <label class="px-0">Amount (ml)</label>
                                            <div class="card flex justify-content-center">
                                                <input type="text" formControlName="feeding_amount_per"
                                                    class="form-control" placeholder="Enter Amount (ML)"
                                                    [disabled]="Formula" [class.is-invalid]="(fourMonthAbove.submitted &&
                                            fourMonthToTowYear.get('feeding_amount_per')?.invalid)
                                            ||
                                            fourMonthToTowYear.get('feeding_amount_per')?.invalid
                                            &&
                                            fourMonthToTowYear.get('feeding_amount_per').touched" />
                                                <div class="invalid-feedback">Amount is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4">
                                        <div class="form-group ml-0 col-12 p-0 mb-0">
                                            <label class="col-form-label col-lg-12 col-sm-12 p-0">Type of
                                                formula</label>
                                            <div class="col-12 p-0">
                                                <input type="text" formControlName="type_of_formula"
                                                    class="form-control" placeholder="Formula" [disabled]="Formula"
                                                    [class.is-invalid]="(fourMonthAbove.submitted &&
                                            fourMonthToTowYear.get('type_of_formula')?.invalid)
                                            ||
                                            fourMonthToTowYear.get('type_of_formula')?.invalid
                                            &&
                                            fourMonthToTowYear.get('type_of_formula').touched" />
                                                <div class="invalid-feedback">Formula is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-2">
                                        <div class="form-group">
                                            <label class="px-0">Remark</label>
                                            <input type="text" name="formula_remark" class="form-control"
                                                placeholder="Remark" formControlName="formula_remark"
                                                [disabled]="Formula" />
                                        </div>
                                    </div>


                                    <div *ngIf="isFormula">
                                        <div class="row justify-content-end mx-0">
                                            <div class="add-listings-box pt-3 mb-3 col-lg-12">
                                                <div formArrayName="formula_calculation" class="row">
                                                    <div *ngFor="let control of getFormulaCalculationControls().controls; let i = index"
                                                        [formGroupName]="i" class="col-lg-4">
                                                        <!-- <div class="row"> -->
                                                        <!-- <div class="col-lg-2 col-md-4">
                                                                <label class="checkbox checkbox-outline px-0  mt-md-2">
                                                                    <input type="checkbox" value="formulaFeeding"
                                                                        name="checkboxes"
                                                                        formControlName="feeding_checkbox" />
                                                                    <span></span>
                                                                    Done?
                                                                </label>
                                                            </div> -->
                                                        <!-- <div class="col-lg-3 col-sm-6 col-12"> -->
                                                        <div class="form-group">
                                                            <label class="px-0">Time</label>
                                                            <p-calendar placeholder="Time"
                                                                formControlName="formula_timeArray" [hourFormat]="12"
                                                                [timeOnly]="true" inputId="time"
                                                                disabled="true"></p-calendar>
                                                        </div>
                                                        <!-- </div> -->
                                                        <!-- <div class="col-lg-3 col-sm-6 col-12">
                                                                <div class="form-group">
                                                                    <label class="px-0">Time Feeding By Nurse</label>
                                                                    <p-calendar placeholder="Actual Time"
                                                                        formControlName="nurse_feeding"
                                                                        [hourFormat]="12" [timeOnly]="true"
                                                                        inputId="time"></p-calendar>
                                                                </div>
                                                            </div> -->
                                                        <!-- <div class="col-lg-3 col-sm-6 col-12">
                                                                <div class="form-group">
                                                                    <label class="px-0">Amount (ml)</label>
                                                                    <input type="text" name="amount"
                                                                        class="form-control" placeholder="Amount"
                                                                        formControlName="amount" />
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-2"></div> -->
                                                        <!-- <div class="col-lg-3 col-sm-12 col-12">
                                                                <div class="form-group">
                                                                    <label class="px-0">Name of formula</label>
                                                                    <input type="text" name="remark"
                                                                        class="form-control"
                                                                        placeholder="Name of formula"
                                                                        formControlName="remark" />
                                                                </div>
                                                            </div> -->
                                                        <!-- <div class="col-lg-3 col-sm-12 col-12">
                                                                <div class="form-group">
                                                                    <label class="px-0">Remark</label>
                                                                    <input type="text" name="remark"
                                                                        class="form-control" placeholder="Remark"
                                                                        id="remark" formControlName="formula_remark" />
                                                                </div>
                                                            </div> -->
                                                        <!-- </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Feeding -->
                        <div class="row">
                            <label class="subHeading mb-3">Bottle Sterilization</label>
                            <div class="form-group col-lg-2 col-md-4">
                                <div class="form-check pl-0 checkbox-list px-0">
                                    <label class="checkbox checkbox-outline px-0">
                                        <!-- <div class="form-check pl-0 checkbox-list"> -->
                                        <!-- <label class="checkbox checkbox-outline col-4"> -->
                                        <input type="checkbox" value="All" formControlName="bottle_sterilization_all"
                                            (change)="handleInput($event, 'feeding')" />
                                        <span></span>
                                        All
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="form-group">
                                    <label class="px-0">Time </label>
                                    <div class="card flex justify-content-center">
                                        <p-calendar placeholder="Time" formControlName="bottle_sterilization_all_time"
                                            [disabled]="All" [timeOnly]="true" [hourFormat]="12" inputId="time"
                                            [class.is-invalid]="(fourMonthAbove.submitted &&
                                            fourMonthToTowYear.get('bottle_sterilization_all_time')?.invalid)
                                            ||
                                            fourMonthToTowYear.get('bottle_sterilization_all_time')?.invalid
                                            &&
                                            fourMonthToTowYear.get('bottle_sterilization_all_time').touched"></p-calendar>
                                        <div class="invalid-feedback">Time is required</div>
                                    </div>
                                </div>
                                <!-- </div> -->
                            </div>
                        </div>
                        <!-- one at a time -->

                        <div class="row">
                            <div class="form-group col-lg-2 col-md-4">
                                <div class="form-check pl-0 checkbox-list px-0">
                                    <label class="checkbox checkbox-outline px-0">
                                        <input type="checkbox" value="One"
                                            formControlName="bottle_sterilization_onetime"
                                            (change)="handleInput($event, 'feeding')" />
                                        <span></span>
                                        One at a time
                                    </label>
                                </div>
                            </div>
                            <div class="form-group col-lg-3">
                                <label class="px-0"> Reference Time </label>
                                <div class="card flex justify-content-center">
                                    <p-calendar placeholder="Time" formControlName="bottle_sterilization_one_at_a_time"
                                        [disabled]="One" [timeOnly]="true" [hourFormat]="12" inputId="time"
                                        [class.is-invalid]="(fourMonthAbove.submitted &&
                                        fourMonthToTowYear.get('bottle_sterilization_one_at_a_time')?.invalid)
                                        ||
                                        fourMonthToTowYear.get('bottle_sterilization_one_at_a_time')?.invalid
                                        &&
                                        fourMonthToTowYear.get('bottle_sterilization_one_at_a_time').touched"></p-calendar>
                                    <div class="invalid-feedback">Time is required</div>
                                </div>
                            </div>
                            <div class="col">
                                <!-- Bottle Sterilization one at a time auto fill fields -->
                                <div class="add-listings-box" *ngIf="isFormula && !One">
                                    <div formArrayName="bottle_sterilization" class="row">
                                        <div *ngFor="let control of getbottle_sterilizationControls().controls; let i = index"
                                            [formGroupName]="i" class="col-md-4">
                                            <!-- <div class=" row"> -->
                                            <!-- <div class="col-md-4">
                                                    <div class="form-group">
                                                        <div class="col-lg-2 col-md-2 mt-3">
                                                            <label class="checkbox checkbox-outline px-0  mt-md-2">
                                                                <input type="checkbox" value="true" name="checkboxes"
                                                                    formControlName="done_sterilization" />
                                                                <span></span>
                                                                Done?
                                                            </label>
                                                        </div>
                                                        
                                                    </div>
                                                </div> -->
                                            <!-- <div class="col-md-4"> -->
                                            <div class="form-group">

                                                <label class="px-0">Time</label>
                                                <p-calendar placeholder="Time" formControlName="sterilization_timeArray"
                                                    [hourFormat]="12" [timeOnly]="true" inputId="time"></p-calendar>
                                            </div>
                                            <!-- </div> -->
                                            <!-- <div class="col">
                                                    <div class="form-group">
                                                        <label class="px-0">Remark</label>
                                                        <input type="text" name="name" class="form-control"
                                                            placeholder="Remark"
                                                            [formControl]="control.get('sterilization_remark')" />
                                                    </div>
                                                </div> -->
                                            <!-- </div> -->
                                        </div>
                                    </div>
                                </div>
                                <!-- </div> -->
                            </div>
                        </div>
                    </div>
                </p-accordionTab>

                <!--SOLID-->
                <p-accordionTab header="Solid Food">
                    <div class="add-listings-box">

                        <h3>Solid</h3>

                        <!--Regular Meal-->
                        <!-- add buttons -->
                        <div formArrayName="solid_food">
                            <label class="subHeading mb-3 mt-sm-0 mt-3">Regular meal</label>
                            <ng-container
                                *ngFor="let control of getRegularFormArray().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">
                                    <div class="form-group mb-2 col-6 col-md-3 col d-flex align-items-center">
                                        <div class="form-check pl-0 checkbox-list px-0">
                                            <label class="checkbox checkbox-outline px-0 m-0">
                                                <!-- <input type="checkbox" value="Breakfast"
                                                    formControlName="regular_meal_type"
                                                    (change)="disableAllRegularFoodControls($event.target.checked,i)" /> -->
                                                <span></span>
                                                Breakfast
                                            </label>
                                        </div>
                                    </div>
                                    <!-- breakfast -->
                                    <div class="col-6 col-md-3 col">
                                        <div class="form-group">
                                            <label class="px-0">Time</label>
                                            <div class="card flex justify-content-center">
                                                <p-calendar placeholder="Time" formControlName="regular_meal_time"
                                                    [timeOnly]="true" [hourFormat]="12" inputId="time"
                                                    [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('regular_meal_time')?.invalid)
                                ||
                                fourMonthToTowYear.get('regular_meal_time')?.invalid
                                &&
                                fourMonthToTowYear.get('regular_meal_time').touched"></p-calendar>
                                                <div class="invalid-feedback">Time is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col col-md-3 col-lg col m-0">
                                        <div class="form-group ml-0 col-12 p-0 mb-0">
                                            <label class="px-0">Food components</label>
                                            <div class="col-12 p-0">
                                                <input type="text" formControlName="regular_meal_food_components"
                                                    class="form-control" placeholder="Food components"
                                                    [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('regular_meal_food_components')?.invalid)
                                ||
                                fourMonthToTowYear.get('regular_meal_food_components')?.invalid
                                &&
                                fourMonthToTowYear.get('regular_meal_food_components').touched" />
                                                <div class="invalid-feedback">Food component is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-auto d-flex mb-3 align-items-end">
                                        <p-button type="button" icon="bx bx-plus" (click)="addRegularObject('add', i)"
                                            iconPos="right" class="secondary-btn"></p-button>
                                        <div class="remove">
                                            <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                icon="bx bx-minus" (click)="addRegularObject('delete', i)"
                                                iconPos="right"></p-button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <!-- Lunck checkbox -->
                        <div formArrayName="lunch_food">
                            <ng-container
                                *ngFor="let control of getLunchFormArray().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">
                                    <div class="form-group mb-2 col-6 col-md-3 col d-flex align-items-center">
                                        <div class="form-check pl-0 checkbox-list px-0">
                                            <label class="checkbox checkbox-outline px-0 m-0">
                                                <!-- <input type="checkbox" value="Lunch"
                                                    formControlName="regular_meal_lunch"
                                                    (change)="disableAlllunchFoodControls($event.target.checked,i)" /> -->
                                                <span></span>
                                                Lunch
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-3 col">
                                        <div class="form-group">
                                            <label class="px-0">Time</label>
                                            <div class="card flex justify-content-center">
                                                <p-calendar placeholder="Time" [timeOnly]="true" [hourFormat]="12"
                                                    formControlName="regular_meal_lunch_time" inputId="time"
                                                    [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('regular_meal_lunch_time')?.invalid)
                                ||
                                fourMonthToTowYear.get('regular_meal_lunch_time')?.invalid
                                &&
                                fourMonthToTowYear.get('regular_meal_lunch_time').touched"></p-calendar>
                                                <div class="invalid-feedback">Time is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col col-md-3 col-lg col m-0">
                                        <div class="form-group ml-0 col-12 p-0 mb-0">
                                            <label class="px-0">Food components</label>
                                            <div class="col-12 p-0">
                                                <input type="text" formControlName="regular_meal_lunch_food_components"
                                                    class="form-control" placeholder="Food components"
                                                    [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('regular_meal_lunch_food_components')?.invalid)
                                ||
                                fourMonthToTowYear.get('regular_meal_lunch_food_components')?.invalid
                                &&
                                fourMonthToTowYear.get('regular_meal_lunch_food_components').touched" />
                                                <div class="invalid-feedback">Food component is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-auto d-flex mb-3 align-items-end">
                                        <p-button type="button" icon="bx bx-plus" (click)="addLunchObject('add', i)"
                                            iconPos="right" class="secondary-btn"></p-button>
                                        <div class="remove">
                                            <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                icon="bx bx-minus" (click)="addLunchObject('delete', i)"
                                                iconPos="right"></p-button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <!-- Dinner -->
                        <div formArrayName="dinner_food">
                            <ng-container
                                *ngFor="let control of getDinnerFormArray().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">
                                    <div class="form-group mb-2 col-6 col-md-3 col d-flex align-items-center">
                                        <div class="form-check pl-0 checkbox-list px-0">
                                            <label class="checkbox checkbox-outline px-0 m-0">
                                                <!-- <input type="checkbox" value="Dinner"
                                                    formControlName="regular_meal_dinner"
                                                    (change)="disableAlldinnerFoodControls($event.target.checked,i)" /> -->
                                                <span></span>
                                                Dinner
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-3 col">
                                        <div class="form-group">
                                            <label class="px-0">Time</label>
                                            <div class="card flex justify-content-center">
                                                <p-calendar placeholder="Time" [timeOnly]="true" [hourFormat]="12"
                                                    formControlName="regular_meal_dinner_time" inputId="time"
                                                    [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('regular_meal_dinner_time')?.invalid)
                                ||
                                fourMonthToTowYear.get('regular_meal_dinner_time')?.invalid
                                &&
                                fourMonthToTowYear.get('regular_meal_dinner_time').touched"></p-calendar>
                                                <div class="invalid-feedback">Time is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col col-md-3 col-lg col m-0">
                                        <div class="form-group ml-0 col-12 p-0 mb-0">
                                            <label class="px-0">Food components</label>
                                            <div class="col-12 p-0">
                                                <input type="text" formControlName="regular_meal_dinner_food_components"
                                                    class="form-control" placeholder="Food components"
                                                    [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('regular_meal_dinner_food_components')?.invalid)
                                ||
                                fourMonthToTowYear.get('regular_meal_dinner_food_components')?.invalid
                                &&
                                fourMonthToTowYear.get('regular_meal_dinner_food_components').touched" />
                                                <div class="invalid-feedback">Food component is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-auto d-flex mb-3 align-items-end">
                                        <p-button type="button" icon="bx bx-plus" (click)="addDinnerObject('add', i)"
                                            iconPos="right" class="secondary-btn"></p-button>
                                        <div class="remove">
                                            <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                icon="bx bx-minus" (click)="addDinnerObject('delete', i)"
                                                iconPos="right"></p-button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div class="row">
                            <label class="subHeading mb-3">Others</label>
                            <div class="form-group mb-2 col-6 col-md-3 col-lg-2 d-flex align-items-center">
                                <div class="form-check pl-0 checkbox-list px-0">
                                    <label class="checkbox checkbox-outline px-0 m-0">
                                        <!-- <input type="checkbox" value="Snack" formControlName="solid_other_snack"
                                            (change)="handleInput($event, 'regularMeal')" /> -->
                                        <span></span>
                                        Snack
                                    </label>
                                </div>
                            </div>
                            <div class="col-6 col-md-3 col">
                                <div class="form-group">
                                    <label class="px-0">Time</label>
                                    <div class="card flex justify-content-center">
                                        <p-calendar placeholder="Time" [timeOnly]="true" [hourFormat]="12"
                                            formControlName="solid_other_snack_time" inputId="time" [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('solid_other_snack_time')?.invalid)
                                ||
                                fourMonthToTowYear.get('solid_other_snack_time')?.invalid
                                &&
                                fourMonthToTowYear.get('solid_other_snack_time').touched"></p-calendar>
                                        <div class="invalid-feedback">Time is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="form-group ml-0 col-12 p-0 mb-0">
                                    <label class="px-0">Food components</label>
                                    <div class="col-12 p-0">
                                        <input type="text" formControlName="soild_other_snack_food_components"
                                            class="form-control" placeholder="Food components" [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('soild_other_snack_food_components')?.invalid)
                                ||
                                fourMonthToTowYear.get('soild_other_snack_food_components')?.invalid
                                &&
                                fourMonthToTowYear.get('soild_other_snack_food_components').touched" />
                                        <div class="invalid-feedback">Food component is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </p-accordionTab>
                <!-- </div> -->


                <!--Food/Medicine Allergy-->

                <p-accordionTab header="Allergy">
                    <div class="add-listings-box">
                        <div class="row">
                            <label class="subHeading mb-3 mt-sm-0 mt-3">Food/Medicine Allergy</label>
                            <!-- noAllergy -->
                            <div class="form-group col-lg-3 col-md-4 col-12 d-flex flex-column">

                                <div class="form-group">
                                    <div class="d-flex">
                                        <p class="ms-2">
                                            <input type="radio" id="allergy_yes" value="1"
                                                formControlName="food_medicine_allergy"
                                                (change)="handleInput($event, 'fnmAllergy')" name="allergy_option">
                                            <label for="allergy_yes" class="mb-0 mx-2">Yes</label>
                                        </p>
                                        <p class="d-flex">
                                            <input type="radio" id="allergy_no" value="0"
                                                formControlName="food_medicine_allergy"
                                                (change)="handleInput($event, 'fnmAllergy')" name="allergy_option">
                                            <label for="allergy_no" class="mb-0 mx-2">No</label>
                                        </p>
                                    </div>
                                </div>
                                <!-- <p class="d-flex">
                        <label class="checkbox checkbox-outline px-0">
                            <input type="checkbox" value="1" formControlName="food_medicine_allergy_yes" />
                            <span></span>
                            Yes
                        </label>
                    </p>
                    <p class="d-flex">
                        <label class="checkbox checkbox-outline px-0">
                            <input type="checkbox" value="0" formControlName="food_medicine_allergy_no" />
                            <span></span>
                            No
                        </label> -->
                                <!-- </p> -->
                            </div>
                            <!-- Allergy -->
                            <div class="col">
                                <div class="form-group">
                                    <!-- <label class="px-0">Specify Products</label> -->
                                    <textarea formControlName="food_medicine_allergy_specify_prod" class="form-control"
                                        placeholder="Specify Products" rows="2"
                                        [class.is-invalid]="(fourMonthAbove.submitted &&
                                        fourMonthToTowYear.get('food_medicine_allergy_specify_prod')?.invalid)
                                        ||
                                        fourMonthToTowYear.get('food_medicine_allergy_specify_prod')?.invalid
                                        &&
                                        fourMonthToTowYear.get('food_medicine_allergy_specify_prod').touched"></textarea>
                                    <div class="invalid-feedback">Specify product is required</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </p-accordionTab>

                <!-- </div> -->

                <p-accordionTab header="Diaper Change">
          
                    <div class="add-listings-box pb-0">
                      <div class="row">
                        <div class="col-lg-2 col-md-4">
                          <label class="checkbox checkbox-outline px-0  mt-md-2">
                            <input type="checkbox" value="formulaFeeding" name="checkboxes" formControlName="diaper_type" />
                            <span></span>
                            Diaper Formula
                          </label>
                        </div>
          
                        <!-- Formula feeding dropdown -->
                        <div class="col-lg-4 col-md-4">
                          <div class="form-group">
                            <label class="p-0">Diaper frequency (every hr​): </label>
                            <div class="card flex justify-content-center">
                              <p-dropdown [options]="frequencyList" formControlName="diaper_change_frequency"
                                (ngModelChange)="creatediaperfields()" optionLabel="name" optionValue="value" [filter]="true"
                                filterBy="value" placeholder="Frequency" [resetFilterOnHide]="true" [class.is-invalid]="(fourMonthAbove.submitted &&
                                  fourMonthToTowYear.get('diaper_change_frequency')?.invalid)
                                  ||
                                  fourMonthToTowYear.get('diaper_change_frequency')?.invalid
                                  &&
                                  fourMonthToTowYear.get('diaper_change_frequency').touched">
          
                              </p-dropdown>
                              <div class="invalid-feedback"> Frequency is required</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-4">
                          <div class="form-group">
                            <label class="p-0">Start Time:</label>
                            <div class="card flex justify-content-center">
                              <p-calendar placeholder="Time" formControlName="diaper_label" [timeOnly]="true" [hourFormat]="12"
                                inputId="time" (ngModelChange)="creatediaperfields()" [class.is-invalid]="(fourMonthAbove.submitted &&
                                  fourMonthToTowYear.get('diaper_label')?.invalid)
                                  ||
                                  fourMonthToTowYear.get('diaper_label')?.invalid
                                  &&
                                  fourMonthToTowYear.get('diaper_label').touched"></p-calendar>
                              <div class="invalid-feedback"> Time is required</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-4">
                          <div class="form-group">
                            <label class="p-0">End Time: </label>
                            <div class="card flex justify-content-center">
                              <p-calendar placeholder="Time" formControlName="diaper_label_end_time" [timeOnly]="true"
                                [hourFormat]="12" (ngModelChange)="creatediaperfields()" inputId="time" [class.is-invalid]="(fourMonthAbove.submitted &&
                                    fourMonthToTowYear.get('diaper_label_end_time')?.invalid)
                                    ||
                                    fourMonthToTowYear.get('diaper_label_end_time')?.invalid
                                    &&
                                    fourMonthToTowYear.get('diaper_label_end_time').touched">
                              </p-calendar>
                              <div class="invalid-feedback">End Time is required</div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-2"></div>
                      <div class="col-lg-4 col-md-2">
                        <div class="form-group">
                          <label class="p-0">Amount (ml): </label>
                          <div class="card flex justify-content-center">
                            <input type="text" name="name" class="form-control" placeholder="Amount(ml)"
                                formControlName="feeding_amount_per" [class.is-invalid]="(fourMonthAbove.submitted &&
                              fourMonthToTowYear.get('feeding_amount_per')?.invalid) 
                              ||
                              fourMonthToTowYear.get('feeding_amount_per')?.invalid
                              &&
                              fourMonthToTowYear.get('feeding_amount_per').touched" />
                            <div class="invalid-feedback">Amount is required</div>
                          </div>
                        </div>
                      </div>
          
                      <div class="col-lg-3 col-md-2">
                        <div class="form-group ml-0 col-12 p-0 mb-0">
                          <label class="p-0">Type of formula: </label>
                          <div class="col-12 p-0">
                            <input type="text" name="name" class="form-control" placeholder="Formula"
                                formControlName="type_of_formula" [class.is-invalid]="(fourMonthAbove.submitted &&
                                  fourMonthToTowYear.get('type_of_formula')?.invalid)
                                  ||
                                  fourMonthToTowYear.get('type_of_formula')?.invalid
                                  &&
                                  fourMonthToTowYear.get('type_of_formula').touched" />
                            <div class="invalid-feedback">Type of formula is required</div>
                          </div>
                        </div>
                      </div> -->
                        <!-- <div class="col-lg-3 col-md-2">
                        <div class="form-group">
                          <label class="px-0">Remark</label>
                          <input type="text" name="formula_remark" class="form-control" placeholder="Remark"
                              formControlName="formula_remark" />
                        </div>
                      </div> -->
                      </div>
                    </div>
                    <div *ngIf="isdiaper">
                      <div class="row justify-content-end mx-0">
                        <div class="add-listings-box pt-3 mb-3 col-lg-12">
                          <div formArrayName="diaper_calculation" class="row">
                            <div *ngFor="let control of getdiaperCalculationControls().controls; let i = index"
                              [formGroupName]="i" class="col-lg-4">
                              <!-- <div class="row"> -->
                              <!-- <div class="col-md col-lg-2 mt-3">
                                  <label class="checkbox checkbox-outline px-0  mt-md-2">
                                    <input type="checkbox" value="formulaFeeding" name="checkboxes"
                                        formControlName="feeding_checkbox" />
                                    <span></span>
                                    Done?
                                  </label>
                                </div> -->
                              <!-- <div class="col-lg-3 col-sm-6 col-12"> -->
                              <div class="form-group">
                                <label class="px-0">Time</label>
                                <p-calendar placeholder="Time" formControlName="diaper_timeArray" [hourFormat]="12"
                                  [timeOnly]="true" inputId="time" disabled="true"></p-calendar>
                              </div>
                              <!-- </div> -->
                              <!-- <div class="col-lg-3 col-sm-6 col-12">
                                  <div class="form-group">
                                    <label class="px-0">Time Feeding By Nurse</label>
                                    <p-calendar placeholder="Actual Time" formControlName="nurse_feeding" [hourFormat]="12"
                                        [timeOnly]="true" inputId="time"></p-calendar>
                                  </div>
                                </div> -->
                              <!-- <div class="col-lg-3 col-sm-6 col-12">
                                  <div class="form-group">
                                    <label class="px-0">Amount (ml)</label>
                                    <input type="text" name="amount" class="form-control" placeholder="Amount"
                                        formControlName="amount" />
                                  </div>
                                </div> -->
                              <!-- <div class="col-lg-2"></div>
                                <div class="col-lg-3 col-sm-12 col-12">
                                  <div class="form-group">
                                    <label class="px-0">Name of formula</label>
                                    <input type="text" name="remark" class="form-control" placeholder="Name of formula"
                                        formControlName="remark" />
                                  </div>
                                </div> -->
                              <!-- <div class="col-lg-3 col-sm-12 col-12">
                                  <div class="form-group">
                                    <label class="px-0">Remark</label>
                                    <input type="text" name="remark" class="form-control" placeholder="Remark" id="remark"
                                        formControlName="formula_remark" />
                                  </div>
                                </div> -->
                              <!-- </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
          
                    <!-- <div class="add-listings-box p-0 border-0">
                      <div class="row">
                        <div class="col-sm-4 col-12">
                          <div class="form-group">
                            <label class="px-0">Frequency </label>
                            <div class="form-check checkbox-list p-0">
                              <p-dropdown [options]="frequencyList" formControlName="diaper_change_frequency" optionLabel="name"
                                  optionValue="value" [filter]="true" filterBy="value" placeholder="Frequency"
                                  (ngModelChange)="DiaperChange($event)" [resetFilterOnHide]="true" [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('diaper_change_frequency')?.invalid)
                                ||
                                fourMonthToTowYear.get('diaper_change_frequency')?.invalid
                                &&
                                fourMonthToTowYear.get('diaper_change_frequency').touched">
                              </p-dropdown>
                              <div class="invalid-feedback">Frequency is required</div>
                            </div>
                          </div>
                        </div>
                       
                        <div class="col-sm-8">
                          <label for="" class="d-none d-md-block">&nbsp;</label>
                          <div class="row" formArrayName="diaper_changes">
                            <div *ngFor="let control of getDiaperChangeControlsfrequency().controls; let i = index"
                                [formGroupName]="i" class="col-6 col-md-4 col-lg-3">
                              <div class="form-group">
          
                                <div class="form-check px-0 checkbox-list">
                                  <label class="checkbox checkbox-outline px-0">
                                  
                                    <span></span>
                                    {{ getTimeLabel(i) }}
                                  </label>
                                </div>
          
                              </div>
                            </div>
                          </div>
          
                        </div>
                    
                      </div>
                    </div> -->
          
                  </p-accordionTab>

                <!-- bathing-->

                <!-- <div class="add-listings-box"> -->
                <p-accordionTab header="Bathing">
                    <div class="add-listings-box">
                        <div class="row">
                            <label class="subHeading mb-3">Bathing </label>
                            <div class="form-group col-md-3 col-lg-2 d-flex mt-md-2">
                                <div class="form-check pl-0 checkbox-list px-0">
                                    <label class="checkbox checkbox-outline px-0">
                                        <input type="checkbox" value="Sponge" formControlName="bathing_sponge"
                                            (change)="handleInput($event, 'Bathing')" />
                                        <span></span>
                                        Sponge
                                    </label>
                                </div>
                            </div>
                            <!-- Sponge -->

                            <div class="col">
                                <div class="form-group">
                                    <label class="px-0">Frequency</label>
                                    <div class="card flex justify-content-center">
                                        <!-- <p-dropdown id="sponge_frequency" [options]="frequencyList"
                                    formControlName="bathing_frequency_sponge" optionLabel="name" optionValue="value"
                                    placeholder="Select Frequency" [disabled]="Sponge">
                                    [class.is-invalid]="(fourMonthAbove.submitted &&
                                    fourMonthToTowYear.get('bathing_frequency_sponge')?.invalid)
                                    ||
                                    fourMonthToTowYear.get('bathing_frequency_sponge')?.invalid
                                    &&
                                    fourMonthToTowYear.get('bathing_frequency_sponge').touched">
                                </p-dropdown>
                                <div class="invalid-feedback">frequency is required</div> -->
                                        <p-dropdown id="sponge_frequency" [options]="frequencyList"
                                            formControlName="bathing_frequency_sponge" optionLabel="name"
                                            (onChange)="addfield()" optionValue="value" [filter]="true" filterBy="value"
                                            placeholder="Select Frequency" [disabled]="Sponge" [class.is-invalid]="(fourMonthAbove.submitted &&
                            fourMonthToTowYear.get('bathing_frequency_sponge')?.invalid)
                            ||
                            fourMonthToTowYear.get('bathing_frequency_sponge')?.invalid
                            &&
                            fourMonthToTowYear.get('bathing_frequency_sponge').touched">
                                        </p-dropdown>
                                        <div class="invalid-feedback">Frequency is required</div>
                                    </div>
                                </div>

                                <div *ngIf="isBathing">
                                    <div class="row" formArrayName="bathing_time">
                                        <div *ngFor="let control of getbathingarray().controls; let i = index"
                                            [formGroupName]="i" class="col-lg-4 col-sm-6 col-12">
                                            <!-- <div class="col-md col-lg-2">
                                                <div class="form-group">
                                                    <label class="checkbox checkbox-outline px-0  mt-md-2">
                                                        <input type="checkbox" value="breastFeeding" name="checkboxes"
                                                            formControlName="sponge_checkbox" />
                                                        Done?
                                                    </label>
                                                </div>
                                            </div> -->
                                            <!-- <div class="col-lg-4 col-sm-6 col-12"> -->
                                            <div class="form-group">
                                                <label class="px-0">Time</label>
                                                <p-calendar placeholder="Select Time" [timeOnly]="true"
                                                    [hourFormat]="12" formControlName="bathing_sponge_time"
                                                    inputId="time" [disabled]="Sponge"
                                                    [class.is-invalid]="(fourMonthAbove.submitted && 
                                                        fourMonthToTowYear.get('bathing_sponge_time')?.invalid)
                                                        ||
                                                        fourMonthToTowYear.get('bathing_sponge_time')?.invalid
                                                        &&
                                                        fourMonthToTowYear.get('bathing_sponge_time').touched"></p-calendar>
                                            </div>
                                            <div class="invalid-feedback">Time is required</div>

                                            <!-- </div> -->
                                            <!-- <div class="col-lg-4 col-sm-6 col-12">
                                                <div class="form-group">
                                                    <label class="px-0">Remark</label>
                                                    <input type="text" name="name" class="form-control"
                                                        placeholder="Remark"
                                                        [formControl]="control.get('spongeBathing_remark')" />
                                                </div>
                                            </div> -->

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Tub Bath -->
                        <div class="row">
                            <div class="form-group  col-md-3 col-lg-2  d-flex mt-md-2 ">
                                <div class="form-check pl-0 checkbox-list px-0">
                                    <label class="checkbox checkbox-outline px-0">
                                        <input type="checkbox" value="Tub" formControlName="bathing_tub"
                                            (change)="handleInput($event, 'Bathing')" />
                                        <span></span>
                                        Tub
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label class="px-0">Frequency</label>
                                    <div class="card flex justify-content-center">
                                        <p-dropdown id="tub_frequency" [options]="frequencyList"
                                            (onChange)="addtubfield()" formControlName="bathing_tub_frequency"
                                            optionLabel="name" optionValue="value" [filter]="true" filterBy="value"
                                            placeholder="Select Frequency" [disabled]="Tub" [class.is-invalid]="(fourMonthAbove.submitted &&
                                            fourMonthToTowYear.get('bathing_tub_frequency')?.invalid)
                                            ||
                                            fourMonthToTowYear.get('bathing_tub_frequency')?.invalid
                                            &&
                                            fourMonthToTowYear.get('bathing_tub_frequency').touched">
                                        </p-dropdown>
                                        <div class="invalid-feedback">Frequency is required</div>
                                    </div>
                                </div>

                                <div *ngIf="isBathingtub">
                                    <div class="row" formArrayName="tub_bathing">
                                        <div *ngFor="let control of getbathingtubarray().controls; let i = index"
                                            [formGroupName]="i" class="col-lg-4 col-sm-6 col-4">
                                            <!-- <div class="col-md col-lg-2">
                                                <div class="form-group">
                                                    <label class="checkbox checkbox-outline px-0  mt-md-2">
                                                        <input type="checkbox" value="breastFeeding" name="checkboxes"
                                                            formControlName="tub_checkbox" />
                                                        Done?
                                                    </label>
                                                </div>
                                            </div> -->
                                            <!-- <div class="col-lg-4 col-sm-6 col-12"> -->
                                            <div class="form-group">
                                                <label class="px-0">Time</label>
                                                <p-calendar placeholder="Select Time" [timeOnly]="true"
                                                    formControlName="bathing_tub_time" [disabled]="Tub"
                                                    [hourFormat]="12" inputId="time"
                                                    [class.is-invalid]="(fourMonthAbove.submitted &&
                                                        fourMonthToTowYear.get('bathing_tub_time')?.invalid)
                                                        ||
                                                        fourMonthToTowYear.get('bathing_tub_time')?.invalid
                                                        &&
                                                        fourMonthToTowYear.get('bathing_tub_time').touched"></p-calendar>
                                                <div class="invalid-feedback">Time is required</div>
                                            </div>
                                            <!-- </div> -->
                                            <!-- <div class="col-lg-4 col-sm-6 col-12">
                                                <div class="form-group">
                                                    <label class="px-0">Remark</label>
                                                    <input type="text" name="name" class="form-control"
                                                        placeholder="Remark"
                                                        [formControl]="control.get('tub_remark')" />
                                                </div>
                                            </div> -->

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Shower -->
                        <div class="row">
                            <div class="form-group  col-md-3 col-lg-2 d-flex mt-md-2">
                                <div class="form-check pl-0 checkbox-list px-0">
                                    <label class="checkbox checkbox-outline px-0">
                                        <input type="checkbox" value="Shower" formControlName="bathing_shower"
                                            (change)="handleInput($event, 'Bathing')" />
                                        <span></span>
                                        Shower
                                    </label>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label class="px-0">Frequency</label>
                                    <div class="card flex justify-content-center">
                                        <p-dropdown id="tub_frequency" [options]="frequencyList"
                                            (onChange)="addshowerfield()" formControlName="bathing_shower_frequency"
                                            optionLabel="name" optionValue="value" [filter]="true" filterBy="value"
                                            placeholder="Select Frequency" [disabled]="Shower" [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('bathing_shower_frequency')?.invalid)
                                ||
                                fourMonthToTowYear.get('bathing_shower_frequency')?.invalid
                                &&
                                fourMonthToTowYear.get('bathing_shower_frequency').touched">
                                        </p-dropdown>
                                        <div class="invalid-feedback">Frequency is required</div>

                                    </div>
                                </div>

                                <div *ngIf="isBathingshower">
                                    <div class="row" formArrayName="shower_bathing">
                                        <div *ngFor="let control of getshowertubarray().controls; let i = index"
                                            [formGroupName]="i" class="col-lg-4 col-sm-6 col-12">

                                            <!-- <div class="col-md col-lg-2">
                                                <div class="form-group">
                                                    <label class="checkbox checkbox-outline px-0  mt-md-2">
                                                        <input type="checkbox" value="breastFeeding" name="checkboxes"
                                                            formControlName="shower_checkbox" />
                                                        Done?
                                                    </label>
                                                </div>
                                            </div> -->
                                            <!-- <div class="col-lg-4 col-sm-6 col-12"> -->
                                            <div class="form-group">
                                                <label class="px-0">Time</label>
                                                <p-calendar placeholder="Select Time" [timeOnly]="true"
                                                    formControlName="bathing_shower_time" [disabled]="Shower"
                                                    [hourFormat]="12" inputId="time"
                                                    [class.is-invalid]="(fourMonthAbove.submitted &&
                                                        fourMonthToTowYear.get('bathing_shower_time')?.invalid)
                                                        ||
                                                        fourMonthToTowYear.get('bathing_shower_time')?.invalid
                                                        &&
                                                        fourMonthToTowYear.get('bathing_shower_time').touched"></p-calendar>
                                                <div class="invalid-feedback">Time is required</div>
                                            </div>
                                            <!-- </div> -->
                                            <!-- <div class="col-lg-4 col-sm-6 col-12">
                                                <div class="form-group">
                                                    <label class="px-0">Remark</label>
                                                    <input type="text" name="name" class="form-control"
                                                        placeholder="Remark"
                                                        [formControl]="control.get('shower_remark')" />
                                                </div>
                                            </div> -->

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </p-accordionTab>

                <!-- </div> -->

                <!-- Skin Care -->
                <!-- <div class="add-listings-box"> -->
                <!-- <div class="form-check checkbox-list p-0">
                <div class="form-check checkbox-list p-0"> -->
                <p-accordionTab header="Skin Care">
                    <div class="add-listings-box p-0 border-0">
                        <label class="subHeading mb-2">Skin Cares</label>
                        <div formArrayName="skin_care">
                            <ng-container *ngFor="let control of getSkinFormArray().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">

                                    <div class="col-lg-4 ">
                                        <div class="form-group ">

                                            <div class="form-check checkbox-list p-0">
                                                <label for="Lotion" class="checkbox checkbox-outline px-0"> <input
                                                        type="checkbox" id="Lotion"
                                                        formControlName="skin_care_lotion"><span>Lotion</span></label>


                                                <label for="Baby_Oil"
                                                    class="checkbox checkbox-outline mt-sm-0 mt-3 px-0"><input
                                                        type="checkbox" id="Baby_Oil"
                                                        formControlName="skin_care_baby_oil"><span>Baby
                                                        Oil</span></label>


                                                <label for="other"
                                                    class="checkbox checkbox-outline mt-sm-0 mt-3 px-0"><input
                                                        type="checkbox" formControlName="skin_care_others"
                                                        value="others"><span>Others</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg ">
                                        <div class="form-group ">
                                            <!-- <label class="px-0">Specify Products</label> -->
                                            <div class="card flex justify-content-center">
                                                <textarea formControlName="skin_care_specify_produ" class="form-control"
                                                    placeholder="Specify Products" rows="2"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto d-flex mb-3 justify-content-end">
                                        <p-button type="button" icon="bx bx-plus" (click)="addSkinObject('add', i)"
                                            iconPos="right" class="secondary-btn mr-2"></p-button>
                                        <div class="remove">
                                            <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                icon="bx bx-minus" (click)="addSkinObject('delete', i)"
                                                iconPos="right"></p-button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                        <!-- wipes-->

                        <div class="row">
                            <label class="subHeading mb-2">Wipes</label>
                            <div class="col-lg-4 col-sm-6 col-12 d-flex align-items-center">
                                <div class="form-group">
                                    <div class="d-flex">
                                        <p class="me-2">
                                            <input type="radio" id="wipes_yes" value="1"
                                                formControlName="skin_care_Wipes"
                                                (change)="handleInput($event, 'wipes')">
                                            <label for="wipes_yes" class="mb-0 mx-2">Yes</label>
                                        </p>
                                        <p class="d-flex">
                                            <input type="radio" id="wipes_no" value="0"
                                                formControlName="skin_care_Wipes"
                                                (change)="handleInput($event, 'wipes')">
                                            <label for="wipes_no" class="mb-0 mx-2">No</label>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group mb-0">
                                    <label class="px-0">Special Instructions</label>
                                    <div class="form-group pb-3 mb-0">
                                        <textarea class="form-control" rows="2" placeholder="Special Instructions"
                                            formControlName="skin_care_special_instructions"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- </div> -->

                        <!-- Changing clothes -->
                        <!-- <div class="add-listings-box"> -->
                        <div class="row">
                            <label class="subHeading mb-2 mt-2">Changing of Clothes </label>
                            <!-- <div class="col-lg-4">
                                  <div class="form-group mb-0">
                                    <label class="px-0">Frequency </label>
                                    <div class="form-check checkbox-list p-0">
                                      <p-dropdown [options]="frequency" [(ngModel)]="selectedClothesFrequency"
                                          formControlName="changing_colthes_frequency" optionLabel="frequency" [filter]="true"
                                          filterBy="frequency" placeholder="Frequency" [resetFilterOnHide]="true">
                                        <ng-template pTemplate="selectedItem">
                                          <div class="flex align-items-center gap-2" *ngIf="selectedClothesFrequency">
                                            <div>{{ selectedClothesFrequency.frequency }}</div>
                                          </div>
                                        </ng-template>
                                        <ng-template let-frequency pTemplate="item">
                                          <div class="flex align-items-center gap-2">
                                            <div>{{ frequency.frequency }}</div>
                                          </div>
                                        </ng-template>
                                      </p-dropdown>
                                    </div>
                                  </div>
                                </div> -->

                            <div class="col">
                                <div class="form-group">
                                    <label class="px-0">Special Instructions: </label>
                                    <div class="col-12 p-0">
                                        <textarea name="name" class="form-control" placeholder="Special Instructions"
                                            formControlName="changing_colthes_spiecal_instructions"
                                            rows="2"> </textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" formArrayName="changing_clothes">
                            <ng-container
                                *ngFor="let control of getClothesFormArray().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">

                                    <!-- <div class="col-lg-4 col-sm-6 col-12">
                                        <div class="form-group">
                                            <div class="form-check checkbox-list p-0">
                                                <label class="checkbox checkbox-outline mt-sm-0 mt-3 px-0">
                                                    <input type="checkbox" value="withMother" name="checkboxes"
                                                        formControlName="changing_colthes" />
                                                    Changing Clothes
                                                </label>
                                            </div>
                                        </div>
                                    </div> -->

                                    <div class="col-lg-4 col-sm-6 col-12">
                                        <div class="form-group">
                                            <label class="px-0">Changing Time </label>
                                            <div class="form-check checkbox-list p-0">
                                                <p-calendar placeholder="Select Time" [timeOnly]="true"
                                                    [hourFormat]="12" inputId="time" formControlName="Changing_time"
                                                    [class.is-invalid]="(fourMonthAbove.submitted &&
                                                       fourMonthToTowYear.get('Changing_time')?.invalid) ||
                                                      fourMonthToTowYear.get('Changing_time')?.invalid &&
                                                      fourMonthToTowYear.get('Changing_time').touched">
                                                </p-calendar>
                                                <div class="invalid-feedback">Time is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-lg-4 col-sm-6 col-12">
                                        <div class="form-group">
                                            <label class="px-0">Remark</label>
                                            <input type="text" name="name" class="form-control" placeholder="Remark"
                                                [formControl]="control.get('clothe_remark')" />
                                        </div>
                                    </div> -->
                                    <div class="col-auto d-flex mb-3 align-items-end">
                                        <p-button type="button" icon="bx bx-plus" (click)="addClothesObject('add', i)"
                                            iconPos="right" class=" secondary-btn mr-2"></p-button>
                                        <div class="remove">
                                            <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                icon="bx bx-minus" (click)="addClothesObject('delete', i)"
                                                iconPos="right">
                                            </p-button>
                                        </div>
                                    </div>

                                </div>
                            </ng-container>
                        </div>

                    </div>
                </p-accordionTab>
                <!-- </div> -->


                <!--Sleeping-->
                <!-- <div class="add-listings-box"> -->
                <p-accordionTab header="Sleeping">
                    <div class="add-listings-box pb-0">
                        <h3>Sleeping</h3>
                        <div class="row">
                            <div class="row" formArrayName="sleeping_time">
                                <ng-container
                                    *ngFor="let control of getSleepArray().controls; index as i; last as last">
                                    <div class="row" [formGroupName]="i">
                                        <!-- <label class="subHeading mb-3">Sleeping </label> -->
                                        <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <div class="form-check checkbox-list p-0">
                                                    <!-- <div class="row py-3"> -->
                                                    <label
                                                        class="checkbox checkbox-outline col-lg-5 col-sm-6 col-12 px-0 mt-sm-4 mt-0">
                                                        <!-- <input type="checkbox" value="withMother" name="checkboxes"
                                                            (change)="handleInput($event, 'sleepingWith')"
                                                            formControlName="sleeping_mother" /> -->
                                                        With Mother
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Time </label>
                                                <div class="form-check checkbox-list p-0">
                                                    <p-calendar placeholder="Select Time" [timeOnly]="true"
                                                        [hourFormat]="12" inputId="time"
                                                        formControlName="sleeping_mother_time" [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('sleeping_mother_time')?.invalid)
                                ||
                                fourMonthToTowYear.get('sleeping_mother_time')?.invalid
                                &&
                                fourMonthToTowYear.get('sleeping_mother_time').touched"></p-calendar>
                                                    <div class="invalid-feedback">Time is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto d-flex mb-3 align-items-end">
                                            <p-button type="button" icon="bx bx-plus"
                                                (click)="addsleeptimeObject('add', i)" iconPos="right"
                                                class=" secondary-btn mr-2"></p-button>
                                            <div class="remove">
                                                <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                    icon="bx bx-minus" (click)="addsleeptimeObject('delete', i)"
                                                    iconPos="right">
                                                </p-button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>

                        <div class="row">
                            <div class="row" formArrayName="nanny_time">
                                <ng-container
                                    *ngFor="let control of getSleepnannyArray().controls; index as i; last as last">
                                    <div class="row" [formGroupName]="i">
                                        <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <div class="form-check checkbox-list p-0">
                                                    <label
                                                        class="checkbox checkbox-outline col-lg-5 col-sm-6 col-12 mt-sm-4 mt-3 px-0">
                                                        <!-- <input type="checkbox" value="withNanny" name="checkboxes"
                                                            (change)="handleInput($event, 'sleepingWith')"
                                                            formControlName="sleeping_nanny" /> -->
                                                        With Nanny
                                                    </label>
                                                    <!-- </div> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Time </label>
                                                <div class="form-check checkbox-list p-0">
                                                    <p-calendar placeholder="Select Time" [timeOnly]="true"
                                                        [hourFormat]="12" inputId="time"
                                                        formControlName="sleeping_nanny_time" [class.is-invalid]="(fourMonthAbove.submitted &&
                                          fourMonthToTowYear.get('sleeping_nanny_time')?.invalid)
                                          ||
                                          fourMonthToTowYear.get('sleeping_nanny_time')?.invalid
                                          &&
                                          fourMonthToTowYear.get('sleeping_nanny_time').touched"></p-calendar>
                                                    <div class="invalid-feedback">Time is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto d-flex mb-3 align-items-end">
                                            <p-button type="button" icon="bx bx-plus" (click)="addnannyObject('add', i)"
                                                iconPos="right" class=" secondary-btn mr-2"></p-button>
                                            <div class="remove">
                                                <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                    icon="bx bx-minus" (click)="addnannyObject('delete', i)"
                                                    iconPos="right">
                                                </p-button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </p-accordionTab>
                <!-- Sleep trining-->
                <p-accordionTab header="Sleep Training">
                    <div class="add-listings-box">

                        <div class="form-check checkbox-list p-0">
                            <div class="form-group">
                                <div class="subHeading mb-3">Sleep Training</div>
                                <div class="d-flex">
                                    <p class="me-2">
                                        <input type="radio" (change)="handleInput($event, 'Training')" id="training_yes"
                                            value="Yes" formControlName="sleep_train">
                                        <label for="training_yes" class="mb-0 mx-2">Yes</label>
                                    </p>
                                    <p class="d-flex">
                                        <input type="radio" id="training_no" value="No" formControlName="sleep_train">
                                        <label for="training_no" class="mb-0 mx-2">No</label>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <!--Walk up time -->
                        <div class="row">
                            <!-- <div class="form-group col-lg-4 col-sm-6 col-12 mb-0 d-flex align-items-center">
                                <div class="form-check pl-0 checkbox-list px-0">
                                    <label class="checkbox checkbox-outline px-0">
                                        Wake up time (morning)
                                    </label>
                                </div>
                            </div> -->
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="px-0"> Wake up time (morning)</label>
                                    <div class="card flex justify-content-center">
                                        <p-calendar placeholder="Time" formControlName="walk_time"
                                            [disabled]="sleep_training" [timeOnly]="true" [hourFormat]="12"
                                            inputId="time" [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('walk_time')?.invalid)
                                ||
                                fourMonthToTowYear.get('walk_time')?.invalid
                                &&
                                fourMonthToTowYear.get('walk_time').touched"></p-calendar>
                                        <div class="invalid-feedback">Wake up time is required</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-sm-6 col-12">
                                <div class="form-group mb-0">
                                    <label class="px-0">Nap time</label>
                                    <div class="card flex justify-content-center">
                                        <p-calendar placeholder="Time" formControlName="nap_time"
                                            [disabled]="sleep_training" [timeOnly]="true" [hourFormat]="12"
                                            inputId="time" [class.is-invalid]="(fourMonthAbove.submitted &&
                            fourMonthToTowYear.get('nap_time')?.invalid)
                            ||
                            fourMonthToTowYear.get('nap_time')?.invalid
                            &&
                            fourMonthToTowYear.get('nap_time').touched"></p-calendar>
                                        <div class="invalid-feedback">Nap time is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-12">
                                <div class="form-group mb-0">
                                    <label class="px-0"> Sleep time (evening) </label>
                                    <div class="card flex justify-content-center">
                                        <p-calendar placeholder="Time" [disabled]="sleep_training" [timeOnly]="true"
                                            formControlName="sleep_time" [hourFormat]="12" inputId="time"
                                            [class.is-invalid]="(fourMonthAbove.submitted &&
                            fourMonthToTowYear.get('sleep_time')?.invalid)
                            ||
                            fourMonthToTowYear.get('sleep_time')?.invalid
                            &&
                            fourMonthToTowYear.get('sleep_time').touched"></p-calendar>
                                        <div class="invalid-feedback"> Sleep time is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Nap time-->
                        <!-- <div class="row">
                            <div class="form-group col-lg-4 col-sm-6 col-12 mb-0 d-flex align-items-center">
                                <div class="form-check pl-0 checkbox-list px-0">
                                    <label class="checkbox checkbox-outline px-0">
                                        Nap time
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-12">
                                <div class="form-group mb-0">
                                    <label class="px-0">Nap time</label>
                                    <div class="card flex justify-content-center">
                                        <p-calendar placeholder="Time" formControlName="nap_time"
                                            [disabled]="sleep_training" [timeOnly]="true" [hourFormat]="12"
                                            inputId="time" [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('nap_time')?.invalid)
                                ||
                                fourMonthToTowYear.get('nap_time')?.invalid
                                &&
                                fourMonthToTowYear.get('nap_time').touched"></p-calendar>
                                        <div class="invalid-feedback">Nap time is required</div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!--Sleep time-->
                        <!-- <div class="row mt-3">
                            <div class="form-group col-lg-4 col-sm-6 col-12 mb-0 d-flex align-items-center">
                                <div class="form-check pl-0 checkbox-list px-0">
                                    <label class="checkbox checkbox-outline px-0 mt-sm-0 mt-2">
                                        Sleep time (evening)
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-12">
                                <div class="form-group mb-0">
                                    <label class="px-0"> Sleep time (evening) </label>
                                    <div class="card flex justify-content-center">
                                        <p-calendar placeholder="Time" [disabled]="sleep_training" [timeOnly]="true"
                                            formControlName="sleep_time" [hourFormat]="12" inputId="time"
                                            [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('sleep_time')?.invalid)
                                ||
                                fourMonthToTowYear.get('sleep_time')?.invalid
                                &&
                                fourMonthToTowYear.get('sleep_time').touched"></p-calendar>
                                        <div class="invalid-feedback"> Sleep time is required</div>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <!--Points-->
                        <div class="row mt-3">
                            <div class="col-lg-6">
                                <div class="form-check checkbox-list p-0">
                                    <div class="form-group">
                                        <label class="px-0">Stroller </label>
                                        <p class="d-flex">
                                            <input type="radio" id="stroller_yes" value="Yes"
                                                formControlName="stroller">
                                            <label for="stroller_yes" class="mb-0 mx-2">Yes</label>
                                        </p>
                                        <p class="d-flex">
                                            <input type="radio" id="stroller_no" value="No" formControlName="stroller">
                                            <label for="stroller_no" class="mb-0 mx-2">No</label>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-check checkbox-list p-0">
                                    <div class="form-group">
                                        <label class="px-0">Can the baby get himself back to sleep when interrupted
                                            during
                                            short
                                            naps?
                                        </label>
                                        <p class="d-flex">
                                            <input type="radio" id="baby_get_yes" value="Yes"
                                                formControlName="interrupt">
                                            <label for="baby_get_yes" class="mb-0 mx-2">Yes</label>
                                        </p>
                                        <p class="d-flex">
                                            <input type="radio" id="baby_get_no" value="No" formControlName="interrupt">
                                            <label for="baby_get_no" class="mb-0 mx-2">No</label>
                                        </p>
                                    </div>
                                </div>

                            </div>
                            <div class="col-lg-6">
                                <div class="form-check checkbox-list p-0">
                                    <div class="form-group">
                                        <label class="px-0">Needs dummy to help soothe the baby during sleep </label>
                                        <p class="d-flex">
                                            <input type="radio" id="sleep_yes" value="Yes" formControlName="need_dummy">
                                            <label for="sleep_yes" class="mb-0 mx-2">Yes</label>
                                        </p>
                                        <p class="d-flex">
                                            <input type="radio" id="sleep_no" value="No" formControlName="need_dummy">
                                            <label for="sleep_no" class="mb-0 mx-2">No</label>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-check checkbox-list p-0">
                                    <div class="form-group">
                                        <label class="px-0">Pacifier </label>
                                        <p class="d-flex">
                                            <input type="radio" id="pacifier_yes" value="Yes"
                                                formControlName="pacifier">
                                            <label for="pacifier_yes" class="mb-0 mx-2">Yes</label>
                                        </p>
                                        <p class="d-flex">
                                            <input type="radio" id="pacifier_no" value="No" formControlName="pacifier">
                                            <label for="pacifier_no" class="mb-0 mx-2">No</label>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label class="px-0">Songs before sleep </label>
                                    <p class="d-flex">
                                        <input type="radio" id="songs_yes" value="Yes" formControlName="song_sleep">
                                        <label for="songs_yes" class="mb-0 mx-2">Yes</label>
                                    </p>
                                    <p class="d-flex">
                                        <input type="radio" id="songs_no" value="No" formControlName="song_sleep">
                                        <label for="songs_no" class="mb-0 mx-2">No</label>
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label class="px-0">Reading before bedtime </label>
                                    <p class="d-flex">
                                        <input type="radio" id="reading_yes" value="Yes"
                                            formControlName="reading_sleep">
                                        <label for="reading_yes" class="mb-0 mx-2">Yes</label>
                                    </p>
                                    <p class="d-flex">
                                        <input type="radio" id="reading_no" value="No" formControlName="reading_sleep">
                                        <label for="reading_no" class="mb-0 mx-2">No</label>
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </p-accordionTab>
                <!--Temprature Monitoring-->
                <p-accordionTab header="Vitamins">
                    <!-- <div class="add-listings-box">
                        <div class="row">
                            <label class="subHeading mb-3">Temperature Monitoring(In hours)</label>
                            <div class="col-lg-4 col-12">
                                <div class="form-group">
                                    <label class="subHeading px-0">Frequency </label>
                                    <div class="card flex justify-content-center">
                                        <p-dropdown id="temp_frequency" [options]="frequencyList"   [filter]="true" filterBy="value"
                                            formControlName="temperature_monitoring_frequency" optionLabel="name"
                                            optionValue="value" placeholder="Select Frequency">
                                        </p-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <!--Vitamins/Medication-->

                    <div class="add-listings-box">
                        <div class="row">
                            <label class="subHeading mb-3">Vitamins/Medications</label>
                            <div formArrayName="vitamins_medications_json">
                                <ng-container
                                    *ngFor="let control of getVitaminFormArray().controls; index as i; last as last">
                                    <div class="row" [formGroupName]="i">
                                        <div class="col-md">
                                            <div class="form-group">
                                                <label class="px-0">Medications</label>
                                                <div class="pl-0 checkbox-list">
                                                    <input type="text" formControlName="vitamin" class="form-control"
                                                        placeholder="Vitamins/ Medications" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md">
                                            <div class="form-group">
                                                <label class="px-0">Frequency</label>
                                                <div class="card flex justify-content-center">
                                                    <p-dropdown id="vitamin_frequency" [filter]="true" filterBy="value"
                                                        [options]="frequencyList" formControlName="frequency"
                                                        optionLabel="name" (onChange)="addmedicinefield(i)"
                                                        optionValue="value" placeholder="Select Frequency">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="ismedicine">
                                            <div class="row" formArrayName="medicine_time">
                                                <div *ngFor="let control of getmedicinearray(i).controls; index as j"
                                                    class="col-lg-4" [formGroupName]="j">
                                                    <!-- <div class="row"> -->
                                                    <!-- <div class="col-md-3">
                                                                <label
                                                                    class="checkbox checkbox-outline col-lg-5 col-sm-6 col-12 mt-sm-4 mt-3 px-0">
                                                                    <input type="checkbox" value="withvitamin"
                                                                        name="checkboxes"
                                                                        formControlName="vitamin_checkbox" />
                                                                    Time
                                                                </label>
                                                            </div> -->
                                                    <div class="form-group">
                                                        <label class="px-0">Time</label>
                                                        <p-calendar placeholder="Time" formControlName="time"
                                                            [timeOnly]="true" [hourFormat]="12"
                                                            inputId="time"></p-calendar>
                                                    </div>
                                                    <!-- <div class="col-md col-sm-6 col-12">
                                                                <div class="form-group">
                                                                    <label class="px-0">Remark</label>
                                                                    <input type="text" name="name" class="form-control"
                                                                        placeholder="Remark"
                                                                        [formControl]="control.get('vitamin_remark')" />
                                                                </div>
                                                            </div> -->
                                                    <!-- </div> -->

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto d-flex mb-3 align-items-end">
                                            <p-button type="button" icon="bx bx-plus"
                                                (click)="addVitaminObject('add', i)" iconPos="right"
                                                class=" secondary-btn mr-2"></p-button>
                                            <div class="remove">
                                                <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                    icon="bx bx-minus" (click)="addVitaminObject('delete', i)"
                                                    iconPos="right"></p-button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="add-listings-box">
                        <label class="subHeading mb-3">Vaccinations</label>
                        <div class="row">
                            <div formArrayName="vaccinations_json">
                                <ng-container
                                    *ngFor="let control of getVaccinationFormArray().controls; index as i; last as last">
                                    <div class="row" [formGroupName]="i">
                                        <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Vaccinations</label>
                                                <div class="pl-0 checkbox-list">
                                                    <input type="text" formControlName="vaccination"
                                                        class="form-control" placeholder="Vaccinations" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Date of Administration</label>
                                                <div class="card flex justify-content-center">
                                                    <p-calendar placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                                                        formControlName="date_administration"
                                                        [showIcon]="true"></p-calendar>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-12 d-flex justify-content-end align-items-center">
                                            <div class="form-group mb-0">
                                                <div class="d-flex justify-content-end">
                                                    <p-button type="button" icon="bx bx-plus"
                                                        (click)="addVaccinationObject('add', i)" iconPos="right"
                                                        class="mr-2"></p-button>
                                                    <div class="remove">
                                                        <p-button type="button" *ngIf="i!= 0" class="ml-2"
                                                            icon="bx bx-minus"
                                                            (click)="addVaccinationObject('delete', i)"
                                                            iconPos="right"></p-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div> -->
                </p-accordionTab>
                <p-accordionTab header="Remarks">
                    <div class="add-listings-box">

                        <label class="subHeading mb-3">Remarks</label>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <div class="pl-0 checkbox-list">
                                        <textarea class="form-control" id="remarks" rows="5"
                                            formControlName="remarks"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </p-accordionTab>

            </p-accordion>

        </ng-container>
        <div class="add-listings-btn text-end">

            <button type="submit" [disabled]="!flag" *ngIf="checkPermission(9)">Submit</button>
        </div>
    </form>
    <app-copyrights></app-copyrights>
</div>