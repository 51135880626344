<p-toast></p-toast>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>
    <div class="dropdown profile-nav-item d-flex flex-column align-items-end">
        <a class="dropdown-toggle admin-btn" style="text-decoration: none;" role="button" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <div class="menu-profile">
                <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
                <span class="name">{{getname}}</span>
            </div>
        </a>
        <div class="dropdown-menu d-flex flex-column align-items-center">
            <div class="dropdown-header align">
                <div class="figure mb-3">
                    <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
                </div>
                <div class="info text-center">
                    <span class="name" style="white-space: break-spaces;">{{getname}}</span>
                    <p class="mb-3 email">{{getRole}}</p>
                </div>
            </div>
            <!-- <div class="dropdown-body">
                <ul class="profile-nav p-0 pt-3">
                    <li class="nav-item"><a routerLink="/dashboard-my-profile" class="nav-link"><i class='bx bx-user'></i> <span>Profile Dashboard</span></a></li>
                </ul>
            </div> -->
            <div class="dropdown-footer">
                <ul class="profile-nav">
                    <li class="nav-item"><a class="nav-link" (click)="logout()"><i class='bx bx-log-out'></i> <span
                                class="logout-text">Logout</span></a></li>
                </ul>
            </div>

        </div>
    </div>
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item">{{Content.subTitle}}</li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <form #container [formGroup]="threeYearAndAboveForm" (ngSubmit)="savethreeYearAndAboveForm(threeYearForm)"
        #threeYearForm="ngForm">
        <!-- BABY DETAILS SECTION -->
        <div class="add-listings-box">
            <h3>Baby Details</h3>
            <div class="row">
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="p-0">Name<span class="required">*</span></label>
                        <p-dropdown [options]="babyDetails" (onChange)="getRemainingDetails($event)" optionValue="id"
                            readonly="true" optionLabel="name" [filter]="true" filterBy="name"
                            formControlName="baby_details_id" placeholder="Select Baby's Name"
                            [resetFilterOnHide]="true" required [class.is-invalid]="(threeYearForm.submitted &&
                            threeYearAndAboveForm.get('baby_details_id')?.invalid)
                            ||
                            threeYearAndAboveForm.get('baby_details_id')?.invalid
                            &&
                            threeYearAndAboveForm.get('baby_details_id').touched">
                        </p-dropdown>
                        <div class="invalid-feedback">Name is required</div>
                    </div>
                </div>
                <!-- Select Gender Start-->
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Gender</label>
                        <div class="card flex justify-content-center">
                            <!-- <p-dropdown [options]="genderArray" optionLabel="name" optionValue="value" [filter]="true"
                                filterBy="name" [(ngModel)]="babyGender" readonly [ngModelOptions]="{standalone: true}"
                                placeholder="Select Baby's Gender" [resetFilterOnHide]="true">
                            </p-dropdown> -->
                            <input type="text" class="form-control" [(ngModel)]="babyGender"
                                [ngModelOptions]="{standalone: true}" placeholder="Select Baby's Gender" required
                                [readonly]="true">
                        </div>
                    </div>
                </div>
                <!-- Select Gender End-->
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="p-0">Date of birth</label>
                        <div class="card flex justify-content-center">
                            <!-- <p-calendar [(ngModel)]="selectedDate" placeholder="DD/MM/YYYY"
                                [ngModelOptions]="{standalone: true}" [showIcon]="true"
                                [readonlyInput]="true"></p-calendar> -->
                            <input type="text" name="name" class="form-control" placeholder="DD/MM/YYYY"
                                [value]="selectedDate" readonly />
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="p-0">Days</label>
                        <input type="text" name="name" class="form-control" placeholder="Years" [value]="days"
                            readonly />
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="p-0">Months</label>
                        <input type="text" name="name" class="form-control" placeholder="Years" [value]="month"
                            readonly />
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="p-0">Years</label>
                        <input type="text" name="name" class="form-control" placeholder="Age" [value]="years"
                            readonly />
                    </div>
                </div>
            </div>
        </div>

        <!-- Feeding -->
        <!-- accordion start -->

        <ng-container *ngIf="flag">
            <p-accordion>
                <!-- allergy accordion start -->
                <p-accordionTab header="Allergy">
                    <div class="add-listings-box">
                        <div class="row">
                            <!-- <div class="form-group row mb-sm-4 mb-0">
                                <div class="col-lg-4"> -->

                            <label class="subHeading mb-3 mt-sm-0 mt-3">Food/Medicines Allergy:</label>
                            <div class="form-group  col-lg-3 col-md-4 col-12 d-flex flex-column">
                                <div class="form-group">
                                    <div class="d-flex">
                                        <p class="ms-2">
                                            <input type="radio" id="allergy_yes" value="1"
                                                formControlName="food_medicine_allergy"
                                                (change)="handleInput($event, 'fnmAllergy')" name="allergy_option">
                                            <label for="allergy_yes" class="mb-0 mx-2">Yes</label>
                                        </p>
                                        <p class="d-flex">
                                            <input type="radio" id="allergy_no" value="0"
                                                formControlName="food_medicine_allergy"
                                                (change)="handleInput($event, 'fnmAllergy')" name="allergy_option">
                                            <label for="allergy_no" class="mb-0 mx-2">No</label>
                                        </p>
                                    </div>
                                    <!-- </div>
                                    </div> -->
                                </div>
                            </div>

                            <!-- Special Instructions-->
                            <div class="col" id="wipes">
                                <div class="col-lg-12">
                                    <!-- <label class="px-0">Specify Products:</label> -->
                                    <div>
                                        <textarea class="form-control" id="kt_autosize_1" rows="2"
                                            formControlName="food_medicine_allergy_specify_prod"
                                            placeholder="Specify Products"
                                            [class.is-invalid]="(threeYearForm.submitted &&
                                            threeYearAndAboveForm.get('food_medicine_allergy_specify_prod')?.invalid)
                                            ||
                                            threeYearAndAboveForm.get('food_medicine_allergy_specify_prod')?.invalid
                                            &&
                                            threeYearAndAboveForm.get('food_medicine_allergy_specify_prod').touched"></textarea>
                                        <div class="invalid-feedback">Specify product is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </p-accordionTab>

                <p-accordionTab header="Solid Food Feeding">
                    <div class="add-listings-box">
                        <h3>Daily Cares</h3>
                        <div class="mb-0">
                            <!-- <div "> -->
                            <div class="form-check checkbox-list p-0">
                                <label class="p-0 subHeading">Feeding: <span class="fw-normal">Prepared
                                        By</span></label>
                                        <div class=" ">
                                            <div class="form-check checkbox-list p-0">
                                                <div class="form-group ">
                                                    <div class=" ">
                                                        <div class="row">
                                                            <div class="col-auto">
                
                                                                <label for="mother" class="checkbox checkbox-outline px-0"><input
                                                                        type="checkbox" id="mother"
                                                                        formControlName="feeding_prepared_mother"
                                                                        value="mother"><span>Mother</span></label>
                                                            </div>
                                                            <div class="col-auto">
                
                                                                <label for="nanny" class="checkbox checkbox-outline px-0"> <input
                                                                        type="checkbox" formControlName="feeding_prepared_nanny"
                                                                        id="nanny" value="nanny"><span>Nurse</span></label>
                                                            </div>
                                                            <div class="col-auto">
                
                                                                <label for="other" class="checkbox checkbox-outline px-0"> <input
                                                                        type="checkbox" id="other"
                                                                        formControlName="feeding_prepared_other"
                                                                        value="other"><span>Other</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                            </div>
                        </div>


                        <!-- Regular meals -->

                        <div class="row">
                            <label class="subHeading mb-3 mt-sm-0 mt-3">Regular Meals:</label>
                            <div formArrayName="regular_food">
                                <ng-container
                                    *ngFor="let control of getRegularFormArray().controls; index as i; last as last">
                                    <div class="row" [formGroupName]="i">
                                        <div class="form-group mb-2 col-md-3 col-lg-2 d-flex align-items-center">
                                            <div class="form-check p-0 checkbox-list">
                                                <label class="checkbox checkbox-outline p-0 mt-sm-auto mt-3">
                                                    <!-- <input type="checkbox" value="breakfast"
                                                        formControlName="regular_meal_type"
                                                        (change)="disableAllRegularFoodControls($event.target.checked,i)" /> -->
                                                    <span></span>
                                                    Breakfast
                                                </label>
                                            </div>
                                        </div>
                                        <!-- breakfast -->
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label class="p-0">Time:</label>
                                                <div class="card flex justify-content-center">
                                                    <p-calendar placeholder="Select Time" [timeOnly]="true"
                                                        [hourFormat]="12" inputId="time"
                                                        formControlName="regular_meal_time"
                                                        [class.is-invalid]="(threeYearForm.submitted &&
                                                        threeYearAndAboveForm.get('regular_meal_time')?.invalid)
                                                        ||
                                                        threeYearAndAboveForm.get('regular_meal_time')?.invalid
                                                        &&
                                                        threeYearAndAboveForm.get('regular_meal_time').touched"></p-calendar>
                                                    <div class="invalid-feedback">time is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md">
                                            <div class="form-group ml-0 col-12 p-0 mb-0">
                                                <label class="p-0">Food components:</label>
                                                <div class="col-12 p-0">
                                                    <input type="text" class="form-control"
                                                        placeholder="Food components"
                                                        formControlName="regular_meal_food_components"
                                                        [class.is-invalid]="(threeYearForm.submitted &&
                                                        threeYearAndAboveForm.get('regular_meal_food_components')?.invalid)
                                                        ||
                                                        threeYearAndAboveForm.get('regular_meal_food_components')?.invalid
                                                        &&
                                                        threeYearAndAboveForm.get('regular_meal_food_components').touched" />
                                                    <div class="invalid-feedback">Food components is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto d-flex align-items-center form group">
                                            <p-button type="button" icon="bx bx-plus"
                                                (click)="addRegularObject('add', i)" iconPos="right"
                                                class="secondary-btn mr-2"></p-button>
                                            <div class="remove">
                                                <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                    icon="bx bx-minus" (click)="addRegularObject('delete', i)"
                                                    iconPos="right"></p-button>
                                            </div>
                                        </div>
                                    </div>

                                </ng-container>
                            </div>
                        </div>

                        <!-- Lunck checkbox -->
                        <div formArrayName="lunch_food">
                            <ng-container
                                *ngFor="let control of getLunchFormArray().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">
                                    <div class="form-group mb-2 col-md-3 col-lg-2 d-flex align-items-center">
                                        <div class="form-check p-0 checkbox-list">
                                            <label class="checkbox checkbox-outline p-0 mt-sm-auto mt-3">
                                                <!-- <input type="checkbox" value="lunch"
                                                    (change)="disableAlllunchFoodControls($event.target.checked,i)"
                                                    formControlName="regular_meal_lunch" /> -->
                                                <span></span>
                                                Lunch
                                            </label>
                                        </div>
                                    </div>


                                    <!-- lunch -->
                                    <!-- <div class=" row form-group mb-0"> -->
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label class="p-0">Time:</label>
                                            <div class="card flex justify-content-center">
                                                <p-calendar placeholder="Select Time" [timeOnly]="true"
                                                    [hourFormat]="12" inputId="time"
                                                    formControlName="regular_meal_lunch_time"
                                                    [class.is-invalid]="(threeYearForm.submitted &&
                                                    threeYearAndAboveForm.get('regular_meal_lunch_time')?.invalid)
                                                    ||
                                                    threeYearAndAboveForm.get('regular_meal_lunch_time')?.invalid
                                                    &&
                                                    threeYearAndAboveForm.get('regular_meal_lunch_time').touched"></p-calendar>
                                                <div class="invalid-feedback">time is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md">
                                        <div class="form-group ml-0 col-12 p-0 mb-0">
                                            <label class="p-0">Food components:</label>
                                            <div class="col-12 p-0">
                                                <input type="text" class="form-control" placeholder="Food components"
                                                    formControlName="regular_meal_lunch_food_components"
                                                    [class.is-invalid]="(threeYearForm.submitted &&
                                                    threeYearAndAboveForm.get('regular_meal_lunch_food_components')?.invalid)
                                                    ||
                                                    threeYearAndAboveForm.get('regular_meal_lunch_food_components')?.invalid
                                                    &&
                                                    threeYearAndAboveForm.get('regular_meal_lunch_food_components').touched" />
                                                <div class="invalid-feedback">Food components is required</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-auto d-flex align-items-center form group">
                                        <p-button type="button" icon="bx bx-plus" (click)="addLunchObject('add', i)"
                                            iconPos="right" class="secondary-btn mr-2"></p-button>
                                        <div class="remove">
                                            <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                icon="bx bx-minus" (click)="addLunchObject('delete', i)"
                                                iconPos="right"></p-button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <!-- Dinner checkbox -->
                        <div formArrayName="dinner_food">
                            <ng-container
                                *ngFor="let control of getDinnerFormArray().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">
                                    <div class="form-group mb-0 col-md-3 col-lg-2 d-flex align-items-center">
                                        <div class="form-check p-0 checkbox-list">
                                            <label class="checkbox checkbox-outline p-0 my-sm-auto my-3">
                                                <!-- <input type="checkbox" value="dinner"
                                                    (change)="disableAlldinnerFoodControls($event.target.checked,i)"
                                                    formControlName="regular_meal_dinner" /> -->
                                                <span></span>
                                                Dinner
                                            </label>
                                        </div>
                                    </div>
                                    <!-- Dinner -->
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label class="p-0">Time:</label>
                                            <div class="card flex justify-content-center">
                                                <p-calendar placeholder="Select Time" [timeOnly]="true"
                                                    [hourFormat]="12" inputId="time"
                                                    formControlName="regular_meal_dinner_time"
                                                    [class.is-invalid]="(threeYearForm.submitted &&
                                                    threeYearAndAboveForm.get('regular_meal_dinner_time')?.invalid)
                                                    ||
                                                    threeYearAndAboveForm.get('regular_meal_dinner_time')?.invalid
                                                    &&
                                                    threeYearAndAboveForm.get('regular_meal_dinner_time').touched"></p-calendar>
                                                <div class="invalid-feedback">time is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md">
                                        <div class="form-group ml-0 col-12 p-0 mb-sm-0">
                                            <label class="p-0">Food components:</label>
                                            <div class="col-12 p-0">
                                                <input type="text" class="form-control" placeholder="Food components"
                                                    formControlName="regular_meal_dinner_food_components"
                                                    [class.is-invalid]="(threeYearForm.submitted &&
                                                    threeYearAndAboveForm.get('regular_meal_dinner_food_components')?.invalid)
                                                    ||
                                                    threeYearAndAboveForm.get('regular_meal_dinner_food_components')?.invalid
                                                    &&
                                                    threeYearAndAboveForm.get('regular_meal_dinner_food_components').touched" />
                                                <div class="invalid-feedback">Food components is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-auto d-flex align-items-center form group">
                                        <p-button type="button" icon="bx bx-plus" (click)="addDinnerObject('add', i)"
                                            iconPos="right" class="secondary-btn mr-2"></p-button>
                                        <div class="remove">
                                            <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                icon="bx bx-minus" (click)="addDinnerObject('delete', i)"
                                                iconPos="right"></p-button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                        <!-- others -->
                        <div class="row ">
                            <div class="form-group col-md-3 col-lg-2 ">
                                <div class="form-check mt-md-2 p-0 checkbox-list">
                                    <label class="checkbox checkbox-outline p-0">
                                        <!-- <input type="checkbox" value="snack" name="solid_other_snack"
                                            (change)="handleInput($event, 'snacks')"
                                            formControlName="solid_other_snack" /> -->
                                        <span></span>
                                        Snack
                                    </label>

                                </div>
                            </div>
                            <!-- snack component -->
                            <!-- <div class=" row form-group"> -->
                            <div class="col-sm-4 col-12">
                                <div class="form-group">
                                    <label class="p-0">Time:</label>
                                    <div class="card flex justify-content-center">
                                        <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12"
                                            inputId="time" formControlName="solid_other_snack_time" [class.is-invalid]="(threeYearForm.submitted &&
                                threeYearAndAboveForm.get('solid_other_snack_time')?.invalid)
                                ||
                                threeYearAndAboveForm.get('solid_other_snack_time')?.invalid
                                &&
                                threeYearAndAboveForm.get('solid_other_snack_time').touched"></p-calendar>
                                        <div class="invalid-feedback">time is required</div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md">
                                <div class="form-group ml-0 col-12 p-0 mb-0">
                                    <label class="p-0">Food components:</label>
                                    <div class="col-12 p-0">
                                        <input type="text" class="form-control" placeholder="Food component"
                                            formControlName="soild_other_snack_food_components" [class.is-invalid]="(threeYearForm.submitted &&
                                threeYearAndAboveForm.get('soild_other_snack_food_components')?.invalid)
                                ||
                                threeYearAndAboveForm.get('soild_other_snack_food_components')?.invalid
                                &&
                                threeYearAndAboveForm.get('soild_other_snack_food_components').touched" />
                                        <div class="invalid-feedback">Food components is required</div>
                                    </div>
                                </div>
                            </div>
                            <!-- </div> -->
                        </div>
                    </div>
                </p-accordionTab>
                <!-- Food/medicines allergy -->

                <!-- </div> -->
                <!-- Diaper Change start  -->
                <!-- <p-accordionTab header="Diaper Change">
                    <div class="add-listings-box p-0 border-0">

                        <div class="row" formArrayName="diaper_change_times">
                            <ng-container
                                *ngFor="let control of getDiaperChangeControls().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">

                                    <div class="col-lg-2 col-sm-6 col-12 mt-3">
                                        <div class="form-group">
                                            <div class="form-check checkbox-list p-0">
                                                <label class="checkbox checkbox-outline mt-sm-0 mt-3 px-0">
                                                    <input type="checkbox" value="withMother" name="checkboxes"
                                                        formControlName="change" />
                                                    Changed?
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-sm-6 col-12">
                                        <div class="form-group">
                                            <label class="px-0">Changing Time </label>
                                            <div class="form-check checkbox-list p-0">
                                                <p-calendar placeholder="Select Time" [timeOnly]="true"
                                                    [hourFormat]="12" inputId="time" formControlName="time"
                                                    [class.is-invalid]="(threeYearForm.submitted &&
                                               threeYearAndAboveForm.get('time')?.invalid) ||
                                              threeYearAndAboveForm.get('time')?.invalid &&
                                              threeYearAndAboveForm.get('time').touched">
                                                </p-calendar>
                                                <div class="invalid-feedback">Time is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-6 col-12">
                                        <div class="form-group">
                                            <label class="px-0">Remark</label>
                                            <input type="text" name="name" class="form-control" placeholder="Remark"
                                                [formControl]="control.get('diaper_remark')" />
                                        </div>
                                    </div>
                                    <div class="col-auto d-flex mb-3 align-items-end">
                                        <p-button type="button" icon="bx bx-plus" (click)="adddiaperObject('add', i)"
                                            iconPos="right" class=" secondary-btn mr-2"></p-button>
                                        <div class="remove">
                                            <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                icon="bx bx-minus" (click)="adddiaperObject('delete', i)"
                                                iconPos="right">
                                            </p-button>
                                        </div>
                                    </div>

                                </div>
                            </ng-container>
                        </div>
                    </div>
                </p-accordionTab> -->
                <!-- <div class="add-listings-box pt-4"> -->
                <!--Tub Bath -->
                <p-accordionTab header="Bathing">
                    <div class="add-listings-box">
                        <div class="row">
                            <!-- <div class="col-sm-4 col-12"> -->
                            <label class="subHeading mb-3">Bathing:</label>
                            <div class="form-group col-md-3 col-lg-2 d-flex mt-md-2">
                                <div class="form-check pl-0 checkbox-list px-0">
                                    <label class="checkbox checkbox-outline px-0">
                                        <input type="checkbox" value="tub-bath" name="bathing_type"
                                            (change)="handleInput($event, 'bathing')" formControlName="bathing_tub" />
                                        <span></span>
                                        Tub Bath
                                    </label>
                                </div>
                            </div>
                            <!-- </div> -->

                            <div class="col">
                                <div class="form-group">
                                    <label class="p-0">Frequency:</label>
                                    <div class="card flex justify-content-center">
                                        <p-dropdown [options]="frequency" optionLabel="frequency" [filter]="true"
                                            filterBy="frequency" placeholder="Frequency" [resetFilterOnHide]="true"
                                            formControlName="bathing_tub_frequency" (onChange)="addtubfield()"
                                            [class.is-invalid]="(threeYearForm.submitted &&
                                            threeYearAndAboveForm.get('bathing_tub_frequency')?.invalid)
                                            ||
                                            threeYearAndAboveForm.get('bathing_tub_frequency')?.invalid
                                            &&
                                            threeYearAndAboveForm.get('bathing_tub_frequency').touched">

                                            <ng-template pTemplate="selectedItem">
                                                <div class="flex align-items-center gap-2"
                                                    *ngIf="threeYearAndAboveForm?.value?.bathing_tub_frequency">
                                                    <div>{{
                                                        threeYearAndAboveForm?.value?.bathing_tub_frequency?.frequency
                                                        }}</div>
                                                </div>
                                            </ng-template>
                                            <ng-template let-frequency pTemplate="item">
                                                <div class="flex align-items-center gap-2">
                                                    <div>{{ frequency?.frequency }}</div>
                                                </div>
                                            </ng-template>

                                        </p-dropdown>
                                        <div class="invalid-feedback">Frequency is required</div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isBathingtub" class="add-listings-box pt-3 mb-3 col-lg-12">
                                <div class="row" formArrayName="tub_bathing">
                                    <div *ngFor="let control of getbathingtubarray().controls; let i = index"
                                        [formGroupName]="i" class="col-lg-4 col-sm-6 col-12">

                                        <!-- <div class="col-md col-lg-2">
                                            <div class="form-group">
                                                <label class="checkbox checkbox-outline px-0  mt-md-2">
                                                    <input type="checkbox" value="breastFeeding" name="checkboxes"
                                                        formControlName="tub_checkbox" />
                                                    Time
                                                </label>
                                            </div>
                                        </div> -->

                                        <!-- <div class="col-lg-4 col-sm-6 col-12"> -->
                                        <div class="form-group">
                                            <label class="p-0">Time:</label>
                                            <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12"
                                                [disabled]="tubBath" inputId="time" formControlName="bathing_tub_time"
                                                [class.is-invalid]="(threeYearForm.submitted &&
                                                    threeYearAndAboveForm.get('bathing_tub_time')?.invalid)
                                                    ||
                                                    threeYearAndAboveForm.get('bathing_tub_time')?.invalid
                                                    &&
                                                    threeYearAndAboveForm.get('bathing_tub_time').touched"></p-calendar>
                                            <div class="invalid-feedback">time is required</div>
                                        </div>
                                        <!-- </div> -->
                                        <!-- <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Remark</label>
                                                <input type="text" name="name" class="form-control" placeholder="Remark"
                                                    [formControl]="control.get('tubBathing_remark')" />
                                            </div>
                                        </div> -->

                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Shower bathing -->
                        <div class="row">
                            <div class="col-md-3 col-lg-2 d-flex align-items-center">
                                <div class="form-group mb-2">
                                    <div class="form-check p-0 checkbox-list">
                                        <label class="checkbox checkbox-outline p-0 m-0">
                                            <input type="checkbox" value="shower-bath" name="bathing_type"
                                                (change)="handleInput($event, 'showerbath')"
                                                formControlName="bathing_shower" />
                                            <span></span>
                                            Shower
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="col">
                                <div class="form-group">
                                    <label class="p-0">Frequency:</label>
                                    <div class="card flex justify-content-center">
                                        <p-dropdown [options]="frequency" optionLabel="frequency" [filter]="true"
                                            filterBy="frequency" placeholder="Frequency" [resetFilterOnHide]="true"
                                            formControlName="bathing_shower_frequency" (onChange)="addshowerfield()"
                                            [class.is-invalid]="(threeYearForm.submitted &&
                                            threeYearAndAboveForm.get('bathing_shower_frequency')?.invalid)
                                            ||
                                            threeYearAndAboveForm.get('bathing_shower_frequency')?.invalid
                                            &&
                                            threeYearAndAboveForm.get('bathing_shower_frequency').touched">
                                            <ng-template pTemplate="selectedItem">
                                                <div class="flex align-items-center gap-2"
                                                    *ngIf="threeYearAndAboveForm?.value?.bathing_shower_frequency">
                                                    <div>{{
                                                        threeYearAndAboveForm?.value?.bathing_shower_frequency?.frequency
                                                        }}
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template let-frequency pTemplate="item">
                                                <div class="flex align-items-center gap-2">
                                                    <div>{{ frequency?.frequency }}</div>
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                        <div class="invalid-feedback">Frequency is required</div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="isBathingshower" class="add-listings-box pt-3 mb-3 col-lg-12">
                                <div class="row" formArrayName="shower_bathing">
                                    <div *ngFor="let control of getshowertubarray().controls; let i = index"
                                        [formGroupName]="i" class="col-lg-4 col-sm-6 col-12">
                                        <!-- <div class="col-md col-lg-2">
                                            <div class="form-group">
                                                <label class="checkbox checkbox-outline px-0  mt-md-2">
                                                    <input type="checkbox" value="breastFeeding" name="checkboxes"
                                                        formControlName="shower_checkbox" />
                                                    Time
                                                </label>
                                            </div>
                                        </div> -->

                                        <!-- <div class="col-lg-4 col-sm-6 col-12"> -->
                                        <div class="form-group">
                                            <label class="p-0">Time:</label>
                                            <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12"
                                                inputId="time" [disabled]="shower" formControlName="bathing_shower_time"
                                                [class.is-invalid]="(threeYearForm.submitted &&
                                                    threeYearAndAboveForm.get('bathing_shower_time')?.invalid)
                                                    ||
                                                    threeYearAndAboveForm.get('bathing_shower_time')?.invalid
                                                    &&
                                                    threeYearAndAboveForm.get('bathing_shower_time').touched"></p-calendar>
                                            <div class="invalid-feedback">time is required</div>
                                        </div>
                                        <!-- </div> -->
                                        <!-- <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Remark</label>
                                                <input type="text" name="name" class="form-control" placeholder="Remark"
                                                    [formControl]="control.get('shower_remark')" />
                                            </div>
                                        </div> -->
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </p-accordionTab>

                <!-- Skin care -->
                <p-accordionTab header="Skin Care">
                    <div class="add-listings-box">
                        <!-- form array add -->
                        <label class="subHeading px-0">Skin Care:
                        </label>
                        <div formArrayName="skin_care">
                            <ng-container *ngFor="let control of getSkinFormArray().controls; index as i; last as last">
                                <div class="form-group row" [formGroupName]="i">
                                    <div class="col-lg-4 col-sm-6 col-12">

                                        <div class="form-check pl-0 checkbox-list px-0">
                                            <label class="checkbox checkbox-outline px-0">
                                                <input type="checkbox" name="skin_care_lotion"
                                                    formControlName="skin_care_lotion" value="0"
                                                    (change)="handleInput($event, 'others')" />
                                                <span></span>
                                                Lotion:
                                            </label>
                                            <label class="checkbox checkbox-outline px-0">
                                                <input type="checkbox" name="skin_care_baby_oil"
                                                    formControlName="skin_care_baby_oil" value="0"
                                                    (change)="handleInput($event, 'others')" />
                                                <span></span>
                                                Baby Oil:
                                            </label>
                                            <label class="checkbox checkbox-outline px-0">
                                                <input type="checkbox" (change)="handleInput($event, 'others')"
                                                    value="skin-care" value="0" name="skin_care_others"
                                                    formControlName="skin_care_others" />
                                                <span></span>
                                                Others:
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col" id="skin_care">
                                        <div class="col-lg-12">
                                            <!-- <label class="px-0">Specify Products:</label> -->
                                            <div>
                                                <textarea class="form-control  " id="kt_autosize_1" rows="2"
                                                    formControlName="skin_care_specify_produ"
                                                    placeholder="Specify Products"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-auto d-flex mb-3 align-items-end">
                                        <p-button type="button" icon="bx bx-plus" (click)="addSkinObject('add', i)"
                                            iconPos="right" class="secondary-btn mr-2"></p-button>
                                        <div class="remove">
                                            <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                icon="bx bx-minus" (click)="addSkinObject('delete', i)"
                                                iconPos="right"></p-button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <!-- wipes -->
                        <label class="subHeading px-0">Wipes:
                        </label>
                        <div class="form-group row">
                            <div class="col-lg-4 col-sm-6 col-12">
                                <div class="radio-inline">
                                    <div class="d-flex">
                                        <p class="me-2">
                                            <input type="radio" id="wipes-yes" (change)="handleInput($event, 'wipes')"
                                                formControlName="skin_care_Wipes" value="1">
                                            <label for="wipes-yes" class="mb-0 mx-sm-2 mx-0">Yes</label>
                                        </p>
                                        <p class="d-flex">
                                            <input type="radio" id="wipes-no" (change)="handleInput($event, 'wipes')"
                                                formControlName="skin_care_Wipes" value="0">
                                            <label for="wipes-no" class="mb-0 mx-sm-2 mx-0">No</label>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!-- Special Instructions-->
                            <div class="col">
                                <div class="col-lg-12">
                                    <label class="px-0">Special Instructions:</label>
                                    <div>
                                        <textarea class="form-control  " id="kt_autosize_1" rows="2"
                                            formControlName="skin_care_special_instructions"
                                            placeholder="Special Instructions"></textarea>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <!-- Changing clothes -->
                        <div class="row">
                            <label class="subHeading py-3">Changing clothes:</label>
                            <!-- <div class="col-lg-4 col-sm-6 col-12">
                                <div class="form-group">
                                    <label class="p-0">Frequency:</label>
                                    <div class="card flex justify-content-center">
                                        <p-dropdown [options]="frequency" optionLabel="frequency" [filter]="true"
                                            filterBy="frequency" placeholder="Frequency" [resetFilterOnHide]="true"
                                            formControlName="changing_colthes_frequency">
                                            <ng-template pTemplate="selectedItem">
                                                <div class="flex align-items-center gap-2"
                                                    *ngIf="threeYearAndAboveForm?.value?.changing_colthes_frequency">
                                                    <div>{{
                                                        threeYearAndAboveForm?.value?.changing_colthes_frequency?.frequency
                                                        }}
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template let-frequency pTemplate="item">
                                                <div class="flex align-items-center gap-2">
                                                    <div>{{ frequency?.frequency }}</div>
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                    </div>
                                </div>
                            </div> -->

                            <div class="col">
                                <div class="form-group mb-2">
                                    <label class="p-0">Special Instructions:</label>
                                    <textarea name="changing_colthes_spiecal_instructions" class="form-control  "
                                        id="kt_autosize_1" rows="2" placeholder="Special Instructions"
                                        formControlName="changing_colthes_spiecal_instructions"></textarea>
                                </div>
                            </div>
                            <div class="row" formArrayName="changing_clothes">
                                <ng-container
                                    *ngFor="let control of getClothesFormArray().controls; index as i; last as last">
                                    <div class="row" [formGroupName]="i">

                                        <!-- <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <div class="form-check checkbox-list p-0">
                                                    <label class="checkbox checkbox-outline mt-sm-0 mt-3 px-0">
                                                        <input type="checkbox" value="withMother" name="checkboxes"
                                                            formControlName="changing_colthes" />
                                                        Changing Clothes
                                                    </label>
                                                </div>
                                            </div>
                                        </div> -->

                                        <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Changing Time </label>
                                                <div class="form-check checkbox-list p-0">
                                                    <p-calendar placeholder="Select Time" [timeOnly]="true"
                                                        [hourFormat]="12" inputId="time" formControlName="Changing_time"
                                                        [class.is-invalid]="(threeYearForm.submitted &&
                                                       threeYearAndAboveForm.get('Changing_time')?.invalid) ||
                                                      threeYearAndAboveForm.get('Changing_time')?.invalid &&
                                                      threeYearAndAboveForm.get('Changing_time').touched">
                                                    </p-calendar>
                                                    <div class="invalid-feedback">Time is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Remark</label>
                                                <input type="text" name="name" class="form-control" placeholder="Remark"
                                                    [formControl]="control.get('clothe_remark')" />
                                            </div>
                                        </div> -->
                                        <div class="col-auto d-flex mb-3 align-items-end">
                                            <p-button type="button" icon="bx bx-plus"
                                                (click)="addClothesObject('add', i)" iconPos="right"
                                                class=" secondary-btn mr-2"></p-button>
                                            <div class="remove">
                                                <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                    icon="bx bx-minus" (click)="addClothesObject('delete', i)"
                                                    iconPos="right">
                                                </p-button>
                                            </div>
                                        </div>

                                    </div>
                                </ng-container>
                            </div>

                        </div>
                    </div>
                </p-accordionTab>
                <!-- </div> -->



                <!-- Sleeping -->
                <!-- <div class="add-listings-box"> -->
                <!-- With mother-->
                <p-accordionTab header="Sleeping">
                    <div class="add-listings-box">
                        <div class="row">
                            <label class="subHeading py-3">Sleeping Independently:</label>
                            <div class="radio-inline mb-3">
                                <div class="d-flex">
                                    <p class="me-3">
                                        <input type="radio" id="sleeping-yes" formControlName="sleeping_independently"
                                            value="1">
                                        <label for="sleeping-yes" class="mb-0 mx-sm-2 mx-0">Yes</label>
                                    </p>
                                    <p class="d-flex">
                                        <input type="radio" id="sleeping-no" formControlName="sleeping_independently"
                                            value="0">
                                        <label for="sleeping-no" class="mb-0 mx-sm-2 mx-0">No</label>
                                    </p>
                                </div>
                            </div>
                            <!-- <div class="col-lg-4 col-sm-6 col-12 d-flex align-items-center">
                                <div class="form-group">
                                    <div class="form-check p-0 checkbox-list">
                                        <label class="checkbox checkbox-outline p-0">
                                            <input type="checkbox" value="sleep-with-mother"
                                                (change)="handleInput($event, 'sleeping-with-mother')"
                                                formControlName="sleeping_mother" />
                                            <span></span>
                                            With Mother
                                        </label>
                                    </div>
                                </div>
                            </div> -->

                            <!-- <div class="col-lg-4 col-sm-6 col-12">

                                <div class="form-group">
                                    <label class="p-0">Time:</label>
                                    <div class="card flex justify-content-center">
                                        <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12"
                                            inputId="time" formControlName="sleeping_mother_time" [class.is-invalid]="(threeYearForm.submitted &&
                                threeYearAndAboveForm.get('sleeping_mother_time')?.invalid)
                                ||
                                threeYearAndAboveForm.get('sleeping_mother_time')?.invalid
                                &&
                                threeYearAndAboveForm.get('sleeping_mother_time').touched"></p-calendar>
                                        <div class="invalid-feedback">time is required</div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="row" formArrayName="sleeping_time">
                                <ng-container
                                    *ngFor="let control of getSleepArray().controls; index as i; last as last">
                                    <div class="row" [formGroupName]="i">
                                        <div class="col-lg-4 col-sm-6 col-12 d-flex align-items-center">

                                            <div class="form-check pl-0 checkbox-list px-0">
                                                <label class="checkbox checkbox-outline px-0">
                                                    <!-- <input type="checkbox" value="With_Mother"
                                                        formControlName="sleeping_mother"
                                                        (change)="handleInput($event, 'sleeping-with-mother')" /> -->
                                                    <span></span>
                                                    With Mother
                                                </label>
                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Time</label>
                                                <div class="card flex justify-content-center">
                                                    <p-calendar placeholder="Time"
                                                        formControlName="sleeping_mother_time" [timeOnly]="true"
                                                        [hourFormat]="12" inputId="time"
                                                        [class.is-invalid]="(threeYearForm.submitted &&
                                                        threeYearAndAboveForm.get('sleeping_mother_time')?.invalid)
                                                        ||
                                                        threeYearAndAboveForm.get('sleeping_mother_time')?.invalid
                                                        &&
                                                        threeYearAndAboveForm.get('sleeping_mother_time').touched"></p-calendar>

                                                    <div class="invalid-feedback">Time is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto d-flex mb-3 align-items-end">
                                            <p-button type="button" icon="bx bx-plus"
                                                (click)="addsleeptimeObject('add', i)" iconPos="right"
                                                class=" secondary-btn mr-2"></p-button>
                                            <div class="remove">
                                                <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                    icon="bx bx-minus" (click)="addsleeptimeObject('delete', i)"
                                                    iconPos="right">
                                                </p-button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>

                        <!-- With Nanny -->
                        <div class="row">
                            <!-- <div class="col-lg-4 col-sm-6 col-12 d-flex align-items-center">
                                <div class="form-group m-0">
                                    <div class="form-check p-0 checkbox-list">
                                        <label class="checkbox checkbox-outline p-0 m-0">
                                            <input type="checkbox" value="sleep-with-nanny" name="sleeping_nanny"
                                                (change)="handleInput($event, 'sleeping-with-nanny')"
                                                formControlName="sleeping_nanny" />
                                            <span></span>
                                            With Nurse
                                        </label>
                                    </div>
                                </div>
                            </div> -->

                            <!-- <div class="col-lg-4 col-sm-6 col-12">
                                <div class="form-group">
                                    <label class="p-0">Time:</label>
                                    <div class="card flex justify-content-center">
                                        <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12"
                                            inputId="time" formControlName="sleeping_nanny_time" [class.is-invalid]="(threeYearForm.submitted &&
                                threeYearAndAboveForm.get('sleeping_nanny_time')?.invalid)
                                ||
                                threeYearAndAboveForm.get('sleeping_nanny_time')?.invalid
                                &&
                                threeYearAndAboveForm.get('sleeping_nanny_time').touched"></p-calendar>
                                        <div class="invalid-feedback">time is required</div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="row" formArrayName="nanny_time">
                                <ng-container
                                    *ngFor="let control of getSleepnannyArray().controls; index as i; last as last">
                                    <div class="row" [formGroupName]="i">
                                        <div class="col-lg-4 col-sm-6 col-12 d-flex align-items-center">
                                            <div class="form-check pl-0 checkbox-list px-0">
                                                <label class="checkbox checkbox-outline px-0">
                                                    <!-- <input type="checkbox" value="With_Nanny"
                                                        formControlName="sleeping_nanny"
                                                        (change)="handleInput($event, 'sleeping-with-nanny')" /> -->
                                                    <span></span>
                                                    With Nurse
                                                </label>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Time</label>
                                                <div class="card flex justify-content-center">
                                                    <p-calendar placeholder="Time" [timeOnly]="true" [hourFormat]="12"
                                                        formControlName="sleeping_nanny_time" inputId="time"
                                                        [class.is-invalid]="(threeYearForm.submitted &&
                                                        threeYearAndAboveForm.get('sleeping_nanny_time')?.invalid)
                                                        ||
                                                        threeYearAndAboveForm.get('sleeping_nanny_time')?.invalid
                                                        &&
                                                        threeYearAndAboveForm.get('sleeping_nanny_time').touched"></p-calendar>

                                                    <div class="invalid-feedback">Time is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto d-flex mb-3 align-items-center">
                                            <p-button type="button" icon="bx bx-plus" (click)="addnannyObject('add', i)"
                                                iconPos="right" class=" secondary-btn mr-2"></p-button>
                                            <div class="remove">
                                                <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                    icon="bx bx-minus" (click)="addnannyObject('delete', i)"
                                                    iconPos="right">
                                                </p-button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </p-accordionTab>

                <!-- <div class="add-listings-box"> -->

                <!-- Temerature monitoring -->
                <p-accordionTab header="Vitamins">
                    <div class="add-listings-box">
                        <!-- <label class="subHeading py-3">Temperature Monitoring(In hours):</label>
                        <div class="form-group row m-0">
                            <div class="col-lg-4 p-0">
                                <div class="form-group ml-0 col-12 p-0">
                                    <div class="card flex justify-content-center">
                                        <p-dropdown id="temp_frequency" [options]="frequencyList" [filter]="true" filterBy="value"
                                            formControlName="temperature_monitoring_frequency" optionLabel="name"
                                            optionValue="value" placeholder="Select Frequency">
                                        </p-dropdown>
                                    </div>
                                </div>
                            </div>

                        </div> -->

                        <!-- Vitamin and medication -->
                        <label class="subHeading py-3 px-0">Vitamins/Medications:</label>
                        <div formArrayName="vitamins_medications_json">
                            <ng-container
                                *ngFor="let control of getVitaminFormArray().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">
                                    <div class="col-md">
                                        <div class="form-group">
                                            <label class="px-0">Medications</label>
                                            <div class="pl-0 checkbox-list">
                                                <input type="text" formControlName="vitamin" class="form-control"
                                                    placeholder="Vitamins/ Medications" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md">
                                        <div class="form-group">
                                            <label class="px-0">Frequency</label>
                                            <div class="card flex justify-content-center">
                                                <p-dropdown id="vitamin_frequency" [options]="frequencyList"
                                                    [filter]="true" filterBy="value" formControlName="frequency"
                                                    optionLabel="name" optionValue="value"
                                                    (onChange)="addmedicinefield(i)" placeholder="Select Frequency">
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-auto d-flex mb-sm-3 mb-0 align-items-end">
                                        <p-button type="button" icon="bx bx-plus" (click)="addVitaminObject('add', i)"
                                            iconPos="right" class="secondary-btn mr-2"></p-button>
                                        <div class="remove">
                                            <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                icon="bx bx-minus" (click)="addVitaminObject('delete', i)"
                                                iconPos="right"></p-button>
                                        </div>
                                    </div>
                                    <div *ngIf="ismedicine" class="add-listings-box pt-3 mb-3 col-lg-12">
                                        <div class="row" formArrayName="medicine_time">
                                            <div *ngFor="let control of getmedicinearray(i).controls; index as j"
                                                [formGroupName]="j" class="col-lg-4">
                                                <!-- <div class="" > -->
                                                <!-- <div class="row"> -->
                                                <!-- <div class="col-md-3">
                                                            <label
                                                                class="checkbox checkbox-outline col-lg-5 col-sm-6 col-12 mt-sm-4 mt-3 px-0">
                                                                <input type="checkbox" value="withvitamin"
                                                                    name="checkboxes"
                                                                    formControlName="vitamin_checkbox" />
                                                                Time
                                                            </label>
                                                        </div> -->
                                                <!-- <div class="col-lg-auto d-flex align-items-center"> -->
                                                <div class="form-group">
                                                    <label class="px-0">Time</label>
                                                    <p-calendar placeholder="Time" formControlName="time"
                                                        [timeOnly]="true" [hourFormat]="12" inputId="time"></p-calendar>
                                                </div>
                                                <!-- </div> -->
                                                <!-- <div class="col-md col-sm-6 col-12">
                                                            <div class="form-group">
                                                                <label class="px-0">Remark</label>
                                                                <input type="text" name="name" class="form-control"
                                                                    placeholder="Remark"
                                                                    [formControl]="control.get('vitamin_remark')" />
                                                            </div>
                                                        </div> -->
                                                <!-- </div> -->
                                                <!-- </div> -->
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </ng-container>
                        </div>

                        <!-- Vaccinatons -->
                        <!-- <label class="subHeading py-3 px-0">Vaccinations:</label>
                        <div formArrayName="vaccinations_json">
                            <ng-container
                                *ngFor="let control of getVaccinationFormArray().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">
                                    <div class="col-lg-4 col-sm-6 col-12">
                                        <div class="form-group">
                                            <label class="px-0">Vaccinations</label>
                                            <div class="pl-0 checkbox-list">
                                                <input type="text" formControlName="vaccination" class="form-control"
                                                    placeholder="Vaccinations" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-6 col-12">
                                        <div class="form-group">
                                            <label class="px-0">Date of Administration</label>
                                            <div class="card flex justify-content-center">
                                                <p-calendar [showIcon]="true" [minDate]="currentDate"
                                                    placeholder="DD/MM/YYY" dateFormat="dd-mm-yy"
                                                    formControlName="date"></p-calendar>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12 d-flex justify-content-end align-items-center">
                                        <div class="form-group mb-0">
                                            <div class="d-flex justify-content-end align-items-center">
                                                <p-button type="button" icon="bx bx-plus"
                                                    (click)="addVaccinationObject('add', i)" iconPos="right"
                                                    class="mr-2"></p-button>
                                                <div class="remove">
                                                    <p-button type="button" *ngIf="i!= 0" class="ml-2"
                                                        icon="bx bx-minus" (click)="addVaccinationObject('delete', i)"
                                                        iconPos="right"></p-button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div> -->
                    </div>
                </p-accordionTab>
                <!-- potty training -->
                <p-accordionTab header="Potty Training">
                    <div class="add-listings-box">
                        <div formArrayName="child_training">
                            <label class="subHeading px-0">Potty Training:</label>
                            <ng-container
                                *ngFor="let control of getTrainingFormArray().controls; index as i; last as last">
                                <div class="" [formGroupName]="i">
                                    <div class="row">
                                        <div class="col-md-4 col-lg-3">
                                            <div class="radio-inline">
                                                <div class="d-flex">
                                                    <p class="me-2">
                                                        <input type="radio" id="training-yes_{{i}}"
                                                            formControlName="potty_training" value="1"
                                                            (change)="handleInput($event, 'potty_training')">
                                                        <label for="training-yes_{{i}}"
                                                            class="mb-0 mx-sm-2 mx-0">Yes</label>
                                                    </p>
                                                    <p class="d-flex">
                                                        <input type="radio" id="training-no_{{i}}"
                                                            formControlName="potty_training" value="0"
                                                            (change)="handleInput($event, 'potty_training')">
                                                        <label for="training-no_{{i}}"
                                                            class="mb-0 mx-sm-2 mx-0">No</label>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-8">
                                            <div class="form-group">
                                                <!-- <label class="p-0">Training Name:</label> -->
                                                <textarea name="training_child" class="form-control" id="kt_autosize_1"
                                                    [readonly]="potty_training" rows="2"
                                                    formControlName="training_child"
                                                    placeholder="Training Name"></textarea>
                                            </div>
                                        </div>
                                        <!-- <div class="row"> -->
                                        <!-- <div class="col-lg-2 col-md-2 mt-3">
                                                <label class="checkbox checkbox-outline px-0  mt-md-2">
                                                    <input type="checkbox" value="true" name="checkboxes"
                                                        formControlName="done_training_child" />
                                                    <span></span>
                                                    Done?
                                                </label>
                                            </div> -->
                                        <div class="col-lg-5 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Time</label>
                                                <p-calendar placeholder="Time" formControlName="time_training_child"
                                                    [disabled]="potty_training" [timeOnly]="true" inputId="time"
                                                    [hourFormat]="12"></p-calendar>
                                            </div>
                                        </div>

                                        <!-- <div class="col-lg-4 col-sm-6 col-12">
                                                <div class="form-group">
                                                    <label class="px-0">Remark</label>
                                                    <input type="text" name="name" class="form-control"
                                                        placeholder="Remark"
                                                        [formControl]="control.get('training_child_remark')" />
                                                </div>
                                            </div> -->
                                        <!-- </div> -->
                                        <div class="col-auto d-flex mb-3 align-items-end">
                                            <p-button type="button" icon="bx bx-plus"
                                                (click)="addTrainingObject('add', i)" iconPos="right"
                                                class="secondary-btn mr-2"></p-button>
                                            <div class="remove">
                                                <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                    icon="bx bx-minus" (click)="addTrainingObject('delete', i)"
                                                    iconPos="right"></p-button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </p-accordionTab>
                <!-- Playing with child outside -->
                <p-accordionTab header="Activities">
                    <div class="add-listings-box">
                        <div formArrayName="outside_child">
                            <label class="subHeading p-0 mb-2">Playing with child outside?:</label>
                            <ng-container *ngFor="let control of getPlayFormArray().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">
                                    <div class="col-lg-4 col-sm-6 col-12">
                                        <div class="form-group">
                                            <div class="d-flex">
                                                <p class="me-3">
                                                    <input type="radio" id="play-yes_{{i}}"
                                                        (change)="handleInput($event, 'training_play')"
                                                        formControlName="play_child_outside" value="1">
                                                    <label for="play-yes_{{i}}" class="mb-0 mx-sm-2 mx-0">Yes</label>
                                                </p>
                                                <p class="d-flex">
                                                    <input type="radio" id="play-no_{{i}}"
                                                        (change)="handleInput($event, 'training_play')"
                                                        formControlName="play_child_outside" value="0">
                                                    <label for="play-no_{{i}}" class="mx-sm-2 mx-0">No</label>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-8">
                                        <div class="form-group">
                                            <!-- <label class="p-0">Special Instructions:</label> -->
                                            <textarea class="form-control" id="kt_autosize_1" rows="2"
                                                [readonly]="playWith"
                                                formControlName="play_child_outside_special_Instructions"
                                                placeholder="Special Instructions"></textarea>
                                        </div>
                                    </div>
                                    <!-- <div class="row"> -->
                                    <!-- <div class="col-lg-3 col-sm-6 col-12">
                                            <label class="checkbox checkbox-outline px-0  mt-md-2">
                                                <input type="checkbox" value="true" name="checkboxes"
                                                    formControlName="play_child_outside_done" />
                                                <span></span>
                                                Done?
                                            </label>
                                        </div> -->
                                    <div class="col-lg-3 col-sm-6 col-12">
                                        <div class="form-group">
                                            <label class="px-0">Time</label>
                                            <p-calendar placeholder="Time" [disabled]="playWith"
                                                formControlName="play_child_outside_time" [timeOnly]="true"
                                                inputId="time" [hourFormat]="12"></p-calendar>
                                        </div>
                                    </div>
                                    <!-- <div class="col-lg-3 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Remark</label>
                                                <input type="text" name="name" class="form-control" placeholder="Remark"
                                                    [formControl]="control.get('play_child_outside_remark')" />
                                            </div>
                                        </div> -->
                                    <div class="col-lg-auto d-flex align-items-center">
                                        <p-button type="button" icon="bx bx-plus" (click)="addPlayObject('add', i)"
                                            iconPos="right" class="secondary-btn mr-2"></p-button>
                                        <div class="remove">
                                            <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                icon="bx bx-minus" (click)="addPlayObject('delete', i)"
                                                iconPos="right"></p-button>
                                        </div>
                                    </div>
                                    <!-- </div> -->
                                </div>
                            </ng-container>
                        </div>


                        <!-- Escort child outside -->
                        <div formArrayName="escort_child">
                            <label class="subHeading p-0 mb-2">Escort child outside?</label>
                            <ng-container
                                *ngFor="let control of getescortFormArray().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">
                                    <div class="col-lg-4 col-sm-6 col-12">
                                        <div class="form-group">
                                            <div class="d-flex">
                                                <p class="me-3">
                                                    <input type="radio" id="escort-yes_{{i}}"
                                                        (change)="handleInput($event, 'escort')"
                                                        formControlName="escort_child_outside" value="1">
                                                    <label for="escort-yes_{{i}}" class="mb-0 mx-sm-2 mx-0">Yes</label>
                                                </p>
                                                <p class="d-flex">
                                                    <input type="radio" id="escort-no_{{i}}"
                                                        (change)="handleInput($event, 'escort')"
                                                        formControlName="escort_child_outside" value="0">
                                                    <label for="escort-no_{{i}}" class=" mx-sm-2 mx-0">No</label>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-8">
                                        <div class="form-group">
                                            <!-- <label class="p-0">Special Instructions:</label> -->
                                            <textarea name="escort_child_outside_special_Instructions"
                                                class="form-control" id="kt_autosize_1" rows="2" [readonly]="escort"
                                                formControlName="escort_child_outside_special_Instructions"
                                                placeholder="Special Instructions"></textarea>
                                        </div>
                                    </div>
                                    <!-- <div class="row"> -->
                                    <!-- <div class="col-lg-3 col-sm-6 col-12">
                                            <label class="checkbox checkbox-outline px-0  mt-md-2">
                                                <input type="checkbox" value="true" name="checkboxes"
                                                    formControlName="escort_child_outside_done" />
                                                <span></span>
                                                Done?
                                            </label>
                                        </div> -->
                                    <div class="col-lg-3 col-sm-6 col-12">
                                        <div class="form-group">
                                            <label class="px-0">Time</label>
                                            <p-calendar placeholder="Time" formControlName="escort_child_outside_time"
                                                [disabled]="escort" [timeOnly]="true" inputId="time"
                                                [hourFormat]="12"></p-calendar>
                                        </div>
                                    </div>
                                    <!-- <div class="col-lg-3 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Remark</label>
                                                <input type="text" name="name" class="form-control" placeholder="Remark"
                                                    [formControl]="control.get('escort_child_outside_remark')" />
                                            </div>
                                        </div> -->


                                    <div class="col-lg-auto d-flex align-items-center">
                                        <p-button type="button" icon="bx bx-plus" (click)="addEscortObject('add', i)"
                                            iconPos="right" class=" secondary-btn mr-2"></p-button>
                                        <div class="remove">
                                            <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                icon="bx bx-minus" (click)="addEscortObject('delete', i)"
                                                iconPos="right"></p-button>
                                        </div>
                                    </div>
                                    <!-- </div> -->
                                </div>
                            </ng-container>
                        </div>

                        <div formArrayName="activities_name">
                            <label class="subHeading py-3">Enter activities name here</label>
                            <ng-container
                                *ngFor="let control of getActiveFormArray().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">
                                    <div class="col-lg-4 col-sm-6 col-12 d-flex align-items-center">
                                        <div class="form-group">
                                            <div class="d-flex">
                                                <p class="me-3">
                                                    <input type="radio" id="active-yes{{i}}"
                                                        (change)="handleInput($event, 'activities')"
                                                        formControlName="other_activity" value="1">
                                                    <label for="active-yes{{i}}" class="mb-0 mx-sm-2 mx-0">Yes</label>
                                                </p>
                                                <p class="d-flex">
                                                    <input type="radio" id="active-no{{i}}"
                                                        (change)="handleInput($event, 'activities')"
                                                        formControlName="other_activity" value="0">
                                                    <label for="active-no{{i}}" class="mb-0 mx-sm-2 mx-0">No</label>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-8">
                                        <div class="form-group">
                                            <!-- <label class="p-0">Activity Name:</label> -->
                                            <textarea name="activities_child_outside_special_Instructions"
                                                class="form-control" id="kt_autosize_1" rows="2"
                                                [readonly]="activities_child"
                                                formControlName="activities_child_outside_special_Instructions"
                                                placeholder="Activity Name"></textarea>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <!-- <div class="col-lg-3 col-sm-6 col-12">
                                            <label class="checkbox checkbox-outline px-0  mt-md-2">
                                                <input type="checkbox" value="true" name="checkboxes"
                                                    formControlName="other_child_outside_done" />
                                                <span></span>
                                                Done?
                                            </label>
                                        </div> -->
                                        <div class="col-lg-3 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Time</label>
                                                <p-calendar placeholder="Time"
                                                    formControlName="other_child_outside_time" [timeOnly]="true"
                                                    [disabled]="activities_child" inputId="time"
                                                    [hourFormat]="12"></p-calendar>
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-3 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Remark</label>
                                                <input type="text" name="name" class="form-control" placeholder="Remark"
                                                    [formControl]="control.get('other_child_outside_remark')" />
                                            </div>
                                        </div> -->
                                        <div class="col-lg-auto d-flex align-items-center">
                                            <p-button type="button" icon="bx bx-plus"
                                                (click)="addActiveObject('add', i)" iconPos="right"
                                                class="secondary-btn mr-2"></p-button>
                                            <div class="remove">
                                                <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                    icon="bx bx-minus" (click)="addActiveObject('delete', i)"
                                                    iconPos="right"></p-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                </p-accordionTab>
                <!-- Escort child to school -->
                <p-accordionTab header="Schooling">
                    <div class="add-listings-box">

                        <div formArrayName="school_name">
                            <label class="subHeading p-0 mb-2">Escort child to school?</label>
                            <ng-container
                                *ngFor="let control of getSchoolFormArray().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">
                                    <div class="col-lg-4 col-sm-6 col-12 ">
                                        <div class="form-group">
                                            <div class="d-flex">
                                                <p class="me-3">
                                                    <input type="radio" id="escortSchool-yes_{{i}}"
                                                        (change)="handleInput($event, 'escortSchool')"
                                                        formControlName="escort_child_school" value="1">
                                                    <label for="escortSchool-yes_{{i}}"
                                                        class="mb-0 mx-sm-2 mx-0">Yes</label>
                                                </p>
                                                <p class="d-flex">
                                                    <input type="radio" id="escortSchool-no_{{i}}"
                                                        (change)="handleInput($event, 'escortSchool')"
                                                        formControlName="escort_child_school" value="0">
                                                    <label for="escortSchool-no_{{i}}" class=" mx-sm-2 mx-0">No</label>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col">
                                        <div class="form-group">
                                            <!-- <label class="p-0">Special Instructions:</label> -->
                                            <textarea name="escort_child_school_special_Instructions"
                                                class="form-control" id="kt_autosize_1" rows="2" [readonly]="activities"
                                                formControlName="escort_child_school_special_instructions"
                                                placeholder="Special Instructions"></textarea>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <!-- <div class="col-lg-3 col-sm-6 col-12">
                                            <label class="checkbox checkbox-outline px-0  mt-md-2">
                                                <input type="checkbox" value="true" name="checkboxes"
                                                    formControlName="school_child_outside_done" />
                                                <span></span>
                                                Done?
                                            </label>
                                        </div> -->
                                        <div class="col-lg-3 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Time</label>
                                                <p-calendar placeholder="Time"
                                                    formControlName="school_child_outside_time" [timeOnly]="true"
                                                    [disabled]="activities" inputId="time"
                                                    [hourFormat]="12"></p-calendar>
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-3 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Remark</label>
                                                <input type="text" name="name" class="form-control" placeholder="Remark"
                                                    [formControl]="control.get('school_child_outside_remark')" />
                                            </div>
                                        </div> -->
                                        <div class="col-lg-auto d-flex align-items-center">
                                            <p-button type="button" icon="bx bx-plus"
                                                (click)="addSchoolObject('add', i)" iconPos="right"
                                                class="secondary-btn mr-2"></p-button>
                                            <div class="remove">
                                                <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                    icon="bx bx-minus" (click)="addSchoolObject('delete', i)"
                                                    iconPos="right"></p-button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </ng-container>
                        </div>
                    </div>
                </p-accordionTab>
                <!-- other task -->
                <p-accordionTab header="Remarks">
                    <div class="add-listings-box">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-12">
                                    <label class="subHeading py-3 px-0">Remarks:</label>
                                    <textarea name="remarks" class="form-control" id="kt_autosize_1" rows="7"
                                        formControlName="remarks"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                </p-accordionTab>

            </p-accordion>
        </ng-container>
        <div class="add-listings-btn" style="text-align: right;">
            <button type="submit" (click)="container.scrollTop = 0" [disabled]="!flag"
                *ngIf="checkPermission(9)">Submit</button>
        </div>
    </form>
    <app-copyrights></app-copyrights>
</div>