<p>feeding-assessment-list works!</p>
<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->
<!-- import { DialogModule } from 'primeng/dialog'; -->

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

  <app-dashboard-navbar></app-dashboard-navbar>

  <!-- Breadcrumb Area -->
  <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
    <h1>{{Content.title}}</h1>
    <ol class="breadcrumb">
      <li class="item"><a routerLink="/">Home</a></li>
      <li class="item">{{Content.subTitle}}</li>
      <li class="item">{{Content.title}}</li>
    </ol>
  </div>

  <!-- <div class="add-listings-box">
        <div class="row justify-content-between align-items-center">
            <div class="form-group col-4">
                <label class="p-0">Name <span class="required">*</span></label>
                <input type="text" name="name" class="form-control" placeholder="Name" (change)="getInput($event)" />
            </div>
            <div class="add-listings-btn mb-0 col-4 text-end">
                <button type="submit" (click)="addTableData()">Add</button>
            </div>
        </div>
    </div> -->
  <div class="add-listings-box">
    <div class="Master-view-box">
      <div class="theader d-flex justify-content-between">
        <div class="d-flex w-100">
          <span class="p-input-icon-left ml-auto mr-2 w-100%">
            <i class="pi pi-search"></i>
            <input pInputText type="text" class="form-control" style="text-align: left; padding-left: 20%; height: 45px !important"  scriptInjection placeholder="Search Keyword"
              (input)="dt1.filterGlobal($event.target.value, 'contains')"  [(ngModel)]="filterVal" />
          </span>
          <p-button type="button" class="mx-2" icon="bx bx-reset" (click)="reset(dt1)"
            tooltipPosition="bottom"></p-button>
        </div>
        <div class="add-listings-btn" style="text-align: right;">
          <a routerLink="/portal/one-year-to-three-year-growth-monitoring" class="nav-link">
            <button type="submit">Add</button>

          </a>
        </div>
        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="onPDFExport()"
        class="p-button-warning ms-2" pTooltip="PDF" tooltipPosition="bottom"></button>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-lg-12 col-md-12">
        <p-table #dt1 [value]="allData" dataKey="name" 
          [globalFilterFields]="['name', 'date_of_birth', 'age']" [rowsPerPageOptions]="[10, 25, 50]"[rows]="10" [showCurrentPageReport]="true"
          responsiveLayout="scroll" [paginator]="true" currentPageReportTemplate="Showing {first} to
					{last} of {totalRecords} entries">
          <ng-template pTemplate="header">
            <tr>
              <th></th>
              <th style="min-width: 62px">S. No.</th>
              <th>Name</th>
              <th>Date Of Birth</th>
              <th>Days</th>
              <th>Months</th>
              <th>Years</th>
            </tr>

          </ng-template>
          <ng-template pTemplate="body" let-product let-expanded="expanded" let-i="rowIndex">
            <tr>
              <td>
                <button type="button" pButton pRipple [pRowToggler]="product"
                  class="p-button-text p-button-rounded p-button-plain"
                  [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                  (click)="getData(product.baby_id)"></button>
              </td>
              <td>{{i+1}}</td>
              <td>{{product.name | titlecase }}</td>
              <td>{{product.date_of_birth}}</td>
              <td>{{product.days}}</td>
              <td>{{product.months}}</td>
              <td>{{product.years}}</td>

            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-product>
            <tr>
              <td colspan="7" class="inner-data">
                <div class="p-3 py-0">
                  <p-table [value]="product.development_milestones
                                    " dataKey="id">
                    <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="id" class="serial-width">Sr No.</th>
              <th pSortableColumn="customer">Action</th>
              <th pSortableColumn="date">Date</th>

            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-order let-expanded="expanded" let-i="rowIndex">
            <tr>
              <td>{{i+1}}</td>
              <td><button pButton pRipple class="p-button-rounded p-button-text p-button-info" pTooltip="View"
                  tooltipPosition="bottom" (click)="showMaximizableDialog(order.id)"><i class="far fa-eye"></i></button>
                <button pButton pRipple class="p-button-rounded p-button-icon" pTooltip="Edit"
                  tooltipPosition="bottom" (click)="editOnCLick(order.id)"><i class="bx bx-pencil"></i>
                </button>
              </td>
              <td>
                {{ order?.created_at?(order?.created_at | date: 'dd-MM-yyyy' ): "-" }}
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="6">There are no order for this product yet.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      </td>
      </tr>
      </ng-template>
      </p-table>

    </div>

    <!----------------------------------------------------------- view dialog --------------------------------------------------------->
    <!-- ***************************************************************************************************************** -->
    <p-dialog header="Baby Details" [(visible)]="displayMaximizable" [modal]="true" [style]="{ width: '90vw' }"
      [draggable]="false" [resizable]="false">
      <!-- Start -->
      <ng-container>
        <div class="row">
          <div class="col-lg-12">
            <div class="my-profile-box">
              <form>
                <!-- Baby detail -->
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Name :</label>
                      <input type="text" readonly class="form-control" value="{{babyData?.name?? '-' | titlecase }}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12
                                                col-md-12">
                    <div class="form-group">
                      <label>Gender :</label>
                      <input type="text" readonly class="form-control" value="{{babyData?.gender ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Date of Birth :</label>
                      <input type="text" readonly class="form-control" value="{{babyData?.date_of_birth ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Days :</label>
                      <input type="text" readonly class="form-control" value="{{babyData?.days ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Months :</label>
                      <input type="text" readonly class="form-control" value="{{babyData?.months ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Years :</label>
                      <input type="text" readonly class="form-control" value="{{babyData?.years ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <h3>Height Weight</h3>
                <div class="row" *ngFor="let data of health">
                  <div class="col-xl-4 col-lg-12
                    col-md-12">
                    <div class="form-group">

                      <label>Time</label>
                      <input type="text" readonly class="form-control"
                          value="{{ data?.time | date:'dd-MM-yyyy'??'-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12
                    col-md-12">
                    <div class="form-group">


                      <label>Height</label>
                      <input type="text" readonly class="form-control" value="{{ data?.height ??'-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12
                    col-md-12">
                    <div class="form-group">
                      <label>Weight</label>
                      <input type="text" readonly class="form-control" value="{{ data?.weight ??'-'}}" />
                    </div>
                  </div>
                </div>
                <!-- motor and coordination -->
                <h3>Motor and Coordination</h3>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Walks independently and seldom falls</label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.walk ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.walk_description?? '-'}}" />
                    </div>
                  </div>
                </div>



                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Squats to pick up the toy</label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.squats_toy ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.squats_toy_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Starts to jump with both feet leaving the ground</label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.jump ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.jump_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">When walking, able to pull toys behind them</label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.pull_toys ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.pull_toys_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Runs</label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.runs ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.runs_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Stands on tip toes</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.stand_on_toes ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.stand_on_toes_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Stands on low furniture</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.stands_on_furniture ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.stands_on_furniture_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Kicks large balls</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.kicks_balls ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.kicks_balls_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Goes up and down stairs with support</label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.stairs ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.stairs_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Consistently uses 2-3 word phrases</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.word_phrases ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.word_phrases_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Follows 2-step unrelated directions, e.g. “Give me the ball and go
                        get your
                        coat”</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.unrelated_directions ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.unrelated_directions_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Simple understanding of concepts including colour, space, and time
                      </label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.understanding ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.understanding_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Frequently moving in and out of various positions (e.g. crawling,
                        climbing, cruising, and walking) to explore and get desirable
                        objects</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.frequent_move ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.frequent_move_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Has adequate endurance and strength to play with peers</label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.strength ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.strength_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Walks with heel toe pattern and not primarily on toes</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.walk_with_heel ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.walk_with_heel_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Leans on furniture, walls, or people and sits slumped over when
                        tired</label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.leans ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.leans_description ?? '-'}}" />
                    </div>
                  </div>
                </div>


                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Coordinates both hands to play, such as swinging a bat or opening a
                        container</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.coordinates ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.coordinates_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!-- motor and coordination end -->

                <!--  SENSORY SECTION -->
                <h3> SENSORY SECTION</h3>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Helps with getting dressed/undressed</label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.dressed ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.dressed_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Has a regular sleep schedule</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.regular_sleep ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.regular_sleep_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Eats an increasing variety of foods</label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.foods ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.foods_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Flips switches on and off</label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.flips ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.flips_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Uses crayons, pens, or markers to make marks on paper</label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.crayons ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.crayons_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Enjoys a variety of movement</label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.sorts ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.sorts_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Stacks 5 or more small blocks or toys on top of each other</label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.stacks ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.stacks_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Takes toys apart and puts them back together</label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.takes_toys ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.takes_toys_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <!--  SENSORY SECTION END-->

                <!--  COMMUNICATION SECTION -->
                <h3> COMMUNICATION SECTION</h3>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Combines sounds and gestures</label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.combines ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.combines_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Imitates simple words and actions</label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.imitates ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.imitates_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Follows simple directions</label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.follows ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.follows_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Identifies 1-2 body parts when named</label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.identifies ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.identifies_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Produces speech-like babbling</label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.babbling ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.babbling_description ?? '-'}}" />
                    </div>
                  </div>
                </div>


                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Points at familiar objects and people in pictures</label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.familiar ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.familiar_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Understands basic nouns and pronouns</label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.basic ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.basic_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Understands “mine” and “yours”</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.understands ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.understands_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Asks “what” and “where” and “why” questions</label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.questions ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.questions_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Uses plurals, e.g. “dogs” </label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.plurals ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.plurals_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Understands “in” and “on” </label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.in_on ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.in_on_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Responds to yes/no questions with head shake/nod
                      </label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.head_shake ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.head_shake_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Uses at least 50 words
                      </label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.use_words ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.use_words_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Names objects and pictures
                      </label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.names ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.names_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Understands action words
                      </label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.action ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.action_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Uses gestures and words in pretend play
                      </label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.gestures ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.gestures_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Follows 2-step related direction, e.g. “Pick up your coat and bring
                        it to me”
                      </label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.direction ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.direction_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Enjoys listening to stories
                      </label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.stories ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.stories_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Consistently uses 2-3 word phrases
                      </label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.phrases ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.phrases_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Simple understanding of concepts including colour, space, and time
                      </label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.concepts ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.concepts_description ?? '-'}}" />
                    </div>
                  </div>
                </div>



                <!-- FEEDING -->
                <h3>FEEDING</h3>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Increases variety of coarsely chopped table foods </label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.table_foods ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.table_foods_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Holds and drink from a cup </label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.drink ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.drink_description ?? '-'}}" />
                    </div>
                  </div>
                </div>
                <!-- feeding end -->

                <!-- PLAY AND SOCIAL SKILLS SECTION -->
                <!-- Enjoys playful interactions with others, e.g. peek a boo  -->
                <h3>Play and Social Skills</h3>
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Interested in interacting with people (plays patty cake)</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.interacting ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.interacting_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Raises hands to be picked up </label>
                      <input type="text" readonly class="form-control" readonly value="{{detail?.picked_up ?? '-'}}" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" readonly
                        value="{{detail?.picked_up_description ?? '-'}}" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Maintains eye contact with people during playful
                        interactions</label>
                      <input type="text" readonly class="form-control" value="{{detail?.eye_contacts ?? '-'}}"
                        readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                        value="{{detail?.eye_contacts_description ?? '-'}}" readonly />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Interested in imitating others </label>
                      <input type="text" readonly class="form-control" value="{{detail?.imitating_others ?? '-'}}"
                        readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                        value="{{detail?.imitating_others_description ?? '-'}}" readonly />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Turns head in response to name being called </label>
                      <input type="text" readonly class="form-control" value="{{detail?.turns_head ?? '-'}}" readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" value="{{detail?.turns_head_description ?? '-'}}"
                        readonly />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Points to objects of interest by 1 year old </label>
                      <input type="text" readonly class="form-control" value="{{detail?.points_objects ?? '-'}}"
                        readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                        value="{{detail?.points_objects_description ?? '-'}}" readonly />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Able to locate objects you are pointing to </label>
                      <input type="text" readonly class="form-control" value="{{detail?.locate_objects ?? '-'}}"
                        readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                        value="{{detail?.locate_objects_description ?? '-'}}" readonly />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Claps and together in response to social play</label>
                      <input type="text" readonly class="form-control" value="{{detail?.claps ?? '-'}}" readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" value="{{detail?.claps_description ?? '-'}}"
                        readonly />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Enjoys playing with a variety of toys and textures </label>
                      <input type="text" readonly class="form-control" value="{{detail?.enjoy_playing ?? '-'}}"
                        readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                        value="{{detail?.enjoy_playing_description ?? '-'}}" readonly />
                    </div>
                  </div>
                </div>


                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Eagerly explores the environment when placed on the floor </label>
                      <input type="text" readonly class="form-control" value="{{detail?.explores ?? '-'}}" readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" value="{{detail?.explores_description ?? '-'}}"
                        readonly />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Enjoys exploring and playing at the playground </label>
                      <input type="text" readonly class="form-control" value="{{detail?.playground ?? '-'}}" readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" value="{{detail?.playground_description ?? '-'}}"
                        readonly />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Enjoys swinging on playground swings </label>
                      <input type="text" readonly class="form-control" value="{{detail?.swinging ?? '-'}}" readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" value="{{detail?.swinging_description ?? '-'}}"
                        readonly />
                    </div>
                  </div>
                </div>


                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Able to participate in small groups with other children </label>
                      <input type="text" readonly class="form-control" value="{{detail?.participate ?? '-'}}"
                        readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                        value="{{detail?.participate_description ?? '-'}}" readonly />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Plays with toys without mouthing them </label>
                      <input type="text" readonly class="form-control" value="{{detail?.mouthing ?? '-'}}" readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" value="{{detail?.mouthing_description ?? '-'}}"
                        readonly />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Enjoys sitting to look at or listen to a book</label>
                      <input type="text" readonly class="form-control" value="{{detail?.listen_book ?? '-'}}"
                        readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                        value="{{detail?.listen_book_description ?? '-'}}" readonly />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Enjoys swinging, being thrown in air, and roughhousing </label>
                      <input type="text" readonly class="form-control" value="{{detail?.roughhousing ?? '-'}}"
                        readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                        value="{{detail?.roughhousing_description ?? '-'}}" readonly />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Able to participate in messy activities that result in dirty hands
                      </label>
                      <input type="text" readonly class="form-control" value="{{detail?.messy_activity ?? '-'}}"
                        readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control"
                        value="{{detail?.messy_activity_description ?? '-'}}" readonly />
                    </div>
                  </div>
                </div>


                <!-- PLAY AND SOCIAL SKILLS SECTION END-->



                <!-- SELF-EXPRESSION SECTION -->
                <h3>SELF-EXPRESSION SECTION</h3>


                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Comforted by cuddling and a parent's touch </label>
                      <input type="text" readonly class="form-control" value="{{detail?.cuddling ?? '-'}}" readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" value="{{detail?.cuddling_description ?? '-'}}"
                        readonly />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Able to calm with experiences such as rocking, touch, and soothing
                        sounds </label>
                      <input type="text" readonly class="form-control" value="{{detail?.calm ?? '-'}}" readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" value="{{detail?.calm_description ?? '-'}}"
                        readonly />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Grown accustomed to everyday sounds and is usually not startled by
                        them </label>
                      <input type="text" readonly class="form-control" value="{{detail?.accustomed ?? '-'}}" readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" value="{{detail?.accustomed_description ?? '-'}}"
                        readonly />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Has an established and reliable sleeping schedule</label>
                      <input type="text" readonly class="form-control" value="{{detail?.sleeping ?? '-'}}" readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" value="{{detail?.sleeping_description ?? '-'}}"
                        readonly />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Able to enjoy a wide variety of touch, noise, and smells </label>
                      <input type="text" readonly class="form-control" value="{{detail?.touch ?? '-'}}" readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" value="{{detail?.touch_description ?? '-'}}"
                        readonly />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height"> Cries and notices when hurt </label>
                      <input type="text" readonly class="form-control" value="{{detail?.cries ?? '-'}}" readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" value="{{detail?.cries_description ?? '-'}}"
                        readonly />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Able to self soothe when upset</label>
                      <input type="text" readonly class="form-control" value="{{detail?.soothe ?? '-'}}" readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" value="{{detail?.soothe_description ?? '-'}}"
                        readonly />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Able to transition to new environment or activity</label>
                      <input type="text" readonly class="form-control" value="{{detail?.transition ?? '-'}}" readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" value="{{detail?.transition_description ?? '-'}}"
                        readonly />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Happy when not hungry or tired</label>
                      <input type="text" readonly class="form-control" value="{{detail?.happy ?? '-'}}" readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" value="{{detail?.happy_description ?? '-'}}"
                        readonly />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Does not require an excessive routine to calm</label>
                      <input type="text" readonly class="form-control" value="{{detail?.excessive ?? '-'}}" readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" value="{{detail?.excessive_description ?? '-'}}"
                        readonly />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Able to be away from parents when with supportive and familiar
                        people</label>
                      <input type="text" readonly class="form-control" value="{{detail?.supportive ?? '-'}}" readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" value="{{detail?.supportive_description ?? '-'}}"
                        readonly />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Enjoys playing near peers</label>
                      <input type="text" readonly class="form-control" value="{{detail?.peers ?? '-'}}" readonly />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label class="label-height">Remark</label>
                      <input type="text" readonly class="form-control" value="{{detail?.peers_description ?? '-'}}"
                        readonly />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template pTemplate="footer">
        <p-button  (click)="displayMaximizable=
                            false" label="Ok" styleClass="p-button-text"></p-button>
      </ng-template>
    </p-dialog>
  </div>
</div>
<app-copyrights></app-copyrights>
