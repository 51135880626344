import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { MessageService, ConfirmationService } from 'primeng/api';
import { MasterService } from 'src/app/services/master.service';
import { TableService } from 'primeng/table';
import { Router } from '@angular/router';
@Component({
  selector: 'app-users-registration',
  templateUrl: './users-registration.component.html',
  styleUrls: ['./users-registration.component.scss'],
  providers: [MessageService, ConfirmationService, TableService],
})
export class UsersRegistrationComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Users Registration',
      subTitle: 'Dashboard'
    }
  ]
  filterval: any;
  userFormData: any = {}
  rolesData: any = []
  loading: any;
  // dt1: any;
  constructor(
    private masterService: MasterService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getAllData();
  }
  formSubmit(form: NgForm) {

    if (form.valid) {
      this.masterService
        .postUserFormData(form.value)
        .then((res: any) => {
          this.messageService.add({
            severity: 'success',
            detail: res?.message,
          });
          form.resetForm();
          this.userFormData = {};
          this.getAllData();
        })
        .catch((error: HttpErrorResponse) => {
        });
    }
  }
  //  **************************************
  //  edit roles api
  //  **************************************
  onRowEditSave(user: any) {
    this.loading = true;
    if (user.name != '' && user.name != null) {
      this.masterService
        .editUserFormData(user, user.id)
        .subscribe((data: any) => {
          this.messageService.add({
            severity: 'success',
            detail: data?.message,
          });
          this.loading = false;
          this.getAllData();
        });
    } else {
      this.messageService.add({
        severity: 'error',
        detail: 'Please Fill All the Details!',
      });
      this.loading = false;
    }
  }

  //  **************************************
  //  get roles api
  //  **************************************

  getAllData() {

    this.masterService.getRoles().then((res: any) => {
      this.rolesData = res.data;
    })
  }
  //  **************************************
  // reset function
  //  **************************************

  reset(dt1: any) {
    dt1.reset();
    this.filterval = '';
  }

  //  **************************************
  // on click route on permission for roles
  //  **************************************
  navigateToPermissionForRoles(id:any) {
      // Add your navigation logic here
      localStorage.setItem('user_role_id', id);
      this.router.navigate(["/portal/permission-for-roles"]);
  } 
}
