<p-toast></p-toast>
<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <div class="dropdown profile-nav-item d-flex flex-column align-items-end">
        <a class="dropdown-toggle admin-btn" style="text-decoration: none;" role="button" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <div class="menu-profile">
                <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
                <span class="name">{{getname}}</span>
            </div>
        </a>
        <div class="dropdown-menu d-flex flex-column align-items-center">
            <div class="dropdown-header align">
                <div class="figure mb-3">
                    <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
                </div>
                <div class="info text-center">
                    <span class="name" style="white-space: break-spaces;">{{getname}}</span>
                    <p class="mb-3 email">{{getRole}}</p>
                </div>
            </div>
            <!-- <div class="dropdown-body">
                <ul class="profile-nav p-0 pt-3">
                    <li class="nav-item"><a routerLink="/dashboard-my-profile" class="nav-link"><i class='bx bx-user'></i> <span>Profile Dashboard</span></a></li>
                </ul>
            </div> -->
            <div class="dropdown-footer">
                <ul class="profile-nav">
                    <li class="nav-item"><a class="nav-link" (click)="logout()"><i class='bx bx-log-out'></i> <span
                                class="logout-text">Logout</span></a></li>
                </ul>
            </div>

        </div>
    </div>
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item">{{Content.subTitle}}</li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <form [formGroup]="fourMonthToTowYear" (ngSubmit)="submitForm(fourMonthAbove)" #fourMonthAbove="ngForm">
        <!-- BABY DETAILS SECTION -->


        <ng-container *ngIf="flag">
            <p-accordion>

                <!--DAILY CARE -->
                <!-- accordion start -->
                <!-- <ng-container *ngIf="flag">
            <p-accordion> -->
                <p-accordionTab header="Milk Feeding">
                    <!-- MILK-->

                    <div class="add-listings-box pb-3">

                        <h3 class="mb-0">Daily Care </h3>
                        <div class=" ">
                            <!-- <div "> -->
                            <div class="form-check checkbox-list p-0">
                                <label class="subHeading px-0">Breast</label>
                                <div class="row">
                                    <div class="col-md">
                                        <div *ngIf="isBreast">
                                            <div class="row" formArrayName="breast_feeding">
                                                <div *ngFor="let control of getbreastarray().controls; let i = index"
                                                    [formGroupName]="i" class="col-6 col-md-4 col-lg-12">
                                                    <div class="row">
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <div class="col-md col-lg-2 mt-3">
                                                                    <label
                                                                        class="checkbox checkbox-outline px-0  mt-md-2">
                                                                        <input type="checkbox" value="breastFeeding"
                                                                            name="checkboxes"
                                                                            formControlName="feeding_breast_checkbox" />
                                                                        Done?
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="p-0">Reference Time: </label>
                                                                <div class="card flex justify-content-center">
                                                                    <p-calendar placeholder="Start Time"
                                                                        formControlName="feeding_breast_time"
                                                                        [timeOnly]="true" [hourFormat]="12"
                                                                        inputId="time" [disabled]="true"
                                                                        [class.is-invalid]="(fourMonthAbove.submitted &&
                                                            fourMonthToTowYear.get('feeding_breast_time')?.invalid)
                                                            ||
                                                              fourMonthToTowYear.get('feeding_breast_time')?.invalid
                                                            &&
                                                              fourMonthToTowYear.get('feeding_breast_time').touched">
                                                                    </p-calendar>
                                                                    <div class="invalid-feedback">Start Time is required
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <label class="p-0">Actual Time: </label>
                                                                <div class="card flex justify-content-center">
                                                                    <p-calendar placeholder="Start Time"
                                                                        formControlName="feeding_breast_actual_time"
                                                                        [timeOnly]="true" [hourFormat]="12"
                                                                        inputId="time" [disabled]="feedingBreast"
                                                                        [class.is-invalid]="(fourMonthAbove.submitted &&
                                                            fourMonthToTowYear.get('feeding_breast_actual_time')?.invalid)
                                                            ||
                                                              fourMonthToTowYear.get('feeding_breast_actual_time')?.invalid
                                                            &&
                                                              fourMonthToTowYear.get('feeding_breast_actual_time').touched">
                                                                    </p-calendar>
                                                                    <div class="invalid-feedback">Start Time is required
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <div class="form-group">
                                                                <div class="card flex justify-content-center">
                                                                    <label class="px-0">Remark</label>
                                                                    <input type="text" name="remark"
                                                                        class="form-control" placeholder="Remark"
                                                                        id="remark"
                                                                        formControlName="breast_feeding_remark" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="subHeading px-0">Formula</label>
                                    <div *ngIf="isFormula">
                                        <div class="row justify-content-end mx-0">
                                            <div class="add-listings-box pt-3 mb-3 col-lg-12">
                                                <div class="row">
                                                    <!-- Formula Feeding Summary -->
                                                    <div class="col-xl-2 col-lg-4 col-md-6">
                                                        <div class="form-group summary2-col">
                                                            <label for="">Formula</label>
                                                            <div type="text" readonly>
                                                                {{ fourMonthToTowYear.value.feeding_type == true ? 'Yes' : '-' }}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-xl-2 col-lg-4 col-md-6">
                                                        <div class="form-group summary2-col">
                                                            <label for="">Frequency</label>
                                                            <div type="text" readonly>
                                                                {{ fourMonthToTowYear.value.feeding_frequency ??'-' }}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-xl-2 col-lg-4 col-md-6">
                                                        <div class="form-group summary2-col">
                                                            <label for="">Start Time</label>
                                                            <div type="text" readonly>
                                                                {{ formatDateTime(fourMonthToTowYear.value.feeding_time) }}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-xl-2 col-lg-4 col-md-6">
                                                        <div class="form-group summary2-col">
                                                            <label for="">End Time</label>
                                                            <div type="text" readonly>
                                                                {{ formatDateTime(fourMonthToTowYear.value.formula_feeding_time) }}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-xl-2 col-lg-4 col-md-6">
                                                        <div class="form-group summary2-col">
                                                            <label for="">Amount</label>
                                                            <div type="text" readonly>
                                                                {{ fourMonthToTowYear.value.feeding_amount_per ?? '-' }}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-xl-2 col-lg-4 col-md-6">
                                                        <div class="form-group">
                                                            <label for="">Type of Formula</label>
                                                            <div type="text" readonly>
                                                                {{ fourMonthToTowYear.value.type_of_formula ?? '-' }}
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div formArrayName="formula_calculation">
                                                    <div *ngFor="let control of getFormulaCalculationControls().controls; let i = index"
                                                        [formGroupName]="i" class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-2 col-md-4">
                                                                <label class="checkbox checkbox-outline px-0  mt-md-2">
                                                                    <input type="checkbox" value="formulaFeeding"
                                                                        name="checkboxes"
                                                                        formControlName="feeding_checkbox" />
                                                                    <span></span>
                                                                    Done?
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-3 col-sm-6 col-12">
                                                                <div class="form-group">
                                                                    <label class="px-0">Time</label>
                                                                    <p-calendar placeholder="Time"
                                                                        formControlName="formula_timeArray"
                                                                        [hourFormat]="12" [timeOnly]="true"
                                                                        inputId="time" disabled="true"></p-calendar>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-sm-6 col-12">
                                                                <div class="form-group">
                                                                    <label class="px-0">Time Feeding By Nurse</label>
                                                                    <p-calendar placeholder="Actual Time"
                                                                        formControlName="nurse_feeding"
                                                                        [hourFormat]="12" [timeOnly]="true"
                                                                        inputId="time"></p-calendar>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-sm-6 col-12">
                                                                <div class="form-group">
                                                                    <label class="px-0">Amount (ml)</label>
                                                                    <input type="text" name="amount"
                                                                        class="form-control" placeholder="Amount"
                                                                        formControlName="amount" />
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-2"></div>
                                                            <div class="col-lg-3 col-sm-12 col-12">
                                                                <div class="form-group">
                                                                    <label class="px-0">It Is Same Formula?</label>
                                                                    <div class="form-check checkbox-list p-0">
                                                                        <p-dropdown [options]="FormulaValue"
                                                                            formControlName="is_same_formula"
                                                                            optionLabel="name" optionValue="value"
                                                                            [filter]="true" filterBy="value"
                                                                            placeholder="No"
                                                                            (ngModelChange)="CheckFormula($event, control)"
                                                                            [resetFilterOnHide]="true">
                                                                        </p-dropdown>
                                                                        <div class="invalid-feedback">Frequency is
                                                                            required</div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3 col-sm-12 col-12">
                                                                <div class="form-group">
                                                                    <label class="px-0">Name of formula</label>
                                                                    <input type="text" name="remark"
                                                                        class="form-control"
                                                                        placeholder="Name of formula"
                                                                        formControlName="remark" disabled />
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-sm-12 col-12">
                                                                <div class="form-group">
                                                                    <label class="px-0">Remark</label>
                                                                    <input type="text" name="remark"
                                                                        class="form-control" placeholder="Remark"
                                                                        id="remark" formControlName="formula_remark" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Feeding -->
                        <div class="row">
                            <label class="subHeading mb-3">Bottle Sterilization</label>
                            <label class="subHeading px-0">All</label>
                            <!-- <div class="col-lg-3">
                                <div class="form-group">
                                    <label class="px-0">Time </label>
                                    <div class="card flex justify-content-center">
                                        <p-calendar placeholder="Time" formControlName="bottle_sterilization_all_time"
                                            [disabled]="All" [timeOnly]="true" [hourFormat]="12" inputId="time"
                                            [class.is-invalid]="(fourMonthAbove.submitted &&
                                            fourMonthToTowYear.get('bottle_sterilization_all_time')?.invalid)
                                            ||
                                            fourMonthToTowYear.get('bottle_sterilization_all_time')?.invalid
                                            &&
                                            fourMonthToTowYear.get('bottle_sterilization_all_time').touched"></p-calendar>
                                        <div class="invalid-feedback">Time is required</div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="row">
                                <div class="col-lg-2 col-md-2 mt-3">
                                  <label class="checkbox checkbox-outline px-0  mt-md-2">
                                    <input type="checkbox" value="true" name="checkboxes"
                                        formControlName="done_sterilization_onetime" />
                                    <span></span>
                                    Done?
                                  </label>
                                </div>
                                <div class="col-lg-3 col-sm-6 col-12">
                                  <div class="form-group">
                                    <label class="px-0">Reference Time</label>
                                    <p-calendar placeholder="Time" formControlName="bottle_sterilization_all_time"
                                        [timeOnly]="true" inputId="time" [hourFormat]="12" readonly></p-calendar>
                                  </div>
                                </div>
                                <div class="col-lg-3 col-sm-6 col-12">
                                  <div class="form-group">
                                    <label class="px-0">Actual Time</label>
                                    <p-calendar placeholder="Time" formControlName="actual_sterilization_all_time"
                                        [timeOnly]="true" inputId="time" [hourFormat]="12"></p-calendar>
                                  </div>
                                </div>
                                <div class="col-lg-4 col-sm-6 col-12">
                                  <div class="form-group">
                                    <label class="px-0">Remark</label>
                                    <input type="text" name="name" class="form-control" placeholder="Remark"
                                       formControlName="onetime_Remark" />
                                  </div>
                                </div>
                              </div>
                        </div>
                        <!-- one at a time -->

                        <label class="subHeading px-0">One at a time</label>

                        <div class="row">
                            <div class="col">
                                <!-- Bottle Sterilization one at a time auto fill fields -->
                                <div class="add-listings-box" *ngIf="isFormula && !One">
                                    <div formArrayName="bottle_sterilization">
                                        <div *ngFor="let control of getbottle_sterilizationControls().controls; let i = index"
                                            [formGroupName]="i">
                                            <div class=" row">
                                                <div class="col-md-2">
                                                    <div class="form-group">
                                                        <div class="col-lg-2 col-md-2 mt-3">
                                                            <label class="checkbox checkbox-outline px-0  mt-md-2">
                                                                <input type="checkbox" value="true" name="checkboxes"
                                                                    formControlName="done_sterilization" />
                                                                <span></span>
                                                                Done?
                                                            </label>
                                                        </div>
                                                        <!-- <label class="px-0">Time</label>
                                                        <p-calendar placeholder="Time"
                                                            formControlName="sterilization_timeArray" [hourFormat]="12"
                                                            [timeOnly]="true" inputId="time"></p-calendar> -->
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-sm-6 col-12">
                                                    <div class="form-group">
                                                        <label class="px-0">Reference Time</label>
                                                        <p-calendar placeholder="Time"
                                                            formControlName="sterilization_timeArray" [hourFormat]="12"
                                                            disabled="true" [timeOnly]="true"
                                                            inputId="time"></p-calendar>
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-sm-6 col-12">
                                                    <div class="form-group">
                                                        <label class="px-0">Actual Time</label>
                                                        <p-calendar placeholder="Time"
                                                            formControlName="actual_sterilization_timeArray"
                                                            [hourFormat]="12" [timeOnly]="true"
                                                            inputId="time"></p-calendar>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="form-group">
                                                        <label class="px-0">Remark</label>
                                                        <input type="text" name="name" class="form-control"
                                                            placeholder="Remark"
                                                            [formControl]="control.get('sterilization_remark')" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- </div> -->
                            </div>
                        </div>
                    </div>
                </p-accordionTab>

                <!--SOLID-->
                <p-accordionTab header="Solid Food">
                    <div class="row">
                        <div class="col">
                            <div class="viewSummary">
                                <div class="col-md-4 form-group" *ngIf="FirstEntry?.solid_other_snack == '1'">
                                    <label for="">Snack Food:</label>
                                    <strong>
                                        {{ FirstEntry?.soild_other_snack_food_components}}
                                    </strong>
                                </div>
                                <div class="col-md-8 form-group" *ngIf="FirstEntry?.solid_other_snack == '1'">
                                    <label for="">Time :</label> <strong>{{ formatDateTime(FirstEntry?.solid_other_snack_time
                                        )}}</strong>
                                </div>
                                <div class="col-md-4 form-group" *ngIf="FirstEntry?.solid_food">
                                    <label for="">Breakfast:</label>
                                    <strong *ngFor="let breakfast of  FirstEntry?.solid_food let i = index">
                                        {{i+1}}.{{ breakfast?.regular_meal_food_components}} at {{
                                        formatDateTime(breakfast?.regular_meal_time )}}<br>
                                    </strong>
                                </div>

                                <div class="col-md-4 form-group"
                                    *ngIf="FirstEntry?.regular_food && babyAge == 'three-years-above'">
                                    <label for="">Breakfast:</label>
                                    <strong *ngFor="let breakfast of  FirstEntry?.regular_food let i = index">
                                        {{i+1}}.{{ breakfast?.regular_meal_food_components}} at {{
                                        formatDateTime(breakfast?.regular_meal_time )}}<br>
                                    </strong>
                                </div>

                                <div class="col-md-4 form-group" *ngIf="FirstEntry?.lunch_food">
                                    <label for="">Lunch:</label>
                                    <strong *ngFor="let lunch of  FirstEntry?.lunch_food let i = index">
                                        {{i+1}}.{{ lunch?.regular_meal_lunch_food_components}} at {{
                                        formatDateTime(lunch?.regular_meal_lunch_time )}}<br>
                                    </strong>
                                </div>

                                <div class="col-md-4 form-group" *ngIf="FirstEntry?.dinner_food">
                                    <label for=""> Dinner:</label>
                                    <strong *ngFor="let dinner of  FirstEntry?.dinner_food let i = index">
                                        {{i+1}}.{{ dinner?.regular_meal_dinner_food_components}} at {{
                                        formatDateTime(dinner?.regular_meal_dinner_time )}}<br>
                                    </strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="add-listings-box">

                        <h3>Solid</h3>


                        <!--Regular Meal-->
                        <!-- add buttons -->
                        <label class="subHeading mb-3 mt-sm-0 mt-3">Regular meal</label>
                        <div formArrayName="solid_food">
                            <ng-container
                                *ngFor="let control of getRegularFormArray().controls; index as i; last as last">

                                <div class="row" [formGroupName]="i">

                                    <div class="form-group mb-2 col-6 col-md-3 col d-flex align-items-center">
                                        <div class="form-check pl-0 checkbox-list px-0">
                                            <label class="checkbox checkbox-outline px-0 m-0">
                                                <input type="checkbox" value="Breakfast"
                                                    formControlName="regular_meal_type" />
                                                <span></span>
                                                Breakfast
                                            </label>
                                        </div>
                                    </div>
                                    <!-- breakfast -->
                                    <div class="col-6 col-md-3 col">
                                        <div class="form-group">
                                            <label class="px-0">Reference Time:</label>
                                            <div class="card flex justify-content-center">
                                                <p-calendar placeholder="Time" formControlName="regular_meal_time"
                                                    [timeOnly]="true" [hourFormat]="12" inputId="time" disabled="true"
                                                    [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('regular_meal_time')?.invalid)
                                ||
                                fourMonthToTowYear.get('regular_meal_time')?.invalid
                                &&
                                fourMonthToTowYear.get('regular_meal_time').touched"></p-calendar>
                                                <div class="invalid-feedback">Time is required</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-6 col-md-3 col">
                                        <div class="form-group">
                                            <label class="px-0">Actual Time:</label>
                                            <div class="card flex justify-content-center">
                                                <p-calendar placeholder="Time"
                                                    formControlName="regular_meal_actual_time" [timeOnly]="true"
                                                    [hourFormat]="12" inputId="time" [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('regular_meal_actual_time')?.invalid)
                                ||
                                fourMonthToTowYear.get('regular_meal_actual_time')?.invalid
                                &&
                                fourMonthToTowYear.get('regular_meal_actual_time').touched"></p-calendar>
                                                <div class="invalid-feedback">Time is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col col-md-3 col-lg col m-0">
                                        <div class="form-group ml-0 col-12 p-0 mb-0">
                                            <label class="px-0">Food components</label>
                                            <div class="col-12 p-0">
                                                <input type="text" formControlName="regular_meal_food_components"
                                                    class="form-control" placeholder="Food components"
                                                    [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('regular_meal_food_components')?.invalid)
                                ||
                                fourMonthToTowYear.get('regular_meal_food_components')?.invalid
                                &&
                                fourMonthToTowYear.get('regular_meal_food_components').touched" />
                                                <div class="invalid-feedback">Food component is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-auto d-flex mb-3 align-items-end">
                                        <p-button type="button" icon="bx bx-plus" (click)="addRegularObject('add', i)"
                                            iconPos="right" class="secondary-btn"></p-button>
                                        <div class="remove">
                                            <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                icon="bx bx-minus" (click)="addRegularObject('delete', i)"
                                                iconPos="right"></p-button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <!-- Lunck checkbox -->
                        <div formArrayName="lunch_food">
                            <ng-container
                                *ngFor="let control of getLunchFormArray().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">
                                    <div class="form-group mb-2 col-6 col-md-3 col d-flex align-items-center">
                                        <div class="form-check pl-0 checkbox-list px-0">
                                            <label class="checkbox checkbox-outline px-0 m-0">
                                                <input type="checkbox" value="Lunch"
                                                    formControlName="regular_meal_lunch"
                                                    (change)="disableAlllunchFoodControls($event.target.checked,i)" />
                                                <span></span>
                                                Lunch
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-3 col">
                                        <div class="form-group">
                                            <label class="px-0">Reference Time:</label>
                                            <div class="card flex justify-content-center">
                                                <p-calendar placeholder="Time" [timeOnly]="true" [hourFormat]="12"
                                                    disabled="true" formControlName="regular_meal_lunch_time"
                                                    inputId="time" [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('regular_meal_lunch_time')?.invalid)
                                ||
                                fourMonthToTowYear.get('regular_meal_lunch_time')?.invalid
                                &&
                                fourMonthToTowYear.get('regular_meal_lunch_time').touched"></p-calendar>
                                                <div class="invalid-feedback">Time is required</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-6 col-md-3 col">
                                        <div class="form-group">
                                            <label class="px-0">Actual Time:</label>
                                            <div class="card flex justify-content-center">
                                                <p-calendar placeholder="Time" [timeOnly]="true" [hourFormat]="12"
                                                    formControlName="regular_meal_lunch_actual_time" inputId="time"
                                                    [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('regular_meal_lunch_actual_time')?.invalid)
                                ||
                                fourMonthToTowYear.get('regular_meal_lunch_actual_time')?.invalid
                                &&
                                fourMonthToTowYear.get('regular_meal_lunch_actual_time').touched"></p-calendar>
                                                <div class="invalid-feedback">Time is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col col-md-3 col-lg col m-0">
                                        <div class="form-group ml-0 col-12 p-0 mb-0">
                                            <label class="px-0">Food components</label>
                                            <div class="col-12 p-0">
                                                <input type="text" formControlName="regular_meal_lunch_food_components"
                                                    class="form-control" placeholder="Food components"
                                                    [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('regular_meal_lunch_food_components')?.invalid)
                                ||
                                fourMonthToTowYear.get('regular_meal_lunch_food_components')?.invalid
                                &&
                                fourMonthToTowYear.get('regular_meal_lunch_food_components').touched" />
                                                <div class="invalid-feedback">Food component is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-auto d-flex mb-3 align-items-end">
                                        <p-button type="button" icon="bx bx-plus" (click)="addLunchObject('add', i)"
                                            iconPos="right" class="secondary-btn"></p-button>
                                        <div class="remove">
                                            <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                icon="bx bx-minus" (click)="addLunchObject('delete', i)"
                                                iconPos="right"></p-button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <!-- Dinner -->
                        <div formArrayName="dinner_food">
                            <ng-container
                                *ngFor="let control of getDinnerFormArray().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">
                                    <div class="form-group mb-2 col-6 col-md-3 col d-flex align-items-center">
                                        <div class="form-check pl-0 checkbox-list px-0">
                                            <label class="checkbox checkbox-outline px-0 m-0">
                                                <input type="checkbox" value="Dinner"
                                                    formControlName="regular_meal_dinner"
                                                    (change)="disableAlldinnerFoodControls($event.target.checked,i)" />
                                                <span></span>
                                                Dinner
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-3 col">
                                        <div class="form-group">
                                            <label class="px-0">Reference Time</label>
                                            <div class="card flex justify-content-center">
                                                <p-calendar placeholder="Time" [timeOnly]="true" [hourFormat]="12"
                                                    formControlName="regular_meal_dinner_time" inputId="time"
                                                    disabled="true" [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('regular_meal_dinner_time')?.invalid)
                                ||
                                fourMonthToTowYear.get('regular_meal_dinner_time')?.invalid
                                &&
                                fourMonthToTowYear.get('regular_meal_dinner_time').touched"></p-calendar>
                                                <div class="invalid-feedback">Time is required</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-6 col-md-3 col">
                                        <div class="form-group">
                                            <label class="px-0">Actual Time:</label>
                                            <div class="card flex justify-content-center">
                                                <p-calendar placeholder="Time" [timeOnly]="true" [hourFormat]="12"
                                                    formControlName="regular_meal_dinner_actual_time" inputId="time"
                                                    [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('regular_meal_dinner_actual_time')?.invalid)
                                ||
                                fourMonthToTowYear.get('regular_meal_dinner_actual_time')?.invalid
                                &&
                                fourMonthToTowYear.get('regular_meal_dinner_actual_time').touched"></p-calendar>
                                                <div class="invalid-feedback">Time is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col col-md-3 col-lg col m-0">
                                        <div class="form-group ml-0 col-12 p-0 mb-0">
                                            <label class="px-0">Food components</label>
                                            <div class="col-12 p-0">
                                                <input type="text" formControlName="regular_meal_dinner_food_components"
                                                    class="form-control" placeholder="Food components"
                                                    [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('regular_meal_dinner_food_components')?.invalid)
                                ||
                                fourMonthToTowYear.get('regular_meal_dinner_food_components')?.invalid
                                &&
                                fourMonthToTowYear.get('regular_meal_dinner_food_components').touched" />
                                                <div class="invalid-feedback">Food component is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-auto d-flex mb-3 align-items-end">
                                        <p-button type="button" icon="bx bx-plus" (click)="addDinnerObject('add', i)"
                                            iconPos="right" class="secondary-btn"></p-button>
                                        <div class="remove">
                                            <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                icon="bx bx-minus" (click)="addDinnerObject('delete', i)"
                                                iconPos="right"></p-button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>


                        <div class="row">
                            <label class="subHeading mb-3">Others</label>
                            <div class="form-group mb-2 col-6 col-md-3 col-lg-2 d-flex align-items-center">
                                <div class="form-check pl-0 checkbox-list px-0">
                                    <label class="checkbox checkbox-outline px-0 m-0">
                                        <input type="checkbox" value="Snack" formControlName="solid_other_snack"
                                            (change)="handleInput($event, 'regularMeal')" />
                                        <span></span>
                                        Snack
                                    </label>
                                </div>
                            </div>
                            <div class="col-6 col-md-3 col">
                                <div class="form-group">
                                    <label class="px-0">Reference Time:</label>
                                    <div class="card flex justify-content-center">
                                        <p-calendar placeholder="Time" [timeOnly]="true" [hourFormat]="12"
                                            disabled="true" formControlName="solid_other_snack_time" inputId="time"
                                            [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('solid_other_snack_time')?.invalid)
                                ||
                                fourMonthToTowYear.get('solid_other_snack_time')?.invalid
                                &&
                                fourMonthToTowYear.get('solid_other_snack_time').touched"></p-calendar>
                                        <div class="invalid-feedback">Time is required</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6 col-md-3 col">
                                <div class="form-group">
                                    <label class="px-0"> Actual Time:</label>
                                    <div class="card flex justify-content-center">
                                        <p-calendar placeholder="Time" [timeOnly]="true" [hourFormat]="12"
                                            formControlName="solid_other_snack_actual_time" inputId="time"
                                            [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('solid_other_snack_actual_time')?.invalid)
                                ||
                                fourMonthToTowYear.get('solid_other_snack_actual_time')?.invalid
                                &&
                                fourMonthToTowYear.get('solid_other_snack_actual_time').touched"></p-calendar>
                                        <div class="invalid-feedback">Time is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col m-0">
                                <div class="form-group ml-0 col-12 p-0 mb-0">
                                    <label class="px-0">Food components</label>
                                    <div class="col-12 p-0">
                                        <input type="text" formControlName="soild_other_snack_food_components"
                                            class="form-control" placeholder="Food components" [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('soild_other_snack_food_components')?.invalid)
                                ||
                                fourMonthToTowYear.get('soild_other_snack_food_components')?.invalid
                                &&
                                fourMonthToTowYear.get('soild_other_snack_food_components').touched" />
                                        <div class="invalid-feedback">Food component is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </p-accordionTab>
                <!-- </div> -->


                <!--Food/Medicine Allergy-->


                <!-- </div> -->

                <p-accordionTab header="Diaper Change">
                    <div class="add-listings-box p-0 border-0">
                        <div class="row">
                            <!-- diaper times Start -->
                            <div class="col-sm-8 col-md-12">
                                <label for="" class="d-none d-md-block">&nbsp;</label>
                                <div class="row" formArrayName="diaper_calculation">
                                    <div *ngFor="let control of getdiaperCalculationControls().controls; let i = index"
                                        [formGroupName]="i" class="row">

                                        <!-- <div class="form-check px-0 checkbox-list">
                                                <label class="checkbox checkbox-outline px-0">
                                                    <input type="checkbox" value="yes"
                                                        formControlName="isDiaperChanged" />
                                                    <span></span>
                                                    {{ getTimeLabel(i) }}
                                                </label>
                                            </div> -->

                                        <div class="col-lg-2 col-sm-6 col-12 d-flex align-items-end">
                                            <div class="form-group">
                                                <div class="form-check checkbox-list p-0">
                                                    <label class="checkbox checkbox-outline mt-sm-0 mt-3 px-0">
                                                        <input type="checkbox" value="withMother" name="checkboxes"
                                                            formControlName="diaper_checkbox" />
                                                        Changed?
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-3 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Changing Time</label>
                                                <div class="form-check checkbox-list p-0">
                                                    <p-calendar placeholder="Select Time" [timeOnly]="true" [hourFormat]="12" inputId="time" readonly
                                                    formControlName="diaper_timeArray"></p-calendar>
                                                    <div class="invalid-feedback">Time is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Actual Time</label>
                                                <div class="form-check checkbox-list p-0">
                                                    <p-calendar placeholder="Select Time" [timeOnly]="true"
                                                        [hourFormat]="12" inputId="time"
                                                        formControlName="diaper_actual_time"></p-calendar>
                                                    <div class="invalid-feedback">Time is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Remark</label>
                                                <input type="text" name="name" class="form-control" placeholder="Remark"
                                                    formControlName="diaperRemark" />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <!-- diaper times end  -->
                        </div>

                        <div class="row" formArrayName="diaper_change_times">
                            <label class="subHeading mb-2 mt-2">Additional Diaper Change </label>

                            <ng-container
                                *ngFor="let control of getDiaperChangeControls().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">

                                    <div class="col-lg-2 col-sm-6 col-12 mt-3">
                                        <div class="form-group">
                                            <div class="form-check checkbox-list p-0">
                                                <label class="checkbox checkbox-outline mt-sm-0 mt-3 px-0">
                                                    <input type="checkbox" value="withMother" name="checkboxes"
                                                        formControlName="change"
                                                        (change)="addValidator(i,$event,'addDiaper')" />
                                                    Changed?
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-sm-6 col-12">
                                        <div class="form-group">
                                            <label class="px-0">Changing Time </label>
                                            <div class="form-check checkbox-list p-0">
                                                <p-calendar placeholder="Select Time" [timeOnly]="true"
                                                    [hourFormat]="12" inputId="time" formControlName="time">
                                                </p-calendar>
                                                <div class="error"
                                                    *ngIf="control?.status !== 'VALID' && control?.status !== 'DISABLED'">
                                                    Time is required</div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-6 col-12">
                                        <div class="form-group">
                                            <label class="px-0">Remark</label>
                                            <input type="text" name="name" class="form-control" placeholder="Remark"
                                                [formControl]="control.get('diaper_remark')" />
                                        </div>
                                    </div>
                                    <div class="col-auto d-flex mb-3 align-items-end">
                                        <p-button type="button" icon="bx bx-plus" (click)="adddiaperObject('add', i)"
                                            iconPos="right" class=" secondary-btn mr-2"></p-button>
                                        <div class="remove">
                                            <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                icon="bx bx-minus" (click)="adddiaperObject('delete', i)"
                                                iconPos="right">
                                            </p-button>
                                        </div>
                                    </div>

                                </div>
                            </ng-container>
                        </div>
                    </div>
                </p-accordionTab>

                <!-- bathing-->

                <!-- <div class="add-listings-box"> -->
                <p-accordionTab header="Bathing">
                    <div class="add-listings-box">
                        <div class="row">
                            <!-- <label class="subHeading mb-3">Bathing </label> -->
                            <label class="subHeading mb-3">Sponge </label>

                            <!-- Sponge -->

                            <div class="col">
                                <div *ngIf="isBathing">
                                    <div class="row" formArrayName="bathing_time">
                                        <div *ngFor="let control of getbathingarray().controls; let i = index"
                                            [formGroupName]="i" class="">
                                            <div class="row">
                                                <div
                                                    class="form-check checkbox-list col-md-3 col-lg-2 d-flex align-items-center form-group">
                                                    <label>
                                                        <input type="checkbox" value="breastFeeding" name="checkboxes"
                                                            formControlName="sponge_checkbox" />
                                                        Done?
                                                    </label>
                                                </div>
                                                <div class="justify-content-center col-md-3 form-group">
                                                    <label class="px-0">Reference Time </label>

                                                    <p-calendar placeholder="Select Time" [timeOnly]="true"
                                                        [hourFormat]="12" disabled="true"
                                                        formControlName="bathing_sponge_time" inputId="time"
                                                        [class.is-invalid]="(fourMonthAbove.submitted && 
                                                        fourMonthToTowYear.get('bathing_sponge_time')?.invalid)
                                                        ||
                                                        fourMonthToTowYear.get('bathing_sponge_time')?.invalid
                                                        &&
                                                        fourMonthToTowYear.get('bathing_sponge_time').touched"></p-calendar>
                                                    <div class="invalid-feedback">Time is required</div>
                                                </div>
                                                <div class="justify-content-center col-md-3 form-group">
                                                    <label class="px-0">Actual Time </label>

                                                    <p-calendar placeholder="Select Time" [timeOnly]="true"
                                                        [hourFormat]="12" formControlName="bathing_sponge_actual_time"
                                                        inputId="time"
                                                        [class.is-invalid]="(fourMonthAbove.submitted && 
                                                        fourMonthToTowYear.get('bathing_sponge_actual_time')?.invalid)
                                                        ||
                                                        fourMonthToTowYear.get('bathing_sponge_actual_time')?.invalid
                                                        &&
                                                        fourMonthToTowYear.get('bathing_sponge_actual_time').touched"></p-calendar>
                                                    <div class="invalid-feedback">Time is required</div>
                                                </div>
                                                <div class="col-md-3 col-lg-3">
                                                    <div class="form-group">
                                                        <label class="px-0">Remark</label>
                                                        <input type="text" name="name" class="form-control"
                                                            placeholder="Remark"
                                                            [formControl]="control.get('spongeBathing_remark')" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Tub Bath -->
                        <label class="subHeading mb-3">Tub</label>
                        <div class="row">

                            <div class="col">
                                <div *ngIf="isBathingtub">
                                    <div class="row" formArrayName="tub_bathing">
                                        <div *ngFor="let control of getbathingtubarray().controls; let i = index"
                                            [formGroupName]="i" class="">
                                            <div class="row">
                                                <div class="form-check checkbox-list col-md-3 col-lg-2 form-group">
                                                    <label>
                                                        <input type="checkbox" value="breastFeeding" name="checkboxes"
                                                            formControlName="tub_checkbox" />
                                                        Done?
                                                    </label>
                                                </div>
                                                <div class="justify-content-center col-md-3 form-group">
                                                    <label class="px-0">Reference time </label>

                                                    <p-calendar placeholder="Select Time" [timeOnly]="true"
                                                        formControlName="bathing_tub_time" [hourFormat]="12"
                                                        inputId="time" disabled="true"
                                                        [class.is-invalid]="(fourMonthAbove.submitted &&
                                                        fourMonthToTowYear.get('bathing_tub_time')?.invalid)
                                                        ||
                                                        fourMonthToTowYear.get('bathing_tub_time')?.invalid
                                                        &&
                                                        fourMonthToTowYear.get('bathing_tub_time').touched"></p-calendar>
                                                    <div class="invalid-feedback">Time is required</div>
                                                </div>

                                                <div class="justify-content-center col-md-3 form-group">
                                                    <label class="px-0">Actual Time </label>

                                                    <p-calendar placeholder="Select Time" [timeOnly]="true"
                                                        formControlName="bathing_tub_actual_time" [hourFormat]="12"
                                                        inputId="time"
                                                        [class.is-invalid]="(fourMonthAbove.submitted &&
                                                        fourMonthToTowYear.get('bathing_tub_actual_time')?.invalid)
                                                        ||
                                                        fourMonthToTowYear.get('bathing_tub_actual_time')?.invalid
                                                        &&
                                                        fourMonthToTowYear.get('bathing_tub_actual_time').touched"></p-calendar>
                                                    <div class="invalid-feedback">Time is required</div>
                                                </div>
                                                <div class="col-md-3 col-lg-3">
                                                    <div class="form-group">
                                                        <label class="px-0">Remark</label>
                                                        <input type="text" name="name" class="form-control"
                                                            placeholder="Remark"
                                                            [formControl]="control.get('tub_remark')" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Shower -->
                        <label class="subHeading mb-3">Shower</label>
                        <div class="row">

                            <div class="col">
                                <div *ngIf="isBathingshower">
                                    <div class="row" formArrayName="shower_bathing">
                                        <div *ngFor="let control of getshowertubarray().controls; let i = index"
                                            [formGroupName]="i">
                                            <div class="row">
                                                <div class="form-check checkbox-list col-md-3 col-lg-2 form-group">
                                                    <label>
                                                        <input type="checkbox" value="breastFeeding" name="checkboxes"
                                                            formControlName="shower_checkbox" />
                                                        Done?
                                                    </label>
                                                </div>
                                                <div class="justify-content-center col-md-3 form-group">
                                                    <label class="px-0">Reference Time </label>

                                                    <p-calendar placeholder="Select Time" [timeOnly]="true"
                                                        formControlName="bathing_shower_time" [hourFormat]="12"
                                                        disabled="true" inputId="time"
                                                        [class.is-invalid]="(fourMonthAbove.submitted &&
                                                        fourMonthToTowYear.get('bathing_shower_time')?.invalid)
                                                        ||
                                                        fourMonthToTowYear.get('bathing_shower_time')?.invalid
                                                        &&
                                                        fourMonthToTowYear.get('bathing_shower_time').touched"></p-calendar>
                                                    <div class="invalid-feedback">Time is required</div>

                                                </div>

                                                <div class="justify-content-center col-md-3 form-group">
                                                    <label class="px-0">Actual Time </label>

                                                    <p-calendar placeholder="Select Time" [timeOnly]="true"
                                                        formControlName="bathing_shower_actual_time" [hourFormat]="12"
                                                        inputId="time"
                                                        [class.is-invalid]="(fourMonthAbove.submitted &&
                                                        fourMonthToTowYear.get('bathing_shower_actual_time')?.invalid)
                                                        ||
                                                        fourMonthToTowYear.get('bathing_shower_actual_time')?.invalid
                                                        &&
                                                        fourMonthToTowYear.get('bathing_shower_actual_time').touched"></p-calendar>
                                                    <div class="invalid-feedback">Time is required</div>

                                                </div>
                                                <div class="col-md-3 col-lg-3">
                                                    <div class="form-group">
                                                        <label class="px-0">Remark</label>
                                                        <input type="text" name="name" class="form-control"
                                                            placeholder="Remark"
                                                            [formControl]="control.get('shower_remark')" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </p-accordionTab>

                <!-- </div> -->

                <!-- Skin Care -->
                <!-- <div class="add-listings-box"> -->
                <!-- <div class="form-check checkbox-list p-0">
                <div class="form-check checkbox-list p-0"> -->
                <p-accordionTab header="Skin Care">
                    <div class="add-listings-box p-0 border-0">
                        <label class="subHeading mb-2">Skin Cares</label>
                        <div formArrayName="skin_care">
                            <ng-container *ngFor="let control of getSkinFormArray().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">

                                    <div class="col-lg-4 ">
                                        <div class="form-group ">

                                            <div class="form-check checkbox-list p-0">
                                                <label for="Lotion" class="checkbox checkbox-outline px-0"> <input
                                                        type="checkbox" id="Lotion"
                                                        formControlName="skin_care_lotion"><span>Lotion</span></label>


                                                <label for="Baby_Oil"
                                                    class="checkbox checkbox-outline mt-sm-0 mt-3 px-0"><input
                                                        type="checkbox" id="Baby_Oil"
                                                        formControlName="skin_care_baby_oil"><span>Baby
                                                        Oil</span></label>


                                                <label for="other"
                                                    class="checkbox checkbox-outline mt-sm-0 mt-3 px-0"><input
                                                        type="checkbox" formControlName="skin_care_others"
                                                        value="others"><span>Others</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg ">
                                        <div class="form-group ">
                                            <!-- <label class="px-0">Specify Products</label> -->
                                            <div class="card flex justify-content-center">
                                                <textarea formControlName="skin_care_specify_produ" class="form-control"
                                                    placeholder="Specify Products" rows="2"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto d-flex mb-3 justify-content-end">
                                        <p-button type="button" icon="bx bx-plus" (click)="addSkinObject('add', i)"
                                            iconPos="right" class="secondary-btn mr-2"></p-button>
                                        <div class="remove">
                                            <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                icon="bx bx-minus" (click)="addSkinObject('delete', i)"
                                                iconPos="right"></p-button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                        <!-- wipes-->


                        <!-- </div> -->

                        <!-- Changing clothes -->
                        <!-- <div class="add-listings-box"> -->
                        <div class="row">
                            <label class="subHeading mb-2 mt-2">Changing of Clothes </label>
                            <!-- <div class="col-lg-4">
                                  <div class="form-group mb-0">
                                    <label class="px-0">Frequency </label>
                                    <div class="form-check checkbox-list p-0">
                                      <p-dropdown [options]="frequency" [(ngModel)]="selectedClothesFrequency"
                                          formControlName="changing_colthes_frequency" optionLabel="frequency" [filter]="true"
                                          filterBy="frequency" placeholder="Frequency" [resetFilterOnHide]="true">
                                        <ng-template pTemplate="selectedItem">
                                          <div class="flex align-items-center gap-2" *ngIf="selectedClothesFrequency">
                                            <div>{{ selectedClothesFrequency.frequency }}</div>
                                          </div>
                                        </ng-template>
                                        <ng-template let-frequency pTemplate="item">
                                          <div class="flex align-items-center gap-2">
                                            <div>{{ frequency.frequency }}</div>
                                          </div>
                                        </ng-template>
                                      </p-dropdown>
                                    </div>
                                  </div>
                                </div> -->

                        </div>
                        <div class="row" formArrayName="changing_clothes">
                            <ng-container
                                *ngFor="let control of getClothesFormArray().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">

                                    <div class="col-lg-3 col-sm-6 col-12 d-flex align-items-end">
                                        <div class="form-group">
                                            <div class="form-check checkbox-list p-0">
                                                <label class="checkbox checkbox-outline mt-sm-0 mt-3 px-0">
                                                    <input type="checkbox" value="withMother" name="checkboxes"
                                                        formControlName="changing_colthes" />
                                                    Changing Clothes
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-2 col-sm-6 col-12">
                                        <div class="form-group">
                                            <label class="px-0">Reference Time </label>
                                            <div class="form-check checkbox-list p-0">
                                                <p-calendar placeholder="Select Time" [timeOnly]="true"
                                                    [hourFormat]="12" inputId="time" disabled="true"
                                                    formControlName="Changing_time" [class.is-invalid]="(fourMonthAbove.submitted &&
                                                       fourMonthToTowYear.get('Changing_time')?.invalid) ||
                                                      fourMonthToTowYear.get('Changing_time')?.invalid &&
                                                      fourMonthToTowYear.get('Changing_time').touched">
                                                </p-calendar>
                                                <div class="invalid-feedback">Time is required</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-2 col-sm-6 col-12">
                                        <div class="form-group">
                                            <label class="px-0">Actual Time </label>
                                            <div class="form-check checkbox-list p-0">
                                                <p-calendar placeholder="Select Time" [timeOnly]="true"
                                                    [hourFormat]="12" inputId="time"
                                                    formControlName="Changing_actual_time" [class.is-invalid]="(fourMonthAbove.submitted &&
                                                       fourMonthToTowYear.get('Changing_actual_time')?.invalid) ||
                                                      fourMonthToTowYear.get('Changing_actual_time')?.invalid &&
                                                      fourMonthToTowYear.get('Changing_actual_time').touched">
                                                </p-calendar>
                                                <div class="invalid-feedback">Time is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-sm-6 col-3">
                                        <div class="form-group">
                                            <label class="px-0">Remark</label>
                                            <input type="text" name="name" class="form-control" placeholder="Remark"
                                                [formControl]="control.get('clothe_remark')" />
                                        </div>
                                    </div>
                                    <div class="col-1 d-flex mb-3 align-items-end">
                                        <p-button type="button" icon="bx bx-plus" (click)="addClothesObject('add', i)"
                                            iconPos="right" class=" secondary-btn mr-2"></p-button>
                                        <div class="remove">
                                            <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                icon="bx bx-minus" (click)="addClothesObject('delete', i)"
                                                iconPos="right">
                                            </p-button>
                                        </div>
                                    </div>

                                </div>
                            </ng-container>
                        </div>

                    </div>
                </p-accordionTab>
                <!-- </div> -->


                <!--Sleeping-->
                <!-- <div class="add-listings-box"> -->
                <p-accordionTab header="Sleeping">
                    <div class="add-listings-box pb-0">
                        <h3>Sleeping</h3>
                        <div class="row">
                            <div class="row" formArrayName="sleeping_time">
                                <ng-container
                                    *ngFor="let control of getSleepArray().controls; index as i; last as last">
                                    <div class="row" [formGroupName]="i">
                                        <!-- <label class="subHeading mb-3">Sleeping </label> -->
                                        <div class="col-lg-3 col-sm-6 col-12">
                                            <div class="form-group">
                                                <div class="form-check checkbox-list p-0">
                                                    <!-- <div class="row py-3"> -->
                                                    <label class="checkbox checkbox-outline px-0 mt-sm-4 mt-0">
                                                        <input type="checkbox" value="withMother" name="checkboxes"
                                                            (change)="handleInput($event, 'sleepingWith')"
                                                            formControlName="sleeping_mother" />
                                                        With Mother
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Reference Time </label>
                                                <div class="form-check checkbox-list p-0">
                                                    <p-calendar placeholder="Select Time" [timeOnly]="true"
                                                        disabled="true" [hourFormat]="12" inputId="time"
                                                        formControlName="sleeping_mother_time"
                                                        [class.is-invalid]="(fourMonthAbove.submitted &&
                                                        fourMonthToTowYear.get('sleeping_mother_time')?.invalid)
                                                        ||
                                                        fourMonthToTowYear.get('sleeping_mother_time')?.invalid
                                                        &&
                                                        fourMonthToTowYear.get('sleeping_mother_time').touched"></p-calendar>
                                                    <div class="invalid-feedback">Time is required</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-3 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Actual Time </label>
                                                <div class="form-check checkbox-list p-0">
                                                    <p-calendar placeholder="Select Time" [timeOnly]="true"
                                                        [hourFormat]="12" inputId="time"
                                                        formControlName="sleeping_mother_actual_time"
                                                        [class.is-invalid]="(fourMonthAbove.submitted &&
                                                        fourMonthToTowYear.get('sleeping_mother_actual_time')?.invalid)
                                                        ||
                                                        fourMonthToTowYear.get('sleeping_mother_actual_time')?.invalid
                                                        &&
                                                        fourMonthToTowYear.get('sleeping_mother_actual_time').touched"></p-calendar>
                                                    <div class="invalid-feedback">Time is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-1 d-flex mb-3 align-items-end">
                                            <p-button type="button" icon="bx bx-plus"
                                                (click)="addsleeptimeObject('add', i)" iconPos="right"
                                                class=" secondary-btn mr-2"></p-button>
                                            <div class="remove">
                                                <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                    icon="bx bx-minus" (click)="addsleeptimeObject('delete', i)"
                                                    iconPos="right">
                                                </p-button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>

                        <div class="row">
                            <div class="row" formArrayName="nanny_time">
                                <ng-container
                                    *ngFor="let control of getSleepnannyArray().controls; index as i; last as last">
                                    <div class="row" [formGroupName]="i">
                                        <div class="col-lg-3 col-sm-6 col-12">
                                            <div class="form-group">
                                                <div class="form-check checkbox-list p-0">
                                                    <label class="checkbox checkbox-outline mt-sm-4 mt-3 px-0">
                                                        <input type="checkbox" value="withNanny" name="checkboxes"
                                                            (change)="handleInput($event, 'sleepingWith')"
                                                            formControlName="sleeping_nanny" />
                                                        With Nanny
                                                    </label>
                                                    <!-- </div> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Reference Time </label>
                                                <div class="form-check checkbox-list p-0">
                                                    <p-calendar placeholder="Select Time" [timeOnly]="true"
                                                        disabled="true" [hourFormat]="12" inputId="time"
                                                        formControlName="sleeping_nanny_time" [class.is-invalid]="(fourMonthAbove.submitted &&
                                          fourMonthToTowYear.get('sleeping_nanny_time')?.invalid)
                                          ||
                                          fourMonthToTowYear.get('sleeping_nanny_time')?.invalid
                                          &&
                                          fourMonthToTowYear.get('sleeping_nanny_time').touched"></p-calendar>
                                                    <div class="invalid-feedback">Time is required</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-3 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Actual Time </label>
                                                <div class="form-check checkbox-list p-0">
                                                    <p-calendar placeholder="Select Time" [timeOnly]="true"
                                                        [hourFormat]="12" inputId="time"
                                                        formControlName="sleeping_nanny_actual_time" [class.is-invalid]="(fourMonthAbove.submitted &&
                                          fourMonthToTowYear.get('sleeping_nanny_actual_time')?.invalid)
                                          ||
                                          fourMonthToTowYear.get('sleeping_nanny_actual_time')?.invalid
                                          &&
                                          fourMonthToTowYear.get('sleeping_nanny_actual_time').touched"></p-calendar>
                                                    <div class="invalid-feedback">Time is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-1 d-flex mb-3 align-items-end">
                                            <p-button type="button" icon="bx bx-plus" (click)="addnannyObject('add', i)"
                                                iconPos="right" class=" secondary-btn mr-2"></p-button>
                                            <div class="remove">
                                                <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                    icon="bx bx-minus" (click)="addnannyObject('delete', i)"
                                                    iconPos="right">
                                                </p-button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </p-accordionTab>
                <!-- Sleep trining-->
                <p-accordionTab header="Sleep Training">
                    <div class="add-listings-box">

                        <div class="form-check checkbox-list p-0">
                            <div class="form-group">
                                <div class="subHeading mb-3">Sleep Training</div>
                                <div class="d-flex">
                                    <p class="me-2">
                                        <input type="radio" (change)="handleInput($event, 'Training')" id="training_yes"
                                            value="Yes" formControlName="sleep_train">
                                        <label for="training_yes" class="mb-0 mx-2">Yes</label>
                                    </p>
                                    <p class="d-flex">
                                        <input type="radio" (change)="handleInput($event, 'Training')" id="training_no"
                                            value="No" formControlName="sleep_train">
                                        <label for="training_no" class="mb-0 mx-2">No</label>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <!--Walk up time -->
                        <div class="row">
                            <!-- <div class="form-group col-lg-4 col-sm-6 col-12 mb-0 d-flex align-items-center">
                                <div class="form-check pl-0 checkbox-list px-0">
                                    <label class="checkbox checkbox-outline px-0">
                                        Wake up time (morning)
                                    </label>
                                </div>
                            </div> -->
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="px-0"> Wake up time (morning)</label>
                                    <div class="card flex justify-content-center">
                                        <p-calendar placeholder="Time" formControlName="walk_time"
                                            [disabled]="sleep_training" [timeOnly]="true" [hourFormat]="12"
                                            inputId="time" [class.is-invalid]="(fourMonthAbove.submitted &&
                                            fourMonthToTowYear.get('walk_time')?.invalid)
                                            ||
                                            fourMonthToTowYear.get('walk_time')?.invalid
                                            &&
                                            fourMonthToTowYear.get('walk_time').touched"></p-calendar>
                                        <div class="invalid-feedback">Wake up time is required</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-sm-6 col-12">
                                <div class="form-group mb-0">
                                    <label class="px-0">Nap time</label>
                                    <div class="card flex justify-content-center">
                                        <p-calendar placeholder="Time" formControlName="nap_time"
                                            [disabled]="sleep_training" [timeOnly]="true" [hourFormat]="12"
                                            inputId="time" [class.is-invalid]="(fourMonthAbove.submitted &&
                            fourMonthToTowYear.get('nap_time')?.invalid)
                            ||
                            fourMonthToTowYear.get('nap_time')?.invalid
                            &&
                            fourMonthToTowYear.get('nap_time').touched"></p-calendar>
                                        <div class="invalid-feedback">Nap time is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-12">
                                <div class="form-group mb-0">
                                    <label class="px-0"> Sleep time (evening) </label>
                                    <div class="card flex justify-content-center">
                                        <p-calendar placeholder="Time" [disabled]="sleep_training" [timeOnly]="true"
                                            formControlName="sleep_time" [hourFormat]="12" inputId="time"
                                            [class.is-invalid]="(fourMonthAbove.submitted &&
                            fourMonthToTowYear.get('sleep_time')?.invalid)
                            ||
                            fourMonthToTowYear.get('sleep_time')?.invalid
                            &&
                            fourMonthToTowYear.get('sleep_time').touched"></p-calendar>
                                        <div class="invalid-feedback"> Sleep time is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--Nap time-->
                        <!-- <div class="row">
                            <div class="form-group col-lg-4 col-sm-6 col-12 mb-0 d-flex align-items-center">
                                <div class="form-check pl-0 checkbox-list px-0">
                                    <label class="checkbox checkbox-outline px-0">
                                        Nap time
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-12">
                                <div class="form-group mb-0">
                                    <label class="px-0">Nap time</label>
                                    <div class="card flex justify-content-center">
                                        <p-calendar placeholder="Time" formControlName="nap_time"
                                            [disabled]="sleep_training" [timeOnly]="true" [hourFormat]="12"
                                            inputId="time" [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('nap_time')?.invalid)
                                ||
                                fourMonthToTowYear.get('nap_time')?.invalid
                                &&
                                fourMonthToTowYear.get('nap_time').touched"></p-calendar>
                                        <div class="invalid-feedback">Nap time is required</div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!--Sleep time-->
                        <!-- <div class="row mt-3">
                            <div class="form-group col-lg-4 col-sm-6 col-12 mb-0 d-flex align-items-center">
                                <div class="form-check pl-0 checkbox-list px-0">
                                    <label class="checkbox checkbox-outline px-0 mt-sm-0 mt-2">
                                        Sleep time (evening)
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-12">
                                <div class="form-group mb-0">
                                    <label class="px-0"> Sleep time (evening) </label>
                                    <div class="card flex justify-content-center">
                                        <p-calendar placeholder="Time" [disabled]="sleep_training" [timeOnly]="true"
                                            formControlName="sleep_time" [hourFormat]="12" inputId="time"
                                            [class.is-invalid]="(fourMonthAbove.submitted &&
                                fourMonthToTowYear.get('sleep_time')?.invalid)
                                ||
                                fourMonthToTowYear.get('sleep_time')?.invalid
                                &&
                                fourMonthToTowYear.get('sleep_time').touched"></p-calendar>
                                        <div class="invalid-feedback"> Sleep time is required</div>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <!--Points-->
                        <div class="row mt-3">
                            <div class="col-lg-6">
                                <div class="form-check checkbox-list p-0">
                                    <div class="form-group">
                                        <label class="px-0">Stroller </label>
                                        <p class="d-flex">
                                            <input type="radio" id="stroller_yes" value="Yes"
                                                formControlName="stroller">
                                            <label for="stroller_yes" class="mb-0 mx-2">Yes</label>
                                        </p>
                                        <p class="d-flex">
                                            <input type="radio" id="stroller_no" value="No" formControlName="stroller">
                                            <label for="stroller_no" class="mb-0 mx-2">No</label>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-check checkbox-list p-0">
                                    <div class="form-group">
                                        <label class="px-0">Can the baby get himself back to sleep when interrupted
                                            during
                                            short
                                            naps?
                                        </label>
                                        <p class="d-flex">
                                            <input type="radio" id="baby_get_yes" value="Yes"
                                                formControlName="interrupt">
                                            <label for="baby_get_yes" class="mb-0 mx-2">Yes</label>
                                        </p>
                                        <p class="d-flex">
                                            <input type="radio" id="baby_get_no" value="No" formControlName="interrupt">
                                            <label for="baby_get_no" class="mb-0 mx-2">No</label>
                                        </p>
                                    </div>
                                </div>

                            </div>
                            <div class="col-lg-6">
                                <div class="form-check checkbox-list p-0">
                                    <div class="form-group">
                                        <label class="px-0">Needs dummy to help soothe the baby during sleep </label>
                                        <p class="d-flex">
                                            <input type="radio" id="sleep_yes" value="Yes" formControlName="need_dummy">
                                            <label for="sleep_yes" class="mb-0 mx-2">Yes</label>
                                        </p>
                                        <p class="d-flex">
                                            <input type="radio" id="sleep_no" value="No" formControlName="need_dummy">
                                            <label for="sleep_no" class="mb-0 mx-2">No</label>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-check checkbox-list p-0">
                                    <div class="form-group">
                                        <label class="px-0">Pacifier </label>
                                        <p class="d-flex">
                                            <input type="radio" id="pacifier_yes" value="Yes"
                                                formControlName="pacifier">
                                            <label for="pacifier_yes" class="mb-0 mx-2">Yes</label>
                                        </p>
                                        <p class="d-flex">
                                            <input type="radio" id="pacifier_no" value="No" formControlName="pacifier">
                                            <label for="pacifier_no" class="mb-0 mx-2">No</label>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label class="px-0">Songs before sleep </label>
                                    <p class="d-flex">
                                        <input type="radio" id="songs_yes" value="Yes" formControlName="song_sleep">
                                        <label for="songs_yes" class="mb-0 mx-2">Yes</label>
                                    </p>
                                    <p class="d-flex">
                                        <input type="radio" id="songs_no" value="No" formControlName="song_sleep">
                                        <label for="songs_no" class="mb-0 mx-2">No</label>
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label class="px-0">Reading before bedtime </label>
                                    <p class="d-flex">
                                        <input type="radio" id="reading_yes" value="Yes"
                                            formControlName="reading_sleep">
                                        <label for="reading_yes" class="mb-0 mx-2">Yes</label>
                                    </p>
                                    <p class="d-flex">
                                        <input type="radio" id="reading_no" value="No" formControlName="reading_sleep">
                                        <label for="reading_no" class="mb-0 mx-2">No</label>
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </p-accordionTab>
                <!--Temprature Monitoring-->
                <p-accordionTab header="Vitamins/Medications">
                    <!-- <div class="add-listings-box">
                        <div class="row">
                            <label class="subHeading mb-3">Temperature Monitoring(In hours)</label>
                            <div class="col-lg-4 col-12">
                                <div class="form-group">
                                    <label class="subHeading px-0">Frequency </label>
                                    <div class="card flex justify-content-center">
                                        <p-dropdown id="temp_frequency" [options]="frequencyList"   [filter]="true" filterBy="value"
                                            formControlName="temperature_monitoring_frequency" optionLabel="name"
                                            optionValue="value" placeholder="Select Frequency">
                                        </p-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <!--Vitamins/Medication-->

                    <div class="add-listings-box">
                        <div class="row">
                            <label class="subHeading mb-3">Vitamins/Medications</label>
                            <div formArrayName="vitamins_medications_json">
                                <ng-container
                                    *ngFor="let control of getVitaminFormArray().controls; index as i; last as last">
                                    <div class="row" [formGroupName]="i">
                                        <div class="col-md-4 form-group">
                                            <label>Medication: {{ control?.value?.vitamin }} and frequency
                                                {{ control?.value?.frequency }}</label>
                                        </div>
                                        <div *ngIf="ismedicine">
                                            <div class="row" formArrayName="medicine_time">
                                                <div *ngFor="let control of getmedicinearray(i).controls; index as j">
                                                    <div [formGroupName]="j">
                                                        <div class="row">
                                                            <div
                                                                class="form-check checkbox-list col-md-3 col-lg-2 form-group d-flex align-items-center">
                                                                <label>
                                                                    <input type="checkbox" value="withvitamin"
                                                                        name="checkboxes"
                                                                        formControlName="vitamin_checkbox" />
                                                                    Done?
                                                                </label>
                                                            </div>
                                                            <div class="justify-content-center col-md-4 form-group">
                                                                <label class="px-0">Reference Time</label>

                                                                <p-calendar placeholder="Time" formControlName="time"
                                                                    disabled="true" [timeOnly]="true" [hourFormat]="12"
                                                                    inputId="time"></p-calendar>
                                                            </div>

                                                            <div class="justify-content-center col-md-3 form-group">
                                                                <label class="px-0">Actual Time</label>
                                                                <p-calendar placeholder="Time"
                                                                    formControlName="actual_time" [timeOnly]="true"
                                                                    [hourFormat]="12" inputId="time"></p-calendar>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <div class="form-group">
                                                                    <label class="px-0">Remark</label>
                                                                    <input type="text" name="name" class="form-control"
                                                                        placeholder="Remark"
                                                                        [formControl]="control.get('vitamin_remark')" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-auto d-flex mb-3 align-items-end">
                                            <p-button type="button" icon="bx bx-plus"
                                                (click)="addVitaminObject('add', i)" iconPos="right"
                                                class=" secondary-btn mr-2"></p-button>
                                            <div class="remove">
                                                <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                    icon="bx bx-minus" (click)="addVitaminObject('delete', i)"
                                                    iconPos="right"></p-button>
                                            </div>
                                        </div> -->
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="add-listings-box">
                        <label class="subHeading mb-3">Vaccinations</label>
                        <div class="row">
                            <div formArrayName="vaccinations_json">
                                <ng-container
                                    *ngFor="let control of getVaccinationFormArray().controls; index as i; last as last">
                                    <div class="row" [formGroupName]="i">
                                        <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Vaccinations</label>
                                                <div class="pl-0 checkbox-list">
                                                    <input type="text" formControlName="vaccination"
                                                        class="form-control" placeholder="Vaccinations" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Date of Administration</label>
                                                <div class="card flex justify-content-center">
                                                    <p-calendar placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                                                        formControlName="date_administration"
                                                        [showIcon]="true"></p-calendar>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-12 d-flex justify-content-end align-items-center">
                                            <div class="form-group mb-0">
                                                <div class="d-flex justify-content-end">
                                                    <p-button type="button" icon="bx bx-plus"
                                                        (click)="addVaccinationObject('add', i)" iconPos="right"
                                                        class="mr-2"></p-button>
                                                    <div class="remove">
                                                        <p-button type="button" *ngIf="i!= 0" class="ml-2"
                                                            icon="bx bx-minus"
                                                            (click)="addVaccinationObject('delete', i)"
                                                            iconPos="right"></p-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div> -->
                </p-accordionTab>
                <!-- <p-accordionTab header="Remarks">
                    <div class="add-listings-box">

                        <label class="subHeading mb-3">Remarks</label>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <div class="pl-0 checkbox-list">
                                        <textarea class="form-control" id="remarks" rows="5"
                                            formControlName="remarks"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </p-accordionTab> -->

                <p-accordionTab header="Remarks">
                    <div class="add-listings-box">
                        <div class="row">
                            <label class="subHeading mb-3">Remarks</label>
                            <div class="col-12">
                                <div class="form-group">
                                    <div class="form-check checkbox-list p-0">
                                        <textarea readonly style="background-color: #ffff84 !important" name="name"
                                            class="form-control" placeholder="Enter Remarks" formControlName="remarks"
                                            rows="5"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <label class="subHeading mb-3">Add Remarks</label>
                        <div formArrayName="nurse_remarks">
                            <ng-container *ngFor="let control of getNurseRemarks().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">
                                    <div class="col-lg">
                                        <div class="form-group">
                                            <!-- <label class="p-0">Specifiy Product: </label> -->
                                            <div class="col-12 p-0">
                                                <textarea name="name" class="form-control" placeholder="Specify Product"
                                                    formControlName="nurse_remark" rows="2"></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg">
                                        <div class="form-group">
                                            <div class="col-12 p-0">
                                                <textarea name="name" class="form-control" placeholder="Remark"
                                                    formControlName="nurse_remark_time" rows="2" readonly></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto d-flex mb-3 align-items-center">
                                        <p-button type="button" (click)="addNurseRemark('add', i)" icon="bx bx-plus"
                                            class="secondary-btn" iconPos="right"></p-button>
                                        <div class="remove">
                                            <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                icon="bx bx-minus" (click)="addNurseRemark('delete', i)"
                                                iconPos="right"></p-button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </p-accordionTab>

            </p-accordion>

        </ng-container>
        <!-- <div class="add-listings-btn text-end">
            <button type="submit" [disabled]="!flag" *ngIf="checkPermission(31)">Submit</button>
        </div> -->
        <div class="add-listings-btn d-flex justify-content-end">
            <div class="me-2">
              <button type="submit" [disabled]="!flag" *ngIf="checkPermission(31)">Save</button>
            </div>
            <div>
              <button type="button" (click)="nannyTaskOnFinalSubmit()">Submit</button>
            </div>
        </div>
    </form>
    <app-copyrights></app-copyrights>
</div>