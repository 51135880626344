<p-toast></p-toast>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>
    <!-- profile section -->

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item">{{Content.subTitle}}</li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

    <!-- Start -->
    <form [formGroup]="initialAssessmentForm" (ngSubmit)="saveAssessmentForm(assessment); " #assessment="ngForm">
        <!-- BASIC INFORMATION SECTION -->
        <p-accordion>
            <p-accordionTab header="Basic Information">
                <div class="add-listings-box">
                    <h3>Service (Type of Shift)</h3>
                    <div class="row">
                        <!-- <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label class="px-0">Service Hours<span class="required">*</span></label>
                                <div>
                                
                                    <p-dropdown [options]="serviceArray" formControlName="service_hours"
                                        optionLabel="name" [filter]="true" filterBy="name" [showClear]="false"
                                        optionValue="name" placeholder="Select Type Of Service"
                                        [resetFilterOnHide]="true" (onChange)="service($event)"></p-dropdown>

                                
                                </div>
                              

                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label class="px-0">Assign Nurse<span class="required">*</span></label>
                                <div>
                                    <p-dropdown [options]="nurseData" formControlName="associated_nurse_id"
                                        optionLabel="first_name" [filter]="true" filterBy="first_name"
                                        [showClear]="false" optionValue="id" placeholder="Select Nurse"
                                        [resetFilterOnHide]="true"></p-dropdown>
                                </div>
                                
                            </div>
                        </div> -->


                        <div formArrayName="nurses">

                            <ng-container *ngFor="let control of getNurses().controls; index as i; last as last">
                                <div class="row" [formGroupName]="i">
                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group">
                                            <label class="px-0">Service Hours<span class="required">*</span></label>
                                            <div>
                                                <p-dropdown [options]="serviceArray" formControlName="service_hours"
                                                    optionLabel="name" [filter]="true" filterBy="name"
                                                    [showClear]="false" optionValue="name"
                                                    placeholder="Select Type Of Service" [resetFilterOnHide]="true"
                                                    (onChange)="service($event)" [ngClass]="{
                                                        'is-invalid': (assessment.submitted && control.get('service_hours')?.invalid) ||
                                                                     control.get('service_hours')?.invalid && control.get('service_hours').touched
                                                    }"></p-dropdown>
                                                <div *ngIf="(assessment.submitted || control.get('service_hours').touched) && control.get('service_hours')?.invalid"
                                                    class="invalid-feedback">
                                                    Shift is Required
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group">
                                            <label class="px-0">Assign Nurse<span class="required">*</span></label>
                                            <div>
                                                <p-dropdown [options]="nurseData" formControlName="associated_nurse_id"
                                                    optionLabel="first_name" [filter]="true" filterBy="first_name"
                                                    [showClear]="false" optionValue="id" placeholder="Select Nurse"
                                                    [resetFilterOnHide]="true" [ngClass]="{
                                                        'is-invalid': (assessment.submitted && control.get('associated_nurse_id')?.invalid) ||
                                                                     control.get('associated_nurse_id')?.invalid && control.get('associated_nurse_id').touched
                                                    }"></p-dropdown>
                                                <div *ngIf="(assessment.submitted || control.get('associated_nurse_id').touched) && control.get('associated_nurse_id')?.invalid"
                                                    class="invalid-feedback">
                                                    Nurse is Required
                                                </div>
                                            </div>

                                        </div>
                                    </div>



                                    <div class="col-auto d-flex mb-3 align-items-end">
                                        <p-button type="button"
                                            [disabled]="initialAssessmentForm.get('nurses').disabled" icon="bx bx-plus"
                                            (click)="addNurseObject('add', i)" iconPos="right"
                                            class=" secondary-btn mr-2"></p-button>
                                        <div class="remove">
                                            <p-button type="button"
                                                [disabled]="initialAssessmentForm.get('nurses').disabled" *ngIf="i!= 0"
                                                class="secondary-btn ms-2" icon="bx bx-minus"
                                                (click)="addNurseObject('delete', i)" iconPos="right">
                                            </p-button>
                                        </div>
                                    </div>

                                </div>
                            </ng-container>
                            <ng-container *ngIf="totalHours">
                                <div class="error">
                                    Total service hours will exceed 24. Cannot add more nurses
                                </div>
                            </ng-container>
                        </div>

                        <div class="add-listings-btn" style="text-align: right;" *ngIf="isedit">
                            <div class="row">
                                <div class="col-lg col-12">
                                    <div class="form-group text-start">
                                        <label class="p-0" style="text-align-last: left;">Created by: </label>
                                        <span>{{createdBy}}</span>
                                        <!-- <input type="text" formControlName="createdby" class="form-control"
                                            placeholder="Created by" readonly /> -->
                                    </div>
                                </div>
                                <div class="col-lg col-12">
                                    <div class="form-group text-start">
                                        <label class="p-0" style="text-align-last: left;">Created at:</label>
                                        <span>{{createdAt?.substring(0, 16).replace('T', ' ') }}</span>

                                        <!-- <input type="text" formControlName="createdat" class="form-control"
                                            placeholder="Updated by" readonly /> -->
                                    </div>
                                </div>

                                <div class="col-lg col-12">
                                    <div class="form-group text-start">
                                        <label class="p-0" style="text-align-last: left;">Updated by:</label>
                                        <span>{{updatedBy}}</span>

                                        <!-- <input type="text" formControlName="updateddat" class="form-control"
                                            placeholder="Created by" readonly /> -->
                                    </div>
                                </div>
                                <div class="col-lg col-12 ">
                                    <div class="form-group text-start">
                                        <label class="p-0" style="text-align-last: left;">Updated at:</label>
                                        <span>{{updatedAt?.substring(0, 16).replace('T', ' ') }}</span>

                                        <!-- <input type="text" formControlName="updateddby" class="form-control"
                                            placeholder="Updated by" readonly /> -->
                                    </div>
                                </div>
                                <div class="col-auto align-self-end">
                                    <div class="form-group">
                                        <p-button (click)="basic()" class="secondary-btn"
                                            *ngIf="checkPermission(7)">Edit</p-button>
                                        <button type="submit" class="ms-2" *ngIf="checkPermission(5)">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </p-accordionTab>
            <!-- BABY DETAILS SECTION -->



            <p-accordionTab header="Baby Details">
                <div class="add-listings-box">
                    <h3>Baby Details</h3>
                    <div class="row">
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="p-0">Name<span class="required">*</span></label>
                                <input type="text" formControlName="name" id="name" class="form-control"
                                    placeholder="Name" [class.is-invalid]="(assessment.submitted &&
                        initialAssessmentForm.get('name')?.invalid)
                        ||
                        initialAssessmentForm.get('name')?.invalid
                        &&
                        initialAssessmentForm.get('name').touched" />
                                <div class="invalid-feedback">
                                    Name is required
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="p-0">Gender<span class="required">*</span></label>
                                <div class="card flex justify-content-center">
                                    <p-dropdown formControlName="gender" [options]="genderArray" optionLabel="name"
                                        optionValue="name" [filter]="true" filterBy="name"
                                        placeholder="Select Baby's Gender" [resetFilterOnHide]="true"
                                        [class.is-invalid]="(assessment.submitted &&
                            initialAssessmentForm.get('gender')?.invalid)
                            ||
                            initialAssessmentForm.get('gender')?.invalid
                            &&
                            initialAssessmentForm.get('gender').touched">
                                    </p-dropdown>
                                    <div class="invalid-feedback">
                                        Gender is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="p-0">Date of birth<span class="required">*</span></label>
                                <div class="card flex justify-content-center">
                                    <p-calendar formControlName="date_of_birth" dateFormat="dd/mm/yy" [showIcon]="true"
                                                (onSelect)="onDateSelect($event)" placeholder="DD/MM/YYYY"
                                                [readonlyInput]="true" [class.is-invalid]="(assessment.submitted &&
                                    initialAssessmentForm.get('date_of_birth')?.invalid) ||
                                    initialAssessmentForm.get('date_of_birth')?.invalid &&
                                    initialAssessmentForm.get('date_of_birth').touched" [maxDate]="maxDate"></p-calendar>
                                    <div class="invalid-feedback">
                                        Date of birth is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="p-0">Days</label>
                                <input type="text" formControlName="days" class="form-control" placeholder="Days" readonly />
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="p-0">Months</label>
                                <input type="text" formControlName="months" class="form-control" placeholder="Months" readonly />
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="p-0">Years </label>
                                <input type="text" formControlName="years" class="form-control" placeholder="Years" readonly />
                            </div>
                        </div>
                    </div>

                    <!-- <div class="row"> -->
                    <div class="row">
                        <div class="form-group">
                            <label class="p-0">Mode of Delivery<span class="required">*</span></label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="normal" formControlName="mode_delivery" value="normal"
                                        name="mode_delivery" />
                                    <label for="normal">Normal</label>
                                </p>
                                <p>
                                    <input type="radio" id="caesarian" formControlName="mode_delivery" value="caesarian"
                                        name="mode_delivery" />
                                    <label for="caesarian">Caesarian</label>
                                </p>
                            </div>
                            <div *ngIf="assessment.submitted &&
                                f.mode_delivery.errors?.required ||
                                f.mode_delivery.touched" class="alert
                                alert-danger">
                                <div *ngIf="f.mode_delivery.errors?.required">
                                    Field is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="p-0">Delivery Complications<span class="required">*</span></label>
                                <div class="d-flex">
                                    <p class="me-3">
                                        <input type="radio" formControlName="delivery_complications" id="yes"
                                            name="delivery_complications" value="Yes"
                                            (change)="showInputOnSelection($event, 'delivery')" />
                                        <label for="yes">Yes</label>
                                        <input *ngIf="showReasonInput" formControlName="reason" class="form-control"
                                            type="text" placeholder="Reason">

                                    </p>
                                    <p class="d-flex">
                                        <input type="radio" id="no" formControlName="delivery_complications"
                                            name="delivery_complications" value="No"
                                            (change)="showInputOnSelection($event, 'delivery')" />
                                        <label for="no">No</label>
                                    </p>
                                </div>
                                <div *ngIf="assessment.submitted &&
                                f.delivery_complications.errors?.required ||
                                f.delivery_complications.touched" class="alert
                                alert-danger">
                                    <div *ngIf="f.delivery_complications.errors?.required">
                                        Field is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label class="p-0">Reason:<span class="required">*</span> </label>
                                <textarea formControlName="reason" rows="2" class="form-control p-2" type="text"
                                    placeholder="Reason" [class.is-invalid]="(assessment.submitted &&
                            initialAssessmentForm.get('reason')?.invalid)
                            ||
                            initialAssessmentForm.get('reason')?.invalid
                            &&
                            initialAssessmentForm.get('reason').touched" required>
                    </textarea>
                                <div class="invalid-feedback">
                                    Reason is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="p-0">Health Issues<span class="required">*</span></label>
                                <div class="d-flex">
                                    <p class="me-3">
                                        <input type="radio" id="health-yes" formControlName="health_issues"
                                            name="health_issues" value="Yes"
                                            (change)="showInputOnSelection($event, 'health')" />
                                        <label for="health-yes">Yes</label>
                                    </p>
                                    <p class="d-flex">
                                        <input type="radio" id="health-no" formControlName="health_issues"
                                            name="health_issues" value="No"
                                            (change)="showInputOnSelection($event, 'health')" />
                                        <label for="health-no">No</label>

                                    </p>
                                </div>
                                <div *ngIf="assessment.submitted &&
                        f.health_issues.errors?.required ||
                        f.health_issues.touched" class="alert
                        alert-danger">
                                    <div *ngIf="f.health_issues.errors?.required">
                                        Field is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label class="p-0">Identify:<span class="required">*</span> </label>
                                <textarea formControlName="health_identify" rows="2" class="form-control p-2"
                                    type="text" placeholder="Identify" [class.is-invalid]="(assessment.submitted &&
                            initialAssessmentForm.get('health_identify')?.invalid)
                            ||
                            initialAssessmentForm.get('health_identify')?.invalid
                            &&
                            initialAssessmentForm.get('health_identify').touched" required>
                    </textarea>
                                <div class="invalid-feedback">
                                    Reason is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="p-0">Any special needs?<span class="required">*</span></label>
                                <div class="d-flex">
                                    <p class="d-flex me-3">
                                        <input type="radio" id="needs-yes" formControlName="any_speical_needs"
                                            name="any_speical_needs" value="Yes"
                                            (change)="showInputOnSelection($event, 'need')">
                                        <label for="needs-yes">Yes</label>
                                    </p>
                                    <p class="d-flex">
                                        <input type="radio" id="needs-no" formControlName="any_speical_needs"
                                            name="any_speical_needs" value="No"
                                            (change)="showInputOnSelection($event, 'need')">
                                        <label for="needs-no">No</label>
                                    </p>

                                </div>
                                <!-- <div class="invalid-feedback" *ngIf="(assessment.submitted &&
                        initialAssessmentForm.get('any_speical_needs')?.invalid)
                        ||
                        initialAssessmentForm.get('any_speical_needs')?.invalid
                        &&
                        initialAssessmentForm.get('any_speical_needs').touched">
                            Field is required
                        </div> -->

                                <div *ngIf="assessment.submitted &&
                        f.any_speical_needs.errors?.required ||
                        f.any_speical_needs.touched" class="alert
                        alert-danger">
                                    <div *ngIf="f.any_speical_needs.errors?.required">
                                        Field is required
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label class="p-0">Identify: <span class="required">*</span></label>
                                <textarea formControlName="speical_identify" rows="2" class="form-control p-2"
                                    type="text" placeholder="Identify" [class.is-invalid]="(assessment.submitted &&
                            initialAssessmentForm.get('speical_identify')?.invalid)
                            ||
                            initialAssessmentForm.get('speical_identify')?.invalid
                            &&
                            initialAssessmentForm.get('speical_identify').touched" required>
                        </textarea>
                                <div class="invalid-feedback">
                                    Field is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="add-listings-btn" style="text-align: right;" *ngIf="isedit">
                        <div class="row">
                            <div class="col-lg col-12">
                                <div class="form-group text-start">
                                    <label class="p-0" style="text-align-last: left;">Created by</label>
                                    <span>{{createdBy}}</span>

                                    <!-- <input type="text" formControlName="createdby" class="form-control"
                                        placeholder="Created by" readonly /> -->
                                </div>
                            </div>
                            <div class="col-lg col-12">
                                <div class="form-group text-start">
                                    <label class="p-0" style="text-align-last: left;">Created at</label>
                                    <span>{{updatedAt?.substring(0, 16).replace('T', ' ') }}</span>

                                    <!-- <input type="text" formControlName="createdat" class="form-control"
                                        placeholder="Updated by" readonly /> -->
                                </div>
                            </div>

                            <div class="col-lg col-12">
                                <div class="form-group text-start">
                                    <label class="p-0" style="text-align-last: left;">Updated by</label>
                                    <span>{{updatedBy}}</span>

                                    <!-- <input type="text" formControlName="updateddat" class="form-control"
                                        placeholder="Created by" readonly /> -->
                                </div>
                            </div>
                            <div class="col-lg col-12 ">
                                <div class="form-group text-start">
                                    <label class="p-0" style="text-align-last: left;">Updated at</label>
                                    <span>{{updatedAt?.substring(0, 16).replace('T', ' ') }}</span>

                                    <!-- <input type="text" formControlName="updateddby" class="form-control"
                                        placeholder="Updated by" readonly /> -->
                                </div>
                            </div>
                            <div class="col-auto align-self-end">
                                <div class="form-group">
                                    <p-button (click)="onclick()" class="secondary-btn"
                                        *ngIf="checkPermission(7)">Edit</p-button>
                                    <button type="submit" class="ms-2" *ngIf="checkPermission(5)">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- </div> -->
                </div>
            </p-accordionTab>

            <!-- MOTHER DETAILS SECTION -->


            <p-accordionTab header="Family Details">
                <p-accordion>
                    <p-accordionTab header="Mother Details">
                        <div class="add-listings-box">
                            <h3>Mother Details</h3>
                            <div class="row">
                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label class="p-0">Name<span class="required">*</span></label>
                                        <input type="text" formControlName="mother_name" name="name"
                                            class="form-control" placeholder="Name" [class.is-invalid]="(assessment.submitted &&
                            initialAssessmentForm.get('mother_name')?.invalid)
                            ||
                            initialAssessmentForm.get('mother_name')?.invalid
                            &&
                            initialAssessmentForm.get('mother_name').touched" />
                                        <div class="invalid-feedback">
                                            Mother name is required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label class="p-0">Date of Birth<span class="required">*</span> </label>
                                        <div class="card flex justify-content-center">
                                            <p-calendar placeholder="DD/MM/YYYY" formControlName="mother_dob"
                                                dateFormat="dd/mm/yy" (onSelect)="calculateAgeAndYears('mother')"
                                                [showIcon]="true" [class.is-invalid]="(assessment.submitted &&
                                initialAssessmentForm.get('mother_dob')?.invalid)
                                ||
                                initialAssessmentForm.get('mother_dob')?.invalid
                                &&
                                initialAssessmentForm.get('mother_dob').touched" [maxDate]="maxDate"></p-calendar>
                                            <div class="invalid-feedback">
                                                Mother date of birth is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label class="p-0">Age<span class="required">*</span></label>
                                        <div class="card flex justify-content-center">
                                            <input type="text" formControlName="mother_age" name="name"
                                                class="form-control" placeholder="Age" [class.is-invalid]="(assessment.submitted &&
                            initialAssessmentForm.get('mother_age')?.invalid)
                            ||
                            initialAssessmentForm.get('mother_age')?.invalid
                            &&
                            initialAssessmentForm.get('mother_age').touched" readonly />
                                            <!-- <p-dropdown [options]="ageList" optionValue="age" formControlName="mother_age"
                                optionLabel="age" [filter]="true" filterBy="age" [showClear]="false"
                                placeholder="Select a Age" [resetFilterOnHide]="true" [class.is-invalid]="(assessment.submitted &&
                                initialAssessmentForm.get('mother_age')?.invalid)
                                ||
                                initialAssessmentForm.get('mother_age')?.invalid
                                &&
                                initialAssessmentForm.get('mother_age').touched">
                                <ng-template pTemplate="selectedItem">
                                    <div class="flex align-items-center gap-2"
                                        *ngIf="initialAssessmentForm.get('mother_age')?.value">
                                        <div>{{ initialAssessmentForm.get('mother_age')?.value }}</div>
                                    </div>
                                </ng-template>
                                <ng-template let-age pTemplate="item">
                                    <div class="flex align-items-center gap-2">
                                        <div>{{ age.age }}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown> -->
                                            <div class="invalid-feedback">
                                                Mother age is required
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label class="px-0">Contact Number<span class="required">*</span> </label>
                                        <input formControlName="mother_mobile" type="text" maxlength="10"
                                            class="form-control" placeholder="Contact Number"
                                            (keypress)="keyPress($event)" [class.is-invalid]="(assessment.submitted &&
                            initialAssessmentForm.get('mother_mobile')?.invalid)
                            ||
                            initialAssessmentForm.get('mother_mobile')?.invalid
                            &&
                            initialAssessmentForm.get('mother_mobile').touched" appNumericInput />
                                        <div class="invalid-feedback">
                                            Contact Number is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label class="px-0">Email<span class="required">*</span> </label>
                                        <input formControlName="mother_email" type="email" name="mother_email" ngModel
                                            email="true" maxlength="50" class="form-control" placeholder="Email"
                                            [class.is-invalid]="(assessment.submitted &&
                            initialAssessmentForm.get('mother_email')?.invalid)
                            ||
                            initialAssessmentForm.get('mother_email')?.invalid
                            &&
                            initialAssessmentForm.get('mother_email').touched" />
                                        <div class="invalid-feedback">
                                            Enter valid email address.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-sm-6 col-12">
                                    <label class="px-0 fw500">Working Mother?<span class="required">*</span></label>
                                    <div class="form-group">
                                        <div class="d-flex">
                                            <p class="me-3">
                                                <input type="radio" id="working-yes" formControlName="mother_working"
                                                    name="mother_working" value="Yes"
                                                    (change)="showInputOnSelection($event, 'working_mother')" />
                                                <label for="working-yes">Yes</label>

                                            </p>
                                            <p class="d-flex">
                                                <input type="radio" id="working-no" formControlName="mother_working"
                                                    name="mother_working" value="No"
                                                    (change)="showInputOnSelection($event, 'working_mother')" />
                                                <label for="working-no">No</label>
                                            </p>
                                        </div>
                                        <div *ngIf="assessment.submitted &&
                        f.mother_working.errors?.required ||
                        f.mother_working.touched" class="alert
                        alert-danger">
                                            <div *ngIf="f.mother_working.errors?.required">
                                                Field is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6 col-12 flex justify-content-center form-group">
                                    <label class="px-0">Hours<span class="required">*</span></label>
                                    <p-dropdown [options]="workingHours" formControlName="mother_work_hours"
                                        optionLabel="hours" [filter]="true" filterBy="hours" [showClear]="false"
                                        optionValue="hours" [disabled]="workingHoursDropdown" placeholder="Select hours"
                                        [resetFilterOnHide]="true" [class.is-invalid]="(assessment.submitted &&
                        initialAssessmentForm.get('mother_work_hours')?.invalid)
                        ||
                        initialAssessmentForm.get('mother_work_hours')?.invalid
                        &&
                        initialAssessmentForm.get('mother_work_hours').touched">
                                        <ng-template pTemplate="selectedItem">
                                            <div class="flex align-items-center gap-2"
                                                *ngIf="initialAssessmentForm.get('mother_work_hours')?.value">
                                                <div>{{ initialAssessmentForm.get('mother_work_hours')?.value }}</div>
                                            </div>
                                        </ng-template>
                                        <ng-template let-hours pTemplate="item">
                                            <div class="flex align-items-center gap-2">
                                                <div>{{ hours.hours }}</div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                    <div class="invalid-feedback">
                                        Working hour is required
                                    </div>
                                </div>

                                <div class="col-lg-4 col-sm-6 col-12 form-group">
                                    <label for="">Mother Para</label>
                                    <div class="d-flex">
                                        <p class="me-3">
                                            <input type="radio" id="primipara" formControlName="mother_para"
                                                value="primipara" />
                                            <label for="primipara">Primipara</label>
                                        </p>
                                        <p class="d-flex">
                                            <input type="radio" id="multipara" formControlName="mother_para"
                                                value="multipara" />
                                            <label for="multipara">Multi Para</label>
                                        </p>
                                    </div>
                                </div>
                                <div class="add-listings-btn" style="text-align: right;" *ngIf="isedit">
                                    <div class="row">
                                        <div class="col-lg col-12">
                                            <div class="form-group text-start">
                                                <label class="p-0" style="text-align-last: left;">Created by:</label>
                                                <span>{{createdBy}}</span>

                                                <!-- <input type="text" formControlName="createdby" class="form-control"
                                                    placeholder="Created by" readonly /> -->
                                            </div>
                                        </div>
                                        <div class="col-lg col-12">
                                            <div class="form-group text-start">
                                                <label class="p-0" style="text-align-last: left;">Created at:</label>
                                                <span>{{createdAt?.substring(0, 16).replace('T', ' ') }}</span>

                                                <!-- <input type="text" formControlName="createdat" class="form-control"
                                                    placeholder="Updated by" readonly /> -->
                                            </div>
                                        </div>

                                        <div class="col-lg col-12">
                                            <div class="form-group text-start">
                                                <label class="p-0" style="text-align-last: left;">Updated by:</label>
                                                <span>{{updatedBy}}</span>

                                                <!-- <input type="text" formControlName="updateddat" class="form-control"
                                                    placeholder="Created by" readonly /> -->
                                            </div>
                                        </div>
                                        <div class="col-lg col-12 ">
                                            <div class="form-group text-start">
                                                <label class="p-0" style="text-align-last: left;">Updated at:</label>
                                                <span>{{updatedAt?.substring(0, 16).replace('T', ' ') }}</span>

                                                <!-- <input type="text" formControlName="updateddby" class="form-control"
                                                    placeholder="Updated by" readonly /> -->
                                            </div>
                                        </div>
                                        <div class="col-auto align-self-end">
                                            <div class="form-group">
                                                <p-button (click)="mother()" class="secondary-btn"
                                                    *ngIf="checkPermission(7)">Edit</p-button>
                                                <button type="submit" class="ms-2"
                                                    *ngIf="checkPermission(5)">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-accordionTab>

                    <!-- FATHER DETAILS SECTION -->

                    <p-accordionTab header="Father Details">
                        <div class="add-listings-box">
                            <h3>Father Details</h3>
                            <div class="row">
                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label class="px-0">Name<span class="required">*</span></label>
                                        <input type="text" formArrayName="father_name" formControlName="father_name"
                                            name="name" class="form-control" placeholder="Name" [class.is-invalid]="(assessment.submitted &&
                            initialAssessmentForm.get('father_name')?.invalid)
                            ||
                            initialAssessmentForm.get('father_name')?.invalid
                            &&
                            initialAssessmentForm.get('father_name').touched" />
                                        <div class="invalid-feedback">
                                            Father name is required
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label class="px-0">Date of Birth<span class="required">*</span></label>
                                        <div class="card flex justify-content-center">
                                            <p-calendar placeholder="DD/MM/YYYY" formControlName="father_dateofbirth"
                                                dateFormat="dd/mm/yy" [showIcon]="true"
                                                (onSelect)="calculateAgeAndYears('father')" [class.is-invalid]="(assessment.submitted &&
                                initialAssessmentForm.get('father_dateofbirth')?.invalid)
                                ||
                                initialAssessmentForm.get('father_dateofbirth')?.invalid
                                &&
                                initialAssessmentForm.get('father_dateofbirth').touched"
                                                [maxDate]="maxDate"></p-calendar>
                                            <div class="invalid-feedback">
                                                Father date of birth is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label class="px-0">Age<span class="required">*</span></label>
                                        <div class="card flex justify-content-center">
                                            <input type="text" formControlName="father_age" name="name"
                                                class="form-control" placeholder="Age" [class.is-invalid]="(assessment.submitted &&
                            initialAssessmentForm.get('father_age')?.invalid)
                            ||
                            initialAssessmentForm.get('father_age')?.invalid
                            &&
                            initialAssessmentForm.get('father_age').touched" readonly />
                                            <!-- <p-dropdown [options]="ageList" formControlName="father_age" optionLabel="age"
                                optionValue="age" [filter]="true" filterBy="age" [showClear]="false"
                                placeholder="Select a Age" [resetFilterOnHide]="true" [class.is-invalid]="(assessment.submitted &&
                                initialAssessmentForm.get('father_age')?.invalid)
                                ||
                                initialAssessmentForm.get('father_age')?.invalid
                                &&
                                initialAssessmentForm.get('father_age').touched">
                                <ng-template pTemplate="selectedItem">
                                    <div class="flex align-items-center gap-2"
                                        *ngIf="initialAssessmentForm.controls['father_age'].value">
                                        <div>{{ initialAssessmentForm.controls['father_age'].value }}</div>
                                    </div>
                                </ng-template>
                                <ng-template let-age pTemplate="item">
                                    <div class="flex align-items-center gap-2">
                                        <div>{{ age.age }}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown> -->
                                            <div class="invalid-feedback">
                                                Father age is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label class="px-0">Working Hours<span class="required">*</span> </label>
                                        <div class="card flex justify-content-center">
                                            <p-dropdown [options]="workingHours" formControlName="father_work_hours"
                                                optionLabel="hours" optionValue="hours" [filter]="true" filterBy="hours"
                                                [showClear]="false" placeholder="Select hours"
                                                [resetFilterOnHide]="true" [class.is-invalid]="(assessment.submitted &&
                                initialAssessmentForm.get('father_work_hours')?.invalid)
                                ||
                                initialAssessmentForm.get('father_work_hours')?.invalid
                                &&
                                initialAssessmentForm.get('father_work_hours').touched">
                                                <ng-template pTemplate="selectedItem">
                                                    <div class="flex align-items-center gap-2"
                                                        *ngIf="initialAssessmentForm.get('father_work_hours')?.value">
                                                        <div>{{ initialAssessmentForm.get('father_work_hours')?.value }}
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template let-hours pTemplate="item">
                                                    <div class="flex align-items-center gap-2">
                                                        <div>{{ hours.hours }}</div>
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                            <div class="invalid-feedback">
                                                Working hours is required
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label class="px-0">Contact Number<span class="required">*</span> </label>
                                        <input formControlName="mobile" type="text" maxlength="10" class="form-control"
                                            placeholder="Contact Number" (keypress)="keyPress($event)"
                                            [class.is-invalid]="(assessment.submitted &&
                            initialAssessmentForm.get('mobile')?.invalid)
                            ||
                            initialAssessmentForm.get('mobile')?.invalid
                            &&
                            initialAssessmentForm.get('mobile').touched" appNumericInput />
                                        <div class="invalid-feedback">
                                            Contact Number is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label class="px-0">Email<span class="required">*</span> </label>
                                        <input formControlName="email" type="email" name="email" ngModel email="true"
                                            maxlength="50" class="form-control" placeholder="Email" [class.is-invalid]="(assessment.submitted &&
                            initialAssessmentForm.get('email')?.invalid)
                            ||
                            initialAssessmentForm.get('email')?.invalid
                            &&
                            initialAssessmentForm.get('email').touched" />
                                        <div class="invalid-feedback">
                                            Enter valid email address.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="add-listings-btn" style="text-align: right;" *ngIf="isedit">
                                <div class="row">
                                    <div class="col-lg col-12">
                                        <div class="form-group text-start">
                                            <label class="p-0" style="text-align-last: left;">Created by:</label>
                                            <span>{{createdBy}}</span>

                                            <!-- <input type="text" formControlName="createdby" class="form-control"
                                                placeholder="Created by" readonly /> -->
                                        </div>
                                    </div>
                                    <div class="col-lg col-12">
                                        <div class="form-group text-start">
                                            <label class="p-0" style="text-align-last: left;">Created at:</label>
                                            <span>{{createdAt?.substring(0, 16).replace('T', ' ') }}</span>

                                            <!-- <input type="text" formControlName="createdat" class="form-control"
                                                placeholder="Updated by" readonly /> -->
                                        </div>
                                    </div>

                                    <div class="col-lg col-12">
                                        <div class="form-group text-start">
                                            <label class="p-0" style="text-align-last: left;">Updated by:</label>
                                            <span>{{updatedBy}}</span>

                                            <!-- <input type="text" formControlName="updateddat" class="form-control"
                                                placeholder="Created by" readonly /> -->
                                        </div>
                                    </div>
                                    <div class="col-lg col-12 ">
                                        <div class="form-group text-start">
                                            <label class="p-0" style="text-align-last: left;">Updated at:</label>
                                            <span>{{updatedAt?.substring(0, 16).replace('T', ' ') }}</span>

                                            <!-- <input type="text" formControlName="updateddby" class="form-control"
                                                placeholder="Updated by" readonly /> -->
                                        </div>
                                    </div>
                                    <div class="col-auto align-self-end">
                                        <div class="form-group">
                                            <p-button (click)="father()" class="secondary-btn"
                                                *ngIf="checkPermission(7)">Edit</p-button>
                                            <button type="submit" class="ms-2"
                                                *ngIf="checkPermission(5)">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </p-accordionTab>


                    <p-accordionTab header="Sibling Details">
                        <!-- SIBLINGS DETAILS SECTION -->
                        <div class="add-listings-box">
                            <h3>Sibling Details</h3>
                            <div formArrayName="sibling_details_json">
                                <ng-container *ngFor="let control of
                    getsiblingarray().controls; index as i; last as last">
                                    <div class="row" [formGroupName]="i">
                                        <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Name</label>
                                                <input formControlName="name" type="text" class="form-control"
                                                    placeholder="Name" />
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Date of Birth </label>
                                                <div class="card flex justify-content-center">
                                                    <p-calendar formControlName="dob" placeholder="DD/MM/YYYY"
                                                        dateFormat="dd/mm/yy" [showIcon]="true"
                                                        (onSelect)="calculateAgeAndYears('sibling',i)"
                                                        [class.is-invalid]="(assessment.submitted &&
                                        initialAssessmentForm.get('dob')?.invalid)
                                        ||
                                        initialAssessmentForm.get('dob')?.invalid
                                        &&
                                        initialAssessmentForm.get('dob').touched" [maxDate]="maxDate"></p-calendar>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Age</label>
                                                <div class="card flex justify-content-center">
                                                    <input type="text" formControlName="age" name="name"
                                                        class="form-control" placeholder="Age" [class.is-invalid]="(assessment.submitted &&
                                                        initialAssessmentForm.get('age')?.invalid)
                                                        ||
                                                        initialAssessmentForm.get('age')?.invalid
                                                        &&
                                                        initialAssessmentForm.get('age').touched" readonly />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="px-0">Special Needs</label>
                                                <div class="d-flex">
                                                    <p class="me-3">
                                                        <input type="radio" formControlName="needs"
                                                            id="{{'needyes' + i}}" value="Yes"
                                                            (change)="setSpecialNeed($event , i)">
                                                        <label for="{{'needyes' + i}}">Yes</label>

                                                    </p>
                                                    <p class="d-flex">
                                                        <input type="radio" formControlName="needs"
                                                            id="{{'needno' + i}}" value="No"
                                                            (change)="setSpecialNeed($event, i )">
                                                        <label for="{{'needno' + i}}">No</label>
                                                        <!-- <input formControlName="siblings_specify" *ngIf="showSiblingNeddsInput"
                                        class="form-control" type="text" placeholder="Reason"> -->
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg col-sm-6 col-12">
                                            <div class="form-group">
                                                <label class="p-0">Specify: </label>
                                                <textarea formControlName="special_needs_identify" rows="3"
                                                    class="form-control" type="text" placeholder="Specify">
                                    </textarea>
                                            </div>
                                        </div>

                                        <div
                                            class="col-lg-auto col-sm-6 col-12 form-group d-flex  justify-content-end align-items-end mt-1">
                                            <p-button type="button" icon="bx bx-plus" (click)="onAddRow('add', last)"
                                                iconPos="right" class="secondary-btn p-0"></p-button>
                                            <div class="remove">
                                                <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                    icon="bx bx-minus" (click)="onAddRow('delete', i)"
                                                    iconPos="right"></p-button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="add-listings-btn" style="text-align: right;" *ngIf="isedit">
                                <div class="row">
                                    <div class="col-lg col-12">
                                        <div class="form-group text-start">
                                            <label class="p-0" style="text-align-last: left;">Created by:</label>
                                            <span>{{createdBy}}</span>

                                            <!-- <input type="text" formControlName="createdby" class="form-control"
                                                placeholder="Created by" readonly /> -->
                                        </div>
                                    </div>
                                    <div class="col-lg col-12">
                                        <div class="form-group text-start">
                                            <label class="p-0" style="text-align-last: left;">Created at:</label>
                                            <span>{{createdAt?.substring(0, 16).replace('T', ' ') }}</span>

                                            <!-- <input type="text" formControlName="createdat" class="form-control"
                                                placeholder="Updated by" readonly /> -->
                                        </div>
                                    </div>

                                    <div class="col-lg col-12">
                                        <div class="form-group text-start">
                                            <label class="p-0" style="text-align-last: left;">Updated by:</label>
                                            <span>{{updatedBy}}</span>

                                            <!-- <input type="text" formControlName="updateddat" class="form-control"
                                                placeholder="Created by" readonly /> -->
                                        </div>
                                    </div>
                                    <div class="col-lg col-12 ">
                                        <div class="form-group text-start">
                                            <label class="p-0" style="text-align-last: left;">Updated at:</label>
                                            <span>{{updatedAt?.substring(0, 16).replace('T', ' ') }}</span>

                                            <!-- <input type="text" formControlName="updateddby" class="form-control"
                                                placeholder="Updated by" readonly /> -->
                                        </div>
                                    </div>
                                    <div class="col-auto align-self-end">
                                        <div class="form-group">
                                            <p-button (click)="sibling()" class="secondary-btn"
                                                *ngIf="checkPermission(7)">Edit</p-button>
                                            <button type="submit" class="ms-2"
                                                *ngIf="checkPermission(5)">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </p-accordionTab>
                </p-accordion>
            </p-accordionTab>



            <!-- SOCIAL ENVIRONMENT DETAILS SECTION -->

            <p-accordionTab header="Location Details">
                <div class="add-listings-box">
                    <h3>Type of House</h3>
                    <div class="row mb-2 align-items-center">
                        <!-- <div class="form-group mb-0">
                            <label class="mb-sm-0 mb-2">Type of House</label>
                        </div> -->
                        <div class="col-lg-4 col-sm-6 col-12 d-flex flex-column">
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="flat" value="flat" name="type_of_house"
                                        (change)="showInputOnSelection($event, 'houseType')"
                                        formControlName="type_of_house">
                                    <label for="flat" class="m-sm-0">Flat</label>

                                </p>

                                <!-- </div> -->
                                <!-- <div class="col-lg-4 col-sm-6 col-12"> -->
                                <p>
                                    <input type="radio" id="villa" value="villa" name="type_of_house"
                                        (change)="showInputOnSelection($event, 'houseType')"
                                        formControlName="type_of_house">
                                    <label for="villa" class="m-sm-0">Villa</label>
                                </p>
                            </div>
                        </div>


                        <!-- <div class="col-lg-4 col-sm-6 col-12">
                            <div class="form-group">
                                <input formControlName="house_no" class="form-control" type="text" placeholder="Villa No"
                                    value="villa" [class.is-invalid]="(assessment.submitted &&
                                    initialAssessmentForm.get('house_no')?.invalid)
                                    ||
                                    initialAssessmentForm.get('house_no')?.invalid
                                    &&
                                    initialAssessmentForm.get('house_no').touched" [disabled]="villaNo"/>
                                <div class="invalid-feedback">
                                    Vill no is required
                                </div>
                            </div>
                        </div> -->
                        <!-- </div> -->
                        <!-- <div class="row mb-2"> -->

                        <div class="col-lg-4 col-sm-6 col-12">
                            <label class="mb-2">{{ villaNo ? 'Villa No' : 'Flat No' }}</label>
                            <div class="form-group">
                                <input formControlName="house_no" class="form-control" type="text"
                                    placeholder="{{ villaNo ? 'Villa No' : 'Flat No' }}" value="flat" />
                                <!-- <div class="invalid-feedback">
                                    {{ villaNo ? 'Villa No' : 'Flat No' }} no is required
                                </div> -->
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                            <label class="mb-2">Address of {{ villaNo ? 'Villa ' : 'Flat ' }}</label>
                            <div class="form-group">
                                <input formControlName="house_address" class="form-control" type="text"
                                    placeholder="Address of {{ villaNo ? 'Villa' : 'Flat ' }}" value="flat" />
                                <!-- <div class="invalid-feedback">
                                    {{ villaNo ? 'Villa No' : 'Flat No' }} no is required
                                </div> -->
                            </div>
                        </div>
                        <!-- <div class="invalid-feedback col-12"
                            *ngIf="(assessment.submitted && initialAssessmentForm.get('type_of_house').invalid) || (initialAssessmentForm.get('type_of_house').invalid && initialAssessmentForm.get('type_of_house').touched)">
                            House Type is required
                        </div> -->
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="px-0">Google Map Location<span class="required">*</span> </label>
                                <input type="text" class="form-control" formControlName="house_location"
                                    placeholder="Enter Your Location" (keydown.enter)="save($event)" [class.is-invalid]="(assessment.submitted &&
                                    initialAssessmentForm.get('house_location')?.invalid)
                                    ||
                                    initialAssessmentForm.get('house_location')?.invalid
                                    &&
                                    initialAssessmentForm.get('house_location').touched" />
                                <div class="invalid-feedback">
                                    Enter valid house Url
                                </div>
                                <!-- <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289316"
                                    width="100%" height="200" frameborder="0" style="border:0"></iframe> -->
                                <!-- <div class="mapouter">
                                    <div class="gmap_canvas"><iframe width="770" height="510" id="gmap_canvas"
                                            src="https://maps.google.com/maps?q=dubai&t=&z=10&ie=UTF8&iwloc=&output=embed"
                                            frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a
                                            href="https://2yu.co">2yu</a><br>
                                        <style>
                                            .mapouter {
                                                position: relative;
                                                text-align: right;
                                                height: 200px;
                                                width: 100%;
                                            }
                                        </style><a href="https://embedgooglemap.2yu.co">html embed google map</a>
                                        <style>
                                            .gmap_canvas {
                                                overflow: hidden;
                                                background: none !important;
                                                height: 200px;
                                                width: 100%;
                                            }
                                        </style>
                                    </div>
                                </div> -->
                                <!-- <div class="mapouter">
                                    <div class="gmap_canvas">
                                        <iframe width="600" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                                        </iframe>
                                        <a href="https://123movies-i.net"></a><br>
                                        <a href="https://www.embedgooglemap.net">embedgooglemap.net
                                        </a>
                                    </div>
                                </div> -->

                            </div>
                        </div>
                    </div>

                    <div class="add-listings-btn" style="text-align: right;" *ngIf="isedit">
                        <div class="row">
                            <div class="col-lg col-12">
                                <div class="form-group text-start">
                                    <label class="p-0" style="text-align-last: left;">Created by:</label>
                                    <span>{{createdBy}}</span>

                                    <!-- <input type="text" formControlName="createdby" class="form-control"
                                        placeholder="Created by" readonly /> -->
                                </div>
                            </div>
                            <div class="col-lg col-12">
                                <div class="form-group text-start">
                                    <label class="p-0" style="text-align-last: left;">Created at:</label>
                                    <!-- <input type="text" formControlName="createdat" class="form-control"
                                        placeholder="Updated by" readonly /> -->
                                    <span>{{createdAt?.substring(0, 16).replace('T', ' ') }}</span>

                                </div>
                            </div>

                            <div class="col-lg col-12">
                                <div class="form-group text-start">
                                    <label class="p-0" style="text-align-last: left;">Updated by:</label>
                                    <!-- <input type="text" formControlName="updateddat" class="form-control"
                                        placeholder="Created by" readonly /> -->
                                    <span>{{updatedBy}}</span>

                                </div>
                            </div>
                            <div class="col-lg col-12 ">
                                <div class="form-group text-start">
                                    <label class="p-0" style="text-align-last: left;">Updated at:</label>
                                    <!-- <input type="text" formControlName="updateddby" class="form-control"
                                        placeholder="Updated by" readonly /> -->
                                    <span>{{updatedAt?.substring(0, 16).replace('T', ' ') }}</span>

                                </div>
                            </div>
                            <div class="col-auto align-self-end">
                                <div class="form-group">
                                    <p-button (click)="location()" class="secondary-btn"
                                        *ngIf="checkPermission(7)">Edit</p-button>
                                    <button type="submit" class="ms-2" *ngIf="checkPermission(5)">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="add-listings-btn" style="text-align: right;" *ngIf="isedit">
                        <p-button (click)="location()">Edit</p-button>
                        <button type="submit" class="ms-2">Submit </button>
                    </div> -->
                </div>
            </p-accordionTab>

            <p-accordionTab header="Social Environment Details">
                <div class="add-listings-box">
                    <h3>Social Environment Details</h3>
                    <div class="row">
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="p-0">Relatives in the House
                                </label>
                                <div class="d-flex">
                                    <p class="me-3">
                                        <input type="radio" id="relative-yes" name="relatives_house"
                                            formControlName="relatives_house" value="Yes"
                                            (change)="showInputOnSelection($event, 'relatives')">
                                        <label for="relative-yes">Yes</label>

                                    </p>
                                    <p class="d-flex">
                                        <input type="radio" id="relatvie-no" name="relatives_house"
                                            formControlName="relatives_house" value="No"
                                            (change)="showInputOnSelection($event, 'relatives')">
                                        <label for="relatvie-no">No</label>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8 col-sm-6 col-12">
                            <ng-container *ngIf="relative">
                                <div formArrayName="relatives_name_json" class="row">
                                    <ng-container *ngFor="let control of
                                            getrelativearray().controls; index as i; last as last">
                                        <div class="col-lg-6 col-12" [formGroupName]="i">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label class="px-0">Name</label>
                                                    <input class="form-control" type="text" placeholder="Name"
                                                        formControlName="name" />
                                                </div>
                                                <!-- <div class="required col-12"
                                            *ngIf="initialAssessmentForm.controls[relatives_house]">
                                            Name is required
                                        </div> -->
                                            </div>
                                        </div>
                                        <div
                                            class="col-lg-6 col-12 form-group d-flex mt-1 d-flex justify-content-end align-items-center">
                                            <p-button type="button" icon="bx bx-plus"
                                                (click)="addRelativeNamesRow('add', last)" iconPos="right"
                                                class="mr-2 d-flex align-items-end"></p-button>
                                            <div class="remove">
                                                <p-button type="button" *ngIf="i!= 0" class="ml-2" icon="bx bx-minus"
                                                    (click)="addRelativeNamesRow('delete', i)"
                                                    iconPos="right"></p-button>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="p-0">Number of Housemaids<span class="required">*</span>
                                </label>
                                <div class="card flex justify-content-center">
                                    <p-dropdown [options]="noOfMaidsList" optionLabel="number" optionValue="number"
                                        [filter]="true" filterBy="number" [showClear]="false"
                                        formControlName="no_of_maids" placeholder="Select a Number"
                                        [resetFilterOnHide]="true" [class.is-invalid]="(assessment.submitted &&
                                initialAssessmentForm.get('no_of_maids')?.invalid)
                                ||
                                initialAssessmentForm.get('no_of_maids')?.invalid
                                &&
                                initialAssessmentForm.get('no_of_maids').touched">
                                        <ng-template pTemplate="selectedItem">
                                            <div class="flex align-items-center gap-2"
                                                *ngIf="initialAssessmentForm.get('no_of_maids')?.value">
                                                <div>{{initialAssessmentForm.get('no_of_maids')?.value}}</div>
                                            </div>
                                        </ng-template>
                                        <ng-template let-number pTemplate="item">
                                            <div class="flex align-items-center gap-2">
                                                <div>{{ number.number }}</div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                    <div class="invalid-feedback">
                                        No of maids is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="p-0">Family Religion<span class="required">*</span>
                                </label>
                                <div class="card flex justify-content-center">
                                    <p-dropdown [options]="religionArray" formControlName="family_religion"
                                        optionLabel="name" [filter]="true" filterBy="name" [showClear]="false"
                                        optionValue="name" placeholder="Select a Family Religion"
                                        [resetFilterOnHide]="true" [class.is-invalid]="(assessment.submitted &&
                                initialAssessmentForm.get('family_religion')?.invalid)
                                ||
                                initialAssessmentForm.get('family_religion')?.invalid
                                &&
                                initialAssessmentForm.get('family_religion').touched">
                                        <ng-template pTemplate="selectedItem">
                                            <div class="flex align-items-center gap-2"
                                                *ngIf="initialAssessmentForm.get('family_religion')?.value">
                                                <div>{{ initialAssessmentForm.get('family_religion')?.value }}</div>
                                            </div>
                                        </ng-template>
                                        <ng-template let-religion pTemplate="item">
                                            <div class="flex align-items-center gap-2">
                                                <div>{{ religion.name }}</div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                    <div class="invalid-feedback">
                                        Family religion is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2 align-items-center">

                    </div>


                    <!-- Reporting issues of child to -->
                    <div class="row">
                        <!-- <label class="mb-3">Reporting Issues of child to:</label> -->
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="px-0">Reporting Issues of child to<span class="required">*</span> </label>
                                <p-dropdown formControlName="report_issue" [options]="reportArray" placeholder="Name"
                                    (onChange)="onDropdownChange($event)" optionLabel="name" [filter]="true"
                                    filterBy="name" [showClear]="false" optionValue="name" [class.is-invalid]="(assessment.submitted &&
    initialAssessmentForm.get('report_issue')?.invalid)
    ||
    initialAssessmentForm.get('report_issue')?.invalid
    &&
    initialAssessmentForm.get('report_issue').touched">
                                </p-dropdown>

                                <div class="invalid-feedback">
                                    Name is required.
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="showAdditionalFields">
                            <div class="col-lg-4 col-sm-6 col-12">
                                <div class="form-group">
                                    <label class="px-0">Name<span class="required">*</span> </label>
                                    <input formControlName="report_name" type="text" class="form-control"
                                        placeholder="Name" [class.is-invalid]="(assessment.submitted &&
                            initialAssessmentForm.get('report_name')?.invalid)
                            ||
                            initialAssessmentForm.get('report_name')?.invalid
                            &&
                            initialAssessmentForm.get('report_name').touched" appNumericInput />
                                    <div class="invalid-feedback">
                                        Name is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-12">
                                <div class="form-group">
                                    <label class="px-0">Contact Number<span class="required">*</span> </label>
                                    <input formControlName="contact_no" type="text" maxlength="10"
                                        class="form-control contact-field" placeholder="Contact Number"
                                        (keypress)="keyPress($event)" [class.is-invalid]="(assessment.submitted &&
                            initialAssessmentForm.get('contact_no')?.invalid)
                            ||
                            initialAssessmentForm.get('contact_no')?.invalid
                            &&
                            initialAssessmentForm.get('contact_no').touched" appNumericInput />
                                    <div class="invalid-feedback">
                                        Contact Number is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-12">
                                <div class="form-group">
                                    <label class="px-0">Upload Photo </label>
                                    <input (change)="uploadPhoto($event)" formControlName="upload_photo" type="file"
                                        accept=".png, .jpg, .jpeg" class="inputfile" placeholder="Name" />
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-lg-8 form-group d-flex mt-1">
                    <p-button type="button" icon="bx bx-plus" (click)="onAddRow('add', last)"
                        iconPos="right" class="mr-2"></p-button>
                    <div class="remove">
                        <p-button type="button"  class="ml-2" icon="bx bx-minus"
                            (click)="onAddRow('delete', i)" iconPos="right"></p-button>
                    </div>
                </div> -->
                    </div>

                    <div class="row">
                        <!-- Mental support for mother -->
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="p-0">Needs mental support for mother?
                                </label>
                                <!-- <div class="d-flex">
                            <input id="categories1" [checked]="true" type="checkbox" value="No" (change)="mentalSupport = true"
                                formControlName="mother_mental_supp" [class.is-invalid]="(assessment.submitted &&
                                    initialAssessmentForm.get(' mother_mental_supp')?.invalid)
                                    ||
                                    initialAssessmentForm.get(' mother_mental_supp')?.invalid
                                    &&
                                    initialAssessmentForm.get(' mother_mental_supp').touched" />
                            <div class="invalid-feedback">
                                Field is required
                            </div>
                            <label for="categories1" class="mx-2 px-0">No</label>
                        </div>
                        <div class="d-flex">
                            <input id="categories1" [checked]="false" type="checkbox" value="Yes" (change)="mentalSupport = false"
                                formControlName="mother_mental_supp" [class.is-invalid]="(assessment.submitted &&
                                    initialAssessmentForm.get(' mother_mental_supp')?.invalid)
                                    ||
                                    initialAssessmentForm.get(' mother_mental_supp')?.invalid
                                    &&
                                    initialAssessmentForm.get(' mother_mental_supp').touched" />
                            <div class="invalid-feedback">
                                Field is required
                            </div>
                            <label for="categories1" class="mx-2 px-0">Yes</label>
                        </div> -->

                                <div class="d-flex">
                                    <p class="me-3">
                                        <input type="radio" id="mother_mental_supp-yes" name="mother_mental_supp"
                                            formControlName="mother_mental_supp" value="Yes"
                                            (change)="showInputOnSelection($event, 'mentalSupport')">
                                        <label for="mother_mental_supp-yes">Yes</label>

                                    </p>
                                    <p class="d-flex">
                                        <input type="radio" id="mother_mental_supp-no" name="mother_mental_supp"
                                            formControlName="mother_mental_supp" value="No"
                                            (change)="showInputOnSelection($event, 'mentalSupport')">
                                        <label for="mother_mental_supp-no">No</label>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label class="p-0">Specify:<span class="required">*</span> </label>
                                <textarea formControlName="mental_supp_specify" rows="3" class="form-control p-2"
                                    type="text" placeholder="Specify" [class.is-invalid]="(assessment.submitted &&
                            initialAssessmentForm.get('mental_supp_specify')?.invalid)
                            ||
                            initialAssessmentForm.get('mental_supp_specify')?.invalid
                            &&
                            initialAssessmentForm.get('mental_supp_specify').touched">
                            </textarea>
                                <div class="invalid-feedback">
                                    Field is required
                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="row">
                        <!-- Appoinment and vaccines -->
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="px-0">Appoinment and Vaccines responsibility:
                                </label>
                                <div class="d-flex">
                                    <!-- <label class="checkbox checkbox-outline p-0"> -->
                                    <input id="categories1" type="checkbox" value="father" name="checkboxes"
                                        formControlName="appointment_vaccine_responsibility"
                                        (change)="showInputOnSelection($event, 'father')"
                                        [checked]="isFatherSelected1" />
                                    <label for="categories" class="mx-2 px-0">Father</label>

                                    <!-- </label> -->
                                    <!-- <label for="categories1" class="mx-2 px-0">Father</label> -->
                                </div>
                                <div class="d-flex">
                                    <!-- <label class="checkbox checkbox-outline p-0"> -->
                                    <input id="categories2" type="checkbox" value="mother" name="checkboxes"
                                        formControlName="appointment_vaccine_responsibility"
                                        (change)="showInputOnSelection($event, 'father')"
                                        [checked]="isMotherSelected1" />
                                    <label for="categories2" class="mx-2 px-0">Mother</label>

                                    <!-- </label> -->
                                    <!-- <label for="categories2" class="mx-2 mx-0 px-0">Mother</label> -->
                                </div>

                                <div class="d-flex">
                                    <!-- <label class="checkbox checkbox-outline p-0"> -->
                                    <input id="categories3" type="checkbox" value="nurse" name="checkboxes"
                                        formControlName="appointment_vaccine_responsibility"
                                        (change)="showInputOnSelection($event, 'father')"
                                        [checked]="isNurseSelected1" />
                                    <label for="categories3" class="mx-2 px-0">Nurse</label>

                                    <!-- </label> -->

                                    <!-- <label for="categories3" class="mx-2 px-0">Nanny</label> -->
                                </div>
                                <!-- <div class="invalid-feedback">
                            Please choose one or more
                        </div> -->
                            </div>
                        </div>

                        <!-- Medication and administration -->
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="px-0">Medication and Administration(if needed):
                                </label>
                                <div class="d-flex">
                                    <input id="categories4" type="checkbox" value="father"
                                        formControlName="medication_admin_responsibility"
                                        (change)="showInputOnSelection($event, 'mother')"
                                        [checked]="isFatherSelected2" />
                                    <label for="categories4" class="mx-2 px-0">Father</label>
                                </div>
                                <div class="d-flex">
                                    <input id="categories5" type="checkbox" value="mother"
                                        formControlName="medication_admin_responsibility"
                                        (change)="showInputOnSelection($event, 'mother')"
                                        [checked]="isMotherSelected2" />
                                    <label for="categories5" class="mx-2 px-0">Mother</label>
                                </div>

                                <div class="d-flex">
                                    <input id="categories6" type="checkbox" value="nurse"
                                        formControlName="medication_admin_responsibility"
                                        (change)="showInputOnSelection($event, 'mother')"
                                        [checked]="isNurseSelected2" />
                                    <label for="categories6" class="mx-2 px-0">Nurse</label>
                                </div>
                                <!-- <div *ngIf="assessment.submitted && f.medication_admin_responsibility.errors" class="invalid-feedback">
                            <div *ngIf="f.medication_admin_responsibility.errors.required">Field is required</div>
                        </div> -->
                            </div>
                        </div>

                        <!-- Baby's supplements -->
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="px-0">Baby's supplies responsibility:
                                </label>
                                <div class="d-flex">
                                    <input id="categories7" type="checkbox" value="father"
                                        formControlName="baby_supplies_responsibility"
                                        (change)="showInputOnSelection($event, 'nurse')"
                                        [checked]="isFatherSelected3" />
                                    <label for="categories7" class="mx-2 px-0">Father</label>
                                </div>
                                <div class="d-flex">
                                    <input id="categories8" type="checkbox" value="mother"
                                        formControlName="baby_supplies_responsibility"
                                        (change)="showInputOnSelection($event, 'nurse')"
                                        [checked]="isMotherSelected3" />
                                    <label for="categories8" class="mx-2 px-0">Mother</label>
                                </div>

                                <div class="d-flex">
                                    <input id="categories9" type="checkbox" value="nurse"
                                        formControlName="baby_supplies_responsibility"
                                        (change)="showInputOnSelection($event, 'nurse')" [checked]="isNurseSelected3" />
                                    <label for="categories9" class="mx-2 px-0">Nurse</label>
                                </div>
                                <!-- <div class="invalid-feedback">
                            Please choose one or more
                        </div> -->
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <!-- Requires changes -->
                        <div class="col-lg-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="px-0">Requires changes in the environment?</label>
                                <!-- <div class="d-flex">
                            <input id="categories12" type="checkbox" value="No" (change)="requireChanges = true"
                                formControlName="environment_changes" [class.is-invalid]="(assessment.submitted &&
                            initialAssessmentForm.get('environment_changes')?.invalid)
                            ||
                            initialAssessmentForm.get('environment_changes')?.invalid
                            &&
                            initialAssessmentForm.get('environment_changes').touched" />
                            <label for="categories13" class="mx-2 px-0">No</label>
                        </div>
                        <div class="d-flex">
                            <input id="categories0" type="checkbox" value="Yes" (change)="requireChanges = false"
                                formControlName="environment_changes" [class.is-invalid]="(assessment.submitted &&
                            initialAssessmentForm.get('environment_changes')?.invalid)
                            ||
                            initialAssessmentForm.get('environment_changes')?.invalid
                            &&
                            initialAssessmentForm.get('environment_changes').touched" />
                            <label for="categories0" class="mx-2 px-0">Yes</label>
                        </div> -->
                                <div class="d-flex">
                                    <p class="me-3">
                                        <input type="radio" id="environment_changes-yes" name="environment_changes"
                                            formControlName="environment_changes" value="Yes"
                                            (change)="showInputOnSelection($event, 'environment_changes')">
                                        <label for="environment_changes-yes">Yes</label>

                                    </p>
                                    <p class="d-flex">
                                        <input type="radio" id="environment_changes-no" name="environment_changes"
                                            formControlName="environment_changes" value="No"
                                            (change)="showInputOnSelection($event, 'environment_changes')">
                                        <label for="environment_changes-no">No</label>
                                    </p>
                                </div>
                                <div class="invalid-feedback">
                                    Field is required
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="form-group">
                                <label class="p-0">Identify requirements:<span class="required">*</span></label>
                                <textarea name="identify" id="kt_autosize_1" rows="3" class="form-control p-2"
                                    type="text" placeholder="Identify"
                                    [readonly]="['environment_changes-no' , ''].includes(initialAssessmentForm.controls['environment_changes'].value)"
                                    formControlName="environment_identify_require" [class.is-invalid]="(assessment.submitted &&
                            initialAssessmentForm.get('environment_identify_require')?.invalid)
                            ||
                            initialAssessmentForm.get('environment_identify_require')?.invalid
                            &&
                            initialAssessmentForm.get('environment_identify_require').touched">
                            </textarea>
                                <div class="invalid-feedback">
                                    Field is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="add-listings-btn" style="text-align: right;" *ngIf="isedit">
                        <div class="row">
                            <div class="col-lg col-12">
                                <div class="form-group text-start">
                                    <label class="p-0" style="text-align-last: left;">Created by:</label>
                                    <span>{{createdBy}}</span>

                                    <!-- <input type="text" formControlName="createdby" class="form-control"
                                        placeholder="Created by" readonly /> -->
                                </div>
                            </div>
                            <div class="col-lg col-12">
                                <div class="form-group text-start">
                                    <label class="p-0" style="text-align-last: left;">Created at:</label>
                                    <span>{{createdAt?.substring(0, 16).replace('T', ' ') }}</span>

                                    <!-- <input type="text" formControlName="createdat" class="form-control"
                                        placeholder="Updated by" readonly /> -->
                                </div>
                            </div>

                            <div class="col-lg col-12">
                                <div class="form-group text-start">
                                    <label class="p-0" style="text-align-last: left;">Updated by:</label>
                                    <span>{{updatedBy}}</span>

                                    <!-- <input type="text" formControlName="updateddat" class="form-control"
                                        placeholder="Created by" readonly /> -->
                                </div>
                            </div>
                            <div class="col-lg col-12 ">
                                <div class="form-group text-start">
                                    <label class="p-0" style="text-align-last: left;">Updated at:</label>
                                    <span>{{updatedAt?.substring(0, 16).replace('T', ' ') }}</span>

                                    <!-- <input type="text" formControlName="updateddby" class="form-control"
                                        placeholder="Updated by" readonly /> -->
                                </div>
                            </div>
                            <div class="col-auto align-self-end">
                                <div class="form-group">
                                    <p-button (click)="social()" class="secondary-btn"
                                        *ngIf="checkPermission(7)">Edit</p-button>
                                    <button type="submit" class="ms-2" *ngIf="checkPermission(5)">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </p-accordionTab>


            <!-- new field of contract form -->
            <p-accordionTab header="Contract Details" *ngIf="!isNurse">

                <div class="add-listings-box" style="display: contents;">

                    <div class="form-group table-responsive">
                        <div formArrayName="contract_json">
                            <ng-container
                                *ngFor="let control of getContractFormArray().controls; index as i; last as last">
                                <table class="table table-bordered" [formGroupName]="i">
                                    <thead>
                                        <tr>
                                            <th>Contract</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Total no Of Days</th>
                                            <th>Amount</th>
                                            <th>Type Of Shift</th>
                                            <th>Add new Contract</th>

                                    </thead>
                                    <tbody>
                                        <tr>

                                            <td>Contract </td>
                                            <td> <p-calendar dateFormat="dd/mm/yy" [showIcon]="true"
                                                    (onSelect)="calculateDays(i)" formControlName="start_date"
                                                    placeholder="DD/MM/YYYY"></p-calendar></td>
                                            <td><p-calendar dateFormat="dd/mm/yy" [showIcon]="true"
                                                    (onSelect)="calculateDays(i)" formControlName="end_date"
                                                    placeholder="DD/MM/YYYY"></p-calendar></td>
                                            <td> <input type="text" class="form-control" placeholder="Total No Of Days"
                                                    formControlName="total_days" /></td>
                                            <td>
                                                <div>
                                                    <input type="number" class="form-control" placeholder="Enter Amount"
                                                        formControlName="amount" />

                                                </div>
                                            </td>
                                            <td> <p-dropdown [options]="serviceArrayForContract" optionLabel="name"
                                                    [filter]="true" filterBy="name" [showClear]="false"
                                                    optionValue="name" placeholder="Select Type Of Shift"
                                                    [resetFilterOnHide]="true"
                                                    formControlName="type_of_shift"></p-dropdown>

                                            </td>
                                            <td>
                                                <div class="col-12 d-flex mb-3 justify-content-center">
                                                    <p-button type="button" icon="bx bx-plus"
                                                        (click)="addcontractObject('add', i)" iconPos="right"
                                                        class="secondary-btn "></p-button>
                                                    <div class="remove">
                                                        <p-button type="button" *ngIf="i!= 0" class="secondary-btn ms-2"
                                                            icon="bx bx-minus" (click)="addcontractObject('delete', i)"
                                                            iconPos="right"></p-button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </ng-container>
                        </div>
                        <div class="add-listings-btn" style="text-align: right;" *ngIf="isedit">
                            <div class="row">
                                <div class="col-lg col-12">
                                    <div class="form-group text-start">
                                        <label class="p-0" style="text-align-last: left;">Created by:</label>
                                        <span>{{createdBy}}</span>

                                        <!-- <input type="text" formControlName="createdby" class="form-control"
                                            placeholder="Created by" readonly /> -->
                                    </div>
                                </div>
                                <div class="col-lg col-12">
                                    <div class="form-group text-start">
                                        <label class="p-0" style="text-align-last: left;">Created at:</label>
                                        <span>{{createdAt?.substring(0, 16).replace('T', ' ') }}</span>

                                        <!-- <input type="text" formControlName="createdat" class="form-control"
                                            placeholder="Updated by" readonly /> -->
                                    </div>
                                </div>

                                <div class="col-lg col-12">
                                    <div class="form-group text-start">
                                        <label class="p-0" style="text-align-last: left;">Updated by:</label>
                                        <span>{{updatedBy}}</span>

                                        <!-- <input type="text" formControlName="updateddat" class="form-control"
                                            placeholder="Created by" readonly /> -->
                                    </div>
                                </div>
                                <div class="col-lg col-12 ">
                                    <div class="form-group text-start">
                                        <label class="p-0" style="text-align-last: left;">Updated at:</label>
                                        <span>{{updatedAt?.substring(0, 16).replace('T', ' ') }}</span>

                                        <!-- <input type="text" formControlName="updateddby" class="form-control"
                                            placeholder="Updated by" readonly /> -->
                                    </div>
                                </div>
                                <div class="col-auto align-self-end">
                                    <div class="form-group">
                                        <p-button (click)="contarct()" class="secondary-btn"
                                            *ngIf="checkPermission(7)">Edit</p-button>
                                        <button type="submit" class="ms-2" *ngIf="checkPermission(5)">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </p-accordionTab>
        </p-accordion>
        <div class="add-listings-btn" style="text-align: right;">
            <button type="submit" *ngIf="checkPermission(5)">Submit </button>
        </div>

    </form>
    <!-- End -->


    <div class="flex-grow-1"></div>

    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->