import { Component, OnInit } from '@angular/core';
import { MaikaListService } from 'src/app/services/maika-list.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MasterService } from 'src/app/services/master.service';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-zero-to-three-months-list',
  templateUrl: './zero-to-three-months-list.component.html',
  styleUrls: ['./zero-to-three-months-list.component.scss'],
})
export class ZeroToThreeMonthsListComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Zero to Three Months',
      subTitle: 'Dashboard',
    },
  ];
  feeding_frequency_breast: any;
  tub_frequency: any;
  sponge_frequency: any;
  changings_frequency: any;
  temp: any;
  bottle: any = [];
  diaper: any = [];
  stellrization: any = [];
  vaccination: any = [];
  vitamins: any = [];
  medicine: any = [];
  // sleepTraing: any [];
  filterVal: string;
  formula_frequency: any;
  breast_frequency: any;
  edit: any;
  zeroToThreeMonthData: any = [];
  skin: any = [];
  tub: any = [];
  bathing: any = [];
  loading: any;
  tooltipText: string = 'Zero to Three Months List';
  datalength: any;
  viewDetail: any = {};
  displayMaximizable: boolean = false;
  dataonzeroposition: any;
  filteredPDFData: any[];
  pdfData: any[];
  exportColumns: any = [];
  cols: any;
  permissionData: any = [];
  permissions: any = [];
  constructor(
    private zero_to_three_month_service: MaikaListService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private masterService: MasterService,
    private messageService: MessageService,

  ) { }

  // fn to get the data from backend
  getAllData() {
    this.spinner.show()
    this.zero_to_three_month_service
      .getZeroToThreeMonthList()
      .subscribe((res: any) => {
        this.spinner.hide();
        console.log('response', res);
        this.zeroToThreeMonthData = res.data;
        this.datalength = res?.data?.length;
        console.log('zero to three month data', res.data);
      },
      (error: any) => {
        this.spinner.hide()
        // Handle any errors that occurred during the API call
      
       
      });
  }

  ngOnInit(): void {
    this.getAllData();
    localStorage.removeItem('editedOrderId');
    localStorage.removeItem('create');

    console.log('new api call');
    this.getAllUserPermission();

    this.cols = [
      { field: 'sr_no', header: 'Sr No' },
      { field: 'name', header: 'Name' },
      { field: 'date_of_birth', header: 'Date of Birth' },
      { field: 'days', header: 'Age' },
      { field: 'months', header: 'Months' },
    ];
    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
  }

  getAllUserPermission() {
    let id = localStorage.getItem('user_id');
    this.masterService.getUserPremissionById(id).then((res: any) => {
      console.log('response', res);

      this.permissionData = res.data;

      for (let i = 0; i < this.permissionData.length; i++) {
        
        this.permissions.push(this.permissionData[i].permission_id);
      }
      console.log('all permissions', this.permissions);
    });
  }

  checkPermission(permission: number) {
    console.log('return permission ', this.permissions.includes(permission));
    return this.permissions.includes(permission);
  }
  formatDateTime(date: string | undefined): string {
    if (date) {
      const formattedDate = new Date(date);
      // const year = formattedDate.getFullYear();
      // const month = ('0' + (formattedDate.getMonth() + 1)).slice(-2);
      // const day = ('0' + formattedDate.getDate()).slice(-2);
      const hours = ('0' + formattedDate.getHours()).slice(-2);
      const minutes = ('0' + formattedDate.getMinutes()).slice(-2);
      const seconds = ('0' + formattedDate.getSeconds()).slice(-2);

      return `${hours}:${minutes}:${seconds}`;
      // return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }
    return '-';
  }
  // open view dialog
  details: any = [];
  showMaximizableDialog(id: any) {
    this.viewDetail = {};
    this.zeroToThreeMonthData?.map((res: any) => {
      console.log('showwww', id, '......', res.id);
      if (res.id == id) {
        // console.log("view detail:", res?.feeding_breast_time);
        this.viewDetail = res;
        console.log('single record:', this.viewDetail);
        this.dataonzeroposition = this.viewDetail.nanny_task_details_json;
        console.log('Data on zero position', this.dataonzeroposition);
        const formula_frequency =
          this.dataonzeroposition?.bathing_frequency_sponge?.frequency;
        console.log('Breast frequency', formula_frequency);
        const tub_frequency =
          this.dataonzeroposition?.bathing_tub_frequency?.frequency;
        console.log('Breast frequency', tub_frequency);
        const breast_frequency =
          this.dataonzeroposition?.feeding_frequency?.frequency;
        console.log('Breast frequency', breast_frequency);
        const feeding_frequency_breast =
          this.dataonzeroposition?.feeding_frequency_breast?.frequency;
        console.log('feeding frequency', feeding_frequency_breast);
        const changings_frequency =
          this.dataonzeroposition?.changing_colthes_frequency?.frequency;
        console.log('Breast frequency', changings_frequency);
        const temperature_monitoring_frequency =
          this.dataonzeroposition?.temperature_monitoring_frequency?.frequency;
        console.log('Breast frequency', temperature_monitoring_frequency);
        // this.breast_frequency = JSON.parse(res?.feeding_frequency_breast)?.frequency;
        // this.formula_frequency = JSON.parse(res?.feeding_frequency)?.frequency;
        // console.log("vaccine",JSON.parse(JSON.parse(res?.vaccinations_json)));
        // console.log("vitamin",JSON.parse(JSON.parse(res?.vitamins_medications_json)));
        // this.vaccination = this.dataonzeroposition?.vaccinations_json;
        // console.log(this.vaccination);
        this.vitamins = this.dataonzeroposition?.vitamins_medications_json;
        
        this.edit = id;

        this.medicine = this.dataonzeroposition?.vitamins_medications_json[0]?.medicine_time;
        
        this.bottle = this.dataonzeroposition?.formula_calculation;
        //get data from skin care array
        this.skin = this.dataonzeroposition?.skin_care;
        this.stellrization = this.dataonzeroposition?.bottle_sterilization;
        this.diaper = this.dataonzeroposition?.diaper_change_times;
        this.bathing = this.dataonzeroposition?.bathing_time;
        this.tub = this.dataonzeroposition?.tub_bathing;
        // this.temp = JSON.parse(res?.temperature_monitoring_frequency)?.frequency;
        // console.log( this.temp);
        // this.tub_frequency = JSON.parse(res?.bathing_tub_frequency)?.frequency;
        // this.sponge_frequency = JSON.parse(res?.bathing_frequency_sponge)?.frequency;
        // this.changings_frequency = JSON.parse(res?.changing_colthes_frequency)?.frequency;
        // this.feeding_frequency_breast = JSON.parse(res?.feeding_frequency_breast)?.frequency;
      } else {
        ;
      }
      console.log(id);
    });
    this.displayMaximizable = true;
  }
  editOnCLick(id: any) {
    localStorage.setItem('editedOrderId', id);
    console.log('id', id);
    this.router.navigateByUrl('/portal/zero-to-three-months');
  }
  reset(dt) {
    dt.reset();
    this.filterVal = '';
  }
  onPDFExport() {
    this.filteredPDFData = [];
    this.pdfData = [];
    this.filteredPDFData = this.zeroToThreeMonthData;

    for (let i = 0; i < this.filteredPDFData.length; i++) {
      this.pdfData.push({
        sr_no: i + 1,
        name: this.filteredPDFData[i]?.name ?? '-',
        date_of_birth: this.filteredPDFData[i]?.date_of_birth ?? '-',
        days: this.filteredPDFData[i]?.days ?? '-',
        months: this.filteredPDFData[i]?.months ?? '-',
      });
    }

    this.zero_to_three_month_service.exportPdf(
      this.pdfData,
      this.exportColumns,
      'zeo-to-three-month.pdf'
    );
  }
}
