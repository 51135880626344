<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item">{{Content.subTitle}}</li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <div class="add-listings-box">
        <div class="Master-view-box">
            <div class="theader d-flex justify-content-between">
                <div class="d-flex w-100">
                    <span class="p-input-icon-left ml-auto mr-2 w-100%">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" class="form-control"
                            style="text-align: left; padding-left: 20%; height: 45px !important" scriptInjection
                            placeholder="Search Keyword" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                            [(ngModel)]="filterVal" />
                    </span>
                    <p-button type="button" class="mx-2" icon="bx bx-reset" tooltipPosition="bottom"
                        (click)="reset(dt1)"></p-button>
                </div>
                <div *ngIf="checkPermission(9)" class="add-listings-btn" style="text-align: right;">
                    <a routerLink="/portal/four-months-to-one-year-plan" class="nav-link">
                        <button type="submit">Add</button>

                    </a>
                </div>
                <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="onPDFExport()"
                    class="p-button-warning ms-2" pTooltip="PDF" tooltipPosition="bottom"></button>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-lg-12 col-md-12">
                <p-table #dt1 dataKey="id" [rows]="10" [loading]="loading" [showCurrentPageReport]="true"
                    [tableStyle]="{'min-width': '50rem', 'width' : '100%'}" styleClass="p-datatable-sm
                p-datatable-customers" responsiveLayout="scroll" [totalRecords]="datalength"
                    [rowsPerPageOptions]="[10, 25, 50]" [paginator]="true" currentPageReportTemplate="Showing {first} to
					{last} of {totalRecords} entries" [globalFilterFields]="['name', 'date_of_birth', 'age']"
                    [value]="fourMonthToTwoYearsData" editMode="row">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="min-width: 62px">S. No.</th>
                            <th>Name</th>
                            <th>Date of Birth</th>
                            <th>Days</th>
                            <th>Months</th>
                            <th>Years</th>
                            <th class="text-center" [width]="110">Action</th>
                        </tr>


                    </ng-template>
                    <!-- tables to show data -->
                    <ng-template pTemplate="body" let-list let-i="rowIndex">
                        <tr>
                            <td>
                                {{ i + 1 }}
                            </td>
                            <td>{{ list?.name ?? '-' | titlecase }}</td>
                            <td>{{ list?.date_of_birth ?? '-' }}</td>
                            <td>{{ list?.days ?? '-' }}</td>
                            <td>{{ list?.months ?? '-' }}</td>
                            <td>{{ list?.years ?? '-' }}</td>
                            <td class="text-center"><button (click)="showMaximizableDialog(list.id)" pButton pRipple
                                    *ngIf="checkPermission(10)" class="p-button-rounded p-button-text p-button-info"
                                    pTooltip="View" tooltipPosition="bottom"><i class="far fa-eye">
                                    </i></button>
                                <button *ngIf="checkPermission(11)" pButton pRipple
                                    class="p-button-rounded p-button-icon bx bx-pencil" pTooltip="Edit"
                                    tooltipPosition="bottom" (click)="editOnCLick(list.id)">
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8">No Data Found.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <!----------------------------------------------------------- view dialog --------------------------------------------------------->
            <!-- ***************************************************************************************************************** -->
            <p-dialog header="Four Months to One Year
            Baby Details" [(visible)]="displayMaximizable" [modal]="true" [style]="{ width: '90vw' }"
                [draggable]="false" [resizable]="false">
                <!-- Start -->
                <ng-container>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="my-profile-box">
                                <form>
                                    <!-- Baby detail -->
                                    <!-- accordion on listing -->
                                    <p-accordion>
                                        <p-accordionTab header="Baby Details">
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Name :</label>
                                                        <input type="text" readonly class="form-control"
                                                            value="{{viewDetail?.name ?? '-' | titlecase }}" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12
                                                col-md-12">
                                                    <div class="form-group">
                                                        <label>Gender :</label>
                                                        <input type="text" readonly class="form-control"
                                                            value="{{viewDetail?.gender ?? '-'}}" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Date of Birth :</label>
                                                        <input type="text" readonly class="form-control"
                                                            value="{{viewDetail?.date_of_birth ?? '-'}}" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Days :</label>
                                                        <input type="text" readonly class="form-control"
                                                            value="{{viewDetail?.days ?? '-'}}" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Months :</label>
                                                        <input type="text" readonly class="form-control"
                                                            value="{{viewDetail?.months ?? '-'}}" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Years :</label>
                                                        <input type="text" readonly class="form-control"
                                                            value="{{viewDetail?.years ?? '-'}}" />
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <p-accordionTab header="Feeding Assesment">
                                            <!-- feeding assesment listing  -->
                                            <h3>Feeding Assesment</h3>
                                            <div class="row">
                                                <label class="subHeading my-2">Assesment</label>
                                                <div class="col-xl-4 col-lg-12
                                             col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Baby is not interested when offered to breastfeed,
                                                            sleepy</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.answer?? '-'
                                                     }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                             col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Baby is showing feeding cues but not
                                                            attaching</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.attach??'-'
                                                     }}" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">

                                                <div class="col-xl-4 col-lg-12
                                             col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Attaches at the breast but quickly falls
                                                            asleep</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.Asleep?? '-'
                                                     }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                             col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Attaches for short bursts with long pause</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.LongPause??'-'
                                                     }}" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">

                                                <div class="col-xl-4 col-lg-12
                                             col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Attaches well with long rhythmical sucking and
                                                            swallowing
                                                            for a short feed</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.rhythmical?? '-'
                                                     }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                             col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Attaches well for a sustained period with long
                                                            rhythmical
                                                            sucking and swallowing</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.swallowing??'-'
                                                     }}" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">

                                                <div class="col-xl-4 col-lg-12
                                             col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Normal skin color and tone</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.Normal_skin?? '-'
                                                     }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                             col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Gaining weight appropriately</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.weight??'-'
                                                     }}" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12
                                             col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Other Concerns</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.Other_concerns??'-'
                                                     }}" />
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <p-accordionTab header="Daily Cares">
                                            <h3>Daily Cares</h3>
                                            <div class="row">
                                                <label class="subHeading my-2">Feeding: Prepared By</label>
                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Mother</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.feeding_prepared_mother == 1? 'Yes': '-'
                                                    }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Nurse</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.feeding_prepared_nanny== 1? 'Yes': '-' ?? '-'
                                                    }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Other</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.feeding_prepared_other== 1? 'Yes': '-' ?? '-'
                                                    }}" />
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- MILK -->
                                        <p-accordionTab header="Milk Feeding">
                                            <h3>Milk</h3>
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Breast</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.feeding_breast== 1? 'Yes': '-' ?? '-'
                                                    }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                        col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Frequency</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.feeding_frequency_breast ?? '-'
                                                }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                    col-md-12" *ngFor="let time of dataonzeroposition?.
                                    breast_feeding">
                                                    <div class="form-group">
                                                        <label for="">Time</label>
                                                        <input type="text" readonly class="form-control"
                                                            [value]="formatDateTime(time?.feeding_breast_time)" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Formula</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.feeding_type== 1? 'Yes': '-' ?? '-'
                                                    }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                        col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Frequency</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.feeding_frequency ?? '-'
                                                }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                    col-md-12">
                                                    <div class="form-group">
                                                        <label for=""> Start Time</label>
                                                        <input type="text" readonly class="form-control"
                                                            [value]="formatDateTime(dataonzeroposition?.feeding_time)" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                        col-md-12">
                                                    <div class="form-group">
                                                        <label for=""> End Time</label>
                                                        <input type="text" readonly class="form-control"
                                                            [value]="formatDateTime(dataonzeroposition?.formula_feeding_time)" />
                                                    </div>
                                                </div>


                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Amount</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.feeding_amount_per ?? '-'
                                                    }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Type of Formula</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.type_of_formula ?? '-'
                                                    }}" />
                                                    </div>
                                                </div>
                                                <div class="row" *ngFor="let data of bottle">
                                                    <div class="col-xl-4 col-lg-12
                                        col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Time</label>
                                                            <input type="text" readonly class="form-control"
                                                                [value]="formatDateTime(data?.formula_timeArray)" />

                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-12
                                                    col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Actual Feeding Time</label>
                                                            <input type="text" readonly class="form-control"
                                                                [value]="formatDateTime(data?.nurse_feeding)" />

                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Amount</label>
                                                            <input type="text" readonly class="form-control" value="{{ data?.amount ?? '-'
                                                    }}" />
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-12
                                      col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Name of formula</label>
                                                            <input type="text" readonly class="form-control"
                                                                value="{{ data?.remark?? '-'}}" />

                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Remark</label>
                                                            <input type="text" readonly class="form-control" value="{{ data?.formula_remark ?? '-'
                                                    }}" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- MILK END-->

                                        <!-- Bottle Sterilization -->
                                        <p-accordionTab header="Bottle Sterilization">
                                            <h3>Bottle Sterilization</h3>
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">All</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.bottle_sterilization_all== 1? 'Yes': '-' ?? '-'
                                                    }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                        col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Time</label>
                                                        <input type="text" readonly class="form-control"
                                                            [value]="formatDateTime(dataonzeroposition?.bottle_sterilization_all_time)" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">One At A time</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.bottle_sterilization_onetime== 1? 'Yes': '-' ?? '-'
                                                    }}" />
                                                    </div>
                                                </div>
                                                <div class="row" *ngFor="let data of stellrization">
                                                    <div class="col-xl-4 col-lg-12
                                        col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Time</label>
                                                            <input type="text" readonly class="form-control"
                                                                [value]="formatDateTime(data?.sterilization_timeArray)" />

                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-12
                                      col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Remark</label>
                                                            <input type="text" readonly class="form-control"
                                                                value="{{ data?.sterilization_remark?? '-'}}" />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Bottle Sterilization End-->

                                        <!--Solid -->
                                        <p-accordionTab header="Solid Food">
                                            <h3>Solid</h3>
                                            <!-- Snack -->
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Snack</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.solid_other_snack== 1? 'Yes': '-' ?? '-'
                                            }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                        col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Time</label>
                                                        <input type="text" readonly class="form-control"
                                                            [value]="formatDateTime(dataonzeroposition?.solid_other_snack_time)" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                        col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Food Component</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.soild_other_snack_food_components ?? '-'
                                            }}" />
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Regular Meal -->
                                            <div class="row" *ngFor="let food of regular">
                                                <!-- Breakfast -->
                                                <div class="col-xl-4 col-lg-12
                                         col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Breakfast</label>
                                                        <input type="text" readonly class="form-control" value="{{ food?.regular_meal_type == 1? 'Yes': '-'?? '-'
                                            }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Time</label>
                                                        <input type="text" readonly class="form-control" value="{{ food?.regular_meal_time ? (food?.regular_meal_time | date: 'h:mm:ss' ): '-'
                                            }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Food Component</label>
                                                        <input type="text" readonly class="form-control" value="{{ food?.regular_meal_food_components ?  food?.regular_meal_food_components:  '-'
                                             }}" />
                                                    </div>
                                                </div>
                                            </div>


                                            <!-- Lunch -->
                                            <div class="row" *ngFor="let food of lunch">
                                                <div class="col-xl-4 col-lg-12
                                         col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Lunch</label>
                                                        <input type="text" readonly class="form-control" value="{{ food?.regular_meal_lunch== 1? 'Yes': '-' ?? '-'
                                            }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Time</label>
                                                        <input type="text" readonly class="form-control" value="{{ food?.regular_meal_lunch_time ? (food?.regular_meal_lunch_time | date: 'h:mm:ss' ): '-'
                                            }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Food Component</label>
                                                        <input type="text" readonly class="form-control" value="{{  food?.regular_meal_lunch_food_components ?  food?.regular_meal_lunch_food_components:'-'
                                            }}" />
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Dinner -->
                                            <div class="row" *ngFor="let food of dinner">
                                                <div class="col-xl-4 col-lg-12
                                         col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Dinner</label>
                                                        <input type="text" readonly class="form-control" value="{{ food?.regular_meal_dinner== 1? 'Yes': '-' ?? '-'
                                            }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Time</label>
                                                        <input type="text" readonly class="form-control" value="{{ food?.regular_meal_dinner_time ? (food?.regular_meal_dinner_time | date: 'h:mm:ss' ): '-'
                                            }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Food Component</label>
                                                        <input type="text" readonly class="form-control" value="{{ food?.regular_meal_dinner_food_components ? food?.regular_meal_dinner_food_components: '-'
                                            }}" />
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Solid End-->

                                        <!-- Food/Medicine Allergy -->
                                        <p-accordionTab header="Food/Medicine Allergy">
                                            <h3>Food/Medicine Allergy</h3>
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
                                         col-md-12">
                                                    <!-- <div class="form-group">
                                                <label for="">Food/Medicine Allergy</label>
                                                <input type="text" readonly class="form-control" value="{{ data?.food_medicine_allergy== 1? 'Yes': '-' ?? '-'
                                            }}" />
                                            </div> -->

                                                    <ng-container>
                                                        <div class="form-group">
                                                            <label for="">Food/Medicine Allergy</label>
                                                            <input type="text" readonly class="form-control"
                                                                *ngIf="dataonzeroposition?.food_medicine_allergy !== null && dataonzeroposition?.food_medicine_allergy !== '' && dataonzeroposition?.food_medicine_allergy !== undefined; else elseBlock"
                                                                value="{{ dataonzeroposition?.food_medicine_allergy == '1'? 'Yes': 'No'}}" />
                                                            <ng-template #elseBlock>
                                                                <input type="text" readonly class="form-control"
                                                                    value="-" />
                                                            </ng-template>
                                                        </div>
                                                    </ng-container>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Food Component</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.food_medicine_allergy_specify_prod ?? '-'
                                            }}" />
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Food/Medicine Allergy End-->

                                        <!-- Bathing -->
                                        <p-accordionTab header="Bathing">
                                            <h3>Bathing</h3>
                                            <div class="row">
                                                <!-- Sponge -->
                                                <div class="col-xl-4 col-lg-12
                                         col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Sponge</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.bathing_sponge== 1? 'Yes': '-' ?? '-'
                                            }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                            col-md-12" *ngFor="let bath of bathing">
                                                    <div class="form-group">
                                                        <label for="">Time</label>
                                                        <input type="text" readonly class="form-control" [value]="formatDateTime(bath?.bathing_sponge_time)
                                        " />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Frequency</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.bathing_frequency_sponge ?? '-'
                                            }}" />
                                                    </div>
                                                </div>
                                            </div>


                                            <!-- Tub -->
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
                                         col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Tub</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.bathing_tub == 1? 'Yes': '-'?? '-'
                                            }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                            col-md-12" *ngFor="let bath of tub">
                                                    <div class="form-group">
                                                        <label for="">Time</label>
                                                        <input type="text" readonly class="form-control" [value]="formatDateTime(bath?.bathing_tub_time)
                                            " />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Frequency</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.bathing_tub_frequency ?? '-'
                                            }}" />
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Shower -->
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
                                         col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Shower</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.bathing_shower== 1? 'Yes': '-' ?? '-'
                                            }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                            col-md-12" *ngFor="let bath of shower">
                                                    <div class="form-group">
                                                        <label for="">Time</label>
                                                        <input type="text" readonly class="form-control" [value]="formatDateTime(bath?.bathing_shower_time )
                                            " />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Frequency</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.bathing_shower_frequency ?? '-'
                                            }}" />
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Bathing End -->
                                        <p-accordionTab header="Diaper Change">
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
                             col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Diaper Frequency</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.diaper_change_frequency?? '-'
                                }}" />
                                                    </div>
                                                    <div class="row" *ngFor="let change of diaper">
                                                        <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Diaper Change</label>
                                                                <input type="text" readonly class="form-control"
                                                                    value="{{ change?.change ? 'Yes' : 'No' }}" />
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12
                                     col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Change Time </label>
                                                                <input type="text" readonly class="form-control"
                                                                    [value]="formatDateTime(change?.time)" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Skin Cares -->
                                        <p-accordionTab header="Skin Cares">
                                            <h3>Skin Cares</h3>
                                            <div class="row" *ngFor="let care of skin ">
                                                <!-- Lotion -->
                                                <div class="col-xl-4 col-lg-12
                                          col-md-12">
                                                    <div class="form-group">
                                                        <label>Lotion</label>
                                                        <input type="text" readonly class="form-control" value="{{ care?.skin_care_lotion== 1? 'Yes': '-' ?? '-'
                                             }}" />
                                                    </div>
                                                </div>

                                                <!-- Baby oil -->
                                                <div class="col-xl-4 col-lg-12
                                         col-md-12">
                                                    <div class="form-group">
                                                        <label>Baby Oil</label>
                                                        <input type="text" readonly class="form-control" value="{{ care?.skin_care_baby_oil== 1? 'Yes': '-' ?? '-'
                                            }}" />
                                                    </div>
                                                </div>

                                                <!-- Others -->
                                                <div class="col-xl-4 col-lg-12
                                        col-md-12">
                                                    <div class="form-group">
                                                        <label>Others</label>
                                                        <input type="text" readonly class="form-control" value="{{ care?.skin_care_others== 1? 'Yes': '-' ?? '-'
                                           }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                             col-md-12">
                                                    <div class="form-group">
                                                        <label>Specify Product</label>
                                                        <input type="text" readonly class="form-control" value="{{ care?.skin_care_specify_produ ? care?.skin_care_specify_produ :'-'
                                             }}" />
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Skin Cares End-->

                                        <!-- Wipes -->
                                        <p-accordionTab header="Wipes">
                                            <h3>Wipes</h3>
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
                                           col-md-12">
                                                    <!-- <div class="form-group">
                                                <label>Wipes</label>
                                                <input type="text" readonly class="form-control" value="{{ data?.skin_care_Wipes== 1? 'Yes': '-' ?? '-'
                                              }}" />
                                            </div> -->

                                                    <ng-container>
                                                        <div class="form-group">
                                                            <label for="">Wipes</label>
                                                            <input type="text" readonly class="form-control"
                                                                *ngIf="dataonzeroposition?.skin_care_Wipes !== null && dataonzeroposition?.skin_care_Wipes !== ''  && dataonzeroposition?.skin_care_Wipes !== undefined; else elseBlock"
                                                                value="{{ dataonzeroposition?.skin_care_Wipes == '1'? 'Yes': 'No'}}" />
                                                            <ng-template #elseBlock>
                                                                <input type="text" readonly class="form-control"
                                                                    value="-" />
                                                            </ng-template>
                                                        </div>
                                                    </ng-container>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                              col-md-12">
                                                    <div class="form-group">
                                                        <label>Special Instruction</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.skin_care_special_instructions ?? '-'
                                              }}" />
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Wipes End-->

                                        <!-- Changing Clothes -->
                                        <p-accordionTab header="Changing Clothes">
                                            <h3>Changing Clothes</h3>
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
                                           col-md-12">
                                                    <div class="form-group">
                                                        <label>Frequency</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.changing_colthes_frequency ?? '-'
                                              }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                              col-md-12">
                                                    <div class="form-group">
                                                        <label>Special Instructions</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.changing_colthes_spiecal_instructions ?? '-'
                                              }}" />
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Changing Clothes End-->

                                        <!-- Sleeping -->
                                        <p-accordionTab header="Sleeping">
                                            <h3>Sleeping</h3>
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
                                           col-md-12">
                                                    <div class="form-group">
                                                        <label>With Mother</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.sleeping_mother== 1? 'Yes': '-' ?? '-'
                                              }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                              col-md-12">
                                                    <div class="form-group">
                                                        <label>Time</label>
                                                        <input type="text" readonly class="form-control" [value]="formatDateTime(dataonzeroposition?.sleeping_mother_time)
                                              " />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
                                         col-md-12">
                                                    <div class="form-group">
                                                        <label>With Nurse</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.sleeping_nanny== 1? 'Yes': '-' ?? '-'
                                            }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label>Time</label>
                                                        <input type="text" readonly class="form-control" [value]="formatDateTime(dataonzeroposition?.sleeping_nanny_time)
                                        " />
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Sleeping End-->

                                        <!-- Sleep Training-->
                                        <p-accordionTab header="Sleep Training">
                                            <h3>Sleep Training</h3>
                                            <div class="row">
                                                <!-- <label class="subHeading my-2">Assesment</label> -->
                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Sleep Training
                                                        </label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.sleep_train?? '-'
                                                    }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Wake up time (morning)</label>
                                                        <input type="text" readonly class="form-control" [value]="formatDateTime( dataonzeroposition?.walk_time)
                                                " />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">

                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Nap time</label>
                                                        <input type="text" readonly class="form-control" [value]="formatDateTime (dataonzeroposition?.nap_time)
                                                    " />
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12
                                        col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Sleep time (evening)</label>
                                                        <input type="text" readonly class="form-control" [value]="formatDateTime (dataonzeroposition?.sleep_time)
                                                " />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Stroller</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.stroller??'-'
                                                    }}" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">

                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Can the baby get himself back to sleep when
                                                            interrupted during short naps?</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.interrupt?? '-'
                                                    }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Needs dummy to help soothe the baby during
                                                            sleep</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.need_dummy??'-'
                                                    }}" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">

                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Pacifier</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.pacifier?? '-'
                                                    }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Songs before sleep
                                                        </label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.song_sleep??'-'
                                                    }}" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Reading Sleep</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.reading_sleep??'-'
                                                    }}" />
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Ponits End -->

                                        <!-- Temperature Monitoring -->
                                        <p-accordionTab header="Vitamins/Medications">
                                            <h3>Temperature Monitoring</h3>
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label>Frequency</label>
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.temperature_monitoring_frequency ??  '-'
                                               }}" />
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Temperature Monitoring End-->

                                            <!-- Vitamins/Medications -->

                                            <h3>Vitamins/Medications</h3>
                                            <div class="row" *ngFor="let vitamin of vitamins">
                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label>Medications</label>
                                                        <input type="text" readonly class="form-control" value="{{ vitamin?.vitamin ? vitamin?.vitamin : '-'
                                               }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                            col-md-12" *ngFor="let time of medicine">
                                                    <div class="form-group">
                                                        <label>Time</label>
                                                        <input type="text" readonly class="form-control"
                                                            value="{{ time?.time? (time?.time | date: 'HH:mm:ss'): '-' }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                    <div class="form-group">
                                                        <label>Frequency</label>
                                                        <input type="text" readonly class="form-control" value="{{ vitamin?.frequency ? vitamin?.frequency : '-'
                                               }}" />
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Vitamins/Medications End-->

                                            <!-- Vaccinations -->
                                            <!-- <h3>Vaccinations</h3>
                                            <div class="row" *ngFor="let vaccine of vaccination">
                                                <div class="col-xl-4 col-lg-12
                                             col-md-12">
                                                    <div class="form-group">
                                                        <label>Vaccinations</label>
                                                        <input type="text" readonly class="form-control" value="{{ vaccine?.vaccination ? vaccine?.vaccination :'-'
                                                }}" />
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                                             col-md-12">
                                                    <div class="form-group">
                                                        <label>Date of Administration</label>
                                                        <input type="text" readonly class="form-control" value="{{ vaccine?.date_administration ?(vaccine?.date_administration | date: 'dd/MM/yyyy'): '-'
                                                }}" />
                                                    </div>
                                                </div>
                                            </div> -->
                                        </p-accordionTab>
                                        <!-- Vaccinations End-->

                                        <!-- Remark -->
                                        <p-accordionTab header="Remark">
                                            <h3>Remark</h3>
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-12
                                              col-md-12">
                                                    <div class="form-group">
                                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.remarks ?? '-'
                                                 }}" />
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Remark End-->
                                    </p-accordion>
                                </form>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-template pTemplate="footer" let-list let-i="rowIndex">


                    <div style="text-align: left; margin-top: 2px; padding-top:10px; padding-bottom:10px; float:left">
                        <table class="table-stamp">
                            <tr>
                                <td><b>Created By:</b> {{viewDetail?.created_by_user_name}}</td>
                                <td><b>Time:</b>{{ viewDetail.created_at.substring(0, 19).replace('T', ' ') }}</td>
                                <td><b>Updated By:</b> {{ viewDetail?.updated_by_user_name}}</td>
                                <td><b>Time:</b>{{ viewDetail.updated_at.substring(0, 19).replace('T', ' ') }}</td>
                            </tr>
                        </table>
                    </div>

                    <div class="col-6 col-md-auto align-self-end ">
                        <div class="form-group">
                            <!-- Buttons for editing and confirming changes -->
                            <button pButton pRipple class="p-button-rounded p-button-icon secondary-btn" pTooltip="Edit"
                                tooltipPosition="bottom" (click)="editOnCLick(edit)"><i
                                    class="bx bx-pencil"></i></button>
                            <p-button (click)="displayMaximizable=false" label="Ok"
                                styleClass="p-button-text"></p-button>
                        </div>
                    </div>


                </ng-template>
            </p-dialog>
        </div>
    </div>
    <app-copyrights></app-copyrights>
</div>